import {
          LOAD_SUBJECTS, SAVE_SUBJECTS, SUBJECTS_ERROR, RESET_SUBJECTS
        } from "./actionTypes";

const initialState = {
  subjects: [],
  error: "",
  loading: false
}

const subjectsReducer = (state=initialState, action) => {
	switch(action.type){
    case LOAD_SUBJECTS:
      return {
        subjects: [],
        error: "",
        loading: true,
      }

    case SAVE_SUBJECTS:
      return {
        ...state,
        subjects: action.payload.subjects,
        error: "",
        loading: false
      }

      case SUBJECTS_ERROR:
        return {
          ...state,
          error: action.error,
          loading: false
        }
      
    case RESET_SUBJECTS:
      return initialState;

    default:
      return state
  }
}

export default subjectsReducer;