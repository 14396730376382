import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap'

import MapWithMarkers from 'components/maps/MapWithMarkers'

class TutorLocationsMapForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sessionLocations: this.props.sessionLocations || "",
      geolocations: this.props.geolocations || [], //array

      radiusUnit: "miles", //miles vs km
      defaultRadius: 10,
    }
  }

  componentDidMount() { 
    this.createMarkerLabels();
  }

  onChangeValue = (event) => {
    const { name, value } = event.target;
    this.setState({
          [name]: value
    });
  }

  addMarker = geolocation => {
    let { geolocations } = this.state;

    //Prevent duplicate addition
    // let index = geolocations.findIndex(geo => {return geo.id == geolocation.id});
    // if(index == -1){
      // geolocations.push(geolocation);
      this.setState({geolocations})  
    // }
  }

  deleteMarker = geolocationId => {
    let { geolocations } = this.state;
    
    let index = geolocations.findIndex(geo =>{ return geo.id == geolocationId});
    if(index > -1){
      geolocations.splice(index, 1);
    }
    
    this.setState({geolocations})
  }

  onDragMarkerEnd = geolocation => {
    //input: {id, lat, lng}  
    let { geolocations } = this.state;

    if(geolocations.length == 0){
      geolocations.push(geolocation);
    }
    else{
      geolocations.forEach(geo => {
        if(geo.id == geolocation.id){
          geo.lat = geolocation.lat;
          geo.lng = geolocation.lng;
        }
      });
    }

    this.setState({geolocations})
  }

  createMarkerLabels = () =>{
    let { geolocations } = this.state;
    let markerCount = 0;

    if(geolocations.length > 0){
      geolocations.forEach(geolocation => {
        markerCount++;
        if(geolocation.label ==undefined || geolocation.label == null){
          geolocation.label = markerCount.toString();
        }
      })

      this.setState({ geolocations });
    }
  }

  onSubmitSessionLocations = values => {
    this.props.onSubmitSessionLocations(values.sessionLocations) 
  }

  onSubmitMapGeolocations = values => {
    let { geolocations } = this.state;
    
    geolocations.forEach(geo => {
      geo.radius = values[geo.id] ? parseInt(values[geo.id]) : null;
      geo.radiusUnit = values.radiusUnit;
    })
    
    this.props.onSubmitMapGeolocations(geolocations);
  }

  render() {
    let { center=null, apiResponse } = this.props;
    let { sessionLocations, geolocations, radiusUnit, defaultRadius } = this.state;

    let loading = apiResponse.loading;

    let geoIds = {};

    if(geolocations.length > 0){
      let index = geolocations.findIndex(geo => {return geo.radiusUnit != null});
      if(index > -1){
        radiusUnit = geolocations[index].radiusUnit;
      } 

      geolocations.forEach(geo => geoIds[geo.id] = geo.radius);
    }

    const SessionSchema = Yup.object().shape({
                              sessionLocations: Yup.string()
                                      .required('Required'),
    });

    const MapsSchema = Yup.object().shape({
                        radiusUnit: Yup.string()
                          .required('Required'),
    });

    return (
        <Card>
            <CardBody>
              <CardTitle>Tutor Session Locations</CardTitle>
              <Formik
                initialValues={{ sessionLocations }}
                validationSchema={SessionSchema}
                onSubmit={this.onSubmitSessionLocations}
              >
              { props  => (
                  <Form className="needs-validation">
                    <FormGroup className="col-sm-8">
                      <Label>Where you can meet for sessions</Label>
                      <Input
                          type="text"
                          name="sessionLocations"
                          className="form-control"
                          placeholder="e.g. On NYU campus, downtown, 10 mile radius around Seattle"
                          value={props.values.sessionLocations}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                      />
                      <ErrorMessage name="sessionLocations" component="div" className="text-danger" />
                    </FormGroup>
                    <div className="col-sm-4">
                        {
                          loading
                              ?
                                <button
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    disabled={true}
                                >
                                    Updating ... {" "}
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                </button>
                              :
                                <button
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    type="submit"
                                >
                                    Update
                                </button>
                          }
                    </div>
                  </Form>
              )}
              </Formik>
              {/* <br />
              <Row>
                <Col md="8">
                  <CardTitle>Map</CardTitle>
                  <p>Add markers to the map below to show the campuses, neighborhoods and/or towns you can meet for <b>IN-PERSON</b> tutor sessions. Leave blank if you do not meet in-person.
                  </p>
                  <b>How To:</b><br />
                  <ol>
                    <li>Click the map to add markers</li>
                    <li>Drag each marker to the desired location and add a radius around the area where you can commute. </li>
                    <li>Double click the marker to delete the location.</li>
                  </ol>
                  {
                    center == null ?
                      <>
                        <b className="text-danger">Location Missing!</b> Add your location under the Contact Information tab.
                      </>
                    :
                      <MapWithMarkers 
                        key={this.props.id}
                        id={this.props.id}
                        // key={"geo-"+(geolocations != null ? geolocations.length : 0)}
                        centerLat={center.lat}
                        centerLng={center.lng}
                        geolocations={geolocations} 
                        dragMarker={true}
                        onDragMarkerEnd={this.onDragMarkerEnd}
                        onAddMarker={this.addMarker}
                        onDeleteMarker={this.deleteMarker} 
                        showMarkerLabel={true}
                        showCircle={true}
                        height="60vh"
                        />
                  }
                </Col>
                <Col md="4">
                  <Formik
                    key={geolocations.length > 0 ? "form-" + geolocations.length : "markerForm"}
                    initialValues={{ radiusUnit, ...geoIds }}
                    validationSchema={MapsSchema}
                    onSubmit={this.onSubmitMapGeolocations}
                  >
                  { props  => (
                      <Form className="needs-validation">
                        {
                          geolocations.length > 0 &&
                            <>
                              <Row>
                                <br />
                                <CardTitle>Marker Radius</CardTitle>
                                <p>Add a radius around the location where you can commute to.  If you cannot or do not want to commute, change the radius to zero.</p>
                                <br /><br /><br />
                                <Col xs="6">
                                  <Label>Radius Unit: </Label>
                                </Col>
                                <Col xs="6" sm="6">
                                  <Input type="select" 
                                      className=" custom-select" 
                                      name="radiusUnit" 
                                      value={props.values.radiusUnit}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}>
                                    <option value="miles">miles</option>
                                    <option value="km">km</option>
                                  </Input>  
                                </Col>
                              </Row>

                              {
                                geolocations.map((geo, i) => 
                                  <span key={"marker-" + i}>
                                    <Row>
                                      <Col xs="6" className="mt-2">
                                        Marker # {geo.label} : 
                                      </Col>
                                      <Col xs="5" sm="6" md="5" xl="4">
                                        <Input
                                          type="number"
                                          className="form-control"
                                          id={geo.id}
                                          name={geo.id.toString()}
                                          placeholder={""}
                                          required 
                                          min={0}
                                          max={200}
                                          value={props.values[geo.id.toString()] || defaultRadius}
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                        />
                                      </Col>
                                    </Row>
                                  </span>
                              )}

                              <Row>
                                <div className="mt-3 col-sm-6 col-md-6">
                                    {
                                      loading
                                          ?
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                disabled={true}
                                            >
                                                Saving ... {" "}
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            </button>
                                          :
                                          <button
                                              className="btn btn-primary btn-block waves-effect waves-light"
                                              type="submit"
                                          >
                                              Save
                                          </button>
                                      }
                                </div>
                              </Row>

                            </>
                        }
                      </Form>
                  )}
                  </Formik>
                </Col>
              </Row> */}
          </CardBody>
        </Card>
    )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
  apiResponse: apiResponseReducer
})

export default connect(mapStateToProps, null)(TutorLocationsMapForm)