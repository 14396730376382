import React, { Component } from 'react'
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "reactstrap"
  
import InboxEditor from "./InboxEditor"

export default class InboxEditorModal extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          modal: this.props.isOpen || false,
        }
        this.toggleModal.bind(this)
    }

    // toggleModal = () => {
    //     this.setState(prevState => ({
    //       modal: !prevState.modal,
    //     }))
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.modal !== this.props.isOpen) {
          this.setState({modal: this.props.isOpen});
        }
      }
    
    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))

        if(this.props.toggleModal != undefined){
            this.props.toggleModal(this.state.modal);
        }
    }
    
    render() {
        let { modal } = this.state;
        
        return (
            <Modal
                isOpen={modal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={this.toggleModal}
            >
                <div className="modal-content">
                <ModalHeader toggle={this.toggleModal}>New Message</ModalHeader>
                <ModalBody>
                    <InboxEditor 
                        {...this.props} />
                </ModalBody>
                {/* <ModalFooter>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={this.toggleModal}
                    >
                        Close
                    </Button>
                    <Button type="button" color="primary">
                        Send <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                </ModalFooter> */}
                </div>
            </Modal>
        )
  }
}
