import React, { Component } from "react"
import { connect } from "react-redux"
import moment from 'moment'

import { Link } from "react-router-dom"

import { ADMIN_MARKETPLACE_CONVERT_TUTOR_REQUEST, ADMIN_USER_DETAILS, STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS, STUDENT_TUTOR_REQUEST_PROGRESS } from "routes/paths"

import { Card, CardBody, CardTitle, Row, Col } from "reactstrap"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { getCurrencySymbol, camelCaseToWords } from "scripts/general"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import DataTable from "components/tables/DataTable"
import { ThemeSpinner } from "theme/elements/spinner"
import NavTabs from "components/navTabs/NavTabs"
import SubjectsDualList from "components/dualList/SubjectsDualList"
import SelectTutorsKanban from "components/kanban/SelectTutorsKanban"

import DuplicateTutorRequestForm from "components/forms/formik/marketplace/DuplicateTutorRequestForm"
import EditTutorRequestForm from "components/forms/formik/marketplace/EditTutorRequestForm"

class TutorRequestsAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestsProcessed: false,

      requestTypes: ["unaddressedRequests", 
              "potentialRejectedByStudent",
              "pendingTutorResponse",
              "contractsRejectedByStudent",
              "pendingStudentResponse",
              "tutorMissedDeadline",
              "studentMissedDeadline",
              "unclassifiedRequests",
              "potentialPendingStudent",
              "marketPlaceRequests",
              "unassignedRequests", 
              //"requestsWithoutAssignments"
      ],
      totalRequests: 0,

      showDuplicateForm: false,
      showEditForm: false,
      showSubjectsForm: false,
       
      requestsWithoutAssignments: [],
      unaddressedRequests: [],
      potentialRejectedByStudent: [],
      pendingTutorResponse: [],
      contractsRejectedByStudent: [],
      pendingStudentResponse: [],
      tutorMissedDeadline: [],
      studentMissedDeadline: [],
      unclassifiedRequests: [],
      potentialPendingStudent: [],
      unassignedRequests: [], 
      marketPlaceRequests:[],

      showReminders: false,
      reminderRequests: [],

      selectedTutorRequest: {},
      tableColumns: [
          { label: "ID", field: "id", sort: "asc", width: 150 },
          { label: "Student", field: "student", sort: "asc", width: 150 },
          { label: "Details", field: "details", width: 150 },
      ],

      tabs: [],
    }

    this.selectTutorRequest.bind(this);
    this.formatDataTableRows.bind(this);
    // this.updateSelectedPotentialTutorsDeclined.bind(this);
  }

  componentDidMount() {
    let { onSubmitApiCall } = this.props;
    onSubmitApiCall("getRequestsWithoutAssignments");
  }

  componentDidUpdate(prevProps, prevState) {
      let { apiResponse = {} } = this.props;
      
      if(prevProps != this.props){
          let newState = processApiResponse(apiResponse, this.state);

          if(prevState != newState){
              this.setState(newState);
              if(!newState.requestsProcessed && newState.requestsWithoutAssignments.length > 0){
                this.processRequestsWithoutAssignments(newState.requestsWithoutAssignments);
              }
          }
      }
  }

  toggleDuplicateForm = () =>{
    let { showDuplicateForm } = this.state;
    showDuplicateForm = !showDuplicateForm;
    const showEditForm = false;
    const showSubjectsForm = false;

    this.setState({ showEditForm, showDuplicateForm, showSubjectsForm });
  }

  toggleEditForm = () =>{
    let { showEditForm } = this.state;
    showEditForm = !showEditForm;
    const showDuplicateForm = false;
    const showSubjectsForm = false;

    this.setState({ showEditForm, showDuplicateForm, showSubjectsForm });
  }

  toggleSubjectForm = () =>{
    let { showSubjectsForm } = this.state;
    showSubjectsForm = !showSubjectsForm;
    const showEditForm = false;
    const showDuplicateForm = false;

    this.setState({ showEditForm, showDuplicateForm, showSubjectsForm });
  }

  getRequestedTutors = requestedTutorsArray => {
    return <>{
              requestedTutorsArray.map((requestedTutor, i) => 
                <span key={i}>
                  <Link to={ADMIN_USER_DETAILS + "/" + requestedTutor.refId} target="_blank" rel="noreferrer">{requestedTutor.name}</Link><br />
                </span>
              )
            }
          </>
  }

  selectTutorRequest = (tutorRequestId) => {
    let { requestTypes } = this.state;
    requestTypes.forEach(requestType => {
      if(this.state.hasOwnProperty(requestType)){
        let selectedTutorRequest = this.state[requestType].filter(tutorRequest => tutorRequest.id == tutorRequestId)[0];
        if(selectedTutorRequest != undefined){
          this.setState({selectedTutorRequest});
          return;
        }
      }
    });
  }

  formatDataTableRows = tutorRequests => {
    let requestRows = [];

    for (var i = 0; i < tutorRequests.length; i++) {
      let potentialTutors = tutorRequests[i].potentialTutors || [];
      let tutorContracts = tutorRequests[i].tutorContracts || [];
      let requestedTutors = tutorRequests[i].requestedTutors || []; //Array: [{id, refId, name}]

      let trId = tutorRequests[i].id;
      let trRefId = tutorRequests[i].refId;
      let reminderType = null;
      if(potentialTutors.length > 0 || tutorContracts.length > 0){
        if(tutorContracts.length > 0){
          reminderType = "tutor-contracts";
        }
        else {
          reminderType = "potential-tutors";
        }
      }

      let row = {id: <Link to="#kanban" onClick={() => this.selectTutorRequest(trId)}>{trId}</Link>,
                student: <>
                          <Link to={ADMIN_USER_DETAILS + "/" + tutorRequests[i].studentRefId} target="_blank" rel="noreferrer">
                            {tutorRequests[i].studentName}
                          </Link>
                          <br />
                          <a href={"http://www.google.com/search?q=" + tutorRequests[i].course.replace(/ /g, "+") + "+" + tutorRequests[i].school.replace(/ /g, "+")} target="_blank" rel="noreferrer">{tutorRequests[i].course + " @ " + tutorRequests[i].school}</a>
                          <br /> 
                          <span className="text-success">{getCurrencySymbol(tutorRequests[i].currency)}{tutorRequests[i].minRate + " - " + tutorRequests[i].maxRate} /hr </span>
                          <br />
                          <small>{tutorRequests[i].city}, {tutorRequests[i].state}</small>
                          <br />
                          {tutorRequests[i].marketing && <>Marketing: {tutorRequests[i].marketing}<br /></>}
                          {
                            tutorRequests[i].online &&
                              <span className="badge badge-success">Online</span>
                          }
                          {" "}
                          {
                            tutorRequests[i].inPerson &&
                              <span className="badge badge-soft-info">In-Person</span>
                          }
                          <br />
                          {
                            tutorRequests[i].scholarship &&
                              <span className="badge badge-warning">Scholarship</span>
                          }
                          <br />
                          <br />
                          <Link to={STUDENT_TUTOR_REQUEST_PROGRESS + "/" + tutorRequests[i].refId} target="_blank">View Progress</Link>
                          <br /><br />

                          {
                            tutorRequests[i].marketRequests.length == 0 
                              ?
                                <Link className="btn btn-sm btn-primary" to={ADMIN_MARKETPLACE_CONVERT_TUTOR_REQUEST + "/" + tutorRequests[i].id} target="_blank" rel="noreferrer">Convert To Market</Link>
                              :
                                <Link to={STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS + "/" + tutorRequests[i].refId} target="_blank" rel="noreferrer">View MARKET Progress</Link>
                          }

                          <br /><br />
                        </>,
                details: <>
                            <b>Created: {moment(tutorRequests[i].createdAt).fromNow()}</b><br />
                            {tutorRequests[i].sessionTime} - {tutorRequests[i].frequency}<br />
                            <span className="text-danger">{tutorRequests[i].concerns != null && tutorRequests[i].concerns != "" && <><br />Concerns: <br />{tutorRequests[i].concerns}</>}</span>
                            <br />
                            {tutorRequests[i].adminNotes}<br />
                            {
                              requestedTutors.length > 0 &&
                                <>
                                  <b>Requested Tutors</b><br />
                                  {this.getRequestedTutors(requestedTutors)}
                                  <br />
                                </>
                            }
                            { potentialTutors.length > 0 &&
                                <>
                                  <span className="text-info"><b>Potential Tutors: {potentialTutors.length}</b><br /></span>
                                  
                                  <span className=" float-right p-2"><button className="btn btn-sm btn-dark" onClick={() => this.updateSelectedPotentialTutorsDeclined(trId)}>Selected Tutors Declined</button></span>
                                  {
                                    potentialTutors.map((potentialTutor, i) =>
                                      <span key={i}>
                                        <Link to={ADMIN_USER_DETAILS + "/" + potentialTutor.tutorRefId} target="_blank" rel="noreferrer">{potentialTutor.tutorName}</Link> 
                                        {" "}
                                        {
                                          potentialTutor.online &&
                                            <span className="badge badge-success">Online</span>
                                        }
                                        {" "}
                                        {
                                          potentialTutor.inPerson &&
                                            <span className="badge badge-soft-info">In-Person</span>
                                        }
                                        <br />
                                        Student: <span className={potentialTutor.interested  == false ? "text-danger" : "text-dark"}>{getCurrencySymbol(potentialTutor.studentCurrency)}{potentialTutor.studentRate} {potentialTutor.studentPrepayRate && <>/ {potentialTutor.studentPrepayRate}</>}</span>
                                        {
                                          potentialTutor.interested == true &&
                                            <i className="bx bx-check-circle text-primary" />
                                        }
                                        {
                                          potentialTutor.interested == false &&
                                            <i className="bx bx-x-circle text-danger" style={{fontSize: "1em"}}/>
                                        }
                                        <br />
                                        Tutor: <span className="text-dark">{getCurrencySymbol(potentialTutor.tutorCurrency)}{potentialTutor.tutorRate}</span><br />  
                                        {
                                          potentialTutor.groupRate > 0 &&
                                          <>
                                            Group: <span className="text-dark">{getCurrencySymbol(potentialTutor.studentCurrency)}{potentialTutor.groupRate} / {potentialTutor.groupPrepayRate}</span><br />
                                            Tutor: <span className="text-dark">{getCurrencySymbol(potentialTutor.tutorCurrency)}{potentialTutor.tutorGroupRate}</span><br />
                                          </>
                                        }
                                        {
                                          potentialTutor.expiresAt &&
                                          <>Expires At: {moment(potentialTutor.expiresAt).fromNow()}</>
                                        }
                                        
                                        <br />
                                        <br />
                                      </span>
                                    )
                                  }
                                </>
                            }
                            {
                              potentialTutors.length >0 && tutorContracts.length > 0 &&
                                <hr />
                            }
                            {tutorContracts.length > 0 &&
                              <>
                                  <span className="text-danger"><b>Tutor Contracts: {tutorContracts.length}</b><br /></span>
                                  {
                                    tutorContracts.map((tutorContract, i) =>
                                      <span key={i}>
                                        <Link to={ADMIN_USER_DETAILS + "/" + tutorContract.tutorRefId} target="_blank" rel="noreferrer">{tutorContract.tutorName}</Link> 
                                        {" "}
                                        {
                                          tutorContract.online &&
                                            <span className="badge badge-soft-dark">Online</span>
                                        }
                                        {" "}
                                        {
                                          tutorContract.inPerson &&
                                            <span className="badge badge-soft-info">In-Person</span>
                                        }
                                        <br />
                                        Student: <span className="text-dark">{getCurrencySymbol(tutorContract.currency)}{tutorContract.rate} {tutorContract.prepayRate && <> / {tutorContract.prepayRate}</>}</span>
                                        {" "}{tutorContract.studentDecision == true && <i className="bx bx-check-circle text-primary" />}
                                        {tutorContract.studentDecision == false && <i className="bx bx-x-circle text-danger" />}
                                        <br />
                                        Tutor: <span className="text-dark">{getCurrencySymbol(tutorContract.tutorCurrency)}{tutorContract.tutorRate}</span>
                                        {" "}{tutorContract.tutorDecision == true && <i className="bx bx-check-circle text-primary" />}
                                        {tutorContract.tutorDecision == false && <i className="bx bx-x-circle text-danger" />}
                                        <br />  
                                        {
                                          tutorContract.groupSession &&
                                          <>
                                            Group: <span className="text-dark">{getCurrencySymbol(tutorContract.currency)}{tutorContract.groupRate} / {tutorContract.groupPrepayRate}</span><br />
                                            Tutor: <span className="text-dark">{getCurrencySymbol(tutorContract.tutorCurrency)}{tutorContract.tutorGroupRate}</span><br />
                                          </>
                                        }
                                        <br />
                                        {
                                          tutorContract.studentDecision != true && tutorContract.studentDeadline &&
                                            <>Student Deadline: {moment(tutorContract.studentDeadline).fromNow()}</>
                                        }
                                        {
                                          tutorContract.tutorDecision != true && tutorContract.tutorDeadline &&
                                            <>Tutor Deadline: {moment(tutorContract.tutorDeadline).fromNow()}</>
                                        }
                                        <br />
                                        <br />
                                      </span>
                                    )
                                  }
                              </>
                            }
                            <br />
                            {
                              reminderType && 
                                <>
                                  {tutorRequests[i].smsAlert && <button className="btn btn-sm btn-outline-info" onClick={() => this.sendSMSReminder(trId, reminderType)}><i className="bx bx-phone" /></button>}
                                  {" "}
                                  <button className="btn btn-sm btn-outline-success" onClick={() => this.sendEmailReminder(trId, reminderType)}><i className="bx bx-envelope" /></button>
                                  <br />
                                </>
                            }
                            {
                              tutorRequests[i].smsReminderCount && <><br /> Last SMS Reminder Sent {moment(tutorRequests[i].smsReminder).fromNow()} ({tutorRequests[i].smsReminderCount})</>
                            }
                            
                            {
                              tutorRequests[i].emailReminderCount && <><br /> Last Email Reminder Sent {moment(tutorRequests[i].emailReminder).fromNow()} ({tutorRequests[i].emailReminderCount})</>
                            }
                            <span className="float-right p-2"><button className="btn btn-sm btn-danger" onClick={() => this.deleteTutorRequest(trRefId)}><i className="bx bx-trash" /></button></span>
                            {
                              tutorRequests[i].noTutorAvailable != true &&
                                <span className=" float-right p-2"><button className="btn btn-sm btn-warning" onClick={() => this.updateNoTutorAvailable(trId)}>None Available</button></span>
                            }
                          </>
                  }
      requestRows.push(row);
    }

    return requestRows;
  }

  processRequestsWithoutAssignments = requestsWithoutAssignments => {
    //Requests With Potential Tutors And NO Tutor Contracts
    let unaddressedRequests = [];
    let unassignedRequests = [];
    let potentialPendingStudent = [];
    let potentialRejectedByStudent = [];
    let pendingTutorResponse = [];
    let pendingStudentResponse = [];
    let contractsRejectedByStudent = [];
    let tutorMissedDeadline = [];
    let studentMissedDeadline = [];
    let unclassifiedRequests = [];
    let marketPlaceRequests = [];

    let reminderRequests = [];
    
    requestsWithoutAssignments.forEach(tutorRequest => {
      let potentialTutors = tutorRequest.potentialTutors || [];
      let tutorContracts = tutorRequest.tutorContracts || [];
      let marketRequests = tutorRequest.marketRequests || [];
      let potentialCount = potentialTutors.length;
      let contractsCount = tutorContracts.length;

      //No Tutor Available
      if(tutorRequest.noTutorAvailable){
        unassignedRequests.push(tutorRequest);
      }
      else if(marketRequests.length > 0){
        marketPlaceRequests.push(tutorRequest);
      }
      else{
        //Potential Tutors
        if(potentialCount > 0 && contractsCount == 0){
          //No Contracts (Student Has Not Expressed Interest In Provided Tutors)
          let rejected = potentialTutors.filter(potentialTutor => {
                          return potentialTutor.interested == false;
                        });

          if(rejected.length > 0){
            //Student rejected at least one potential tutor
            potentialRejectedByStudent.push(tutorRequest);
          }
          else{
            //Student has not responded to any potential tutor
            potentialPendingStudent.push(tutorRequest);
          }
        }

        //Tutor Contracts
        else if(contractsCount > 0){
          //Tutor Rejected AND/OR Deadline Past
          let tutorPending = tutorContracts.filter(tutorContract => {
            return tutorContract.tutorDecision != true
                  && moment().isBefore(tutorContract.tutorDeadline);
          });

          let tutorMissed = tutorContracts.filter(tutorContract => {
                        return tutorContract.tutorDecision != true 
                              && moment().isAfter(tutorContract.tutorDeadline);
                      });
          
          let studentRejected = tutorContracts.filter(tutorContract => {
            return tutorContract.studentDecision === false;
          });

          let studentPending = tutorContracts.filter(tutorContract => {
            return tutorContract.studentDecision != true
                  && tutorContract.studentDeadline != null
                  && moment().isBefore(tutorContract.studentDeadline);
          });

          let studentMissed = tutorContracts.filter(tutorContract => {
            return tutorContract.studentDecision != true 
                  && tutorContract.studentDeadline != null
                  && moment().isAfter(tutorContract.studentDeadline);
          });

          if(tutorMissed.length == contractsCount){
            tutorMissedDeadline.push(tutorRequest);
          }
          else if(tutorPending.length == contractsCount){
            pendingTutorResponse.push(tutorRequest);
          }
          //Students
          else if(studentRejected.length == contractsCount){
            contractsRejectedByStudent.push(tutorRequest);
          }
          else if(studentMissed.length == contractsCount){
            studentMissedDeadline.push(tutorRequest);
          }
          else if(studentPending.length == contractsCount){
            pendingStudentResponse.push(tutorRequest);
            reminderRequests.push(tutorRequest);
          }
          else{
            unclassifiedRequests.push(tutorRequest);
            
            //Deadline Has Not passed
            let activeContracts = tutorContracts.filter(tutorContract => {
                                    return tutorContract.studentDeadline != null && moment().isBefore(tutorContract.studentDeadline);
                                  });

            if(activeContracts.length > 0){
              reminderRequests.push(tutorRequest);
            }
          }
        }
        //Unaddressed (Not Classified)
        else{
          unaddressedRequests.push(tutorRequest);
        }
      }
    });
    
    this.setState({ 
        requestsProcessed: true,
       unaddressedRequests,
       unassignedRequests, 
       potentialPendingStudent,
       potentialRejectedByStudent,
       pendingTutorResponse,
       pendingStudentResponse,
       contractsRejectedByStudent,
       tutorMissedDeadline,
       studentMissedDeadline,
       unclassifiedRequests,
       marketPlaceRequests,
       reminderRequests}, 
       () => {
         this.constructTabs();
       });
  }

  constructTabs = () => {
    let { requestTypes,  tableColumns} = this.state;
    let tabs = []
    let tabCount = 1;

    requestTypes.forEach(requestType => {
      if(this.state.hasOwnProperty(requestType)){
        let tutorRequests = this.state[requestType] || [];
        if(tutorRequests.length > 0 || requestType == "unaddressedRequests"){
          let title = camelCaseToWords(requestType) + " (" + tutorRequests.length + ")";
          
          if(requestType != "unaddressedRequests"){
            tutorRequests = tutorRequests.sort((a,b) => a.id < b.id); //sort by Id desc
          }

          let requestRows = this.formatDataTableRows(tutorRequests);
          let content = <DataTable columns={tableColumns} rows={requestRows} />
          tabs.push({id: tabCount, title, content});
          tabCount++;
        }
      }
    });
    
    this.setState({tabs});
  }

  duplicateTutorRequest = (values) => {
    let { onSubmitApiCall } = this.props;
    let { selectedTutorRequest } = this.state;
    onSubmitApiCall("postAdminDuplicateTutorRequest", {...values, tutorRequestId: selectedTutorRequest.id });
  }

  updateTutorRequest = (values) => {
    let { onSubmitApiCall } = this.props;
    let { selectedTutorRequest } = this.state;

    onSubmitApiCall("patchAdminUpdateTutorRequest", {...values, tutorRequestId: selectedTutorRequest.id });
  }

  updateSubjects = subjectIds => {
    let { selectedTutorRequest } = this.state;

    let formData = {subjects: subjectIds, tutorRequestId: selectedTutorRequest.id};
    
    let { onSubmitApiCall } = this.props;         
    onSubmitApiCall("updateTutorRequestSubjects", formData);
}


  updateNoTutorAvailable = tutorRequestId => {
    if(confirm("Are you sure there are NO TUTORS AVAILABLE?")){
      let { onSubmitApiCall } = this.props;
      onSubmitApiCall("patchNoTutorAvailable", tutorRequestId );
    }
  }

  updateSelectedPotentialTutorsDeclined = tutorRequestId => {
    if(confirm("Are you sure that SELECTED POTENTIAL TUTORS DECLINED?")){
      let { onSubmitApiCall } = this.props;
      onSubmitApiCall("updateSelectedPotentialTutorsDeclined", tutorRequestId );
    }
  }

  deleteTutorRequest = tutorRequestRefId => {
    if(confirm("Are you sure you want to DELETE this tutor request?")){
      let { onSubmitApiCall } = this.props;
      onSubmitApiCall("deleteTutorRequest", tutorRequestRefId);
    }
  }

  sendSMSReminder = (tutorRequestId, type) => {
    //type: potential-tutors vs. tutor-contracts
    if(confirm("Confirm SMS / TEXT Reminder.")){
      let { onSubmitApiCall } = this.props;
      let formData = {tutorRequestId, type };
      onSubmitApiCall("postAdminTutorRequestSendSMSReminder", formData);
    }
  }

  sendEmailReminder = (tutorRequestId, type) => {
    //type: potential-tutors vs. tutor-contracts
    if(confirm("Confirm Email Reminder.")){
      let { onSubmitApiCall } = this.props;
      let formData = {tutorRequestId, type };
      onSubmitApiCall("postAdminTutorRequestSendEmailReminder", formData);
    }
  }

  render() {
    let { requestsWithoutAssignments, selectedTutorRequest, showDuplicateForm, showEditForm, showSubjectsForm, tabs, showReminders, reminderRequests } = this.state;
    const { apiResponse } = this.props;
    let loading = apiResponse.loading

    let startDate = selectedTutorRequest.hasOwnProperty("startDate") ? moment(selectedTutorRequest.startDate) : null;
    let endDate = selectedTutorRequest.hasOwnProperty("endDate") ? moment(selectedTutorRequest.endDate) : null;
    
    let subjectIds = [];
    let subjectNames = [];
    if(selectedTutorRequest.subjects){
      subjectIds = selectedTutorRequest.subjects.map(subject => subject.id);
      subjectNames = selectedTutorRequest.subjects.map(subject => camelCaseToWords(subject.name));
  }

    return (
      <React.Fragment>
        <BTNMetaTags title="Tutor Requests" />
        
        {
          loading &&
              <center><ThemeSpinner /></center>
        }
        {
          reminderRequests.length > 0 &&  
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>Send Reminders ({reminderRequests.length})</CardTitle>
                    {
                        reminderRequests .length > 0 &&
                        <>
                          <a className="text-info" onClick={() => {this.setState({showReminders: !showReminders})}}>{showReminders ? "Hide Table": "Show Table"}</a>
                          <>
                            {showReminders &&
                              <table className="table-responsive table mb-0">
                                <tbody>
                                  {
                                    reminderRequests.map((tReq, i) => 
                                      <tr key={"reminders-"+ i}>
                                        <td>
                                          <Link to="#kanban" onClick={() => this.selectTutorRequest(tReq.id)}>{tReq.id}</Link> - 
                                          <Link to={ADMIN_USER_DETAILS + "/" + tReq.studentRefId} target="_blank" rel="noreferrer">{tReq.studentName}</Link><br />
                                          <a href={"http://www.google.com/search?q=" + tReq.course.replace(/ /g, "+") + "+" + tReq.school.replace(/ /g, "+")} target="_blank" rel="noreferrer">{tReq.course + " @ " + tReq.school}</a>
                                        </td>
                                        <td>
                                        { 
                                          tReq.tutorContracts.length > 0 &&
                                            tReq.tutorContracts.map((tutorContract, i) =>
                                              <span key={i}>
                                                <Link to={ADMIN_USER_DETAILS + "/" + tutorContract.tutorRefId} target="_blank" rel="noreferrer">{tutorContract.tutorName}</Link> 
                                                {" "}
                                                <span className="text-dark">
                                                  {getCurrencySymbol(tutorContract.currency)}{tutorContract.rate} {tutorContract.prepayRate && <> / {tutorContract.prepayRate}</>}
                                                  / 
                                                  {getCurrencySymbol(tutorContract.tutorCurrency)}{tutorContract.tutorRate}
                                                </span>
                                                {" "}{tutorContract.studentDecision == true && <i className="bx bx-check-circle text-primary" />}
                                                {tutorContract.studentDecision == false && <i className="bx bx-x-circle text-danger" />}
                                                
                                                {" "}
                                                {
                                                  tutorContract.studentDecision != true && tutorContract.studentDeadline &&
                                                    <>Student Deadline: {moment(tutorContract.studentDeadline).fromNow()}</>
                                                }
                                                {
                                                  tutorContract.tutorDecision != true && tutorContract.tutorDeadline &&
                                                    <>Tutor Deadline: {moment(tutorContract.tutorDeadline).fromNow()}</>
                                                }
                                                <br />
                                              </span>
                                            )
                                        }
                                        </td>
                                        <td>
                                          {
                                            tReq.smsReminderCount && <>Last SMS Reminder Sent {moment(tReq.smsReminder).fromNow()} ({tReq.smsReminderCount})<br /></>
                                          }
                                          
                                          {
                                            tReq.emailReminderCount && <>Last Email Reminder Sent {moment(tReq.emailReminder).fromNow()} ({tReq.emailReminderCount})<br /></>
                                          }
                                          <br />
                                          <button className="btn btn-sm btn-outline-success mr-2" onClick={() => this.sendEmailReminder(tReq.id, "tutor-contracts")}><i className="bx bx-envelope" /></button>
                                          <button className="btn btn-sm btn-outline-info" onClick={() => this.sendSMSReminder(tReq.id, "tutor-contracts")}><i className="bx bx-phone" /></button>
                                        </td>
                                      </tr>
                                      )
                                  }
                                </tbody>
                              </table>
                            }
                          </>
                        </>
                      }
                    </CardBody>
                  </Card>
              </Col>
            </Row>
        }
        <Card>
          <CardBody>
            <CardTitle>{requestsWithoutAssignments.length} Tutor Requests</CardTitle>
            <NavTabs key={"tabs-" + tabs.length} tabs={tabs} />
          </CardBody>
        </Card>
        {
          selectedTutorRequest.hasOwnProperty("id") &&
          <div id="kanban" style={{display: "block"}}>
            <Card>
              <CardBody>
                  <Row>
                    <Col md="6">
                      <CardTitle>
                        {selectedTutorRequest.id} /   {selectedTutorRequest.refId} ({moment(selectedTutorRequest.createdAt).fromNow()}) <br />
                        <Link to={ADMIN_USER_DETAILS + "/" + selectedTutorRequest.studentRefId} target="_blank" rel="noreferrer">{selectedTutorRequest.studentName}</Link> {"  -  "}
                        <a href={"http://www.google.com/search?q=" + selectedTutorRequest.course.replace(/ /g, "+") + "+" + selectedTutorRequest.school.replace(/ /g, "+")} target="_blank" rel="noreferrer">{selectedTutorRequest.course} @ {selectedTutorRequest.school}</a> {" "}
                        <br />
                        {
                          selectedTutorRequest.online &&
                            <span className="badge badge-success">Online</span>
                        }
                        {" "}
                        {
                          selectedTutorRequest.inPerson &&
                            <span className="badge badge-info">In-Person</span>
                        }
                        {" "}
                        {
                          selectedTutorRequest.scholarship &&
                            <span className="badge badge-warning">Scholarship</span>
                        }
                        <br/><br />
                        <span style={{color:"green"}}>{getCurrencySymbol(selectedTutorRequest.currency)}{selectedTutorRequest.minRate} - {selectedTutorRequest.maxRate}/hr</span>
                        <br />
                        {selectedTutorRequest.city}, {selectedTutorRequest.state} <br />
                        {selectedTutorRequest.marketing && <>Marketing: {selectedTutorRequest.marketing}<br /></>}
                        {
                          selectedTutorRequest.requestedTutors && selectedTutorRequest.requestedTutors.length > 0 &&
                          <><br /> Requested Tutors: <br />{this.getRequestedTutors(selectedTutorRequest.requestedTutors)}<br/></>
                        }
                        Dates: {startDate && startDate.format("MM/DD/YY")} - {endDate && endDate.format("MM/DD/YY")} <br />
                        {endDate && ((moment().diff(endDate, 'days') >= -14) || Math.abs(moment(startDate).diff(endDate, "days")) < 14) && <span style={{color: "red"}}>CHECK DATES<br /></span>}

                        {selectedTutorRequest.sessionTime} - {selectedTutorRequest.frequency}<br />
                        <span className="text-secondary">{selectedTutorRequest.concerns != null && selectedTutorRequest.concerns != "" && <><br />Concerns: <br />{selectedTutorRequest.concerns}<br /><br /></>}</span>
                      </CardTitle>
                      
                      <p>{subjectNames.join(", ")}</p>

                    </Col>
                    <Col md="6">
                      <a className="text-primary" onClick={this.toggleDuplicateForm}>Duplicate Request</a>
                      <br />
                      <a className="text-primary" onClick={this.toggleEditForm}>Edit Request</a>
                      <br />
                      <a className="text-primary" onClick={this.toggleSubjectForm}>Edit Subjects</a>
                      <br /><br />
                      { showSubjectsForm && <SubjectsDualList onSubmit={this.updateSubjects} selected={subjectIds}/> }
                      { showDuplicateForm && <DuplicateTutorRequestForm onSubmit={this.duplicateTutorRequest} loading={loading} course={selectedTutorRequest.course} /> }
                      { showEditForm && <EditTutorRequestForm onSubmit={this.updateTutorRequest} loading={loading} {...selectedTutorRequest} /> }
                    </Col>
                  </Row>
                  
                <br />
                <hr />
                <h3>Search Tutors</h3>
                <SelectTutorsKanban key={"kanban-"+selectedTutorRequest.id} tutorRequest={selectedTutorRequest} />
              </CardBody>
            </Card>
          </div>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
  apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorRequestsAdmin)
