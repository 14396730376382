import React from "react"
import { UncontrolledTooltip } from "reactstrap"

export const DeleteAssignmentAction = (props) => {
    //props: id, color, onClick
    return (
        <a id={"delete-assignment-" + props.id} className={`text-${props.color || "danger"}`} onClick={props.onClick}>
            <i className="bx bx-trash" />
            <UncontrolledTooltip
                placement="top"
                target={"delete-assignment-" + props.id}
            >
            Delete Assignment #{props.id}
            </UncontrolledTooltip>
        </a>
    )
}