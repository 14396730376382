import React, { Component } from 'react'
import ReactDataTable from '../dataTableComponents/ReactDataTable'

import userListColumns from './userListColumns'

export default class UsersListTable extends Component {
  render() {

    let columns = userListColumns();
    let rows = this.props.users;

    let tableProps = {...this.props, rows, columns, createColumns: false};

    return (
        <ReactDataTable key={"usersTable-" + rows.length} {...tableProps} pagination={true} />
    )
  }
}
