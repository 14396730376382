export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_API_ERROR = "LOGIN_API_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET"
export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS"

export const CLONE_USER = "CLONE_USER"