import React, { Component } from "react"
import moment from "moment"
import { TabPane, Row, Col, Form, FormFeedback, FormGroup, Label, Input } from "reactstrap"

//import DateRangeUI from "../../DateRangeUI"
import DateRangeUI from "components/datePicker/DateRangeUI"

class StartEndDateTab extends Component {  
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Form>
            <FormGroup>
              <Row>
                <Label>Select Start & End Dates <small>(An estimation is fine)</small></Label>
                <p>This helps us find tutors who can commit to the entire semester or time you need assistance.</p>
            
                <p className="text-muted">
                  We recommend selecting the end of the semester/quarter as the End Date.
                </p>
              </Row>
              {/* <Row>
                <Col md={12}>
                  {this.props.invalidFields.includes("startDate") &&
                    <FormFeedback>
                      {this.props.validationErrors.find(vError => vError.field == "startDate").message}
                    </FormFeedback>
                  }
                  {this.props.invalidFields.includes("endDate") &&
                    <FormFeedback>
                      {this.props.validationErrors.find(vError => vError.field == "endDate").message}
                    </FormFeedback>
                  }
                  <Row>
                    <Col md={{size: 8, offset: 2}}>
                      <p>Dates Selected:<br />
                      <span className="text-primary" style={{fontWeight: "bold"}}>{moment(this.props.formData.startDate).format("MMM Do YYYY")} ---- {moment(this.props.formData.endDate).format("MMM Do YYYY")}</span></p>
                      <DateRangeUI startDate={this.props.formData.startDate} endDate={this.props.formData.endDate} onChange={this.props.onChangeDateRange} daysInFuture={365}/>
                      <br />
                      <small> Use arrows to scroll to a different month </small>
                    </Col>
                  </Row>
                </Col>
              </Row> */}
              <Row>
                    <Col md="6">
                      <Label for="startDate">
                        Start Date 
                      </Label>
                      <Input
                          className="form-control"
                          type="date"
                          name="startDate"
                          min={moment().format("YYYY-MM-DD")}
                          onChange={this.props.onChange}
                          value={this.props.formData.startDate}
                          invalid={this.props.invalidFields.includes("startDate")}
                      />
                    </Col>
                    <Col md="6">
                      <Label for="endDate">
                        End Date
                     </Label>
                      <Input
                          className="form-control"
                          type="date"
                          name="endDate"
                          min={moment().add(1, "day").format("YYYY-MM-DD")}
                          onChange={this.props.onChange}
                          value={this.props.formData.endDate}
                          invalid={this.props.invalidFields.includes("endDate")}
                      />
                      {this.props.invalidFields.includes("endDate") &&
                        <FormFeedback>
                          {this.props.validationErrors.find(vError => vError.field == "endDate").message}
                        </FormFeedback>
                      }
                    </Col>
                  </Row>
            </FormGroup>
          </Form>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default StartEndDateTab
