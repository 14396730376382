import { 
          SAVE_CHAT_MESSAGES, RESET_CHAT_MESSAGES,
          SAVE_CHAT_THREADS
        } from "./actionTypes";


export const saveChatMessages = messages => {
    return {
      type: SAVE_CHAT_MESSAGES,
      payload: messages,
    }
}

export const saveChatThreads = chatThreads => {
  return {
    type: SAVE_CHAT_THREADS,
    payload: chatThreads,
  }
}

export const resetChatMessages = () => {
  return {
    type: RESET_CHAT_MESSAGES
  }
}

