import React, { Component } from "react"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import { ThemeButton } from "theme/elements/buttons"

class FormWizard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      tabs: this.props.tabs,
      formData: this.props.formFields,
    }
    this.toggleTab.bind(this)
    this.onChangeValue.bind(this)
    this.onChangeChecked.bind(this)
    this.onChangeDateRange.bind(this)
    this.onChangeRateRange.bind(this)
    this.onChangeSlider.bind(this)
    this.onChangeArray.bind(this)
    
  }
    
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= this.state.tabs.length) {
        this.setState({
          activeTab: tab,
        })
      }
    }
  }

  onChangeValue = (event) => {
    const { name, value } = event.target;
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
    });
  }

  onChangeChecked = (event) => {
    const { name, checked } = event.target;
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: checked
        }
    });
  }

  onChangeDateRange = (startDate, endDate) => {
    this.setState({
        formData: {
          ...this.state.formData,
          startDate,
          endDate
        }
    });
  }

  onChangeRateRange = (render, handle, sliderValue) => {
    this.setState({
        formData: {
          ...this.state.formData,
          minRate: sliderValue[0],
          maxRate: sliderValue[1],
        }
    });
  }

  onChangeSlider = (name, value) => {
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
    });
  }

  /////////// TEST!!! /////////////////
  onChangeArray = (event) => {
    const {name, value} = event.target;
    let form_array = this.state.formData[name];

    if (event.target.checked){
      if(!form_array.includes(value)){
        form_array.push(value);
      }
    }
    else{
      var index = form_array.indexOf(value);
      if (index >= 0) {
        form_array.splice( index, 1 );
      }
    }

    this.setState({
        formData: {
          ...this.state.formData,
          [name]: form_array
        }
    });
  }
  //////////////////////////////////////////////////////

  render() {
    let errorStyle={border: "6px solid $danger"};
    let validationErrors = this.props.validationErrors || [];
    let invalidFields = this.props.invalidFields || [];

    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">{this.props.formTitle || ""}</h4>

                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                  <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                    {this.state.tabs.map((tab, index) =>
                      <NavItem key={"nav-" + index}>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === tab.id,
                          })}
                          onClick={() => {
                            this.toggleTab(tab.id)
                          }}
                        >
                          {
                            tab.validationFields && invalidFields.filter(inField => tab.validationFields.includes(inField)).length ?
                              (<span className="step-number-error mr-2">{tab.id}</span>)
                              :
                              (<span className="step-number mr-2">{tab.id}</span>)
                          }


                          {tab.title}
                        </NavLink>
                      </NavItem>
                    )}
                  </ul>
                  <TabContent
                    activeTab={this.state.activeTab}
                    className="twitter-bs-wizard-tab-content"
                  >
                    {this.state.tabs.map((tab, index) =>
                      <TabPane tabId={tab.id} key={tab.id}>
                        <tab.component
                          {...tab.props}
                          key={"tab-" + index}
                          formData={this.state.formData}
                          onChange={this.onChangeValue}
                          onChangeChecked={this.onChangeChecked}
                          onChangeDateRange={this.onChangeDateRange}
                          onChangeRateRange={this.onChangeRateRange}
                          onChangeSlider={this.onChangeSlider}
                          onChangeArray={this.onChangeArray}
                          validationErrors={validationErrors}
                          invalidFields={invalidFields}
                        />
                    </TabPane>
                    )}
                  </TabContent>
                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                    {this.state.activeTab !== 1 &&
                      <li className="previous">
                        <Link
                          to="#"
                          onClick={() => {
                            this.toggleTab(this.state.activeTab - 1)
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                    }
                    {this.state.activeTab == this.state.tabs.length
                      ?
                        (
                          this.props.loading
                            ?
                              (
                                <li className="next">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled
                                  >
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                                    Submitting
                                  </button>
                                </li>
                               )
                            :
                              (
                                <li
                                  className="next"
                                >
                                  <Link
                                    to="#"
                                    onClick={event => this.props.onSubmit(event, this.state.formData)}
                                  >
                                    Submit
                                  </Link>
                                </li>
                              )
                        )
                      :
                        (
                          <li className="next">
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        )
                    }
                  </ul>
                </div>
                {
                    this.props.onSave &&
                      <>
                        <br />
                        <br />
                        <br />
                        <center>
                          <ThemeButton color={"secondary"} onClick={() => this.props.onSave(this.state.formData)} >
                            {this.props.saveButtonText || "Save" }
                          </ThemeButton>
                        </center>
                      </>  
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default FormWizard
