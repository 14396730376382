import {
  GET_INBOX_THREADS_SUCCESS,
  GET_INBOX_THREADS_FAIL,
  ADD_INBOX_THREAD_SUCCESS,
  ADD_INBOX_THREAD_FAIL,
  DELETE_INBOX_THREAD_SUCCESS,
  DELETE_INBOX_THREAD_FAIL,
  GET_STARRED_THREADS_SUCCESS,
  GET_STARRED_THREADS_FAIL,
  GET_IMPORTANT_THREADS_SUCCESS,
  GET_IMPORTANT_THREADS_FAIL,
  GET_SENT_THREADS_SUCCESS,
  GET_SENT_THREADS_FAIL,
  GET_DRAFT_THREADS_SUCCESS,
  GET_DRAFT_THREADS_FAIL,
  GET_TRASH_THREADS_SUCCESS,
  GET_TRASH_THREADS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  inboxthreads: [],
  starredthreads: [],
  importantthreads: [],
  draftthreads: [],
  sentthreads: [],
  trashthreads: [],
  error: {},
}

const inboxReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INBOX_THREADS_SUCCESS:
      return {
        ...state,
        inboxthreads: action.payload,
      }

    case GET_INBOX_THREADS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_INBOX_THREAD_SUCCESS:
      return {
        ...state,
        inboxthreads: [...state.inboxthreads, action.payload],
      }

    case ADD_INBOX_THREAD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INBOX_THREAD_SUCCESS:
      return {
        ...state,
        inboxthreads: state.inboxthreads.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_INBOX_THREAD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_STARRED_THREADS_SUCCESS:
      return {
        ...state,
        starredthreads: action.payload,
      }

    case GET_STARRED_THREADS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case GET_IMPORTANT_THREADS_SUCCESS:
      return {
        ...state,
        importantthreads: action.payload,
      }

    case GET_IMPORTANT_THREADS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TRASH_THREADS_SUCCESS:
      return {
        ...state,
        trashthreads: action.payload,
      }

    case GET_TRASH_THREADS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DRAFT_THREADS_SUCCESS:
      return {
        ...state,
        draftthreads: action.payload,
      }

    case GET_DRAFT_THREADS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SENT_THREADS_SUCCESS:
      return {
        ...state,
        sentthreads: action.payload,
      }

    case GET_SENT_THREADS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default inboxReducer