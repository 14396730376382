import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
  } from "reactstrap"

  import classnames from "classnames"

import { tutorChecklistItems } from './tutor-checklist'

class TutorChecklist extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            activeTab: 1,
        }

        this.toggleTab = this.toggleTab.bind(this)
    }
  
    toggleTab(tab) {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        })
      }
    }

    render() {
        return (
            <React.Fragment>
                <BTNMetaTags title="Tutor Checklist" />

                <Container fluid>
                    <div className="checkout-tabs">
                    <Row>
                        <Col sm="2">
                            <Nav className="flex-column" pills>
                                {
                                    tutorChecklistItems.map((content, i) =>
                                    <NavItem key={i}>
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === content.id,
                                            })}
                                            onClick={() => {
                                                this.toggleTab(content.id)
                                            }}
                                        >
                                            <i className={`${content.navIcon} d-block check-nav-icon mt-4 mb-2`}/>
                                            <p className="font-weight-bold mb-4">
                                                {content.navTitle}
                                            </p>
                                        </NavLink>
                                    </NavItem>
                                    )
                                }
                            </Nav>
                        </Col>
                        <Col sm="10">
                            <Card>
                                <CardBody>
                                    <TabContent activeTab={this.state.activeTab}>
                                        {
                                            tutorChecklistItems.map((content, j) => 
                                                <TabPane tabId={content.id} key={"tab-pane-"+j}>
                                                    <CardTitle className="mb-5">
                                                        {content.navTitle}
                                                    </CardTitle>
                                                    {content.items.map((item, k) =>
                                                        <Media className="faq-box mb-4" key={"media-"+k}>
                                                            <div className="faq-icon mr-3">
                                                                {/*<i className={item.listIcon + " font-size-20"}/>*/}
                                                                <i className={`${content.listIcon} font-size-20`}/>
                                                            </div>
                                                            <Media body>
                                                                <h5 className="font-size-15">
                                                                    {item.title}
                                                                </h5>
                                                                <div className="text-dark">
                                                                {item.body}
                                                                </div>
                                                            </Media>
                                                        </Media>
                                                    )}
                                                </TabPane>
                                            )
                                        }
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </div>
                </Container>
            </React.Fragment>
            )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorChecklist)
