import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from 'react-router-dom'

import { ADMIN_REFUNDS_ISSUE_BALANCE_REFUND, ADMIN_USER_DETAILS } from 'routes/paths'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { DeleteAssignmentAction } from './actions'

import { formatCurrencyAmount, formatDate } from 'scripts/general'
import { ThemeButtonLink } from 'theme/elements/buttons'

class AssignmentsAdminTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
          
            adminColumns: ["id", "dates", "studentName", "tutorName", "course", "studentRate", "location", "details", "refId", "action"],
            processing: false
        }
        
        this.createColumns.bind(this)
        this.createRows.bind(this)

        this.addActionField.bind(this)
        this.formatFields.bind(this)
        this.deleteAssignment.bind(this)
    }
    
    componentDidMount() {
        let { assignments=[] } = this.props;
        this.createColumns();
        this.createRows(assignments);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { assignments=[] } = nextProps;
    //     this.createRows(assignments);
    // }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createColumns = () => {
        let { adminColumns } = this.state;
        let columns = createSpecifiedColumns(adminColumns);
        this.setState({columns});
    }

    createRows = (assignments) => {
        let rows = assignments;
        rows = this.formatFields(rows);
        rows = this.addActionField(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            let studentCurrency = row.studentCurrency || "usd";
            let tutorCurrency = row.tutorCurrency || "usd";
            
            row.dates = formatDate(row.startDate) + " - " + formatDate(row.endDate)
            row.studentName = <Link to={ADMIN_USER_DETAILS + "/" + row.studentRefId} target="_blank">{row.studentName}</Link>
            row.tutorName = <Link to={ADMIN_USER_DETAILS + "/" + row.tutorRefId} target="_blank">{row.tutorName}</Link>

            row.location = <>
                                {row.studentLocation ? <>{row.studentLocation}<br /></>: ""}
                                <small className="text-dark">{row.btnLocation}</small>
                            </>
            
            row.details =  <>
                        {
                            row.scholarship &&
                                <span className="text-success">Scholarship<br /></span>
                        }
                        {
                            row.balance != 0 &&
                            <>
                                Balance: <span className={row.balance == 0 ? "text-secondary" : row.balance > 0 ? "text-info" : "text-danger"}>{formatCurrencyAmount(row.balance, studentCurrency)}</span>
                                <br />
                            </>
                        }
                        {row.sessionsCount > 0 && <>Count: {row.sessionsCount}<br /></>}
                        <br />
                        {row.balance > 0 && row.sessionsCount > 0 && !row.scholarship && <><ThemeButtonLink to={ADMIN_REFUNDS_ISSUE_BALANCE_REFUND + "/" + row.id} className="btn btn-sm btn-secondary">Refund Balance</ThemeButtonLink><br /></>}
                        
                        <small>Created: {formatDate(row.createdAt)}</small>
                        <br />
                        <small>Created By: {row.assignedBy}</small>
                    </>
            
            if(typeof row.studentRate == "number"){
                row.studentRate = <>
                                    {formatCurrencyAmount(row.studentRate, studentCurrency)}/hr <br />
                                    {
                                        row.studentPrepayRate > 0 &&
                                            <>Prepaid: {formatCurrencyAmount(row.studentPrepayRate, studentCurrency)}/hr<br /></>
                                    }
                                    {
                                        row.groupSession &&
                                        <>
                                            Group: {formatCurrencyAmount(row.groupRate, studentCurrency)}/hr <br />
                                            Group Prepaid: {formatCurrencyAmount(row.groupPrepayRate, studentCurrency)}/hr<br />
                                        </>
                                    }
                                    <span className="text-secondary">
                                        {formatCurrencyAmount(row.tutorRate, tutorCurrency)}/hr <br />
                                        {
                                            row.groupSession &&
                                            <>
                                                Group: {formatCurrencyAmount(row.tutorGroupRate, tutorCurrency)}/hr<br />
                                            </>
                                        }
                                    </span>
                                </>
            }
        });

        return rows;
    }

    addActionField = (rows) => {
        rows.forEach(row => {
            row["action"] = <DeleteAssignmentAction id={row.id} onClick={() => this.deleteAssignment(row.id)} />
        });

       return rows;
    }

    deleteAssignment = (assignmentId) => {
        if(confirm("Are you sure you want to delete this assignment?")){
            const { onSubmitApiCall } = this.props;
            onSubmitApiCall("deleteAssignment", assignmentId);
            window.location.reload();
        }
    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        return (
            <>
                <p>{rows.length} Assignments</p>
                {
                    columns.length > 0 &&
                    <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsAdminTable)