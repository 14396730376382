import { capitalizeWords } from "scripts/general";

export const groupSubjectsByParent = (subjectsFromServer) => {
    let parentsArray = [];

    subjectsFromServer.forEach(subject => {
        let parentObject = parentsArray.find(parentObj => parentObj.id === subject.parentId);
        
        if(parentObject === undefined){
            let name = subject.parentName != null ? capitalizeWords(subject.parentName.replace(/_/g, " ")) : "Unclassified";
            let parentId = subject.parentId != undefined && subject.parentId != null ? subject.parentId  : 99999999999;
            parentObject = {id: parentId, name, subjects: []}
            parentsArray.push(parentObject);    
        }

        let subjectObject = {id: subject.id, name: capitalizeWords(subject.name.replace(/_/g, " "))};
        parentObject.subjects.push(subjectObject);
    });
    
    return parentsArray;
}