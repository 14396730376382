import React, { Component } from "react";

import ChatRequestDetails from "./ChatRequestDetails";

// User  chat detail component 
export default class UserChatRequestDetails extends Component {
  
  render() {
    let { chat, pusher } = this.props;
    
    return (
        <>
          <div className="hide-scrollbar flex-fill">
            <ChatRequestDetails key={"crd-"+chat.threadId} chat={chat} pusher={pusher}/>
          </div>
        </>
    );
  }
}
