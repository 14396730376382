import PropTypes from 'prop-types'
import React, { Component } from "react"

import { connect } from "react-redux"

import { hideRightSidebar } from 'store/actions'
//SimpleBar
import SimpleBar from "simplebar-react"

import { Link } from "react-router-dom"

import "./rightbar.scss"

class RightSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
    
    }
    this.hideRightbar = this.hideRightbar.bind(this)
  }

  /**
   * Hides the right sidebar
   */
  hideRightbar(e) {
    e.preventDefault()
    this.props.hideRightSidebar()
  }

  render() {
    return (
      <React.Fragment>
        <div className="right-bar">
          <SimpleBar style={{ height: "900px" }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={this.hideRightbar}
                  className="right-bar-toggle float-right"
                >
                  <i className="mdi mdi-close noti-icon"/>
                </Link>
                <h5 className="m-0">Settings</h5>
              </div>

              <hr className="my-0" />

              
            </div>
          </SimpleBar>
        </div>
        <div className="rightbar-overlay"/>
      </React.Fragment>
    )
  }
}

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  hideRightSidebar: PropTypes.func,
  isPreloader: PropTypes.bool,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  topbarTheme: PropTypes.any
}

const mapStatetoProps = state => {
  return { ...state.Layout }
}

export default connect(mapStatetoProps, {
  hideRightSidebar,
})(RightSidebar)
