import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Alert, Row } from 'reactstrap'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { cleanUpString } from "scripts/general"

import SearchTutorsAdminForm from 'components/search/SearchTutorsAdminForm'
import BTNKanban from './BTNKanban'
import CardTutorBox from './CardTutorBox'
import SortHeader from './SortHeader'
import { formatCardData } from './scripts'

class MarketPlaceKanban extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         tutorRequest: this.props.tutorRequest || {},
         tutors: [],

         boardId: "",
         columns: [{
                id: 1,
                title: "Potential Tutors",
                cards: []
            },
            {
                id: "exclusive",
                title: "Exclusive",
                cards: []
            },
            {
                id: "banned",
                title: "Rejected / Banned",
                cards: []
            }
            ],
        
        sortOptions: [//{id: 1, key: "btnRank", title: "Recommended"},
                    {id: 2, key: "distanceFromUser", title: "Distance"},
                    {id: 3, key: "timeDifference", title: "Time Difference"},
                    {id: 4, key: "hoursCount", title: "Hours"},              
                    {id: 5, key: "studentsCount", title: "Students"},              
                  ],
        
        //Filter (TODO) & Sort
        online: true,
        inPerson: true,
        status: true,

        contractError: "",

      }
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
  
            if(apiResponse != {} && prevState != newState){
              this.setState(newState);
              this.formatBoardData(newState.tutors);  
            }
        }
    }

    onTutorSearch = async (e, formData) => {
        e.preventDefault();
        let { onSubmitApiCall } = this.props;
  
        //Clean Up String Fields Before Submit
        for (var key in formData) {
          if (typeof formData[key] == "string") {
            formData[key] = cleanUpString(formData[key]);
          }
        }
  
        onSubmitApiCall("submitTutorSearchAdmin", formData);
    }

    createCardData = tutor => {
      let cardData = formatCardData(tutor);

      return cardData;
    }

    formatBoardData = (tutors) => {
        let { columns } = this.state;
        let potentialColumnId = 1;
        let potentialColumn = columns.find(col => {return col.id === potentialColumnId});

        // let potentialCards = potentialColumn.cards;
        let potentialCards = [];

        for (var tutor of tutors) {
            let found = false;
            columns.map(column => {
                column.cards.map(card => {
                    if(card.id == tutor.id && column.id != potentialColumnId){
                        found = true;
                    }
                });
            });
            
            if(!found){
                potentialCards.push(this.createCardData(tutor));
            }
        }

        potentialColumn.cards = potentialCards;

        this.setState({columns, boardId: moment().valueOf()});
    }

    onCardRemove = ({board, column, card}) => {
        //TODO: move to end of Potential column
        let columns = board.columns;
        this.setState({columns});
        this.props.onBoardChange({columns});
    }

    onCardDragEnd = ({board, card, source, destination}) => {
        let columns = board.columns;
        this.setState({columns});
        this.props.onBoardChange({columns});
    }

    filterTutors = ({name, value}) =>{
        this.setState({[name]: value, boardId: moment().valueOf()});
    }

    filterColumns = () =>{
        let { online, inPerson, status, columns } = this.state;
        let filteredColumns = [...columns];
        let potentialColumnId = 1;

        filteredColumns.forEach(column => {
            if(column.id == potentialColumnId){
                let cards = [...column.cards];
                // cards = cards.filter(card => card.status == status);
                if( online == false || inPerson == false){
                    if(online == false && inPerson == false){
                        cards = [];
                    }
                    else{
                        cards = inPerson ? cards.filter(card => card.inPerson == true) : cards.filter(card => card.online == true);
                    }
                }
                column.cards = cards
            }
        })

        return filteredColumns;
    }

    render() {
        let { tutorRequest } = this.props;
        let { boardId, columns } = this.state;
        let { tutors, sortOptions } = this.state;

        let filteredColumns = this.filterColumns();

        let card = <CardTutorBox 
                        // rates={tutorRatesArray} 
                        showRates={false}
                        showConcerns={false}
                        />

        return (
            <>
                <div id="search">
                    <SearchTutorsAdminForm
                        onSubmit={this.onTutorSearch}
                        course={tutorRequest.course || ""}
                        zipcode={tutorRequest.city ? tutorRequest.city + ", " + tutorRequest.state : ""}
                        // minRate={tutorRequest.minRate}
                        minRate={10}
                        maxRate={tutorRequest.maxRate > 20 ? tutorRequest.maxRate - 10 : 20}
                        subjects={tutorRequest.subjects || []}
                        onChangeCheck={this.filterTutors}
                        />
                </div>
                
                <br />

                {this.state.contractError && <Alert color="danger">{this.state.contractError}</Alert> }

                {!isEmpty(tutors) 
                    ? 
                        <React.Fragment>
                            <Row>
                                <h3>{tutors.length} Tutors</h3>
                                {" "}
                                <SortHeader data={tutors} onChangeOption={this.formatBoardData} onChangeOrder={this.formatBoardData} sortOptions={sortOptions} defaultSortBy={2} />
                            </Row>
                            
                            <BTNKanban key={"kanban-" + boardId} columns={filteredColumns} card={card} onCardRemove={this.onCardRemove} onCardDragEnd={this.onCardDragEnd} />

                        </React.Fragment>
                    :
                        <Row>
                            <h3>No Tutors Found</h3>
                        </Row>
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceKanban)
  