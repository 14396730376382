import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment';

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { ContractData } from './actions';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Button } from 'reactstrap';

class TutorContractsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            // contractsColumns: ["request", "details"],
            contractsColumns: ["request"],
            processing: false
        }
    }
    
    componentDidMount() {
        let { tutorContracts=[]} = this.props;
        let { contractsColumns } = this.state;
        
        let columns = createSpecifiedColumns(contractsColumns);
        this.setState({columns});

        this.createRows(tutorContracts);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { tutorContracts=[] } = nextProps;
    //     this.createRows(tutorContracts);
    // }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    window.location.reload();
                    // this.setState({processing: false});
                }
            }
        }
    }

    createRows = tutorContracts => {
        let rows = tutorContracts;

        rows.sort((a,b) =>{
            return moment(b.createdAt) > moment(a.createdAt); //SORT DESC createdAt
        });

        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row.request = this.createContractData(row)
        });
        
        return rows;
    }

    createAction = row => {
        let acceptButton = row.tutorDecision === true ? null : <Button color="info"
                                                                        className="btn-rounded btn-sm w-md mt-1 ml-4 mr-4"
                                                                        onClick={() => this.triggerAction(row, true)}>
                                                                        Accept {" "}
                                                                    </Button>
                                                    
        let declineButton = row.tutorDecision !== null ? null : <Button color="danger"
                                                                    className="btn-rounded btn-sm w-md mt-1 ml-4 mr-4"
                                                                    onClick={() => this.triggerAction(row, false)}>
                                                                    Decline {" "}
                                                                </Button>;
        return <p className="mt-4 mb-4"> {acceptButton}{declineButton} </p>
    }

    createContractData = row => {
        return <ContractData contract={row}>{this.createAction(row)}</ContractData>
    }

    triggerAction = (tutorContract, tutorDecision) =>{
        let { rows } = this.state;
        let { onSubmitApiCall } = this.props;

        rows.forEach(row => {
            if(row.id == tutorContract.id){
                row.tutorDecision = tutorDecision;
                row.action = this.createAction(row);
            }
        });

        onSubmitApiCall("submitContractTutorDecision", { tutorContractId: tutorContract.id, online: tutorContract.online, inPerson: tutorContract.inPerson, decision: tutorDecision });
        this.setState({rows, processing: true});
    }

    render() {
        let {rows, columns, processing } = this.state;

        let tableProps = {...this.props, rows, columns, createColumns: false};

        return (
            <>
                {
                    columns.length > 0 && !processing &&
                        <ReactDataTable key={"basicTable-" + rows.length} {...tableProps} pagination={this.props.paginate || false} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(TutorContractsTable)
