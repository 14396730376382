import React, { Component } from 'react'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import EditPaymentSettings from "components/forms/edit-account/EditPaymentSettings"
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

export default class PaymentSettings extends Component {
    render() {
        return (
            <>
                <BTNMetaTags title={`Payment Settings`} />
                <Row>
                    <Col md={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                <CardTitle>Payment Settings</CardTitle>
                                <EditPaymentSettings />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}
