import React, { Component } from 'react'

import DataTable from 'react-data-table-component';

import { createColumnsFromRows } from './scripts';

export default class ReactDataTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            columns: this.props.columns || [],
            rows: this.props.rows || [],
        }
    }
    
    componentDidMount() {
        let { createColumns = false, rows } = this.props;
        
        if(createColumns){
          let columns = createColumnsFromRows(rows);
          this.setState({columns});
        }
    }

    render() {
        let { columns } = this.state;
        let { rows=[] } = this.props;
        let { responsive=true, pagination=true, striped=true, hover=true, dense=true,
                fixedHeader=false, fixedHeaderScrollHeight="300px" } = this.props;

        return (
            <>
                {
                    columns.length > 0 &&
                        <DataTable 
                            data={rows} 
                            columns={columns} 
                            responsive={responsive}
                            pagination={pagination}
                            striped={striped}
                            highlightOnHover={hover}
                            dense={dense} 
                            fixedHeader={fixedHeader}
                            fixedHeaderScrollHeight={fixedHeaderScrollHeight}
                            />
                }
            </>
        )
    }
}
