import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ThemeAvatar extends Component {
    //rounded vs roudned-circle = square with rounded edges vs circle
    render() {
        let { avatar, name="", className=null, size="sm", rounded="rounded-circle", color="primary", fontSize=16, alt="avatar", rightMargin=3,bottomPadding=4 } = this.props;

        return (
            <>
                {avatar ? (
                    <img
                      src={avatar}
                      className={className || `avatar-${size} mr-${rightMargin} ${rounded} mb-${bottomPadding} float-left float-lg-none`}
                      alt={alt}
                    />
                  ) : (
                    <div className={`avatar-${size} mr-${rightMargin} mx-lg-auto mb-${bottomPadding} float-left float-lg-none`}>
                      <span
                        className={className ||
                          `avatar-title ${rounded} bg-soft-${color} text-${color} font-size-${fontSize}`
                        }
                      >
                        {name.toUpperCase().charAt(0)}
                      </span>
                    </div>
                  )}
            </>
        )
    }
}

ThemeAvatar.protoType = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    alt: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOf(["sm", "md", "lg"]),
    rounded: PropTypes.oneOf(["rounded", "rounded-circle"])

  }