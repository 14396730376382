import React, { Component } from "react"
import moment from "moment"

import { TabPane, Row, Col, FormGroup, Label, Input } from "reactstrap"


class AvailabilityTab extends Component {
  
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Row>
            <p>Adjust these dates to indicate when you are ready to start (or end) tutoring. For example, starting when you were hired and ending when you graduate.</p>
            <Col lg="6">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="startDate">Start Date*</Label>
                    <Input
                      type="date"
                      name="startDate"
                      id="startDate"
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().add(6, 'months').format("YYYY-MM-DD")}
                      onChange={this.props.onChange}
                      value={this.props.formData.startDate}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="endDate">End Date*</Label>
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().add(2, 'years').format("YYYY-MM-DD")}
                      onChange={this.props.onChange}
                      value={this.props.formData.endDate}
                    />
                  </FormGroup>
                </Col>
              </Row>

              
            </Col>
          </Row>

          <hr />
          <Row>
            <p>Taking a break? Indicate when you'll be gone below. For example, May 15th to Aug 15th for summer break. <br /><br />
              <b>NOTE:</b> This is for extended breaks, not a holiday weekend. <br />
              <b>REMINDER:</b> You cannot leave in the middle of your student's academic term.  </p>
            <Col lg="6">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="breakStartDate">Break Start Date</Label>
                    <Input
                      type="date"
                      name="breakStartDate"
                      id="breakStartDate"
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().add(6, 'months').format("YYYY-MM-DD")}
                      onChange={this.props.onChange}
                      value={this.props.formData.breakStartDate}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="breakReturnDate">Break End Date (Return Date)</Label>
                    <Input
                      type="date"
                      name="breakReturnDate"
                      id="breakReturnDate"
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().add(2, 'years').format("YYYY-MM-DD")}
                      onChange={this.props.onChange}
                      value={this.props.formData.breakReturnDate}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default AvailabilityTab
