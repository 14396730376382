import React, { Component } from "react"
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes
import { authProtectedRoutes, studentRoutes, tutorApplicantRoutes, leadRoutes, tutorRoutes, adminRoutes, staffRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// layouts
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import FullPageLayout from "components/FullPageLayout/index.js"

// Import scss
import "./assets/scss/theme.scss"
import ToastNotification from "components/alerts/ToastNotification"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout
    return layoutCls
  }

  render() {
    const Layout = this.getLayout()
    const { user } = this.props;
    let roles = user.roles || [];
    let isAdmin = roles.includes("admin");
    
    return (
      <React.Fragment>
        <ToastNotification />
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                isAdminProtected={false}
              />
            ))}
            {
              (roles.includes("student") || isAdmin) && 
                studentRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={route.fullPage == true ? FullPageLayout : Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isAdminProtected={true}
                    exact
                  />
                ))
            }
            {
              (roles.includes("tutor") || isAdmin) && 
                tutorRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={route.fullPage == true ? FullPageLayout : Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isAdminProtected={true}
                    exact
                  />
                ))
            }
            {
              (roles.includes("tutor-applicant") || roles.includes("tutorApplicant") || isAdmin) && 
                tutorApplicantRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={route.fullPage == true ? FullPageLayout : Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isAdminProtected={true}
                    exact
                  />
                ))
            }
            {
              (roles.includes("leads") || isAdmin) && 
                leadRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={route.fullPage == true ? FullPageLayout : Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isAdminProtected={true}
                    exact
                  />
                ))
            }
            {
              (roles.includes("staff") || isAdmin) && 
                staffRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={route.fullPage == true ? FullPageLayout : Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isAdminProtected={true}
                    exact
                  />
                ))
            }
            {
              isAdmin && 
                adminRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={route.fullPage == true ? FullPageLayout : Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isAdminProtected={true}
                    exact
                  />
                ))
            }

            {/* KEEP AT BOTTOM AS IT CONTAINS THE 404 */}
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={route.fullPage == true ? FullPageLayout : Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                isAdminProtected={false}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    user: state.userReducer
  }
}

App.propTypes = {
  layout: PropTypes.object
}

export default connect(mapStateToProps, null)(App)
