import { GET_APP_SETTING, GET_SUBJECTS } from '../api/urls';
import { get } from "../helpers/api_helper";

export const getAppSetting = field => {
  let url = GET_APP_SETTING +"/" + field;
  return get(url);
}

export const getSubjects = () => {
  return get(GET_SUBJECTS);
}