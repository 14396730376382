export const statusOptions = [
    // {id: 1, name: "offline", className: "avatar-offline"}, //grey
    {id: 1, status: "offline", className: ""}, //grey
    {id: 2, status: "online", className: "avatar-online"}, //green
    {id: 3, status: "flagged", className: "avatar-away"}, //orange - warning
    {id: 4, status: "blocked", className: "avatar-busy"}, //red - danger
];

export const getAvatarClass = (participant, isAdmin=false) => {
    let status = "offline";
    
    //TODO: USE PUSHER TO DETERMINE ONLINE STATUS 
    //(check in onlineMembers)
    //let status = onlineMembers.includes(participant.userId) ? "online" : "offline"

    //Determine participant status
    if(isAdmin){
        if(participant.blocked == true){
            status = "blocked";
        }
        else if(participant.flagged == true){
            status = "flagged";
        }
    }
    
    return statusOptions.find(option => option.status == status).className;
}