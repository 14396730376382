import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import MultiStepForm from 'templates/forms/anfra/multistep/MultiStepForm'

import TutorRatingStep from './TutorRatingStep';
import TutorReviewStep from './TutorReviewStep';
import ResumeSessionsStep from './ResumeSessionsStep';

import AnfraSuccessPage from 'templates/forms/anfra/components/Success';
import AnfraVersion2 from 'templates/forms/anfra/layouts/version2/Version2';

import googleReview from "assets/images/google-review.png"
import { ThemeSpinner } from 'theme/elements/spinner'


class EvaluationFormDetailed extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       assignmentId: props.match.params.assignmentId || "",
       /*formFields: {
        knowledge: 0,
        effective: 0,
        professional: 0,
        communication: 0,
        value: 0,
        recommend: null, //work again

        publicReview: null,
        privateReview: null,

        // resumeSessions: false,
        // startDate: "",
        // endDate: "",
        // newCourse: "",
       },*/
       
       type: null,
       firstName: "",
       lastName: "",
       course: "",
       endDate: "",
       googleReviewUrl: "",

       rateBuffTutor: false,
       success: false,
       submitting: false,
    }
  }

  componentDidMount() { 
    //load tutor details from assignment Id
    const { onSubmitApiCall } = this.props;
    let { assignmentId } = this.state;

    if(assignmentId != "") {
        onSubmitApiCall("getAssignmentToEvaluate", assignmentId);
    }  
  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;

    if(prevProps != this.props){
        let newState = processApiResponse(apiResponse, this.state);

        if(apiResponse != {} && prevState != newState){
            this.setState(newState);
        }
        
        //Redirect
        if(apiResponse != {} && apiResponse.success && this.state.submitting){
            this.setState({redirect: true, submitting: false});
        }
    }
  }

  onSubmit = ( formData = {}) => {
    const { onSubmitApiCall } = this.props;
    let { assignmentId, type } = this.state;
    this.setState({ submitting: true })
    
    let recommend = formData.recommend === "true";

    formData["type"] = type;
    formData["assignmentId"] = assignmentId;
    formData["recommend"] = recommend;

    onSubmitApiCall("submitDetailedEvaluation", formData);

    this.setState({success: true, submitting: false, rateBuffTutor: recommend});
  }

  render() {
    const { apiResponse } = this.props;
    let { success, submitting, rateBuffTutor, 
          type, firstName, lastName, course, endDate, googleReviewUrl } = this.state;

    let loading = apiResponse.loading;

    let data = { firstName }

    let stepClass = "covid-test-wrap test-step step-block";
    let nextButtonClass = "button";
    let prevButtonClass = "prev-btn";

    let steps = [];

    if(type == "end" || type == "first"){
      steps = [
        {id: 1, title: "Tutor's Performance", component: TutorRatingStep, active: true },
        {id: 2, title: "Profile Review For" + firstName, component: TutorReviewStep },
        // {id: 3, title: "Reserve Spot With " + firstName, component: ResumeSessionsStep },
      ];
    
      steps.map(step =>{
        step.class = stepClass;
        step.nextButtonClass =  nextButtonClass; 
        step.prevButtonClass = prevButtonClass;
        step.divClass = "step-block";
      });
    }

    let rightTitle = <>
                        <h2>{type == "end" ? "End Of Semester Evaluation" : "First Session Evaluation"}<br />
                          {firstName && <small>{firstName + " " + lastName + " - " + course}</small>}
                        </h2>
                      </>
    
    let title = firstName != null && firstName != "" ? `Evaluate ${firstName} ${lastName}` : "Tutor Evaluation"
    
    //TODO
    //Success
    //  Do you want to resume sessions with this tutor?

    return (
      <>
        <BTNMetaTags title={title}  />
        
        <AnfraVersion2 
            key={steps.length}
            leftTitle="Tutor Evaluation" 
            // leftSubtitle={type == "end" ? "End Of Semester" : "First Session Evaluation"}
            leftContent={`Please review ${firstName || "your tutor"}'s performance as this helps other students & parents. Be brutally honest!`}
            rightTitle={rightTitle}
            progressBar={type ? true : false}>
              {
                loading && !submitting &&
                    <center><ThemeSpinner /></center>
              }
              {
                submitting ?
                  <center>
                    <h1>Submitting...</h1>
                    <ThemeSpinner />
                  </center>
                :
                  <>
                    {
                      success ?
                        <>
                          {
                            rateBuffTutor == true ?
                              <AnfraSuccessPage image={googleReview}>
                                <p>
                                  Thank you for evaluating {firstName}. <br /> 
                                  Please take a moment to review BuffTutor on Google.  It really helps other students & parents find us.
                                </p>
                                <br /><br />
                                <a href={googleReviewUrl} target="_blank" rel="noreferrer" className="btn btn-lg btn-primary">Go To Google</a> 
                              </AnfraSuccessPage>
                            :
                              <AnfraSuccessPage>  
                                  <h2>Submitted</h2>
                                  <p>
                                    Thank you for evaluating {firstName}. <br /> 
                                    Your feedback helps us keep the very best tutors in our network!
                                  </p>
                                  <br /><br />
                                  <Link to={"/"}><button className="btn btn-lg btn-primary">Dashboard</button></Link> 
                              </AnfraSuccessPage>
                          }
                        </>
                          
                        :

                        <>
                        { steps.length == 0 ?
                          <>{!loading &&
                              <div>
                                <h4 style={{color: "darkred"}}>Evaluation Form Could Not Be Loaded</h4>
                                <p style={{fontSize: "1.15rem"}}>There are 2 types of evaluations that can be submitted:</p>
                                <ul style={{fontSize: "1.15rem"}}>
                                  <li><b>First Session Evaluation </b> <br /> 
                                      This form will be available after the first session has occured. </li>
                                  <li><b>End Of Semester Evaluation</b><br /> 
                                      This form will be available one week before the assignment ends if you have met with {firstName || "your tutor"} more than 3 times.</li>
                                </ul>

                                <p style={{fontSize: "1.15rem"}}><b>Each type of evaluation can only be submitted once.</b></p>
                              </div>
                            }
                          </>
                        :
                          <MultiStepForm steps={steps} onSubmit={this.onSubmit} data={data}/>
                        }
                        </>
                    }
                  </>
              }
        </AnfraVersion2>
      </>
    )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
  apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationFormDetailed)