import React, { Component } from 'react'

import { createGoogleAuthURL } from 'scripts/google-api'
import { ADMIN_GOOGLE_AUTH_CALLBACK } from 'routes/paths'
import { Alert } from 'reactstrap'

export default class GoogleApiLoginForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            error: "",
            authorized: false
        }
        this.getAuthenticationURL.bind(this)
    }

    getAuthenticationURL = async () => {
        this.setState({loading: true, error: ""});
        const redirectURL = ADMIN_GOOGLE_AUTH_CALLBACK;
        let formData = {redirectURL};

        await createGoogleAuthURL(formData)
            .then(response => {
                if(response == "Authorized"){
                    this.setState({authorized: true})
                }
                else { 
                    // window.location.href = response 
                    // window.open(response, '_blank').focus();
                    var popup_window=window.open(response,"myWindow","toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=400, height=400");            
                    try {
                        popup_window.focus();   
                    } catch (e) {
                        alert("Pop-up window blocked. Please allow this window.");
                    }
                }
            })
            .catch(error => { this.setState({error: error.response.data})} );

        this.setState({loading: false});
    }

    render() {
        let { error, authorized } = this.state;

        return (
            <>
                {error && <Alert color="danger">{error}</Alert>}
                {authorized && <Alert color="info">Already Authorized</Alert>}
                <button
                    className="btn btn-warning btn-block waves-effect waves-light"
                    type="submit"
                    onClick={this.getAuthenticationURL}
                >
                    Log Into Google
                </button>         
            </>
        )
    }
}
