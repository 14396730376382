import React, { Component } from "react"
import GoogleMapReact from 'google-map-react';

export default class MapWithMarkers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // places: this.props.geolocations || [{id: 0, lat: 37.871666, lng: -122.272781, radius: 1, radiusUnit: "miles"}], //id, lat, lng, radius, unit (default berkeley)
      places: this.props.geolocations || [],
      markerCount: 0, 
    }
  }

  convertMilesToKm = miles => {
    return miles * 1.6093;
  }

  createMarker = (map, maps, place) => {
    let { dragMarker = false, onDragMarkerEnd = null, onDeleteMarker = null, showMarkerLabel=false, showCircle=false } = this.props;

    let marker = new maps.Marker({
      map,
      position: {
        lat: place.lat,
        lng: place.lng,
      },
      // animation: maps.Animation.DROP,
      draggable: dragMarker,
      label: showMarkerLabel ? place.label : null,
    });

    if(showCircle && place.radius){
      let radius = place.radiusUnit == "miles" ? this.convertMilesToKm(place.radius) : place.radius;
      
      var circle = new maps.Circle({
        map,
        radius: radius * 1000, //metres
        fillColor: '#AA0000',
        strokeColor: "#FF0000",
        strokeOpacity: 0.1,
        strokeWeight: 1,
        // fillColor: "#FF0000",
        fillOpacity: 0.25,  
      });
      circle.bindTo('center', marker, 'position');
      marker._myCircle = circle;
    }

    maps.event.addListener(marker, 'dragend', function(){
      if(onDragMarkerEnd != null) {
        onDragMarkerEnd({//id: place.id,
          id: place.markerId, lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
      }
    });

    maps.event.addListener(marker, 'dblclick', function(){
      if(marker._myCircle != undefined && marker._myCircle != null){
        marker._myCircle.setMap(null)
      }

      marker.setMap(null);
      if(onDeleteMarker != null) {
        onDeleteMarker(place.id);
      }
    });
  }

  handleApiLoaded = (map, maps, places) => {
    let { onAddMarker = null } = this.props;
    // let { markerCount } = this.state;
    let markerCount = places.length;

    //Create Markers
    places.forEach((place) => {
        this.createMarker(map, maps, place);
    });

    if(places.length > 1){
      const bounds = new maps.LatLngBounds();
  
      places.forEach((place) => {
        bounds.extend({lat: place.lat, lng: place.lng});
      });
      
      // Fit map to bounds
      map.fitBounds(bounds);
      // Bind the resize listener
      maps.event.addListener(map, 'idle', () => {
        maps.event.addListener(window, 'resize', () => {
          map.fitBounds(bounds);
        });
      });
    }

    //Add Marker
    maps.event.addListener(map, 'click', event =>{
      markerCount++;
      let statePlaces = this.state.places;

      let placeId = Math.floor(Math.random() * 10000000);
      let newPlace = {id: placeId, label: markerCount.toString(), lat: event.latLng.lat(), lng: event.latLng.lng()}
      statePlaces.push(newPlace);

      this.createMarker(map, maps, newPlace);
      
      if(onAddMarker != null){
        onAddMarker(newPlace);
      }
      this.setState({markerCount});
    });
  };

  render() {
    let { centerLat=null, centerLng=null, zoom=11, height="40vh", width="100%"} = this.props;
    let { places } = this.state
    centerLat = centerLat || (places.length > 0 ? places[0].lat : null);
    centerLng = centerLng || (places.length > 0 ? places[0].lng : null);

    const defaultProps = {
      center: { lat: centerLat, lng: centerLng},
      zoom
    };

    return ( 
      <>
        {
          centerLat != null &&
            <div style={{ height, width }}>
              <GoogleMapReact
                // key={"map-"+ places.length}
                key={this.props.id || "map"}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps, places)} 
              />
            </div>
        }
      </> 
    )
  }
}