import React, { Component } from 'react'
import moment from 'moment';

import { Form, Formik } from 'formik';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { getCurrencySymbol, formatDateTime } from 'scripts/general';

export default class ProposalStudentView extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showRejectForm: false,
        }
    }

    acceptProposal = () => {
        if(confirm("Please confirm your decision to ACCEPT this proposal.")){
            const { details, onAcceptProposal } = this.props;
            onAcceptProposal({proposalId: details.proposalId});
        }
    }

    rejectProposal = (values) => {
        if(confirm("Please confirm your decision to REJECT this proposal.")){
            const { details, onRejectProposal } = this.props;
            let { rejectReason, rejectReasonOther } = values;
            
            rejectReason = rejectReasonOther == null || rejectReasonOther.trim() == "" ? rejectReason : rejectReason + " - " + rejectReasonOther.trim();
            onRejectProposal({rejectReason, proposalId: details.proposalId});
        }
    }

    render() {
        let { showRejectForm } = this.state;
        let { details, loading } = this.props;

        let initialValues = { rejectReason: "", rejectReasonOther: "" }

        return (
            <Card className="pb-3 pt-3" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.25)"}}>
                <CardBody>
                    {
                    !showRejectForm ? 
                        <>
                            <h3>Rate: <span className="text-info">{getCurrencySymbol(details.studentCurrency)}{details.studentRate}/hr</span></h3>
                            <p>
                                {
                                    details.onlineTutor &&
                                        <span className="badge badge font-size-11 badge-soft-info">
                                            Online Tutoring
                                        </span>
                                }
                                {" "}
                                {
                                    details.inPersonTutor && 
                                        <span className="badge font-size-11 badge-soft-success">
                                            In-Person Tutoring
                                        </span>
                                }
                                <br /><br />
                                {!details.matchCreated && details.studentDeadline && <>Respond By <span className={moment(details.studentDeadline).isBefore(new Date()) ? "text-danger" : "text-dark"}>{formatDateTime(details.studentDeadline)}</span></>}
                            </p>
                            {details.matchCreated 
                                ?
                                    <Row>
                                        <Col>
                                            <center><b><em>Match Created</em></b></center>
                                        </Col>
                                    </Row>
                                :
                                    <Row>
                                        <Col>
                                            {
                                                details.tutorDecision === false || moment(details.studentDeadline).isBefore(new Date()) ?
                                                    <Button
                                                        color="info"
                                                        className="btn-rounded btn-sm w-md mt-1 mr-1"
                                                        disabled={true}>
                                                        Create Match {" "}
                                                    </Button>
                                                :
                                                    <Button
                                                        color="info"
                                                        className="btn-rounded btn-sm w-md mt-1 mr-1"
                                                        disabled={loading}
                                                        onClick={this.acceptProposal}>
                                                        Create Match {" "}
                                                        {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                                                    </Button>
                                            }
                                            <Button
                                                type="button"
                                                color="danger"
                                                className="btn-rounded btn-sm w-md mt-1 float-right"
                                                onClick={() => this.setState({ showRejectForm : true })}
                                                disabled={loading || details.tutorDecision === false || moment(details.studentDeadline).isBefore(new Date()) ? true : false}
                                            >
                                                Decline Match {" "}
                                                {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                                            </Button>
                                            {
                                                moment(details.studentDeadline).isBefore(new Date()) &&
                                                <p><br /><a href="mailto:Contact@BuffTutor.com">Contact Us</a> To Extend The Deadline To Respond</p>
                                            }
                                        </Col>
                                    </Row>
                            }
                            {details.tutorDecision === false && 
                                <Row>
                                    <Col>
                                        <br />
                                        <span className="text-danger" style={{fontWeight: "bold"}}>Proposal Withdrawn</span><br />
                                        {details.tutorRejectReason}
                                    </Col>
                                </Row>
                            }

                            {/* <Row>
                                <Col><br /><small>*If you decline the match, this chat will be deleted and you will not be able to message this tutor.</small></Col>
                            </Row> */}
                        </>
                    :
                        <>
                            <CardTitle className="mt-4 mb-3">
                                <a onClick={() => this.setState({showRejectForm : false})} >
                                    <i className='bx bx-x float-left' style={{fontSize: "1.5em"}} />
                                </a>
                                Reject Proposal
                            </CardTitle>
                            <p>Please explain why this proposal does not work for you:</p>
                            
                            <Formik
                                initialValues={initialValues}
                                onSubmit={this.rejectProposal}
                            >
                                { props  => (
                                    <Form className="needs-validation">
                                        <FormGroup check>
                                            <Input type="radio" id="rate" name="rejectReason" value="Rate Too High" required onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="rate" check>Rate Too High</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="schedule" name="rejectReason" value="Schedule Conflict" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="schedule" check>Schedule Conflict</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="proficiency" name="rejectReason" value="Tutor not proficient with course material" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="proficiency" check>Tutor not proficient with course material</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="unresponsive" name="rejectReason" value="Unresponsive Tutor" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="unresponsive" check>Unresponsive Tutor</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="unprofessional" name="rejectReason" value="Unprofessional Behavior By Tutor" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="unprofessional" check>Unprofessional Behavior By Tutor</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="other" name="rejectReason" value="Other" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="other" check>Other</Label>
                                        </FormGroup>
                                
                                        <div className="form-group">
                                            <Input
                                                name="rejectReasonOther"
                                                label="Additional Comments (Optional)"
                                                className="form-control"
                                                placeholder="Your comments will be shared with the tutor."
                                                type="textarea"
                                                value={props.values.rejectReasonOther}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </div>
                                        <div className="mt-3">  
                                            <button
                                                className="btn btn-sm btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Submit Decision {" "}
                                                {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>              
                    }
                </CardBody>
            </Card>
        )
  }
}

