import React, { Component } from "react"
import { TabPane, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from "reactstrap"

class AvailabilityTab extends Component {
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="sessionTime">
                    Session Time: When would you prefer to meet?
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="sessionTime"
                    name="sessionTime"
                    placeholder="Mondays before 11am, Weekends after 3pm"
                    onChange={this.props.onChange}
                    value={this.props.formData.sessionTime}
                    invalid={this.props.invalidFields.includes("sessionTime")}
                  />
                {this.props.invalidFields.includes("sessionTime") &&
                    <FormFeedback>
                      {this.props.validationErrors.find(vError => vError.field == "sessionTime").message}
                    </FormFeedback>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="frequency">
                    Session Frequency: How often would you like to meet?
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="frequency"
                    name="frequency"
                    placeholder="Twice A Week, Only before exams"
                    onChange={this.props.onChange}
                    value={this.props.formData.frequency}
                    invalid={this.props.invalidFields.includes("frequency")}
                  />
                {this.props.invalidFields.includes("frequency") &&
                    <FormFeedback>
                      {this.props.validationErrors.find(vError => vError.field == "frequency").message}
                    </FormFeedback>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default AvailabilityTab
