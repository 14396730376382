import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"

import { Card, CardBody, CardTitle, Col, Media, Row } from 'reactstrap'
import { TUTOR_CONNECT_TO_STRIPE } from 'routes/paths'

class SwitchStripeToExpress extends Component {
    deleteStripeStandardAccount =  () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("deleteStripeStandardAccount");
    }

    render() {
        return (
            <> 
                <BTNMetaTags title="Convert To Stripe Express" />
                
                <Row>
                    <Col lg="10">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    Switch To Stripe Express
                                </CardTitle>
                                <p>Starting February 10th 2022, all tutors will be required to have a Stripe Express account to charge students and receive payments.  Follow the steps below to convert your Stripe Standard Account into a Stripe Express Account.  </p>
                                <b>What you will need:</b>
                                <li>Your DOB and SSN / TIN number (allows Stripe to generate a 1099 next year)</li>
                                <li>Your ID (for identity verification)</li>
                                <li>Your bank's routing number and full account number (as seen on a check) OR a debit card</li>
                                <li>Approx 10 min</li>
                                <br />
                                <Media className="faq-box mb-4">
                                    <div className="faq-icon mr-3">
                                        {/*<i className={item.listIcon + " font-size-20"}/>*/}
                                        <i className=""/>
                                    </div>
                                    <Media body>
                                        {/* <h5 className="font-size-15">
                                            Deactivate Your Stripe Standard Connection
                                        </h5> */}
                                        <div className="text-dark">
                                            <h4>Steps</h4>
                                            <ol>
                                            <li>Disconnect your Stripe Standard account from the BuffTutor platform: <br /><button onClick={this.deleteStripeStandardAccount} className="btn btn-sm btn-primary">Disconnect Account</button><br /><br /></li>
                                            <li>Follow the link below to setup your Stripe Express account and connect to the BuffTutor Stripe platform: <br /><Link to={TUTOR_CONNECT_TO_STRIPE} className="btn btn-sm btn-success">Setup Stripe Express</Link><br /><br /></li>
                                            <li>That's It!</li>
                                            </ol>
                                        </div>
                                    </Media>
                                </Media>      
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(SwitchStripeToExpress)
