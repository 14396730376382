import React, { Component } from 'react'
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardBody, CardTitle, Container } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

import RefundsTable from 'components/tables/refunds/RefundsTable'

class RefundsAdmin extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             refunds: []
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;

        // onSubmitApiCall("getActiverefunds");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        let { refunds } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="Refunds" />
                
                <Container fluid>
                    <Card>                        
                        <CardBody>
                            <CardTitle>Refunds ...TODO (DATA NOT LOADED)</CardTitle>
                            {
                                loading &&
                                    <center><ThemeSpinner /></center>
                            }
                            <RefundsTable key={"refunds-" + refunds.length} refunds={refunds} role="admin" />
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RefundsAdmin)
