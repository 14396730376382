import { SAVE_TOAST_ALERT, CLEAR_TOAST_ALERT } from "./actionTypes";

const initialState = {
  toastType: "", //info, warning, danger, success, primary, dark
  message: "",
  title: null
}

const toastReducer = (state=initialState, action) => {
	switch(action.type){
    case SAVE_TOAST_ALERT:
      return {
        toastType: action.toastType,
        message: action.message,
        title: action.title
      }
    case CLEAR_TOAST_ALERT:
      return {
        toastType: "",
        message: "",
        title: null
      }
    default:
      return state
  }
}

export default toastReducer;
