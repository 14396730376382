import React, { Component } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'

import PaymentMethodDetails from './PaymentMethodDetails'
import ThemeAccordion from 'theme/accordion/ThemeAccordion'
import { ThemeSpinner } from 'theme/elements/spinner'

import { getPaymentMethods } from 'scripts/cart'
import { getUserPaymentMethods } from 'scripts/account'

export default class PaymentOptions extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            savedCards: [],
             paymentMethods: [],
             paymentMethodItems: [],
             error: null,
             loading: false
        }

        this.formatAccordionItems.bind(this);
        this.onSubmit.bind(this);
    }
    
    async componentDidMount() {
        //load from server
        this.setState({loading: true});
        let userId = this.props.userId;

        let response = await getPaymentMethods();
        let storedMethods = await getUserPaymentMethods(userId);

        if(response.hasOwnProperty('error')){
            this.setState({error: response.error});
        }
        if(storedMethods.hasOwnProperty('error')){
            this.setState({error: storedMethods.error});
        }
        
        
        this.setState({paymentMethods: response.paymentMethods || [],
                        savedCards: storedMethods.cards || []});
    

        this.formatAccordionItems();
        this.setState({loading: false});
    }

    formatAccordionItems = () => {
        let { paymentMethods, savedCards } = this.state;
        let paymentMethodItems = [];
        
        savedCards.forEach(savedCard => {
            savedCard["method"] = "storedCard";
            savedCard["brand"] = savedCard["brand"].charAt(0).toUpperCase() + savedCard["brand"].slice(1);

            if(!savedCard.expired){
                paymentMethodItems.push({
                    id: savedCard.id,
                    title: savedCard.brand + " ••••" + savedCard.lastFour,
                    body: <PaymentMethodDetails paymentMethod={savedCard} onSubmit={this.onSubmit} />,
                    isOpen: false
                });
            }
          
        });

        paymentMethods.forEach(paymentMethod => {
            paymentMethodItems.push({
                id: paymentMethod.refId,
                title: paymentMethod.title,
                body: <PaymentMethodDetails paymentMethod={paymentMethod} onSubmit={this.onSubmit} />,
                isOpen: false
            });
        });

        this.setState({paymentMethodItems});
    }

    onSubmit = ({results, error}) =>{
        if (this.props.onSubmit != null && typeof this.props.onSubmit == "function") {
            this.props.onSubmit({results, error});
        }
    }

    render() {
        const { paymentMethodItems, loading } = this.state;

        return (
            <Card>
                <CardBody>
                    <CardTitle>Select A Payment Method</CardTitle>
                    {
                        loading
                            ?
                                <center><ThemeSpinner /></center>
                            :
                                paymentMethodItems.length > 0 &&
                                   <ThemeAccordion key={"pay-" + paymentMethodItems.length} items={paymentMethodItems}
                                    showRadio={true} />
                    }
                </CardBody>
            </Card>
        )
    }
}

  