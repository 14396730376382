import React, { Component } from 'react'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

import { countryCallingCodes } from 'constants/country_calling_codes';

export default class EditContactInfoForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = { }
        this.onSubmit.bind(this)
    }
    
    onSubmit = values => {
        this.props.onSubmit(values);
    }

    render() {
        let { loading, initialValues } = this.props;

        const ContactInfoSchema = Yup.object().shape({
                                    firstName: Yup.string()
                                                .required('Required'),
                                    lastName: Yup.string()
                                                .required('Required'),
                                    email: Yup.string()
                                            .email('Invalid email')
                                            .required('Enter a valid email address.'),
                                    // dialCode: Yup.string()
                                    //                 .required('Required'),
                                    phone: Yup.string()
                                                .required('Enter your phone number'),
          });

        return (
            <>
                <Formik
                    initialValues={{...initialValues}}
                    validationSchema={ContactInfoSchema}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <Label for="firstName">First Name</Label>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                            className="form-control"
                                            value={props.values.firstName}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                        <ErrorMessage name="firstName" component="div" className="text-danger" />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <Label for="lastName">Last Name</Label>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                            className="form-control"
                                            value={props.values.lastName}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                        <ErrorMessage name="lastName" component="div" className="text-danger" />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup className="mb-3">
                                        <Label for="email">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            label="Email"
                                            placeholder="Email Address"
                                            className="form-control"
                                            value={props.values.email}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="phone">
                                            Phone <span className="flag-icon flag-icon-gr"></span>
                                        </Label>
                                        
                                        <Row>
                                            <Col sm={4}>
                                                <Input 
                                                    type="select" 
                                                    name="dialCode" 
                                                    className="custom-select" 
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.dialCode}
                                                    required>
                                                    {
                                                        countryCallingCodes.map((country, index) => 
                                                            <option key={index} value={country.dialCode}>
                                                            {country.dialCode} {country.name} 
                                                            </option>
                                                        )
                                                    }
                                                </Input>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="phone"
                                                        placeholder="Phone"
                                                        className="form-control"
                                                        value={props.values.phone}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                    <ErrorMessage name="phone" component="div" className="text-danger" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button type="submit" color="primary" disabled={loading}>Update Contact Info</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>            
            </>
        )
    }
}