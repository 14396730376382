import React, { Component } from 'react'
import { connect } from "react-redux"
import PropTypes from 'prop-types'

import { submitApiCallAction  } from "store/actions"
import { processApiResponse } from 'scripts/api'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { IssueRefundAction } from './actions'

import { formatCurrencyAmount, formatDate } from 'scripts/general'

class RefundsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            studentColumns: ["#", "refundReason", "tutorName", "status", "subtotal", "deductions", "total", "createdAt", "completedAt", "id"],
            
            tutorColumns: ["#", "refundReason", "studentName", "status", "total", "createdAt", "completedAt", "id"],
            
            adminColumns: ["id", "refundReason", "userName", "tutorName", "objectDetails", "status", "subtotal", "deductions", "total", "refundedFromTutor", "refundedFromBtn", "transactionId", "transferReversalId", "refundId", "createdAt", "completedAt", "refId", "action"],
            processing: false
        }
        
        this.createColumns.bind(this)
        this.createRows.bind(this)

        this.addActionField.bind(this)
        this.formatFields.bind(this)
        this.triggerAction.bind(this)
    }
    
    componentDidMount() {
        let { refunds=[], role } = this.props;
        this.createColumns(role);
        this.createRows(refunds, role);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { refunds=[], role } = nextProps;
    //     this.createRows(refunds, role);
    // }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createColumns = (role) => {
        let { studentColumns, tutorColumns, adminColumns } = this.state;
        let specifiedFields = [];

        switch (role) {
            case "student":
                specifiedFields = studentColumns;
                break;
            case "tutor":
                specifiedFields = tutorColumns;
                break;
            case "admin":
                specifiedFields = adminColumns;
                break;
            default:
                break;
        }

        let columns = createSpecifiedColumns(specifiedFields);

        this.setState({columns});
    }

    createRows = (refunds, role) => {
        let rows = refunds;
        rows = this.formatFields(rows);
        rows = this.addActionField(rows, role);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        let count = 1
        rows.forEach(row => {
            let userCurrency =row.currency || "usd";
            let tutorCurrency = row.tutorCurrency || "usd";
            let btnCurrency = row.btnCurrency || "usd";

            row["#"] = count;

            if(typeof row.completedAt !== undefined && row.completedAt != null && row.completedAt != ""){
                row.completedAt = formatDate(row.completedAt);
            }
            if(typeof row.createdAt !== undefined){
                row.createdAt = formatDate(row.createdAt);
            }
 
            if(typeof row.subtotal !== undefined && typeof row.subtotal == "number"){
                row.subtotal = formatCurrencyAmount(row.subtotal, userCurrency);
            }
            if(typeof row.deductions !== undefined && typeof row.deductions == "number"){
                row.deductions = formatCurrencyAmount(row.deductions, userCurrency);
            }
            if(typeof row.total !== undefined && typeof row.total == "number"){
                row.total = formatCurrencyAmount(row.total, userCurrency);
            }
            if(typeof row.refundedFromTutor !== undefined && typeof row.refundedFromTutor == "number"){
                row.refundedFromTutor = formatCurrencyAmount(row.refundedFromTutor, tutorCurrency);
            }

            //Admin
            if(typeof row.refundedFromBtn !== undefined && typeof row.refundedFromBtn == "number"){
                row.refundedFromBtn = formatCurrencyAmount(row.refundedFromBtn, btnCurrency);
            }
            
            row.refundReason += row.reasonDetails != null ? " - " + row.reasonDetails : "";
            if(row.issueRefund == true){
                row.status = row.status == "succeeded" ? "Refund Issued" : "Refund Approved, But Not Completed";
            }
            else {
                row.status = row.issueRefund === false ? "Refund Denied" : "Under Review";
            }
            
            count++;
        });
        
        return rows;
    }

    addActionField = (rows, role) => {
        if(role == "admin"){
            rows.forEach(row => {
                if(row.issueRefund == null){
                    row["action"] = <IssueRefundAction id={row.id} onClick={() => this.triggerAction(row.id)} />
                }
            });
        }

       return rows;
    }

    triggerAction = (refundId) =>{
        if(confirm(`Are you sure you want to refund this payment?`)){
            alert("needs work");
            
            let { onSubmitApiCall } = this.props;

            this.setState({processing: true});
        }

    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        return (
            <>
                <p>{rows.length} Refund(s)</p>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    
})
  
export default connect(mapStateToProps, mapDispatchToProps)(RefundsTable)


RefundsTable.protoType = {
    role: PropTypes.oneOf(["student", "tutor", "admin"]).isRequired,
    refunds: PropTypes.arrayOf(PropTypes.object)
}