import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts';
import { ADMIN_USER_DETAILS } from 'routes/paths';

export default class RecentTutorActivitiesTable extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         rows: [],
         columns: [],
         tableColumns: [ "tutor",
                        "assignments_count",

                        "new_assignments_count",
                        "prev_assignments_count",
                        "assignments_change",

                        "new_sessions_count",
                        "prev_sessions_count",
                        "sessions_change",

                        "new_total_hours",
                        "prev_total_hours",
                        "hours_change",

                        "new_total_revenue",
                        "prev_total_revenue",
                        "revenue_change",

                        "new_deleted_count",
                        "prev_deleted_count",
                        "deleted_change"]
      }
    }

    componentDidMount() { 
        let { recentActivities=[] } = this.props;
        this.createColumns();
        this.createRows(recentActivities);
    }

    createColumns = () => {
        let { tableColumns } = this.state;

        let columns = createSpecifiedColumns(tableColumns);
        this.setState({columns});
    }

    createRows = (recentActivities, admin) => {
        let rows = recentActivities;
        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row["tutor"] = <Link to={ADMIN_USER_DETAILS + "/" + row["tutor_ref_id"]} target="_blank">{row["tutor_name"]}</Link>

            row["assignments_change"] = row["new_assignments_count"] == null && row["prev_assignments_count"] == null ? "-" : (100 * (row["new_assignments_count"] - row["prev_assignments_count"]) / row["prev_assignments_count"]) + "%";
            row["sessions_change"] = row["new_sessions_count"] == null && row["prev_sessions_count"] == null ? "-" : (100 * (row["new_sessions_count"] - row["prev_sessions_count"]) / row["prev_sessions_count"]) + "%";
            row["hours_change"] = row["new_total_hours"] == null && row["prev_total_hours"] == null ? "-" : (100 * (row["new_total_hours"] - row["prev_total_hours"]) / row["prev_total_hours"]) + "%";
            row["revenue_change"] = row["new_total_revenue"] == null && row["prev_total_revenue"] == null ? "-" : (100 * (row["new_total_revenue"] - row["prev_total_revenue"]) / row["prev_total_revenue"]) + "%";
            row["deleted_change"] = row["new_deleted_count"] == null && (row["prev_deleted_count"] == null || row["prev_deleted_count"] == 0) ? "-" : (100 * (row["new_deleted_count"] - row["prev_deleted_count"]) / row["prev_deleted_count"]) + "%";

            row["new_total_revenue"] = row["new_total_revenue"] != null ? "$ " + row["new_total_revenue"].toFixed(2) : "-";
            row["prev_total_revenue"] = row["prev_total_revenue"] != null ? "$ " + row["prev_total_revenue"].toFixed(2) : "-";
        });
        
        return rows;
    }

    render() {
        let {rows, columns } = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false,
                            fixedHeader: true, fixedHeaderScrollHeight: "200px"};

        return (
            <>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} />
                }
            </>
        )
    }
}
