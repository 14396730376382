import React, { Component } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

export default class MarketHowItWorks extends Component {
  render() {
    let { steps } = this.props;
    return (
      <Row>
        {
          steps.map((step, i) =>
              <Col lg={6} xs={12} key={i} className="mb-4 pb-2">
                  <Card>
                      <CardBody>
                          <h5>{i+1}) {step.title}</h5>
                          <p className="text-dark mb-2" style={{fontSize: "1rem"}}>{step.desc}</p>
                          {
                            step.image && 
                              <img src={step.image} className="img-fluid" alt="How It Works" style={{border: "1px lightgray solid", borderRadius: "2%"}} />
                          }
                      </CardBody>
                  </Card>
              </Col>
          )
        }
      </Row>
    )
  }
}
