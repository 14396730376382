import React, { Component } from 'react'
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardBody, CardTitle, Container } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'
import EvaluationsTable from 'components/tables/evaluations/EvaluationsTable'

class EvaluationsAdmin extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             evaluations: []
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;

        // onSubmitApiCall("getActiveevaluations");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        let { evaluations } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title={`Evaluations`} />
                
                <Container fluid>
                    <Card>                        
                        <CardBody>
                            <CardTitle>Evaluations (TODO: DATA NOT LOADED)</CardTitle>
                            {
                                loading &&
                                    <center><ThemeSpinner /></center>
                            }
                            <EvaluationsTable key={"eval-" + evaluations.length} evaluations={evaluations}/>
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationsAdmin)
