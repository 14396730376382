import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";

//Example: https://upmostly.com/tutorials/react-dropzone-file-uploads-react
// fileRejections NOT rejectedFiles

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.state = { 
            selectedFiles: [] 
    }
  }

  handleAcceptedFiles = files => {
    var selectedFiles = this.state.selectedFiles;
    var fileExists = false;

    files.map(file => {    
        selectedFiles.map( selFile => {
          if(selFile.name == file.name){
            fileExists = true;
          }
        });
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: this.formatBytes(file.size),
        })
      }
    )
   
    files.map(file => {
      if(!fileExists){
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: this.formatBytes(file.size),
        });
        selectedFiles.push(file);
      }
      
    })
    
    this.setState({ selectedFiles });
  }

  onRemoveFile = (filename) => {
    var selectedFiles = this.state.selectedFiles;

    for (let i = 0; i < selectedFiles.length; i++) {
      if(selectedFiles[i].name == filename){
        selectedFiles.splice(i, 1);
      }      
    }

    this.setState({ selectedFiles });
  }
  
  onRemoveAllFiles = () => {
    this.setState({selectedFiles : []});
  }

  onSubmit = (event) => {
    event.preventDefault();
    var selectedFiles = this.state.selectedFiles;
    
    if(selectedFiles.length > 0 && this.props.onSubmit !== "undefined"){
      this.props.onSubmit(selectedFiles);
    }
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  render() {
    const { accept="*"} = this.props; //accept = string OR array of accepted file types = ["audio/*", "video/*", "image/*", ".pdf", "image/jpeg"]
    const { selectedFiles } = this.state;

    const maxSizeMB = 1; // 1 MB
    const maxSize = maxSizeMB * 1048576 ; 

    return (
      <React.Fragment>
        <Dropzone
          onDrop={acceptedFiles =>
            this.handleAcceptedFiles(acceptedFiles)
          }
          minSize={0}
          maxSize={maxSize}
          accept= {accept}
        >
          {({ getRootProps, getInputProps, fileRejections }) => {
            let isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
              
            return (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload"/>
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                  <small>Max File Size: {maxSizeMB} MB</small>
                  <br />
                  {isFileTooLarge && (
                    <small className="text-danger mt-2">
                      File is too large.
                    </small>
                  )}
                </div>
              </div>
            )}
          }
        </Dropzone>
        <div
          className="dropzone-previews mt-3"
          id="file-previews"
        >
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        className="text-muted font-weight-bold"
                      >
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                        {" "}{" "}{" "}
                        <Link
                          to="#"
                          className="text-danger font-weight-bold"
                          onClick={() => this.onRemoveFile(f.name)}
                        >
                          <i className="bx bx-trash"></i>
                        </Link>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            )
          })}
        </div>

        <div className="text-center mt-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onSubmit}
            disabled={selectedFiles.length == 0}
          >
            Upload Files
          </button>
        </div>
        <div className="text-center mt-4">
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onRemoveAllFiles}
            disabled={selectedFiles.length == 0}
          >
            Remove All Files
          </button>
        </div>    

      </React.Fragment>
    )
  }
}

export default FileUpload
