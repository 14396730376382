import React, { Component } from "react";

// Render when No chat history
class EmptyChat extends Component {
  render() {
    return (
      <>
        <main className="main">
          <div className="chats">
            <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
              <div className="container">
                <i className="bx bxs-chat text-secondary" style={{fontSize: "7em"}}></i>
                <h5>Welcome</h5>
                <p className="text-muted">
                  Please select a chat to start messaging.
                </p>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
export default EmptyChat;
