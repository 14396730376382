export const GET_INBOX_THREADS = "GET_INBOX_THREADS"
export const GET_INBOX_THREADS_SUCCESS = "GET_INBOX_THREADS_SUCCESS"
export const GET_INBOX_THREADS_FAIL = "GET_INBOX_THREADS_FAIL"

export const ADD_NEW_INBOX_THREAD = "ADD_NEW_INBOX_THREAD"
export const ADD_INBOX_THREAD_SUCCESS = "ADD_INBOX_THREAD_SUCCESS"
export const ADD_INBOX_THREAD_FAIL = "ADD_INBOX_THREAD_FAIL"

export const DELETE_INBOX_THREAD = "DELETE_INBOX_THREAD"
export const DELETE_INBOX_THREAD_SUCCESS = "DELETE_INBOX_THREAD_SUCCESS"
export const DELETE_INBOX_THREAD_FAIL = "DELETE_INBOX_THREAD_FAIL"

//starred thread
export const GET_STARRED_THREADS = "GET_STARRED_THREADS"
export const GET_STARRED_THREADS_SUCCESS = "GET_STARRED_THREADS_SUCCESS"
export const GET_STARRED_THREADS_FAIL = "GET_STARRED_THREADS_FAIL"


//important thread
export const GET_IMPORTANT_THREADS = "GET_IMPORTANT_THREADS"
export const GET_IMPORTANT_THREADS_SUCCESS = "GET_IMPORTANT_THREADS_SUCCESS"
export const GET_IMPORTANT_THREADS_FAIL = "GET_IMPORTANT_THREADS_FAIL"


//sent thread
export const GET_SENT_THREADS = "GET_SENT_THREADS"
export const GET_SENT_THREADS_SUCCESS = "GET_SENT_THREADS_SUCCESS"
export const GET_SENT_THREADS_FAIL = "GET_SENT_THREADS_FAIL"


//draft thread
export const GET_DRAFT_THREADS = "GET_DRAFT_THREADS"
export const GET_DRAFT_THREADS_SUCCESS = "GET_DRAFT_THREADS_SUCCESS"
export const GET_DRAFT_THREADS_FAIL = "GET_DRAFT_THREADS_FAIL"


//trash thread
export const GET_TRASH_THREADS = "GET_TRASH_THREADS"
export const GET_TRASH_THREADS_SUCCESS = "GET_TRASH_THREADS_SUCCESS"
export const GET_TRASH_THREADS_FAIL = "GET_TRASH_THREADS_FAIL"