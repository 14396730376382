import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts';
import { ADMIN_USER_DETAILS } from 'routes/paths';
import moment from 'moment';

export default class AssignmentsMetricsTable extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         rows: [],
         columns: [],
         tableColumns: [ "tutor",
                        "assignments_count",
                        "total_revenue",
                        "total_tutor_revenue",
                        "total_btn_revenue",
                        "revenue_per_assignment",
                        "tutor_revenue_per_assignment",
                        "btn_revenue_per_assignment",
                        "sessions_per_assignment",
                        "hours_per_assignment",
                        "active_rate",
                        "disabled_rate",
                        "total_hours",
                        "total_sessions",
                        "match_days_per_assignment",
                        "last_session_date"]
      }
    }

    componentDidMount() { 
        let { assignmentsMetrics=[] } = this.props;
        this.createColumns();
        this.createRows(assignmentsMetrics);
    }

    createColumns = () => {
        let { tableColumns } = this.state;

        let columns = createSpecifiedColumns(tableColumns);
        this.setState({columns});
    }

    createRows = (evaluations, admin) => {
        let rows = evaluations;
        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row["tutor"] = <Link to={ADMIN_USER_DETAILS + "/" + row["tutor_ref_id"]} target="_blank">{row["tutor_name"]}</Link>
            row["last_session_date"] = row["last_session_date"] != null ? moment(row["last_session_date"]).fromNow() : "-";

            row["total_revenue"] = row["total_revenue"] != null ? "$ " + row["total_revenue"].toFixed(2) : "-";
            row["total_tutor_revenue"] = row["total_tutor_revenue"] != null ? "$ " + row["total_tutor_revenue"].toFixed(2) : "-";
            row["total_btn_revenue"] = row["total_btn_revenue"] != null ? "$ " + row["total_btn_revenue"].toFixed(2) : "-";

            row["active_rate"] = row["active_rate"] != null ? (row["active_rate"].toFixed(3) * 100) + "%" : "-";
            row["disabled_rate"] = row["disabled_rate"] != null ? (row["disabled_rate"].toFixed(3) * 100) + "%" : "-";

            row["revenue_per_assignment"] = row["revenue_per_assignment"] != null ? "$ " + row["revenue_per_assignment"].toFixed(2) : "-";
            row["tutor_revenue_per_assignment"] = row["tutor_revenue_per_assignment"] != null ? "$ " + row["tutor_revenue_per_assignment"].toFixed(2) : "-";
            row["btn_revenue_per_assignment"] = row["btn_revenue_per_assignment"] != null ? "$ " + row["btn_revenue_per_assignment"].toFixed(2) : "-";
            row["sessions_per_assignment"] = row["sessions_per_assignment"] != null ? row["sessions_per_assignment"].toFixed(2) : "-";
            row["hours_per_assignment"] = row["hours_per_assignment"] != null ? row["hours_per_assignment"].toFixed(2) : "-";
            row["match_days_per_assignment"] = row["match_days_per_assignment"] != null ? row["match_days_per_assignment"].toFixed(2) : "-";
        });
        
        return rows;
    }

    render() {
        let {rows, columns } = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false, 
                            fixedHeader: true, fixedHeaderScrollHeight: "200px"};

        return (
            <>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} />
                }
            </>
        )
    }
}
