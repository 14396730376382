import React, { Component } from "react"

import { Link } from "react-router-dom"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logoSmall from "../../assets/images/logo/BuffTutor_Logo_Small.png"

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="pl-4">
                <Link to="/" className="logo">
                  <img src={logoSmall} alt="" height="40" />
                </Link>
              </div>
            </div>
            <div className="d-flex">
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

export default Header