import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Formik } from 'formik';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { ThemeFileUpload } from 'theme/elements/styledInputs';
import { uploadBusinessPostImage } from 'scripts/google-api';

class GoogleLocationUploadMedia extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            logoUrl: "https://bufftutor.com/images/logo/BuffTutor_Logo-Image_Icon-200x200.png",
            coverUrl: "https://bufftutor.com/images/bufftutor-homepage-tutor-session-01.jpg",
            selectedCategory: "LOGO",
            file: null,
            fileError: "",
        }
        this.onSubmit.bind(this)
    }

    onChangeFile = event => {
        let file = event.target.files[0];
        let maxMB = 25; //25 MB;
        let maxSize = maxMB * 1024 * 1024; 
    
        if (file.size > maxSize) {
          this.setState({fileError: "File too large.  Max size is " + maxMB + " MB"});
        }
        else{
          this.setState({ file });
        }
    }


    onSubmit = async (values) => {
        
        let { file, logoUrl, coverUrl } = this.state;
        const { locations =[] } = this.props;

        let mediaUrl = values.mediaUrl;

        //Construct 
        let locationNames = values.locationNames;
        let selectedLocations = [];
        locationNames.forEach(locName => {
            let location = {};
            location.name = locName;

            //Find account Id
            let matchedLocation = locations.filter(loc =>{
                                return loc.name == locName;
                            })[0] || {};

            location.accountId = matchedLocation.accountId;

            selectedLocations.push(location);
        });

        delete values.locationNames;
        values.locations = selectedLocations;
        
        //Set Media URL
        if(file != null && file != ""){
            await uploadBusinessPostImage(file)
                    .then(response => {
                        mediaUrl = response;
                    })
                    .catch(error => { alert(error.response.data)});
        }
        else if(mediaUrl == null || mediaUrl.trim() == ""){
            switch (values.category) {
                case "LOGO":
                    mediaUrl = logoUrl;
                    break;

                case "COVER":
                    mediaUrl = coverUrl;
                    break;
                default:
                    alert("Media URL is required")
                    break;
            }
        }

        if(mediaUrl != null && mediaUrl.trim() != ""){
            values = {...values, mediaUrl }
            this.props.onSubmit(values);
        }
    }

    render() {
        let { apiResponse, locations = [] } = this.props;
        const { fileError, logoUrl, coverUrl } = this.state;

        let loading = apiResponse.loading;
        let error = apiResponse.error;
        
        const categories = ["LOGO", "COVER", "PROFILE", "AT_WORK", "COMMON_AREA", "ROOMS", "TEAMS", "ADDITIONAL", "CATEGORY_UNSPECIFIED"];
        
        let locationNames = [];
        locations.forEach(location =>{
            locationNames.push(location.name);
        })

        const defaultValues = {
            // mediaUrl: logoUrl,
            category: "LOGO",
            locationNames: locationNames,
          };

        return (
            <>  
                <p>
                    Default Urls: &nbsp;&nbsp;
                    <a href={logoUrl} target="_blank" rel="noreferrer">LOGO</a> &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={coverUrl} target="_blank" rel="noreferrer">COVER</a> <br />
                </p>
               
                {error ? <Alert color="danger">{error}</Alert> : null}
               
                <Formik
                    initialValues={defaultValues}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            {
                                locations.length > 0 &&
                                    <Input type="select" 
                                        name="locationNames" 
                                        label="Locations"
                                        multiple 
                                        required
                                        value={props.values.locationNames}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}>
                                        {
                                            locations.map((location, index) =>
                                                <option key={index} value={location.name}>{location.title}</option>
                                            )
                                        }
                                    </Input>
                            }
                            
                            <Row>
                                <Col sm="4">
                                    <Label>Category</Label>
                                    {
                                        categories.map((category, i) =>
                                            <FormGroup check key={"fgradio-"+i}>
                                                <Input type="radio" id={category} name="category" value={category} required onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                                <Label for={category} check>{category}</Label>
                                            </FormGroup>
                                        )
                                    }
                                </Col>
                                <Col sm="8">
                                    <p><b>Provide URL OR Select A File</b></p>
                                    <div className="form-group">
                                        <Label>Media Url</Label>
                                        <Input
                                            type="text"
                                            name="mediaUrl"
                                            value={props.values.mediaUrl}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                    </div>
                                    
                                    <ThemeFileUpload name={"avatar"}
                                            accept="image/*"
                                            onChange={this.onChangeFile}
                                            hideButton={true} />
                                    {fileError && <small className="text-danger">{fileError}</small>}
                                        
                                    <br /><br />
                                    <div className="form-group">
                                        <Label>Description</Label>
                                        <Input
                                            type="text"
                                            name="description"
                                            label="Description"
                                            placeholder="Optional"
                                            value={props.values.description}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col sm={6}>
                                    <div className="mt-3">
                                        {
                                        loading
                                            ?
                                            <button
                                                className="btn btn-info btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={true}
                                            >
                                                Uploading ... {" "}
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            
                                            </button>
                                            :
                                            <button
                                                className="btn btn-info btn-block waves-effect waves-light"
                                                type="submit"
                                            >
                                                Upload Media
                                            </button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

// const mapDispatchToProps = dispatch => ({
//     onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
// })
  
export default connect(mapStateToProps, null)(GoogleLocationUploadMedia)