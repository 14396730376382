import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import { ReactComponent as CloseSvg } from "theme-chat/assets/media/icons/close.svg";

import UserChatDetails from './chatDetails/UserChatDetails';
import UserChatRequestDetails from './requestDetails/UserChatRequestDetails';


export default class ChatSidebar extends Component {
   // detailsType = "user", "tutorRequest"
    closeSidebar = () => {
      this.props.closeSidebar();
    };
    
    render() {
        let { chat, messages, participant, pusher, detailsType, isAdmin=false} = this.props;

        let chatDetailsComponent= null;
        let chatDetailsTitle="";
        
        switch (detailsType) {
            case "tutorRequest":
                chatDetailsComponent = <UserChatRequestDetails chat={chat} pusher={pusher} isAdmin={isAdmin} />
                chatDetailsTitle= "Tutor Request Details";
                break;

            case "user":
                let images = [];
                let documents = [];

                messages.map(message =>{
                if(message.attachment != null){
                    if(message.image == true){
                    images.push(message.attachment);
                    }
                    else{
                    documents.push({fileName: message.body, url: message.attachment});
                    }
                }
                })
                
                chatDetailsComponent = <UserChatDetails participant={participant} images={images} documents={documents} isAdmin={isAdmin} />
                chatDetailsTitle= "User Details";
            default:
                break;
        }

        return (
            <div className="d-flex h-100 flex-column">
              <div className="chat-info-header px-2">
                <div className="container-fluid">
                  <ul className="nav justify-content-between align-items-center">
                    <li className="text-center">
                      <h5 className="text-truncate mb-0">{chatDetailsTitle}</h5>
                    </li>
                    <li className="nav-item list-inline-item">
                      <Link
                        className="nav-link text-muted px-0"
                        to="#"
                        onClick={this.closeSidebar}
                      >
                        <CloseSvg />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              { chatDetailsComponent }

            </div>
        )
    }
}
