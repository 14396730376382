import React, { Component } from 'react'
import { Link } from "react-router-dom"

import { Col, Row } from 'reactstrap';

import { TUTOR_PROFILE } from "routes/paths";

import ThemeAvatar from 'theme/images/ThemeAvatar';

import { getCurrencySymbol } from 'scripts/general';

const TutorInfoWindow = (props) => {
    const { tutor } = props;
    
    const style = "vertical";

    const infoWindowHorizontalStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 300,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 0,
        fontSize: 14,
        zIndex: 100,
        borderRadius: "5%"
    };

    const closeHorizontalStyle= {
        top: "0.15rem",
        left: "0.9rem", 
        position: "absolute", 
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        border: "none",
        padding: "2px 4px",
        margin: "3px",
        color: "white",
        fontStyle: "sans-serif",
        zIndex: "999",
        pointerEvents:"auto",
    }

    const infoWindowVerticalStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: "12.5rem",
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        paddingTop: "0.0rem",
        paddingBottom: "0.75rem",
        paddingLeft: "0.75rem",
        paddingRight: "0.75rem",
        fontSize: 14,
        zIndex: 100,
        borderRadius: "5%"
    }

    const closeVerticalStyle = {
        top: "0.35rem",
        // left: "0.5rem", 
        right: "0.5rem", 
        position: "absolute", 
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        border: "none",
        padding: "2px 4px",
        margin: "3px",
        color: "white",
        fontStyle: "sans-serif",
        zIndex: "999",
        pointerEvents:"auto",
    }

    return (
        <>
            {
                style == "horizontal" ?
                    <div style={infoWindowHorizontalStyle}>
                        <Link
                            to={TUTOR_PROFILE + "/" + (tutor.refId || tutor.id)}
                            className="text-primary"
                            target="_blank"
                        >
                            <Row>
                                <Col xs="5">
                                    <button style={closeHorizontalStyle} onClick={() => props.onCloseWindow()}><i className="fa fa-times" aria-hidden="true"></i></button>
                                    <ThemeAvatar avatar={tutor.avatar} name={tutor.userName} size="xl" rounded="rounded" bottomPadding="0" />
                                </Col>
                                <Col xs="7" className="mt-2">
                                    <div style={{ fontSize: 16 }} className="text-truncate text-dark">
                                        {tutor.userName}
                                    </div>
                                    {
                                        tutor.acceptingStudents != true &&
                                            <div className="badge badge font-size-14 badge-soft-danger">
                                                Unavailable
                                            </div>
                                    }
                                    <div className="text-truncate text-secondary" style={{ fontSize: 14 }}>
                                        {tutor.major}
                                    </div>
                                    {
                                        tutor.city &&
                                            <span className="text-truncate text-muted">
                                                <i className="fa fa-map-marker-alt" />{" "}{tutor.city}
                                            </span>
                                    }
                                    <div style={{ color: "black" }}>
                                        {
                                            !tutor.rating && 
                                                <>
                                                    {String.fromCharCode(9733)}
                                                    {tutor.rating || 4.7} ({tutor.evaluationsCount})
                                                </>
                                        }

                                        {/*<span style={{ color: 'black' }}>
                                        {tutor.rating || 4.7} ({tutor.evaluationsCount})
                                        {' '}
                                        </span>
                                        <span style={{ color: 'black' }}>
                                        {String.fromCharCode(9733).repeat(Math.floor(tutor.rating || 4.7))}
                                        </span>
                                        <span style={{ color: 'lightgrey' }}>
                                        {String.fromCharCode(9733).repeat(5 - Math.floor(tutor.rating || 4.7))}
                                        </span> */}
                                    </div>
                                    <br />
                                    {
                                        tutor.inPerson && 
                                            <span className="badge badge font-size-11 badge-success">
                                            In-Person
                                            </span>
                                    }
                                    {" "}
                                    {
                                        tutor.online &&
                                            <span className="badge badge font-size-11 badge-info">
                                                Online
                                            </span>
                                    }
                                
                                    {/* <div style={{ fontSize: 14, color: 'grey' }}>
                                        {'$'.repeat(tutor.price_level)}
                                    </div> */}
                                    {
                                        tutor.acceptingStudents != true &&
                                            <div style={{ fontSize: 14, color: 'red' }}>
                                                Unavailable
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Link>
                    </div>
                :
                    <div style={infoWindowVerticalStyle}>
                        <Link
                            to={TUTOR_PROFILE + "/" + (tutor.refId || tutor.id)}
                            className="text-primary"
                            target="_blank"
                        >
                            <button style={closeVerticalStyle} onClick={() => props.onCloseWindow()}><i className="fa fa-times" aria-hidden="true"></i></button>
                            {
                                tutor.avatar && 
                                    <Row>
                                        <img
                                            src={tutor.avatar}
                                            className="rounded"
                                            // width="200rem"
                                            height="200rem"
                                            />
                                    </Row>
                            }
                            
                            <Row>
                                <Col xs="12" className="mt-2">
                                    <div>
                                        <span style={{ fontSize: 18 }} className="text-truncate text-dark">
                                            {tutor.userName}
                                        </span>
                                        <span style={{ color: "black"}} className="float-right">
                                            {
                                                !tutor.rating && 
                                                    <>
                                                        {String.fromCharCode(9733)}{" "}
                                                        {tutor.rating || 4.7} ({tutor.evaluationsCount})
                                                    </>
                                            }
                                        </span>
                                    </div>
                                    {
                                        tutor.acceptingStudents != true &&
                                            <div className="badge badge font-size-14 badge-soft-danger">
                                                Unavailable
                                            </div>
                                    }
                                    <div></div>
                                    <div className="text-truncate text-secondary">
                                        {tutor.major}
                                    </div>
                                    
                                    {
                                        tutor.city &&
                                            <span className="text-truncate text-muted">
                                                <i className="fa fa-map-marker-alt" />{" "}{tutor.city}
                                            </span>
                                    }
                                    {
                                        tutor.minRate &&
                                        <div className="text-dark">
                                            {tutor.currency && getCurrencySymbol(tutor.currency)}{tutor.minRate} - {tutor.maxRate}/hr
                                        </div>
                                    }
                                    
                                    <div className="mb-1"></div>
                                    {
                                        tutor.inPerson && 
                                            <span className="badge badge font-size-11 badge-success">
                                            In-Person
                                            </span>
                                    }
                                    {" "}
                                    {
                                        tutor.online &&
                                            <span className="badge badge font-size-11 badge-info">
                                                Online
                                            </span>
                                    }
                                </Col>
                            </Row>
                        </Link>
                    </div>
            }
        </>
    );
};

export default TutorInfoWindow;