import { get, post, put, del } from "../helpers/api_helper"
import { GET_ACCOUNT_STUDENT_RECENT_SESSIONS, GET_ACCOUNT_TUTOR_RECENT_SESSIONS,
         GET_ACTIVE_TUTOR_SESSIONS_FOR_STUDENT, GET_ACTIVE_TUTOR_SESSIONS_FOR_TUTOR,
         POST_CHARGE_TUTOR_SESSION,
         POST_CANCEL_TUTOR_SESSION_SCHEDULED_CHARGE, 
         POST_DELETE_TUTOR_SESSION,
      POST_DELETE_TUTOR_SESSION_REQUEST } from "../api/urls"

// export async function getRecentTutorSessionsForTutor(tutorId){
//    //return get(url + "/" + userId);
//    return true
// }

export const getRecentTutorSessionsForStudent = studentId => {
   return get(GET_ACCOUNT_STUDENT_RECENT_SESSIONS + "/" + studentId);
}

export const getRecentTutorSessionsForTutor = tutorId => {
   return get(GET_ACCOUNT_TUTOR_RECENT_SESSIONS + "/" + tutorId);
}

export const getActiveTutorSessionsForStudent = (studentId) => {
   return get(GET_ACTIVE_TUTOR_SESSIONS_FOR_STUDENT + "/" + studentId);
}

export const getActiveTutorSessionsForTutor = (tutorId) => {
   return get(GET_ACTIVE_TUTOR_SESSIONS_FOR_TUTOR + "/" + tutorId);
}

export const chargeTutorSession = formData => {
   return post(POST_CHARGE_TUTOR_SESSION + "/" + formData.assignmentId, formData);
}

export const chargeTutorSessionImmediately = formData => {
   return post(POST_CHARGE_TUTOR_SESSION + "/" + formData.assignmentId +"/OLD", formData);
}

export const cancelTutorSessionScheduledCharge = tutorSessionId => {
   return post(POST_CANCEL_TUTOR_SESSION_SCHEDULED_CHARGE + "/" + tutorSessionId);
}

export const deleteTutorSession = formData => {
   return post(POST_DELETE_TUTOR_SESSION + "/" + formData.tutorSessionId, formData);
}

export const requestDeleteTutorSession = formData => {
   return post(POST_DELETE_TUTOR_SESSION_REQUEST + "/" + formData.tutorSessionId, formData);
}
