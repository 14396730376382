import React, { Component } from "react"

import BasicModal from "./BasicModal"
import ChargeTutorSessionForm from "components/forms/charge-session/ChargeTutorSessionForm"

class ChargeSessionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.toggleModal.bind(this)
    this.onSubmitCharge.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal !== this.props.isOpen) {
      this.setState({modal: this.props.isOpen});
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));

    if(this.props.toggleModal != undefined){
      this.props.toggleModal(this.state.modal);
    }
  }

  onSubmitCharge = () =>{
    if(typeof this.props.toggleModal !== undefined){
      this.props.toggleModal(); //Close Modal
    }
    this.setState({modal: false});
  }

  render() {
    let { assignment } = this.props;

    return (
        <BasicModal isOpen={this.state.modal}
            header={`Charge Tutor Session`}
            toggleModal={this.toggleModal}>
          <p><b>{assignment.name} In {assignment.course}</b></p>
          <p className="text-dark">Charge each tutor session immediately <b>AFTER</b> it occurs.</p>
          <ChargeTutorSessionForm assignment={assignment} onSubmit={this.onSubmitCharge}/>
        </BasicModal>
    )
  }
}

export default ChargeSessionModal;
