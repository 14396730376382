import React, { Component } from 'react'
import moment from "moment";
import { Link } from 'react-router-dom';

import { ReactComponent as InfoSvg } from "theme-chat/assets/media/icons/infosvg.svg";
import { ReactComponent as LocationSvg } from "theme-chat/assets/media/icons/location.svg";

import { ADMIN_USER_DETAILS, TUTOR_PROFILE } from "routes/paths";
import BTNStarRatings from "components/ratings/BTNStarRatings";


export default class ChatParticipantDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showUserInformation: true,
        }
    }  

    toggleShowUserInformation = () => {
        this.setState({ showUserInformation: !this.state.showUserInformation });
    };

    render() {
        let { participant, isAdmin=false } = this.props;
        let { showUserInformation } = this.state;

        return (
        <>
            <div className="text-center p-3">
                {participant.avatar && 
                    <div className="avatar avatar-xl mx-5 mb-3">
                    <img className="avatar-img" src={participant.avatar} alt=""></img>
                    </div>
                }
                {
                    isAdmin ?
                        <h5 className="mb-1"><Link to={ADMIN_USER_DETAILS + "/" + participant.userId} className="contacts-link text-primary" target="_blank" rel="noreferrer">{participant.userName}</Link></h5>
                    :
                        <h5 className="mb-1">{participant.userName}</h5>
                }
                {
                            participant.rating > 0 &&
                                <div className="mb-2">
                                    <BTNStarRatings active={false} rating={participant.rating} showFractions={true} /> &nbsp;&nbsp;&nbsp; 
                                    <br /> 
                                    <small className="text-secondary">{participant.rating > 0 && <><b>{participant.rating.toFixed(1)}</b> &bull; </>}
                                    {" "}{participant.reviewsCount || 0} Reviews </small>
                                    
                                </div>
                        }
                {participant.education && 
                    <p className="mb-0 text-muted d-flex align-items-center justify-content-center">
                        {participant.major}<br />
                        {participant.education}
                    </p>
                }
                {
                   participant.hiredAt &&
                   <>
                        <a href={TUTOR_PROFILE + "/" + participant.userId} target="_blank" rel="noreferrer">View Profile</a>
                        <br /><br />
                   </> 
                }
                {participant.location && 
                    <p style={{marginBottom: "0"}} className="text-muted d-flex align-items-center justify-content-center">
                        <LocationSvg />
                        <span>{participant.location}</span>
                    </p>
                }
                
                {
                    participant.recentReview &&
                    <>
                        <b>Most Recent Review</b>
                        <p className="text-truncate ml-5 mr-5" >{participant.recentReview}</p>
                    </>
                }
            </div>
            <div className="chat-info-group">
                <Link
                    className="chat-info-group-header"
                    data-toggle="collapse"
                    to="#"
                    onClick={this.toggleShowUserInformation}
                >
                    <h6 className="mb-0">User Info</h6>
                    <InfoSvg className="hw-20 text-muted" />
                </Link>

                <div
                    className={
                    "chat-info-group-body collapse " +
                    (showUserInformation ? "show" : "")
                    }
                >
                    <div className="chat-info-group-content list-item-has-padding">
                        <ul className="list-group list-group-flush ">
                            {
                                participant.hiredAt &&
                                    <li className="list-group-item border-0">
                                        <p className="small text-muted mb-0">Joined BuffTutor:</p>
                                        <p className="mb-0">{moment(participant.hiredAt).format("MMMM YYYY")}</p>
                                    </li>
                            }
                            <li className="list-group-item border-0">
                                <p className="small text-muted mb-0">Email</p>
                                <p className="mb-0">{participant.email ? <a href={"mailto:" + participant.email}>{participant.email}</a> : "Hidden *"}</p>
                            </li>
                            <li className="list-group-item border-0">
                                <p className="small text-muted mb-0">Phone</p>
                                <p className="mb-0">{participant.phone ? <a href={"tel:" + participant.phone}>{participant.phone}</a> : "Hidden *"}</p>
                            </li>
                            {
                                participant.timezone &&
                                    <li className="list-group-item border-0">
                                        <p className="small text-muted mb-0">Local Time</p>
                                        <p className="mb-0">
                                            {moment().tz(participant.timezone).format("LT")} 
                                            {/* <br />
                                            <small>({participant.timezone.replace(/_/g, " ")})</small> */}
                                        </p>
                                    </li>
                            }
                        </ul>
                        <br />
                        {!participant.email && <p className="small text-muted mb-0 ml-3">* Will be visible after a match is created.</p> }
                    </div>
                </div>
            </div>
        </>
        )
    }
}
