import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from 'prop-types'

import { Col, Row } from "reactstrap"
import { getAppSetting } from 'scripts/app'
import { ThemeSelect } from 'theme/elements/styledInputs'
import PricingCard from "./PricingCard"
import {CART_ORDER_CONFIRMATION} from "routes/paths"


import { getCurrencySymbol } from "scripts/general"

import { addItemToCart } from "store/actions"

class TutorRatePricing extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       groupSession: false,
       individualOptions: [],
       groupOptions: [],
       processingFeeDecimal: 0.00, //decimal

       pricingType: "paygo",
       prepayHours: 2, //min prepay value
       prepayValues: [2,4,8,12,16],
       rate: 0,
    }
    this.loadProcessingFee.bind(this)
    this.generateRateOptions.bind(this)
    this.onChangeHours.bind(this)
    this.onSelectPricing.bind(this)
  }
  
  async componentDidMount() {
    await this.loadProcessingFee();
    this.generateRateOptions();
  }

  loadProcessingFee = async () => {
   let processingFeeDecimal = await getAppSetting("paygo_fee");

   this.setState({processingFeeDecimal});

  }

  generateRateOptions = () =>{
    let { tutorContract } = this.props;
    let { processingFeeDecimal } = this.state;
    let groupSession = tutorContract.groupSession || false;
    let studentRate = tutorContract.rate;
    let studentPrepayRate = tutorContract.prepayRate > 0 ? tutorContract.prepayRate : studentRate;
    let groupRate = tutorContract.groupRate;
    let groupPrepayRate = tutorContract.groupPrepayRate;


    let currencyIso = tutorContract.currency || "usd";
    let currencySymbol = getCurrencySymbol(currencyIso);

    let footnotePrepay = "** Unused balances are refundable, minus a 15% admin fee.";
    let footnotePayGo = "** The first hour must be prepaid, however subsequent sessions/hours will be charged to your stored payment method after each session occurs.";
    let processingFee = processingFeeDecimal * 100;

    let featuresPrepay = [
            //{title: "Lower Rate"},
            {title: "Discounted Rate"},
            {title: "No Payment Processing Fee"},
            // {title: "Additional Hours Are Charged At The Pay As You Go Rate "},
            //{title: "Pay By Credit / Debit Cards, Prepaid Cards, Bank ..."},
          ];

    let featuresPayGo =  [
            {title: "Only Prepay For First Hour"},
            {title: processingFee + "% Payment Processing Fee "},
            {title: "Switch To Prepaid Package In The Future, If Desired"},
          ];
    
    let featuresGroupPrepay = [ ...featuresPrepay,
            {title: "1-On-1 Private Sessions Charged @ " + currencySymbol + studentPrepayRate +"/hr"},
          ];
    let featuresGroupPayGo =  [...featuresPayGo,
      {title: "1-On-1 Private Sessions Charged @ " + currencySymbol + studentRate +"/hr"},
          ];
    
    let individualOptions = [
      {
        title: "Prepay",
        type:"prepay",
        description: "Pay upfront for sessions ",
        icon: "bxs-hourglass-top",
        currency: currencySymbol,
        price: studentPrepayRate,
        features: featuresPrepay, //{title}
        footnote: footnotePrepay
        
      },
      {
        title: "Pay As You Go",
        type:"paygo",
        description: "Pay after each session",
        icon: "bxs-hourglass-bottom",
        currency: currencySymbol,
        price: studentRate,
        features: featuresPayGo, //{title}
        footnote: footnotePayGo
      },
      
    ];

    let groupOptions = [
      {
        title: "Prepay",
        type:"prepay-group",
        description: "Pay upfront for group sessions ",
        icon: "bx-group",
        currency: currencySymbol,
        price: groupPrepayRate,
        features: featuresGroupPrepay, //{title}
        footnote: footnotePrepay
      },
      {
        title: "Pay As You Go",
        type:"paygo-group",
        description: "Pay after each group session",
        icon: "bxs-group",
        currency: currencySymbol,
        price: groupRate,
        features: featuresGroupPayGo, //{title}
        footnote: footnotePayGo
      },
     
    ];

    this.setState({ individualOptions, groupOptions, groupSession });
  }

  onChangeHours = (event) => {
    const { value } = event.target;
    this.setState({
        prepayHours: value
    });
  }

  onSelectPricing = (pricingType) => {
    //Select Plan & Add To Cart
    let { prepayHours } = this.state;
    let { tutorContract, onAddItemToCart } = this.props;
    let rate = tutorContract.rate;
    let currency = tutorContract.currency || "usd";

    let name = "First Hour Prepayment - Pay As You Go ";
    let description = tutorContract.tutorName + " in " + tutorContract.course;
    let image = tutorContract.tutorAvatar;

    switch (pricingType) {
      case "prepay":
        if (tutorContract.prepayRate > 0){
          rate = tutorContract.prepayRate;
        }
        name = "Tutor Sessions Prepayment - " + prepayHours + " Hours @ " + getCurrencySymbol(currency) + rate + "/hr";
        description = tutorContract.tutorName + " in " + tutorContract.course;
        break;

      case "paygo-group":
        rate = tutorContract.groupRate;  
        name = "First Hour Prepayment - Pay As You Go (Group Session)";
        description = "Group session with " + tutorContract.tutorName + " in " + tutorContract.course;
          
        prepayHours = 1;
        break;
      case "prepay-group":
        if (tutorContract.groupPrepayRate > 0){
          rate = tutorContract.groupPrepayRate;
        }  
        name = "Group Tutor Sessions Prepayment - " + prepayHours + " Hours @ " + getCurrencySymbol(currency) + rate + "/hr";
        description = tutorContract.tutorName + " in " + tutorContract.course;
        break;

      default:
        //"paygo"	
        prepayHours = 1;
        break;
    }

    
    //create Cart Item
    let price = rate * prepayHours;
    
    let cartItem = {
      id: tutorContract.id,
      type: pricingType,
      object: "tutorContract",
      objectId: tutorContract.id,
      name,
      description,
      price,
      quantity: 1,
      currency,
      image,
      metadata: { 
                  hours: typeof prepayHours == "number" ? prepayHours : Number.parseInt(prepayHours),
                  method: "submitContractStudentDecision",
                  data: {
                    id: tutorContract.id,
                    decision: true
                  },
                }
    };

    onAddItemToCart(cartItem);

    this.setState({ pricingType, rate});
  }

  render() {    
    let { individualOptions, groupOptions, groupSession, prepayHours, prepayValues} = this.state;
    let options = groupSession ? groupOptions : individualOptions;    

    return (
      <React.Fragment>
        <Row>
          {options.map((option, key) => (
            <Col xl={{size: 4, offset: key == 0 ? 2 : 0}} sm="6" key={"_pricing_" + key}>
              <PricingCard 
                title={option.title}
                description={option.description}
                icon={option.icon}
                currency={option.currency}
                price={option.price ? option.price.toFixed(2) : ""}
                features={option.features}
                link={CART_ORDER_CONFIRMATION}
                footnote={option.footnote}
                onSelect={() => this.onSelectPricing(option.type)}
                >
                  {
                    option.type.includes("prepay") &&
                      <>
                        <div className="col-sm-8">
                          <ThemeSelect label={"Select Package: "} value={prepayHours} onChange={this.onChangeHours}>
                            {prepayValues.map((value, k) =>
                              <option key={"opt-"+k} value={value}>{value} Hours</option>
                            )}
                          </ThemeSelect>
                        </div>
                        <br />
                      </>
                  }
                </PricingCard>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onAddItemToCart: cartItem => dispatch(addItemToCart(cartItem)),
})

export default connect(null, mapDispatchToProps)(TutorRatePricing)

TutorRatePricing.protoType = {
  tutorContract: PropTypes.object,
}