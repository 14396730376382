import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { ThemeSpinner } from 'theme/elements/spinner'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { TUTOR_CHECKLIST } from 'routes/paths'

class StripeAccountLogin extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             accountId: "",
             loginLink: "",
             accountType: "",

             noAccount: false
        }
    }
    
    //Check If Account ID Exists
    //Redirect To Stripe Onboarding Flow
    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getAccountId");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                if(newState.loginLink != null && newState.loginLink != ""){
                    window.location.href = newState.loginLink;
                } 

                else if(prevState.accountId != newState.accountId 
                    && newState.accountId != null && newState.accountId != ""
                    && newState.accountType != null && newState.accountType != ""
                    && newState.accountType != "standard"){
                        
                        this.getLoginLink(newState.accountId);
                }

                else if(prevState.accountType != newState.accountType
                    && newState.accountType == "standard"){
                    if(newState.accountId != null && newState.accountId != ""){
                        window.location.href = "https://dashboard.stripe.com/login";
                    } 
                    else{
                        newState["noAccount"] = true;
                    }
                }
                
                this.setState(newState);
            }
        }
    }

    getLoginLink =  (accountId) => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("createLoginLink", accountId);
    }

    render() {
        const { apiResponse } = this.props;
        const { noAccount } = this.state;
        const loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="Login To Stripe" />

                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                {
                                    noAccount ?
                                        <>
                                            <center><CardTitle>Account Not Found</CardTitle></center>
                                            <center>Refer to the <Link to={TUTOR_CHECKLIST}>Tutor Checklist</Link> to set up your account to accept payments.</center>
                                        </>
                                    :
                                        <center><CardTitle>Logging Into Stripe...</CardTitle></center>
                                }
                                {
                                    loading && 
                                        <center><ThemeSpinner color={"success"}/></center>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(StripeAccountLogin)
