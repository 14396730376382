import React, { Component } from 'react'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { Input, Label } from 'reactstrap';
import { getCurrencySymbol } from 'scripts/general';

export default class CreateBidForm extends Component {
    onSubmit = values => {
        this.props.onSubmit(values);
    }
    onDelete = () => {
        this.props.onDelete();
    }

    render() {
        let {  currency, minBid=1, maxBid, message, submitted } = this.props;

        let initialValues = {maxBid: maxBid ? maxBid : minBid, message}
        const BidSchema = Yup.object().shape({
                                    maxBid: Yup.number()
                                                .positive()
                                                .min(minBid)
                                                .required('Please enter bid'),
                                    message: Yup.string()
                                                .required("Please add a message to the student.")
                                    
          });

        return (
            <Formik
                initialValues={{...initialValues}}
                validationSchema={BidSchema}
                onSubmit={this.onSubmit}
            >
                { props  => (
                    <Form className="needs-validation">
                        <div className="form-group">
                            <Label for="maxBid">{`Your Bid (${getCurrencySymbol(currency || "usd")})`}</Label>
                            <Input
                                name="maxBid"
                                id="maxBid"
                                className="form-control col-md-6"
                                placeholder="Your Bid"
                                type="number"
                                min={minBid}
                                value={props.values.maxBid}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                            <ErrorMessage name="maxBid" component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                            <Label for="message">Message To Student</Label>
                            <Input
                                name="message"
                                className="form-control"
                                placeholder="Introductory message to student."
                                type="textarea"
                                value={props.values.message}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                            <ErrorMessage name="message" component="div" className="text-danger" />
                        </div>
                        <div className="mt-3">  
                            <button
                                className={`btn btn-sm ${submitted ? "btn-secondary" : "btn-primary"} btn-block waves-effect waves-light`}
                                type="submit"
                            >
                                {submitted ? "Update Bid" : "Create Bid"}
                            </button>
                        </div>
                        {
                            submitted &&
                            <div className="mt-3">  
                                <button
                                    className="btn btn-sm  btn-danger btn-block waves-effect waves-light"
                                    type="button"
                                    onClick={this.onDelete}
                                >
                                    Delete Bid
                                </button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>            
        )
    }
}
