import { LOADING_PROFILE, PROFILE_ERROR, SAVE_PROFILE, UPDATE_PROFILE } from "./actionTypes";

const initialState = {
  error: "",
  loading: false
}

const profileReducer = (state=initialState, action) => {
	let profile = {};

  switch(action.type){
    case LOADING_PROFILE:
      return {
        ...state,
        error: "",
        loading: true
      }
      
    case PROFILE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }

    case SAVE_PROFILE:
      profile = action.payload.hasOwnProperty("profile") ? action.payload.profile : action.payload;
      return {
        ...profile,
        error: "",
        loading: false
      }
    
    case UPDATE_PROFILE:
      profile = action.payload.hasOwnProperty("profile") ? action.payload.profile : action.payload;
      return {
        ...state,
        ...profile,
        error: "",
        loading: false
      }
      
    default:
      return state;
  }
}

export default profileReducer;
