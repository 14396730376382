import { DELETE_TUTOR_AVAILABILITY_EVENT, POST_CREATE_TUTOR_AVAILABILITY_EVENT, POST_CREATE_TUTOR_CALENDAR, POST_CREATE_TUTOR_SESSION_EVENT, PUT_UPDATE_TUTOR_AVAILABILITY_EVENT } from '../api/urls';
import { post, put } from "../helpers/api_helper";

export const createTutorCalendar = tutorRefId => {
  return post(POST_CREATE_TUTOR_CALENDAR + "/" + tutorRefId);
}

export const createTutorAvailableEvent = formData => {
  let userId = formData.userId;
  delete formData.userId
  
  return post(POST_CREATE_TUTOR_AVAILABILITY_EVENT + "/" + userId, formData);
}

export const updateTutorAvailability = formData => {
  let userId = formData.userId;
  delete formData.userId
  
  return put(PUT_UPDATE_TUTOR_AVAILABILITY_EVENT + "/" + userId, formData);
}

//Delete Availablility
export const deleteTutorAvailability = formData => {
  let userId = formData.userId;
  let eventId = formData.eventId;
  
  return post(DELETE_TUTOR_AVAILABILITY_EVENT + "/" + userId + "/" + eventId);
}

export const createTutorSessionEvent = formData => {
  let userId = formData.userId;
  delete formData.userId
  return post(POST_CREATE_TUTOR_SESSION_EVENT + "/" + userId, formData);
}

export const updateTutorSessionEvent = formData => {
  let userId = formData.userId;
  delete formData.userId
  alert("TODO");
  // return put(PUT_UPDATE_TUTOR_AVAILABILITY_EVENT + "/" + userId, formData);
}

//Delete Tutor Session Event
export const deleteTutorSessionEvent = formData => {
  let userId = formData.userId;
  let eventId = formData.eventId;
  alert("TODO");
  // return post(DELETE_TUTOR_AVAILABILITY_EVENT + "/" + userId + "/" + eventId);
}