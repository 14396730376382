import React, { Component } from 'react'

import GoogleSubmitURLForm from 'components/forms/google-indexing-api/GoogleSubmitURLForm';

import BTNMetaTags from "components/metatags/BTNMetaTags"

class GoogleSubmitBatchURLs extends Component {
    render() {
        return (
            <>
                <BTNMetaTags title="Google Job Management" />

                <h3>Submit Batch URLs To Google</h3>
                <br />
                <br />
                <GoogleSubmitURLForm />
            </>
        )
    }
}

export default GoogleSubmitBatchURLs;