import React, { Component } from 'react'
import moment from "moment"

import { replaceAllLinks } from "scripts/chat";
import { groupSubjectsByParent } from './scripts';

export default class MarketRequestDetailsTable extends Component {

    render() {
        let { marketRequest, isAdmin=false } = this.props
        let subjectParents = [];

        if(marketRequest.subjects && marketRequest.subjects.length > 0){
            subjectParents = groupSubjectsByParent(marketRequest.subjects);
        }

        return (
            <>
                <table className="table table-striped table-sm table-responsive">
                    <tbody>
                        <tr>
                            <td><b>ID: </b></td>
                            <td>{marketRequest.id}{marketRequest.refId && <> / {marketRequest.refId }</>}</td>
                        </tr>
                        {isAdmin &&
                            <tr>
                                <td><b>Request ID: </b></td>
                                <td>{marketRequest.tutorRequestId}</td>
                            </tr>
                        }
                        <tr>
                            <td><b>Course: </b></td>
                            <td>{marketRequest.course} @ {marketRequest.school}</td>
                        </tr>
                        {/* {
                            subjectParents.length > 0 &&
                                <tr>
                                    <td><b>Subjects:</b></td> 
                                    <td>
                                        {
                                            subjectParents.map((parent, i) =>
                                                <span key={"parent-"+i}>
                                                    <b>{parent.name}</b>: {parent.subjects.map(subject => subject.name).join(", ")}<br />
                                                </span>
                                            )
                                        }
                                    </td>
                                </tr>
                        } */}
                        <tr>
                            <td><b>Location: </b></td>
                            <td>
                                {marketRequest.studentLocation}
                                {
                                    marketRequest.distance != null &&
                                        <><br /><small>({marketRequest.distance.toFixed(0)} miles away)</small></>
                                }
                            </td>
                        </tr>
                        {
                            marketRequest.studentTimezone &&
                                <tr>
                                    <td><b>Local Time:</b></td> 
                                    <td>
                                        {moment().tz(marketRequest.studentTimezone).format("LT")}{" "}
                                        ({marketRequest.studentTimezone.replace(/_/g, " ")}) </td>
                                </tr>
                        }
                        <tr>
                            <td><b>Dates: </b></td>
                            <td>{moment(marketRequest.startDate).format("MMM Do")} to {moment(marketRequest.endDate).format("MMM Do YYYY")} *</td>
                        </tr>
                        <tr>
                            <td><b>Preferred Time: </b></td>
                            <td>{marketRequest.sessionTime && replaceAllLinks(marketRequest.sessionTime)}</td>
                        </tr>
                        <tr>
                            <td><b>Frequency: </b></td>
                            <td>{marketRequest.frequency &&replaceAllLinks(marketRequest.frequency)}</td>
                        </tr>
                        {
                            marketRequest.concerns &&
                                <tr className="table-warning">
                                    <td colSpan="2">
                                        <b>Concerns: </b><br />
                                        {replaceAllLinks(marketRequest.concerns)}
                                    </td>
                                </tr>
                            
                        }
                    </tbody>
                </table>
                
                * Dates are estimates
            </>
        )
    }
}
