import React, { Component } from 'react'
import moment from 'moment'

import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar';


import { createTutorSessionEventTitle, createTutorSessionEventTitleAdmin, getStatus } from './scripts'

//REF: https://fullcalendar.io/docs#toc
//Example: https://codesandbox.io/s/full-calendar-react-resize-issue-dcgb5?file=/src/App.tsx:3475-3492

export default class TimeGridCalendar extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        timezone: this.props.timezone || "America/Chicago",
        showTzSelect: false,
        businessHours: []
      }

      this.calendarComponentRef = React.createRef()
    }

    /**
     * Transform event object on load
     */

    eventDataTransform = event => {
        let { admin=false, owner=false } = this.props;

        let type = "";

        if(event.extendedProps){
            let extendedProps = event.extendedProps;
            type = extendedProps.type;
        }

        //Event Title
        switch (type) {
            case "session":
                event.title =  admin ? createTutorSessionEventTitleAdmin(event) : createTutorSessionEventTitle(event);                 
                break;

            case "availability":
                if(!owner){
                    event.title = "";
                    event.groupId = "businessHours";
                    event.display = "background";
                    event.groupId = "businessHours";    
                }
                else{
                    event.title = "Available";
                    event.groupId = "availability";
                }

                break;

            case "zoom":
                if(event.title == undefined || event.title == "undefined"){
                    event.title= "Reserved";
                }
                if(!admin){
                    event.title = "";
                    event.display = "inverse-background";
                    event.groupId = "zoom";
                    event.color = "#808080";
                }

                break;
            default:
                break;    
        } 

        return event;     
    }

    /**
     * Modify Events To customize titles & display/css
     */
    eventDidMount = ({event, el}) => {
        let type = "";
        if(event.extendedProps){
            let extendedProps = event.extendedProps;
            type = extendedProps.type;
        }

        if(event.groupId != "businessHours"){
            el.style.backgroundColor = getStatus(event).color;
        }
        
        //Event Title
        switch (type) {
            case "session":
                //Recurring Event Border
                if(event.id.includes("_")){
                    el.style.border = "solid 2px orange"
                    el.style.borderRadius = "5px"
                }                 
                break;
            case "availability":
                break;
            case "zoom":
            default: 
                break;
        }      
    }

    // removeEvent = eventId => {
    //     let event = this.calendarComponentRef.current?.getApi().getEventById(eventId);
    //     event.remove();
    // }

    /**
     * Handling date/time selected on calendar
     */
    handleDateSelect = arg => {
        let { onDateSelected=null } = this.props;
        const selectedStartDate = arg.startStr;
        const selectedEndDate = arg.endStr;
        
        if(onDateSelected != null){
            onDateSelected({selectedStartDate, selectedEndDate})
        }        
    }

    /**
     * Handling date click on calendar
     */
    handleDateClick = arg => {
        let { onDateSelected=null } = this.props;
        const selectedStartDate = moment(arg.dateStr).toISOString();
        
        if(onDateSelected != null){
            onDateSelected({selectedStartDate, selectedEndDate: null})
        }
    }

    /**
     * Handling click on event on calendar
     */
    handleEventClick = arg => {
        arg.jsEvent.preventDefault(); //prevent navigation to Google Calendar
        let { onEventClicked=null } = this.props;

        if(onEventClicked != null){    
            let event = arg.event
            onEventClicked(event);
        }
    }

    /**
     * On calendar drop event from external draggable element
     */
    onDrop = dropInfo => {
        let { onEventDropped=null } = this.props;
        const element = dropInfo.draggedEl; //The HTML element that was being dragged.
        const title = draggedEl.innerText;
        const selectedStartDate = dropInfo.dateStr; //ISO string
        
        if(onEventDropped != null){
            onEventDropped({title, selectedStartDate, element});
        }
    }

    render() {
        let { googleCalendarId, timezone, calendars=[],    
                allDaySlot=false, handleWindowResize=true, 
                // scrollTime=moment().subtract(2, "hours").format("HH:mm"),
                scrollTime="08:00",
                editable=false, eventDurationEditable=false,
                droppable=false, selectable=true,
                expandRows=true, height=null,

                maxSelectedHours=3 } = this.props;

        let googleCalendarApiKey = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY

        let calendarProps = { allDaySlot, handleWindowResize, scrollTime,
                                editable, eventDurationEditable,
                                droppable, selectable,
                                expandRows, height
                            }
        
        //Events Source(s)
        if(calendars != null && calendars.length > 0){
            calendarProps.googleCalendarId = null;
            calendarProps.eventSources = calendars
        }
        else{
            calendarProps.events = { googleCalendarId }
        }

        return (
            <>
                <FullCalendar
                    {...calendarProps}
                    ref={this.calendarComponentRef}
                    googleCalendarApiKey={googleCalendarApiKey}
                    plugins={[
                                interactionPlugin,
                                timeGridPlugin,
                                googleCalendarPlugin,
                            ]}
                    nowIndicator={true}
                    selectMirror={true}
                    initialView={ window.innerWidth <=768 ? 'timeGridDay' :  'timeGridWeek'}
                    timeZone={timezone}
                    headerToolbar= {{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'timeGridWeek,timeGridDay'
                                }}
                    select={this.handleDateSelect}
                    selectAllow={selectInfo => {var duration = moment.duration(moment(selectInfo.end).diff(moment(selectInfo.start)));
                                                    return duration.asHours() <= maxSelectedHours;}} 
                    eventDidMount= {this.eventDidMount}                                                    
                    dateClick={this.handleDateClick}
                    eventClick={this.handleEventClick}
                    drop={this.onDrop}
                    slotLabelInterval={{ hour: 1 }}
                    slotDuration={{ minute: 15 }}
                    slotEventOverlap={true}

                    eventDataTransform={this.eventDataTransform}
                    // defaultTimedEventDuration={"01:00"} //not working
                    // eventSourceSuccess={info => console.log("source success",info)}
                    // eventSourceFailure={() => alert("Unable to load calendar")}
                />
            </>
        )
    }
}
