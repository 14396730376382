import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Formik } from 'formik';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { ThemeFileUpload } from 'theme/elements/styledInputs';
import { uploadBusinessPostImage } from 'scripts/google-api';


class GoogleCreatePostForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            awsUrl: "",
            file: null,
            fileError: "",
            fileLoading: false,

            cta: false,
            mediaUrl: false
        }
        this.onSubmit.bind(this)
    }

    onChangeFile = event => {
        let file = event.target.files[0];
        let maxMB = 25; //25 MB;
        let maxSize = maxMB * 1024 * 1024; 
    
        if (file.size > maxSize) {
          this.setState({fileError: "File too large.  Max size is " + maxMB + " MB"});
        }
        else{
          this.setState({ file });
        }
    }

    onSubmit = async (values) => {
        let { file } = this.state;
        const { locations =[] } = this.props;

        if((values.mediaUrl == null || values.mediaUrl.trim() == "") && (file == null || file == "")){
            alert("Please attach a media file");
            return;
        }

        let mediaUrl = null;

        //Construct 
        let locationNames = values.locationNames;
        let selectedLocations = [];
        locationNames.forEach(locName => {
            let location = {};
            location.name = locName;

            //Find account Id
            let matchedLocation = locations.filter(loc =>{
                                return loc.name == locName;
                            })[0] || {};

            location.accountId = matchedLocation.accountId;

            selectedLocations.push(location);
        });

        delete values.locationNames;
        values.locations = selectedLocations;

        // Upload Image & Get URL
        if(file != null && file != ""){
            this.setState({fileLoading: true});
            await uploadBusinessPostImage(file)
                    .then(response => {
                        this.setState({awsUrl: response, fileLoading: false});
                        mediaUrl = response;
                        values = {...values, mediaUrl }
                    })
                    .catch(error => { alert(error.response.data)});
        }
        
        this.props.onSubmit(values);
    }

    render() {
        let { apiResponse, locations = [] } = this.props;
        const { awsUrl, fileError, fileLoading } = this.state;

        let loading = apiResponse.loading;
        let error = apiResponse.error;
        
        //End Date
        let endDate =  new Date();
        let addDays = 120;
        endDate.setDate(endDate.getDate() + addDays);

        let locationNames = [];

        locations.forEach(location =>{
            locationNames.push(location.name);
        })

        const defaultValues = {
            type: 'offer',
            topicType: "OFFER",
            mediaFormat: "PHOTO",

            mediaUrl: "",
            actionType: "",
            actionUrl: "",

            startDay: new Date().getDate(),
            startMonth: new Date().getMonth() + 1,
            startYear: new Date().getFullYear(),

            endDay: endDate.getDate(),
            endMonth: endDate.getMonth() + 1,
            endYear: endDate.getFullYear(),

            locationNames: locationNames,

            title: "10% Off First Hour!",
            summary: "New Student Discount! Try out one of our tutors and instantly receive a 10% discount off your first hour. No coupon needed."
          };

        return (
            <>
                {error ? <Alert color="danger">{error}</Alert> : null}

                <Formik
                    initialValues={defaultValues}
                    onSubmit={this.onSubmit}
                >
                    { props  => (

                        <Form className="needs-validation">
                            {
                                locations.length > 0 &&
                                    <Row>
                                        <Col sm="5" style={{border: "solid lightgray 1px", borderRadius: "5px", padding: "1rem", margin: "1rem"}}>
                                            <h3>GMB Locations</h3>
                                            <FormGroup>
                                                <Input 
                                                    type="select" 
                                                    name="locationNames" 
                                                    multiple 
                                                    required
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.locationNames}>
                                                    {
                                                        locations.map((location, index) =>
                                                            <option key={index} value={location.name}>{location.title}</option>
                                                        )
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                            }
                    
                            <Row>
                                <Col sm="5" style={{border: "solid lightgray 1px", borderRadius: "5px", padding: "1rem", margin: "1rem"}}>
                                    <Label>Type</Label>
                                    <FormGroup check>
                                        <Input type="radio" id="offer" name="type" value="offer" required onClick={() => {this.setState({cta: false})}} onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                        <Label for="offer" check>Offer</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Input type="radio" id="cta" name="type" value="cta" onClick={() => {this.setState({cta: true})}} onChange={props.handleChange} onBlur={props.handleBlur} />
                                        <Label for="cta" check>Call To Action</Label>
                                    </FormGroup>

                                    <br />

                                    <Label>Topic Type</Label>
                                    <FormGroup check>
                                        <Input type="radio" id="ttOffer" name="topicType" value="OFFER" required onChange={props.handleChange} onBlur={props.handleBlur}/> 
                                        <Label for="ttOffer" check>Offer (covers both Call To Action & Offers)</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Input type="radio" id="ttEvent" name="topicType" value="EVENT" onChange={props.handleChange} onBlur={props.handleBlur}/>
                                        <Label for="ttEvent" check>Event</Label>
                                    </FormGroup>
                                </Col>

                                <Col sm="5" style={{border: "solid lightgray 1px", borderRadius: "5px", padding: "1rem", margin: "1rem"}}>
                                    <h3>Media</h3>
                                    <FormGroup check>
                                        <Input type="radio" id="photo" name="mediaFormat" value="PHOTO" required onChange={props.handleChange} onBlur={props.handleBlur}/> 
                                        <Label for="photo" check>Photo</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Input type="radio" id="video" name="mediaFormat" value="VIDEO" onChange={props.handleChange} onBlur={props.handleBlur}/>
                                        <Label for="video" check>Video</Label>
                                    </FormGroup>
                                    
                                    <br />

                                    <Row>
                                        <Col sm="8">
                                            <h4>Attach Media</h4>
                                            { this.state.mediaUrl ?
                                                    <div className="form-group">
                                                        Need to upload a file? <a className='text-primary' onClick={() => this.setState({mediaUrl: false})}>Upload file</a>
                                                        <br />
                                                        <Label>Media URL</Label>
                                                        <Input
                                                            type="text"
                                                            name="mediaUrl"
                                                            value={props.values.mediaUrl}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                        />
                                                    </div>
                                                :
                                                    <>
                                                        Have an existing media url? <a className='text-primary' onClick={() => this.setState({mediaUrl: true})}>Type In Media URL</a><br /><br />
                                                        <label>Upload File </label> {" "} 
                                                        <ThemeFileUpload name={"avatar"}
                                                                accept="image/*"
                                                                onChange={this.onChangeFile}
                                                                hideButton={true} />
                                                        {fileError && <small className="text-danger">{fileError}</small>}

                                                        <br />
                                                        {awsUrl && <a href={awsUrl}>{awsUrl}</a>}
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                {
                                    this.state.cta &&
                                        <Col sm="5" style={{border: "solid lightgray 1px", borderRadius: "5px", padding: "1rem", margin: "1rem"}}>
                                            <Label>Action Type (CTA)</Label>
                                            <FormGroup check>
                                                <Input type="radio" id="learn" name="actionType" value="LEARN_MORE" required onChange={props.handleChange} onBlur={props.handleBlur}/> 
                                                <Label for="learn" check>Learn More</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" id="sign" name="actionType" value="SIGN_UP" onChange={props.handleChange} onBlur={props.handleBlur}/>  
                                                <Label for="sign" check>Sign Up</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" id="book" name="actionType" value="BOOK" onChange={props.handleChange} onBlur={props.handleBlur}/> 
                                                <Label for="book" check>Book</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" id="order" name="actionType" value="ORDER" onChange={props.handleChange} onBlur={props.handleBlur}/> 
                                                <Label for="order" check>Order</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" id="shop" name="actionType" value="SHOP" onChange={props.handleChange} onBlur={props.handleBlur}/> 
                                                <Label for="shop" check>Shop</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" id="call" name="actionType" value="CALL" onChange={props.handleChange} onBlur={props.handleBlur}/>  
                                                <Label for="call" check>Call</Label>
                                            </FormGroup>

                                            <br />
                                            <div className="form-group">
                                                <Label>Action URL</Label>
                                                <Input
                                                    type="text"
                                                    name="actionUrl"
                                                    value={props.values.actionUrl}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                }
                                
                            </Row>
                            
                            <Row >
                                <Col sm="5" style={{border: "solid lightgray 1px", borderRadius: "5px", padding: "1rem", margin: "1rem"}}>
                                    <h3>Post Text</h3>
                                    <div className="form-group">
                                        <Label for="title">Title</Label>
                                        <Input
                                            type="text"
                                            id="title"
                                            name="title"
                                            value={props.values.title}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Label for="summary">Summary</Label>
                                        <Input
                                            type="textarea"
                                            id="summary"
                                            name="summary"
                                            value={props.values.summary}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                    </div>
                                </Col>
                                <Col sm="5" style={{border: "solid lightgray 1px", borderRadius: "5px", padding: "1rem", margin: "1rem"}}>
                                    <h3>Dates (MM/DD/YYYY)</h3>
                                    <Row>
                                        <Col xs={4}>
                                            <div className="form-group">
                                                <Label for="startMonth">Start Month</Label>
                                                <Input
                                                    type="number"
                                                    id="startMonth"
                                                    name="startMonth"
                                                    min={1}
                                                    max={12}
                                                    value={props.values.startMonth}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="form-group">
                                                <Label for="startDay">Start Day</Label>
                                                <Input
                                                    type="number"
                                                    id="startDay"
                                                    name="startDay"
                                                    min={1}
                                                    max={31}
                                                    value={props.values.startDay}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </Col>    
                                        <Col xs={4}>
                                            <div className="form-group">
                                                <Label for="startYear">Start Year</Label>
                                                <Input
                                                    type="number"
                                                    id="startYear"
                                                    name="startYear"
                                                    min={new Date().getFullYear()}
                                                    max={(new Date().getFullYear()) + 1}
                                                    value={props.values.startYear}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <div className="form-group">
                                                <Label for="endMonth">End Month</Label>
                                                <Input
                                                    type="number"
                                                    id="endMonth"
                                                    name="endMonth"
                                                    min={1}
                                                    max={12}
                                                    value={props.values.endMonth}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="form-group">
                                                <Label for="endDay">End Day</Label>
                                                <Input
                                                    type="number"
                                                    id="endDay"
                                                    name="endDay"
                                                    min={1}
                                                    max={31}
                                                    value={props.values.endDay}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="form-group">
                                                <Label for="endYear">End Year</Label>
                                                <Input
                                                    type="number"
                                                    id="endYear"
                                                    name="endYear"
                                                    min={new Date().getFullYear()}
                                                    max={(new Date().getFullYear()) + 1}
                                                    value={props.values.endYear}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col sm={5}>
                                    <div className="mt-3">
                                        {
                                        loading
                                            ?
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={true}
                                            >
                                                Posting ... {" "}
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            
                                            </button>
                                            :
                                            <>
                                            {
                                                fileLoading ? 
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                        disabled={true}
                                                    >
                                                        Uploading Media ... {" "}
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    
                                                    </button>
                                                :
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Submit Post
                                                    </button>
                                            }
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})
  
export default connect(mapStateToProps, null)(GoogleCreatePostForm)