import React, { Component } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export default class ThemeSkeleton extends Component {
  render() {
    let { count=5, circle=false, width="10vh", height="10vh", lineWidth=null, lineHeight=null} = this.props;
    return (
        <>
            <Skeleton count={circle ? 1 : count}
                circle={circle} 
                width={circle ? width: lineWidth} 
                height={circle ? height : lineHeight}/>  
        </>
    )
  }
}
