import React from "react"

export const ThemeBadge = (props) => {
    return (
        <span className={`badge bg-${props.color || "primary"} ${props.pill ? "rounded-pill" : ""} `}>
            {props.children}
        </span>
    )
}

export const ThemeSoftBadge = (props) => {
    return (
        <span className={`badge badge-soft-${props.color || "primary"} ${props.pill ? "rounded-pill" : ""}`}>
            {props.children}
        </span>
    )
}