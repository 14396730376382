import React, { Component } from 'react'
import { Form, Formik } from 'formik';
import { Col, Input, Label, Row } from 'reactstrap';

export default class CreateMarketRequestForm extends Component {
    onSubmit = values => {
        if(values.concerns == null || values.concerns == "" || values.concerns.trim() == ""){
            values.concerns = null;
        }

        if(values.adminNotes == null || values.adminNotes == "" || values.adminNotes.trim() == ""){
            values.adminNotes = null;
        }

        this.props.onSubmit(values);
    }

  render() {
    let { maxRate, concerns, adminNotes, exclusiveCount=0, bannedCount=0, loading } = this.props;
    let initialValues = { minRate: 12, 
                        maxRate: Math.ceil(maxRate * 0.8 / 5) * 5,
                        concerns, adminNotes }

    return (
        <Formik
            initialValues={{...initialValues}}
            onSubmit={this.onSubmit}
        >
            { props  => (
                <Form className="needs-validation">
                    <Row>
                        <Col xs="6">
                            <Input 
                                type="number" 
                                name={"minRate"} 
                                label="Min Tutor Earnings"
                                value={props.values.minRate}
                                className="form-control"
                                placeholder="minRate"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur} />
                        </Col>
                        <Col xs="6">
                            <Input 
                                type="number" 
                                name={"maxRate"} 
                                label="Max Tutor Earnings"
                                value={props.values.maxRate}
                                className="form-control"
                                placeholder="minRate"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur} />
                        </Col>
                    </Row>
                    *** Rate = Min / Max Tutor Earnings
                    <br /><br />
                    {/* <Input 
                        type="number" 
                        name={"proposalsLimit"} 
                        label="Proposals Limit"
                        value={10}
                        className="form-control"
                        placeholder="Max Proposals" /> */}

                    {/* <Row>
                    <Col sm="12">
                        
                        <Label check>
                            <Input type="checkbox" name="exclusive" label="Exclusive" trueValue={true} onClick={event => {this.setState({exclusive: !exclusive })}} />
                            <span style={{fontSize: "1rem"}}>Exclusive</span> (Add tutors below BEFORE submitting form)
                        </Label>
                    </Col>
                    </Row>
                    <br /> */}
                    <Row>
                        <Col sm="6">
                            <Label>Additional Comments (Optional)</Label>
                            <Input
                                name="concerns"
                                value={props.values.concerns}
                                className="form-control"
                                placeholder="Concerns"
                                type="textarea"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </Col>
                        <Col sm="6">
                            <Label>Admin Notes</Label>
                            <Input
                                name="adminNotes"
                                value={props.values.adminNotes}
                                className="form-control"
                                placeholder="Admin Notes"
                                type="textarea"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </Col>
                    </Row>
                    <br />
                    <button
                        className="col-md-6 col-offset-md-6 btn btn-sm btn-info btn-block waves-effect waves-light"
                        type="submit"
                        disabled={loading}
                    >
                        {exclusiveCount > 0 ? <>{`Submit Exclusive Tutors (${exclusiveCount})`}</> : <>Submit {bannedCount > 0 && <>{`(${bannedCount} Tutors Banned)`}</>}</>}
                    </button>
                </Form>
            )}
      </Formik>
    )
  }
}

