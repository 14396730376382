import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as DocumentsSvg } from "theme-chat/assets/media/icons/documents.svg";
import { ReactComponent as ProfileDocumentsSvg } from "theme-chat/assets/media/icons/profiledocuments.svg";

export default class ChatDocuments extends Component {
    //document: { fileName, fileSize, url }
    constructor(props) {
        super(props)
        this.state = {
          showDocuments: true,
        }
    } 

    toggleShowDocuments = () => {
        this.setState({ showDocuments: !this.state.showDocuments });
    };

    render() {
        let { documents } = this.props;
        let { showDocuments } = this.state;

        return (
            <div className="chat-info-group">
                <Link
                    className="chat-info-group-header"
                    data-toggle="collapse"
                    to="#"
                    onClick={this.toggleShowDocuments}
                >
                    <h6 className="mb-0">Documents</h6>
                    <DocumentsSvg className="hw-20 text-muted" />
                </Link>

                <div
                    className={
                        "chat-info-group-body collapse " +
                        (showDocuments ? "show" : "")
                    }
                    id="shared-files"
                >
                    <div className="chat-info-group-content list-item-has-padding">
                        <ul className="list-group list-group-flush">
                        {
                            documents.map((document, j) => 
                            <li key={"doc" + j} className="list-group-item">
                                <div className="document">
                                    <div className="btn btn-primary btn-icon rounded-circle text-light mr-2">
                                        <ProfileDocumentsSvg />
                                    </div>

                                    <div className="document-body">
                                        <h6 className="text-truncate">
                                        <a href={document.url} target="_blank" rel="noreferrer">
                                            {document.fileName}
                                        </a>
                                        </h6>
                                        <ul className="list-inline small mb-0">
                                            <li className="list-inline-item">
                                                <span className="text-muted">{document.fileSize}</span>
                                            </li>
                                            {/* <li className="list-inline-item">
                                                <span className="text-muted text-uppercase">
                                                docs
                                                </span>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            )
                        }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
