import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardSubtitle, Col, Row } from 'reactstrap';

import { USER_TUTOR_SESSIONS, TUTOR_PROFILE, TUTOR_EVALUATIONS,
            PROFICIENCY_EXAM_LIST, TUTOR_LOG_INTO_STRIPE, TUTOR_CHECKLIST, TUTOR_TAX_INFO, TUTOR_MARKETPLACE } from 'routes/paths';

import UserCard from 'components/cards/user-card/UserCard';
import AssignmentsTable from 'components/tables/AssignmentsTable';
import MiniCard from 'components/cards/MiniCard';
import { ThemeSpinner } from 'theme/elements/spinner';

import { triggerReducerLoadingAction, submitApiCallAction, submitApiCallWithReducerAction } from "store/actions"

import TutorSessionsTable from 'components/tables/tutor-sessions/TutorSessionsTable';

import { TUTOR_ADD_RETURNING_STUDENT } from 'routes/paths';
import { LOAD_TUTOR_CONTRACT, TUTOR_CONTRACT_ERROR } from 'store/tutorContracts/actionTypes';
import { LOAD_TUTOR_SESSIONS, TUTOR_SESSION_ERROR } from 'store/tutorSessions/actionTypes';
import { ASSIGNMENT_ERROR, LOAD_ASSIGNMENTS, SAVE_ASSIGNMENTS } from 'store/assignments/actionTypes';
import { LOADING_USER, SAVE_USER, USER_ERROR } from 'store/user/actionTypes';

class TutorDashboard extends Component {
    componentDidMount() {
        let { onReducerLoading, onSubmitApiCallWithReducer, user } = this.props;
        
        onReducerLoading(LOAD_TUTOR_CONTRACT);
        onSubmitApiCallWithReducer("getTutorContractsForTutor", "SAVE_MULTIPLE_TUTOR_CONTRACTS", user.id, TUTOR_CONTRACT_ERROR);
    
        onReducerLoading(LOAD_TUTOR_SESSIONS);
        onSubmitApiCallWithReducer("getRecentTutorSessionsForTutor", "SAVE_RECENT_TUTOR_SESSIONS", user.id, TUTOR_SESSION_ERROR);
        
        this.loadAssignments();
    }

    updateTutorStatus = status => {
        const { user, onReducerLoading, onSubmitApiCallWithReducer } = this.props;

        onReducerLoading(LOADING_USER);
        onSubmitApiCallWithReducer("updateProfileStatus", SAVE_USER, {userId: user.id, status}, USER_ERROR);
    }
    
    loadAssignments = () => {
        let { onReducerLoading, onSubmitApiCallWithReducer, user } = this.props;
        onReducerLoading(LOAD_ASSIGNMENTS);
        onSubmitApiCallWithReducer("getAssignmentsForTutor", SAVE_ASSIGNMENTS, user.id, ASSIGNMENT_ERROR);
      }

    render() {
        let { user, assignmentsReducer={}, tutorContractsReducer={}, tutorSessionsReducer={} } = this.props //reducers
        
        let tutorContracts = tutorContractsReducer.tutorContracts || [];
        let tutorContractsLoading = tutorContractsReducer.loading || false;
        let recentSessions = tutorSessionsReducer.recentSessions || [];
        let recentSessionsLoading = tutorSessionsReducer.loading || false;
        let assignments = assignmentsReducer.assignments || [];
        let assignmentsLoading = assignmentsReducer.loading || false;
        
        return (
            <>
                <Row>
                    <Col lg="3" md="5" sm="6">
                        {
                            user.loading &&
                            <center><ThemeSpinner /></center>
                        }
                        <UserCard user={user}>
                            {
                                user.profileActive === false &&
                                    <>
                                        <span className="badge badge-soft-danger">Profile Not Active</span>
                                        <br />
                                        <br />
                                    </>
                            }
                            <Link to={TUTOR_PROFILE + "/" + user.id} className="btn btn-outline-primary btn-sm me-2 w-md" >
                                View Profile 
                            </Link>
                            <br />
                            <br />
                            <Link to={TUTOR_CHECKLIST}>
                                    Tutor Checklist
                            </Link>
                            
                            <Link to={TUTOR_TAX_INFO} className="ml-4">
                                    Taxes
                            </Link>
                            
                        </UserCard>
                    </Col>
                    <Col lg="4" sm="6">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4 h4">My Tutor Requests</CardTitle>
                                <div className="text-center">
                                <div className="mb-3">
                                    <i className="bx bx-laptop text-info display-4" style={{ lineHeight : '1'}}/>
                                </div>
                                </div>
                                <CardSubtitle><center>Review and respond to tutor requests.</center></CardSubtitle>
                                <br />
                                <center><Link to={TUTOR_MARKETPLACE + "/" + user.id} className="btn btn-outline-info">New Tutor Requests</Link></center>
                                <br />
                                {
                                    tutorContracts.map((tutorContract, key) =>
                                        <div key={key}>
                                            <li>
                                                {tutorContract.course} {" "}
                                                {
                                                    tutorContract.tutorDecision == 0 &&
                                                        <span className="badge badge-soft-danger">Declined</span>
                                                }
                                                {
                                                    tutorContract.tutorDecision == 1 &&
                                                        <span className="badge badge-soft-success">Accepted</span>
                                                }
                                            </li>
                                        </div>
                                    )
                                } 
                                {/* {
                                    tutorContractsLoading&&
                                        <center><ThemeSpinner /></center>
                                } */}
                                <p className="text-muted">Want To Tutor More Courses? Pass <Link to={PROFICIENCY_EXAM_LIST}>Profiency Exams</Link></p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="5" sm="6">
                        <MiniCard icon={"bx-star"} title={"Evaluations"} text={"Reviews & References"}> 
                            <div className="mt-4 mt-sm-0">
                                <br /><br />
                                <div className="text-center">
                                    <Link to={TUTOR_EVALUATIONS} className="btn btn-outline-secondary btn-sm me-2 w-md">View Evaluations</Link>
                                </div>
                            </div>
                        </MiniCard>
                        <MiniCard icon={"bx-dollar"} title={"Earnings"} text={"View Payments"}> 
                            <div className="mt-4 mt-sm-0">
                                <br /><br />
                                <div className="text-center">
                                    <Link to={TUTOR_LOG_INTO_STRIPE} className="btn btn-outline-secondary btn-sm me-2 w-md">Log Into Stripe</Link>
                                </div>
                            </div>
                        </MiniCard>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h4>My Students</h4>
                        <p>Click your student's name to view the contact information.</p>
                        <p>Resuming sessions with previous students?  Great!  Click To <a href={TUTOR_ADD_RETURNING_STUDENT}>Add Returning Students</a>.</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5} md={5}>
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">Assignments &nbsp;&nbsp;<small><a onClick={this.loadAssignments} className="text-primary">Reload</a></small></CardTitle>
                                {
                                    assignmentsLoading &&
                                        <center><ThemeSpinner /></center>  
                                }
                                <AssignmentsTable key={"assign-" + assignments.length} assignments={assignments}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={7} md={7}>
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4 h4">Recent Tutor Sessions</CardTitle>
                                <CardSubtitle>
                                    To view all sessions & more details, <Link to={USER_TUTOR_SESSIONS + "/" + user.id}>click here</Link>.
                                </CardSubtitle>
                                <br />
                                <p>You have <b>1 hour</b> to cancel (<i className="bx bx-x-circle"/>) a pending charge and <b>24 hours</b> to delete (<i className="bx bx-trash"/>) a charged session</p>
                                {/* <small><i className="bx bx-trash" /> = Delete Session</small> */}
                                {
                                    recentSessionsLoading &&
                                        <center><ThemeSpinner /></center>
                                }
                                <TutorSessionsTable key={"sessions-" + recentSessions.length} role={"tutor"} tutorSessions={recentSessions} paginate={false}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>  

            </>
        )
    }
}

const mapStateToProps = ({ assignmentsReducer, tutorContractsReducer, tutorSessionsReducer, userReducer }) => ({
    assignmentsReducer,
    tutorContractsReducer,
    tutorSessionsReducer,
    user: userReducer
  })
  
  const mapDispatchToProps = dispatch => ({
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(TutorDashboard)