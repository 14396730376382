import React, { Component } from "react"
import { Line } from "react-chartjs-2"

export default class LineChart extends Component {
    render() {
        const { labels, datasets, chartTitle=""} = this.props;   
        const data = { labels, datasets }

        var options = {
            plugins: {
                title: {
                    display: true,
                    text: chartTitle
                }
            }
        }

        return (
            <React.Fragment>
                <Line width={474} height={300} data={data} options={options} />
            </React.Fragment>
        )
    }
}
