import React from "react"
import { UncontrolledTooltip } from "reactstrap"

export const DeletePaymentAction = (props) => {
    //props: id, color, onClick
    return (
        <a id={"delete-payment-" + props.id} className={`text-${props.color || "danger"}`} onClick={props.onClick}>
            <i className="bx bx-trash" />
            <UncontrolledTooltip
                placement="top"
                target={"delete-payment-" + props.id}
            >
            Delete Payment #{props.id}
            </UncontrolledTooltip>
        </a>
    )
}

export const ReverseTransferAction = (props) => {
    //props: id, color, onClick
    return (
        <a id={"reverse-transfer-" + props.id} className={`text-${props.color || "danger"}`} onClick={props.onClick}>
            <i className="bx bx-undo" />
            <UncontrolledTooltip
                placement="top"
                target={"reverse-transfer-" + props.id}
            >
            Reverse Transfer #{props.id} (TODO)
            </UncontrolledTooltip>
        </a>
    )
}