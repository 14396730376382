import {
    RESET_API_REDUCER,
    SUBMIT_API_CALL,
    SAVE_API_RESPONSE,
    SAVE_API_ERROR,

    SUBMIT_API_CALL_WITH_REDUCER_ACTION,
  } from "./actionTypes"

  export const triggerReducerLoadingAction = actionType => {
    return {
      type: actionType
    }
  }

  export const resetApiReducerAction = () => {
    return {
      type: RESET_API_REDUCER,
    }
  }

  export const submitApiCallAction = (apiMethod, apiData=null, alertType="info") => ({
    //toast alert types: info, warning, error.  (default = success)
    type: SUBMIT_API_CALL,
    method: apiMethod,
    alertType: alertType,
    data: apiData
  })

  export const submitApiCallWithReducerAction = (apiMethod, reducerAction, apiData=null, errorAction=null, alertType="info") => ({
    type: SUBMIT_API_CALL_WITH_REDUCER_ACTION,
    actionType: reducerAction,
    method: apiMethod,
    alertType: alertType,
    data: apiData,
    errorAction
  })

  export const saveApiResponseAction = response => {
    return {
      type: SAVE_API_RESPONSE,
      payload: response
    }
  }

  export const saveApiErrorAction = error => {
    return {
      type: SAVE_API_ERROR,
      error
    }
  }

  export const saveApiReducerAction = (apiResponse, actionType) => {
    return {
      type: actionType,
      payload: apiResponse
    }
  }

  export const saveApiReducerErrorAction = (actionType, error) => {
    return {
      type: actionType,
      error
    }
  }
