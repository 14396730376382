import Pusher from 'pusher-js';
//import Pusher from 'pusher-js/with-encryption';

Pusher.logToConsole = process.env.NODE_ENV === "production" ? false : true;

export const pusher = (userId) => {
    const token = localStorage.getItem("btnToken");

    return new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                    app_id: process.env.REACT_APP_PUSHER_APP_ID,
                    secret: process.env.REACT_APP_PUSHER_SECRET,
                    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                    encrypted: true,
                    useTLS: true,
                    authEndpoint: process.env.REACT_APP_API_URL + "/pusher/auth",
                    auth: {
                        params: {userId},
                        headers: {
                            'Authorization': token,
                        },
                    },
                    userAuthentication: {
                        endpoint: process.env.REACT_APP_API_URL + "/pusher/user-auth",
                        transport: "ajax",
                        params: {
                            userId,
                            // token
                        },
                        headers: {
                            'Authorization': token,
                        },
                        customHandler: null,
                    }

                    //   channelAuthorization: {
                    //     params: {
                    //       param1: 'value1',
                    //       param2: 'value2'
                    //     },
                    //     headers: {
                    //       header1: 'value1',
                    //       header2: 'value2'
                    //     }
                    //   }
                })
};