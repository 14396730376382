import React, { Component } from 'react'
import { connect } from "react-redux"
import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import AssignmentsAdminTable from 'components/tables/assignments/AssignmentsAdminTable'
import { Card, CardBody, CardTitle, Container } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

class AssignmentsAdmin extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             assignments: []
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;

        onSubmitApiCall("getActiveAssignments");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    countBTNLocations = assignments => {
        let btnLocations = [];
        assignments.map(assignment => {
            let exists = false;
            
            btnLocations.every(location => {
                if(location.name == assignment.btnLocation){
                    location.count += 1;
                    location.balance += assignment.balance;
                    exists = true;
                    return false
                }
                return true;
            });

            if(!exists){
                let newLocation = {name: assignment.btnLocation, count: 1, balance: assignment.balance};
                btnLocations.push(newLocation);
            }
        });

        return btnLocations;
    }

    render() {
        let { assignments } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;
        
        let totalBalance = 0;
        assignments.forEach(assign => {
            if(!assign.scholarship){
                totalBalance += assign.balance;
            }
        });

        let btnLocations = assignments.length > 0 ? this.countBTNLocations(assignments) : [];

        return (
            <>
                <BTNMetaTags title={`Assignments`} />
                
                <Container fluid>
                    <Card>                        
                        <CardBody>
                            <CardTitle>Active Assignments <br />
                                Balance: <span className="text-success">${totalBalance.toFixed(2)}</span>
                            </CardTitle>
                            {
                                loading &&
                                    <center><ThemeSpinner /></center>
                            }
                            {
                                btnLocations.sort((a,b) => { return a.count < b.count})
                                            .map((btnLocation, i) => 
                                                <li key={i}>{btnLocation.name}: {btnLocation.count} Assignments - <span className={btnLocation.balance < 0 ? "text-danger" : undefined}>${btnLocation.balance}</span></li>
                                            )
                            }
                            <br />
                            <AssignmentsAdminTable key={"assign-" + assignments.length} assignments={assignments}/>
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsAdmin)
