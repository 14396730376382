import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from "reactstrap"

import { STUDENT_TUTOR_REQUEST_PROGRESS, STUDENT_TUTOR_REQUESTS, STUDENT_REQUEST_TUTOR, USER_TUTOR_SESSIONS, STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS } from "routes/paths"
import { triggerReducerLoadingAction, submitApiCallWithReducerAction, submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import TutorAssignmentCard from "components/cards/TutorAssignmentCard"
import UserCard from "components/cards/user-card/UserCard"
import MiniCard from 'components/cards/MiniCard';
import TutorSessionsTable from "components/tables/tutor-sessions/TutorSessionsTable"
import { ThemeSpinner } from "theme/elements/spinner"
import { LOAD_TUTOR_SESSIONS, SAVE_RECENT_TUTOR_SESSIONS, TUTOR_SESSION_ERROR } from "store/tutorSessions/actionTypes"
import { ASSIGNMENT_ERROR, LOAD_ASSIGNMENTS, SAVE_ASSIGNMENTS, SAVE_PREVIOUS_ASSIGNMENTS } from "store/assignments/actionTypes"

class StudentDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tutorRequestsSummary: [],
    }
    this.onRequestBalanceRefund.bind(this);
    this.loadAssignments.bind(this);
    this.loadPreviousAssignments.bind(this);
  }

  componentDidMount() {
    let { onReducerLoading, onSubmitApiCallWithReducer, user, onSubmitApiCall } = this.props;

    // onReducerLoading("LOAD_TUTOR_REQUESTS");
    // onSubmitApiCallWithReducer("getAccountStudentRequestsSummary", "SAVE_TUTOR_REQUESTS_SUMMARY", user.id);
    onSubmitApiCall("getAccountStudentRequestsSummary", user.id);
    
    onReducerLoading(LOAD_TUTOR_SESSIONS);
    onSubmitApiCallWithReducer("getRecentTutorSessionsForStudent", SAVE_RECENT_TUTOR_SESSIONS, user.id, TUTOR_SESSION_ERROR);

    this.loadAssignments();
  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;
    
    if(prevProps != this.props){
        let newState = processApiResponse(apiResponse, this.state);

        if(apiResponse != {} && prevState != newState){
            this.setState(newState);
        }
    }
  } 

  onRequestBalanceRefund = tutorContractId => {
    const { onSubmitApiCall } = this.props;
    onSubmitApiCall("postRequestBalanceRefund", tutorContractId);
  }

  loadAssignments = () => {
    let { onReducerLoading, onSubmitApiCallWithReducer, user } = this.props;
    onReducerLoading(LOAD_ASSIGNMENTS);
    onSubmitApiCallWithReducer("getAssignmentsForStudent", SAVE_ASSIGNMENTS, user.id, ASSIGNMENT_ERROR);
  }

  loadPreviousAssignments = () => {
    let { onReducerLoading, onSubmitApiCallWithReducer, user } = this.props;
    onReducerLoading(LOAD_ASSIGNMENTS);
    onSubmitApiCallWithReducer("getPreviousAssignmentsForStudent", SAVE_PREVIOUS_ASSIGNMENTS, user.id, ASSIGNMENT_ERROR);
  }

  render() {
    let { user, assignmentsReducer={}, tutorSessions={}, apiResponse } = this.props //reducers
    let { tutorRequestsSummary } = this.state;
    
    let recentSessions = tutorSessions.recentSessions || [];
    let recentSessionsLoading = tutorSessions.loading || false;
    let assignments = assignmentsReducer.assignments || [];
    let previousAssignments = assignmentsReducer.previousAssignments || [];
    let assignmentsLoading = assignmentsReducer.loading || false;

    let loading = apiResponse.loading

    let showGoogleReview = assignments.length > 0 || previousAssignments.length > 0 ? true : false;

    return (
      <React.Fragment>
        <Row>
          <Col xl="3" sm="3">
            <UserCard user={user} / >
          </Col>
          <Col xl="4" sm="5">
            <Card>
              <CardBody>
                <CardTitle className="mb-4 h4">My Tutor Request Progress</CardTitle>
                <div className="text-center">
                  <div className="mb-3">
                    <i className="bx bx-laptop text-info display-4" style={{ lineHeight : '1'}}/>
                  </div>
                </div>
                <CardSubtitle>Review and select tutors to work with.</CardSubtitle> 
                {
                  tutorRequestsSummary.map((tutorRequest, key) =>
                    <div key={key}>
                      <li>
                        <Link to={(tutorRequest.hasMarketRequest ? STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS : STUDENT_TUTOR_REQUEST_PROGRESS) + "/" + tutorRequest.id}>
                          {tutorRequest.course}
                        </Link>
                            {
                              tutorRequest.potentialTutorsCount > 0 &&
                                <small>{" - "}{tutorRequest.potentialTutorsCount} Tutor(s)</small>
                            }
                            {
                              tutorRequest.tutorContractsCount > 0 &&
                                <small>{" - "}{tutorRequest.tutorContractsCount} Proposal(s)</small>
                            }
                            {
                                tutorRequest.marketProposalsCount > 0 &&
                                <small>{" - "}{tutorRequest.marketProposalsCount} Proposal(s)</small>
                            }
                      </li>
                    </div>
                  )
                } 
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                } 
                <br />
                <small><Link to={STUDENT_TUTOR_REQUESTS + "/" + user.id}>View All Requests</Link></small>
                <br />
                <br />
                <small className={"text-muted"}>Need Another Tutor? <Link to={STUDENT_REQUEST_TUTOR} >Request A Tutor</Link> Today!</small>
              </CardBody>
            </Card>
          </Col>
          {
            showGoogleReview && user.googleReviewUrl &&
              <Col xl="4" sm="4">
                <Card>
                  <CardBody>
                    <center><CardTitle className="mb-2 h4">Google Review</CardTitle></center>
                    <div className="text-center">
                      <div className="mb-2">
                        <i className="bx bxs-star text-warning" style={{ lineHeight : '1'}}/>
                        <i className="bx bxs-star text-warning" style={{ lineHeight : '1'}}/>
                        <i className="bx bxs-star text-warning" style={{ lineHeight : '1'}}/>
                        <i className="bx bxs-star text-warning" style={{ lineHeight : '1'}}/>
                        <i className="bx bxs-star text-warning" style={{ lineHeight : '1'}}/>
                      </div>
                    </div>
                    <center>Did we exceed your expectations? Please leave us a 5-star review on Google!</center>
                    <div className="mt-4">
                        <div className="text-center">
                            <a href={user.googleReviewUrl} target="_blank" className="btn btn-outline-primary btn-sm me-2 w-md" rel="noreferrer">Leave A Review</a>
                        </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
          }
        </Row>

        <br />
        
        <hr />
        <Row>
          <Col md={12}>
            <h4>My Tutors</h4>
            Click your tutor's name to view the contact information.
            <p>
              <small>
                <i className="bx bx-envelope" /> = Send Email {" "}
                <i className="bx bx-phone" /> = Send Text {" "}
                <i className="bx bx-user-circle" /> = View Tutor Profile {" "}
                <i className="bx bx-dollar" /> = Charges & Refunds {" "}
                <i className="bx bx-star" /> = Evaluate Tutor 
              </small>
            </p>
            <p><small>Contact information missing? To reload the information <a className="text-primary" onClick={this.loadAssignments}>click here</a>  or reload the page.<br />
              Looking for previous assignments? <a className="text-primary" onClick={this.loadPreviousAssignments}>Load Previous Tutors</a>
            </small></p>
          </Col>
        </Row>
        <Row> 
          <Col lg={3} sm={4}>
            {
              assignments.length > 0 && assignmentsLoading &&
                <center><ThemeSpinner /></center>  
            }
          </Col>
        </Row>
        <Row> 
          {
            assignments.length > 0 
              ?
                assignments.map((assignment, index) =>
                  <Col key={"assign-col-" + index} lg={3} sm={4}>
                    <TutorAssignmentCard key={"assign-" + index} assignment={assignment} user={user} onRequestBalanceRefund={this.onRequestBalanceRefund} />
                  </Col>
                )
              :
                <Col lg={3} sm={4}>
                  <Card>
                    <CardBody>
                        <center><b>No Active Assignments  </b></center>
                        {
                          assignmentsLoading &&
                            <center><ThemeSpinner /></center>  
                        }
                    </CardBody>
                  </Card>
                </Col>
            }
            {
              previousAssignments.length > 0 &&
                previousAssignments.map((assignment, index) =>
                      <Col key={"pA-col-" + index} lg={3} sm={4}>
                        <TutorAssignmentCard key={"prevAssign-" + index} assignment={assignment} user={user} onRequestBalanceRefund={this.onRequestBalanceRefund} ended={true} />
                      </Col>
                    )
            }

            <Col xl={6} sm={8}>
              <Card>
                <CardBody>
                    <CardTitle className="mb-4 h4">Recent Tutor Sessions</CardTitle>
                    <CardSubtitle>
                      To view all sessions & more details, <Link to={USER_TUTOR_SESSIONS + "/" + user.id}>click here</Link>.
                    </CardSubtitle>
                    <small><i className="bx bx-undo" /> = Refund Session</small>
                    {
                      recentSessionsLoading &&
                          <center><ThemeSpinner /></center>
                    }
                    <TutorSessionsTable key={"sessions-" + recentSessions.length} role={"student"} tutorSessions={recentSessions} paginate={false} />
                </CardBody>
              </Card> 
            </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ assignmentsReducer, tutorRequestsReducer, tutorSessionsReducer, userReducer, apiResponseReducer }) => ({
  assignmentsReducer,
  tutorRequests: tutorRequestsReducer,
  tutorSessions: tutorSessionsReducer,
  user: userReducer,

  apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
  onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
  onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard)