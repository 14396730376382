import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom';

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardBody, Col, Row } from 'reactstrap';
import { ThemeSpinner } from 'theme/elements/spinner';
import ThemeAvatar from 'theme/images/ThemeAvatar';

class ScholarshipMatchConfirmation extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tutorContractId: props.match.params.tutorContractId, 
            tutorContract: {},

            submitted: false,
            redirect: false
        }
    }

    componentDidMount() {
        let { tutorContractId } = this.state;
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorContractForStudent", tutorContractId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                if(apiResponse.success && this.state.submitted){
                    this.setState({redirect: true});
                }
                else{
                    this.setState(newState);
                }
            }
        }
    }
    
    createScholarshipAssignment = async () => {
        const { onSubmitApiCall  } = this.props;
        const { tutorContractId } = this.state;
        this.setState({submitted: true});

        let formData = { decision: true, id: tutorContractId };
        await onSubmitApiCall("submitContractStudentDecision", formData);
    }

    render() {
        const { redirect, tutorContract } = this.state;
        const { apiResponse } = this.props;
        const loading = apiResponse.loading;

        return (
            <>
                {
                    redirect &&
                        <Redirect to="/" />
                }
                <BTNMetaTags title="Scholarship Assignment Confirmation" />
                
                {
                    loading &&
                    <center><ThemeSpinner /></center>
                }

                {
                    tutorContract.tutorName != null &&
                        <>
                            <Row>
                                <Col xl={{size: 6, offset:  3}} md={{size: 10, offset: 1}}>
                                    <Card>
                                        <CardBody>
                                            <Row>                                        
                                                <Col sm={{size: 4, offset:  2}} xs="4">
                                                    <div className="text-lg-center">
                                                        <ThemeAvatar avatar={tutorContract.tutorAvatar} name={tutorContract.tutorName} size={"md"}/>
                                                    </div>
                                                </Col>
                                                <Col sm={{size: 6}} xs="8">
                                                    <h5 className="text-truncate mb-4 mb-lg-1">
                                                        {tutorContract.tutorName} <br />
                                                        {tutorContract.course}<br />
                                                    
                                                        <span className="font-size-15 text-muted">
                                                            {tutorContract.education}<br />
                                                            {tutorContract.major}
                                                        </span>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={{size: 6, offset:  3}} md={{size: 10, offset: 1}}>
                                    <Card>
                                        <CardBody>
                                            <h4>Confirm Assignment</h4>
                                            <p className="text-dark">
                                                Since your scholarship program will cover tutoring costs, we do not require an upfront payment.  
                                            </p>
                                            <p className="text-dark">
                                                After a match is made, we wil email further instructions & restrictions.
                                            </p>
                                            <p className="text-dark">
                                                <b>Please Note:</b> You cannot begin tutor sessions until your scholarship program has approved this match.  You will receive an email alert from us when the match has been approved.
                                            </p>
                                            <center><button onClick={this.createScholarshipAssignment} className={"btn btn-primary"}>Create Match</button></center>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                }
            </>
        )
    }
}


const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipMatchConfirmation)

