import React, { Component } from 'react'
import moment from "moment";
import { Link } from "react-router-dom";

import { ReactComponent as BackToList } from "theme-chat/assets/media/icons/backtolist.svg";
    
import ChatHeaderDropDown from './ChatHeaderDropDown';

export default class ChatHeader extends Component {
    showDetails = () => {
        document.body.click();
        this.props.showDetails("tutorRequest");
    };

    toggleChatSearchBox = () => {
        document.body.click();
        this.props.toggleChatSearchBox();
    };
    
    blockParticipant = ({participantId, userName}) =>{
        document.body.click();
        if(confirm("Are you sure you want to block " + userName + "? ")){
            this.props.blockParticipant(participantId);
        }
    }

    unblockParticipant = ({participantId, userName}) =>{
        document.body.click();
        if(confirm("Are you sure you want to unblock " + userName + "? You may need to reload this page to reactivate messaging.")){
            this.props.unblockParticipant(participantId);
        }
    }
    
    render() {
        let { participants, onlineMembers } = this.props;
        
        return (
            <div className="chat-header">
                <Link className="contacts-link text-dark" to="#" onClick={this.props.backToListClicked}>
                    <button
                        // className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
                        className="btn btn-icon btn-minimal btn-md text-secondary d-xl-none"
                        type="button"
                    >
                        <BackToList />
                    </button>
                </Link>
                {participants.map((participant, i) => 
                    <div key={"participant-" + i} className="media chat-name align-items-center text-truncate ml-2">
                        <a onClick={() => this.props.onChangeSelectedParticipant(participant.id)}>
                        {
                            participant.avatar ?
                                <div className={"avatar " + (onlineMembers.includes(participant.userId) ? "avatar-online" : "") + " d-none d-sm-inline-block mr-3"}>
                                <img src={participant.avatar} alt=""></img>
                                </div>
                            :
                            <div className={"avatar " + (onlineMembers.includes(participant.userId) ? "avatar-online" : "") + " bg-info text-light d-none d-sm-inline-block mr-3"}>
                                <span style={{fontSize: "1.5rem"}}>{participant.userName.substring(0,1)}</span>
                            </div>
                        }
                        
                        <div className="media-body align-self-center ">
                            <h6 className="text-truncate mb-0">{participant.userName}</h6>
                            {participant.title && <small>{participant.title}<br/></small>}
                            {participant.timezone && <small className="text-muted">Local Time: {moment().tz(participant.timezone).format("LT")}<br /></small>}
                        </div>
                        </a>
                    </div>
                )}
                
                <ul className="nav flex-nowrap">
                    {/* Large View */}
                    {/* <li className="nav-item list-inline-item d-none d-sm-block mr-1">
                        <Link
                        className="nav-link text-muted px-1"
                        onClick={this.toggleChatSearchBox}
                        to="#"
                        >
                        <SearchSvg />
                        </Link>
                    </li> */}
                    <li className="nav-item list-inline-item d-none d-sm-block mr-1">
                        <button
                            className="btn btn-md btn-outline-dark"
                            onClick={this.showDetails}
                        >
                            Request Details
                        </button>

                    </li>
                    <li className="nav-item list-inline-item d-none d-sm-block mr-0">
                        <ChatHeaderDropDown participants={participants} 
                            showDetails={this.showDetails} 
                            toggleChatSearchBox={this.toggleChatSearchBox}
                            blockParticipant={this.blockParticipant}
                            unblockParticipant={this.unblockParticipant} />
                    </li>

                    {/* Mobile View */}
                    <li className="nav-item list-inline-item d-sm-none mr-0">
                        <ChatHeaderDropDown participants={participants} 
                            showDetails={this.showDetails} 
                            toggleChatSearchBox={this.toggleChatSearchBox}
                            blockParticipant={this.blockParticipant}
                            unblockParticipant={this.unblockParticipant} />
                    </li>
                </ul>
            </div>
        )
  }
}
