import React, { Component } from 'react'

import { Form, Formik } from 'formik';
import { Button, Input } from 'reactstrap'

import { maggieTemplate, requestTutorTemplate, becomeTutorTemplate } from 'constants/emailTemplates';

export default class InboxEditor extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         body: "",
         toEmailAddress: "",
         subject: "",
         defaultBody: "",

         submitting: false,
         mimeType: "text",
      }
    }

    getToEmailAddress = thread =>{
        let toEmailAddress = thread.to
        if(toEmailAddress == null || toEmailAddress.includes("@bufftutor.com")){
            toEmailAddress = thread.from;
        }

        //Remove <> from "Name <name@email.com>"
        if(toEmailAddress.indexOf("<") == -1){
            return toEmailAddress
        }

        let pos = toEmailAddress.indexOf("<") + 1;
        return toEmailAddress.substring(pos, toEmailAddress.indexOf(">", pos));
    }

    getThreadBody = thread =>{
        let messages = thread.messages;
        return messages[messages.length - 1].body;
    }

    onSubmit = values => {
        let { maggie = false, thread, reply } = this.props;

        if(values.body == null || values.body.trim() == ""){
            alert("Message Required - Editor");
            return;
        }

        //Maggie Responses
        if(maggie == true){
            values.fromName = "Maggie Andrews";
        }

        //Reply to Thread
        if(reply == true){
            //Create References
            let messages = thread.messages;
            let references = messages[0].headerMessageId;
            if(messages.length > 1){
                references += " " + messages[messages.length - 1].headerMessageId;
            }
            values.references = references;
            values.inReplyTo = messages[messages.length - 1].headerMessageId;
        }

        values.threadId = thread.id;

        this.setState({submitting: true});
        this.props.onSubmit(values);
    }

    render() {
        let { thread, reply = true, maggie = false } = this.props;
        let { defaultBody, submitting } = this.state;

        let defaultValues = {
            toEmailAddress: this.getToEmailAddress(thread),
            subject: thread.subject,
            // body: this.getThreadBody(thread)
            body: defaultBody
        }

        return (
            <>
                <Formik
                    initialValues={defaultValues}
                    onSubmit={this.onSubmit}
                    enableReinitialize
                >
                    { props  => (
                        <Form className="needs-validation">
                            <h4>From : {maggie ? "Maggie Andrews" : "BuffTutor"}</h4>
                            <br />
                            Templates: {
                                maggie ?
                                        <a href="#" className="text-primary" onClick={() => this.setState({defaultBody: maggieTemplate})}>Default Maggie</a>
                                    :
                                        <>
                                            <a href="#" className="text-primary ml-4" onClick={() => this.setState({defaultBody: requestTutorTemplate})}>Request A Tutor</a>
                                            <a href="#" className="text-primary ml-4" onClick={() => this.setState({defaultBody: becomeTutorTemplate})}>Become A Tutor</a>
                                        </>
                            }
                            <br /><br />
                            <div className="mb-3">
                                <Input
                                    type="email"
                                    name="toEmailAddress"
                                    className="form-control"
                                    placeholder="To"
                                    value={props.values.toEmailAddress}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                            </div>

                            <div className="mb-3">
                                <Input
                                    type="text"
                                    name="subject"
                                    className="form-control"
                                    placeholder="Subject"
                                    value={props.values.subject}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    disabled={reply == true ? true : false}
                                />
                            </div>
                            <Input
                                name="body"
                                className="form-control"
                                type="textarea"
                                value={props.values.body}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                rows="15"
                            />
                            <br />
                            <Button type="submit" color="primary" disabled={submitting} className="float-right mr-1">
                                Send <i className="fab fa-telegram-plane ms-1"></i>
                            </Button>
                        </Form>
                    )}
                </Formik>
            </>
        )
  }
}
