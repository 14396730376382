import React, { Component } from 'react'
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as HorizontalOptionDotsSvg } from "theme-chat/assets/media/icons/horizontaloptiondots.svg";

import { UserMessageDropdownOptions, AdminMessageDropdownOptions } from './dropDownOptions';

export default class BTNMessageDropdown extends Component {
  
  render() {
    let { message={}, isAdmin=false } = this.props;
    let dropdownOptions = isAdmin ? AdminMessageDropdownOptions : UserMessageDropdownOptions;

    return (
      <Dropdown>
        <Dropdown.Toggle
          className="text-muted hw-20"
          as={HorizontalOptionDotsSvg}
        ></Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu">
          {
            dropdownOptions.map((option, i) =>
              <Link key={"dropdown-" + i}className="dropdown-item d-flex align-items-center" to="#" onClick={() => option.function(message)}>
                {option.icon}
                <span>{option.title}</span>
              </Link>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}