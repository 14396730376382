import React, { Component } from 'react'
import classnames from "classnames"
import {
    Container,
    Row,
    Col,
    Table,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Form,
    FormGroup,
    Label,
    CardBody,
    CardTitle,
    CardSubtitle,
  } from "reactstrap"

export default class VerticalTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
          activeTab: 1,
          selectedGroup: null,
          tabs: this.props.tabs || [],
        }
        this.toggleTab = this.toggleTab.bind(this)
        this.handleSelectGroup = this.handleSelectGroup.bind(this)
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
            activeTab: tab,
            })
        }
    }

    handleSelectGroup = selectedGroup => {
        this.setState({ selectedGroup })
    }

    render() {
        return (
            <>
                <Row>
                    <Col lg="2"  className="checkout-tabs">
                        <Nav className="flex-column" pills>
                        {this.state.tabs.map((tab, index) =>
                            <NavItem key={"nav-" + index}>
                                <NavLink
                                    className={classnames({
                                    active: this.state.activeTab === tab.id,
                                    })}
                                    onClick={() => {
                                    this.toggleTab(tab.id)
                                    }}
                                >
                                    <i className={`${tab.icon} d-block check-nav-icon mt-4 mb-2`}/>
                                    <p className="font-weight-bold mb-4">{tab.title}</p>
                                </NavLink>
                            </NavItem>
                        )}
                        </Nav>
                    </Col>
                    <Col lg="10">
                        {/* <Card>
                            <CardBody> */}
                                <TabContent activeTab={this.state.activeTab}>
                                    {this.state.tabs.map((tab, index) =>
                                        <TabPane tabId={tab.id} key={"tab-" + index}>
                                            <tab.component {...tab.props} />
                                        </TabPane>
                                    )}
                                </TabContent>
                            {/* </CardBody>
                        </Card>                 */}
                    </Col>
                </Row>
            </>
        )
    }
}
