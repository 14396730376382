import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Yup from "yup";
import moment from 'moment';

import { ErrorMessage, Form, Formik } from 'formik';

import {
  Row,
  Col,
  Input, 
  Label
} from "reactstrap"


export default class TutorAvailabilityForm extends Component {
    onSaveEvent = values => {
        let { event, calendarId, timezone, isEdit } = this.props;
        let startString = `${values.date} ${values.startTime}`;
        let start = moment.tz(startString, timezone).format();
        
        let recurrenceRule = null;
        if(values.frequency != "none" && values.occurrences > 1){
            recurrenceRule = "RRULE:" + values.frequency + ";COUNT=" + values.occurrences;
        }
        
        if(isEdit == true){
            values.calendarId = calendarId;
        }

        delete values.date;
        delete values.startTime;
        delete values.frequency;

        this.props.onSaveEvent({...values, type: "availability", start, timezone, recurrenceRule, eventId: event ? event.id : null});
    }

    onDeleteEvent = () => {
        if(confirm("Are you sure you want to delete this event?")){
            let { event } = this.props;
            this.props.onDeleteEvent({ eventId: event.id,  type: "availability"});
        }
    }
    
    onClose = () => {
        this.props.onClose();
    }

    render() {
        let { event, isEdit, loading, timezoneName, selectedStartDate, selectedEndDate } = this.props;

        let date = selectedStartDate != null ? moment(selectedStartDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        let startTime = selectedStartDate != null ? moment(selectedStartDate).format("HH:mm") : moment().add(1, "hours").format("HH:00");
        let duration = selectedEndDate != null ? moment(selectedEndDate).diff(moment(selectedStartDate), 'minutes') : 60;
        let frequency = "none";
        let occurrences = 1;

        if(isEdit == true){
            let { start, end } = event;
            //use "utc" to process stored Javascript Date
            date = moment(start).utc().format("YYYY-MM-DD");
            startTime = moment(start).utc().format("HH:mm");
            duration = moment(end).diff(moment(start), 'minutes');
        }
        
        let initialValues = {
            date,
            startTime,
            duration,
            frequency,
            occurrences
        }

        const AvailabilitySchema = Yup.object().shape({
                                        date: Yup.date().required('Please select a date'),
                                        startTime: Yup.string().required('Please select a start time'),
                                        duration: Yup.number().required("Please select how long the event will last"),
                                        // frequency: Yup.string().required('Please select how often this event repeats'),
                                        occurrences: Yup.number().integer().required("How many times should this event occur in total?"),
                                    });

        return (
            <Formik
                initialValues={{...initialValues}}
                validationSchema={AvailabilitySchema}
                onSubmit={this.onSaveEvent}
            >
                { props  => (
                    <Form className="needs-validation">
                        <p>Select a time when you are <b>available</b> for sessions.</p>
                        <Row>
                            <Col className="col-6 mb-3">
                                <Label for="date">Select Date & Time</Label>
                                <Input
                                    className="form-control"
                                    type="date"
                                    name="date"
                                    min={moment().format("YYYY-MM-DD")}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.date}
                                />
                                <ErrorMessage name="date" component="div" className="text-danger" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-6 mb-3">
                            <Label for="startTime">Start Time</Label>
                                <Input
                                    className="form-control"
                                    type="time"
                                    name="startTime"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.startTime}
                                />
                                <small style={{color: "darkblue"}}>Time Zone: {timezoneName}</small>
                                <ErrorMessage name="startTime" component="div" className="text-danger" />
                            </Col>
                            <Col className="col-6 mb-3">
                                <Label for="duration">Duration</Label>
                                <Input 
                                    type="select" 
                                    name="duration" 
                                    className="custom-select" 
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.duration}
                                >
                                    <option value={30}>30 min</option>
                                    <option value={45}>45 min</option>
                                    <option value={60}>1 hour</option>
                                    <option value={75}>1 hour and 15 min</option>
                                    <option value={90}>1 hour and 30 min</option>
                                    <option value={105}>1 hour and 45 min</option>
                                    <option value={120}>2 hours</option>
                                    <option value={150}>2 hours and 30 min</option>
                                    <option value={180}>3 hours</option>
                                    <option value={240}>4 hours</option>
                                    <option value={300}>5 hours</option>
                                    <option value={360}>6 hours</option>
                                    <option value={420}>7 hours</option>
                                    <option value={480}>8 hours</option>
                                    <option value={540}>9 hours</option>
                                    <option value={600}>10 hours</option>

                                </Input>
                                <ErrorMessage name="duration" component="div" className="text-danger" />
                            </Col>
                        </Row>
                        {
                            !isEdit &&
                        
                            <Row className='mb-4'>
                                <Col>
                                    <Label for="frequency">Recurring Frequency</Label>
                                    <Input 
                                        type="select" 
                                        name="frequency" 
                                        className="custom-select" 
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.frequency}
                                    >
                                        <option value={"none"}>Does Not Repeat</option>
                                        <option value={"FREQ=DAILY"}>Daily</option>
                                        <option value={"FREQ=WEEKLY;BYDAY=MO,WE,FR"}>Mon, Wed, Fri</option>
                                        <option value={"FREQ=WEEKLY;BYDAY=TU,TH"}>Tuesday, Thursday</option>
                                        <option value={"FREQ=WEEKLY"}>Weekly</option>
                                        <option value={"FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR"}>Weekdays</option>
                                        <option value={"FREQ=WEEKLY;BYDAY=SA,SU"}>Weekends</option>
                                    </Input>
                                    <ErrorMessage name="frequency" component="div" className="text-danger" />
                                </Col>
                                <Col>
                                    <Label for="occurrences">Occurrences</Label>
                                    <Input type="number" 
                                        name="occurrences" 
                                        className="custom-select" 
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.occurrences}
                                        min={1}
                                        step={1} />
                                    <ErrorMessage name="occurrences" component="div" className="text-danger" />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={this.onClose}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                    {!!isEdit && (
                                        <button
                                            type="button"
                                            className="btn btn-danger me-2"
                                            onClick={this.onDeleteEvent}
                                            disabled={loading}
                                        >
                                         Delete
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-event float-right"
                                        disabled={loading}
                                    >
                                        {isEdit ? "Save" : "Create Event"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }
}

// TutorAvailabilityForm.propTypes = {
//     event: PropTypes.object,
//     isEdit: PropTypes.boolean,
//     loading: PropTypes.boolean,

//     onSaveEvent: PropTypes.func,
//     onDeleteEvent: PropTypes.func,
//     onClose: PropTypes.func
// }