import React, { Component } from 'react'
import { connect } from 'react-redux'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { ThemeSpinner } from 'theme/elements/spinner'
import { Col, Row } from 'reactstrap'

export class IssueBalanceRefund extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            assignmentId: props.match.params.assignmentId || "",
            refundId: null,
            initiated: false,
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
                if(prevState.refundId != newState.refundId && newState.refundId != null){
                    this.issueBalanceRefund(newState.refundId);
                }
            }
        }
    }

    initiateBalanceRefund = () =>{
        const { onSubmitApiCall } = this.props;
        const { assignmentId } = this.state;
        onSubmitApiCall("createBalanceRefund", assignmentId);
    }

    issueBalanceRefund = refundId =>{
        this.setState({initiated: true}, () =>{
            const { onSubmitApiCall } = this.props;
            onSubmitApiCall("issueBalanceRefund", refundId);
        })
    }

    render() {
        const { apiResponse } = this.props;
        const { initiated } = this.state;
        let loading = apiResponse.loading;

        return (
            <Row>
                <Col md={{size: 4, offset: 4}}>
                    <h3>Issue Balance Refund</h3>
                    {
                        loading &&
                        <center><ThemeSpinner /></center>
                    }
                    {
                        !initiated &&
                            <button className="btn btn-lg btn-primary" disabled={initiated || loading} onClick={this.initiateBalanceRefund}>Confirm Refund</button>
                    }
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IssueBalanceRefund)
