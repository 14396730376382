import React, { Component } from 'react'
import { Dropdown, DropdownButton } from "react-bootstrap";

import { AdminFilterOptionsArray, UserFilterOptionsArray } from "./filterOptions";
import { ReactComponent as SearchSvg } from "theme-chat/assets/media/icons/search.svg";

// Top chat filter
export default class BTNChatFilter extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      filterOptions: this.props.isAdmin ? AdminFilterOptionsArray : UserFilterOptionsArray,
    }
  }

  handleChange = async (eventKey) => {
    const { filterOptions } = this.state;
    let selectedOption = filterOptions.find(option => option.id == eventKey)
    await this.props.filterOptionChanged(selectedOption);
  };
  
  render() {
    let { search, handleSearch, selectedOption } = this.props;
    const { filterOptions } = this.state;
    let selectedTitle = filterOptions.find(opt => opt.id == selectedOption).title;

    return (
      <div className="sidebar-sub-header">
        <DropdownButton
          className="border-0 f-16 p-0 min-w-150 cut-texts"
          variant="outline-default"
          onSelect={this.handleChange}
          title={selectedTitle}
        >
          {
            filterOptions.map((option, i) =>
              <Dropdown.Item
                key={"dropdown-"+i}
                eventKey={option.id}
                active={selectedOption === option.id}
              >
                {option.title}
              </Dropdown.Item>
            )
          }
        </DropdownButton>
        <form className="form-inline">
          <div className="input-group">
            <input
              type="text"
              className="form-control search border-right-0 transparent-bg pr-0"
              placeholder="Search users..."
              value={search}
              onChange={handleSearch}
            ></input>
            <div className="input-group-append">
              <div
                className="input-group-text transparent-bg border-left-0"
                role="button"
              >
                <SearchSvg className="text-muted hw-20" />
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}