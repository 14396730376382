import React, { Component } from 'react'
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from 'reactstrap'

export default class MarketCalculateEarnings extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         studentRate: this.props.studentRate || 35,
      }
    }

    render() {
        let { studentRate } = this.state;
        let { btnServiceFee } = this.props;
        
        studentRate = Number(studentRate);
        let btnPercent = btnServiceFee * 100;
        let btnRate = studentRate * btnServiceFee;
        let tutorEarnings = studentRate - btnRate;

        return (
            <Card>
                <CardBody>
                    <CardTitle>Calculate Your Earnings</CardTitle>
                    <Row>
                        <Col xs={6} sm={4}>
                            <Label>Student Rate</Label>
                            <Input
                                type="number"
                                className="form-control"
                                placeholder="Student Rate"
                                onChange={event => {this.setState({studentRate: event.target.value})}}
                                value={studentRate > 0 ? studentRate : ""}
                                label="Student Rate"
                                min={15}
                                max={200}
                            />
                            <br />
                        </Col>
                        <Col xs={12} sm={8}>
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Student Rate</td>
                                        <td>${studentRate.toFixed(2)}/hr</td>
                                    </tr>
                                    <tr>
                                        <td>BuffTutor Fee ({btnPercent}%)</td>
                                        <td>-${btnRate.toFixed(2)}/hr</td>
                                    </tr>
                                    <tr style={{fontWeight: "bold"}}>
                                        <td>You Earn</td>
                                        <td>${tutorEarnings.toFixed(2)}/hr</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}
