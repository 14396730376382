import React, { Component } from "react"
import {
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"

class NavTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      tabs: this.props.tabs || [],
      //tabs: [{id: #, title: '', content: <Something />},]
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.tabs != this.props.tabs){
      this.setState({ tabs: this.props.tabs});
    }
  }
  
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const tabs = this.state.tabs;

    return (
      <React.Fragment>
        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
          {tabs.map(tab =>
            <NavItem key={"navItem-"+tab.id}>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === tab.id,
                })}
                onClick={() => {
                  this.toggleTab(tab.id)
                }}
              >
                {tab.title}
              </NavLink>
            </NavItem>
          )}
        </ul>
        <TabContent
          activeTab={this.state.activeTab}
          className="p-2 pt-3"
        >
          {tabs.map(tab =>
            <TabPane tabId={tab.id} id={tab.id} key={tab.id}>
              {tab.content}
            </TabPane>
          )}
        </TabContent>
      </React.Fragment>
    )
  }
}

export default NavTabs
