import React, { Component } from 'react'
import { connect } from "react-redux"
import PropTypes from 'prop-types'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import StarRatings from "react-star-ratings"

import { formatDate } from 'scripts/general'

class EvaluationsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            
            tableColumns: ["#", "course", "type", "rating", "strength", "weakness", "websiteReference","createdAt" ],
            processing: false
        }
        
        this.createColumns.bind(this)
        this.createRows.bind(this)

        this.formatFields.bind(this)
    }
    
    componentDidMount() {
        let { evaluations=[], admin=false } = this.props;
        this.createColumns(admin);
        this.createRows(evaluations, admin);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { evaluations=[], admin=false } = nextProps;
    //     this.createRows(evaluations, admin);
    // }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createColumns = (admin) => {
        let { tableColumns } = this.state;

        if(admin){
            tableColumns.push("hours");
            tableColumns.push("tutorName");
            tableColumns.push("studentName");
            tableColumns.push("frequency");
            tableColumns.push("id");
        }

        let columns = createSpecifiedColumns(tableColumns);
        

        this.setState({columns});
    }

    createRows = (evaluations, admin) => {
        let rows = evaluations;
        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        let count = 1
        rows.forEach(row => {
            row["#"] = count;
            
            row.rating = <StarRatings
                            rating={row.rating || 0}
                            starRatedColor={row.rating >= 3 ? "#F1B44C" : "#FF0000"}
                            numberOfStars={5}
                            name="rating"
                            starDimension="14px"
                            starSpacing="3px"
                        />
            if(typeof row.createdAt !== undefined){
                row.createdAt = formatDate(row.createdAt);
            }
            count++;
        });
        
        return rows;
    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        return (
            <>
                <p>{rows.length} evaluations</p>
                <p>First = Evaluation after the first session.  <br />
                    End = Evaluation at the end of the semester (or end of the match)</p>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(EvaluationsTable)


EvaluationsTable.protoType = {
    admin: PropTypes.bool,
    evaluations: PropTypes.arrayOf(PropTypes.object)
}