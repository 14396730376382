import React, { Component } from "react";

import ChatList from "./ChatList/ChatList";
import EmptyChat from "./ChatMain/EmptyChat";
import ChatConversation from "./ChatMain/ChatConversation";

import "theme-chat/assets/webfonts/inter/inter.css";
import "theme-chat/assets/css/app.min.css";
import "theme-chat/index.css"
import "./Chats.css"

// Chat master component
export default class Chats extends Component {
  state = {
    mainVisible: false,
    threadId: "",
    chat: {}
  };

  componentDidMount() {
    let { chats=[] } = this.props;

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let threadId = params.get('threadId') || params.get('threadid') || "";
    
    if(threadId != "" && chats.length > 0){
      let chat = chats.find(c => c.threadId.toLowerCase() == threadId.toLowerCase());
      if(chat != undefined){
        this.handleChatClick({ threadId, chat});
      }
    }
  }

  handleChatClick = ({threadId, chat}) => {
    this.setState({ mainVisible: true, threadId, chat });
  };

  handleBackToList = () => {
    this.setState({ mainVisible: false });
  };

  loadThreadsByType = type => {
    this.props.loadThreadsByType(type);
  }

  render() {
    let { chats=[], isAdmin, loadingThreads, pusher, user } = this.props;
    const { threadId, chat, mainVisible } = this.state;

    const fullPageStyle= {position: "fixed",
                            top: 0,                        
                            // left: "0.5%",
                            width: "100%",
                            zIndex: 1005}
    
    chats = chats.sort((a,b) => {return a.lastMessageDate < b.lastMessageDate}); //order desc
    
    return (
      <div className="chats-tab-open h-100" style={fullPageStyle}>
        <div className={"main-layout h-100"}>
          <ChatList
              user={user}
              chats={chats} 
              openThreadId={threadId}
              loadingThreads={loadingThreads}
              isAdmin={isAdmin} 
              handleChatClick={this.handleChatClick} 
              loadThreadsByType={this.loadThreadsByType}
              />

          {
            threadId != null && threadId != ""
              ? <ChatConversation 
                    key={threadId}
                    showMain={mainVisible}
                    threadId={threadId} 
                    chat={chat}
                    isAdmin={isAdmin}
                    pusher={pusher}
                    backToListClicked={this.handleBackToList} />
              : <EmptyChat />
          }

          <div className="backdrop"></div>
        </div>
      </div>
    );
  }
}