import { camelCaseToWords } from "scripts/general";

export function createColumnsFromRows(rowsArray, sortable=true){
    let columns = [];

    const row = rowsArray[0];
    
    for (const key in row) {
      if (Object.hasOwnProperty.call(row, key)) {
        let column = {};
        column["name"] = camelCaseToWords(key);
        column["selector"] = row => row[key];
        
        column["wrap"] = true

        if(sortable){
            column["sortable"] = true;
        }

        columns.push(column);
      }
    }

    return columns;
}

export function createSpecifiedColumns(fields=[], sortable=true){
  let columns = [];

  fields.forEach(field => {
    let column = {};
    column["name"] = camelCaseToWords(field);
    column["selector"] = row => row[field];

    column["wrap"] = true
    
    if(sortable){
        column["sortable"] = true;
    }

    columns.push(column);
  });

  return columns;
}