import React, { Component } from 'react'

import { Alert } from 'reactstrap';
import { ThemeSpinner } from 'theme/elements/spinner';

import { storeGoogleCredential } from 'scripts/google-api';
import { ADMIN_GOOGLE_AUTH_CALLBACK } from 'routes/paths';

export default class GoogleAuthCallback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: ""
        }
    }

    async componentDidMount() { 
        let params = new URLSearchParams(window.location.search);
        let code = params.get('code');
        const redirectURL = ADMIN_GOOGLE_AUTH_CALLBACK;
        
        let formData = {code, redirectURL};

        if(code != null){
            this.setState({loading: true});
            await storeGoogleCredential(formData)
                .then(response => {
                    window.close();
                })
                .catch(error => {this.setState({error: error.response.data})});

            // this.setState({loading: false});
        }
        else{
            this.setState({error: "Code not provided in url query parameters"});
        }
    }


    render() {
        const { loading, error } = this.state;

        return (
            <>
                <h3><center>Processing Credentials</center></h3>
                {error && <Alert color="danger">{error}</Alert> }
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }
            </>
        )
    }
}
  