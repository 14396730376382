import React, { Component } from 'react'
import BasicModal from './BasicModal'
import EvaluationForm from 'components/forms/evaluations/EvaluationForm'

export default class EvaluationFormModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             isOpen: false,
             errorMessage: null
        }
        this.onSubmit.bind(this)
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOpen !== this.props.isOpen) {
          this.setState({isOpen: this.props.isOpen});
        }
    }

    onSubmit = (errorMessage) => {
        if(typeof errorMessage == undefined || errorMessage != null){
            this.setState({errorMessage});
        }
        else{
            if(this.props.toggleModal != undefined){
                this.props.toggleModal();
                window.location.reload();
            }
        }
    }

    render() {
        let { type, assignment } = this.props;
        let { isOpen, errorMessage } = this.state;
        let tutorName = assignment.name;
        let course = assignment.course;
        let header = "";

        if(type == "end") {
            header = "Final Evaluation of " + tutorName + " - " + course;
        }
        else{
            header = "First Evaluation of " + tutorName + " - " + course;
        }

        if(type == null || type == "" || type == "none"){
            errorMessage = "Evaluation Cannot Be Submitted At This Time.";
        }

        return (
            <BasicModal 
                isOpen={isOpen} 
                header={header} 
                error={errorMessage}
                toggleModal={this.props.toggleModal}>
                {
                    type == "first" || type == "end" ?
                        <EvaluationForm type={type} 
                            assignment={assignment}
                            onSubmit={this.onSubmit}
                            />
                    :
                        <>
                            <p>A First Session evaluation will be available after the first session has occured.</p>
                            <p>An End Of Semester evaluation can be submitted if you've met with your tutor more than 3 times. This evaluation will be available 1 week before the assignment's End Date</p>
                            <p><b>Each type of evaluation can only be submitted once.</b></p>
                        </>
                }
            </BasicModal>
        )
    }
}
