import { GET_AD_ACCOUNT_FOR_OWNER,
          PATCH_UPDATE_AD_ACCOUNT_AVATAR,
          PATCH_UPDATE_AD_ACCOUNT_LEAD_NOTIFICATION,

          POST_CREATE_ACCOUNT_FROM_APPLICANT, POST_CREATE_ACCOUNT_FROM_PROFILE,
          GET_ACTIVE_LEADS_FOR_TUTORS, 
          POST_ADMIN_CREATE_LEAD, POST_CREATE_LEAD_BID, 
          PATCH_DELETE_LEAD_BID,

          GET_LEAD_BIDS_FOR_TUTOR_REQUEST, GET_LEAD_BID_DETAILS
  } from 'api/urls';
import { get, post, postFormData,patch } from "helpers/api_helper"


//Ad Account
export const getAdAccountForOwner = userRefId => {
  return get(GET_AD_ACCOUNT_FOR_OWNER + "/" + userRefId);
}


export const patchUpdateAdAccountLeadNotification = formData => {
  return patch(PATCH_UPDATE_AD_ACCOUNT_LEAD_NOTIFICATION + "/" + formData.accountId, formData);
}

export const postUpdateAdAccountAvatar = ({ accountId, avatarFile }) => {
  var formData = new FormData();
  formData.append("avatar", avatarFile); 
  let headers = { headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } };

  return postFormData(PATCH_UPDATE_AD_ACCOUNT_AVATAR + "/" + accountId, formData, headers);
}

export const convertApplicantToAdAccount = applicantRefId => {
  return post(POST_CREATE_ACCOUNT_FROM_APPLICANT + "/" + applicantRefId);
}

export const convertProfileToAdAccount = userRefId => {
  return post(POST_CREATE_ACCOUNT_FROM_PROFILE + "/" + userRefId);
}

//Leads
export const adminCreateTutorRequestLead = formData => {
    return post(POST_ADMIN_CREATE_LEAD + "/" + formData.tutorRequestId, formData);
}


export const getLeadsForTutors = () => {
  return get(GET_ACTIVE_LEADS_FOR_TUTORS);
}

export const postCreateLeadBid = formData => {
  //leadRefId
  return post(POST_CREATE_LEAD_BID + "/" + formData.leadId, formData);
}

export const patchDeleteLeadBid = bidId => {
  //bidRefId
  return patch(PATCH_DELETE_LEAD_BID + "/" + bidId);
}

//Ads
export const getLeadBidsForTutorRequest = tutorRequestId => {
  return get(GET_LEAD_BIDS_FOR_TUTOR_REQUEST + "/" + tutorRequestId);
}

export const getLeadBidDetails = leadBidId => {
  return get(GET_LEAD_BID_DETAILS + "/" + leadBidId);
}