import React, { Component } from 'react'
import { connect } from "react-redux"
import PropTypes from 'prop-types'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { DeletePaymentAction } from './actions'

import { formatCurrencyAmount, formatDate } from 'scripts/general'

class PaymentsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            userColumns: ["#", "description", "subtotal", "discount", "fee", "total", "createdAt", "id"],
            
            adminColumns: ["id", "createdAt", "userName", "description", "objectDetails", "subtotal", "discount", "fee", "processingFee", "total", "status", "transactionId", "completedAt", "refId", "action"],
            processing: false
        }
        
        this.createColumns.bind(this)
        this.createRows.bind(this)

        this.addActionField.bind(this)
        this.formatFields.bind(this)
        this.triggerAction.bind(this)
    }
    
    componentDidMount() {
        let { payments=[], admin=false } = this.props;
        this.createColumns(admin);
        this.createRows(payments, admin);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { payments=[], admin=false } = nextProps;
    //     this.createRows(payments, admin);
    // }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createColumns = (admin) => {
        let { userColumns, adminColumns } = this.state;
        let specifiedFields = userColumns;

        if(admin){
            specifiedFields = adminColumns;
        }

        let columns = createSpecifiedColumns(specifiedFields);

        this.setState({columns});
    }

    createRows = (payments, admin) => {
        let rows = payments;
        rows = this.formatFields(rows);
        rows = this.addActionField(rows, admin);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        let count = 1
        rows.forEach(row => {
            let userCurrency = row.currency || "usd";
            
            row["#"] = count;
            if(typeof row.completedAt !== undefined){
                row.completedAt = formatDate(row.completedAt);
            }
            if(typeof row.createdAt !== undefined){
                row.createdAt = formatDate(row.createdAt);
            }
                
            if(typeof row.subtotal !== undefined && typeof row.subtotal == "number"){
                row.subtotal = formatCurrencyAmount(row.subtotal, userCurrency);
            }
            if(typeof row.discount !== undefined && typeof row.discount == "number"){
                row.discount = formatCurrencyAmount(row.discount, userCurrency);
            }

            if(typeof row.fee !== undefined && typeof row.fee == "number"){
                row.fee = formatCurrencyAmount(row.fee, userCurrency);
            }

            if(typeof row.total !== undefined && typeof row.total == "number"){
                row.total = formatCurrencyAmount(row.total, userCurrency);
            }
            if(typeof row.processingFee !== undefined && typeof row.processingFee == "number"){
                let processingFeeCurrency = row.processingFeeCurrency || "usd";
                row.processingFee = formatCurrencyAmount(row.processingFee, processingFeeCurrency);
            }

            row.status += row.paid == true ? " - Paid" : "";
            row.status += row.refunded == true ? " - Refunded" : "";
            
            count++;
        });
        
        return rows;
    }

    addActionField = (rows, admin) => {
        if(admin){
            rows.forEach(row => {
                if(row.refundable == true){
                    row["action"] = <DeletePaymentAction id={row.id} onClick={() => this.triggerAction(row.id)} />
                }
            });
        }

       return rows;
    }

    triggerAction = (paymentId) =>{
        if(confirm(`Are you sure you want to delete & refund this payment?`)){
            let { onSubmitApiCall } = this.props;
            onSubmitApiCall("issuePaymentRefund", paymentId);
            this.setState({processing: true});
        }
    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        return (
            <>
                <p>{rows.length} Payments</p>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTable)


PaymentsTable.protoType = {
    admin: PropTypes.bool,
    payments: PropTypes.arrayOf(PropTypes.object)
}