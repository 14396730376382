import { GET_PAYMENT_METHODS, POST_CALCULATE_CART_TOTAL } from '../api/urls';
import { get, post } from "../helpers/api_helper";

import { processApiError } from 'store/api/scripts';

export async function getPaymentMethods() {
  try {
    return await get(GET_PAYMENT_METHODS);  

  } catch (error) {
   
    let errorMessage = processApiError(error);
    return {error: errorMessage};
  }
}

export const calculateCartTotal = cart => {
  return post(POST_CALCULATE_CART_TOTAL, cart);
}