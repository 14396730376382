import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

import { Link } from 'react-router-dom'

import logo from "assets/images/logo/BuffTutor_Logo_Small.png"

export default class AuthHeader extends Component {
    render() {
        const { title, subtitle } = this.props;

        return (
            <div className="bg-soft-primary">
                <Row>
                    <Col xs="4">
                        <div className="auth-logo pt-5 ml-3 mb-4">
                            <Link to="/" className="auth-logo-dark">
                            <div className="avatar-md profile-user-wid mb-2">
                                <span className="avatar-title rounded-circle bg-light">
                                <img
                                    src={logo}
                                    alt=""
                                    height="50"
                                />
                                </span>
                            </div>
                            </Link>
                        </div>
                    </Col>
                    <Col xs="8">
                        <div className="text-primary p-4">
                            <h5 className="text-primary">{title}</h5>
                            <p>{subtitle}</p>
                        </div>    
                    </Col>
                </Row>
            </div>
        )
    }
}
