import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { TUTOR_LOG_INTO_STRIPE } from 'routes/paths'
import BTNMetaTags from "components/metatags/BTNMetaTags"

export default class TaxInfo extends Component {
    //https://stripe.com/docs/connect/express-dashboard-taxes
    //Support Article + Questions: https://stripe.com/docs/connect/platform-express-dashboard-taxes-faqs 
    //Email Template: https://stripe.com/docs/connect/platform-express-dashboard-taxes-communication

    //https://help.doordash.com/dashers/s/article/Common-Dasher-Tax-Questions?language=en_US#canada
    
    render() {
        const year = (new Date()).getFullYear() - 1;
        const threshold = 600;
        const stripeContact = "https://support.stripe.com/express/contact/email";

        return (
            <>
                <BTNMetaTags title={`Tax Information For Tutors`} />

                <Row>
                    <Col lg="9">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    Tax Information For Tutors 
                                </CardTitle>
                                <p><em>BuffTutor only provides tax documents for tutors in the USA. For tutors located in other countries, please consult your local tax authorities for information.</em>  </p>
                                
                                <p>If you earned <b>${threshold} or more</b> in {year} on the BuffTutor platform, you will receive a 1099-NEC form via Stripe.</p>

                                <p>Please Note:
                                    <ul>
                                        <li>Eligible tutors will receive a Stripe invite by the end of {year} via email to confirm their account information. Please lookout for an email with the subject line <b>"Action required to receive your BuffTutor {year} tax form"</b> OR <b>"Get your BuffTutor {year} tax forms faster by enabling e-delivery"</b>. Be sure to check your Junk/Spam folder for the email.</li>
                                        {/* <li>The amount reported on the 1099 is the total amount paid out to the tutor's bank account (minus any refunds and payout reversals) via Stripe Express in {year}. </li> */}
                                    </ul>
                                </p>
                                <p>Click <a href="https://support.stripe.com/express/topics/1099-tax-forms" target="_blank" rel="noreferrer">here</a> to visit the Stripe Support articles.</p>
                                    <Link to={TUTOR_LOG_INTO_STRIPE} className="btn btn-primary btn-md me-2 w-md">Log Into Stripe Express</Link>
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="9">
                        <Card>
                            <CardBody>
                                <CardTitle>Frequently Asked Questions</CardTitle>
                                <p>
                                    <b>What tax forms will be sent?  </b>
                                    <br />
                                    The only tax form that eligible tutors will receive is the 1099-NEC, and this is ONLY for tutors who earn ${threshold} or more on the platform in {year}. Tutors who earn less will NOT receive a 1099 form.
                                </p>

                                <p>
                                    <b>What is a 1099-NEC tax form?</b>
                                    <br />
                                    1099-NEC forms are federal income tax information forms used to report earnings and proceeds other than wages, salaries and tips (which are reported on the federal W-2 form). The forms are filed with the U.S. Internal Revenue Service (IRS) and, if required, state tax departments. A 1099-NEC form summarizes tutor earnings as independent contractors in the US. It's provided to you and the IRS, as well as some US states, if you earn ${threshold} or more in {year}.
                                </p>
                                <p>
                                    <b>When will I receive my 1099?</b>
                                    <br />
                                    Your 1099 tax form will be sent to you by January 31, {year + 1} {/*(note: paper forms delivered by postal delivery might take up to 10 additional business days).*/}
                                    <ul>
                                        <li><span className='text-primary'>Starting November {year}:</span> Stripe will email you instructions on how to set up e-delivery (<b className='text-danger'>fastest delivery method</b>){/*and create a Stripe Express account*/}. You'll need to complete these steps to access your 1099 tax form electronically.</li>
                                        <li>
                                            <span className='text-primary'>By January 31, {year + 1}:</span><br />
                                            <ul>
                                                <li>Your 1099 tax form will be available to download through Stripe Express.</li>
                                                {/*<li>Your 1099 tax form will be mailed to you if you don't receive an email from Stripe or don't consent to e-delivery. Please allow up to 10 business days for postal delivery.</li>*/}
                                                <li>BuffTutor will file your 1099 tax form with the IRS and relevant state tax authorities.</li>
                                            </ul>
                                        </li>
                                        <li>April 15, {year + 1}: IRS deadline to file taxes. You'll need your 1099 tax form to file your taxes.</li>
                                    </ul>
                                    
                                    In January, we strongly suggest that you ensure all of your tax filing details and delivery preferences are up to date in Stripe Express. Your name, address and Taxpayer Identification Number (Social Security Number /Employer Identification Number) are of primary importance.
                                </p>
                                    
                                <p>
                                    <b>How will I know when my 1099 tax form is available?</b>
                                    <br />
                                    If you opted in to e-delivery, Stripe will send an email notification about the availability of your 1099 tax form to the email associated with your Stripe Express account.
                                </p>
                                
                                <p>
                                    <b>How will I receive my 1099 tax form?</b>
                                    <br />
                                    After you log in to your Stripe Express account, you will be able to consent to e-delivery of your 1099 tax forms. If you do not consent to e-delivery, you will not be able to view or download your 1099 tax forms in the Stripe Express Tax forms page. If you opted in to e-delivery, your 1099 tax form will be delivered electronically near the end of January.
                                </p>

                                <p>
                                    <b>Why haven't I received a Stripe Express invitation?</b>
                                    <br />
                                    Stripe uses the email information associated with your BuffTutor account to send you an invitation to sign up for Stripe Express. Check your spam or junk mail folder in case your invitation landed there.
                                        <br />
                                        You may not have received an invitation for other reasons, such as:
                                        <ul>
                                            <li>You earned less than the threshold.</li>
                                            <li>Your email address on file is incorrect, missing, or unable to receive email.</li>
                                            <li>Your email address changed since you first signed up with BuffTutor{/* (refer to the next question for how to update you information)*/}.</li>
                                        </ul>

                                    If you haven't received the invitation email, please <a href={stripeContact} target="_blank" rel="noreferrer">contact Stripe Support</a>.
                                </p>

                                {/* <p>
                                    <b>My information has changed since I signed up for BuffTutor. How can I edit my information?</b>
                                    <br />
                                    Under Stripe Express Account, you can update the email address and phone number used to log in to Stripe Express. Please note that only one contact method can be updated at a time.
                                </p> */}
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </>
        )
    }
}
