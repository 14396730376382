import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody } from "reactstrap"

import { TUTOR_PROFILE } from "routes/paths";
import { getCurrencySymbol, formatOldProficiencyExamName } from "scripts/general";

class SearchTutorDetails extends Component {

  render() {
    const { tutor, isAdmin } = this.props;
    let maxLength = 250;
    let tutorExperience = tutor.experience && (tutor.experience.length > maxLength) ? tutor.experience.substring(0, maxLength - 3) + "..." : tutor.experience;
    const tutorId = typeof tutor.id == "string" ? tutor.id : tutor.refId;
    const profileLink = TUTOR_PROFILE + "/" + tutorId;

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col lg="3">
                <div className="product-img position-relative">
                  <img
                    src={tutor.avatar}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                <div className="mt-4 text-center">
                  <h5 className="mb-3 text-truncate">
                    <Link
                      to={profileLink}
                      className="text-primary"
                      target="_blank"
                    >
                      {tutor.userName}
                    </Link>
                    <br />
                    {
                      tutor.city &&
                        <span className="text-muted mr-2">
                          {`${tutor.city}, ${tutor.state}`}
                        </span>
                    }
                  </h5>
                  {/*}<div className="text-muted mb-3">
                    <StarRatings
                      rating={product.rating}
                      starRatedColor="#F1B44C"
                      starEmptyColor="#2D363F"
                      numberOfStars={5}
                      name="rating"
                      starDimension="14px"
                      starSpacing="3px"
                    />
                  </div>*/}
                </div>
              </Col>
              <Col lg="9">
                {
                  !isAdmin && 
                    <>
                      {tutor.distanceFromUser} Miles Away<br />
                      <b>Timezone: </b>{tutor.timezone} <br />
                      <span className="text-success" style={{fontWeight: "bold"}}>
                        {getCurrencySymbol(tutor.currency)}{tutor.minRate} - {tutor.maxRate} /hr <br />
                      </span>
                      <b>Students: </b>{tutor.activeStudentsCount} / {tutor.studentsCount}<br />
                      <b>Hours: </b>{tutor.activeHoursCount} / {tutor.hoursCount}<br />
                      <b>Evaluations: </b>{tutor.evaluationsCount}<br />
                      <b>References: </b>{tutor.references.length} <br />
                      <b>Contracts:</b>{tutor.tutorContractsCount ? tutor.tutorContractsCount + " / " + tutor.activeTutorContractsCount : null}<br />
                      <b>Declined: </b>{tutor.declinedContractsCount ? tutor.declinedContractsCount + "/" + tutor.activeDeclinedContractsCount : null}<br />
                      <b>Missed: </b> {tutor.missedContractsCount ? tutor.missedContractsCount + "/" + tutor.activeMissedContractsCount : null}
                      <br /><br />
                    </>
                }
                {
                  tutor.inPerson && 
                    <span className="badge badge font-size-11 badge-success">
                      In-Person
                    </span>
                }
                {" "}
                {
                  tutor.online &&
                      <span className="badge badge font-size-11 badge-info">
                        Online
                      </span>
                }
                <br /><br />
                <b>{tutor.education}</b><br />
                <b>Major: {tutor.major}<br /></b>
                {tutorExperience} <br />
                {
                    tutor.assessmentResults && tutor.assessmentResults.map((result, i)=>
                        <span key={i} className={result.grade >= 80 ? "text-primary" : (result.grade > 70 ? "text-info" : "text-danger")}>
                            {result.proficiencyExamName != null && result.proficiencyExamName != "" 
                                ?
                                    result.proficiencyExamName
                                :
                                    formatOldProficiencyExamName(result.name)
                            }
                            {" "} - {result.grade}%
                            <br />
                        </span>    
                    )
                }
                {!tutor.acceptingStudents && <><b style={{color:"red"}}>Unavailable</b><br /></>}
                {/*tutor.backgroundCheck && <><b className="text-primary">Background Check: </b>{formatDate(tutor.backgroundCheck)}<br /></>*/}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default SearchTutorDetails;
