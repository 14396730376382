import React, { Component } from 'react'

import { connect } from "react-redux"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import Chats from 'theme-chat/components/Chats/Chats';

class ChatAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatThreads: [],
        }
      }

    componentDidMount() {
        this.loadThreadsByType("recent");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
    
            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    loadThreadsByType = type =>{
        const { onSubmitApiCall } = this.props

        switch (type) {
            case "recent":
                onSubmitApiCall("getAdminRecentChats");
                break;
            case "flagged":
                console.log("loading flagged")
                onSubmitApiCall("getAdminFlaggedChats");
                break;
            default:
                break;
        }
    }

    render() {
        let { chatThreads  } = this.state
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="Admin - All Messages" />
                
                <Chats key={chatThreads.length} 
                        chats={chatThreads} 
                        isAdmin={true}
                        loadingThreads={loading} 
                        loadThreadsByType={this.loadThreadsByType}/>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChatAdmin)
  