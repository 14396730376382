import React, { Component } from 'react'
import { connect } from "react-redux"

import { ThemeButton } from 'theme/elements/buttons'
import { ThemeInput } from 'theme/elements/styledInputs'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

class FindPreviousStudentForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             assignments: [],
             studentName: "",
             error: ""
        }
        this.onChangeValue.bind(this)
        this.onSubmit.bind(this)
    }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
                
                //Pass assignments to Props/Parent Component
                if(Array.isArray(newState.assignments) && newState.assignments.length > 0
                    && newState.assignments != prevState.assignments
                    && typeof this.props.onSubmit != undefined ){
                        this.props.onSubmit(newState.assignments);
                }   
            }
        }
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({error: ""});

        let { studentName } = this.state;

        if(typeof studentName == "string" && studentName.length > 2){
            let { onSubmitApiCall} = this.props;
            await onSubmitApiCall("findPreviousStudent", { studentName });
        }
        else{
            this.setState({error: "Invalid Name (must be at least 2 characters)"});
        }
    }

    
    render() {
        let { studentName, error } = this.state;
        let { apiResponse = {}} = this.props;
        let loading = apiResponse.loading;

        return (
            <>
                <ThemeInput 
                    id="studentName"
                    name="studentName"
                    value={studentName}
                    placeholder={"First Or Last Name"}
                    label="Student Name"
                    onChange={this.onChangeValue}
                    autoFocus={true}
                    required={true}
                    />
                {
                    error && <span className="text-danger">{error}</span>
                }
                <br />
                {
                    loading ? 
                        <ThemeButton onClick={this.onSubmit} icon={"bx bx-search"} disabled={true}>Searching...</ThemeButton>
                        :
                        <ThemeButton onClick={this.onSubmit} icon={"bx bx-search"}>Search</ThemeButton>
                }
                
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(FindPreviousStudentForm)