import React, { Component } from 'react'
import moment from 'moment';

import { Form, Formik } from 'formik';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { getCurrencySymbol, formatDateTime } from 'scripts/general';

export default class ProposalTutorView extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showRejectForm: false,
          disableExtension: false,
        }
    }

    extendDeadline = () => {
        if(confirm("This action will add 2 days to the student's deadline to respond.  Please confirm.")){
            const { details, onExtendProposalDeadline } = this.props;
            this.setState({disableExtension: true});
            onExtendProposalDeadline({proposalId: details.proposalId});
        }
    }

    reactivateProposal = () => {
        if(confirm("Please confirm your decision to REACTIVATE your proposal.")){
            const { details, onReactivateProposal } = this.props;
            onReactivateProposal({proposalId: details.proposalId});
        }
    }

    rejectProposal = (values) => {
        if(confirm("Please confirm your decision to WITHDRAW your proposal.")){
            const { details, onRejectProposal } = this.props;
            let { rejectReason, rejectReasonOther } = values;
            
            rejectReason = rejectReasonOther == null || rejectReasonOther.trim() == "" ? rejectReason : rejectReason + " - " + rejectReasonOther.trim();
            onRejectProposal({rejectReason, proposalId: details.proposalId});
        }
    }

    render() {
        let { showRejectForm, disableExtension } = this.state;
        let { details, loading } = this.props;

        let initialValues = { rejectReason: "", rejectReasonOther: "" }

        return (
            <Card className="pb-3 pt-3" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.25)"}}>
                <CardBody>
                    {
                    !showRejectForm ? 
                        <>
                            <h3>Rate: <span className="text-info">{getCurrencySymbol(details.studentCurrency)}{details.studentRate}/hr*</span></h3>
                            <p>
                                {
                                    details.onlineTutor &&
                                        <span className="badge badge font-size-11 badge-soft-info">
                                            Online Tutoring
                                        </span>
                                }
                                {" "}
                                {
                                    details.inPersonTutor && 
                                        <span className="badge font-size-11 badge-soft-success">
                                            In-Person Tutoring
                                        </span>
                                }
                                <br /><br />
                                {!details.matchCreated && details.studentDeadline && 
                                    <>
                                        <span style={{fontSize: "0.85em"}}>
                                            Student Deadline: <span className={moment(details.studentDeadline).isBefore(new Date()) ? "text-danger" : "text-dark"}>{formatDateTime(details.studentDeadline)}</span>
                                        </span>
                                        <br />
                                        {
                                            moment(details.studentDeadline).isBefore(new Date()) &&
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    outline={true}
                                                    className="btn-sm w-md"
                                                    onClick={this.extendDeadline}
                                                    disabled={loading || disableExtension}
                                                >
                                                    Extend Student Deadline
                                                </Button>
                                        }
                                    </>
                                }
                                <br />
                                <small className="text-secondary">*View the Rate Details section below for your earnings.</small>
                            </p>
                            {
                                details.matchCreated 
                                    ?
                                        <Row>
                                            <Col>
                                                <center><b><em>Match Created</em></b></center>
                                            </Col>
                                        </Row>
                                    :
                                        <Row>
                                            <Col>
                                                <center>
                                                    <b style={{color: "darkred"}}>A Match Has Not Been Created Yet.</b> 
                                                    <br />{details.studentName ? details.studentName.substring(0,details.studentName.length - 2) : "The student"} must click the Create Match button.
                                                </center>
                                                {details.tutorDecision === false && <small><span className="text-danger">Proposal Withdrawn</span><br /></small>}

                                                {/* {
                                                    moment(details.studentDeadline).isBefore(new Date()) &&
                                                        <p><br /><a href="mailto:Contact@BuffTutor.com">Contact Us</a> To Extend The Student's Deadline To Respond</p>
                                                } */}
                                                {/* {
                                                    details.tutorDecision === true ?
                                                        <Button
                                                            type="button"
                                                            color="danger"
                                                            className="btn-rounded btn-sm w-md"
                                                            onClick={() => this.setState({ showRejectForm : true })}
                                                            disabled={loading || moment(details.studentDeadline).isBefore(new Date()) ? true : false}
                                                        >
                                                            Withdraw Proposal {" "}
                                                            {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                                                        </Button>
                                                    :
                                                        <Button
                                                            type="button"
                                                            color="info"
                                                            className="btn-rounded btn-sm w-md"
                                                            onClick={this.reactivateProposal}
                                                            disabled={loading || moment(details.studentDeadline).isBefore(new Date()) ? true : false}
                                                        >
                                                            Re-Activate Proposal {" "}
                                                            {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                                                        </Button>
                                                } */}
                                            </Col>
                                        </Row>
                            }
                        </>
                    :
                        <>
                            <CardTitle className="mt-4 mb-3">
                                <a onClick={() => this.setState({showRejectForm : false})} >
                                    <i className='bx bx-x float-left' style={{fontSize: "1.5em"}} />
                                </a>
                                Withdraw Proposal
                            </CardTitle>
                            <p>Please explain why you would like to withdraw your proposal:</p>
                            
                            <Formik
                                initialValues={initialValues}
                                onSubmit={this.rejectProposal}
                            >
                                { props  => (
                                    <Form className="needs-validation">
                                        <FormGroup check>
                                            <Input type="radio" id="lowRate" name="rejectReason" value="Rate Too Low" required onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="lowRate" check>Rate Too Low</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="schedule" name="rejectReason" value="Schedule Conflict" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="schedule" check>Schedule Conflict</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="notAccepting" name="rejectReason" value="No Longer Accepting Students" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="notAccepting" check>No Longer Accepting Students</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="notComfortable" name="rejectReason" value="Not Comfortable With Course Material" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="notComfortable" check>Not Comfortable With Course Material</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="unresponsive" name="rejectReason" value="Unresponsive Student" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="unresponsive" check>Unresponsive Student</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="unprofessional" name="rejectReason" value="Unprofessional Behavior By Student" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="unprofessional" check>Unprofessional Behavior By Student</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="other" name="rejectReason" value="Other" onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="other" check>Other</Label>
                                        </FormGroup>

                                        <div className="form-group">
                                            <Input
                                                name="rejectReasonOther"
                                                label="Additional Comments (Optional)"
                                                className="form-control"
                                                placeholder="Your comments will be shared with the student."
                                                type="textarea"
                                                value={props.values.rejectReasonOther}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </div>
                                        <div className="mt-3">  
                                            <button
                                                className="btn btn-sm btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Submit Decision {" "}
                                                {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>              
                    }
                </CardBody>
            </Card>
        )
  }
}

