import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

import LineChart from './chartjs/LineChart'

export default class AnalyticsMonthlyChart extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            datasets: [],
            cumulativeDatasets: [],
            chartData: this.props.chartData || [],
            type: this.props.type || "amount", //e.g. amount, hours, count
            chartTitle: this.props.title || "",
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        }
    }
    
     
    componentDidMount() {
        this.processMonthlyChartData();
    }
    
    processMonthlyChartData = () => {
        let { chartData, type } = this.state;
        let datasets = [];
        let cumulativeDatasets = [];
        let years = [];

        let colors = ['rgba(153, 102, 255)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)', 'rgba(255, 99, 132)', 'rgba(255, 159, 64)',
                        //Repeat Colors
                    'rgba(153, 102, 255)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)', 'rgba(255, 99, 132)', 'rgba(255, 159, 64)'];
        
        let backgroundColors = ['rgba(153, 102, 255, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)',
                                    //Repeat Colors
                                'rgba(153, 102, 255, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)'];
        
        chartData.forEach(dataObject => {
            if(years.indexOf(dataObject.year) == -1){
                years.push(dataObject.year);
            }
        });

        let colorIndex = 0;
        years.forEach(year => {
            let monthData =[];
            let monthCumulutiveData =[];

            for (let month = 0; month < 12; month++) {
                monthData[month] = 0;
                monthCumulutiveData[month] = 0;
                const matchedData = chartData.filter(data => {
                                            return data.year == year && data.month == month + 1; //SQL returns months 1-12, loop index is 0-11
                                    })[0];
                if(matchedData && matchedData.hasOwnProperty(type)){
                    monthData[month] = matchedData[type];
                }

                monthCumulutiveData[month] = month == 0 ? monthData[month] : monthCumulutiveData[month - 1] + monthData[month];
            }
            
            const dataset = {
                    label: year,
                    data: monthData,
                    fill: true,
                    lineTension: 0.2,
                    backgroundColor: backgroundColors[colorIndex],
                    borderColor: colors[colorIndex],
                }
        
            const cumulativeDataset = {
                    label: year,
                    data: monthCumulutiveData,
                    fill: true,
                    lineTension: 0.2,
                    backgroundColor: backgroundColors[colorIndex],
                    borderColor: colors[colorIndex],
                }
            
            datasets.push(dataset);
            cumulativeDatasets.push(cumulativeDataset);
            colorIndex++;
        });
    
        this.setState({datasets, cumulativeDatasets});
    }

    render() {
        const { datasets, cumulativeDatasets, labels, chartTitle } = this.state

        return (
            <div>
                {
                    datasets.length > 0 &&
                        <Row>
                            <Col sm="6">
                                <LineChart chartTitle={chartTitle} labels={labels} datasets={datasets} />
                            </Col>
                            <Col sm="6">
                                <LineChart chartTitle={chartTitle + " - Cumulative"} labels={labels} datasets={cumulativeDatasets} />
                            </Col>
                        </Row>
                }
            </div>
        )
    }
}
