import React from "react"
import { Card, CardBody } from "reactstrap"

export const FormLayout = props => {
    return (
        <Card className="overflow-hidden" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.3)"}}>
            <CardBody className="pt-6">
                <div className="p-2">
                    {props.children}
                </div>
            </CardBody>
        </Card>
    
    )

} 