import React, { Component } from "react"
import { TabPane, Row, Col, FormGroup, Label, Input } from "reactstrap"

import Select from "react-select"

class CoursesTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subjects: [
        {value: 1, label: "Astronomy"},
        {value: 2, label: "Math: Calculus"},
        {value: 3, label: "Math: Algebra"},
        {value: 4, label: "Chemistry"},
        {value: 5, label: "Physics"},
      ],
    }
  }

  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Row>
            <Col lg="12">
            {/*<FormGroup className="select2-container">
              <label className="control-label text-danger">
                Subjects -- NOT WORKING
              </label>
              <Select
                id="subjects"
                name="subjects"
                value={this.props.subjects}
                isMulti={true}
                onChange={this.props.onChangeArray}
                options={this.state.subjects}
                classNamePrefix="select2-selection"
              />
            </FormGroup>*/}
              <p>Make sure that you list only the courses you have passed our online proficiency test in.  If a course was not included on our exams, include courses that you are proficient in and can teach, not just courses you did well in.</p>

              <Label>For College Courses</Label> <br />
              <ul>
                <li>Search the course catalog for the university (universities) near you for courses you can tutor.  Include both the course number and course name.  </li>
                <li>List the course number as the 2-4 letter label and 3-4 digit course number (do not include section numbers).  Add a space between the label letters and course number.  <b>Example: CHEM 1133 - General Chemistry 2</b> </li>
                <li>OPTIONAL: You may also include commonly used names of the courses, e.g. MATH 161  Calculus for Physical Scientists II (Calc 2)</li>
              </ul>

              <Label>For High/Middle/Elementary School Classes</Label> <br />
              <ul>
                <li>Include the class level and a description of the class. For example:  High School Physics, Chemistry, Algebra, Middle School Writing, Elementary School Reading</li>
                <li>Do not simply state High School Math, instead: High School Algebra, Geometry, Precalculus.  </li>
              </ul>

              <Label>For Other Tutoring Areas</Label><br />
              <ul>
                <li>If you are proficient in a software package or programming language and can teach it, be sure to include that.  For example "PHP, C++, MatLab, Mathematica, R"</li>
                <li>Include standardized tests, e.g. SAT Writing, GRE Chemistry, if you are familiar and proficient in both the content and test taking  procedures / strategies.</li>
                <li>Feel free to add General Tutoring/Mentoring if you wish to do that.  We have received requests for tutors who can help with mentoring, organization, study skills.</li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="courses">
                  Courses / Subjects Tutored
                </Label>
                <textarea
                  id="courses"
                  name="courses"
                  className="form-control"
                  rows="8"
                  placeholder={"MATH 1300 / APPM 1350 - Analytic Geometry & Calculus 1 (Calc 1) \nPHYS 1110 / PHYS 2010 - General Physics 1 \nHigh School Biology \nGRE Chemistry \nMatLab, R programming, C++"}
                  onChange={this.props.onChange}
                  value={this.props.formData.courses}
                />
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default CoursesTab
