import React, { Component } from "react"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"

import BTNAlert from "../alerts/BTNAlert"

import Header from "./Header"

class FullPageLayout extends Component {
  
  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header />
          
          {/* <div className="page-content"> */}
            <BTNAlert />
            {this.props.children}
          {/* </div> */}

        </div>
      </React.Fragment>
    )
  }
}

FullPageLayout.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(FullPageLayout)
