import React, { Component } from 'react'
import StarRatings from "react-star-ratings"
//REF: https://github.com/ekeric13/react-star-ratings  (For Styling)

export default class BTNStarRatings extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             rating: this.props.rating || 0
        }
        this.onChangeRating.bind(this);
    }
    
    onChangeRating = (newRating, name) => {
        this.setState({ rating: newRating });

        if(typeof this.props.onChangeRating !== undefined){
            this.props.onChangeRating(newRating);
        }
    }

    render() {
        let { name="rating", stars=5, active=true, showFractions=false,
                color="#F1B44C", dimension="14px", spacing="3px" } = this.props;
        let { rating } = this.state;
        if(!active){
            rating = this.props.rating;
        }

        return (
            <StarRatings
                name={name}    
                rating={rating || 0}
                numberOfStars={stars}
                changeRating={active ? this.onChangeRating : null}
                isSelectable={active}
                isAggregateRating={showFractions}
                starRatedColor={color}
                starDimension={dimension}
                starSpacing={spacing}
            />
        )
    }
}

