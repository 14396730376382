import React, { Component } from 'react'
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { createTimelineStatuses } from './scripts'

import TutorRequestDetails from './TutorRequestDetails'

import VerticalTimeline from 'components/timeline/VerticalTimeline'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';

import { ThemeSpinner } from 'theme/elements/spinner'
import TutorContractCard from 'components/tutorContracts/TutorContractCard'
import NavTabs from 'components/navTabs/NavTabs'
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton'

class TutorRequestProgress extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tutorRequestId: props.match.params.tutorRequestId,
            tutorRequest: {},
            potentialSelected: 0,

            isOpen: false
        }
        this.submitReviewDecision.bind(this);
        this.submitContractDecision.bind(this);
    }
    
    componentDidMount() {
        let { tutorRequestId } = this.state;
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorRequestProgressForStudent", tutorRequestId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    submitReviewDecision = async (formData) => {
        let {onSubmitApiCall} = this.props;
        await onSubmitApiCall("submitReviewStudentDecision", formData);

        let { id } = formData;
        let { tutorRequest, tutorRequest: { potentialTutors }, potentialSelected } = this.state;
        
        if(formData.decision == true){
            potentialSelected++;
        }
        
        potentialTutors = potentialTutors.filter(potentialTutor =>{
                                return potentialTutor.id != id;
                            });
        tutorRequest.potentialTutors = potentialTutors;
        this.setState({tutorRequest, potentialSelected});
    }

    submitContractDecision = async(formData) => {
        //Only submit NO to server.  YES redirects to Payment Plan page
        let { onSubmitApiCall } = this.props;
        await onSubmitApiCall("submitContractStudentDecision", formData);

        let { id } = formData;
        let { tutorRequest, tutorRequest: { tutorContracts } } = this.state;
        
        tutorContracts = tutorContracts.filter(tutorContract =>{
            return tutorContract.id != id;
        });
        
        tutorRequest.tutorContracts = tutorContracts;
        this.setState({tutorRequest});
    }

    render() {
        let { tutorRequest } = this.state;
        let { apiResponse } = this.props;
        let { potentialTutors =[], tutorContracts=[] } = tutorRequest;

        potentialTutors.sort((a,b) => a.studentDecision == false ? 1 : -1);
        tutorContracts.sort((a,b) => a.studentDecision == false ? 1 : -1);

        let loading = apiResponse.loading;
        let timelineStatuses = createTimelineStatuses(tutorRequest);
        let tabs = [];
        let tabId = 1;
        if(tutorContracts.length > 0){
            tabs.push({id: tabId, title: "Available Tutors - " + tutorContracts.length, 
                        content: <>
                            <h2>Available Tutors ({tutorContracts.length})</h2>
                            <p>Review the proposals below and select the tutor(s) you would like to work with. <b>You have three days to accept a proposal</b>.</p>

                            {!tutorRequest.scholarship &&
                                <>
                                    <b>Please note:</b><br />
                                    <ul>
                                        <li>You will be prompted to add a payment method (e.g. credit/debit card) to your account.</li>
                                        <li>To access the tutor's contact information, you will be required to pay for the first hour upfront. Subsequent sessions will be charged to your stored card <b>after</b> they occur.</li>
                                        <li><b>24 Hour Full Refund Guarantee</b> - If an issue or concern arises with your selected tutor (e.g. scheduling conflicts, unresponsive tutor), we'll fully refund your prepayment.</li>
                                        <li>If you accidentally declined a tutor, simply reload this page to change your decision. </li> 
                                    </ul>
                                </>
                            }
                            {
                            tutorContracts.map((contract, i) =>
                                <TutorContractCard key={"contract-" + i} contract={contract} stage={"confirm"} scholarship={tutorRequest.scholarship || false} onSubmitDecision={this.submitContractDecision} />
                            )}</> 
                        });
            tabId++;
        }

        if(potentialTutors.length > 0){
            tabs.push({id: tabId, title: "Tutors To Consider - " + potentialTutors.length, 
                        content: <>
                            <h3>Tutors To Consider({potentialTutors.length})</h3>
                            <p>While the tutors below do not meet your criteria perfectly, they may still a good match.</p> 
                            <p>
                                Click <i className="bx bx-check" /> To Express Interest In Tutor & Check Availability<br />
                                Click <i className="bx bx-x" /> To Dismiss Tutor
                            </p>
                            <ul>
                                <li>Review tutor profiles and rate each tutor listed below. </li>    
                                <li>Each approved tutor will be contacted to confirm availability and rate.</li>                                            
                                <li>Once tutors have confirmed availability, you will receive an email alert and you can select the tutor(s) you want to work with.</li>
                                <li>Tutors have <b>ONE DAY</b> to respond. </li>
                                <li><b>Your response here is NOT binding.</b> You can change your decision at any point.</li>
                                <li>If you accidentally dismissed a tutor, simply reload this page to change your decision </li>
                            </ul> 
                            {
                            potentialTutors.map((proposal, i) =>
                                <TutorContractCard key={"potential-" + i} contract={proposal} onSubmitDecision={this.submitReviewDecision} />
                                )}</> 
                        });
        }

        return (
            <>
                <>
                    <BTNMetaTags title="Tutor Request Progress" />
                    
                    <Row>
                        <Col sm={4}>
                            {
                                tutorRequest.id == null
                                    ?   
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Tutor Request Details</CardTitle>
                                                <br /><br />
                                                <center><ThemeSpinner /></center>
                                                <br /><br />
                                            </CardBody>
                                        </Card>
                                    :   
                                        <TutorRequestDetails key={"request-" + tutorRequest.id == null ? "" : tutorRequest.id} tutorRequest={tutorRequest}/>
                            }
                        </Col>
                        <Col sm={8}  id="progress-timeline">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tutor Request Progress</CardTitle>
                                    {
                                        tutorRequest.id == null
                                            ?   <ThemeSkeleton />
                                            :   <VerticalTimeline key={"vtime-" + tutorRequest.id} statuses={timelineStatuses}/>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* {
                       tutorContracts.length > 0 && 
                            <Row id="tutor-proposals">
                                <Col lg={12}>
                                    <Card>
                                        <CardBody className={"text-dark"}>
                                            <h2>Select A Tutor ({tutorContracts.length})</h2>
                                            <p>Review the proposals below and select the tutor(s) you would like to work with. You have three days to accept a proposal.</p>
                                            <p>
                                                Click <i className="bx bx-check" /> To Accept Proposal <br />
                                                Click <i className="bx bx-x" /> To Decline Proposal
                                            </p>
                                            {!tutorRequest.scholarship &&
                                                <>
                                                    <b>Please note:</b><br />
                                                    <ul>
                                                        <li>You will be prompted to add a payment method (e.g. credit/debit card) to your account.</li>
                                                        <li>To access the tutor's contact information, you will be required to pay for the first hour upfront.</li>
                                                    </ul>
                                                </>
                                            }
                                            <div  style={{backgroundColor: "#afcfee", padding: "1em"}}>
                                                {
                                                    tutorContracts.length > 0
                                                        ?
                                                            <SwiperPagination loop={false} centeredSlides={false} slides=
                                                                {
                                                                    tutorContracts.map((contract, i) =>
                                                                        <TutorContractForStudent key={i} contract={contract} stage={"confirm"} scholarship={tutorRequest.scholarship || false} onSubmitDecision={this.submitContractDecision} />
                                                                    )
                                                                }>
                                                            </SwiperPagination>
                                                        :
                                                            <ConfirmationPage className={"bx bx-search-alt text-warning"} title={"No Matches"}>
                                                                We'll search our network again for suitable tutors. <br />
                                                                If we are unable to find other tutors, you will receive an email alert.
                                                            </ConfirmationPage>
                                                }
                                            </div> 
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }

                    {
                        potentialTutors.length > 0 && 
                            <Row id="potential-tutors">
                                <Col xs={12}>      
                                    <h2>Rate Tutors ({potentialTutors.length})</h2>
                                    <p>Review the tutors below to let us know who you would be interested in working with.</p> 
                                    <p>
                                        Click <i className="bx bx-check" /> To Express Interest In Tutor <br />
                                        Click <i className="bx bx-x" /> To Dismiss Tutor
                                    </p>
                                    <ul>
                                        <li>Review tutor profiles and rate each tutor listed below. </li>    
                                        <li>Each approved tutor will be contacted to confirm availability and rate.</li>                                            
                                        <li>Once tutors have confirmed availability, you will receive an email alert and you can select the tutor(s) you want to work with.</li>
                                        <li>Tutors have <b>ONE DAY</b> to respond, if you do not hear back from a tutor within 1 day, feel free to select another tutor listed below.</li>
                                        <li><b>Your response here is NOT binding.</b> You can change your decision at any point.</li>
                                        <li>If you accidentally dismissed a tutor, simply reload this page to change your decision </li>
                                    </ul>                                
                                    {
                                        potentialTutors.length > 0 
                                            ?
                                                <>    
                                                    <SwiperPagination loop={false} centeredSlides={false} slides=
                                                        {
                                                            potentialTutors.map((proposal, i) =>
                                                                <TutorContractForStudent key={i} contract={proposal} onSubmitDecision={this.submitReviewDecision} />
                                                            )
                                                        }>
                                                    </SwiperPagination>
                                                    *  The listed rate is an estimate and has not been confirmed by the tutor. <br />
                                                    ** Your response is NOT binding, it simply alerts the tutor to your request.
                                                </>
                                            :
                                                <>
                                                    {
                                                        potentialSelected == 0 
                                                            ?
                                                                <ConfirmationPage className={"bx bx-search-alt text-warning"} title={"No Matches"}>
                                                                    We'll search our network again for suitable tutors. <br />
                                                                    If we are unable to find other tutors, you will receive an email alert.
                                                                </ConfirmationPage>
                                                            :
                                                                <ConfirmationPage title={"Submissions Successful"}>
                                                                    We will alert approved tutors and they will have 1 day to submit a proposal.  <br />
                                                                    You will receive an email alert as proposals come in.
                                                                </ConfirmationPage>
                                                    }
                                                </>
                                    }
                                </Col>
                            </Row>
                    }
                    <hr /> */}
                    <NavTabs key={"tabs-" + tabs.length} tabs={tabs}/>
                    
                    {/* {
                        tutorRequest.id && 
                            <AdsForTutorRequestProgress key={"ads-" + tutorRequest.id} tutorRequestId={tutorRequest.id || null} />
                    } */}
                    
                </>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorRequestProgress)