const allFilter = () => {return true};
const unreadFilter = chat => { return chat.isUnread }
const readFilter = chat => { return chat.isUnread == null || chat.isUnread == false }
const flaggedFilter = chat => { return chat.flagged }
const disabledFilter = chat => { return chat.disabled }
const matchedFilter = chat => { return chat.matched }
const notMatchedFilter = chat => { return !chat.matched }
const blockedFilter = chat => { 
    let hasBlockedParticipant = false;
    if(chat.participants != null && chat.participants.length > 0){
        chat.participants.forEach(participant => {
            if(participant.blocked == true){
                hasBlockedParticipant = true;
            }
        })
    }
    return hasBlockedParticipant;
}

export const AdminFilterOptionsArray = [
    {id: 1, title: "All", filter: allFilter},
    {id: 2, title: "Unread", filter: unreadFilter},
    {id: 3, title: "Flagged", filter: flaggedFilter},
    {id: 4, title: "Disabled", filter: disabledFilter},
    {id: 5, title: "Blocked", filter: blockedFilter},
    {id: 6, title: "Matched", filter: matchedFilter},
    {id: 7, title: "Not Matched", filter: notMatchedFilter},
    // {id: 8, title: "No Sessions"},
    // {id: 9, title: "Deleted"},
];

export const UserFilterOptionsArray = 
[
    {id: 1, title: "All", filter: allFilter},
    {id: 2, title: "Unread", filter: unreadFilter},
    {id: 3, title: "Read", filter: readFilter},
    // {id: 4, title: "Matched", filter: matchedFilter},
    {id: 5, title: "Blocked", filter: blockedFilter},
];

export const searchForUser = (chat={}, searchTerm="") => {
    let isPresent = false;
    let participants = chat.participants || [];
    
    participants.forEach(participant => {
        if(participant.userName && participant.userName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0){
            isPresent = true;
        }  
    })
    
    return isPresent;
}
