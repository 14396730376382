import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { formatDate } from 'scripts/general';
import { ADMIN_TUTOR_APPLICATION_DETAILS } from 'routes/paths'
import ThemeAvatar from 'theme/images/ThemeAvatar';

import { getCurrencySymbol } from 'scripts/general';
import { ThemeBadge, ThemeSoftBadge } from 'theme/elements/badges';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

export default class AdAccountDetails extends Component {
    render() {
        let { tutorApplications=[], adAccount={} } = this.props;

        return (
            <div>
                <Row>
                    <Col lg="8">
                        <Card>
                            <CardBody>
                                <CardTitle>Ad Account Details</CardTitle>
                                
                                {
                                    adAccount.inPerson === true &&
                                        <ThemeSoftBadge color="info">In Person</ThemeSoftBadge>
                                }
                                {" "}
                                {
                                    adAccount.online === true &&
                                        <ThemeSoftBadge color="success">Online</ThemeSoftBadge>
                                }
                                <br /><br />
                                {
                                    adAccount.avatar != null && 
                                        <ThemeAvatar avatar={adAccount.avatar} />
                                }
                                
                                <b>ID:</b> {adAccount.id} / {adAccount.refId}<br />
                                <b>Major:</b> {adAccount.major} @ {adAccount.school}<br />
                                {
                                    adAccount.education && 
                                        <><b>Education:</b> {adAccount.education} {adAccount.educationVerified && <b>Verified</b>}<br /></>
                                }
                                {
                                    (adAccount.minRate != null || adAccount.maxRate != null) &&
                                        <><b>Rate:</b> {getCurrencySymbol(adAccount.currency || "usd")}{adAccount.minRate} - {adAccount.maxRate}/hr<br /></>
                                }
                                <b>Quality Score (Account / Admin):</b> {adAccount.qualityScoreAccount} / {adAccount.qualityScoreAdmin} <br />

                                {
                                    adAccount.banned === true &&
                                        <ThemeBadge color="danger">BANNED</ThemeBadge>
                                }
                                {
                                    adAccount.disabled === true &&
                                        <ThemeSoftBadge color="warning">Disabled</ThemeSoftBadge>
                                }
                                <br />

                                {tutorApplications.length > 0 && <CardTitle>Applicant Details</CardTitle>}
                                {
                                    tutorApplications.map((application, i) =>
                                        <>
                                            <li>ID: {application.id}, Submitted: {formatDate(application.createdAt)} 
                                                <Link className="pl-2" to={ADMIN_TUTOR_APPLICATION_DETAILS + "/" + application.id}
                                                        target="_blank">
                                                    View Application
                                                </Link>
                                            </li>
                                        </>
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row>
                    <Col lg="8">
                        <Card>
                            <CardBody>
                                <CardTitle>Courses</CardTitle>
                                <p style={{ whiteSpace: "pre-wrap"}}>{adAccount.courses}</p>

                                <br />

                                <CardTitle>Experience</CardTitle>
                                <div style={{ whiteSpace: "pre-wrap"}}>{adAccount.experience}</div>

                                {
                                    adAccount.adminNotes && 
                                        <>
                                            <br /><br />
                                            <CardTitle>Admin Notes</CardTitle>
                                            <div style={{ whiteSpace: "pre-wrap"}}>{adAccount.adminNotes}</div>
                                        </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </div>
        )
    }
}
