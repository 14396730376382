import { get, post } from "../helpers/api_helper"
import { GET_GMAIL_INBOX, 
            GET_THREAD_MESSAGES,
            POST_SEND_GMAIL_EMAIL} from "../api/urls"


export const getGmailInbox = () => {
    return get(GET_GMAIL_INBOX);
}

export const getThreadMessages = threadId => {
    return get(GET_THREAD_MESSAGES + "/" + threadId);
}

export const postSendGmailEmail = (googleEmailData) => {
    //send emails to self when running test environments
    if(process.env.NODE_ENV !== "production"){
        googleEmailData.subject +=  " - " + googleEmailData.toEmailAddress;
        googleEmailData.toEmailAddress = "Contact@BuffTutor.com";
    }
    
    return post(POST_SEND_GMAIL_EMAIL, googleEmailData);
}