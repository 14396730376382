import React, { Component } from "react"
import { connect } from "react-redux"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import {
  Container,
  Row, Col,
  Alert,
  Card, CardBody, CardTitle,
  FormGroup, Label, Input,

  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
} from "reactstrap"

//Scripts
import { cleanUpString } from "scripts/general"

import SearchTutorDetails from "components/search/SearchTutorDetails"
import RateSlider from "components/slider/RateSlider"
import { ThemeSpinner } from "theme/elements/spinner"
import MapWithInfo from "components/maps/MapWithInfo"

class SearchTutors extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tutors: [],
      searchGeolocation: {},
      formData: {
        course: "",
        online: true,
        inPerson: true,
        tutorStatus: true,
        minRate: 20,
        maxRate: 50,
        zipcode: ""
      },
      sortedTutors: [],
      displayedTutors: [],
      sortBy: 2, //DEFAULT SORT OPTION ID
      sortOptions: [//{id: 1, key: "btnRank", title: "Recommended", order: "desc"},
                    {id: 2, key: "hoursCount", title: "Hours", order: "desc"},              
                    {id: 3, key: "distanceFromUser", title: "Distance (Nearest)", order: "asc"},
                    {id: 4, key: "id", title: "ID ASC", order: "asc"},
                    {id: 5, key: "id", title: "ID DESC", order: "desc"},
                  ],
      page: 1,
      perPage: 10,
      pagesCount: 1,
      error: "",
      loading: false
    }

    this.onChangeCheck.bind(this)
    this.onChangeValue.bind(this)
    this.onChangeRate.bind(this)
    this.onChangeOrder.bind(this)
    this.sortResults.bind(this)
    this.loadPageResults.bind(this)
    this.onSubmit.bind(this)

  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;

    if(prevProps != this.props){
        let newState = processApiResponse(apiResponse, this.state);
        if(apiResponse != {} && prevState != newState){
            this.setState(newState);
            //Process tutors
            if(prevState.tutors != newState.tutors){
              let {sortOptions, sortBy, perPage} = this.state;
              let tutors = newState.tutors;
              
              let sortOption = sortOptions.filter(option => {return option.id == sortBy})[0];
              
              if(newState.formData.zipcode != ""){
                sortOption = sortOptions.filter(option => {return option.key == "distanceFromUser"})[0];
                sortBy = sortOption.id;
              }

              let sortedTutors = tutors.sort(this.sortResults(sortOption.key, sortOption.order));
              let pagesCount = Math.ceil(sortedTutors.length / perPage);
  
              this.setState({ sortedTutors, tutors, pagesCount, sortBy });

              if(prevState.tutors = [] && newState.tutors != [] && sortedTutors.length > 0){
                this.loadPageResults(sortedTutors, 1);
              }
            }
        }
    }
  }

  onChangeCheck= (event) => {
    let { name } = event.target;
    this.setState(prevState => ({
        formData: {
          ...this.state.formData,
          [name]: !this.state.formData[name]
        }
    }));
  }

  onChangeValue = (event) => {
    const { name, value } = event.target;
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
    });
  }

  loadActiveTutors = () => {
    let { onSubmitApiCall } = this.props;
    onSubmitApiCall("postAdminLoadActiveTutors");
  }

  onChangeRate = (render, handle, sliderValue) => {
    this.setState({
        formData: {
          ...this.state.formData,
          minRate: sliderValue[0],
          maxRate: sliderValue[1],
        }
    });
  }

  onChangeOrder = (event) =>{
    const { name, value } = event.target;
    let sortBy = value;

    let sortOption = this.state.sortOptions.filter(option => {return option.id == sortBy})[0];
    let sortedTutors = this.state.tutors.sort(this.sortResults(sortOption.key, sortOption.order));

    this.setState({sortedTutors, sortBy});
    this.loadPageResults(sortedTutors, 1);
  }

  sortResults = (key, order = "desc") => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {

        return 0;
      }

      const valueA = (typeof a[key] === 'string')
        ? a[key].toLowerCase() : a[key];
      const valueB = (typeof b[key] === 'string')
        ? b[key].toLowerCase() : b[key];

      let comparison = 0;
      if (valueA < valueB) {
        comparison = 1;
      } else if (valueA > valueB) {
        comparison = -1;
      }

      return (
        (order !== 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  loadPageResults = (tutors, page) => {
    let {perPage} = this.state;
    let displayedTutors = tutors.slice((page - 1) * perPage, page * perPage);
    this.setState({ displayedTutors, page });
  }

  onSubmit = async (e, formData) => {
    e.preventDefault();
    let { onSubmitApiCall } = this.props;

    this.setState({formData, tutors: [], displayedTutors: []});
    //Clean Up String Fields Before Submit
    for (var key in formData) {
      if (typeof formData[key] == "string") {
        formData[key] = cleanUpString(formData[key]);
      }
    }
    onSubmitApiCall("submitTutorSearchAdmin", formData);
  }

  render() {
    let { tutors, searchGeolocation, displayedTutors, page, pagesCount, sortedTutors, sortOptions, sortBy, formData } = this.state;
    const { apiResponse } = this.props;
    let {minRate, maxRate, zipcode} = formData;
    
    let loading = apiResponse.loading
    let pageNumbersArray = [...new Array(pagesCount).keys()];

    let geolocations=[];
    let index = 1;
    tutors.forEach(tutor =>{
      geolocations.push({id: index, lat: tutor.lat, lng: tutor.lng, tutor});
      
      if(searchGeolocation.lat == null){
        searchGeolocation = {lat: tutor.lat, lng: tutor.lng}
      }
      index++;
    });

    return (
      <React.Fragment>
        <BTNMetaTags title="Search Tutors" />
        
        <Container fluid>
          {this.state.error && <Alert color="danger">{this.state.error}</Alert> }
          {loading && <center><ThemeSpinner /></center>}
          <Row>
            <Col sm="2">
              <button onClick={this.loadActiveTutors} className="btn btn-info btn-block waves-effect waves-light float-right">
                Load Active Tutors
              </button>
            </Col>
          </Row>
          <Form onSubmit={event => this.onSubmit(event, this.state.formData)}>
            <Row className="row-cols-md-auto g-3 align-items-center">
              <Col>
                <Card style={{marginBottom: "0px"}}>
                  <CardBody>
                    <div>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              type="text"
                              className="form-control"
                              id="course"
                              name="course"
                              placeholder="Course / Subject"
                              onChange={this.onChangeValue}
                              value={this.state.formData.course}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                              <Input
                                type="text"
                                className="form-control"
                                id="zipcode"
                                name="zipcode"
                                placeholder="Zipcode or City, State"
                                onChange={this.onChangeValue}
                                value={zipcode}
                              />
                          </FormGroup>
                        </Col>
                        <Col>
                          <Row>
                            <Col xs="6">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="online"
                                    name="online"
                                    checked={this.state.formData.online}
                                    onChange={this.onChangeCheck}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="online"
                                  >
                                    Online
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col xs="6">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="inPerson"
                                    name="inPerson"
                                    checked={this.state.formData.inPerson}
                                    onChange={this.onChangeCheck}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="inPerson"
                                  >
                                    In Person
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col sm="2">
                          <Row>
                            <Col>
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="tutorStatus"
                                    name="tutorStatus"
                                    checked={this.state.formData.tutorStatus}
                                    onChange={this.onChangeCheck}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="tutorStatus"
                                  >
                                    Accepting Students
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col> */}
                        <Col>
                          <h5 className="font-size-14 mb-4">Rate</h5>
                          <br />
                          <RateSlider onChange={this.onChangeRate} minRate={minRate} maxRate={maxRate}/>
                          <br />
                        </Col>
                        <Col>
                          <button
                            type="submit"
                            className="btn btn-success"
                          >
                              Search
                          </button> 
                        </Col>
                      </Row>
                    </div>  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col lg="6" style={{
              overflowY: 'scroll',
              border: "1px solid red",
              float: 'left',
              height:'70vh',
              position:'relative'
            }}>
              {
                displayedTutors.length > 0 && !loading &&
                <>
                  <Row>
                    <Col lg={"12"}>
                      <div className="mt-2">
                        <h5>{tutors.length} Tutors Near {searchGeolocation && searchGeolocation.city ? searchGeolocation.city : zipcode}</h5>
                      </div>
                      <FormGroup className="mb-4" row>
                        <Col md="10">
                          <select
                            className="form-control select2"
                            title="order"
                            value={sortBy}
                            onChange={this.onChangeOrder}
                          >
                            {sortOptions.map(option =>
                              <option value={option.id} key={option.id}>{option.title}</option>
                            )}
                          </select>
                        </Col>
                      </FormGroup>
                    </Col>

                    {
                      displayedTutors.map((tutor, key) => (
                        <Col xl="12" sm="12" key={"_col_" + key}>
                          <SearchTutorDetails tutor={tutor} />
                        </Col>
                      ))
                    }
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                        <PaginationItem disabled={page === 1}>
                          <PaginationLink
                            previous
                            href="#"
                            onClick={() => this.loadPageResults(sortedTutors, page - 1)}
                          />
                        </PaginationItem>
                        {pageNumbersArray.map(i => (
                          <PaginationItem active={i + 1 === page} key={i}>
                            <PaginationLink
                              onClick={() => this.loadPageResults(sortedTutors, i + 1)}
                              href="#"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={page === pagesCount}>
                          <PaginationLink
                            next
                            href="#"
                            onClick={() => this.loadPageResults(sortedTutors, page + 1)}
                          />
                        </PaginationItem>
                      </Pagination>
                    </Col>
                  </Row>
                </>
              }
            </Col>
            <Col lg="6" style={{
              height:'70vh',
            }}>
              {
                searchGeolocation.lat &&
                  <Row>
                    <Col>
                      <MapWithInfo 
                        key={searchGeolocation.lat}
                        centerLat={searchGeolocation.lat || null}
                        centerLng={searchGeolocation.lng || null}
                        // geolocations={[{id:1, lat: 39.73952522, lng: -105.00568665},
                        //   {id:2, lat: 40.01498400, lng: -105.27054600},
                        //   {id:3, lat: 39.75, lng: -105.00568665},]}
                        geolocations={geolocations}
                      />
                    </Col>
                  </Row>
              }
            </Col>
          </Row>

          {/* <Row>
            <Col lg="3">
              <Card>
                <CardBody>                   
                  <CardTitle className="mb-4">Search</CardTitle>
                  <div className="mt-4 pt-3">
                    <Form onSubmit={event => this.onSubmit(event, this.state.formData)}>
                      <FormGroup>
                        <Label for="course">
                          Course / Subject
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="course"
                          name="course"
                          placeholder="Course name or course number"
                          onChange={this.onChangeValue}
                          value={this.state.formData.course}
                        />
                      </FormGroup>
                      <FormGroup>
                          <Label for="zipcode">
                            Location
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="zipcode"
                            name="zipcode"
                            placeholder="Zipcode or City, State"
                            onChange={this.onChangeValue}
                            value={zipcode}
                          />
                      </FormGroup>
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="online"
                                name="online"
                                checked={this.state.formData.online}
                                onChange={this.onChangeCheck}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="online"
                              >
                                Online
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="inPerson"
                                name="inPerson"
                                checked={this.state.formData.inPerson}
                                onChange={this.onChangeCheck}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="inPerson"
                              >
                                In Person
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="tutorStatus"
                                name="tutorStatus"
                                checked={this.state.formData.tutorStatus}
                                onChange={this.onChangeCheck}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="tutorStatus"
                              >
                                Accepting Students
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <h5 className="font-size-14 mb-4">Rate</h5>
                      <br />
                      <RateSlider onChange={this.onChangeRate} minRate={minRate} maxRate={maxRate}/>
                      <br />
                      <button
                        type="submit"
                        className="btn btn-success"
                      >
                          Search
                      </button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="9">
              {
                displayedTutors.length > 0 && 
                <>
                  <Row>
                    <Col lg={"12"}>
                      <div className="mt-2">
                        <h5>{tutors.length} Tutors Near {searchGeolocation && searchGeolocation.city ? searchGeolocation.city : zipcode}</h5>
                      </div>
                      <FormGroup className="mb-4" row>
                        <Col md="10">
                          <select
                            className="form-control select2"
                            title="order"
                            value={sortBy}
                            onChange={this.onChangeOrder}
                          >
                            {sortOptions.map(option =>
                              <option value={option.id} key={option.id}>{option.title}</option>
                            )}
                          </select>
                        </Col>
                      </FormGroup>
                    </Col>

                    {
                      displayedTutors.map((tutor, key) => (
                        <Col xl="12" sm="12" key={"_col_" + key}>
                          <SearchTutorDetails tutor={tutor} />
                        </Col>
                      ))
                    }
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                        <PaginationItem disabled={page === 1}>
                          <PaginationLink
                            previous
                            href="#"
                            onClick={() => this.loadPageResults(sortedTutors, page - 1)}
                          />
                        </PaginationItem>
                        {pageNumbersArray.map(i => (
                          <PaginationItem active={i + 1 === page} key={i}>
                            <PaginationLink
                              onClick={() => this.loadPageResults(sortedTutors, i + 1)}
                              href="#"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={page === pagesCount}>
                          <PaginationLink
                            next
                            href="#"
                            onClick={() => this.loadPageResults(sortedTutors, page + 1)}
                          />
                        </PaginationItem>
                      </Pagination>
                    </Col>
                  </Row>
                </>
              }
            </Col>
          </Row> */}
            
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
  apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchTutors)