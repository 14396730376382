import React, { Component } from 'react'
import { connect } from "react-redux"
import { submitApiCallAction } from "store/actions"

import { CardTitle, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import BTNStarRatings from 'components/ratings/BTNStarRatings'
import { ThemeButton } from 'theme/elements/buttons'
import ThemeAvatar from 'theme/images/ThemeAvatar'

class EvaluationForm extends Component {
    //props MUST supply assignment
    constructor(props) {
        super(props)
    
        this.state = {
             rating: 5,
             tutorStrength: "",
             tutorWeakness: "",
             websiteReference: "",
             submitting: false
        }

        this.copyStrengths.bind(this);
        this.onChangeValue.bind(this);
        this.onChangeRating.bind(this);
        this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { apiResponse } = this.props;

        if(!apiResponse.loading && apiResponse.success && this.state.submitting){
            if(typeof this.props.onSubmit !== undefined){
                this.props.onSubmit(); //use to pass error message
            }
        }
    }
    
    copyStrengths = () =>{
        this.setState({websiteReference: this.state.tutorStrength});
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              ...this.state,
              [name]: value
        });
    }

    onChangeRating = rating =>{
        this.setState({ rating });
    }

    onSubmit = (event) => {
        event.preventDefault();
        
        this.setState({submitting: true});
        let { type="first", assignment, onSubmitApiCall } = this.props;
        let formData = {...this.state, type, assignmentId: assignment.id};
        
        onSubmitApiCall("postSubmitTutorEvaluation", formData);
    }


    render() {
        let { rating, tutorStrength, tutorWeakness, websiteReference } = this.state;
        let { type="first", assignment: { name, avatar } } = this.props;

        return (
            <>
                <Form>
                    <Row>
                        <Col xl={12} md={12}>
                            <ThemeAvatar avatar={avatar} name={name} />
                            <span style={{fontWeight: "bold"}}>
                                Rate {name}: 
                                <span style={{padding: "1em"}}>{" "}</span>
                                <BTNStarRatings rating={rating} onChangeRating={this.onChangeRating}/>    
                            </span>
                        </Col>
                        
                    </Row>  
                    <Row>
                        <CardTitle>This section is only shown to your tutor.</CardTitle>
                        <br />
                        <Col lg={12}>
                            <FormGroup>
                                <Label for="tutorStrength">
                                    Strengths:
                                </Label>
                                <textarea
                                    id="tutorStrength"
                                    name="tutorStrength"
                                    className="form-control"
                                    rows="2"
                                    placeholder="What you tutor excels at"
                                    onChange={this.onChangeValue}
                                    value={tutorStrength}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={12}>
                            <FormGroup>
                                <Label for="tutorWeakness">
                                    Weaknesses: 
                                </Label>
                                <textarea
                                    id="tutorWeakness"
                                    name="tutorWeakness"
                                    className="form-control"
                                    rows="2"
                                    placeholder="What your tutor needs to work on"
                                    onChange={this.onChangeValue}
                                    value={tutorWeakness}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        type=="end" &&
                            <Row>
                                <CardTitle>OPTIONAL: This section will show up on your tutor's profile.</CardTitle>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label for="websiteReference">
                                            Profile Reference: Tell the world how great (or not) your tutor is.
                                        </Label>
                                        <p>To copy the Strengths section, <a className="text-primary" onClick={this.copyStrengths}>click here</a>.</p>
                                        <textarea
                                            id="websiteReference"
                                            name="websiteReference"
                                            className="form-control"
                                            rows="3"
                                            placeholder="Why others should (or should not) work with this tutor"
                                            onChange={this.onChangeValue}
                                            value={websiteReference}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                    }
                       
                    
                    <Row>
                        {
                            this.state.submitting ?
                                <ThemeButton disabled={true} active={false} icon={"bx bx-loader bx-spin"}> Submitting </ThemeButton>
                            :
                                <ThemeButton onClick={this.onSubmit}> Submit </ThemeButton>
                        }
                    </Row>
                </Form>  
            </>
        )
    }
}
const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(EvaluationForm)