import React, { Component } from "react"
import { TabPane, Row, Col, FormGroup, FormFeedback, Label, Input } from "reactstrap"

class CourseTab extends Component {
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="school">Course</Label>
                <Input
                  id="course"
                  name="course"
                  placeholder="Calc 2 (MATH 121) or 10th Grade Writing"
                  type="text"
                  // errorMessage="Enter the course you need assistance with"
                  className="form-control"
                  // validate={{ required: { value: true } }}
                  onChange={this.props.onChange}
                  value={this.props.formData.course}
                  invalid= {this.props.invalidFields.includes("course") ? true : false}
                />
                {this.props.invalidFields.includes("course") &&
                    <FormFeedback>
                      Enter the course you need assistance with
                    </FormFeedback>
                  }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="school">School</Label>
                <Input
                  type="text"
                  id="school"
                  name="school"
                  placeholder="School"
                  // errorMessage="Enter the school you are taking your class"
                  className="form-control"
                  // validate={{ required: { value: true } }}
                  onChange={this.props.onChange}
                  value={this.props.formData.school}
                  invalid= {this.props.invalidFields.includes("school") ? true : false}
                />
                {this.props.invalidFields.includes("school") &&
                  <FormFeedback>
                    Enter the school you are taking your class
                  </FormFeedback>
                }
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default CourseTab
