import React, { Component } from 'react'
import { connect } from 'react-redux';
// import CardForm from 'components/stripe/CardForm';
import PaymentForm from 'components/stripe/PaymentForm';


import { createPaymentIntent } from 'scripts/stripe';

class PaymentMethodDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             submitting: false,
        }
        this.onSubmitCard.bind(this);
        this.chargeStoredPaymentMethod.bind(this);
    }
    
    onSubmitCard = ({results, error}) => {
        if(typeof this.props.onSubmit != undefined){
            this.props.onSubmit({results, error});
        }
    }

    chargeStoredPaymentMethod = async (metadata) => {
        this.setState({submitting: true});
        let results = await createPaymentIntent(metadata);

        if(typeof this.props.onSubmit != undefined){
            this.props.onSubmit({results: true, error: results.error});
        }
    }

    render() {
        let { paymentMethod, total, currency, savePaymentMethod=true, chargePaymentMethod=true } = this.props;
        let { cart={} } = this.props;
        let { submitting } = this.state;
        
        total = total == undefined ? cart.total : total;
        currency = currency == undefined ? cart.currency : currency;
        
        return (
            <>
                {
                    paymentMethod.method == "card" &&

                       <PaymentForm 
                            amount={total}
                            currency={currency}
                            metadata={chargePaymentMethod ? { cart } : {}}
                            saveCard={savePaymentMethod}
                            chargeCard={chargePaymentMethod}
                            onSubmit={this.onSubmitCard} />
                }
                {
                    paymentMethod.method == "storedCard" &&
                        <button
                            className="btn btn-warning waves-effect waves-light"
                            disabled={submitting ? true : false}
                            onClick={() => this.chargeStoredPaymentMethod({cart, method: "card", cardId: paymentMethod.id })}
                            >
                            {
                                submitting ?
                                <>
                                    Submitting {" "}{" "}{" "}
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                </>
                                :
                                <>
                                    Submit Payment: {total}    
                                </>
                            }
                        </button>
                }

                {
                    paymentMethod.instructions != null && paymentMethod.instructions != "" &&
                        <>
                            <h2>Instructions</h2>
                            <p>{paymentMethod.instructions}</p>
                        </>
                }
            </>
        )
    }
}

const mapStateToProps = ({ cartReducer}) => ({
    cart: cartReducer
  })

export default connect(mapStateToProps, null)(PaymentMethodDetails)