import React, { Component } from 'react'

import BTNStarRatings from 'components/ratings/BTNStarRatings'

export default class TutorRatingStep extends Component {
  
  onChangeRating = (name, rating) => {
    this.props.onChangeStarRating(name, rating);
  }

  render() {
    let tutorName = null;

    let { data=null } = this.props;

    if(data != null){
      let { firstName = null } = data;
      tutorName = firstName;
    }

    let formLayout = "version2"; // or job 1
    let radioGroupClass = "";
    let radioDivClass = "";
    let radioClass = ""; 
    let radioLabelClass = "";

    switch (formLayout) {
      case "job1":
        radioGroupClass = "check-gender";
        radioDivClass = "custom-radio";
        radioClass = "custom-control-input"; 
        radioLabelClass = "custom-control-label";
        break;

      case "version2":
        radioClass = "form-control"; 
        break;

      default:
        break;
    }
    
    return (
      <>
        <div className="pb60">
          <h4><b>Knowledgeable</b></h4>
          <p style={{fontSize: "1.15rem"}}>
            Did {tutorName || "the tutor"} have a solid understanding of the course material?
          </p>
          <BTNStarRatings dimension="2rem" onChangeRating={rating => this.onChangeRating("knowledge", rating)} />
        </div> 

        <div className="pb60">
          <h4><b>Effectiveness</b></h4>
          <p style={{fontSize: "1.15rem"}}>
            Did {tutorName || "the tutor"} improve your understanding of the course material?
            Did your grade in the class improve?
          </p>
          <BTNStarRatings dimension="2rem" onChangeRating={rating => this.onChangeRating("effective", rating)} />
        </div>  
        
        <div className="pb60">
          <h4><b>Professionalism</b></h4>
          <p style={{fontSize: "1.15rem"}}>
            Was {tutorName || "the tutor"} prepared for sessions? 
            Was {tutorName || "the tutor"} reliable and respectful?
          </p>
          <BTNStarRatings dimension="2rem" onChangeRating={rating => this.onChangeRating("professional", rating)} />
        </div>

        <div className="pb60">
          <h4><b>Communication</b></h4>
          <p style={{fontSize: "1.15rem"}}>
            Did {tutorName || "the tutor"} respond to your emails/calls/texts in a timely manner? 
          </p>
          <BTNStarRatings dimension="2rem" onChangeRating={rating => this.onChangeRating("communication", rating)} />
        </div>

        <div className="pb60">
          <h4><b>Value</b></h4>
          <p style={{fontSize: "1.15rem"}}>
            Do you feel like you got your money's worth?
          </p>
          <BTNStarRatings dimension="2rem" onChangeRating={rating => this.onChangeRating("value", rating)} />
        </div>

        <h4>Would you recommend {tutorName || "the tutor"} to your friends, family or classmates?</h4>
        <div className={`form-group ${radioGroupClass} pb40`}> 
          <div className={radioDivClass}>
            <input type="radio" name="recommend" value={true} className={radioClass} id="recommend-yes" onChange={this.props.onChange}/>
            <label className={radioLabelClass} htmlFor="recommend-yes">Yes</label>
          </div>
          <div className={radioDivClass}>
            <input type="radio" name="recommend" value={false} className={radioClass} id="recommend-no" onChange={this.props.onChange} />
            <label className={radioLabelClass} htmlFor="recommend-no">No</label>
          </div>
        </div>
      </>
    )
  }
}
