import React, { Component } from 'react'
import BasicTour from 'components/tours/BasicTour';

import reviewProposals from "assets/images/how-it-works/students/01-Student-Review_Proposals.png";
import chatImage from "assets/images/how-it-works/students/02-Chat_With_RequestDetails.png";

export default class TutorRequestProgressTour extends Component {
    constructor(props) {
        super(props)
        this.state = {
            steps: [
                {
                    
                    target: 'body',
                    content: <div>Submitted proposals will show up below. Click each proposal to review the tutor's profile (including reviews) and to chat with the tutor. <br /><br /><img src={reviewProposals} className="img-fluid" alt="How It Works" style={{border: "1px lightgray solid", borderRadius: "2%"}} /></div>,
                    title: 'Review Tutor Proposals',
                    placement: 'center',
                },
                {
                    target: 'body',
                    content: <div>Use our Chat Application to discuss your needs with potential tutors. <br /><br /> <img src={chatImage} className="img-fluid" alt="How It Works" style={{border: "1px lightgray solid", borderRadius: "2%"}} /></div>,
                    title: 'Chat With Tutors',
                    placement: 'center',
                    
                },
                {
                    target: 'body',
                    content: <div>To select a tutor to work with, click "Accept Proposal" and prepay for the first hour. Then get to work!</div>,
                    title: 'Get To Work',
                    placement: 'center',
                },
              ]
        }
    }

    render() {
        let { steps } = this.state;

        return (
            <>
                <BasicTour steps={steps} showSkipButton={false} />
            </>
        )
    }
}
