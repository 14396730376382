import React, { Component } from 'react'
import { connect } from "react-redux"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { TUTOR_CONTRACTOR_AGREEMENT } from 'routes/paths'

import { ThemeSpinner } from 'theme/elements/spinner'
import { ThemeButton, ThemeButtonLink } from 'theme/elements/buttons'

class ReviewContract extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            contractorAgreementURL: ""
        }
        this.getContractorAgreement.bind(this)
    }
    
    componentDidMount() {
        this.getContractorAgreement();    
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    getContractorAgreement = () =>{
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorContractorAgreementTemplate");

    }
    
    render() {
        let { contractorAgreementURL } = this.state;
        let { apiResponse, tutorApplicationReducer: { tutorApplication } } = this.props;

        let loading = apiResponse.loading;
        let offered = tutorApplication.offered || false;
        let hired = tutorApplication.hired ? tutorApplication.hired : false;

        return (
            <>
                <p>Please carefully review and sign the contract. <br/> <br />
                    *** After you sign the contract, you may need to log out and back into your account to access tools for hired tutors.  Your Dashboard will no longer show the Tutor Applicant Checklist</p>
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }

                {
                    offered && contractorAgreementURL &&
                        <a href={contractorAgreementURL} target="_blank" rel="noreferrer">Review Contract</a>
                }
                <br /> <br />
                {
                    hired 
                        ?
                            
                            <ThemeButtonLink to={TUTOR_CONTRACTOR_AGREEMENT}>Sign Contract</ThemeButtonLink>   
                        :
                            <>
                                <ThemeButton disabled={true}>Sign Contract</ThemeButton>   
                                <small>You have not been offered a position yet.</small>
                            </>
                }
                
            </>
        )
    }
}

const mapStateToProps = ({ tutorApplicationReducer, apiResponseReducer }) => ({
    tutorApplicationReducer,
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ReviewContract)