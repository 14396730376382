import React, { Component } from "react"
import { connect } from "react-redux"
import BTNMetaTags from "components/metatags/BTNMetaTags"

import {
  Container
} from "reactstrap"

import VerticalTabs from "components/tabs/VerticalTabs"

import EditUserInfoForm from "components/forms/edit-account/EditUserInfoForm"
import EditProfileForm from "components/forms/edit-profile/EditProfileForm"

class EditAccount extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       userId: props.match.params.userId,
       tabs: []
    }
  }
  
  componentDidMount() {
    this.createTabs();
  }

  createTabs = () => {
    const { user } = this.props;
    let { userId } = this.state;
    
    let tabs = [{ id: 1, title: "Contact Information", icon: "bx bx-phone", component: EditUserInfoForm, props: { userId } },]
    
    let roles = user.roles || []
    
    if(roles.includes("tutor") || (userId != user.id && roles.includes("admin"))){
      tabs.push({ id: 2, title: "Edit Profile", icon: "bx bx-user", component: EditProfileForm, props: {tutorId: userId} })
    }

    this.setState({tabs});
  }

  render() {
    let { tabs } = this.state;

    return (
      <React.Fragment>
        <BTNMetaTags title={`Edit Account`} />
        
        <Container fluid={true}>
          {
            tabs.length > 0 &&
              <VerticalTabs key={"vtabs-" + tabs.length} tabs={tabs}/>
          }
          
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ userReducer }) => ({
  user: userReducer
})

export default connect(mapStateToProps, null)(EditAccount)


