import React, { Component } from "react"

import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from "yup";

import { FormGroup, Input } from "reactstrap";

export default class ForgotPasswordForm extends Component {
  handleSubmit = (values, actions) => {
    this.props.onSubmit(values);
    // actions.setSubmitting(false);
  }

  render() {
    const PasswordSchema = Yup.object().shape({
                          email: Yup.string()
                                  .email('Invalid email')
                                  .required('Required')
                        });

    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={PasswordSchema}
        onSubmit={this.handleSubmit}
      >
      { props  => (
          <Form className="needs-validation">
            <FormGroup className="mb-3">
              <Input
                name="email"
                label="Email"
                value={props.values.email}
                className="form-control"
                placeholder="Enter email"
                type="email"
                required
                autoFocus={true}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <ErrorMessage name="email" component="div" className="text-danger" />
            </FormGroup>

            {this.props.children}
            
          </Form>
      )}
      </Formik>
    )
  }
}
