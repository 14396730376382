import React, { Component } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ChatDocFileSvg } from "theme-chat/assets/media/icons/chatdocmessage.svg";

import { ReactComponent as ChatPlusSvg } from "theme-chat/assets/media/icons/chatplus.svg";
import { ReactComponent as GallarySvg } from "theme-chat/assets/media/icons/gallary.svg";
import { ReactComponent as SendMessageSvg } from "theme-chat/assets/media/icons/sendmessage.svg";

import { Dropdown } from "react-bootstrap";
import { checkIfEmailInString, checkIfURLInString } from "scripts/chat";
// import { checkAllLinkTypes } from "scripts/chat";

export default class ChatFooter extends Component {
//props: showLinks, onSendMessage, chatDisabled
  constructor(props) {
        super(props);
        this.state = {
          
          message: "",
    
          chatFile: "",
          chatImage: "",
          fileError: "",
          fileName: "",
          textError: "",
        }
    }

  onChangeMessage = event => {
    event.preventDefault();
    let message = event.target.value;

    let textError = null;

    const { showLinks } = this.props;
    // if(!showLinks && (checkAllLinkTypes(message))){
    if(!showLinks && (checkIfEmailInString(message) || checkIfURLInString(message))){
        textError =   "No Contact Information Or Links Allowed Until A Match Is Created.";
    }

    this.setState({message, textError});
  }

  handleEmojiSelect = (param) => {
    this.setState({ message: this.state.message + param.native });
  };

  onKeyDown = e => {
      const { key, /*value,*/ shiftKey } = e

      if (key === "Enter" && shiftKey) {
        this.sendMessage();
      }
  }
    
  onChangeFile = event => {
    event.preventDefault();

    let chatFile = event.target.files[0];
    let maxSizeMB = 10; //10 MB
    let maxSize = 1024 * 1024 * maxSizeMB; //in bytes

    if (chatFile && chatFile.size > maxSize) {
      this.setState({chatImage: "", fileError: "File too large.  Max size is " + maxSizeMB +"MB", fileName: ""});
    }
    else{
      this.setState({ chatFile, chatImage: "",
                        fileName: chatFile.name, fileError: "" });
    }
    event.target.value = "";
  }

  onChangeImage = event => {
    event.preventDefault();
    let chatImage = event.target.files[0];
    let maxSizeMB = 10; //10 MB
    let maxSize = 1024 * 1024 * maxSizeMB; //in bytes

    if (chatImage && chatImage.size > maxSize) {
      this.setState({chatFile: "", fileError: "File too large.  Max size is " + maxSizeMB +"MB", fileName: ""});
    }
    else{
      this.setState({ chatImage, chatFile: "",
                        fileName: chatImage.name , fileError: ""});
    }
    event.target.value = "";
  }

  removeFile = () => {
    this.setState({ chatImage: "", chatFile: "", fileName: "", fileError: ""});
  }
    
  sendMessage = () => {
    const { threadId, user, disableChat } = this.props;
    const { chatFile, chatImage, message} = this.state;

    if(disableChat){
        this.setState({ message: "", 
                        chatFile: "", chatImage: "",
                        fileName: "", fileError: "" });
        return;
    }

    if(threadId != null && threadId != ""){
        const messageObject = {
            id: Math.floor(Math.random() * 100),
            threadId,
            userId: user.id,
            body: message.trim(),
            createdAt: new Date(),
            chatFile,
            chatImage,
        }
        
        this.setState({ message: "", 
                        chatFile: "", chatImage: "",
                        fileName: "", fileError: "" });
        
        this.props.onSendMessage(messageObject);
    }
  }

  render() {
      const { message, fileError, fileName, textError } = this.state;
      const { disableChat } = this.props;

      return (
          <div className="chat-footer">
            <div className="form-row align-items-center">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend mr-sm-2 mr-1">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="text-muted hw-20"
                        as={ChatPlusSvg}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        <Link className="dropdown-item" to="#" onClick={() => this.imageUpload.click()}>
                          <GallarySvg className="hw-20 mr-2" />
                          <span>Image</span>
                        </Link>
                        <Link className="dropdown-item" to="#" onClick={() => this.fileUpload.click()}>
                          <ChatDocFileSvg className="hw-20 mr-2" />
                          <span>File</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <textarea className="form-control no-resize"
                    style={textError ? {border: "1px red solid"} : {border: "none", outline: "none"}}
                    name="message"
                    value={message}
                    rows={3}
                    onKeyDown={this.onKeyDown}
                    onChange={this.onChangeMessage}
                    placeholder="Press Shift + Enter to send" 
                    autoFocus={true} />
                  
                  {fileName && 
                        <>
                            <small className="text-primary">{fileName.length > 15 ? fileName.substring(0, 15) + "..." : fileName}</small> {" "}
                            <Link
                                to="#"
                                className="text-danger font-weight-bold"
                                >
                                <i className="bx bx-trash" onClick={this.removeFile}></i>
                            </Link>
                        </>
                  }
                  <input type="file" 
                      style={{display: "none"}}
                      id={"fileUpload"} 
                      name={"fileUpload"} 
                      // accept={acceptedFiles} 
                      onChange={this.onChangeFile}
                      ref={(fileUpload) => {
                          this.fileUpload = fileUpload;
                        }}
                      />
                  
                  <input type="file" 
                      style={{display: "none"}}
                      id={"imageUpload"} 
                      name={"imageUpload"} 
                      accept={"image/*"} 
                      onChange={this.onChangeImage}
                      ref={(imageUpload) => {
                          this.imageUpload = imageUpload;
                        }}
                      />
                </div>
                {textError && <small className="ml-4" style={{color: "red"}}>{textError}</small>}
                {fileError && <small className="text-danger">{fileError}</small>}
              </div>
              <div className="col-auto">
              {
                  disableChat ?
                    <button className="btn btn-secondary btn-icon rounded-circle text-light mb-1"
                        disabled={true}>
                      <SendMessageSvg />
                    </button>

                  :
                    <button className="btn btn-primary btn-icon rounded-circle text-light mb-1"
                      onClick={this.sendMessage}>
                      <SendMessageSvg />
                    </button>
                }
              </div>
            </div>
          </div>
      )
  }
}
