import { get, post, patch } from "../helpers/api_helper"
import { POST_TUTOR_REQUEST, 
          GET_TUTOR_REQUEST_PROGRESS_FOR_STUDENT,
          GET_CURRENT_TUTOR_REQUESTS_FOR_STUDENT,

          PATCH_DELETE_TUTOR_REQUEST,
          GET_TUTOR_REQUEST
        } from "../api/urls"

export const getTutorRequest = tutorRequestId => {
   return get(GET_TUTOR_REQUEST + "/" + tutorRequestId);
}

export const getTutorRequestProgressForStudent = tutorRequestId => {
   return get(GET_TUTOR_REQUEST_PROGRESS_FOR_STUDENT + "/" + tutorRequestId);
}

export const getTutorRequestsForStudent = studentId => {
   return get(GET_CURRENT_TUTOR_REQUESTS_FOR_STUDENT + "/" + studentId);
}

export async function submitTutorRequest(formData){
   return post(POST_TUTOR_REQUEST, formData);
}

export async function deleteTutorRequest(tutorRequestId){
   return patch(PATCH_DELETE_TUTOR_REQUEST + "/" + tutorRequestId);
}
