import moment from "moment";

const filterUnreviewed = marketRequest => { return marketRequest.proposalSubmitted == null};
const filterSubmitted = marketRequest => { return marketRequest.proposalSubmitted == true};

export const requestFilterOptions = [
    {id: 1, title: "All", filter: () => {return true}},
    {id: 2, title: "Unreviewed", filter: filterUnreviewed},
    {id: 3, title: "Submitted", filter: filterSubmitted},
]

const filterStudentsMissedDeadline = marketRequest => { 
    let missedAllDeadlines = marketRequest.proposals && marketRequest.proposals.length > 0 ? true : false;
    let pendingCount = 0;

    marketRequest.proposals.forEach(proposal =>{
        if(moment(proposal.studentDeadline).isAfter(moment())){
            pendingCount++;
        }
    })

    return pendingCount > 0 ? false : missedAllDeadlines;
}

const filterUnreviewedProposals = marketRequest => {
    let allUnreviewed = marketRequest.proposals && marketRequest.proposals.length > 0 ? true : false;

    marketRequest.proposals.forEach(proposal => {
        if(proposal.reviewed != null){
            allUnreviewed = false;
        }
    })

    return allUnreviewed;
}

const filterPendingStudentResponse = marketRequest => { 
    let pendingResponse = false;
    marketRequest.proposals.forEach(proposal =>{
        if(moment(proposal.studentDeadline).isAfter(moment())){
            pendingResponse = true;
        }
    })

    return pendingResponse;
}

const filterPendingTutorResponse = marketRequest => { 
    return marketRequest.noTutorAvailable != true 
            && moment(marketRequest.proposalsDeadline).isAfter(moment()) 
            && marketRequest.proposals.length == 0;
}

const filterTutorsMissedDeadline = marketRequest => { 
    return marketRequest.noTutorAvailable != true 
            && moment(marketRequest.proposalsDeadline).isBefore(moment()) 
            && marketRequest.proposals.length == 0
}

const filterUnassigned = marketRequest => { return marketRequest.noTutorAvailable == true};

export const requestFilterOptionsAdmin = [
    {id: 1, title: "All", filter: () => {return true}},
    {id: 2, title: "Unreviewed Proposals", filter: filterUnreviewedProposals},
    {id: 3, title: "Pending Student Response", filter: filterPendingStudentResponse},
    {id: 4, title: "Pending Tutor Response", filter: filterPendingTutorResponse},
    {id: 5, title: "Tutors Missed Deadline", filter: filterTutorsMissedDeadline},
    {id: 6, title: "Students Missed Deadline", filter: filterStudentsMissedDeadline},
    {id: 7, title: "Unassigned", filter: filterUnassigned},
];