import {
          LOAD_TUTOR_APPLICATION,
          SAVE_TUTOR_APPLICATION,
          TUTOR_APPLICATION_ERROR,
        } from "./actionTypes";

const initialState = {
  tutorApplication: {},
  error: "",
  loading: false
}

const tutorApplicationReducer = (state=initialState, action) => {
	switch(action.type){
    case LOAD_TUTOR_APPLICATION:
      return {
        ...state,
        error: "",
        loading: true
      }
      
    case TUTOR_APPLICATION_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }

    case SAVE_TUTOR_APPLICATION:
      return {
        ...state,
        tutorApplication: action.payload.tutorApplication,
        error: "",
        loading: false
      }
    default:
      return state
  }
}

export default tutorApplicationReducer;