import React, { Component } from 'react'
import { ThemeFileUpload } from "theme/elements/styledInputs"

export default class UploadAvatarForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
          avatarFile: "",
          fileError: "",
        }

        this.onChangeAvatar.bind(this)
        this.onUploadAvatar.bind(this)
    }

    onChangeAvatar = event => {
        let avatarFile = event.target.files[0];
        let maxKB = 100; //100 KB;
        let maxSize = maxKB * 1024; 
    
        if (avatarFile.size > maxSize) {
          this.setState({fileError: "File too large.  Max size is " + maxKB + " KB"});
        }
        else{
          this.setState({ avatarFile });
        }
    }

    onUploadAvatar = event => {
        event.preventDefault();

        let { avatarFile } = this.state;
        
        if(avatarFile != null && avatarFile != ""){
            this.props.onSubmit(avatarFile);
        }
        else{
            this.setState({fileError: "No File Selected"});
        }
    }

    render() {
        const { fileError } = this.state;
        
        return (
            <>
                <ThemeFileUpload name={"avatar"}
                                  accept="image/*"
                                  onChange={this.onChangeAvatar}
                                  onClick={this.onUploadAvatar} />
                {fileError && <small className="text-danger">{fileError}</small>}
                <br />

                <p>Upload a professional picture for review.<br />
                Please crop your picture to a square image (e.g. 250px x 250px) before uploading it.</p>
            </>
        )
    }
}
