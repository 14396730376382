import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from "react-select"

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ThemeButton, ThemeButtonLink } from 'theme/elements/buttons'
import { ThemeSpinner } from 'theme/elements/spinner'

import ReactDataTable from 'components/tables/dataTableComponents/ReactDataTable'

import GoogleApiLoginForm from 'components/forms/google-business-api/GoogleApiLoginForm'
import GoogleCreatePostForm from 'components/forms/formik/google-business/GoogleCreatePostForm'
import GoogleCreateServicesForm from 'components/forms/formik/google-business/GoogleCreateServicesForm'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import GoogleSubmitVerificationCodeForm from 'components/forms/formik/google-business/GoogleSubmitVerificationCodeForm'
import GoogleCreateServiceAreaForm from 'components/forms/formik/google-business/GoogleCreateServiceAreaForm'
import GoogleLocationUploadMedia from 'components/forms/formik/google-business/GoogleLocationUploadMedia'
import GoogleSaveLocationDetails from 'components/forms/formik/google-business/GoogleSaveLocationDetails'

class GoogleMyBusiness extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            locationsTableRows: [],
            accounts: [],
            locations: [],
            selectedLocations: [],
            verificationOptions: [],
            
            showLocationsTable: false,
            showPostForm: false,
            showServicesForm: false,
            showBusinessDetailsSection: false,
            showVerificationsForm: false,
        }
        this.loadBusinessLocations.bind(this);
        // this.loadBusinessDetails.bind(this);
        this.createBusinessPost.bind(this);
        this.createBusinessServices.bind(this);
        this.createBusinessServiceAreas.bind(this);
        this.uploadBusinessMedia.bind(this);
        this.saveLocationsDetails.bind(this);
        this.getVerificationOptions.bind(this);
        this.requestLocationVerification.bind(this);
        this.submitVerificationPin.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    loadBusinessLocations = formData => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("postGetGMBLocations", formData);
    }

    createLocationsTable = () => {
        let { locations } = this.state;
        let rows = [];
        
        locations.forEach(location => {
            let row = {};
            row.storeCode = location.storeCode;
            row.title = location.title;
            row.account = location.accountName;
            // row.accountId = location.accountId;
            row.city = location.city;
            row.state = location.state;
            row.streetAddress = location.addressLines ? location.addressLines.join(", ") + ", " + location.postalCode : null;
            row.serviceArea = location.serviceArea ? this.processServiceArea(location.serviceArea).join(", ") : "" ;
            row.url = location.websiteUri;
            row.labels = location.labels ? location.labels.join(", ") : "";
            // row.services = location.serviceItems  ? this.processServices(location.serviceItems).join(", ") : "";
            // row.name = location.name;
            
            row.categories = location.primaryCategory;
            if(location.additionalCategories != null){
                row.categories += "\n" + location.additionalCategories.join(", ");
            }
            
            row.description = location.profile ? location.profile.description: "";
            rows.push(row);
            
        })
        
        this.setState({locationsTableRows: rows});
    }

    createBusinessPost = values => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("createBusinessPost", values);
    }

    createBusinessServices = values => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("addBusinessServices", values);
    }

    createBusinessServiceAreas = values => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("addBusinessServiceAreas", values);
    }

    createDefaultBusinessServiceAreas = () => {
        const { onSubmitApiCall } = this.props;
        let { selectedLocations } = this.state;

        onSubmitApiCall("addDefaultBusinessServiceArea", {locations: selectedLocations});
    }

    uploadBusinessMedia = values => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("uploadBusinessMedia", values);
    }
    
    saveLocationsDetails = values => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("saveLocationsDetails", values);
    }

    disableGoogleLocations = locations => {
        if(confirm("Are you sure you want to DISABLE selected locations?")){
            const { onSubmitApiCall } = this.props;
            let simplifiedLocations = [];

            locations.forEach(location => {
                simplifiedLocations.push({name: location.name, postalCode: location.postalCode});
            });

            onSubmitApiCall("disableGoogleLocation", {locations: simplifiedLocations});
        }
    }

    getVerificationOptions = locations => {
        
        const { onSubmitApiCall } = this.props;
        let simplifiedLocations = [];
        
        locations.forEach(location => {
            simplifiedLocations.push({name: location.name, title: location.title});
        });
        
        onSubmitApiCall("fetchVerificationOptions", {locations: simplifiedLocations});
    }

    
    requestLocationVerification = ({locationName, selectedMethod, email, mailerContact} ) => {
        const { onSubmitApiCall } = this.props;
        let options = [];
        options.push({locationName, selectedMethod, email, mailerContact});
        
        onSubmitApiCall("requestLocationVerification", { options });
    }

    bulkRequestLocationVerificationByEmail = verificationOptions => {
        const { onSubmitApiCall } = this.props;
        let options = [];
        
        verificationOptions.forEach(option => {
            if(option.email){
                options.push({locationName: option.locationName, selectedMethod: "EMAIL", email: option.email});
            }
        })
        
        onSubmitApiCall("requestLocationVerification", { options });
    }

    submitVerificationPin = ({locationName, pin}) => {
        const { onSubmitApiCall } = this.props;
        let verificationCodes = [];
        verificationCodes.push({locationName, pin});

        onSubmitApiCall("completeLocationVerification", { verificationCodes });
    }

    getAccountGroups = locations => {
        let groups = locations.map(location => location.accountName);
        groups = groups.filter(function(item, pos){
                            return groups.indexOf(item)== pos; 
                        }) //distinct values
                        .sort((a,b)=> {return a > b}); //ASC
        return groups;
    }

    groupSelectOptions = () => {
        let { locations } = this.state;

        let groups = this.getAccountGroups(locations);

        let selectData = [];

        groups.forEach(group => {
            let groupLocations = locations.filter(location => {
                                    return location.accountName == group
                                }).sort((a,b) => {
                                    return a.storeCode > b.storeCode;
                                });
            let options = [];

            groupLocations.map(location => {
                options.push({ label: location.storeCode + " - " + location.title + " (" + location.city + ", " + location.state + ")", value: location });
            })

            let selectGroup = {
                label: group,
                options
              };
            
              selectData.push(selectGroup);
        });

        return selectData;
    }

    parseSelectedLocations = reactSelectLocations => {
        if(reactSelectLocations != null){
            let selectedLocations = []
            reactSelectLocations.forEach(rsEntry => {
                selectedLocations.push(rsEntry.value);
            })

            this.setState({selectedLocations});
        }
    }

    // processServices = serviceItems => {
    //     if(serviceItems != null){
    //         let services = [];
    //         serviceItems.forEach(service => {
    //             let details = service.freeFormServiceItem;
    //             services.push(details.label.displayName);
    //         })
    //         return services;
    //     }
    // }

    processServiceArea = serviceArea => {
        let allAreas = [];
        
        let placesArray = serviceArea.places.placeInfos;
        placesArray.forEach(place => {
            allAreas.push(place.placeName);
        })
        return allAreas;
    }

    selectByGroup = group => {
        let { locations } = this.state;
        let groupLocations = locations.filter((location) => {return location.accountName == group});
        this.setState({selectedLocations: groupLocations});
    }

    selectAllLocations = () => {
        const { locations } = this.state;
        this.setState({selectedLocations: locations});
    }
    deselectAllLocations = () => {
        this.setState({selectedLocations: []});
    }

    render() {
        let { locations, selectedLocations, 
                verificationOptions,
                showPostForm, showServicesForm, showBusinessDetailsSection,
                showLocationsTable, showVerificationsForm,
                locationsTableRows } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        let locationsSelectArray = this.groupSelectOptions();
        let groups = this.getAccountGroups(locations) || [];
        let locationsTableProps = {rows: locationsTableRows, createColumns: true};

        return (
            <>
                <BTNMetaTags title="GMB Management" />
                
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }
                <Row>
                    <Col xs={{size: "5", offset: "7"}}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={{size: 6, offset: 6}}>
                                        <GoogleApiLoginForm />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <p>Load Locations</p>
                                        <button className="btn btn-success btn-sm waves-effect waves-light" onClick={() => this.loadBusinessLocations({groupName: "Verified"})}>Verified</button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-secondary btn-sm  waves-effect waves-light" onClick={() => this.loadBusinessLocations({groupName: "BuffTutor"})}>Ungrouped</button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-dark btn-sm waves-effect waves-light" onClick={() => this.loadBusinessLocations({groupName: "Pending"})}>Pending</button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-primary btn-sm waves-effect waves-light" onClick={() => this.loadBusinessLocations({groupName: "Unverified"})}>Unverified</button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-info btn-sm waves-effect waves-light" onClick={() => this.loadBusinessLocations({groupName: "Disabled"})}>Disabled</button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-danger btn-sm  waves-effect waves-light" onClick={() => this.loadBusinessLocations({})}>All</button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                {locations.length > 0 && 
                    <>  
                        <Row style={{paddingBottom: "50px"}}>
                            &nbsp;&nbsp;
                            <Card>
                                <CardBody>
                                    <CardTitle>{locations.length} Google Business Locations</CardTitle>
                                    <Row>
                                        <Col>
                                            {
                                                locations.length > 0 && <ThemeButton color="success" className="btn-sm"  onClick={() => {this.createLocationsTable(); this.setState({showLocationsTable: !showLocationsTable})}}>{showLocationsTable ? <>Hide Locations Table</> : <>View Locations Table</>}</ThemeButton>
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        showLocationsTable && 
                                            <>
                                                <Row>
                                                    <Col>
                                                        <ReactDataTable {...locationsTableProps} pagination={this.props.paginate || true} />
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </CardBody>
                            </Card>
                        
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Card>
                                <CardBody>
                                    <CardTitle>Edit Locations</CardTitle>
                                    <Row>
                                        <Col sm="8">
                                            <Select options={locationsSelectArray}
                                                isMulti={true}  
                                                hideSelectedOptions={true}
                                                placeholder="Select Locations"
                                                onChange={this.parseSelectedLocations} />
                                        </Col>
                                        <Col sm="4">
                                            <ThemeButton onClick={selectedLocations.length > 0 ? () => this.deselectAllLocations() : () => this.selectAllLocations()}>{selectedLocations.length > 0 ? <>Deselect All Locations</> : <>Select All Locations</>}</ThemeButton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                groups.length > 0 && groups.map((group, i) => 
                                                    <span key={"group-select-" + i} ><ThemeButtonLink className="btn-sm" color={i % 2 ? "secondary" : "dark"} onClick={() => this.selectByGroup(group)}>{group} Locations</ThemeButtonLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                )
                                            }
                                            <br /><br />
                                            {
                                                selectedLocations.length > 0 &&
                                                    <>
                                                        <hr />
                                                        <ThemeButtonLink color="secondary" onClick={() => this.setState({ showBusinessDetailsSection : !showBusinessDetailsSection, showServicesForm: false, showPostForm: false, showVerificationsForm: false})}>{showBusinessDetailsSection ? <>Hide Details</> : <>Edit Details</>}</ThemeButtonLink>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <ThemeButtonLink color="info" onClick={() => this.setState({ showServicesForm : !showServicesForm, showPostForm: false, showVerificationsForm: false, showBusinessDetailsSection: false})}>{showServicesForm ? <>Hide Services</> : <>Add Services</>}</ThemeButtonLink>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <ThemeButtonLink color="success" onClick={() => this.setState({ showPostForm : !showPostForm, showServicesForm: false, showVerificationsForm: false, showBusinessDetailsSection: false})}>{showPostForm ? <>Hide Post Form</> : <>Create A Post</>}</ThemeButtonLink>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <ThemeButtonLink color="warning" onClick={() => this.setState({ showVerificationsForm : !showVerificationsForm, showServicesForm: false, showPostForm: false, showBusinessDetailsSection: false})}>{showVerificationsForm ? <>Hide Verifications</> : <>Verify Locations</>}</ThemeButtonLink>
                                                        
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Row>
                    </>
                }
                
                {
                    showServicesForm &&
                    <>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Add Services</CardTitle>
                                        <GoogleCreateServicesForm onSubmit={this.createBusinessServices} locations={selectedLocations}/>    
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }

                {
                    showPostForm &&
                    <>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Business Post</CardTitle>
                                        <GoogleCreatePostForm locations={selectedLocations} onSubmit={this.createBusinessPost} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }
                {
                    showVerificationsForm &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Verify Businesses</CardTitle>
                                        <p>{selectedLocations.length} Locations Selected</p>
                                        <Row>
                                            <Col sm="6">
                                                <ThemeButton onClick={() => this.getVerificationOptions(selectedLocations)} disabled={loading}>{loading ? <>Fetching Options</> : <>Fetch Verification Options</>}</ThemeButton>

                                                <br />
                                                <ThemeButton color="danger" onClick={() => this.disableGoogleLocations(selectedLocations)}>Disable Locations</ThemeButton>
                                                <br /><br />
                                                {verificationOptions.length > 0 && <ThemeButton color="success" onClick={() => this.bulkRequestLocationVerificationByEmail(verificationOptions)}>Bulk Verify By Email</ThemeButton>}
                                            </Col>
                                            <Col sm="6">
                                                {   
                                                    selectedLocations.map((location, i) => 
                                                        <li key={"li-" + i}>{location.title}</li>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <br />
                                        <table className="table">
                                            <tr>
                                                <th>Location</th>
                                                <th>Method</th>
                                                <th>Request Pin</th>
                                                <th>Submit Pin</th>
                                            </tr>
                                            {
                                                verificationOptions.map((option, i) =>
                                                    <tr key={"tr-" + i }>
                                                        <td>{option.locationTitle}</td>
                                                        <td>
                                                            {option.verificationMethods}<br />
                                                            {option.phone ? <>{option.phone} <br /></> : null}
                                                        </td>
                                                        <td>
                                                            {
                                                                option.email &&
                                                                    <ThemeButton color="info" onClick={() => this.requestLocationVerification({locationName: option.locationName, selectedMethod: "EMAIL", email: option.email})}>Email</ThemeButton>
                                                            }
                                                            {
                                                                option.postalCode &&
                                                                    <ThemeButton color="secondary" onClick={() => this.requestLocationVerification({locationName: option.locationName, selectedMethod: "ADDRESS"})}>Postcard</ThemeButton>
                                                            }
                                                        </td>
                                                        <td>
                                                            <GoogleSubmitVerificationCodeForm locationName={option.locationName} onSubmit={this.submitVerificationPin} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                } 
                {
                    showBusinessDetailsSection &&
                        <Row>
                            <Col xs="6">
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Upload Images</CardTitle>
                                                <GoogleLocationUploadMedia locations={selectedLocations} onSubmit={this.uploadBusinessMedia} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Save Business Details To Database</CardTitle>
                                        <GoogleSaveLocationDetails locations={selectedLocations} onSubmit={this.saveLocationsDetails} />
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Add Service Areas</CardTitle>
                                        <p>Bulk add default service areas: </p>
                                        <ThemeButton color="success" onClick={this.createDefaultBusinessServiceAreas} disabled={loading}>{loading ? <>Adding Service Areas ...</> : <>Add Default Service Areas ({selectedLocations.length})</>}</ThemeButton>
                                        <br />
                                        
                                        <CardTitle>Add To Individual Location</CardTitle>
                                        <GoogleCreateServiceAreaForm location={selectedLocations[0]} onSubmit={this.createBusinessServiceAreas} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                }
            </>
        )
    }
}


const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMyBusiness);
