import React, { Component } from 'react'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { ADMIN_USER_DETAILS, TUTOR_PROFILE } from 'routes/paths';
import { getCurrencySymbol } from 'scripts/general';

export default class MarketProposalsTable extends Component {
    render() {
        let { proposals, onDelete, onExtendProposalDeadline } = this.props;

        return (
            <table className="table table-striped table-sm table-responsive">
                <thead>
                    <tr>
                        <th>Proposal ID</th>
                        <th>Tutor</th>
                        <th>Rate</th>
                        <th>Deadline</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    proposals.map((proposal, i) =>
                        <tr key={"proposal-" + i} className={proposal.disabled ? "text-secondary": ""}>
                            <td>{proposal.id} / {proposal.refId}</td>
                            <td>
                                {
                                    proposal.tutorName &&
                                        <>
                                            <Link to={ADMIN_USER_DETAILS + "/" + proposal.tutorRefId} className="text-info" target="_blank">
                                                {proposal.tutorName}
                                            </Link>
                                            {" "}{" "}
                                            <Link to={TUTOR_PROFILE + "/" + proposal.tutorRefId} id={"profile" + proposal.tutorRefId} target="_blank">
                                                <i className="bx bx-user-circle" />
                                                <UncontrolledTooltip placement="top" target={"profile" + proposal.tutorRefId}>
                                                    Profile
                                                </UncontrolledTooltip>
                                            </Link>
                                        </>
                                }
                                <br />
                                {
                                    proposal.online &&
                                        <span className="badge badge-soft-info">Online</span> 
                                }
                                {" "}
                                {
                                    proposal.inPerson &&
                                        <span className="badge badge-soft-success">In-Person</span>
                                }
                            </td>
                            <td>
                                <b>Student : </b>{" "}
                                    {
                                        proposal.studentRateInitial == proposal.studentRateFinal 
                                        ?
                                            <>{getCurrencySymbol(proposal.studentCurrency)}{proposal.studentRateFinal}</>
                                        :
                                            <>
                                                <s className="text-secondary">{getCurrencySymbol(proposal.studentCurrency)}{proposal.studentRateInitial}</s> <span className={proposal.studentRateFinal > proposal.studentRateInitial ? "text-danger" : "text-dark"}>{getCurrencySymbol(proposal.studentCurrency)}{proposal.studentRateFinal}</span>
                                            </>
                                    }
                                <br />
                                <b>Tutor :</b>{" "}
                                    {  
                                        proposal.tutorRateInitial == proposal.tutorRateFinal 
                                        ?
                                            <>{getCurrencySymbol(proposal.tutorCurrency)}{proposal.tutorRateFinal}</>
                                        :
                                            <>
                                                <s className="text-secondary">{getCurrencySymbol(proposal.tutorCurrency)}{proposal.tutorRateInitial}</s> <span className={proposal.tutorRateFinal > proposal.tutorRateInitial ? "text-danger" : "text-dark"}>{getCurrencySymbol(proposal.tutorCurrency)}{proposal.tutorRateFinal}</span>
                                            </>
                                    }
                            </td>
                            <td>{proposal.studentDeadline && moment(proposal.studentDeadline).fromNow()}</td>
                            <td>{proposal.status}</td>
                            <td>
                                {
                                    proposal.studentDeadline && moment(proposal.studentDeadline).isBefore(new Date()) &&
                                        <Button
                                            type="button"
                                            color="primary"
                                            outline={true}
                                            className="btn-sm w-md"
                                            onClick={() => onExtendProposalDeadline(proposal.refId)}
                                        >
                                            Extend Deadline
                                        </Button>
                                }
                            </td>
                            <td><a onClick={() => onDelete(proposal.refId)}><i className="bx bx-trash text-danger" /></a></td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        )
    }
}
