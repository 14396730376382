import { post, postFormData, patch } from "../helpers/api_helper"
import { 
        POST_GENERATE_AUTH_URL, POST_GOOGLE_AUTH_CALLBACK,
        POST_GOOGLE_API_SUBMIT_URLS, POST_CREATE_AND_INDEX_URLS,
        
        POST_GOOGLE_GET_LOCATIONS_LIST,
        POST_GOOGLE_POSTS_UPLOAD_IMAGE, POST_GOOGLE_POSTS_CREATE,
        POST_GOOGLE_CREATE_BUSINESS_SERVICES,
        POST_GOOGLE_CREATE_BUSINESS_SERVICE_AREA, POST_GOOGLE_CREATE_DEFAULT_BUSINESS_SERVICE_AREA,
        POST_UPLOAD_BUSINESS_MEDIA, PATCH_SAVE_GOOGLE_BUSINESS_DETAILS, PATCH_DISABLE_GOOGLE_BUSINESS,
        POST_FETCH_BUSINESS_VERIFICATION_OPTIONS, POST_REQUEST_BUSINESS_VERIFICATION, POST_COMPLETE_BUSINESS_VERIFICATIONS,
        
        POST_GOOGLE_SUBMIT_SITEMAPS,
     } from '../api/urls';

export const postSubmitURLsToGoogle = formData => {
    return post(POST_GOOGLE_API_SUBMIT_URLS + "/" + formData.action, formData);
}

export const postCreateAndIndexUrls = () => {
    return post(POST_CREATE_AND_INDEX_URLS);
}

//API Auth
export async function createGoogleAuthURL(formData){
    //requires redirectURL
    return post(POST_GENERATE_AUTH_URL, formData);
}

export async function storeGoogleCredential (formData) {
    //requires code, redirectURL
    return post(POST_GOOGLE_AUTH_CALLBACK, formData );
}

//GMB
export const postGetGMBLocations = formData => {
    return post(POST_GOOGLE_GET_LOCATIONS_LIST, formData);
}

export async function uploadBusinessPostImage(file){

    var formData = new FormData();
    formData.append("file", file);
    let headers = { headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } };
  
    return postFormData(POST_GOOGLE_POSTS_UPLOAD_IMAGE, formData, headers);
}

export const createBusinessPost = postData => {
    return post(POST_GOOGLE_POSTS_CREATE, postData);
}

export const addBusinessServices = formData => {
    return post(POST_GOOGLE_CREATE_BUSINESS_SERVICES, formData);
}

export const addBusinessServiceAreas = formData => {
    return post(POST_GOOGLE_CREATE_BUSINESS_SERVICE_AREA, formData);
}

export const addDefaultBusinessServiceArea = formData => {
    return post(POST_GOOGLE_CREATE_DEFAULT_BUSINESS_SERVICE_AREA, formData);
}

export const uploadBusinessMedia = formData => {
    return post(POST_UPLOAD_BUSINESS_MEDIA, formData);
}

export const saveLocationsDetails = formData => {
    return patch(PATCH_SAVE_GOOGLE_BUSINESS_DETAILS, formData);
}

export const disableGoogleLocation = formData => {
    return patch(PATCH_DISABLE_GOOGLE_BUSINESS, formData);
}

//GMB Verification
export const fetchVerificationOptions = formData => {
    return post(POST_FETCH_BUSINESS_VERIFICATION_OPTIONS, formData);
}

export const requestLocationVerification = formData => {
    return post(POST_REQUEST_BUSINESS_VERIFICATION, formData);
}

export const completeLocationVerification = formData => {
    return post(POST_COMPLETE_BUSINESS_VERIFICATIONS, formData);
}

//Google Search
export async function submitSitemapsToGoogle(){
    return post(POST_GOOGLE_SUBMIT_SITEMAPS);
}