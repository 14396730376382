import React, { Component } from "react"
import { TabPane, Row, Col, Form, FormGroup, Label, Input } from "reactstrap"

import RateSlider from "../../slider/RateSlider"
import { acceptedCurrencies, currencyCodeDetails } from "../../../constants/currency_codes"

class BudgetTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxRate: this.props.formData.maxRate,
    }
  }
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Form>
            <Row>
              <Col md={{size: 10}}>
                <div className="p-3">
                <Label>Your Budget (per hour):  </Label>  Move the slider to indicate what you can afford
                  <br />
                  <br />
                  <div className="form-group row mb-0">
                    <div className="col-sm-3">
                      <select className="custom-select" default={"USD"} name="currency" id="currency" onChange={this.props.onChange}>
                        {
                          acceptedCurrencies.map((currency, i) =>
                              <option key = {i} value={currency}>{currencyCodeDetails[currency].symbol}</option>
                          )
                        }
                      </select>
                      <br />
                    </div>
                    <div className="col-sm-9">
                      <br />
                      <RateSlider onChange={this.props.onChangeRateRange} />
                    </div>
                  </div>
                    <br />

                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="8">
                <FormGroup>
                  <Label for="concerns">
                    Other Requests or Concerns (Optional)
                  </Label>
                  <textarea
                    id="concerns"
                    name="concerns"
                    className="form-control"
                    rows="3"
                    placeholder="I prefer to meet at my house. My scholarship is paying for my tutoring. I need to meet before Thursday!"
                    onChange={this.props.onChange}
                    value={this.props.formData.concerns}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <Col>
                <h4>Text Alerts</h4>
                <p>Sign up for updates by text, as well as by email. <b>We will only text you about tutor matches, nothing else!</b></p>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="smsAlert"
                    name="smsAlert"
                    checked={this.props.formData.smsAlert}
                    onChange={this.props.onChangeChecked}
                  />{" "}
                  <label
                    className="custom-control-label"
                    htmlFor="smsAlert"
                  >
                    Text Me Tutor Match Updates
                  </label>
                </div>
              </Col>
            </Row> */}
          </Form>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default BudgetTab
