import React, { Component } from "react"
import { connect } from "react-redux"

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToastNotification extends Component {
  //REF: Options => https://fkhadra.github.io/react-toastify/api/toast
  // REF: Notification Center: https://fkhadra.github.io/react-toastify/addons/use-notification-center

  constructor(props) {
    super(props)
    this.state = {
      autoClose: 5000,
      // icon: false,
      // pauseOnFocusLoss: false,
    }

    this.showToast.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.toastType != ""
        /*&& (this.props.toastType != prevProps.toastType
              && this.props.message != prevProps.message)*/ ) {
      this.showToast(this.props.toastType, this.props.message, this.props.title);
    }
  }

  showToast(toastType, message, title=null) {
    //toastType = success, info, warning, error
    toastType = toastType === "danger" ? "error" : toastType;

    let options = {
      // position: toast.POSITION.TOP_LEFT,
      type: toastType,
      autoClose: (toastType != "warning" && toastType != "error") ? this.state.autoClose : false, //make warning & errors sticky (must be clicked to remove)
      transition: Slide,
    }

    toast(message, options);
  }

  render() {
    return <ToastContainer theme="dark" />
  }
}

const mapStateToProps = ({ toastReducer }) => ({
  toastType: toastReducer.toastType,
  message: toastReducer.message,
  title: toastReducer.title
})

const mapDispatchToProps = dispatch => ({
  //onClearToast: () => dispatch(clearToastAlert()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotification)
