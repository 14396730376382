import React, { Component } from 'react'

export default class Step extends Component {
  render() {
    let { step, first=false, last=false, data=null } = this.props;
  
    return (
      <>
        <div className={`${step.class || "form-steps"} ${step.active == true ? "active" : ""}`}>
            <h3>{step.title}</h3>
            {
              first != true && step.prevButtonClass == null &&
                <>
                  <a className="prev"><i className="las la-arrow-left"></i>Previous Step</a>
                </>
            }
            <div className={step.divClass || ""}>
              <step.component 
                // formData={this.props.formData}
                data={data}
                onChange={this.props.onChange}
                onChangeChecked={this.props.onChangeChecked}
                onChangeDateRange={this.props.onChangeDateRange}
                onChangeRateRange={this.props.onChangeRateRange}
                onChangeSlider={this.props.onChangeSlider}
                onChangeStarRating={this.props.onChangeStarRating}
              />
              <br />
              {
                last == true ?
                  <button className={step.nextButtonClass || "btn btn-xl btn-primary"} onClick={this.props.onSubmit}>Submit</button>
                :
                  <button className={step.nextButtonClass || "btn btn-xl btn-primary"}>Next</button>
              }
              {
                first != true && step.prevButtonClass != null && step.prevButtonClass != "" &&
                  <a href="#" className={step.prevButtonClass}>Previous</a>
              }
            </div>
        </div>
      </>
    )
  }
}
