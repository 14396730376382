import React, { Component } from "react"

import { Media } from "reactstrap"
import StarRatings from "react-star-ratings"

class TutorReviews extends Component {
  render() {
    const reviews = this.props.reviews; //array
    //review: id, studentName, websiteReference, rating, course, createdAt
    return (
      <React.Fragment>
          {reviews.map((review, k) => (
            <Media
              className={review.id === 1 ? "border-bottom" : "border-bottom mt-3"}
              key={"__media__" + k}
            >
              <Media body>
                <br />
                {
                  review.rating != null &&
                    <div className="text-muted float-right mr-3 mb-3">
                      <StarRatings
                        rating={review.rating}
                        starRatedColor={review.rating >= 3 ? "#F1B44C" : "#FF0000"}
                        numberOfStars={5}
                        name="rating"
                        starDimension="14px"
                        starSpacing="3px"
                      />
                    </div>
                }
                
                <h5 className="mt-0 mb-1 font-size-15">{review.studentName} {" - "} {review.course}</h5>
                <p className="text-muted">{review.websiteReference}</p>
                <div className="text-muted font-size-12">
                  <i className="far fa-calendar-alt text-primary mr-1" />
                  {review.createdAt}
                </div>
                <br />
              </Media>
            </Media>
          ))}
      </React.Fragment>
    )
  }
}

export default TutorReviews
