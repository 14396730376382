import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Media,Button } from "reactstrap"


class PricingCard extends Component {
  render() {
    let { title, description, icon, currency = "$", price, link = null, features, footnote} = this.props;

    return (
      <React.Fragment>
        <Card className="plan-box">
          <CardBody className="p-4">
            <Media>
              <Media body>
                <h5>{ title }</h5>
                <p className="text-muted">{ description }</p>
              </Media>
              <div className="ms-3">
                <i
                  className={
                    "bx " + icon + " h1 text-primary"
                  }
                />
              </div>
            </Media>
            <div className="py-4">
              <h2>
                <sup>
                  <small>{ currency }</small>
                </sup>{" "}
                { price } {" "}
                <span className="font-size-13">
                  Per Hour
                </span>
              </h2>
            </div>
            <div className="text-center">
              {
                this.props.children
              }
              {     
                link ? 
                  <Link
                    to={link}
                    color="primary"
                    className="btn btn-primary btn-sm"
                    onClick={this.props.onSelect || null}
                  >
                    Get Started
                  </Link>
                  :
                  <Button
                    onClick={this.props.onSelect}
                    color="primary"
                    className="btn btn-primary btn-sm"
                  >
                    Select Plan
                  </Button>
              }
              
            </div>

            <div className="plan-features mt-5">
              {features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                  {feature.title}
                </p>
              ))}
            </div>
            <small>{footnote}</small>
          </CardBody>
        </Card>

      </React.Fragment>
    )
  }
}

PricingCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.number,
  features: PropTypes.array,
  link: PropTypes.string,
  footnote: PropTypes.string,
  onSelect: PropTypes.func
}

export default PricingCard
