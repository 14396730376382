import React, { Component } from 'react'
import { connect } from "react-redux"

import { ThemeButtonLink } from 'theme/elements/buttons';

import { PROFICIENCY_EXAM_LIST } from 'routes/paths';
import { Col, Row } from 'reactstrap';

class ProficiencyExamsTab extends Component {    
    render() {
        const { tutorApplicationReducer={} } = this.props;
        let tutorApplication = tutorApplicationReducer.tutorApplication || {};
        let proficiencyExams = tutorApplication.proficiencyExams || [];

        return (
            <>
                <Row>
                    <Col md={{size: 10, offset: 1}}>

                        {/* <p>Pass proficiency exams for courses you wish to tutor. If the courses you wish to tutor do not have a proficiency exam, please skip this step.</p> */}
                        <p>Take our proficiency exams for following courses: </p>
                        {
                            proficiencyExams.length == 0 ?
                                <>
                                    <center><b style={{color: "darkblue"}}>No proficiency exam for the subjects you tutor. Please proceed to the next step.</b></center>
                                </>
                            :
                                <>
                                    <ul>
                                        {proficiencyExams.map((exam, i) => 
                                            <li key={`li-${i}`}>{exam.name}</li>
                                        )}
                                    </ul>
                                    <p>
                                        <b>SUGGESTIONS:</b> <br />
                                        Start with the highest level course for each subject you wish to tutor.  <br />
                                        If you tutor a higher level Math course (Calc and above), skip the lower level courses (Algebra, Trig, Geometry, Precalc) 
                                    </p>
                                    <p><b>Applications missing relevant proficiency exam results will be considered incomplete and will not be reviewed. </b></p>

                                    <ThemeButtonLink to={PROFICIENCY_EXAM_LIST}>
                                        Take Exams
                                    </ThemeButtonLink>
                                </>
                        }
                        
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ userReducer, tutorApplicationReducer, apiResponseReducer }) => ({    user: userReducer,
    tutorApplicationReducer,
})

const mapDispatchToProps = dispatch => ({
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProficiencyExamsTab)