import React, { Component } from "react"
import { Container } from "reactstrap"
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import StudentDashboard from "components/dashboards/StudentDashboard"
import TutorApplicantDashboard from "components/dashboards/TutorApplicantDashboard"
import TutorDashboard from "components/dashboards/TutorDashboard"
import AdminDashboard from "components/dashboards/AdminDashboard"

import { ThemeButtonGroup } from "theme/elements/buttons"

import { updateUserSelectedRole } from "store/actions"
import { clearAlerts } from "store/actions"
import AdAccountDashboard from "components/dashboards/AdAccountDashboard"


class Dashboard extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       role: this.props.user.selectedRole,
    }
  }
  
  componentDidMount() {
    let { onClearAlerts } = this.props;
    onClearAlerts();
  }

  createRoleButtons = roles => {
    let roleButtons = [];

    if(roles.length > 1){
      if(roles.includes("student")){
        roleButtons.push({color: "info", value: "student", text: "Student"})
      }

      if(roles.includes("tutor")){
        roleButtons.push({color: "secondary", value: "tutor", text: "Tutor"})
      }
      else if(roles.includes("tutor-applicant") || roles.includes("tutorApplicant")){
        roleButtons.push({color: "warning", value: "tutor-applicant", text: "Tutor Applicant"})
      }

      if(roles.includes("admin")){
        roleButtons.push({color: "danger", value: "admin", text: "admin"})
      } else if(roles.includes("staff")){

      } 
    }

    return roleButtons;
  }

  switchRole = (event) => {
    const { value } = event.target;
    updateUserSelectedRole(value);
    this.setState({role: value});
  }
  
  render() {
    let { user={} } = this.props;
    const { role } = this.state;
    
    let roles = user.roles || [];
    let roleButtons = [];

    if(roles.length > 1){
        roleButtons = this.createRoleButtons(roles);
    }

    let dashboard = null;

    switch(role) {
      case "student":
        dashboard = <StudentDashboard />;
        break;
      case "tutor-applicant":
      case "tutorApplicant":
        dashboard = <TutorApplicantDashboard />
        break;
      
      case "leads":
        dashboard = <AdAccountDashboard />;
        break;
      
      case "tutor":
        dashboard = <TutorDashboard />
        break;

      case "admin":
        dashboard = <AdminDashboard />
        break;
      case "staff":
      default:
        break;
    }

    return (
      <React.Fragment>
        <BTNMetaTags title={`${user.firstName}'s Dashboard`} />

        <Container fluid>
          {
            roleButtons.length > 1 &&
              <>  
                <p>Your account has multiple roles. Click to switch roles. </p>
                <ThemeButtonGroup onClick={this.switchRole} buttons={roleButtons}/>
                <br />
              </>
          }
          <br />
          { dashboard }
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({userReducer}) => ({
  user: userReducer,
})

const mapDispatchToProps = dispatch => ({
  onClearAlerts: () => dispatch(clearAlerts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)