import { get, post, put, patch, del } from "../helpers/api_helper"
import { PATCH_SUBMIT_REVIEW_STUDENT_DECISION, PATCH_SUBMIT_TUTOR_CONTRACT_STUDENT_DECISION,
        GET_TUTOR_CONTRACT_FOR_STUDENT, GET_TUTOR_CONTRACTS_FOR_TUTOR,
        PATCH_SUBMIT_TUTOR_CONTRACT_TUTOR_DECISION,

        POST_CREATE_BALANCE_REFUND_REQUEST
        } from "../api/urls"

export const submitReviewStudentDecision = (formData) => {
   return patch(PATCH_SUBMIT_REVIEW_STUDENT_DECISION + "/" + formData.id, formData);
}

export const submitContractStudentDecision = (formData) => {
        return patch(PATCH_SUBMIT_TUTOR_CONTRACT_STUDENT_DECISION + "/" + formData.id, formData);
}


export const getTutorContractForStudent = tutorContractId => {
        return get(GET_TUTOR_CONTRACT_FOR_STUDENT + "/" + tutorContractId);
}

export const getTutorContractsForTutor = tutorId => {
        return get(GET_TUTOR_CONTRACTS_FOR_TUTOR + "/" + tutorId);
}

export const submitContractTutorDecision = (formData) => {
        return patch(PATCH_SUBMIT_TUTOR_CONTRACT_TUTOR_DECISION + "/" + formData.tutorContractId, formData);
}

export const postRequestBalanceRefund = tutorContractId => {
        return post(POST_CREATE_BALANCE_REFUND_REQUEST +"/"+ tutorContractId);  
}