import moment from "moment";
import { currencyCodeDetails } from "constants/currency_codes";

export function cleanUpString(dirtyString){
  let cleanString = dirtyString.trim();

  //TODO: Escape characters that could cause JS or MYSQL problems

  return cleanString;
}

export function validateFormFields(formFields, skipFields = []){
  let errorFields = [];
  for (var field in formFields) {
    if (!formFields[field] && !skipFields.includes(field)) {
          errorFields.push(_.startCase(field));
    }
  }
  return errorFields;
}

export function capitalizeWords(words){
  words = words.toString();
  let wordsArray = words.split(" ");
  let wordsString = "";

  for (let index = 0; index < wordsArray.length; index++) {
    let word = wordsArray[index];
    word = word.toLowerCase();
    word = word.charAt(0).toUpperCase() + word.slice(1);
    wordsString += word + " ";
  }

  return wordsString.trim();
}

export function camelCaseToWords(camelString){
  //Word With Spaces
  let snake_string = camelString.toString().replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  let words = snake_string.toString().replace(/_/g, " ");

  return capitalizeWords(words);
}

export function getCurrencyDetails(currencyIsoCode = "usd"){
  return currencyCodeDetails[currencyIsoCode.toUpperCase()];
}


export function getCurrencySymbol(currencyIsoCode){
  if(currencyIsoCode == null || currencyIsoCode == ""){
    return null;
  }
  
  currencyIsoCode = currencyIsoCode.toUpperCase();
  let currencyDetails = currencyCodeDetails[currencyIsoCode];
  let currencySymbol = currencyIsoCode == "USD" ? currencyDetails.symbol_native : currencyDetails.symbol;

  return currencySymbol;
}

export function formatCurrencyAmountWithoutSymbol(amount, currencyIsoCode = "usd"){
  amount = amount != null ? amount : 0;

  let currencyDetails = getCurrencyDetails(currencyIsoCode);

  return Number(amount).toFixed(currencyDetails.decimal_digits);
}

export function formatCurrencyAmount(amount, currencyIsoCode = "usd"){
  amount = amount != null ? amount : 0;

  return getCurrencySymbol(currencyIsoCode) + formatCurrencyAmountWithoutSymbol(amount, currencyIsoCode);
}

export function formatDate(date){
  //REF: https://momentjs.com/
  //using Multiple Locale Support
  return moment(date).format("L");
}

export function formatTime(time){
  return moment(time).format("LT");
}

export function formatDateWithMonth(date){
  return moment(date).format("ll");
}

export function formatDateTime(datetime){
  return moment(datetime).format("lll");
}

export function formatDateTimeWithDay(datetime){
  return moment(datetime).format("llll");
}

export function getAssignmentStatus(endDate){
  const gracePeriod = 14 * 24; // 14 days in hours

  let difference = moment().diff(endDate, 'hours'); // today - endDate

  //End Date Is Future
  if(difference < 0){
    if(Math.abs(difference) > gracePeriod){
      return "active";
    }
    
    return "ending_soon";
  }
  
  //End Date Is Past
  return "ended";
}

export function formatOldProficiencyExamName(examName){
  examName = examName.split(".csv")[0]; //remove file extension
  examName = examName.substring(examName.indexOf("-") + 1); //remove dept @ front
  
  return camelCaseToWords(examName); 
}