import React, { Component } from "react"
import { TabPane, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from "reactstrap"

class LocationTab extends Component {
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Form>
            <Row>
              <Col>
                <Label>Would you like to meet in-person or online? Select both if you have no preference.</Label>
                <FormGroup>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inPerson"
                      name="inPerson"
                      checked={this.props.formData.inPerson}
                      onChange={this.props.onChangeChecked}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inPerson"
                    >
                      In Person
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="online"
                      name="online"
                      checked={this.props.formData.online}
                      onChange={this.props.onChangeChecked}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="online"
                    >
                      Online Tutoring
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {/*<Row>
              <Col md="6">
                <FormGroup>
                  <Label for="postalCode">
                    Your (or The Student's) Zip Code
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    name="postalCode"
                    placeholder="Zip Code"
                    onChange={this.props.onChange}
                    value={this.props.formData.postalCode}
                    invalid={this.props.invalidFields.includes("postalCode")}
                  />
                <small>Where you are or where you can meet</small>
                {this.props.invalidFields.includes("postalCode") &&
                    <FormFeedback>
                      {this.props.validationErrors.find(vError => vError.field == "postalCode").message}
                    </FormFeedback>
                  }
                </FormGroup>
              </Col>
            </Row>*/}
          </Form>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default LocationTab
