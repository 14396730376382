import React, { Component } from "react"
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

import { apiError, resetPassword } from "store/actions"

import { validatePassword } from "scripts/authentication"
import AuthHeader from "./AuthHeader"
import ResetPasswordForm from "components/forms/formik/authentication/ResetPasswordForm"

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",
      userId: ""
    }
  }


  componentDidMount() {
    this.props.apiError("");
    
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let userId = params.get('user');
    let token = params.get('token');
    
    if(token != null && userId != null){
      token = token.indexOf("Bearer ") == -1 ? "Bearer " + token : token;
      localStorage.setItem("tempToken", token);
      this.setState({userId, token});
    }
  }

  handleValidSubmit = (values) => {
    let { userId } = this.state;
    let { apiError, resetPassword } = this.props;
    let password = values.password;
    let history = this.props.history;
    
    if(validatePassword(password)){
      resetPassword({userId, password, history});
    }
    else{
      apiError("Invalid Password.");
    }
  }

  render() {
    let { loading, error } = this.props;

    return (
      <React.Fragment>
        <BTNMetaTags title="Reset Password" />
        
        <div className="account-pages my-3 pt-sm-3">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <AuthHeader title="BuffTutor Login" subtitle="Reset Password" />
                  <CardBody className="pt-6">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                        
                    <p>
                        Enter new password.
                    </p>

                    <div className="p-2">
                      <ResetPasswordForm onSubmit={this.handleValidSubmit}>
                        {
                            loading
                              ?
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                  disabled={true}
                                >
                                  Submitting ... {" "}
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                
                                </button>
                              :
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                >
                                  Reset Password
                                </button>
                          }
                      </ResetPasswordForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} BuffTutor Network.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { error, loading } = state.Login
  return { error, loading }
}

export default connect(mapStateToProps, { apiError, resetPassword })(ResetPassword)
