import React from "react"
import { UncontrolledTooltip } from "reactstrap"

export const IssueRefundAction = (props) => {
    //props: id, color, onClick
    return (
        <a id={"issue-refund-" + props.id} className={`text-${props.color || "danger"}`} onClick={props.onClick}>
            <i className="bx bx-undo" />
            <UncontrolledTooltip
                placement="top"
                target={"issue-refund-" + props.id}
            >
            Issue Refund #{props.id} (TODO)
            </UncontrolledTooltip>
        </a>
    )
}