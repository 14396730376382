import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody, 
} from "reactstrap"

import TutorSessionReservationForm from './forms/TutorSessionReservationForm';
import TutorAvailabilityForm from './forms/TutorAvailabilityForm';

export default class CalendarEventModal extends Component {

    onClose = () => {
        this.props.onClose();
    }

    onDelete = ({event, type}) => {
        if(confirm("Are you sure you want to delete this event?")){
            this.props.onDeleteEvent({ eventId: event.id,  type });
        }
    }

    render() {
        let { event, calendarId, timezone, timezoneName, isOpen, isEdit, formType, loading, assignments=[], selectedStartDate, selectedEndDate, 
                onSaveEvent, onDeleteEvent, onClose } = this.props;

        if(isEdit){
            let { extendedProps } = event;
            if(extendedProps){
                formType = extendedProps.type;
            }
        }
        

        let modalForm = null;
        let type = "";
        
        switch (formType) {
            case "session":
                modalForm = <TutorSessionReservationForm 
                                {...{isEdit, loading, event, calendarId, assignments, timezone, timezoneName, selectedStartDate, selectedEndDate, 
                                        onSaveEvent, onDeleteEvent, onClose }} />
                type = "Tutor Session";
                break;

            case "availability":
            default:
                modalForm = <TutorAvailabilityForm 
                                {...{isEdit, loading, event, calendarId, timezone, timezoneName, selectedStartDate, selectedEndDate, 
                                    onSaveEvent, onDeleteEvent, onClose }} />
                type = "Tutor Availability";
                break;
        }

        return (
            <Modal
                isOpen={isOpen}
                // className={this.props.className}
                id="event-modal"
                toggle={this.onClose}
            >
                <ModalHeader toggle={this.onClose} tag="h4">
                    {!!isEdit ? "Edit " : "Add "} {type}
                </ModalHeader>
                <ModalBody>
                    {
                        event.id && 
                            <>
                                <h5><b>{event.title}</b> {event.id.includes("_") ? "(Recurring)" : ""}
                                <a className="text-danger m-4" onClick={() => this.onDelete({event, type: formType})}><i className="bx bx-trash float-right" ></i></a>
                                </h5>
                                {
                                    event.location && <><i className="bx bx-map" />{event.location}</>
                                }
                                {
                                    event.description && <p>{event.description}</p>
                                }
                            </>
                    }
                    
                    {
                        modalForm != null && 
                        <Card>
                            <CardBody>
                                { modalForm }
                            </CardBody>
                        </Card>
                    }
                </ModalBody>
            </Modal>
        )
    }
}

// CalendarEventModal.propTypes = {
//     event: PropTypes.object,
//     formType: PropTypes.oneOf([ 'none', 'session', "availability" ]),
//     isOpen: PropTypes.boolean,
//     isEdit: PropTypes.boolean,
//     loading: PropTypes.boolean,
//     assignments: PropTypes.array,

//     onSaveEvent: PropTypes.func,
//     onDeleteEvent: PropTypes.func,
//     onClose: PropTypes.func
// }