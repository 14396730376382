import React, { Component } from 'react'
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import TutorSessionsTable from 'components/tables/tutor-sessions/TutorSessionsTable'
import NavTabs from 'components/navTabs/NavTabs';
import PaymentsTable from 'components/tables/payments/PaymentsTable';
import PaymentTransfersTable from 'components/tables/payments/PaymentTransfersTable'
import RefundsTable from 'components/tables/refunds/RefundsTable';

import { Card, CardTitle, CardBody } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

class TutorSessions extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             roles: localStorage.getItem("roles") || [],
             userId: props.match.params.userId,
             studentActiveSessions: [],
             tutorActiveSessions: [],

             studentPayments: [],
             studentRefunds: [],
             tutorTransfers: [],
             tutorRefunds: []
        }
    }
    
    async componentDidMount() {
        let { onSubmitApiCall, user } = this.props;
        let { userId } = this.state;
        let roles = user.roles || [];

        if(roles.includes("student") || roles.includes("admin")){
            await onSubmitApiCall("getActiveTutorSessionsForStudent", userId);
            await onSubmitApiCall("getStudentPayments", userId);
        }

        if(roles.includes("tutor") || roles.includes("admin")){
            await onSubmitApiCall("getActiveTutorSessionsForTutor", userId);
            await onSubmitApiCall("getTutorTransfers", userId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        let { studentActiveSessions, tutorActiveSessions,
            studentPayments, studentRefunds, tutorTransfers, tutorRefunds } = this.state;

        let { apiResponse = {}, user} = this.props;
        let roles = user.roles || [];
        let loading = apiResponse.loading;
        
        let studentKey = studentActiveSessions.length + studentPayments.length + studentRefunds.length;
        let tutorKey = tutorActiveSessions.length + tutorTransfers.length + tutorRefunds.length;

        let studentTabs = [{id: 1, title: 'Tutor Sessions', content: <TutorSessionsTable type={"detailed"} role={"student"} tutorSessions={studentActiveSessions}/> },
                    {id: 2, title: 'Payments', content: <PaymentsTable payments={studentPayments} />},
                    {id: 3, title: 'Refunds', content: <RefundsTable role="student" refunds={studentRefunds} />},
                    ];

        let tutorTabs = [{id: 1, title: 'Tutor Sessions', content: <TutorSessionsTable type={"detailed"} role={"tutor"} tutorSessions={tutorActiveSessions} />  },
                    {id: 2, title: 'Payments', content: <PaymentTransfersTable transfers={tutorTransfers} />},
                    {id: 3, title: 'Refunds', content: <RefundsTable role="tutor" refunds={tutorRefunds} />},
                    ];

        return (
            <>
              <BTNMetaTags title={`Tutor Sessions`} />

              {
                roles.includes("student") &&
                    <>
                        <Card>
                            <CardBody>
                                <CardTitle>Tutor Sessions & Charges </CardTitle>
                                <p>You Have 5 Days To Request A Tutor Session Refund</p>
                                <small><i className="bx bx-undo" /> = Refund Session (scroll right)</small>
                                {
                                    loading &&
                                        <center><ThemeSpinner /></center>
                                }
                                <NavTabs key={"studentTabs-" + studentKey} tabs={studentTabs}/>
                            </CardBody>
                        </Card>
                        
                    </>
              }

              {
                roles.includes("tutor") &&
                    <>
                        <Card>
                            
                            <CardBody>
                                <CardTitle>Tutor Sessions & Charges</CardTitle>
                                <b>Please Note: </b>
                                <p>To prevent duplicate charges or other mistakes, all submitted tutor session charges are placed on a <b>one hour hold</b> during which you can delete any duplicate or incorrect submissions.  After the holding period, the student's card will be charged and your earnings transferred to you.</p>
                                <p>You have <b>1 Hour</b> to cancel a Pending Tutor Session Charge and <b>24 Hours</b> to delete a Tutor Session.</p>

                                <small><i className="bx bx-x-circle bx-xs" /> = Cancel Session </small><br />
                                <small><i className="bx bx-trash bx-xs" /> = Delete Session (scroll right)</small>
                                <br /><br />
                                
                                {
                                    loading &&
                                        <center><ThemeSpinner /></center>
                                }
                                <NavTabs key={"tutorTabs" + tutorKey} tabs={tutorTabs}/>
                            </CardBody>
                        </Card>
                    </>
              }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(TutorSessions)