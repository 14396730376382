import React, { Component } from "react";
import { Link } from "react-router-dom";

import { ThemeSpinner } from "theme/elements/spinner";

import BTNChatFilter from "./BTNChatFilter";
import ChatListItem from "./ChatListItem";
import { AdminFilterOptionsArray, searchForUser, UserFilterOptionsArray } from "./filterOptions";
import { Col, Row } from "reactstrap";

// Chat list component - To display list
class ChatList extends Component {
  state = {
    threadId: "",
    search: "",
    filterOption: 1, //all chats
    filterOptionsArray: this.props.isAdmin ? AdminFilterOptionsArray : UserFilterOptionsArray,
    AllChatList: [],
    FilterChatList: [],
    type: "recent"
  };

  componentDidMount() {
    let { openThreadId, chats=[] } = this.props;

    let AllChatList = chats.map((chat) => {
                          chat.active = chat.threadId === openThreadId;
                          return chat;
                        });

    this.setState({
      threadId: openThreadId,
      AllChatList,
      FilterChatList: AllChatList
    });
  }

  handleChatClick = ({threadId, chat}) => {
    let { FilterChatList } = this.state;
    FilterChatList.map((chat) => {
      chat.active = chat.threadId == threadId;
      return chat;
    });
    
    this.setState({FilterChatList});

    this.props.handleChatClick({threadId, chat});
  };

  handleFilterOptionChanged = (selectedOption) => {
    let { AllChatList } = this.state;
    let chatList = [...AllChatList];
    
    chatList = chatList.filter(chat => selectedOption.filter(chat));

    this.setState({
      filterOption: selectedOption.id,
      FilterChatList: chatList,
      search: "",
    });
  };

  handleSearchChat = (event) => {
    let search = event.target.value;
    let { AllChatList, filterOption, filterOptionsArray} = this.state;
    
    let chatList = [...AllChatList];
    let selectedOption = filterOptionsArray.find(option => option.id == filterOption);

    //Narrow the list down to the already selected filter
    chatList = chatList.filter(chat => selectedOption.filter(chat));

    if (search) {
      chatList = chatList.filter(chat => searchForUser(chat, search));
    }

    this.setState({
      search,
      FilterChatList: chatList,
    });
  };

  loadThreadsByType = type => {
    if(this.state.type != type){
      this.setState({type});
      this.props.loadThreadsByType(type);
    }
  }

  render() {
    let { loadingThreads = false, isAdmin=false, user } = this.props;
    let { type } = this.state;
    
    return (
      <aside className="sidebar">
        <div className="tab-content">
          <div className="tab-pane active" id="chats-content">
            <div className="d-flex flex-column h-100">
              <div className="hide-scrollbar h-100" id="chatContactsList">
                <div className="sidebar-header sticky-top p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-weight-semibold mb-0 ml-3"><Link to="/" className="btn btn-lg btn-secondary btn-outline"> <i className="fa fa-home"/> Home</Link></h5>
                  </div>
                  {
                    isAdmin && 
                      <>
                        <Row className="pt-2">
                          <Col xs="6">
                            <h2>Chats : {type && !loadingThreads ? `${type.toUpperCase()} (${this.state.FilterChatList.length})` : "-"}</h2>
                          </Col>
                          <Col xs="6">
                            <button className="btn btn-sm btn-info" disabled={type == "recent" ? true : false} onClick={() => this.loadThreadsByType("recent")}>Recent</button>
                            <button className="btn btn-sm btn-warning" disabled={type == "flagged" ? true : false} onClick={() => this.loadThreadsByType("flagged")}>Flagged</button>
                          </Col>
                        </Row>
                      </>
                  }
                  
                  <BTNChatFilter 
                    isAdmin={isAdmin}
                    selectedOption={this.state.filterOption}
                    filterOptionChanged={this.handleFilterOptionChanged}
                    search={this.state.search}
                    handleSearch={this.handleSearchChat}
                  />
                </div>
                <ul
                  className="contacts-list"
                  id="chatContactTab"
                  data-chat-list=""
                >
                  {
                    loadingThreads &&
                      <center><ThemeSpinner /></center>
                  }
                  
                  {this.state.FilterChatList.map((chat, index) => 
                      <ChatListItem
                        key={"chatitem-" + index}
                        chat={chat}
                        user={user}
                        status={""}
                        endIcon={""}
                        isAdmin={isAdmin}
                        handleChatClick={() => {
                          this.handleChatClick({threadId: chat.threadId, chat});
                        }}
                      />  
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
export default ChatList;
