export function processApiError(error){
    if (error.response) {
        let status = error.response.status;
        let data = error.response.data;

        let message = "";

        switch (status) {
          case 401:
            message = "Unauthorized. Session may have expired, try logging out and back into your account.";
            break;
          case 404:
            message = "Page Not Found";  
            // message = status + " - " + data.error;
            // message += " - " + data.path; //TEMP
            break;
          default:
            if(typeof data == "string"){
              // message = status + " - " + data;
              message = data;
            }
            else {
              // message = status + " - " + data.error;
              message = data.error;
              if(data.message != "No message available"){
                message += " - " + data.message; //TEMP
              }
            }
            
            break;
        }
        
        return message;
    }
    else{
        if(typeof error == "object" && error.hasOwnProperty("message")){
          return error.message;
        }
    }
    return error; //if error message is a string
}