import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment';

import ReactDataTable from 'components/tables/dataTableComponents/ReactDataTable';
import { createSpecifiedColumns } from 'components/tables/dataTableComponents/scripts'; 

import { RestartJob, DeleteJob } from './actions';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

class BatchJobsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            tableColumns: ["id", "status", "exitDescription", "parameters" /*, "action"*/],

            processing: false
        }
    }
    
    componentDidMount() {
        let { jobExecutions=[]} = this.props;
        let { tableColumns } = this.state;
        
        let columns = createSpecifiedColumns(tableColumns);
        this.setState({columns});

        this.createRows(jobExecutions);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createRows = rows => {
        rows.sort((a,b) =>{
            return moment(b.jobInstanceId) < moment(a.jobInstanceId); //SORT ASC
        });

        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row.id = <>
                        <b>Execution Id: </b>{row.jobExecutionId}<br/>
                        <b>Instance Id: </b>{row.jobInstanceId}<br />
                        <b>Job Name: </b>{row.jobName}<br/>
                    </>
            row.exitDescription = row.exitDescription != null && row.exitDescription.length > 250 ? row.exitDescription.substring(0, 250) + "..." : row.exitDescription;
            row.status = <>
                            <b>Batch Status:</b> {row.batchStatus}<br />
                            <b>Exit Status:</b> {row.exitStatus}
                        </>
            row.parameters = this.extractParameters(row.jobParameters);
            // row["action"] = <>
            //                     <RestartJob id={row.jobExecutionId} onClick={() => this.triggerAction("restart", row.jobExecutionId)} />
            //                     <DeleteJob id={row.jobExecutionId} onClick={() => this.triggerAction("delete", row.jobExecutionId)} />
            //                 </>
        });
        
        return rows;
    }

    extractParameters = jobParameters => {
        let parameters = jobParameters["parameters"];
        let parametersString = "";
        for(const key in parameters){
            parametersString += `${key}: ${parameters[key]["value"]} ; `
        }

        return parametersString;
    }
    
        /*
    triggerAction = (action, jobExecutionId) =>{
        if(confirm("Are you sure you want to "+ action +" this job?")){
            let { onSubmitApiCall } = this.props;       

            switch (action) {
                case "restart":
                    onSubmitApiCall("restartChargeSessionBatchJob", jobExecutionId);
                    break;
                case "delete":
                    alert("TODO");
                    break;
                default:
                    break;
            }
            window.location.reload();
        }
    }
    */

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        
        return (
            <>
                <p>{rows.length} Applications</p>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />

                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(BatchJobsTable)
