import React, { Component } from 'react'
import { Form, Formik } from 'formik';
import { Col, Input, Label, Row } from 'reactstrap';

export default class EditTutorRequestForm extends Component {
    
    onSubmit = values => {
        this.props.onSubmit(values);
    }

  render() {
    let { scholarship=false, course, loading, concerns, startDate, endDate } = this.props;
    let initialValues = { //scholarship: scholarship == null ? false : scholarship,
                            scholarship, course, concerns, startDate, endDate }

    return (
        <Formik
            initialValues={{...initialValues}}
            onSubmit={this.onSubmit}
        >
            { props  => (
                <Form className="needs-validation">
                    <div className="form-group pl-3">
                        <Input
                            name="scholarship"
                            value={props.values.scholarship}
                            className="form-control"
                            type="checkbox"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            checked={props.values.scholarship}
                        />
                        <Label>Scholarship</Label>
                    </div>
                    
                    <Row>
                        <Col>
                            <div className="form-group pl-3">
                                <Input
                                    name="startDate"
                                    value={props.values.startDate}
                                    className="form-control"
                                    type="date"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                <Label>Start Date</Label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group pl-3">
                                <Input
                                    name="endDate"
                                    value={props.values.endDate}
                                    className="form-control"
                                    type="date"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                <Label>End Date</Label>
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <Input
                            name="course"
                            value={props.values.course}
                            className="form-control"
                            placeholder="Course"
                            type="text"
                            autoFocus={true}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            name="concerns"
                            value={props.values.concerns}
                            className="form-control"
                            placeholder="Concerns"
                            type="textarea"
                            autoFocus={true}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </div>
                    <button
                        className="col-md-6 col-offset-md-6 btn btn-sm btn-info btn-block waves-effect waves-light"
                        type="submit"
                        disabled={loading}
                    >
                        Update Request
                    </button>
                </Form>
            )}
      </Formik>
    )
  }
}

