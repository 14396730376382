import React, { Component } from 'react'
import { Col, Row } from 'reactstrap';
import { ThemeSelect } from 'theme/elements/styledInputs';
import { sortResults } from './scripts';

export default class SortHeader extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        sortBy: this.props.defaultSortBy || 1, //DEFAULT SORT OPTION ID
        sortOrder: "asc", //"desc"
        // sortOptions: [//{id: 1, key: "btnRank", title: "Recommended"},
        //             {id: 2, key: "distanceFromUser", title: "Distance"},
        //             {id: 3, key: "timeDifference", title: "Time Difference"},
        //             {id: 4, key: "hoursCount", title: "Hours"},              
        //             {id: 5, key: "studentsCount", title: "Students"},              
        //           ],
      }
    }
    onChangeOption = (event) =>{
        let { sortOptions, data, onChangeOption } = this.props;
        let { sortOrder } = this.state;
        
        const { name, value } = event.target;
        let sortBy = value;
    
        let sortOption = sortOptions.filter(option => {return option.id == sortBy})[0];
        let sortedData = data.sort(sortResults(sortOption.key, sortOrder));
    
        this.setState({sortBy});
        onChangeOption(sortedData);
    }

    onChangeOrder = () => {
        let { sortOptions, data, onChangeOrder } = this.props;
        let { sortBy, sortOrder } = this.state;

        sortOrder = sortOrder == "asc" ? "desc" : "asc";

        let sortOption = sortOptions.filter(option => {return option.id == sortBy})[0];
        let sortedData = data.sort(sortResults(sortOption.key, sortOrder));

        this.setState({sortOrder});
        onChangeOrder(sortedData);
    }

    render() {
        let { sortOptions } = this.props;
        let { sortBy, sortOrder } = this.state;

        return (
            <>
                <Row>
                    <Col sm="6">
                        <ThemeSelect name="order" id="order" 
                                    title="order"
                                    value={sortBy}
                                    onChange={this.onChangeOption}>
                            {sortOptions.map(option =>
                                <option value={option.id} key={option.id}>{option.title}</option>
                            )}
                        </ThemeSelect>
                    </Col>
                    <Col sm="6">
                        <button className="btn btn-secondary" onClick={this.onChangeOrder}>
                            {sortOrder == "asc" ?
                                <i className="mdi mdi-sort-descending"></i>
                                :
                                <i className="mdi mdi-sort-ascending"></i>
                            }
                        </button>
                    </Col>
                </Row>
            </>
        )
      }
}
