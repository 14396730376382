import React from "react"
import { Redirect } from "react-router-dom"

//Paths
import * as paths from "./paths";

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ResetPassword from "pages/Authentication/ResetPassword";

// BTN
import FAQs from "pages/policies/FAQs"

import Dashboard from "pages/Dashboard"
import TutorSessions from "pages/account/TutorSessions";

//Admin
import UsersList from "pages/admin/users/UsersList"
import UserDetails from "pages/admin/users/UserDetails";
import TutorRequestsAdmin from "pages/admin/tutorRequests/TutorRequestsAdmin"
import TutorApplicationsList from "pages/admin/tutorApplications/TutorApplicationsList";
import AssignmentsAdmin from "pages/admin/AssignmentsAdmin";
import EvaluationsAdmin from "pages/admin/EvaluationsAdmin";
import RefundsAdmin from "pages/admin/RefundsAdmin";
import IssueBalanceRefund from "pages/admin/refunds/IssueBalanceRefund";
import Analytics from "pages/admin/Analytics";
import ScholarshipAssignments from "pages/admin/scholarships/ScholarshipAssignments";
import GoogleSubmitBatchURLs from "pages/admin/google-indexing/GoogleSubmitBatchURLs";
import GoogleMyBusiness from "pages/admin/google-my-business/GoogleMyBusiness";
import GoogleAuthCallback from "pages/admin/google-api/GoogleAuthCallback";

//import EditProfile from "pages/account/EditProfile"
import TutorProfile from "pages/account/TutorProfile"
import PaymentSettings from "pages/account/PaymentSettings";

import RequestTutor from "pages/students/RequestTutor"
import TutorRequestProgress from "pages/students/tutor-match-process/TutorRequestProgress";
import ScholarshipMatchConfirmation from "pages/students/tutor-match-process/ScholarshipMatchConfirmation";
import MyTutorRequests from "pages/students/MyTutorRequests";
import SelectPaymentPlan from "pages/students/tutor-match-process/SelectPaymentPlan";
import EvaluationFormDetailed from "pages/students/evaluations/EvaluationFormDetailed";

import AddReturningStudents from "pages/tutors/AddReturningStudents";
import Evaluations from "pages/tutors/Evaluations";
import MyTutorContracts from "pages/tutors/MyTutorContracts";
import TutorChecklist from "pages/policies/TutorChecklist";
import CreateStripeAccount from "pages/tutors/stripe-flow/CreateStripeAccount";
import StripeAccountLogin from "pages/tutors/stripe-flow/StripeAccountLogin";
import TaxInfo from "pages/account/TaxInfo";

import SignContract from "pages/tutorApplicants/SignContract";
import SwitchStripeToExpress from "pages/tutors/SwitchStripeToExpress";

import SearchTutors from "pages/search/SearchTutors"

import Assessment from "pages/proficiencyExams/Assessment";
import AssessmentAdmin from "pages/proficiencyExams/AssessmentAdmin";
import ExamList from "pages/proficiencyExams/ExamList";
import EditAccount from "pages/account/EditAccount";
import ViewCart from "pages/cart/ViewCart";
import OrderConfirmation from "pages/cart/OrderConfirmation";
import SuccessPage from "pages/cart/SuccessPage";

import ChatAdmin from "pages/chat/ChatAdmin";
import ChatUser from "pages/chat/ChatUser";
// import ChatApp from "theme-chat/ChatApp"
import ChatApp from "theme-chat/components/Chats/Chats";

import MarketPlace from "pages/marketplace/MarketPlace";
import MarketPlaceAdmin from "pages/marketplace/MarketPlaceAdmin";
import MarketCreateProposal from "pages/marketplace/MarketCreateProposal";
import MarketProposalsStatus from "pages/marketplace/tutors/MarketProposalsStatus";
import MarketTutorRequestProgress from "pages/marketplace/tutor-request-progress/MarketTutorRequestProgress";
import MarketHowItWorksStudent from "pages/marketplace/how-it-works/MarketHowItWorksStudent";
import MarketHowItWorksTutor from "pages/marketplace/how-it-works/MarketHowItWorksTutor";

//LEADS
import ActiveLeads from "pages/leads/ActiveLeads";

import Error404 from "pages/error/Error404";
import TutorApplicationDetails from "pages/admin/tutorApplications/TutorApplicationDetails";
import MarketConvertTutorRequest from "pages/marketplace/MarketConvertTutorRequest";
import Inbox from "pages/inbox/Inbox";


const authProtectedRoutes = [
  { path: paths.POLICIES_FAQS, component: FAQs },

  //ALL USERS
  { path: paths.USER_ACCOUNT_EDIT  + "/:userId", component: EditAccount },
  { path: paths.USER_TUTOR_SESSIONS + "/:userId", component: TutorSessions },

  { path: paths.TUTOR_PROFILE + "/:tutorId", component: TutorProfile },
  { path: paths.PAYMENT_SETTINGS, component: PaymentSettings },
  { path: paths.CART_VIEW_DETAILS, component: ViewCart },
  { path: paths.CART_ORDER_CONFIRMATION, component: OrderConfirmation },
  { path: paths.CART_ORDER_SUCCESS, component: SuccessPage },
  
  { path: paths.TUTOR_CONTRACTOR_AGREEMENT, component: SignContract }, //BOTH tutors & applicants
  
  //Applicants
  { path: paths.PROFICIENCY_EXAM_TAKE_ASSESSMENT + "/:assessmentId", component: Assessment },
  { path: paths.PROFICIENCY_EXAM_LIST, component: ExamList },


  { path: paths.SEARCH_TUTORS, component: SearchTutors },
  
  { path: paths.HOME_DASHBOARD, component: Dashboard, title: "Dashboard"},
  // this route should be at the end of all other routes
  // { path: "/", component: Dashboard, title: "Dashboard"}
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { component: Error404},
]

const studentRoutes = [
   //Students
   { path: paths.STUDENT_REQUEST_TUTOR, component: RequestTutor },
   { path: paths.STUDENT_TUTOR_REQUESTS + "/:studentId", component: MyTutorRequests, title: "My Tutor Requests" },
   { path: paths.STUDENT_TUTOR_REQUEST_PROGRESS + "/:tutorRequestId", component: TutorRequestProgress, title: "Tutor Request Details" },
   { path: paths.STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION + "/:tutorContractId", component: ScholarshipMatchConfirmation }, 
   { path: paths.STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE + "/:tutorContractId", component: SelectPaymentPlan }, 
   { path: paths.STUDENT_EVALUATION_FORM + "/:assignmentId", component: EvaluationFormDetailed, fullPage: true }, 

   { path: paths.STUDENT_MARKETPLACE_HOW_IT_WORKS, component: MarketHowItWorksStudent },
   { path: paths.STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS + "/:tutorRequestId", component: MarketTutorRequestProgress },
   { path: paths.CHAT_INBOX, component: ChatUser, fullPage: true},
]

const tutorRoutes = [
  //Tutors
  { path: paths.TUTOR_ADD_RETURNING_STUDENT, component: AddReturningStudents },
  { path: paths.TUTOR_LIST_TUTOR_CONTRACTS + "/:tutorId", component: MyTutorContracts },
  { path: paths.TUTOR_EVALUATIONS, component: Evaluations },
  { path: paths.TUTOR_CONNECT_TO_STRIPE, component: CreateStripeAccount },
  { path: paths.TUTOR_LOG_INTO_STRIPE, component: StripeAccountLogin },
  { path: paths.TUTOR_CHECKLIST, component: TutorChecklist },
  { path: paths.TUTOR_TAX_INFO, component: TaxInfo },

  {path: paths.TUTOR_SWITCH_TO_STRIPE_EXPRESS, component: SwitchStripeToExpress},
  
  //MarketPlace
  { path: paths.TUTOR_MARKETPLACE_HOW_IT_WORKS, component: MarketHowItWorksTutor },
  { path: paths.TUTOR_MARKETPLACE + "/:tutorId", component: MarketPlace },
  { path: paths.TUTOR_MARKETPLACE_CREATE_PROPOSAL + "/:marketRequestId", component: MarketCreateProposal },
  { path: paths.TUTOR_MARKETPLACE_PENDING_PROPOSALS + "/:tutorId", component: MarketProposalsStatus },
  { path: paths.CHAT_INBOX, component: ChatUser, fullPage: true},
]

const tutorApplicantRoutes = [];

const leadRoutes = [
  { path: paths.LEADS_LIST_ACTIVE, component: ActiveLeads },
]

const adminRoutes = [
   //Admin
   { path: paths.ADMIN_USER_MANAGER, component: UsersList },
   { path: paths.ADMIN_USER_DETAILS + "/:userId", component: UserDetails},
   { path: paths.ADMIN_TUTOR_REQUESTS, component: TutorRequestsAdmin},
   { path: paths.ADMIN_TUTOR_APPLICATIONS, component: TutorApplicationsList},
   { path: paths.ADMIN_TUTOR_APPLICATION_DETAILS  + "/:applicationId", component: TutorApplicationDetails},
   { path: paths.PROFICIENCY_EXAM_LOAD_ASSESSMENT_SOLUTIONS + "/:assessmentId", component: AssessmentAdmin },
   { path: paths.ADMIN_ASSIGNMENTS, component: AssignmentsAdmin},
   { path: paths.ADMIN_EVALUATIONS, component: EvaluationsAdmin},
   { path: paths.ADMIN_REFUNDS, component: RefundsAdmin},
   { path: paths.ADMIN_REFUNDS_ISSUE_BALANCE_REFUND + "/:assignmentId", component: IssueBalanceRefund},
   { path: paths.ADMIN_ANALYTICS, component: Analytics},
   { path: paths.ADMIN_SCHOLARSHIPS, component: ScholarshipAssignments},
   { path: paths.ADMIN_GOOGLE_SUBMIT_BATCH_URLS, component: GoogleSubmitBatchURLs},
   { path: paths.ADMIN_GOOGLE_MY_BUSINESS, component: GoogleMyBusiness},
   { path: paths.ADMIN_GMAIL_INBOX, component: Inbox},
   { path: paths.ADMIN_GOOGLE_AUTH_CALLBACK, component: GoogleAuthCallback},
   { path: paths.ADMIN_INBOX, component: ChatAdmin, fullPage: true},

   { path: paths.ADMIN_MARKETPLACE, component: MarketPlaceAdmin },
   { path: paths.ADMIN_MARKETPLACE_CONVERT_TUTOR_REQUEST + "/:tutorRequestId", component: MarketConvertTutorRequest },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password", component: ResetPassword },
]

const staffRoutes = [];

export { authProtectedRoutes, studentRoutes, tutorRoutes, tutorApplicantRoutes, leadRoutes, adminRoutes, staffRoutes, publicRoutes }
