import React, { Component } from "react"
import { TabPane, Row, Col, FormGroup, Label, Input } from "reactstrap"

class RateTab extends Component {
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Row>
            <Col lg="12">
              <p><b className="text-info">Please enter your desired take home earnings (i.e. your net income after our service fee has been deducted).</b></p>
              <div className="form-group row">
                <Col lg="4">
                  <Label for="minRate">
                    Min Hourly Rate
                  </Label>
                  <input
                    className="form-control"
                    type="number"
                    id="minRate"
                    name="minRate"
                    min="5"
                    max="200"
                    onChange={this.props.onChange}
                    value={this.props.formData.minRate}
                  />
                </Col>
                <Col lg="4">
                  <Label for="maxRate">
                    Max Hourly Rate
                  </Label>
                  <input
                    className="form-control"
                    type="number"
                    id="maxRate"
                    name="maxRate"
                    min="10"
                    max="200"
                    onChange={this.props.onChange}
                    value={this.props.formData.maxRate}
                  />
                </Col>
              </div>
              <FormGroup>
                <Label for="rateDetails">
                  Rate Details (Optional)
                </Label>

                <textarea
                  id="rateDetails"
                  name="rateDetails"
                  className="form-control"
                  rows="3"
                  placeholder={"Examples: \n1) My range is $20-$30.  $20/hr if the student wants to meet multiple times in a week, $30/hr for the following upper division courses: ... \n2) My group rate is $20/hr per student for a group of 2 and $15/hr per student for groups of  3 or more. \n3) My rate for online tutoring is $15-20/hr."}
                  onChange={this.props.onChange}
                  value={this.props.formData.rateDetails}
                />
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default RateTab
