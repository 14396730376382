//REF: https://www.npmjs.com/package/react-date-range
//REF: https://hypeserver.github.io/react-date-range/#daterange
//REQUIRES date-fns PACKAGE!!!
import React, { Component } from "react"

import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { addDays } from 'date-fns';

import moment from 'moment';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class DateRangeUI extends Component {
    //item.selection = {startDate: [native Date Object],
    //                  endDate: [native Date Object],}

  constructor(props) {
    super(props)
    this.state = {
      selection : {
          // startDate: this.props.startDate ? new Date(this.props.startDate) : new Date(),
          // endDate: this.props.endDate ? new Date(this.props.endDate) : new Date(), //null = continuous
          // key: 'selection',
      }
    }
    this.onChangeRange.bind(this)
  }

  componentDidMount() {
    const startDate = this.props.startDate ? new Date(this.props.startDate) : new Date();
    const endDate = this.props.endDate ? new Date(this.props.endDate) : startDate; //null = continuous
    const key = 'selection';

    const selection = {startDate, endDate, key};
    this.setState({selection});
  }

  onChangeRange = (item) =>{
    var selection = item.selection;
    this.setState({ selection });

    if (typeof this.props.onChange !== 'undefined') {
      let startDate = moment(selection.startDate).format('YYYY-MM-DD');
      let endDate = moment(selection.endDate).format('YYYY-MM-DD');
      this.props.onChange(startDate, endDate);
    }
  }

  render(){
    var daysInPast = this.props.daysInPast || 0; //e.g.-10 days in past
    var daysInFuture = this.props.daysInFuture || 900; //e.g.900 days in future
    var locale = this.props.locale || "enUS";
    var months = this.props.months || 1

    return (
      <DateRange
        editableDateInputs={true}
        onChange={this.onChangeRange}
        moveRangeOnFirstSelection={false}
        ranges = {[this.state.selection]}

        months={months}
        direction={this.props.direction || "vertical"}

        locale={locales[locale]}
        minDate={addDays(new Date(), daysInPast)}
        maxDate={addDays(new Date(), daysInFuture)}
        
        retainEndDateOnFirstSelection={true}
      />
    )
  }
}

export default DateRangeUI
