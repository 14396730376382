import React, { Component } from "react"
import { TabPane, Row, Col, FormGroup, Label, Input } from "reactstrap"

class ExperienceTab extends Component {
  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Row>
            <Col lg="12">
              <p>Briefly describe your teaching/tutoring experience and academic accomplishments. Use this section to demostrate your proficiency in the areas you wish to tutor. Write this in 3rd person, avoid "I", "you", etc. Be sure to include any experience as a test prep (e.g. SAT) instructor or tutor.</p>

              <p>Keep this section professional and relevant. <b>Do NOT include your full name, contact info or external links of any kind. We will shut down any account that includes this information in the profile.</b></p>

              <p className="text-danger">If you offer online tutoring, be sure to briefly describe the setup, e.g. the platform you use (e.g. Skype, Zoom), online tools you use (e.g. a shared whiteboard), how you provide students with a copy of the lesson or material discussed (e.g. Google Docs). Your selected platform must be FREE for students to use.</p>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="experience">
                  Experience
                </Label>
                <textarea
                  id="experience"
                  name="experience"
                  className="form-control"
                  rows="8"
                  placeholder="Describe your previous teaching/tutoring experience & academic accomplishments....this is the time to brag!"
                  onChange={this.props.onChange}
                  value={this.props.formData.experience}
                />
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default ExperienceTab
