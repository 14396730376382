import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom";
import moment from 'moment';

import { Alert } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

// import { validateName, validateEmail, validatePassword } from "../../../scripts/authentication"
import { cleanUpString, validateFormFields } from "../../../scripts/general"

import FormWizard from "../FormWizard"
import CourseTab from  "./CourseTab"
import LocationTab from  "./LocationTab"
import AvailabilityTab from  "./AvailabilityTab"
import StartEndDateTab from  "./StartEndDateTab"
import BudgetTab from  "./BudgetTab"
import { ThemeSpinner } from "theme/elements/spinner";

class RequestTutorForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      validationError: "",
      showSubmitButton: true,
      submitted: false,
      tabs: [
        {id: 1, title: "Course Info", component: CourseTab },
        {id: 2, title: "Type", component: LocationTab },
        {id: 3, title: "Availability", component: AvailabilityTab },
        {id: 4, title: "Dates", component: StartEndDateTab },
        {id: 5, title: "Budget", component: BudgetTab },
      ],
      formFields: {
        course: "",
        school: "",
        // postalCode: "",
        inPerson: true,
        online: true,
        sessionTime: "",
        frequency: "",
        // startDate: moment().format("YYYY-MM-DD"),
        // endDate: moment().add(3, "month").format("YYYY-MM-DD"),
        startDate: "",
        endDate: "",
        minRate: 40,
        maxRate: 80,
        currency: "USD",
        concerns: "",

        smsAlert: false,
        // firstName: "",
        // lastName: "",
        // email: "",
        // dialCode: "",
        // phone: "",
        // password: "",
        // marketing: "",
      }
    }
    this.resetForm.bind(this)
    this.validateForm.bind(this)
    this.onSubmit.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;
    
    if(prevProps != this.props){
      let newState = processApiResponse(apiResponse, this.state);

      if(apiResponse != {} && prevState != newState){
        this.setState(newState);
      }
    }
  }

  resetForm(){
    //NOTE: DOES NOT WORK.  Does not reset course in FormWizard
    let resetFormData = {...this.state.formFields};
    resetFormData.course = "";// Only reset the course for quick 2nd submissoin

    this.setState({formFields: resetFormData,
                    validationError: "",
                    showSubmitButton: true,
                    submitted: false
                  });
  }

  validateForm = (formData) =>{
    let valid = true;
    let error = "The following fields have an error: \n";
    let skipFields = ["inPerson","online", "concerns", "firstName", "lastName", "email", "phone", "password", "marketing", "smsAlert"];

    let errorFields = validateFormFields(formData, skipFields);

    // //Validating Conditional Contact Information
    // if (!formData.studentId) {
    //     if (!validateName(formData.firstName)) {
    //       errorFields.push("First Name");
    //       valid = false;
    //     }
    //     if (!validateName(formData.lastName)) {
    //       errorFields.push("Last Name");
    //       valid = false;
    //     }
    //     if (!validateEmail(formData.email)) {
    //       errorFields.push("Email");
    //       valid = false;
    //     }
    //     if (!validatePassword(formData.password)) {
    //       errorFields.push("Password");
    //       valid = false;
    //     }
    // }

    valid = !errorFields.length;
    error += errorFields.join(", ");
    return {valid, error};
  }

  onSubmit = (e, formData) => {
    e.preventDefault();
    const { user } = this.props;

    this.setState({validationError: "",
                    showSubmitButton: false});

    //ADD user id to form
    let studentId = user.id;

    if (studentId) {
      formData = {...formData,
                  studentId};
    }

    //Clean Up String Fields Before POST
    for (var key in formData) {
      if (typeof formData[key] == "string") {
        formData[key] = cleanUpString(formData[key]);
      }
    }

    //Validate Fields
    let validateResults = this.validateForm(formData);
    if (!validateResults.valid) {
      this.setState({validationError: validateResults.error});
      this.setState({showSubmitButton: true});
    }
    else{
      const { onSubmitApiCall } = this.props;
      onSubmitApiCall("submitTutorRequest", formData)
      this.setState({submitted: true});
    }
  }

  render() {
    let { redirect, validationError, tabs, formFields, showSubmitButton, submitted } = this.state;

    let { apiResponse } = this.props;
    let loading = apiResponse.loading;

    return (
      <React.Fragment>
        {
          redirect &&
              <Redirect to="/" />
        }
        {
          loading &&
            <center><ThemeSpinner /></center>
        }

        {validationError &&
          <Alert color="danger">{validationError}</Alert> }
        <b>All fields are required unless denoted as Optional.</b> <br /><br />
        <FormWizard formTitle="Request A Tutor"
            tabs={tabs}
            formFields={formFields}
            onSubmit={this.onSubmit}
            loading={loading}
            showSubmitButton={showSubmitButton}
            />

        {!loading && submitted &&
          <SweetAlert
            title="Tutor Request Received!"
            success
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="info"
            confirmBtnText="Submit Another"
            cancelBtnText="Go Home"
            onConfirm={() => this.resetForm()}
            onCancel={() => this.setState({ submitted: false, redirect: true })}
          >
            We'll get to work finding the perfect tutor for you.
            <br />
            You'll receive proposals from available tutors within the next 1-2 days
          </SweetAlert>
        }
        {validationError &&
          <Alert color="danger">{validationError}</Alert> }
      </React.Fragment>
    )
  }
}
const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
  user: userReducer,
  apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestTutorForm)
