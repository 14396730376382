import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { FormGroup, Input } from "reactstrap";

import { submitApiCallAction } from "store/actions"

import { states } from 'constants/states_codes';

class SendCheckrInvitationForm extends Component {
    constructor(props) {
      super(props)
    
      this.state = {}
    }

    onSubmit = formData => {
        const { userId, backgroundCheckId, tutorApplicationId } = this.props;

        formData["userId"] = userId;
        formData["backgroundCheckId"] = backgroundCheckId;
        formData["tutorApplicationId"] = tutorApplicationId;

        let { onSubmitApiCall } = this.props;         
        onSubmitApiCall("createCheckrInvitation", formData);
    }

    render() {
        let { disabled, apiResponse } = this.props;
        
        let loading = apiResponse.loading;

        const BackgroundSchema = Yup.object().shape({
            countryISO: Yup.string()
                            .required('Select the country where you will be working.')
            //                 ,
            // state: Yup.string()
            //             .required('Select the state where you will be working.')
          });

        return (
            <Formik
                initialValues={{ countryISO: '', state: "" }}
                validationSchema={BackgroundSchema}
                onSubmit={this.onSubmit}
            >
                { props  => (
                    <Form className="needs-validation">
                        <FormGroup className="mb-3">    
                            <Input 
                                type="select" 
                                id="countryISO" 
                                name="countryISO" 
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.countryISO}
                                className="custom-select">
                                    <option value={""} onClick={() => this.setState({countryISO: ""})}>
                                        Select Country
                                    </option>    
                                    <option value={"US"} onClick={() => this.setState({countryISO: "US"})}>
                                        United States
                                    </option>
                                    <option value={"CA"} onClick={() => this.setState({countryISO: "CA"})}>
                                        Canada
                                    </option>
                            </Input>
                            <ErrorMessage name="countryISO" component="div" className="text-danger" />
                        </FormGroup>
                          
                        {
                            props.values.countryISO == "US" &&
                                <FormGroup className="mb-3">
                                    <Input 
                                        type="select" 
                                        name="state" 
                                        className="custom-select" 
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.state}
                                        required>
                                        
                                            <option value={""}>
                                                Select State
                                            </option> 
                                            {
                                                Object.keys(states).map((stateAbbr, i) => 
                                                    <option key={"state" + i} value={stateAbbr}>
                                                        {states[stateAbbr]}
                                                    </option>
                                                )
                                            }
                                    </Input>
                                    {/* <ErrorMessage name="state" component="div" className="text-danger" /> */}
                                </FormGroup>
                        }   
                        <div className="mt-3">
                            {
                                loading
                                    ?
                                        <button
                                            className="btn btn-primary btn-block waves-effect waves-light"
                                            type="button"
                                            disabled={true}
                                        >
                                            Submitting... {" "}
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                        </button>
                                    :
                                        <button
                                            className="btn btn-primary btn-block waves-effect waves-light"
                                            type="submit"
                                            disabled={disabled}
                                        >
                                            Submit
                                        </button>
                          }
                        </div>
                    </Form>
                )}
            </Formik>
        )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendCheckrInvitationForm)
