import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

export const ThemeButton = (props) => {
    return (
        <div className="button-items">
            <Button
                color={props.color ||"primary"}
                className={"mr-1 waves-effect waves-light btn btn-outline" + props.className}
                outline={props.outline || false}
                onClick={props.onClick}
                active={props.active || true}
                disabled={props.disabled || false}
            >
                {
                    props.icon && 
                    <>
                        <i className={`${props.icon} font-size-16 align-middle mr-2`}></i>{" "}
                    </>
                }
                {props.children}
            </Button>
        </div>
    )
}

export const ThemeButtonRounded = (props) => {
    return (
        <div className="button-items">
            <Button
                color={props.color ||"primary"}
                className={"btn-rounded waves-effect waves-light " + props.className}
                outline={props.outline || false}
                onClick={props.onClick}
                active={props.active || true}
                disabled={props.disabled || false}
            >
                {
                    props.icon && 
                    <>
                        <i className={`${props.icon} font-size-16 align-middle mr-2`}></i>{" "}
                    </>
                }
                {props.children}
            </Button>
        </div>
    )
}

export const ThemeButtonLink = (props) => {
    return (
        <span className="button-items">
            <Link
                to={props.to || "#"}
                role="button"
                className={`btn btn-${props.color || "primary"} waves-effect waves-light ${props.className}`}
                target={props.target || ""}
                onClick={props.onClick}
            >
                {
                    props.icon && 
                    <>
                        <i className={`${props.icon} font-size-16 align-middle mr-2`}></i>{" "}
                    </>
                }
                {props.children}
            </Link>
        </span>
    )
}

export const ThemeButtonGroup = (props) => {
    return (
        <div
            className="btn-group"
            role="group"
            aria-label="Basic example"
        >
            {
                props.buttons.map((button, i)=>
                    <Button key={i} color={button.color || "primary"} value={button.value}
                                    onClick={props.onClick} 
                                    className={props.className}
                                    disabled={button.disabled || false} >
                        {
                            button.icon && 
                            <>
                                <i className={`${button.icon} font-size-16 align-middle mr-2`}></i>{" "}
                            </>
                        }
                        {button.text}{" "}    
                    </Button>
                )
            }
        </div>
    )
}
