import React, { Component } from 'react'
import { connect } from "react-redux"

import { Alert, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { Dropdown, DropdownButton } from "react-bootstrap";

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { requestFilterOptions } from './scripts';

import BTNMetaTags from "components/metatags/BTNMetaTags"
// import MarketPlaceTable from 'components/tables/marketplace/MarketPlaceTable';
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TUTOR_MARKETPLACE_CREATE_PROPOSAL, TUTOR_MARKETPLACE_HOW_IT_WORKS, TUTOR_MARKETPLACE_PENDING_PROPOSALS } from 'routes/paths';


class MarketPlace extends Component {
    constructor(props) {
        super(props)
        this.state = {
          marketRequests: [],
          tutorId: props.match.params.tutorId,

          selectedFilterOption: 1,
          filteredRequests: [],
          filterOnline: true,
          filterInPerson: true,
          filterOptions: requestFilterOptions,
        }
      }

    componentDidMount() {
        let { tutorId } = this.state;
        const { onSubmitApiCall } = this.props
        onSubmitApiCall("getMarketPlace", tutorId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    filterRequests = eventKey => {
        let { filterOptions, marketRequests } = this.state;

        let marketRequestsList = [...marketRequests];
        let selectedFilterOption = filterOptions.find(option => option.id == eventKey)

        marketRequestsList = marketRequestsList.filter(request => selectedFilterOption.filter(request));

        this.setState({
            selectedFilterOption: selectedFilterOption.id,
            filteredRequests: marketRequestsList,
        });
    }

    getDeadlineClass = marketRequest =>{
        let deadlineDays = moment().diff(marketRequest.proposalsDeadline, 'days'); //today - deadline
        let deadlineHours = moment().diff(marketRequest.proposalsDeadline, 'hours'); 
        let deadlinePast = deadlineDays > 0 ? true : false;
        let deadlineClass = "text-dark";
        
        if(deadlinePast){
            deadlineClass = "text-danger";
        }
        else if(Math.abs(deadlineHours) < 3){
            deadlineClass = "text-warning"
        }

        return deadlineClass;
    }

    render() {
        const { apiResponse, user } = this.props
        let { marketRequests, filterOptions, filteredRequests, selectedFilterOption } = this.state
        
        const { loading, error } = apiResponse;

        let selectedTitle = filterOptions.find(opt => opt.id == selectedFilterOption).title;

         //First loading, load all proposals
        if(selectedFilterOption == 1 && filteredRequests.length == 0){
            filteredRequests = marketRequests;
        }

        return (
            <>
                <BTNMetaTags title="Tutor Requests" />
                <Row>
                    <Col xl={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                <h3>Tutor Requests</h3>
                                { error && <Alert color="danger">{error}</Alert>}
                                <Link to={TUTOR_MARKETPLACE_HOW_IT_WORKS}>How It Works</Link>
                                <br /><br />
                                <Link to={TUTOR_MARKETPLACE_PENDING_PROPOSALS + "/" + user.id} className="btn btn-outline-info btn-sm">View Pending Proposals</Link>
                                <br /><br />
                                <Row>
                                    <Col sm="4" xs="12">
                                        <DropdownButton
                                            className="border-0 f-16 p-0 min-w-150 cut-texts"
                                            variant="outline-default"
                                            onSelect={this.filterRequests}
                                            title={"Filter: " + selectedTitle}
                                            >
                                            {
                                                filterOptions.map((option, i) =>
                                                    <Dropdown.Item
                                                        key={"dropdown-"+i}
                                                        eventKey={option.id}
                                                        active={selectedFilterOption === option.id}
                                                    >
                                                        <span>{option.title}</span>
                                                    </Dropdown.Item>
                                                )
                                            }
                                        </DropdownButton>
                                    </Col>
                                </Row>
                                {
                                    loading
                                        ? <ThemeSkeleton />
                                        : 
                                            <Table key={"table-" + Math.random()}>
                                                <tbody className="p-2">
                                                    {filteredRequests.length == 0 && <center>No Requests</center>}
                                                    {filteredRequests.map((request, i) =>
                                                        <tr key={"row-"+i}>
                                                            <td>
                                                                <Row>
                                                                    <Col sm="10">
                                                                        <h5>
                                                                            {request.studentName} <br />
                                                                            {request.course} {" "}
                                                                            {
                                                                                request.school &&
                                                                                <> @ {request.school}</>   
                                                                            }
                                                                            <br />
                                                                            <small>{request.studentLocation}</small>
                                                                        </h5>
                                                                        {moment(request.startDate).format("MMM Do")} to {moment(request.endDate).format("MMM Do YYYY")}
                                                                        <br />
                                                                        {
                                                                            request.online &&
                                                                                <span className="badge badge-soft-info">Online Tutoring</span> 
                                                                        }
                                                                        {" "}
                                                                        {
                                                                            request.inPerson &&
                                                                                <span className="badge badge-soft-success">In-Person Tutoring</span>
                                                                        }
                                                                        <br /><br />
                                                                        <b className={this.getDeadlineClass(request)}>
                                                                            Respond By {moment(request.proposalsDeadline).format("lll")}  ({moment(request.proposalsDeadline).fromNow()})
                                                                        </b>
                                                                        <br /><br />
                                                                    </Col>
                                                                    <Col sm="2">
                                                                        <>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Link to={TUTOR_MARKETPLACE_CREATE_PROPOSAL + "/" + request.id } className="btn btn-outline-info">View Details</Link>
                                                                                    <br />
                                                                                    {   
                                                                                        request.proposalsLeft && request.proposalsLeft <= 3 &&
                                                                                            <>
                                                                                                <em className="text-danger">{request.proposalsLeft} Proposals Left</em>
                                                                                                <br />
                                                                                            </>
                                                                                    }
                                                                                    { request.proposalSubmitted == true && <em>Submitted </em> }
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(MarketPlace)
  