import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"

import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap"

import { triggerReducerLoadingAction, submitApiCallAction, submitApiCallWithReducerAction } from "store/actions"
import { TUTOR_PROFILE } from "routes/paths"
import { processApiResponse } from 'scripts/api'

import CustomReactSwitch from "components/switch/CustomReactSwitch"
import { ThemeFileUpload } from "theme/elements/styledInputs"

import FormWizard from "../FormWizard"

import TutorDetailsTab from "./TutorDetailsTab"
import AvailabilityTab from "./AvailabilityTab"
import ExperienceTab from "./ExperienceTab"
import CoursesTab from "./CoursesTab"
import RateTab from "./RateTab"
import { ThemeSpinner } from "theme/elements/spinner"
import { ThemeButtonLink } from "theme/elements/buttons"
import { LOADING_PROFILE, PROFILE_ERROR, SAVE_PROFILE } from "store/profile/actionTypes"

import TutorLocationsMapForm from "../formik/edit-account/TutorLocationsMapForm"


class EditProfileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tutorId: this.props.tutorId,
      avatarFile: "",
      fileError: "",

      //For Form Wizard
      activeTab: 1,
      tabs: [
        {id: 1, title: "Tutor Details", component: TutorDetailsTab },
        {id: 2, title: "Availability", component: AvailabilityTab },
        {id: 3, title: "Experience", component: ExperienceTab },
        {id: 4, title: "Courses", component: CoursesTab },
        {id: 5, title: "Rate", component: RateTab },
      ],

      profile: {}
    }
    this.onSubmit.bind(this)
  }

  componentDidMount() {
    const { user, onSubmitApiCall, onReducerLoading, onSubmitApiCallWithReducer } = this.props;
    const { tutorId } = this.state;
    
    if(user.id == tutorId){
      onReducerLoading(LOADING_PROFILE);
      onSubmitApiCallWithReducer("getProfileDetails", SAVE_PROFILE, tutorId, PROFILE_ERROR);
    }
    else{
      onSubmitApiCall("getProfileDetails", tutorId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;
    
    if(prevProps != this.props){
        let newState = processApiResponse(apiResponse, this.state);

        if(apiResponse != {} && prevState != newState){
            this.setState(newState);
        }
    }
  }

  updateTutorStatus = status => {
    const { user, onSubmitApiCall, onSubmitApiCallWithReducer, onReducerLoading } = this.props;
    const { tutorId } = this.state;

    if(user.id == tutorId){
      onReducerLoading(LOADING_PROFILE);
      onSubmitApiCallWithReducer("updateProfileStatus", SAVE_PROFILE, {userId: tutorId, status}, PROFILE_ERROR);
    }
    else{
      onSubmitApiCall("updateProfileStatus", {userId: tutorId, status});
    }
    
    this.setState({profile: {...this.state.profile, 
                              acceptingStudents: status}});
  }

  onChangeAvatar = event => {
    let avatarFile = event.target.files[0];
    let maxSize = 1024 * 100; //100 KB;

    if (avatarFile.size > maxSize) {
      this.setState({fileError: "File too large.  Max size is 100 KB"});
    }
    else{
      this.setState({ avatarFile });
    }
  }

  uploadAvatar = event => {
    event.preventDefault();

    let { avatarFile, tutorId } = this.state;
    let { user, onSubmitApiCall, onReducerLoading, onSubmitApiCallWithReducer } = this.props;
    
    if(avatarFile != null && avatarFile != ""){
      if(user.id == tutorId){
        onReducerLoading(LOADING_PROFILE);
        onSubmitApiCallWithReducer("updateProfileAvatar", SAVE_PROFILE, { tutorId, avatarFile }, PROFILE_ERROR);
      }
      else {
        onSubmitApiCall("updateProfileAvatar", { tutorId, avatarFile });
      }
    }
    else{
      this.setState({fileError: "No File Selected"});
    }
  }

  onSubmit = (e, formData) => {
    e.preventDefault();

    let { user, onSubmitApiCall, onReducerLoading, onSubmitApiCallWithReducer } = this.props;
    const { tutorId } = this.state;
    
    formData = {...formData, tutorId};
    
    //Min / Max Rates As Integers
    formData.minRate = Math.round(formData.minRate);
    formData.maxRate = Math.round(formData.maxRate);
    
    if(user.id == tutorId){
      onReducerLoading(LOADING_PROFILE);
      onSubmitApiCallWithReducer("updateProfileDetails", SAVE_PROFILE, formData, PROFILE_ERROR);
    }
    else{
      onSubmitApiCall("updateProfileDetails", formData);
    } 
  }

  updateSessionLocations = sessionLocations => {
    const { onSubmitApiCall } = this.props;
    const { tutorId } = this.state;

    onSubmitApiCall("updateSessionLocations", { tutorId, sessionLocations});
  }

  updateMapGeolocations = geolocations => {
    const { onSubmitApiCall } = this.props;
    const { tutorId } = this.state;
    
    onSubmitApiCall("updateProfileGeolocations", { tutorId, geolocations});
  }

  render() {
    const { profileReducer={}, apiResponse, user } = this.props;
    let { tutorId, fileError, tabs, profile } = this.state;

    if(user.id == tutorId){
      profile = profileReducer;
    }

    let { acceptingStudents, avatar, active, onBreak,
            startDate, endDate, breakStartDate, breakReturnDate } = profile;
    
    let loading = profileReducer.loading || apiResponse.loading;
    let title = "";
    
    if(!active){
      title  = <>
                  <span className="text-danger">Profile Not Active</span><br />
                  Fill in all required fields (*) and check the Start/End dates
                </>
    } else if(onBreak){
      title = <>
                <span className="text-warning">Tutor On Break</span><br />
                Update the Break Dates to activate profileReducer.
              </>
      
    }

    //Dates
    if(profile.hasOwnProperty("startDate")){
      startDate = startDate != null && startDate != "" ? moment(startDate).format("YYYY-MM-DD") ||"" : "";
      endDate = endDate != null && endDate != "" ? moment(endDate).format("YYYY-MM-DD") ||"" : "";

      breakStartDate = breakStartDate != null && breakStartDate != "" ? moment(breakStartDate).format("YYYY-MM-DD") ||"" : "";
      breakReturnDate = breakReturnDate != null && breakReturnDate != "" ? moment(breakReturnDate).format("YYYY-MM-DD") ||"" : "";

      profile = { ...profile, startDate, endDate, breakStartDate, breakReturnDate };
    }
    
    return (
        <>
          {
            loading &&
                <center><ThemeSpinner /></center>
          }
          <Card>
            <CardBody>
              
              <CardTitle>Edit Profile</CardTitle>
              <Row>
                  {!avatar && 
                      <Col lg="2">
                        <CardImg
                          src={avatar}
                          className="rounded avatar-lg"
                        />
                      </Col>
                  }
                  <Col>
                    <ThemeButtonLink to={TUTOR_PROFILE + "/" + tutorId} target="_blank" color="info">
                      View Profile 
                    </ThemeButtonLink>
                  </Col>
              </Row>
              <br />
              <Row>
                <Col lg="6">
                  <CardTitle>Status</CardTitle>
                  Are you available to take on more students? {" "}
                  <br />
                  {
                    profile.hasOwnProperty("acceptingStudents") &&
                      <CustomReactSwitch yesLabel={"Available"} 
                          noLabel={"Unavailable"} 
                          checked={acceptingStudents} 
                          onColor={"success"} 
                          offColor={"danger"}
                          onChange={this.updateTutorStatus} />
                  }
                </Col>
                <Col lg="6">
                  <CardTitle>Profile Picture</CardTitle>
                  <ThemeFileUpload name={"avatar"}
                                    accept="image/*"
                                    onChange={this.onChangeAvatar}
                                    onClick={this.uploadAvatar} />
                  {fileError && <small className="text-danger">{fileError}</small>}
                  <br />

                  <p>Upload a professional picture for review. Upon review and approval, we'll add the picture to your profile</p>
                  <p>Please crop your picture to a square image (e.g. 250px x 250px) before uploading it.</p>
                  <small className="text-secondary">Note: You may need to reload the page to view changes </small>
                </Col>
              </Row> 
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle>Profile Details</CardTitle>
                <p>To save changes, navigate to the last page and click "Submit"</p>
              {
                profile.hasOwnProperty("major") &&
                  <FormWizard 
                    key={"form-"+(profile.major ? "wizard" : "loading")}
                    formTitle={title}
                    tabs={tabs}
                    formFields={profile}
                    onSubmit={this.onSubmit}/>
              }
            </CardBody>
          </Card>

          <TutorLocationsMapForm key={profile.geolocations != null ? "map-" + profile.geolocations.length : "map"}
              id={profile.geolocations != null ? "map-" + profile.geolocations.length : "map"}
              center={profile.lat ? {lat: profile.lat, lng: profile.lng} : null}
              geolocations={profile.geolocations || []} 
              sessionLocations={profile.sessionLocations}
              onSubmitSessionLocations={this.updateSessionLocations}
              onSubmitMapGeolocations={this.updateMapGeolocations} />
        </>
    )
  }
}


const mapStateToProps = ({ profileReducer, userReducer, apiResponseReducer }) => ({
  profileReducer,
  user: userReducer,
  apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
  onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm)