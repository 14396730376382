import React from "react"

export const ThemeSpinner = (props) => {
    return (
        <div
            className={`spinner-border text-${props.color || "primary"} m-1`}
            role="status"
        >
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export const ThemeSpinnerGrow = (props) => {
    return (
        <div
            className={`spinner-grow text-${props.color || "primary"} m-1`}
            role="status"
        >
            <span className="sr-only">Loading...</span>
        </div>
    )
}