import React, { Component } from 'react'
import { Link } from "react-router-dom";

import { ReactComponent as GallarySvg } from "theme-chat/assets/media/icons/gallary.svg";

export default class ChatMedia extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showMedia: true,
        }
    } 

    toggleShowMedia = () => {
        this.setState({ showMedia: !this.state.showMedia });
    };

    render() {
        let { images } = this.props;
        let { showMedia } = this.state;

        return (
            <div className="chat-info-group">
                <Link
                    className="chat-info-group-header"
                    data-toggle="collapse"
                    to="#"
                    onClick={this.toggleShowMedia}
                >
                    <h6 className="mb-0">Media</h6>
                    <GallarySvg className="hw-20 text-muted" />
                </Link>

                <div
                    className={
                        "chat-info-group-body collapse " +
                        (showMedia ? "show" : "")
                    }
                    id="shared-media"
                >
                    <div className="chat-info-group-content">
                        <div className="form-row">
                        {
                            images.map((image, i) =>
                                <div key={"image-" + i} className="col-4 col-md-2 col-xl-4">
                                    <a href={image} target="_blank" rel="noreferrer">
                                        <img
                                            src={image}
                                            className="img-fluid rounded border"
                                            alt=""
                                        ></img>
                                    </a>
                                </div>
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
