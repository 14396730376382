import React, { Component } from 'react';
import { connect } from 'react-redux';

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import TutorRatePricing from 'components/pricing/TutorRatePricing';
import { ThemeSpinner } from 'theme/elements/spinner';
import ThemeAvatar from 'theme/images/ThemeAvatar';

class SelectPaymentPlan extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tutorContractId: props.match.params.tutorContractId, 
            tutorContract: {}
        }
    }

    componentDidMount() {
        let { tutorContractId } = this.state;
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorContractForStudent", tutorContractId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        let { tutorContract } = this.state;
        let { apiResponse } = this.props;

        let loading = apiResponse.loading;
        
        return (
            <>    
                <Container fluid>
                    <BTNMetaTags title="Select Payment Plan" />
                    
                    <Row className="justify-content-center">
                        <div className="text-center mb-2">
                            <h4>Choose A Payment Plan</h4>
                            <p className="text-muted">
                                Enjoy a lower rate with the Prepaid plan or the flexibility of the Pay As You Go plan.
                            </p>
                        </div>
                    </Row>
                    
                    {
                        tutorContract.tutorName != null &&
                        
                        <Row>
                            <Col xl={{size: 6, offset:  3}}>
                                <Card>
                                    <CardBody>
                                        <Row>                                        
                                            <Col sm={{size: 2, offset:  2}} xs  ="3">
                                                <div className="text-lg-center">
                                                    <ThemeAvatar avatar={tutorContract.tutorAvatar} name={tutorContract.tutorName} size={"md"}/>
                                                </div>
                                            </Col>
                                            <Col sm={{size: 6}} xs="8">
                                                <h5 className="text-truncate mb-4 mb-lg-1">
                                                    {tutorContract.course}<br />
                                                    {tutorContract.tutorName} <br />
                                                    <span className="font-size-15 text-muted">
                                                        {tutorContract.education}<br />
                                                        {tutorContract.major}
                                                    </span>
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size: 8, offset:  2}} xs="12">
                                                <h5><b className="text-primary">24 Hour Full Refund Guarantee </b></h5>
                                                <p>If any issue or concern arises with this tutor <b>before</b> the first session (e.g. conflicting schedules, unresponsive tutor), we will fully refund your prepayment including processing fees. <br /><b>Get started with {tutorContract.tutorName} risk free today!</b></p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    {
                        loading ?
                            <Card>
                                <CardBody>
                                    <center><ThemeSpinner /></center>
                                </CardBody>
                            </Card>
                        :
                        <TutorRatePricing key={"pricing-" + tutorContract.id} tutorContract={tutorContract} />     
                    }   
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, cartReducer }) => ({
    apiResponse: apiResponseReducer,
    cart: cartReducer
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentPlan)

