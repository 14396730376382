import moment from "moment";

export const deadlineHasPassed = proposal =>{
    return proposal.studentDeadline != null && moment().diff(proposal.studentDeadline, 'minutes') > 0;
}

const filterUnreviewed = proposal => { return proposal.studentDecision == null};
const filterAccepted = proposal => { return proposal.studentDecision == true};
const filterDeclined = proposal => { return proposal.studentDecision == false};
const filterMissed = proposal => { return proposal.studentDecision != true && deadlineHasPassed(proposal)};

export const proposalFilterOptions = [
    {id: 1, title: "All", filter: () => {return true}},
    {id: 2, title: "Unreviewed", filter: filterUnreviewed},
    {id: 3, title: "Accepted", filter: filterAccepted},
    {id: 4, title: "Declined", filter: filterDeclined},
    {id: 5, title: "Missed", filter: filterMissed},
]

export const sortScore = (a,b) => {
    //sort desc (highest score @ top)
    return (a.tutorScore + a.matchScore) < (b.tutorScore + b.matchScore); 
}

export const sortTutors = (a,b) => {
    //sort desc (highest score @ top)
    return a.tutorScore < b.tutorScore; 
}

export const sortRateHigh = (a,b) => {
    return a.rate < b.rate; 
}

export const sortRateLow = (a,b) => {
    return a.rate > b.rate; 
}

const sortNew = (a,b) =>{
    return  a.createdAt < b.createdAt;
};

// const sortOld = (a,b) =>{
//     return a.createdAt > b.createdAt;
// };


export const proposalSortOptions =[
    {id: 1, title: "Recommended", sort: sortScore},
    {id: 2, title: "Top Rated", sort: sortTutors},
    {id: 3, title: "Newest", sort: sortNew},
    {id: 4, title: "Rate (High To Low)", sort: sortRateHigh},
    {id: 5, title: "Rate (Low To High", sort: sortRateLow},
]