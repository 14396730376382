import React, { Component } from 'react'
import { Form, Formik } from 'formik';
import { Input } from 'reactstrap';

export default class DuplicateTutorRequestForm extends Component {
    onSubmit = values => {
        this.props.onSubmit(values);
    }

  render() {
    let { course, loading } = this.props;
    let initialValues = { editedCourse: course, newCourse: course }

    return (
        <Formik
            initialValues={{...initialValues}}
            onSubmit={this.onSubmit}
        >
            { props  => (
                <Form className="needs-validation">
                    <div className="form-group">
                        <Input
                            name="editedCourse"
                            label="Edited (Old) Course"
                            value={props.values.editedCourse}
                            className="form-control"
                            placeholder="Edited Course"
                            type="text"
                            autoFocus={true}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            name="newCourse"
                            label="New Course"
                            value={props.values.newCourse}
                            className="form-control"
                            placeholder="New Course"
                            type="text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </div>
                    <button
                        className="col-md-6 col-offset-md-6 btn btn-sm btn-info btn-block waves-effect waves-light"
                        type="submit"
                        disabled={loading}
                    >
                        Duplicate Request
                    </button>
                </Form>
            )}
      </Formik>
    )
  }
}

