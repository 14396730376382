import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

import { ThemeButtonLink } from 'theme/elements/buttons'
import { ThemeSpinner } from 'theme/elements/spinner'
import SignContractForm from 'components/forms/formik/tutorApplicants/SignContractForm'

class SignContract extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            signedContractURL: "",
            contractorAgreementURL: ""
        }
        this.getContractorAgreement.bind(this)
    }
    
    componentDidMount() {
        this.getContractorAgreement();    
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    getContractorAgreement = () =>{
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorContractorAgreementTemplate");
    }

    handleValidSubmit = (values) => {
        const { onSubmitApiCall } = this.props;
        let address = values.address;
        let legalName = values.name;

        onSubmitApiCall("signTutorContractorAgreement", { address, legalName});
    }

    render() {
        let { contractorAgreementURL, signedContractURL } = this.state;
        let { apiResponse } = this.props;
        let loading  = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="Sign Tutor Independent Contractor Agreement" />
                
                <Container fluid>
                    <Row>
                        <Col md={{offset: 2, size: 8}}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Submit Form To Sign Contract</CardTitle>
                                    {
                                        loading &&
                                            <center><ThemeSpinner /></center>
                                    }
                                    {
                                        signedContractURL ?
                                                <>
                                                    <p>Save a copy of the signed contract for your records.</p>
                                                    <p><a href={signedContractURL} target="_blank" rel="noreferrer">Download Signed Contract</a></p>

                                                    <br />
                                                        <ThemeButtonLink to="/">
                                                            Home
                                                        </ThemeButtonLink>
                                                    <p>NOTE TO NEW HIRES: You may need to log out and sign back into your account to view your Tutor Dashboard.</p>
                                                </>
                                            :
                                                <>
                                                    <p>Carefully review the contract and sign below.</p>

                                                    {
                                                        contractorAgreementURL &&
                                                            <center><a href={contractorAgreementURL} target="_blank" rel="noreferrer">Review Contractor Agreement**</a><br /><br /></center>
                                                    }
                                                    <small>** This is just the template. After signing the contract, you will be able to download the contract with your information filled in.</small>
                                                    <br /><br />
                                                    <p><b>By signing this agreement, I understand that: </b></p>
                                                    <li>I must be available and work with assigned students until the end of the academic term/semester in which I began working with the assigned student.</li>
                                                    <li>I must report <b>all</b> sessions with assigned students using the BuffTutor platform.</li>
                                                    <li>I must report each session using the BuffTutor platform within <b>3 days</b> of the session.</li>
                                                    <li><b className="text-danger">I cannot accept a direct payment of any form from assigned students</b> and doing so will result in fines and legal action. All sessions must be charged using the BuffTutor platform.</li>
                                                    <li>My electronic signature below is legally binding</li>
                                                    <br /><br />
                                                    
                                                    <Row>
                                                        <Col md={{offset: 6, size: 6}}>
                                                            <SignContractForm onSubmit={this.handleValidSubmit} loading={loading} />
                                                        </Col>
                                                    </Row> 
                                                </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignContract)
