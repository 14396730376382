import React, { Component } from 'react'

import { Card, CardBody, Col, Row } from 'reactstrap';

import NavTabs from 'components/navTabs/NavTabs';
import TutorSessionsTable from 'components/tables/tutor-sessions/TutorSessionsTable';
import AssignmentsAdminTable from 'components/tables/assignments/AssignmentsAdminTable';
import EvaluationsTable from 'components/tables/evaluations/EvaluationsTable';
import PaymentsTable from 'components/tables/payments/PaymentsTable';
import RefundsTable from 'components/tables/refunds/RefundsTable';
import MiniCard from 'components/cards/MiniCard';

import { getCurrencySymbol } from 'scripts/general';

export default class StudentDetails extends Component {
    sortResults = () => {
        //Sort Id Desc
        return function (a, b) {
            return a.id < b.id;
        }
    }

    render() {
        let { student={} } = this.props;
        let assignments = student.assignments.sort(this.sortResults()) ||[];
        let tutorSessions = student.tutorSessions.sort(this.sortResults()) || [];
        let evaluations = student.evaluations.sort(this.sortResults()) || [];
        let payments = student.payments.sort(this.sortResults()) || [];
        let refunds = student.refunds.sort(this.sortResults()) || [];
       
        let tabs = [{id: 1, title: 'Assignments', content: <AssignmentsAdminTable assignments={assignments} />},
                    {id: 2, title: 'Tutor Sessions', content: <TutorSessionsTable tutorSessions={tutorSessions} role={"admin"} />},
                    {id: 3, title: 'Payments', content: <PaymentsTable payments={payments} admin={true}/>},
                    {id: 4, title: 'Refunds', content: <RefundsTable role="admin" refunds={refunds} />},
                    {id: 5, title: 'Evaluations', content: <EvaluationsTable admin={true} evaluations={evaluations} />},];

        let total = 0;
        let currencySymbol = "$";
        payments.forEach(payment => {
            const currency = payment.currency || "usd";
            currencySymbol = getCurrencySymbol(currency);
            total += payment.total;
        });

        return (
            <div>
                <Row>
                    <Col md="4">
                        <MiniCard title={"Assignments"} text={assignments.length.toString()} icon={"bx-user"} />
                    </Col>
                    <Col md="4">
                        <MiniCard title={"Sessions"} text={tutorSessions.length.toString()} icon={"bx-chalkboard"} />
                    </Col>
                    <Col md="4">
                        <MiniCard title={"Payments"} text={typeof total == "number" ? currencySymbol + total.toFixed(2) : "$0"} icon={"bx-dollar"} />
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <NavTabs tabs={tabs}/>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
