//Color: https://codepen.io/chiss22/pen/qBdEqdj
export const eventStatuses = [{status: "available", color: "#16A765"}, 
                            {status: "pending", color: "#000000"}, 
                            {status: "confirmed", color: "#9FC6E7"}, 
                            {status: "expiring-soon", color: "#FF7537"},
                            {status: "expired", color: "#FA573C"},
                            {status: "deleted", color: "#AC725E"},
                            {status: "cancelled", color: "#FAD165"}
                            ]

export const getStatus = (event) => {
    let { extendedProps } = event;
    let { type, status, confirmedAt } = extendedProps;
    
    if(type == "availability" || type == "available"){
        return eventStatuses.filter(eventStatus => eventStatus.status == "available")[0];
    }

    if(status != null){
        let statusArray = eventStatuses.filter(eventStatus => eventStatus.status == status);
        if(statusArray.length > 0){
            return statusArray[0];
        }
    }

    if(confirmedAt != null){
        return eventStatuses.filter(eventStatus => eventStatus.status == "confirmed")[0];
    }
    else{
        return eventStatuses.filter(eventStatus => eventStatus.status == "pending")[0];
    }

}

export const createTutorSessionEventTitle = (event, userRefId) =>{
    let { extendedProps } = event;
    let { studentId, tutorId, tutorName, studentName, course } = extendedProps;

    let name = "Tutor Session";

    if(studentId && studentId == userRefId){
        name = tutorName;
    }
    else if(tutorId && tutorId == userRefId){
        name = studentName;
    }
    
    return name + " - " + course;
}

export const createTutorSessionEventTitleAdmin = (event, role) => {
    let { extendedProps } = event;

    let name = "Tutor Session";

    if(role == "student"){
        name = extendedProps.tutorName;
    }
    else if(role == "tutor"){
        name = extendedProps.studentName;
    }

    return name + " - " + extendedProps.course;
}
