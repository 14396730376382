//REF: https://github.com/endSly/world-universities-csv/blob/master/world-universities.csv
//CSV TO JSON: https://www.convertcsv.com/csv-to-json.htm
const universities = {
    "AD": [
       {
          "university": "University of Andorra",
          "website": "http://www.uda.ad/"
       }
    ],
    "AE": [
       {
          "university": "Abu Dhabi University",
          "website": "http://www.adu.ac.ae/"
       },
       {
          "university": "Ajman University of Science & Technology",
          "website": "http://www.ajman.ac.ae/"
       },
       {
          "university": "Alain University of Science and Technology",
          "website": "http://www.alainuniversity.ac.ae/"
       },
       {
          "university": "Al Ghurair University",
          "website": "http://www.agu.ae/"
       },
       {
          "university": "Alhosn University",
          "website": "http://www.alhosnu.ae/"
       },
       {
          "university": "Al Khawarizmi International College",
          "website": "http://www.khawarizmi.com/"
       },
       {
          "university": "American College Of Dubai",
          "website": "http://www.acd.ac.ae/"
       },
       {
          "university": "American University in Dubai",
          "website": "http://www.aud.edu/"
       },
       {
          "university": "American University in the Emirates",
          "website": "http://www.aue.ae/"
       },
       {
          "university": "American University of Sharjah",
          "website": "http://www.aus.edu/"
       },
       {
          "university": "British University in Dubai",
          "website": "http://www.buid.ac.ae/"
       },
       {
          "university": "Dubai Medical College for Girls",
          "website": "http://www.dmcg.edu/"
       },
       {
          "university": "Dubai Pharmacy College",
          "website": "http://www.dpc.edu/"
       },
       {
          "university": "Etisalat University College",
          "website": "http://www.ece.ac.ae/"
       },
       {
          "university": "Gulf Medical University",
          "website": "http://www.gmu.ac.ae/"
       },
       {
          "university": "Hamdan Bin Mohammed e-University",
          "website": "http://www.hbmeu.ac.ae/"
       },
       {
          "university": "Higher Colleges of Technology",
          "website": "http://www.hct.ac.ae/"
       },
       {
          "university": "Ittihad University",
          "website": "http://www.ittihad.ac.ae/"
       },
       {
          "university": "Jumeira University",
          "website": "http://www.ju.ac.ae/"
       },
       {
          "university": "Khalifa University",
          "website": "http://www.kustar.ac.ae/"
       },
       {
          "university": "Khalifa University of Science - Technology and Research",
          "website": "http://www.ku.ac.ae/"
       },
       {
          "university": "Masdar University Of Science And Technology",
          "website": "http://www.masdar.ac.ae/"
       },
       {
          "university": "New York University - Abu Dhabi",
          "website": "http://nyuad.nyu.edu/"
       },
       {
          "university": "Paris-Sorbonne University Abu Dhabi",
          "website": "http://www.sorbonne.ae/"
       },
       {
          "university": "Rak Medical & Health Sciences University",
          "website": "http://www.rakmhsu.com/"
       },
       {
          "university": "Rochester Institute of Technology - Dubai",
          "website": "http://dubai.rit.edu/"
       },
       {
          "university": "Skyline University College - Sharjah",
          "website": "http://www.skylineuniversity.com/"
       },
       {
          "university": "Synergy University - Dubai Campus",
          "website": "http://www.synergydubai.ae/"
       },
       {
          "university": "The Emirates Academy of Hotel Managment",
          "website": "http://www.emiratesacademy.edu/"
       },
       {
          "university": "The Petroleum Institute",
          "website": "http://www.pi.ac.ae/"
       },
       {
          "university": "United Arab Emirates University",
          "website": "http://www.uaeu.ac.ae/"
       },
       {
          "university": "University Of Dubai",
          "website": "http://www.ud.ac.ae/"
       },
       {
          "university": "University of Jazeera",
          "website": "http://www.uojazeera.com/"
       },
       {
          "university": "University of Sharjah",
          "website": "http://www.sharjah.ac.ae/"
       },
       {
          "university": "University of Wollongong - Dubai Campus",
          "website": "http://www.uowdubai.ac.ae/"
       },
       {
          "university": "Zayed University",
          "website": "http://www.zu.ac.ae/"
       }
    ],
    "AF": [
       {
          "university": "Afghan University",
          "website": "http://www.afghanuniversity.edu.af/"
       },
       {
          "university": "Al-Birony University",
          "website": "http://www.au.edu.af/"
       },
       {
          "university": "American University of Afghanistan",
          "website": "http://www.auaf.edu.af/"
       },
       {
          "university": "Aria Institute of Higher Education",
          "website": "http://www.aria.edu.af/"
       },
       {
          "university": "Badakhshan University",
          "website": "http://www.badakhshan.edu.af/"
       },
       {
          "university": "Baghlan University",
          "website": "http://www.baghlan.edu.af/"
       },
       {
          "university": "Bakhtar University",
          "website": "http://www.bakhtar.edu.af/"
       },
       {
          "university": "Balkh University",
          "website": "http://www.ba.edu.af/"
       },
       {
          "university": "Bamiyan University",
          "website": "http://www.bu.edu.af/"
       },
       {
          "university": "Bost University",
          "website": "http://www.bost.edu.af/"
       },
       {
          "university": "Dawat University",
          "website": "http://www.dawat.edu.af/"
       },
       {
          "university": "Dunya Institute of Higher Education",
          "website": "http://www.dunya.edu.af/"
       },
       {
          "university": "Faryab Higher Education Institute",
          "website": "http://www.faryab.edu.af/"
       },
       {
          "university": "Ghazni University",
          "website": "http://www.gu.edu.af/"
       },
       {
          "university": "Herat University",
          "website": "http://www.hu.edu.af/"
       },
       {
          "university": "Ibn Sina University",
          "website": "http://www.ibnesina.edu.af/"
       },
       {
          "university": "Jawzjan University",
          "website": "http://www.ju.edu.af/"
       },
       {
          "university": "Kaboora Institute of Higher Education",
          "website": "http://www.kaboora.edu.af/"
       },
       {
          "university": "Kabul Education University",
          "website": "http://www.keu.edu.af/"
       },
       {
          "university": "Kabul Health Sciences Institute",
          "website": "http://www.kabuli.edu.af/"
       },
       {
          "university": "Kabul Medical University",
          "website": "http://www.kmu.edu.af/"
       },
       {
          "university": "Kabul University",
          "website": "http://www.ku.edu.af/"
       },
       {
          "university": "Kandahar University",
          "website": "http://www.kan.edu.af/"
       },
       {
          "university": "Kardan University",
          "website": "http://www.kardan.edu.af/"
       },
       {
          "university": "Karwan Institute of Higher Education",
          "website": "http://www.karwan.edu.af/"
       },
       {
          "university": "Kateb Institute of Higher Education",
          "website": "http://www.kateb.edu.af/"
       },
       {
          "university": "Khana-e-Noor Institute of Higher Education",
          "website": "http://www.ken.edu.af/"
       },
       {
          "university": "Khurasan University",
          "website": "http://www.kumsa.net/"
       },
       {
          "university": "Maryam Institute of Higher Education",
          "website": "http://www.maryam.edu.af/"
       },
       {
          "university": "Nangarhar University",
          "website": "http://www.nu.edu.af/"
       },
       {
          "university": "National Military Academy of Afghanistan",
          "website": "http://www.nmaa.edu.af/"
       },
       {
          "university": "Paktia University",
          "website": "http://www.pu.edu.af/"
       },
       {
          "university": "Pamir University",
          "website": "http://www.pamir.edu.af/"
       },
       {
          "university": "Parwan University",
          "website": "http://www.parwan.edu.af/"
       },
       {
          "university": "Polytechnical University of Kabul",
          "website": "http://www.polytechnic-kabul.org/"
       },
       {
          "university": "Rana Institute of Higher Education",
          "website": "http://www.rihs.edu.af/"
       },
       {
          "university": "Sadat Institute of Higher Education",
          "website": "http://www.sadat.edu.af/"
       },
       {
          "university": "Salam University",
          "website": "http://www.salam.edu.af/"
       },
       {
          "university": "Shaikh Zayed University",
          "website": "http://www.szu.edu.af/"
       },
       {
          "university": "Taj Institute of Higher Education",
          "website": "http://www.taj.edu.af/"
       },
       {
          "university": "Takhar University",
          "website": "http://www.tu.edu.af/"
       }
    ],
    "AG": [
       {
          "university": "American University of Antigua",
          "website": "http://www.auamed.org/"
       },
       {
          "university": "University of Health Sciences Antigua",
          "website": "http://www.uhsa.ag/"
       }
    ],
    "AL": [
       {
          "university": "Academy of Arts",
          "website": "http://www.akademiaearteve.edu.al/"
       },
       {
          "university": "Academy of Sports and Physical Training",
          "website": "http://www.aefs.edu.al/"
       },
       {
          "university": "Agricultural University of Tirane",
          "website": "http://www.ubt.edu.al/"
       },
       {
          "university": "American University of Tirana",
          "website": "http://www.uat.edu.al/"
       },
       {
          "university": "Beder University",
          "website": "http://www.beder.edu.al/"
       },
       {
          "university": "Epoka University",
          "website": "http://www.epoka.edu.al/"
       },
       {
          "university": "Polytechnic University of Tirane",
          "website": "http://www.upt.al/"
       },
       {
          "university": "University of Elbasan Aleksander Xhuvani",
          "website": "http://www.uniel.edu.al/"
       },
       {
          "university": "University of Gjirokstra Eqerem Cabej",
          "website": "http://www.uogj.edu.al/"
       },
       {
          "university": "University of Korca Fan Noli",
          "website": "http://www.unkorce.edu.al/"
       },
       {
          "university": "University of New York Tirana",
          "website": "http://www.unyt.edu.al/"
       },
       {
          "university": "University of Shkodra Luigj Gurakuqi",
          "website": "http://www.unishk.edu.al/"
       },
       {
          "university": "University of Tirana",
          "website": "http://www.unitir.edu.al/"
       },
       {
          "university": "University of Vlora Ismail Qemali",
          "website": "http://www.univlora.edu.al/"
       },
       {
          "university": "University “Pavaresia” Vlore",
          "website": "http://www.unipavaresia.edu.al/"
       }
    ],
    "AM": [
       {
          "university": "American University of Armenia",
          "website": "http://www.aua.am/"
       },
       {
          "university": "Armenian State Agrarian University",
          "website": "http://www.asau.am/"
       },
       {
          "university": "Armenian State University of Economics",
          "website": "http://www.asue.am/"
       },
       {
          "university": "Eurasia International University",
          "website": "http://www.eiu.am/"
       },
       {
          "university": "European Regional Educational Academy of Armenia",
          "website": "http://www.eriicta.am/"
       },
       {
          "university": "French University in Armenia (UFAR)",
          "website": "http://www.ufar.am/"
       },
       {
          "university": "Mehrabyan Medical Institute and Medical College",
          "website": "http://www.armmed.am/"
       },
       {
          "university": "Russian-Armenian (Slavonic) State University",
          "website": "http://www.rau.am/"
       },
       {
          "university": "State Engineering University of Armenia",
          "website": "http://www.seua.am/"
       },
       {
          "university": "Yerevan Haibusak University",
          "website": "http://www.haybusak.org/"
       },
       {
          "university": "Yerevan State Medical University",
          "website": "http://www.ysmu.am/"
       },
       {
          "university": "Yerevan State University",
          "website": "http://www.ysu.am/"
       }
    ],
    "AN": [
       {
          "university": "American University of the Caribbean - Sint Maarten",
          "website": "http://www.aucmed.edu/"
       },
       {
          "university": "International University School of Medicine (IUSOM)",
          "website": "http://www.internationaluniversity-schoolofmedicine.org/"
       },
       {
          "university": "St.James's School of Medicine - Bonaire",
          "website": "http://www.sjsm.org/"
       },
       {
          "university": "University of Sint Eustatius School of Medicine",
          "website": "http://www.eustatiusmed.edu/"
       },
       {
          "university": "University of the Netherlands Antilles - Curacao",
          "website": "http://www.una.an/"
       }
    ],
    "AO": [
       {
          "university": "Universidade Católica de Angola",
          "website": "http://www.ucan.edu/"
       },
       {
          "university": "Universidade Gregorio Semedo",
          "website": "http://www.ugs.ed.ao/"
       },
       {
          "university": "Universidade Independente de Angola",
          "website": "http://www.unia.ao/"
       },
       {
          "university": "Universidade Lusíada de Angola",
          "website": "http://www.ulangola.net/"
       },
       {
          "university": "Universidade Metodista de Angola",
          "website": "http://www.uma.co.ao/"
       },
       {
          "university": "Universidade Metropolitana de Angola",
          "website": "http://www.unimetroangola.com/"
       },
       {
          "university": "Universidade Tecnica de Angola",
          "website": "http://www.utanga.co.ao/"
       },
       {
          "university": "Univesidade Agostinho Neto",
          "website": "http://www.uan.ao/"
       }
    ],
    "AR": [
       {
          "university": "Instituto de Enseñanza Superior del Ejército",
          "website": "http://www.iese.edu.ar/"
       },
       {
          "university": "Instituto Nacional de Educación Física General Manuel Belgrano",
          "website": "http://www.unlu.edu.ar/Belgrano.htm"
       },
       {
          "university": "Instituto Tecnológico de Buenos Aires",
          "website": "http://www.itba.edu.ar/"
       },
       {
          "university": "Instituto Universitario Aeronáutico",
          "website": "http://www.iua.edu.ar/"
       },
       {
          "university": "Instituto Universitario CEMA",
          "website": "http://www.cema.edu.ar/"
       },
       {
          "university": "Instituto Universitario de Ciencias de la Salud Fundación H.A. Barceló",
          "website": "http://www.barcelo.edu.ar/"
       },
       {
          "university": "Instituto Universitario de Estudios Navales y Marítimos",
          "website": "http://www.inun.edu.ar/"
       },
       {
          "university": "Instituto Universitario de la Policia Federal",
          "website": "http://www.universidad-policial.edu.ar/"
       },
       {
          "university": "Instituto Universitario Nacional del Arte",
          "website": "http://www.iuna.edu.ar/"
       },
       {
          "university": "Universidad Abierta Interamericana",
          "website": "http://www.vaneduc.edu.ar/uai/"
       },
       {
          "university": "Universidad Adventista del Plata",
          "website": "http://www.uapar.edu/"
       },
       {
          "university": "Universidad Argentina de la Empresa",
          "website": "http://www.uade.edu.ar/"
       },
       {
          "university": "Universidad Argentina John F. Kennedy",
          "website": "http://www.kennedy.edu.ar/"
       },
       {
          "university": "Universidad Atlantida Argentina",
          "website": "http://www.atlantida.edu.ar/"
       },
       {
          "university": "Universidad Austral Buenos Aires",
          "website": "http://www.austral.edu.ar/"
       },
       {
          "university": "Universidad Autónoma de Entre Ríos",
          "website": "http://www.uader.edu.ar/"
       },
       {
          "university": "Universidad Blas Pascal",
          "website": "http://www.ubp.edu.ar/"
       },
       {
          "university": "Universidad CAECE - Buenos Aires",
          "website": "http://www.caece.edu.ar/"
       },
       {
          "university": "Universidad Católica Argentina Santa Maria de los Buenos Aires",
          "website": "http://www.uca.edu.ar/"
       },
       {
          "university": "Universidad Católica de Córdoba",
          "website": "http://www.uccor.edu.ar/"
       },
       {
          "university": "Universidad Católica de Cuyo",
          "website": "http://www.uccuyo.edu.ar/"
       },
       {
          "university": "Universidad Católica de La Plata",
          "website": "http://www.ucalp.edu.ar/"
       },
       {
          "university": "Universidad Católica de Salta",
          "website": "http://www.ucasal.edu.ar/"
       },
       {
          "university": "Universidad Católica de Santa Fé",
          "website": "http://www.ucsf.edu.ar/"
       },
       {
          "university": "Universidad Católica de Santiago del Estero",
          "website": "http://www.ucse.edu.ar/"
       },
       {
          "university": "Universidad Champagnat",
          "website": "http://www.uch.edu.ar/"
       },
       {
          "university": "Universidad de Belgrano",
          "website": "http://www.ub.edu.ar/"
       },
       {
          "university": "Universidad de Bologna - Representación en Buenos Aires",
          "website": "http://www.unibo.edu.ar/"
       },
       {
          "university": "Universidad de Buenos Aires",
          "website": "http://www.uba.ar/"
       },
       {
          "university": "Universidad de Ciencias Empresariales y Sociales",
          "website": "http://www.uces.edu.ar/"
       },
       {
          "university": "Universidad de Concepción del Uruguay",
          "website": "http://www.ucu.edu.ar/"
       },
       {
          "university": "Universidad de Congreso",
          "website": "http://www.ucongres.edu.ar/"
       },
       {
          "university": "Universidad de Flores - Buenos Aires",
          "website": "http://www.uflo.edu.ar/"
       },
       {
          "university": "Universidad del Aconcagua",
          "website": "http://www.uda.edu.ar/"
       },
       {
          "university": "Universidad de la Cuenca del Plata",
          "website": "http://www.ucp.edu.ar/"
       },
       {
          "university": "Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)",
          "website": "http://www.ufasta.edu.ar/"
       },
       {
          "university": "Universidad de la Marina Mercante",
          "website": "http://www.udemm.edu.ar/"
       },
       {
          "university": "Universidad del Centro Educativo Latinoamericano Rosario",
          "website": "http://www.ucel.edu.ar/"
       },
       {
          "university": "Universidad del Cine - Buenos Aires",
          "website": "http://www.ucine.edu.ar/"
       },
       {
          "university": "Universidad del Museo Social Argentino",
          "website": "http://www.umsa.edu.ar/"
       },
       {
          "university": "Universidad del Norte Santo Tomás de Aquino",
          "website": "http://www.unsta.edu.ar/"
       },
       {
          "university": "Universidad del Salvador",
          "website": "http://www.salvador.edu.ar/"
       },
       {
          "university": "Universidad de Mendoza",
          "website": "http://www.um.edu.ar/"
       },
       {
          "university": "Universidad de Morón",
          "website": "http://www.unimoron.edu.ar/"
       },
       {
          "university": "Universidad de Palermo",
          "website": "http://www.palermo.edu.ar/"
       },
       {
          "university": "Universidad de San Andres",
          "website": "http://www.udesa.edu.ar/"
       },
       {
          "university": "Universidad Empresarial Siglio 21",
          "website": "http://www.uesiglo21.edu.ar/"
       },
       {
          "university": "Universidad ISALUD",
          "website": "http://www.isalud.edu.ar/"
       },
       {
          "university": "Universidad Juan Agustín Maza",
          "website": "http://www.umaza.edu.ar/"
       },
       {
          "university": "Universidad Maimónides",
          "website": "http://www.maimonides.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Catamarca",
          "website": "http://www.unca.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Córdoba",
          "website": "http://www.unc.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Cuyo",
          "website": "http://www.uncu.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Entre Ríos",
          "website": "http://www.uner.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Formosa",
          "website": "http://www.unf.edu.ar/"
       },
       {
          "university": "Universidad Nacional de General San Martín",
          "website": "http://www.unsam.edu.ar/"
       },
       {
          "university": "Universidad Nacional de General Sarmiento",
          "website": "http://www.ungs.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Jujuy",
          "website": "http://www.unju.edu.ar/"
       },
       {
          "university": "Universidad Nacional de La Matanza",
          "website": "http://www.unlam.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Lanus",
          "website": "http://www.unla.edu.ar/"
       },
       {
          "university": "Universidad Nacional de La Pampa",
          "website": "http://www.unlpam.edu.ar/"
       },
       {
          "university": "Universidad Nacional de la Patagonia Austral",
          "website": "http://www.unpa.edu.ar/"
       },
       {
          "university": "Universidad Nacional de la Patagonia San Juan Bosco",
          "website": "http://www.unp.edu.ar/"
       },
       {
          "university": "Universidad Nacional de La Plata",
          "website": "http://www.unlp.edu.ar/"
       },
       {
          "university": "Universidad Nacional de La Rioja",
          "website": "http://www.unlar.edu.ar/"
       },
       {
          "university": "Universidad Nacional del Centro de la Provincia de Buenos Aires",
          "website": "http://www.unicen.edu.ar/"
       },
       {
          "university": "Universidad Nacional del Comahue",
          "website": "http://www.uncoma.edu.ar/"
       },
       {
          "university": "Universidad Nacional del Littoral",
          "website": "http://www.unl.edu.ar/"
       },
       {
          "university": "Universidad Nacional del Nordeste",
          "website": "http://www.unne.edu.ar/"
       },
       {
          "university": "Universidad Nacional del Noroeste de la Provincia de Buenos Aires",
          "website": "http://www.unnoba.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Lomas de Zamora",
          "website": "http://www.unlz.edu.ar/"
       },
       {
          "university": "Universidad Nacional del Sur",
          "website": "http://www.uns.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Luján",
          "website": "http://www.unlu.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Mar del Plata",
          "website": "http://www.mdp.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Misiones",
          "website": "http://www.unam.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Quilmes",
          "website": "http://www.unq.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Río Cuarto",
          "website": "http://www.unrc.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Rosario",
          "website": "http://www.unr.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Salta",
          "website": "http://www.unsa.edu.ar/"
       },
       {
          "university": "Universidad Nacional de San Juan",
          "website": "http://www.unsj.edu.ar/"
       },
       {
          "university": "Universidad Nacional de San Luis",
          "website": "http://www.unsl.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Santiago del Estero",
          "website": "http://www.unse.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Tres de Febrero",
          "website": "http://www.untref.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Tucumán",
          "website": "http://www.unt.edu.ar/"
       },
       {
          "university": "Universidad Nacional de Villa María",
          "website": "http://www.unvm.edu.ar/"
       },
       {
          "university": "Universidad Notarial Argentina",
          "website": "http://www.universidadnotarial.edu.ar/"
       },
       {
          "university": "Universidad Tecnológica Nacional",
          "website": "http://www.utn.edu.ar/"
       },
       {
          "university": "Universidad Torcuato di Tella",
          "website": "http://www.utdt.edu/"
       }
    ],
    "AT": [
       {
          "university": "Akademie der bildenden Künste Wien",
          "website": "http://www.akbild.ac.at/"
       },
       {
          "university": "Donau-Universität Krems",
          "website": "http://www.donau-uni.ac.at/"
       },
       {
          "university": "Fachhochschule Burgenland",
          "website": "http://www.fh-burgenland.at/"
       },
       {
          "university": "Fachhochschule für Bank- und Finanzwirtschaft",
          "website": "http://www.fh-vie.ac.at/"
       },
       {
          "university": "Fachhochschule JOANNEUM",
          "website": "http://www.fh-joanneum.at/"
       },
       {
          "university": "Fachhochschule Kärnten",
          "website": "http://www.fh-kaernten.ac.at/"
       },
       {
          "university": "Fachhochschule Krems",
          "website": "http://www.fh-krems.ac.at/"
       },
       {
          "university": "Fachhochschule Kufstein (Tirol)",
          "website": "http://www.fh-kufstein.ac.at/"
       },
       {
          "university": "Fachhochschule Salzburg",
          "website": "http://www.fh-sbg.ac.at/"
       },
       {
          "university": "Fachhochschule St. Pölten",
          "website": "http://www.fh-stpoelten.ac.at/"
       },
       {
          "university": "Fachhochschule Vorarlberg",
          "website": "http://www.fhv.at/"
       },
       {
          "university": "Fachhochschule Wiener Neustadt",
          "website": "http://www.fhwn.ac.at/"
       },
       {
          "university": "Fachhochschulstudiengänge der Wiener Wirtschaft",
          "website": "http://www.fhw.at/"
       },
       {
          "university": "Fachhochschulstudiengänge Hagenberg",
          "website": "http://www.fh-hagenberg.at/"
       },
       {
          "university": "Fachhochschulstudiengänge Krems IMC",
          "website": "http://www.imc-krems.ac.at/"
       },
       {
          "university": "Fachhochschulstudiengänge Steyr",
          "website": "http://www.fh-steyr.at/"
       },
       {
          "university": "Fachhochschulstudiengänge Wels",
          "website": "http://www.fh-wels.at/"
       },
       {
          "university": "Institute of Science and Technology",
          "website": "http://www.ist.ac.at/"
       },
       {
          "university": "Katholisch-Theologische Privatuniversität Linz",
          "website": "http://www.kth-linz.ac.at/"
       },
       {
          "university": "MCI-Management Center Innsbruck",
          "website": "http://www.mci.edu/"
       },
       {
          "university": "Medizinische Universität Graz",
          "website": "http://www.meduni-graz.at/"
       },
       {
          "university": "Medizinische Universität Innsbruck",
          "website": "http://www.i-med.ac.at/"
       },
       {
          "university": "Medizinische Universität Wien",
          "website": "http://www.meduniwien.at/"
       },
       {
          "university": "Montanuniversität Leoben",
          "website": "http://www.unileoben.ac.at/"
       },
       {
          "university": "Private Universität für Medizinische Informatik und Technik Tirol",
          "website": "http://www.umit.at/"
       },
       {
          "university": "Technikum Wien",
          "website": "http://www.technikum-wien.at/"
       },
       {
          "university": "Technische Universität Graz",
          "website": "http://www.tugraz.at/"
       },
       {
          "university": "Technische Universität Wien",
          "website": "http://www.tuwien.ac.at/"
       },
       {
          "university": "Universität für angewandte Kunst Wien",
          "website": "http://ns2.uni-ak.ac.at/"
       },
       {
          "university": "Universität für Bodenkultur Wien",
          "website": "http://www.boku.ac.at/"
       },
       {
          "university": "Universität für künstlerische und industrielle Gestaltung Linz",
          "website": "http://www.khs-linz.ac.at/"
       },
       {
          "university": "Universität für Musik und darstellende Kunst Graz",
          "website": "http://www.kug.ac.at/"
       },
       {
          "university": "Universität für Musik und darstellende Kunst  Wien",
          "website": "http://www.mdw.ac.at/"
       },
       {
          "university": "Universität Graz",
          "website": "http://www.kfunigraz.ac.at/"
       },
       {
          "university": "Universität Innsbruck",
          "website": "http://www.uibk.ac.at/"
       },
       {
          "university": "Universität Klagenfurt",
          "website": "http://www.uni-klu.ac.at/"
       },
       {
          "university": "Universität Linz",
          "website": "http://www.uni-linz.ac.at/"
       },
       {
          "university": "Universität Mozarteum Salzburg",
          "website": "http://www.moz.ac.at/"
       },
       {
          "university": "Universität Salzburg",
          "website": "http://www.sbg.ac.at/"
       },
       {
          "university": "Universität Vienna",
          "website": "http://www.univie.ac.at/"
       },
       {
          "university": "Veterinärmedizinische Universität Wien",
          "website": "http://www.vu-wien.ac.at/"
       },
       {
          "university": "Webster University - Vienna",
          "website": "http://www.webster.ac.at/"
       },
       {
          "university": "Wirtschaftsuniversität Wien",
          "website": "http://www.wu-wien.ac.at/"
       }
    ],
    "AU": [
       {
          "university": "Australian Catholic University",
          "website": "http://www.acu.edu.au/"
       },
       {
          "university": "Australian Correspondence Schools",
          "website": "http://www.acs.edu.au/"
       },
       {
          "university": "Australian Defence Force Academy",
          "website": "http://www.adfa.oz.au/"
       },
       {
          "university": "Australian Lutheran College",
          "website": "http://www.alc.edu.au/"
       },
       {
          "university": "Australian Maritime College",
          "website": "http://www.amc.edu.au/"
       },
       {
          "university": "Australian National University",
          "website": "http://www.anu.edu.au/"
       },
       {
          "university": "Avondale College",
          "website": "http://www.avondale.edu.au/"
       },
       {
          "university": "Batchelor Institute of Indigenous Tertiary Education",
          "website": "http://www.nt.gov.au/batc/"
       },
       {
          "university": "Blue Mountains Hotel School",
          "website": "http://www.hotelschool.com.au/"
       },
       {
          "university": "Bond University",
          "website": "http://www.bond.edu.au/"
       },
       {
          "university": "Central Queensland University",
          "website": "http://www.cqu.edu.au/"
       },
       {
          "university": "Charles Darwin University",
          "website": "http://www.cdu.edu.au/"
       },
       {
          "university": "Charles Sturt University",
          "website": "http://www.csu.edu.au/"
       },
       {
          "university": "Curtin University of Technology",
          "website": "http://www.curtin.edu.au/"
       },
       {
          "university": "Deakin University",
          "website": "http://www.deakin.edu.au/"
       },
       {
          "university": "Edith Cowan University",
          "website": "http://www.ecu.edu.au/"
       },
       {
          "university": "Flinders University of South Australia",
          "website": "http://www.flinders.edu.au/"
       },
       {
          "university": "Griffith University",
          "website": "http://www.gu.edu.au/"
       },
       {
          "university": "Holmes Institute",
          "website": "http://www.holmes.edu.au/"
       },
       {
          "university": "Institute Of Technology - Australia",
          "website": "http://www.iota.edu.au/"
       },
       {
          "university": "International College of Tourism and Hotel Management",
          "website": "http://www.icthm.edu.au/"
       },
       {
          "university": "James Cook University of North Queensland",
          "website": "http://www.jcu.edu.au/"
       },
       {
          "university": "La Trobe University",
          "website": "http://www.latrobe.edu.au/"
       },
       {
          "university": "Macquarie University",
          "website": "http://www.mq.edu.au/"
       },
       {
          "university": "Marcus Oldham College",
          "website": "http://www.marcusoldham.vic.edu.au/"
       },
       {
          "university": "Monash University",
          "website": "http://www.monash.edu.au/"
       },
       {
          "university": "Murdoch University",
          "website": "http://www.murdoch.edu.au/"
       },
       {
          "university": "Queensland University of Technology",
          "website": "http://www.qut.edu.au/"
       },
       {
          "university": "Royal Melbourne Institute of Technology",
          "website": "http://www.rmit.edu.au/"
       },
       {
          "university": "Southern Cross University",
          "website": "http://www.scu.edu.au/"
       },
       {
          "university": "Swinburne University of Technology",
          "website": "http://www.swin.edu.au/"
       },
       {
          "university": "University of Adelaide",
          "website": "http://www.adelaide.edu.au/"
       },
       {
          "university": "University of Ballarat",
          "website": "http://www.ballarat.edu.au/"
       },
       {
          "university": "University of Canberra",
          "website": "http://www.canberra.edu.au/"
       },
       {
          "university": "University of Divinity",
          "website": "http://www.divinity.edu.au/"
       },
       {
          "university": "University of Melbourne",
          "website": "http://www.unimelb.edu.au/"
       },
       {
          "university": "University of Newcastle",
          "website": "http://www.newcastle.edu.au/"
       },
       {
          "university": "University of New England",
          "website": "http://www.une.edu.au/"
       },
       {
          "university": "University of New South Wales",
          "website": "http://www.unsw.edu.au/"
       },
       {
          "university": "University of Notre Dame Australia",
          "website": "http://www.nd.edu.au/"
       },
       {
          "university": "University of Queensland",
          "website": "http://www.uq.edu.au/"
       },
       {
          "university": "University of South Australia",
          "website": "http://www.unisa.edu.au/"
       },
       {
          "university": "University of Southern Queensland",
          "website": "http://www.usq.edu.au/"
       },
       {
          "university": "University of Sydney",
          "website": "http://www.usyd.edu.au/"
       },
       {
          "university": "University of Tasmania",
          "website": "http://www.utas.edu.au/"
       },
       {
          "university": "University of Technology Sydney",
          "website": "http://www.uts.edu.au/"
       },
       {
          "university": "University of the Sunshine Coast",
          "website": "http://www.usc.edu.au/"
       },
       {
          "university": "University of Western Australia",
          "website": "http://www.uwa.edu.au/"
       },
       {
          "university": "University of Western Sydney",
          "website": "http://www.uws.edu.au/"
       },
       {
          "university": "University of Wollongong",
          "website": "http://www.uow.edu.au/"
       }
    ],
    "AZ": [
       {
          "university": "Academy of Public Administration",
          "website": "http://www.dia.edu.az/"
       },
       {
          "university": "Azerbaijan Diplomatic Academy",
          "website": "http://www.ada.edu.az/"
       },
       {
          "university": "Azerbaijan International University",
          "website": "http://www.abu.az/"
       },
       {
          "university": "Azerbaijan Medical University",
          "website": "http://www.amu.edu.az/"
       },
       {
          "university": "Azerbaijan National Conservatorie",
          "website": "http://www.conservatory.az/"
       },
       {
          "university": "Azerbaijan State Economic University",
          "website": "http://www.aseu.az/"
       },
       {
          "university": "Azerbaijan State Oil Academy",
          "website": "http://adna.jis.az/"
       },
       {
          "university": "Azerbaijan Technical University",
          "website": "http://www.aztu.az/"
       },
       {
          "university": "Azerbaijan Technology University",
          "website": "http://www.aztu.edu.az/"
       },
       {
          "university": "Azerbaijan Toursim Institute",
          "website": "http://www.tourism.edu.az/"
       },
       {
          "university": "Azerbaijan University",
          "website": "http://www.au.edu.az/"
       },
       {
          "university": "Azerbaijan University ol Languages",
          "website": "http://www.adu.edu.az/"
       },
       {
          "university": "Baki Business University",
          "website": "http://www.bbu.edu.az/"
       },
       {
          "university": "Baku Slavic University",
          "website": "http://www.bsu-edu.org/"
       },
       {
          "university": "Baku State University",
          "website": "http://www.bsu.az/"
       },
       {
          "university": "Ganja State University ",
          "website": "http://www.gsu.az/"
       },
       {
          "university": "Khazar University",
          "website": "http://www.khazar.org/"
       },
       {
          "university": "Lankaran State University",
          "website": "http://www.lsu.edu.az/"
       },
       {
          "university": "Nakhchivan Private University",
          "website": "http://www.az-npu.org/"
       },
       {
          "university": "Nakhchivan State University",
          "website": "http://www.ndu.edu.az/"
       },
       {
          "university": "National Aviation Academy",
          "website": "http://www.naa.edu.az/"
       },
       {
          "university": "Odlar Yurdu University",
          "website": "http://www.oyu.edu.az/"
       },
       {
          "university": "Police Academy",
          "website": "http://www.pa.edu.az/"
       },
       {
          "university": "Qafqaz University",
          "website": "http://www.qafqaz.edu.az/"
       },
       {
          "university": "Sumgait State University",
          "website": "http://www.sdu.edu.az/"
       },
       {
          "university": "Western University",
          "website": "http://www.wu.edu.az/"
       }
    ],
    "BA": [
       {
          "university": "American University",
          "website": "http://www.aubih.ba/"
       },
       {
          "university": "International Burch University",
          "website": "http://www.ibu.edu.ba/"
       },
       {
          "university": "International University of Sarajevo",
          "website": "http://www.ius.edu.ba/"
       },
       {
          "university": "International University of Travnik",
          "website": "http://www.iu-travnik.com/"
       },
       {
          "university": "Sarajevo Film Academy",
          "website": "http://www.sfa.ba/"
       },
       {
          "university": "Slobomir P Univerzitet",
          "website": "http://www.spu.ba/"
       },
       {
          "university": "University of Banja Luka",
          "website": "http://www.unibl.org/"
       },
       {
          "university": "University of Bihac",
          "website": "http://www.unbi.ba/"
       },
       {
          "university": "University of East Srarajevo",
          "website": "http://www.unssa.rs.ba/"
       },
       {
          "university": "University of Mostar",
          "website": "http://www.sve-mo.ba/"
       },
       {
          "university": "University of Sarajevo",
          "website": "http://www.unsa.ba/"
       },
       {
          "university": "University of Tuzla",
          "website": "http://www.untz.ba/"
       },
       {
          "university": "University of Zenica",
          "website": "http://www.unze.ba/"
       },
       {
          "university": "University Vitez In Travnik",
          "website": "http://www.unvi.edu.ba/"
       },
       {
          "university": "Univerzitet u Mostaru Dzemal Bijedic",
          "website": "http://www.unmo.ba/"
       }
    ],
    "BB": [
       {
          "university": "University of the West Indies - Cave Hill",
          "website": "http://www.uwichill.edu.bb/"
       }
    ],
    "BD": [
       {
          "university": "Ahsanullah University of Science & Technology",
          "website": "http://www.aust.edu/"
       },
       {
          "university": "American International University - Bangladesh",
          "website": "http://www.aiub.edu/"
       },
       {
          "university": "Asa University Bangladesh",
          "website": "http://www.asaub.edu.bd/"
       },
       {
          "university": "Asian University of Bangladesh",
          "website": "http://www.aub-bd.org/"
       },
       {
          "university": "Atish Dipankar University",
          "website": "http://www.atishdipankaruniversity.edu.bd/"
       },
       {
          "university": "Bangabandhu Sheikh Mujibur Rahman Agricultural University",
          "website": "http://www.bsmrau.edu.bd/"
       },
       {
          "university": "Bangabandhu Sheikh Mujibur Rahman Medical University",
          "website": "http://www.bsmmu.edu.bd/"
       },
       {
          "university": "Bangladesh Agricultural University",
          "website": "http://www.bau.edu.bd/"
       },
       {
          "university": "Bangladesh Open University",
          "website": "http://www.bou.edu.bd/"
       },
       {
          "university": "Bangladesh University",
          "website": "http://www.bu.edu.bd/"
       },
       {
          "university": "Bangladesh University of Business & Technology",
          "website": "http://www.bubt.edu.bd/"
       },
       {
          "university": "Bangladesh University of Engineering and Technology",
          "website": "http://www.buet.ac.bd/"
       },
       {
          "university": "Bangladesh University of Professionals",
          "website": "http://www.bup.edu.bd/"
       },
       {
          "university": "Bangladesh University of Textiles",
          "website": "http://www.butex.edu.bd/"
       },
       {
          "university": "Begum Rokeya University - Rangpur",
          "website": "http://www.brur.ac.bd/"
       },
       {
          "university": "BGC Trust University - Bangladesh",
          "website": "http://www.bgctrustbd.org/btub/"
       },
       {
          "university": "Brac University",
          "website": "http://www.bracuniversity.net/"
       },
       {
          "university": "Chittagong Independent University",
          "website": "http://www.ciu.edu.bd/"
       },
       {
          "university": "Chittagong University of Engineering and Technology",
          "website": "http://www.cuet.ac.bd/"
       },
       {
          "university": "City University",
          "website": "http://www.cityuniversity.edu.bd/"
       },
       {
          "university": "Comilla University",
          "website": "http://www.cou.ac.bd/"
       },
       {
          "university": "Daffodil International University",
          "website": "http://www.daffodilvarsity.edu.bd/"
       },
       {
          "university": "Darul Ihsan University",
          "website": "http://www.diu.ac.bd/"
       },
       {
          "university": "Dhaka International University",
          "website": "http://www.diu.net.bd/"
       },
       {
          "university": "Dhaka University of Engineering and Technology",
          "website": "http://www.duet.ac.bd/"
       },
       {
          "university": "East Delta University",
          "website": "http://www.eastdelta.edu.bd/"
       },
       {
          "university": "Eastern University",
          "website": "http://www.easternuni.edu.bd/"
       },
       {
          "university": "East West University",
          "website": "http://www.ewubd.edu/"
       },
       {
          "university": "East-West University - Mohakhali",
          "website": "http://www.ewubd.edu/"
       },
       {
          "university": "Green University of Bangladesh",
          "website": "http://www.green.edu.bd/"
       },
       {
          "university": "Hajee Mohammad Danesh Science and Technology University",
          "website": "http://www.hstu.ac.bd/"
       },
       {
          "university": "IBAIS University",
          "website": "http://www.ibaisuniv.edu.bd/"
       },
       {
          "university": "Independent University - Bangladesh",
          "website": "http://www.iub.edu.bd/"
       },
       {
          "university": "International Culture University ",
          "website": "http://www.icu-edu.org/"
       },
       {
          "university": "International Islamic University Chittagong",
          "website": "http://www.iiuc.ac.bd/"
       },
       {
          "university": "International University of Business Agriculture and Technology",
          "website": "http://www.iubat.edu/"
       },
       {
          "university": "Islamic University Kushtia",
          "website": "http://www.iu.ac.bd/"
       },
       {
          "university": "Islamic University of Technology",
          "website": "http://www.iutoic-dhaka.edu/"
       },
       {
          "university": "Jagannath University",
          "website": "http://www.jnu.ac.bd/"
       },
       {
          "university": "Jahangirnagar University",
          "website": "http://www.juniv.edu/"
       },
       {
          "university": "Jatiya Kabi Kazi Nazrul Islam University",
          "website": "http://www.jkkniu.edu.bd/"
       },
       {
          "university": "Khulna University",
          "website": "http://www.ku.ac.bd/"
       },
       {
          "university": "Khulna University of Engineering And Technology",
          "website": "http://www.kuet.ac.bd/"
       },
       {
          "university": "Leading University",
          "website": "http://www.lus.ac.bd/"
       },
       {
          "university": "Manarat International University",
          "website": "http://www.manarat.ac.bd/"
       },
       {
          "university": "Mawlana Bhashani Science And Technology University",
          "website": "http://www.mbstu.ac.bd/"
       },
       {
          "university": "Metropolitan University",
          "website": "http://www.metrouni.edu.bd/"
       },
       {
          "university": "Military Institute of Science and Technology",
          "website": "http://www.mist.ac.bd/"
       },
       {
          "university": "National University",
          "website": "http://www.nu.edu.bd/"
       },
       {
          "university": "Noakhali University of Science and Technology",
          "website": "http://www.nstu.edu.bd/"
       },
       {
          "university": "North East University Bangladesh",
          "website": "http://www.neub.edu.bd/"
       },
       {
          "university": "Northern University Bangladesh",
          "website": "http://www.nub.ac.bd/"
       },
       {
          "university": "North South University",
          "website": "http://www.northsouth.edu/"
       },
       {
          "university": "Pabna University of Science and Technology",
          "website": "http://www.pust.ac.bd/"
       },
       {
          "university": "Patuakhali Science and Technology University",
          "website": "http://www.pstu.ac.bd/"
       },
       {
          "university": "People's University of Bangladesh",
          "website": "http://www.pub.ac.bd/"
       },
       {
          "university": "Premier University",
          "website": "http://www.puc.ac.bd/"
       },
       {
          "university": "Presidency University",
          "website": "http://www.presidency.edu.bd/"
       },
       {
          "university": "Queens University",
          "website": "http://www.queensuniversity.edu.bd/"
       },
       {
          "university": "Rajshahi University of Engineering and Technology",
          "website": "http://www.ruet.ac.bd/"
       },
       {
          "university": "Shahjalal University of Science and Technology",
          "website": "http://www.sust.edu/"
       },
       {
          "university": "Southeast University",
          "website": "http://www.seu.ac.bd/"
       },
       {
          "university": "Southern University Bangladesh",
          "website": "http://www.southern.edu.bd/"
       },
       {
          "university": "Stamford University",
          "website": "http://www.stamforduniversity.edu.bd/"
       },
       {
          "university": "Sylhet Agricultural University",
          "website": "http://www.sau.ac.bd/"
       },
       {
          "university": "Sylhet Engineering College",
          "website": "http://www.sec.ac.bd/"
       },
       {
          "university": "Sylhet International University",
          "website": "http://www.siu.edu.bd/"
       },
       {
          "university": "United International University",
          "website": "http://www.uiu.ac.bd/"
       },
       {
          "university": "University of Asia Pacific - Dhanmondi",
          "website": "http://www.uap-bd.edu/"
       },
       {
          "university": "University of Chittagong",
          "website": "http://www.cu.ac.bd/"
       },
       {
          "university": "University of Development Alternative",
          "website": "http://www.uoda.edu.bd/"
       },
       {
          "university": "University of Dhaka",
          "website": "http://www.du.ac.bd/"
       },
       {
          "university": "University of Information Technology & Sciences",
          "website": "http://www.uits.edu.bd/"
       },
       {
          "university": "University of Liberal Arts",
          "website": "http://www.ulab.edu.bd/"
       },
       {
          "university": "University of Rajshahi",
          "website": "http://www.ru.ac.bd/"
       },
       {
          "university": "University of Science & Technology Chittagong",
          "website": "http://www.ustc.ac.bd/"
       },
       {
          "university": "World University of Bangladesh",
          "website": "http://www.wub.edu/"
       }
    ],
    "BE": [
       {
          "university": "Brexgata University Academy",
          "website": "http://www.brexgata.eu/"
       },
       {
          "university": "Brussels Management School (ICHEC)",
          "website": "http://www.ichec.be/"
       },
       {
          "university": "Brussels School of International Studies",
          "website": "http://www.ukc.ac.uk/international/bsis/"
       },
       {
          "university": "College of Europe",
          "website": "http://www.coleurope.eu/"
       },
       {
          "university": "Continental Theological Seminary",
          "website": "http://www.ctsem.edu/"
       },
       {
          "university": "ECAM - Institut Supérieur Industriel",
          "website": "http://www.ecam.be/"
       },
       {
          "university": "EHSAL - Europese Hogeschool Brussel",
          "website": "http://www.ehsal.be/"
       },
       {
          "university": "Erasmushogeschool Brussel",
          "website": "http://www.ehb.be/"
       },
       {
          "university": "European Carolus Magnus University",
          "website": "http://www.carolus-magnus-university.eu/"
       },
       {
          "university": "European International University",
          "website": "http://www.europeaniu.org/"
       },
       {
          "university": "Evangelische Theologische Faculteit - Leuven",
          "website": "http://www.etf.edu/"
       },
       {
          "university": "Faculté Polytechnique de Mons",
          "website": "http://www.fpms.ac.be/"
       },
       {
          "university": "Facultés Universitaires Catholiques de Mons",
          "website": "http://www.fucam.ac.be/"
       },
       {
          "university": "Facultés Universitaires Notre-Dame de la Paix",
          "website": "http://www.fundp.ac.be/"
       },
       {
          "university": "Facultés Universitaires Saint-Louis",
          "website": "http://www.fusl.ac.be/"
       },
       {
          "university": "Faculté Universitaire des Sciences Agronomiques de Gembloux",
          "website": "http://www.fsagx.ac.be/"
       },
       {
          "university": "Fondation Universitaire Luxembourgeoise",
          "website": "http://www.ful.ac.be/"
       },
       {
          "university": "Hasselt University",
          "website": "http://www.uhasselt.be/"
       },
       {
          "university": "Hogere Zeevaartschool - Maritime Academy",
          "website": "http://www.hzs.be/"
       },
       {
          "university": "Hogeschool Antwerpen",
          "website": "http://www.ha.be/"
       },
       {
          "university": "Hogeschool voor Wetenschap en Kunst (VLEKHO) - Brussel",
          "website": "http://www.vlekho.be/"
       },
       {
          "university": "Hogeschool voor Wetenschap & Kunst",
          "website": "http://www.wenk.be/"
       },
       {
          "university": "Hogeschool West-Vlaanderen (TU)",
          "website": "http://www.howest.be/"
       },
       {
          "university": "Karel De Grote Hogeschool",
          "website": "http://www.kdg.be/"
       },
       {
          "university": "Katholieke Hogeschool Kempen",
          "website": "http://www.khk.be/"
       },
       {
          "university": "Katholieke Hogeschool Leuven",
          "website": "http://www.khleuven.be/"
       },
       {
          "university": "Katholieke Hogeschool Limburg",
          "website": "http://www.khlim.be/"
       },
       {
          "university": "Katholieke Universiteit Brussel",
          "website": "http://www.kubrussel.ac.be/"
       },
       {
          "university": "Katholieke Universiteit Leuven",
          "website": "http://www.kuleuven.ac.be/"
       },
       {
          "university": "Katholieke Universiteit Leuven - Campus Kortrijk",
          "website": "http://www.kulak.ac.be/"
       },
       {
          "university": "Royal Military Academy",
          "website": "http://www.rma.ac.be/"
       },
       {
          "university": "Thierry Graduate School of Leadership",
          "website": "http://www.thierryschool.org/"
       },
       {
          "university": "United Business Institute",
          "website": "http://www.ubi.edu/"
       },
       {
          "university": "Université Catholique de Louvain",
          "website": "http://www.ucl.ac.be/"
       },
       {
          "university": "Université de Liège",
          "website": "http://www.ulg.ac.be/"
       },
       {
          "university": "Université de Mons-Hainaut",
          "website": "http://www.umh.ac.be/"
       },
       {
          "university": "Universiteit Antwerpen",
          "website": "http://www.ua.ac.be/"
       },
       {
          "university": "Universiteit Antwerpen Management School",
          "website": "http://www.uams.be/"
       },
       {
          "university": "Universiteit Antwerpen - UFSIA",
          "website": "http://www.ufsia.ac.be/"
       },
       {
          "university": "Universiteit Gent",
          "website": "http://www.rug.ac.be/"
       },
       {
          "university": "Université Libre de Bruxelles",
          "website": "http://www.ulb.ac.be/"
       },
       {
          "university": "Vesalius College",
          "website": "http://www.vesalius.edu/"
       },
       {
          "university": "Vlerick Leuven Gent Management School",
          "website": "http://www.vlerick.be/"
       },
       {
          "university": "Vrije Universiteit Brussel",
          "website": "http://www.vub.ac.be/"
       }
    ],
    "BF": [
       {
          "university": "Université de Ouagadougou",
          "website": "http://www.univ-ouaga.bf/"
       }
    ],
    "BG": [
       {
          "university": "Academy of Economics Dimitur A. Tscenov",
          "website": "http://www.uni-svishtov.bg/"
       },
       {
          "university": "Academy of Music - Dance and Fine Arts",
          "website": "http://amti.hit.bg/"
       },
       {
          "university": "Agricultural University of Plovdiv",
          "website": "http://www.au-plovdiv.bg/"
       },
       {
          "university": "American University in Bulgaria",
          "website": "http://www.aubg.bg/"
       },
       {
          "university": "Bourgas Free University",
          "website": "http://www.bfu.bg/"
       },
       {
          "university": "Bourgas University Prof. Assen Zlatarov",
          "website": "http://www.btu.bg/"
       },
       {
          "university": "City University Programs in Bulgaria ",
          "website": "http://www.cityu.bg/"
       },
       {
          "university": "International University College",
          "website": "http://www.vumk.eu/"
       },
       {
          "university": "Medical University of Sofia",
          "website": "http://www.mu-sofia.bg/"
       },
       {
          "university": "Medical University Pleven",
          "website": "http://www.mu-pleven.bg/"
       },
       {
          "university": "Medical University Plovdiv",
          "website": "http://www.meduniversity-plovdiv.bg/"
       },
       {
          "university": "Medical University Varna",
          "website": "http://www.mu-varna.bg/"
       },
       {
          "university": "Military University Shoumen",
          "website": "http://www.pv-ma.bg/"
       },
       {
          "university": "National Academy for Theatre and Film Arts Krustju Sarafov",
          "website": "http://www.natfiz.bg/"
       },
       {
          "university": "National Academy of Arts",
          "website": "http://www.nha-bg.org/"
       },
       {
          "university": "National Academy of Music Pantcho Vladigerov",
          "website": "http://www.nma.bg/"
       },
       {
          "university": "National Sports Academy Sofia",
          "website": "http://www.nsa.bg/"
       },
       {
          "university": "New Bulgarian University",
          "website": "http://www.nbu.bg/"
       },
       {
          "university": "Shoumen University Konstantin Preslavski",
          "website": "http://www.shu-bg.net/"
       },
       {
          "university": "Sofia University St. Kliment Ohridski",
          "website": "http://www.uni-sofia.bg/"
       },
       {
          "university": "South-West University Neofit Rilski",
          "website": "http://www.swu.bg/"
       },
       {
          "university": "Technical University of Gabrovo",
          "website": "http://www.tugab.bg/"
       },
       {
          "university": "Technical University of Sofia",
          "website": "http://www.tu-sofia.bg/"
       },
       {
          "university": "Technical University of Varna",
          "website": "http://www.tu-varna.acad.bg/"
       },
       {
          "university": "Trakia University Stara Zagora",
          "website": "http://www.uni-sz.bg/"
       },
       {
          "university": "University of Architecture - Civil Engineering and Geodesy",
          "website": "http://www.uacg.bg/"
       },
       {
          "university": "University of Chemical Technology and Metallurgy",
          "website": "http://www.uctm.edu/"
       },
       {
          "university": "University of Economics Varna",
          "website": "http://www.ue-varna.bg/"
       },
       {
          "university": "University of Food Technology",
          "website": "http://www.uft-plovdiv.bg/"
       },
       {
          "university": "University of Forestry Sofia",
          "website": "http://www.ltu.bg/"
       },
       {
          "university": "University of Mining and Geology St. Ivan Rils",
          "website": "http://www.mgu.bg/"
       },
       {
          "university": "University of National and World Economy",
          "website": "http://www.unwe.acad.bg/"
       },
       {
          "university": "University of Plovdiv",
          "website": "http://www.uni-plovdiv.bg/"
       },
       {
          "university": "University of Rousse",
          "website": "http://www.ru.acad.bg/"
       },
       {
          "university": "Varna Free University",
          "website": "http://www.vfu.bg/"
       },
       {
          "university": "Veliko Turnovo University Cyril and Methodius",
          "website": "http://www.uni-vt.bg/"
       }
    ],
    "BH": [
       {
          "university": "Al Ahlia University",
          "website": "http://www.ahliauniversity.org/"
       },
       {
          "university": "Ama International University",
          "website": "http://www.amaiu.edu.bh/"
       },
       {
          "university": "Applied Science University",
          "website": "http://www.asu.edu.bh/"
       },
       {
          "university": "Arabian Gulf University",
          "website": "http://www.agu.edu.bh/"
       },
       {
          "university": "Arab Open University",
          "website": "http://www.aou.org.bh/"
       },
       {
          "university": "Bahrain Polytechnic",
          "website": "http://www.polytechnic.bh/"
       },
       {
          "university": "Gulf University College",
          "website": "http://www.gulfuniversity.net/"
       },
       {
          "university": "New York Instiute of Technology",
          "website": "http://www.nyit.edu.bh/"
       },
       {
          "university": "RCSI-Medical University of Bahrain",
          "website": "http://www.rcsi-mub.com/"
       },
       {
          "university": "The Kingdom University",
          "website": "http://www.ku.edu.bh/"
       },
       {
          "university": "University College Bahrain",
          "website": "http://www.ucb.edu.bh/"
       },
       {
          "university": "University of Bahrain",
          "website": "http://www.uob.edu.bh/"
       }
    ],
    "BI": [
       {
          "university": "Hope Africa University",
          "website": "http://hopeafricauniversity.org/"
       },
       {
          "university": "Université du Burundi",
          "website": "http://www.ub.edu.bi/"
       },
       {
          "university": "Université Lumière de Bujumbura",
          "website": "http://www.ulbu.bi/"
       }
    ],
    "BJ": [
       {
          "university": "Espam Formation University",
          "website": "http://www.espam-formationuc.org/"
       },
       {
          "university": "Houdegbe North American University Benin",
          "website": "http://www.hnaubenin.org/"
       },
       {
          "university": "Université d'Abomey-Calavi (UAC)",
          "website": "http://www.uac.bj/"
       }
    ],
    "BM": [
       {
          "university": "Bermuda College",
          "website": "http://www.bercol.bm/"
       }
    ],
    "BN": [
       {
          "university": "Institut Teknologi Brunei",
          "website": "http://www.itb.edu.bn/"
       },
       {
          "university": "Universiti Islam Sultan Sharif Ali",
          "website": "http://www.unissa.edu.bn/"
       },
       {
          "university": "University of Brunei Darussalam",
          "website": "http://www.ubd.edu.bn/"
       }
    ],
    "BO": [
       {
          "university": "Escuela Militar de Ingeniería",
          "website": "http://www.emi.edu.bo/"
       },
       {
          "university": "Universidad Adventista de Bolivia",
          "website": "http://www.uab.edu.bo/"
       },
       {
          "university": "Universidad Amazonica de Pando",
          "website": "http://www.uapnet.edu.bo/"
       },
       {
          "university": "Universidad Andina Simón Bolivar",
          "website": "http://www.uasb.edu.bo/"
       },
       {
          "university": "Universidad Autónoma del Beni José Ballivián",
          "website": "http://www.uabjb.edu.bo/"
       },
       {
          "university": "Universidad Autónoma Gabriel René Moreno",
          "website": "http://www.uagrm.edu.bo/"
       },
       {
          "university": "Universidad Autónoma Juan Misael Saracho",
          "website": "http://www.uajms.edu.bo/"
       },
       {
          "university": "Universidad Autónoma Tomás Frías",
          "website": "http://www.uatf.edu.bo/"
       },
       {
          "university": "Universidad Católica Boliviana - Cochabamba",
          "website": "http://www.ucbcba.edu.bo/"
       },
       {
          "university": "Universidad Católica Boliviana - La Paz",
          "website": "http://www.ucb.edu.bo/"
       },
       {
          "university": "Universidad Católica Boliviana - Santa Cruz",
          "website": "http://www.ucbscz.edu.bo/"
       },
       {
          "university": "Universidad Católica Boliviana - Tarija",
          "website": "http://www.ucbtja.edu.bo/"
       },
       {
          "university": "Universidad Central",
          "website": "http://www.unicen.edu.bo/"
       },
       {
          "university": "Universidad de Aquino Bolivia",
          "website": "http://www.udabol.edu.bo/"
       },
       {
          "university": "Universidad Domingo Savio",
          "website": "http://www.upds.edu.bo/"
       },
       {
          "university": "Universidad Empresarial Mateo Kuljis",
          "website": "http://www.unikuljis.edu.bo/"
       },
       {
          "university": "Universidad Juan Misael Saracho",
          "website": "http://www.uajms.edu.bo/"
       },
       {
          "university": "Universidad Los Andes",
          "website": "http://www.udelosandes.edu.bo/"
       },
       {
          "university": "Universidad Mayor de San Andrés",
          "website": "http://www.umsanet.edu.bo/"
       },
       {
          "university": "Universidad Mayor de San Simón",
          "website": "http://www.umss.edu.bo/"
       },
       {
          "university": "Universidad Nacional Siglo XX Llallagua",
          "website": "http://www.unsxx.edu.bo/"
       },
       {
          "university": "Universidad Nuestra Senora de La Paz",
          "website": "http://www.unslp.edu.bo/"
       },
       {
          "university": "Universidad NUR",
          "website": "http://www.nur.edu/"
       },
       {
          "university": "Universidad Privada Abierta Latinoamericana",
          "website": "http://www.upal.edu/"
       },
       {
          "university": "Universidad Privada Boliviana",
          "website": "http://www.upb.edu/"
       },
       {
          "university": "Universidad Privada del Valle",
          "website": "http://www.univalle.edu/"
       },
       {
          "university": "Universidad Privada de Santa Cruz de la Sierra",
          "website": "http://www.upsa.edu.bo/"
       },
       {
          "university": "Universidad Privada Franz Tamayo (UNIFRANZ)",
          "website": "http://www.unifranz.edu.bo/"
       },
       {
          "university": "Universidad Salesiana de Bolivia",
          "website": "http://www.usalesiana.edu.bo/"
       },
       {
          "university": "Universidad San Francisco Xavier",
          "website": "http://www.usfx.info/"
       },
       {
          "university": "Universidad San Francisco Xavier de Chuquisaca",
          "website": "http://www.usfx.edu.bo/"
       },
       {
          "university": "Universidad Técnica de Oruro",
          "website": "http://www.uto.edu.bo/"
       }
    ],
    "BR": [
       {
          "university": "Centro Regional Universitário de Espiríto Santo do Pinhal",
          "website": "http://www.creupi.br/"
       },
       {
          "university": "Centro Universitário Adventista de São Paulo",
          "website": "http://www.unasp.edu.br/"
       },
       {
          "university": "Centro Universitário Barao de Maua",
          "website": "http://www.baraodemaua.br/"
       },
       {
          "university": "Centro Universitário Claretiano",
          "website": "http://www.claretiano.edu.br/"
       },
       {
          "university": "Centro Universitário de Araraquara",
          "website": "http://www.uniara.com.br/"
       },
       {
          "university": "Centro Universitário de João Pessoa",
          "website": "http://www.unipe.br/"
       },
       {
          "university": "Centro Universitário Monte Serrat",
          "website": "http://www.unimonte.br/"
       },
       {
          "university": "Centro Universitário Newton Paiva",
          "website": "http://www.newtonpaiva.br/"
       },
       {
          "university": "Centro Universitário Plinio Leite",
          "website": "http://www.unipli.com.br/"
       },
       {
          "university": "Centro Universitário Senac",
          "website": "http://www.sp.senac.br/"
       },
       {
          "university": "Centro Universitário Serra dos Órgãos",
          "website": "http://www.unifeso.edu.br/"
       },
       {
          "university": "EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória",
          "website": "http://www.emescam.br/"
       },
       {
          "university": "FAAP - Fundação Armando Alvares Penteado",
          "website": "http://www.faap.br/"
       },
       {
          "university": "Faculdade Integradas do Ceará",
          "website": "http://www.fic.br/"
       },
       {
          "university": "Faculdade Italo Brasileira",
          "website": "http://www.italo.br/"
       },
       {
          "university": "Faculdade Jaguariúna",
          "website": "http://www.faj.br/"
       },
       {
          "university": "Faculdades Integradas Curitiba",
          "website": "http://www.faculdadescuritiba.br/"
       },
       {
          "university": "Faculdades Integradas do Brasil - UNIBRASIL",
          "website": "http://www.unibrasil.com.br/"
       },
       {
          "university": "Faculdades Integradas Toledo",
          "website": "http://www.toledo.br/"
       },
       {
          "university": "Faculdades Integradas UPIS",
          "website": "http://www.upis.br/"
       },
       {
          "university": "FAE Business School - Faculdade de Administração e Economia",
          "website": "http://www.fae.edu/"
       },
       {
          "university": "Fundação Educacional de Ituverava",
          "website": "http://www.feituverava.com.br/"
       },
       {
          "university": "Instituto Mauá de Tecnologia",
          "website": "http://www.maua.br/"
       },
       {
          "university": "Instituto Nacional de Matemática Pura e Aplicada - IMPA",
          "website": "http://www.impa.br/"
       },
       {
          "university": "Instituto Tecnológico de Aeronáutica",
          "website": "http://www.ita.br/"
       },
       {
          "university": "Pontifícia Universidade Católica de Campinas",
          "website": "http://www.puccamp.br/"
       },
       {
          "university": "Pontifícia Universidade Católica de Minas Gerais",
          "website": "http://www.pucminas.br/"
       },
       {
          "university": "Pontifícia Universidade Católica de São Paulo",
          "website": "http://www.pucsp.br/"
       },
       {
          "university": "Pontificia Universidade Católica do Paraná",
          "website": "http://www.pucpr.br/"
       },
       {
          "university": "Pontifícia Universidade Católica do Rio de Janeiro",
          "website": "http://www.puc-rio.br/"
       },
       {
          "university": "Pontifícia Universidade Católica do Rio Grande do Sul",
          "website": "http://www.pucrs.br/"
       },
       {
          "university": "Universidade Bandeirante de São Paulo",
          "website": "http://www.uniban.br/"
       },
       {
          "university": "Universidade Braz Cubas",
          "website": "http://www.brazcubas.br/"
       },
       {
          "university": "Universidade Camilo Castelo Branco",
          "website": "http://www.unicastelo.br/"
       },
       {
          "university": "Universidade Candido Mendes",
          "website": "http://www.candidomendes.br/"
       },
       {
          "university": "Universidade Castelo Branco",
          "website": "http://www.castelobranco.br/"
       },
       {
          "university": "Universidade Católica de Brasília",
          "website": "http://www.ucb.br/"
       },
       {
          "university": "Universidade Católica de Goiás",
          "website": "http://www.ucg.br/"
       },
       {
          "university": "Universidade Católica de Pelotas",
          "website": "http://www.ucpel.tche.br/"
       },
       {
          "university": "Universidade Católica de Pernambuco",
          "website": "http://www.unicap.br/"
       },
       {
          "university": "Universidade Católica de Petrópolis",
          "website": "http://www.ucp.br/"
       },
       {
          "university": "Universidade Católica de Salvador",
          "website": "http://www.ucsal.br/"
       },
       {
          "university": "Universidade Católica de Santos",
          "website": "http://www.unisantos.com.br/"
       },
       {
          "university": "Universidade Católica Dom Bosco",
          "website": "http://www.unibosco.br/"
       },
       {
          "university": "Universidade Cidade de São Paulo",
          "website": "http://www.unicid.br/"
       },
       {
          "university": "Universidade Cruzeiro do Sul",
          "website": "http://www.unicsul.br/"
       },
       {
          "university": "Universidade da Amazônia",
          "website": "http://www.unama.br/"
       },
       {
          "university": "Universidade da Região da Campanha",
          "website": "http://www.urcamp.tche.br/"
       },
       {
          "university": "Universidade de Alfenas",
          "website": "http://www.unifenas.br/"
       },
       {
          "university": "Universidade de Brasília",
          "website": "http://www.unb.br/"
       },
       {
          "university": "Universidade de Caxias do Sul",
          "website": "http://www.ucs.tche.br/"
       },
       {
          "university": "Universidade de Cruz Alta",
          "website": "http://www.unicruz.tche.br/"
       },
       {
          "university": "Universidade de Fortaleza",
          "website": "http://www.unifor.br/"
       },
       {
          "university": "Universidade de Franca",
          "website": "http://www.unifran.br/"
       },
       {
          "university": "Universidade de Guarulhos",
          "website": "http://www.ung.br/"
       },
       {
          "university": "Universidade de Itaúna",
          "website": "http://web.prover.com.br/fui/"
       },
       {
          "university": "Universidade de Marília",
          "website": "http://www.unimar.br/"
       },
       {
          "university": "Universidade de Mogi das Cruzes",
          "website": "http://www.umc.br/"
       },
       {
          "university": "Universidade de Nova Iguaçu",
          "website": "http://www.unig.br/"
       },
       {
          "university": "Universidade de Passo Fundo",
          "website": "http://www.upf.tche.br/"
       },
       {
          "university": "Universidade de Pernambuco",
          "website": "http://www.upe.br/"
       },
       {
          "university": "Universidade de Ribeirão Preto",
          "website": "http://www.unaerp.br/"
       },
       {
          "university": "Universidade de Santa Cruz do Sul",
          "website": "http://www.unisc.br/"
       },
       {
          "university": "Universidade de Santo Amaro",
          "website": "http://www.unisa.br/"
       },
       {
          "university": "Universidade de São Paulo",
          "website": "http://www.usp.br/"
       },
       {
          "university": "Universidade de Taubaté",
          "website": "http://www.unitau.br/"
       },
       {
          "university": "Universidade de Tocantins",
          "website": "http://www.pop-to.rnp.br/"
       },
       {
          "university": "Universidade de Uberaba",
          "website": "http://www.uniube.br/"
       },
       {
          "university": "Universidade de Vila Velha",
          "website": "http://www.uvv.br/"
       },
       {
          "university": "Universidade do Amazonas",
          "website": "http://www.fua.br/"
       },
       {
          "university": "Universidade do Estado da Bahia",
          "website": "http://www.uneb.br/"
       },
       {
          "university": "Universidade do Estado de Santa Catarina",
          "website": "http://www.udesc.br/"
       },
       {
          "university": "Universidade do Estado do Rio de Janeiro",
          "website": "http://www.uerj.br/"
       },
       {
          "university": "Universidade do Estado do Rio Grande do Norte",
          "website": "http://www.uern.br/"
       },
       {
          "university": "Universidade do Extremo Sul Catarinense",
          "website": "http://www.unesc.rct-sc.br/"
       },
       {
          "university": "Universidade do Grande Rio",
          "website": "http://www.unigranrio.br/"
       },
       {
          "university": "Universidade do Oeste de Santa Catarina",
          "website": "http://www.unoesc.edu.br/"
       },
       {
          "university": "Universidade do Oeste Paulista",
          "website": "http://www.unoeste.br/"
       },
       {
          "university": "Universidade do Rio de Janeiro",
          "website": "http://www.unirio.br/"
       },
       {
          "university": "Universidade do Rio Grande",
          "website": "http://www.furg.br/"
       },
       {
          "university": "Universidade do Sagrado Coração",
          "website": "http://www.usc.br/"
       },
       {
          "university": "Universidade do Sul de Santa Catarina",
          "website": "http://www.unisul.br/"
       },
       {
          "university": "Universidade do Vale do Itajaí",
          "website": "http://www.univali.rct-sc.br/"
       },
       {
          "university": "Universidade Estácio de Sá",
          "website": "http://www.estacio.br/"
       },
       {
          "university": "Universidade Estadual da Paraíba",
          "website": "http://www.uepb.edu.br/"
       },
       {
          "university": "Universidade Estadual de Campinas",
          "website": "http://www.unicamp.br/"
       },
       {
          "university": "Universidade Estadual de Feira de Santana",
          "website": "http://www.uefs.br/"
       },
       {
          "university": "Universidade Estadual de Londrina",
          "website": "http://www.uel.br/"
       },
       {
          "university": "Universidade Estadual de Maringá",
          "website": "http://www.uem.br/"
       },
       {
          "university": "Universidade Estadual de Montes Claros",
          "website": "http://www.unimontes.br/"
       },
       {
          "university": "Universidade Estadual de Ponta Grossa",
          "website": "http://www.uepg.br/"
       },
       {
          "university": "Universidade Estadual de Santa Cruz",
          "website": "http://www.uesc.br/"
       },
       {
          "university": "Universidade Estadual do Ceará",
          "website": "http://www.uece.br/"
       },
       {
          "university": "Universidade Estadual do Centro-Oeste",
          "website": "http://www.unicentro.br/"
       },
       {
          "university": "Universidade Estadual do Maranhão",
          "website": "http://www.uema.br/"
       },
       {
          "university": "Universidade Estadual do Norte Fluminense",
          "website": "http://www.uenf.br/"
       },
       {
          "university": "Universidade Estadual do Oeste do Paraná",
          "website": "http://www.unioeste.br/"
       },
       {
          "university": "Universidade Estadual do Piauí",
          "website": "http://www.uespi.br/"
       },
       {
          "university": "Universidade Estadual do Vale do Acaraú",
          "website": "http://www.uvanet.br/"
       },
       {
          "university": "Universidade Estadual Paulista",
          "website": "http://www.unesp.br/"
       },
       {
          "university": "Universidade Estadual Sudoeste da Bahia",
          "website": "http://www.uesb.br/"
       },
       {
          "university": "Universidade Federal da Bahia",
          "website": "http://www.ufba.br/"
       },
       {
          "university": "Universidade Federal da Grande Dourados",
          "website": "http://www.ufgd.edu.br/"
       },
       {
          "university": "Universidade Federal da Paraíba",
          "website": "http://www.ufpb.br/"
       },
       {
          "university": "Universidade Federal de Alagoas",
          "website": "http://www.ufal.br/"
       },
       {
          "university": "Universidade Federal de Campina Grande",
          "website": "http://www.ufcg.edu.br/"
       },
       {
          "university": "Universidade Federal de Goiás",
          "website": "http://www.ufg.br/"
       },
       {
          "university": "Universidade Federal de Juiz de Fora",
          "website": "http://www.ufjf.br/"
       },
       {
          "university": "Universidade Federal de Lavras",
          "website": "http://www.ufla.br/"
       },
       {
          "university": "Universidade Federal de Mato Grosso",
          "website": "http://www.ufmt.br/"
       },
       {
          "university": "Universidade Federal de Mato Grosso do Sul",
          "website": "http://www.ufms.br/"
       },
       {
          "university": "Universidade Federal de Minas Gerais",
          "website": "http://www.ufmg.br/"
       },
       {
          "university": "Universidade Federal de Ouro Preto",
          "website": "http://www.ufop.br/"
       },
       {
          "university": "Universidade Federal de Pelotas",
          "website": "http://www.ufpel.tche.br/"
       },
       {
          "university": "Universidade Federal de Pernambuco",
          "website": "http://www.ufpe.br/"
       },
       {
          "university": "Universidade Federal de Rondônia",
          "website": "http://www.unir.br/"
       },
       {
          "university": "Universidade Federal de Roraima",
          "website": "http://www.technet.com.br/vestibular/"
       },
       {
          "university": "Universidade Federal de Santa Catarina",
          "website": "http://www.ufsc.br/"
       },
       {
          "university": "Universidade Federal de Santa Maria",
          "website": "http://www.ufsm.br/"
       },
       {
          "university": "Universidade Federal de São Carlos",
          "website": "http://www.ufscar.br/"
       },
       {
          "university": "Universidade Federal de São Paulo",
          "website": "http://www.epm.br/"
       },
       {
          "university": "Universidade Federal de Sergipe",
          "website": "http://www.ufs.br/"
       },
       {
          "university": "Universidade Federal de Uberlândia",
          "website": "http://www.ufu.br/"
       },
       {
          "university": "Universidade Federal de Viçosa",
          "website": "http://www.ufv.br/"
       },
       {
          "university": "Universidade Federal do ABC",
          "website": "http://www.ufabc.edu.br/"
       },
       {
          "university": "Universidade Federal do Acre",
          "website": "http://www.ufac.br/"
       },
       {
          "university": "Universidade Federal do Amapá",
          "website": "http://www.unifap.br/"
       },
       {
          "university": "Universidade Federal do Ceará",
          "website": "http://www.ufc.br/"
       },
       {
          "university": "Universidade Federal do Espírito Santo",
          "website": "http://www.ufes.br/"
       },
       {
          "university": "Universidade Federal do Maranhão",
          "website": "http://www.ufma.br/"
       },
       {
          "university": "Universidade Federal do Pará",
          "website": "http://www.ufpa.br/"
       },
       {
          "university": "Universidade Federal do Paraná",
          "website": "http://www.ufpr.br/"
       },
       {
          "university": "Universidade Federal do Piauí",
          "website": "http://www.ufpi.br/"
       },
       {
          "university": "Universidade Federal do Rio de Janeiro",
          "website": "http://www.ufrj.br/"
       },
       {
          "university": "Universidade Federal do Rio Grande do Norte",
          "website": "http://www.ufrn.br/"
       },
       {
          "university": "Universidade Federal do Rio Grande do Sul",
          "website": "http://www.ufrgs.br/"
       },
       {
          "university": "Universidade Federal do Vale do São Francisco",
          "website": "http://www.univasf.edu.br/"
       },
       {
          "university": "Universidade Federal Fluminense",
          "website": "http://www.uff.br/"
       },
       {
          "university": "Universidade Federal Rural de Pernambuco",
          "website": "http://www.ufrpe.br/"
       },
       {
          "university": "Universidade Federal Rural do Rio de Janeiro",
          "website": "http://www.ufrrj.br/"
       },
       {
          "university": "Universidade Federal Rural do Semi-Árido",
          "website": "http://www.ufersa.edu.br/"
       },
       {
          "university": "Universidade Gama Filho",
          "website": "http://www.ugf.br/"
       },
       {
          "university": "Universidade Ibirapuera",
          "website": "http://www.unib.br/"
       },
       {
          "university": "Universidade Luterana do Brasil",
          "website": "http://www.ulbra.br/"
       },
       {
          "university": "Universidade Mackenzie",
          "website": "http://www.mackenzie.br/"
       },
       {
          "university": "Universidade Metodista de Piracicaba",
          "website": "http://www.unimep.br/"
       },
       {
          "university": "Universidade Metropolitana de Santos",
          "website": "http://www.unimes.com.br/"
       },
       {
          "university": "Universidade para o Desenvolvimento do Alto Vale do Itajaí",
          "website": "http://www.unidavi.rct-sc.br/"
       },
       {
          "university": "Universidade Paulista",
          "website": "http://www.unip-objetivo.br/"
       },
       {
          "university": "Universidade Potiguar",
          "website": "http://www.unp.br/"
       },
       {
          "university": "Universidade Regional de Blumenau",
          "website": "http://www.furb.rct-sc.br/"
       },
       {
          "university": "Universidade Regional do Cariri",
          "website": "http://www.urca.br/"
       },
       {
          "university": "Universidade Regional do Noroeste do Estado do Rio Grande do Sul",
          "website": "http://www.unijui.tche.br/"
       },
       {
          "university": "Universidade Regional Integrada do Alto Uruguai e das Missões",
          "website": "http://www.uri.br/"
       },
       {
          "university": "Universidade Salgado de Oliveira",
          "website": "http://www.universo.br/"
       },
       {
          "university": "Universidade Salvador",
          "website": "http://www.unifacs.br/"
       },
       {
          "university": "Universidade Santa Cecília dos Bandeirantes",
          "website": "http://www.stcecilia.br/"
       },
       {
          "university": "Universidade Santa Úrsula",
          "website": "http://www.usu.br/"
       },
       {
          "university": "Universidade São Francisco",
          "website": "http://www.usf.br/"
       },
       {
          "university": "Universidade São Judas Tadeu",
          "website": "http://www.saojudas.br/"
       },
       {
          "university": "Universidade São Marcos",
          "website": "http://www.smarcos.br/"
       },
       {
          "university": "Universidade Tiradentes",
          "website": "http://www.unit.br/"
       },
       {
          "university": "Universidade Vale do Paraiba",
          "website": "http://www.univap.br/"
       },
       {
          "university": "Universidade Vale do Rio Doce",
          "website": "http://www.univale.br/"
       },
       {
          "university": "Universidade Vale do Rio dos Sinos",
          "website": "http://www.unisinos.br/"
       },
       {
          "university": "Universidade Veiga de Almeida",
          "website": "http://www.uva.br/"
       }
    ],
    "BS": [
       {
          "university": "The College of The Bahamas",
          "website": "http://www.cob.edu.bs/"
       }
    ],
    "BT": [
       {
          "university": "Royal University of Bhutan",
          "website": "http://www.rub.edu.bt/"
       }
    ],
    "BW": [
       {
          "university": "ABM University College",
          "website": "http://www.abm.ac.bw/"
       },
       {
          "university": "Botho University",
          "website": "http://www.bothocollege.ac.bw/"
       },
       {
          "university": "Botswana Accountancy College",
          "website": "http://www.bac.ac.bw/"
       },
       {
          "university": "Botswana College of Agriculture",
          "website": "http://www.bca.bw/"
       },
       {
          "university": "Botswana International University of Science & Technology",
          "website": "http://www.biust.ac.bw/"
       },
       {
          "university": "Gaborone Universal College of Law",
          "website": "http://www.guc.co.bw/"
       },
       {
          "university": "New Era College of Arts - Science & Technology",
          "website": "http://www.neweracollege.ac.bw/"
       },
       {
          "university": "University of Botswana",
          "website": "http://www.ub.bw/"
       }
    ],
    "BY": [
       {
          "university": "Academy of Public Administration of Belarus",
          "website": "http://www.pac.by/"
       },
       {
          "university": "Academy of the Ministry of Internal Affairs of the Republic of Belarus",
          "website": "http://academy.mia.by/"
       },
       {
          "university": "Belarusian-Russian University",
          "website": "http://www.bru.mogilev.by/"
       },
       {
          "university": "Belarussian National Technical University",
          "website": "http://www.bntu.by/"
       },
       {
          "university": "Belarussian State Academy of Music",
          "website": "http://www.bgam.edu.by/"
       },
       {
          "university": "Belarussian State Agrarian Technical University",
          "website": "http://www.batu.edu.by/"
       },
       {
          "university": "Belarussian State Agricultural Academy",
          "website": "http://www.baa.by/"
       },
       {
          "university": "Belarussian State Economic University",
          "website": "http://www.bseu.by/"
       },
       {
          "university": "Belarussian State Medical University",
          "website": "http://www.bsmu.by/"
       },
       {
          "university": "Belarussian State Pedagogical University M. Tanka",
          "website": "http://www.bspu.unibel.by/"
       },
       {
          "university": "Belarussian State Polytechnical Academy",
          "website": "http://www.bspa.unibel.by/"
       },
       {
          "university": "Belarussian State Technological University",
          "website": "http://www.bstu.unibel.by/"
       },
       {
          "university": "Belarussian State University",
          "website": "http://www.bsu.by/"
       },
       {
          "university": "Belarussian State University of Culture and Arts",
          "website": "http://www.buk.by/"
       },
       {
          "university": "Belarussian State University of Informatics and Radioelectronics",
          "website": "http://www.bsuir.by/"
       },
       {
          "university": "Belarussian State University of Transport",
          "website": "http://www.belsut.gomel.by/"
       },
       {
          "university": "Brest State Technical University",
          "website": "http://www.bstu.by/"
       },
       {
          "university": "Brest State University",
          "website": "http://www.brsu.brest.by/"
       },
       {
          "university": "European Humanities University",
          "website": "http://www.data.minsk.by/ehu/"
       },
       {
          "university": "Gomel State Medical University",
          "website": "http://www.meduniver.gomel.by/"
       },
       {
          "university": "Gomel State Technical University Pavel Sukhoi",
          "website": "http://www.gstu.gomel.by/"
       },
       {
          "university": "Gomel State University Francisk Scarnia",
          "website": "http://www.gsu.unibel.by/"
       },
       {
          "university": "Grodno State Agrarian University",
          "website": "http://www.uni-agro.grodno.by/"
       },
       {
          "university": "Grodno State Medical University",
          "website": "http://www.grsmu.by/"
       },
       {
          "university": "Grodno State University Yanka Kupaly",
          "website": "http://www.grsu.by/"
       },
       {
          "university": "Institute for Command Engineers of The Ministry for Emergency Situations",
          "website": "http://www.kii.gov.by/"
       },
       {
          "university": "International Sakharov Environmental University",
          "website": "http://www.iseu.by/"
       },
       {
          "university": "Minsk Institute of Management",
          "website": "http://www.miu.by/"
       },
       {
          "university": "Minsk State Linguistic University",
          "website": "http://www.mslu.by/"
       },
       {
          "university": "Mogilev State University A.A. Kuleshov",
          "website": "http://msu.mogilev.by/"
       },
       {
          "university": "Mozyr State Pedagogical Institute",
          "website": "http://www.mgpu.gomel.by/"
       },
       {
          "university": "Polotsk State University",
          "website": "http://www.psu.by/"
       },
       {
          "university": "Vitebsk State Academy of Veterinary Medicine",
          "website": "http://www.vsavm.com/"
       },
       {
          "university": "Vitebsk State Medical University",
          "website": "http://www.vgmu.vitebsk.by/"
       },
       {
          "university": "Vitebsk State Technological University",
          "website": "http://www.vstu.vitebsk.by/"
       },
       {
          "university": "Vitebsk State University",
          "website": "http://www.vsu.by/"
       }
    ],
    "BZ": [
       {
          "university": "American University of the Caribbean - School of Medicine",
          "website": "http://www.aucmed.edu/"
       },
       {
          "university": "Central America Health Sciences University (Belize Medical College)",
          "website": "http://www.cahsu.edu/"
       },
       {
          "university": "Greenford International University",
          "website": "http://www.giuedu.bz/"
       },
       {
          "university": "University of Belize",
          "website": "http://www.ub.edu.bz/"
       },
       {
          "university": "West Coast University (WCU) ",
          "website": "http://www.westcoastuniversity.bz/"
       }
    ],
    "CA": [
       {
          "university": "Acadia University",
          "website": "http://www.acadiau.ca/"
       },
       {
          "university": "Algonquin College",
          "website": "http://www.algonquincollege.com/"
       },
       {
          "university": "Ashton College",
          "website": "http://www.ashtoncollege.com/"
       },
       {
          "university": "Assumption University",
          "website": "http://www.assumptionu.ca/"
       },
       {
          "university": "Athabasca University",
          "website": "http://www.athabascau.ca/"
       },
       {
          "university": "Augustana University College",
          "website": "http://www.augustana.ab.ca/"
       },
       {
          "university": "Bishop's University",
          "website": "http://www.ubishops.ca/"
       },
       {
          "university": "Bow Valley College",
          "website": "http://www.bowvalleycollege.ca/"
       },
       {
          "university": "Brandon University",
          "website": "http://www.brandonu.ca/"
       },
       {
          "university": "Brescia College",
          "website": "http://www.uwo.ca/brescia/"
       },
       {
          "university": "British Columbia Institute of Technology",
          "website": "http://www.bcit.ca/"
       },
       {
          "university": "British Columbia Open University",
          "website": "http://www.bcou.ca/"
       },
       {
          "university": "Brock University",
          "website": "http://www.brocku.ca/"
       },
       {
          "university": "Camosun College",
          "website": "http://www.camosun.bc.ca/"
       },
       {
          "university": "Campion College",
          "website": "http://www.uregina.ca/campion/"
       },
       {
          "university": "Canadian College of Business & Computers",
          "website": "http://www.ccbc.ca/"
       },
       {
          "university": "Canadian Mennonite University",
          "website": "http://www.cmu.ca/"
       },
       {
          "university": "Capilano College",
          "website": "http://www.capcollege.bc.ca/"
       },
       {
          "university": "Carleton University",
          "website": "http://www.carleton.ca/"
       },
       {
          "university": "Centennial College",
          "website": "http://www.centennialcollege.ca/"
       },
       {
          "university": "Collège Boréal",
          "website": "http://www.borealc.on.ca/"
       },
       {
          "university": "College of New Caledonia",
          "website": "http://www.cnc.bc.ca/"
       },
       {
          "university": "College of the Rockies",
          "website": "http://www.cotr.bc.ca/"
       },
       {
          "university": "Columbia College",
          "website": "http://www.columbiacollege.ca/"
       },
       {
          "university": "Concordia University",
          "website": "http://www.concordia.ca/"
       },
       {
          "university": "Concordia University College of Alberta",
          "website": "http://www.concordia.ab.ca/"
       },
       {
          "university": "Conestoga College",
          "website": "http://www.conestogac.on.ca/"
       },
       {
          "university": "Dalhousie University",
          "website": "http://www.dal.ca/"
       },
       {
          "university": "DeVry Institute of Technology",
          "website": "http://www.cal.devry.ca/"
       },
       {
          "university": "Dominican College of Philosophy and Theology",
          "website": "http://www.op.org/canada/college.htm"
       },
       {
          "university": "Douglas College",
          "website": "http://www.douglas.bc.ca/"
       },
       {
          "university": "Durham College",
          "website": "http://www.durhamc.on.ca/"
       },
       {
          "university": "École des Hautes Études Commerciales",
          "website": "http://www.hec.ca/"
       },
       {
          "university": "École de technologie supérieure - Université du Québec",
          "website": "http://www.etsmtl.ca/"
       },
       {
          "university": "École nationale d'administration publique - Université du Québec",
          "website": "http://www.enap.uquebec.ca/"
       },
       {
          "university": "École Polytechnique de Montréal - Université de Montréal",
          "website": "http://www.polymtl.ca/"
       },
       {
          "university": "Emily Carr Institute of Art + Design",
          "website": "http://www.eciad.bc.ca/"
       },
       {
          "university": "First Nations University of Canada",
          "website": "http://www.firstnationsuniversity.ca/"
       },
       {
          "university": "George Brown College",
          "website": "http://www.gbrownc.on.ca/"
       },
       {
          "university": "Humber College",
          "website": "http://www.humber.ca/"
       },
       {
          "university": "Huron University College",
          "website": "http://www.huronuc.on.ca/"
       },
       {
          "university": "Institut Armand-Frappier - Université du Québec",
          "website": "http://www.iaf.inrs.ca/"
       },
       {
          "university": "Institut National de la Recherche Scientifique - Université du Québec",
          "website": "http://www.inrs.uquebec.ca/"
       },
       {
          "university": "King's College",
          "website": "http://www.uwo.ca/kings/"
       },
       {
          "university": "Kingston College",
          "website": "http://www.kingston.edu/"
       },
       {
          "university": "King's University College",
          "website": "http://www.kingsu.ab.ca/"
       },
       {
          "university": "Kwantleen University College",
          "website": "http://www.kwantlen.bc.ca/"
       },
       {
          "university": "Lakehead University",
          "website": "http://www.lakeheadu.ca/"
       },
       {
          "university": "Langara College",
          "website": "http://www.langara.bc.ca/"
       },
       {
          "university": "Lansbridge University",
          "website": "http://www.lansbridge.com/"
       },
       {
          "university": "Laurentian University of Sudbury",
          "website": "http://www.laurentian.ca/"
       },
       {
          "university": "Luther College",
          "website": "http://www.saskweb.com/luthercollege/"
       },
       {
          "university": "MacEwan University",
          "website": "http://www.macewan.ca/"
       },
       {
          "university": "Malaspina University College",
          "website": "http://www.mala.bc.ca/"
       },
       {
          "university": "McGill University",
          "website": "http://www.mcgill.ca/"
       },
       {
          "university": "McMaster University",
          "website": "http://www.mcmaster.ca/"
       },
       {
          "university": "Memorial University of Newfoundland",
          "website": "http://www.ucs.mun.ca/"
       },
       {
          "university": "Mount Allison University",
          "website": "http://www.mta.ca/"
       },
       {
          "university": "Mount Royal College",
          "website": "http://www.mtroyal.ab.ca/"
       },
       {
          "university": "Mount Saint Vincent University",
          "website": "http://www.msvu.ca/"
       },
       {
          "university": "Nicola Valley Institute of Technology",
          "website": "http://www.nvit.bc.ca/"
       },
       {
          "university": "Nipissing University",
          "website": "http://www.nipissingu.ca/"
       },
       {
          "university": "Northern Alberta Institute of Technology",
          "website": "http://www.nait.ca/"
       },
       {
          "university": "Northern Lights College",
          "website": "http://www.nlc.bc.ca/"
       },
       {
          "university": "North Island College",
          "website": "http://www.nic.bc.ca/"
       },
       {
          "university": "Nova Scotia Agricultural College",
          "website": "http://www.nsac.ns.ca/"
       },
       {
          "university": "Nova Scotia College of Art and Design",
          "website": "http://www.nscad.ns.ca/"
       },
       {
          "university": "Okanagan University College",
          "website": "http://www.okanagan.bc.ca/"
       },
       {
          "university": "Ontario College of Art and Design",
          "website": "http://www.ocad.ca/"
       },
       {
          "university": "Pacific International College",
          "website": "http://www.picollege.ca/"
       },
       {
          "university": "Queen's University",
          "website": "http://www.queensu.ca/"
       },
       {
          "university": "Quest University",
          "website": "http://www.questu.ca/"
       },
       {
          "university": "Redeemer College",
          "website": "http://www.redeemer.ca/"
       },
       {
          "university": "Regent College",
          "website": "http://www.regent-college.edu/"
       },
       {
          "university": "Royal Military College of Canada",
          "website": "http://www.rmc.ca/"
       },
       {
          "university": "Royal Roads University",
          "website": "http://www.royalroads.ca/"
       },
       {
          "university": "Ryerson Polytechnic University",
          "website": "http://www.ryerson.ca/"
       },
       {
          "university": "Saskatchewan Indian Federated College",
          "website": "http://www.sifc.edu/"
       },
       {
          "university": "Sauder School of Business",
          "website": "http://www.sauder.ubc.ca/"
       },
       {
          "university": "Selkirk College",
          "website": "http://www.selkirk.bc.ca/"
       },
       {
          "university": "Seneca College",
          "website": "http://www.senecac.on.ca/"
       },
       {
          "university": "Sheridan College",
          "website": "http://www.sheridanc.on.ca/"
       },
       {
          "university": "Simon Fraser University",
          "website": "http://www.sfu.ca/"
       },
       {
          "university": "Southern Alberta Institute of Technology",
          "website": "http://www.sait.ca/"
       },
       {
          "university": "St. Anne University",
          "website": "http://www.ustanne.ednet.ns.ca/"
       },
       {
          "university": "St. Clair College",
          "website": "http://www.stclairc.on.ca/"
       },
       {
          "university": "St. Francis Xavier University",
          "website": "http://www.stfx.ca/"
       },
       {
          "university": "St. Mary's University",
          "website": "http://www.stmarys.ca/"
       },
       {
          "university": "St. Paul University",
          "website": "http://www.ustpaul.ca/"
       },
       {
          "university": "St. Thomas University",
          "website": "http://www.stthomasu.ca/"
       },
       {
          "university": "Télé-université - Université du Québec",
          "website": "http://www.teluq.uquebec.ca/"
       },
       {
          "university": "Thompson Rivers University",
          "website": "http://www.tru.ca/"
       },
       {
          "university": "Trent University",
          "website": "http://www.trentu.ca/"
       },
       {
          "university": "Trinity Western University",
          "website": "http://www.twu.ca/"
       },
       {
          "university": "Université de Montréal",
          "website": "http://www.umontreal.ca/"
       },
       {
          "university": "Université de Sherbrooke",
          "website": "http://www.usherb.ca/"
       },
       {
          "university": "Université du Québec à Chicoutimi",
          "website": "http://www.uqac.ca/"
       },
       {
          "university": "Université du Québec à Montréal",
          "website": "http://www.uqam.ca/"
       },
       {
          "university": "Université du Québec à Rimouski",
          "website": "http://www.uqar.uquebec.ca/"
       },
       {
          "university": "Université du Québec à Trois-Rivières",
          "website": "http://www.uqtr.uquebec.ca/"
       },
       {
          "university": "Université du Québec en Abitibi-Témiscamingue",
          "website": "http://www.uqat.uquebec.ca/"
       },
       {
          "university": "Université du Québec en Outaouais",
          "website": "http://www.uqo.ca/"
       },
       {
          "university": "Université Laval",
          "website": "http://www.ulaval.ca/"
       },
       {
          "university": "University Canada West",
          "website": "http://www.universitycanadawest.ca/"
       },
       {
          "university": "University College of Cape Breton",
          "website": "http://www.uccb.ns.ca/"
       },
       {
          "university": "University College of Saint-Boniface",
          "website": "http://www.ustboniface.mb.ca/"
       },
       {
          "university": "University College of the Cariboo",
          "website": "http://www.cariboo.bc.ca/"
       },
       {
          "university": "University College of the Fraser Valley",
          "website": "http://www.ucfv.bc.ca/"
       },
       {
          "university": "University of Alberta",
          "website": "http://www.ualberta.ca/"
       },
       {
          "university": "University of British Columbia",
          "website": "http://www.ubc.ca/"
       },
       {
          "university": "University of Calgary",
          "website": "http://www.ucalgary.ca/"
       },
       {
          "university": "University of Guelph",
          "website": "http://www.uoguelph.ca/"
       },
       {
          "university": "University of King's College",
          "website": "http://www.ukings.ns.ca/"
       },
       {
          "university": "University of Lethbridge",
          "website": "http://www.uleth.ca/"
       },
       {
          "university": "University of Manitoba",
          "website": "http://www.umanitoba.ca/"
       },
       {
          "university": "University of Moncton",
          "website": "http://www.umoncton.ca/"
       },
       {
          "university": "University of Moncton - Edmundston",
          "website": "http://www.cuslm.ca/"
       },
       {
          "university": "University of Moncton - Shippagan",
          "website": "http://www.cus.ca/"
       },
       {
          "university": "University of New Brunswick",
          "website": "http://www.unb.ca/"
       },
       {
          "university": "University of New Brunswick - Saint John",
          "website": "http://www.unbsj.ca/"
       },
       {
          "university": "University of Northern British Columbia",
          "website": "http://www.unbc.ca/"
       },
       {
          "university": "University of Ontario Institute of Technology",
          "website": "http://www.uoit.ca/"
       },
       {
          "university": "University of Ottawa",
          "website": "http://www.uottawa.ca/"
       },
       {
          "university": "University of Prince Edward Island",
          "website": "http://www.upei.ca/"
       },
       {
          "university": "University of Québec",
          "website": "http://www.uquebec.ca/"
       },
       {
          "university": "University of Regina",
          "website": "http://www.uregina.ca/"
       },
       {
          "university": "University of Saskatchewan",
          "website": "http://www.usask.ca/"
       },
       {
          "university": "University of St. Jerome's College",
          "website": "http://www.usjc.uwaterloo.ca/"
       },
       {
          "university": "University of St. Michael's College",
          "website": "http://www.utoronto.ca/stmikes/"
       },
       {
          "university": "University of Sudbury",
          "website": "http://www.usudbury.com/"
       },
       {
          "university": "University of Toronto",
          "website": "http://www.utoronto.ca/"
       },
       {
          "university": "University of Toronto - Mississauga",
          "website": "http://www.utm.utoronto.ca/"
       },
       {
          "university": "University of Toronto - Scarborough",
          "website": "http://www.scar.utoronto.ca/"
       },
       {
          "university": "University of Trinity College",
          "website": "http://www.trinity.utoronto.ca/"
       },
       {
          "university": "University of Victoria",
          "website": "http://www.uvic.ca/"
       },
       {
          "university": "University of Waterloo",
          "website": "http://www.uwaterloo.ca/"
       },
       {
          "university": "University of Western Ontario",
          "website": "http://www.uwo.ca/"
       },
       {
          "university": "University of Windsor",
          "website": "http://www.uwindsor.ca/"
       },
       {
          "university": "University of Winnipeg",
          "website": "http://www.uwinnipeg.ca/"
       },
       {
          "university": "Vancouver Community College",
          "website": "http://www.vcc.ca/"
       },
       {
          "university": "Victoria University Toronto - University of Toronto",
          "website": "http://vicu.utoronto.ca/"
       },
       {
          "university": "Wilfrid Laurier University",
          "website": "http://www.wlu.ca/"
       },
       {
          "university": "William and Catherine Booth College",
          "website": "http://www.boothcollege.ca/"
       },
       {
          "university": "York University",
          "website": "http://www.yorku.ca/"
       },
       {
          "university": "Yukon College",
          "website": "http://www.yukoncollege.yk.ca/"
       }
    ],
    "CD": [
       {
          "university": "Université Catholique de Bukavu",
          "website": "http://www.ucbukavu.ac.cd/"
       },
       {
          "university": "Université Chrétienne Bilingue du Congo",
          "website": "http://www.ucbc.org/"
       },
       {
          "university": "Université de Bandundu Ville",
          "website": "http://www.uniband.org/"
       },
       {
          "university": "Université de Kamina",
          "website": "http://www.ukamina.org/"
       },
       {
          "university": "Université de Kikwite",
          "website": "http://www.unikik.net/"
       },
       {
          "university": "Université de Kinshasa",
          "website": "http://www.unikin.cd/"
       },
       {
          "university": "Université de Kisangani",
          "website": "http://www.unikis.ac.cd/"
       },
       {
          "university": "Université de Kolwezi",
          "website": "http://www.unikol.ac.cd/"
       },
       {
          "university": "Université de Lubumbashi",
          "website": "http://www.unilu.ac.cd/"
       },
       {
          "university": "Université de l'Uélé",
          "website": "http://www.uniuele.ac.cd/"
       },
       {
          "university": "Université Kongo",
          "website": "http://www.universitekongo.org/"
       },
       {
          "university": "Université Protestante au Congo",
          "website": "http://www.upc.ac.cd/"
       },
       {
          "university": "Université Shalom de Bunia",
          "website": "http://www.unishabunia.org/"
       }
    ],
    "CF": [
       {
          "university": "Université de Bangui",
          "website": "http://www.univ-bangui.net/"
       }
    ],
    "CG": [
       {
          "university": "University Marien Ngouabi Brazzaville",
          "website": "http://www.univ-mngb.net/"
       }
    ],
    "CH": [
       {
          "university": "Business and Hotel Management School",
          "website": "http://www.bhms.ch/"
       },
       {
          "university": "Business School Lausanne (BSL)",
          "website": "http://www.bsl-lausanne.ch/"
       },
       {
          "university": "Cubidor University Switzerland",
          "website": "http://www.cubidor.com/"
       },
       {
          "university": "DCT International Hotel & Business Management School",
          "website": "http://www.dct.ch/"
       },
       {
          "university": "European Graduate School - Media & Communications",
          "website": "http://www.egs.edu/"
       },
       {
          "university": "European University",
          "website": "http://www.euruni.edu/"
       },
       {
          "university": "Fachhochschule Pur",
          "website": "http://www.phw.info/"
       },
       {
          "university": "Fachhochschule St. Gallen",
          "website": "https://www.fhsg.ch/"
       },
       {
          "university": "Franklin College Switzerland",
          "website": "http://www.fc.edu/"
       },
       {
          "university": "Geneva Business School",
          "website": "http://www.gbs-ge.ch/"
       },
       {
          "university": "Glion Institute of Higher Education",
          "website": "http://www.glion.edu/"
       },
       {
          "university": "Graduate School of Business Administration Zurich (GSBA Zurich)",
          "website": "http://www.gsba.ch/"
       },
       {
          "university": "Hochschule für Gestaltung und Kunst Zürich",
          "website": "http://www.hgkz.ch/"
       },
       {
          "university": "International Culinary Institute (ICI)",
          "website": "http://www.ici-luzern.com/"
       },
       {
          "university": "International Hotel Management Institute",
          "website": "http://www.imi-luzern.com/"
       },
       {
          "university": "International School of Business Management",
          "website": "http://www.isbm-school.com/"
       },
       {
          "university": "International University in Geneva",
          "website": "http://www.iun.ch/"
       },
       {
          "university": "Lausanne Hotel School (EHL)",
          "website": "http://www.ehl.ch/"
       },
       {
          "university": "Les Roches Gruyère University of Applied Sciences",
          "website": "http://www.lrguas.ch/"
       },
       {
          "university": "Les Roches International School of Hotel Management",
          "website": "http://www.lesroches.edu/"
       },
       {
          "university": "Libera Università degli Studi di Scienze Umane e Tecnologiche",
          "website": "http://www.uniludes.ch/"
       },
       {
          "university": "Lucerne University of Applied Sciences and Arts ",
          "website": "http://www.hslu.ch/"
       },
       {
          "university": "NTB Interstate University of Applied Sciences of Technology",
          "website": "http://www.ntb.ch/"
       },
       {
          "university": "Pädagogische Hochschule Zürich (Zurich School of Education)",
          "website": "http://www.phzh.ch/"
       },
       {
          "university": "Schiller International University - American College of Switzerland",
          "website": "http://www.american-college.com/"
       },
       {
          "university": "School of Management Fribourg",
          "website": "http://www.heg-fr.ch/"
       },
       {
          "university": "Swiss Business School Zurich (SBS)",
          "website": "http://www.sbs.edu/"
       },
       {
          "university": "Swiss Federal Institute of Technology - Lausanne",
          "website": "http://www.epfl.ch/"
       },
       {
          "university": "Swiss Federal Institute of Technology - Zurich",
          "website": "http://www.ethz.ch/"
       },
       {
          "university": "Swiss Management Center",
          "website": "http://www.swissmc.ch/"
       },
       {
          "university": "University Center César Ritz",
          "website": "http://www.uccr.edu/"
       },
       {
          "university": "University of Applied Sciences Aargau",
          "website": "http://www.fh-aargau.ch/"
       },
       {
          "university": "University of Applied Sciences Basel (FHBB )",
          "website": "http://www.fhbb.ch/"
       },
       {
          "university": "University of Applied Sciences Chur",
          "website": "http://www.fh-htachur.ch/"
       },
       {
          "university": "University of Applied Sciences Rapperswil",
          "website": "http://www.hsr.ch/"
       },
       {
          "university": "University of Applied Sciences Solothurn Northwestern Switzerland",
          "website": "http://www.fhso.ch/"
       },
       {
          "university": "University of Basel",
          "website": "http://www.unibas.ch/"
       },
       {
          "university": "University of Berne",
          "website": "http://www.unibe.ch/"
       },
       {
          "university": "University of Fribourg",
          "website": "http://www.unifr.ch/"
       },
       {
          "university": "University of Geneva",
          "website": "http://www.unige.ch/"
       },
       {
          "university": "University of Lausanne",
          "website": "http://www.unil.ch/"
       },
       {
          "university": "University of Lucerne",
          "website": "http://www.unilu.ch/"
       },
       {
          "university": "University of Neuchatel",
          "website": "http://www.unine.ch/"
       },
       {
          "university": "University of St. Gallen",
          "website": "http://www.unisg.ch/"
       },
       {
          "university": "University of Switzerland",
          "website": "http://www.swissu.org/"
       },
       {
          "university": "University of the Italian-speaking Part of Switzerland",
          "website": "http://www.unisi.ch/"
       },
       {
          "university": "University of Zürich",
          "website": "http://www.unizh.ch/"
       },
       {
          "university": "Victoria University",
          "website": "http://www.victoria-uni.ch/"
       },
       {
          "university": "Webster University Geneva",
          "website": "http://www.webster.ch/"
       },
       {
          "university": "Zurich University of Applied Sciences Winterthur",
          "website": "http://www.zhwin.ch/"
       }
    ],
    "CI": [
       {
          "university": "Université d'Abobo-Adjamé",
          "website": "http://www.uabobo.ci/"
       },
       {
          "university": "Université de Bouaké",
          "website": "http://www.ubouake.ci/"
       },
       {
          "university": "Université de Cocody",
          "website": "http://www.univ-cocody.ci/"
       }
    ],
    "CL": [
       {
          "university": "Escuela de Arquitectura y Diseño",
          "website": "http://www.arquitecturaucv.cl/"
       },
       {
          "university": "Pontificia Universidad Catolica de Chile",
          "website": "http://www.puc.cl/"
       },
       {
          "university": "Universidad Academia de Humanismo Cristiano",
          "website": "http://www.academia.cl/"
       },
       {
          "university": "Universidad Academia de Humanismo Cristiano",
          "website": "http://www.academia.cl/"
       },
       {
          "university": "Universidad Adolfo Ibañez",
          "website": "http://www.uai.cl/"
       },
       {
          "university": "Universidad Adolfo Ibáñez",
          "website": "http://www.uai.cl/"
       },
       {
          "university": "Universidad Adventista de Chile",
          "website": "http://www.unachile.cl/"
       },
       {
          "university": "Universidad Alberto Hurtado",
          "website": "http://www.uahurtado.cl/"
       },
       {
          "university": "Universidad Arcis",
          "website": "http://www.universidadarcis.cl/"
       },
       {
          "university": "Universidad Arturo Prat",
          "website": "http://www.unap.cl/"
       },
       {
          "university": "Universidad Austral de Chile",
          "website": "http://www.uach.cl/"
       },
       {
          "university": "Universidad Autonoma del Sur",
          "website": "http://www.uas.cl/"
       },
       {
          "university": "Universidad Bernardo O'Higgins",
          "website": "http://www.ubohiggins.cl/"
       },
       {
          "university": "Universidad Bolivariana",
          "website": "http://www.ubolivariana.cl/"
       },
       {
          "university": "Universidad Católica Cardenal Raúl Silva Henríquez",
          "website": "http://www.ucsh.cl/"
       },
       {
          "university": "Universidad Catolica de La Santísima Concepción",
          "website": "http://www.ucsc.cl/"
       },
       {
          "university": "Universidad Catolica del Maule",
          "website": "http://www.ucm.cl/"
       },
       {
          "university": "Universidad Catolica del Norte",
          "website": "http://www.ucn.cl/"
       },
       {
          "university": "Universidad Catolica de Temuco",
          "website": "http://www.uctem.cl/"
       },
       {
          "university": "Universidad Catolica de Valparaiso",
          "website": "http://www.ucv.cl/"
       },
       {
          "university": "Universidad Central",
          "website": "http://www.ucentral.cl/"
       },
       {
          "university": "Universidad Chileno Britanica de Cultura",
          "website": "http://www.ubritanica.cl/"
       },
       {
          "university": "Universidad de Aconcagua",
          "website": "http://www.uaconcagua.cl/"
       },
       {
          "university": "Universidad de Antofagasta",
          "website": "http://www.uantof.cl/"
       },
       {
          "university": "Universidad de Artes - Ciencias y Comunicación",
          "website": "http://www.uniacc.cl/"
       },
       {
          "university": "Universidad de Atacama",
          "website": "http://www.uda.cl/"
       },
       {
          "university": "Universidad de Chile",
          "website": "http://www.uchile.cl/"
       },
       {
          "university": "Universidad de Ciencias de la Informatica",
          "website": "http://www.ucinf.cl/"
       },
       {
          "university": "Universidad de Concepcion",
          "website": "http://www.udec.cl/"
       },
       {
          "university": "Universidad de La Frontera",
          "website": "http://www.ufro.cl/"
       },
       {
          "university": "Universidad de Las Américas",
          "website": "http://www.uamericas.cl/"
       },
       {
          "university": "Universidad de La Serena",
          "website": "http://www.userena.cl/"
       },
       {
          "university": "Universidad del Bío-Bío",
          "website": "http://www.ubiobio.cl/"
       },
       {
          "university": "Universidad del Desarrollo",
          "website": "http://www.udesarrollo.cl/"
       },
       {
          "university": "Universidad del Mar",
          "website": "http://www.udelmar.cl/"
       },
       {
          "university": "Universidad de Los Andes",
          "website": "http://www.uandes.cl/"
       },
       {
          "university": "Universidad de Los Lagos",
          "website": "http://www.ulagos.cl/"
       },
       {
          "university": "Universidad del Pacífico",
          "website": "http://www.upacifico.cl/"
       },
       {
          "university": "Universidad de Magallanes",
          "website": "http://www.umag.cl/"
       },
       {
          "university": "Universidad de Playa Ancha de Ciencias de la Educacion",
          "website": "http://www.upa.cl/"
       },
       {
          "university": "Universidad de San Andres",
          "website": "http://www.usanandres.cl/"
       },
       {
          "university": "Universidad de Santiago de Chile",
          "website": "http://www.usach.cl/"
       },
       {
          "university": "Universidad de Talca",
          "website": "http://www.utalca.cl/"
       },
       {
          "university": "Universidad de Tarapacá",
          "website": "http://www.uta.cl/"
       },
       {
          "university": "Universidad de Valparaiso",
          "website": "http://www.uv.cl/"
       },
       {
          "university": "Universidad de Viña del Mar",
          "website": "http://www.uvm.cl/"
       },
       {
          "university": "Universidad Diego Portales",
          "website": "http://www.udp.cl/"
       },
       {
          "university": "Universidad Finis Terrae",
          "website": "http://www.ufinis.cl/"
       },
       {
          "university": "Universidad Francisco de Aguirre",
          "website": "http://www.unfa.cl/"
       },
       {
          "university": "Universidad Gabriela Mistral",
          "website": "http://www.ugm.cl/"
       },
       {
          "university": "Universidad Iberoamericana de Ciencias y Tecnologia",
          "website": "http://www.unicit.cl/"
       },
       {
          "university": "Universidad International SEK - Santiago",
          "website": "http://www.uisek.cl/"
       },
       {
          "university": "Universidad José Santos Ossa",
          "website": "http://www.ujso.cl/"
       },
       {
          "university": "Universidad La Republica",
          "website": "http://www.ulare.cl/"
       },
       {
          "university": "Universidad Mariano Egaña",
          "website": "http://www.ume.cl/"
       },
       {
          "university": "Universidad Maritima de Chile",
          "website": "http://www.umaritima.cl/"
       },
       {
          "university": "Universidad Mayor",
          "website": "http://www.umayor.cl/"
       },
       {
          "university": "Universidad Metropolitana de Ciencias de la Educación",
          "website": "http://www.umce.cl/"
       },
       {
          "university": "Universidad Miguel de Cervantes",
          "website": "http://www.ucervantes.cl/"
       },
       {
          "university": "Universidad Nacional Andrés Bello",
          "website": "http://www.unab.cl/"
       },
       {
          "university": "Universidad San Sebastian",
          "website": "http://www.uss.cl/"
       },
       {
          "university": "Universidad Santo Tomás",
          "website": "http://www.ust.cl/"
       },
       {
          "university": "Universidad Técnica Federico Santa María",
          "website": "http://www.utfsm.cl/"
       },
       {
          "university": "Universidad Tecnológica Metropolitana",
          "website": "http://www.utem.cl/"
       },
       {
          "university": "Universidad Tecnologica Vicente Perez Rosales",
          "website": "http://www.uvipro.cl/"
       }
    ],
    "CM": [
       {
          "university": "Bamenda University of Science & Technology",
          "website": "http://www.bamendauniversity.com/"
       },
       {
          "university": "Fomic Polytechnic",
          "website": "http://www.fomicgroup.cm/"
       },
       {
          "university": "Université de Bamenda",
          "website": "http://www.unibda.net/"
       },
       {
          "university": "Université de Buéa",
          "website": "http://www.ubuea.cm/"
       },
       {
          "university": "Université de Douala",
          "website": "http://www.univ-douala.com/"
       },
       {
          "university": "Université de Dschang",
          "website": "http://www.univ-dschang.org/"
       },
       {
          "university": "Université de Ngaoundéré",
          "website": "http://www.univ-ndere.cm/"
       },
       {
          "university": "Université des Montagnes",
          "website": "http://www.udesmontagnes.org/"
       },
       {
          "university": "Université de Yaoundé I",
          "website": "http://www.uy1.uninet.cm/"
       },
       {
          "university": "Université de Yaoundé II",
          "website": "http://www.universite-yde2.org/"
       }
    ],
    "CN": [
       {
          "university": "2nd Military Medical University",
          "website": "http://www.smmu.edu.cn/"
       },
       {
          "university": "3rd Military Medical University",
          "website": "http://www.tmmu.edu.cn/"
       },
       {
          "university": "4th Military Medical University",
          "website": "http://www.fmmu.edu.cn/"
       },
       {
          "university": "Anhui Medical University",
          "website": "http://www.ahmu.edu.cn/"
       },
       {
          "university": "Anhui Normal University",
          "website": "http://www.ahnu.edu.cn/"
       },
       {
          "university": "Anhui Technical College of Water Resources and Hydroelectric Power",
          "website": "http://www.ahsdxy.ah.edu.cn/"
       },
       {
          "university": "Anhui University",
          "website": "http://www.ahu.edu.cn/"
       },
       {
          "university": "Anhui University of Finance and Economics",
          "website": "http://www.aufe.edu.cn/"
       },
       {
          "university": "Anhui University of Traditional Chinese Medicine",
          "website": "http://www.ahtcm.edu.cn/"
       },
       {
          "university": "Beijing Foreign Studies University",
          "website": "http://www.bfsu.edu.cn/"
       },
       {
          "university": "Beijing Forestry University",
          "website": "http://www.bjfu.edu.cn/"
       },
       {
          "university": "Beijing Information Science and Technology University",
          "website": "http://www.biti.edu.cn/"
       },
       {
          "university": "Beijing Institute of Technology",
          "website": "http://www.bit.edu.cn/"
       },
       {
          "university": "Beijing International Studies University",
          "website": "http://www.bisu.edu.cn/"
       },
       {
          "university": "Beijing Language and Culture University",
          "website": "http://www.blcu.edu.cn/"
       },
       {
          "university": "Beijing Medical University",
          "website": "http://www.bjmu.edu.cn/"
       },
       {
          "university": "Beijing New Asia University",
          "website": "http://www.newasia.bj.edu.cn/"
       },
       {
          "university": "Beijing Normal University",
          "website": "http://www.bnu.edu.cn/"
       },
       {
          "university": "Beijing Petroleum University",
          "website": "http://www.bjpeu.edu.cn/"
       },
       {
          "university": "Beijing Polytechnic University",
          "website": "http://www.bjpu.edu.cn/"
       },
       {
          "university": "Beijing Sport University",
          "website": "http://www.bsu.edu.cn/"
       },
       {
          "university": "Beijing Union University",
          "website": "http://www.ipdbuu.com.cn/"
       },
       {
          "university": "Beijing University of Aeronautics and Astronautics",
          "website": "http://www.buaa.edu.cn/"
       },
       {
          "university": "Beijing University of Agriculture",
          "website": "http://www.bac.edu.cn/"
       },
       {
          "university": "Beijing University of Chemical Technology",
          "website": "http://www.buct.edu.cn/"
       },
       {
          "university": "Beijing University of Chinese Medicine and Pharmacology",
          "website": "http://www.bjucmp.edu.cn/"
       },
       {
          "university": "Beijing University of Posts and Telecommunications",
          "website": "http://www.bupt.edu.cn/"
       },
       {
          "university": "Beijing University of Science and Technology",
          "website": "http://www.ustb.edu.cn/"
       },
       {
          "university": "Bohai University",
          "website": "http://www.bhu.edu.cn/"
       },
       {
          "university": "Buddhist Acamedy of China",
          "website": "http://www.zgfxy.cn/"
       },
       {
          "university": "Capital Normal University",
          "website": "http://www.cnu.edu.cn/"
       },
       {
          "university": "Capital University of Economics and Business",
          "website": "http://www.cueb.edu.cn/"
       },
       {
          "university": "Capital University of Medical Sciences",
          "website": "http://www.cpums.edu.cn/"
       },
       {
          "university": "Central Academy of  Fine Art",
          "website": "http://www.cafa.edu.cn/"
       },
       {
          "university": "Central China Normal University",
          "website": "http://www.ccnu.edu.cn/"
       },
       {
          "university": "Central Radio and TV University",
          "website": "http://www.crtvu.edu.cn/"
       },
       {
          "university": "Central South Forestry University",
          "website": "http://www.csfu.edu.cn/"
       },
       {
          "university": "Central South University",
          "website": "http://www.csu.edu.cn/"
       },
       {
          "university": "Central University for Nationalities",
          "website": "http://www.cun.edu.cn/"
       },
       {
          "university": "Central University of Finance and Economics",
          "website": "http://www.cufe.edu.cn/"
       },
       {
          "university": "Changchun Teachers College",
          "website": "http://www.cncnc.edu.cn/"
       },
       {
          "university": "Changchun University of Technology",
          "website": "http://www.ccut.edu.cn/"
       },
       {
          "university": "Changsha Railway University",
          "website": "http://www.csru.edu.cn/"
       },
       {
          "university": "Changsha University of Electric Power",
          "website": "http://www.csuep.edu.cn/"
       },
       {
          "university": "Chengdu Institute of Sichuan International Studies University",
          "website": "http://www.cisisu.edu.cn/"
       },
       {
          "university": "Chengdu University",
          "website": "http://www.cdu.edu.cn/"
       },
       {
          "university": "Chengdu University of Technology",
          "website": "http://www.cdut.edu.cn/"
       },
       {
          "university": "Chengdu University of Traditional Chinese Medicine",
          "website": "http://www.cdutcm.edu.cn/"
       },
       {
          "university": "China Academy of Art",
          "website": "http://www.chinaacademyofart.com/"
       },
       {
          "university": "China Agricultural University",
          "website": "http://www.cau.edu.cn/"
       },
       {
          "university": "China Agriculture University East",
          "website": "http://www.bjaeu.edu.cn/"
       },
       {
          "university": "China Foreign Affairs University",
          "website": "http://www.cfau.edu.cn/"
       },
       {
          "university": "China Medical University Shenyang",
          "website": "http://www.cmu.edu.cn/"
       },
       {
          "university": "China Pharmaceutical University Nanjing",
          "website": "http://www.cpu.edu.cn/"
       },
       {
          "university": "China Three Gorges University",
          "website": "http://www.ctgu.edu.cn/"
       },
       {
          "university": "China University of Geoscience Beijing",
          "website": "http://www.cugb.edu.cn/"
       },
       {
          "university": "China University of Geosciences Wuhan",
          "website": "http://www.cug.edu.cn/"
       },
       {
          "university": "China University of Mining Technology - Beijing",
          "website": "http://www.cumtb.edu.cn/"
       },
       {
          "university": "China University of Mining Technology - Xuzhou",
          "website": "http://www.cumt.edu.cn/"
       },
       {
          "university": "China University of Political Science and Law",
          "website": "http://www.cupl.edu.cn/"
       },
       {
          "university": "China USA Business University",
          "website": "http://www.cubu.edu/"
       },
       {
          "university": "China youth college for political science",
          "website": "http://www.cyc.edu.cn/"
       },
       {
          "university": "Chinese People's Public Security University",
          "website": "http://www.cppsu.edu.cn/"
       },
       {
          "university": "Chongqing Education College",
          "website": "http://www.cqec.net.cn/"
       },
       {
          "university": "Chongqing Medical University",
          "website": "http://www.cqmu.edu.cn/"
       },
       {
          "university": "Chongqing Normal University",
          "website": "http://www.cqnu.edu.cn/"
       },
       {
          "university": "Chongqing Normal University Foreign Trade and Business College",
          "website": "http://www.swsm.cn/"
       },
       {
          "university": "Chongqing Technology and Business University",
          "website": "http://www.ctbu.edu.cn/"
       },
       {
          "university": "Chongqing Telecommunication College",
          "website": "http://www.cqtcedu.com/"
       },
       {
          "university": "Chongqing Three Gorges University",
          "website": "http://www.sanxiau.edu.cn/"
       },
       {
          "university": "Chongqing University",
          "website": "http://www.cqu.edu.cn/"
       },
       {
          "university": "Chongqing University of Communications",
          "website": "http://www.cquc.edu.cn/"
       },
       {
          "university": "Chongqing University of Post and Telecommunications",
          "website": "http://www.cqupt.edu.cn/"
       },
       {
          "university": "Chongqing University of Science and Technology ",
          "website": "http://www.cqust.cn/"
       },
       {
          "university": "Chongqing University of Technology",
          "website": "http://www.cqut.edu.cn/"
       },
       {
          "university": "Chongqing Vocational College of Public Transportation",
          "website": "http://www.cqgyzy.com/"
       },
       {
          "university": "Chongqing Wenli University",
          "website": "http://www.cqwu.net/"
       },
       {
          "university": "Civil Aviation University of China",
          "website": "http://www.cauc.edu/"
       },
       {
          "university": "Communication University of China",
          "website": "http://www.cuc.edu.cn/"
       },
       {
          "university": "Dalian Martime University",
          "website": "http://www.dlmu.edu.cn/"
       },
       {
          "university": "Dalian Medical University",
          "website": "http://www.dlmedu.edu.cn/"
       },
       {
          "university": "Dalian Polytechnic University",
          "website": "http://www.dlpu.edu.cn/"
       },
       {
          "university": "Dalian University",
          "website": "http://www.dlu.edu.cn/"
       },
       {
          "university": "Dalian University of Foreign Language",
          "website": "http://www.dlufl.edu.cn/"
       },
       {
          "university": "Dalian University of Technology",
          "website": "http://www.dlut.edu.cn/"
       },
       {
          "university": "Dongbei University of Finance And Economics",
          "website": "http://www.dufe.edu.cn/"
       },
       {
          "university": "Donghua University - Shanghai",
          "website": "http://www.dhu.edu.cn/"
       },
       {
          "university": "East China Jiao Tong University",
          "website": "http://www.ecjtu.jx.cn/"
       },
       {
          "university": "East China Normal University",
          "website": "http://www.ecnu.edu.cn/"
       },
       {
          "university": "East China University of Science and Technology",
          "website": "http://www.ecust.edu.cn/"
       },
       {
          "university": "Emeishan Buddhist College",
          "website": "http://www.emsfj.com/"
       },
       {
          "university": "Foshan University",
          "website": "http://www.fosu.edu.cn/"
       },
       {
          "university": "Fudan University",
          "website": "http://www.fudan.edu.cn/"
       },
       {
          "university": "Fujian Agricultural University",
          "website": "http://www.fjau.edu.cn/"
       },
       {
          "university": "Fujian Medical University",
          "website": "http://www.fjmu.edu.cn/"
       },
       {
          "university": "Fujian Normal University",
          "website": "http://www.fjnu.edu.cn/"
       },
       {
          "university": "Fujian University of Traditional Chinese Medicine",
          "website": "http://www.fjtcm.edu.cn/"
       },
       {
          "university": "Fushun Petroleum University",
          "website": "http://www.fspu.edu.cn/"
       },
       {
          "university": "Fuzhou University",
          "website": "http://www.fzu.edu.cn/"
       },
       {
          "university": "Gansu Agricultural University",
          "website": "http://www.gsau.edu.cn/"
       },
       {
          "university": "Gansu University of Technology",
          "website": "http://www.gsut.edu.cn/"
       },
       {
          "university": "Guangdong Peizheng College ",
          "website": "http://www.peizheng.com.cn/"
       },
       {
          "university": "Guangdong Polytechnic Normal University",
          "website": "http://www.gdin.edu.cn/"
       },
       {
          "university": "Guangdong Radio & TV University",
          "website": "http://www.gdrtvu.edu.cn/"
       },
       {
          "university": "Guangdong University of Foreign Studies",
          "website": "http://www.gdufs.edu.cn/"
       },
       {
          "university": "Guangdong University of Technology",
          "website": "http://www.gdut.edu.cn/"
       },
       {
          "university": "Guangxi Medical University",
          "website": "http://www.gxmu.edu.cn/"
       },
       {
          "university": "Guangxi Normal University",
          "website": "http://www.gxnu.edu.cn/"
       },
       {
          "university": "Guangxi Traditional Chinese Medical University",
          "website": "http://www.gxtcmu.edu.cn/"
       },
       {
          "university": "Guangxi University",
          "website": "http://www.gxu.edu.cn/"
       },
       {
          "university": "Guangxi University for Nationalities",
          "website": "http://www.gxun.edu.cn/"
       },
       {
          "university": "Guangzhou Academy of Fine Art",
          "website": "http://www.gzarts.edu.cn/"
       },
       {
          "university": "Guangzhou Normal University",
          "website": "http://www.guangztc.edu.cn/"
       },
       {
          "university": "Guangzhou University",
          "website": "http://www.gzhu.edu.cn/"
       },
       {
          "university": "Guangzhou University of Traditional Chinese Medicine",
          "website": "http://www.gzhtcm.edu.cn/"
       },
       {
          "university": "Guizhou Normal University",
          "website": "http://www.gznu.edu.cn/"
       },
       {
          "university": "Guizhou University",
          "website": "http://www.gzu.edu.cn/"
       },
       {
          "university": "Hainan Normal University",
          "website": "http://www.hainnu.edu.cn/"
       },
       {
          "university": "Hainan University",
          "website": "http://www.hainu.edu.cn/"
       },
       {
          "university": "Harbin Engineering University",
          "website": "http://www.hrbeu.edu.cn/"
       },
       {
          "university": "Harbin Institute of Technology",
          "website": "http://www.hit.edu.cn/"
       },
       {
          "university": "Harbin Medical University",
          "website": "http://www.hrbmu.edu.cn/"
       },
       {
          "university": "Harbin Normal University",
          "website": "http://www.hrbnu.edu.cn/"
       },
       {
          "university": "Harbin University of Civil Engineering & Architecture",
          "website": "http://www.hrbucea.edu.cn/"
       },
       {
          "university": "Harbin University of Science and Technology",
          "website": "http://www.hrbust.edu.cn/"
       },
       {
          "university": "Hebei Academy of Fine Art",
          "website": "http://www.hbafa.com/"
       },
       {
          "university": "Hebei Agricultural University",
          "website": "http://www.hebau.edu.cn/"
       },
       {
          "university": "Hebei Medical University",
          "website": "http://www.hebmu.edu.cn/"
       },
       {
          "university": "Hebei Normal University",
          "website": "http://www.hebnu.edu.cn/"
       },
       {
          "university": "Hebei United University",
          "website": "http://www.hebeiuniteduniversity.com/"
       },
       {
          "university": "Hebei University",
          "website": "http://www.hbu.edu.cn/"
       },
       {
          "university": "Hebei University of Economics and Trade",
          "website": "http://www.heuet.edu.cn/"
       },
       {
          "university": "Hebei University of Science and Technology",
          "website": "http://www.hebust.edu.cn/"
       },
       {
          "university": "Hebei University of Technology",
          "website": "http://www.hebut.edu.cn/"
       },
       {
          "university": "Hefei University of Technology",
          "website": "http://www.hfut.edu.cn/"
       },
       {
          "university": "Hehai University",
          "website": "http://www.hhu.edu.cn/"
       },
       {
          "university": "Heilongjiang August 1st Reclamation University",
          "website": "http://www.hlaflru.edu.cn/"
       },
       {
          "university": "Heilongjiang Commercial University",
          "website": "http://www.hljcu.edu.cn/"
       },
       {
          "university": "Heilongjiang University",
          "website": "http://www.hlju.edu.cn/"
       },
       {
          "university": "Henan Agriculture University",
          "website": "http://www.henau.edu.cn/"
       },
       {
          "university": "Henan Buddhist College",
          "website": "http://www.hnfjxy.cn/"
       },
       {
          "university": "Henan Normal University",
          "website": "http://www.henannu.edu.cn/"
       },
       {
          "university": "Henan Univeristy",
          "website": "http://www.henu.edu.cn/"
       },
       {
          "university": "Hohai University Changzhou",
          "website": "http://www.hhuc.edu.cn/"
       },
       {
          "university": "Huaihai Institute of Technology",
          "website": "http://www.hhit.edu.cn/"
       },
       {
          "university": "Huaihua Medical College",
          "website": "http://www.yizhuan.com/"
       },
       {
          "university": "Huaihua Radio and Television University",
          "website": "http://www.hhrtu.com/"
       },
       {
          "university": "Huaihua University",
          "website": "http://www.hhu.edu.cn/"
       },
       {
          "university": "Huanghe Science & Technology University",
          "website": "http://www.hhstu.edu.cn/"
       },
       {
          "university": "Huaqiao University Quanzhuo",
          "website": "http://www.hqu.edu.cn/"
       },
       {
          "university": "Huazhong Agricultural University",
          "website": "http://www.hzau.edu.cn/"
       },
       {
          "university": "Huazhong University of Science and Technology",
          "website": "http://www.hust.edu.cn/"
       },
       {
          "university": "Hubei University",
          "website": "http://www.hubu.edu.cn/"
       },
       {
          "university": "Huizhou University",
          "website": "http://www.hzu.edu.cn/"
       },
       {
          "university": "Hunan Agricultural University",
          "website": "http://www.hunau.net/"
       },
       {
          "university": "Hunan Normal University",
          "website": "http://www.hunnu.edu.cn/"
       },
       {
          "university": "Hunan University",
          "website": "http://www.hunu.edu.cn/"
       },
       {
          "university": "Inner Mongolia Agricultural University",
          "website": "http://www.imau.edu.cn/"
       },
       {
          "university": "Inner Mongolia Normal University",
          "website": "http://www.imnu.edu.cn/"
       },
       {
          "university": "Inner Mongolia Polytechnic University",
          "website": "http://www.impu.edu.cn/"
       },
       {
          "university": "Inner Mongolia University",
          "website": "http://www.imu.edu.cn/"
       },
       {
          "university": "International Business University of Beijing",
          "website": "http://www.ibub.edu.cn/"
       },
       {
          "university": "Jiangsu University of Science and Technology",
          "website": "http://www.jsust.edu.cn/"
       },
       {
          "university": "Jiangxi Agricultural University",
          "website": "http://www.jxau.edu.cn/"
       },
       {
          "university": "Jiangxi Normal University",
          "website": "http://www.jxnu.edu.cn/"
       },
       {
          "university": "Jiangxi University of Finance and Economics",
          "website": "http://www.jxufe.edu.cn/"
       },
       {
          "university": "Jiangxi University of Traditional Chinese Medicine",
          "website": "http://www.jutcm.com/"
       },
       {
          "university": "Jiaying University",
          "website": "http://www.jyu.edu.cn/"
       },
       {
          "university": "Jilin Agricultural University",
          "website": "http://www.jlau.edu.cn/"
       },
       {
          "university": "Jilin University",
          "website": "http://www.jlu.edu.cn/"
       },
       {
          "university": "Jilin University of Technology",
          "website": "http://www.jut.edu.cn/"
       },
       {
          "university": "Jimei University",
          "website": "http://www.jmu.edu.cn/"
       },
       {
          "university": "Jinan University",
          "website": "http://www.jnu.edu.cn/"
       },
       {
          "university": "Jingdezhen China Institute",
          "website": "http://www.jci.edu.cn/"
       },
       {
          "university": "Jishou University",
          "website": "http://www.jsu.edu.cn/"
       },
       {
          "university": "Kunmimg University of Science and Technology",
          "website": "http://www.kmust.edu.cn/"
       },
       {
          "university": "Lanzhou University",
          "website": "http://www.lzu.edu.cn/"
       },
       {
          "university": "Liaocheng Teachers University",
          "website": "http://www.lctu.edu.cn/"
       },
       {
          "university": "Liaoning Normal University",
          "website": "http://www.lnnu.edu.cn/"
       },
       {
          "university": "Liaoning Technical University",
          "website": "http://www.lntu.edu.cn/"
       },
       {
          "university": "Liaoning University",
          "website": "http://www.lnu.edu.cn/"
       },
       {
          "university": "Logistics Engineering University of PLA",
          "website": "http://www.hqgc.net/"
       },
       {
          "university": "Ludong University",
          "website": "http://www.ytnc.edu.cn/"
       },
       {
          "university": "Luxun Academy of Fine Art",
          "website": "http://www.lumei.edu.cn/"
       },
       {
          "university": "Luzhou Medical College",
          "website": "http://www.lzmc.edu.cn/"
       },
       {
          "university": "Nanchang University",
          "website": "http://www.ncu.edu.cn/"
       },
       {
          "university": "Nanjing Agricultural University",
          "website": "http://www.njau.edu.cn/"
       },
       {
          "university": "Nanjing Forestry University",
          "website": "http://www.njfu.edu.cn/"
       },
       {
          "university": "Nanjing Institute of Meteorology",
          "website": "http://www.njim.edu.cn/"
       },
       {
          "university": "Nanjing Medical University",
          "website": "http://www.njmu.edu.cn/"
       },
       {
          "university": "Nanjing Normal University",
          "website": "http://www.njnu.edu.cn/"
       },
       {
          "university": "Nanjing Union Theological Seminary",
          "website": "http://www.njuts.cn/"
       },
       {
          "university": "Nanjing University",
          "website": "http://www.nju.edu.cn/"
       },
       {
          "university": "Nanjing University of Aeronautics and Astronautics",
          "website": "http://www.nuaa.edu.cn/"
       },
       {
          "university": "Nanjing University of Chemical Technology",
          "website": "http://www.njuct.edu.cn/"
       },
       {
          "university": "Nanjing University of Economics",
          "website": "http://www.njue.edu.cn/"
       },
       {
          "university": "Nanjing University of Posts and Telecommunications",
          "website": "http://www.njupt.edu.cn/"
       },
       {
          "university": "Nanjing University of Science and Technology",
          "website": "http://www.njust.edu.cn/"
       },
       {
          "university": "Nanjing University of Traditional Chinese Medicine",
          "website": "http://www.njutcm.edu.cn/"
       },
       {
          "university": "Nankai University",
          "website": "http://nku.nankai.edu.cn/"
       },
       {
          "university": "National University of Defense Technology",
          "website": "http://www.nudt.edu.cn/"
       },
       {
          "university": "Neijiang Teacher University",
          "website": "http://www.neijtc.edu.cn/"
       },
       {
          "university": "Ningbo University",
          "website": "http://www.nbu.edu.cn/"
       },
       {
          "university": "Ningbo University of Technology",
          "website": "http://www.nbut.edu.cn/"
       },
       {
          "university": "Ningxia Medical College",
          "website": "http://www.nxmc.edu.cn/"
       },
       {
          "university": "Ningxia University",
          "website": "http://www.nxu.edu.cn/"
       },
       {
          "university": "North China Electric Power University",
          "website": "http://www.ncepubj.edu.cn/"
       },
       {
          "university": "North China University of Technology",
          "website": "http://www.ncut.edu.cn/"
       },
       {
          "university": "Northeast Agricultural University",
          "website": "http://www.neau.edu.cn/"
       },
       {
          "university": "Northeastern University",
          "website": "http://www.neu.edu.cn/"
       },
       {
          "university": "Northeast Forest University",
          "website": "http://www.nefu.edu.cn/"
       },
       {
          "university": "Northeast Normal University",
          "website": "http://www.nenu.edu.cn/"
       },
       {
          "university": "Northeast University at Qinhuangdao Campus",
          "website": "http://www.neuq.edu.cn/"
       },
       {
          "university": "Northern Jiaotong University",
          "website": "http://www.njtu.edu.cn/"
       },
       {
          "university": "Northern Sichuan Medical College",
          "website": "http://www.nsmc.edu.cn/"
       },
       {
          "university": "Northwest A&F University",
          "website": "http://www.nwau.edu.cn/"
       },
       {
          "university": "Northwest Normal University Lanzhou",
          "website": "http://www.nwnu.edu.cn/"
       },
       {
          "university": "Northwest Polytechnical University Xi'an",
          "website": "http://www.nwpu.edu.cn/"
       },
       {
          "university": "Northwest University Xi'an",
          "website": "http://www.nwu.edu.cn/"
       },
       {
          "university": "Ocean University of China",
          "website": "http://www.ouc.edu.cn/"
       },
       {
          "university": "Panzhihua University",
          "website": "http://www.pzhu.edu.cn/"
       },
       {
          "university": "Peking University",
          "website": "http://www.pku.edu.cn/"
       },
       {
          "university": "Qingdao University",
          "website": "http://www.qdu.edu.cn/"
       },
       {
          "university": "Qingdao University of Science and Technology",
          "website": "http://www.qust.edu.cn/"
       },
       {
          "university": "Qinghai Normal University",
          "website": "http://www.qhnu.edu.cn/"
       },
       {
          "university": "Qinghai Radio & Television University",
          "website": "http://www.qhrtvu.edu.cn/"
       },
       {
          "university": "Qinghai University",
          "website": "http://www.qhu.edu.cn/"
       },
       {
          "university": "Qiongzhou University",
          "website": "http://www.qzu.edu.cn/"
       },
       {
          "university": "Qufu Normal University",
          "website": "http://www.qfnu.edu.cn/"
       },
       {
          "university": "Renmin University of China",
          "website": "http://www.ruc.edu.cn/"
       },
       {
          "university": "Shaanxi Normal University",
          "website": "http://www.snnu.edu.cn/"
       },
       {
          "university": "Shandong Agricultural University",
          "website": "http://www.sdau.edu.cn/"
       },
       {
          "university": "Shandong Economic University",
          "website": "http://www.sdie.edu.cn/"
       },
       {
          "university": "Shandong Medical University",
          "website": "http://www.sdmu.edu.cn/"
       },
       {
          "university": "Shandong Normal University",
          "website": "http://www.sdnu.edu.cn/"
       },
       {
          "university": "Shandong University",
          "website": "http://www.sdu.edu.cn/"
       },
       {
          "university": "Shandong University of Art and Design",
          "website": "http://www.sdada.edu.cn/"
       },
       {
          "university": "Shandong University of Science and Technology",
          "website": "http://www.sdust.edu.cn/"
       },
       {
          "university": "Shandong University of Technology",
          "website": "http://www.sdut.edu.cn/"
       },
       {
          "university": "Shandong University of Triaditional Chinese Medicine",
          "website": "http://www.sdutcm.edu.cn/"
       },
       {
          "university": "Shanghai Business School",
          "website": "http://www.sbs.edu.cn/"
       },
       {
          "university": "Shanghai City College",
          "website": "http://www.umcollege.com/"
       },
       {
          "university": "Shanghai Customs College",
          "website": "http://shanghai_edu.customs.gov.cn/"
       },
       {
          "university": "Shanghai Dainji University",
          "website": "http://www.sdju.edu.cn/"
       },
       {
          "university": "Shanghai Institue of Foreign Trade",
          "website": "http://www.shift.edu.cn/"
       },
       {
          "university": "Shanghai International Studies University",
          "website": "http://www.shisu.edu.cn/"
       },
       {
          "university": "Shanghai Jiaotong University",
          "website": "http://www.sjtu.edu.cn/"
       },
       {
          "university": "Shanghai Lida Polytechnic Institute",
          "website": "http://www.lidapoly.com/"
       },
       {
          "university": "Shanghai Lixin University of Commerce",
          "website": "http://www.lixin.edu.cn/"
       },
       {
          "university": "Shanghai Maritime University",
          "website": "http://www.shmtu.edu.cn/"
       },
       {
          "university": "Shanghai Medical University",
          "website": "http://www.shmu.edu.cn/"
       },
       {
          "university": "Shanghai Normal University",
          "website": "http://www.shtu.edu.cn/"
       },
       {
          "university": "Shanghai Ocean University",
          "website": "http://www.shou.edu.cn/"
       },
       {
          "university": "Shanghai Ouhua Vocational Technical College",
          "website": "http://www.shouhua.net.cn/"
       },
       {
          "university": "Shanghai Sanda University",
          "website": "http://www.sandau.edu.cn/"
       },
       {
          "university": "Shanghai Second Medical University",
          "website": "http://www.shsmu.edu.cn/"
       },
       {
          "university": "Shanghai Second Polytechnic University",
          "website": "http://www.shspu.edu.cn/"
       },
       {
          "university": "Shanghai Sipo Polytechnic",
          "website": "http://www.sh-sipopolytechnic.com/"
       },
       {
          "university": "Shanghai Television University",
          "website": "http://www.shtvu.edu.cn/"
       },
       {
          "university": "Shanghai TieDao University",
          "website": "http://www.shtdu.edu.cn/"
       },
       {
          "university": "Shanghai University",
          "website": "http://www.shu.edu.cn/"
       },
       {
          "university": "Shanghai University of Engineering Science",
          "website": "http://www.sues.edu.cn/"
       },
       {
          "university": "Shanghai University of Finance and Economics",
          "website": "http://www.shufe.edu.cn/"
       },
       {
          "university": "Shanghai University of Science and Technology",
          "website": "http://www.usst.edu.cn/"
       },
       {
          "university": "Shanghai University of Traditional Chinese Medicine and Pharmacology",
          "website": "http://www.shutcm.edu.cn/"
       },
       {
          "university": "Shantou University",
          "website": "http://www.stu.edu.cn/"
       },
       {
          "university": "Shanxi Agricultural University",
          "website": "http://www.sxau.edu.cn/"
       },
       {
          "university": "Shanxi Normal University",
          "website": "http://www.snnu.edu.cn/"
       },
       {
          "university": "Shanxi University",
          "website": "http://www.sxu.edu.cn/"
       },
       {
          "university": "Shaoguan University",
          "website": "http://www.sgu.edu.cn/"
       },
       {
          "university": "Shenyang Institute of Chemical Technology",
          "website": "http://www.syict.edu.cn/"
       },
       {
          "university": "Shenyang Jianzhu University",
          "website": "http://www.sjzu.edu.cn/"
       },
       {
          "university": "Shenyang Pharmaceutical University",
          "website": "http://www.syphu.edu.cn/"
       },
       {
          "university": "Shenyang Polytechnic University",
          "website": "http://www.sypu.edu.cn/"
       },
       {
          "university": "Shenyang University",
          "website": "http://www.syu.edu.cn/"
       },
       {
          "university": "Shenzhen University",
          "website": "http://www.szu.edu.cn/"
       },
       {
          "university": "Shihezi University ",
          "website": "http://www.shzu.edu.cn/"
       },
       {
          "university": "Sichuan Agricultural University",
          "website": "http://www.sicau.edu.cn/"
       },
       {
          "university": "Sichuan Fine Art Institute",
          "website": "http://zsb.scfai.edu.cn/"
       },
       {
          "university": "Sichuan International Studies University",
          "website": "http://www.sisu.edu.cn/"
       },
       {
          "university": "Sichuan Normal University",
          "website": "http://www.sicnu.edu.cn/"
       },
       {
          "university": "Sichuan University",
          "website": "http://www.scu.edu.cn/"
       },
       {
          "university": "South China Agricultural University",
          "website": "http://www.scau.edu.cn/"
       },
       {
          "university": "South China Construction University",
          "website": "http://www.sccu.edu.cn/"
       },
       {
          "university": "South China Normal University",
          "website": "http://www.scnu.edu.cn/"
       },
       {
          "university": "South China University",
          "website": "http://www.usc.edu.cn/"
       },
       {
          "university": "South China University of Technology",
          "website": "http://www.scut.edu.cn/"
       },
       {
          "university": "Southeast University",
          "website": "http://www.seu.edu.cn/"
       },
       {
          "university": "Southern Medial University",
          "website": "http://www.fimmu.edu.cn/"
       },
       {
          "university": "Southern Yangtze University",
          "website": "http://www.sytu.edu.cn/"
       },
       {
          "university": "South University of Science and Technology of China ",
          "website": "http://www.sustc.edu.cn/"
       },
       {
          "university": "Southwest Agricultural University",
          "website": "http://www.swau.edu.cn/"
       },
       {
          "university": "Southwest Forestry University",
          "website": "http://www.swfc.edu.cn/"
       },
       {
          "university": "Southwest Jiaotong University",
          "website": "http://www.swjtu.edu.cn/"
       },
       {
          "university": "Southwest Petroleum University",
          "website": "http://www.swpu.edu.cn/"
       },
       {
          "university": "Southwest University",
          "website": "http://www.swnu.edu.cn/"
       },
       {
          "university": "Southwest University of Finance and Economics",
          "website": "http://www.swufe.edu.cn/"
       },
       {
          "university": "Southwest University of Nationalities",
          "website": "http://www.swun.edu.cn/"
       },
       {
          "university": "Southwest University of Political Science and Law",
          "website": "http://www.swupl.edu.cn/"
       },
       {
          "university": "Southwest University of Science and Technology",
          "website": "http://www.swust.edu.cn/"
       },
       {
          "university": "South West University Yucai College",
          "website": "http://www.swuyc.edu.cn/"
       },
       {
          "university": "Sun Yat-Sen University of Medical Sciences",
          "website": "http://www.gzsums.edu.cn/"
       },
       {
          "university": "Suzhou University",
          "website": "http://www.suda.edu.cn/"
       },
       {
          "university": "Taiyuan University of Technology",
          "website": "http://www.tyut.edu.cn/"
       },
       {
          "university": "Taizhou College",
          "website": "http://www.tzc.edu.cn/"
       },
       {
          "university": "Tarim University",
          "website": "http://www.taru.edu.cn/"
       },
       {
          "university": "The University of Nottingham Ningbo China",
          "website": "http://www.nottingham.edu.cn/"
       },
       {
          "university": "Tianjin Academy of Fine Art",
          "website": "http://www.tjarts.edu.cn/"
       },
       {
          "university": "Tianjin Medical University",
          "website": "http://www.tijmu.edu.cn/"
       },
       {
          "university": "Tianjin Normal University",
          "website": "http://www.tjnu.edu.cn/"
       },
       {
          "university": "Tianjin Open University",
          "website": "http://www.tjrtvu.edu.cn/"
       },
       {
          "university": "Tianjin Polytechnic University",
          "website": "http://www.tjpu.edu.cn/"
       },
       {
          "university": "Tianjin University",
          "website": "http://www.tju.edu.cn/"
       },
       {
          "university": "Tianjin University of Commerce",
          "website": "http://www.tjcu.edu.cn/"
       },
       {
          "university": "Tianjin University of Finance & Economics",
          "website": "http://www.tjufe.edu.cn/"
       },
       {
          "university": "Tianjin University of Technology",
          "website": "http://www.tjut.edu.cn/"
       },
       {
          "university": "Tibet Agricultural and Animal Husbandry College",
          "website": "http://www.xza.cn/"
       },
       {
          "university": "Tibet Tibetan Medical College",
          "website": "http://www.ttmc.edu.cn/"
       },
       {
          "university": "Tibet University",
          "website": "http://www.utibet.edu.cn/"
       },
       {
          "university": "Tibet University of Nationalities",
          "website": "http://www.xzmy.edu.cn/"
       },
       {
          "university": "Tongji Medical University",
          "website": "http://www.tjmu.edu.cn/"
       },
       {
          "university": "Tongji University",
          "website": "http://www.tongji.edu.cn/"
       },
       {
          "university": "Tsinghua University",
          "website": "http://www.tsinghua.edu.cn/"
       },
       {
          "university": "University of Electronic Science and Technology of China",
          "website": "http://www.uestc.edu.cn/"
       },
       {
          "university": "University of Hydraulic Electric Engineering",
          "website": "http://www.uheeyc.edu.cn/"
       },
       {
          "university": "University of International Business and Economics",
          "website": "http://www.uibe.edu.cn/"
       },
       {
          "university": "University of Jinan",
          "website": "http://www.ujn.edu.cn/"
       },
       {
          "university": "University of Petroleum (East China)",
          "website": "http://www.hdpu.edu.cn/"
       },
       {
          "university": "University of Science and Technology Beijing",
          "website": "http://www.ustb.edu.cn/"
       },
       {
          "university": "University of Science and Technology of China",
          "website": "http://www.ustc.edu.cn/"
       },
       {
          "university": "Urumqi Vocational University",
          "website": "http://www.uvu.edu.cn/"
       },
       {
          "university": "Wenzhou Medical College",
          "website": "http://www.wzmu.net/"
       },
       {
          "university": "Wenzhou University",
          "website": "http://www.wzu.edu.cn/"
       },
       {
          "university": "West China University of Medical Sciences",
          "website": "http://www.wcums.edu.cn/"
       },
       {
          "university": "Wudanshan Taoist College ",
          "website": "http://www.wdsdjxy.com/"
       },
       {
          "university": "Wuhan Automobile Polytechnical University",
          "website": "http://www.whapu.edu.cn/"
       },
       {
          "university": "Wuhan Technical University of Surveying and Mapping",
          "website": "http://www.wtusm.edu.cn/"
       },
       {
          "university": "Wuhan Transportation University",
          "website": "http://www.whtu.edu.cn/"
       },
       {
          "university": "Wuhan University",
          "website": "http://www.whu.edu.cn/"
       },
       {
          "university": "Wuhan University of Hydraulic and Electric Engineering",
          "website": "http://www.wuhee.edu.cn/"
       },
       {
          "university": "Wuhan University of Technology",
          "website": "http://www.whut.edu.cn/"
       },
       {
          "university": "Wuhan University School of Medicine",
          "website": "http://wsm.whu.edu.cn/"
       },
       {
          "university": "Wuxi University of Light Industry",
          "website": "http://www.wxuli.edu.cn/"
       },
       {
          "university": "Wuyi University",
          "website": "http://www.wyu.edu.cn/"
       },
       {
          "university": "Xiamen University",
          "website": "http://www.xmu.edu.cn/"
       },
       {
          "university": "Xi'an Academy of Fine Art",
          "website": "http://www.xafa.edu.cn/"
       },
       {
          "university": "Xiangtan Normal University",
          "website": "http://www.xtnu.edu.cn/"
       },
       {
          "university": "Xiangtan University",
          "website": "http://www.xtu.edu.cn/"
       },
       {
          "university": "Xi'an Highway University",
          "website": "http://www.xahu.edu.cn/"
       },
       {
          "university": "Xi'an International Studies University",
          "website": "http://www.xisu.edu.cn/"
       },
       {
          "university": "Xi'an Jiaotong-Liverpool University",
          "website": "http://www.xjtlu.edu.cn/"
       },
       {
          "university": "Xi'an Jiaotong University",
          "website": "http://www.xjtu.edu.cn/"
       },
       {
          "university": "Xi'an University of Architecture and Technology",
          "website": "http://www.xauat.edu.cn/"
       },
       {
          "university": "Xi'an University of Electronic Science and Technology",
          "website": "http://www.xidian.edu.cn/"
       },
       {
          "university": "Xi'an University of Technology",
          "website": "http://www.xaut.edu.cn/"
       },
       {
          "university": "Xihua University",
          "website": "http://www.xhu.edu.cn/"
       },
       {
          "university": "Xijiang University",
          "website": "http://www.xiju.edu.cn/"
       },
       {
          "university": "Xinghai Conservatory of Music",
          "website": "http://www.xhcom.edu.cn/"
       },
       {
          "university": "Xinjiang Agriculture University",
          "website": "http://www.xjau.edu.cn/"
       },
       {
          "university": "Xinjiang Normal University",
          "website": "http://www.xjnu.edu.cn/"
       },
       {
          "university": "Xinjiang University",
          "website": "http://www.xju.edu.cn/"
       },
       {
          "university": "Xinjiang University of Finance and Economics",
          "website": "http://www.xjufe.edu.cn/"
       },
       {
          "university": "Xuzhou Normal University",
          "website": "http://www.xznu.edu.cn/"
       },
       {
          "university": "Yan'an University",
          "website": "http://www.yau.edu.cn/"
       },
       {
          "university": "Yanbian University",
          "website": "http://www.ybu.edu.cn/"
       },
       {
          "university": "Yanbian University of Science and Technology",
          "website": "http://www.ybust.edu.cn/"
       },
       {
          "university": "Yangtze Normal University",
          "website": "http://www.yznu.cn/"
       },
       {
          "university": "Yangtze University",
          "website": "http://www.yangtzeu.edu.cn/"
       },
       {
          "university": "Yangzhou University",
          "website": "http://www.yzu.edu.cn/"
       },
       {
          "university": "Yan Shan University",
          "website": "http://www.ysu.edu.cn/"
       },
       {
          "university": "Yantai Education Institute & Yantai Television University",
          "website": "http://www.ytet.edu.cn/"
       },
       {
          "university": "Yantai University",
          "website": "http://www.ytu.edu.cn/"
       },
       {
          "university": "Yunnan Agriculture University",
          "website": "http://www.ynau.edu.cn/"
       },
       {
          "university": "Yunnan Normal University",
          "website": "http://www.ynnu.edu.cn/"
       },
       {
          "university": "Yunnan University",
          "website": "http://www.ynu.edu.cn/"
       },
       {
          "university": "Zhanjiang Ocean University",
          "website": "http://www.zjou.edu.cn/"
       },
       {
          "university": "Zhejiang Forestry University",
          "website": "http://www.zjfc.edu.cn/"
       },
       {
          "university": "Zhejiang Gongshang University",
          "website": "http://www.zjgsu.edu.cn/"
       },
       {
          "university": "Zhejiang Normal University",
          "website": "http://www.zjnu.edu.cn/"
       },
       {
          "university": "Zhejiang Sci-Tech University ",
          "website": "http://www.zstu.edu.cn/"
       },
       {
          "university": "Zhejiang University",
          "website": "http://www.zju.edu.cn/"
       },
       {
          "university": "Zhejiang University of Technology",
          "website": "http://www.zjut.edu.cn/"
       },
       {
          "university": "Zhengda Software College",
          "website": "http://www.zdsoft.com.cn/"
       },
       {
          "university": "Zhengzhou Grain University",
          "website": "http://www.zzgc.edu.cn/"
       },
       {
          "university": "Zhengzhou University of Technology",
          "website": "http://www.zzut.edu.cn/"
       },
       {
          "university": "Zhongnan University of Economics and Law",
          "website": "http://www.znuel.net/"
       },
       {
          "university": "Zhongnan University of Finance and Economics",
          "website": "http://www.znufe.edu.cn/"
       },
       {
          "university": "Zhongnan University of Technology",
          "website": "http://www.zhnut.edu.cn/"
       },
       {
          "university": "Zhongshan University",
          "website": "http://www.zsu.edu.cn/"
       }
    ],
    "CO": [
       {
          "university": "Centro de Estudios Investigación y Tecnología (CEIT)",
          "website": "http://www.iceit.edu.co/"
       },
       {
          "university": "Colegio de Estudios Superiores de Administración (CESA)",
          "website": "http://www.cesa.edu.co/"
       },
       {
          "university": "Conservatorio del Tolima",
          "website": "http://www.bundenet.com/umusical/"
       },
       {
          "university": "Corporación Educativa Mayor del Desarrollo Simón Bolivar",
          "website": "http://www.unisimonbolivar.edu.co/"
       },
       {
          "university": "Corporación Universitaria de Ibagué (CORUNIVERSITARIA)",
          "website": "http://www.cui.edu.co/"
       },
       {
          "university": "Corporación Universitaria de la Costa (UNICOSTA)",
          "website": "http://www.cuc.edu.co/"
       },
       {
          "university": "Corporación Universitaria de Santander (UDES)",
          "website": "http://www.udes.edu.co/"
       },
       {
          "university": "Corporación Universitaria Tecnológica de Bolivar",
          "website": "http://www.cutb.edu.co/"
       },
       {
          "university": "Escuela Colombiana de Carreras Industriales",
          "website": "http://www.ecci.edu.co/"
       },
       {
          "university": "Escuela Colombiana de Ingeniería Julio Garavito",
          "website": "http://www.escuelaing.edu.co/"
       },
       {
          "university": "Escuela de Administración de Negocios",
          "website": "http://www.ean.edu.co/"
       },
       {
          "university": "Escuela de Ingeniería de Antioquia",
          "website": "http://www.eia.edu.co/"
       },
       {
          "university": "Escuela de Policia General Santander",
          "website": "http://www.escueladepolicia.edu.co/"
       },
       {
          "university": "Escuela Superiore de Administración Pública",
          "website": "http://www.esap.edu.co/"
       },
       {
          "university": "Fundacion Escuela Colombiana de Rehabiliación",
          "website": "http://www.ecr.edu.co/"
       },
       {
          "university": "Fundación Universitaria de Boyacá",
          "website": "http://www.uniboyaca.edu.co/"
       },
       {
          "university": "Fundación Universitaria del Area Andina. Sede Pereira",
          "website": "http://www.funandi.edu.co/"
       },
       {
          "university": "Fundación Universitaria Luis Amigó",
          "website": "http://www.funlam.edu.co/"
       },
       {
          "university": "Fundación Universitaria Manuela Beltrán",
          "website": "http://www.umb.edu.co/"
       },
       {
          "university": "Fundación Universitaria San Martín",
          "website": "http://www.sanmartin.edu.co/"
       },
       {
          "university": "Institucion Universitaria de Envigado",
          "website": "http://www.iue.edu.co/"
       },
       {
          "university": "Institución Universitaria Iberoamericana",
          "website": "http://www.iberoamericana.edu.co/"
       },
       {
          "university": "Instituto Tecnológico Metropolitano",
          "website": "http://www.itm.edu.co/"
       },
       {
          "university": "Instituto Universitario de La Paz",
          "website": "http://www.unipaz.edu.co/"
       },
       {
          "university": "Politécnico Columbiano Jaime Isaza Cadavid",
          "website": "http://www.politecjic.edu.co/"
       },
       {
          "university": "Politécnico Grancolombiano - Institución Universitaria",
          "website": "http://www.poligran.edu.co/"
       },
       {
          "university": "Pontificia Universidad Javeriana",
          "website": "http://www.javeriana.edu.co/"
       },
       {
          "university": "Universidad Adventista de Colombia",
          "website": "http://www.unac.edu.co/"
       },
       {
          "university": "Universidad Antonio Nariño",
          "website": "http://www.uanarino.edu.co/"
       },
       {
          "university": "Universidad Autónoma de Bucaramanga",
          "website": "http://www.unab.edu.co/"
       },
       {
          "university": "Universidad Autónoma de Colombia",
          "website": "http://www.fuac.edu.co/"
       },
       {
          "university": "Universidad Autónoma de Las Américas",
          "website": "http://www.uam.edu.co/"
       },
       {
          "university": "Universidad Autónoma del Caribe",
          "website": "http://www.uac.edu.co/"
       },
       {
          "university": "Universidad Autónoma de Manizales",
          "website": "http://www.autonoma.edu.co/"
       },
       {
          "university": "Universidad Autónoma de Occidente",
          "website": "http://www.cuao.edu.co/"
       },
       {
          "university": "Universidad Autónoma Latinoamericana",
          "website": "http://www.unaula.edu.co/"
       },
       {
          "university": "Universidad Bellas Artes",
          "website": "http://www.bellasartes.edu.co/"
       },
       {
          "university": "Universidad Católica de Colombia",
          "website": "http://www.ucatolica.edu.co/"
       },
       {
          "university": "Universidad Católica del Norte",
          "website": "http://www.ucn.edu.co/"
       },
       {
          "university": "Universidad Católica del Oriente",
          "website": "http://www.uco.edu.co/"
       },
       {
          "university": "Universidad Católica de Manizales",
          "website": "http://www.ucatolicamz.edu.co/"
       },
       {
          "university": "Universidad Católica Popular del Risaralda",
          "website": "http://www.ucpr.edu.co/"
       },
       {
          "university": "Universidad Central",
          "website": "http://www.ucentral.edu.co/"
       },
       {
          "university": "Universidad Central del Valle del Cauca",
          "website": "http://www.uceva.edu.co/"
       },
       {
          "university": "Universidad CES",
          "website": "http://www.ces.edu.co/"
       },
       {
          "university": "Universidad Cooperativa de Colombia",
          "website": "http://www.ucc.edu.co/"
       },
       {
          "university": "Universidad de América Bogotá",
          "website": "http://www.uamerica.edu.co/"
       },
       {
          "university": "Universidad de Antioquia",
          "website": "http://www.udea.edu.co/"
       },
       {
          "university": "Universidad de Antioquía",
          "website": "http://www.udea.edu.co/"
       },
       {
          "university": "Universidad de Bogotá Jorge Tadeo Lozano",
          "website": "http://www.utadeo.edu.co/"
       },
       {
          "university": "Universidad de Caldas",
          "website": "http://www.ucaldas.edu.co/"
       },
       {
          "university": "Universidad de Cartagena",
          "website": "http://www.unicartagena.edu.co/"
       },
       {
          "university": "Universidad de Ciencias Aplicadas y Ambientales (UDCA)",
          "website": "http://www.udca.edu.co/"
       },
       {
          "university": "Universidad de Córdoba",
          "website": "http://www.unicordoba.edu.co/"
       },
       {
          "university": "Universidad de La Amazonia",
          "website": "http://www.uniamazonia.edu.co/"
       },
       {
          "university": "Universidad de La Guajira",
          "website": "http://www.uniguajira.edu.co/"
       },
       {
          "university": "Universidad de La Sabana",
          "website": "http://www.unisabana.edu.co/"
       },
       {
          "university": "Universidad de La Salle",
          "website": "http://www.lasalle.edu.co/"
       },
       {
          "university": "Universidad del Atlántico",
          "website": "http://www.uniatlantico.edu.co/"
       },
       {
          "university": "Universidad del Cauca",
          "website": "http://www.ucauca.edu.co/"
       },
       {
          "university": "Universidad del Magdalena",
          "website": "http://www.unimagdalena.edu.co/"
       },
       {
          "university": "Universidad del Norte",
          "website": "http://www.uninorte.edu.co/"
       },
       {
          "university": "Universidad de Los Andes",
          "website": "http://www.uniandes.edu.co/"
       },
       {
          "university": "Universidad de Los Llanos",
          "website": "http://www.unillanos.edu.co/"
       },
       {
          "university": "Universidad del Quindío",
          "website": "http://www.uniquindio.edu.co/"
       },
       {
          "university": "Universidad del Rosario",
          "website": "http://www.urosario.edu.co/"
       },
       {
          "university": "Universidad del Sinú",
          "website": "http://www.unisinucartagena.edu.co/"
       },
       {
          "university": "Universidad del Tolima",
          "website": "http://www.ut.edu.co/"
       },
       {
          "university": "Universidad del Valle del Cauca",
          "website": "http://www.univalle.edu.co/"
       },
       {
          "university": "Universidad de Manizales",
          "website": "http://www.umanizales.edu.co/"
       },
       {
          "university": "Universidad de Medellín",
          "website": "http://www.udem.edu.co/"
       },
       {
          "university": "Universidad de Nariño",
          "website": "http://www.udenar.edu.co/"
       },
       {
          "university": "Universidad de Pamplona",
          "website": "http://www.unipamplona.edu.co/"
       },
       {
          "university": "Universidad de San Buenaventura",
          "website": "http://www.usb.edu.co/"
       },
       {
          "university": "Universidad de San Buenaventura Medellin",
          "website": "http://www.usbmed.edu.co/"
       },
       {
          "university": "Universidad de Sucre",
          "website": "http://www.unisucre.edu.co/"
       },
       {
          "university": "Universidad Distral Francisco José de Caldas",
          "website": "http://www.udistrital.edu.co/"
       },
       {
          "university": "Universidad EAFIT",
          "website": "http://www.eafit.edu.co/"
       },
       {
          "university": "Universidad El Bosque",
          "website": "http://www.unbosque.edu.co/"
       },
       {
          "university": "Universidad Externado de Colombia",
          "website": "http://www.uexternado.edu.co/"
       },
       {
          "university": "Universidad Francisco de Paula Santander",
          "website": "http://www.ufps.edu.co/"
       },
       {
          "university": "Universidad ICESI",
          "website": "http://www.icesi.edu.co/"
       },
       {
          "university": "Universidad INCCA de Colombia",
          "website": "http://www.unincca.edu.co/"
       },
       {
          "university": "Universidad Industrial de Santander",
          "website": "http://www.uis.edu.co/"
       },
       {
          "university": "Universidad La Gran Colombia",
          "website": "http://www.ugrancolombia.edu.co/"
       },
       {
          "university": "Universidad Libre de Colombia",
          "website": "http://www.unilibre.edu.co/"
       },
       {
          "university": "Universidad Mariana",
          "website": "http://www.umariana.edu.co/"
       },
       {
          "university": "Universidad Metropolitana",
          "website": "http://www.unimetro.edu.co/"
       },
       {
          "university": "Universidad Militar Nueva Granada",
          "website": "http://www.umng.edu.co/"
       },
       {
          "university": "Universidad Nacional Abierta y a Distancia",
          "website": "http://www.unad.edu.co/"
       },
       {
          "university": "Universidad Nacional de Colombia",
          "website": "http://www.unal.edu.co/"
       },
       {
          "university": "Universidad Pedagogica Nacional",
          "website": "http://www.pedagogica.edu.co/"
       },
       {
          "university": "Universidad Pedagógica y Tecnológica de Colombia",
          "website": "http://www.uptc.edu.co/"
       },
       {
          "university": "Universidad Piloto de Colombia",
          "website": "http://www.unipiloto.edu.co/"
       },
       {
          "university": "Universidad Pontificia Bolivariana",
          "website": "http://www.upb.edu.co/"
       },
       {
          "university": "Universidad Popular del Cesar",
          "website": "http://www.unicesar.edu.co/"
       },
       {
          "university": "Universidad Santiago de Cali",
          "website": "http://www.usaca.edu.co/"
       },
       {
          "university": "Universidad Santo Tomás",
          "website": "http://www.usta.edu.co/"
       },
       {
          "university": "Universidad Sergio Arboleda",
          "website": "http://www.usergioarboleda.edu.co/"
       },
       {
          "university": "Universidad Surcolombiana",
          "website": "http://www.usurcolombia.com/"
       },
       {
          "university": "Universidad Tecnológica del Choco Diego Luis Cordoba",
          "website": "http://www.utch.edu.co/"
       },
       {
          "university": "Universidad Tecnológica de Pereira",
          "website": "http://www.utp.edu.co/"
       }
    ],
    "CR": [
       {
          "university": "Instituto Tecnológico de Costa Rica",
          "website": "http://www.itcr.ac.cr/"
       },
       {
          "university": "Universidad Adventista de Centro América",
          "website": "http://www.unadeca.ac.cr/"
       },
       {
          "university": "Universidad Autónoma de Centro América",
          "website": "http://www.uaca.ac.cr/"
       },
       {
          "university": "Universidad Autonoma Monterrey",
          "website": "http://www.unam.ac.cr/"
       },
       {
          "university": "Universidad Braulio Carrillo",
          "website": "http://www.universidadbrauliocarrillo.com/"
       },
       {
          "university": "Universidad Católica Anselmo Llorente",
          "website": "http://www.ucatolica.ac.cr/"
       },
       {
          "university": "Universidad Central",
          "website": "http://www.universidadcentral.com/"
       },
       {
          "university": "Universidad de Cartago Florencio del Castillo",
          "website": "http://www.uca.ac.cr/"
       },
       {
          "university": "Universidad de Costa Rica",
          "website": "http://www.ucr.ac.cr/"
       },
       {
          "university": "Universidad de Iberoamérica",
          "website": "http://www.unibe.ac.cr/"
       },
       {
          "university": "Universidad de La Salle",
          "website": "http://www.ulasalle.ac.cr/"
       },
       {
          "university": "Universidad del Turismo",
          "website": "http://www.utur.ac.cr/"
       },
       {
          "university": "Universidad de San José",
          "website": "http://www.universidadsanjosecr.com/"
       },
       {
          "university": "Universidad Empresarial",
          "website": "http://www.unem.edu/internacional/"
       },
       {
          "university": "Universidad Estatal a Distancia",
          "website": "http://www.uned.ac.cr/"
       },
       {
          "university": "Universidad Fidélitas",
          "website": "http://www.ufidelitas.ac.cr/"
       },
       {
          "university": "Universidad Fundepos Alma Mater",
          "website": "http://www.fundepos.ac.cr/"
       },
       {
          "university": "Universidad Hispanoamericana",
          "website": "http://www.uhispanoamericana.ac.cr/"
       },
       {
          "university": "Universidad Internacional de las Américas",
          "website": "http://www.uia.ac.cr/"
       },
       {
          "university": "Universidad Isaac Newton",
          "website": "http://www.isaacnewtoncr.com/"
       },
       {
          "university": "Universidad Latina de Costa Rica",
          "website": "http://www.ulatina.ac.cr/"
       },
       {
          "university": "Universidad Latinoamericana de Ciencia y Tecnología",
          "website": "http://www.ulacit.ac.cr/"
       },
       {
          "university": "Universidad Libre de Costa Rica",
          "website": "http://www.ulicori.ac.cr/"
       },
       {
          "university": "Universidad Metropolitana Castro Carazo",
          "website": "http://www.umca.net/"
       },
       {
          "university": "Universidad Nacional",
          "website": "http://www.una.ac.cr/"
       },
       {
          "university": "Universidad para la Cooperación Internacional",
          "website": "http://www.uci.ac.cr/"
       },
       {
          "university": "Universidad San Juan de la Cruz",
          "website": "http://www.universidadsanjuan.ac.cr/"
       },
       {
          "university": "Universidad San Juan de la Cruz",
          "website": "http://www.sjdlc.com/"
       },
       {
          "university": "Universidad San Marcos",
          "website": "http://www.usam.ac.cr/"
       },
       {
          "university": "Universidad Santa Paula",
          "website": "http://www.uspsantapaula.com/"
       },
       {
          "university": "Universidad Veritas",
          "website": "http://www.uveritas.ac.cr/"
       },
       {
          "university": "University for Peace",
          "website": "http://www.upeace.org/"
       }
    ],
    "CU": [
       {
          "university": "Instituto Superior Minero Metalúrgico Dr. Antonio Núñez Jiménez",
          "website": "http://www.ismm.edu.cu/"
       },
       {
          "university": "Instituto Superior Politécnico José Antonio Echeverría",
          "website": "http://www.cujae.edu.cu/"
       },
       {
          "university": "Universidad Central de Las Villas",
          "website": "http://www.uclv.edu.cu/"
       },
       {
          "university": "Universidad de Camagüey",
          "website": "http://www.reduc.edu.cu/"
       },
       {
          "university": "Universidad de Ciego de Avila",
          "website": "http://www.unica.cu/"
       },
       {
          "university": "Universidad de Cienfuegos",
          "website": "http://www.ucf.edu.cu/"
       },
       {
          "university": "Universidad de Granma",
          "website": "http://www.udg.co.cu/"
       },
       {
          "university": "Universidad de Holguín",
          "website": "http://www.uho.edu.cu/"
       },
       {
          "university": "Universidad de La Habana",
          "website": "http://www.uh.cu/"
       },
       {
          "university": "Universidad de Matanzas Camilo Cienfuegos",
          "website": "http://www.umcc.cu/"
       },
       {
          "university": "Universidad de Oriente",
          "website": "http://www.uo.edu.cu/"
       },
       {
          "university": "Universidad de Pinar del Río",
          "website": "http://www.upr.edu.cu/"
       },
       {
          "university": "Universidad Pedagógica José Martí - Camagüey",
          "website": "http://www.ispcmw.rimed.cu/"
       }
    ],
    "CV": [
       {
          "university": "Universidade Jean Piaget de Cabo Verde",
          "website": "http://cv.unipiaget.org/"
       }
    ],
    "CY": [
       {
          "university": "Americanos College",
          "website": "http://www.ac.ac.cy/"
       },
       {
          "university": "Cyprus College",
          "website": "http://www.cycollege.ac.cy/"
       },
       {
          "university": "Cyprus International Institute of Management (CIIM)",
          "website": "http://www.ciim.ac.cy/"
       },
       {
          "university": "Cyprus International University",
          "website": "http://www.ciu.edu.tr/"
       },
       {
          "university": "Cyprus University of Technology",
          "website": "http://www.cut.ac.cy/"
       },
       {
          "university": "Eastern Mediterranean University",
          "website": "http://www.emu.edu.tr/"
       },
       {
          "university": "European University Cyprus",
          "website": "http://www.euc.ac.cy/"
       },
       {
          "university": "European University of Lefke",
          "website": "http://www.lefke.edu.tr/"
       },
       {
          "university": "Frederick University",
          "website": "http://www.frederick.ac.cy/"
       },
       {
          "university": "Girne American University",
          "website": "http://www.gau.edu.tr/"
       },
       {
          "university": "Intercollege",
          "website": "http://www.intercollege.ac.cy/"
       },
       {
          "university": "Near East University",
          "website": "http://www.neu.edu.tr/"
       },
       {
          "university": "Open University of Cyprus",
          "website": "http://www.ouc.ac.cy/"
       },
       {
          "university": "The CTL Eurocollege",
          "website": "http://www.ctleuro.ac.cy/"
       },
       {
          "university": "University of Cyprus",
          "website": "http://www.ucy.ac.cy/"
       },
       {
          "university": "University of Nicosia",
          "website": "http://www.unic.ac.cy/"
       }
    ],
    "CZ": [
       {
          "university": "Academy of Performing Arts - Film and TV Fakulty",
          "website": "http://www.famu.cz/"
       },
       {
          "university": "Anglo-American University",
          "website": "http://www.aauni.edu/"
       },
       {
          "university": "Brno University of Technology",
          "website": "http://www.vutbr.cz/"
       },
       {
          "university": "Cevro Institut College",
          "website": "http://www.vsci.cz/"
       },
       {
          "university": "Charles University Prague",
          "website": "http://www.cuni.cz/"
       },
       {
          "university": "Czech Technical University of Prague",
          "website": "http://www.cvut.cz/"
       },
       {
          "university": "Czech University of Agriculture Prague",
          "website": "http://www.czu.cz/"
       },
       {
          "university": "Masaryk University",
          "website": "http://www.muni.cz/"
       },
       {
          "university": "Mendel University of Agriculture and Forestry",
          "website": "http://www.mendelu.cz/"
       },
       {
          "university": "Palacky University",
          "website": "http://www.upol.cz/"
       },
       {
          "university": "Prague College",
          "website": "http://www.praguecollege.cz/"
       },
       {
          "university": "Prague Institute of Chemical Technology",
          "website": "http://www.vscht.cz/"
       },
       {
          "university": "Prague International University",
          "website": "http://www.mpu-prague.cz/"
       },
       {
          "university": "Silesian University",
          "website": "http://www.slu.cz/"
       },
       {
          "university": "Technical University of Liberec",
          "website": "http://www.vslib.cz/"
       },
       {
          "university": "Technical University of Mining and Metallurgy Ostrava",
          "website": "http://www.vsb.cz/"
       },
       {
          "university": "Tiffin University Prague",
          "website": "http://www.tiffinprague.cz/"
       },
       {
          "university": "Tomas Bata University in Zlin",
          "website": "http://www.utb.cz/"
       },
       {
          "university": "University of Economics Prague",
          "website": "http://www.vse.cz/"
       },
       {
          "university": "University of Education Hradec Kralove",
          "website": "http://www.vsp.cz/"
       },
       {
          "university": "University of Jan Evangelista Purkyne",
          "website": "http://www.ujep.cz/"
       },
       {
          "university": "University of New York in Prague",
          "website": "http://www.unyp.cz/"
       },
       {
          "university": "University of Northern Virginia - Prague Campus",
          "website": "http://www.unva.cz/"
       },
       {
          "university": "University of Ostrava",
          "website": "http://www.osu.cz/"
       },
       {
          "university": "University of Pardubice",
          "website": "http://www.upce.cz/"
       },
       {
          "university": "University of South Bohemia",
          "website": "http://www.jcu.cz/"
       },
       {
          "university": "University of Veterinary and Pharmaceutical Science",
          "website": "http://www.vfu.cz/"
       },
       {
          "university": "University of West Bohemia",
          "website": "http://www.zcu.cz/"
       }
    ],
    "DE": [
       {
          "university": "AKAD Hochschulen für Berufstätige - Fachhochschule Leipzig",
          "website": "http://www.akad.de/"
       },
       {
          "university": "Albert-Ludwigs-Universität Freiburg",
          "website": "http://www.uni-freiburg.de/"
       },
       {
          "university": "Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin",
          "website": "http://www.asfh-berlin.de/"
       },
       {
          "university": "Augustana Hochschule Neuendettelsau",
          "website": "http://www.augustana.de/"
       },
       {
          "university": "Bauhaus Universität Weimar",
          "website": "http://www.uni-weimar.de/"
       },
       {
          "university": "Bayerische Julius-Maximilians-Universität Würzburg",
          "website": "http://www.uni-wuerzburg.de/"
       },
       {
          "university": "Bergische Universität Gesamthochschule Wuppertal",
          "website": "http://www.uni-wuppertal.de/"
       },
       {
          "university": "BiTS - Business and Information Technology School gGmbH",
          "website": "http://www.bits-iserlohn.de/"
       },
       {
          "university": "Brandenburgische Technische Universität Cottbus",
          "website": "http://www.tu-cottbus.de/"
       },
       {
          "university": "Carl von Ossietzky Universität Oldenburg",
          "website": "http://www.uni-oldenburg.de/"
       },
       {
          "university": "Christian-Albrechts-Universität Kiel",
          "website": "http://www.uni-kiel.de/"
       },
       {
          "university": "Cologne Business School",
          "website": "http://www.cbs.de/"
       },
       {
          "university": "Deutsche Hochschule für Verwaltungswissenschaften Speyer",
          "website": "http://www.dhv-speyer.de/"
       },
       {
          "university": "Deutsche Sporthochschule Köln",
          "website": "http://www.dshs-koeln.de/"
       },
       {
          "university": "Deutsche Telekom Fachhochschule Leipzig",
          "website": "http://www.fh-telekom-leipzig.de/"
       },
       {
          "university": "Deutsch-Ordens Fachhochschule Riedlingen - Hochschule für Wirtschaft",
          "website": "http://www.fh-riedlingen.de/"
       },
       {
          "university": "DIPLOMA-Fachhochschule Ölsnitz/Vogtland",
          "website": "http://www.diploma.de/"
       },
       {
          "university": "Duale Hochschule Baden-Württemberg",
          "website": "http://www.dhbw.de/"
       },
       {
          "university": "E.A.P. Europäische Wirtschaftshochschule Berlin",
          "website": "http://www.eap.net/"
       },
       {
          "university": "Eberhard-Karls-Universität Tübingen",
          "website": "http://www.uni-tuebingen.de/"
       },
       {
          "university": "Ernst-Moritz-Arndt Universität Greifswald",
          "website": "http://www.uni-greifswald.de/"
       },
       {
          "university": "Europa Fachhochschule Fresenius",
          "website": "http://www.fh-fresenius.de/"
       },
       {
          "university": "Europäische Betriebswirtschafts-Akademie",
          "website": "http://www.eba-muenchen.de/"
       },
       {
          "university": "Europäische Fachhochschule",
          "website": "http://www.eufh.de/"
       },
       {
          "university": "Europa-Universität Viadrina Frankfurt (Oder)",
          "website": "http://www.euv-frankfurt-o.de/"
       },
       {
          "university": "European Business School Schloß Reichartshausen",
          "website": "http://www.ebs.de/"
       },
       {
          "university": "European College of Liberal Arts",
          "website": "http://www.ecla.de/"
       },
       {
          "university": "European Management School",
          "website": "http://www.ems-mainz.de/"
       },
       {
          "university": "Evangelische Fachhochschule Berlin - Fachhochschule für Sozialarbeit und Sozialpädagogik",
          "website": "http://www.evfh-berlin.de/"
       },
       {
          "university": "Evangelische Fachhochschule Darmstadt",
          "website": "http://www.efh-darmstadt.de/"
       },
       {
          "university": "Evangelische Fachhochschule Freiburg - Hochschule für Soziale Arbeit - Diakonie und Religionspädagogik",
          "website": "http://www.efh-freiburg.de/"
       },
       {
          "university": "Evangelische Fachhochschule für Religionspädagogik - und Gemeindediakonie Moritzburg",
          "website": "http://www.fhs-moritzburg.de/"
       },
       {
          "university": "Evangelische Fachhochschule für Sozialpädagogik der Diakonenanstalt des Rauhen Hauses Hamburg",
          "website": "http://www.rauheshaus.de/fachhochschule/"
       },
       {
          "university": "Evangelische Fachhochschule Hannover",
          "website": "http://www.efh-hannover.de/"
       },
       {
          "university": "Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen",
          "website": "http://www.efhlu.de/"
       },
       {
          "university": "Evangelische Fachhochschule Nürnberg",
          "website": "http://www.evfh-nuernberg.de/"
       },
       {
          "university": "Evangelische Fachhochschule Reutlingen-Ludwigsburg - Hochschule für Soziale Arbeit - Religionspädagogik und Diakonie",
          "website": "http://www.efh-reutlingen-ludwigsburg.de/"
       },
       {
          "university": "Evangelische Fachhochschule Rheinland-Westfalen-Lippe",
          "website": "http://www.efh-bochum.de/"
       },
       {
          "university": "Evangelische Hochschule für Soziale Arbeit Dresden (FH)",
          "website": "http://www.ehs-dresden.de/"
       },
       {
          "university": "Fachhochschule Aachen",
          "website": "http://www.fh-aachen.de/"
       },
       {
          "university": "Fachhochschule Aschaffenburg",
          "website": "http://www.fh-aschaffenburg.de/"
       },
       {
          "university": "Fachhochschule Augsburg",
          "website": "http://www.fh-augsburg.de/"
       },
       {
          "university": "Fachhochschule Biberach - Hochschule für Bauwesen und Wirtschaft",
          "website": "http://www.fh-biberach.de/"
       },
       {
          "university": "Fachhochschule Bielefeld",
          "website": "http://www.fh-bielefeld.de/"
       },
       {
          "university": "Fachhochschule Bingen",
          "website": "http://www.fh-bingen.de/"
       },
       {
          "university": "Fachhochschule Bochum",
          "website": "http://www.fh-bochum.de/"
       },
       {
          "university": "Fachhochschule Bonn-Rhein-Sieg",
          "website": "http://www.fh-bonn-rhein-sieg.de/"
       },
       {
          "university": "Fachhochschule Brandenburg",
          "website": "http://www.fh-brandenburg.de/"
       },
       {
          "university": "Fachhochschule Braunschweig/Wolfenbüttel",
          "website": "http://www.fh-wolfenbuettel.de/"
       },
       {
          "university": "Fachhochschule Deggendorf",
          "website": "http://www.fh-deggendorf.de/"
       },
       {
          "university": "Fachhochschule der Wirtschaft",
          "website": "http://www.fhdw.de/"
       },
       {
          "university": "Fachhochschule des Mittelstandes (FHM)",
          "website": "http://www.fhm-mittelstand.de/"
       },
       {
          "university": "Fachhochschule Dortmund",
          "website": "http://www.fh-dortmund.de/"
       },
       {
          "university": "Fachhochschule Düsseldorf",
          "website": "http://www.fh-duesseldorf.de/"
       },
       {
          "university": "Fachhochschule Eberswalde",
          "website": "http://www.fh-eberswalde.de/"
       },
       {
          "university": "Fachhochschule Erfurt",
          "website": "http://www.fh-erfurt.de/"
       },
       {
          "university": "Fachhochschule Flensburg",
          "website": "http://www.fh-flensburg.de/"
       },
       {
          "university": "Fachhochschule Frankfurt am Main",
          "website": "http://www.fh-frankfurt.de/"
       },
       {
          "university": "Fachhochschule für das öffentliche Bibliothekswesen Bonn",
          "website": "http://www.fhoebb.de/"
       },
       {
          "university": "Fachhochschule für die Wirtschaft",
          "website": "http://www.fhdw.bib.de/"
       },
       {
          "university": "Fachhochschule für Oekonomie und Management (FOM)",
          "website": "http://www.fom.de/"
       },
       {
          "university": "Fachhochschule für Technik und Wirtschaft Berlin",
          "website": "http://www.fhtw-berlin.de/"
       },
       {
          "university": "Fachhochschule Furtwangen - Hochschule für Technik und Wirtschaft",
          "website": "http://www.fh-furtwangen.de/"
       },
       {
          "university": "Fachhochschule für Verwaltung und Rechtspflege Berlin",
          "website": "http://www.fhvr.berlin.de/"
       },
       {
          "university": "Fachhochschule für Wirtschaft Berlin",
          "website": "http://www.fhw-berlin.de/"
       },
       {
          "university": "Fachhochschule Gelsenkirchen",
          "website": "http://www.fh-gelsenkirchen.de/"
       },
       {
          "university": "Fachhochschule Gießen-Friedberg",
          "website": "http://www.fh-giessen.de/"
       },
       {
          "university": "Fachhochschule Hamburg",
          "website": "http://www.fh-hamburg.de/"
       },
       {
          "university": "Fachhochschule Hannover",
          "website": "http://www.fh-hannover.de/"
       },
       {
          "university": "Fachhochschule Heidelberg",
          "website": "http://www.fh-heidelberg.de/"
       },
       {
          "university": "Fachhochschule Heilbronn - Hochschule für Technik und Wirtschaft",
          "website": "http://www.fh-heilbronn.de/"
       },
       {
          "university": "Fachhochschule Hildesheim/Holzminden/Göttingen - Hochschule für angewandte Wissenschaft und Kunst",
          "website": "http://www.fh-hildesheim.de/"
       },
       {
          "university": "Fachhochschule Hof",
          "website": "http://www.fh-hof.de/"
       },
       {
          "university": "Fachhochschule Ingolstadt",
          "website": "http://www.fh-ingolstadt.de/"
       },
       {
          "university": "Fachhochschule Jena",
          "website": "http://www.fh-jena.de/"
       },
       {
          "university": "Fachhochschule Kaiserslautern",
          "website": "http://www.fh-kl.de/"
       },
       {
          "university": "Fachhochschule Kempten - Hochschule für Technik und Wirtschaft",
          "website": "http://www.fh-Kempten.de/"
       },
       {
          "university": "Fachhochschule Kiel",
          "website": "http://www.fh-kiel.de/"
       },
       {
          "university": "Fachhochschule Koblenz",
          "website": "http://www.fh-koblenz.de/"
       },
       {
          "university": "Fachhochschule Köln",
          "website": "http://www.fh-koeln.de/"
       },
       {
          "university": "Fachhochschule Konstanz - Hochschule für Technik - Wirtschaft und Gestaltung",
          "website": "http://www.fh-konstanz.de/"
       },
       {
          "university": "Fachhochschule Landshut - Hochschule für Wirtschaft - Sozialwesen - Technik",
          "website": "http://www.fh-landshut.de/"
       },
       {
          "university": "Fachhochschule Lausitz",
          "website": "http://www.fh-lausitz.de/"
       },
       {
          "university": "Fachhochschule Lippe",
          "website": "http://www.fh-lippe.de/"
       },
       {
          "university": "Fachhochschule Lübeck",
          "website": "http://www.fh-luebeck.de/"
       },
       {
          "university": "Fachhochschule Ludwigshafen - Hochschule für Wirtschaft",
          "website": "http://www.fh-ludwigshafen.de/"
       },
       {
          "university": "Fachhochschule Mainz",
          "website": "http://www.fh-mainz.de/"
       },
       {
          "university": "Fachhochschule Mannheim - Hochschule für Sozialwesen",
          "website": "http://www.fhs-mannheim.de/"
       },
       {
          "university": "Fachhochschule Mannheim - Hochschule für Technik und Gestaltung",
          "website": "http://www.fh-mannheim.de/"
       },
       {
          "university": "Fachhochschule Merseburg",
          "website": "http://www.fh-merseburg.de/"
       },
       {
          "university": "Fachhochschule München",
          "website": "http://www.fh-muenchen.de/"
       },
       {
          "university": "Fachhochschule Münster",
          "website": "http://www.fh-muenster.de/"
       },
       {
          "university": "Fachhochschule Neubrandenburg",
          "website": "http://www.fh-nb.de/"
       },
       {
          "university": "Fachhochschule Neu-Ulm",
          "website": "http://www.fh-neu-ulm.de/"
       },
       {
          "university": "Fachhochschule Niederrhein",
          "website": "http://www.fh-niederrhein.de/"
       },
       {
          "university": "Fachhochschule Nordhausen",
          "website": "http://www.fh-nordhausen.de/"
       },
       {
          "university": "Fachhochschule Nordhessen",
          "website": "http://www.diploma.de/"
       },
       {
          "university": "Fachhochschule Nordostniedersachsen",
          "website": "http://www.fhnon.de/"
       },
       {
          "university": "Fachhochschule Nürtingen - Hochschule für Wirtschaft - Landwirtschaft und Landespflege",
          "website": "http://www.fh-nuertingen.de/"
       },
       {
          "university": "Fachhochschule Offenburg - Hochschule für Technik und Wirtschaft",
          "website": "http://www.fh-offenburg.de/"
       },
       {
          "university": "Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven",
          "website": "http://www.fh-oow.de/"
       },
       {
          "university": "Fachhochschule Osnabrück",
          "website": "http://www.fh-osnabrueck.de/"
       },
       {
          "university": "Fachhochschule Pforzheim - Hochschule für Gestaltung - Technik und Wirtschaft",
          "website": "http://www.fh-pforzheim.de/"
       },
       {
          "university": "Fachhochschule Potsdam",
          "website": "http://www.fh-potsdam.de/"
       },
       {
          "university": "Fachhochschule Ravensburg-Weingarten",
          "website": "http://www.fh-weingarten.de/"
       },
       {
          "university": "Fachhochschule Regensburg",
          "website": "http://www.fh-regensburg.de/"
       },
       {
          "university": "Fachhochschule Reutlingen - Hochschule für Technik und Wirtschaft",
          "website": "http://www.fh-reutlingen.de/"
       },
       {
          "university": "Fachhochschule Rosenheim - Hochschule für Technik und Wirtschaft",
          "website": "http://www.fh-rosenheim.de/"
       },
       {
          "university": "Fachhochschule Rottenburg - Hochschule für Forstwirtschaft",
          "website": "http://www.fh-rottenburg.de/"
       },
       {
          "university": "Fachhochschule Schmalkalden",
          "website": "http://www.fh-schmalkalden.de/"
       },
       {
          "university": "Fachhochschule Schwäbisch Gmünd - Hochschule für Gestaltung",
          "website": "http://www.hfg-gmuend.de/"
       },
       {
          "university": "Fachhochschule Schwäbisch Hall - Hochschule für Gestaltung",
          "website": "http://www.fh-schwaebischhall.de/"
       },
       {
          "university": "Fachhochschule Stralsund",
          "website": "http://www.fh-stralsund.de/"
       },
       {
          "university": "Fachhochschule Stuttgart - Hochschule der Medien",
          "website": "http://www.hdm-stuttgart.de/"
       },
       {
          "university": "Fachhochschule Stuttgart - Hochschule für Technik",
          "website": "http://www.fht-stuttgart.de/"
       },
       {
          "university": "Fachhochschule Trier - Hochschule für Technik - Wirtschaft und Gestaltung",
          "website": "http://www.fh-trier.de/"
       },
       {
          "university": "Fachhochschule Ulm - Hochschule für Technik",
          "website": "http://www.fh-ulm.de/"
       },
       {
          "university": "Fachhochschule und Berufskollegs NTA - Prof.Dr. Grübler gemein. GmbH",
          "website": "http://www.fh-isny.de/"
       },
       {
          "university": "Fachhochschule Wedel",
          "website": "http://www.fh-wedel.de/"
       },
       {
          "university": "Fachhochschule Weihenstephan",
          "website": "http://www.fh-weihenstephan.de/"
       },
       {
          "university": "Fachhochschule Westküste - Hochschule für Wirtschaft und Technik",
          "website": "http://www.fh-westkueste.de/"
       },
       {
          "university": "Fachhochschule Wiesbaden",
          "website": "http://www.fh-wiesbaden.de/"
       },
       {
          "university": "Fachhochschule Worms",
          "website": "http://www.fh-worms.de/"
       },
       {
          "university": "Fachhochschule Würzburg - Schweinfurt",
          "website": "http://www.fh-wuerzburg.de/"
       },
       {
          "university": "Fern-Fachhochschule Hamburg",
          "website": "http://www.fern-fh.de/"
       },
       {
          "university": "Fernuniversität Gesamthochschule Hagen",
          "website": "http://www.fernuni-hagen.de/"
       },
       {
          "university": "Freie Kunst-Studienstätte Ottersberg",
          "website": "http://www.fh-ottersberg.de/"
       },
       {
          "university": "Freie Universität Berlin",
          "website": "http://www.fu-berlin.de/"
       },
       {
          "university": "Friedrich-Alexander Universität Erlangen-Nürnberg",
          "website": "http://www.uni-erlangen.de/"
       },
       {
          "university": "Friedrich-Schiller Universität Jena",
          "website": "http://www.uni-jena.de/"
       },
       {
          "university": "Georg-August Universität Göttingen",
          "website": "http://www.uni-goettingen.de/"
       },
       {
          "university": "Georg-Simon-Ohm-Fachhochschule Nürnberg",
          "website": "http://www.fh-nuernberg.de/"
       },
       {
          "university": "Gustav-Siewerth-Akademie",
          "website": "http://www.siewerth-akademie.de/"
       },
       {
          "university": "Hafencity Universität Hamburg",
          "website": "http://www.hcu-hamburg.de/"
       },
       {
          "university": "Handelshochschule Leipzig",
          "website": "http://www.hhl.de/"
       },
       {
          "university": "Heinrich-Heine Universität Düsseldorf",
          "website": "http://www.uni-duesseldorf.de/"
       },
       {
          "university": "Hertie School of Governance",
          "website": "http://www.hertie-school.org/"
       },
       {
          "university": "Hochschule Albstadt-Sigmaringen",
          "website": "http://www.hs-albsig.de/"
       },
       {
          "university": "Hochschule Anhalt (FH) - Hochschule für angewandte Wissenschaften",
          "website": "http://www.hs-anhalt.de/"
       },
       {
          "university": "Hochschule Bremen",
          "website": "http://www.hs-bremen.de/"
       },
       {
          "university": "Hochschule Bremerhaven",
          "website": "http://www.hs-bremerhaven.de/"
       },
       {
          "university": "Hochschule Coburg",
          "website": "http://www.hs-coburg.de/"
       },
       {
          "university": "Hochschule Darmstadt",
          "website": "http://www.hs-darmstadt.de/"
       },
       {
          "university": "Hochschule Esslingen",
          "website": "http://www.hs-esslingen.de/"
       },
       {
          "university": "Hochschule Fulda",
          "website": "http://www.hs-fulda.de/"
       },
       {
          "university": "Hochschule für Bankwirtschaft (HfB) - Private Fachhochschule der Bankakademie",
          "website": "http://www.hfb.de/"
       },
       {
          "university": "Hochschule für Berufstätige Rendsburg",
          "website": "http://www.akad.de/"
       },
       {
          "university": "Hochschule für Internationales Management",
          "website": "http://www.himh.de/"
       },
       {
          "university": "Hochschule für Jüdische Studien Heidelberg",
          "website": "http://www.hjs.uni-heidelberg.de/"
       },
       {
          "university": "Hochschule für Philosophie München",
          "website": "http://www.hfph.mwn.de/"
       },
       {
          "university": "Hochschule für Politik (HFP)",
          "website": "http://www.hfp.mhn.de/"
       },
       {
          "university": "Hochschule für Technik und Wirtschaft des Saarlandes",
          "website": "http://www.htw-saarland.de/"
       },
       {
          "university": "Hochschule für Technik und Wirtschaft Dresden (FH)",
          "website": "http://www.htw-dresden.de/"
       },
       {
          "university": "Hochschule für Technik und Wirtschaft Karlsruhe",
          "website": "http://www.hs-karlsruhe.de/"
       },
       {
          "university": "Hochschule für Technik - Wirtschaft und Kultur Leipzig (FH)",
          "website": "http://www.htwk-leipzig.de/"
       },
       {
          "university": "Hochschule Harz - Hochschule für angewandte Wissenschaften (FH)",
          "website": "http://www.hs-harz.de/"
       },
       {
          "university": "Hochschule Magdeburg-Stendal (FH)",
          "website": "http://www.hs-magdeburg.de/"
       },
       {
          "university": "Hochschule Mittweida (FH)",
          "website": "http://www.htwm.de/"
       },
       {
          "university": "Hochschule Vechta",
          "website": "http://www.uni-vechta.de/"
       },
       {
          "university": "Hochschule Wismar - Fachhochschule für Technik - Wirtschaft und Gestaltung",
          "website": "http://www.hs-wismar.de/"
       },
       {
          "university": "Hochschule Zittau/Görlitz (FH)",
          "website": "http://www.hs-zigr.de/"
       },
       {
          "university": "Humboldt Universität Berlin",
          "website": "http://www.hu-berlin.de/"
       },
       {
          "university": "HWP - Hamburger Universität für Wirtschaft und Politik",
          "website": "http://www.hwp-hamburg.de/"
       },
       {
          "university": "Internationale Fachhochschule Aalen",
          "website": "http://www.international.fh-aalen.de/"
       },
       {
          "university": "Internationale Fachhochschule Bad Honnef",
          "website": "http://www.fh-bad-honnef.de/"
       },
       {
          "university": "Internationales Hochschulinstitut Zittau",
          "website": "http://www.ihi-zittau.de/"
       },
       {
          "university": "International School of Management ISM Dortmund",
          "website": "http://www.ism-dortmund.de/"
       },
       {
          "university": "International School of New Media - University of Lübeck",
          "website": "http://www.isnm.de/"
       },
       {
          "university": "International University in Germany",
          "website": "http://www.i-u.de/"
       },
       {
          "university": "Jacobs University Bremen",
          "website": "http://www.jacobs-university.de/"
       },
       {
          "university": "Johannes-Gutenberg Universität Mainz",
          "website": "http://www.uni-mainz.de/"
       },
       {
          "university": "Johann Wolfgang Goethe Universität Frankfurt am Main",
          "website": "http://www.uni-frankfurt.de/"
       },
       {
          "university": "Justus Liebig Universität Gießen",
          "website": "http://www.uni-giessen.de/"
       },
       {
          "university": "Karlshochschule International University",
          "website": "http://www.karlshochschule.de/"
       },
       {
          "university": "Katholische Fachhochschule Berlin (KFB)",
          "website": "http://www.kfb-berlin.de/"
       },
       {
          "university": "Katholische Fachhochschule Freiburg - Hochschule für Sozialwesen - Religionspädagogik und Pflege",
          "website": "http://www.kfh-Freiburg.de/"
       },
       {
          "university": "Katholische Fachhochschule Mainz",
          "website": "http://www.kfh-mainz.de/"
       },
       {
          "university": "Katholische Fachhochschule Norddeutschland",
          "website": "http://www.kath-fh-nord.de/"
       },
       {
          "university": "Katholische Fachhochschule Nordrhein-Westfalen",
          "website": "http://www.kfhnw.de/"
       },
       {
          "university": "Katholische Hochschule für Soziale Arbeit Saarbrücken",
          "website": "http://www.khsa.de/"
       },
       {
          "university": "Katholische Stiftungsfachhochschule München",
          "website": "http://www.ksfh.de/"
       },
       {
          "university": "Katholische Universität Eichstätt",
          "website": "http://www.ku-eichstaett.de/"
       },
       {
          "university": "Kirchliche Hochschule Bethel",
          "website": "http://www.bethel.de/kiho/"
       },
       {
          "university": "Kirchliche Hochschule Wuppertal",
          "website": "http://www.uni-wuppertal.de/inst/kiho/"
       },
       {
          "university": "Kunstakademie Düsseldorf.",
          "website": "http://www.kunstakademie-duesseldorf.de/"
       },
       {
          "university": "Kunsthochschule Berlin-Weissensee - Hochschule für Gestaltung ",
          "website": "http://www.kh-berlin.de/"
       },
       {
          "university": "Ludwig-Maximilians-Universität München",
          "website": "http://www.uni-muenchen.de/"
       },
       {
          "university": "Lutherische Theologische Hochschule Oberursel",
          "website": "http://www.selk.de/LThH/"
       },
       {
          "university": "Märkische Fachhochschule Iserlohn",
          "website": "http://www.mfh-iserlohn.de/"
       },
       {
          "university": "Martin-Luther Universität Halle-Wittenberg",
          "website": "http://www.uni-halle.de/"
       },
       {
          "university": "Medizinische Hochschule Hannover",
          "website": "http://www.mh-hannover.de/"
       },
       {
          "university": "Medizinische Universität Lübeck",
          "website": "http://www.mu-luebeck.de/"
       },
       {
          "university": "Merkur Internationale FH Karlsruhe",
          "website": "http://www.merkur-fh.org/"
       },
       {
          "university": "Merz Akademie - Hochschule für Gestaltung Stuttgart",
          "website": "http://www.merz-akademie.de/"
       },
       {
          "university": "Muthesius-Hochschule - Fachhochschule für Kunst und Gestaltung",
          "website": "http://www.muthesius.de/"
       },
       {
          "university": "Nordakademie - Staatlich anerkannte private Fachhochschule mit dualen Studiengängen",
          "website": "http://www.nordakademie.de/"
       },
       {
          "university": "Northern Institute of Technology Management (NIT)",
          "website": "http://www.nithh.de/"
       },
       {
          "university": "Otto-Friedrich Universität Bamberg",
          "website": "http://www.uni-bamberg.de/"
       },
       {
          "university": "Otto-von-Guericke Universität Magdeburg",
          "website": "http://www.uni-magdeburg.de/"
       },
       {
          "university": "Pädagogische Hochschule Erfurt/Mühlhausen",
          "website": "http://www.ph-erfurt.de/"
       },
       {
          "university": "Pädagogische Hochschule Freiburg",
          "website": "http://www.ph-freiburg.de/"
       },
       {
          "university": "Pädagogische Hochschule Heidelberg",
          "website": "http://www.ph-heidelberg.de/"
       },
       {
          "university": "Pädagogische Hochschule Karlsruhe",
          "website": "http://www.ph-karlsruhe.de/"
       },
       {
          "university": "Pädagogische Hochschule Ludwigsburg",
          "website": "http://www.ph-ludwigsburg.de/"
       },
       {
          "university": "Pädagogische Hochschule Schwäbisch Gmünd",
          "website": "http://www.ph-gmuend.de/"
       },
       {
          "university": "Pädagogische Hochschule Weingarten",
          "website": "http://www.ph-weingarten.de/"
       },
       {
          "university": "Phillips-Universität Marburg",
          "website": "http://www.uni-marburg.de/"
       },
       {
          "university": "Philosophisch-Theologische Hochschule der Salesianer Don Boscos",
          "website": "http://www.pth-bb.de/"
       },
       {
          "university": "Philosophisch-Theologische Hochschule Münster",
          "website": "http://www.pth-muenster.de/"
       },
       {
          "university": "Philosophisch-Theologische Hochschule Sankt Georgen",
          "website": "http://www.st-georgen.uni-frankfurt.de/"
       },
       {
          "university": "Philosophisch-Theologische Hochschule SVD Sankt Augustin",
          "website": "http://www.philtheol-augustin.de/"
       },
       {
          "university": "Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)",
          "website": "http://www.pthv.de/"
       },
       {
          "university": "Philosophisch-Theologisches Studium Erfurt - Staatlich anerkannte Wissenschaftliche Hochschule",
          "website": "http://www.uni-erfurt.de/phil-theol/"
       },
       {
          "university": "Popakademie Baden-Württemberg",
          "website": "http://www.popakademie.de/"
       },
       {
          "university": "Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz",
          "website": "http://www.fhwt.de/"
       },
       {
          "university": "Private Fachhochschule Göttingen",
          "website": "http://www.pfh-goettingen.de/"
       },
       {
          "university": "Private FernFachhochschule Darmstadt",
          "website": "http://www.privatfh-da.de/"
       },
       {
          "university": "Private Hanseuniversität",
          "website": "http://www.hanseuni.de/"
       },
       {
          "university": "Private Universität Witten/Herdecke",
          "website": "http://www.uni-wh.de/"
       },
       {
          "university": "Rheinische Fachhochschule Köln",
          "website": "http://www.rfh-koeln.de/"
       },
       {
          "university": "Rheinische Friedrich-Wilhelms Universität Bonn",
          "website": "http://www.uni-bonn.de/"
       },
       {
          "university": "Rheinisch Westfälische Technische Hochschule Aachen",
          "website": "http://www.rwth-aachen.de/"
       },
       {
          "university": "Ruhr-Universität Bochum",
          "website": "http://www.ruhr-uni-bochum.de/"
       },
       {
          "university": "Ruprecht-Karls-Universität Heidelberg",
          "website": "http://www.uni-heidelberg.de/"
       },
       {
          "university": "Schiller International University - Heidelberg",
          "website": "http://www.siu-heidelberg.de/"
       },
       {
          "university": "SRH University of Applied Sciences",
          "website": "http://www.srh-berlin.de/"
       },
       {
          "university": "Staatlich anerkannte Fachhochschule für Kunsttherapie",
          "website": "http://www.fhkt.de/"
       },
       {
          "university": "Staatliche Hochschule für Musik",
          "website": "http://www.mh-trossingen.de/"
       },
       {
          "university": "Steinbeis-Hochschule-Berlin",
          "website": "http://www.stw.de/shb/"
       },
       {
          "university": "Technische Fachhochschule Berlin",
          "website": "http://www.tfh-berlin.de/"
       },
       {
          "university": "Technische Fachhochschule Georg Agricola für Rohstoff - Energie und - Umwelt zu Bochum",
          "website": "http://www.tfh-bochum.de/"
       },
       {
          "university": "Technische Fachhochschule Wildau",
          "website": "http://www.tfh-wildau.de/"
       },
       {
          "university": "Technische Universität Bergakademie Freiberg",
          "website": "http://www.tu-freiberg.de/"
       },
       {
          "university": "Technische Universität Berlin",
          "website": "http://www.tu-berlin.de/"
       },
       {
          "university": "Technische Universität Carolo-Wilhelmina Braunschweig",
          "website": "http://www.tu-bs.de/"
       },
       {
          "university": "Technische Universität Chemnitz",
          "website": "http://www.tu-chemnitz.de/"
       },
       {
          "university": "Technische Universität Clausthal",
          "website": "http://www.tu-clausthal.de/"
       },
       {
          "university": "Technische Universität Darmstadt",
          "website": "http://www.tu-darmstadt.de/"
       },
       {
          "university": "Technische Universität Dresden",
          "website": "http://www.tu-dresden.de/"
       },
       {
          "university": "Technische Universität Hamburg-Harburg",
          "website": "http://www.tu-harburg.de/"
       },
       {
          "university": "Technische Universität Ilmenau",
          "website": "http://www.tu-ilmenau.de/"
       },
       {
          "university": "Technische Universität München",
          "website": "http://www.tu-muenchen.de/"
       },
       {
          "university": "Theologische Fakultät Paderborn",
          "website": "http://www.paderborn.de/theofak/"
       },
       {
          "university": "Theologische Fakultät Trier",
          "website": "http://www.uni-trier.de/uni/theo/"
       },
       {
          "university": "Theologische Hochschule Friedensau",
          "website": "http://www.thh-friedensau.de/"
       },
       {
          "university": "Tierärztliche Hochschule Hannover",
          "website": "http://www.tiho-hannover.de/"
       },
       {
          "university": "Universität Augsburg",
          "website": "http://www.uni-augsburg.de/"
       },
       {
          "university": "Universität Bayreuth",
          "website": "http://www.uni-bayreuth.de/"
       },
       {
          "university": "Universität Bielefeld",
          "website": "http://www.uni-bielefeld.de/"
       },
       {
          "university": "Universität Bremen",
          "website": "http://www.uni-bremen.de/"
       },
       {
          "university": "Universität der Bundeswehr Hamburg",
          "website": "http://www.unibw-hamburg.de/"
       },
       {
          "university": "Universität der Bundeswehr München",
          "website": "http://www.unibw-muenchen.de/"
       },
       {
          "university": "Universität des Saarlandes",
          "website": "http://www.uni-saarland.de/"
       },
       {
          "university": "Universität Dortmund",
          "website": "http://www.uni-dortmund.de/"
       },
       {
          "university": "Universität Duisburg-Essen",
          "website": "http://www.uni-duisburg-essen.de/"
       },
       {
          "university": "Universität Erfurt",
          "website": "http://www.uni-erfurt.de/"
       },
       {
          "university": "Universität Flensburg",
          "website": "http://www.uni-flensburg.de/"
       },
       {
          "university": "Universität Fridericana Karlsruhe (Technische Hochschule)",
          "website": "http://www.uni-karlsruhe.de/"
       },
       {
          "university": "Universität Hamburg",
          "website": "http://www.uni-hamburg.de/"
       },
       {
          "university": "Universität Hannover",
          "website": "http://www.uni-hannover.de/"
       },
       {
          "university": "Universität Hildesheim",
          "website": "http://www.uni-hildesheim.de/"
       },
       {
          "university": "Universität Hohenheim",
          "website": "http://www.uni-hohenheim.de/"
       },
       {
          "university": "Universität Kaiserslautern",
          "website": "http://www.uni-kl.de/"
       },
       {
          "university": "Universität Kassel",
          "website": "http://www.uni-kassel.de/"
       },
       {
          "university": "Universität Koblenz-Landau",
          "website": "http://www.uni-koblenz-landau.de/"
       },
       {
          "university": "Universität Köln",
          "website": "http://www.uni-koeln.de/"
       },
       {
          "university": "Universität Konstanz",
          "website": "http://www.uni-konstanz.de/"
       },
       {
          "university": "Universität Leipzig",
          "website": "http://www.uni-leipzig.de/"
       },
       {
          "university": "Universität Lüneburg",
          "website": "http://www.uni-lueneburg.de/"
       },
       {
          "university": "Universität Mannheim",
          "website": "http://www.uni-mannheim.de/"
       },
       {
          "university": "Universität Osnabrück",
          "website": "http://www.uni-osnabrueck.de/"
       },
       {
          "university": "Universität Paderborn",
          "website": "http://www.uni-paderborn.de/"
       },
       {
          "university": "Universität Passau",
          "website": "http://www.uni-passau.de/"
       },
       {
          "university": "Universität Potsdam",
          "website": "http://www.uni-potsdam.de/"
       },
       {
          "university": "Universität Regensburg",
          "website": "http://www.uni-regensburg.de/"
       },
       {
          "university": "Universität Rostock",
          "website": "http://www.uni-rostock.de/"
       },
       {
          "university": "Universität Siegen",
          "website": "http://www.uni-siegen.de/"
       },
       {
          "university": "Universität Stuttgart",
          "website": "http://www.uni-stuttgart.de/"
       },
       {
          "university": "Universität Trier",
          "website": "http://www.uni-trier.de/"
       },
       {
          "university": "Universität Ulm",
          "website": "http://www.uni-ulm.de/"
       },
       {
          "university": "Westfälische Wilhelms-Universität Münster",
          "website": "http://www.uni-muenster.de/"
       },
       {
          "university": "Westsächsische Hochschule Zwickau (FH)",
          "website": "http://www.fh-zwickau.de/"
       },
       {
          "university": "Wissenschaftliche Hochschule für Unternehmensführung - Otto-Beisheim Hochschule",
          "website": "http://www.whu-koblenz.de/"
       }
    ],
    "DJ": [
       {
          "university": "Université de Djibouti",
          "website": "http://www.univ.edu.dj/"
       }
    ],
    "DK": [
       {
          "university": "Aalborg Business College",
          "website": "http://www.ah.dk/"
       },
       {
          "university": "Aalborg University",
          "website": "http://www.auc.dk/"
       },
       {
          "university": "Aarhus School of Architecture",
          "website": "http://www.a-aarhus.dk/"
       },
       {
          "university": "Aarhus School of Business",
          "website": "http://www.hha.dk/"
       },
       {
          "university": "Aarhus Technical College",
          "website": "http://www.ats.dk/"
       },
       {
          "university": "Aarhus University",
          "website": "http://www.au.dk/"
       },
       {
          "university": "Copenhagen Business School",
          "website": "http://www.cbs.dk/"
       },
       {
          "university": "Copenhagen University",
          "website": "http://www.ku.dk/"
       },
       {
          "university": "Danish Business Academy",
          "website": "http://www.ddea.dk/"
       },
       {
          "university": "Danish University of Education",
          "website": "http://www.dpu.dk/"
       },
       {
          "university": "Engineering College of Aarhus",
          "website": "http://www.iha.dk/"
       },
       {
          "university": "Engineering College of Copenhagen",
          "website": "http://www.ihk.dk/"
       },
       {
          "university": "International Business School of Scandinavia",
          "website": "http://www.ibss.eu/"
       },
       {
          "university": "International People's College",
          "website": "http://www.ipc.dk/"
       },
       {
          "university": "IT University of Copenhagen",
          "website": "http://www.itu.dk/"
       },
       {
          "university": "Knightsbridge University",
          "website": "http://www.knightsbridgeuniversity.com/"
       },
       {
          "university": "Lutheran School Of Theology In Aarhus",
          "website": "http://www.teologi.dk/"
       },
       {
          "university": "Odense University",
          "website": "http://www.ou.dk/"
       },
       {
          "university": "Roskilde University",
          "website": "http://www.ruc.dk/"
       },
       {
          "university": "Royal Academy of Music",
          "website": "http://www.musik-kons.dk/"
       },
       {
          "university": "Royal Danish Academy of Fine Arts - School of Architecture",
          "website": "http://www.karch.dk/"
       },
       {
          "university": "Royal Danish Academy of Fine Arts - School of Visual Art",
          "website": "http://www.kunstakademiet.dk/"
       },
       {
          "university": "Royal Danish Academy of Music",
          "website": "http://www.dkdm.dk/"
       },
       {
          "university": "Royal Danish School of Educational Sciences",
          "website": "http://www.dlh.dk/"
       },
       {
          "university": "Royal Danish School of Pharmacy",
          "website": "http://www.dfh.dk/"
       },
       {
          "university": "Royal School of Library and Information Science",
          "website": "http://www.db.dk/"
       },
       {
          "university": "Royal Veterinary and Agricultural University",
          "website": "http://www.kvl.dk/"
       },
       {
          "university": "Southern Denmark Business School",
          "website": "http://www.hhs.dk/"
       },
       {
          "university": "Technical University of Denmark",
          "website": "http://www.dtu.dk/"
       },
       {
          "university": "Tietgen Business College",
          "website": "http://www.tietgen.dk/"
       },
       {
          "university": "University of Southern Denmark",
          "website": "http://www.sdu.dk/"
       }
    ],
    "DM": [
       {
          "university": "Ballsbridge University ",
          "website": "http://www.acedu.org/"
       },
       {
          "university": "Dominica State College",
          "website": "http://www.dsc.dm/"
       },
       {
          "university": "New World University",
          "website": "http://www.newworld.ac/"
       },
       {
          "university": "Ross University Caribbean School of Medicine",
          "website": "http://www.rossu.edu/medical-school/"
       }
    ],
    "DO": [
       {
          "university": "Instituto Tecnológico de Santo Domingo",
          "website": "http://www.intec.edu.do/"
       },
       {
          "university": "Pontificia Universidad Católica Madre y Maestra (PUCMM)",
          "website": "http://www.pucmm.edu.do/"
       },
       {
          "university": "Universidad Abierta Para Adultos",
          "website": "http://www.uapa.edu.do/"
       },
       {
          "university": "Universidad Adventista Dominicana",
          "website": "http://www.unad.edu.do/"
       },
       {
          "university": "Universidad Agroforestal Fernando A.Meriño",
          "website": "http://www.uafam.edu.do/"
       },
       {
          "university": "Universidad APEC",
          "website": "http://www.unapec.edu.do/"
       },
       {
          "university": "Universidad Autónoma de Santo Domingo",
          "website": "http://www.uasd.edu.do/"
       },
       {
          "university": "Universidad Católica de Santo Domingo",
          "website": "http://www.ucsd.edu.do/"
       },
       {
          "university": "Universidad Católica Madre y Maestra",
          "website": "http://www.pucmm.edu.do/"
       },
       {
          "university": "Universidad Católica Nordestana",
          "website": "http://www.ucne.edu.do/"
       },
       {
          "university": "Universidad Católica Tecnológica del Cibao",
          "website": "http://www.ucateci.edu.do/"
       },
       {
          "university": "Universidad Central del Este",
          "website": "http://www.uce.edu.do/"
       },
       {
          "university": "Universidad Central Dominicana de Estudio Profesionales",
          "website": "http://66.98.64.31/"
       },
       {
          "university": "Universidad de la Tercera Edad",
          "website": "http://www.ute.edu.do/"
       },
       {
          "university": "Universidad del Caribe",
          "website": "http://www.unicaribe.edu.do/"
       },
       {
          "university": "Universidad Dominicana O&M",
          "website": "http://www.udoym.edu.do/"
       },
       {
          "university": "Universidad Dominico-Americana",
          "website": "http://www.icda.edu.do/espanol/unicda/"
       },
       {
          "university": "Universidad Eugenio Maria de Hostos",
          "website": "http://www.uniremhos.edu.do/"
       },
       {
          "university": "Universidad Experimental Felix Adam",
          "website": "http://www.unefa.edu.do/"
       },
       {
          "university": "Universidad Federico Henríquez y Carvajal",
          "website": "http://www.ufhec.edu.do/"
       },
       {
          "university": "Universidad Iberoamericana",
          "website": "http://www.unibe.edu.do/"
       },
       {
          "university": "Universidad Interamericana",
          "website": "http://www.unica.edu.do/"
       },
       {
          "university": "Universidad Nacional Evangélica",
          "website": "http://www.unev-rd.edu.do/"
       },
       {
          "university": "Universidad Nacional Pedro Henríquez Ureña",
          "website": "http://www.unphu.edu.do/"
       },
       {
          "university": "Universidad Odontológica Dominicana",
          "website": "http://www.uod.edu.do/"
       },
       {
          "university": "Universidad Tecnológica del Sur",
          "website": "http://www.utesur.edu.do/"
       },
       {
          "university": "Universidad Tecnológica de Santiago",
          "website": "http://www.utesa.edu/"
       },
       {
          "university": "Universidad Ulises Francisco Espaillat",
          "website": "http://www.ufhec.edu.do/"
       }
    ],
    "DZ": [
       {
          "university": "Centre Universitaire de Jijel",
          "website": "http://www.univ-jijel.dz/"
       },
       {
          "university": "Centre Universitaire de Tebessa",
          "website": "http://www.univ-tebessa.dz/"
       },
       {
          "university": "Centre Universitaire de Tiaret",
          "website": "http://www.univ-tiaret.dz/"
       },
       {
          "university": "Centre Universitaire d'Oum El Bouaghi",
          "website": "http://www.univ-oeb.dz/"
       },
       {
          "university": "Université Abou Bekr Belkaid - Tlemcen",
          "website": "http://www.univ-tlemcen.dz/"
       },
       {
          "university": "Université Amar Telidji",
          "website": "http://www.lagh-univ.dz/"
       },
       {
          "university": "Université Badji Mokhtar",
          "website": "http://www.univ-annaba.dz/"
       },
       {
          "university": "Université d'Alger",
          "website": "http://www.univ-alger.dz/"
       },
       {
          "university": "Université d'Alger 3",
          "website": "http://www.univ-alger3.dz/"
       },
       {
          "university": "Université de Batna",
          "website": "http://www.univ-batna.dz/"
       },
       {
          "university": "Université de Béjaia",
          "website": "http://www.univ-bejaia.dz/"
       },
       {
          "university": "Université de Biskra",
          "website": "http://www.univ-biskra.dz/"
       },
       {
          "university": "Université de Blida",
          "website": "http://www.univ-blida.dz/"
       },
       {
          "university": "Université de Chlef",
          "website": "http://www.univ-chlef.dz/"
       },
       {
          "university": "Université de Guelma",
          "website": "http://www.univ-guelma.dz/"
       },
       {
          "university": "Université de Mostaganem",
          "website": "http://www.univ-mosta.dz/"
       },
       {
          "university": "Université de M'sila",
          "website": "http://www.univ-msila.dz/"
       },
       {
          "university": "Université de Saida",
          "website": "http://www.univ-saida.dz/"
       },
       {
          "university": "Université de Sétif",
          "website": "http://www.univ-setif.dz/"
       },
       {
          "university": "Université de Sidi-Bel-Abbès (Djillali Liabès)",
          "website": "http://www.univ-sba.dz/"
       },
       {
          "university": "Université de Skikda",
          "website": "http://www.univ-skikda.dz/"
       },
       {
          "university": "Université des Sciences et de la Technologie d'Oran",
          "website": "http://www.univ-usto.dz/"
       },
       {
          "university": "Université des Sciences et de la Technologie Houari Boumediène",
          "website": "http://www.usthb.dz/"
       },
       {
          "university": "Universite des Sciences Islamiques de Constantine",
          "website": "http://www.univ-emir.dz/"
       },
       {
          "university": "Université d'Oran Es-Senia",
          "website": "http://www.univ-oran.dz/"
       },
       {
          "university": "Université Dr Tahar Moulay",
          "website": "http://www.univ-saida.dz/"
       },
       {
          "university": "Université Kasdi Merbah Ouargla",
          "website": "http://www.univ-ouargla.dz/"
       },
       {
          "university": "Université Mentouri Constantine",
          "website": "http://www.umc.edu.dz/"
       },
       {
          "university": "Université M'hamed Bouguerra de Boumerdes",
          "website": "http://www.umbb.dz/"
       },
       {
          "university": "Université Mouloud Mammeri de Tizi Ouzou",
          "website": "http://www.ummto.dz/"
       }
    ],
    "EC": [
       {
          "university": "Brookdale Community College",
          "website": "http://www.brookdale.edu.ec/"
       },
       {
          "university": "Escuela Politécnica de Chimborazo",
          "website": "http://www.espoch.edu/"
       },
       {
          "university": "Escuela Politécnica del Ejercito",
          "website": "http://www.espe.edu.ec"
       },
       {
          "university": "Escuela Politécnica Nacional",
          "website": "http://www.epn.edu.ec/"
       },
       {
          "university": "Escuela Superior Politécnica del Litoral",
          "website": "http://www.espol.edu.ec/"
       },
       {
          "university": "Pontificia Universidad Católica del Ecuador",
          "website": "http://www.puce.edu.ec/"
       },
       {
          "university": "Universidad Agraria del Ecuador",
          "website": "http://www.uagraria.edu.ec/"
       },
       {
          "university": "Universidad Andina Simón Bolívar",
          "website": "http://www.uasb.edu.ec/"
       },
       {
          "university": "Universidad Casa Grande",
          "website": "http://www.casagrande.edu.ec/"
       },
       {
          "university": "Universidad Catolica de Azogues",
          "website": "http://www.ucaazo.edu.ec/"
       },
       {
          "university": "Universidad Católica de Cuenca",
          "website": "http://www.ucacue.edu.ec/"
       },
       {
          "university": "Universidad Católica de Santiago de Guayaquil",
          "website": "http://www.ucsg.edu.ec/"
       },
       {
          "university": "Universidad Central del Ecuador",
          "website": "http://www.ucentral.edu.ec/"
       },
       {
          "university": "Universidad de Cuenca",
          "website": "http://www.ucuenca.edu.ec/"
       },
       {
          "university": "Universidad de Especialidades del Espíritu Santo",
          "website": "http://www.uees.edu.ec/"
       },
       {
          "university": "Universidad de Especialidades Turísticas ",
          "website": "http://www.uct.edu.ec/"
       },
       {
          "university": "Universidad de Guayaquil",
          "website": "http://www.ug.edu.ec/"
       },
       {
          "university": "Universidad del Azuay",
          "website": "http://www.uazuay.edu.ec/"
       },
       {
          "university": "Universidad del Pacifico",
          "website": "http://www.upacifico.edu.ec/"
       },
       {
          "university": "Universidad Estatal de Bolívar",
          "website": "http://www.ueb.isfun.net/"
       },
       {
          "university": "Universidad Internacional del Ecuador",
          "website": "http://www.internacional.edu.ec/"
       },
       {
          "university": "Universidad Internacional SEK - Quito",
          "website": "http://www.sek.edu/html/ecuador.html"
       },
       {
          "university": "Universidad Laica Eloy Alfaro de Manabi",
          "website": "http://www.uleam.edu.ec/"
       },
       {
          "university": "Universidad Laica Vicente Rocafuerte de Guayaquil",
          "website": "http://www.ulaicavr.com/"
       },
       {
          "university": "Universidad Nacional de Chimborazo",
          "website": "http://www.unach.edu.ec/"
       },
       {
          "university": "Universidad Nacional de Loja",
          "website": "http://www.unl.edu.ec/"
       },
       {
          "university": "Universidad Politécnica Salesiana",
          "website": "http://www.ups.edu.ec/"
       },
       {
          "university": "Universidad San Francisco de Quito",
          "website": "http://www.usfq.edu.ec/"
       },
       {
          "university": "Universidad Santa María Campus Guayaquil",
          "website": "http://www.usm.edu.ec/"
       },
       {
          "university": "Universidad Técnica de Ambato",
          "website": "http://www.uta.edu.ec/"
       },
       {
          "university": "Universidad Técnica de Babahoyo",
          "website": "http://www.utb.edu.ec/"
       },
       {
          "university": "Universidad Técnica de Cotopaxi",
          "website": "http://www.utc.edu.ec/"
       },
       {
          "university": "Universidad Técnica de Esmeraldas Luis Vargas Torres",
          "website": "http://www.utelvt.edu.ec/"
       },
       {
          "university": "Universidad Técnica del Norte",
          "website": "http://www.utn.edu.ec/"
       },
       {
          "university": "Universidad Técnica de Machala",
          "website": "http://www.utmachala.edu.ec/"
       },
       {
          "university": "Universidad Técnica de Manabí",
          "website": "http://www.utm.edu.ec/"
       },
       {
          "university": "Universidad Técnica Estatal de Quevedo",
          "website": "http://www.uteq.edu.ec/"
       },
       {
          "university": "Universidad Técnica Particular de Loja",
          "website": "http://www.utpl.edu.ec/"
       },
       {
          "university": "Universidad Tecnológica América",
          "website": "http://www.unita.edu.ec/"
       },
       {
          "university": "Universidad Tecnológica Equinoccial",
          "website": "http://www.ute.edu.ec/"
       },
       {
          "university": "Universidad Tecnológica Indoamérica",
          "website": "http://www.indoamerica.edu.ec/"
       },
       {
          "university": "Universidad Tecnologica Israel",
          "website": "http://www.uisrael.ec/"
       },
       {
          "university": "Universidad Tecnológica San Antonio de Machala",
          "website": "http://www.utsam.edu.ec/"
       }
    ],
    "EE": [
       {
          "university": "Estonian Academy of Arts",
          "website": "http://www.artun.ee/"
       },
       {
          "university": "Estonian Academy of Music and Theatre",
          "website": "http://www.ema.edu.ee/"
       },
       {
          "university": "Estonian Academy of Security Sciences",
          "website": "http://www.sisekaitse.ee/"
       },
       {
          "university": "Estonian Business School",
          "website": "http://www.ebs.ee/"
       },
       {
          "university": "Estonian University of Life Sciences",
          "website": "http://www.emu.ee/"
       },
       {
          "university": "Tallinn University",
          "website": "http://www.tlu.ee/"
       },
       {
          "university": "Tallinn University of Technology",
          "website": "http://www.ttu.ee/"
       },
       {
          "university": "Tartu Health Care College",
          "website": "http://www.nooruse.ee/"
       },
       {
          "university": "University of Applied Sciences",
          "website": "http://www.tktk.ee/"
       },
       {
          "university": "University of Tartu",
          "website": "http://www.ut.ee/"
       }
    ],
    "EG": [
       {
          "university": "Ain Shams University",
          "website": "http://www.shams.edu.eg/"
       },
       {
          "university": "Akhbar El Yom Academy",
          "website": "http://www.akhbaracademy.edu.eg/"
       },
       {
          "university": "Al Azhar University",
          "website": "http://www.azhar.edu.eg/"
       },
       {
          "university": "Alexandria University",
          "website": "http://www.alex.edu.eg/"
       },
       {
          "university": "American University in Cairo",
          "website": "http://www.aucegypt.edu/"
       },
       {
          "university": "Arab Academy for Science & Technology",
          "website": "http://www.aast.edu/"
       },
       {
          "university": "Arab Open University",
          "website": "http://www.aou.edu.eg/"
       },
       {
          "university": "Assiut University",
          "website": "http://www.aun.edu.eg/"
       },
       {
          "university": "Benha University",
          "website": "http://www.bu.edu.eg/"
       },
       {
          "university": "Beni Suef University",
          "website": "http://www.bsu.edu.eg/"
       },
       {
          "university": "Cairo University",
          "website": "http://www.cu.edu.eg/"
       },
       {
          "university": "Damanhour University",
          "website": "http://www.damanhour.edu.eg/"
       },
       {
          "university": "Damietta University",
          "website": "http://www.du.edu.eg/"
       },
       {
          "university": "Delta University",
          "website": "http://www.deltauniv.edu.eg/"
       },
       {
          "university": "El Shorouk Academy",
          "website": "http://www.elshoroukacademy.edu.eg/"
       },
       {
          "university": "Fayoum University",
          "website": "http://www.fayoum.edu.eg/"
       },
       {
          "university": "Future University",
          "website": "http://www.futureuniversity.edu.eg/"
       },
       {
          "university": "German University in Cairo",
          "website": "http://www.guc.edu.eg/"
       },
       {
          "university": "Helwan University",
          "website": "http://www.helwan.edu.eg/"
       },
       {
          "university": "Higher Technological Institute",
          "website": "http://www.hti.edu.eg/"
       },
       {
          "university": "Kafr El-Sheikh University",
          "website": "http://www.kfs.edu.eg/"
       },
       {
          "university": "Mansoura University",
          "website": "http://www.mans.edu.eg/"
       },
       {
          "university": "Menoufia University",
          "website": "http://www.menofia.edu.eg/"
       },
       {
          "university": "Military Technical College",
          "website": "http://www.mtc.edu.eg/"
       },
       {
          "university": "Minia University",
          "website": "http://www.minia.edu.eg/"
       },
       {
          "university": "Misr International University",
          "website": "http://www.miuegypt.edu.eg/"
       },
       {
          "university": "Misr University for Sience and Technology",
          "website": "http://www.must.edu.eg/"
       },
       {
          "university": "Modern Acadmy",
          "website": "http://www.modern-academy.edu.eg/"
       },
       {
          "university": "Modern Sciences & Arts University",
          "website": "http://www.msa.eun.eg/"
       },
       {
          "university": "Modern University For Technology and Information",
          "website": "http://www.mti.edu.eg/"
       },
       {
          "university": "Nile University",
          "website": "http://www.nileu.edu.eg/"
       },
       {
          "university": "October 6 university",
          "website": "http://www.o6u.edu.eg/"
       },
       {
          "university": "Pharos International University",
          "website": "http://www.pua.edu.eg/"
       },
       {
          "university": "Port Said University",
          "website": "http://www.psu.edu.eg/"
       },
       {
          "university": "Sadat Academy for Management Sciences",
          "website": "http://www.sadatacademy.edu.eg/"
       },
       {
          "university": "Sinai University",
          "website": "http://www.su.edu.eg/"
       },
       {
          "university": "Sohag University",
          "website": "http://www.sohag-univ.edu.eg/"
       },
       {
          "university": "South Valley University",
          "website": "http://www.svu.edu.eg/"
       },
       {
          "university": "Suez Canal University",
          "website": "http://www.suez.edu.eg/"
       },
       {
          "university": "Tanta University",
          "website": "http://www.tanta.edu.eg/"
       },
       {
          "university": "Université Française d'Égypte",
          "website": "http://www.ufe.edu.eg/"
       },
       {
          "university": "Université Senghor d'Alexandrie",
          "website": "http://www.usenghor-francophonie.org/"
       },
       {
          "university": "University of Sadat City",
          "website": "http://www.usc.edu.eg/"
       },
       {
          "university": "Zagazig University",
          "website": "http://www.zu.edu.eg/"
       }
    ],
    "ER": [
       {
          "university": "Eritrea Institute of Technology",
          "website": "http://www.eit.edu.er/"
       }
    ],
    "ES": [
       {
          "university": "Barcelona Graduate School of Economics",
          "website": "http://www.barcelonagse.eu/"
       },
       {
          "university": "Bircham International University",
          "website": "http://www.bircham.edu/"
       },
       {
          "university": "Centro Universitario Villanueva",
          "website": "http://www.villanueva.edu/"
       },
       {
          "university": "Escuela Superior de Gestion Comercial y Marketing (ESIC)",
          "website": "http://www.esic.es/"
       },
       {
          "university": "Global Business School Barcelona",
          "website": "http://www.global-business-school.org/"
       },
       {
          "university": "IE University",
          "website": "http://www.ie.edu/"
       },
       {
          "university": "Iniciativa Universidad del Atlantico",
          "website": "http://www.universidadatlantico.org/"
       },
       {
          "university": "International University of Southern Europe",
          "website": "http://www.iuse.edu.es/"
       },
       {
          "university": "La Salle Universities - International Programmes",
          "website": "http://www.lasalleuniversities.net/"
       },
       {
          "university": "Mondragon Univertsitatea",
          "website": "http://www.muni.es/"
       },
       {
          "university": "Schiller International University - Madrid",
          "website": "http://www.schillermadrid.edu/"
       },
       {
          "university": "Universidad Abierta Interactiva",
          "website": "http://www.uniactiva.com/"
       },
       {
          "university": "Universidad Alfonso X El Sabio",
          "website": "http://www.uax.es/"
       },
       {
          "university": "Universidad Antonio de Nebrija",
          "website": "http://www.unnet.es/"
       },
       {
          "university": "Universidad Autónoma de Madrid",
          "website": "http://www.uam.es/"
       },
       {
          "university": "Universidad Cardenal Herrera-CEU",
          "website": "http://www.uchceu.es/"
       },
       {
          "university": "Universidad Carlos III de Madrid",
          "website": "http://www.uc3m.es/"
       },
       {
          "university": "Universidad Católica de Ávila",
          "website": "http://www.ucavila.es/"
       },
       {
          "university": "Universidad Católica de Valencia",
          "website": "http://www.ucv.es/"
       },
       {
          "university": "Universidad Católica San Antonio",
          "website": "http://www.ucam.edu/"
       },
       {
          "university": "Universidad Complutense de Madrid",
          "website": "http://www.ucm.es/"
       },
       {
          "university": "Universidad de Alcalá de Henares",
          "website": "http://www.uah.es/"
       },
       {
          "university": "Universidad de Alicante",
          "website": "http://www.ua.es/"
       },
       {
          "university": "Universidad de Almería",
          "website": "http://www.ualm.es/"
       },
       {
          "university": "Universidad de Burgos",
          "website": "http://www.ubu.es/"
       },
       {
          "university": "Universidad de Cádiz",
          "website": "http://www.uca.es/"
       },
       {
          "university": "Universidad de Cantabria",
          "website": "http://www.unican.es/"
       },
       {
          "university": "Universidad de Castilla La Mancha",
          "website": "http://www.uclm.es/"
       },
       {
          "university": "Universidad de Córdoba",
          "website": "http://www.uco.es/"
       },
       {
          "university": "Universidad de Deusto",
          "website": "http://www.deusto.es/"
       },
       {
          "university": "Universidad de Extremadura",
          "website": "http://www.unex.es/"
       },
       {
          "university": "Universidad de Gerona",
          "website": "http://www.udg.es/"
       },
       {
          "university": "Universidad de Granada",
          "website": "http://www.ugr.es/"
       },
       {
          "university": "Universidad de Huelva",
          "website": "http://www.uhu.es/"
       },
       {
          "university": "Universidad de Jaén",
          "website": "http://www.ujaen.es/"
       },
       {
          "university": "Universidad de La Coruña",
          "website": "http://www.udc.es/"
       },
       {
          "university": "Universidad de La Laguna",
          "website": "http://www.ull.es/"
       },
       {
          "university": "Universidad de La Rioja",
          "website": "http://www.unirioja.es/"
       },
       {
          "university": "Universidad de Las Palmas de Gran Canaria",
          "website": "http://www.ulpgc.es/"
       },
       {
          "university": "Universidad de León",
          "website": "http://www.unileon.es/"
       },
       {
          "university": "Universidad del País Vasco",
          "website": "http://www.ehu.es/"
       },
       {
          "university": "Universidad de Málaga",
          "website": "http://www.uma.es/"
       },
       {
          "university": "Universidad de Murcia",
          "website": "http://www.um.es/"
       },
       {
          "university": "Universidad de Navarra",
          "website": "http://www.unav.es/"
       },
       {
          "university": "Universidad de Oviedo",
          "website": "http://www.uniovi.es/"
       },
       {
          "university": "Universidad de Salamanca",
          "website": "http://www.usal.es/"
       },
       {
          "university": "Universidad de San Pablo CEU",
          "website": "http://www.ceu.es/"
       },
       {
          "university": "Universidad de Santiago de Compostela",
          "website": "http://www.usc.es/"
       },
       {
          "university": "Universidad de Sevilla",
          "website": "http://www.us.es/"
       },
       {
          "university": "Universidad de Valencia",
          "website": "http://www.uv.es/"
       },
       {
          "university": "Universidad de Valladolid",
          "website": "http://www.uva.es/"
       },
       {
          "university": "Universidad de Vigo",
          "website": "http://www.uvigo.es/"
       },
       {
          "university": "Universidad de Zaragoza",
          "website": "http://www.unizar.es/"
       },
       {
          "university": "Universidad Europea de Madrid",
          "website": "http://www.uem.es/"
       },
       {
          "university": "Universidad Francisco de Vitoria",
          "website": "http://www.ufv.es/"
       },
       {
          "university": "Universidad Internacional de Andalucía",
          "website": "http://www.uia.es/"
       },
       {
          "university": "Universidad Internacional de Andalucía - Sede Antonio Machado de Baeza",
          "website": "http://www.uniaam.uia.es/"
       },
       {
          "university": "Universidad Internacional de Andalucía - Sede La Rábida",
          "website": "http://www.uniara.uia.es/"
       },
       {
          "university": "Universidad Internacional Menéndez Pelayo",
          "website": "http://www.uimp.es/"
       },
       {
          "university": "Universidad Miguel Hernández de Elche",
          "website": "http://www.umh.es/"
       },
       {
          "university": "Universidad Nacional de Educación a Distancia",
          "website": "http://www.uned.es/"
       },
       {
          "university": "Universidad Pablo de Olavide",
          "website": "http://www.upo.es/"
       },
       {
          "university": "Universidad Politécnica de Cartagena",
          "website": "http://www.upct.es/"
       },
       {
          "university": "Universidad Politécnica de Cataluna",
          "website": "http://www.upc.edu/"
       },
       {
          "university": "Universidad Politécnica de Madrid",
          "website": "http://www.upm.es/"
       },
       {
          "university": "Universidad Politécnica de Valencia",
          "website": "http://www.upv.es/"
       },
       {
          "university": "Universidad Pontificia Comillas",
          "website": "http://www.upco.es/"
       },
       {
          "university": "Universidad Pontificia de Salamanca",
          "website": "http://www.upsa.es/"
       },
       {
          "university": "Universidad Pública de Navarra",
          "website": "http://www.unavarra.es/"
       },
       {
          "university": "Universidad Rey Juan Carlos",
          "website": "http://www.urjc.es/"
       },
       {
          "university": "Universitat Autónoma de Barcelona",
          "website": "http://www.uab.es/"
       },
       {
          "university": "Universitat de Barcelona",
          "website": "http://www.ub.es/"
       },
       {
          "university": "Universitat de les Illes Balears",
          "website": "http://www.uib.es/"
       },
       {
          "university": "Universitat de Lleida",
          "website": "http://www.udl.es/"
       },
       {
          "university": "Universitat de Vic",
          "website": "http://www.uvic.es/"
       },
       {
          "university": "Universitat Internacional de Catalunya",
          "website": "http://www.uic.es/"
       },
       {
          "university": "Universitat Jaume I de Castellón",
          "website": "http://www.uji.es/"
       },
       {
          "university": "Universitat Oberta de Catalunya",
          "website": "http://www.uoc.es/"
       },
       {
          "university": "Universitat Pompeu Fabra",
          "website": "http://www.upf.es/"
       },
       {
          "university": "Universitat Rámon Llull",
          "website": "http://www.url.es/"
       },
       {
          "university": "Universitat Rovira I Virgili Tarragona",
          "website": "http://www.urv.es/"
       }
    ],
    "ET": [
       {
          "university": "Adama Science and Technology University",
          "website": "http://www.astu.edu.et/"
       },
       {
          "university": "Addis Ababa Science & Technology University",
          "website": "http://www.aastu.org/"
       },
       {
          "university": "Addis Ababa University",
          "website": "http://www.aau.edu.et/"
       },
       {
          "university": "Adigrat University",
          "website": "http://www.adu.edu.et/"
       },
       {
          "university": "Aksum University",
          "website": "http://www.aku.edu.et/"
       },
       {
          "university": "Ambo University",
          "website": "http://www.ambou.edu.et/"
       },
       {
          "university": "Arba Minch University",
          "website": "http://www.amu.edu.et/"
       },
       {
          "university": "Assosa University",
          "website": "http://www.asu.edu.et/"
       },
       {
          "university": "Bahir Dar University",
          "website": "http://www.bdu.edu.et/"
       },
       {
          "university": "Bule Hora  University",
          "website": "http://www.bulehorauniversity.com/"
       },
       {
          "university": "Debre Birhan University",
          "website": "http://www.dbu.edu.et/"
       },
       {
          "university": "Debre Markos University",
          "website": "http://www.dmu.edu.et/"
       },
       {
          "university": "Dilla University",
          "website": "http://www.dillauniversity.edu.et/"
       },
       {
          "university": "Dire Dawa University",
          "website": "http://www.ddu.edu.et/"
       },
       {
          "university": "Ethiopian Civil Service University",
          "website": "http://www.ecsc.edu.et/"
       },
       {
          "university": "Gondar University",
          "website": "http://www.uog.edu.et/"
       },
       {
          "university": "Haramaya University",
          "website": "http://www.haramaya.edu.et/"
       },
       {
          "university": "Hawassa University",
          "website": "http://www.hu.edu.et/"
       },
       {
          "university": "Jigjiga University",
          "website": "http://www.jju.edu.et/"
       },
       {
          "university": "Jimma University",
          "website": "http://www.ju.edu.et/"
       },
       {
          "university": "Madawalabu University",
          "website": "http://www.mwu.edu.et/"
       },
       {
          "university": "Mekelle Institute of Technology",
          "website": "http://www.mitethiopia.edu.et/"
       },
       {
          "university": "Mekelle University",
          "website": "http://www.mu.edu.et/"
       },
       {
          "university": "Mizan Tepi University",
          "website": "http://www.mtu.edu.et/"
       },
       {
          "university": "Semera University",
          "website": "http://www.su.edu.et/"
       },
       {
          "university": "Unity  University",
          "website": "http://www.uuc.edu.et/"
       },
       {
          "university": "Wolaita Sodo University",
          "website": "http://www.wsu.edu.et/"
       },
       {
          "university": "Wolkite University",
          "website": "http://www.wku.edu.et/"
       },
       {
          "university": "Wollega University",
          "website": "http://www.wuni.edu.et/"
       },
       {
          "university": "Wollo University",
          "website": "http://www.wu.edu.et/"
       }
    ],
    "FI": [
       {
          "university": "Abo Akademi University",
          "website": "http://www.abo.fi/"
       },
       {
          "university": "Academy of Fine Arts",
          "website": "http://www.kuva.fi/"
       },
       {
          "university": "Central Ostrobothnia University of Applied Sciences",
          "website": "http://www.cou.fi/"
       },
       {
          "university": "Diaconia University of Applied Sciences",
          "website": "http://www.diak.fi/"
       },
       {
          "university": "EVTEK University of Applied Sciences",
          "website": "http://www.evtek.fi/"
       },
       {
          "university": "Helsinki School of Economics and Business Administration",
          "website": "http://www.hkkk.fi/"
       },
       {
          "university": "Helsinki University of Technology",
          "website": "http://www.hut.fi/"
       },
       {
          "university": "Lahti Polytechnic",
          "website": "http://www.lamk.fi/"
       },
       {
          "university": "Lappeenranta University of Technology",
          "website": "http://www.lut.fi/"
       },
       {
          "university": "Oulu Polytechnic",
          "website": "http://www.oamk.fi/"
       },
       {
          "university": "Rovaniemi University of Applied Sciences",
          "website": "http://www.ramk.fi/"
       },
       {
          "university": "Satakunta University Of Applied Scinces",
          "website": "http://www.samk.fi/"
       },
       {
          "university": "Scandinavian Art and Business Institute",
          "website": "http://www.sabi.eu.com/"
       },
       {
          "university": "Sibelius Academy",
          "website": "http://www.siba.fi/"
       },
       {
          "university": "South Carelian Polytechnic",
          "website": "http://www.scp.fi/"
       },
       {
          "university": "Swedish School of Economics and Business Administration - Finland",
          "website": "http://www.shh.fi/"
       },
       {
          "university": "Tampere Polytechnic",
          "website": "http://www.tpu.fi/"
       },
       {
          "university": "Tampere University of Technology",
          "website": "http://www.tut.fi/"
       },
       {
          "university": "Theatre Academy Finland",
          "website": "http://www.teak.fi/"
       },
       {
          "university": "Turku School of Economics and Business Administration",
          "website": "http://www.tukkk.fi/"
       },
       {
          "university": "University of Art and Design Helsinki",
          "website": "http://www.uiah.fi/"
       },
       {
          "university": "University of Helsinki",
          "website": "http://www.helsinki.fi/"
       },
       {
          "university": "University of Joensuu",
          "website": "http://www.joensuu.fi/"
       },
       {
          "university": "University of Jyväskylä",
          "website": "http://www.jyu.fi/"
       },
       {
          "university": "University of Kuopio",
          "website": "http://www.uku.fi/"
       },
       {
          "university": "University of Lapland",
          "website": "http://www.ulapland.fi/"
       },
       {
          "university": "University of Oulu",
          "website": "http://www.oulu.fi/"
       },
       {
          "university": "University of Tampere",
          "website": "http://www.uta.fi/"
       },
       {
          "university": "University of Turku",
          "website": "http://www.utu.fi/"
       },
       {
          "university": "University of Vaasa",
          "website": "http://www.uwasa.fi/"
       },
       {
          "university": "Vaasa University of Applied Sciences",
          "website": "http://www.puv.fi/"
       }
    ],
    "FJ": [
       {
          "university": "Fiji National University ",
          "website": "http://www.fnu.ac.fj/"
       },
       {
          "university": "Fiji School of Medicine",
          "website": "http://www.fsm.ac.fj/"
       },
       {
          "university": "University of Fiji",
          "website": "http://www.unifiji.ac.fj/"
       },
       {
          "university": "University of the South Pacific",
          "website": "http://www.usp.ac.fj/"
       }
    ],
    "FO": [
       {
          "university": "University of the Faroe Islands",
          "website": "http://www.setur.fo/"
       }
    ],
    "FR": [
       {
          "university": "AgroParisTech",
          "website": "http://www.agroparistech.fr/"
       },
       {
          "university": "American Graduate School in Paris",
          "website": "http://www.ags.edu/"
       },
       {
          "university": "American University of Paris",
          "website": "http://www.aup.fr/"
       },
       {
          "university": "British Institute in Paris - University of London",
          "website": "http://www.bip.lon.ac.uk/"
       },
       {
          "university": "Centre de Formation et de Perfectionnement des Journalistes",
          "website": "http://www.cfpj.com/"
       },
       {
          "university": "Centre d'Etudes Supérieures des Techniques Industrielles",
          "website": "http://www.ismcm-cesti.fr/"
       },
       {
          "university": "Centre d'Etudes Supérieures Industrielles Paris",
          "website": "http://www.cesi.fr/"
       },
       {
          "university": "Centre National d'Etudes Agronomiques des Régions Chaudes",
          "website": "http://www.cnearc.fr/"
       },
       {
          "university": "Conservatoire National des Arts et Métiers",
          "website": "http://www.cnam.fr/"
       },
       {
          "university": "Ecole Catholique d'Arts & Metiers",
          "website": "http://www.ecam.fr/"
       },
       {
          "university": "Ecole Centrale d'Electronique - ECE",
          "website": "http://www.ece.fr/"
       },
       {
          "university": "Ecole Centrale de Lille",
          "website": "http://www.ec-lille.fr/"
       },
       {
          "university": "Ecole Centrale de Lyon",
          "website": "http://www.ec-lyon.fr/"
       },
       {
          "university": "Ecole Centrale de Nantes",
          "website": "http://www.ec-nantes.fr/"
       },
       {
          "university": "Ecole d'Architecture de Nancy",
          "website": "http://www.nancy.archi.fr/"
       },
       {
          "university": "Ecole de l'Air",
          "website": "http://www.aea.asso.fr/"
       },
       {
          "university": "Ecole des Hautes Etudes Commerciales",
          "website": "http://www.hec.fr/"
       },
       {
          "university": "Ecole des Hautes Etudes Commerciales du Nord",
          "website": "http://edu.edhec.com/"
       },
       {
          "university": "Ecole des Hautes Etudes Industrielles de Lille",
          "website": "http://www.hei.fr/"
       },
       {
          "university": "Ecole des Ingénieurs de la Ville de Paris",
          "website": "http://www.eivp-paris.fr/"
       },
       {
          "university": "Ecole d'Ingénieurs en Informatique pour l'Industrie",
          "website": "http://www.e3i.univ-tours.fr/"
       },
       {
          "university": "Ecole Européen des Affaires",
          "website": "http://www.eap.fr/"
       },
       {
          "university": "Ecole Européenne de Chimie - Polymères et Matériaux de Strasbourg",
          "website": "http://www-ecpm.u-strasbg.fr/"
       },
       {
          "university": "Ecole Française d'Electronique et d'Informatique",
          "website": "http://www.efrei.fr/"
       },
       {
          "university": "Ecole Française de Papeterie et des Industries Graphiques",
          "website": "http://www.efpg.inpg.fr/"
       },
       {
          "university": "Ecole Nationale d'Administration",
          "website": "http://www.ena.fr/"
       },
       {
          "university": "Ecole Nationale de la Météorologie",
          "website": "http://www.enm.meteo.fr/"
       },
       {
          "university": "Ecole Nationale de la Statistique et de l'Administration Economique",
          "website": "http://www.ensae.fr/"
       },
       {
          "university": "Ecole Nationale de la Statistique et de l'Analyse de l'information",
          "website": "http://www.ensai.fr/"
       },
       {
          "university": "Ecole Nationale de l'Aviation Civile",
          "website": "http://www.enac.fr/"
       },
       {
          "university": "Ecole Nationale des Ponts et Chausees",
          "website": "http://www.enpc.fr/"
       },
       {
          "university": "Ecole Nationale des Sciences Géographiques",
          "website": "http://www.ensg.ign.fr/"
       },
       {
          "university": "Ecole Nationale des Travaux Publics de l'Etat",
          "website": "http://www.entpe.fr/"
       },
       {
          "university": "Ecole Nationale d'Ingénieurs de Metz",
          "website": "http://www.enim.fr/"
       },
       {
          "university": "Ecole Nationale d'Ingénieurs de Saint-Etienne",
          "website": "http://www.enise.fr/"
       },
       {
          "university": "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
          "website": "http://www.enitiaa-nantes.fr/"
       },
       {
          "university": "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
          "website": "http://www.enitab.fr/"
       },
       {
          "university": "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
          "website": "http://www.enitac.fr/"
       },
       {
          "university": "Ecole Nationale d'Ingénieurs de Tarbes",
          "website": "http://www.enit.fr/"
       },
       {
          "university": "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
          "website": "http://www-engees.u-strasbg.fr/"
       },
       {
          "university": "Ecole Nationale Supérieur de Géologie de Nancy",
          "website": "http://www.ensg.u-nancy.fr/"
       },
       {
          "university": "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
          "website": "http://www.ensma.fr/"
       },
       {
          "university": "Ecole Nationale Supérieur de Mécaniques et des Microtechniques",
          "website": "http://www.ens2m.fr/"
       },
       {
          "university": "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
          "website": "http://www.ensieta.fr/"
       },
       {
          "university": "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
          "website": "http://www.ensica.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure Agronomique de Toulouse",
          "website": "http://www.ensat.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Agronomie de Montpellier",
          "website": "http://www.ensam.inra.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Agronomie de Rennes",
          "website": "http://agro.roazhon.inra.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
          "website": "http://www.ensaia.u-nancy.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
          "website": "http://www.paris.ensam.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Biologie Appliquée à la Nutrition et à l'Alementation",
          "website": "http://www.u-bourgogne.fr/ENSBANA/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Clermont-Ferrand",
          "website": "http://ensccf.univ-bpclermont.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Lille",
          "website": "http://www.ensc-lille.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Montpellier",
          "website": "http://www.enscm.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Mulhouse",
          "website": "http://www.enscmu.univ-mulhouse.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Paris",
          "website": "http://www.enscp.jussieu.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Rennes",
          "website": "http://www.ensc-rennes.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie de Toulouse",
          "website": "http://www.univ-inpt.fr/~ensct/"
       },
       {
          "university": "Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux",
          "website": "http://www.enscpb.u-bordeaux.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
          "website": "http://www.supaero.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
          "website": "http://www.inpg.fr/INPG/fr_see.html"
       },
       {
          "university": "Ecole Nationale Supérieure d'Electronique - d'Electrotechnique - d'Informatique et d'Hydraulique de Toulouse",
          "website": "http://www.enseeiht.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
          "website": "http://www.enserb.u-bordeaux.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
          "website": "http://www.enserg.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
          "website": "http://www.ensea.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Physique de Grenoble",
          "website": "http://www-enspg.inpg.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Physique de Marseille",
          "website": "http://www.enspm.u-3mrs.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Physique de Strasbourg",
          "website": "http://www-ensps.u-strasbg.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Arts et Industries de Strasbourg",
          "website": "http://www-ensais.u-strasbg.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Arts et Industries Textiles",
          "website": "http://www.ensait.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Industries Chimiques de Nancy",
          "website": "http://www.ensic.u-nancy.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Industries Textiles de Mulhouse",
          "website": "http://www.ensitm.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Mines d'Alès",
          "website": "http://www.ensm-ales.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Mines de Douai",
          "website": "http://www.ensm-douai.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Mines de Nancy",
          "website": "http://www.mines.u-nancy.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Mines de Paris",
          "website": "http://www.ensmp.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Mines de St-Etienne",
          "website": "http://www.emse.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Telecommunications de Bretagne",
          "website": "http://www.enst-bretagne.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure des Telecommunications de Paris",
          "website": "http://www.enst.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure de Techniques Avancées",
          "website": "http://www.ensta.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
          "website": "http://www.hmg.inpg.fr/"
       },
       {
          "university": "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
          "website": "http://www-ensimag.imag.fr/"
       },
       {
          "university": "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
          "website": "http://www.ensica.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
          "website": "http://www.inp-fc.fr/~ensigc/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
          "website": "http://www.ensieg.inpg.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
          "website": "http://www.univ-valenciennes.fr/ensimev/"
       },
       {
          "university": "Ecole Nationale Supérieure du Pétrole et des Monteurs",
          "website": "http://www.ifp.fr/EC/EC000GP1.html"
       },
       {
          "university": "Ecole Nationale Supérieure Electricité et Mécanique",
          "website": "http://www.ensem.u-nancy.fr/"
       },
       {
          "university": "Ecole Nationale Supérieure en Electrotechnique - Electronique - Informatique et Hydraulique de Toulouse",
          "website": "http://www.enseeiht.fr/"
       },
       {
          "university": "Ecole Nationale Vétérinaire d'Alfort",
          "website": "http://www.vet-alfort.fr/"
       },
       {
          "university": "Ecole Nationale Vétérinaire de Lyon",
          "website": "http://www.vet-lyon.fr/"
       },
       {
          "university": "Ecole Nationale Vétérinaire de Nantes",
          "website": "http://www.vet-nantes.fr/"
       },
       {
          "university": "Ecole Nationale Vétérinaire de Toulouse",
          "website": "http://www.envt.fr/"
       },
       {
          "university": "Ecole Normale Supérieure de Cachan",
          "website": "http://www.ens-cachan.fr/"
       },
       {
          "university": "Ecole Normale Supérieure de Fontenay-Saint Cloud",
          "website": "http://www.ens-fcl.fr/"
       },
       {
          "university": "Ecole Normale Supérieure de Lyon",
          "website": "http://www.ens-lyon.fr/"
       },
       {
          "university": "Ecole Normale Supérieure de Paris",
          "website": "http://www.ens.fr/"
       },
       {
          "university": "Ecole Polytechnique",
          "website": "http://www.polytechnique.fr/"
       },
       {
          "university": "Ecole Polytechnique Marseille",
          "website": "http://www.polytech-marseille.fr/"
       },
       {
          "university": "Ecole Polytechnique Universitaire de Lille",
          "website": "http://www.polytech-lille.fr/"
       },
       {
          "university": "Ecole pour les Etudes et la Recherche en Informatique et Electronique",
          "website": "http://www.eerie.fr/"
       },
       {
          "university": "Ecole Spéciale de Mécanique et d'Electricité",
          "website": "http://www.esme.fr/"
       },
       {
          "university": "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
          "website": "http://www.estp.fr/"
       },
       {
          "university": "Ecole Superieur d'Ingenieurs Leonard de Vinci",
          "website": "http://www.devinci.fr/"
       },
       {
          "university": "Ecole Supérieure d'Agriculture d'Angers",
          "website": "http://www.groupe-esa.com/"
       },
       {
          "university": "Ecole Supérieure d'Agriculture de Purpan",
          "website": "http://www.esa-purpan.fr/"
       },
       {
          "university": "Ecole Supérieure de Chimie Organique et Minérale",
          "website": "http://www.escom.fr/"
       },
       {
          "university": "Ecole Supérieure de Chimie Physique Electronique de Lyon",
          "website": "http://www.cpe.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Bordeaux",
          "website": "http://www.esc-bordeaux.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Brest",
          "website": "http://www.esc-brest.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Clermont-Ferrand",
          "website": "http://www.esc-clermont.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Dijon",
          "website": "http://www.escdijon.com/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Grenoble",
          "website": "http://www.esc-grenoble.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Le Havre/Caen",
          "website": "http://www.esc-normandie.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Lille",
          "website": "http://www.esc-lille.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Lyon",
          "website": "http://www.em-lyon.com/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Marseille-Provence",
          "website": "http://www.esc-marseille.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Nantes-Atlantique",
          "website": "http://www.escna.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Paris",
          "website": "http://www.escp.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Pau",
          "website": "http://www.esc-pau.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Reims",
          "website": "http://www.esc-reims.edu/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Rouen",
          "website": "http://www.esc-rouen.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Sophia Antipolis",
          "website": "http://www.ceram.edu/"
       },
       {
          "university": "Ecole Supérieure de Commerce de Toulouse",
          "website": "http://www.esc-toulouse.fr/"
       },
       {
          "university": "Ecole Supérieure de Commerce et Management",
          "website": "http://www.escem.fr/"
       },
       {
          "university": "Ecole Supérieure d'Electricité",
          "website": "http://www.supelec.fr/"
       },
       {
          "university": "Ecole Supérieure d'Electronique de l'Ouest",
          "website": "http://www.eseo.fr/"
       },
       {
          "university": "Ecole Supérieure de Physique et de Chimie Industrielles",
          "website": "http://www.espci.fr/"
       },
       {
          "university": "Ecole Supérieure des Sciences Commerciales d'Angers",
          "website": "http://www.essca.asso.fr/"
       },
       {
          "university": "Ecole Supérieure des Sciences Economiques et Commerciales",
          "website": "http://www.essec.fr/"
       },
       {
          "university": "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
          "website": "http://www.esstin.u-nancy.fr/"
       },
       {
          "university": "Ecole Supérieure des Techniques Industrielles et des Textiles",
          "website": "http://www.fupl.asso.fr/estit/"
       },
       {
          "university": "Ecole Supérieure d'Informatique-Electronique-Automatique",
          "website": "http://www.esiea.fr/"
       },
       {
          "university": "Ecole Supérieure d'Ingénieurs de Marseille",
          "website": "http://esim.imt-mrs.fr/"
       },
       {
          "university": "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
          "website": "http://www.esiee.fr/"
       },
       {
          "university": "Ecole Supérieure d'Ingénieurs en Génie Electrique",
          "website": "http://www.esigelec.fr/"
       },
       {
          "university": "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
          "website": "http://www.esitpa.org/"
       },
       {
          "university": "Ecole Supérieure d'Optique",
          "website": "http://www.iota.u-psud.fr/~ecole/"
       },
       {
          "university": "Ecole Supérieure Internationale d'Administration des Entreprises",
          "website": "http://www.esiae.fr/"
       },
       {
          "university": "Ecole Superieure Robert de Sorbon",
          "website": "http://www.sorbon.fr/"
       },
       {
          "university": "Ecole Universitaire d'Ingénieurs de Lille",
          "website": "http://www.eudil.fr/"
       },
       {
          "university": "ENIC Telecom Lille 1",
          "website": "http://www.enic.fr/"
       },
       {
          "university": "EPF Ecole d'Ingénieurs",
          "website": "http://www.epf.fr/"
       },
       {
          "university": "European Business School",
          "website": "http://www.ebs-paris.com/"
       },
       {
          "university": "European Management Center Paris",
          "website": "http://www.emc-campus.com/"
       },
       {
          "university": "Grenoble Ecole de Management",
          "website": "http://www.grenoble-em.com/"
       },
       {
          "university": "Groupe Sup de Co Amiens Picardie",
          "website": "http://www.supco-amiens.fr/"
       },
       {
          "university": "Groupe Sup de Co Montpellier",
          "website": "http://www.supco-montpellier.fr/"
       },
       {
          "university": "IDRAC (Institut de recherche en action commerciale)",
          "website": "http://www.ecoles-idrac.com/"
       },
       {
          "university": "Institut Catholique d'Arts et Métiers Lille",
          "website": "http://www.icam.fr/"
       },
       {
          "university": "Institut Catholique d'Arts et Métiers Nantes",
          "website": "http://www.icam.fr/presentation/nantes.htm"
       },
       {
          "university": "Institut Catholique de Paris",
          "website": "http://www.icp.fr/"
       },
       {
          "university": "Institut Catholique de Toulouse",
          "website": "http://www.ict-toulouse.asso.fr/"
       },
       {
          "university": "Institut Commercial de Nancy",
          "website": "http://www.icn-nancy.com/"
       },
       {
          "university": "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
          "website": "http://www.ireste.fr/"
       },
       {
          "university": "Institut des hautes études économiques et commerciales",
          "website": "http://www.inseec.fr/"
       },
       {
          "university": "Institut des Sciences de la Matière et du Rayonnement",
          "website": "http://www.ismra.fr/"
       },
       {
          "university": "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
          "website": "http://cust.univ-bpclermont.fr/"
       },
       {
          "university": "Institut des Sciences de l'Ingénieur de Montpellier",
          "website": "http://www.isim.univ-montp2.fr/"
       },
       {
          "university": "Institut d'Etudes Politiques de Bordeaux",
          "website": "http://www.iep.u-bordeaux.fr/"
       },
       {
          "university": "Institut d'Etudes Politiques de Paris (Sciences Po)",
          "website": "http://www.sciences-po.fr/"
       },
       {
          "university": "Institut National des Sciences Appliquées de Lyon",
          "website": "http://www.insa-lyon.fr/"
       },
       {
          "university": "Institut National des Sciences Appliquées de Rennes",
          "website": "http://www.insa-rennes.fr/"
       },
       {
          "university": "Institut National des Sciences Appliquées de Rouen",
          "website": "http://www.insa-rouen.fr/"
       },
       {
          "university": "Institut National des Sciences Appliquées de Toulouse",
          "website": "http://www.insa-tlse.fr/"
       },
       {
          "university": "Institut National des Télécommunications",
          "website": "http://www.int-evry.fr/"
       },
       {
          "university": "Institut National Polytechnique de Grenoble",
          "website": "http://www.inpg.fr/"
       },
       {
          "university": "Institut National Polytechnique de Lorraine",
          "website": "http://www.inpl-nancy.fr/"
       },
       {
          "university": "Institut National Polytechnique de Toulouse",
          "website": "http://www.inp-fc.fr/"
       },
       {
          "university": "Institut National Supérieur de Formation Agro-Alimentaire",
          "website": "http://agro.roazhon.inra.fr/etab/insfa/accueil.htm"
       },
       {
          "university": "Institut Supérieur Agricole de Beauvais",
          "website": "http://www.isab.fr/"
       },
       {
          "university": "Institut Supérieur d'Agriculture Lille",
          "website": "http://www.isa.fupl.asso.fr/"
       },
       {
          "university": "Institut Supérieur d'Agriculture Rhone-Alpes",
          "website": "http://www.isara.fr/"
       },
       {
          "university": "Institut Supérieure d'Electronique de Paris",
          "website": "http://www.isep.fr/"
       },
       {
          "university": "Institut Supérieure d'Electronique du Nord",
          "website": "http://www.isen.fr/"
       },
       {
          "university": "Institut Textile et Chimique de Lyon",
          "website": "http://www.itech.fr/"
       },
       {
          "university": "Schiller International University - Paris",
          "website": "http://www.schillerparis.com/"
       },
       {
          "university": "Schiller International University - Strasbourg",
          "website": "http://www.schillerstrasbourg.com/"
       },
       {
          "university": "Université Blaise Pascal (Clermont-Ferrand II)",
          "website": "http://www.univ-bpclermont.fr/"
       },
       {
          "university": "Université Bordeaux I",
          "website": "http://www.cribx1.u-bordeaux.fr/"
       },
       {
          "university": "Université Catholique de Lille",
          "website": "http://www.fupl.asso.fr/"
       },
       {
          "university": "Université Catholique de l' Ouest",
          "website": "http://www.uco.fr/"
       },
       {
          "university": "Université Catholique de Lyon",
          "website": "http://www.univ-catholyon.fr/"
       },
       {
          "university": "Université Charles-de-Gaulle (Lille III)",
          "website": "http://www.univ-lille3.fr/"
       },
       {
          "university": "Université Claude Bernard (Lyon I)",
          "website": "http://www.univ-lyon1.fr/"
       },
       {
          "university": "Université d'Aix-Marseille III",
          "website": "http://www.u-3mrs.fr/"
       },
       {
          "university": "Université d'Angers",
          "website": "http://www.univ-angers.fr/"
       },
       {
          "university": "Université d'Artois",
          "website": "http://www.univ-artois.fr/"
       },
       {
          "university": "Université d'Auvergne (Clermont-Ferrand I)",
          "website": "http://www.u-clermont1.fr/"
       },
       {
          "university": "Université d'Avignon",
          "website": "http://www.univ-avignon.fr/"
       },
       {
          "university": "Université de Bourgogne",
          "website": "http://www.u-bourgogne.fr/"
       },
       {
          "university": "Université de Bretagne Occidentale",
          "website": "http://www.univ-brest.fr/"
       },
       {
          "university": "Université de Bretagne Sud",
          "website": "http://www.univ-ubs.fr/"
       },
       {
          "university": "Université de Caen Basse Normandie",
          "website": "http://www.unicaen.fr/"
       },
       {
          "university": "Université de Cergy-Pontoise",
          "website": "http://www.u-cergy.fr/"
       },
       {
          "university": "Université de Corse Pascal Paoli",
          "website": "http://www.univ-corse.fr/"
       },
       {
          "university": "Université de Franche-Comté",
          "website": "http://www.univ-fcomte.fr/"
       },
       {
          "university": "Université de Haute-Alsace",
          "website": "http://www.univ-mulhouse.fr/"
       },
       {
          "university": "Université de la Méditerranée (Aix Marseille II)",
          "website": "http://www.univmed.fr/"
       },
       {
          "university": "Université de La Rochelle",
          "website": "http://www.univ-lr.fr/"
       },
       {
          "university": "Université de Limoges",
          "website": "http://www.unilim.fr/"
       },
       {
          "university": "Université de Marne la Vallée",
          "website": "http://www.univ-mlv.fr/"
       },
       {
          "university": "Université de Metz",
          "website": "http://www.univ-metz.fr/"
       },
       {
          "university": "Université de Montpellier I",
          "website": "http://www.univ-montp1.fr/"
       },
       {
          "university": "Université de Montpellier II",
          "website": "http://www.univ-montp2.fr/"
       },
       {
          "university": "Université de Nantes",
          "website": "http://www.univ-nantes.fr/"
       },
       {
          "university": "Université de Nice-Sophia Antipolis",
          "website": "http://www.unice.fr/"
       },
       {
          "university": "Université Denis Diderot (Paris VII)",
          "website": "http://www.sigu7.jussieu.fr/"
       },
       {
          "university": "Université de Pau et des Pays de l'Adour",
          "website": "http://www.univ-pau.fr/"
       },
       {
          "university": "Université de Perpignan",
          "website": "http://www.univ-perp.fr/"
       },
       {
          "university": "Université de Picardie Jules-Verne",
          "website": "http://www.u-picardie.fr/"
       },
       {
          "university": "Université de Poitiers",
          "website": "http://www.univ-poitiers.fr/"
       },
       {
          "university": "Université de Provence (Aix-Marseille I)",
          "website": "http://www.up.univ-mrs.fr/"
       },
       {
          "university": "Université de Reims Champagne-Ardenne",
          "website": "http://www.univ-reims.fr/"
       },
       {
          "university": "Université de Rouen - Haute Normandie",
          "website": "http://www.univ-rouen.fr/"
       },
       {
          "university": "Université de Savoie",
          "website": "http://www.univ-savoie.fr/"
       },
       {
          "university": "Université des Sciences et Technologies de Lille (Lille I)",
          "website": "http://www.univ-lille1.fr/"
       },
       {
          "university": "Université des Sciences Humaines (Strasbourg II)",
          "website": "http://ushs.u-strasbg.fr/"
       },
       {
          "university": "Université des Sciences Sociales (Toulouse I)",
          "website": "http://www.univ-tlse1.fr/"
       },
       {
          "university": "Université de Technologie de Belfort Montbéliard",
          "website": "http://www.utbm.fr/"
       },
       {
          "university": "Université de Technologie de Compiègne",
          "website": "http://www.utc.fr/"
       },
       {
          "university": "Université de Technologie de Troyes",
          "website": "http://www.utt.fr/"
       },
       {
          "university": "Université de Toulouse",
          "website": "http://www.univ-toulouse.fr/"
       },
       {
          "university": "Université de Toulouse-le-Mirail (Toulouse II)",
          "website": "http://www.univ-tlse2.fr/"
       },
       {
          "university": "Université de Valenciennes et du Hainaut-Cambrésis",
          "website": "http://www.univ-valenciennes.fr/"
       },
       {
          "university": "Université de Versailles Saint-Quentin-en-Yvelines",
          "website": "http://www.uvsq.fr/"
       },
       {
          "university": "Université d'Evry Val d'Essonne",
          "website": "http://www.univ-evry.fr/"
       },
       {
          "university": "Université d'Orléans",
          "website": "http://www.univ-orleans.fr/"
       },
       {
          "university": "Université du Droit et de la Sante (Lille II)",
          "website": "http://www.univ-lille2.fr/"
       },
       {
          "university": "Université du Havre",
          "website": "http://www.univ-lehavre.fr/"
       },
       {
          "university": "Université du Littoral Cote d'Opale",
          "website": "http://www.univ-littoral.fr/"
       },
       {
          "university": "Université du Maine",
          "website": "http://www.univ-lemans.fr/"
       },
       {
          "university": "Université du Sud - Toulon et Var",
          "website": "http://www.univ-tln.fr/"
       },
       {
          "university": "Université François Rabelais de Tours",
          "website": "http://www.univ-tours.fr/"
       },
       {
          "university": "Université Henri Poincaré (Nancy I)",
          "website": "http://www.uhp-nancy.fr/"
       },
       {
          "university": "Université Jean Monnet",
          "website": "http://www.univ-st-etienne.fr/"
       },
       {
          "university": "Université Jean Moulin (Lyon III)",
          "website": "http://www.univ-lyon3.fr/"
       },
       {
          "university": "Université Joseph Fourier (Grenoble I)",
          "website": "http://www.ujf-grenoble.fr/"
       },
       {
          "university": "Université Louis Pasteur (Strasbourg I)",
          "website": "http://www-ulp.u-strasbg.fr/"
       },
       {
          "university": "Université Lumiére (Lyon II)",
          "website": "http://www.univ-lyon2.fr/"
       },
       {
          "university": "Université Michel de Montaigne (Bordeaux III )",
          "website": "http://www.montaigne.u-bordeaux.fr/"
       },
       {
          "university": "Université Montesquieu (Bordeaux IV)",
          "website": "http://www.montesquieu.u-bordeaux.fr/"
       },
       {
          "university": "Université Nancy II",
          "website": "http://www.univ-nancy2.fr/"
       },
       {
          "university": "Université Panthéon-Assas (Paris II)",
          "website": "http://www.u-paris2.fr/"
       },
       {
          "university": "Université Panthéon-Sorbonne (Paris I)",
          "website": "http://www.univ-paris1.fr/"
       },
       {
          "university": "Université Paris-Dauphine (Paris IX)",
          "website": "http://www.dauphine.fr/"
       },
       {
          "university": "Université Paris Nanterre (Paris X)",
          "website": "http://www.u-paris10.fr/"
       },
       {
          "university": "Université Paris Nord (Paris XIII)",
          "website": "http://www.univ-paris13.fr/"
       },
       {
          "university": "Université Paris-Sorbonne (Paris IV)",
          "website": "http://www.paris4.sorbonne.fr/"
       },
       {
          "university": "Université Paris Sud (Paris XI)",
          "website": "http://www.u-psud.fr/"
       },
       {
          "university": "Université Paul Sabatier (Toulouse III)",
          "website": "http://www.ups-tlse.fr/"
       },
       {
          "university": "Université Paul Valéry (Montpellier III)",
          "website": "http://www.univ-montp3.fr/"
       },
       {
          "university": "Université Pierre et Marie Curie (Paris VI)",
          "website": "http://www.jussieu.fr/"
       },
       {
          "university": "Université Pierre Mendes-France (Grenoble II)",
          "website": "http://www.upmf-grenoble.fr/"
       },
       {
          "university": "Université René Descartes (Paris V)",
          "website": "http://www.univ-paris5.fr/"
       },
       {
          "university": "Université Rennes I",
          "website": "http://www.univ-rennes1.fr/"
       },
       {
          "university": "Université Rennes II - Haute-Bretagne",
          "website": "http://www.uhb.fr/"
       },
       {
          "university": "Université Robert Schuman (Strasbourg III)",
          "website": "http://www-urs.u-strasbg.fr/"
       },
       {
          "university": "Université Sorbonne-Nouvelle (Paris III)",
          "website": "http://www.univ-paris3.fr/"
       },
       {
          "university": "Université Stendhal (Grenoble III)",
          "website": "http://www.u-grenoble3.fr/"
       },
       {
          "university": "Université Val-de-Marne (Paris XII)",
          "website": "http://www.univ-paris12.fr/"
       },
       {
          "university": "Université Victor Segalen (Bordeaux II)",
          "website": "http://www.u-bordeaux2.fr/"
       },
       {
          "university": "Université Vincennes Saint-Denis (Paris VIII)",
          "website": "http://www.univ-paris8.fr/"
       }
    ],
    "GA": [
       {
          "university": "Université Omar Bongo",
          "website": "http://www.uob.ga/"
       }
    ],
    "GB": [
       {
          "university": "Aga Khan University",
          "website": "http://www.aku.edu/"
       },
       {
          "university": "American InterContinental University - London",
          "website": "http://www.aiuniv.edu/"
       },
       {
          "university": "Anglia Ruskin University",
          "website": "http://www.anglia.ac.uk/"
       },
       {
          "university": "Aston University",
          "website": "http://www.aston.ac.uk/"
       },
       {
          "university": "Birkbeck College - University of London",
          "website": "http://www.bbk.ac.uk/"
       },
       {
          "university": "Birmingham City University",
          "website": "http://www.bcu.ac.uk/"
       },
       {
          "university": "Bournemouth University",
          "website": "http://www.bournemouth.ac.uk/"
       },
       {
          "university": "British College of Osteopathic Medicine",
          "website": "http://www.bcom.ac.uk/"
       },
       {
          "university": "Brunel University Uxbridge",
          "website": "http://www.brunel.ac.uk/"
       },
       {
          "university": "Buckinghamshire New University",
          "website": "http://www.bucks.ac.uk/"
       },
       {
          "university": "Camberwell College of Arts",
          "website": "http://www.camb.linst.ac.uk/"
       },
       {
          "university": "Canterbury Christ Church University",
          "website": "http://www.canterbury.ac.uk/"
       },
       {
          "university": "Cardiff University",
          "website": "http://www.cardiff.ac.uk/"
       },
       {
          "university": "Central Saint Martins College of Art & Design",
          "website": "http://www.csm.linst.ac.uk/"
       },
       {
          "university": "Chelsea College of Art and Design",
          "website": "http://www.chelsea.linst.ac.uk/"
       },
       {
          "university": "City University",
          "website": "http://www.city.ac.uk/"
       },
       {
          "university": "Courtauld Institute of Art - University of London",
          "website": "http://www.courtauld.ac.uk/"
       },
       {
          "university": "Coventry University",
          "website": "http://www.coventry.ac.uk/"
       },
       {
          "university": "Cranfield University",
          "website": "http://www.cranfield.ac.uk/"
       },
       {
          "university": "De Montfort University Leicester",
          "website": "http://www.dmu.ac.uk/"
       },
       {
          "university": "European School of Economics",
          "website": "http://www.eselondon.ac.uk/"
       },
       {
          "university": "Falmouth University",
          "website": "http://www.falmouth.ac.uk/"
       },
       {
          "university": "Glasgow Caledonian University",
          "website": "http://www.gcal.ac.uk/"
       },
       {
          "university": "Glasgow School of Art",
          "website": "http://www.gsa.ac.uk/"
       },
       {
          "university": "Goldsmiths College - University of London",
          "website": "http://www.gold.ac.uk/"
       },
       {
          "university": "Heriot-Watt University",
          "website": "http://www.hw.ac.uk/"
       },
       {
          "university": "Heythrop College - University of London",
          "website": "http://www.heythrop.ac.uk/"
       },
       {
          "university": "Huron University USA in London",
          "website": "http://www.huron.ac.uk/"
       },
       {
          "university": "ifs University College",
          "website": "http://www.ifslearning.ac.uk/"
       },
       {
          "university": "Imperial College London",
          "website": "http://www.ic.ac.uk/"
       },
       {
          "university": "Imperial College School of Medicine",
          "website": "http://www.med.ic.ac.uk/"
       },
       {
          "university": "Institue of Historical Research - University of London",
          "website": "http://ihr.sas.ac.uk/"
       },
       {
          "university": "Institute of Advanced Legal Studies - University of London",
          "website": "http://www.sas.ac.uk/ials/"
       },
       {
          "university": "Institute of Classical Studies - University of London",
          "website": "http://www.sas.ac.uk/icls/"
       },
       {
          "university": "Institute of Commonwealth Studies - University of London",
          "website": "http://www.ihr.sas.ac.uk/ics/"
       },
       {
          "university": "Institute of Education - University of London",
          "website": "http://www.ioe.ac.uk/"
       },
       {
          "university": "Institute of Germanic Studies - University of London",
          "website": "http://www.sas.ac.uk/igs/"
       },
       {
          "university": "Institute of Latin American Studies - University of London",
          "website": "http://www.sas.ac.uk/ilas/"
       },
       {
          "university": "International Centre for Isclamic Science",
          "website": "http://www.kolieh.com/"
       },
       {
          "university": "International Colleges of Islamic Science",
          "website": "http://www.islamiccolleges.com/"
       },
       {
          "university": "Keele University",
          "website": "http://www.keele.ac.uk/"
       },
       {
          "university": "King's College London - University of London",
          "website": "http://www.kcl.ac.uk/"
       },
       {
          "university": "Kingston University",
          "website": "http://www.kingston.ac.uk/"
       },
       {
          "university": "Leeds Metropolitan University",
          "website": "http://www.lmu.ac.uk/"
       },
       {
          "university": "Liverpool Hope University College",
          "website": "http://www.hope.ac.uk/"
       },
       {
          "university": "Liverpool John Moores University",
          "website": "http://www.livjm.ac.uk/"
       },
       {
          "university": "London Business School",
          "website": "http://www.lbs.lon.ac.uk/"
       },
       {
          "university": "London College of Science & Technology",
          "website": "http://www.lcst.ac/"
       },
       {
          "university": "London Guildhall University",
          "website": "http://www.lgu.ac.uk/"
       },
       {
          "university": "London Institute of Management and Technology",
          "website": "http://www.limt.co.uk/"
       },
       {
          "university": "London Metropolitan University",
          "website": "http://www.londonmet.ac.uk/"
       },
       {
          "university": "London School of Business & Finance",
          "website": "http://www.lsbf.org.uk/"
       },
       {
          "university": "London School of Economics and Political Science - University of London",
          "website": "http://www.lse.ac.uk/"
       },
       {
          "university": "London School of Hygiene & Tropical Medicine - University of London",
          "website": "http://www.lshtm.ac.uk/"
       },
       {
          "university": "London School of Jewish Studies",
          "website": "http://www.brijnet.org/lsjs/"
       },
       {
          "university": "Loughborough University",
          "website": "http://www.lboro.ac.uk/"
       },
       {
          "university": "Middlesex University",
          "website": "http://www.mdx.ac.uk/"
       },
       {
          "university": "Napier University",
          "website": "http://www.napier.ac.uk/"
       },
       {
          "university": "Newport International University",
          "website": "http://www.niu.org.uk/"
       },
       {
          "university": "Nottingham Trent University",
          "website": "http://www.ntu.ac.uk/"
       },
       {
          "university": "Open University",
          "website": "http://www.open.ac.uk/"
       },
       {
          "university": "Oxford Brookes University",
          "website": "http://www.brookes.ac.uk/"
       },
       {
          "university": "Queen Mary and Westfield College - University of London",
          "website": "http://www.qmw.ac.uk/"
       },
       {
          "university": "Richmond University - The American International University in London",
          "website": "http://www.richmond.ac.uk/"
       },
       {
          "university": "Roehampton University of Surrey",
          "website": "http://www.roehampton.ac.uk/"
       },
       {
          "university": "Royal Academy of Music - University of London",
          "website": "http://www.ram.ac.uk/"
       },
       {
          "university": "Royal College of Art",
          "website": "http://www.rca.ac.uk/"
       },
       {
          "university": "Royal College of Music - University of London",
          "website": "http://www.rcm.ac.uk/"
       },
       {
          "university": "Royal Free Hospital School of Medicine - University of London",
          "website": "http://www.rfhsm.ac.uk/"
       },
       {
          "university": "Royal Holloway and Bedford New College",
          "website": "http://www.rhbnc.ac.uk/"
       },
       {
          "university": "Saint George's Hospital Medical School - University of London",
          "website": "http://www.sghms.ac.uk/"
       },
       {
          "university": "Schiller International University - London",
          "website": "http://www.schillerlondon.ac.uk/"
       },
       {
          "university": "School of Oriental and African Studies - University of London",
          "website": "http://www.soas.ac.uk/"
       },
       {
          "university": "School of Pharmacy - University of London",
          "website": "http://www.ulsop.ac.uk/"
       },
       {
          "university": "School of Slavonic and East European Studies - University of London",
          "website": "http://www.ssees.ac.uk/"
       },
       {
          "university": "Sheffield Hallam University",
          "website": "http://www.shu.ac.uk/"
       },
       {
          "university": "Sotheby´s Institute of Art - London",
          "website": "http://www.sothebysinstitutelondon.com/"
       },
       {
          "university": "Southampton Solent University",
          "website": "http://www.solent.ac.uk/"
       },
       {
          "university": "South Bank University",
          "website": "http://www.sbu.ac.uk/"
       },
       {
          "university": "Staffordshire University",
          "website": "http://www.staffs.ac.uk/"
       },
       {
          "university": "St.Patrick's International College - London",
          "website": "http://www.st-patricks.ac.uk/"
       },
       {
          "university": "Stratford College London",
          "website": "http://www.sclondon.ac/"
       },
       {
          "university": "Swansea Metropolitan University",
          "website": "http://www.smu.ac.uk/"
       },
       {
          "university": "Thames Valley University",
          "website": "http://www.tvu.ac.uk/"
       },
       {
          "university": "The American University in London",
          "website": "http://www.aul.edu/"
       },
       {
          "university": "The Manchester Metropolitan University",
          "website": "http://www.mmu.ac.uk/"
       },
       {
          "university": "The Queen's University Belfast",
          "website": "http://www.qub.ac.uk/"
       },
       {
          "university": "The Robert Gordon University",
          "website": "http://www.rgu.ac.uk/"
       },
       {
          "university": "Trinity College Carmarthen",
          "website": "http://www.trinity-cm.ac.uk/"
       },
       {
          "university": "Trinity College of Music",
          "website": "http://www.tcm.ac.uk/"
       },
       {
          "university": "United Medical and Dental Schools - University of London",
          "website": "http://www.umds.ac.uk/"
       },
       {
          "university": "University Campus Suffolk",
          "website": "http://www.ucs.ac.uk/"
       },
       {
          "university": "University College London - University of London",
          "website": "http://www.ucl.ac.uk/"
       },
       {
          "university": "University of Aberdeen",
          "website": "http://www.abdn.ac.uk/"
       },
       {
          "university": "University of Abertay Dundee",
          "website": "http://www.abertay.ac.uk/"
       },
       {
          "university": "University of Bath",
          "website": "http://www.bath.ac.uk/"
       },
       {
          "university": "University of Bedfordshire",
          "website": "http://www.beds.ac.uk/"
       },
       {
          "university": "University of Birmingham",
          "website": "http://www.bham.ac.uk/"
       },
       {
          "university": "University of Bolton",
          "website": "http://www.bolton.ac.uk/"
       },
       {
          "university": "University of Bradford",
          "website": "http://www.brad.ac.uk/"
       },
       {
          "university": "University of Brighton",
          "website": "http://www.bton.ac.uk/"
       },
       {
          "university": "University of Bristol",
          "website": "http://www.bris.ac.uk/"
       },
       {
          "university": "University of Buckingham",
          "website": "http://www.buck.ac.uk/"
       },
       {
          "university": "University of Cambridge",
          "website": "http://www.cam.ac.uk/"
       },
       {
          "university": "University of Central Lancashire",
          "website": "http://www.uclan.ac.uk/"
       },
       {
          "university": "University of Chester",
          "website": "http://www.chester.ac.uk/"
       },
       {
          "university": "University of Derby",
          "website": "http://www.derby.ac.uk/"
       },
       {
          "university": "University of Dundee",
          "website": "http://www.dundee.ac.uk/"
       },
       {
          "university": "University of Durham",
          "website": "http://www.dur.ac.uk/"
       },
       {
          "university": "University of East Anglia",
          "website": "http://www.uea.ac.uk/"
       },
       {
          "university": "University of East London",
          "website": "http://www.uel.ac.uk/"
       },
       {
          "university": "University of Edinburgh",
          "website": "http://www.ed.ac.uk/"
       },
       {
          "university": "University of Essex",
          "website": "http://www.essex.ac.uk/"
       },
       {
          "university": "University of Exeter",
          "website": "http://www.ex.ac.uk/"
       },
       {
          "university": "University of Glamorgan",
          "website": "http://www.glam.ac.uk/"
       },
       {
          "university": "University of Glasgow",
          "website": "http://www.gla.ac.uk/"
       },
       {
          "university": "University of Gloucestershire",
          "website": "http://www.glos.ac.uk/"
       },
       {
          "university": "University of Greenwich",
          "website": "http://www.gre.ac.uk/"
       },
       {
          "university": "University of Halifax - Birmingham Campus",
          "website": "http://www.halifaxuni.ac/"
       },
       {
          "university": "University of Hertfordshire",
          "website": "http://www.herts.ac.uk/"
       },
       {
          "university": "University of Huddersfield",
          "website": "http://www.hud.ac.uk/"
       },
       {
          "university": "University of Hull",
          "website": "http://www.hull.ac.uk/"
       },
       {
          "university": "University of Kent at Canterbury",
          "website": "http://www.ukc.ac.uk/"
       },
       {
          "university": "University of Lancaster",
          "website": "http://www.lancs.ac.uk/"
       },
       {
          "university": "University of Leeds",
          "website": "http://www.leeds.ac.uk/"
       },
       {
          "university": "University of Leicester",
          "website": "http://www.le.ac.uk/"
       },
       {
          "university": "University of Lincoln",
          "website": "http://www.lincoln.ac.uk/"
       },
       {
          "university": "University of Liverpool",
          "website": "http://www.liv.ac.uk/"
       },
       {
          "university": "University of London",
          "website": "http://www.lon.ac.uk/"
       },
       {
          "university": "University of Manchester",
          "website": "http://www.man.ac.uk/"
       },
       {
          "university": "University of Newcastle-upon-Tyne",
          "website": "http://www.ncl.ac.uk/"
       },
       {
          "university": "University of Northampton",
          "website": "http://www.northampton.ac.uk/"
       },
       {
          "university": "University of North London",
          "website": "http://www.unl.ac.uk/"
       },
       {
          "university": "University of Northumbria at Newcastle",
          "website": "http://www.unn.ac.uk/"
       },
       {
          "university": "University of Nottingham",
          "website": "http://www.nottingham.ac.uk/"
       },
       {
          "university": "University of Oxford",
          "website": "http://www.ox.ac.uk/"
       },
       {
          "university": "University of Paisley",
          "website": "http://www.paisley.ac.uk/"
       },
       {
          "university": "University of Plymouth",
          "website": "http://www.plymouth.ac.uk/"
       },
       {
          "university": "University of Portsmouth",
          "website": "http://www.port.ac.uk/"
       },
       {
          "university": "University of Reading",
          "website": "http://www.rdg.ac.uk/"
       },
       {
          "university": "University of Salford",
          "website": "http://www.salford.ac.uk/"
       },
       {
          "university": "University of Sheffield",
          "website": "http://www.shef.ac.uk/"
       },
       {
          "university": "University of Southampton",
          "website": "http://www.soton.ac.uk/"
       },
       {
          "university": "University of St. Andrews",
          "website": "http://www.st-and.ac.uk/"
       },
       {
          "university": "University of Stirling",
          "website": "http://www.stir.ac.uk/"
       },
       {
          "university": "University of Strathclyde",
          "website": "http://www.strath.ac.uk/"
       },
       {
          "university": "University of Sunderland",
          "website": "http://www.sunderland.ac.uk/"
       },
       {
          "university": "University of Surrey",
          "website": "http://www.surrey.ac.uk/"
       },
       {
          "university": "University of Sussex",
          "website": "http://www.sussex.ac.uk/"
       },
       {
          "university": "University of Teesside",
          "website": "http://www.tees.ac.uk/"
       },
       {
          "university": "University of the West of England - Bristol",
          "website": "http://www.uwe.ac.uk/"
       },
       {
          "university": "University of Ulster",
          "website": "http://www.ulst.ac.uk/"
       },
       {
          "university": "University of Wales",
          "website": "http://www.wales.ac.uk/"
       },
       {
          "university": "University of Wales - Aberystwyth",
          "website": "http://www.aber.ac.uk/"
       },
       {
          "university": "University of Wales - Bangor",
          "website": "http://www.bangor.ac.uk/"
       },
       {
          "university": "University of Wales College of Medicine",
          "website": "http://www.uwcm.ac.uk/"
       },
       {
          "university": "University of Wales Institute - Cardiff",
          "website": "http://www.uwic.ac.uk/"
       },
       {
          "university": "University of Wales - Lampeter",
          "website": "http://www.lamp.ac.uk/"
       },
       {
          "university": "University of Wales - Newport",
          "website": "http://www.newport.ac.uk/"
       },
       {
          "university": "University of Wales - Swansea",
          "website": "http://www.swan.ac.uk/"
       },
       {
          "university": "University of Warwick",
          "website": "http://www.warwick.ac.uk/"
       },
       {
          "university": "University of Westminster",
          "website": "http://www.westminster.ac.uk/"
       },
       {
          "university": "University of Wolverhampton",
          "website": "http://www.wlv.ac.uk/"
       },
       {
          "university": "University of Worcester",
          "website": "http://www.worc.ac.uk/"
       },
       {
          "university": "University of York",
          "website": "http://www.york.ac.uk/"
       },
       {
          "university": "Warburg Institute - University of London",
          "website": "http://www.sas.ac.uk/warburg/"
       },
       {
          "university": "Warnborough University",
          "website": "http://www.warnborough.edu/"
       },
       {
          "university": "William Gilbert College",
          "website": "http://www.williamgilbert.co.uk/"
       },
       {
          "university": "Wimbledon School of Art",
          "website": "http://www.wimbledon.ac.uk/"
       }
    ],
    "GD": [
       {
          "university": "St. George's University",
          "website": "http://www.sgu.edu/"
       }
    ],
    "GE": [
       {
          "university": "Agricultural University of Georgia",
          "website": "http://www.agruni.edu.ge/"
       },
       {
          "university": "Akaki Tsereteli State University",
          "website": "http://www.atsu.edu.ge/"
       },
       {
          "university": "Caucasus University",
          "website": "http://www.cu.edu.ge/"
       },
       {
          "university": "Free University of Tbilisi",
          "website": "http://www.freeuni.ge/"
       },
       {
          "university": "Georgian Technical University",
          "website": "http://www.gtu.edu.ge/"
       },
       {
          "university": "Ilia Chavchavadze State University",
          "website": "http://www.iliauni.edu.ge/"
       },
       {
          "university": "International Blacksea University",
          "website": "http://www.ibsu.edu.ge/"
       },
       {
          "university": "St. Andrew Georgian University",
          "website": "http://www.sangu.ge/"
       },
       {
          "university": "Tbilisi State Medical University",
          "website": "http://www.tsmu.edu/"
       },
       {
          "university": "Tbilisi State University",
          "website": "http://www.tsu.edu.ge/"
       },
       {
          "university": "Tbilisi Teaching University",
          "website": "http://www.tbuniver.edu.ge/"
       },
       {
          "university": "University of Grigol Robakidze",
          "website": "http://www.gruni.edu.ge/"
       }
    ],
    "GF": [
       {
          "university": "Université des Antilles et de la Guyane",
          "website": "http://www.univ-ag.fr/"
       }
    ],
    "GH": [
       {
          "university": "Accra Polytechnic ",
          "website": "http://www.accrapolytechnic.edu.gh/"
       },
       {
          "university": "Akrofi-Christaller Institute of Theeology - Mission and  Culture",
          "website": "http://www.acighana.org/"
       },
       {
          "university": "All Nations University College",
          "website": "http://www.anuc.edu.gh/"
       },
       {
          "university": "Ashesi University",
          "website": "http://www.ashesi.edu.gh/"
       },
       {
          "university": "Cape Coast Polytechnic ",
          "website": "http://www.cpoly.edu.gh/"
       },
       {
          "university": "Central University College",
          "website": "http://www.central.edu.gh/"
       },
       {
          "university": "Ghana Christian University College",
          "website": "http://www.ghanacu.org/"
       },
       {
          "university": "Ghana Institute of Management and Public Administration (GIMPA)",
          "website": "http://www.gimpa.edu.gh/"
       },
       {
          "university": "Ghana Telecom University College",
          "website": "http://www.gtuc.edu.gh/"
       },
       {
          "university": "Ho Polytechnic ",
          "website": "http://www.hopoly.edu.gh/"
       },
       {
          "university": "Islamic University College",
          "website": "http://www.iug.edu.gh/"
       },
       {
          "university": "Koforidua Polytechnic ",
          "website": "http://www.koforiduapoly.edu.gh/"
       },
       {
          "university": "Kumasi Polytechnic ",
          "website": "http://www.kpoly.edu.gh/"
       },
       {
          "university": "Kwame Nkrumah University of Science and Technology",
          "website": "http://www.knust.edu.gh/"
       },
       {
          "university": "Lancaster University Ghana",
          "website": "http://www.lancaster.edu.gh/"
       },
       {
          "university": "Pan African Christian University College",
          "website": "http://www.pacuc.edu.gh/"
       },
       {
          "university": "Prempeh College",
          "website": "http://www.prempeh-college.com/"
       },
       {
          "university": "Presbyterian University College",
          "website": "http://www.presbyuniversity.edu.gh/"
       },
       {
          "university": "Regent University College of Science and Technology  ",
          "website": "http://www.regentghana.net/"
       },
       {
          "university": "Takoradi Polytechnic ",
          "website": "http://www.tpoly.edu.gh/"
       },
       {
          "university": "Trinity Theological Seminary",
          "website": "http://www.trinity.edu.gh/"
       },
       {
          "university": "University for Development Studies",
          "website": "http://www.uds.edu.gh/"
       },
       {
          "university": "University of Cape Coast",
          "website": "http://www.ucc.edu.gh/"
       },
       {
          "university": "University of Education - Winneba",
          "website": "http://www.uew.edu.gh/"
       },
       {
          "university": "University of Ghana",
          "website": "http://www.ug.edu.gh/"
       },
       {
          "university": "Valley View University",
          "website": "http://www.vvu.edu.gh/"
       }
    ],
    "GL": [
       {
          "university": "University of Greenland",
          "website": "http://www.ilisimatusarfik.gl/"
       }
    ],
    "GM": [
       {
          "university": "American International University West Africa",
          "website": "http://www.aiu.edu.gm/"
       },
       {
          "university": "EUCLID University",
          "website": "http://www.euclid.int/"
       },
       {
          "university": "University of the Gambia",
          "website": "http://www.utg.edu.gm/"
       }
    ],
    "GN": [
       {
          "university": "Université Gamal Abdel Nasser de Conakry",
          "website": "http://www.uganc.org/"
       },
       {
          "university": "Université Julius Nyerere Kankan",
          "website": "http://www.ujnk.org/"
       },
       {
          "university": "Université Kofi Annan",
          "website": "http://www.univ-kag.org/"
       },
       {
          "university": "Université Thierno Amadou Diallo",
          "website": "http://www.utad-petel-edu.org/"
       }
    ],
    "GP": [
       {
          "university": "Université des Antilles et de la Guyane",
          "website": "http://www.univ-ag.fr/"
       }
    ],
    "GQ": [
       {
          "university": "Universidad Nacional de Guinea Ecuatorial",
          "website": "http://www.unge.gq/"
       }
    ],
    "GR": [
       {
          "university": "Aegean University",
          "website": "http://www.aegean.gr/"
       },
       {
          "university": "Agricultural University of Athens",
          "website": "http://www.aua.gr/"
       },
       {
          "university": "American College of Greece",
          "website": "http://www.acg.gr/"
       },
       {
          "university": "American College of Thessaloniki",
          "website": "http://www.act.edu/"
       },
       {
          "university": "Aristotle University of Thessaloniki",
          "website": "http://www.auth.gr/"
       },
       {
          "university": "Athens Graduate School of Management (AGSM)",
          "website": "http://www.agsm.gr/"
       },
       {
          "university": "Athens Laboratory of Business Administration (ALBA)",
          "website": "http://www.alba.edu.gr/"
       },
       {
          "university": "Athens School of Fine Arts",
          "website": "http://www.asfa.gr/"
       },
       {
          "university": "Athens University of Economics and Business",
          "website": "http://www.aueb.gr/"
       },
       {
          "university": "City University Athens",
          "website": "http://www.cityu.gr/"
       },
       {
          "university": "DEI Bachelor & Master Degrees",
          "website": "http://www.dei.edu.gr/"
       },
       {
          "university": "Dimocritus University of Thrace",
          "website": "http://www.duth.gr/"
       },
       {
          "university": "European University - Athens Campus",
          "website": "http://www.euruni.edu/"
       },
       {
          "university": "Harokopio University",
          "website": "http://www.forthnet.gr/harokopio/"
       },
       {
          "university": "Hellenic Army Academy",
          "website": "http://www.sse.gr/"
       },
       {
          "university": "Hellenic Open University",
          "website": "http://www.eap.gr/"
       },
       {
          "university": "Institute of Management Development - Ohrid",
          "website": "http://www.imd.edu.gr/"
       },
       {
          "university": "Institute of Management & Enteurpreneurship of South East Europe",
          "website": "http://www.imese.gr/"
       },
       {
          "university": "International Hellenic University",
          "website": "http://www.ihu.edu.gr/"
       },
       {
          "university": "Ionian University Corfu",
          "website": "http://www.uion.edu.gr/"
       },
       {
          "university": "National Technical University of Athens",
          "website": "http://www.ntua.gr/"
       },
       {
          "university": "Panteios University of Economics and Political Sciences Athens",
          "website": "http://www.panteion.gr/"
       },
       {
          "university": "Technical University of Crete",
          "website": "http://www.tuc.gr/"
       },
       {
          "university": "Technological Education Institute of Athens",
          "website": "http://www.teiath.gr/"
       },
       {
          "university": "Technological Education Institute of Epiros",
          "website": "http://www.teiep.gr/"
       },
       {
          "university": "Technological Education Institute of Halkida - Euboea",
          "website": "http://www.teihal.gr/"
       },
       {
          "university": "Technological Education Institute of Heraklion",
          "website": "http://www.teiher.gr/"
       },
       {
          "university": "Technological Education Institute of Kalamata",
          "website": "http://www.teikal.gr/"
       },
       {
          "university": "Technological Education Institute of Kavala",
          "website": "http://www.teikav.edu.gr/"
       },
       {
          "university": "Technological Education Institute of Kozani",
          "website": "http://www.teikoz.gr/"
       },
       {
          "university": "Technological Education Institute of Lamia",
          "website": "http://www.teilam.gr/"
       },
       {
          "university": "Technological Education Institute of Larissa",
          "website": "http://www.teilar.gr/"
       },
       {
          "university": "Technological Education Institute of Mesologgi",
          "website": "http://www.teimes.gr/"
       },
       {
          "university": "Technological Education Institute of Patras",
          "website": "http://www.teipat.gr/"
       },
       {
          "university": "Technological Education Institute of Piraeus",
          "website": "http://www.teipir.gr/"
       },
       {
          "university": "Technological Education Institute of Serres",
          "website": "http://www.teiser.gr/"
       },
       {
          "university": "Technological Education Institute of Thessaloniki",
          "website": "http://www.teithe.gr/"
       },
       {
          "university": "University of Athens",
          "website": "http://www.uoa.gr/"
       },
       {
          "university": "University of Central Greece",
          "website": "http://www.ucg.gr/"
       },
       {
          "university": "University of Crete",
          "website": "http://www.uoc.gr/"
       },
       {
          "university": "University of Indianapolis in Athens",
          "website": "http://www.uindy.edu.gr/"
       },
       {
          "university": "University of Ioannina",
          "website": "http://www.uoi.gr/"
       },
       {
          "university": "University of LaVerne in Athens",
          "website": "http://www.laverne.edu.gr/"
       },
       {
          "university": "University of Macedonia",
          "website": "http://www.uom.gr/"
       },
       {
          "university": "University of Patras",
          "website": "http://www.upatras.gr/"
       },
       {
          "university": "University of Peloponnese",
          "website": "http://www.uop.gr/"
       },
       {
          "university": "University of Piraeus",
          "website": "http://www.unipi.gr/"
       },
       {
          "university": "University of Thessaly",
          "website": "http://www.uth.gr/"
       },
       {
          "university": "University of Western Macedonia",
          "website": "http://www.uowm.gr/"
       },
       {
          "university": "Yorker International University - Athens",
          "website": "http://www.nyuniversity.net/"
       }
    ],
    "GT": [
       {
          "university": "Centro Universitario Ciudad Vieja",
          "website": "http://www.cucv.edu.gt/"
       },
       {
          "university": "Centro Universitario de Occidente",
          "website": "http://www.cunoc.edu.gt/"
       },
       {
          "university": "Universidad del Istmo",
          "website": "http://www.unis.edu.gt/"
       },
       {
          "university": "Universidad del Valle de Guatemala",
          "website": "http://www.uvg.edu.gt/"
       },
       {
          "university": "Universidad de San Carlos de Guatemala",
          "website": "http://www.usac.edu.gt/"
       },
       {
          "university": "Universidad Francisco Marroquín",
          "website": "http://www.ufm.edu.gt/"
       },
       {
          "university": "Universidad Galileo",
          "website": "http://www.galileo.edu/"
       },
       {
          "university": "Universidad Mariano Gálvez",
          "website": "http://www.umg.edu.gt/"
       },
       {
          "university": "Universidad Panamericana",
          "website": "http://www.upana.edu.gt/"
       },
       {
          "university": "Universidad Rafael Landívar",
          "website": "http://www.url.edu.gt/"
       },
       {
          "university": "Universidad Rural de Guatemala",
          "website": "http://www.urural.edu.gt/"
       }
    ],
    "GU": [
       {
          "university": "University of Guam",
          "website": "http://www.uog.edu/"
       }
    ],
    "GY": [
       {
          "university": "Gemsville Technical University",
          "website": "http://www.gemsvilleuniversity.com/"
       },
       {
          "university": "Greenheart Medical School",
          "website": "http://www.greenheartmed.org/"
       },
       {
          "university": "Texila American University",
          "website": "http://www.tauedu.org/"
       },
       {
          "university": "University of Guyana",
          "website": "http://www.uog.edu.gy/"
       }
    ],
    "HK": [
       {
          "university": "Chinese University of Hong Kong",
          "website": "http://www.cuhk.hk/"
       },
       {
          "university": "Chu Hai College",
          "website": "http://www.chuhai.edu.hk/"
       },
       {
          "university": "City University of Hong Kong",
          "website": "http://www.cityu.edu.hk/"
       },
       {
          "university": "Hong Kong Academy for Performing Arts ",
          "website": "http://www.hkapa.edu/"
       },
       {
          "university": "Hong Kong Baptist University",
          "website": "http://www.hkbu.edu.hk/"
       },
       {
          "university": "Hong Kong Institute of Education",
          "website": "http://www.ied.edu.hk/"
       },
       {
          "university": "Hong Kong Polytechnic University",
          "website": "http://www.polyu.edu.hk/"
       },
       {
          "university": "Hong Kong Shue Yan College",
          "website": "http://www.hksyc.edu/"
       },
       {
          "university": "Hong Kong University of Science and Technology",
          "website": "http://www.ust.hk/"
       },
       {
          "university": "Lingnan University",
          "website": "http://www.ln.edu.hk/"
       },
       {
          "university": "Open University of Hong Kong",
          "website": "http://www.ouhk.edu.hk/"
       },
       {
          "university": "University of Hong Kong",
          "website": "http://www.hku.hk/"
       }
    ],
    "HN": [
       {
          "university": "Escuela Agricola Panamericana Zamorano",
          "website": "http://www.zamorano.edu/"
       },
       {
          "university": "Universidad Católica de Honduras",
          "website": "http://www.unicah.edu/"
       },
       {
          "university": "Universidad de San Pedro Sula",
          "website": "http://www.usps.edu.hn/"
       },
       {
          "university": "Universidad José Cecilio del Valle",
          "website": "http://www.ujcv.edu.hn/"
       },
       {
          "university": "Universidad Metropolitana de Honduras",
          "website": "http://www.unimetro.edu.hn/"
       },
       {
          "university": "Universidad Nacional Autónoma de Honduras",
          "website": "http://www.unah.edu.hn/"
       },
       {
          "university": "Universidad Pedagógica Nacional Francisco Morazán",
          "website": "http://www.upnfm.edu.hn/"
       },
       {
          "university": "Universidad Tecnológica Centroamericana",
          "website": "http://www.unitec.edu/"
       },
       {
          "university": "Universidad Tecnológica de Honduras",
          "website": "http://www.uth.hn/"
       }
    ],
    "HR": [
       {
          "university": "University of Dubrovnik",
          "website": "http://www.unidu.hr/"
       },
       {
          "university": "University of Osijek",
          "website": "http://www.unios.hr/"
       },
       {
          "university": "University of Pula",
          "website": "http://www.unipu.hr/"
       },
       {
          "university": "University of Rijeka",
          "website": "http://www.uniri.hr/"
       },
       {
          "university": "University of Split",
          "website": "http://www.unist.hr/"
       },
       {
          "university": "University of Zadar",
          "website": "http://www.unizd.hr/"
       },
       {
          "university": "University of Zagreb",
          "website": "http://www.unizg.hr/"
       },
       {
          "university": "Zagreb School of Economics and Management",
          "website": "http://www.zsem.hr/"
       }
    ],
    "HT": [
       {
          "university": "American University of the Caribbean",
          "website": "http://www.auchaiti.org/"
       },
       {
          "university": "Université Chrétienne du Nord d'Haiti",
          "website": "http://www.ucnh.org/"
       },
       {
          "university": "Université d'Etat d'Haiti",
          "website": "http://www.ueh.edu.ht/"
       },
       {
          "university": "Université Épiscopale d'Haiti",
          "website": "http://www.uneph.org/"
       },
       {
          "university": "Université Notre Dame d'Haïti",
          "website": "http://www.undh.org/"
       },
       {
          "university": "Université Quisqueya",
          "website": "http://www.uniq.edu/"
       }
    ],
    "HU": [
       {
          "university": "Academy of Drama and Film",
          "website": "http://www.filmacademy.hu/"
       },
       {
          "university": "Andrassy Gyula German Speaking University Budapest",
          "website": "http://www.andrassyuni.hu/"
       },
       {
          "university": "Avicenna International College",
          "website": "http://www.avicenna.hu/"
       },
       {
          "university": "Budapest Buddhist University",
          "website": "http://www.tkbf.hu/"
       },
       {
          "university": "Budapest University of Economic Sciences and Public Administration",
          "website": "http://www.bke.hu/"
       },
       {
          "university": "Central European University",
          "website": "http://www.ceu.hu/"
       },
       {
          "university": "College of Dunaujvaros",
          "website": "http://www.duf.hu/"
       },
       {
          "university": "Dániel Berzsenyi Teacher Training College",
          "website": "http://www.bdtf.hu/"
       },
       {
          "university": "Debrecen University of Agricultural Sciences",
          "website": "http://www.agr.unideb.hu/"
       },
       {
          "university": "Eötvös Lorand University",
          "website": "http://www.elte.hu/"
       },
       {
          "university": "Esztergom Theological College",
          "website": "http://www.ehf.hu/"
       },
       {
          "university": "Haynal Imre University of Health Sciences Budapest",
          "website": "http://www.hiete.hu/"
       },
       {
          "university": "Hungarian Academy of Craft and Design",
          "website": "http://www.mif.hu/"
       },
       {
          "university": "Hungarian Academy of Fine Arts Budapest",
          "website": "http://www.arts7.hu/"
       },
       {
          "university": "Hungarian University of Physical Education",
          "website": "http://www.hupe.hu/"
       },
       {
          "university": "Janus Pannonius University",
          "website": "http://www.jpte.hu/"
       },
       {
          "university": "Károl Gáspár University of the Reformed Church",
          "website": "http://www.kgre.hu/"
       },
       {
          "university": "Kodolanyi Janos University College",
          "website": "http://www.kodolanyi.hu/"
       },
       {
          "university": "Kossuth Lajos University",
          "website": "http://www.lib.klte.hu/"
       },
       {
          "university": "Liszt Ferenc Academy of Music Budapest",
          "website": "http://www.lfze.hu/"
       },
       {
          "university": "Pázmány Péter Catholic University",
          "website": "http://www.ppke.hu/"
       },
       {
          "university": "Reformed Theological Academy of Debrecen",
          "website": "http://www.drk.hu/"
       },
       {
          "university": "Séchenyi István University",
          "website": "http://www.sze.hu/"
       },
       {
          "university": "Semmelweis University of Medical Sciences",
          "website": "http://www.sote.hu/"
       },
       {
          "university": "Szent István University",
          "website": "http://www.szie.hu/"
       },
       {
          "university": "Technical University of Budapest",
          "website": "http://www.bme.hu/"
       },
       {
          "university": "University Medical School of Debrecen",
          "website": "http://www.dote.hu/"
       },
       {
          "university": "University Medical School of Pécs",
          "website": "http://www.pote.hu/"
       },
       {
          "university": "University of Debrecen ",
          "website": "http://www.unideb.hu/"
       },
       {
          "university": "University of Horticulture and Food Industry",
          "website": "http://www.kee.hu/"
       },
       {
          "university": "University of Miskolc",
          "website": "http://www.uni-miskolc.hu/"
       },
       {
          "university": "University of Pannonia",
          "website": "http://www.uni-pannon.hu/"
       },
       {
          "university": "University of Pecs",
          "website": "http://www.pte.hu/"
       },
       {
          "university": "University of Sopron",
          "website": "http://www.efe.hu/"
       },
       {
          "university": "University of Szeged",
          "website": "http://www.u-szeged.hu/"
       },
       {
          "university": "University of Veterinary Science",
          "website": "http://www.univet.hu/"
       },
       {
          "university": "University of West Hungary",
          "website": "http://www.nyme.hu/"
       }
    ],
    "ID": [
       {
          "university": "Abfi Institute Perbanas Jakarta",
          "website": "http://www.perbanasinstitute.ac.id/"
       },
       {
          "university": "Binus University",
          "website": "http://www.binus.ac.id/"
       },
       {
          "university": "Bogor Agricultural University",
          "website": "http://www.ipb.ac.id/"
       },
       {
          "university": "Institut Sains dan Teknologi Al Kamal",
          "website": "http://www.ista.ac.id/"
       },
       {
          "university": "Institut Sains & Teknologi Akprind",
          "website": "http://www.akprind.ac.id/"
       },
       {
          "university": "Institut Teknologi Adhi Tama Surabaya",
          "website": "http://www.itats.ac.id/"
       },
       {
          "university": "Institut Teknologi Bandung",
          "website": "http://www.itb.ac.id/"
       },
       {
          "university": "Institut Teknologi Sepuluh Nopember",
          "website": "http://www.its.ac.id/"
       },
       {
          "university": "Institut Teknologi Telkom",
          "website": "http://www.stttelkom.ac.id/"
       },
       {
          "university": "Politeknik Negeri Bandung",
          "website": "http://www.polban.ac.id/"
       },
       {
          "university": "Politeknik Negeri Jakarta",
          "website": "http://www.pnj.ac.id/"
       },
       {
          "university": "Politeknik Negeri Lhokseumawe",
          "website": "http://www.pnl.ac.id/"
       },
       {
          "university": "Politeknik Negeri Malang",
          "website": "http://www.poltek-malang.ac.id/"
       },
       {
          "university": "Politeknik Negeri Padang",
          "website": "http://www.polinpdg.ac.id/"
       },
       {
          "university": "Politeknik Negeri Pontianak",
          "website": "http://www.polnep.ac.id/"
       },
       {
          "university": "Politeknik Negeri Sambas",
          "website": "http://www.poltesa.ac.id/"
       },
       {
          "university": "Politeknik Negeri Semarang",
          "website": "http://www.polines.ac.id/"
       },
       {
          "university": "Politeknik Pos Indonesia",
          "website": "http://www.poltekpos.ac.id/"
       },
       {
          "university": "Prasetiya Mulya Business School",
          "website": "http://www.pmbs.ac.id/"
       },
       {
          "university": "Sekolah Tinggi Akuntansi Negara (STAN)",
          "website": "http://www.stan.ac.id/"
       },
       {
          "university": "Stie Perbanas Surabaya",
          "website": "http://www.perbanas.ac.id/"
       },
       {
          "university": "STMIK AMIKOM Yogyakarta",
          "website": "http://www.amikom.ac.id/"
       },
       {
          "university": "STMIK Sinar Nusantara",
          "website": "http://www.sinus.ac.id/"
       },
       {
          "university": "Swiss German University",
          "website": "http://www.sgu.ac.id/"
       },
       {
          "university": "Trisakti Institute of Tourism ",
          "website": "http://www.stptrisakti.ac.id/"
       },
       {
          "university": "Unitomo Surabaya",
          "website": "http://www.unitomo.ac.id/"
       },
       {
          "university": "Universitas 17 Agustus 1945 Banyuwangi",
          "website": "http://www.untag-banyuwangi.ac.id/"
       },
       {
          "university": "Universitas 17 Agustus 1945 Cirebon",
          "website": "http://www.untagcirebon.info/"
       },
       {
          "university": "Universitas 17 Agustus 1945 Jakarta",
          "website": "http://www.untag-jkt.org/"
       },
       {
          "university": "Universitas 17 Agustus 1945 Samarinda",
          "website": "http://www.untag-smd.ac.id/"
       },
       {
          "university": "Universitas 17 Agustus 1945 Semarang",
          "website": "http://www.untagsmg.ac.id/"
       },
       {
          "university": "Universitas 17 Agustus 1945 Surabaya",
          "website": "http://www.untag.ac.id/"
       },
       {
          "university": "Universitas Advent Indonesia",
          "website": "http://www.unai.edu/"
       },
       {
          "university": "Universitas Ahmad Dahlan",
          "website": "http://www.uad.ac.id/"
       },
       {
          "university": "Universitas Airlangga",
          "website": "http://www.unair.ac.id/"
       },
       {
          "university": "Universitas Amir Hamzah",
          "website": "http://www.unhamzah.ac.id/"
       },
       {
          "university": "Universitas Andalas",
          "website": "http://www.unand.ac.id/"
       },
       {
          "university": "Universitas Atma Jaya Yogyakarta",
          "website": "http://www.uajy.ac.id/"
       },
       {
          "university": "Universitas Bakrie",
          "website": "http://www.bakrie.ac.id/"
       },
       {
          "university": "Universitas Bengkulu",
          "website": "http://www.unib.ac.id/"
       },
       {
          "university": "Universitas Bhayangkara Jakarta Raya",
          "website": "http://www.ubharajaya.ac.id/"
       },
       {
          "university": "Universitas Bhayangkara Surabaya",
          "website": "http://www.ubhara.ac.id/"
       },
       {
          "university": "Universitas Bojonegoro",
          "website": "http://www.unigoro.ac.id/"
       },
       {
          "university": "Universitas Brawijaya",
          "website": "http://www.ub.ac.id/"
       },
       {
          "university": "Universitas Bunda Mulia Jakarta",
          "website": "http://www.ubm.ac.id/"
       },
       {
          "university": "Universitas Bung Hatta",
          "website": "http://www.bunghatta.ac.id/"
       },
       {
          "university": "Universitas Cenderawasih",
          "website": "http://www.uncen.ac.id/"
       },
       {
          "university": "Universitas Darma Agung",
          "website": "http://www.uda.ac.id/"
       },
       {
          "university": "Universitas Darma Persada",
          "website": "http://www.unsada.ac.id/"
       },
       {
          "university": "Universitas Dian Nuswantoro",
          "website": "http://www.dinus.ac.id/"
       },
       {
          "university": "Universitas Diponegoro",
          "website": "http://www.undip.ac.id/"
       },
       {
          "university": "Universitas Dr. R. Moestopo",
          "website": "http://www.moestopo.ac.id/"
       },
       {
          "university": "Universitas Gadjah Mada",
          "website": "http://www.ugm.ac.id/"
       },
       {
          "university": "Universitas Gajayana Malang",
          "website": "http://www.unigamalang.ac.id/"
       },
       {
          "university": "Universitas Gunadarma",
          "website": "http://www.gunadarma.ac.id/"
       },
       {
          "university": "Universitas Haluoleo",
          "website": "http://www.unhalu.ac.id/"
       },
       {
          "university": "Universitas Hasanuddin",
          "website": "http://www.unhas.ac.id/"
       },
       {
          "university": "Universitas HKBP Nommensen",
          "website": "http://www.nommensen.org/"
       },
       {
          "university": "Universitas Ibn Chaldun Jakarta",
          "website": "http://www.ibnuchaldun.com/"
       },
       {
          "university": "Universitas Ibn Khaldun Bogor",
          "website": "http://www.uika-bogor.ac.id/"
       },
       {
          "university": "Universitas Indonesia",
          "website": "http://www.ui.ac.id/"
       },
       {
          "university": "Universitas Indonusa Esa Unggul",
          "website": "http://www.indonusa.ac.id/"
       },
       {
          "university": "Universitas Islam Bandung",
          "website": "http://www.unisba.ac.id/"
       },
       {
          "university": "Universitas Islam Indonesia",
          "website": "http://www.uii.ac.id/"
       },
       {
          "university": "Universitas Islam Jakarta",
          "website": "http://www.uinjkt.ac.id/"
       },
       {
          "university": "Universitas Islam Malang",
          "website": "http://www.unisma.ac.id/"
       },
       {
          "university": "Universitas Islam Nusantara",
          "website": "http://www.uninus.ac.id/"
       },
       {
          "university": "Universitas Islam Riau",
          "website": "http://www.uir.ac.id/"
       },
       {
          "university": "Universitas Islam Sultan Agung",
          "website": "http://www.unissula.ac.id/"
       },
       {
          "university": "Universitas Islam Sumatera Utara",
          "website": "http://www.uisu.ac.id/"
       },
       {
          "university": "Universitas Islam Syekh-Yusuf ",
          "website": "http://www.unistangerang.ac.id/"
       },
       {
          "university": "Universitas Jambi",
          "website": "http://www.unja.ac.id/"
       },
       {
          "university": "Universitas Janabadra",
          "website": "http://www.janabadra.ac.id/"
       },
       {
          "university": "Universitas Jayabaya",
          "website": "http://www.jayabaya.ac.id/"
       },
       {
          "university": "Universitas Jember",
          "website": "http://www.unej.ac.id/"
       },
       {
          "university": "Universitas Jenderal Achmad Yani",
          "website": "http://www.unjani.ac.id/"
       },
       {
          "university": "Universitas Jenderal Soedirman",
          "website": "http://www.unsoed.ac.id/"
       },
       {
          "university": "Universitas Katolik Indonesia Atma Jaya",
          "website": "http://www.atmajaya.ac.id/"
       },
       {
          "university": "Universitas Katolik Parahyangan",
          "website": "http://www.unpar.ac.id/"
       },
       {
          "university": "Universitas Katolik Soegijapranoto",
          "website": "http://www.unika.ac.id/"
       },
       {
          "university": "Universitas Katolik Widya Karya",
          "website": "http://www.widyakarya.ac.id/"
       },
       {
          "university": "Universitas Katolik Widya Manadala",
          "website": "http://www.wima.ac.id/"
       },
       {
          "university": "Universitas Kediri",
          "website": "http://www.unik-kediri.ac.id/"
       },
       {
          "university": "Universitas Khairun",
          "website": "http://www.unkhair.ac.id/"
       },
       {
          "university": "Universitas Klabat",
          "website": "http://www.tagnet.org/unklab/"
       },
       {
          "university": "Universitas Komputer Indonesia",
          "website": "http://www.unikom.ac.id/"
       },
       {
          "university": "Universitas Krisnadwipayana",
          "website": "http://www.unkris.ac.id/"
       },
       {
          "university": "Universitas Kristen Duta Wacana",
          "website": "http://www.ukdw.ac.id/"
       },
       {
          "university": "Universitas Kristen Indonesia",
          "website": "http://www.uki.ac.id/"
       },
       {
          "university": "Universitas Kristen Indonesia Tomohon",
          "website": "http://www.ukit-tomohon.org/"
       },
       {
          "university": "Universitas Kristen Krida Wacana",
          "website": "http://www.ukrida.ac.id/"
       },
       {
          "university": "Universitas Kristen Maranatha",
          "website": "http://www.maranatha.edu/"
       },
       {
          "university": "Universitas Kristen Petra",
          "website": "http://www.petra.ac.id/"
       },
       {
          "university": "Universitas Kristen Satya Wacana",
          "website": "http://www.uksw.edu/"
       },
       {
          "university": "Universitas Lambung Mangkurat",
          "website": "http://www.unlam.ac.id/"
       },
       {
          "university": "Universitas Lampung",
          "website": "http://www.unila.ac.id/"
       },
       {
          "university": "Universitas Lancang Kuning",
          "website": "http://unilak.ac.id/"
       },
       {
          "university": "Universitas Ma Chung",
          "website": "http://www.machung.ac.id/"
       },
       {
          "university": "Universitas Madura",
          "website": "http://www.unira-pmk.net/"
       },
       {
          "university": "Universitas Mahasaraswati Denpasar",
          "website": "http://www.unmas.org/"
       },
       {
          "university": "Universitas Mahendradatta",
          "website": "http://www.mahendradatta.org/"
       },
       {
          "university": "Universitas Mataram",
          "website": "http://www.unram.ac.id/"
       },
       {
          "university": "Universitas Mercu Buana",
          "website": "http://www.mercubuana.ac.id/"
       },
       {
          "university": "Universitas Merdeka Madiun",
          "website": "http://www.unmer-madiun.ac.id/"
       },
       {
          "university": "Universitas Merdeka Malang",
          "website": "http://www.unmer.ac.id/"
       },
       {
          "university": "Universitas Methodist Indonesia",
          "website": "http://www.umi-mdn.edu/"
       },
       {
          "university": "Universitas Muhammadiyah Jakarta",
          "website": "http://www.umj.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Jember",
          "website": "http://www.unmuhjember.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Magelang",
          "website": "http://www.ummgl.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Makassar",
          "website": "http://www.unismuh.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Malang",
          "website": "http://www.umm.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Mataram",
          "website": "http://www.unmuhmataram.com/"
       },
       {
          "university": "Universitas Muhammadiyah Sumatra Utara",
          "website": "http://www.umsu.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Surakarta",
          "website": "http://www.ums.ac.id/"
       },
       {
          "university": "Universitas Muhammadiyah Yogyakarta",
          "website": "http://www.umy.ac.id/"
       },
       {
          "university": "Universitas Mulawarman",
          "website": "http://www.unmul.ac.id/"
       },
       {
          "university": "Universitas Muria Kudus",
          "website": "http://www.umk.ac.id/"
       },
       {
          "university": "Universitas Muslim Indonesia",
          "website": "http://www.umi.ac.id/"
       },
       {
          "university": "Universitas Narotama",
          "website": "http://www.narotama.ac.id/"
       },
       {
          "university": "Universitas Nasional Jakarta",
          "website": "http://www.unas.ac.id/"
       },
       {
          "university": "Universitas Nasional Pasim",
          "website": "http://www.pasim.ac.id/"
       },
       {
          "university": "Universitas Negeri Gorontalo",
          "website": "http://www.ung.ac.id/"
       },
       {
          "university": "Universitas Negeri Jakarta",
          "website": "http://www.unj.ac.id/"
       },
       {
          "university": "Universitas Negeri Makassar",
          "website": "http://www.unm.ac.id/"
       },
       {
          "university": "Universitas Negeri Malang",
          "website": "http://www.malang.ac.id/"
       },
       {
          "university": "Universitas Negeri Manado",
          "website": "http://www.unima.ac.id/"
       },
       {
          "university": "Universitas Negeri Medan",
          "website": "http://www.unimed.ac.id/"
       },
       {
          "university": "Universitas Negeri Padang",
          "website": "http://www.unp.ac.id/"
       },
       {
          "university": "Universitas Negeri Semarang",
          "website": "http://www.unnes.ac.id/"
       },
       {
          "university": "Universitas Negeri Surabaya",
          "website": "http://www.unesa.ac.id/"
       },
       {
          "university": "Universitas Negeri Yogyakarta",
          "website": "http://www.uny.ac.id/"
       },
       {
          "university": "Universitas Ngurah Rai",
          "website": "http://www.unr.ac.id/"
       },
       {
          "university": "Universitas Nusa Cendana",
          "website": "http://www.undana.ac.id/"
       },
       {
          "university": "Universitas Padjadjaran",
          "website": "http://www.unpad.ac.id/"
       },
       {
          "university": "Universitas Pakuan",
          "website": "http://www.unpak.ac.id/"
       },
       {
          "university": "Universitas Palangka Raya",
          "website": "http://www.upr.ac.id/"
       },
       {
          "university": "Universitas Pancasakti Tegal",
          "website": "http://www.upstegal.ac.id/"
       },
       {
          "university": "Universitas Pancasila",
          "website": "http://www.univpancasila.ac.id/"
       },
       {
          "university": "Universitas Paramadina Mulya",
          "website": "http://www.paramadina.ac.id/"
       },
       {
          "university": "Universitas Pasundan",
          "website": "http://www.unpas.ac.id/"
       },
       {
          "university": "Universitas Pattimura",
          "website": "http://www.unpatti.ac.id/"
       },
       {
          "university": "Universitas Pekalongan",
          "website": "http://www.unikal.ac.id/"
       },
       {
          "university": "Universitas Pelita Harapan",
          "website": "http://www.uph.edu/"
       },
       {
          "university": "Universitas Pembangunan Nasional Veteran East Java",
          "website": "http://www.upnjatim.ac.id/"
       },
       {
          "university": "Universitas Pembangunan Panca Budi",
          "website": "http://www.pancabudi.ac.id/"
       },
       {
          "university": "Universitas Pendidikan Indonesia",
          "website": "http://www.upi.edu/"
       },
       {
          "university": "Universitas Persada Indonesia Y.A.I",
          "website": "http://www.yai.ac.id/"
       },
       {
          "university": "Universitas Pesantren Darul Ulum Jombang",
          "website": "http://www.unipdu.ac.id/"
       },
       {
          "university": "Universitas Proklamasi 45",
          "website": "http://www.up45.ac.id/"
       },
       {
          "university": "Universitas Putera Batam",
          "website": "http://www.puterabatam.ac.id/"
       },
       {
          "university": "Universitas Riau",
          "website": "http://www.unri.ac.id/"
       },
       {
          "university": "Universitas Sam Ratulangi",
          "website": "http://www.unsrat.ac.id/"
       },
       {
          "university": "Universitas Sanata Dharma",
          "website": "http://www.usd.ac.id/"
       },
       {
          "university": "Universitas Sarjanawiyata Tamansiswa",
          "website": "http://www.sarjanawiyata.ac.id/"
       },
       {
          "university": "Universitas Sebelas Maret",
          "website": "http://www.uns.ac.id/"
       },
       {
          "university": "Universitas Semarang",
          "website": "http://www.usm.ac.id/"
       },
       {
          "university": "Universitas Siliwangi",
          "website": "http://www.unsil.ac.id/"
       },
       {
          "university": "Universitas Simalungun",
          "website": "http://www.usi.ac.id/"
       },
       {
          "university": "Universitas Slamet Riyadi Surakarta",
          "website": "http://www.unisri.ac.id/"
       },
       {
          "university": "Universitas Sriwijaya",
          "website": "http://www.unsri.ac.id/"
       },
       {
          "university": "Universitas Sultan Ageng Tirtayasa",
          "website": "http://www.untirta.ac.id/"
       },
       {
          "university": "Universitas Sumatera Utara",
          "website": "http://www.usu.ac.id/"
       },
       {
          "university": "Universitas Surabaya",
          "website": "http://www.ubaya.ac.id/"
       },
       {
          "university": "Universitas Swadaya Gunung Djati",
          "website": "http://www.unswagati-crb.ac.id/"
       },
       {
          "university": "Universitas Syiah Kuala",
          "website": "http://www.unsyiah.ac.id/"
       },
       {
          "university": "Universitas Tadulako",
          "website": "http://www.untad.ac.id/"
       },
       {
          "university": "Universitas Tanjungpura",
          "website": "http://www.untan.ac.id/"
       },
       {
          "university": "Universitas Tarumanagara",
          "website": "http://www.untar.ac.id/"
       },
       {
          "university": "Universitas Terbuka Indonesia",
          "website": "http://www.ut.ac.id/"
       },
       {
          "university": "Universitas Tidar Magelang",
          "website": "http://www.utm.ac.id/"
       },
       {
          "university": "Universitas Trilogi",
          "website": "http://www.universitas-trilogi.ac.id/"
       },
       {
          "university": "Universitas Trisakti",
          "website": "http://www.trisakti.ac.id/"
       },
       {
          "university": "Universitas Tunas Pembangunan",
          "website": "http://www.utp.ac.id/"
       },
       {
          "university": "Universitas Udayana",
          "website": "http://www.unud.ac.id/"
       },
       {
          "university": "Universitas Widya Gama Malang",
          "website": "http://www.widyagama.ac.id/"
       },
       {
          "university": "Universitas Widyatama",
          "website": "http://www.widyatama.ac.id/"
       },
       {
          "university": "Universitas Wijaya Kusuma Purwokerto",
          "website": "http://www.unwiku.ac.id/"
       },
       {
          "university": "Universitas Wijaya Kusuma Surabaya",
          "website": "http://www.wijayakusumasby.ac.id/"
       },
       {
          "university": "Universitas Wisnuwardhana",
          "website": "http://www.wisnuwardhana.ac.id/"
       },
       {
          "university": "Universitas Yarsi",
          "website": "http://www.yarsi.ac.id/"
       }
    ],
    "IE": [
       {
          "university": "Athlone Institute of Technology",
          "website": "http://www.ait.ie/"
       },
       {
          "university": "Carlow Institute of Technology",
          "website": "http://www.itcarlow.ie/"
       },
       {
          "university": "Cork Institute of Technology",
          "website": "http://www.cit.ie/"
       },
       {
          "university": "Dublin City University",
          "website": "http://www.dcu.ie/"
       },
       {
          "university": "Dublin Institute for Advanced Studies",
          "website": "http://www.dias.ie/"
       },
       {
          "university": "Dublin Institute of Technology",
          "website": "http://www.dit.ie/"
       },
       {
          "university": "Galway Mayo Institute of Technology",
          "website": "http://www.gmit.ie/"
       },
       {
          "university": "Griffith College",
          "website": "http://www.gcd.ie/"
       },
       {
          "university": "Institue of Technology - Tralee",
          "website": "http://www.ittralee.ie/"
       },
       {
          "university": "Irish International University (Distance Education)",
          "website": "http://www.iiuedu.ie/"
       },
       {
          "university": "LSB College",
          "website": "http://www.lsb.ie/"
       },
       {
          "university": "National College of Ireland",
          "website": "http://www.ncirl.ie/"
       },
       {
          "university": "National University of Ireland",
          "website": "http://www.nui.ie/"
       },
       {
          "university": "National University of Ireland - Galway",
          "website": "http://www.ucg.ie/"
       },
       {
          "university": "National University of Ireland - Maynooth",
          "website": "http://www.may.ie/"
       },
       {
          "university": "Royal College of Physicians of Ireland",
          "website": "http://www.rcpi.ie/"
       },
       {
          "university": "Royal College of Surgeons",
          "website": "http://www.rcsi.ie/"
       },
       {
          "university": "Shannon College of Hotel Management",
          "website": "http://www.shannoncollege.com/"
       },
       {
          "university": "University College Cork",
          "website": "http://www.ucc.ie/"
       },
       {
          "university": "University College Dublin",
          "website": "http://www.ucd.ie/"
       },
       {
          "university": "University of Dublin - Trinity College",
          "website": "http://www.tcd.ie/"
       },
       {
          "university": "University of Limerick",
          "website": "http://www.ul.ie/"
       },
       {
          "university": "Warnborough University",
          "website": "http://www.warnborough.edu/"
       },
       {
          "university": "Waterford Institute Of Technology",
          "website": "http://www.wit.ie/"
       }
    ],
    "IL": [
       {
          "university": "Acdemic Center for Law and Business",
          "website": "http://www.clb.ac.il/"
       },
       {
          "university": "Afeka Tel Aviv Academic College of Engineering",
          "website": "http://www.afeka.ac.il/"
       },
       {
          "university": "Ariel University Center of Samaria",
          "website": "http://www.ariel.ac.il/"
       },
       {
          "university": "Ashkelon Academic College",
          "website": "http://www.ash-college.ac.il/"
       },
       {
          "university": "Bar-Ilan University",
          "website": "http://www.biu.ac.il/"
       },
       {
          "university": "Ben-Gurion University of the Negev",
          "website": "http://www.bgu.ac.il/"
       },
       {
          "university": "Bezalel Academy of Art and Design",
          "website": "http://www.bezalel.ac.il/"
       },
       {
          "university": "College of Management",
          "website": "http://www.colman.ac.il/"
       },
       {
          "university": "Emeq Yizrael College",
          "website": "http://www.yvc.ac.il/"
       },
       {
          "university": "Galillee College",
          "website": "http://www.galilcol.ac.il/"
       },
       {
          "university": "Hebrew University of Jerusalem",
          "website": "http://www.huji.ac.il/"
       },
       {
          "university": "Jerusalem University College",
          "website": "http://www.juc.edu/"
       },
       {
          "university": "Open University of Israel",
          "website": "http://www.openu.ac.il/"
       },
       {
          "university": "Russell Berrie Nanotechnology Institute",
          "website": "http://rbni.technion.ac.il/"
       },
       {
          "university": "Sami Shamoon College of Engineering",
          "website": "http://www.sce.ac.il/"
       },
       {
          "university": "Shenkar School of Engineering & Design",
          "website": "http://www.shenkar.ac.il/"
       },
       {
          "university": "Technion - Israel Institute of Technology",
          "website": "http://www.technion.ac.il/"
       },
       {
          "university": "Tel Aviv University",
          "website": "http://www.tau.ac.il/"
       },
       {
          "university": "The Interdisciplinary Center Herzliya",
          "website": "http://www.idc.ac.il/"
       },
       {
          "university": "University of Haifa",
          "website": "http://www.haifa.ac.il/"
       },
       {
          "university": "Weizmann Institute of Science",
          "website": "http://www.weizmann.ac.il/"
       },
       {
          "university": "Western Galilee College",
          "website": "http://www.wgalil.ac.il/"
       }
    ],
    "IN": [
       {
          "university": "Acharya Nagarjuna University",
          "website": "http://www.nagarjunauniversity.ac.in/"
       },
       {
          "university": "Acharya Ranga Agricultural University",
          "website": "http://www.angrau.net/"
       },
       {
          "university": "Ahmedabad University",
          "website": "http://www.ahduni.edu.in/"
       },
       {
          "university": "AISECT University",
          "website": "http://www.aisectuniversity.ac.in/"
       },
       {
          "university": "Alagappa University",
          "website": "http://www.alagappauniversity.ac.in/"
       },
       {
          "university": "Aligarh Muslim University",
          "website": "http://www.amu.ac.in/"
       },
       {
          "university": "Allahabad Agricultural Institute - Deemed University",
          "website": "http://www.aaidu.org/"
       },
       {
          "university": "Allahabad University",
          "website": "http://www.alldunivpio.org/"
       },
       {
          "university": "All India Institute of Medical Sciences",
          "website": "http://www.aiims.ac.in/"
       },
       {
          "university": "Amity University",
          "website": "http://www.amity.edu/"
       },
       {
          "university": "Amravati University",
          "website": "http://www.amtuni.com/"
       },
       {
          "university": "Amrita Vishwa Vidyapeetham (Deemed University)",
          "website": "http://www.amrita.edu/"
       },
       {
          "university": "Andhra University",
          "website": "http://www.andhrauniversity.info/"
       },
       {
          "university": "Annamalai University",
          "website": "http://www.annamalaiuniversity.ac.in/"
       },
       {
          "university": "Anna University",
          "website": "http://www.annauniv.edu/"
       },
       {
          "university": "Anna University of Technology - Tirunelveli",
          "website": "http://www.annauniv.edu.in/"
       },
       {
          "university": "ARYA Institute of Engineering & Technology",
          "website": "http://www.aryainstitutejpr.com/"
       },
       {
          "university": "Asian Business School",
          "website": "http://www.abs.edu.in/"
       },
       {
          "university": "Assam Agricultural University",
          "website": "http://www.aau.ac.in/"
       },
       {
          "university": "Assam University",
          "website": "http://www.assamuniversity.nic.in/"
       },
       {
          "university": "Avinashilingam Institute for Home Science and Higher Education for Women",
          "website": "http://www.tnuniv.ac.in/adu/"
       },
       {
          "university": "Awadhesh Pratap Singh University",
          "website": "http://www.apsurewa.nic.in/"
       },
       {
          "university": "Babasaheb Bhimrao Ambedkar University",
          "website": "http://www.bbauindia.org/"
       },
       {
          "university": "Banaras Hindu University",
          "website": "http://www.bhu.ac.in/"
       },
       {
          "university": "Banasthali University",
          "website": "http://www.banasthali.org/"
       },
       {
          "university": "Bangalore University",
          "website": "http://bengaluruuniversity.com/"
       },
       {
          "university": "Bankura University",
          "website": "http://www.bankurauniv.com/"
       },
       {
          "university": "Barkatullah University",
          "website": "http://www.bubhopal.nic.in/"
       },
       {
          "university": "Bengal Engineering College",
          "website": "http://www.becs.ac.in/"
       },
       {
          "university": "Berhampur University",
          "website": "http://www.bamu.nic.in/"
       },
       {
          "university": "Bhagwant University",
          "website": "http://www.bhagwantuniversity.com/"
       },
       {
          "university": "Bharathiar University",
          "website": "http://www.bharathi.ernet.in/"
       },
       {
          "university": "Bharathidasan University",
          "website": "http://www.bdu.ernet.in/"
       },
       {
          "university": "Bharath Institue Of Higher Education & Research",
          "website": "http://www.bharathuniv.com/"
       },
       {
          "university": "Bharati Vidyapeeth University",
          "website": "http://www.bharatividyapeeth.edu/"
       },
       {
          "university": "Bhavnagar University",
          "website": "http://www.bhavuni.edu/"
       },
       {
          "university": "Bhupendra Narayan Mandal University",
          "website": "http://www.bnmu.in/"
       },
       {
          "university": "Bidhan Chandra Agricultural University",
          "website": "http://www.bckv.edu.in/"
       },
       {
          "university": "Biju Pattnaik University of Technology",
          "website": "http://www.bput.org/"
       },
       {
          "university": "Birla Institute of Technology and Science",
          "website": "http://www.bits-pilani.ac.in/"
       },
       {
          "university": "Birla Institute of Technology - Ranchi",
          "website": "http://bitmesraranchi.org/"
       },
       {
          "university": "Birsa Agricultural University",
          "website": "http://www.bau.nic.in/"
       },
       {
          "university": "B. R. Ambedkar Bihar University",
          "website": "http://www.brabu.net/"
       },
       {
          "university": "B. S. Abdur Rahman University",
          "website": "http://www.bsauniv.ac.in/"
       },
       {
          "university": "Bundelkhand University",
          "website": "http://www.bundelkhanduniversity.org/"
       },
       {
          "university": "Burdwan University",
          "website": "http://www.buruniv.ac.in/"
       },
       {
          "university": "CCS Haryana Agricultural University",
          "website": "http://hau.ernet.in/"
       },
       {
          "university": "Center for Entrepreneurship and Small Business Management",
          "website": "http://www.cesbm.ac.in/"
       },
       {
          "university": "Central Agricultural University",
          "website": "http://www.cau.org.in/"
       },
       {
          "university": "Central Institute of English and Foreign Languages",
          "website": "http://www.ciefl.org/"
       },
       {
          "university": "Central Institute of Fisheries Education",
          "website": "http://www.cife.edu.in/"
       },
       {
          "university": "Central Institute of Higher Tibetan Studies",
          "website": "http://www.cihts.ac.in/"
       },
       {
          "university": "Chandra Shekhar Azad University of Agriculture and Technology",
          "website": "http://www.csauk.ac.in/"
       },
       {
          "university": "Chaudhary Charan Singh Haryana Agricultural University",
          "website": "http://www.hau.ernet.in/"
       },
       {
          "university": "Chaudhary Charan Singh University",
          "website": "http://www.ccsuniversity.ac.in/"
       },
       {
          "university": "Ch. Charan Singh University",
          "website": "http://www.ccsuniversity.org/"
       },
       {
          "university": "Chhatrapati Shahu Ji Maharaj University",
          "website": "http://www.kanpuruniversity.org/"
       },
       {
          "university": "Christ University",
          "website": "http://www.christuniversity.in/"
       },
       {
          "university": "CMJ University",
          "website": "http://www.cmjuniversity.edu.in/"
       },
       {
          "university": "Cochin University of Science and Technology",
          "website": "http://www.cusat.ac.in/"
       },
       {
          "university": "Darul Huda Islamic University",
          "website": "http://www.darulhuda.com/"
       },
       {
          "university": "Dav University",
          "website": "http://www.davuniversity.org/"
       },
       {
          "university": "Dayalbagh Educational Institute",
          "website": "http://www.dei.ac.in/"
       },
       {
          "university": "Deccan College Postgraduate and Research Institute",
          "website": "http://www.deccancollegepune.ac.in/"
       },
       {
          "university": "Deen Dayal Upadhyay Gorakhpur University",
          "website": "http://www.ddugu.edu.in/"
       },
       {
          "university": "Delhi College of Engineering (DCE)",
          "website": "http://www.dceonline.net/"
       },
       {
          "university": "Devi Ahilya University of Indore",
          "website": "http://www.davv.ac.in/"
       },
       {
          "university": "Dhirubhai Ambani Institute of Information and Communication Technology",
          "website": "http://www.da-iict.org/"
       },
       {
          "university": "Dibrugarh University",
          "website": "http://www.dibru.ernet.in/"
       },
       {
          "university": "Dravidian University",
          "website": "http://www.dravidianuniversity.ac.in/"
       },
       {
          "university": "Dr. Babasaheb Ambedkar Marathwada Universtiy",
          "website": "http://www.bamu.net/"
       },
       {
          "university": "Dr. Babasaheb Ambedkar Technological University",
          "website": "http://www.dbatechuni.org/"
       },
       {
          "university": "Dr. Bhim Rao Abdekar University",
          "website": "http://www.brauagra.com/"
       },
       {
          "university": "Dr. B.R. Ambedkar Open University",
          "website": "http://www.braou.ac.in/"
       },
       {
          "university": "Dr. C.V. Raman University",
          "website": "http://www.cvru.ac.in/"
       },
       {
          "university": "Dr. D.Y. Patil University",
          "website": "http://www.dypatiluniversity.org/"
       },
       {
          "university": "Dr. Hari Singh Gour University - formerly University of Sagar",
          "website": "http://www.sagaruniversity.nic.in/"
       },
       {
          "university": "Dr. Panjabrao Deshmukh Krishi Vidyapeeth",
          "website": "http://www.pdkv.ac.in/"
       },
       {
          "university": "Dr. Ram Manohar Lohia Avadh University",
          "website": "http://www.rmlau.ac.in/"
       },
       {
          "university": "Dr. YS Parmar University of Horticulture and Forestry",
          "website": "http://www.ysparmaruniversity.org/"
       },
       {
          "university": "Eiilm University",
          "website": "http://www.eiilmuniversity.ac.in/"
       },
       {
          "university": "Engineering Colleges in Tamil Nadu",
          "website": "http://www.sonatech.ac.in/"
       },
       {
          "university": "Fakir Mohan University",
          "website": "http://www.fmuniversity.nic.in/"
       },
       {
          "university": "Fatima mata national college kollam kerala",
          "website": "http://www.fatimacollege.net/"
       },
       {
          "university": "First Global University to teaching Jainism",
          "website": "http://www.jainuniversity.org/"
       },
       {
          "university": "Forest Research Institute Dehradun",
          "website": "http://fri.icfre.gov.in/"
       },
       {
          "university": "Gandhigram Rural Institute",
          "website": "http://www.tnuniv.ac.in/gri/"
       },
       {
          "university": "Gandhi Institute of Technology and Managment",
          "website": "http://www.gitam.edu/"
       },
       {
          "university": "Gauhati University",
          "website": "http://www.gu.nic.in/"
       },
       {
          "university": "Global Open University  - Nagaland",
          "website": "http://www.government.ind.in/"
       },
       {
          "university": "Goa University",
          "website": "http://www.unigoa.ac.in/"
       },
       {
          "university": "Gokhale Institute of Politics and Economics",
          "website": "http://www.gipe.ernet.in/"
       },
       {
          "university": "Govind Ballabh Pant University of Agriculture and Technology",
          "website": "http://www.gbpuat.ac.in/"
       },
       {
          "university": "Gujarat Ayurved University",
          "website": "http://www.ayurveduniversity.com/"
       },
       {
          "university": "Gujarat Technological University Ahmedabad",
          "website": "http://www.gtu.ac.in/"
       },
       {
          "university": "Gujarat University Ahmedabad",
          "website": "http://www.gujaratuniversity.org.in/"
       },
       {
          "university": "Gulbarga University",
          "website": "http://www.gulbargauniversity.kar.nic.in/"
       },
       {
          "university": "Guru Ghasidas University",
          "website": "http://www.ggu.ac.in/"
       },
       {
          "university": "Guru Gobind Singh Indraprastha University",
          "website": "http://www.ipu.ac.in/"
       },
       {
          "university": "Guru Jambeshwar University",
          "website": "http://www.gju.ernet.in/"
       },
       {
          "university": "Gurukula Kangri University",
          "website": "http://www.gkvharidwar.org/"
       },
       {
          "university": "Gurukul University",
          "website": "http://www.gurukuluniversity.org/"
       },
       {
          "university": "Guru Nanak Dev University",
          "website": "http://www.gnduonline.org/"
       },
       {
          "university": "Hemchandracharay North Gujarat University",
          "website": "http://www.ngu.ac.in/"
       },
       {
          "university": "Hemwati Nandan Bahuguna Garhwal University",
          "website": "http://www.hnbgu.ac.in/"
       },
       {
          "university": "Hidayatullah National Law University - Raipur",
          "website": "http://www.hnluraipur.com/"
       },
       {
          "university": "Himachal Pradesh Agricultural University",
          "website": "http://hillagric.ernet.in/"
       },
       {
          "university": "Himachal Pradesh University",
          "website": "http://hpuniv.nic.in/"
       },
       {
          "university": "IASE (Institute of Advanced Studies) Deemed University",
          "website": "http://www.iaseduniv.org/"
       },
       {
          "university": "ICFAI University",
          "website": "http://www.icfai.org/"
       },
       {
          "university": "Indian Agricultural Research Institute",
          "website": "http://www.iari.ernet.in/"
       },
       {
          "university": "Indian Board of Alternative Medicine",
          "website": "http://www.altmedworld.net/"
       },
       {
          "university": "Indian Institute of Information Technology",
          "website": "http://www.iiita.com/"
       },
       {
          "university": "Indian Institute of Information Technology and Management - Kerala",
          "website": "http://www.iiitmk.ac.in/"
       },
       {
          "university": "Indian Institute of Management - Tiruchirappalli",
          "website": "http://www.iimtrichy.ac.in/"
       },
       {
          "university": "Indian Institute of Science",
          "website": "http://www.iisc.ernet.in/"
       },
       {
          "university": "Indian Institute of Technology - Bombay",
          "website": "http://www.iitb.ac.in/"
       },
       {
          "university": "Indian Institute of Technology - Delhi",
          "website": "http://www.iitd.ernet.in/"
       },
       {
          "university": "Indian Institute of Technology - Guwahati",
          "website": "http://www.iitg.ernet.in/"
       },
       {
          "university": "Indian Institute of Technology - Hyderabad",
          "website": "http://www.iith.ac.in/"
       },
       {
          "university": "Indian Institute of Technology - Kanpur",
          "website": "http://www.iitk.ac.in/"
       },
       {
          "university": "Indian Institute of Technology - Kharagpur",
          "website": "http://www.iitkgp.ernet.in/"
       },
       {
          "university": "Indian Institute of Technology - Madras",
          "website": "http://www.iitm.ac.in/"
       },
       {
          "university": "Indian Institute of Technology - Roorkee",
          "website": "http://www.iitr.ernet.in/"
       },
       {
          "university": "Indian Institute of Technology - Roorkee",
          "website": "http://www.iitr.ac.in/"
       },
       {
          "university": "Indian Law Institue",
          "website": "http://www.ilidelhi.org/"
       },
       {
          "university": "Indian School of Business Management and Administration",
          "website": "http://isbm.org.in/"
       },
       {
          "university": "Indian School of Mines (ISM)",
          "website": "http://www.ismdhanbad.ac.in/"
       },
       {
          "university": "Indian Statistical Institute",
          "website": "http://www.isical.ac.in/"
       },
       {
          "university": "Indian University",
          "website": "http://www.iur.ac.in/"
       },
       {
          "university": "Indian Veterinary Research Institute",
          "website": "http://www.ivri.nic.in/"
       },
       {
          "university": "Indira Gandhi Agricultural University",
          "website": "http://www.igau.edu.in/"
       },
       {
          "university": "Indira Gandhi Institute of Development and Research",
          "website": "http://www.igidr.ac.in/"
       },
       {
          "university": "Indira Gandhi Institute of Medical Sciences",
          "website": "http://www.igims.org/"
       },
       {
          "university": "Indira Gandhi National Open University",
          "website": "http://www.ignou.ac.in/"
       },
       {
          "university": "Institute of Management and Technical Studies ",
          "website": "http://www.imtsinstitute.com/"
       },
       {
          "university": "Institute of Technology and Management",
          "website": "http://www.itmindia.edu/"
       },
       {
          "university": "Integral University",
          "website": "http://www.integraluniversity.ac.in/"
       },
       {
          "university": "International Institute for Population Sciences",
          "website": "http://www.iipsindia.org/"
       },
       {
          "university": "Jadavpur University",
          "website": "http://www.jadavpur.edu/"
       },
       {
          "university": "Jagran Lakecity University",
          "website": "http://www.jlu.edu.in/"
       },
       {
          "university": "Jai Narayan Vyas University",
          "website": "http://www.jnvu.edu.in/"
       },
       {
          "university": "Jain University - Centre for Management Studies",
          "website": "http://www.cms.ac.in/"
       },
       {
          "university": "Jain Vishva Bharati Institute",
          "website": "http://www.jvbi.ac.in/"
       },
       {
          "university": "Jai Prakash University",
          "website": "http://jpv.bih.nic.in/"
       },
       {
          "university": "Jaipur National University",
          "website": "http://www.jnujaipur.ac.in/"
       },
       {
          "university": "Jamia Hamdard University",
          "website": "http://www.jamiahamdard.edu/"
       },
       {
          "university": "Jamia Millia Islamia University",
          "website": "http://jmi.nic.in/"
       },
       {
          "university": "Jawaharlal Nehru Agricultural University",
          "website": "http://www.jnkvv.nic.in/"
       },
       {
          "university": "Jawaharlal Nehru Centre for Advanced  Scientific Research",
          "website": "http://www.jncasr.ac.in/"
       },
       {
          "university": "Jawaharlal Nehru Technological University",
          "website": "http://www.jntu.ac.in/"
       },
       {
          "university": "Jawaharlal Nehru University",
          "website": "http://www.jnu.ac.in/"
       },
       {
          "university": "Jaypee Institute of Information Technology",
          "website": "http://www.jiit.ac.in/"
       },
       {
          "university": "Jiwaji University",
          "website": "http://www.jiwaji.edu/"
       },
       {
          "university": "Jodhpur National University",
          "website": "http://www.jodhpurnationaluniversity.com/"
       },
       {
          "university": "Kakatiya University",
          "website": "http://www.kuwarangal.com/"
       },
       {
          "university": "Kalinga University",
          "website": "http://www.kalingauniversity.com/"
       },
       {
          "university": "Kameshwar Singh Darbhanga Sanskrit University",
          "website": "http://www.ksdsu.edu.in/"
       },
       {
          "university": "Kamrah International Institute of Technology (KIIT)",
          "website": "http://www.kiit.in/"
       },
       {
          "university": "Kannada University",
          "website": "http://www.kannadauniversity.org/"
       },
       {
          "university": "Kannur University",
          "website": "http://www.kannuruniversity.ac.in/"
       },
       {
          "university": "Kanpur University",
          "website": "http://www.kanpuruniversity.org/"
       },
       {
          "university": "Karnataka State Law University",
          "website": "http://www.kslu.ac.in/"
       },
       {
          "university": "Karnataka State Open University",
          "website": "http://www.karnatakastateopenuniversity.in/"
       },
       {
          "university": "Karnatak University",
          "website": "http://www.kud.ac.in/"
       },
       {
          "university": "Karpagam University",
          "website": "http://www.karpagamuniv.com/"
       },
       {
          "university": "Karunya Institute Of Technology ( Deemed University)",
          "website": "http://www.karunya.edu/"
       },
       {
          "university": "Kerala Agricultural University",
          "website": "http://www.kau.edu/"
       },
       {
          "university": "Kerala University of Fisheries and Ocean Sciences",
          "website": "http://www.kufos.ac.in/"
       },
       {
          "university": "Kohinoor School of Hospitality Management",
          "website": "http://kimi.kohinoor.ac.in/"
       },
       {
          "university": "Konkan Agricultural University",
          "website": "http://www.dbskkv.org/"
       },
       {
          "university": "Krishna University",
          "website": "http://www.krishnauniversity.ac.in/"
       },
       {
          "university": "Kumaun University",
          "website": "http://www.kumaununiversity.org/"
       },
       {
          "university": "Kurukshetra University",
          "website": "http://www.kukinfo.com/"
       },
       {
          "university": "Kuvempu University",
          "website": "http://www.kuvempu.ac.in/"
       },
       {
          "university": "Lakshmibai National Institute of Physical Education",
          "website": "http://www.lnipe.edu/"
       },
       {
          "university": "Lalit Narayan Mithila University",
          "website": "http://lnmu.bih.nic.in/"
       },
       {
          "university": "L.D.College of Engineering",
          "website": "http://www.ldceahd.org/"
       },
       {
          "university": "Madhya Pradesh Bhoj (Open) University",
          "website": "http://www.mpbou.org/"
       },
       {
          "university": "Madurai Kamaraj University",
          "website": "http://www.mkuhyd.com/"
       },
       {
          "university": "Magadh University",
          "website": "http://www.magadhuniversity.org/"
       },
       {
          "university": "Maharaja Ganga Singh University - Bikaner",
          "website": "http://www.mgsubikaner.ac.in/"
       },
       {
          "university": "Maharaja Sayajirao University of Baroda",
          "website": "http://www.msubaroda.ac.in/"
       },
       {
          "university": "Maharishi Dayanand University - Rohtak (Haryana )",
          "website": "http://www.mdurohtak.com/"
       },
       {
          "university": "Maharshi Dayanand Sarswati University Ajmer",
          "website": "http://www.mdsuajmer.ac.in/"
       },
       {
          "university": "Mahatma Gandhi Chitrakut Gramoday University",
          "website": "http://www.ruraluniversity-chitrakoot.org/"
       },
       {
          "university": "Mahatma Gandhi Kashi Vidyapith ",
          "website": "http://www.mgkvp.ac.in/"
       },
       {
          "university": "Mahatma Gandhi University - Kerala",
          "website": "http://www.mgu.ac.in/"
       },
       {
          "university": "Mahatma Gandhi University Meghalaya ",
          "website": "http://www.mgu.edu.in/"
       },
       {
          "university": "Mahatma Jyotiba Phule Rohilkhand University Bareilly ",
          "website": "http://www.mjpru.ac.in/"
       },
       {
          "university": "Mahatma Phule Agricultural University",
          "website": "http://mpkv.mah.nic.in/"
       },
       {
          "university": "Makanlal Chutur Vedi University",
          "website": "http://www.mcu.ac.in/"
       },
       {
          "university": "Makhanlal Chaturvedi National University of Journalism and Communication",
          "website": "http://www.mcu.ac.in/"
       },
       {
          "university": "Mangalore University",
          "website": "http://www.mangaloreuniversity.ac.in/"
       },
       {
          "university": "Manipal University",
          "website": "http://www.manipal.edu/"
       },
       {
          "university": "Manipur University",
          "website": "http://www.manipur.nic.in/maniuniversity.htm"
       },
       {
          "university": "Manonmaniam Sundaranar University",
          "website": "http://www.tnuniv.ac.in/msu/"
       },
       {
          "university": "Mar Athanasios College for Advanced Studies",
          "website": "http://www.macfast.org/"
       },
       {
          "university": "Marathwada Agricultural University",
          "website": "http://mkv2.mah.nic.in/"
       },
       {
          "university": "Mohan Lal Sukhadia University",
          "website": "http://www.mlsu.ac.in/"
       },
       {
          "university": "Mother Teresa Women's University",
          "website": "http://www.tnuniv.ac.in/mteresa/"
       },
       {
          "university": "Motilal Nehru National Institute Of Technology",
          "website": "http://www.mnnit.ac.in/"
       },
       {
          "university": "Moulana Azad national Urdu University",
          "website": "http://www.manuu.ac.in/"
       },
       {
          "university": "Nagaland University",
          "website": "http://www.nagauniv.org.in/"
       },
       {
          "university": "Nagpur University",
          "website": "http://www.nagpur-university.com/"
       },
       {
          "university": "Nalanda Open University",
          "website": "http://www.nalandaopenuniversity.info/"
       },
       {
          "university": "Narendra Deva University of Agriculture and Technology",
          "website": "http://www.nduat.nic.in/"
       },
       {
          "university": "Narsee Monjee Institute of Management Studies",
          "website": "http://www.nmims.edu/"
       },
       {
          "university": "National Dairy Research Institute",
          "website": "http://ndri.nic.in/"
       },
       {
          "university": "National Institute of Industrial Engineering",
          "website": "http://www.nitie.edu/"
       },
       {
          "university": "National Institute of Mental Health and Neuro Sciences",
          "website": "http://www.nimhans.kar.nic.in/"
       },
       {
          "university": "National Institute of Technology - Calicut",
          "website": "http://www.nitc.ac.in/"
       },
       {
          "university": "National Institute of Technology - Durgapur",
          "website": "http://www.nitdgp.ac.in/"
       },
       {
          "university": "National Institute of Technology - Jamshedpur",
          "website": "http://www.nitjsr.ac.in/"
       },
       {
          "university": "National Institute of Technology Kurukshetra",
          "website": "http://www.nitkkr.net/"
       },
       {
          "university": "National Institute of Technology - Rourkela",
          "website": "http://www.nitrkl.ac.in/"
       },
       {
          "university": "National Institute of Technology - Trichy",
          "website": "http://www.nitt.edu/"
       },
       {
          "university": "National Institute of Technology - Warangal",
          "website": "http://www.nitw.ernet.in/"
       },
       {
          "university": "National Law School of India University",
          "website": "http://www.nls.ac.in/"
       },
       {
          "university": "National Museum Institute of History of Art - Conservation and Museology",
          "website": "http://www.nationalmuseumindia.org/nmi/index.htm"
       },
       {
          "university": "National Sanskrit University",
          "website": "http://www.sanskrit.nic.in/"
       },
       {
          "university": "Netaji Subhas Inst of Technology",
          "website": "http://www.nsit.ac.in/"
       },
       {
          "university": "Nirma University",
          "website": "http://www.nirmauni.ac.in/"
       },
       {
          "university": "Nizam's Institute of Medical Sciences",
          "website": "http://nims.ap.nic.in/"
       },
       {
          "university": "North Bengal University",
          "website": "http://www.nbu.ac.in/"
       },
       {
          "university": "North Eastern Hill University",
          "website": "http://www.nehu.ac.in/"
       },
       {
          "university": "North Maharashtra University",
          "website": "http://www.nmu.ac.in/"
       },
       {
          "university": "Open International University for Alternative Medicines",
          "website": "http://www.altmeduniversity.com/"
       },
       {
          "university": "Orissa University of Agriculture and Technology",
          "website": "http://bhub.ori.nic.in/ouat/"
       },
       {
          "university": "Osmania University",
          "website": "http://www.osmania.ac.in/"
       },
       {
          "university": "Pandit Ravi Shankar Shukla University",
          "website": "http://www.rsuniversity.com/"
       },
       {
          "university": "Panjab University",
          "website": "http://www.puchd.ac.in/"
       },
       {
          "university": "Patna University",
          "website": "http://puonline.bih.nic.in/"
       },
       {
          "university": "Periyar University",
          "website": "http://www.spuniv.org/"
       },
       {
          "university": "Pes University",
          "website": "http://www.pes.edu/"
       },
       {
          "university": "Pondicherry University",
          "website": "http://www.pondiuni.org/"
       },
       {
          "university": "Postgraduate lnstitute of Medical Education and Research",
          "website": "http://www.pgimer.nic.in/"
       },
       {
          "university": "Potti Sreeramulu Telugu University",
          "website": "http://www.teluguuniversity.ac.in/"
       },
       {
          "university": "PSG College of Technology",
          "website": "http://www.psgtech.edu/"
       },
       {
          "university": "Punjab Agricultural University",
          "website": "http://www.pau.edu/"
       },
       {
          "university": "Punjab Engineering College Deemed University",
          "website": "http://www.pec.ac.in/"
       },
       {
          "university": "Punjabi University Patiala",
          "website": "http://www.punjabiuniversity.ac.in/"
       },
       {
          "university": "Punjab Technical University",
          "website": "http://www.ptu.ac.in/"
       },
       {
          "university": "Rabindra Bharati University",
          "website": "http://www.rabindrabharatiuniversity.net/"
       },
       {
          "university": "Rajasthan Agricultural University - Bikaner",
          "website": "http://www.raubikaner.org/"
       },
       {
          "university": "Rajasthan Technical University",
          "website": "http://www.rtu.ac.in/"
       },
       {
          "university": "Rajasthan Vidyapeeth University",
          "website": "http://www.jrnrvu.org/"
       },
       {
          "university": "Rajendra Agricultural University",
          "website": "http://www.pusavarsity.org.in/"
       },
       {
          "university": "Rajitlal Institute of Technology & Health Sciences (RITHS)",
          "website": "http://www.riths.ac.in/"
       },
       {
          "university": "Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)",
          "website": "http://www.rgtu.net/"
       },
       {
          "university": "Rajiv Gandhi University",
          "website": "http://www.rgu.ac.in/"
       },
       {
          "university": "Ranchi University",
          "website": "http://www.ranchiuniversity.org.in/"
       },
       {
          "university": "Rani Durgavati University",
          "website": "http://www.rdunijbpin.org/"
       },
       {
          "university": "RKDF University",
          "website": "http://www.rkdf.ac.in/"
       },
       {
          "university": "Sambalpur University",
          "website": "http://www.sambalpuruniversitypgc.in/"
       },
       {
          "university": "Sampurnanand Sanskrit University",
          "website": "http://ssvv.up.nic.in/"
       },
       {
          "university": "Sanjay Gandhi Postgraduate lnstitute of Medical Sciences",
          "website": "http://www.sgpgi.ac.in/"
       },
       {
          "university": "Sardarkrushinagar Dantiwada Agricultural University",
          "website": "http://www.sdau.edu.in/"
       },
       {
          "university": "Sardar Patel University",
          "website": "http://www.spuvvn.edu/"
       },
       {
          "university": "Sardar Vallabhai Patel University of Agriculture Amd Technology",
          "website": "http://www.svbpuctm.org/"
       },
       {
          "university": "SASTRA Deemed University",
          "website": "http://www.sastra.edu/"
       },
       {
          "university": "Sathyabama University",
          "website": "http://www.sathyabamauniv.ac.in/"
       },
       {
          "university": "Saurashtra University",
          "website": "http://www.saurashtrauniversity.edu/"
       },
       {
          "university": "School of Planning and Architecture",
          "website": "http://www.spa.ac.in/"
       },
       {
          "university": "Shanmugha Arts Science Technology and Research Academy",
          "website": "http://www.sce.ac.in/"
       },
       {
          "university": "Sher-E-Kashmir University of Agricultural Sciences and Technology",
          "website": "http://www.skuastkashmir.ac.in/"
       },
       {
          "university": "Shivaji University",
          "website": "http://www.unishivaji.ac.in/"
       },
       {
          "university": "Shobhit University - Meerut",
          "website": "http://www.shobhituniversity.ac.in/"
       },
       {
          "university": "Shreemati Nathibai Damodar Thackersey Women's University",
          "website": "http://www.sndt.edu/"
       },
       {
          "university": "Shridhar University",
          "website": "http://www.shridharuniversity.ac.in/"
       },
       {
          "university": "Shri Jagannath Sanskrit University",
          "website": "http://www.sjsv.nic.in/"
       },
       {
          "university": "Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha",
          "website": "http://www.slbsrsv.ac.in/"
       },
       {
          "university": "Shri Mata Vaishno Devi University",
          "website": "http://www.smvdu.net.in/"
       },
       {
          "university": "Sido Kanhu Murmu University",
          "website": "http://www.skmu.edu.in/"
       },
       {
          "university": "Sikha 'O' Anushandhan University",
          "website": "http://www.soauniversity.ac.in/"
       },
       {
          "university": "Sikkim Manipal University of Health - Medical and Technological Sciences",
          "website": "http://www.smu.edu.in/"
       },
       {
          "university": "Singhania University Rajasthan",
          "website": "http://www.singhaniauniversity.co.in/"
       },
       {
          "university": "SMK Fomra Institute of Technology",
          "website": "http://www.smkfomra.net/"
       },
       {
          "university": "Solapur University",
          "website": "http://su.digitaluniversity.ac/"
       },
       {
          "university": "South Asian University",
          "website": "http://www.southasianuniversity.org/"
       },
       {
          "university": "South Gujarat University",
          "website": "http://www.sgu.ernet.in/"
       },
       {
          "university": "Sree Chitra Tirunal Institute for Medical Sciences and Technology",
          "website": "http://sctimst.ker.nic.in/"
       },
       {
          "university": "Sree Rama Krishna Degree College",
          "website": "http://www.srkdc.org/"
       },
       {
          "university": "Sree Sankaracharya University of Sanskrit",
          "website": "http://www.ssus.ac.in/"
       },
       {
          "university": "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya",
          "website": "http://www.kanchiuniv.ac.in/"
       },
       {
          "university": "Sri Krishnadevaraya University",
          "website": "http://skuniv.ap.nic.in/"
       },
       {
          "university": "Sri Padmavati Women's University",
          "website": "http://www.padmavatiwomen-univ.org/"
       },
       {
          "university": "Sri Ramachardra Medical College and Research Institute",
          "website": "http://www.hostindia.com/srmc/"
       },
       {
          "university": "Sri Sant Gajanan Maharaj College of Engineering",
          "website": "http://www.ssgmce.org/"
       },
       {
          "university": "Sri Sathya Sai Institute of Higher Learning",
          "website": "http://www.srisathyasai.org.in/pages/instts/HighLearn.htm"
       },
       {
          "university": "Sri Venkateswara Institute of Medical Sciences",
          "website": "http://svimstpt.ap.nic.in/"
       },
       {
          "university": "Sri Venkateswara University",
          "website": "http://svuni.ap.nic.in/"
       },
       {
          "university": "SRM Institute Of Science & Technology ( Deemed University)",
          "website": "http://www.srmuniv.ac.in/"
       },
       {
          "university": "Sunrise University Alwar",
          "website": "http://www.sunriseuniversity.in/"
       },
       {
          "university": "Swami Ramanand Teerth Marathwada University",
          "website": "http://www.srtmun.org/"
       },
       {
          "university": "Symbiosis International University",
          "website": "http://www.siu.edu.in/"
       },
       {
          "university": "Takhatmal Shrivallabh Homoeopathic Medical College",
          "website": "http://www.tshmc.org/"
       },
       {
          "university": "Tamil Nadu Agricultural University",
          "website": "http://dbs.tn.nic.in/tnau/"
       },
       {
          "university": "Tamil Nadu Dr. Ambedkar Law University",
          "website": "http://www.tndalu.org/"
       },
       {
          "university": "Tamil Nadu Dr. M.G.R. Medical University",
          "website": "http://www.tnmmu.ac.in/"
       },
       {
          "university": "Tamil Nadu Veterinary and Animal Sciences University",
          "website": "http://www.tanuvas.com/"
       },
       {
          "university": "Tamil University",
          "website": "http://www.tamiluniversity.ac.in/"
       },
       {
          "university": "Tata Institute of Social Sciences",
          "website": "http://www.tiss.edu/"
       },
       {
          "university": "Telugu University",
          "website": "http://www.teluguuniversity.ac.in/"
       },
       {
          "university": "Tezpur University",
          "website": "http://www.tezu.ernet.in/"
       },
       {
          "university": "Thapar Institute of Engineering and Technology",
          "website": "http://www.tiet.ac.in/"
       },
       {
          "university": "The New College",
          "website": "http://www.thenewcollege.org/"
       },
       {
          "university": "Thiagarajar College of Engineering",
          "website": "http://www.tce.edu/"
       },
       {
          "university": "Thiruvalluvar University",
          "website": "http://www.tvuni.in/"
       },
       {
          "university": "Tilak Maharashtra Vidyapeeth",
          "website": "http://www.tmv.edu.in/"
       },
       {
          "university": "Tilka Manjhi Bhagalpur University",
          "website": "http://www.tmbu.org/"
       },
       {
          "university": "Tripura University",
          "website": "http://www.tripurauniv.in/"
       },
       {
          "university": "Tumkur University",
          "website": "http://www.tumkuruniversity.ac.in/"
       },
       {
          "university": "University of Agricultural Sciences - Bangalore",
          "website": "http://uasbng.kar.nic.in/"
       },
       {
          "university": "University of Agricultural Sciences - Dharwad",
          "website": "http://www.uasd.net/"
       },
       {
          "university": "University of Burdwan",
          "website": "http://www.burdwanuniversity.ac.in/"
       },
       {
          "university": "University of Calcutta",
          "website": "http://www.caluniv.ac.in/"
       },
       {
          "university": "University of Calicut",
          "website": "http://unical.ac.in/"
       },
       {
          "university": "University of Delhi",
          "website": "http://www.du.ac.in/"
       },
       {
          "university": "University of Health Sciences Andhra Pradesh",
          "website": "http://59.163.116.210/"
       },
       {
          "university": "University of Hyderabad",
          "website": "http://www.uohyd.ernet.in/"
       },
       {
          "university": "University of Jammu",
          "website": "http://www.jammuvarsity.org/"
       },
       {
          "university": "University of Kalyani",
          "website": "http://www.klyuniv.ac.in/"
       },
       {
          "university": "University of Kashmir",
          "website": "http://kashmiruniversity.net/"
       },
       {
          "university": "University of Kerala",
          "website": "http://www.keralauniversity.ac.in/"
       },
       {
          "university": "University of Kota",
          "website": "http://www.uok.ac.in/"
       },
       {
          "university": "University of Lucknow",
          "website": "http://www.lucknowuniv.org/"
       },
       {
          "university": "University of Madras",
          "website": "http://www.unom.ac.in/"
       },
       {
          "university": "University of Media Arts",
          "website": "http://www.uma.ac.in/"
       },
       {
          "university": "University of Mumbai",
          "website": "http://www.mu.ac.in/"
       },
       {
          "university": "University of Music and Fine Arts",
          "website": "http://www.iksvv.com/"
       },
       {
          "university": "University of Mysore",
          "website": "http://www.uni-mysore.ac.in/"
       },
       {
          "university": "University of North Bengal",
          "website": "http://www.nbu.ac.in/"
       },
       {
          "university": "University of Pune",
          "website": "http://www.unipune.ernet.in/"
       },
       {
          "university": "University of Rajasthan",
          "website": "http://www.uniraj.org/"
       },
       {
          "university": "University of Roorkee",
          "website": "http://www.uor.org/"
       },
       {
          "university": "Utkal University",
          "website": "http://www.utkaluniv.org/"
       },
       {
          "university": "Uttarakhand Open University ",
          "website": "http://www.uou.ac.in/"
       },
       {
          "university": "Uttarakhand Technical University",
          "website": "http://www.uktech.ac.in/"
       },
       {
          "university": "Uttar Pradesh Technical University",
          "website": "http://www.uptu.ac.in/"
       },
       {
          "university": "Vardhaman Mahaveer Open University",
          "website": "http://www.vmou.ac.in/"
       },
       {
          "university": "VBS Purvanchal University",
          "website": "http://vbspu.ac.in/"
       },
       {
          "university": "Veer Kunwar Singh University",
          "website": "http://www.vksu-ara.org/"
       },
       {
          "university": "Veer Surendra Sai University of Technology",
          "website": "http://www.vssut.ac.in/"
       },
       {
          "university": "Vellore Institute of Technology",
          "website": "http://www.vit.ac.in/"
       },
       {
          "university": "Vidyasagar University",
          "website": "http://www.vidyasagar.ac.in/"
       },
       {
          "university": "Vignan University",
          "website": "http://www.vignanuniversity.org/"
       },
       {
          "university": "Vikrama Simhapuri University",
          "website": "http://www.simhapuriuniv.ac.in/"
       },
       {
          "university": "Vikram University",
          "website": "http://www.vikramuniversity.org/"
       },
       {
          "university": "Vinayaka Mission's Research Foundation-Deemed University",
          "website": "http://www.vinayakamissions.com/"
       },
       {
          "university": "Vinoba Bhave University",
          "website": "http://www.vbu.co.in/"
       },
       {
          "university": "Visva-Bharati University",
          "website": "http://www.visva-bharati.ac.in/"
       },
       {
          "university": "Visvesvaraya National Institute of Technology",
          "website": "http://www.vnitnagpur.ac.in/"
       },
       {
          "university": "Visvesvaraya Technological University",
          "website": "http://www.vtu.ac.in/"
       },
       {
          "university": "West Bengal University of Animal and Fishery Sciences",
          "website": "http://www.wbuafs.nic.in/"
       },
       {
          "university": "West Bengal University of Technology",
          "website": "http://www.wbut.net/"
       },
       {
          "university": "Yashawantrao Chavan Maharashtra Open University",
          "website": "http://www.ycmou.com/"
       }
    ],
    "IQ": [
       {
          "university": "Ahlulbait International University",
          "website": "http://www.ahlulbaitonline.com/"
       },
       {
          "university": "Al Maarif University College",
          "website": "http://www.auc-edu.org/"
       },
       {
          "university": "Al Mamon University College",
          "website": "http://www.almamonuc.org/"
       },
       {
          "university": "Al Mansour University College",
          "website": "http://www.muc.edu.iq/"
       },
       {
          "university": "Al Muthanna University",
          "website": "http://www.uoalmuthana.edu.iq/"
       },
       {
          "university": "Al Nahrain University",
          "website": "http://www.nahrainuniv.edu.iq/"
       },
       {
          "university": "Al Rafidain University College",
          "website": "http://www.coalrafidain.edu.iq/"
       },
       {
          "university": "Al Rasheed University College",
          "website": "http://www.alrasheed-uc.com/"
       },
       {
          "university": "Alsadrain University",
          "website": "http://www.alsadrain.org/"
       },
       {
          "university": "Al Turath University College",
          "website": "http://www.turathun.com/"
       },
       {
          "university": "Al Yarmouk University College",
          "website": "http://www.yu.edu.jo/"
       },
       {
          "university": "American University of Iraq - Sulaimani (Kurdistan Region)",
          "website": "http://www.auis.edu.iq/"
       },
       {
          "university": "Baghdad College of Economic Sciences University",
          "website": "http://www.baghdadcollege.edu.iq/"
       },
       {
          "university": "Baghdad College of Pharmacy",
          "website": "http://www.bpc.edu.iq/"
       },
       {
          "university": "British Royal University",
          "website": "http://broyalu.net/"
       },
       {
          "university": "Cihan University",
          "website": "http://www.cihanuniversity.edu.iq/"
       },
       {
          "university": "College of Science - Baghdad University",
          "website": "http://www.scbaghdad.edu.iq/"
       },
       {
          "university": "Dijla University College",
          "website": "http://www.duc.edu.iq/"
       },
       {
          "university": "Diyala University",
          "website": "http://www.uodiyala.edu.iq/"
       },
       {
          "university": "Hawler Medical University",
          "website": "http://www.hawlermu.org/"
       },
       {
          "university": "Iraq University College",
          "website": "http://www.iraquniversity.net/"
       },
       {
          "university": "Ishik University",
          "website": "http://www.iu.edu.iq/"
       },
       {
          "university": "Kerbala University",
          "website": "http://www.uokerbala.edu.iq/"
       },
       {
          "university": "Komar University of Science and Technology",
          "website": "http://www.komar.edu.iq/"
       },
       {
          "university": "Koya University (Kurdistan Region)",
          "website": "http://www.koyauniversity.org/"
       },
       {
          "university": "Madenat Alelem University College",
          "website": "http://www.madenatalelem.com/"
       },
       {
          "university": "Misan University",
          "website": "http://www.uomisan.edu.iq/"
       },
       {
          "university": "Nawroz University",
          "website": "http://www.nawrozuniversity.com/"
       },
       {
          "university": "Salahddin University (Kurdistan Region)",
          "website": "http://www.suh-edu.com/"
       },
       {
          "university": "Soran University",
          "website": "http://www.soran.edu.iq/"
       },
       {
          "university": "Sulaimani Polytechnic University",
          "website": "http://www.spu.edu.iq/"
       },
       {
          "university": "Thi Qar University",
          "website": "http://www.thiqaruni.org/"
       },
       {
          "university": "Tikrit University",
          "website": "http://www.tu.edu.iq/"
       },
       {
          "university": "Uinversity of Babylon",
          "website": "http://www.uobabylon.edu.iq/"
       },
       {
          "university": "University of Al-Qadisiyah",
          "website": "http://www.qu.edu.iq/"
       },
       {
          "university": "University of Anbar",
          "website": "http://www.uoanbar.edu.iq/"
       },
       {
          "university": "University of Baghdad",
          "website": "http://www.uobaghdad.edu.iq/"
       },
       {
          "university": "University of Basrah",
          "website": "http://www.uobasrah.edu.iq/"
       },
       {
          "university": "University of Dohuk (Kurdistan Region)",
          "website": "http://www.uod.ac/"
       },
       {
          "university": "University of Human Development (Kurdistan Region)",
          "website": "http://www.uhd.edu.iq/"
       },
       {
          "university": "University of Kirkuk",
          "website": "http://www.uokirkuk.edu.iq/"
       },
       {
          "university": "University of Kufa",
          "website": "http://www.uokufa.edu.iq/"
       },
       {
          "university": "University of Kurdistan - Hawler",
          "website": "http://www.ukh.ac/"
       },
       {
          "university": "University of Mosul",
          "website": "http://www.uomosul.edu.iq/"
       },
       {
          "university": "University of Mustansiriyah",
          "website": "http://www.uomustansiriyah.edu.iq/"
       },
       {
          "university": "University of Sulaimania (Kurdistan Region)",
          "website": "http://www.univsul.edu.iq/"
       },
       {
          "university": "University of Technology - Iraq",
          "website": "http://www.uotechnology.edu.iq/"
       },
       {
          "university": "University of Wassit",
          "website": "http://www.uowasit.edu.iq/"
       }
    ],
    "IR": [
       {
          "university": "Adiban Higher Education Institue",
          "website": "http://www.adiban.ac.ir/"
       },
       {
          "university": "Ahvaz University of Medical Sciences",
          "website": "http://www.aums.ac.ir/"
       },
       {
          "university": "Ahwaz Jondishapour University of Medical Sciences",
          "website": "http://www.ajums.ac.ir/"
       },
       {
          "university": "Allameh Tabatabaie University",
          "website": "http://www.atu.ac.ir/"
       },
       {
          "university": "Almustafa Open University",
          "website": "http://www.almustafaou.ir/"
       },
       {
          "university": "Amirkabir College of Managmant & Technology",
          "website": "http://www.acmt.ac.ir/"
       },
       {
          "university": "Amirkabir University of Technology",
          "website": "http://www.aut.ac.ir/"
       },
       {
          "university": "Arak University",
          "website": "http://www.araku.ac.ir/"
       },
       {
          "university": "Arak University of Medical Sciences",
          "website": "http://www.arakmu.ac.ir/"
       },
       {
          "university": "Arak University of Technology",
          "website": "http://www.arakut.ac.ir/"
       },
       {
          "university": "Ardabil University of Medical Sciences",
          "website": "http://www.arums.ac.ir/"
       },
       {
          "university": "Art University",
          "website": "http://www.art.ac.ir/"
       },
       {
          "university": "Azzahra University",
          "website": "http://www.azzahra.ac.ir/"
       },
       {
          "university": "Babol Noshirvani University of Technology",
          "website": "http://www.nit.ac.ir/"
       },
       {
          "university": "Babol University of Medical Sciences",
          "website": "http://www.mubabol.ac.ir/"
       },
       {
          "university": "Baghyatoolah Medical Sciences University",
          "website": "http://www.bmsu.ac.ir/"
       },
       {
          "university": "Baha'i Institute for Higher Education",
          "website": "http://www.bihe.org/"
       },
       {
          "university": "Birjand University",
          "website": "http://www.birjand.ac.ir/"
       },
       {
          "university": "Birjand University of Medical Sciences",
          "website": "http://www.bums.ac.ir/"
       },
       {
          "university": "Bu Ali Sina University",
          "website": "http://www.basu.ac.ir/"
       },
       {
          "university": "Bushehr University of Medical Sciences",
          "website": "http://www.bpums.ac.ir/"
       },
       {
          "university": "Damghan University of Basic Sciences",
          "website": "http://www.dubs.ac.ir/"
       },
       {
          "university": "Daneshestan Institute of Higher Education",
          "website": "http://www.sdi.ac.ir/"
       },
       {
          "university": "Delijan Payame Noor University",
          "website": "http://www.delijanpnu.ac.ir/"
       },
       {
          "university": "Deylaman Institute of Higher Education",
          "website": "http://www.deylaman.ac.ir/"
       },
       {
          "university": "Fasa Faculty of Medical Sciences",
          "website": "http://www.fums.ac.ir/"
       },
       {
          "university": "Ferdowsi University of Mashhad",
          "website": "http://www.um.ac.ir/"
       },
       {
          "university": "Golestan University of Medical Sciences",
          "website": "http://www.goums.ac.ir/"
       },
       {
          "university": "Gonabad University of Medical Sciences",
          "website": "http://www.gmu.ac.ir/"
       },
       {
          "university": "Gorgan University of Agricultural Sciences and Natural Resources",
          "website": "http://www.gau.ac.ir/"
       },
       {
          "university": "Guilan University",
          "website": "http://www.guilan.ac.ir/"
       },
       {
          "university": "Guilan University of Medical Sciences",
          "website": "http://www.gums.ac.ir/"
       },
       {
          "university": "Hamadan University of Medical Sciences",
          "website": "http://www.umsha.ac.ir/"
       },
       {
          "university": "Hormozgan University of Medical Sciences",
          "website": "http://www.hums.ac.ir/"
       },
       {
          "university": "Hyrcania Institute of Higher Education",
          "website": "http://www.hyrcania.ac.ir/"
       },
       {
          "university": "Ilam University",
          "website": "http://www.ilam.ac.ir/"
       },
       {
          "university": "Ilam University of Medical Sciences",
          "website": "http://www.medilam.hbi.ir/"
       },
       {
          "university": "Imam Khomeini International University",
          "website": "http://www.ikiu.ac.ir/"
       },
       {
          "university": "Imam Reza University",
          "website": "http://www.imamreza.ac.ir/"
       },
       {
          "university": "Imam Sadiq University",
          "website": "http://www.isu.ac.ir/"
       },
       {
          "university": "Iranian Academy of Persian Language and Literature",
          "website": "http://www.persianacademy.ir/"
       },
       {
          "university": "Iran University of Medical Sciences",
          "website": "http://www.iums.ac.ir/"
       },
       {
          "university": "Iran University of Science and Technology Tehran",
          "website": "http://www.iust.ac.ir/"
       },
       {
          "university": "Isfahan University",
          "website": "http://www.ui.ac.ir/"
       },
       {
          "university": "Isfahan University of Medical Sciences",
          "website": "http://www.mui.ac.ir/"
       },
       {
          "university": "Isfahan University of Technology",
          "website": "http://www.iut.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Ahar",
          "website": "http://www.iau-ahar.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Aliabad ",
          "website": "http://www.aliabadiau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Arak",
          "website": "http://www.iau-arak.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Ardabil",
          "website": "http://www.iauardabil.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Bandar Abbas",
          "website": "http://www.iauba.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Bojnourd",
          "website": "http://www.bojnourdiau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Bostanabad",
          "website": "http://www.iaubos.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Boukan",
          "website": "http://www.iau-boukan.ac.ir"
       },
       {
          "university": "Islamic Azad University - Central Tehran Branch",
          "website": "http://www.iauctb.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Dehaghan",
          "website": "http://www.dehaghan.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Falavarjan ",
          "website": "http://www.falavarjan.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Fars Science & Research Branch",
          "website": "http://www.fsriau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Garmsar",
          "website": "http://www.iau-garmsar.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Gorgan",
          "website": "http://www.gorganiau.ir/"
       },
       {
          "university": "Islamic Azad University - Hamedan",
          "website": "http://www.iauh.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Janah",
          "website": "http://www.janahiau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Karaj",
          "website": "http://www.iaukb.ir/"
       },
       {
          "university": "Islamic Azad University - Kazeron",
          "website": "http://www.kau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Kerman",
          "website": "http://www.iauk.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Khorasgan",
          "website": "http://www.khuisf.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Lahijan",
          "website": "http://www.iau-lahijan.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Mahshar",
          "website": "http://www.mahshahriau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Majlesi",
          "website": "http://www.iaumajlesi.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Mashhad",
          "website": "http://www.mshdiau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Maybod",
          "website": "http://www.maybod.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Mehriz",
          "website": "http://www.iaumehriz.com/"
       },
       {
          "university": "Islamic Azad University - Mobarakeh",
          "website": "http://www.mau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Najaf Abad",
          "website": "http://www.iaun.ac.ir/"
       },
       {
          "university": "Islamic Azad University - North Tehran",
          "website": "http://www.iau-tnb.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Parand",
          "website": "http://www.piau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Qazvin",
          "website": "http://www.qazviniau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Qom",
          "website": "http://www.qom-iau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Quchan",
          "website": "http://www.iauq.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Region One",
          "website": "http://www.iauro.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Roodehen",
          "website": "http://www.riau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Sanandaj",
          "website": "http://www.iausdj.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Saveh",
          "website": "http://www.iau-saveh.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Semnan",
          "website": "http://www.semnaniau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Shabestar",
          "website": "http://www.iaushab.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Shahr-e-rey Branch",
          "website": "http://www.iausr.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Shahrood",
          "website": "http://www.iau-shahrood.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Shahryar",
          "website": "http://www.shahryariau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Shiraz",
          "website": "http://www.iaushiraz.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Shirvan",
          "website": "http://www.iau-shirvan.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Sowmesara",
          "website": "http://www.sowmesaraiau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Tabriz",
          "website": "http://www.iaut.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Tafresh",
          "website": "http://www.iautb.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Tehran Medical",
          "website": "http://www.iautmu.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Tehran Science & Research Branch",
          "website": "http://www.srbiau.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Tehran South",
          "website": "http://www.azad.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Varamin",
          "website": "http://www.iauvaramin.ac.ir/"
       },
       {
          "university": "Islamic Azad University - Yazd",
          "website": "http://www.iauyazd.ac.ir/"
       },
       {
          "university": "Jahrom University of Medical Sciences",
          "website": "http://www.jums.ac.ir/"
       },
       {
          "university": "Jami Institute of Technology",
          "website": "http://www.jami.ac.ir/"
       },
       {
          "university": "Kamalolmolk Art & Architecture Higher Education Institute of Noshahr",
          "website": "http://www.kamalolmolk.ac.ir/"
       },
       {
          "university": "Kar University",
          "website": "http://www.karuniversity.com/"
       },
       {
          "university": "Kashan University",
          "website": "http://www.kashanu.ac.ir/"
       },
       {
          "university": "Kashan University of Medical Sciences",
          "website": "http://www.kaums.ac.ir/"
       },
       {
          "university": "Kerman Graduate University of Technology",
          "website": "http://www.kgut.ac.ir/"
       },
       {
          "university": "Kermanshah University of Medical Sciences",
          "website": "http://www.kums.ac.ir/"
       },
       {
          "university": "Kermanshah University of Technology",
          "website": "http://www.kut.ac.ir/"
       },
       {
          "university": "Kerman University of Medical Sciences",
          "website": "http://www.kmu.ac.ir/"
       },
       {
          "university": "Khomeinishahr Payame Noor University",
          "website": "http://km.isfpnu.ac.ir/"
       },
       {
          "university": "K.N.Toosi University of Technology",
          "website": "http://www.kntu.ac.ir/"
       },
       {
          "university": "Kurdistan University of Medical Sciences",
          "website": "http://www.muk.ac.ir/"
       },
       {
          "university": "Lorestan University",
          "website": "http://www.lu.ac.ir/"
       },
       {
          "university": "Lorestan University of Medical Sciences",
          "website": "http://www.lums.ac.ir/"
       },
       {
          "university": "Malayer University",
          "website": "http://www.malayeru.ac.ir/"
       },
       {
          "university": "Malekan Payame Nour University",
          "website": "http://www.malekanpnu.ac.ir/"
       },
       {
          "university": "Mamasani University of Technology",
          "website": "http://www.mamasaniiau.ac.ir/"
       },
       {
          "university": "Mashhad University of Medical Sciences",
          "website": "http://www.mums.ac.ir/"
       },
       {
          "university": "Mazandaran University of Iran",
          "website": "http://www.umz.ac.ir/"
       },
       {
          "university": "Mazandaran University of Medical Science",
          "website": "http://www.mazums.ac.ir/"
       },
       {
          "university": "Mazandaran University of Science & Technology",
          "website": "http://www.ustmb.ac.ir/"
       },
       {
          "university": "Mofid University",
          "website": "http://www.mofidu.ac.ir/"
       },
       {
          "university": "Mustafa International University",
          "website": "http://www.miu.ac.ir/"
       },
       {
          "university": "Najaf Abad University of Applied Science and Technology",
          "website": "http://www.najafabad-uast.ac.ir/"
       },
       {
          "university": "Payame Noor University",
          "website": "http://www.pnu.ac.ir/"
       },
       {
          "university": "Payame Noor University - Tafresh",
          "website": "http://www.pnut.ac.ir/"
       },
       {
          "university": "Persian Gulf University",
          "website": "http://www.pgu.ac.ir/"
       },
       {
          "university": "Petroleum University of Technology",
          "website": "http://www.put.ac.ir/"
       },
       {
          "university": "Power and Water Institute of Technology",
          "website": "http://www.pwit.ac.ir/"
       },
       {
          "university": "Qazvin University of Medical Sciences",
          "website": "http://www.qums.ac.ir/"
       },
       {
          "university": "Qom University",
          "website": "http://www.qom.ac.ir/"
       },
       {
          "university": "Qom University of Medical Sciences",
          "website": "http://www.muq.ac.ir/"
       },
       {
          "university": "Qom University of Technology",
          "website": "http://www.qut.ac.ir/"
       },
       {
          "university": "Rafsanjan University of Medical Sciences",
          "website": "http://www.rums.ac.ir/"
       },
       {
          "university": "Raghebe Esfahani University",
          "website": "http://www.raghebisf.ac.ir/"
       },
       {
          "university": "Razi University",
          "website": "http://www.razi.ac.ir/"
       },
       {
          "university": "Sabzevar School of Medical Sciences",
          "website": "http://www.medsab.ac.ir/"
       },
       {
          "university": "Sabzevar Teacher Training University",
          "website": "http://www.sttu.ac.ir/"
       },
       {
          "university": "Sadjad Institute of Technology",
          "website": "http://www.sadjad.ac.ir/"
       },
       {
          "university": "Sadra University",
          "website": "http://www.sadra.ac.ir/"
       },
       {
          "university": "Sahand University of Technology",
          "website": "http://www.sut.ac.ir/"
       },
       {
          "university": "School of Economic Sciences",
          "website": "http://www.ses.ac.ir/"
       },
       {
          "university": "Semnan University",
          "website": "http://www.semnan.ac.ir/"
       },
       {
          "university": "Semnan University of Medical Sciences",
          "website": "http://www.sem-ums.ac.ir/"
       },
       {
          "university": "Shahed University",
          "website": "http://www.shahed.ac.ir/"
       },
       {
          "university": "Shaheed Beheshti University",
          "website": "http://www.sbu.ac.ir/"
       },
       {
          "university": "Shaheed Beheshti University of Medical Sciences",
          "website": "http://www.sbmu.ac.ir/"
       },
       {
          "university": "Shaheed Chamran University",
          "website": "http://www.cua.ac.ir/"
       },
       {
          "university": "Shaheed Rajaei Teacher Training University",
          "website": "http://www.srttu.edu/"
       },
       {
          "university": "Shahid Bahonar University of Kerman",
          "website": "http://www.uk.ac.ir/"
       },
       {
          "university": "Shahrekord University",
          "website": "http://www.sku.ac.ir/"
       },
       {
          "university": "Shahrekord University of Medical Sciences",
          "website": "http://www.skums.ac.ir/"
       },
       {
          "university": "Shahrood University of Medical Sciences",
          "website": "http://www.shmu.ac.ir/"
       },
       {
          "university": "Shahrood University of Technology",
          "website": "http://www.shahroodut.ac.ir/"
       },
       {
          "university": "Sharif University of Technology",
          "website": "http://www.sharif.ac.ir/"
       },
       {
          "university": "Sharif University of Technology - Kish Campus",
          "website": "http://www.kish.ac.ir/"
       },
       {
          "university": "Sheikhbahaee University",
          "website": "http://www.shbu.ac.ir/"
       },
       {
          "university": "Shiraz Payamnoor University",
          "website": "http://www.spnu.ac.ir/"
       },
       {
          "university": "Shiraz University",
          "website": "http://www.shirazu.ac.ir/"
       },
       {
          "university": "Shiraz University of Medical Sciences",
          "website": "http://www.sums.ac.ir/"
       },
       {
          "university": "Shiraz University of Technology",
          "website": "http://www.sutech.ac.ir/"
       },
       {
          "university": "Shomal University",
          "website": "http://www.shomal.ac.ir/"
       },
       {
          "university": "Sistan & Balouchestan University",
          "website": "http://www.usb.ac.ir/"
       },
       {
          "university": "Tabari Institute of Higher Education",
          "website": "http://www.tabari.ac.ir/ "
       },
       {
          "university": "Tabriz College of Technology",
          "website": "http://www.tct.ac.ir/"
       },
       {
          "university": "Tabriz Islamic Art University",
          "website": "http://www.tabriziau.ac.ir/"
       },
       {
          "university": "Tabriz University",
          "website": "http://www.tabrizu.ac.ir/"
       },
       {
          "university": "Tabriz University of Medical Sciences",
          "website": "http://www.tbzmed.ac.ir/"
       },
       {
          "university": "Takestan Islamic Azad University",
          "website": "http://www.tiau.ac.ir/"
       },
       {
          "university": "Tarbiat Modares University",
          "website": "http://www.modares.ac.ir/"
       },
       {
          "university": "Tehran University of Medical Sciences",
          "website": "http://www.tums.ac.ir/"
       },
       {
          "university": "Tehran University of Teacher Training",
          "website": "http://www.tmu.ac.ir/"
       },
       {
          "university": "The Bahá'í Institute For Higher Education (BIHE)",
          "website": "http://www.bihe.org/"
       },
       {
          "university": "Torbat Heydarieh University of Medical Sciences",
          "website": "http://www.thums.ac.ir/"
       },
       {
          "university": "University College of Nabi Akram",
          "website": "http://www.ucna.info/"
       },
       {
          "university": "University of Applied Science and Technology",
          "website": "http://www.uast.ac.ir/"
       },
       {
          "university": "University of Hormozgan",
          "website": "http://www.hormozgan.ac.ir/"
       },
       {
          "university": "University of Kurdistan",
          "website": "http://www.uok.ac.ir/"
       },
       {
          "university": "University of Lavasanat Pnu",
          "website": "http://www.lavasanpnu.ac.ir/"
       },
       {
          "university": "University of Mashhad Pnu",
          "website": "http://www.pnum.ac.ir/"
       },
       {
          "university": "University of Mohaghegh",
          "website": "http://www.uma.ac.ir/"
       },
       {
          "university": "University of Science and Culture",
          "website": "http://www.usc.ac.ir/"
       },
       {
          "university": "University of Social Welfare and Rehabilitation Scinences",
          "website": "http://www.uswr.ac.ir/"
       },
       {
          "university": "University of Tehran",
          "website": "http://www.ut.ac.ir/"
       },
       {
          "university": "Urmia University",
          "website": "http://www.urmia.ac.ir/"
       },
       {
          "university": "Urmia University of Medical Sciences",
          "website": "http://www.umsu.ac.ir/"
       },
       {
          "university": "Urmia University of Technology",
          "website": "http://www.uut.ac.ir/"
       },
       {
          "university": "Vali-e-Asr University",
          "website": "http://www.vru.ac.ir/"
       },
       {
          "university": "Yasuj University",
          "website": "http://www.yu.ac.ir/"
       },
       {
          "university": "Yasuj University of Medical Sciences",
          "website": "http://www.yums.ac.ir/"
       },
       {
          "university": "Yazd Medical University",
          "website": "http://www.ssu.ac.ir/"
       },
       {
          "university": "Yazd University",
          "website": "http://www.yazduni.ac.ir/"
       },
       {
          "university": "Zabol University",
          "website": "http://www.uoz.ac.ir/"
       },
       {
          "university": "Zahedan University of Medical Sciences",
          "website": "http://www.zdmu.ac.ir/"
       },
       {
          "university": "Zanjan Institute for Advanced Studies in Basic Sciences",
          "website": "http://www.iasbs.ac.ir/"
       },
       {
          "university": "Zanjan University",
          "website": "http://www.znu.ac.ir/"
       },
       {
          "university": "Zanjan University of Medical Sciences",
          "website": "http://www.zums.ac.ir/"
       }
    ],
    "IS": [
       {
          "university": "Bifrost School of Business",
          "website": "http://www.bifrost.is/"
       },
       {
          "university": "Holar University College",
          "website": "http://www.holar.is/"
       },
       {
          "university": "Hvanneyri Agricultural University",
          "website": "http://www.hvanneyri.is/"
       },
       {
          "university": "Iceland Academy of the Arts",
          "website": "http://www.lhi.is/"
       },
       {
          "university": "Iceland University of Education",
          "website": "http://www.khi.is/"
       },
       {
          "university": "Reykjavík University",
          "website": "http://www.ru.is/"
       },
       {
          "university": "Technological University of Iceland",
          "website": "http://www.iti.is/"
       },
       {
          "university": "University Centre of the Westfjords",
          "website": "http://www.hsvest.is/"
       },
       {
          "university": "University of Akureyri",
          "website": "http://www.unak.is/"
       },
       {
          "university": "University of Iceland",
          "website": "http://www.hi.is/"
       }
    ],
    "IT": [
       {
          "university": "Abdus Salam international centre for theoretical physics",
          "website": "http://www.ictp.trieste.it/"
       },
       {
          "university": "Basilicata University Potenza",
          "website": "http://www.unibas.it/"
       },
       {
          "university": "Campus Bio-Medico University of Rome",
          "website": "http://www.unicampus.it/"
       },
       {
          "university": "Catholic University of the Sacred Heart",
          "website": "http://www.unicatt.it/"
       },
       {
          "university": "Dutch University Institute for Art History (DUIA)",
          "website": "http://www.iuoart.org/"
       },
       {
          "university": "European School of Economics",
          "website": "http://www.uniese.it/"
       },
       {
          "university": "European University Institute",
          "website": "http://www.iue.it/"
       },
       {
          "university": "Fondazione Sacro Cuore",
          "website": "http://www.sacrocuore.org/"
       },
       {
          "university": "Free International University of Social Studies",
          "website": "http://www.luiss.it/"
       },
       {
          "university": "Free University Institute Carlo Cattaneo",
          "website": "http://www.liuc.it/"
       },
       {
          "university": "Free University Maria Santissima Assunta",
          "website": "http://www.lumsa.it/"
       },
       {
          "university": "Free University of Bozen",
          "website": "http://www.unibz.it/"
       },
       {
          "university": "Higher School of University and Advanced Studies Pisa",
          "website": "http://www.sssup.it/"
       },
       {
          "university": "Institute for Advanced Studies Lucca",
          "website": "http://www.imtlucca.it/"
       },
       {
          "university": "International Higher School for Advanced Studies Trieste",
          "website": "http://www.sissa.it/"
       },
       {
          "university": "Istituto Universitario di Studi Superiori",
          "website": "http://www.iusspavia.it/"
       },
       {
          "university": "John Cabot University",
          "website": "http://www.johncabot.edu/"
       },
       {
          "university": "Johns Hopkins University - SAIS Bologna Center",
          "website": "http://www.jhubc.it/"
       },
       {
          "university": "Link Campus University of Malta",
          "website": "http://www.unilink.it/"
       },
       {
          "university": "NABA - Nuova Accademia di Belle Arti - Milan",
          "website": "http://www.naba.it/"
       },
       {
          "university": "Polytechnic Institute of Bari",
          "website": "http://www.poliba.it/"
       },
       {
          "university": "Polytechnic Institute of Milan",
          "website": "http://www.polimi.it/"
       },
       {
          "university": "Polytechnic Institute of Turin",
          "website": "http://www.polito.it/"
       },
       {
          "university": "Pontifica Università Gregoriana",
          "website": "http://www.unigre.it/"
       },
       {
          "university": "Pontificia Università della Santa Croce",
          "website": "http://www.pusc.it/"
       },
       {
          "university": "Pontificia Università Lateranense",
          "website": "http://www.pul.it/"
       },
       {
          "university": "Pontificia Università S. Tommaso",
          "website": "http://www.angelicum.org/"
       },
       {
          "university": "Pontificio Ateneo Antonianum",
          "website": "http://www.antonianum.ofm.org/"
       },
       {
          "university": "Pontificio Istituto Orientale",
          "website": "http://www.pio.urbe.it/"
       },
       {
          "university": "School of Education Pisa",
          "website": "http://www.sns.it/"
       },
       {
          "university": "Second University of Naples",
          "website": "http://www.unina2.it/"
       },
       {
          "university": "Third University of Rome",
          "website": "http://www.uniroma3.it/"
       },
       {
          "university": "Università Bocconi",
          "website": "http://www.uni-bocconi.it/"
       },
       {
          "university": "Università Pontificia Salesiana",
          "website": "http://www.unisal.it/"
       },
       {
          "university": "University Institute of Architecture Venice",
          "website": "http://www.iuav.unive.it/"
       },
       {
          "university": "University Institute of Modern Languages",
          "website": "http://www.iulm.it/"
       },
       {
          "university": "University Institute of Naval Studies",
          "website": "http://www.uninav.it/"
       },
       {
          "university": "University Institute of Oriental Studies",
          "website": "http://www.iuo.it/"
       },
       {
          "university": "University Institute of Teacher Training Suor Orsola Benincasa",
          "website": "http://www.unisob.na.it/"
       },
       {
          "university": "University of Ancona",
          "website": "http://www.unian.it/"
       },
       {
          "university": "University of Aquila",
          "website": "http://www.univaq.it/"
       },
       {
          "university": "University of Bari",
          "website": "http://www.uniba.it/"
       },
       {
          "university": "University of Bergamo",
          "website": "http://www.unibg.it/"
       },
       {
          "university": "University of Bologna",
          "website": "http://www.unibo.it/"
       },
       {
          "university": "University of Brescia",
          "website": "http://www.unibs.it/"
       },
       {
          "university": "University of Cagliari",
          "website": "http://www.unica.it/"
       },
       {
          "university": "University of Calabria",
          "website": "http://www.unical.it/"
       },
       {
          "university": "University of Camerino",
          "website": "http://www.unicam.it/"
       },
       {
          "university": "University of Cassino",
          "website": "http://www.unicas.it/"
       },
       {
          "university": "University of Catania",
          "website": "http://www.unict.it/"
       },
       {
          "university": "University of Chieti",
          "website": "http://www.unich.it/"
       },
       {
          "university": "University of Commerce Luigi Bocconi",
          "website": "http://www.uni-bocconi.it/"
       },
       {
          "university": "University of Ferrara",
          "website": "http://www.unife.it/"
       },
       {
          "university": "University of Florence",
          "website": "http://www.unifi.it/"
       },
       {
          "university": "University of Foggia",
          "website": "http://www.unifg.it/"
       },
       {
          "university": "University of Gastronomic Sciences",
          "website": "http://www.unisg.it/"
       },
       {
          "university": "University of Genoa",
          "website": "http://www.unige.it/"
       },
       {
          "university": "University of Insubria",
          "website": "http://www.uninsubria.it/"
       },
       {
          "university": "University of Italian Studies for Foreigners of Perugia",
          "website": "http://www.unistrapg.it/"
       },
       {
          "university": "University of Italian Studies for Foreigners of Siena",
          "website": "http://www.unistrasi.it/"
       },
       {
          "university": "University of Lecce",
          "website": "http://www.unile.it/"
       },
       {
          "university": "University of Macerata",
          "website": "http://www.unimc.it/"
       },
       {
          "university": "University of Messina",
          "website": "http://www.unime.it/"
       },
       {
          "university": "University of Milan",
          "website": "http://www.unimi.it/"
       },
       {
          "university": "University of Milan - Bicocca",
          "website": "http://www.unimib.it/"
       },
       {
          "university": "University of Modena",
          "website": "http://www.casa.unimo.it/"
       },
       {
          "university": "University of Molise",
          "website": "http://www.unimol.it/"
       },
       {
          "university": "University of Naples Federico II",
          "website": "http://www.unina.it/"
       },
       {
          "university": "University of Padua",
          "website": "http://www.unipd.it/"
       },
       {
          "university": "University of Palermo",
          "website": "http://www.unipa.it/"
       },
       {
          "university": "University of Parma",
          "website": "http://www.unipr.it/"
       },
       {
          "university": "University of Pavia",
          "website": "http://www.unipv.it/"
       },
       {
          "university": "University of Perugia",
          "website": "http://www.unipg.it/"
       },
       {
          "university": "University of Pisa",
          "website": "http://www.unipi.it/"
       },
       {
          "university": "University of Reggio Calabria",
          "website": "http://www.unirc.it/"
       },
       {
          "university": "University of Roma La Sapienza",
          "website": "http://www.uniroma1.it/"
       },
       {
          "university": "University of Roma Tor Vergata",
          "website": "http://www.uniroma2.it/"
       },
       {
          "university": "University of Salerno",
          "website": "http://www.unisa.it/"
       },
       {
          "university": "University of Sannio",
          "website": "http://www.unisannio.it/"
       },
       {
          "university": "University of Sassari",
          "website": "http://www.uniss.it/"
       },
       {
          "university": "University of Siena",
          "website": "http://www.unisi.it/"
       },
       {
          "university": "University of Teramo",
          "website": "http://www.unite.it/"
       },
       {
          "university": "University of Trento",
          "website": "http://www.unitn.it/"
       },
       {
          "university": "University of Trieste",
          "website": "http://www.univ.trieste.it/"
       },
       {
          "university": "University of Turin",
          "website": "http://www.unito.it/"
       },
       {
          "university": "University of Udine",
          "website": "http://www.uniud.it/"
       },
       {
          "university": "University of Urbino",
          "website": "http://www.uniurb.it/"
       },
       {
          "university": "University of Venice",
          "website": "http://www.unive.it/"
       },
       {
          "university": "University of Verona",
          "website": "http://www.univr.it/"
       },
       {
          "university": "Viterbo State University",
          "website": "http://www.unitus.it/"
       },
       {
          "university": "Yorker International University - Milano",
          "website": "http://www.nyuniversity.net/"
       }
    ],
    "JM": [
       {
          "university": "Northern Caribbean University",
          "website": "http://www.ncu.edu.jm/"
       },
       {
          "university": "University of Technology Jamaica",
          "website": "http://www.utech.edu.jm/"
       },
       {
          "university": "University of the West Indies - Mona",
          "website": "http://www.uwimona.edu.jm/"
       }
    ],
    "JO": [
       {
          "university": "Al-Ahliyya Amman University",
          "website": "http://www.ammanu.edu.jo/"
       },
       {
          "university": "Al al-Bayt University",
          "website": "http://www.aabu.edu.jo/"
       },
       {
          "university": "Al-Balqa Applied University",
          "website": "http://www.bau.edu.jo/"
       },
       {
          "university": "Al Hussein Bin Talal University",
          "website": "http://www.ahu.edu.jo/"
       },
       {
          "university": "Al-Isra University",
          "website": "http://www.isra.edu.jo/"
       },
       {
          "university": "Al-Zaytoonah University",
          "website": "http://www.alzaytoonah.edu.jo/"
       },
       {
          "university": "Amman Arab University for Higher Studies",
          "website": "http://www.aau.edu.jo/"
       },
       {
          "university": "Amman University",
          "website": "http://www.amman.edu/"
       },
       {
          "university": "Applied Science University",
          "website": "http://www.asu.edu.jo/"
       },
       {
          "university": "Arab Academy for Banking and Financial Sciences",
          "website": "http://www.aabfs.org/"
       },
       {
          "university": "Arab Open University",
          "website": "http://www.aou.edu.jo/"
       },
       {
          "university": "Balqa Applied University",
          "website": "http://www.bau.edu.jo/"
       },
       {
          "university": "German Jordanian University",
          "website": "http://www.gju.edu.jo/"
       },
       {
          "university": "Hashemite University",
          "website": "http://www.hu.edu.jo/"
       },
       {
          "university": "Irbid National University",
          "website": "http://www.inu.edu.jo/"
       },
       {
          "university": "Jadara University",
          "website": "http://www.jadara.edu.jo/"
       },
       {
          "university": "Jerash Private University",
          "website": "http://www.jerashun.edu.jo/"
       },
       {
          "university": "Jordan Academy of Music / Higher Institute of Music",
          "website": "http://www.jam.edu.jo/"
       },
       {
          "university": "Jordan University of Science and Technology",
          "website": "http://www.just.edu.jo/"
       },
       {
          "university": "Middle East University",
          "website": "http://www.meu.edu.jo/"
       },
       {
          "university": "Mu' tah University",
          "website": "http://www.mutah.edu.jo/"
       },
       {
          "university": "New York Institute of Technology",
          "website": "http://www.nyit.edu.jo/"
       },
       {
          "university": "Philadelphia University",
          "website": "http://www.philadelphia.edu.jo/"
       },
       {
          "university": "Princess Sumaya University for Technology",
          "website": "http://www.psut.edu.jo/"
       },
       {
          "university": "Tafila Technical University ",
          "website": "http://www.ttu.edu.jo/"
       },
       {
          "university": "The World Islamic Sciences & Education University",
          "website": "http://www.wise.edu.jo/"
       },
       {
          "university": "University of Jordan",
          "website": "http://www.ju.edu.jo/"
       },
       {
          "university": "University of Petra",
          "website": "http://www.uop.edu.jo/"
       },
       {
          "university": "Yarmouk University",
          "website": "http://www.yu.edu.jo/"
       },
       {
          "university": "Zarka Private University",
          "website": "http://www.zpu.edu.jo/"
       }
    ],
    "JP": [
       {
          "university": "Aichi Bunkyo University",
          "website": "http://www.abu.ac.jp/"
       },
       {
          "university": "Aichi Gakuin University",
          "website": "http://www.aichi-gakuin.ac.jp/"
       },
       {
          "university": "Aichi Gakusen University",
          "website": "http://www.gakusen.ac.jp/"
       },
       {
          "university": "Aichi Institute of Technology",
          "website": "http://www.aitech.ac.jp/"
       },
       {
          "university": "Aichi Medical University",
          "website": "http://www.aichi-med-u.ac.jp/"
       },
       {
          "university": "Aichi Prefectural University",
          "website": "http://www.aichi-pu.ac.jp/"
       },
       {
          "university": "Aichi Prefectural University of Fine Arts & Music",
          "website": "http://www.aichi-fam-u.ac.jp/"
       },
       {
          "university": "Aichi Sangyo University",
          "website": "http://www.asu.ac.jp/"
       },
       {
          "university": "Aichi Shukutoku University",
          "website": "http://www.aasa.ac.jp/"
       },
       {
          "university": "Aichi University",
          "website": "http://www.aichi-u.ac.jp/"
       },
       {
          "university": "Aichi University of Education",
          "website": "http://www.aichi-edu.ac.jp/"
       },
       {
          "university": "Aikoku Gakuen University",
          "website": "http://www.aikoku-u.ac.jp/"
       },
       {
          "university": "Akita University",
          "website": "http://www.akita-u.ac.jp/"
       },
       {
          "university": "Akita University of Economics and Law",
          "website": "http://www.akeihou-u.ac.jp/"
       },
       {
          "university": "American University Extension - Okinawa",
          "website": "http://www.aue-okinawa.ac.jp/"
       },
       {
          "university": "Aomori Chuoh Gakuin University",
          "website": "http://www.aomoricgu.ac.jp/"
       },
       {
          "university": "Aomori Public College",
          "website": "http://www.nebuta.ac.jp/"
       },
       {
          "university": "Aomori University",
          "website": "http://www.aomori-u.ac.jp/"
       },
       {
          "university": "Aomori University of Health and Welfare",
          "website": "http://www.auhw.ac.jp/"
       },
       {
          "university": "Aoyama Gakuin University",
          "website": "http://www.aoyama.ac.jp/"
       },
       {
          "university": "Asahikawa Medical College",
          "website": "http://www.asahikawa-med.ac.jp/"
       },
       {
          "university": "Asahikawa University",
          "website": "http://www.asahikawa-u.ac.jp/"
       },
       {
          "university": "Asahi University",
          "website": "http://www.asahi-u.ac.jp/"
       },
       {
          "university": "Ashikaga Institute of Technology",
          "website": "http://www.ashitech.ac.jp/"
       },
       {
          "university": "Ashiya University",
          "website": "http://www.ashiya-u.ac.jp/"
       },
       {
          "university": "Asia University",
          "website": "http://www.asia-u.ac.jp/"
       },
       {
          "university": "Atomi College",
          "website": "http://www.atomi.ac.jp/"
       },
       {
          "university": "Azabu University",
          "website": "http://www.azabu-u.ac.jp/"
       },
       {
          "university": "Baika Women's College",
          "website": "http://www.baika.ac.jp/"
       },
       {
          "university": "Baiko Women's College",
          "website": "http://www.baiko.ac.jp/"
       },
       {
          "university": "Beppu University",
          "website": "http://www.beppu-u.ac.jp/"
       },
       {
          "university": "Bukkyo University",
          "website": "http://www.bukkyo-u.ac.jp/"
       },
       {
          "university": "Bunka Women's University",
          "website": "http://www.bunka-wu.ac.jp/"
       },
       {
          "university": "Bunkyo University",
          "website": "http://www.bunkyo.ac.jp/"
       },
       {
          "university": "Chiba Institute of Technology",
          "website": "http://www.it-chiba.ac.jp/"
       },
       {
          "university": "Chiba Keizai University",
          "website": "http://www.cku.ac.jp/"
       },
       {
          "university": "Chiba University",
          "website": "http://www.chiba-u.ac.jp/"
       },
       {
          "university": "Chiba University of Commerce",
          "website": "http://www.cuc.ac.jp/"
       },
       {
          "university": "Chikushi Jogakuen University",
          "website": "http://www.chikushi.ac.jp/"
       },
       {
          "university": "Chubu Gakuin University & Chubu Women's College",
          "website": "http://www.chubu-gu.ac.jp/"
       },
       {
          "university": "Chubu University",
          "website": "http://www.chubu.ac.jp/"
       },
       {
          "university": "Chukyo Gakuin University",
          "website": "http://www.chukyogakuin-u.ac.jp/"
       },
       {
          "university": "Chukyo University",
          "website": "http://www.chukyo-u.ac.jp/"
       },
       {
          "university": "Chukyo Women's University",
          "website": "http://www.chujo-u.ac.jp/"
       },
       {
          "university": "Chuo Gakuin University",
          "website": "http://www.cgu.ac.jp/"
       },
       {
          "university": "Chuo University",
          "website": "http://www.chuo-u.ac.jp/"
       },
       {
          "university": "Daido Institute of Technology",
          "website": "http://www.daido-it.ac.jp/"
       },
       {
          "university": "Daiichi College of Pharmaceutical Sciences",
          "website": "http://www.daiichi-cps.ac.jp/"
       },
       {
          "university": "Dai Ichi University - College of Technology",
          "website": "http://www.daiichi-koudai.ac.jp/"
       },
       {
          "university": "Daiichi University of Economics",
          "website": "http://www.daiichi-ue.ac.jp/"
       },
       {
          "university": "Daito Bunka University",
          "website": "http://www.daito.ac.jp/"
       },
       {
          "university": "Doho University",
          "website": "http://www.doho.ac.jp/"
       },
       {
          "university": "Dohto University",
          "website": "http://www.dohto.ac.jp/"
       },
       {
          "university": "Dokkyo University",
          "website": "http://www.dokkyo.ac.jp/"
       },
       {
          "university": "Dokkyo University School of Medicine",
          "website": "http://www.dokkyomed.ac.jp/"
       },
       {
          "university": "Doshisha University",
          "website": "http://www.doshisha.ac.jp/"
       },
       {
          "university": "Doshisha Women's College of Liberal Arts",
          "website": "http://www.dwc.doshisha.ac.jp/"
       },
       {
          "university": "Edogawa University",
          "website": "http://www.edogawa-u.ac.jp/"
       },
       {
          "university": "Ehime University",
          "website": "http://www.ehime-u.ac.jp/"
       },
       {
          "university": "Eichi University",
          "website": "http://www.eichi-u.ac.jp/"
       },
       {
          "university": "Elisabeth University of Music",
          "website": "http://www.eum.ac.jp/"
       },
       {
          "university": "Ferris University",
          "website": "http://www.ferris.ac.jp/"
       },
       {
          "university": "Fujita Health University",
          "website": "http://www.fujita-hu.ac.jp/"
       },
       {
          "university": "Fuji University",
          "website": "http://www.termnet.co.jp/fujidai/"
       },
       {
          "university": "Fuji Women's College",
          "website": "http://www.fujijoshi.ac.jp/"
       },
       {
          "university": "Fukui Medical School",
          "website": "http://www.fukui-med.ac.jp/"
       },
       {
          "university": "Fukui Prefectural University",
          "website": "http://www.fpu.ac.jp/"
       },
       {
          "university": "Fukui University",
          "website": "http://www.fukui-u.ac.jp/"
       },
       {
          "university": "Fukui University of Technology",
          "website": "http://www.fukui-ut.ac.jp/"
       },
       {
          "university": "Fukuoka Dental College",
          "website": "http://www.fdcnet.ac.jp/"
       },
       {
          "university": "Fukuoka Institute of Technology",
          "website": "http://www.fit.ac.jp/"
       },
       {
          "university": "Fukuoka International University",
          "website": "http://www.fukuoka-int-u.ac.jp/"
       },
       {
          "university": "Fukuoka Prefectural University",
          "website": "http://www.fukuoka-pu.ac.jp/"
       },
       {
          "university": "Fukuoka University",
          "website": "http://www.fukuoka-u.ac.jp/"
       },
       {
          "university": "Fukuoka University of Education",
          "website": "http://www.fukuoka-edu.ac.jp/"
       },
       {
          "university": "Fukuoka Women's University",
          "website": "http://www.fwu.ac.jp/"
       },
       {
          "university": "Fukushima Medical College",
          "website": "http://www.fmu.ac.jp/"
       },
       {
          "university": "Fukushima University",
          "website": "http://www.fukushima-u.ac.jp/"
       },
       {
          "university": "Fukuyama Heisei University",
          "website": "http://www.heisei-u.ac.jp/"
       },
       {
          "university": "Fukuyama University",
          "website": "http://www.fukuyama-u.ac.jp/"
       },
       {
          "university": "Gakushuin University",
          "website": "http://www.gakushuin.ac.jp/"
       },
       {
          "university": "Gifu Keizai University",
          "website": "http://www.gifu-keizai.ac.jp/"
       },
       {
          "university": "Gifu Pharmaceutical University",
          "website": "http://www.gifu-pu.ac.jp/"
       },
       {
          "university": "Gifu Shotoku Gakuen University",
          "website": "http://www.shotoku.ac.jp/"
       },
       {
          "university": "Gifu University",
          "website": "http://www.gifu-u.ac.jp/"
       },
       {
          "university": "Gifu University for Education and Languages",
          "website": "http://www.gifu-kyoiku.ac.jp/"
       },
       {
          "university": "Gifu Women's University",
          "website": "http://www.gijodai.ac.jp/"
       },
       {
          "university": "Graduate University for Advanced Studies",
          "website": "http://www.soken.ac.jp/"
       },
       {
          "university": "Gunma Prefectural Women's University",
          "website": "http://www.gpwu.ac.jp/"
       },
       {
          "university": "Gunma University",
          "website": "http://www.gunma-u.ac.jp/"
       },
       {
          "university": "Hachinohe Institute of Technology",
          "website": "http://www.hi-tech.ac.jp/"
       },
       {
          "university": "Hachinohe University",
          "website": "http://www.hachinohe-u.ac.jp/"
       },
       {
          "university": "Hakodate University",
          "website": "http://www.hakodate-u.ac.jp/"
       },
       {
          "university": "Hakuoh University",
          "website": "http://www.hakuoh.ac.jp/"
       },
       {
          "university": "Hamamatsu University",
          "website": "http://www.hamamatsu-u.ac.jp/"
       },
       {
          "university": "Hamamatsu University School of Medicine",
          "website": "http://www.hama-med.ac.jp/"
       },
       {
          "university": "Hanazono University",
          "website": "http://www.hanazono.ac.jp/"
       },
       {
          "university": "Hannan University",
          "website": "http://www.hannan-u.ac.jp/"
       },
       {
          "university": "Heisei International University",
          "website": "http://www.hiu.ac.jp/"
       },
       {
          "university": "Higashi Nippon International University",
          "website": "http://www.tonichi-kokusai-u.ac.jp/"
       },
       {
          "university": "Hijiyama University",
          "website": "http://www.hijiyama-u.ac.jp/"
       },
       {
          "university": "Himeji Dokkyo University",
          "website": "http://www.himeji-du.ac.jp/"
       },
       {
          "university": "Himeji Institute of Technology",
          "website": "http://www.himeji-tech.ac.jp/"
       },
       {
          "university": "Hirosaki Gakuin University",
          "website": "http://www.hirogaku-u.ac.jp/"
       },
       {
          "university": "Hirosaki University",
          "website": "http://www.hirosaki-u.ac.jp/"
       },
       {
          "university": "Hiroshima Bunkyo Women's University",
          "website": "http://www.h-bunkyo.ac.jp/"
       },
       {
          "university": "Hiroshima City University",
          "website": "http://www.hiroshima-cu.ac.jp/"
       },
       {
          "university": "Hiroshima Institute of Technology",
          "website": "http://www.it-hiroshima.ac.jp/"
       },
       {
          "university": "Hiroshima International University",
          "website": "http://www.hirokoku-u.ac.jp/"
       },
       {
          "university": "Hiroshima Jogakuin University",
          "website": "http://www.hju.ac.jp/"
       },
       {
          "university": "Hiroshima Kokusai Gakuin University",
          "website": "http://www.hkg.ac.jp/"
       },
       {
          "university": "Hiroshima Prefectural University",
          "website": "http://www.hiroshima-pu.ac.jp/"
       },
       {
          "university": "Hiroshima Shudo University",
          "website": "http://www.shudo-u.ac.jp/"
       },
       {
          "university": "Hiroshima University",
          "website": "http://www.hiroshima-u.ac.jp/"
       },
       {
          "university": "Hiroshima University of Economics",
          "website": "http://www.hue.ac.jp/"
       },
       {
          "university": "Hiroshima Women's University",
          "website": "http://www.hirojo-u.ac.jp/"
       },
       {
          "university": "Hitotsubashi University",
          "website": "http://www.hit-u.ac.jp/"
       },
       {
          "university": "Hokkaido Information University",
          "website": "http://www.do-johodai.ac.jp/"
       },
       {
          "university": "Hokkaido Institute of Pharmaceutical Sciences",
          "website": "http://www.hokuyakudai.ac.jp/"
       },
       {
          "university": "Hokkaido Institute of Technology",
          "website": "http://www.hit.ac.jp/"
       },
       {
          "university": "Hokkaido Tokai University",
          "website": "http://www.u-tokai.ac.jp/"
       },
       {
          "university": "Hokkaido University",
          "website": "http://www.hokudai.ac.jp/"
       },
       {
          "university": "Hokkaido University of Education",
          "website": "http://www.hokkyodai.ac.jp/"
       },
       {
          "university": "Hokkaido University of Health Sciences",
          "website": "http://www.hoku-iryo-u.ac.jp/"
       },
       {
          "university": "Hokkaigakuen University",
          "website": "http://www.hokkai-s-u.ac.jp/"
       },
       {
          "university": "Hokkaigakuen University of Kitami",
          "website": "http://www.hokkai-k-u.ac.jp/"
       },
       {
          "university": "Hokuriku University",
          "website": "http://www.hokuriku-u.ac.jp/"
       },
       {
          "university": "Hokusei Gakuen University",
          "website": "http://www.hokusei.ac.jp/"
       },
       {
          "university": "Hosei University",
          "website": "http://www.hosei.ac.jp/"
       },
       {
          "university": "Hoshi University",
          "website": "http://www.hoshi.ac.jp/"
       },
       {
          "university": "Hyogo College of Medicine",
          "website": "http://www.hyo-med.ac.jp/"
       },
       {
          "university": "Hyogo University",
          "website": "http://www.hyogo-dai.ac.jp/"
       },
       {
          "university": "Hyogo University of Education",
          "website": "http://www.hyogo-u.ac.jp/"
       },
       {
          "university": "Ibaraki Christian College",
          "website": "http://www.icc.ac.jp/"
       },
       {
          "university": "Ibaraki Prefectural University of Health Sciences",
          "website": "http://www.ipu.ac.jp/"
       },
       {
          "university": "Ibaraki University",
          "website": "http://www.ibaraki.ac.jp/"
       },
       {
          "university": "International Buddhist University",
          "website": "http://www.shitennoji.ac.jp/"
       },
       {
          "university": "International Budo University",
          "website": "http://www.budo-u.ac.jp/"
       },
       {
          "university": "International Christian University",
          "website": "http://www.icu.ac.jp/"
       },
       {
          "university": "International University of Health and Welfare",
          "website": "http://www.iuhw.ac.jp/"
       },
       {
          "university": "International University of Japan",
          "website": "http://www.iuj.ac.jp/"
       },
       {
          "university": "International University of Kagoshima",
          "website": "http://www.iuk.ac.jp/"
       },
       {
          "university": "Ishinomaki Senshu University",
          "website": "http://www.isenshu-u.ac.jp/"
       },
       {
          "university": "Iwaki Meisei University",
          "website": "http://www.iwakimu.ac.jp/"
       },
       {
          "university": "Iwate Medical University",
          "website": "http://www.iwate-med.ac.jp/"
       },
       {
          "university": "Iwate Prefectural University",
          "website": "http://www.iwate-pu.ac.jp/"
       },
       {
          "university": "Iwate University",
          "website": "http://www.iwate-u.ac.jp/"
       },
       {
          "university": "Japan Advanced Institute of Science and Technology",
          "website": "http://www.jaist.ac.jp/"
       },
       {
          "university": "Japan College of Social Work",
          "website": "http://www.shakaijigyo.ac.jp/"
       },
       {
          "university": "Japanese Red Cross College of Nursing",
          "website": "http://www.redcross.ac.jp/"
       },
       {
          "university": "Japan Women's University",
          "website": "http://www.jwu.ac.jp/"
       },
       {
          "university": "Jichi Medical School",
          "website": "http://www.jichi.ac.jp/"
       },
       {
          "university": "Jikei University School of Medicine",
          "website": "http://www.jikei.ac.jp/"
       },
       {
          "university": "Jissen Women's University",
          "website": "http://www.jissen.ac.jp/"
       },
       {
          "university": "Jobu University",
          "website": "http://www.jobu.ac.jp/"
       },
       {
          "university": "Joetsu University of Education",
          "website": "http://www.juen.ac.jp/"
       },
       {
          "university": "Josai International University",
          "website": "http://www.jiu.ac.jp/"
       },
       {
          "university": "Josai University",
          "website": "http://www.josai.ac.jp/"
       },
       {
          "university": "Juntendo University",
          "website": "http://www.juntendo.ac.jp/"
       },
       {
          "university": "Kagawa Institute of Nutrition",
          "website": "http://www.eiyo.ac.jp/"
       },
       {
          "university": "Kagawa Medical School",
          "website": "http://www.kms.ac.jp/"
       },
       {
          "university": "Kagawa University",
          "website": "http://www.kagawa-u.ac.jp/"
       },
       {
          "university": "Kagoshima Immaculate Heart University",
          "website": "http://www.juntan.k-junshin.ac.jp/jundai/"
       },
       {
          "university": "Kagoshima University",
          "website": "http://www.kagoshima-u.ac.jp/"
       },
       {
          "university": "Kagoshima Women's College",
          "website": "http://www.kwc-u.ac.jp/"
       },
       {
          "university": "Kamakura Women's College",
          "website": "http://www.kamakura-u.ac.jp/"
       },
       {
          "university": "Kanagawa Dental College",
          "website": "http://www.kdcnet.ac.jp/"
       },
       {
          "university": "Kanagawa Institute of Technology",
          "website": "http://www.kanagawa-it.ac.jp/"
       },
       {
          "university": "Kanagawa University",
          "website": "http://www.kanagawa-u.ac.jp/"
       },
       {
          "university": "Kanazawa College of Art",
          "website": "http://www.kanazawa-bidai.ac.jp/"
       },
       {
          "university": "Kanazawa College of Economics",
          "website": "http://www.kanazawa-eco.ac.jp/"
       },
       {
          "university": "Kanazawa Gakuin University",
          "website": "http://www.kanazawa-gu.ac.jp/"
       },
       {
          "university": "Kanazawa Institute of Technology",
          "website": "http://www.kanazawa-it.ac.jp/"
       },
       {
          "university": "Kanazawa Medical University",
          "website": "http://www.kanazawa-med.ac.jp/"
       },
       {
          "university": "Kanazawa University",
          "website": "http://www.kanazawa-u.ac.jp/"
       },
       {
          "university": "Kanda University of International Studies",
          "website": "http://www.kuis.ac.jp/"
       },
       {
          "university": "Kansai Gaidai University",
          "website": "http://www.kansai-gaidai-u.ac.jp/"
       },
       {
          "university": "Kansai Medical University",
          "website": "http://www.kmu.ac.jp/"
       },
       {
          "university": "Kansai University",
          "website": "http://www.kansai-u.ac.jp/"
       },
       {
          "university": "Kansai University of International Studies",
          "website": "http://www.kuins.ac.jp/"
       },
       {
          "university": "Kansai University of Social Welfare",
          "website": "http://www.kusw.ac.jp/"
       },
       {
          "university": "Kanto Gakuen University",
          "website": "http://www.kanto-gakuen.ac.jp/"
       },
       {
          "university": "Kanto Gakuin University",
          "website": "http://www.kanto-gakuin.ac.jp/"
       },
       {
          "university": "Kawamura Gakuen Woman's University",
          "website": "http://www.kgwu.ac.jp/"
       },
       {
          "university": "Kawasaki Medical School",
          "website": "http://www.kawasaki-m.ac.jp/"
       },
       {
          "university": "Kawasaki University of Medical Care",
          "website": "http://www.kawasaki-m.ac.jp/mw/"
       },
       {
          "university": "Keiai University",
          "website": "http://www.u-keiai.ac.jp/"
       },
       {
          "university": "Keio University",
          "website": "http://www.keio.ac.jp/"
       },
       {
          "university": "Keisen Jogaku-en College",
          "website": "http://www.keisen.ac.jp/"
       },
       {
          "university": "Keiwa College",
          "website": "http://www.keiwa-c.ac.jp/"
       },
       {
          "university": "Kibi International University",
          "website": "http://www.kiui.ac.jp/"
       },
       {
          "university": "Kinjo Gakuin University",
          "website": "http://www.kinjo-u.ac.jp/"
       },
       {
          "university": "Kinki University",
          "website": "http://www.kindai.ac.jp/"
       },
       {
          "university": "Kitakyushu University",
          "website": "http://www.kitakyu-u.ac.jp/"
       },
       {
          "university": "Kitami Institute of Technology",
          "website": "http://www.kitami-it.ac.jp/"
       },
       {
          "university": "Kitasato University",
          "website": "http://www.kitasato-u.ac.jp/"
       },
       {
          "university": "Kobe City University of ForeignStudies",
          "website": "http://www.kobe-cufs.ac.jp/"
       },
       {
          "university": "Kobe Design University",
          "website": "http://www.kobe-du.ac.jp/"
       },
       {
          "university": "Kobe Gakuin University",
          "website": "http://www.kobegakuin.ac.jp/"
       },
       {
          "university": "Kobe International University",
          "website": "http://www.kobe-kiu.ac.jp/"
       },
       {
          "university": "Kobe Jogakuin University",
          "website": "http://www.kobe-c.ac.jp/"
       },
       {
          "university": "Kobe Pharmaceutical University",
          "website": "http://www.kobepharma-u.ac.jp/"
       },
       {
          "university": "Kobe Shinwa Women's University",
          "website": "http://www.kobe-shinwa.ac.jp/"
       },
       {
          "university": "Kobe Shoin Women's University",
          "website": "http://www.shoin.ac.jp/"
       },
       {
          "university": "Kobe University",
          "website": "http://www.kobe-u.ac.jp/"
       },
       {
          "university": "Kobe University of Mercantile Marine",
          "website": "http://www.kshosen.ac.jp/"
       },
       {
          "university": "Kobe Women's University",
          "website": "http://www.suma.kobe-wu.ac.jp/"
       },
       {
          "university": "Kochi Medical School",
          "website": "http://www.kochi-ms.ac.jp/"
       },
       {
          "university": "Kochi University",
          "website": "http://www.kochi-u.ac.jp/"
       },
       {
          "university": "Kochi University of Technology",
          "website": "http://www.kochi-tech.ac.jp/"
       },
       {
          "university": "Kochi Women's University",
          "website": "http://www.kochi-wu.ac.jp/"
       },
       {
          "university": "Kogakkan University",
          "website": "http://www.kogakkan-u.ac.jp/"
       },
       {
          "university": "Kogakuin University",
          "website": "http://www.kogakuin.ac.jp/"
       },
       {
          "university": "Koka Women's College",
          "website": "http://www.koka.ac.jp/"
       },
       {
          "university": "Kokugakuin University",
          "website": "http://www.kokugakuin.ac.jp/"
       },
       {
          "university": "Kokushikan University",
          "website": "http://www.kokushikan.ac.jp/"
       },
       {
          "university": "Komazawa University",
          "website": "http://www.komazawa-u.ac.jp/"
       },
       {
          "university": "Konan University",
          "website": "http://www.konan-u.ac.jp/"
       },
       {
          "university": "Konan Women's University",
          "website": "http://www.konan-wu.ac.jp/"
       },
       {
          "university": "Korea University",
          "website": "http://www.korea-u.ac.jp/"
       },
       {
          "university": "Koriyama Women's University and College",
          "website": "http://www.koriyama-kgc.ac.jp/"
       },
       {
          "university": "Koshien University",
          "website": "http://www.koshien.ac.jp/"
       },
       {
          "university": "Koyasan University",
          "website": "http://www.koyasan-u.ac.jp/"
       },
       {
          "university": "Kumamoto Gakuen University",
          "website": "http://www.kumagaku.ac.jp/"
       },
       {
          "university": "Kumamoto Institute of Technology",
          "website": "http://www.knct.ac.jp/"
       },
       {
          "university": "Kumamoto Prefectural University",
          "website": "http://www.pu-kumamoto.ac.jp/"
       },
       {
          "university": "Kumamoto University",
          "website": "http://www.kumamoto-u.ac.jp/"
       },
       {
          "university": "Kunitachi College of Music",
          "website": "http://www.kunitachi.ac.jp/"
       },
       {
          "university": "Kurashiki Sakuyo University",
          "website": "http://www.ksu.ac.jp/"
       },
       {
          "university": "Kurashiki University of Science and the Arts",
          "website": "http://www.kusa.ac.jp/"
       },
       {
          "university": "Kure University",
          "website": "http://www.kure-u.ac.jp/"
       },
       {
          "university": "Kurume Institute of Technology",
          "website": "http://www.kurume-it.ac.jp/"
       },
       {
          "university": "Kurume University",
          "website": "http://www.kurume-u.ac.jp/"
       },
       {
          "university": "Kushiro Public University of Economics",
          "website": "http://www.kushiro-pu.ac.jp/"
       },
       {
          "university": "Kwansei Gakuin University",
          "website": "http://www.kwansei.ac.jp/"
       },
       {
          "university": "Kwassui Women's College",
          "website": "http://www.kwassui.ac.jp/"
       },
       {
          "university": "Kyorin University",
          "website": "http://www.kyorin-u.ac.jp/"
       },
       {
          "university": "Kyoritsu Pharmaceutical University",
          "website": "http://www.kyoritsu-ph.ac.jp/"
       },
       {
          "university": "Kyoritsu Woman's University",
          "website": "http://www.kyoritsu-wu.ac.jp/"
       },
       {
          "university": "Kyoto Bunkyo University",
          "website": "http://www.kbu.ac.jp/"
       },
       {
          "university": "Kyoto City University of Arts",
          "website": "http://www.kcua.ac.jp/"
       },
       {
          "university": "Kyoto Gakuen University",
          "website": "http://www.kyotogakuen-u.ac.jp/"
       },
       {
          "university": "Kyoto Institute of Technology",
          "website": "http://www.kit.ac.jp/"
       },
       {
          "university": "Kyoto Pharmaceutical University",
          "website": "http://www.kyoto-phu.ac.jp/"
       },
       {
          "university": "Kyoto Prefectural University",
          "website": "http://www.kpu.ac.jp/"
       },
       {
          "university": "Kyoto Prefectural University of Medicine",
          "website": "http://www.kpu-m.ac.jp/"
       },
       {
          "university": "Kyoto Sangyo University",
          "website": "http://www.kyoto-su.ac.jp/"
       },
       {
          "university": "Kyoto Seika University",
          "website": "http://www.kyoto-seika.ac.jp/"
       },
       {
          "university": "Kyoto Tachibana Women's University",
          "website": "http://www.tachibana-u.ac.jp/"
       },
       {
          "university": "Kyoto University",
          "website": "http://www.kyoto-u.ac.jp/"
       },
       {
          "university": "Kyoto University of Art and Design",
          "website": "http://www.kyoto-art.ac.jp/"
       },
       {
          "university": "Kyoto University of Education",
          "website": "http://www.kyokyo-u.ac.jp/"
       },
       {
          "university": "Kyoto University of Foreign Studies",
          "website": "http://www.kufs.ac.jp/"
       },
       {
          "university": "Kyoto Women's University",
          "website": "http://www.kyoto-wu.ac.jp/"
       },
       {
          "university": "Kyushu Dental College",
          "website": "http://www.kyu-dent.ac.jp/"
       },
       {
          "university": "Kyushu Institute of Design",
          "website": "http://www.kyushu-id.ac.jp/"
       },
       {
          "university": "Kyushu Institute of Technology",
          "website": "http://www.kyutech.ac.jp/"
       },
       {
          "university": "Kyushu International University",
          "website": "http://www.kiu.ac.jp/"
       },
       {
          "university": "Kyushu Kyoritsu University",
          "website": "http://www.kyukyo-u.ac.jp/"
       },
       {
          "university": "Kyushu Sangyo University",
          "website": "http://www.ip.kyusan-u.ac.jp/"
       },
       {
          "university": "Kyushu Tokai University",
          "website": "http://www.ktokai-u.ac.jp/"
       },
       {
          "university": "Kyushu University",
          "website": "http://www.kyushu-u.ac.jp/"
       },
       {
          "university": "Kyushu University of Nursing and SocialWelfare",
          "website": "http://www.kyushu-ns.ac.jp/"
       },
       {
          "university": "Kyushu Women's University",
          "website": "http://www.kwuc.ac.jp/"
       },
       {
          "university": "Matsumoto Dental University",
          "website": "http://www.mdu.ac.jp/"
       },
       {
          "university": "Matsusaka University",
          "website": "http://www.mie-chukyo-u.ac.jp/"
       },
       {
          "university": "Matsuyama University",
          "website": "http://www.matsuyama-u.ac.jp/"
       },
       {
          "university": "Meiji College of Pharmacy",
          "website": "http://www.my-pharm.ac.jp/"
       },
       {
          "university": "Meiji Gakuin University",
          "website": "http://www.meijigakuin.ac.jp/"
       },
       {
          "university": "Meiji University",
          "website": "http://www.meiji.ac.jp/"
       },
       {
          "university": "Meiji University of Oriental Medicine",
          "website": "http://www.meiji-u.ac.jp/"
       },
       {
          "university": "Meijo University",
          "website": "http://www.meijo-u.ac.jp/"
       },
       {
          "university": "Meikai University",
          "website": "http://www.meikai.ac.jp/"
       },
       {
          "university": "Meio University",
          "website": "http://www.meio-u.ac.jp/"
       },
       {
          "university": "Meisei University",
          "website": "http://www.meisei-u.ac.jp/"
       },
       {
          "university": "Mejiro University",
          "website": "http://www.mejiro.ac.jp/"
       },
       {
          "university": "Mie University",
          "website": "http://www.mie-u.ac.jp/"
       },
       {
          "university": "Mimasaka Women's College",
          "website": "http://www.mimasaka.ac.jp/"
       },
       {
          "university": "Minamikyushu University",
          "website": "http://www.nankyudai.ac.jp/"
       },
       {
          "university": "Miyagi Gakuin Women's College",
          "website": "http://www.mgu.ac.jp/"
       },
       {
          "university": "Miyagi University",
          "website": "http://www.myu.ac.jp/"
       },
       {
          "university": "Miyagi University of Education",
          "website": "http://www.miyakyo-u.ac.jp/"
       },
       {
          "university": "Miyazaki Medical College",
          "website": "http://www.miyazaki-med.ac.jp/"
       },
       {
          "university": "Miyazaki Municipal University",
          "website": "http://www.miyazaki-mu.ac.jp/"
       },
       {
          "university": "Miyazaki Prefectural Nursing University",
          "website": "http://www.mpu.ac.jp/"
       },
       {
          "university": "Miyazaki University",
          "website": "http://www.miyazaki-u.ac.jp/"
       },
       {
          "university": "Morioka College",
          "website": "http://www.morioka-u.ac.jp/"
       },
       {
          "university": "Mukogawa Women's University",
          "website": "http://www.mukogawa-u.ac.jp/"
       },
       {
          "university": "Muroran Institute of Technology",
          "website": "http://www.muroran-it.ac.jp/"
       },
       {
          "university": "Musashi Institute of Technology",
          "website": "http://www.musashi-tech.ac.jp/"
       },
       {
          "university": "Musashino Academy of Music",
          "website": "http://www.musashino-music.ac.jp/"
       },
       {
          "university": "Musashino Art University",
          "website": "http://www.musabi.ac.jp/"
       },
       {
          "university": "Musashino Women's University",
          "website": "http://www.musashino-wu.ac.jp/"
       },
       {
          "university": "Musashi University",
          "website": "http://www.musashi.ac.jp/"
       },
       {
          "university": "Nagano University",
          "website": "http://www.nagano.ac.jp/"
       },
       {
          "university": "Nagaoka University of Technology",
          "website": "http://www.nagaokaut.ac.jp/"
       },
       {
          "university": "Nagasaki Institute of Applied Science",
          "website": "http://www.nias.ac.jp/"
       },
       {
          "university": "Nagasaki Prefectural University",
          "website": "http://www.nagasakipu.ac.jp/"
       },
       {
          "university": "Nagasaki University",
          "website": "http://www.nagasaki-u.ac.jp/"
       },
       {
          "university": "Nagoya City University",
          "website": "http://www.nagoya-cu.ac.jp/"
       },
       {
          "university": "Nagoya College of Music",
          "website": "http://www.doho.ac.jp/~meion/"
       },
       {
          "university": "Nagoya Economics University",
          "website": "http://www.nagoya-ku.ac.jp/"
       },
       {
          "university": "Nagoya Gakuin University",
          "website": "http://www.ngu.ac.jp/"
       },
       {
          "university": "Nagoya Institute of Technology",
          "website": "http://www.nitech.ac.jp/"
       },
       {
          "university": "Nagoya University",
          "website": "http://www.nagoya-u.ac.jp/"
       },
       {
          "university": "Nagoya University of Arts",
          "website": "http://www.nua.ac.jp/"
       },
       {
          "university": "Nagoya University of Commerce and Business Administration",
          "website": "http://www.nucba.ac.jp/"
       },
       {
          "university": "Nagoya University of Foreign Studies",
          "website": "http://www.nufs.nakanishi.ac.jp/"
       },
       {
          "university": "Nagoya Women's University",
          "website": "http://www.nagoya-wu.ac.jp/"
       },
       {
          "university": "Nakamura Gakuen University",
          "website": "http://www.nakamura-u.ac.jp/"
       },
       {
          "university": "Nanzan University",
          "website": "http://www.nanzan-u.ac.jp/"
       },
       {
          "university": "Nara Medical University",
          "website": "http://www.naramed-u.ac.jp/"
       },
       {
          "university": "Nara Sangyo University",
          "website": "http://www.nara-su.ac.jp/"
       },
       {
          "university": "Nara University",
          "website": "http://www.nara-u.ac.jp/"
       },
       {
          "university": "Nara University of Commerce",
          "website": "http://www.narauc.ac.jp/"
       },
       {
          "university": "Nara University of Education",
          "website": "http://www.nara-edu.ac.jp/"
       },
       {
          "university": "Nara Women's University",
          "website": "http://www.nara-wu.ac.jp/"
       },
       {
          "university": "Naruto University of Education",
          "website": "http://www.naruto-u.ac.jp/"
       },
       {
          "university": "National Defence Medical College",
          "website": "http://www.ndmc.ac.jp/"
       },
       {
          "university": "National Fisheries University",
          "website": "http://www.fish-u.ac.jp/"
       },
       {
          "university": "National Institute of Fitness and Sports Kanoya",
          "website": "http://www.nifs-k.ac.jp/"
       },
       {
          "university": "Nihon Fukushi University",
          "website": "http://www.n-fukushi.ac.jp/"
       },
       {
          "university": "Nihon University",
          "website": "http://www.nihon-u.ac.jp/"
       },
       {
          "university": "Niigata College of Pharmacy",
          "website": "http://www.niigata-pharm.ac.jp/"
       },
       {
          "university": "Niigata Sangyo University",
          "website": "http://www.nsu.ac.jp/"
       },
       {
          "university": "Niigata University",
          "website": "http://www.niigata-u.ac.jp/"
       },
       {
          "university": "Niigata University of International and Information Studies",
          "website": "http://www.nuis.ac.jp/"
       },
       {
          "university": "Niigata University of Management",
          "website": "http://www.niigataum.ac.jp/"
       },
       {
          "university": "Nippon Bunri University",
          "website": "http://www.nbu.ac.jp/"
       },
       {
          "university": "Nippon Dental University",
          "website": "http://www.ndu.ac.jp/"
       },
       {
          "university": "Nippon Institute of Technology",
          "website": "http://www.nit.ac.jp/"
       },
       {
          "university": "Nippon Medical School",
          "website": "http://www.nms.ac.jp/"
       },
       {
          "university": "Nippon Sport Science University",
          "website": "http://www.nittai.ac.jp/"
       },
       {
          "university": "Nippon Veterinary and Animalscience University",
          "website": "http://www.nvau.ac.jp/"
       },
       {
          "university": "Nishikyushu University",
          "website": "http://www.nisikyu-u.ac.jp/"
       },
       {
          "university": "Nishinippon Institute of Technology",
          "website": "http://www.nishitech.ac.jp/"
       },
       {
          "university": "Nisho Gakusha University",
          "website": "http://www.nishogakusha-u.ac.jp/"
       },
       {
          "university": "Nortre Dame Seishin University",
          "website": "http://www.ndsu.ac.jp/"
       },
       {
          "university": "Notre Dame Women's College",
          "website": "http://www.notredame.ac.jp/"
       },
       {
          "university": "Obihiro University of Agriculture and Veterinary Medicine",
          "website": "http://www.obihiro.ac.jp/"
       },
       {
          "university": "Obirin University",
          "website": "http://www.obirin.ac.jp/"
       },
       {
          "university": "Ochanomizu Women's University",
          "website": "http://www.ocha.ac.jp/"
       },
       {
          "university": "Ohka Gakuen University",
          "website": "http://www.ohkagakuen-u.ac.jp/"
       },
       {
          "university": "Ohtani Women's University",
          "website": "http://www.ohtani-w.ac.jp/"
       },
       {
          "university": "Ohu University",
          "website": "http://www.ohu-u.ac.jp/"
       },
       {
          "university": "Oita Medical University",
          "website": "http://www.oita-med.ac.jp/"
       },
       {
          "university": "Oita University",
          "website": "http://www.oita-u.ac.jp/"
       },
       {
          "university": "Oita University of Nursing and Health Sciences",
          "website": "http://www.oita-nhs.ac.jp/"
       },
       {
          "university": "Okayama Prefectural University",
          "website": "http://www.oka-pu.ac.jp/"
       },
       {
          "university": "Okayama Shoka University",
          "website": "http://www.osu.ac.jp/"
       },
       {
          "university": "Okayama University",
          "website": "http://www.okayama-u.ac.jp/"
       },
       {
          "university": "Okayama University of Science",
          "website": "http://www.ous.ac.jp/"
       },
       {
          "university": "Okinawa International University",
          "website": "http://www.okiu.ac.jp/"
       },
       {
          "university": "Okinawa Prefectural University of Fine Arts",
          "website": "http://www.okigei.ac.jp/"
       },
       {
          "university": "Okinawa University",
          "website": "http://www.okinawa-u.ac.jp/"
       },
       {
          "university": "Osaka City University",
          "website": "http://www.osaka-cu.ac.jp/"
       },
       {
          "university": "Osaka College of Music",
          "website": "http://www.daion.ac.jp/"
       },
       {
          "university": "Osaka Dental University",
          "website": "http://www.osaka-dent.ac.jp/"
       },
       {
          "university": "Osaka Electro-Communication University",
          "website": "http://www.osakac.ac.jp/"
       },
       {
          "university": "Osaka Gakuin University",
          "website": "http://www.osaka-gu.ac.jp/"
       },
       {
          "university": "Osaka Institute of Technology",
          "website": "http://www.oit.ac.jp/"
       },
       {
          "university": "Osaka International University",
          "website": "http://www.oiu.oiu.ac.jp/"
       },
       {
          "university": "Osaka International University for Women",
          "website": "http://www.oiuw.oiu.ac.jp/"
       },
       {
          "university": "Osaka Medical College",
          "website": "http://www.osaka-med.ac.jp/"
       },
       {
          "university": "Osaka Prefectural University",
          "website": "http://www.osakafu-u.ac.jp/"
       },
       {
          "university": "Osaka Sangyo University",
          "website": "http://www.osaka-sandai.ac.jp/"
       },
       {
          "university": "Osaka Shoin Women's College",
          "website": "http://www.osaka-shoin.ac.jp/"
       },
       {
          "university": "Osaka University",
          "website": "http://www.osaka-u.ac.jp/"
       },
       {
          "university": "Osaka University of Arts",
          "website": "http://www.osaka-geidai.ac.jp/"
       },
       {
          "university": "Osaka University of Commerce",
          "website": "http://www.daishodai.ac.jp/"
       },
       {
          "university": "Osaka University of Economics",
          "website": "http://www.osaka-ue.ac.jp/"
       },
       {
          "university": "Osaka University of Economics & Law",
          "website": "http://www.keiho-u.ac.jp/"
       },
       {
          "university": "Osaka University of Education",
          "website": "http://www.osaka-kyoiku.ac.jp/"
       },
       {
          "university": "Osaka University of Foreign Studies",
          "website": "http://www.osaka-gaidai.ac.jp/"
       },
       {
          "university": "Osaka University of Health and Sport Sciences",
          "website": "http://www.ouhs.ac.jp/"
       },
       {
          "university": "Osaka University of Pharmaceutical Sciences",
          "website": "http://www.oups.ac.jp/"
       },
       {
          "university": "Osaka Women's University",
          "website": "http://www.osaka-wu.ac.jp/"
       },
       {
          "university": "Otani University",
          "website": "http://www.otani.ac.jp/"
       },
       {
          "university": "Otaru University of Commerce",
          "website": "http://www.otaru-uc.ac.jp/"
       },
       {
          "university": "Otemae University",
          "website": "http://www.otemae.ac.jp/"
       },
       {
          "university": "Otemon Gakuin University",
          "website": "http://www.otemon.ac.jp/"
       },
       {
          "university": "Otsuma Women's University",
          "website": "http://www.otsuma.ac.jp/"
       },
       {
          "university": "Polytechnic University",
          "website": "http://www.uitec.ac.jp/"
       },
       {
          "university": "Poole Gakuin University",
          "website": "http://www.poole.ac.jp/"
       },
       {
          "university": "Rakuno Gakuen University",
          "website": "http://www.rakuno.ac.jp/"
       },
       {
          "university": "Reitaku University",
          "website": "http://www.reitaku-u.ac.jp/"
       },
       {
          "university": "Rikkyo University (St. Paul's University)",
          "website": "http://www.rikkyo.ac.jp/"
       },
       {
          "university": "Rissho University",
          "website": "http://www.ris.ac.jp/"
       },
       {
          "university": "Ritsumeikan Asia Pacific University",
          "website": "http://www.apu.ac.jp/"
       },
       {
          "university": "Ritsumeikan University",
          "website": "http://www.ritsumei.ac.jp/"
       },
       {
          "university": "Ryukoku University",
          "website": "http://www.ryukoku.ac.jp/"
       },
       {
          "university": "Ryutsu Keizai University",
          "website": "http://www.rku.ac.jp/"
       },
       {
          "university": "Saga Medical School",
          "website": "http://www.saga-med.ac.jp/"
       },
       {
          "university": "Sagami Women's University",
          "website": "http://www.sagami-wu.ac.jp/"
       },
       {
          "university": "Saga University",
          "website": "http://www.saga-u.ac.jp/"
       },
       {
          "university": "Saitama Institute of Technology",
          "website": "http://www.sit.ac.jp/"
       },
       {
          "university": "Saitama Medical School",
          "website": "http://www.saitama-med.ac.jp/"
       },
       {
          "university": "Saitama Prefectural University",
          "website": "http://www.spu.ac.jp/"
       },
       {
          "university": "Saitama University",
          "website": "http://www.saitama-u.ac.jp/"
       },
       {
          "university": "Sakushin Gakuin University",
          "website": "http://www.sakushin-u.ac.jp/"
       },
       {
          "university": "Sankei University",
          "website": "http://www.miyasankei-u.ac.jp/"
       },
       {
          "university": "Sanno University",
          "website": "http://www.sanno.ac.jp/"
       },
       {
          "university": "Sanyo Gakuen University",
          "website": "http://www.sguc.ac.jp/"
       },
       {
          "university": "Sapporo Gakuin University",
          "website": "http://www.sgu.ac.jp/"
       },
       {
          "university": "Sapporo International University",
          "website": "http://www.siu.ac.jp/"
       },
       {
          "university": "Sapporo Medical University",
          "website": "http://www.sapmed.ac.jp/"
       },
       {
          "university": "Sapporo University",
          "website": "http://www.sapporo-u.ac.jp/"
       },
       {
          "university": "Science University of Tokyo",
          "website": "http://www.sut.ac.jp/"
       },
       {
          "university": "Science University of Tokyo in Yamaguchi",
          "website": "http://www.yama.sut.ac.jp/"
       },
       {
          "university": "Seian University of Art & Design",
          "website": "http://www.seian.ac.jp/"
       },
       {
          "university": "Seigakuin University",
          "website": "http://www.seigakuin-univ.ac.jp/"
       },
       {
          "university": "Seijo University",
          "website": "http://www.seijo.ac.jp/"
       },
       {
          "university": "Seikei University",
          "website": "http://www.seikei.ac.jp/"
       },
       {
          "university": "Seinan Gakuin University",
          "website": "http://www.seinan-gu.ac.jp/"
       },
       {
          "university": "Seisen University",
          "website": "http://www.seisen-u.ac.jp/"
       },
       {
          "university": "Seiwa College",
          "website": "http://www.seiwa-u.ac.jp/"
       },
       {
          "university": "Sendai University",
          "website": "http://www.sendaidaigaku.jp/"
       },
       {
          "university": "Senshu University",
          "website": "http://www.senshu-u.ac.jp/"
       },
       {
          "university": "Senzoku Gakuen College",
          "website": "http://www.senzoku.ac.jp/"
       },
       {
          "university": "Setsunan University",
          "website": "http://www.setsunan.ac.jp/"
       },
       {
          "university": "Shibaura Institute of Technology",
          "website": "http://www.shibaura-it.ac.jp/"
       },
       {
          "university": "Shiga University",
          "website": "http://www.shiga-u.ac.jp/"
       },
       {
          "university": "Shiga University of Medical Science",
          "website": "http://www.shiga-med.ac.jp/"
       },
       {
          "university": "Shikoku Christian College",
          "website": "http://www.sg-u.ac.jp/"
       },
       {
          "university": "Shikoku University",
          "website": "http://www.shikoku-u.ac.jp/"
       },
       {
          "university": "Shimane University",
          "website": "http://www.shimane-u.ac.jp/"
       },
       {
          "university": "Shimane University - Faculty of Medicine",
          "website": "http://www.shimane-med.ac.jp/"
       },
       {
          "university": "Shimonoseki City University",
          "website": "http://www.shimonoseki-cu.ac.jp/"
       },
       {
          "university": "Shinshu University",
          "website": "http://www.shinshu-u.ac.jp/"
       },
       {
          "university": "Shirayuri Women's College",
          "website": "http://www.shirayuri.ac.jp/"
       },
       {
          "university": "Shizuoka Prefectural University",
          "website": "http://www.u-shizuoka-ken.ac.jp/"
       },
       {
          "university": "Shizuoka Sangyo University",
          "website": "http://www.ssu.ac.jp/"
       },
       {
          "university": "Shizuoka University",
          "website": "http://www.shizuoka.ac.jp/"
       },
       {
          "university": "Shokei College",
          "website": "http://www.shokei-gakuen.ac.jp/shokeicol/"
       },
       {
          "university": "Shonan Institute of Technology",
          "website": "http://www.shonan-it.ac.jp/"
       },
       {
          "university": "Showa College of Pharmaceutical Sciences",
          "website": "http://www.shoyaku.ac.jp/"
       },
       {
          "university": "Showa University",
          "website": "http://www.showa-u.ac.jp/"
       },
       {
          "university": "Showa Women's University",
          "website": "http://www.swu.ac.jp/"
       },
       {
          "university": "Shuchiin College",
          "website": "http://www.shuchiin.ac.jp/"
       },
       {
          "university": "Shujitsu Women's University",
          "website": "http://www.shujitsu.ac.jp/"
       },
       {
          "university": "Shukutoku University",
          "website": "http://www.shukutoku.ac.jp/"
       },
       {
          "university": "Shumei University",
          "website": "http://www.shumei-u.ac.jp/"
       },
       {
          "university": "Siebold University of Nagasaki",
          "website": "http://www.sun.ac.jp/"
       },
       {
          "university": "Soai University",
          "website": "http://www.soai.ac.jp/"
       },
       {
          "university": "Soka University",
          "website": "http://www.soka.ac.jp/"
       },
       {
          "university": "Sonoda Women's University",
          "website": "http://www.sonoda-u.ac.jp/"
       },
       {
          "university": "Sophia University",
          "website": "http://www.sophia.ac.jp/"
       },
       {
          "university": "St. Andrew's University",
          "website": "http://www.andrew.ac.jp/"
       },
       {
          "university": "St. Luke' s College of Nursing",
          "website": "http://www.slcn.ac.jp/"
       },
       {
          "university": "St. Marianna University School of Medicine",
          "website": "http://www.marianna-u.ac.jp/"
       },
       {
          "university": "Sugino Women's College",
          "website": "http://www.sugino.ac.jp/daiindex.html"
       },
       {
          "university": "Sugiyama Jogakuen University",
          "website": "http://www.sugiyama-u.ac.jp/"
       },
       {
          "university": "Surugadai University",
          "website": "http://www.surugadai.ac.jp/"
       },
       {
          "university": "Suzuka International University",
          "website": "http://www.suzuka-iu.ac.jp/"
       },
       {
          "university": "Suzuka University of Medical Science",
          "website": "http://www.suzuka-u.ac.jp/"
       },
       {
          "university": "Taisho University",
          "website": "http://www.tais.ac.jp/"
       },
       {
          "university": "Takachiho University",
          "website": "http://www.takachiho.ac.jp/"
       },
       {
          "university": "Takamatsu University",
          "website": "http://www.takamatsu-u.ac.jp/"
       },
       {
          "university": "Takarazuka University of Art and Design",
          "website": "http://www.takara-univ.ac.jp/"
       },
       {
          "university": "Takasaki City University of Economics",
          "website": "http://www.tcue.ac.jp/"
       },
       {
          "university": "Takushoku University",
          "website": "http://www.takushoku-u.ac.jp/"
       },
       {
          "university": "Tama Art University",
          "website": "http://www.tamabi.ac.jp/"
       },
       {
          "university": "Tamagawa University",
          "website": "http://www.tamagawa.ac.jp/"
       },
       {
          "university": "Tama Institute of Management and Information Sciences",
          "website": "http://www.timis.ac.jp/"
       },
       {
          "university": "Teikyo Heisei University",
          "website": "http://www.teikyo-u.ac.jp/"
       },
       {
          "university": "Teikyo University of Science and Technology",
          "website": "http://www.ntu.ac.jp/"
       },
       {
          "university": "Temple University Japan",
          "website": "http://www.tuj.ac.jp/"
       },
       {
          "university": "Tenri University",
          "website": "http://www.tenri-u.ac.jp/"
       },
       {
          "university": "Tezukayama Gakuin University",
          "website": "http://www.tezuka-gu.ac.jp/"
       },
       {
          "university": "Tezukayama University",
          "website": "http://www.tezukayama-u.ac.jp/"
       },
       {
          "university": "Toho College of Music",
          "website": "http://www.toho-music.ac.jp/"
       },
       {
          "university": "Toho Gakuen School of Music",
          "website": "http://www.tohomusic.ac.jp/"
       },
       {
          "university": "Tohoku Bunka Gakuen University",
          "website": "http://www.tbgu.ac.jp/"
       },
       {
          "university": "Tohoku College of Pharmacy",
          "website": "http://www.tohoku-pharm.ac.jp/"
       },
       {
          "university": "Tohoku Fukushi University",
          "website": "http://www.tfu.ac.jp/"
       },
       {
          "university": "Tohoku Gakuin University",
          "website": "http://www.tohoku-gakuin.ac.jp/"
       },
       {
          "university": "Tohoku Institute of Technology",
          "website": "http://www.tohtech.ac.jp"
       },
       {
          "university": "Tohoku University",
          "website": "http://www.tohoku.ac.jp/"
       },
       {
          "university": "Tohoku University of Art and Design",
          "website": "http://www.tuad.ac.jp/"
       },
       {
          "university": "Tohoku Women's College",
          "website": "http://www.tojo.ac.jp/"
       },
       {
          "university": "Toho University",
          "website": "http://www.toho-u.ac.jp/"
       },
       {
          "university": "Tohwa University",
          "website": "http://www.tohwa-u.ac.jp/"
       },
       {
          "university": "Toin University of Yokohama",
          "website": "http://www.toin.ac.jp/"
       },
       {
          "university": "Tokai Gakuen University",
          "website": "http://www.tokaigakuen-u.ac.jp/"
       },
       {
          "university": "Tokai University",
          "website": "http://www.u-tokai.ac.jp/"
       },
       {
          "university": "Tokai University Educational System",
          "website": "http://www.tokai.ac.jp/"
       },
       {
          "university": "Tokai Women's College",
          "website": "http://www.tokaijoshi-u.ac.jp/"
       },
       {
          "university": "Tokiwa University",
          "website": "http://www.tokiwa.ac.jp/"
       },
       {
          "university": "Tokoha Gakuen University",
          "website": "http://www.tokoha-u.ac.jp/"
       },
       {
          "university": "Tokushima Bunri University",
          "website": "http://www.bunri-u.ac.jp/"
       },
       {
          "university": "Tokushima University",
          "website": "http://www.tokushima-u.ac.jp/"
       },
       {
          "university": "Tokuyama University",
          "website": "http://www.tokuyama-u.ac.jp/"
       },
       {
          "university": "Tokyo College of Music",
          "website": "http://www.tokyo-ondai.ac.jp/"
       },
       {
          "university": "Tokyo Denki University",
          "website": "http://www.dendai.ac.jp/"
       },
       {
          "university": "Tokyo Dental College",
          "website": "http://www.tdc.ac.jp/"
       },
       {
          "university": "Tokyo Engineering University",
          "website": "http://www.teu.ac.jp/"
       },
       {
          "university": "Tokyo Gakugei University",
          "website": "http://www.u-gakugei.ac.jp/"
       },
       {
          "university": "Tokyo Institute of Polytechnics",
          "website": "http://www.t-kougei.ac.jp/"
       },
       {
          "university": "Tokyo Institute of Technology",
          "website": "http://www.titech.ac.jp/"
       },
       {
          "university": "Tokyo International University",
          "website": "http://www.tiu.ac.jp/"
       },
       {
          "university": "Tokyo Kasei University",
          "website": "http://www.tokyo-kasei.ac.jp/"
       },
       {
          "university": "Tokyo Keizai University",
          "website": "http://www.tku.ac.jp/"
       },
       {
          "university": "Tokyo Medical and Dental University",
          "website": "http://www.tmd.ac.jp/"
       },
       {
          "university": "Tokyo Medical College",
          "website": "http://www.tokyo-med.ac.jp/"
       },
       {
          "university": "Tokyo Metropolitan Institute of Technology",
          "website": "http://www.tmit.ac.jp/"
       },
       {
          "university": "Tokyo Metropolitan University",
          "website": "http://www.metro-u.ac.jp/"
       },
       {
          "university": "Tokyo Metropolitan University of Health Sciences",
          "website": "http://www.metro-hs.ac.jp/"
       },
       {
          "university": "Tokyo National University of Fine Arts and Music",
          "website": "http://www.geidai.ac.jp/"
       },
       {
          "university": "Tokyo University",
          "website": "http://www.u-tokyo.ac.jp/"
       },
       {
          "university": "Tokyo University of Agriculture",
          "website": "http://www.nodai.ac.jp/"
       },
       {
          "university": "Tokyo University of Agriculture and Technology",
          "website": "http://www.tuat.ac.jp/"
       },
       {
          "university": "Tokyo University of Art and Design",
          "website": "http://www.zokei.ac.jp/"
       },
       {
          "university": "Tokyo University of Fisheries",
          "website": "http://www.tokyo-u-fish.ac.jp/"
       },
       {
          "university": "Tokyo University of Foreign Studies",
          "website": "http://www.tufs.ac.jp/"
       },
       {
          "university": "Tokyo University of Information Sciences",
          "website": "http://www.tuis.ac.jp/"
       },
       {
          "university": "Tokyo University of Mercantile Marine",
          "website": "http://www.tosho-u.ac.jp/"
       },
       {
          "university": "Tokyo University of Pharmacy and Life Science",
          "website": "http://www.toyaku.ac.jp/"
       },
       {
          "university": "Tokyo Woman's Christian University",
          "website": "http://www.twcu.ac.jp/"
       },
       {
          "university": "Tokyo Women's College of Physical Education",
          "website": "http://www.twcpe.ac.jp/"
       },
       {
          "university": "Tokyo Women's Medial College",
          "website": "http://www.twmu.ac.jp/"
       },
       {
          "university": "Tomakomai Komazawa University",
          "website": "http://www.t-komazawa.ac.jp/"
       },
       {
          "university": "Tottori University",
          "website": "http://www.tottori-u.ac.jp/"
       },
       {
          "university": "Tottori University of Environmental Studies",
          "website": "http://www.kankyo-u.ac.jp/"
       },
       {
          "university": "Toyama Medical and Pharmaceutical University",
          "website": "http://www.toyama-mpu.ac.jp/"
       },
       {
          "university": "Toyama Prefectural University",
          "website": "http://www.pu-toyama.ac.jp/"
       },
       {
          "university": "Toyama University",
          "website": "http://www.toyama-u.ac.jp/"
       },
       {
          "university": "Toyama University of International Studies",
          "website": "http://www.tuins.ac.jp/"
       },
       {
          "university": "Toyo Eiwa Women's University",
          "website": "http://www.toyoeiwa.ac.jp/"
       },
       {
          "university": "Toyo Gakuen University",
          "website": "http://www.toyogakuen-u.ac.jp/"
       },
       {
          "university": "Toyohashi University of Technology",
          "website": "http://www.tut.ac.jp/"
       },
       {
          "university": "Toyota Technological Institute",
          "website": "http://www.toyota-ti.ac.jp/"
       },
       {
          "university": "Toyo University",
          "website": "http://www.toyo.ac.jp/"
       },
       {
          "university": "Tsuda College",
          "website": "http://www.tsuda.ac.jp/"
       },
       {
          "university": "Tsukuba University",
          "website": "http://www.tsukuba.ac.jp/"
       },
       {
          "university": "Tsurumi University",
          "website": "http://www.tsurumi-u.ac.jp/"
       },
       {
          "university": "Tsuru University",
          "website": "http://www.tsuru.ac.jp/"
       },
       {
          "university": "Ueno Gakuen College",
          "website": "http://www.uenogakuen.ac.jp/"
       },
       {
          "university": "United Nations University",
          "website": "http://www.unu.edu/"
       },
       {
          "university": "University of Aizu",
          "website": "http://www.u-aizu.ac.jp/"
       },
       {
          "university": "University of Bunkyo",
          "website": "http://www.u-bunkyo.ac.jp/"
       },
       {
          "university": "University of East Asia",
          "website": "http://www.toua-u.ac.jp/"
       },
       {
          "university": "University of Electro-Communications",
          "website": "http://www.uec.ac.jp/"
       },
       {
          "university": "University of Marketing and Distribution Sciences",
          "website": "http://www.umds.ac.jp/"
       },
       {
          "university": "University of Occupational and Environmental Health - Japan",
          "website": "http://www.uoeh-u.ac.jp/"
       },
       {
          "university": "University of Shiga Prefecture",
          "website": "http://www.usp.ac.jp/"
       },
       {
          "university": "University of the Air",
          "website": "http://www.u-air.ac.jp/"
       },
       {
          "university": "University of the Ryukyus",
          "website": "http://www.u-ryukyu.ac.jp/"
       },
       {
          "university": "University of the Sacred Heart Tokyo",
          "website": "http://www.u-sacred-heart.ac.jp/"
       },
       {
          "university": "Utsunomiya University",
          "website": "http://www.utsunomiya-u.ac.jp/"
       },
       {
          "university": "Wakayama Medical College",
          "website": "http://www.wakayama-med.ac.jp/"
       },
       {
          "university": "Wakayama University",
          "website": "http://www.wakayama-u.ac.jp/"
       },
       {
          "university": "Wakkanai Hokusei Gakuen University",
          "website": "http://www.wakhok.ac.jp/"
       },
       {
          "university": "Wako University",
          "website": "http://www.wako.ac.jp/"
       },
       {
          "university": "Waseda University",
          "website": "http://www.waseda.ac.jp/"
       },
       {
          "university": "Wayo Women's University",
          "website": "http://www.wayo.ac.jp/"
       },
       {
          "university": "Women's College of Fine Arts",
          "website": "http://www.joshibi.ac.jp/"
       },
       {
          "university": "Yamagata University",
          "website": "http://www.yamagata-u.ac.jp/"
       },
       {
          "university": "Yamaguchi Prefectural University",
          "website": "http://www.yamaguchi-pu.ac.jp/"
       },
       {
          "university": "Yamaguchi University",
          "website": "http://www.yamaguchi-u.ac.jp/"
       },
       {
          "university": "Yamanashi Gakuin University",
          "website": "http://www.ygu.ac.jp/"
       },
       {
          "university": "Yamanashi Medical University",
          "website": "http://www.yamanashi-med.ac.jp/"
       },
       {
          "university": "Yamanashi University",
          "website": "http://www.yamanashi.ac.jp/"
       },
       {
          "university": "Yasuda Women's University",
          "website": "http://www.yasuda-u.ac.jp/"
       },
       {
          "university": "Yokkaichi University",
          "website": "http://www.yokkaichi-u.ac.jp/"
       },
       {
          "university": "Yokohama City University",
          "website": "http://www.yokohama-cu.ac.jp/"
       },
       {
          "university": "Yokohama College of Commerce",
          "website": "http://www.shodai.ac.jp/"
       },
       {
          "university": "Yokohama National University",
          "website": "http://www.ynu.ac.jp/"
       }
    ],
    "KE": [
       {
          "university": "Adventist University of Africa",
          "website": "http://www.aua.ac.ke/"
       },
       {
          "university": "Africa International University",
          "website": "http://www.aiu.ac.ke/"
       },
       {
          "university": "Africa Nazarene University",
          "website": "http://www.anu.ac.ke/"
       },
       {
          "university": "Aga Khan University",
          "website": "http://www.aku.edu/"
       },
       {
          "university": "Catholic University of Eastern Africa",
          "website": "http://www.cuea.edu/"
       },
       {
          "university": "Chuka University",
          "website": "http://www.chuka.ac.ke/"
       },
       {
          "university": "Daystar University",
          "website": "http://www.daystar.ac.ke/"
       },
       {
          "university": "Dedan Kimathi University of Technology",
          "website": "http://www.dkut.ac.ke/"
       },
       {
          "university": "Egerton University",
          "website": "http://www.egerton.ac.ke/"
       },
       {
          "university": "Great Lakes University of Kisumu",
          "website": "http://www.gluk.ac.ke/"
       },
       {
          "university": "Gretsa Universtiy",
          "website": "http://www.gretsauniversity.ac.ke/"
       },
       {
          "university": "Inoorero University",
          "website": "http://www.iu.ac.ke/"
       },
       {
          "university": "Jaramogi Oginga Odinga University of Science and Technology",
          "website": "http://www.jooust.ac.ke/"
       },
       {
          "university": "Jomo Kenyatta University of Agriculture and Technology",
          "website": "http://www.jkuat.ac.ke/"
       },
       {
          "university": "Kabarak University",
          "website": "http://www.kabarak.ac.ke/"
       },
       {
          "university": "Karatina University",
          "website": "http://www.karatinauniversity.ac.ke/"
       },
       {
          "university": "Kenya College of Accountancy",
          "website": "http://www.kca.ac.ke/"
       },
       {
          "university": "Kenya Medical Training College",
          "website": "http://www.kmtc.ac.ke/"
       },
       {
          "university": "Kenya Methodist University",
          "website": "http://www.kemu.ac.ke/"
       },
       {
          "university": "Kenyatta University",
          "website": "http://www.ku.ac.ke/"
       },
       {
          "university": "Kiriri Womens University of Science and Technology",
          "website": "http://www.kwust.ac.ke/"
       },
       {
          "university": "Kisii University",
          "website": "http://www.kisiiuniversity.ac.ke/"
       },
       {
          "university": "Laikipia University",
          "website": "http://www.laikipia.ac.ke/"
       },
       {
          "university": "Maasai Mara University",
          "website": "http://www.mmarau.ac.ke/"
       },
       {
          "university": "Maseno University",
          "website": "http://www.maseno.ac.ke/"
       },
       {
          "university": "Masinde Muliro University of Science and Technology",
          "website": "http://www.mmust.ac.ke/"
       },
       {
          "university": "Meru University of Science and Technology",
          "website": "http://www.must.ac.ke/"
       },
       {
          "university": "Moi University",
          "website": "http://www.mu.ac.ke/"
       },
       {
          "university": "Mount Kenya University",
          "website": "http://www.mku.ac.ke/"
       },
       {
          "university": "Multimedia University of Kenya",
          "website": "http://www.mmu.ac.ke/"
       },
       {
          "university": "Nairobi Institute of Business Studies ",
          "website": "http://www.nibs.ac.ke/"
       },
       {
          "university": "Nairobi Technical Training Institute ",
          "website": "http://www.ntti.ac.ke/"
       },
       {
          "university": "Pan Africa Christian University",
          "website": "http://www.pacuniversity.ac.ke/"
       },
       {
          "university": "Pioneer International University",
          "website": "http://www.piu.ac.ke/"
       },
       {
          "university": "Presbyterian University of East Africa",
          "website": "http://www.puea.ac.ke/"
       },
       {
          "university": "Pwani University",
          "website": "http://www.pu.ac.ke/"
       },
       {
          "university": "Riara University School of Business and Law",
          "website": "http://www.riarauniversity.ac.ke/"
       },
       {
          "university": "Scott Christian University",
          "website": "http://www.scott.ac.ke/"
       },
       {
          "university": "South Eastern Kenya University",
          "website": "http://www.seku.ac.ke/"
       },
       {
          "university": "St. Pauls University",
          "website": "http://www.spu.ac.ke/"
       },
       {
          "university": "Strathmore University",
          "website": "http://www.strathmore.edu/"
       },
       {
          "university": "Technical University of Kenya",
          "website": "http://www.tukenya.ac.ke/"
       },
       {
          "university": "Technical University of Mombasa",
          "website": "http://www.tum.ac.ke/"
       },
       {
          "university": "United States International University",
          "website": "http://www.usiu.ac.ke/"
       },
       {
          "university": "University of Eastern Africa - Baraton",
          "website": "http://www.ueab.ac.ke/"
       },
       {
          "university": "University of Eldoret",
          "website": "http://www.uoeld.ac.ke/"
       },
       {
          "university": "University of Kabianga",
          "website": "http://www.kabianga.ac.ke/"
       },
       {
          "university": "University of Nairobi",
          "website": "http://www.uonbi.ac.ke/"
       },
       {
          "university": "Uzima University College ",
          "website": "http://www.uzimauniversity.ac.ke/"
       },
       {
          "university": "Zetech College",
          "website": "http://www.zetechcollege.com/"
       }
    ],
    "KG": [
       {
          "university": "American University of Central Asia",
          "website": "http://www.auca.kg/"
       },
       {
          "university": "Asian Medical Institute",
          "website": "http://www.asmi.edu.kg/"
       },
       {
          "university": "Bishkek Humanities University",
          "website": "http://www.bhu.kg/"
       },
       {
          "university": "International Ataturk Alatoo University",
          "website": "http://www.iaau.edu.kg/"
       },
       {
          "university": "International University of Kyrgyzstan",
          "website": "http://www.iuk.kg/"
       },
       {
          "university": "Jalalabad State University Kyrgyzstan",
          "website": "http://www.jasu.edu.kg/"
       },
       {
          "university": "Kyrgyz National Agrarian University",
          "website": "http://www.knau.kg/"
       },
       {
          "university": "Kyrgyz National University",
          "website": "http://www.university.kg/"
       },
       {
          "university": "Kyrgyz Russian Slavic University",
          "website": "http://www.krsu.edu.kg/"
       },
       {
          "university": "Kyrgyz State Technical University",
          "website": "http://www.kstu.kg/"
       },
       {
          "university": "Kyrgyz State University of Construction - Transportation and Architecture",
          "website": "http://www.ksucta.kg/"
       },
       {
          "university": "Kyrgyz Turkish  Manas University",
          "website": "http://www.manas.kg/"
       },
       {
          "university": "Medical Faculty - Osh State University",
          "website": "http://www.oshmed.com/"
       }
    ],
    "KH": [
       {
          "university": "Angkor University",
          "website": "http://www.angkor.edu.kh/"
       },
       {
          "university": "Asia Europe University",
          "website": "http://www.aeu.edu.kh/"
       },
       {
          "university": "Build Bright University",
          "website": "http://www.bbu.edu.kh/"
       },
       {
          "university": "Cambodian Mekong University",
          "website": "http://www.mekong.edu.kh/"
       },
       {
          "university": "Cambodia University of Specialties",
          "website": "http://www.cus.edu.kh/"
       },
       {
          "university": "Chamreun University of Poly Technology",
          "website": "http://www.cup.edu.kh/"
       },
       {
          "university": "International University",
          "website": "http://www.iu.edu.kh/"
       },
       {
          "university": "National Institute of Education",
          "website": "http://www.ine.edu.kh/"
       },
       {
          "university": "National University of Management",
          "website": "http://www.num.edu.kh/"
       },
       {
          "university": "Norton University",
          "website": "http://www.norton.edu.kh/"
       },
       {
          "university": "Pannnasatra University of Cambodia",
          "website": "http://www.puc.edu.kh/"
       },
       {
          "university": "Royal University of Agriculture",
          "website": "http://www.rua.edu.kh/"
       },
       {
          "university": "Royal University of Fine Arts",
          "website": "http://www.rufa.edu.kh/"
       },
       {
          "university": "Royal University of Law and Economics",
          "website": "http://www.rule.edu.kh/"
       },
       {
          "university": "Royal University of Phnom Penh",
          "website": "http://www.rupp.edu.kh/"
       },
       {
          "university": "Svey Reong University",
          "website": "http://www.sru.edu.kh/"
       },
       {
          "university": "University of Cambodia",
          "website": "http://www.uc.edu.kh/"
       },
       {
          "university": "University of Health and Science",
          "website": "http://www.univ-sante.edu.kh/"
       },
       {
          "university": "University of Technology Phnom Penh",
          "website": "http://www.utpp.edu.kh/"
       },
       {
          "university": "Western University",
          "website": "http://www.western.edu.kh/"
       },
       {
          "university": "Zaman University",
          "website": "http://www.zamanuniversity.edu.kh/"
       }
    ],
    "KN": [
       {
          "university": "International University for Graduate Studies",
          "website": "http://www.iugrad.edu.kn/"
       },
       {
          "university": "Ross University Caribbean School of Veterinary Medicine",
          "website": "http://www.rossu.edu/veterinary-school/"
       },
       {
          "university": "St Theresa's Medical University",
          "website": "http://www.stmu.org/"
       },
       {
          "university": "University of Silvaner",
          "website": "http://www.unisilvaner.info/"
       },
       {
          "university": "Windsor University School of Medicine",
          "website": "http://www.windsor.edu/"
       }
    ],
    "KP": [
       {
          "university": "Pyongyang University of Science and Technology",
          "website": "http://www.pust.net/"
       }
    ],
    "KR": [
       {
          "university": "Ajou University",
          "website": "http://www.ajou.ac.kr/"
       },
       {
          "university": "Andong National University",
          "website": "http://www.andong.ac.kr/"
       },
       {
          "university": "Catholic University of Korea",
          "website": "http://www.cuk.ac.kr/"
       },
       {
          "university": "Catholic University of Pusan",
          "website": "http://www.cup.ac.kr/"
       },
       {
          "university": "Catholic University of Taegu-Hyosung",
          "website": "http://www.cataegu.ac.kr/"
       },
       {
          "university": "Changwon National University",
          "website": "http://www.changwon.ac.kr/"
       },
       {
          "university": "Cheju National University",
          "website": "http://www.cheju.ac.kr/"
       },
       {
          "university": "Cheju National University of Education",
          "website": "http://www.cheju-e.ac.kr/"
       },
       {
          "university": "Chinju National University",
          "website": "http://www.chinju.ac.kr/"
       },
       {
          "university": "Chinju National University of Education",
          "website": "http://www.chinju-e.ac.kr/"
       },
       {
          "university": "Chodang University",
          "website": "http://www.chodang.ac.kr/"
       },
       {
          "university": "Chonbuk National University",
          "website": "http://www.chonbuk.ac.kr/"
       },
       {
          "university": "Chonbuk Sanup University of Technology (Howon University)",
          "website": "http://www.howon.ac.kr/"
       },
       {
          "university": "Chongju National University of Education",
          "website": "http://www.chongju-e.ac.kr/"
       },
       {
          "university": "Chongju University",
          "website": "http://www.chongju.ac.kr/"
       },
       {
          "university": "Chonju National University of Education",
          "website": "http://www.chonju-e.ac.kr/"
       },
       {
          "university": "Chonnam National University",
          "website": "http://www.chonnam.ac.kr/"
       },
       {
          "university": "Chosun University",
          "website": "http://www.chosun.ac.kr/"
       },
       {
          "university": "Chunchon National University of Education",
          "website": "http://www.cnue-e.ac.kr/"
       },
       {
          "university": "Chung-Ang University",
          "website": "http://www.cau.ac.kr/"
       },
       {
          "university": "Chungbuk National University",
          "website": "http://www.chungbuk.ac.kr/"
       },
       {
          "university": "Chung-Ju National University",
          "website": "http://www.chungju.ac.kr/"
       },
       {
          "university": "Chungnam National University",
          "website": "http://www.chungnam.ac.kr/"
       },
       {
          "university": "Daebul University",
          "website": "http://www.daebul.ac.kr/"
       },
       {
          "university": "Dankook University",
          "website": "http://www.dankook.ac.kr/"
       },
       {
          "university": "Dong-A University",
          "website": "http://www.donga.ac.kr/"
       },
       {
          "university": "Dongduk Women's University",
          "website": "http://www.dongduk.ac.kr/"
       },
       {
          "university": "Dong Eui University",
          "website": "http://www.dongeui.ac.kr/"
       },
       {
          "university": "Dongguk University",
          "website": "http://www.dongguk.ac.kr/"
       },
       {
          "university": "Dongseo University",
          "website": "http://www.dongseo.ac.kr/"
       },
       {
          "university": "Dongshin University",
          "website": "http://www.dongshinu.ac.kr/"
       },
       {
          "university": "Dong Yang University of Technology",
          "website": "http://www.dytc.ac.kr/"
       },
       {
          "university": "Duksung Women's University",
          "website": "http://www.duksung.ac.kr/"
       },
       {
          "university": "Ewha Women's University",
          "website": "http://www.ewha.ac.kr/"
       },
       {
          "university": "Gwangju Catholic College",
          "website": "http://www.gjcatholic.ac.kr/"
       },
       {
          "university": "Gyeongju University",
          "website": "http://www.gyeongju.ac.kr/"
       },
       {
          "university": "Gyeongsang National University",
          "website": "http://www.gsnu.ac.kr/"
       },
       {
          "university": "Halla University",
          "website": "http://www.halla.ac.kr/"
       },
       {
          "university": "Hallym University",
          "website": "http://www.hallym.ac.kr/"
       },
       {
          "university": "Hankuk Aviation University",
          "website": "http://www.hangkong.ac.kr/"
       },
       {
          "university": "Hankuk University of Foreign Studies",
          "website": "http://www.hufs.ac.kr/"
       },
       {
          "university": "Hankyong National University",
          "website": "http://www.hknu.ac.kr/"
       },
       {
          "university": "Hannam University",
          "website": "http://www.hannam.ac.kr/"
       },
       {
          "university": "Hansei University",
          "website": "http://www.hansei.ac.kr/"
       },
       {
          "university": "Hanseo University",
          "website": "http://www.hanseo.ac.kr/"
       },
       {
          "university": "Hanshin University",
          "website": "http://www.hanshin.ac.kr/"
       },
       {
          "university": "Hansung University Seoul",
          "website": "http://www.hansung.ac.kr/"
       },
       {
          "university": "Hanyang University",
          "website": "http://www.hanyang.ac.kr/"
       },
       {
          "university": "Honam University",
          "website": "http://www.honam.ac.kr/"
       },
       {
          "university": "Hongik University",
          "website": "http://www.hongik.ac.kr/"
       },
       {
          "university": "Hoseo University",
          "website": "http://www.hoseo.ac.kr/"
       },
       {
          "university": "Hyupsung University",
          "website": "http://www.hyupsung.ac.kr/"
       },
       {
          "university": "Inchon National University of Education",
          "website": "http://www.inchon-e.ac.kr/"
       },
       {
          "university": "Inchon University",
          "website": "http://www.inchon.ac.kr/"
       },
       {
          "university": "Information and Communications University",
          "website": "http://www.icu.ac.kr/"
       },
       {
          "university": "Inha University",
          "website": "http://www.inha.ac.kr/"
       },
       {
          "university": "Inje University",
          "website": "http://www.inje.ac.kr/"
       },
       {
          "university": "Jeonju University",
          "website": "http://www.jeonju.ac.kr/"
       },
       {
          "university": "Kangnam University",
          "website": "http://www.kangnam.ac.kr/"
       },
       {
          "university": "Kangnung National University",
          "website": "http://www.kangnung.ac.kr/"
       },
       {
          "university": "Kangwon National University",
          "website": "http://www.kangwon.ac.kr/"
       },
       {
          "university": "Kaya University",
          "website": "http://www.kaya.ac.kr/"
       },
       {
          "university": "KDI School of Public Policy and Management",
          "website": "http://www.kdischool.ac.kr/"
       },
       {
          "university": "Keimyung University",
          "website": "http://www.keimyung.ac.kr/"
       },
       {
          "university": "Kongju National University",
          "website": "http://www.kyongju.ac.kr/"
       },
       {
          "university": "Kongju National University of Education",
          "website": "http://www.kongju-e.ac.kr/"
       },
       {
          "university": "Konkuk University",
          "website": "http://www.konkuk.ac.kr/"
       },
       {
          "university": "Konyang University",
          "website": "http://www.konyang.ac.kr/"
       },
       {
          "university": "Kookmin University",
          "website": "http://www.kookmin.ac.kr/"
       },
       {
          "university": "Korea Advanced Institute of Science & Technology",
          "website": "http://www.kaist.ac.kr/"
       },
       {
          "university": "Korea Maritime University",
          "website": "http://www.kmaritime.ac.kr/"
       },
       {
          "university": "Korea National Open University",
          "website": "http://www.knou.ac.kr/"
       },
       {
          "university": "Korea National University of Arts",
          "website": "http://www.knua.ac.kr/"
       },
       {
          "university": "Korea National University of Education",
          "website": "http://www.knue.ac.kr/"
       },
       {
          "university": "Korea National University of Physical Education",
          "website": "http://www.knupe.ac.kr/"
       },
       {
          "university": "Korea University",
          "website": "http://www.korea.ac.kr/"
       },
       {
          "university": "Kosin University",
          "website": "http://www.kosin.ac.kr/"
       },
       {
          "university": "Kumoh National University of Technology",
          "website": "http://www.kumoh.ac.kr/"
       },
       {
          "university": "Kunsan National University",
          "website": "http://www.kunsan.ac.kr/"
       },
       {
          "university": "Kwan Dong University",
          "website": "http://www.kwandong.ac.kr/"
       },
       {
          "university": "Kwangju National University of Education",
          "website": "http://www.kwangju-e.ac.kr/"
       },
       {
          "university": "Kwangju University",
          "website": "http://www.kwangju.ac.kr/"
       },
       {
          "university": "Kwangwoon University",
          "website": "http://www.kwangwoon.ac.kr/"
       },
       {
          "university": "Kyonggi University",
          "website": "http://www.kyonggi.ac.kr/"
       },
       {
          "university": "Kyongju University",
          "website": "http://www.kyongju.ac.kr/"
       },
       {
          "university": "Kyunghee University",
          "website": "http://www.kyunghee.ac.kr/"
       },
       {
          "university": "Kyungil University",
          "website": "http://www.kyungil.ac.kr/"
       },
       {
          "university": "Kyungnam University",
          "website": "http://www.kyungnam.ac.kr/"
       },
       {
          "university": "Kyungpook National University",
          "website": "http://www.kyungpook.ac.kr/"
       },
       {
          "university": "Kyungsung University",
          "website": "http://www.kyungsung.ac.kr/"
       },
       {
          "university": "Kyungwon University",
          "website": "http://www.kyungwon.ac.kr/"
       },
       {
          "university": "Miryang National University",
          "website": "http://www.miryang.ac.kr/"
       },
       {
          "university": "Mokpo National Maritime University",
          "website": "http://www.mmu.ac.kr/"
       },
       {
          "university": "Mokpo National University",
          "website": "http://www.mokpo.ac.kr/"
       },
       {
          "university": "Mokwon University Taejon",
          "website": "http://www.mokwon.ac.kr/"
       },
       {
          "university": "Myongji University",
          "website": "http://www.myongji.ac.kr/"
       },
       {
          "university": "Paichai University",
          "website": "http://www.paichai.ac.kr/"
       },
       {
          "university": "Pohang University of Science and Technology",
          "website": "http://www.postech.ac.kr/"
       },
       {
          "university": "Pukyong National University",
          "website": "http://www.pknu.ac.kr/"
       },
       {
          "university": "Pusan National University",
          "website": "http://www.pusan.ac.kr/"
       },
       {
          "university": "Pusan National University of Education",
          "website": "http://www.pusan-e.ac.kr/"
       },
       {
          "university": "Pusan University of Foreign Studies",
          "website": "http://www.pufs.ac.kr/"
       },
       {
          "university": "Pusan Women's University",
          "website": "http://www.pwc.ac.kr/"
       },
       {
          "university": "Pyongtaek University ",
          "website": "http://www.ptuniv.ac.kr/"
       },
       {
          "university": "Sahmyook University",
          "website": "http://www.syu.ac.kr/"
       },
       {
          "university": "Samchok National University",
          "website": "http://www.samchok.ac.kr/"
       },
       {
          "university": "Sang Ji University",
          "website": "http://www.sangji.ac.kr/"
       },
       {
          "university": "Sang Myung University",
          "website": "http://www.sangmyung.ac.kr/"
       },
       {
          "university": "Sejong University",
          "website": "http://www.sejong.ac.kr/"
       },
       {
          "university": "Semyung University",
          "website": "http://www.semyung.ac.kr/"
       },
       {
          "university": "Seokyeong University",
          "website": "http://www.skuniv.ac.kr/"
       },
       {
          "university": "Seokyeong University",
          "website": "http://www.seokyeong.ac.kr/"
       },
       {
          "university": "Seonam University",
          "website": "http://www.seonam.ac.kr/"
       },
       {
          "university": "Seoul City University",
          "website": "http://www.uos.ac.kr/"
       },
       {
          "university": "Seoul National University",
          "website": "http://www.snu.ac.kr/"
       },
       {
          "university": "Seoul National University of Education",
          "website": "http://www.seoul-e.ac.kr/"
       },
       {
          "university": "Seoul National University of Technology",
          "website": "http://www.snut.ac.kr/"
       },
       {
          "university": "Seoul Teological University",
          "website": "http://www.stu.ac.kr/"
       },
       {
          "university": "Seoul Women's University",
          "website": "http://www.swu.ac.kr/"
       },
       {
          "university": "Seowon University",
          "website": "http://www.seowon.ac.kr/"
       },
       {
          "university": "Silla University",
          "website": "http://www.silla.ac.kr/"
       },
       {
          "university": "Sogang University",
          "website": "http://www.sogang.ac.kr/"
       },
       {
          "university": "Sookmyung Women's University",
          "website": "http://www.sookmyung.ac.kr/"
       },
       {
          "university": "Soonchunhyang University",
          "website": "http://www.sch.ac.kr/"
       },
       {
          "university": "Soongsil University",
          "website": "http://www.soongsil.ac.kr/"
       },
       {
          "university": "Sunchon National University",
          "website": "http://www.sunchon.ac.kr/"
       },
       {
          "university": "Sungkyul University",
          "website": "http://www.sungkyul.ac.kr/"
       },
       {
          "university": "Sung Kyun Kwan University",
          "website": "http://www.skku.ac.kr/"
       },
       {
          "university": "Sungshin Women's University",
          "website": "http://www.sungshin.ac.kr/"
       },
       {
          "university": "Sunmoon University",
          "website": "http://www.sunmoon.ac.kr/"
       },
       {
          "university": "Suwon Catholic University",
          "website": "http://www.suwoncatholic.ac.kr/"
       },
       {
          "university": "Suwon University",
          "website": "http://www.suwon.ac.kr/"
       },
       {
          "university": "Taegu National University of Education",
          "website": "http://www.taegu-e.ac.kr/"
       },
       {
          "university": "Taegu University",
          "website": "http://www.taegu.ac.kr/"
       },
       {
          "university": "Taejon National University of Technology",
          "website": "http://www.tnut.ac.kr/"
       },
       {
          "university": "Taejon University",
          "website": "http://www.taejon.ac.kr/"
       },
       {
          "university": "Taeshin Christian University",
          "website": "http://www.taeshin.ac.kr/"
       },
       {
          "university": "Tongmyung University of Information Technology",
          "website": "http://www.tit.ac.kr/"
       },
       {
          "university": "Ulsan University",
          "website": "http://www.ulsan.ac.kr/"
       },
       {
          "university": "University of Science and Technology",
          "website": "http://www.ust.ac.kr/"
       },
       {
          "university": "Wonkwang University",
          "website": "http://www.wonkwang.ac.kr/"
       },
       {
          "university": "Woosuk University",
          "website": "http://www.woosuk.ac.kr/"
       },
       {
          "university": "Yeungnam University",
          "website": "http://www.yeungnam.ac.kr/"
       },
       {
          "university": "Yonsei University",
          "website": "http://www.yonsei.ac.kr/"
       },
       {
          "university": "Yosu National University",
          "website": "http://www.yosu.ac.kr/"
       }
    ],
    "KW": [
       {
          "university": "American University of Kuwait ",
          "website": "http://www.auk.edu.kw/"
       },
       {
          "university": "American University of Middle East",
          "website": "http://www.aum.edu.kw/"
       },
       {
          "university": "Arab Open University - Kuwait Branch",
          "website": "http://www.aou.edu.kw/"
       },
       {
          "university": "Australlian College of Kuwait ",
          "website": "http://www.ackonline.com/"
       },
       {
          "university": "Gulf University for Science and Technology",
          "website": "http://www.gust.edu.kw/"
       },
       {
          "university": "Kuwait International Law School",
          "website": "http://www.kilaw.edu.kw/"
       },
       {
          "university": "Kuwait Maastricht Business School",
          "website": "http://www.kmbs.edu.kw/"
       },
       {
          "university": "Kuwait University",
          "website": "http://www.kuniv.edu.kw/"
       }
    ],
    "KY": [
       {
          "university": "International College of the Cayman Islands",
          "website": "http://www.icci.edu.ky/"
       },
       {
          "university": "St. Matthews University",
          "website": "http://www.stmatthews.edu/"
       }
    ],
    "KZ": [
       {
          "university": "Abai State University",
          "website": "http://www.kaznpu.kz/"
       },
       {
          "university": "Abylai Khan University",
          "website": "http://www.ablaikhan.kz/"
       },
       {
          "university": "Aktau State University",
          "website": "http://www.aktsu.kz/"
       },
       {
          "university": "Al-Farabi Kazakh National University",
          "website": "http://www.kaznu.kz/"
       },
       {
          "university": "Atyrau State University",
          "website": "http://www.atgu.kz/"
       },
       {
          "university": "East Kazakhstan State University",
          "website": "http://www.vkgu.kz/"
       },
       {
          "university": "East Kazakstan State Technical University",
          "website": "http://www.ektu.kz/"
       },
       {
          "university": "Eurasian Institute of market",
          "website": "http://www.eurazir.kz/"
       },
       {
          "university": "International Kazakh-Turkish University",
          "website": "http://www.turkistan.kz/"
       },
       {
          "university": "Karaganda State Buketov University",
          "website": "http://www.ksu.kz/"
       },
       {
          "university": "Karaganda State Technical University",
          "website": "http://www.kstu.kz/"
       },
       {
          "university": "Kazak American University",
          "website": "http://www.kau.kz/"
       },
       {
          "university": "Kazakh British Technical University",
          "website": "http://www.kbtu.kz/"
       },
       {
          "university": "Kazakhstan Institute of Management - Economics - and Strategic Research",
          "website": "http://www.kimep.kz/"
       },
       {
          "university": "Kazak National Technical University",
          "website": "http://www.kazntu.kz/"
       },
       {
          "university": "Kazak State University of Agriculture",
          "website": "http://www.agriun.almaty.kz/"
       },
       {
          "university": "Kostanai State University",
          "website": "http://www.ksu.kst.kz/"
       },
       {
          "university": "L.N. Gumilyov Eurasian National University",
          "website": "http://www.enu.kz/"
       },
       {
          "university": "North Kazakhstan State University",
          "website": "http://www.nkzu.kz/"
       },
       {
          "university": "Pavlodar University",
          "website": "http://www.psu.kz/"
       },
       {
          "university": "Semey State Medical University",
          "website": "http://www.sgma.kz/"
       },
       {
          "university": "Semey State University",
          "website": "http://www.semgu.kz/"
       },
       {
          "university": "Suleyman Demirel University",
          "website": "http://www.sdu.edu.kz/"
       },
       {
          "university": "Turar Ryskulov Kazakh Economic University",
          "website": "http://www.kazeu.kz/"
       },
       {
          "university": "University of International Business",
          "website": "http://www.uib.kz/"
       },
       {
          "university": "Western Kazakhstan Agricultural University",
          "website": "http://www.wkau.kz/"
       },
       {
          "university": "Zhetysu State University ",
          "website": "http://www.zhgu.kz/"
       },
       {
          "university": "Zhezkazgan Baikonurov University",
          "website": "http://www.zhezu.kz/"
       }
    ],
    "LA": [
       {
          "university": "National University of Laos",
          "website": "http://www.nuol.edu.la/"
       },
       {
          "university": "Soutsaka Institute of management and Technology",
          "website": "http://www.simt.edu.la/"
       }
    ],
    "LB": [
       {
          "university": "Al Imam Al-Ouzai University",
          "website": "http://www.wakf.org/"
       },
       {
          "university": "Al-Islah University",
          "website": "http://www.islahonline.org/"
       },
       {
          "university": "Al-Manar University",
          "website": "http://www.mut.edu.lb/"
       },
       {
          "university": "American University College of Technology",
          "website": "http://www.aut.edu/"
       },
       {
          "university": "American University of Beirut",
          "website": "http://www.aub.edu.lb/"
       },
       {
          "university": "American University of Science and Technology",
          "website": "http://www.aust.edu.lb/"
       },
       {
          "university": "Arab Open University",
          "website": "http://www.arabou-lb.edu.lb/"
       },
       {
          "university": "Arts - Sciences and Technology University",
          "website": "http://www.aul.edu.lb/"
       },
       {
          "university": "Balamand University",
          "website": "http://www.balamand.edu.lb/"
       },
       {
          "university": "Beirut Arab University",
          "website": "http://www.bau.edu.lb/"
       },
       {
          "university": "Business & Computer University College",
          "website": "http://www.hu.edu.lb/"
       },
       {
          "university": "Centre Universitaire de Technologie",
          "website": "http://www.cut.edu.lb/"
       },
       {
          "university": "Global University",
          "website": "http://www.gu.edu.lb/"
       },
       {
          "university": "Haigazian University",
          "website": "http://www.haigazian.edu.lb/"
       },
       {
          "university": "Hariri Canadian University",
          "website": "http://www.hariricanadian.edu.lb/"
       },
       {
          "university": "Holy Spirit University of Kaslik",
          "website": "http://www.usek.edu.lb/"
       },
       {
          "university": "Jinan University",
          "website": "http://www.jinan.edu.lb/"
       },
       {
          "university": "Lebanese American University",
          "website": "http://www.lau.edu.lb/"
       },
       {
          "university": "Lebanese International University",
          "website": "http://www.liu.edu.lb/"
       },
       {
          "university": "Lebanese University",
          "website": "http://www.ul.edu.lb/"
       },
       {
          "university": "Matn University",
          "website": "http://www.matnu.edu.lb/"
       },
       {
          "university": "Near East School of Theology",
          "website": "http://www.theonest.edu.lb/"
       },
       {
          "university": "Notre Dame University",
          "website": "http://www.ndu.edu.lb/"
       },
       {
          "university": "St. Joseph University Beirut",
          "website": "http://www.usj.edu.lb/"
       }
    ],
    "LC": [
       {
          "university": "Spartan University of Health Sciences",
          "website": "http://www.spartanmed.org/"
       }
    ],
    "LI": [
       {
          "university": "Internationle Akademie für Philosophie",
          "website": "http://www.iap.li/"
       },
       {
          "university": "Universität Liechtenstein",
          "website": "http://www.uni.li/"
       }
    ],
    "LK": [
       {
          "university": "Arthur C Clarke Institute of Modern Technologies",
          "website": "http://www.accimt.ac.lk/"
       },
       {
          "university": "Buddhasravaka Bhikshu University",
          "website": "http://www.bbu.ac.lk/"
       },
       {
          "university": "Buddhist and Pali University of Sri Lanka",
          "website": "http://www.bpu.ac.lk/"
       },
       {
          "university": "Eastern University of Sri Lanka",
          "website": "http://www.esn.ac.lk/"
       },
       {
          "university": "Horizon College of Business and Technology",
          "website": "http://www.horizoncampus.edu.lk/"
       },
       {
          "university": "Kotelawala Defence University",
          "website": "http://www.kdu.ac.lk/"
       },
       {
          "university": "Open University of Sri Lanka",
          "website": "http://www.ou.ac.lk/"
       },
       {
          "university": "Postgraduate Institute of Agriculture (PGIA)",
          "website": "http://www.pgia.ac.lk/"
       },
       {
          "university": "Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)",
          "website": "http://www.kln.ac.lk/institutes/pgipbs/"
       },
       {
          "university": "Rajarata University of Sri Lanka",
          "website": "http://www.rjt.ac.lk/"
       },
       {
          "university": "Sabaragamuwa University of Sri Lanka",
          "website": "http://www.sab.ac.lk/"
       },
       {
          "university": "South Asian Institute of Technology And Medicine (SAITM) ",
          "website": "http://www.saitm.edu.lk/"
       },
       {
          "university": "South Eastern University of Sri Lanka",
          "website": "http://www.seu.ac.lk/"
       },
       {
          "university": "Sri Lanka Institute of Advanced Technical Education",
          "website": "http://www.sliate.net/"
       },
       {
          "university": "Sri Lanka Institute of Information Technology (SLIT)",
          "website": "http://www.sliit.lk/"
       },
       {
          "university": "University of Colombo",
          "website": "http://www.cmb.ac.lk/"
       },
       {
          "university": "University of Jaffna",
          "website": "http://www.jfn.ac.lk/"
       },
       {
          "university": "University of Kelaniya",
          "website": "http://www.kln.ac.lk/"
       },
       {
          "university": "University of Moratuwa",
          "website": "http://www.mrt.ac.lk/"
       },
       {
          "university": "University of Peradeniya",
          "website": "http://www.pdn.ac.lk/"
       },
       {
          "university": "University of Ruhuna",
          "website": "http://www.ruh.ac.lk/"
       },
       {
          "university": "University of Sri Jayawardenapura",
          "website": "http://www.sjp.ac.lk/"
       },
       {
          "university": "University of the Visual & Performing Arts",
          "website": "http://www.vpa.ac.lk/"
       },
       {
          "university": "Uva Wellassa University",
          "website": "http://www.uwu.ac.lk/"
       },
       {
          "university": "Wayamba University of Sri Lanka ",
          "website": "http://www.wyb.ac.lk/"
       }
    ],
    "LR": [
       {
          "university": "University of Liberia",
          "website": "http://www.universityliberia.org/"
       }
    ],
    "LS": [
       {
          "university": "National University of Lesotho",
          "website": "http://www.nul.ls/"
       }
    ],
    "LT": [
       {
          "university": "International School of Management",
          "website": "http://www.ism.lt/"
       },
       {
          "university": "Kaunas Medical Academy",
          "website": "http://info.kma.lt/"
       },
       {
          "university": "Kaunas University of Technology",
          "website": "http://www.ktu.lt/"
       },
       {
          "university": "Klaipeda University",
          "website": "http://www.ku.lt/"
       },
       {
          "university": "Lithuania Christian College",
          "website": "http://www.lcc.lt/"
       },
       {
          "university": "Lithunian Academy of Music and Theatre",
          "website": "http://www.lmta.lt/"
       },
       {
          "university": "Lithunian Institute of Physical Education",
          "website": "http://www.lkka.lt/"
       },
       {
          "university": "Lithunian University of Agriculture",
          "website": "http://www.lzua.lt/"
       },
       {
          "university": "Lithunian Veterinary Academy",
          "website": "http://www.lva.lt/"
       },
       {
          "university": "Military academy of Lithuania",
          "website": "http://www.lka.lt/"
       },
       {
          "university": "Mykolo Romerio university",
          "website": "http://www.mruni.eu/"
       },
       {
          "university": "Siauliai University",
          "website": "http://www.su.lt/"
       },
       {
          "university": "Vilnius Academy of Arts",
          "website": "http://www.mch.mii.lt/more/vda/"
       },
       {
          "university": "Vilnius Gediminas Technical University",
          "website": "http://www.vgtu.lt/"
       },
       {
          "university": "Vilnius Pedagogical University",
          "website": "http://www.vpu.lt/"
       },
       {
          "university": "Vilnius University",
          "website": "http://www.vu.lt/"
       },
       {
          "university": "Vytautas Magnus University",
          "website": "http://www.vdu.lt/"
       }
    ],
    "LU": [
       {
          "university": "International University Institute of Luxembourg",
          "website": "http://www.iuil.lu/"
       },
       {
          "university": "University of Luxemburg",
          "website": "http://www.uni.lu/"
       }
    ],
    "LV": [
       {
          "university": "Baltic International Academy",
          "website": "http://www.bsa.edu.lv/"
       },
       {
          "university": "Daugavpils University",
          "website": "http://www.dau.lv/"
       },
       {
          "university": "Higher School of Psychology",
          "website": "http://www.psy.lv/"
       },
       {
          "university": "Latvian Academy of Arts",
          "website": "http://www.lma.lv/"
       },
       {
          "university": "Latvian Academy of Culture",
          "website": "http://www.lka.edu.lv/"
       },
       {
          "university": "Latvian Academy of Music",
          "website": "http://www.lmuza.lv/academy/"
       },
       {
          "university": "Latvian Academy of Sports Education",
          "website": "http://lspa.lanet.lv/"
       },
       {
          "university": "Latvian Maritime Academy",
          "website": "http://www.lama.lv/"
       },
       {
          "university": "Latvian University of Agriculture",
          "website": "http://www.llu.lv/"
       },
       {
          "university": "Liepaja Pedagogical Higher School",
          "website": "http://www.cs.lpu.lv/"
       },
       {
          "university": "Medical Academy of Latvia",
          "website": "http://www.aml.lv/"
       },
       {
          "university": "National Defence Academy of Latvia",
          "website": "http://www.mil.lv/"
       },
       {
          "university": "Police Academy of Latvia",
          "website": "http://www.polak.edu.lv/"
       },
       {
          "university": "Rezekne Higher School",
          "website": "http://www.ru.lv/"
       },
       {
          "university": "Riga Aviation University",
          "website": "http://www.rau.lv/"
       },
       {
          "university": "Riga Teacher Training and Educational Management Academy",
          "website": "http://www.rpiva.lv/"
       },
       {
          "university": "Riga Technical University",
          "website": "http://www.rtu.lv/"
       },
       {
          "university": "School of Business and Finance",
          "website": "http://www.ba.lv/"
       },
       {
          "university": "Stockholm School of Economics in Riga",
          "website": "http://www.sseriga.edu.lv/"
       },
       {
          "university": "Transport and Telecommunication Institute",
          "website": "http://www.tsi.lv/"
       },
       {
          "university": "University of Latvia",
          "website": "http://www.lu.lv/"
       },
       {
          "university": "Vidzeme University College",
          "website": "http://www.va.lv/"
       }
    ],
    "LY": [
       {
          "university": "Al-Asmarya University for Islamic Studies",
          "website": "http://www.asmarya.edu.ly/"
       },
       {
          "university": "Al-Jabal Al-Gharbi University",
          "website": "http://www.jgu.edu.ly/"
       },
       {
          "university": "Elmergib University",
          "website": "http://www.elmergib.edu.ly/"
       },
       {
          "university": "Libyan International Medical University (LIMU)",
          "website": "http://www.limu.edu.ly/"
       },
       {
          "university": "Libya Open University",
          "website": "http://www.libopenuniv-edu.org/"
       },
       {
          "university": "Omar Al-Mukhtar University",
          "website": "http://www.omu.edu.ly/"
       },
       {
          "university": "Sebha University",
          "website": "http://www.sebhau.edu.ly/"
       },
       {
          "university": "Sirte University",
          "website": "http://www.su.edu.ly/"
       },
       {
          "university": "University of Benghazi",
          "website": "http://www.uob.edu.ly/"
       },
       {
          "university": "University of Tripoli",
          "website": "http://www.uot.edu.ly/"
       },
       {
          "university": "Zawiya University",
          "website": "http://www.zu.edu.ly/"
       }
    ],
    "MA": [
       {
          "university": "Al Akhawayn University",
          "website": "http://www.aui.ma/"
       },
       {
          "university": "Ecole des Hautes Etudes Commerciales MAROC",
          "website": "http://www.hec.ac.ma/"
       },
       {
          "university": "Ecole des Hautes Etudes en Gestion Informatique et Communication",
          "website": "http://www.edhec.ac.ma/"
       },
       {
          "university": "Ecole Mohammadia d'Ingénieurs",
          "website": "http://www.emi.ac.ma/"
       },
       {
          "university": "Ecole National d'Agriculture de Meknes",
          "website": "http://www.enameknes.ac.ma/"
       },
       {
          "university": "Ecole Nationale d'Architecture",
          "website": "http://www.archi.ac.ma/"
       },
       {
          "university": "Ecole Supérieure de Commerce et des Affaires",
          "website": "http://www.esca.ac.ma/"
       },
       {
          "university": "Ecole Superieure des Télécommunications",
          "website": "http://www.suptelecom.net.ma/"
       },
       {
          "university": "Ecole Supérieure d'Informatique et de Management",
          "website": "http://www.hightech.edu/"
       },
       {
          "university": "Institut des Hautes Etudes de Management",
          "website": "http://www.hem.ac.ma/"
       },
       {
          "university": "Institute of Management and Business Technology",
          "website": "http://www.imbt.ma/"
       },
       {
          "university": "Institut National des Postes et Télécommunications",
          "website": "http://www.inpt.ac.ma/"
       },
       {
          "university": "Institut Supérieur de Commerce et d'Administration des Entreprises",
          "website": "http://www.groupeiscae.ma/"
       },
       {
          "university": "Institut Supérieur de Management et de Technologie (MATCI)",
          "website": "http://www.matci.ac.ma/"
       },
       {
          "university": "International Institute for Higher Education",
          "website": "http://www.iihem.ac.ma/"
       },
       {
          "university": "ISFORT - Institut de Formation en Technologie Alimentaire",
          "website": "http://www.isfort.ac.ma/"
       },
       {
          "university": "Université Abdelmalek Essadi",
          "website": "http://www.uae.ac.ma/"
       },
       {
          "university": "Université Cadi Ayyad",
          "website": "http://www.ucam.ac.ma/"
       },
       {
          "university": "Université Chouaib Doukkali",
          "website": "http://www.ucd.ac.ma/"
       },
       {
          "university": "Université Hassan Ier - Settat",
          "website": "http://www.uh1.ac.ma/"
       },
       {
          "university": "Université Hassan II - Aïn Chock",
          "website": "http://www.rectorat-uh2c.ac.ma/"
       },
       {
          "university": "Université Hassan II - Mohammadia",
          "website": "http://www.uh2m.ac.ma/"
       },
       {
          "university": "Université Ibn Toufail",
          "website": "http://www.univ-ibntofail.ac.ma/"
       },
       {
          "university": "Université Ibn Zohr Agadir",
          "website": "http://www.esta.ac.ma/"
       },
       {
          "university": "Université Mohammed Ier",
          "website": "http://www.univ-oujda.ac.ma/"
       },
       {
          "university": "Université Mohammed V - Agdal",
          "website": "http://www.um5a.ac.ma/"
       },
       {
          "university": "Université Mohammed V - Souissi",
          "website": "http://www.um5s.ac.ma/"
       },
       {
          "university": "Université Moulay Ismail Meknès",
          "website": "http://www.rumi.ac.ma/"
       },
       {
          "university": "Université Sidi Mohammed Ben Abdellah",
          "website": "http://www.usmba.ac.ma/"
       }
    ],
    "MC": [
       {
          "university": "University of Southern Europe (Monaco Business School)",
          "website": "http://www.riviera.fr/usehome.htm"
       }
    ],
    "MD": [
       {
          "university": "Academy of Economic Studies of Moldova",
          "website": "http://www.ase.md/"
       },
       {
          "university": "Balti State University Alecu Russo",
          "website": "http://www.usb.md/"
       },
       {
          "university": "Comrat State University",
          "website": "http://www.kdu.md/"
       },
       {
          "university": "Free International University of Moldova",
          "website": "http://www.ulim.md/"
       },
       {
          "university": "Moldova Cooperative Trade University",
          "website": "http://www.uccm.md/"
       },
       {
          "university": "Moldova State Agricultural University",
          "website": "http://www.uasm.md/"
       },
       {
          "university": "Moldova State University",
          "website": "http://www.usm.md/"
       },
       {
          "university": "Moldova State University of Medicine and Pharmacy N. Testemitsanu",
          "website": "http://www.usmf.md/"
       },
       {
          "university": "Moldova Technical University",
          "website": "http://www.utm.md/"
       },
       {
          "university": "Pedagogical State University Ion Creanga",
          "website": "http://upm.moldnet.md/"
       },
       {
          "university": "Tiraspol State University",
          "website": "http://www.ust.md/"
       },
       {
          "university": "University of Applied Sciences of Moldova",
          "website": "http://www.usam.md/"
       }
    ],
    "ME": [
       {
          "university": "University of Montenegro",
          "website": "http://www.ucg.cg.ac.yu/"
       }
    ],
    "MG": [
       {
          "university": "Université d'Antananarivo",
          "website": "http://www.univ-antananarivo.mg/"
       },
       {
          "university": "Université d'Antsiranana",
          "website": "http://www.univ-antsiranana.mg/"
       },
       {
          "university": "Université de Fianarantsoa",
          "website": "http://www.univ-fianar.mg/"
       },
       {
          "university": "Université de Mahajanga",
          "website": "http://www.univ-mahajanga.mg/"
       },
       {
          "university": "Université de Toamasina",
          "website": "http://www.univ-toamasina.mg/"
       },
       {
          "university": "Université de Toliary",
          "website": "http://www.univ-toliara.mg/"
       }
    ],
    "MK": [
       {
          "university": "FON University",
          "website": "http://www.fon.edu.mk/"
       },
       {
          "university": "South East European University",
          "website": "http://www.seeu.edu.mk/"
       },
       {
          "university": "State University of Tetova",
          "website": "http://www.unite.edu.mk/"
       },
       {
          "university": "St.Cyril and Methodius University",
          "website": "http://www.ukim.edu.mk/"
       },
       {
          "university": "St.Kliment Ohridski University",
          "website": "http://www.uklo.edu.mk/"
       },
       {
          "university": "University for Information Science and Technology  St. Paul The Apostle ",
          "website": "http://www.uist.edu.mk/"
       },
       {
          "university": "University Goce Delcev",
          "website": "http://www.ugd.edu.mk/"
       }
    ],
    "ML": [
       {
          "university": "Université de Bamako",
          "website": "http://www.ml.refer.org/u-bamako/"
       }
    ],
    "MM": [
       {
          "university": "International Theravada Buddhist Missionary University",
          "website": "http://www.itbmu.org.mm/"
       },
       {
          "university": "Mandalay Technological University",
          "website": "http://www.most.gov.mm/mtu/"
       },
       {
          "university": "Myanmar Aerospace Engineering University",
          "website": "http://www.most.gov.mm/maeu/"
       },
       {
          "university": "Technological University (Banmaw)",
          "website": "http://www.most.gov.mm/banmawtu/"
       },
       {
          "university": "Technological University (Dawei)",
          "website": "http://www.most.gov.mm/daweitu/"
       },
       {
          "university": "Technological University (Hinthada)",
          "website": "http://www.most.gov.mm/hinthadatu/"
       },
       {
          "university": "Technological University (Hmawbi)",
          "website": "http://www.most.gov.mm/hmawbitu/"
       },
       {
          "university": "Technological University (Hpaan)",
          "website": "http://www.most.gov.mm/hpaantu/"
       },
       {
          "university": "Technological University (Kalay)",
          "website": "http://www.most.gov.mm/kalaytu/"
       },
       {
          "university": "Technological University (Kyaing Tong)",
          "website": "http://www.most.gov.mm/kyaingtongtu/"
       },
       {
          "university": "Technological University (Kyaukse)",
          "website": "http://www.most.gov.mm/kyauksetu/"
       },
       {
          "university": "Technological University (Lashio)",
          "website": "http://www.most.gov.mm/lashiotu/"
       },
       {
          "university": "Technological University (Loikaw)",
          "website": "http://www.most.gov.mm/loikawtu/"
       },
       {
          "university": "Technological University (Magway)",
          "website": "http://www.most.gov.mm/magwaytu/"
       },
       {
          "university": "Technological University (Mandalay)",
          "website": "http://www.most.gov.mm/mdytu/"
       },
       {
          "university": "Technological University (Maubin)",
          "website": "http://www.most.gov.mm/maubintu/"
       },
       {
          "university": "Technological University (Mawlamyaing)",
          "website": "http://www.most.gov.mm/mawlamyaingtu/"
       },
       {
          "university": "Technological University (Meikhtila)",
          "website": "http://www.most.gov.mm/meikhtilatu/"
       },
       {
          "university": "Technological University (Monywa)",
          "website": "http://www.most.gov.mm/monywatu/"
       },
       {
          "university": "Technological University (Myeik)",
          "website": "http://www.most.gov.mm/myeiktu/"
       },
       {
          "university": "Technological University (Myitkyina) ",
          "website": "http://www.most.gov.mm/myitkyinatu/"
       },
       {
          "university": "Technological University (Pakokku)",
          "website": "http://www.most.gov.mm/pakokkutu/"
       },
       {
          "university": "Technological University (Pathein)",
          "website": "http://www.most.gov.mm/patheintu/"
       },
       {
          "university": "Technological University (Pinlon)",
          "website": "http://www.most.gov.mm/pinlontu/"
       },
       {
          "university": "Technological University (Pyay)",
          "website": "http://www.most.gov.mm/ptu/"
       },
       {
          "university": "Technological University (Sittwe)",
          "website": "http://www.most.gov.mm/sittwetu/"
       },
       {
          "university": "Technological University (Taunggyi)",
          "website": "http://www.most.gov.mm/taunggyitu/"
       },
       {
          "university": "Technological University (Taungoo)",
          "website": "http://www.most.gov.mm/taungootu/"
       },
       {
          "university": "Technological University (Thanlyin)",
          "website": "http://www.most.gov.mm/thanlyintu/"
       },
       {
          "university": "Technological University (West Yangon)",
          "website": "http://www.most.gov.mm/wytu/"
       },
       {
          "university": "Technological University (Yangon)",
          "website": "http://www.most.gov.mm/ytu/"
       },
       {
          "university": "University of Computer Studies - Yangon",
          "website": "http://www.ucsy.edu.mm/"
       },
       {
          "university": "University of Medicine 1 - Yangon",
          "website": "http://www.um1ygn.edu.mm/"
       },
       {
          "university": "University of Medicine Magway",
          "website": "http://www.ummg.edu.mm/"
       }
    ],
    "MN": [
       {
          "university": "Health sciences University of Mongolia",
          "website": "http://www.hsum-ac.mn/"
       },
       {
          "university": "Institute of Commerce and Business",
          "website": "http://www.icb.edu.mn/"
       },
       {
          "university": "Mongolian National University",
          "website": "http://www.num.edu.mn/"
       },
       {
          "university": "Mongolian State University of Agriculture",
          "website": "http://www.msua.edu.mn/"
       },
       {
          "university": "Mongolian State University of Arts and Culture",
          "website": "http://www.msuac.edu.mn/"
       },
       {
          "university": "Mongolian State University of Education",
          "website": "http://www.msue.edu.mn/"
       },
       {
          "university": "Mongolian University of Science and Technology",
          "website": "http://www.must.edu.mn/"
       },
       {
          "university": "Orkhon University",
          "website": "http://www.orkhon.edu.mn/"
       },
       {
          "university": "Ulaanbaatar University",
          "website": "http://www.ulaanbaatar.edu.mn/"
       },
       {
          "university": "University of the Humanities",
          "website": "http://www.humanities.mn/"
       }
    ],
    "MO": [
       {
          "university": "Macau Polytechnic Institute",
          "website": "http://www.ipm.edu.mo/"
       },
       {
          "university": "University of Macau",
          "website": "http://www.umac.mo/"
       },
       {
          "university": "University of Saint Joseph",
          "website": "http://www.usj.edu.mo/"
       }
    ],
    "MQ": [
       {
          "university": "Université des Antilles et de la Guyane",
          "website": "http://www.univ-ag.fr/"
       }
    ],
    "MR": [
       {
          "university": "Université de Nouakchott",
          "website": "http://www.univ-nkc.mr/"
       }
    ],
    "MS": [
       {
          "university": "University of Science - Arts and Technology",
          "website": "http://www.usat.ms/"
       }
    ],
    "MT": [
       {
          "university": "European Institute of Education",
          "website": "http://www.eieonline.com/"
       },
       {
          "university": "International Tourism Institute",
          "website": "http://www.itismalta.com/"
       },
       {
          "university": "University of Malta",
          "website": "http://www.um.edu.mt/"
       }
    ],
    "MU": [
       {
          "university": "University of Mauritius",
          "website": "http://www.uom.ac.mu/"
       },
       {
          "university": "University of Technology",
          "website": "http://www.utm.ac.mu/"
       }
    ],
    "MV": [
       {
          "university": "Cyryx College",
          "website": "http://www.cyryxcollege.edu.mv/"
       },
       {
          "university": "Maldives National University",
          "website": "http://www.mnu.edu.mv/"
       },
       {
          "university": "Mandhu College",
          "website": "http://www.mandhu.com/edu/"
       }
    ],
    "MW": [
       {
          "university": "Catholic University of Malawi",
          "website": "http://www.cunima.net/"
       },
       {
          "university": "Mzuzu University",
          "website": "http://www.mzuni.ac.mw/"
       },
       {
          "university": "University of Livingstonia",
          "website": "http://www.ulivingstonia.com/"
       },
       {
          "university": "University of Malawi",
          "website": "http://www.unima.mw/"
       }
    ],
    "MX": [
       {
          "university": "Benemerita Universidad Autónoma de Puebla",
          "website": "http://www.buap.mx/"
       },
       {
          "university": "Centro de Enseñanza Técnica Industrial",
          "website": "http://www.ceti.mx/"
       },
       {
          "university": "Centro de Estudios Universitarios Monterrey",
          "website": "http://www.ceu.edu.mx/"
       },
       {
          "university": "Centro de Estudios Universitarios Xochicalco",
          "website": "http://www.ceux.mx/"
       },
       {
          "university": "Centro Universitario Ixtlahuaca",
          "website": "http://www.ceunix.com.mx/"
       },
       {
          "university": "CETYS Universidad",
          "website": "http://www.cetys.mx/"
       },
       {
          "university": "Dirección General de Institutos Tecnológicos",
          "website": "http://www.dgit.gob.mx/"
       },
       {
          "university": "El Colegio de México",
          "website": "http://www.colmex.mx/"
       },
       {
          "university": "Escuela Bancaria y Comercial",
          "website": "http://www.ebc.mx/"
       },
       {
          "university": "Escuela Nacional de Estudios Superiores Unidad León",
          "website": "http://www.enes.unam.mx/"
       },
       {
          "university": "Instituto de Estudios Superiores de la Sierra",
          "website": "http://www.ties.com.mx/"
       },
       {
          "university": "Instituto Politécnico Nacional",
          "website": "http://www.ipn.edu.mx/"
       },
       {
          "university": "Instituto Politécnico Nacional - Campus Guanajuato",
          "website": "http://www.upiig.ipn.mx/"
       },
       {
          "university": "Instituto Tecnológico Autonómo de México",
          "website": "http://www.itam.mx/"
       },
       {
          "university": "Instituto Tecnológico de Aguascalientes",
          "website": "http://www.ita.mx/"
       },
       {
          "university": "Instituto Tecnológico de Celaya",
          "website": "http://www.itc.mx/"
       },
       {
          "university": "Instituto Tecnologico de Durango",
          "website": "http://www.itdurango.edu.mx/"
       },
       {
          "university": "Instituto Tecnológico de León",
          "website": "http://www.itleon.edu.mx/"
       },
       {
          "university": "Instituto Tecnologico de Minatitlan",
          "website": "http://www.itmina.edu.mx/"
       },
       {
          "university": "Instituto Tecnológico de Morelia",
          "website": "http://www.itmorelia.edu.mx/"
       },
       {
          "university": "Instituto Tecnológico de Querétaro",
          "website": "http://www.itq.edu.mx/"
       },
       {
          "university": "Instituto Tecnológico de San Luis Potosí",
          "website": "http://www.itslp.edu.mx/"
       },
       {
          "university": "Instituto Tecnológico de Sonora",
          "website": "http://www.itson.mx/"
       },
       {
          "university": "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)",
          "website": "http://www.itesm.mx/"
       },
       {
          "university": "Instituto Tecnológico y de Estudios Superiores de Occidente",
          "website": "http://www.iteso.mx/"
       },
       {
          "university": "Politécnico de Guanajuato",
          "website": "http://www.poligto.edu.mx/"
       },
       {
          "university": "Tecnologico de Baja California - Universidad",
          "website": "http://www.tecbc.mx/"
       },
       {
          "university": "Universidad Anáhuac",
          "website": "http://www.anahuac.mx/"
       },
       {
          "university": "Universidad Anáhuac del Sur",
          "website": "http://www.uas.mx/"
       },
       {
          "university": "Universidad Autónoma Agraria Antonio Narro",
          "website": "http://www.uaaan.mx/"
       },
       {
          "university": "Universidad Autónoma Benito Juárez de Oaxaca",
          "website": "http://www.uabjo.mx/"
       },
       {
          "university": "Universidad Autónoma de Aguascalientes",
          "website": "http://www.uaa.mx:8001/"
       },
       {
          "university": "Universidad Autónoma de Baja California",
          "website": "http://www.uabc.mx/"
       },
       {
          "university": "Universidad Autónoma de Baja California Sur",
          "website": "http://www.uabcs.mx/"
       },
       {
          "university": "Universidad Autónoma de Campeche",
          "website": "http://www.uacam.mx/"
       },
       {
          "university": "Universidad Autónoma de Chapingo",
          "website": "http://www.chapingo.mx/"
       },
       {
          "university": "Universidad Autónoma de Chiapas",
          "website": "http://www.unach.mx/"
       },
       {
          "university": "Universidad Autónoma de Chihuahua",
          "website": "http://www.uach.mx/"
       },
       {
          "university": "Universidad Autónoma de Ciudad Juárez",
          "website": "http://www.uacj.mx/"
       },
       {
          "university": "Universidad Autónoma de Coahuila",
          "website": "http://www.uadec.mx/"
       },
       {
          "university": "Universidad Autonoma  de Durango",
          "website": "http://www.uad.edu.mx/"
       },
       {
          "university": "Universidad Autónoma de Fresnillo",
          "website": "http://www.uaf.mx/"
       },
       {
          "university": "Universidad Autónoma de Guadalajara",
          "website": "http://www.uag.mx/"
       },
       {
          "university": "Universidad Autónoma de Guerrero",
          "website": "http://www.uagro.mx/"
       },
       {
          "university": "Universidad Autónoma de la Ciudad de México",
          "website": "http://www.uacm.edu.mx/"
       },
       {
          "university": "Universidad Autónoma de la Laguna",
          "website": "http://www.ual.mx/"
       },
       {
          "university": "Universidad Autónoma del Carmen",
          "website": "http://www.unacar.mx/"
       },
       {
          "university": "Universidad Autónoma del Estado de Hidalgo",
          "website": "http://www.reduaeh.mx/"
       },
       {
          "university": "Universidad Autónoma del Estado de México",
          "website": "http://www.uaemex.mx/"
       },
       {
          "university": "Universidad Autónoma del Estado de Morelos",
          "website": "http://www.uaem.mx/"
       },
       {
          "university": "Universidad Autónoma del Noreste",
          "website": "http://www.uane.edu.mx/"
       },
       {
          "university": "Universidad Autónoma de Nayarit",
          "website": "http://www.uan.mx/"
       },
       {
          "university": "Universidad Autónoma de Nuevo León",
          "website": "http://www.uanl.mx/"
       },
       {
          "university": "Universidad Autónoma de Querétaro",
          "website": "http://www.uaq.mx/"
       },
       {
          "university": "Universidad Autónoma de San Luis Potosí",
          "website": "http://www.uaslp.mx/"
       },
       {
          "university": "Universidad Autónoma de Sinaloa",
          "website": "http://www.uasnet.mx/"
       },
       {
          "university": "Universidad Autónoma de Tamaulipas",
          "website": "http://www.uat.mx/"
       },
       {
          "university": "Universidad Autónoma de Tlaxcala",
          "website": "http://www.uatx.mx/"
       },
       {
          "university": "Universidad Autónoma de Veracruz Villa Rica",
          "website": "http://www.univillarica.mx/"
       },
       {
          "university": "Universidad Autónoma de Yucatán",
          "website": "http://www.uady.mx/"
       },
       {
          "university": "Universidad Autónoma de Zacatecas",
          "website": "http://www.reduaz.mx/"
       },
       {
          "university": "Universidad Autonoma España de Durango",
          "website": "http://www.uaed.edu.mx/"
       },
       {
          "university": "Universidad Autónoma Metropolitana",
          "website": "http://www.uam.mx/"
       },
       {
          "university": "Universidad Autónoma Metropolitana - Azcapotzalco",
          "website": "http://www-azc.uam.mx/"
       },
       {
          "university": "Universidad Autónoma Metropolitana - Iztapalapa",
          "website": "http://www.iztapalapa.uam.mx/"
       },
       {
          "university": "Universidad Autónoma Metropolitana - Xochimilco",
          "website": "http://cueyatl.uam.mx/"
       },
       {
          "university": "Universidad Champagnat",
          "website": "http://www.champagnat.edu.mx/"
       },
       {
          "university": "Universidad Chapultepéc",
          "website": "http://www.univchapultepec.edu.mx/"
       },
       {
          "university": "Universidad Cientifica Latinoamericana de Hidalgo",
          "website": "http://www.uclah.edu.mx/"
       },
       {
          "university": "Universidad Cristóbal Colón",
          "website": "http://www.ver.ucc.mx/"
       },
       {
          "university": "Universidad Cuauhtémoc",
          "website": "http://www.cuauhtemoc.edu.mx/"
       },
       {
          "university": "Universidad de Celaya",
          "website": "http://www.udec.edu.mx/"
       },
       {
          "university": "Universidad de Ciencias y Artes de Chiapas",
          "website": "http://www.unicach.edu.mx/"
       },
       {
          "university": "Universidad de Colima",
          "website": "http://www.ucol.mx/"
       },
       {
          "university": "Universidad de Comunicación Avanzada",
          "website": "http://www.unica.edu.mx/"
       },
       {
          "university": "Universidad de Cuautitlan Izcalli",
          "website": "http://www.udeci.edu.mx/"
       },
       {
          "university": "Universidad de Guadalajara",
          "website": "http://www.udg.mx/"
       },
       {
          "university": "Universidad de Guanajuato",
          "website": "http://www.ugto.mx/"
       },
       {
          "university": "Universidad de Guanajuato - Campus León",
          "website": "http://www.campusleon.ugto.mx/"
       },
       {
          "university": "Universidad de Hermosillo",
          "website": "http://www.udeh.edu.mx/"
       },
       {
          "university": "Universidad de La Salle - Bajío",
          "website": "http://bajio.delasalle.edu.mx/"
       },
       {
          "university": "Universidad de las Américas",
          "website": "http://www.udla.mx/"
       },
       {
          "university": "Universidad de las Américas - Puebla",
          "website": "http://www.udlap.mx/"
       },
       {
          "university": "Universidad de la Sierra A.C.",
          "website": "http://www.usac.edu.mx/"
       },
       {
          "university": "Universidad del Bajío",
          "website": "http://www.ubac.edu.mx/"
       },
       {
          "university": "Universidad del Centro de México",
          "website": "http://www.ucem.edu.mx/"
       },
       {
          "university": "Universidad de León",
          "website": "http://www.universidaddeleon.edu.mx/"
       },
       {
          "university": "Universidad del Mar",
          "website": "http://www.umar.mx/"
       },
       {
          "university": "Universidad del Mayab",
          "website": "http://www.unimayab.edu.mx/"
       },
       {
          "university": "Universidad del Noreste",
          "website": "http://www.une.edu.mx/"
       },
       {
          "university": "Universidad del Noroeste",
          "website": "http://www.uno.mx/"
       },
       {
          "university": "Universidad del Norte",
          "website": "http://www.un.edu.mx/"
       },
       {
          "university": "Universidad del Tepeyac",
          "website": "http://www.tepeyac.edu.mx/"
       },
       {
          "university": "Universidad del Valle de Atemajac",
          "website": "http://www.univa.mx/"
       },
       {
          "university": "Universidad del Valle del Bravo",
          "website": "http://www.uvb.edu.mx/"
       },
       {
          "university": "Universidad del Valle de México",
          "website": "http://www.uvmnet.edu/"
       },
       {
          "university": "Universidad del Valle de Puebla",
          "website": "http://members.rotfl.com/uvp/"
       },
       {
          "university": "Universidad del Valle de Toluca",
          "website": "http://www.uvt.edu.mx/"
       },
       {
          "university": "Universidad de Montemorelos",
          "website": "http://www.umontemorelos.edu.mx/"
       },
       {
          "university": "Universidad de Monterrey",
          "website": "http://www.udem.edu.mx/"
       },
       {
          "university": "Universidad de Norteamerica",
          "website": "http://www.un.mx/"
       },
       {
          "university": "Universidad de Occidente",
          "website": "http://www.udo.mx/"
       },
       {
          "university": "Universidad de Quintana Roo",
          "website": "http://www.uqroo.mx/"
       },
       {
          "university": "Universidad de San Miguel",
          "website": "http://www.udesanmiguel.edu.mx/"
       },
       {
          "university": "Universidad de Sonora",
          "website": "http://www.uson.mx/"
       },
       {
          "university": "Universidad Don Vasco",
          "website": "http://www.udv.edu.mx/"
       },
       {
          "university": "Universidad España de Durango",
          "website": "http://www.unes.edu.mx/"
       },
       {
          "university": "Universidad Franciscana de México",
          "website": "http://www.ufm.edu.mx/"
       },
       {
          "university": "Universidad Franco-Mexicana",
          "website": "http://www.ufram.edu.mx/"
       },
       {
          "university": "Universidad Fray Luca Paccioli",
          "website": "http://www.frayluca.edu.mx/"
       },
       {
          "university": "Universidad Hispanoamericana",
          "website": "http://hispano.edu.mx/"
       },
       {
          "university": "Universidad Iberoamericana",
          "website": "http://www.uia.mx/"
       },
       {
          "university": "Universidad Iberoamericana - Campus León",
          "website": "http://www.leon.uia.mx/"
       },
       {
          "university": "Universidad Insurgentes Plantel León",
          "website": "http://www.uileon.edu.mx/"
       },
       {
          "university": "Universidad Intercontinental",
          "website": "http://www.uic.edu.mx/"
       },
       {
          "university": "Universidad Itaca",
          "website": "http://www.i.edu.mx/"
       },
       {
          "university": "Universidad Jóse Vasconcelos",
          "website": "http://www.univas.edu.mx/"
       },
       {
          "university": "Universidad Juárez Autónoma de Tabasco",
          "website": "http://www.ujat.mx/"
       },
       {
          "university": "Universidad Juárez del Estado de Durango",
          "website": "http://www.ujed.mx/"
       },
       {
          "university": "Universidad Justo Sierra",
          "website": "http://www.ujsierra.mx/"
       },
       {
          "university": "Universidad Kino",
          "website": "http://www.unikino.mx/"
       },
       {
          "university": "Universidad la Concordia",
          "website": "http://www.universidadlaconcordia.edu.mx/"
       },
       {
          "university": "Universidad La Salle",
          "website": "http://www.ulsa.edu.mx/"
       },
       {
          "university": "Universidad La Salle Cuernavaca",
          "website": "http://www.ulsac.edu.mx/"
       },
       {
          "university": "Universidad Lasallista Benavente",
          "website": "http://www.ulsab.edu.mx/"
       },
       {
          "university": "Universidad Latina",
          "website": "http://www.unla.edu.mx/"
       },
       {
          "university": "Universidad Latinoamericana",
          "website": "http://www.ula.edu.mx/"
       },
       {
          "university": "Universidad Madero",
          "website": "http://www.umad.edu.mx/"
       },
       {
          "university": "Universidad Mesoamericana de San Agustín",
          "website": "http://www.umsa.edu.mx/"
       },
       {
          "university": "Universidad Metropolitana de Monterrey",
          "website": "http://www.umm.edu.mx/"
       },
       {
          "university": "Universidad Metropolitana Latin Campus",
          "website": "http://www.umla.edu.mx/"
       },
       {
          "university": "Universidad Mexicana",
          "website": "http://www.unimex.edu.mx/"
       },
       {
          "university": "Universidad Mexicana del Noreste",
          "website": "http://www.umne.edu.mx/"
       },
       {
          "university": "Universidad México Americana del Norte",
          "website": "http://www.uman.edu.mx/"
       },
       {
          "university": "Universidad México Internacional",
          "website": "http://www.umi.edu.mx/"
       },
       {
          "university": "Universidad Michoacana de San Nicolás de Hidalgo",
          "website": "http://www.umich.mx/"
       },
       {
          "university": "Universidad Motolinía del Pedegral",
          "website": "http://www.universidadmotolinia.edu.mx/"
       },
       {
          "university": "Universidad Motolinía del Pedregal",
          "website": "http://www.motolinia.com.mx/"
       },
       {
          "university": "Universidad Nacional Autónoma de México",
          "website": "http://www.unam.mx/"
       },
       {
          "university": "Universidad Nuevo Mundo",
          "website": "http://www.unum.edu.mx/"
       },
       {
          "university": "Universidad Obrera de Mexico",
          "website": "http://www.uom.edu.mx/"
       },
       {
          "university": "Universidad Olmeca",
          "website": "http://www.olmeca.edu.mx/"
       },
       {
          "university": "Universidad Panamericana",
          "website": "http://www.up.mx/"
       },
       {
          "university": "Universidad Pedagógica Nacional",
          "website": "http://www.upn.mx/"
       },
       {
          "university": "Universidad Pedagógica Nacional - Unidad León",
          "website": "http://www.lef.upn.mx/"
       },
       {
          "university": "Universidad Pedagógica Veracruzana",
          "website": "http://www.secupv.org/"
       },
       {
          "university": "Universidad Popular Autonóma del Estado de Puebla",
          "website": "http://www.upaep.mx/"
       },
       {
          "university": "Universidad Regiomontana",
          "website": "http://www.ur.mx/"
       },
       {
          "university": "Universidad Regional del Sureste",
          "website": "http://www.urse.edu.mx/"
       },
       {
          "university": "Universidad Regional Miguel Hidalgo",
          "website": "http://www.urmh.edu.mx/"
       },
       {
          "university": "Universidad Salesiana",
          "website": "http://www.universidadsalesiana.edu.mx/"
       },
       {
          "university": "Universidad Santa Fe",
          "website": "http://www.usf.com.mx/"
       },
       {
          "university": "Universidad Simón Bolivar",
          "website": "http://www.usb.edu.mx/"
       },
       {
          "university": "Universidad Tecnologica de la Mixteca",
          "website": "http://www.utm.mx/"
       },
       {
          "university": "Universidad Tecnológica de México",
          "website": "http://www.unitec.mx/"
       },
       {
          "university": "Universidad Tecnologica de Nezahualcoyotl",
          "website": "http://www.utneza.edu.mx/"
       },
       {
          "university": "Universidad Valle del Grijalva",
          "website": "http://www.uvg1.net/"
       },
       {
          "university": "Universidad Veracruzana",
          "website": "http://www.uv.mx/"
       },
       {
          "university": "Universidad Xicotepetl",
          "website": "http://www.uxac.edu.mx/"
       },
       {
          "university": "Westhill University",
          "website": "http://www.westhill.edu.mx/"
       }
    ],
    "MY": [
       {
          "university": "Advance Tertiary College",
          "website": "http://www.atc2u.com/"
       },
       {
          "university": "Aimst University",
          "website": "http://www.aimst.edu.my/"
       },
       {
          "university": "Al-Bukhari International University",
          "website": "http://www.aiu.edu.my/"
       },
       {
          "university": "Allianze College of Medical Sciences (ACMS)",
          "website": "http://www.acms.edu.my/"
       },
       {
          "university": "Al Madinah International University",
          "website": "http://www.mediu.edu.my/"
       },
       {
          "university": "Asia E University",
          "website": "http://www.aeu.edu.my/"
       },
       {
          "university": "Asia Pacific Institute of Information Technology (APIIT)",
          "website": "http://www.apiit.edu.my/"
       },
       {
          "university": "Baitulmal Management Institute (IPB)",
          "website": "http://www.ipb.edu.my/"
       },
       {
          "university": "Binary University College of Managemant & Entrepreneurship",
          "website": "http://www.binary.edu.my/"
       },
       {
          "university": "Brickfields Asia College",
          "website": "http://www.bac.edu.my/"
       },
       {
          "university": "British Malaysian Institute",
          "website": "http://www.bmi.edu.my/"
       },
       {
          "university": "City University College of Science and Technology",
          "website": "http://www.city.edu.my/"
       },
       {
          "university": "Curtin University of Technology - Sarawak Campus",
          "website": "http://www.curtin.edu.my/"
       },
       {
          "university": "Cyberjaya University College of Medical Science",
          "website": "http://www.cybermed.edu.my/"
       },
       {
          "university": "Darul Hikmah Islamic College",
          "website": "http://www.hikmah.edu.my/"
       },
       {
          "university": "Darul Naim College of Technology",
          "website": "http://www.ktd.edu.my/"
       },
       {
          "university": "Darul Quran Islamic College University",
          "website": "http://kudqi.net.my/"
       },
       {
          "university": "Darul Takzim Institute of Technology",
          "website": "http://www.instedt.edu.my/"
       },
       {
          "university": "Darul Ulum Islamic College",
          "website": "http://kidu-darululum.blogspot.com/"
       },
       {
          "university": "FTMS Global Academy",
          "website": "http://www.ftmsglobal.com/"
       },
       {
          "university": "Help University College",
          "website": "http://www.help.edu.my/"
       },
       {
          "university": "Iact College",
          "website": "http://www.iact.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Batu Lintang",
          "website": "http://www.ipbl.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Darul Aman",
          "website": "http://www.ipda.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Dato' Razali Ismail ",
          "website": "http://www.ipgmkdri.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Ilmu Khas",
          "website": "http://www.ipik.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Ipoh",
          "website": "http://www.ipip.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Islamic Education",
          "website": "http://www.ipislam.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Keningau",
          "website": "http://www.ipks.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Kent",
          "website": "http://www.ipkent.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Kota Bharu",
          "website": "http://www.ipgkkb.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Malay Language",
          "website": "http://www.ipbmm.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Melaka ",
          "website": "http://www.ippm.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Penang",
          "website": "http://www.i4p.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Perlis",
          "website": "http://www.ipgperlis.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Perlis",
          "website": "http://www.ipgperlis.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Raja Melewar",
          "website": "http://www.iprm.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Rajang",
          "website": "http://www.ipgkrajang.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Sarawak",
          "website": "http://www.ipsmiri.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Sultan Abdul Halim",
          "website": "http://www.ipsah.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Sultan Mizan",
          "website": "http://www.ipgmksm.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Tawau",
          "website": "http://www.ipgmtawau.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Technical Education ",
          "website": "http://www.ipteknik.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Temenggong Ibrahim",
          "website": "http://www.ipgkti.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Tengku Ampuan Afzan",
          "website": "http://www.iptaa.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Tuanku Bainun",
          "website": "http://www.iptb.edu.my/"
       },
       {
          "university": "Institute of Teachers Education - Tun Hussein Onn",
          "website": "http://www.iptho.edu.my/"
       },
       {
          "university": "Institut Prima Bestari - Pine Academy ",
          "website": "http://www.pine.edu.my/"
       },
       {
          "university": "International Islamic College",
          "website": "http://www.iic.edu.my/"
       },
       {
          "university": "International Islamic College of Penang",
          "website": "http://www.kitab.edu.my/"
       },
       {
          "university": "International Islamic University",
          "website": "http://www.iiu.edu.my/"
       },
       {
          "university": "International Medical University",
          "website": "http://www.imu.edu.my/"
       },
       {
          "university": "International University College of Nursing (IUCN)",
          "website": "http://www.iucn.edu.my/"
       },
       {
          "university": "International University College of Technology Twintech (IUCTT)",
          "website": "http://www.iuctt.edu.my/"
       },
       {
          "university": "Islamic College for Sciences and Technologies",
          "website": "http://www.kist.edu.my/"
       },
       {
          "university": "Johore Bharu Primeir Polytechnic",
          "website": "http://www.polijb.edu.my/"
       },
       {
          "university": "KBU International College",
          "website": "http://www.kbu.edu.my/"
       },
       {
          "university": "KDU College Sdn Bhd",
          "website": "http://www.kdu.edu.my/"
       },
       {
          "university": "Kolej Universiti Insaniah",
          "website": "http://www.kuin.edu.my/"
       },
       {
          "university": "Kota Bharu Polytechnic",
          "website": "http://www.pkb.edu.my/"
       },
       {
          "university": "Kota Kinabalu Polytechnic",
          "website": "http://www.pkksabah.edu.my/"
       },
       {
          "university": "Kuala Lumpur Infrastructure University College",
          "website": "http://www.kliuc.edu.my/"
       },
       {
          "university": "Kuala Lumpur Metropolitan University",
          "website": "http://www.klmu.edu.my/"
       },
       {
          "university": "Kuala Terengganu City Polytechnic",
          "website": "http://www.pkkt.edu.my/"
       },
       {
          "university": "Kuching Polytechnic",
          "website": "http://www.poliku.edu.my/"
       },
       {
          "university": "Limkokwing University College of Creative Technology",
          "website": "http://www.limkokwing.edu.my/"
       },
       {
          "university": "Linton University College",
          "website": "http://www.linton.edu.my/"
       },
       {
          "university": "Mahsa University College for Health and Medical Science",
          "website": "http://www.mahsa.edu.my/"
       },
       {
          "university": "Malaysia University of Science and Technology (MUST)",
          "website": "http://www.must.edu.my/"
       },
       {
          "university": "Management and Science University",
          "website": "http://www.msu.edu.my/"
       },
       {
          "university": "Mara Poly-Tech College",
          "website": "http://www.kptm.edu.my/"
       },
       {
          "university": "Melaka City Polytechnic",
          "website": "http://www.polimelaka.edu.my/"
       },
       {
          "university": "Melaka Islamic University College",
          "website": "http://www.kuim.edu.my/"
       },
       {
          "university": "Merlimau Polytechnic",
          "website": "http://www.pmm.edu.my/"
       },
       {
          "university": "Monash University - Malaysia Campus",
          "website": "http://www.monash.edu.my/"
       },
       {
          "university": "Muadzam Shah Polytechnic",
          "website": "http://www.polimuadzam.edu.my/"
       },
       {
          "university": "Multimedia University",
          "website": "http://www.mmu.edu.my/"
       },
       {
          "university": "Murni Nursing College",
          "website": "http://www.murni.edu.my/"
       },
       {
          "university": "Newcastle University - Medicine Malaysia ",
          "website": "http://numed.ncl.ac.uk/"
       },
       {
          "university": "Nilai University College",
          "website": "http://www.nilai.edu.my/"
       },
       {
          "university": "Olympia College",
          "website": "http://www.olympia.edu.my/"
       },
       {
          "university": "Open University Malaysia",
          "website": "http://www.oum.edu.my/"
       },
       {
          "university": "Penang International Dental College",
          "website": "http://www.pidc.edu.my/"
       },
       {
          "university": "Perak Islamic College",
          "website": "http://www.kiperak.edu.my/"
       },
       {
          "university": "Perdana University",
          "website": "http://www.perdanauniversity.edu.my/"
       },
       {
          "university": "Perlis Islamic Higher Learning Institute",
          "website": "http://www.iptips.edu.my/"
       },
       {
          "university": "Petronas Technology University",
          "website": "http://www.utp.edu.my/"
       },
       {
          "university": "Port Dickson Polytechnic",
          "website": "http://www.polipd.edu.my/"
       },
       {
          "university": "Primier International University Perak",
          "website": "http://www.piup.edu.my/"
       },
       {
          "university": "PTPL College",
          "website": "http://www.ptpl.edu.my/"
       },
       {
          "university": "PTPL College",
          "website": "http://www.ptpl.edu.my/"
       },
       {
          "university": "Raffles University",
          "website": "http://www.raffles-university.edu.my/"
       },
       {
          "university": "Saito College",
          "website": "http://www.saito.edu.my/"
       },
       {
          "university": "Seberang Perai Polytechnic",
          "website": "http://www.psp.edu.my/"
       },
       {
          "university": "Segi University College",
          "website": "http://www.segi.edu.my/"
       },
       {
          "university": "Selangor Islamic University College",
          "website": "http://www.kuis.edu.my/"
       },
       {
          "university": "Shahputra College",
          "website": "http://www.kolejshahputra.edu.my/"
       },
       {
          "university": "Sultan Abdul Halim Muadzam Shah Polytechnic",
          "website": "http://www.polimas.edu.my/"
       },
       {
          "university": "Sultanah Bahiyah Polytechnic",
          "website": "http://www.ptsb.edu.my/"
       },
       {
          "university": "Sultan Ahmad Shah Islamic College",
          "website": "http://www.kipsas.edu.my/"
       },
       {
          "university": "Sultan Azlan Shah Polytechnic ",
          "website": "http://www.psas.edu.my/"
       },
       {
          "university": "Sultan Haji Ahmad Shah Polytechnic",
          "website": "http://www.polisas.edu.my/"
       },
       {
          "university": "Sultan Idris Shah Polytechnic",
          "website": "http://www.psis.edu.my/"
       },
       {
          "university": "Sultan Ismail Petra International Islamic College",
          "website": "http://www.kias.edu.my/"
       },
       {
          "university": "Sultan Mizan Zainal Abidin Polytechnic",
          "website": "http://www.psmza.edu.my/"
       },
       {
          "university": "Sultan Salahuddin Abdul Aziz Shah Polytechnic",
          "website": "http://www.psa.edu.my/"
       },
       {
          "university": "Sunway University College",
          "website": "http://www.sunway.edu.my/"
       },
       {
          "university": "Swinburne University of Technology - Sarawak Campus",
          "website": "http://www.swinburne.edu.my/"
       },
       {
          "university": "Taj International College",
          "website": "http://www.taj.edu.my/"
       },
       {
          "university": "Taylor's University College",
          "website": "http://www.taylors.edu.my/"
       },
       {
          "university": "TPM College",
          "website": "http://www.tpmcollege.edu.my/"
       },
       {
          "university": "Tunku Abdul Rahman Chinese College",
          "website": "http://www.tarc.edu.my/"
       },
       {
          "university": "Tunku Abdul Rahman University (Chinese University)",
          "website": "http://www.utar.edu.my/"
       },
       {
          "university": "Tunku Syed Sirajuddin Polytechnic",
          "website": "http://www.ptss.edu.my/"
       },
       {
          "university": "UCSI University",
          "website": "http://www.ucsi.edu.my/"
       },
       {
          "university": "Ungku Omar Premier Polytechnic",
          "website": "http://www.puo.edu.my/"
       },
       {
          "university": "Universiti Darul Iman",
          "website": "http://www.udm.edu.my/"
       },
       {
          "university": "Universiti Industri Selangor",
          "website": "http://www.unisel.edu.my/"
       },
       {
          "university": "Universiti Kebangsaan Malaysia",
          "website": "http://www.ukm.my/"
       },
       {
          "university": "Universiti Kuala Lumpur",
          "website": "http://www.unikl.edu.my/"
       },
       {
          "university": "Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)",
          "website": "http://miit.unikl.edu.my/"
       },
       {
          "university": "Universiti Malaya",
          "website": "http://www.um.edu.my/"
       },
       {
          "university": "Universiti Malaysia Kelantan",
          "website": "http://www.umk.edu.my/"
       },
       {
          "university": "Universiti Malaysia Perlis",
          "website": "http://www.unimap.edu.my/"
       },
       {
          "university": "Universiti Malaysia Sabah",
          "website": "http://www.ums.edu.my/"
       },
       {
          "university": "Universiti Malaysia Sarawak",
          "website": "http://www.unimas.my/"
       },
       {
          "university": "Universiti Malaysia Terengganu",
          "website": "http://www.umt.edu.my/"
       },
       {
          "university": "Universiti Pendidikan Sultan Idris",
          "website": "http://www.upsi.edu.my/"
       },
       {
          "university": "Universiti Putra Malaysia",
          "website": "http://www.upm.edu.my/"
       },
       {
          "university": "Universiti Sains Malaysia",
          "website": "http://www.usm.my/"
       },
       {
          "university": "Universiti Sultan Zainal Abidin",
          "website": "http://www.unisza.edu.my/"
       },
       {
          "university": "Universiti Teknikal Malaysia Melaka",
          "website": "http://www.utem.edu.my/"
       },
       {
          "university": "Universiti Teknologi Malaysia",
          "website": "http://www.utm.my/"
       },
       {
          "university": "Universiti Teknologi Mara",
          "website": "http://www.uitm.edu.my/"
       },
       {
          "university": "Universiti Teknologi Petronas",
          "website": "http://www.utp.edu.my/"
       },
       {
          "university": "Universiti Tenaga Nasional",
          "website": "http://www.uniten.edu.my/"
       },
       {
          "university": "Universiti Tun Abdul Razak",
          "website": "http://www.unitar.edu.my/"
       },
       {
          "university": "Universiti Tun Hussein Onn Malaysia",
          "website": "http://www.uthm.edu.my/"
       },
       {
          "university": "Universiti Tunku Abdul Rahman",
          "website": "http://www.utar.edu.my/"
       },
       {
          "university": "Universiti Utara Malaysia",
          "website": "http://www.uum.edu.my/"
       },
       {
          "university": "University College of Technology & Innovation (UCTI)",
          "website": "http://www.ucti.edu.my/"
       },
       {
          "university": "University Malaysia Pahang",
          "website": "http://www.ump.edu.my/"
       },
       {
          "university": "University of Management and Technology ",
          "website": "http://www.umtech.edu.my/"
       },
       {
          "university": "University of Nottingham - Malaysia Campus",
          "website": "http://www.nottingham.edu.my/"
       },
       {
          "university": "University Tun Abdul Razak",
          "website": "http://www.unirazak.edu.my/"
       },
       {
          "university": "Wawasan Open University",
          "website": "http://www.wou.edu.my/"
       },
       {
          "university": "West Minster International College",
          "website": "http://www.westminster.edu.my/"
       },
       {
          "university": "YPC-iTWEB College",
          "website": "http://www.kolejypc.edu.my/"
       }
    ],
    "MZ": [
       {
          "university": "Instituto Superior de Ciênicas e Tecnologia de Moçambique",
          "website": "http://www.isctem.ac.mz/"
       },
       {
          "university": "Instituto Superior de Relações Internacionais (ISRI) ",
          "website": "http://www.isri.ac.mz/"
       },
       {
          "university": "Instituto Superior Politécnico e Universitário",
          "website": "http://www.ispu.ac.mz/"
       },
       {
          "university": "Universidade Católica de Moçambique",
          "website": "http://www.ucm.ac.mz/"
       },
       {
          "university": "Universidade Eduardo Mondlane",
          "website": "http://www.uem.mz/"
       }
    ],
    "NA": [
       {
          "university": "Ilsa Independent College",
          "website": "http://www.ilsacollege.edu.na/"
       },
       {
          "university": "Institute of Information Technology",
          "website": "http://www.iit.com.na/"
       },
       {
          "university": "International University of Management",
          "website": "http://www.ium.edu.na/"
       },
       {
          "university": "Polytechnic of Namibia",
          "website": "http://www.polytechnic.edu.na/"
       },
       {
          "university": "University of Namibia",
          "website": "http://www.unam.na/"
       }
    ],
    "NC": [
       {
          "university": "University of New Caledonia",
          "website": "http://www.ufp.nc/"
       }
    ],
    "NE": [
       {
          "university": "Université Abdou Moumouni de Niamey",
          "website": "http://uam.refer.ne/"
       }
    ],
    "NG": [
       {
          "university": "Abia State Polytechnic",
          "website": "http://www.abiapoly.edu.ng/"
       },
       {
          "university": "Abia State University",
          "website": "http://www.absu.edu.ng/"
       },
       {
          "university": "ABTI American University of Nigeria",
          "website": "http://www.aun.edu.ng/"
       },
       {
          "university": "Abubakar Tafawa Balewa University",
          "website": "http://www.atbu.edu.ng/"
       },
       {
          "university": "Adamawa State University",
          "website": "http://www.adsu.edu.ng/"
       },
       {
          "university": "Adekunle Ajasin University",
          "website": "http://www.aaua.edu.ng/"
       },
       {
          "university": "Adeleke University",
          "website": "http://www.adelekeuniversity.edu.ng/"
       },
       {
          "university": "Adeyemi College of Education",
          "website": "http://www.aceondo-ng.com/"
       },
       {
          "university": "Afe Babalola University",
          "website": "http://www.abuad.edu.ng/"
       },
       {
          "university": "African University of Science and Technology",
          "website": "http://www.aust-abuja.org/"
       },
       {
          "university": "Ahmadu Bello University",
          "website": "http://www.abu.edu.ng/"
       },
       {
          "university": "Ajayi Crowther University",
          "website": "http://www.acu.edu.ng/"
       },
       {
          "university": "Akanu Ibiam Federal Polytechnic - Unwana",
          "website": "http://www.polyunwana.net/"
       },
       {
          "university": "Akwa Ibom State University of Technology",
          "website": "http://www.akutech.edu.ng/"
       },
       {
          "university": "Al-hikmah University",
          "website": "http://www.alhikmah.edu.ng/"
       },
       {
          "university": "Ambrose Alli University",
          "website": "http://www.aauekpoma.edu.ng/"
       },
       {
          "university": "Anambra State University of Science and Technology",
          "website": "http://www.ansu.edu.ng/"
       },
       {
          "university": "Auchi Polytechnic",
          "website": "http://www.auchipoly.edu.ng/"
       },
       {
          "university": "Babcock University",
          "website": "http://www.babcockuni.edu.ng/"
       },
       {
          "university": "Bauchi State University - Gadau",
          "website": "http://www.basug.edu.ng/"
       },
       {
          "university": "Bayero University Kano",
          "website": "http://www.buk.edu.ng/"
       },
       {
          "university": "Baze University Abuja",
          "website": "http://www.bazeuniversity.edu.ng"
       },
       {
          "university": "Bells University of Technology",
          "website": "http://www.bellsuniversity.org/"
       },
       {
          "university": "Benson Idahosa University",
          "website": "http://www.biu.edu.ng/"
       },
       {
          "university": "Benue State University",
          "website": "http://www.bsum.edu.ng/"
       },
       {
          "university": "Bingham University",
          "website": "http://www.binghamuni.edu.ng/"
       },
       {
          "university": "Bowen University",
          "website": "http://bowenuniversity-edu.org/"
       },
       {
          "university": "Caleb University",
          "website": "http://www.calebuniversity.edu.ng/"
       },
       {
          "university": "Caritas University",
          "website": "http://www.caritasuni.edu.ng/"
       },
       {
          "university": "College of Education Ikere",
          "website": "http://www.coeikere.edu.ng/"
       },
       {
          "university": "College of Education Oju",
          "website": "http://www.coeoju.com/"
       },
       {
          "university": "Covenant University",
          "website": "http://www.covenantuniversity.edu.ng/"
       },
       {
          "university": "Crawford University ",
          "website": "http://www.crawforduniversity.edu.ng/"
       },
       {
          "university": "Crescent University",
          "website": "http://www.crescent-university.edu.ng/"
       },
       {
          "university": "Cross River University of Science and Technology",
          "website": "http://www.crutech.edu.ng/"
       },
       {
          "university": "Ebonyi State University",
          "website": "http://www.ebsu-edu.net/"
       },
       {
          "university": "Ekiti State University",
          "website": "http://www.eksu.edu.ng/"
       },
       {
          "university": "Enugu State University of Science and Technology",
          "website": "http://www.esut.edu.ng/"
       },
       {
          "university": "Federal College Of Education (Technical) - Akoka",
          "website": "http://www.fcetakoka-edu.net/"
       },
       {
          "university": "Federal Polytechnic Bauchi - Nigeria",
          "website": "http://fptb.edu.ng/"
       },
       {
          "university": "Federal University of Petroleum Resources",
          "website": "http://www.fupre.edu.ng/"
       },
       {
          "university": "Federal University of Technology - Akure",
          "website": "http://www.futa.edu.ng/"
       },
       {
          "university": "Federal University of Technology - Minna",
          "website": "http://www.futminna.edu.ng/"
       },
       {
          "university": "Federal University of Technology - Owerri",
          "website": "http://www.futo.edu.ng/"
       },
       {
          "university": "Federal University of Technology - Yola",
          "website": "http://www.futy.edu.ng/"
       },
       {
          "university": "Fountain University",
          "website": "http://www.fountainuniversity.edu.ng/"
       },
       {
          "university": "Godfrey Okoye University",
          "website": "http://www.go-uni-enugu.net/"
       },
       {
          "university": "Godfrey Okoye University ",
          "website": "http://www.gouni.edu.ng/"
       },
       {
          "university": "Gombe State University",
          "website": "http://www.gsu.edu.ng/"
       },
       {
          "university": "Ibrahim Babangida University",
          "website": "http://www.ibbu.edu.ng/"
       },
       {
          "university": "Igbinedion University",
          "website": "http://www.iuokada.edu.ng/"
       },
       {
          "university": "Imo State University",
          "website": "http://www.imsu.edu.ng/"
       },
       {
          "university": "Joseph Ayo Babalola University",
          "website": "http://www.jabu.edu.ng/"
       },
       {
          "university": "Kaduna State University",
          "website": "http://www.kasuportal.net/"
       },
       {
          "university": "Kano State University of Technology Wudil",
          "website": "http://www.kustwudil.edu.ng/"
       },
       {
          "university": "Katsina University",
          "website": "http://www.kuk.edu.ng/"
       },
       {
          "university": "Kebbi State University of Science and Technology",
          "website": "http://www.ksusta.edu.ng/"
       },
       {
          "university": "Kogi State University",
          "website": "http://www.ksuportal.edu.ng/"
       },
       {
          "university": "Kwararafa University",
          "website": "http://www.kwararafauniversity.edu.ng/"
       },
       {
          "university": "Kwara State Polytecnic",
          "website": "http://www.kwarapoly.edu.ng/"
       },
       {
          "university": "Kwara State University ",
          "website": "http://www.kwasu.edu.ng/"
       },
       {
          "university": "Ladoke Akintola University of Technology",
          "website": "http://www.lautech.edu.ng/"
       },
       {
          "university": "Lagos City Polytechnic",
          "website": "http://www.mylcp.net/"
       },
       {
          "university": "Lagos State Polytechnic",
          "website": "http://www.laspotech.net/"
       },
       {
          "university": "Lagos State University",
          "website": "http://www.lasunigeria.org/"
       },
       {
          "university": "Landmark University",
          "website": "http://www.lmu.edu.ng/"
       },
       {
          "university": "Lead City University of Ibadan",
          "website": "http://www.lcu.edu.ng/"
       },
       {
          "university": "Madonna University",
          "website": "http://www.madonnauniversity.edu.ng/"
       },
       {
          "university": "Michael Okpara University of Agriculture",
          "website": "http://www.mouau.edu.ng/"
       },
       {
          "university": "Nasarawa State University Keffi",
          "website": "http://www.nsuk.edu.ng/"
       },
       {
          "university": "National Open University of Nigeria",
          "website": "http://www.nou.edu.ng/"
       },
       {
          "university": "Niger Delta University",
          "website": "http://www.ndu.edu.ng/"
       },
       {
          "university": "Nigerian Defence Academy",
          "website": "http://www.nda.edu.ng/"
       },
       {
          "university": "Nigerian Turkish Nile University",
          "website": "http://www.ntnu.edu.ng/"
       },
       {
          "university": "Nnamdi Azikiwe University",
          "website": "http://www.unizik.edu.ng/"
       },
       {
          "university": "Novena University",
          "website": "http://www.novenauniversity.edu.ng/"
       },
       {
          "university": "Obafemi Awolowo University Ile-Ife",
          "website": "http://www.oauife.edu.ng/"
       },
       {
          "university": "Obong University",
          "website": "http://www.obonguniversity.net/"
       },
       {
          "university": "Oduduwa University",
          "website": "http://www.oduduwauniversity.edu.ng/"
       },
       {
          "university": "Olabisi Onabanjo University ",
          "website": "http://www.oouagoiwoye.edu.ng/"
       },
       {
          "university": "Ondo State University of Science and Technology",
          "website": "http://www.osustech.edu.ng/"
       },
       {
          "university": "Osun State University",
          "website": "http://www.uniosun.edu.ng/"
       },
       {
          "university": "Pan-African University",
          "website": "http://www.pau.edu.ng/"
       },
       {
          "university": "Paul University",
          "website": "http://www.pauluniversity.edu.ng/"
       },
       {
          "university": "Polytechnic Ibadan",
          "website": "http://www.polyibadan.edu.ng/"
       },
       {
          "university": "Redeemers University",
          "website": "http://www.run.edu.ng/"
       },
       {
          "university": "Renaissance University",
          "website": "http://www.rnu.edu.ng/"
       },
       {
          "university": "Rivers State University of Science and Technology",
          "website": "http://www.ust.edu.ng/"
       },
       {
          "university": "Salem University",
          "website": "http://www.salemuniversity.edu.ng/"
       },
       {
          "university": "Sokoto State University ",
          "website": "http://www.ssu.edu.ng/"
       },
       {
          "university": "Tai Solarin University of Education",
          "website": "http://www.tasued.edu.ng/"
       },
       {
          "university": "Tansian University",
          "website": "http://www.tansianuniversity.edu.ng/"
       },
       {
          "university": "Taraba State University",
          "website": "http://www.tsuniversity.edu.ng/"
       },
       {
          "university": "The Achievers University",
          "website": "http://www.achievers.edu.ng/"
       },
       {
          "university": "The Federal Polytechnic Offa",
          "website": "http://www.fedpoffa.edu.ng/"
       },
       {
          "university": "Umaru Musa Yar'Adua University ",
          "website": "http://www.umyu.edu.ng/"
       },
       {
          "university": "University of Abuja",
          "website": "http://www.unibuja.edu.ng/"
       },
       {
          "university": "University of Agriculture Abeokuta",
          "website": "http://www.unaab.edu.ng/"
       },
       {
          "university": "University of Agriculture Makurdi",
          "website": "http://www.uam.edu.ng/"
       },
       {
          "university": "University of Benin",
          "website": "http://www.uniben.edu/"
       },
       {
          "university": "University of Calabar",
          "website": "http://www.unical.edu.ng/"
       },
       {
          "university": "University of Ibadan",
          "website": "http://www.ui.edu.ng/"
       },
       {
          "university": "University of Ilorin",
          "website": "http://www.unilorin.edu.ng/"
       },
       {
          "university": "University of Jos",
          "website": "http://www.unijos.edu.ng/"
       },
       {
          "university": "University of Lagos",
          "website": "http://www.unilag.edu.ng/"
       },
       {
          "university": "University of Maiduguri",
          "website": "http://www.unimaid.edu.ng/"
       },
       {
          "university": "University of Mkar",
          "website": "http://www.unimkar.edu.ng/"
       },
       {
          "university": "University of Nigeria",
          "website": "http://www.unn.edu.ng/"
       },
       {
          "university": "University of Portharcourt",
          "website": "http://www.uniport.edu.ng/"
       },
       {
          "university": "University of Uyo",
          "website": "http://www.uniuyo.edu.ng/"
       },
       {
          "university": "Usmanu Danfodiyo University Sokoto",
          "website": "http://www.udusok.edu.ng/"
       },
       {
          "university": "Veritas University",
          "website": "http://www.veritas.edu.ng/"
       },
       {
          "university": "Wellspring University",
          "website": "http://www.wellspringuniversity.edu.ng/"
       },
       {
          "university": "Wesley University of Science and Technology",
          "website": "http://www.wustoportal.edu.ng/"
       },
       {
          "university": "Western Delta University",
          "website": "http://www.wdu.edu.ng/"
       },
       {
          "university": "Yaba College of Technology",
          "website": "http://www.yabatech.edu.ng/"
       }
    ],
    "NI": [
       {
          "university": "Universidad Americana",
          "website": "http://www.uam.edu.ni/"
       },
       {
          "university": "Universidad Catolica Redemptoris Mater",
          "website": "http://www.unica.edu.ni/"
       },
       {
          "university": "Universidad Central de Nicaragua",
          "website": "http://www.ucn.edu.ni/"
       },
       {
          "university": "Universidad Centroamericana",
          "website": "http://www.uca.edu.ni/"
       },
       {
          "university": "Universidad Centroamericana de Ciencias Empresariales (UCEM)",
          "website": "http://www.ucem.edu.ni/"
       },
       {
          "university": "Universidad Ciencias Comerciales",
          "website": "http://www.ucc.edu.ni/"
       },
       {
          "university": "Universidad de las Américas (ULAM)",
          "website": "http://www.ulam.edu.ni/"
       },
       {
          "university": "Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense",
          "website": "http://www.uraccan.edu.ni/"
       },
       {
          "university": "Universidad de Managua (U de M)",
          "website": "http://www.udem.edu.ni/"
       },
       {
          "university": "Universidad Hispanoamericana",
          "website": "http://www.uhispam.edu.ni/"
       },
       {
          "university": "Universidad Iberoamericana de Ciencia y Tecnologia",
          "website": "http://www.unicit.edu.ni/"
       },
       {
          "university": "Universidad Internacional de Integración de América Latina",
          "website": "http://www.unival.edu.ni/"
       },
       {
          "university": "Universidad Nacional Agraria",
          "website": "http://www.una.edu.ni/"
       },
       {
          "university": "Universidad Nacional Autonoma de Nicaragua",
          "website": "http://www.unan.edu.ni/"
       },
       {
          "university": "Universidad Nacional de Ingenieria",
          "website": "http://www.uni.edu.ni/"
       },
       {
          "university": "Universidad Nicaragüense de Ciencia y Tecnológica",
          "website": "http://www.ucyt.edu.ni/"
       },
       {
          "university": "Universidad Politecnica de Nicaragua",
          "website": "http://www.upoli.edu.ni/"
       },
       {
          "university": "Universidad Popular de Nicaragua (UPONIC)",
          "website": "http://www.uponic.edu.ni/"
       }
    ],
    "NL": [
       {
          "university": "Christelijke Hogeschool Windesheim",
          "website": "http://www.windesheim.nl/"
       },
       {
          "university": "De Haagse Hogeschool",
          "website": "http://www.dehaagsehogeschool.nl/"
       },
       {
          "university": "Delft University of Technology",
          "website": "http://www.tudelft.nl/"
       },
       {
          "university": "Dutch Delta University",
          "website": "http://www.ddu.nl/"
       },
       {
          "university": "Eindhoven University of Technology",
          "website": "http://www.tue.nl/"
       },
       {
          "university": "Erasmus University Rotterdam",
          "website": "http://www.eur.nl/"
       },
       {
          "university": "European Open University",
          "website": "http://www.europeanopenuniversity.com/"
       },
       {
          "university": "Fontys University of Applied Sciences",
          "website": "http://www.fontys.edu/"
       },
       {
          "university": "Foundation University",
          "website": "http://www.foundationuniversity.com/"
       },
       {
          "university": "Free University Amsterdam",
          "website": "http://www.vu.nl/"
       },
       {
          "university": "Hanzehogeschool Groningen",
          "website": "http://www.hanze.nl/"
       },
       {
          "university": "Hogeschool Inholland",
          "website": "http://www.inholland.nl/"
       },
       {
          "university": "Hogeschool Leiden",
          "website": "http://www.hsleiden.nl/"
       },
       {
          "university": "Hogeschool Rotterdam",
          "website": "http://www.hro.nl/"
       },
       {
          "university": "Hogeschool Utrecht",
          "website": "http://www.hu.nl/"
       },
       {
          "university": "Hogeschool van Amsterdam",
          "website": "http://www.hva.nl/"
       },
       {
          "university": "Hogeschool voor de Kunsten Utrecht (HKU)",
          "website": "http://www.hku.nl/"
       },
       {
          "university": "Hotelschool The Hague",
          "website": "http://www.hotelschool.nl/"
       },
       {
          "university": "Institute of Social Studies",
          "website": "http://www.iss.nl/"
       },
       {
          "university": "International Institute for Geo - Information Science and Earth Observation",
          "website": "http://www.itc.nl/"
       },
       {
          "university": "Islamic University of Rotterdam",
          "website": "http://www.islamicuniversity.nl/"
       },
       {
          "university": "Leiden University",
          "website": "http://www.leidenuniv.nl/"
       },
       {
          "university": "NHL University of Applied Sciences",
          "website": "http://www.nhl.nl/"
       },
       {
          "university": "NHTV Breda University of Professional Education",
          "website": "http://www.nhtv.nl"
       },
       {
          "university": "NTI University",
          "website": "http://www.nti.nl/"
       },
       {
          "university": "Open University of the Netherlands",
          "website": "http://www.ou.nl/"
       },
       {
          "university": "Radboud University of Nijmegen",
          "website": "http://www.ru.nl/"
       },
       {
          "university": "Roosevelt Academy University College",
          "website": "http://www.roac.nl/"
       },
       {
          "university": "Saxion Universities ",
          "website": "http://saxion.edu/"
       },
       {
          "university": "Stenden University",
          "website": "http://www.stenden.com/"
       },
       {
          "university": "Tilburg University",
          "website": "http://www.tilburguniversity.nl/"
       },
       {
          "university": "UNESCO-IHE Institute for Water Education",
          "website": "http://www.unesco-ihe.org/"
       },
       {
          "university": "Universiteit Doesburg (UNDO)",
          "website": "http://www.universiteitdoesburg.nl/"
       },
       {
          "university": "University for Humanistics (UH)",
          "website": "http://www.uvh.nl/"
       },
       {
          "university": "University of Amsterdam",
          "website": "http://www.uva.nl/"
       },
       {
          "university": "University of Groningen",
          "website": "http://www.rug.nl/"
       },
       {
          "university": "University of Maastricht",
          "website": "http://www.unimaas.nl/"
       },
       {
          "university": "University of Nijenrode",
          "website": "http://www.nijenrode.nl/"
       },
       {
          "university": "University of Twente",
          "website": "http://www.utwente.nl/"
       },
       {
          "university": "Utrecht University",
          "website": "http://www.uu.nl/"
       },
       {
          "university": "Wageningen University",
          "website": "http://www.wageningenuniversity.nl/"
       },
       {
          "university": "Wittenborg University",
          "website": "http://www.wittenborg.eu/"
       },
       {
          "university": "Zuyd University",
          "website": "http://www.zuyd.nl/"
       }
    ],
    "NO": [
       {
          "university": "Agder University College",
          "website": "http://www.hia.no/"
       },
       {
          "university": "Agricultural University of Norway",
          "website": "http://www.nlh.no/"
       },
       {
          "university": "Bergen University College",
          "website": "http://www.hib.no/"
       },
       {
          "university": "Bodo Regional University",
          "website": "http://www.hibo.no/"
       },
       {
          "university": "Finnmark University College",
          "website": "http://www.hifm.no/"
       },
       {
          "university": "Lillehammer University College",
          "website": "http://www.hil.no/"
       },
       {
          "university": "Molde University College",
          "website": "http://www.himolde.no/"
       },
       {
          "university": "National Academy of Fine Arts",
          "website": "http://ska.khio.no/main"
       },
       {
          "university": "National College of Art and Design",
          "website": "http://www.khio.no/shks/"
       },
       {
          "university": "Norway's Information Technology University College",
          "website": "http://www.nith.no/"
       },
       {
          "university": "Norwegian College of Veterinary Medicine",
          "website": "http://www.veths.no/"
       },
       {
          "university": "Norwegian School of Economics and Business Administration",
          "website": "http://www.nhh.no/"
       },
       {
          "university": "Norwegian School of Management BI",
          "website": "http://www.bi.no/"
       },
       {
          "university": "Norwegian State Academy of Music",
          "website": "http://www.nmh.no/"
       },
       {
          "university": "Norwegian Teacher Academy for Studies in Religion and Education",
          "website": "http://www.nla.no/"
       },
       {
          "university": "Norwegian University of Life Sciences",
          "website": "http://www.umb.no/"
       },
       {
          "university": "Norwegian University of Science and Technology",
          "website": "http://www.ntnu.no/"
       },
       {
          "university": "Norwegian University of Sport and Physical Education",
          "website": "http://www.nih.no/"
       },
       {
          "university": "Oslo School of Architecture",
          "website": "http://www.aho.no/"
       },
       {
          "university": "University of Bergen",
          "website": "http://www.uib.no/"
       },
       {
          "university": "University of Oslo",
          "website": "http://www.uio.no/"
       },
       {
          "university": "University of Stavanger",
          "website": "http://www.uis.no/"
       },
       {
          "university": "University of Tromsø",
          "website": "http://www.uit.no/"
       }
    ],
    "NP": [
       {
          "university": "Agriculture and Forestry University",
          "website": "http://www.afu.edu.np/"
       },
       {
          "university": "B.P.Koirala Institute of Health Sciences",
          "website": "http://www.bpkihs.edu/"
       },
       {
          "university": "Kathmandu University",
          "website": "http://www.ku.edu.np/"
       },
       {
          "university": "Lumbini Buddhist University",
          "website": "http://www.lbu.edu.np/"
       },
       {
          "university": "Nepal Sanskrit University",
          "website": "http://www.nsu.edu.np/"
       },
       {
          "university": "Pokhara University",
          "website": "http://www.pu.edu.np/"
       },
       {
          "university": "Purbanchal University",
          "website": "http://www.purbuniv.edu.np/"
       },
       {
          "university": "Tribhuvan University Kathmandu",
          "website": "http://www.tribhuvan-university.edu.np/"
       }
    ],
    "NU": [
       {
          "university": "St Clements University - Higher Education School",
          "website": "http://www.stclements.edu.nu/"
       }
    ],
    "NZ": [
       {
          "university": "Auckland University of Technology",
          "website": "http://www.aut.ac.nz/"
       },
       {
          "university": "Christchurch Polytechnic Institute of Technology",
          "website": "http://www.cpit.ac.nz/"
       },
       {
          "university": "Lincoln University",
          "website": "http://www.lincoln.ac.nz/"
       },
       {
          "university": "Massey University",
          "website": "http://www.massey.ac.nz/"
       },
       {
          "university": "Open Polytechnic ",
          "website": "http://www.openpolytechnic.ac.nz/"
       },
       {
          "university": "University of Auckland",
          "website": "http://www.auckland.ac.nz/"
       },
       {
          "university": "University of Canterbury",
          "website": "http://www.canterbury.ac.nz/"
       },
       {
          "university": "University of Otago",
          "website": "http://www.otago.ac.nz/"
       },
       {
          "university": "University of Waikato",
          "website": "http://www.waikato.ac.nz/"
       },
       {
          "university": "Victoria University of Wellington",
          "website": "http://www.vuw.ac.nz/"
       }
    ],
    "OM": [
       {
          "university": "Al-Buraimi University College",
          "website": "http://www.buc.edu.om/"
       },
       {
          "university": "Arab Open University",
          "website": "http://www.aou.edu.om/"
       },
       {
          "university": "Dhofar University",
          "website": "http://www.du.edu.om/"
       },
       {
          "university": "Ibra College of Technology",
          "website": "http://www.ict.edu.om/"
       },
       {
          "university": "Majan University College",
          "website": "http://www.majancollege.edu.om/"
       },
       {
          "university": "Muscat College",
          "website": "http://www.muscatcollege.edu.om/"
       },
       {
          "university": "Oman College Of Management & Technology",
          "website": "http://www.omancollege.edu.om/"
       },
       {
          "university": "Sohar University",
          "website": "http://www.soharuni.edu.om/"
       },
       {
          "university": "Sultan Qaboos University",
          "website": "http://www.squ.edu.om/"
       },
       {
          "university": "Sur University College",
          "website": "http://www.suc.edu.om/"
       },
       {
          "university": "University of Nizwa",
          "website": "http://www.unizwa.edu.om/"
       }
    ],
    "PA": [
       {
          "university": "Columbus University",
          "website": "http://www.columbus.edu/"
       },
       {
          "university": "Floret Global University",
          "website": "http://www.floret.edu.pa/"
       },
       {
          "university": "Instituto de Enseñanza Superior Oteima",
          "website": "http://www.institutoteima.ac.pa/"
       },
       {
          "university": "Universidad Abierta y a Distancia de Panama",
          "website": "http://www.unadp.ac.pa/"
       },
       {
          "university": "Universidad Autonoma de Chiriqui",
          "website": "http://www.unachi.ac.pa/"
       },
       {
          "university": "Universidad Católica Santa María La Antigua",
          "website": "http://www.usma.ac.pa/"
       },
       {
          "university": "Universidad de Cartago",
          "website": "http://www.ucapanama.org/"
       },
       {
          "university": "Universidad del Istmo",
          "website": "http://www.udi.edu/"
       },
       {
          "university": "Universidad de Panamá",
          "website": "http://www.up.ac.pa/"
       },
       {
          "university": "Universidad Especializada de Las Americas",
          "website": "http://www.udelas.ac.pa/"
       },
       {
          "university": "Universidad Interamericana de Educacion a Distancia de Panama",
          "website": "http://www.uniedpa.com/"
       },
       {
          "university": "Universidad Latina de Panamá",
          "website": "http://www.ulat.ac.pa/"
       },
       {
          "university": "Universidad Latinoamericana de Ciencia y Tecnologia - Panamá",
          "website": "http://www.ulacit.ac.pa/"
       },
       {
          "university": "Universidad Tecnológica de Panamá",
          "website": "http://www.utp.ac.pa/"
       },
       {
          "university": "Universidad Tecnológica Oteima",
          "website": "http://www.oteima.ac.pa/"
       },
       {
          "university": "Victoria International University",
          "website": "http://www.viu.edu.pa/"
       },
       {
          "university": "West Coast University (WCU)",
          "website": "http://www.westcoastuniversity-edu.com/"
       }
    ],
    "PE": [
       {
          "university": "Pontificia Universidad Católica del Perú",
          "website": "http://www.pucp.edu.pe/"
       },
       {
          "university": "Universidad Alas Peruanas",
          "website": "http://www.uap.edu.pe/"
       },
       {
          "university": "Universidad Andina del Cusco",
          "website": "http://www.uandina.edu.pe/"
       },
       {
          "university": "Universidad Andina Nestor Caceares Velasquez",
          "website": "http://www.uancv.edu.pe/"
       },
       {
          "university": "Universidad Católica de Santa María",
          "website": "http://www.ucsm.edu.pe/"
       },
       {
          "university": "Universidad Católica Santo Toribio de Mogrovejo",
          "website": "http://www.usat.edu.pe/"
       },
       {
          "university": "Universidad César Vallejo",
          "website": "http://www.ucv.edu.pe/"
       },
       {
          "university": "Universidad Cientifica del Sur",
          "website": "http://www.ucsur.edu.pe/"
       },
       {
          "university": "Universidad de Chiclayo",
          "website": "http://www.udch.edu.pe/"
       },
       {
          "university": "Universidad de Ciencias y Humanidades",
          "website": "http://www.uch.edu.pe/"
       },
       {
          "university": "Universidad de Huánuco",
          "website": "http://www.udh.edu.pe/"
       },
       {
          "university": "Universidad de Lambayeque",
          "website": "http://www.udl.edu.pe/"
       },
       {
          "university": "Universidad de Lima",
          "website": "http://www.ulima.edu.pe/"
       },
       {
          "university": "Universidad del Pacifico",
          "website": "http://www.up.edu.pe/"
       },
       {
          "university": "Universidad de Piura",
          "website": "http://www.udep.edu.pe/"
       },
       {
          "university": "Universidad Femenina del Sagrado Corazón",
          "website": "http://www.unife.edu.pe/"
       },
       {
          "university": "Universidad Los Angeles de Chimbote",
          "website": "http://www.uladech.edu.pe/"
       },
       {
          "university": "Universidad Marcelino Champagnat",
          "website": "http://www.champagnat.edu.pe/"
       },
       {
          "university": "Universidad Nacional Agraria de la Selva",
          "website": "http://www.unas.edu.pe/"
       },
       {
          "university": "Universidad Nacional Agraria La Molina",
          "website": "http://www.lamolina.edu.pe/"
       },
       {
          "university": "Universidad Nacional Agraria La Selva",
          "website": "http://www.unas.edu.pe/"
       },
       {
          "university": "Universidad Nacional Daniel Alcides Carrion",
          "website": "http://www.undac.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Cajamarca",
          "website": "http://www.unc.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Educación Enrique Guzmán y Valle",
          "website": "http://www.une.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Ingeniería",
          "website": "http://www.uni.edu.pe/"
       },
       {
          "university": "Universidad Nacional de la Amazonía Peruana",
          "website": "http://www.unapiquitos.edu.pe/"
       },
       {
          "university": "Universidad Nacional de La Libertad - Trujillo",
          "website": "http://www.unitru.edu.pe/"
       },
       {
          "university": "Universidad Nacional del Altiplano",
          "website": "http://www.unap.edu.pe/"
       },
       {
          "university": "Universidad Nacional del Callao",
          "website": "http://www.unac.edu.pe/"
       },
       {
          "university": "Universidad Nacional del Centro del Perú",
          "website": "http://www.uncp.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Piura",
          "website": "http://www.unp.edu.pe/"
       },
       {
          "university": "Universidad Nacional de San Agustin",
          "website": "http://www.unsa.edu.pe/"
       },
       {
          "university": "Universidad Nacional de San Antonio Abad",
          "website": "http://www.unsaac.edu.pe/"
       },
       {
          "university": "Universidad Nacional de San Cristóbal de Huamanga",
          "website": "http://www.unsch.edu.pe/"
       },
       {
          "university": "Universidad Nacional de San Martín",
          "website": "http://www.unsm.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Santa - Chimbote",
          "website": "http://www.uns.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Tumbes",
          "website": "http://www.untumbes.edu.pe/"
       },
       {
          "university": "Universidad Nacional de Ucayali",
          "website": "http://www.unu.edu.pe/"
       },
       {
          "university": "Universidad Nacional Federico Villarreal",
          "website": "http://www.unfv.edu.pe/"
       },
       {
          "university": "Universidad Nacional Hermilio Valdizan",
          "website": "http://www.unheval.edu.pe/"
       },
       {
          "university": "Universidad Nacional Jorge Basadre Grohmann",
          "website": "http://www.unjbg.edu.pe/"
       },
       {
          "university": "Universidad Nacional José Faustino Sánchez Carrión",
          "website": "http://www.unjfsc.edu.pe/"
       },
       {
          "university": "Universidad Nacional Mayor de San Marcos",
          "website": "http://www.unmsm.edu.pe/"
       },
       {
          "university": "Universidad Nacional Pedro Ruíz Gallo",
          "website": "http://www.unprg.edu.pe/"
       },
       {
          "university": "Universidad Nacional San Luis Gonzaga",
          "website": "http://www.unica.edu.pe/"
       },
       {
          "university": "Universidad Nacional Santiago Antúnez de Mayolo",
          "website": "http://www.unasam.edu.pe/"
       },
       {
          "university": "Universidad Norbert Wiener",
          "website": "http://www.uwiener.edu.pe/"
       },
       {
          "university": "Universidad Particular de Chiclayo",
          "website": "http://www.udch.edu.pe/"
       },
       {
          "university": "Universidad Particular Inca Garcilaso de la Vega",
          "website": "http://www.uigv.edu.pe/"
       },
       {
          "university": "Universidad Peruana Cayetano Heredia",
          "website": "http://www.upch.edu.pe/"
       },
       {
          "university": "Universidad Peruana de Ciencias Aplicadas",
          "website": "http://www.upc.edu.pe/"
       },
       {
          "university": "Universidad Peruana de Ciencias e Informática",
          "website": "http://www.upci.edu.pe/"
       },
       {
          "university": "Universidad Peruana Union",
          "website": "http://www.upeu.edu.pe/"
       },
       {
          "university": "Universidad Privada Antenor Orrego",
          "website": "http://www.upao.edu.pe/"
       },
       {
          "university": "Universidad Privada del Norte",
          "website": "http://www.upnorte.edu.pe/"
       },
       {
          "university": "Universidad Privada de Tacna",
          "website": "http://www.upt.edu.pe/"
       },
       {
          "university": "Universidad Privada Juan Mejía Baca",
          "website": "http://www.umb.edu.pe/"
       },
       {
          "university": "Universidad Privada Los Andes",
          "website": "http://www.upla.edu.pe/"
       },
       {
          "university": "Universidad Privada San Juan Bautista",
          "website": "http://www.upsjb.edu.pe/"
       },
       {
          "university": "Universidad Privada San Pedro",
          "website": "http://www.usanpedro.edu.pe/"
       },
       {
          "university": "Universidad Privada Sergio Bernales",
          "website": "http://www.upsb.edu.pe/"
       },
       {
          "university": "Universidad Ricardo Palma",
          "website": "http://www.urp.edu.pe/"
       },
       {
          "university": "Universidad San Ignacio de Loyola",
          "website": "http://www.sil.edu.pe/"
       },
       {
          "university": "Universidad San Martin de Porres",
          "website": "http://www.usmp.edu.pe/"
       },
       {
          "university": "Universidad San Pablo",
          "website": "http://www.usp.edu.pe/"
       },
       {
          "university": "Universidad Señor de Sipán",
          "website": "http://www.uss.edu.pe/"
       },
       {
          "university": "Universidad Tecnológica de los Andes de Apurímac",
          "website": "http://www.utea.edu.pe/"
       },
       {
          "university": "Universidad Tecnológica del Peru",
          "website": "http://www.utp.edu.pe/"
       }
    ],
    "PF": [
       {
          "university": "Université de la Polynésie Française",
          "website": "http://www.upf.pf/"
       }
    ],
    "PG": [
       {
          "university": "Divine Word University",
          "website": "http://www.dwu.ac.pg/"
       },
       {
          "university": "Pacific Adventist University",
          "website": "http://www.pau.ac.pg/"
       },
       {
          "university": "Papua New Guinea University of Technology",
          "website": "http://www.unitech.ac.pg/"
       },
       {
          "university": "University of Goroka",
          "website": "http://www.uog.ac.pg/"
       },
       {
          "university": "University of Papua New Guinea",
          "website": "http://www.upng.ac.pg/"
       }
    ],
    "PH": [
       {
          "university": "Adamson University",
          "website": "http://www.adamson.edu.ph/"
       },
       {
          "university": "Adventist University of the Philippines",
          "website": "http://www.aup.edu.ph/"
       },
       {
          "university": "Angeles University",
          "website": "http://www.auf.edu.ph/"
       },
       {
          "university": "Aquinas University",
          "website": "http://www.aquinas-university.edu/"
       },
       {
          "university": "Araullo University",
          "website": "http://www.aupen.edu.ph/"
       },
       {
          "university": "Arellano University",
          "website": "http://www.arellano.edu.ph/"
       },
       {
          "university": "Ateneo de Davao University",
          "website": "http://www.addu.edu.ph/"
       },
       {
          "university": "Ateneo de Manila University",
          "website": "http://www.admu.edu.ph/"
       },
       {
          "university": "Ateneo de Naga University",
          "website": "http://www.adnu.edu.ph/"
       },
       {
          "university": "Ateneo de Zamboanga University",
          "website": "http://www.adzu.edu.ph/"
       },
       {
          "university": "Benguet State University",
          "website": "http://www.bsu.edu.ph/"
       },
       {
          "university": "Bicol University",
          "website": "http://www.bicol-u.edu.ph/"
       },
       {
          "university": "Bulacan State University",
          "website": "http://www.bulsu.edu.ph/"
       },
       {
          "university": "Cagayan State University",
          "website": "http://www.csu.edu.ph/"
       },
       {
          "university": "Capitol University",
          "website": "http://www.cu-cdo.edu.ph/"
       },
       {
          "university": "Central Luzon State University",
          "website": "http://www2.mozcom.com/~clsu/"
       },
       {
          "university": "Central Mindanao University",
          "website": "http://www.cmu.edu.ph/"
       },
       {
          "university": "Central Philippine University",
          "website": "http://www.cpuic.edu/"
       },
       {
          "university": "Centro Escolar University",
          "website": "http://www.ceu.edu.ph/"
       },
       {
          "university": "Colegio de San Juan de Letran",
          "website": "http://www.letran.edu/"
       },
       {
          "university": "College of the Holy Spirit",
          "website": "http://www.holyspirit.edu.ph/"
       },
       {
          "university": "Davao Doctors College",
          "website": "http://www.davaodoctors.edu.ph/"
       },
       {
          "university": "De La Salle University",
          "website": "http://www.dlsu.edu.ph/"
       },
       {
          "university": "De La Salle University - Araneta",
          "website": "http://www.araneta.dlsu.edu.ph/"
       },
       {
          "university": "Divine Word College of Legazpi",
          "website": "http://www.dwc-legazpi.edu/"
       },
       {
          "university": "Don Mariano Marcos Memorial State University",
          "website": "http://www.dmmmsu.edu.ph/"
       },
       {
          "university": "Far Eastern University",
          "website": "http://www.feu.edu.ph/"
       },
       {
          "university": "Feati University",
          "website": "http://www.featiu.edu.ph/"
       },
       {
          "university": "Foundation University",
          "website": "http://www.foundationu.com/"
       },
       {
          "university": "Holy Angel University",
          "website": "http://www.hau.edu.ph/"
       },
       {
          "university": "Information and Communications Technology Academy",
          "website": "http://www.iacademy.ph/"
       },
       {
          "university": "Irish University Business School - Cebu",
          "website": "http://www.iubs.co.uk/"
       },
       {
          "university": "Isabela State University",
          "website": "http://www.isu.edu.ph/"
       },
       {
          "university": "Leyte Normal University",
          "website": "http://lnu.evis.net.ph/"
       },
       {
          "university": "Leyte State University ",
          "website": "http://www.lsu-visca.edu.ph/"
       },
       {
          "university": "Liceo de Cagayan University",
          "website": "http://www.ldcu.edu.ph/"
       },
       {
          "university": "Lyceum of the Philippines University",
          "website": "http://www.lpu.edu.ph/"
       },
       {
          "university": "Manila Central University",
          "website": "http://www.mcu.edu.ph/"
       },
       {
          "university": "Manuel L. Quezon University",
          "website": "http://www.mlqu.edu.ph/"
       },
       {
          "university": "Manuel S. Enverga University Foundation",
          "website": "http://www.mseuf.edu.ph/"
       },
       {
          "university": "Mariano Marcos State University",
          "website": "http://www.mmsu.edu.ph/"
       },
       {
          "university": "Mindanao State University",
          "website": "http://www.msumain.edu.ph/"
       },
       {
          "university": "Mindanao State University - Iligan Institute of Technology",
          "website": "http://www.msuiit.edu.ph/"
       },
       {
          "university": "Misamis University",
          "website": "http://www.mu.fapenet.org/"
       },
       {
          "university": "Mountain View College",
          "website": "http://www.mvc.edu.ph/"
       },
       {
          "university": "National University",
          "website": "http://www.nu.edu.ph/"
       },
       {
          "university": "New Era University",
          "website": "http://www.neu.edu.ph/"
       },
       {
          "university": "Northwestern University of the Philippines",
          "website": "http://www.nulaoag.com/"
       },
       {
          "university": "Notre Dame of Marbel University",
          "website": "http://www.ndmu.edu.ph/"
       },
       {
          "university": "Notre Dame University",
          "website": "http://www.ndu.fapenet.org/"
       },
       {
          "university": "Our Lady of Fatima University",
          "website": "http://www.fatima.edu.ph/"
       },
       {
          "university": "Palawan State University",
          "website": "http://www.psu-online.edu.ph/"
       },
       {
          "university": "Pangasinan State University",
          "website": "http://www.psu.edu.ph/"
       },
       {
          "university": "Philippine Christian University",
          "website": "http://www.pcu.edu.ph/"
       },
       {
          "university": "Philippine Military Academy",
          "website": "http://www.pma.ph/"
       },
       {
          "university": "Philippine Normal University",
          "website": "http://www.pnumanila.com.ph/"
       },
       {
          "university": "Philippine Women's University",
          "website": "http://www.pwu.edu/"
       },
       {
          "university": "Polytechnic University of the Philippines",
          "website": "http://www.pup.edu.ph/"
       },
       {
          "university": "Rizal Technological University",
          "website": "http://www.rtu.edu.ph/"
       },
       {
          "university": "Saint Ferdinand College",
          "website": "http://www.sfc.edu.ph/"
       },
       {
          "university": "Saint Louis College",
          "website": "http://www.slc-sflu.edu.ph/"
       },
       {
          "university": "Saint Louis University",
          "website": "http://www.slu.edu.ph/"
       },
       {
          "university": "Saint Mary's University",
          "website": "http://www.smu.edu.ph/"
       },
       {
          "university": "Saint Paul University",
          "website": "http://www.spu.edu.ph/"
       },
       {
          "university": "Samar State University",
          "website": "http://www.ssu.edu.ph/"
       },
       {
          "university": "San Beda College",
          "website": "http://www.sanbeda.edu.ph/"
       },
       {
          "university": "Silliman University",
          "website": "http://www.su.edu.ph/"
       },
       {
          "university": "Southwestern University",
          "website": "http://www.swu.edu.ph/"
       },
       {
          "university": "Tarlac State University",
          "website": "http://www2.mozcom.com/~tsu-ics/"
       },
       {
          "university": "Technological University of the Philippines",
          "website": "http://www.tup.edu.ph/"
       },
       {
          "university": "University of Asia and the Pacific",
          "website": "http://www.uap.edu.ph/"
       },
       {
          "university": "University of Baguio",
          "website": "http://www.ubaguio.edu/"
       },
       {
          "university": "University of Batangas",
          "website": "http://www.ub.edu.ph/"
       },
       {
          "university": "University of Bohol",
          "website": "http://www.universityofbohol.com/"
       },
       {
          "university": "University of Cagayan Valley",
          "website": "http://www.ucv.edu.ph/"
       },
       {
          "university": "University of Cebu",
          "website": "http://www.universityofcebu.edu.ph/"
       },
       {
          "university": "University of Eastern Philippines",
          "website": "http://www.uep.educ.ph/"
       },
       {
          "university": "University of lloilo",
          "website": "http://www.ui.phinma.edu.ph/"
       },
       {
          "university": "University of Manila",
          "website": "http://www.univman.edu.ph/"
       },
       {
          "university": "University of Mindanao",
          "website": "http://www.umindanao.edu.ph/"
       },
       {
          "university": "University of Negros Occidental-Recoletos",
          "website": "http://www.uno-r.edu.ph/"
       },
       {
          "university": "University of Northeastern Philippines",
          "website": "http://www.unep.edu.ph/"
       },
       {
          "university": "University of Northern Philippines",
          "website": "http://www.unp.edu.ph/"
       },
       {
          "university": "University of Nueva Caceres",
          "website": "http://www.unc.edu.ph/"
       },
       {
          "university": "University of Pangasinan",
          "website": "http://www.upang.edu.ph/"
       },
       {
          "university": "University of Perpetual Help",
          "website": "http://www.uphr.edu.ph/"
       },
       {
          "university": "University of Perpetual Help - System Dalta",
          "website": "http://www.perpetualdalta.edu.ph/"
       },
       {
          "university": "University of Regina Carmeli",
          "website": "http://www.urc.edu.ph/"
       },
       {
          "university": "University of Saint La Salle",
          "website": "http://www.usls.edu/"
       },
       {
          "university": "University of San Agustin",
          "website": "http://www.usa.edu.ph/"
       },
       {
          "university": "University of San Carlos",
          "website": "http://www.usc.edu.ph/"
       },
       {
          "university": "University of San Jose Recoletos",
          "website": "http://www.usjr.edu.ph/"
       },
       {
          "university": "University of Santo Tomas",
          "website": "http://www.ust.edu.ph/"
       },
       {
          "university": "University of Southeastern Philippines",
          "website": "http://www.usep.edu.ph/"
       },
       {
          "university": "University of Southern Mindanao",
          "website": "http://www.usm.edu.ph/"
       },
       {
          "university": "University of Southern Philippines Foundation",
          "website": "http://www.usp.ph/"
       },
       {
          "university": "University of the Assumption",
          "website": "http://www.ua.edu.ph/"
       },
       {
          "university": "University of the City of Manila",
          "website": "http://www.plm.edu.ph/"
       },
       {
          "university": "University of the Cordilleras",
          "website": "http://www.uc-bcf.edu.ph/"
       },
       {
          "university": "University of the East - Coloocan",
          "website": "http://www.ue.edu.ph/caloocan/"
       },
       {
          "university": "University of the East - Manila",
          "website": "http://www.ue.edu.ph/manila/"
       },
       {
          "university": "University of the East - Ramon Magsaysay Memorial Medical Center",
          "website": "http://www.uerm.edu.ph/"
       },
       {
          "university": "University of the Immaculate Conception",
          "website": "http://uic.globe.com.ph/"
       },
       {
          "university": "University of the Philippines Baguio",
          "website": "http://www.upb.edu.ph/"
       },
       {
          "university": "University of the Philippines Diliman",
          "website": "http://www.upd.edu.ph/"
       },
       {
          "university": "University of the Philippines Los Banos",
          "website": "http://www.uplb.edu.ph/"
       },
       {
          "university": "University of the Philippines Manila",
          "website": "http://www.upm.edu.ph/"
       },
       {
          "university": "University of the Philippines Mindanao",
          "website": "http://www.upmin.edu.ph/"
       },
       {
          "university": "University of the Philippines Open University",
          "website": "http://www.upou.org/"
       },
       {
          "university": "University of the Philippines Visayas",
          "website": "http://www.upv.edu.ph/"
       },
       {
          "university": "University of the Philippines Visayas - Cebu High School",
          "website": "http://www.uphighschoolcebu.edu.ph/"
       },
       {
          "university": "University of the Visayas",
          "website": "http://www.uv.edu.ph/"
       },
       {
          "university": "Virgen Milagrosa University Foundation",
          "website": "http://www.vmuf.edu.ph/"
       },
       {
          "university": "Wesleyan University Philippines",
          "website": "http://www.wesleyan.edu.ph/"
       },
       {
          "university": "Western Mindanao State University",
          "website": "http://www.wmsu.edu.ph/"
       },
       {
          "university": "West Visayas State University",
          "website": "http://www.wvsu.edu.ph/"
       },
       {
          "university": "Xavier University",
          "website": "http://www.xu.edu.ph/"
       }
    ],
    "PK": [
       {
          "university": "Abasyn University Peshawar",
          "website": "http://www.abasyn.edu.pk/"
       },
       {
          "university": "Aga Khan University",
          "website": "http://www.aku.edu/"
       },
       {
          "university": "Air University",
          "website": "http://www.au.edu.pk/"
       },
       {
          "university": "Alhamd Islamic University",
          "website": "http://www.aiu.edu.pk/"
       },
       {
          "university": "Al-Khair University",
          "website": "http://www.alkhair.edu.pk/"
       },
       {
          "university": "Allama Iqbal Open University",
          "website": "http://www.aiou.edu.pk/"
       },
       {
          "university": "Alpha Omega University",
          "website": "http://www.alphaomega.edu.pk/"
       },
       {
          "university": "American University of Hawaii - Punjab Campus",
          "website": "http://www.auh.edu.pk/"
       },
       {
          "university": "Arid Agriculture University",
          "website": "http://www.uaar.edu.pk/"
       },
       {
          "university": "Asian Management Institute",
          "website": "http://www.ami.edu.pk/"
       },
       {
          "university": "Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)",
          "website": "http://www.apiit.edu.pk/"
       },
       {
          "university": "Azad Jammu and Kashmir University",
          "website": "http://www.ajku.edu.pk/"
       },
       {
          "university": "Bahauddin Zakariya University - Multan",
          "website": "http://www.bzu.edu.pk/"
       },
       {
          "university": "Bahria University",
          "website": "http://www.bahria.edu.pk/"
       },
       {
          "university": "Balochistan University of Engineering and Technology Khuzdar",
          "website": "http://www.buetk.edu.pk/"
       },
       {
          "university": "Balochistan University of Information Technology & Management Sciences ",
          "website": "http://www.buitms.edu.pk/"
       },
       {
          "university": "Baqai Medical University",
          "website": "http://baqai.freeyellow.com/"
       },
       {
          "university": "Cecos Univeristy of Information Technology",
          "website": "http://www.cecos.edu.pk/"
       },
       {
          "university": "College of Business Management ( CBM )",
          "website": "http://www.cbm.edu.pk/"
       },
       {
          "university": "COMSATS Institute of Information Technology",
          "website": "http://www.ciit.edu.pk/"
       },
       {
          "university": "COMSATS Institute of Information Technology - Abbottabad",
          "website": "http://www.ciit-atd.edu.pk/"
       },
       {
          "university": "COMSATS Institute of Information Technology - Attock",
          "website": "http://www.ciit-attock.edu.pk/"
       },
       {
          "university": "COMSATS Institute of Information Technology - Lahore",
          "website": "http://www.ciitlahore.edu.pk/"
       },
       {
          "university": "COMSATS Institute of Information Technology - Wah",
          "website": "http://www.ciit-wah.edu.pk/"
       },
       {
          "university": "COSMIQ Institute of Technology",
          "website": "http://www.cosmiq.edu.pk/"
       },
       {
          "university": "Dadabhoy Institute of Higher Education",
          "website": "http://www.dadabhoy.edu.pk/"
       },
       {
          "university": "Dawood College of Engineering and Technology",
          "website": "http://www.dcet.edu.pk/"
       },
       {
          "university": "Dow University of Health Sciences",
          "website": "http://www.duhs.edu.pk/"
       },
       {
          "university": "Faisalabad Institute of Textile and Fashion Design",
          "website": "http://www.fitfd.edu.pk/"
       },
       {
          "university": "FAST - National University of Computer and Emerging Sciences (NUCES)",
          "website": "http://www.nu.edu.pk/"
       },
       {
          "university": "Federal Urdu University of Arts Science and Technology",
          "website": "http://www.fuuast.edu.pk/"
       },
       {
          "university": "Gandhara Institute of Medical Sciences",
          "website": "http://www.gandhara.edu.pk/"
       },
       {
          "university": "Gangdara Institute Of Science & Technology",
          "website": "http://www.gist.edu.pk/"
       },
       {
          "university": "GC University",
          "website": "http://www.gcu.edu.pk/"
       },
       {
          "university": "Ghulam Ishaq Khan Institute of Science & Technology",
          "website": "http://www.giki.edu.pk/"
       },
       {
          "university": "Gift University",
          "website": "http://www.gift.edu.pk/"
       },
       {
          "university": "Gomal University",
          "website": "http://www.gu.edu.pk/"
       },
       {
          "university": "Government College University Faisalabad",
          "website": "http://www.gcuf.edu.pk/"
       },
       {
          "university": "Government College University Lahore",
          "website": "http://www.gcu.edu.pk/"
       },
       {
          "university": "Greenwich University",
          "website": "http://www.guic.edu.pk/"
       },
       {
          "university": "Hahnamann Honoeopathic Medical College",
          "website": "http://www.pakistanhomoeopathy.com/"
       },
       {
          "university": "Hajvery University Lahore for Women",
          "website": "http://www.hajvery.edu.pk/"
       },
       {
          "university": "Hamdard University",
          "website": "http://www.hamdard.edu.pk/"
       },
       {
          "university": "Hazara University",
          "website": "http://www.hu.edu.pk/"
       },
       {
          "university": "Hitec University",
          "website": "http://www.hitecuni.edu.pk/"
       },
       {
          "university": "Indus Institute of Higher Education",
          "website": "http://www.indus.edu.pk/"
       },
       {
          "university": "Institute of Business Administration (IBA)",
          "website": "http://www.iba.edu.pk/"
       },
       {
          "university": "Institute of Business Administration Sukkur",
          "website": "http://www.iba-suk.edu.pk/"
       },
       {
          "university": "Institute of Business Management ",
          "website": "http://www.iobm.edu.pk/"
       },
       {
          "university": "Institute of Business & Technology",
          "website": "http://www.biztek.edu.pk/"
       },
       {
          "university": "Institute of Industrial Electronics Engineering",
          "website": "http://www.iiee.edu.pk/"
       },
       {
          "university": "Institute of Management Sciences - Lahore (IMS)",
          "website": "http://www.pakaims.edu.pk/"
       },
       {
          "university": "Institute of Management Sciences - Peshawar",
          "website": "http://www.imsciences.edu.pk/"
       },
       {
          "university": "Institute of Space Technology",
          "website": "http://www.ist.edu.pk/"
       },
       {
          "university": "International Islamic University - Islamabad",
          "website": "http://www.iiu.edu.pk/"
       },
       {
          "university": "Iqra University",
          "website": "http://www.iqra.edu.pk/"
       },
       {
          "university": "Islamia University of Bahawalpur",
          "website": "http://www.iub.edu.pk/"
       },
       {
          "university": "Isra University",
          "website": "http://www.isra.edu.pk/"
       },
       {
          "university": "Jinnah University for Women",
          "website": "http://www.juw.edu.pk/"
       },
       {
          "university": "Karachi Institute Of Economics And Technology",
          "website": "http://www.pafkiet.edu.pk/"
       },
       {
          "university": "Karachi Institute of Information Technology",
          "website": "http://www.kiit.edu.pk/"
       },
       {
          "university": "Karachi Medical and Dental College",
          "website": "http://www.kmdc.edu.pk/"
       },
       {
          "university": "Karachi School of Art",
          "website": "http://www.ksa.edu.pk/"
       },
       {
          "university": "Karakoram International University",
          "website": "http://www.kiu.edu.pk/"
       },
       {
          "university": "KASB Institute of Technology",
          "website": "http://www.kasbit.edu.pk/"
       },
       {
          "university": "Kohat University of Science and Technology  (KUST)",
          "website": "http://www.kust.edu.pk/"
       },
       {
          "university": "Lahore College for Women University",
          "website": "http://www.lcwu.edu.pk/"
       },
       {
          "university": "Lahore University of Management Sciences",
          "website": "http://www.lums.edu.pk/"
       },
       {
          "university": "Liaquat University of Medical & Health Sciences Jamshoro",
          "website": "http://www.lumhs.edu.pk/"
       },
       {
          "university": "Mehran University of Engineering & Technology",
          "website": "http://www.muet.edu.pk/"
       },
       {
          "university": "Minhaj University Lahore",
          "website": "http://www.mul.edu.pk/"
       },
       {
          "university": "Mohammad Ali Jinnah University",
          "website": "http://www.jinnah.edu.pk/"
       },
       {
          "university": "Mohammad Ali Jinnah University - Karachi",
          "website": "http://www.jinnah.edu/"
       },
       {
          "university": "Mohi-ud-Din Islamic University",
          "website": "http://www.miu.edu.pk/"
       },
       {
          "university": "Mooreland University",
          "website": "http://www.moorelanduniversity.com/"
       },
       {
          "university": "National College of Arts",
          "website": "http://www.nca.edu.pk/"
       },
       {
          "university": "National University of Computer & Emerging Sciences",
          "website": "http://www.nu.edu.pk/"
       },
       {
          "university": "National University of Modern Languages",
          "website": "http://www.numl.edu.pk/"
       },
       {
          "university": "National University of Science and Technology",
          "website": "http://www.nust.edu.pk/"
       },
       {
          "university": "NED University of Engineering and Technology Karachi",
          "website": "http://www.neduet.edu.pk/"
       },
       {
          "university": "Newports Institute of Communication & Economics",
          "website": "http://www.newports.edu.pk/"
       },
       {
          "university": "North West Frontier Province Agricultural University",
          "website": "http://www.aup.edu.pk/"
       },
       {
          "university": "Nuclear Institute for Agriculture and Biology (NIAB)",
          "website": "http://www.niab.org.pk/"
       },
       {
          "university": "Pak-AIMS (American Institute of Management Sciences)",
          "website": "http://www.pakaims.edu/"
       },
       {
          "university": "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
          "website": "http://www.pieas.edu.pk/"
       },
       {
          "university": "Pakistan Virtual University",
          "website": "http://www.vu.edu.pk/"
       },
       {
          "university": "Planwel University",
          "website": "http://www.planwel.edu/"
       },
       {
          "university": "Preston Institute of Management Sciences and Technology (PIMSAT)",
          "website": "http://www.pimsat-khi.edu.pk/"
       },
       {
          "university": "Preston University - Pakistan Campus",
          "website": "http://www.prestonpak.edu.pk/"
       },
       {
          "university": "Punjab Institute of Computer science",
          "website": "http://www.pics.edu.pk/"
       },
       {
          "university": "Punjab University College of Information Tecnology",
          "website": "http://www.pucit.edu.pk/"
       },
       {
          "university": "Pyramid Education Center",
          "website": "http://www.pedu.edu.pk/"
       },
       {
          "university": "Qauid-e-Awam University of Engineering Sciences & Technology",
          "website": "http://www.panasia.org.sg/tcdc/pakistan/qauid_sindh/index.html"
       },
       {
          "university": "Quaid-i-Azam University",
          "website": "http://www.qau.edu.pk/"
       },
       {
          "university": "Qurtuba University of Science and Infromation Technology",
          "website": "http://www.qurtuba.edu.pk/"
       },
       {
          "university": "Riphah International Univeristy",
          "website": "http://www.riphah.edu.pk/"
       },
       {
          "university": "Sarhad University of Science & Information Technology - Peshawar ",
          "website": "http://www.suit.edu.pk/"
       },
       {
          "university": "Shah Abdul Latif University Khairpur",
          "website": "http://www.salu.edu.pk/"
       },
       {
          "university": "Shaheed Benazir Bhutto Women University ",
          "website": "http://www.fwu.edu.pk/"
       },
       {
          "university": "Shaheed Zulifkar Ali Bhutto Institute of Science and Technology",
          "website": "http://www.szabist.edu.pk/"
       },
       {
          "university": "Sindh Agricultural University",
          "website": "http://www.sau.edu.pk/"
       },
       {
          "university": "Sindh Madressatul Islam University",
          "website": "http://www.smiu.edu.pk/"
       },
       {
          "university": "Sir Syed Institute Of Technology Islamabad",
          "website": "http://www.ssms.edu.pk/"
       },
       {
          "university": "Sir Syed University of Engineering and Technology",
          "website": "http://www.ssuet.edu.pk/"
       },
       {
          "university": "Textile Institute of Pakistan",
          "website": "http://www.tip.edu.pk/"
       },
       {
          "university": "The Global College Lahore",
          "website": "http://www.global.edu.pk/"
       },
       {
          "university": "The Government Sadiq College Women University Bahawalpur",
          "website": "http://www.gscwu.edu.pk/"
       },
       {
          "university": "The Superior College ",
          "website": "http://www.superior.edu.pk/"
       },
       {
          "university": "University of Agriculture Faisalabad",
          "website": "http://www.uaf.edu.pk/"
       },
       {
          "university": "University of Balochistan",
          "website": "http://www.uob.edu.pk/"
       },
       {
          "university": "University of Central Punjab",
          "website": "http://www.ucp.edu.pk/"
       },
       {
          "university": "University of East",
          "website": "http://www.uoe.edu.pk/"
       },
       {
          "university": "University of Education",
          "website": "http://www.ue.edu.pk/"
       },
       {
          "university": "University of Engineering and Technology Lahore",
          "website": "http://www.uet.edu/"
       },
       {
          "university": "University of Engineering and Technology Peshawar",
          "website": "http://www.nwfpuet.edu.pk/"
       },
       {
          "university": "University of Engineering and Technology Taxila",
          "website": "http://www.uettaxila.edu.pk/"
       },
       {
          "university": "University of Faisalabad",
          "website": "http://www.tuf.edu.pk/"
       },
       {
          "university": "University of Gujrat",
          "website": "http://www.uog.edu.pk/"
       },
       {
          "university": "University of Islamic Studies",
          "website": "http://www.aldirasat.edu.pk/"
       },
       {
          "university": "University of Karachi",
          "website": "http://www.uok.edu.pk/"
       },
       {
          "university": "University of Lahore",
          "website": "http://www.uol.edu.pk/"
       },
       {
          "university": "University of Malakand",
          "website": "http://www.uom.edu.pk/"
       },
       {
          "university": "University of Management and Technology",
          "website": "http://www.umt.edu.pk/"
       },
       {
          "university": "University of Munawwar-ul-Islam",
          "website": "http://www.umi.edu.pk/"
       },
       {
          "university": "University of NorthWest",
          "website": "http://www.unw.edu.pk/"
       },
       {
          "university": "University of Peshawar",
          "website": "http://www.upesh.edu/"
       },
       {
          "university": "University of Sargodha",
          "website": "http://www.uos.edu.pk/"
       },
       {
          "university": "University of Science & Technology Bannu",
          "website": "http://www.ustb.edu.pk/"
       },
       {
          "university": "University of Sindh",
          "website": "http://www.usindh.edu.pk/"
       },
       {
          "university": "University of South Asia",
          "website": "http://www.usa.edu.pk/"
       },
       {
          "university": "University of the Punjab",
          "website": "http://www.pu.edu.pk/"
       },
       {
          "university": "University of the Punjab - Gujranwala Campus",
          "website": "http://www.pugc.edu.pk/"
       },
       {
          "university": "University of Veterinary & Animal Science",
          "website": "http://www.uvas.edu.pk/"
       },
       {
          "university": "University of Wah",
          "website": "http://www.uw.edu.pk/"
       },
       {
          "university": "Virtual University of Pakistan",
          "website": "http://www.vu.edu.pk/"
       },
       {
          "university": "Wah Medical College",
          "website": "http://www.wahmedicalcollege.edu.pk/"
       },
       {
          "university": "Zawiya Academy",
          "website": "http://www.zawiya.edu.pk/"
       },
       {
          "university": "Zia-ud-Din Medical University",
          "website": "http://www.geocities.com/CollegePark/7329/"
       }
    ],
    "PL": [
       {
          "university": "Academy of Economics in Cracow",
          "website": "http://www.ae.krakow.pl/"
       },
       {
          "university": "Academy of Economics in Katowice",
          "website": "http://www.ae.katowice.pl/"
       },
       {
          "university": "Academy of Economics in Poznan",
          "website": "http://www.ae.poznan.pl/"
       },
       {
          "university": "Academy of Economics in Wroclaw",
          "website": "http://www.ae.wroc.pl/"
       },
       {
          "university": "Academy of Humanities and Economics in Lodz",
          "website": "http://www.ahe.edu.pl/"
       },
       {
          "university": "Academy of International Economic and Political Relations - Gdynia",
          "website": "http://www.wsms.edu.pl/"
       },
       {
          "university": "Adam Mickiewicz University of Poznan",
          "website": "http://www.amu.edu.pl/"
       },
       {
          "university": "AGH University of Science and Technology",
          "website": "http://www.agh.edu.pl/"
       },
       {
          "university": "Agricultural-Technical Academy in Olsztyn",
          "website": "http://www.art.olsztyn.pl/"
       },
       {
          "university": "Agricultural University of Cracow",
          "website": "http://www.ar.krakow.pl/"
       },
       {
          "university": "Agricultural University of Lublin",
          "website": "http://www.ar.lublin.pl/"
       },
       {
          "university": "Agricultural University of Poznan",
          "website": "http://www.au.poznan.pl/"
       },
       {
          "university": "Agricultural University of Szczecin",
          "website": "http://www.ar.szczecin.pl/"
       },
       {
          "university": "Agricultural University of Warsaw",
          "website": "http://www.sggw.waw.pl/"
       },
       {
          "university": "Agricultural University of Wroclaw",
          "website": "http://www.ar.wroc.pl/"
       },
       {
          "university": "Akademia Podlaska",
          "website": "http://www.ap.siedlce.pl/"
       },
       {
          "university": "Aleksander Gieysztor School of Humanities in Pultusk",
          "website": "http://www.wsh.edu.pl/"
       },
       {
          "university": "Aleksander Zelwerowicz State Theatre Academy",
          "website": "http://www.at.edu.pl/"
       },
       {
          "university": "Andrzej Frycz Modrzewski Cracow College",
          "website": "http://www.ksw.edu.pl/"
       },
       {
          "university": "Cardinal Stefan Wyszynski University in Warsaw",
          "website": "http://www.uksw.edu.pl/"
       },
       {
          "university": "Catholic University of Lublin",
          "website": "http://www.kul.lublin.pl/"
       },
       {
          "university": "Christian Theological Academy in Warszaw",
          "website": "http://www.qdnet.pl/ChAT/"
       },
       {
          "university": "College of Computer Science in Lodz",
          "website": "http://www.wsinf.edu.pl/"
       },
       {
          "university": "Collegium Civitas",
          "website": "http://www.collegium.edu.pl/"
       },
       {
          "university": "European Academy of Arts in Warsaw",
          "website": "http://www.eas.edu.pl/"
       },
       {
          "university": "Fine Arts Academy in Gdansk",
          "website": "http://www.asp.gda.pl/"
       },
       {
          "university": "Fine Arts Academy in Katowice",
          "website": "http://www.aspkat.edu.pl/"
       },
       {
          "university": "Fine Arts Academy in Poznan",
          "website": "http://www.asp.poznan.pl/"
       },
       {
          "university": "Fine Arts Academy in Warsaw",
          "website": "http://www.asp.waw.pl/"
       },
       {
          "university": "Fine Arts Academy in Wroclaw",
          "website": "http://www.asp.wroc.pl/"
       },
       {
          "university": "Fine Arts Academy Jan Matejko in Cracow",
          "website": "http://149.156.19.10/"
       },
       {
          "university": "Fine Arts Academy Wladyslaw Strzeminski in Lodz",
          "website": "http://www.asp.lodz.pl/"
       },
       {
          "university": "French Institute of Management",
          "website": "http://www.ifg.com.pl/"
       },
       {
          "university": "Gdansk Management College",
          "website": "http://www.wsz.pl/"
       },
       {
          "university": "Gdynia Maritime Academy",
          "website": "http://www.wsm.gdynia.pl/"
       },
       {
          "university": "Higher School o Business in Tarnow",
          "website": "http://wsb.toi.tarnow.pl/"
       },
       {
          "university": "Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz",
          "website": "http://www.wsb-nlu.edu.pl/"
       },
       {
          "university": "Jagiellonian University Cracow",
          "website": "http://www.uj.edu.pl/"
       },
       {
          "university": "Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala",
          "website": "http://www.tyszkiewicz.edu.pl/"
       },
       {
          "university": "Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala",
          "website": "http://www.tyszkiewicz.edu.pl/"
       },
       {
          "university": "Katowice School of Economics",
          "website": "http://www.gwsh.pl/"
       },
       {
          "university": "Lazarski School of Commerce and Law",
          "website": "http://www.lazarski.pl/"
       },
       {
          "university": "Leon Kozminski Academy of Entrepreneurship and Mangement",
          "website": "http://www.wspiz.edu.pl/"
       },
       {
          "university": "Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw",
          "website": "http://www.wspiz.edu.pl/"
       },
       {
          "university": "Ludwik Solski State Academy of Theatre in Cracow",
          "website": "http://www.pwst.krakow.pl/"
       },
       {
          "university": "Maria Curie-Sklodowska University Lublin",
          "website": "http://www.umcs.lublin.pl/"
       },
       {
          "university": "Maria Grzegorzewska College for Special Education",
          "website": "http://www.wsps.waw.pl/"
       },
       {
          "university": "Maritime University in Szczecin",
          "website": "http://www.wsm.szczecin.pl/"
       },
       {
          "university": "Medical Academy in Bialystok",
          "website": "http://www.amb.edu.pl/"
       },
       {
          "university": "Medical Academy in Gdansk",
          "website": "http://www.amg.gda.pl/"
       },
       {
          "university": "Medical Academy in Lodz",
          "website": "http://www.am.lodz.pl/"
       },
       {
          "university": "Medical Academy in Lublin",
          "website": "http://www.am.lublin.pl/"
       },
       {
          "university": "Medical Academy in Warszawa",
          "website": "http://www.amwaw.edu.pl/"
       },
       {
          "university": "Medical Academy in Wroclaw",
          "website": "http://www.am.wroc.pl/"
       },
       {
          "university": "Medical Academy Karol Marcinkowski in Poznan",
          "website": "http://www.usoms.poznan.pl/"
       },
       {
          "university": "Medical Academy Ludwik Rydygier in Bydgoszcz",
          "website": "http://www.amb.bydgoszcz.pl/"
       },
       {
          "university": "Music Academy Felix Nowowiejski in Bydgoszcz",
          "website": "http://www.amuz.bydgoszcz.pl/"
       },
       {
          "university": "Music Academy Fryderyk Chopin in Warszaw",
          "website": "http://www.chopin.edu.pl/"
       },
       {
          "university": "Music Academy Ignacy Jana Paderewski in Poznan",
          "website": "http://www.amuz.poznan.pl/"
       },
       {
          "university": "Music Academy in Cracow",
          "website": "http://www.amuz.krakow.pl/"
       },
       {
          "university": "Music Academy in Lodz",
          "website": "http://www.amuz.lodz.pl/"
       },
       {
          "university": "Music Academy Karol Lipinski in Wroclaw",
          "website": "http://www.amuz.wroc.pl/"
       },
       {
          "university": "Music Academy Karol Szymanowski in Katowice",
          "website": "http://www.am.katowice.pl/"
       },
       {
          "university": "Music Academy Stanislaw Moniuszko in Gdansk",
          "website": "http://www.amuz.gda.pl/"
       },
       {
          "university": "Nicolaus Copernicus University of Torun",
          "website": "http://www.uni.torun.pl/"
       },
       {
          "university": "Pawel Wlodkowic University College in Plock",
          "website": "http://www.wlodkowic.pl/"
       },
       {
          "university": "Pedagogical University of Bydgoszcz",
          "website": "http://www.wsp.bydgoszcz.pl/"
       },
       {
          "university": "Pedagogical University of Czestochowa",
          "website": "http://www.wsp.czest.pl/"
       },
       {
          "university": "Pedagogical University of Kielce",
          "website": "http://www.pu.kielce.pl/"
       },
       {
          "university": "Pedagogical University of Krakow",
          "website": "http://www.wsp.krakow.pl/"
       },
       {
          "university": "Pedagogical University of Rzeszow",
          "website": "http://www.univ.rzeszow.pl/"
       },
       {
          "university": "Pedagogical University of Slupsk",
          "website": "http://www.wsp.slupsk.pl/"
       },
       {
          "university": "Pedagogical University of the Polish Association for Adult Education in Warsaw",
          "website": "http://www.wsptwpwaw.edu.pl/"
       },
       {
          "university": "Pedagogical University of Zielona Gora",
          "website": "http://www.wsp.zgora.pl/"
       },
       {
          "university": "Physical Education Academy Eugeniusz Piasecki in Poznan",
          "website": "http://www.awf.poznan.pl/"
       },
       {
          "university": "Physical Education Academy in Katowice",
          "website": "http://www.awf.katowice.pl/"
       },
       {
          "university": "Physical Education Academy in Wroclaw",
          "website": "http://www.awf.wroc.pl/"
       },
       {
          "university": "Physical Education Academy Jedrzej Sniadecki in Gdansk",
          "website": "http://www.awf.gda.pl/"
       },
       {
          "university": "Physical Education Academy Jozef Pilsudski in Warsaw",
          "website": "http://www.awf.edu.pl/"
       },
       {
          "university": "Polish-Japanese Institute of Information Technology in Warsaw",
          "website": "http://www.pjwstk.edu.pl/"
       },
       {
          "university": "Polish Open University in Warsaw (WSZ/POU)",
          "website": "http://www.wsz-pou.edu.pl/"
       },
       {
          "university": "Polonia University in Czêstochowa",
          "website": "http://www.ap.edu.pl/"
       },
       {
          "university": "Pomeranian Academy of Medicine in Szczecin",
          "website": "http://www.pam.szczecin.pl/"
       },
       {
          "university": "Poznan School of Banking",
          "website": "http://www.wsb.poznan.pl/"
       },
       {
          "university": "Poznan University of Life Sciences",
          "website": "http://www.puls.edu.pl/"
       },
       {
          "university": "Ryszard Lazarski University of Commerce and Law in Warsaw",
          "website": "http://www.wship.edu.pl/"
       },
       {
          "university": "School of Banking and Management in Cracow",
          "website": "http://www.wszib.krakow.pl/"
       },
       {
          "university": "Silesian Academy of Medicine in Katowice",
          "website": "http://www.slam.katowice.pl/"
       },
       {
          "university": "Silesian School of Economics and Languages",
          "website": "http://www.gallus.pl/"
       },
       {
          "university": "Silesian Technical University of Gliwice",
          "website": "http://www.polsl.gliwice.pl/"
       },
       {
          "university": "Staszic Pila College",
          "website": "http://www.pwsz.pila.pl/"
       },
       {
          "university": "State Film - Television and Theatre Higher School Leon Schiller in Lodz",
          "website": "http://www.filmowka.lodz.pl/"
       },
       {
          "university": "Teacher Training College of Revalidation - Rehabilitation and Physical Education",
          "website": "http://www.nkrriwf.pl/"
       },
       {
          "university": "Technical University of Bialystok",
          "website": "http://www.pb.bialystok.pl/"
       },
       {
          "university": "Technical University of Cracow",
          "website": "http://www.pk.edu.pl/"
       },
       {
          "university": "Technical University of Czestochowa",
          "website": "http://www.pcz.czest.pl/"
       },
       {
          "university": "Technical University of Gdansk",
          "website": "http://www.pg.gda.pl/"
       },
       {
          "university": "Technical University of Kielce",
          "website": "http://www.tu.kielce.pl/"
       },
       {
          "university": "Technical University of Koszalin",
          "website": "http://www.tu.koszalin.pl/"
       },
       {
          "university": "Technical University of Lodz",
          "website": "http://www.p.lodz.pl/"
       },
       {
          "university": "Technical University of Lublin",
          "website": "http://www.pol.lublin.pl/"
       },
       {
          "university": "Technical University of Opole",
          "website": "http://www.po.opole.pl/"
       },
       {
          "university": "Technical University of Poznan",
          "website": "http://www.put.poznan.pl/"
       },
       {
          "university": "Technical University of Radom",
          "website": "http://www.man.radom.pl/"
       },
       {
          "university": "Technical University of Rzeszow",
          "website": "http://www.prz.rzeszow.pl/"
       },
       {
          "university": "Technical University of Szczecin",
          "website": "http://www.tuniv.szczecin.pl/"
       },
       {
          "university": "Technical University of Warsaw",
          "website": "http://www.pw.edu.pl/"
       },
       {
          "university": "Technical University of Wroclaw",
          "website": "http://www.pwr.wroc.pl/"
       },
       {
          "university": "Technical University of Zielona Gora",
          "website": "http://www.pz.zgora.pl/"
       },
       {
          "university": "University of Bialystok",
          "website": "http://www.ub.edu.pl/"
       },
       {
          "university": "University of Finance and Management in Bialystok",
          "website": "http://www.wsfiz.edu.pl/"
       },
       {
          "university": "University of Gdansk",
          "website": "http://www.univ.gda.pl/"
       },
       {
          "university": "University of Information Technology and Management in Rzeszow",
          "website": "http://www.wsiz.rzeszow.pl/"
       },
       {
          "university": "University of Insurance and Banking in Warsaw",
          "website": "http://www.wsub.waw.pl/"
       },
       {
          "university": "University of Lodz",
          "website": "http://www.uni.lodz.pl/"
       },
       {
          "university": "University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.",
          "website": "http://www.dswe.wroc.pl/"
       },
       {
          "university": "University of Management and Marketing",
          "website": "http://www.wszim.edu.pl/"
       },
       {
          "university": "University of Opole",
          "website": "http://www.uni.opole.pl/"
       },
       {
          "university": "University of Silesia",
          "website": "http://www.us.edu.pl/"
       },
       {
          "university": "University of Szczecin",
          "website": "http://www.univ.szczecin.pl/"
       },
       {
          "university": "University of Technology and Life Sciences",
          "website": "http://www.utp.edu.pl/"
       },
       {
          "university": "University of Wamia and Masuria in Olsztyn",
          "website": "http://www.uwm.edu.pl/"
       },
       {
          "university": "University of Warsaw",
          "website": "http://www.uw.edu.pl/"
       },
       {
          "university": "University of Wroclaw",
          "website": "http://www.uni.wroc.pl/"
       },
       {
          "university": "University School of Physical Education",
          "website": "http://www.awf.krakow.pl/"
       },
       {
          "university": "Walcz College",
          "website": "http://www.pwsz.eu/"
       },
       {
          "university": "Warsaw School of Economics",
          "website": "http://www.sgh.waw.pl/"
       },
       {
          "university": "Warsaw School of Information Technology",
          "website": "http://www.wsisiz.edu.pl/"
       },
       {
          "university": "Warsaw School of Social Psychology",
          "website": "http://www.swps.edu.pl/"
       },
       {
          "university": "West Pomeranian Business School in Szczecin",
          "website": "http://www.zpsb.szczecin.pl/"
       },
       {
          "university": "Zachodniopomorska School of Science and Engineering",
          "website": "http://www.zut.edu.pl/"
       }
    ],
    "PR": [
       {
          "university": "Caribbean University",
          "website": "http://www.caribbean.edu/"
       },
       {
          "university": "Centro de Estudios Avanzados de Puerto Rico y el Caribe",
          "website": "http://www.ceaprc.org/"
       },
       {
          "university": "Pontificia Universidad Catolica de Puerto Rico",
          "website": "http://www.pucpr.edu/"
       },
       {
          "university": "San Juan Bautista School of Medicine",
          "website": "http://www.sanjuanbautista.edu/"
       },
       {
          "university": "Sistema Universitario Ana G. Méndez",
          "website": "http://www.suagm.edu/"
       },
       {
          "university": "Universidad Central de Bayamón",
          "website": "http://www.ucb.edu.pr/"
       },
       {
          "university": "Universidad Central del Caribe",
          "website": "http://www.uccaribe.edu/"
       },
       {
          "university": "Universidad del Sagrado Corazon",
          "website": "http://www.sagrado.edu/"
       },
       {
          "university": "Universidad del Turabo",
          "website": "http://www.ut.pr/"
       },
       {
          "university": "Universidad de Puerto Rico",
          "website": "http://www.upr.clu.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Aguadilla",
          "website": "http://www.uprag.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Arecibo",
          "website": "http://www.upra.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Bayamon",
          "website": "http://www.uprb.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Carolina",
          "website": "http://www.uprc.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Cayey",
          "website": "http://www.cayey.upr.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Ciencias Medicas",
          "website": "http://www.rcm.upr.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Humacao",
          "website": "http://cuhwww.upr.clu.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Mayaguez",
          "website": "http://www.uprm.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Ponce",
          "website": "http://upr-ponce.upr.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Rio Pedras",
          "website": "http://www.uprrp.edu/"
       },
       {
          "university": "Universidad de Puerto Rico - Utuado",
          "website": "http://www.uprutuado.edu/"
       },
       {
          "university": "Universidad Interamericana de Puerto Rico",
          "website": "http://www.inter.edu/"
       },
       {
          "university": "Universidad Interamericana de Puerto Rico - Bayamón",
          "website": "http://www.bc.inter.edu/"
       },
       {
          "university": "Universidad Interamericana de Puerto Rico - San Germán",
          "website": "http://www.sg.inter.edu/"
       },
       {
          "university": "Universidad Metropolitana",
          "website": "http://www.suagm.edu/suagm/umet/"
       },
       {
          "university": "Universidad Politecnica de Puerto Rico",
          "website": "http://www.pupr.edu/"
       }
    ],
    "PS": [
       {
          "university": "Al-Aqsa University",
          "website": "http://www.alaqsa.edu.ps/"
       },
       {
          "university": "Al-Azhar University of Gaza",
          "website": "http://www.alazhar.edu.ps/"
       },
       {
          "university": "Al-Quds Open University",
          "website": "http://www.qou.edu/"
       },
       {
          "university": "Al-Quds University - The Arab University in Jerusalem",
          "website": "http://www.alquds.edu/"
       },
       {
          "university": "An-Najah National University",
          "website": "http://www.najah.edu/"
       },
       {
          "university": "Arab American University - Jenin",
          "website": "http://www.aauj.edu/"
       },
       {
          "university": "Bethlehem University",
          "website": "http://www.bethlehem.edu/"
       },
       {
          "university": "Birzeit University",
          "website": "http://www.birzeit.edu/"
       },
       {
          "university": "Hebron University",
          "website": "http://www.hebron.edu/"
       },
       {
          "university": "Islamic University of Gaza",
          "website": "http://www.iugaza.edu.ps/"
       },
       {
          "university": "Palestine Polytechnic University",
          "website": "http://www.ppu.edu/"
       },
       {
          "university": "Palestine Technical College-Dier Elbalah",
          "website": "http://www.ptcdb.edu.ps/"
       },
       {
          "university": "Palestine Technical University - Kadoorie",
          "website": "http://www.ptuk.edu.ps/"
       },
       {
          "university": "Ummah University",
          "website": "http://www.uou.edu.ps/"
       },
       {
          "university": "University College of Applied Sciences",
          "website": "http://www.ucas.edu.ps/"
       },
       {
          "university": "University of Palestine",
          "website": "http://www.up.edu.ps/"
       }
    ],
    "PT": [
       {
          "university": "Academia Nacional Superior de Orquesta",
          "website": "http://www.oml.pt/anso/"
       },
       {
          "university": "Escola Nautica Infante D. Henrique",
          "website": "http://www.enautica.pt/"
       },
       {
          "university": "Escola Superior de Artes e Design",
          "website": "http://www.esad.pt/"
       },
       {
          "university": "Escola Superior de Hotelaria e Turismo do Estoril",
          "website": "http://www.eshte.pt/"
       },
       {
          "university": "European University Portugal",
          "website": "http://www.europeanuniversity.pt/"
       },
       {
          "university": "Instituto de Artes Visuais - Design e Marketing - IADE",
          "website": "http://www.iade.pt/"
       },
       {
          "university": "Instituto Piaget",
          "website": "http://www.ipiaget.org/"
       },
       {
          "university": "Instituto Politécnico da Guarda",
          "website": "http://www.ipg.pt/"
       },
       {
          "university": "Instituto Politécnico de Beja",
          "website": "http://www.ipbeja.pt/"
       },
       {
          "university": "Instituto Politécnico de Bragança",
          "website": "http://www.ipb.pt/"
       },
       {
          "university": "Instituto Politécnico de Castelo Branco",
          "website": "http://www.ipcb.pt/"
       },
       {
          "university": "Instituto Politécnico de Coimbra",
          "website": "http://www.ipc.pt/"
       },
       {
          "university": "Instituto Politécnico de Leiria",
          "website": "http://www.iplei.pt/"
       },
       {
          "university": "Instituto Politécnico de Lisboa",
          "website": "http://www.ipl.pt/"
       },
       {
          "university": "Instituto Politécnico de Portalegre",
          "website": "http://www.ipportalegre.pt/"
       },
       {
          "university": "Instituto Politécnico de Santarém",
          "website": "http://www.ipsantarem.pt/"
       },
       {
          "university": "Instituto Politécnico de Setúbal",
          "website": "http://www.ips.pt/"
       },
       {
          "university": "Instituto Politécnico de Tomar",
          "website": "http://www.ipt.pt/"
       },
       {
          "university": "Instituto Politécnico de Viana do Castelo",
          "website": "http://www.ipvc.pt/"
       },
       {
          "university": "Instituto Politécnico do Cávado e do Ave",
          "website": "http://www.ipca.pt/"
       },
       {
          "university": "Instituto Politecnico do Porto",
          "website": "http://www.ipp.pt/"
       },
       {
          "university": "Instituto Superior Bissaya Barreto",
          "website": "http://www.isbb.pt/"
       },
       {
          "university": "Instituto Superior D. Afonso III - INUAF",
          "website": "http://www.inuaf-studia.pt/"
       },
       {
          "university": "Instituto Superior de Assistentes e Intérpretes",
          "website": "http://www.isai.pt/"
       },
       {
          "university": "Instituto Superior de Ciências do Trabalho e da Empresa",
          "website": "http://www.iscte.pt/"
       },
       {
          "university": "Instituto Superior de Ciências Educativas",
          "website": "http://www.pedago.pt/isce.htm"
       },
       {
          "university": "Instituto Superior de Ciências Empresariais e de Turismo",
          "website": "http://www.iscet.pt/"
       },
       {
          "university": "Instituto Superior de Entre Douro e Vouga",
          "website": "http://www.isvouga.com/"
       },
       {
          "university": "Instituto Superior de Gestão - ISG",
          "website": "http://www.isg.pt/"
       },
       {
          "university": "Instituto Superior de Informática e Gestão",
          "website": "http://www.cocite.pt/"
       },
       {
          "university": "Instituto Superior de Línguas e Administração",
          "website": "http://www.isla.pt/"
       },
       {
          "university": "Instituto Superior de Paços de Brandão",
          "website": "http://www.ispab.pt/"
       },
       {
          "university": "Instituto Superior de Psicologia Aplicada",
          "website": "http://www.ispa.pt/"
       },
       {
          "university": "Instituto Superior de Saúde do Alto Ave",
          "website": "http://www.isave.edu.pt/"
       },
       {
          "university": "Instituto Superior de Tecnologias Avançadas - ISTEC",
          "website": "http://www.istec.pt/"
       },
       {
          "university": "Instituto Superior de Transportes e Comunicações",
          "website": "http://www.istp.pt/"
       },
       {
          "university": "Instituto Superior Novas Profissões - INP",
          "website": "http://www.inp.pt/"
       },
       {
          "university": "Instituto Superior Politécnico Gaya",
          "website": "http://www.ispgaya.pt/"
       },
       {
          "university": "Universidade Aberta Lisboa",
          "website": "http://www.univ-ab.pt/"
       },
       {
          "university": "Universidade Atlântica",
          "website": "http://www.uatla.pt/"
       },
       {
          "university": "Universidade Autónoma de Lisboa Luís de Camoes",
          "website": "http://www.universidade-autonoma.pt/"
       },
       {
          "university": "Universidade Catolica Portuguesa",
          "website": "http://www.ucp.pt/"
       },
       {
          "university": "Universidade da Beira Interior",
          "website": "http://www.ubi.pt/"
       },
       {
          "university": "Universidade da Madeira",
          "website": "http://www.uma.pt/"
       },
       {
          "university": "Universidade de Aveiro",
          "website": "http://www.ua.pt/"
       },
       {
          "university": "Universidade de Coimbra",
          "website": "http://www.uc.pt/"
       },
       {
          "university": "Universidade de Evora",
          "website": "http://www.uevora.pt/"
       },
       {
          "university": "Universidade de Lisboa",
          "website": "http://www.ul.pt/"
       },
       {
          "university": "Universidade de Tras-os-Montes e Alto Douro",
          "website": "http://www.utad.pt/"
       },
       {
          "university": "Universidade do Algarve",
          "website": "http://www.ualg.pt/"
       },
       {
          "university": "Universidade do Minho",
          "website": "http://www.uminho.pt/"
       },
       {
          "university": "Universidade do Porto",
          "website": "http://www.up.pt/"
       },
       {
          "university": "Universidade dos Acores",
          "website": "http://www.uac.pt/"
       },
       {
          "university": "Universidade Fernando Pessoa",
          "website": "http://www.ufp.pt/"
       },
       {
          "university": "Universidade Independente",
          "website": "http://www.uni.pt/"
       },
       {
          "university": "Universidade Internacional Lisboa",
          "website": "http://www.uinternacional.pt/"
       },
       {
          "university": "Universidade Lusiada",
          "website": "http://www.ulusiada.pt/"
       },
       {
          "university": "Universidade Lusófona de Humanidades e Technologias",
          "website": "http://www.ulusofona.pt/"
       },
       {
          "university": "Universidade Moderna de Lisboa",
          "website": "http://www.umoderna.pt/"
       },
       {
          "university": "Universidade Nova de Lisboa",
          "website": "http://www.unl.pt/"
       },
       {
          "university": "Universidade Portucalense Infante D. Henrique",
          "website": "http://www.uportu.pt/"
       },
       {
          "university": "Universidade Tecnica de Lisboa",
          "website": "http://www.utl.pt/"
       }
    ],
    "PY": [
       {
          "university": "Universidad Americana",
          "website": "http://www.uamericana.edu.py/"
       },
       {
          "university": "Universidad Autónoma de Asunción",
          "website": "http://www.uaa.edu.py/"
       },
       {
          "university": "Universidad Autónoma del Paraguay",
          "website": "http://www.uap.edu.py/"
       },
       {
          "university": "Universidad Católica Nuestra Senora de la Asunción",
          "website": "http://www.uc.edu.py/"
       },
       {
          "university": "Universidad Columbia del Paraguay",
          "website": "http://www.columbia.edu.py/"
       },
       {
          "university": "Universidad Comunera",
          "website": "http://www.ucom.edu.py/"
       },
       {
          "university": "Universidad de la Integración de las Americas (UNIDAD)",
          "website": "http://www.unida.edu.py/"
       },
       {
          "university": "Universidad del Cono Sur de las Américas",
          "website": "http://www.ucsa.edu.py/"
       },
       {
          "university": "Universidad del Norte",
          "website": "http://www.uninorte.edu.py/"
       },
       {
          "university": "Universidad Metropolitana de Asunción",
          "website": "http://www.uma.edu.py/"
       },
       {
          "university": "Universidad Nacional de Asunción",
          "website": "http://www.una.py/"
       },
       {
          "university": "Universidad Nacional del Este",
          "website": "http://www.une.edu.py/"
       },
       {
          "university": "Universidad Privada del Este",
          "website": "http://www.upe.edu.py/"
       }
    ],
    "QA": [
       {
          "university": "Northwestern University In Qatar",
          "website": "http://www.qatar.northwestern.edu/"
       },
       {
          "university": "University of Qatar",
          "website": "http://www.qu.edu.qa/"
       }
    ],
    "RE": [
       {
          "university": "Université de la Reunion",
          "website": "http://www.univ-reunion.fr/"
       }
    ],
    "RO": [
       {
          "university": "1 December University of Alba Iulia",
          "website": "http://www.uab.ro/"
       },
       {
          "university": "Academia de Studii Economice din Bucuresti",
          "website": "http://www.ase.ro/"
       },
       {
          "university": "Academia Tehnica Militara",
          "website": "http://www.mta.ro/"
       },
       {
          "university": "Academy of Arts George Enescu Iasi",
          "website": "http://www.arteiasi.ro/"
       },
       {
          "university": "Academy of Music Georghe Dima Cluj-Napoca",
          "website": "http://www.amgd.eu/"
       },
       {
          "university": "AISTEDA",
          "website": "http://www.aistedaab.ro/"
       },
       {
          "university": "Babes-Bolyai University of Cluj-Napoca",
          "website": "http://www.ubbcluj.ro/"
       },
       {
          "university": "Banat's University of Agricultural Sciences",
          "website": "http://www.usab-tm.ro/"
       },
       {
          "university": "Constantin Brancoveanu University Pitesti",
          "website": "http://www.univcb.ro/"
       },
       {
          "university": "Emanuel University",
          "website": "http://www.emanuel.ro/"
       },
       {
          "university": "Institute of Architecture Ion Mincu Bucharest",
          "website": "http://www.iaim.ro/"
       },
       {
          "university": "Maritime University Constanta",
          "website": "http://www.universitateamaritima.ro/"
       },
       {
          "university": "National Academy for Physical Education and Sports Bucharest",
          "website": "http://www.anefs-edu.ro/"
       },
       {
          "university": "National School of Political and Administrative Studies Bucharest",
          "website": "http://www.snspa.ro/"
       },
       {
          "university": "National University of Arts",
          "website": "http://www.unarte.ro/"
       },
       {
          "university": "National University of Music",
          "website": "http://www.unmb.ro/"
       },
       {
          "university": "National University of Theater and Film Arts",
          "website": "http://www.unatc.ro/"
       },
       {
          "university": "North University of Baia Mare",
          "website": "http://www.ubm.ro/"
       },
       {
          "university": "Oradea University",
          "website": "http://www.oradeauniversity.com"
       },
       {
          "university": "Petru Maior University of Targu Mures",
          "website": "http://www.upm.ro/"
       },
       {
          "university": "Romanian-American University",
          "website": "http://www.rau.ro/"
       },
       {
          "university": "Spiru Haret University",
          "website": "http://www.spiruharet.ro/"
       },
       {
          "university": "Targu-Mures University of Theatre",
          "website": "http://www.uat.ro/"
       },
       {
          "university": "Technical University of Civil Engineering Bucharest",
          "website": "http://www.utcb.ro/"
       },
       {
          "university": "Technical University of Cluj-Napoca",
          "website": "http://www.utcluj.ro/"
       },
       {
          "university": "Technical University of Iasi",
          "website": "http://www.tuiasi.ro/"
       },
       {
          "university": "Technical University of Timisoara",
          "website": "http://www.utt.ro/"
       },
       {
          "university": "Universitatea de Vest Vasile Goldi ",
          "website": "http://www.uvvg.ro/"
       },
       {
          "university": "University Aurel Vlaicu Arad",
          "website": "http://www.uav.ro/"
       },
       {
          "university": "University Lucian Blaga of Sibiu",
          "website": "http://www.ulbsibiu.ro/"
       },
       {
          "university": "University of Agriculture and Veterinary Medicine Bucharest",
          "website": "http://www.usab.ro/"
       },
       {
          "university": "University of Agriculture and Veterinary Medicine Cluj-Napoca",
          "website": "http://www.usamvcluj.ro/"
       },
       {
          "university": "University of Agriculture and Veterinary Medicine Iasi",
          "website": "http://www.univagro-iasi.ro/"
       },
       {
          "university": "University of Agriculture and Veterinary Medicine Timisoara",
          "website": "http://www.usab-tm.ro/"
       },
       {
          "university": "University of Art and Design Cluj-Napoca",
          "website": "http://www.uartdcluj.ro/"
       },
       {
          "university": "University of Bacau",
          "website": "http://www.ub.ro/"
       },
       {
          "university": "University of Bucharest",
          "website": "http://www.unibuc.ro/"
       },
       {
          "university": "University of Constanta",
          "website": "http://www.univ-ovidius.ro/"
       },
       {
          "university": "University of Constanta Medical School",
          "website": "http://www.ovidunivconstanta-edu.org/"
       },
       {
          "university": "University of Craiova",
          "website": "http://www.central.ucv.ro/"
       },
       {
          "university": "University of Galatzi",
          "website": "http://www.ugal.ro/"
       },
       {
          "university": "University of Iasi",
          "website": "http://www.uaic.ro/"
       },
       {
          "university": "University of Medicine and Pharmacology of Oradea",
          "website": "http://www.oradeauniversity.com/"
       },
       {
          "university": "University of Medicine and Pharmacy of Bucharest",
          "website": "http://www.univermed-cdgm.ro/"
       },
       {
          "university": "University of Medicine and Pharmacy of Cluj-Napoca",
          "website": "http://www.umfcluj.ro/"
       },
       {
          "university": "University of Medicine and Pharmacy of Iasi",
          "website": "http://www.umfiasi.ro/"
       },
       {
          "university": "University of Medicine and Pharmacy of Targu Mures",
          "website": "http://www.umftgm.ro/"
       },
       {
          "university": "University of Medicine and Pharmacy of Timisoara",
          "website": "http://www.umft.ro/"
       },
       {
          "university": "University of Oradea",
          "website": "http://www.uoradea.ro/"
       },
       {
          "university": "University of Petrosani",
          "website": "http://www.upet.ro/"
       },
       {
          "university": "University of Pitesti",
          "website": "http://www.upit.ro/"
       },
       {
          "university": "University of Resita",
          "website": "http://www.uem.ro/"
       },
       {
          "university": "University of Sibiu",
          "website": "http://www.sibiu.ro/"
       },
       {
          "university": "University of Suceava",
          "website": "http://www.usv.ro/"
       },
       {
          "university": "University of Targu Jiu",
          "website": "http://www.utgjiu.ro/"
       },
       {
          "university": "University Oil- Gas Ploiesti",
          "website": "http://www.upg-ploiesti.ro/"
       },
       {
          "university": "University Petre Andrei Iasi",
          "website": "http://www.upa.ro/"
       },
       {
          "university": "University Politehnica of Bucharest",
          "website": "http://www.pub.ro/"
       },
       {
          "university": "University Titu Maiorescu",
          "website": "http://www.utm.ro/"
       },
       {
          "university": "University Transilvany of Brasov",
          "website": "http://www.unitbv.ro/"
       },
       {
          "university": "Valahia University of Targoviste",
          "website": "http://www.valahia.ro/"
       },
       {
          "university": "West University of Timisoara",
          "website": "http://www.uvt.ro/"
       }
    ],
    "RS": [
       {
          "university": "Drzavni Univerzitet u Novom Pazaru",
          "website": "http://www.np.ac.rs/"
       },
       {
          "university": "European University",
          "website": "http://www.eu.ac.rs/"
       },
       {
          "university": "International University of Novi Pazar",
          "website": "http://www.uninp.edu.rs/"
       },
       {
          "university": "Megatrend University of Applied Sciences",
          "website": "http://www.megatrend-edu.net/"
       },
       {
          "university": "Metropolitan University",
          "website": "http://www.metropolitan.edu.rs/"
       },
       {
          "university": "Privredna Akademija (Business Academy)",
          "website": "http://www.privrednaakademija.edu.rs/"
       },
       {
          "university": "Singidunum University",
          "website": "http://www.singidunum.edu.rs/"
       },
       {
          "university": "University of Belgrade",
          "website": "http://www.bg.ac.rs/"
       },
       {
          "university": "University of Fine Arts Belgrade",
          "website": "http://www.arts.bg.ac.rs/"
       },
       {
          "university": "University of Kragujevac",
          "website": "http://www.kg.ac.rs/"
       },
       {
          "university": "University of Nis",
          "website": "http://www.ni.ac.rs/"
       },
       {
          "university": "University of Novi Sad",
          "website": "http://www.uns.ac.rs/"
       },
       {
          "university": "University of Priština",
          "website": "http://www.pr.ac.rs/"
       }
    ],
    "RU": [
       {
          "university": "Academy of Management and Entrepreneurship",
          "website": "http://www.ame.ru/"
       },
       {
          "university": "Adygeja State University",
          "website": "http://www.adygnet.ru/"
       },
       {
          "university": "Altai State Medical University",
          "website": "http://www.asmu.ru/"
       },
       {
          "university": "Altai State Technical University",
          "website": "http://www.astu.secna.ru/"
       },
       {
          "university": "Altai State University",
          "website": "http://www.asu.ru/"
       },
       {
          "university": "Amur State University",
          "website": "http://www.amursu.ru/"
       },
       {
          "university": "Arhangelsk State Technical University",
          "website": "http://www.agtu.ru/"
       },
       {
          "university": "Astrahan State Medical Academy",
          "website": "http://agma.astranet.ru/"
       },
       {
          "university": "Astrahan State Technical University",
          "website": "http://www.astu.org/"
       },
       {
          "university": "Astrakhan State University",
          "website": "http://www.aspu.ru/"
       },
       {
          "university": "Baikal National University of Economics and Law",
          "website": "http://www.isea.ru/"
       },
       {
          "university": "Baltic State Technical University",
          "website": "http://www.voenmeh.ru/"
       },
       {
          "university": "Bashkir State Agrarian University",
          "website": "http://www.bashedu.ru/vuz/bgau_e.htm"
       },
       {
          "university": "Bashkir State Medical University",
          "website": "http://www.bashedu.ru/vuz/bgmu_e.htm"
       },
       {
          "university": "Bashkir State Pedagogical University named After M. Akmullah",
          "website": "http://www.bspu.ru/"
       },
       {
          "university": "Bashkir State University",
          "website": "http://www.bashedu.ru/"
       },
       {
          "university": "Bauman Moscow State Technical University",
          "website": "http://www.bmstu.ru/"
       },
       {
          "university": "Belgorod State Agricultural Academy",
          "website": "http://www.bsaa.ru/"
       },
       {
          "university": "Belgorod State Technical University",
          "website": "http://www.bstu.ru/"
       },
       {
          "university": "Belgorod State University",
          "website": "http://www.bsu.edu.ru/"
       },
       {
          "university": "Birsk State Pedagogical Institute",
          "website": "http://www.bashedu.ru/vuz/birskgpi_e.htm"
       },
       {
          "university": "Biysk State Padagogical University after V.M. Shukshin ",
          "website": "http://www.bigpi.biysk.ru/wwwsite/"
       },
       {
          "university": "Bratsk State Technical University",
          "website": "http://www.brstu.ru/"
       },
       {
          "university": "Brjansk State Technical University",
          "website": "http://www.tu-bryansk.ru/"
       },
       {
          "university": "Burjat State University",
          "website": "http://www.bsu.burnet.ru/"
       },
       {
          "university": "Cheljabinsk State Institute of Teacher Training",
          "website": "http://cspi.urc.ac.ru/"
       },
       {
          "university": "Cheljabinsk State University",
          "website": "http://www.csu.ac.ru/"
       },
       {
          "university": "Cheljabinsk University of Agricultural Engineering",
          "website": "http://www.urc.ac.ru/Universities/AGRO/"
       },
       {
          "university": "Chuvash State University",
          "website": "http://www.chuvsu.ru/"
       },
       {
          "university": "Dagestan State University",
          "website": "http://www.dgu.ru/"
       },
       {
          "university": "Don State Agrarian University",
          "website": "http://www.dongau.ru/"
       },
       {
          "university": "Don State Technical University",
          "website": "http://www.dstu.edu.ru/"
       },
       {
          "university": "Dubna International University for Nature - Society and Man",
          "website": "http://www.uni-dubna.ru/"
       },
       {
          "university": "East-Siberian State Institute of Culture",
          "website": "http://www.vsgaki.burnet.ru/"
       },
       {
          "university": "East-Siberian State University",
          "website": "http://www.vsgtu.eastsib.ru/"
       },
       {
          "university": "East-Siberian State University of Technology",
          "website": "http://www.esstu.ru/"
       },
       {
          "university": "European University at St.Petersburg",
          "website": "http://www.eu.spb.ru/"
       },
       {
          "university": "Far Easten State University of Humanities",
          "website": "http://www.khspu.ru/"
       },
       {
          "university": "Far Eastern State Technical Fisheries University",
          "website": "http://www.dalrybvtuz.ru/"
       },
       {
          "university": "Far Eastern State Technical University",
          "website": "http://www.festu.ru/"
       },
       {
          "university": "Far Eastern State University",
          "website": "http://www.dvgu.ru/"
       },
       {
          "university": "Far East State Transport University",
          "website": "http://www.dvgups.ru/"
       },
       {
          "university": "Finance Academy",
          "website": "http://www.fa.ru/"
       },
       {
          "university": "Gnesins Russian Academy of Music",
          "website": "http://www.gnesin-academy.ru/"
       },
       {
          "university": "Gorno-Altaisk State University",
          "website": "http://www.gasu.ru/"
       },
       {
          "university": "Gubkin Russian State University of Oil and Gas",
          "website": "http://www.gubkin.ru/"
       },
       {
          "university": "Herzen State Pedagogical University of Russia",
          "website": "http://www.herzen.spb.ru/"
       },
       {
          "university": "Higher School of Economics",
          "website": "http://www.hse.ru/"
       },
       {
          "university": "Immanuel Kant State University of Russia",
          "website": "http://www.kantiana.ru/"
       },
       {
          "university": "I.M. Sechenov Moscow Medical Academy",
          "website": "http://www.mma.ru/"
       },
       {
          "university": "Institute for the Economy in Transition",
          "website": "http://www.iet.ru/"
       },
       {
          "university": "Institute of Business and Politics",
          "website": "http://www.ibp-moscow.ru/"
       },
       {
          "university": "Institute of Management and Economy",
          "website": "http://www.ime.ru/"
       },
       {
          "university": "International Banking Institute - St. Petersburg",
          "website": "http://www.ibi.spb.ru/"
       },
       {
          "university": "International East-European University",
          "website": "http://www.ieeu.udm.ru/"
       },
       {
          "university": "International University of Engineering - Moscow",
          "website": "http://www.miu.ru/"
       },
       {
          "university": "International University of Fundamental Studies - St. Petersburg",
          "website": "http://www.iufs.edu/"
       },
       {
          "university": "Irkutsk State Academy of Economics",
          "website": "http://www.isea.irk.ru/"
       },
       {
          "university": "Irkutsk State Technical University",
          "website": "http://www.istu.irk.ru/"
       },
       {
          "university": "Irkutsk State University",
          "website": "http://www.isu.ru/"
       },
       {
          "university": "Ivanovo State Academy of Medicine",
          "website": "http://isma.indi.ru/"
       },
       {
          "university": "Ivanovo State Power University",
          "website": "http://www.ispu.ru/"
       },
       {
          "university": "Ivanovo State Textile Academy",
          "website": "http://education.ivanovo.ru:8101/IGTA/"
       },
       {
          "university": "Ivanovo State University",
          "website": "http://www.ivanovo.ac.ru/"
       },
       {
          "university": "Ivanovo State University of Chemistry and Technology",
          "website": "http://www.isuct.ru/"
       },
       {
          "university": "Izhevsk State Technical University",
          "website": "http://www.istu.ru/"
       },
       {
          "university": "Jewish University in Moscow",
          "website": "http://www.jum.ru/"
       },
       {
          "university": "Kabardino-Balkarian State University",
          "website": "http://www.kbsu.ru/"
       },
       {
          "university": "Kaliningrad State Technical University",
          "website": "http://www.klgtu.ru/"
       },
       {
          "university": "Kalmyk State University",
          "website": "http://www.kalmsu.ru/"
       },
       {
          "university": "Kaluga State Pedagogical University",
          "website": "http://kspu.kaluga.ru/"
       },
       {
          "university": "Kamchatka State University Vitus Bering",
          "website": "http://www.kamgu.ru/"
       },
       {
          "university": "Karelian State Pedagogical University",
          "website": "http://kspu.ptz.ru/"
       },
       {
          "university": "Kazan State Academy of Architecture and Civil Engineering",
          "website": "http://www.ksaba.ru/"
       },
       {
          "university": "Kazan State Finance and Economics Institute ",
          "website": "http://www.ksfei.ru/"
       },
       {
          "university": "Kazan State Music Conservatory",
          "website": "http://www.kazanconservatory.ru/"
       },
       {
          "university": "Kazan State Pedagogical University",
          "website": "http://www.kgpu.ru/"
       },
       {
          "university": "Kazan State University",
          "website": "http://www.ksu.ru/"
       },
       {
          "university": "Kazan State University of Medicine",
          "website": "http://www.kgmu.kcn.ru/"
       },
       {
          "university": "Kazan State University of Technology",
          "website": "http://www.kai.ru/"
       },
       {
          "university": "Kemerovo State University",
          "website": "http://www.kemsu.ru/"
       },
       {
          "university": "Khabarovsk State Academy of Economics and Law",
          "website": "http://www.ael.ru/"
       },
       {
          "university": "Khabarovsk State University of Technology",
          "website": "http://www.khstu.ru/"
       },
       {
          "university": "Komsomolsk-on-Amur State Technical University",
          "website": "http://www.knastu.ru/"
       },
       {
          "university": "Kostroma State Technological University",
          "website": "http://www.kstu.edu.ru/"
       },
       {
          "university": "Krasnoyarsk State Technical University",
          "website": "http://www.kgtu.runnet.ru/"
       },
       {
          "university": "Kuban State University",
          "website": "http://www.kubsu.ru/"
       },
       {
          "university": "Kuban State University of Agriculture",
          "website": "http://www.kubagro.ru/"
       },
       {
          "university": "Kuban State University of Technology",
          "website": "http://www.kubstu.ru/"
       },
       {
          "university": "Kurgan International University",
          "website": "http://www.kiu.ru/"
       },
       {
          "university": "Kursk State Medical University",
          "website": "http://www.ksmu.kursknet.ru/"
       },
       {
          "university": "Kursk State Technical University",
          "website": "http://www.kstu.kursk.ru/"
       },
       {
          "university": "Kursk State University",
          "website": "http://www.kursk-uni.ru/"
       },
       {
          "university": "Kuzbass State Technical University",
          "website": "http://www.kuzstu.ac.ru/"
       },
       {
          "university": "Linguistic University of Nizhny Novgorod",
          "website": "http://www.lunn.sci-nnov.ru/"
       },
       {
          "university": "Lipeck State Technical University",
          "website": "http://www.stu.lipetsk.ru/"
       },
       {
          "university": "Magnitogorsk State University",
          "website": "http://www.masu.ru/"
       },
       {
          "university": "Marij State Technical University",
          "website": "http://www.marstu.mari.ru/"
       },
       {
          "university": "Marij State University",
          "website": "http://margu.mari.ru/"
       },
       {
          "university": "Maxim Gorky Institute of Literature",
          "website": "http://filine.centro.ru/Gorky/"
       },
       {
          "university": "Mendeleev Russian University of Chemical Technology",
          "website": "http://www.muctr.edu.ru/"
       },
       {
          "university": "Modern Institute of Managament",
          "website": "http://miom.org/"
       },
       {
          "university": "Modern University for the Humanities",
          "website": "http://www.muh.ru/"
       },
       {
          "university": "Mordovian State University",
          "website": "http://www.mrsu.ru/"
       },
       {
          "university": "Moscow External University of the Humanities",
          "website": "http://www.chat.ru/~megu/"
       },
       {
          "university": "Moscow Institute of Physics and Technology",
          "website": "http://www.mipt.ru/"
       },
       {
          "university": "Moscow International Higher Business School (MIRBIS)",
          "website": "http://www.mirbis.ru/"
       },
       {
          "university": "Moscow P. I. Tchaikovsky Conservatory",
          "website": "http://www.mosconsv.ru/"
       },
       {
          "university": "Moscow Power Engineering Institute (Technical University)",
          "website": "http://www.mpei.ac.ru/"
       },
       {
          "university": "Moscow State Academy of Applied Biotechnology",
          "website": "http://www.msaab.ru/"
       },
       {
          "university": "Moscow State Automobile and Road Technical University",
          "website": "http://www.madi.ru/"
       },
       {
          "university": "Moscow State Industrial University",
          "website": "http://www.msiu.ru/"
       },
       {
          "university": "Moscow State Institute of International Relations MFA Russia (MGIMO-University)",
          "website": "http://www.mgimo.ru/"
       },
       {
          "university": "Moscow State Institute of Radiotechnics - Electronics and Automation (Technical University)",
          "website": "http://www.mirea.ru/"
       },
       {
          "university": "Moscow State Institute of Steel and Alloys",
          "website": "http://www.misis.ru/"
       },
       {
          "university": "Moscow State Technical University of Civil Aviation",
          "website": "http://www.mstuca.ru/"
       },
       {
          "university": "Moscow State Textile University A.N. Kosygin",
          "website": "http://www.msta.ac.ru/"
       },
       {
          "university": "Moscow State University",
          "website": "http://www.msu.ru/"
       },
       {
          "university": "Moscow State University M.V. Lomonosov",
          "website": "http://www.msu.ru/"
       },
       {
          "university": "Moscow State University of Civil Engineering",
          "website": "http://mgsu.norna.ru/"
       },
       {
          "university": "Moscow State University of Commerce",
          "website": "http://www.rsute.ru/"
       },
       {
          "university": "Moscow State University of Culture",
          "website": "http://www.msuc.edu.ru/"
       },
       {
          "university": "Moscow State University of Ecological Engineering",
          "website": "http://www.msuie.ru/"
       },
       {
          "university": "Moscow State University of Economics - Statistics and Informatics",
          "website": "http://www.mesi.ru/"
       },
       {
          "university": "Moscow State University of Environmental Engineering",
          "website": "http://www.msuee.ru/"
       },
       {
          "university": "Moscow State University of Forestry Engineering",
          "website": "http://www.mgul.ac.ru/"
       },
       {
          "university": "Moscow State University of Geodesy and Cartography",
          "website": "http://www.miigaik.ru/"
       },
       {
          "university": "Moscow State University of Railway Transport",
          "website": "http://www.miit.ru/"
       },
       {
          "university": "Moscow State University of Technology (Stankin)",
          "website": "http://www.stankin.ru/"
       },
       {
          "university": "Moscow Technical University of Informatics and Communication",
          "website": "http://www.mtuci.ru/"
       },
       {
          "university": "Moscow University Touro",
          "website": "http://www.touro.ru/"
       },
       {
          "university": "Murmansk State Technical University",
          "website": "http://www.mstu.edu.ru/"
       },
       {
          "university": "National Research University of Electronic Technology",
          "website": "http://www.miet.ru/"
       },
       {
          "university": "Nayanova University",
          "website": "http://www.nayanova.edu/"
       },
       {
          "university": "Nevsky Institute of Language and Culture",
          "website": "http://www.nilc.spb.ru/"
       },
       {
          "university": "New Economic School",
          "website": "http://www.nes.ru/"
       },
       {
          "university": "NGO Vladikavkaz Institute of Economics - Management - and Law",
          "website": "http://www.vieup.ru/"
       },
       {
          "university": "Nizhny Novgorod State Academy of Medicine",
          "website": "http://www.n-nov.mednet.com/"
       },
       {
          "university": "Nizhny Novgorod State Architectural - Building University",
          "website": "http://www.ngasu.sci-nnov.ru/"
       },
       {
          "university": "Nizhny Novgorod State Technical University",
          "website": "http://www.nntu.sci-nnov.ru/"
       },
       {
          "university": "Nizhny Novgorod State University",
          "website": "http://www.unn.ac.ru/"
       },
       {
          "university": "Northern State Medical University",
          "website": "http://www.nsmu.ru/"
       },
       {
          "university": "North Ossetian State University",
          "website": "http://www.nosu.ru/"
       },
       {
          "university": "North-West State Technical University",
          "website": "http://www.nwpi.ru/"
       },
       {
          "university": "Novgorod State University",
          "website": "http://www.novsu.ac.ru/"
       },
       {
          "university": "Novosibirsk State Academy of Economics and Mangement",
          "website": "http://cit.drbit.com.ru/"
       },
       {
          "university": "Novosibirsk State Agricultural University",
          "website": "http://www.nsau.edu.ru/"
       },
       {
          "university": "Novosibirsk State Medical Academy",
          "website": "http://www.medin.nsc.ru/"
       },
       {
          "university": "Novosibirsk State Music Academy M. Glinka",
          "website": "http://www.conservatoire.ru/"
       },
       {
          "university": "Novosibirsk State Technical University",
          "website": "http://www.nstu.ru/"
       },
       {
          "university": "Novosibirsk State University",
          "website": "http://www.nsu.ru/"
       },
       {
          "university": "Obninsk State Technical University for Nuclear Power Engineering",
          "website": "http://www.iate.obninsk.ru/"
       },
       {
          "university": "Omsk State Agrarian University",
          "website": "http://www.omgau.ru/"
       },
       {
          "university": "Omsk State Medicical Academy",
          "website": "http://www.omsk-osma.ru/"
       },
       {
          "university": "Omsk State Pedagogical University",
          "website": "http://www.omsk.edu/"
       },
       {
          "university": "Omsk State Technical University",
          "website": "http://www.omgtu.ru/"
       },
       {
          "university": "Omsk State Transport University",
          "website": "http://www.omgups.ru/"
       },
       {
          "university": "Omsk State University",
          "website": "http://www.univer.omsk.su/"
       },
       {
          "university": "Orenburg State University",
          "website": "http://www.osu.ru/"
       },
       {
          "university": "Oriental University",
          "website": "http://www.orun.ru/"
       },
       {
          "university": "Penza State University",
          "website": "http://www.pnzgu.ru/"
       },
       {
          "university": "People' s Friendship University of Russia",
          "website": "http://www.pfu.edu.ru/"
       },
       {
          "university": "Perm State Academy of Agriculture",
          "website": "http://www.pstu.ac.ru/"
       },
       {
          "university": "Perm State Medical Academy",
          "website": "http://www.psma.ru/"
       },
       {
          "university": "Perm State Pedagogical University",
          "website": "http://www.pspu.ac.ru/"
       },
       {
          "university": "Perm State Pharmaceutical Academy",
          "website": "http://www.psfa.ru/"
       },
       {
          "university": "Perm State Technical University",
          "website": "http://www.pstu.ac.ru/"
       },
       {
          "university": "Perm State University",
          "website": "http://www.psu.ru/"
       },
       {
          "university": "Petrozavodsk State University",
          "website": "http://www.karelia.ru/psu/"
       },
       {
          "university": "Pjatigorsk State Linguistic University",
          "website": "http://www.pglu.ru/"
       },
       {
          "university": "Plekhanov Russian Academy of Economics",
          "website": "http://www.rea.ru/"
       },
       {
          "university": "Pomorsk State University",
          "website": "http://www.pomorsu.ru/"
       },
       {
          "university": "Pomor State University",
          "website": "http://www.pomorsu.ru/"
       },
       {
          "university": "Rostov State Medical University",
          "website": "http://www.rsmu.da.ru/"
       },
       {
          "university": "Rostov State University",
          "website": "http://www.rsu.ru/"
       },
       {
          "university": "Russian Academy of Arts",
          "website": "http://www.rah.ru/"
       },
       {
          "university": "Russian Academy of Theatre Arts",
          "website": "http://www.gitis.net/"
       },
       {
          "university": "Russian Customs Academy - Vladivostok Branch",
          "website": "http://www.vfrta.ru/"
       },
       {
          "university": "Russian State Geological Prospecting University ",
          "website": "http://www.mgri-rggru.ru/"
       },
       {
          "university": "Russian State Hydrometeorological University",
          "website": "http://www.rshu.ru/"
       },
       {
          "university": "Russian State Medical University",
          "website": "http://www.rsmu.ru/"
       },
       {
          "university": "Russian State University for the Humanities",
          "website": "http://www.rsuh.ru/"
       },
       {
          "university": "Ryazan State Pedagogical University",
          "website": "http://www.ttc.ryazan.ru/"
       },
       {
          "university": "Rybinsk State Academy of Aviational Technology",
          "website": "http://www.rgata.yaroslavl.ru/"
       },
       {
          "university": "Sakhalin State University",
          "website": "http://www.sakhgu.sakhalin.ru/"
       },
       {
          "university": "Samara State Academy for Railway Transportation",
          "website": "http://www.samiit.ru/"
       },
       {
          "university": "Samara State Academy of Architecture and Civil Engineering",
          "website": "http://www.ssaba.smr.ru/"
       },
       {
          "university": "Samara State Aerospace University",
          "website": "http://www.ssau.ru/"
       },
       {
          "university": "Samara State Agricultural Academy",
          "website": "http://www.ssaa.ru/"
       },
       {
          "university": "Samara State Medical University",
          "website": "http://www.samsmu.ru/"
       },
       {
          "university": "Samara State Technical University",
          "website": "http://www.sstu.samara.ru/"
       },
       {
          "university": "Samara State University",
          "website": "http://www.ssu.samara.ru/"
       },
       {
          "university": "Samara State University of Economics",
          "website": "http://www.sseu.ru/"
       },
       {
          "university": "Samara State University of Teacher Training",
          "website": "http://www.ssttu.samara.ru/"
       },
       {
          "university": "Saratov State Academy of Law",
          "website": "http://www.sgap.ru/"
       },
       {
          "university": "Saratov State Agrarian University",
          "website": "http://www.sgau.ru/"
       },
       {
          "university": "Saratov State Medical University",
          "website": "http://www.med.sgu.ru/"
       },
       {
          "university": "Saratov State Socio-Economic University",
          "website": "http://www.ssea.runnet.ru/"
       },
       {
          "university": "Saratov State Technical University",
          "website": "http://www.sstu-edu.ru/"
       },
       {
          "university": "Saratov State University",
          "website": "http://www.sgu.ru/"
       },
       {
          "university": "Siberian Academy of Public Services",
          "website": "http://www.sapanet.ru/"
       },
       {
          "university": "Siberian State Industrial University",
          "website": "http://sibsiu.kemerovo.su/"
       },
       {
          "university": "Siberian State Medical University",
          "website": "http://www.ssmu.ru/"
       },
       {
          "university": "Siberian State Transport University",
          "website": "http://www.stu.ru/"
       },
       {
          "university": "Siberian State University of Telecommunications and Informatics",
          "website": "http://www.neic.nsk.su/"
       },
       {
          "university": "Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology",
          "website": "http://www.mgavm.ru/"
       },
       {
          "university": "Smolensk Humanitarian University",
          "website": "http://shu.smolensk.su/"
       },
       {
          "university": "Smolensk State Medical Academy",
          "website": "http://www.sgma.info/"
       },
       {
          "university": "Smolny University",
          "website": "http://www.smolny-un.spb.ru/"
       },
       {
          "university": "Southern Ural State University",
          "website": "http://www.tu-chel.ac.ru/"
       },
       {
          "university": "State Maritine Academy",
          "website": "http://www.gma.ru/"
       },
       {
          "university": "State University of Aerospace Technologies",
          "website": "http://www.intstudy.mai.ru/"
       },
       {
          "university": "State University of Management",
          "website": "http://www.guu.ru/"
       },
       {
          "university": "Stavropol State Technical University",
          "website": "http://www.ncstu.ru/"
       },
       {
          "university": "Stavropol State University",
          "website": "http://www.stavsu.ru/"
       },
       {
          "university": "Sterlitamak State Pedagogical Institute",
          "website": "http://www.bashedu.ru/vuz/sgpi_e.htm"
       },
       {
          "university": "St. Petersburg Institute of Arts and Restoration",
          "website": "http://www.spbiir.ru/"
       },
       {
          "university": "St. Petersburg Institute of Sales Management and Economics",
          "website": "http://www.spbtei.ru/"
       },
       {
          "university": "St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture",
          "website": "http://artacademy.spb.ru/"
       },
       {
          "university": "St. Petersburg State Academy for Engineering Economics (ENGECON)",
          "website": "http://www.engec.ru/"
       },
       {
          "university": "St. Petersburg State Agrarian University",
          "website": "http://www.spbgau.spb.ru/"
       },
       {
          "university": "St. Petersburg State Chemical Pharmaceutical Academy",
          "website": "http://www.spcpa.ru/"
       },
       {
          "university": "St. Petersburg State Cinema and TV University",
          "website": "http://www.gukit.ru/"
       },
       {
          "university": "St. Petersburg State Conservatory",
          "website": "http://www.conservatory.ru/"
       },
       {
          "university": "St. Petersburg State Electrotechnical University",
          "website": "http://www.eltech.ru/"
       },
       {
          "university": "St. Petersburg State Forest-Technical Academy",
          "website": "http://www.ftacademy.ru/"
       },
       {
          "university": "St. Petersburg State Institute of Technology (Technological University)",
          "website": "http://www.gti.spb.ru/"
       },
       {
          "university": "St. Petersburg State Marine Technical University",
          "website": "http://www.smtu.ru/"
       },
       {
          "university": "St. Petersburg StateMechnikov  Medical Academy",
          "website": "http://www.spsmma.com/"
       },
       {
          "university": "St. Petersburg State Medical Academy",
          "website": "http://www.mechnik.spb.ru/"
       },
       {
          "university": "St. Petersburg State Mining Institute (Technical University)",
          "website": "http://www.spmi.edu.ru/"
       },
       {
          "university": "St. Petersburg State Mountain Institut",
          "website": "http://www.gorny-ins.ru/"
       },
       {
          "university": "St. Petersburg State Pediatric Medical Academy",
          "website": "http://www.gpma.ru/"
       },
       {
          "university": "St. Petersburg State Polytechnical University",
          "website": "http://www.spbstu.ru/"
       },
       {
          "university": "St. Petersburg State Technical University",
          "website": "http://www.unilib.neva.ru/"
       },
       {
          "university": "St. Petersburg State Technological University of Plant Polymers",
          "website": "http://www.gturp.spb.ru/"
       },
       {
          "university": "St. Petersburg State Theatre Arts Academy",
          "website": "http://www.tart.spb.ru/"
       },
       {
          "university": "St. Petersburg State University",
          "website": "http://www.spbu.ru/"
       },
       {
          "university": "St. Petersburg State University of Aerospace Instrumentation",
          "website": "http://www.suai.ru/"
       },
       {
          "university": "St. Petersburg State University of Architecture and Civil Engineering",
          "website": "http://www.spbgasu.ru/"
       },
       {
          "university": "St. Petersburg State University of Civil Aviation",
          "website": "http://www.academiaga.ru/"
       },
       {
          "university": "St. Petersburg State University of Culture and Arts",
          "website": "http://www.spbguki.ru/"
       },
       {
          "university": "St. Petersburg State University of Culture and Arts",
          "website": "http://www.spbguki.ru/"
       },
       {
          "university": "St. Petersburg State University of Economics and Finance",
          "website": "http://www.uef.ru/"
       },
       {
          "university": "St. Petersburg State University of Engineering and Economics",
          "website": "http://www.engec.ru/"
       },
       {
          "university": "St. Petersburg State University of Information Technologies - Mechanics and Optics",
          "website": "http://www.ifmo.ru/"
       },
       {
          "university": "St. Petersburg State University of Technology and Design",
          "website": "http://www.sutd.ru/"
       },
       {
          "university": "St. Petersburg State University of Telecommunication",
          "website": "http://www.sut.ru/"
       },
       {
          "university": "St. Petersburg State University of Waterway Communications",
          "website": "http://www.spbuwc.ru/"
       },
       {
          "university": "Surgut State University",
          "website": "http://www.surgu.wsnet.ru/"
       },
       {
          "university": "Syktyvkar State University",
          "website": "http://ssu.komi.com:8101/"
       },
       {
          "university": "Taganrog State Pedagogical Institute",
          "website": "http://www.tgpi.ttn.ru/"
       },
       {
          "university": "Taganrog State University of Radioengineering",
          "website": "http://www.tsure.ru/"
       },
       {
          "university": "Tambov State Technical University",
          "website": "http://www.tstu.ru/"
       },
       {
          "university": "Timirjazev Moscow Academy of Agricultutre",
          "website": "http://www.timacad.ru/"
       },
       {
          "university": "Togliatti State University",
          "website": "http://www.tltsu.ru/"
       },
       {
          "university": "Tomsk Polytechnic University",
          "website": "http://www.tpu.ru/"
       },
       {
          "university": "Tomsk State Pedagogical University",
          "website": "http://www.tspu.edu.ru/"
       },
       {
          "university": "Tomsk State University",
          "website": "http://www.tsu.ru/"
       },
       {
          "university": "Tomsk State University of Architecture and Building",
          "website": "http://www.tsuab.ru/"
       },
       {
          "university": "Tomsk State University of Control Systems and Radioelectronics",
          "website": "http://www.tusur.ru/"
       },
       {
          "university": "Tula State University",
          "website": "http://www.tsu.tula.ru/"
       },
       {
          "university": "Tuva State University",
          "website": "http://tuvsu.ru/"
       },
       {
          "university": "Tver State Medical Academy",
          "website": "http://www.tsma.ru/"
       },
       {
          "university": "Tver State Technical University",
          "website": "http://www.tstu.tver.ru/"
       },
       {
          "university": "Tver State University",
          "website": "http://university.tversu.ru/"
       },
       {
          "university": "Tyumen State Agricultural Academy",
          "website": "http://www.tgsha.ru/"
       },
       {
          "university": "Tyumen State University",
          "website": "http://www.tsu.tmn.ru/"
       },
       {
          "university": "Tyumen State University of Gas and Oil",
          "website": "http://www.tsogu.ru/"
       },
       {
          "university": "Udmurt State University",
          "website": "http://www.uni.udm.ru/"
       },
       {
          "university": "Ufa State Aviation Technical University",
          "website": "http://www.ugatu.ac.ru/"
       },
       {
          "university": "Ufa State Institute of Arts",
          "website": "http://www.bashedu.ru/vuz/ugii_e.htm"
       },
       {
          "university": "Ufa State Petroleum Technological University",
          "website": "http://www.rusoil.net/"
       },
       {
          "university": "Ukhta State Technical University",
          "website": "http://uii.sever.ru/"
       },
       {
          "university": "Ulyanovsk Higher School of Civil Aviation",
          "website": "http://www.uvauga.ru/"
       },
       {
          "university": "Ulyanovsk State Agricultural Academy",
          "website": "http://www.ugsha.ru/"
       },
       {
          "university": "Ulyanovsk State Pedagogical University",
          "website": "http://www.ulspu.ru/"
       },
       {
          "university": "Ulyanovsk State Technical University",
          "website": "http://www.ulstu.ru/"
       },
       {
          "university": "Ulyanovsk State University",
          "website": "http://www.ulsu.ru/"
       },
       {
          "university": "Ural Academy of Public Administration",
          "website": "http://www.uapa.ru/"
       },
       {
          "university": "Ural Gorkij State University",
          "website": "http://www.usu.ru/"
       },
       {
          "university": "Ural State Academy of Architecture and Arts",
          "website": "http://www.usaaa.ru/"
       },
       {
          "university": "Ural State Academy of Law",
          "website": "http://www.usla.ru/"
       },
       {
          "university": "Ural State Academy of Mining and Geology",
          "website": "http://www.usmga.ru/"
       },
       {
          "university": "Ural State Academy of Railway Transport",
          "website": "http://www.usart.ru/"
       },
       {
          "university": "Ural State Conservatory",
          "website": "http://www.uscon.ru/"
       },
       {
          "university": "Ural State Forestry Technical Academy",
          "website": "http://www.usfea.ru/"
       },
       {
          "university": "Ural State Technical University",
          "website": "http://www.ustu.ru/"
       },
       {
          "university": "Ural State University of Economics",
          "website": "http://www.usue.ru/"
       },
       {
          "university": "Vaganova Academy of Russian Ballet",
          "website": "http://www.kirovballet.com/vaganova.html"
       },
       {
          "university": "Vladimir State University",
          "website": "http://www.vlsu.ru/"
       },
       {
          "university": "Vladivostock State University of Economics",
          "website": "http://www.vvsu.ru/"
       },
       {
          "university": "Volga State Academy of Water Transport",
          "website": "http://www.aqua.sci-nnov.ru/"
       },
       {
          "university": "Volgograd Medical Academy",
          "website": "http://www.avtlg.ru/~vlgmed/"
       },
       {
          "university": "Volgograd State Academy of Physical Education",
          "website": "http://www.vgafk.ru/"
       },
       {
          "university": "Volgograd State Pedagogical University",
          "website": "http://www.vspu.ru/"
       },
       {
          "university": "Volgograd State Technical University",
          "website": "http://www.vstu.ru/"
       },
       {
          "university": "Volgograd State University",
          "website": "http://www.volsu.ru/"
       },
       {
          "university": "Volgograd State University of Architecture and Civil Engineering (VolgGASU)",
          "website": "http://www.vgasa.ru/"
       },
       {
          "university": "Vologda State Pedagogical University",
          "website": "http://www.uni-vologda.ac.ru/"
       },
       {
          "university": "Voronezh State Academy of Technology",
          "website": "http://www.vgta.vrn.ru/"
       },
       {
          "university": "Voronezh State Agricultural University",
          "website": "http://www.vsau.ru/"
       },
       {
          "university": "Voronezh State Medical Academy",
          "website": "http://www.vsma.info/"
       },
       {
          "university": "Voronezh State Pedagogical University",
          "website": "http://www.vspu.ac.ru/"
       },
       {
          "university": "Voronezh State Technical University",
          "website": "http://www.vorstu.ac.ru/"
       },
       {
          "university": "Voronezh State University",
          "website": "http://www.vsu.ru/"
       },
       {
          "university": "Vyatka State Pedagogical University",
          "website": "http://www.vspu.kirov.ru/"
       },
       {
          "university": "Yakutsk State University",
          "website": "http://www.ysu.ru/"
       },
       {
          "university": "Yaroslavl International University of Business and New Technologies",
          "website": "http://www.iubnt.yar.ru/"
       },
       {
          "university": "Yaroslavl State Medical Academy",
          "website": "http://www.yma.ac.ru/"
       },
       {
          "university": "Yaroslavl State Pedagogical University",
          "website": "http://www.yspu.yar.ru/"
       },
       {
          "university": "Yaroslavl State Technical University",
          "website": "http://www.ystu.yar.ru/"
       },
       {
          "university": "Yaroslavl State Theatre Institute",
          "website": "http://www.theatrins-yar.ru/"
       },
       {
          "university": "Yaroslavl State University",
          "website": "http://www.uniyar.ac.ru/"
       },
       {
          "university": "Yelets State University",
          "website": "http://www.elsu.ru/"
       },
       {
          "university": "Yugra State University",
          "website": "http://www.ugrasu.ru/"
       }
    ],
    "RW": [
       {
          "university": "Adventist University of Central Africa",
          "website": "http://www.auca.ac.rw/"
       },
       {
          "university": "Higher Institute of Agriculture and Animal Husbandry",
          "website": "http://www.isae.ac.rw/"
       },
       {
          "university": "Institut d'Agriculture - de Technologie et d'Education de Kibungo",
          "website": "http://www.inatek.ac.rw/"
       },
       {
          "university": "Kigali Health Institute",
          "website": "http://www.khi.ac.rw/"
       },
       {
          "university": "Kigali Independent University",
          "website": "http://www.ulk.ac.rw/"
       },
       {
          "university": "Kigali Institute of Education",
          "website": "http://www.kie.ac.rw/"
       },
       {
          "university": "Kigali Institute of Science & Technology",
          "website": "http://www.kist.ac.rw/"
       },
       {
          "university": "National University of Rwanda",
          "website": "http://www.nur.ac.rw/"
       },
       {
          "university": "School of Finance and Banking",
          "website": "http://www.sfb.ac.rw/"
       },
       {
          "university": "Umutara Polytechnic",
          "website": "http://www.umutarapolytech.ac.rw/"
       },
       {
          "university": "Université Catholique de Kabgayi",
          "website": "http://www.uck.ac.rw/"
       }
    ],
    "SA": [
       {
          "university": "Al-baha University",
          "website": "http://www.bu.edu.sa/"
       },
       {
          "university": "Al-Batterjee Medical College",
          "website": "http://www.bmcmedcollege.com/"
       },
       {
          "university": "Alfaisal University",
          "website": "http://www.alfaisal.edu/"
       },
       {
          "university": "Al-Imam Mohamed Ibn Saud Islamic University",
          "website": "http://www.imamu.edu.sa/"
       },
       {
          "university": "Aljouf University",
          "website": "http://www.ju.edu.sa/"
       },
       {
          "university": "Alkharj University",
          "website": "http://www.ku.edu.sa/"
       },
       {
          "university": "Al-Yamamah College",
          "website": "http://www.alyamamah.edu.sa/"
       },
       {
          "university": "Arab Open University",
          "website": "http://www.arabou.org.sa/"
       },
       {
          "university": "Batterjee Medical College",
          "website": "http://www.bmc.edu.sa/"
       },
       {
          "university": "Buraydah College for Applied Medical Sciences",
          "website": "http://www.bpc.edu.sa/"
       },
       {
          "university": "College of Busniess Admnistration",
          "website": "http://www.cba.edu.sa/"
       },
       {
          "university": "College of Nursing and Allied Health Scinces",
          "website": "http://www.ngha.med.sa/"
       },
       {
          "university": "College of Technology at Abha",
          "website": "http://www.act.gotevot.edu.sa/"
       },
       {
          "university": "College of Technology at Dammam",
          "website": "http://www.dct.gotevot.edu.sa/"
       },
       {
          "university": "College of Technology at Jazan",
          "website": "http://www.tnmeyah.net/jct/"
       },
       {
          "university": "College of Technology at Jeddah",
          "website": "http://www.jct.edu.sa/"
       },
       {
          "university": "College of Technology at Kharj",
          "website": "http://kct.gotevot.edu.sa/"
       },
       {
          "university": "College of Technology at Riyadh",
          "website": "http://www.rct.edu.sa/"
       },
       {
          "university": "College of Telecommunication & Information ",
          "website": "http://www.cti.edu.sa/"
       },
       {
          "university": "Dammam Community College",
          "website": "http://www.dcc.edu.sa/"
       },
       {
          "university": "Dar al Hekma College",
          "website": "http://www.daralhekma.edu.sa/"
       },
       {
          "university": "Dar Al-Uloom Colleges",
          "website": "http://www.dau.edu.sa/"
       },
       {
          "university": "Effat College",
          "website": "http://www.effatcollege.edu.sa/"
       },
       {
          "university": "Fahad Bin Sultan University",
          "website": "http://www.fbsc.edu.sa/"
       },
       {
          "university": "Ibn Sina National College for Medical Studies ",
          "website": "http://www.ibnsina.edu.sa/"
       },
       {
          "university": "Institute of Public Administration",
          "website": "http://www.ipa.edu.sa/"
       },
       {
          "university": "Islamic University of Medinah",
          "website": "http://www.iu.edu.sa/"
       },
       {
          "university": "Jazan University",
          "website": "http://www.jazanu.edu.sa/"
       },
       {
          "university": "Jubail Industrial College",
          "website": "http://www.jic.edu.sa/"
       },
       {
          "university": "Jubail University College",
          "website": "http://www.ucj.edu.sa/"
       },
       {
          "university": "King Abdul Aziz University",
          "website": "http://www.kau.edu.sa/"
       },
       {
          "university": "King Abdullah University of Science and Technology ",
          "website": "http://www.kaust.edu.sa/"
       },
       {
          "university": "King Fahad University of Petroleum and Minerals",
          "website": "http://www.kfupm.edu.sa/"
       },
       {
          "university": "King Fahd Security College",
          "website": "http://www.kfsc.edu.sa/"
       },
       {
          "university": "King Faisal University",
          "website": "http://www.kfu.edu.sa/"
       },
       {
          "university": "King Khaled University",
          "website": "http://www.kku.edu.sa/"
       },
       {
          "university": "King Saud bin Abdulaziz University for Health Sciences ",
          "website": "http://www.ksau-hs.edu.sa/"
       },
       {
          "university": "King Saud University",
          "website": "http://www.ksu.edu.sa/"
       },
       {
          "university": "Knowledge College for Science and Technology",
          "website": "http://www.mcst.edu.sa/"
       },
       {
          "university": "Knowledge International University",
          "website": "http://www.almaarifah.com/"
       },
       {
          "university": "Majmaah University",
          "website": "http://www.mu.edu.sa/"
       },
       {
          "university": "Naif Arab University for Security Sciences",
          "website": "http://www.nauss.edu.sa/"
       },
       {
          "university": "Najran University",
          "website": "http://www.nu.edu.sa/"
       },
       {
          "university": "Northern Border University",
          "website": "http://www.nbu.edu.sa/"
       },
       {
          "university": "Prince Mohammad Bin Fahd University",
          "website": "http://www.pmu.edu.sa/"
       },
       {
          "university": "Princess Nora Bint Abdulrahman University",
          "website": "http://www.rug.edu.sa/"
       },
       {
          "university": "Prince Sultan College for Tourism and Hotel Scinces",
          "website": "http://www.pscabha.edu.sa/"
       },
       {
          "university": "Prince Sultan University",
          "website": "http://www.psu.edu.sa/"
       },
       {
          "university": "Private College of Dentistry and Pharmacy",
          "website": "http://www.pcdp.edu.sa/"
       },
       {
          "university": "Qassim Private College",
          "website": "http://www.qc.edu.sa/"
       },
       {
          "university": "Qassim University",
          "website": "http://www.qu.edu.sa/"
       },
       {
          "university": "Riyadh College of Dentistry and Pharmacy",
          "website": "http://www.riyadh.edu.sa/"
       },
       {
          "university": "Saad College of Nursing and Allied Health Sciences",
          "website": "http://www.saadcollege.com/"
       },
       {
          "university": "Saudi Electronic University",
          "website": "http://www.seu.edu.sa/"
       },
       {
          "university": "Shaqra University",
          "website": "http://www.su.edu.sa/"
       },
       {
          "university": "Taibah University",
          "website": "http://www.taibahu.edu.sa/"
       },
       {
          "university": "Taif University",
          "website": "http://www.tu.edu.sa/"
       },
       {
          "university": "Umm Al-Qura University",
          "website": "http://www.uqu.edu.sa/"
       },
       {
          "university": "University of Dammam",
          "website": "http://www.ud.edu.sa/"
       },
       {
          "university": "University of Hail",
          "website": "http://www.uoh.edu.sa/"
       },
       {
          "university": "University of Tabuk",
          "website": "http://www.ut.edu.sa/"
       },
       {
          "university": "Yanbu Industrial College",
          "website": "http://www.yic.edu.sa/"
       },
       {
          "university": "Yanbu Technical Institute",
          "website": "http://www.yti.edu.sa/"
       },
       {
          "university": "Yanbu University College",
          "website": "http://www.yuc.edu.sa/"
       }
    ],
    "SB": [
       {
          "university": "University of the South Pacific",
          "website": "http://www.usp.ac.fj/"
       }
    ],
    "SC": [
       {
          "university": "University of Seychelles",
          "website": "http://www.unisey.ac.sc/"
       },
       {
          "university": "University of Seychelles - American Institute of Medicine",
          "website": "http://www.usaim.edu/"
       }
    ],
    "SD": [
       {
          "university": "Ahfad University for Women",
          "website": "http://www.ahfad.edu.sd/"
       },
       {
          "university": "Al Fashir University",
          "website": "http://www.fashir.edu.sd/"
       },
       {
          "university": "Al-Zaiem Al-Azhari University",
          "website": "http://www.aau.edu.sd/"
       },
       {
          "university": "Bakht Er-Ruda University",
          "website": "http://www.bakhtalruda.edu.sd/"
       },
       {
          "university": "Bayan College for Science & Technology",
          "website": "http://www.bayantech.edu/"
       },
       {
          "university": "Blue Nile University",
          "website": "http://www.bnu.edu.sd/"
       },
       {
          "university": "Canadian Sudanese College",
          "website": "http://www.ccs.edu.sd/"
       },
       {
          "university": "Dalanj University",
          "website": "http://www.dalanjuniversity.edu.sd/"
       },
       {
          "university": "Elrazi College Of Medical & Technological Sciences",
          "website": "http://www.elrazicollege.net/"
       },
       {
          "university": "Gezira College of Technology",
          "website": "http://www.geziracollege.edu.sd/"
       },
       {
          "university": "High Institute for Banking & Financial Studies",
          "website": "http://www.hibfs.edu.sd/"
       },
       {
          "university": "International University of Africa (IUA)",
          "website": "http://www.iua.edu.sd/"
       },
       {
          "university": "Kassala University",
          "website": "http://www.kassalauni.edu.sd/"
       },
       {
          "university": "National College for Medical and Technical Studies",
          "website": "http://www.nc.edu.sd/"
       },
       {
          "university": "National Ribat University",
          "website": "http://www.ribat.edu.sd/"
       },
       {
          "university": "Neelain University",
          "website": "http://www.neelain.edu.sd/"
       },
       {
          "university": "Nile Valley University",
          "website": "http://www.nilevalley.edu.sd/"
       },
       {
          "university": "Omdurman Ahlia University",
          "website": "http://www.oau.edu.sd/"
       },
       {
          "university": "Omdurman Islamic University",
          "website": "http://www.oiu.edu.sd/"
       },
       {
          "university": "Open University of Sudan (OUS)",
          "website": "http://www.ous.edu.sd/"
       },
       {
          "university": "Red Sea University",
          "website": "http://www.rsu.edu.sd/"
       },
       {
          "university": "Shendi University",
          "website": "http://www.ush.sd/"
       },
       {
          "university": "Sinnar University",
          "website": "http://www.sinnaruniv.edu.sd/"
       },
       {
          "university": "Sudan Academy of Sciences",
          "website": "http://www.sas-sd.net/"
       },
       {
          "university": "Sudan University for Science and Technology",
          "website": "http://www.sustech.edu/"
       },
       {
          "university": "University of Dongola",
          "website": "http://www.uofd.edu.sd/"
       },
       {
          "university": "University of El Imam El Mahdi University",
          "website": "http://www.elmahdi.edu.sd/"
       },
       {
          "university": "University of Gadarif",
          "website": "http://www.gaduniv.edu.sd/"
       },
       {
          "university": "University of Gezira",
          "website": "http://www.uofg.edu.sd/"
       },
       {
          "university": "University of Holy Quran and Islamic Siences",
          "website": "http://www.quran-unv.edu.sd/"
       },
       {
          "university": "University of Khartoum",
          "website": "http://www.uofk.edu/"
       },
       {
          "university": "University of Kordofan",
          "website": "http://www.kordofan.edu.sd/"
       },
       {
          "university": "University Of Medical Sciences & Technology (UMST)",
          "website": "http://www.umst-edu.com/"
       },
       {
          "university": "University of Science and Technology",
          "website": "http://www.ust.edu.sd/"
       },
       {
          "university": "Upper Nile University",
          "website": "http://www.unu.edu.sd/"
       }
    ],
    "SE": [
       {
          "university": "Blekinge Institute of Technology",
          "website": "http://www.bth.se/"
       },
       {
          "university": "Chalmers University of Technology",
          "website": "http://www.chalmers.se/"
       },
       {
          "university": "Dalarna University College",
          "website": "http://www.du.se/"
       },
       {
          "university": "Free University Stockholm",
          "website": "http://www.stockholm-fu.com/"
       },
       {
          "university": "Göteborg University",
          "website": "http://www.gu.se/"
       },
       {
          "university": "Gotland University College",
          "website": "http://www.hgo.se/"
       },
       {
          "university": "Halmstad University College",
          "website": "http://www.hh.se/"
       },
       {
          "university": "Jönköping University College",
          "website": "http://www.hj.se/"
       },
       {
          "university": "Jönköping University College of Health Sciences",
          "website": "http://www.hj.se/hhj/"
       },
       {
          "university": "Kalmar University College",
          "website": "http://www.hik.se/"
       },
       {
          "university": "Karlstad University",
          "website": "http://www.kau.se/"
       },
       {
          "university": "Karolinska Institute Stockholm",
          "website": "http://www.ki.se/"
       },
       {
          "university": "Linköping University",
          "website": "http://www.liu.se/"
       },
       {
          "university": "Lulea University of Technology",
          "website": "http://www.luth.se/"
       },
       {
          "university": "Lund University",
          "website": "http://www.lu.se/"
       },
       {
          "university": "Mälardalen University",
          "website": "http://www.mdh.se/"
       },
       {
          "university": "Malmö University College",
          "website": "http://www.mah.se/"
       },
       {
          "university": "Mid-Sweden University",
          "website": "http://www.miun.se/"
       },
       {
          "university": "Nordic School of Public Health",
          "website": "http://www.nhv.se/"
       },
       {
          "university": "Örebro University",
          "website": "http://www.oru.se/"
       },
       {
          "university": "Royal lnstitute of Technology",
          "website": "http://www.kth.se/"
       },
       {
          "university": "School of Design and Crafts",
          "website": "http://www.hdk.gu.se/"
       },
       {
          "university": "Södertörn University College",
          "website": "http://www.sh.se/"
       },
       {
          "university": "Stockholm Institute of Education",
          "website": "http://www.lhs.se/"
       },
       {
          "university": "Stockholm School of Economics",
          "website": "http://www.hhs.se/"
       },
       {
          "university": "Stockholm University",
          "website": "http://www.su.se/"
       },
       {
          "university": "Swedish University of Agricultural Sciences",
          "website": "http://www.slu.se/"
       },
       {
          "university": "Umea University",
          "website": "http://www.umu.se/"
       },
       {
          "university": "University College of Arts - Crafts and Design",
          "website": "http://www.konstfack.se/"
       },
       {
          "university": "University College of Borås",
          "website": "http://www.hb.se/"
       },
       {
          "university": "University College of Gävle",
          "website": "http://www.hig.se/"
       },
       {
          "university": "University College of Kristianstad",
          "website": "http://www.hkr.se/"
       },
       {
          "university": "University College of Skövde",
          "website": "http://www.his.se/"
       },
       {
          "university": "University College of Trollhättan/Uddevalla",
          "website": "http://www.htu.se/"
       },
       {
          "university": "Uppsala University",
          "website": "http://www.uu.se/"
       },
       {
          "university": "Växjö University",
          "website": "http://www.vxu.se/"
       },
       {
          "university": "World Maritime University",
          "website": "http://www.wmu.se/"
       }
    ],
    "SG": [
       {
          "university": "Aventis School of Management",
          "website": "http://www.aventis.edu.sg/"
       },
       {
          "university": "Nanyang Technological University",
          "website": "http://www.ntu.edu.sg/"
       },
       {
          "university": "National University of Singapore",
          "website": "http://www.nus.edu.sg/"
       },
       {
          "university": "Singapore Institute of Management (SIM)",
          "website": "http://www.sim.edu.sg/"
       },
       {
          "university": "Singapore Management University",
          "website": "http://www.smu.edu.sg/"
       },
       {
          "university": "Taoist College Singapore",
          "website": "http://www.taoistcollege.org.sg/"
       }
    ],
    "SI": [
       {
          "university": "University of Ljubljana",
          "website": "http://www.uni-lj.si/"
       },
       {
          "university": "University of Maribor",
          "website": "http://www.uni-mb.si/"
       },
       {
          "university": "University of Nova Gorica",
          "website": "http://www.ung.si/"
       },
       {
          "university": "University of Primorska",
          "website": "http://www.upr.si/"
       }
    ],
    "SK": [
       {
          "university": "Academy of Arts in Banská Bystrica",
          "website": "http://www.aku.sk/"
       },
       {
          "university": "Academy of Fine Arts and Design in Bratislava",
          "website": "http://www.vsvu.sk/"
       },
       {
          "university": "Armed Forces Academy of General Milan Rastislav Štefánik",
          "website": "http://www.aos.sk/"
       },
       {
          "university": "Bratislava International School of Liberal Arts",
          "website": "http://www.bisla.sk/"
       },
       {
          "university": "Catholic University in Ruzomberok",
          "website": "http://www.ku.sk/"
       },
       {
          "university": "Catholic University in Ružomberok",
          "website": "http://www.ku.sk/"
       },
       {
          "university": "College in Sládkovičovo",
          "website": "http://www.vssladkovicovo.sk/"
       },
       {
          "university": "Comenius University in Bratislava",
          "website": "http://www.uniba.sk/"
       },
       {
          "university": "International School of Management in Prešov",
          "website": "http://www.ismpo.sk/"
       },
       {
          "university": "Janos Selye University",
          "website": "http://www.selyeuni.sk/"
       },
       {
          "university": "Law University of Bratislava",
          "website": "http://www.uninova.sk/"
       },
       {
          "university": "Matej Bel University in Banská Bystrica",
          "website": "http://www.umb.sk/"
       },
       {
          "university": "Pavol Jozef Safarik University in Kosice",
          "website": "http://www.upjs.sk/"
       },
       {
          "university": "Police Academy in Bratislava",
          "website": "http://www.akademiapz.sk/"
       },
       {
          "university": "School of Management",
          "website": "http://www.vsm.sk/"
       },
       {
          "university": "Slovak Medical University",
          "website": "http:///www.szu.sk/"
       },
       {
          "university": "Slovak University of Agriculture in Nitra",
          "website": "http://www.uniag.sk/"
       },
       {
          "university": "Slovak University of Technology in Bratislava",
          "website": "http://www.stuba.sk/"
       },
       {
          "university": "St. Elizabeth’s College of Health and Social Sciences in Bratislava",
          "website": "http://www.vssvalzbety.sk/"
       },
       {
          "university": "Technical Institute of Dubnica in Dubnica nad Váhom",
          "website": "http://www.dti.sk/"
       },
       {
          "university": "Technical University in Kosice",
          "website": "http://www.tuke.sk/"
       },
       {
          "university": "Technical University in Zvolen",
          "website": "http://www.tuzvo.sk/"
       },
       {
          "university": "University Konstantina Filozov in Nitra",
          "website": "http://www.ukf.sk/"
       },
       {
          "university": "University of Alexandra Dubček in Trenčín",
          "website": "http://www.tnuni.sk/"
       },
       {
          "university": "University of Central Europe in Skalica",
          "website": "http://www.sevs.sk/"
       },
       {
          "university": "University of Economics in Bratislava",
          "website": "http://www.euba.sk/"
       },
       {
          "university": "University of Performing Arts in Bratislava",
          "website": "http://www.vsmu.sk/"
       },
       {
          "university": "University of Presov",
          "website": "http://www.unipo.sk/"
       },
       {
          "university": "University of St. Cyril and Methodius in Trnava",
          "website": "http://www.ucm.sk/"
       },
       {
          "university": "University of SV Cyril and Methodius in Trnava",
          "website": "http://www.ucm.sk/"
       },
       {
          "university": "University of Trnava",
          "website": "http://www.truni.sk/"
       },
       {
          "university": "University of Veterinary Medicine in Kosice",
          "website": "http://www.uvm.sk/"
       },
       {
          "university": "University of Zilinska",
          "website": "http://www.uniza.sk/"
       }
    ],
    "SL": [
       {
          "university": "Fourah Bay College - University of Sierra Leone",
          "website": "http://fbcusl.8k.com/"
       },
       {
          "university": "LICCSAL Business College",
          "website": "http://www.liccsalsl.org/"
       },
       {
          "university": "Njala University",
          "website": "http://www.nu-online.com/"
       },
       {
          "university": "University of Sierra Leone",
          "website": "http://www.tusol.org/"
       }
    ],
    "SM": [
       {
          "university": "University of San Marino",
          "website": "http://www.unirsm.sm/"
       }
    ],
    "SN": [
       {
          "university": "L'Université de l'Entreprise",
          "website": "http://www.afi-ue.sn/"
       },
       {
          "university": "Université Alioune Diop de Bambey",
          "website": "http://www.bambey.univ.sn/"
       },
       {
          "university": "Universite Amadou Hampaté Ba",
          "website": "http://www.uahb.sn/"
       },
       {
          "university": "Université Cheikh Anta Diop",
          "website": "http://www.ucad.sn/"
       },
       {
          "university": "Université Dakar Bourguiba",
          "website": "http://www.udb.sn/"
       },
       {
          "university": "Université de Thiès",
          "website": "http://www.univ-thies.sn/"
       },
       {
          "university": "Université de Ziguinchor",
          "website": "http://www.univ-zig.sn/"
       },
       {
          "university": "Université du Sahel",
          "website": "http://www.unis.sn/"
       },
       {
          "university": "Université Euro-Afrique",
          "website": "http://www.uea.edu.sn/"
       },
       {
          "university": "Université Gaston Berger Saint-Louis",
          "website": "http://www.ugb.sn/"
       }
    ],
    "SO": [
       {
          "university": "Amoud University",
          "website": "http://www.amouduniversity.org/"
       },
       {
          "university": "Benadir University",
          "website": "http://www.benadiruniversity.net/"
       },
       {
          "university": "Bosaso College",
          "website": "http://www.bosasocollege.com/"
       },
       {
          "university": "East Africa University Bosaso",
          "website": "http://www.eastafricauniversity.net/"
       },
       {
          "university": "East Somalia University",
          "website": "http://www.eastsomaliauniversity.com/"
       },
       {
          "university": "Eelo American University",
          "website": "http://www.eelouniversity.org/"
       },
       {
          "university": "Gollis University",
          "website": "http://www.gollisuniversity.com/"
       },
       {
          "university": "Hiiraan University",
          "website": "http://www.hiiraanuniversity.info/"
       },
       {
          "university": "Maakhir University",
          "website": "http://maakhiruniversity.net/"
       },
       {
          "university": "Mogadishu University",
          "website": "http://www.mogadishuuniversity.com/"
       },
       {
          "university": "Nugaal University",
          "website": "http://www.nugaaluniversity.com/"
       },
       {
          "university": "Puntland State University",
          "website": "http://www.puntlandstateuniversity.com/"
       },
       {
          "university": "Sanaag University of Science and Technology",
          "website": "http://www.sanaaguniversity.com/"
       },
       {
          "university": "Somaliland University of Technology",
          "website": "http://www.somtech.org/"
       },
       {
          "university": "University of Burao",
          "website": "http://www.buraouniversity.com/"
       },
       {
          "university": "University of Hargeisa",
          "website": "http://www.hargeisauniversity.net/"
       },
       {
          "university": "University of Somalia",
          "website": "http://www.universityofsomalia.net/"
       }
    ],
    "SR": [
       {
          "university": "Anton de Kom University",
          "website": "http://www.uvs.edu/"
       }
    ],
    "SS": [
       {
          "university": "University of Juba",
          "website": "http://www.juba.edu.sd/"
       },
       {
          "university": "University of Northern Bahr El-Ghazal",
          "website": "http://www.unbeg.edu.sd/"
       }
    ],
    "SV": [
       {
          "university": "Universidad Albert Einstein",
          "website": "http://www.uae.edu.sv/"
       },
       {
          "university": "Universidad Autónoma de Santa Ana",
          "website": "http://www.unasa.edu.sv/"
       },
       {
          "university": "Universidad Capitain General Gerardo Barrios",
          "website": "http://www.ugb.edu.sv/"
       },
       {
          "university": "Universidad Católica de Occidente",
          "website": "http://www.unico.edu.sv/"
       },
       {
          "university": "Universidad Centroamericana José Simeón Canas",
          "website": "http://www.uca.edu.sv/"
       },
       {
          "university": "Universidad Cristiana de las Asambleas de Dios",
          "website": "http://www.ucad.edu.sv/"
       },
       {
          "university": "Universidad de El Salvador",
          "website": "http://www.ues.edu.sv/"
       },
       {
          "university": "Universidad de Oriente",
          "website": "http://www.univo.edu.sv/"
       },
       {
          "university": "Universidad de Sonsonate",
          "website": "http://www.usonsonate.edu.sv/"
       },
       {
          "university": "Universidad Don Bosco",
          "website": "http://www.cdb.edu.sv/"
       },
       {
          "university": "Universidad Dr. Andreas Bello",
          "website": "http://www.unab.edu.sv/"
       },
       {
          "university": "Universidad Dr. Jose Matias Delgado",
          "website": "http://www.ujmd.edu.sv/"
       },
       {
          "university": "Universidad Evangélica de El Salvador",
          "website": "http://www.uees.edu.sv/"
       },
       {
          "university": "Universidad Francisco Gavidia",
          "website": "http://www.ufg.edu.sv/"
       },
       {
          "university": "Universidad Isaac Newton",
          "website": "http://www.usin.edu.sv/"
       },
       {
          "university": "Universidad Luterana Salvadorena",
          "website": "http://www.netcomsa.com/uls/"
       },
       {
          "university": "Universidad Modular Abierta",
          "website": "http://www.uma.edu.sv/"
       },
       {
          "university": "Universidad Monsenor Oscar Arnulfo Romeo",
          "website": "http://www.umoar.edu.sv/"
       },
       {
          "university": "Universidad Nueva San Salvador",
          "website": "http://www.unssa.edu.sv/"
       },
       {
          "university": "Universidad Panamericana de San Salvador",
          "website": "http://www.upan.edu.sv/"
       },
       {
          "university": "Universidad Pedagógica de El Salvador",
          "website": "http://www.universidadpedagogica.com/"
       },
       {
          "university": "Universidad Politécnica de El Salvador",
          "website": "http://www.upes.edu.sv/"
       },
       {
          "university": "Universidad Salvadorena Alberto Masferrer",
          "website": "http://www.usam.edu.sv/"
       },
       {
          "university": "Universidad Técnica Latinoamericana",
          "website": "http://www.utla.edu.sv/"
       },
       {
          "university": "Universidad Tecnológica de El Salvador",
          "website": "http://www.utec.edu.sv/"
       }
    ],
    "SY": [
       {
          "university": "Al-Baath University",
          "website": "http://www.albaath-univ.edu.sy/"
       },
       {
          "university": "Al-Wataniya Private University",
          "website": "http://www.wpu.edu.sy/"
       },
       {
          "university": "Arab European University",
          "website": "http://www.aiu.edu.sy/"
       },
       {
          "university": "Damascus University",
          "website": "http://www.damascusuniversity.edu.sy/"
       },
       {
          "university": "Higher Institute of Business Administration",
          "website": "http://www.hiba.edu.sy/"
       },
       {
          "university": "International University for Science & Technology ",
          "website": "http://www.iust.edu.sy/"
       },
       {
          "university": "Ittihad Private University",
          "website": "http://www.ipu.edu.sy/"
       },
       {
          "university": "Mamoun Private University for Science and Technology",
          "website": "http://www.must.edu.sy/"
       },
       {
          "university": "Syrian International Private University for Science & Technology",
          "website": "http://www.siust.edu.sy/"
       },
       {
          "university": "Syrian Virtual University",
          "website": "http://www.svuonline.org/"
       },
       {
          "university": "Tishreen University",
          "website": "http://www.tishreen.edu.sy/"
       },
       {
          "university": "University of Aleppo",
          "website": "http://www.alepuniv.edu.sy/"
       },
       {
          "university": "University of Kalamoon",
          "website": "http://www.uok.edu.sy/"
       },
       {
          "university": "Wadi International University",
          "website": "http://www.wiu.edu.sy/"
       },
       {
          "university": "Yarmouk Private University ",
          "website": "http://www.ypu.edu.sy/"
       }
    ],
    "SZ": [
       {
          "university": "University of Swaziland",
          "website": "http://www.uniswa.sz/"
       }
    ],
    "TC": [
       {
          "university": "Charisma University",
          "website": "http://www.charismauniversity.org/"
       }
    ],
    "TD": [
       {
          "university": "Université de N'Djamena",
          "website": "http://www.univ-ndjamena.org/"
       }
    ],
    "TG": [
       {
          "university": "Université de Lomé",
          "website": "http://www.univ-lome.tg/"
       }
    ],
    "TH": [
       {
          "university": "Asian Institute of Technology",
          "website": "http://www.ait.ac.th/"
       },
       {
          "university": "Asian University of Science and Technology",
          "website": "http://www.asianust.ac.th/"
       },
       {
          "university": "Assumption University of Thailand",
          "website": "http://www.au.edu/"
       },
       {
          "university": "Bangkok University",
          "website": "http://www.bu.ac.th/"
       },
       {
          "university": "Burapha University",
          "website": "http://www.buu.ac.th/"
       },
       {
          "university": "Chaopraya University ",
          "website": "http://www.cpu.ac.th/"
       },
       {
          "university": "Chiang Mai Rajabhat University",
          "website": "http://www.cmru.ac.th/"
       },
       {
          "university": "Chiang Mai University",
          "website": "http://www.chiangmai.ac.th/"
       },
       {
          "university": "Chiang Mai Vocational College",
          "website": "http://www.cmvc.ac.th/"
       },
       {
          "university": "Chulalongkorn University",
          "website": "http://www.chula.ac.th/"
       },
       {
          "university": "Dhurakijpundit University",
          "website": "http://www.dpu.ac.th/"
       },
       {
          "university": "Eastern Asia University",
          "website": "http://www.eau.ac.th/"
       },
       {
          "university": "Engineering Faculty of Asian University",
          "website": "http://www.efau.org/"
       },
       {
          "university": "Hatyai University",
          "website": "http://www.hu.ac.th/"
       },
       {
          "university": "Huachiew Chalermprakiet University",
          "website": "http://www.hcu.ac.th/"
       },
       {
          "university": "International Buddhist College",
          "website": "http://www.ibc.ac.th/"
       },
       {
          "university": "Kasem Bundit University",
          "website": "http://www.kbu.ac.th/"
       },
       {
          "university": "Kasetsart University",
          "website": "http://www.ku.ac.th/"
       },
       {
          "university": "Khon Kaen University",
          "website": "http://www.kku.ac.th/"
       },
       {
          "university": "King Mongkut's University of Technology Ladkrabang",
          "website": "http://www.kmitl.ac.th/"
       },
       {
          "university": "King Mongkut's University of Technology North Bangkok",
          "website": "http://www.kmutnb.ac.th/"
       },
       {
          "university": "King Mongkut's University of Technology Thonburi",
          "website": "http://www.kmutt.ac.th/"
       },
       {
          "university": "Krirk University",
          "website": "http://www.krirk.ac.th/"
       },
       {
          "university": "Lampang College of Commerce and Technology",
          "website": "http://www.lcct.ac.th/"
       },
       {
          "university": "Lampang Rajabhat University",
          "website": "http://www.lpru.ac.th/"
       },
       {
          "university": "Lampang Vocational College   ",
          "website": "http://www.lampangvc.ac.th/"
       },
       {
          "university": "Lamphun College of Agriculture and Technology",
          "website": "http://www.lcat.ac.th/"
       },
       {
          "university": "Mae Fah Luang University ",
          "website": "http://www.mfu.ac.th/"
       },
       {
          "university": "Maejo University",
          "website": "http://www.mju.ac.th/"
       },
       {
          "university": "Mahachulalongkorn Buddhist University",
          "website": "http://www.mcu.ac.th/"
       },
       {
          "university": "Mahamakut Buddhist University",
          "website": "http://www.mbu.ac.th/"
       },
       {
          "university": "Mahanakorn University of Technology",
          "website": "http://www.mut.ac.th/"
       },
       {
          "university": "Mahasarakham University",
          "website": "http://www.msu.ac.th/"
       },
       {
          "university": "Mahidol University",
          "website": "http://www.mahidol.ac.th/"
       },
       {
          "university": "Mahidol University International College",
          "website": "http://www.muic.mahidol.ac.th/"
       },
       {
          "university": "Naresuan University",
          "website": "http://www.nu.ac.th/"
       },
       {
          "university": "National Institute of Development Administration",
          "website": "http://www.nida.ac.th/"
       },
       {
          "university": "Nivadhana University",
          "website": "http://www.nivadhana.ac.th/"
       },
       {
          "university": "Pathumthani University",
          "website": "http://www.ptu.ac.th/"
       },
       {
          "university": "Payap University Chaiang Mai",
          "website": "http://www.payap.ac.th/"
       },
       {
          "university": "Phranakhon Si Ayutthaya Rajabhat University",
          "website": "http://www.aru.ac.th/"
       },
       {
          "university": "Prince of Songkla University",
          "website": "http://www.psu.ac.th/"
       },
       {
          "university": "Rajamangala University of Technology - Lanna Chiang Rai",
          "website": "http://chiangraicampus.rmutl.ac.th/"
       },
       {
          "university": "Rajamangala University of Technology - Lanna Nan",
          "website": "http://www.nan.rmutl.ac.th/"
       },
       {
          "university": "Rajamangala University of Technology - Phra Nakhon",
          "website": "http://www.rmutp.ac.th/"
       },
       {
          "university": "Ramkhamhaeng University",
          "website": "http://www.ru.ac.th/"
       },
       {
          "university": "Rangsit University",
          "website": "http://www.rsu.ac.th/"
       },
       {
          "university": "Shinawatra University",
          "website": "http://www.shinawatra.ac.th/"
       },
       {
          "university": "Siam Technology College",
          "website": "http://www.siamtechu.net/"
       },
       {
          "university": "Siam University",
          "website": "http://www.siam.edu/"
       },
       {
          "university": "Silpakorn University",
          "website": "http://www.su.ac.th/"
       },
       {
          "university": "South-East Asia University",
          "website": "http://www.sau.ac.th/"
       },
       {
          "university": "Srinakharinwirot University",
          "website": "http://www.swu.ac.th/"
       },
       {
          "university": "Sripatum University",
          "website": "http://www.spu.ac.th/"
       },
       {
          "university": "Stamford International University",
          "website": "http://www.stamford.edu/"
       },
       {
          "university": "St. John's University",
          "website": "http://www.stjohn.ac.th/"
       },
       {
          "university": "Suan Dusit Rajabhat University",
          "website": "http://www.dusit.ac.th/"
       },
       {
          "university": "Sukhothai Thammathirat Open University",
          "website": "http://www.stou.ac.th/"
       },
       {
          "university": "Suranaree University of Technology",
          "website": "http://www.sut.ac.th/"
       },
       {
          "university": "Thaksin University",
          "website": "http://www.tsu.ac.th/"
       },
       {
          "university": "Thammasat University",
          "website": "http://www.tu.ac.th/"
       },
       {
          "university": "Ubonratchathani University",
          "website": "http://www.ubu.ac.th/"
       },
       {
          "university": "University of the Thai Chamber of Commerce",
          "website": "http://www.utcc.ac.th/"
       },
       {
          "university": "Vongchavalitkul University",
          "website": "http://www.vu.ac.th/"
       },
       {
          "university": "Walailak University",
          "website": "http://www.wu.ac.th/"
       },
       {
          "university": "Webster University - Thailand",
          "website": "http://www.webster.ac.th/"
       },
       {
          "university": "Yonok University",
          "website": "http://www.yonok.ac.th/"
       }
    ],
    "TJ": [
       {
          "university": "Tajik Agrarian University",
          "website": "http://www.tajagroun.tj/"
       },
       {
          "university": "Tajikistan International University",
          "website": "http://www.tiu.tj/"
       },
       {
          "university": "Tajik National University",
          "website": "http://tgnu.tarena.tj/"
       }
    ],
    "TM": [
       {
          "university": "International Turkmen Turkish University",
          "website": "http://www.ittu.edu.tm/"
       }
    ],
    "TN": [
       {
          "university": "Instituts Supérieurs de Formation des Maîtres",
          "website": "http://www.disfm.rnu.tn/"
       },
       {
          "university": "Instituts Supérieurs des Etudes Technologiques",
          "website": "http://www.isetr.rnu.tn/"
       },
       {
          "university": "Institut Supérieur de Gestion de Tunis",
          "website": "http://www.isg.rnu.tn/"
       },
       {
          "university": "Université Centrale",
          "website": "http://www.ucaat.com/"
       },
       {
          "university": "Université de 7 Novembre à Carthage",
          "website": "http://u7nc.uvt.rnu.tn/"
       },
       {
          "university": "Université de Gabès",
          "website": "http://www.univgb.rnu.tn/"
       },
       {
          "university": "Université de Gafsa",
          "website": "http://www.ugaf.rnu.tn/"
       },
       {
          "university": "Université de Jendouba",
          "website": "http://www.uj.rnu.tn/"
       },
       {
          "university": "Université de Kairouan",
          "website": "http://www.univ-k.rnu.tn/"
       },
       {
          "university": "Université de la Manouba",
          "website": "http://www.uma.rnu.tn/"
       },
       {
          "university": "Université de Monastir",
          "website": "http://www.um.rnu.tn/"
       },
       {
          "university": "Université de Sfax",
          "website": "http://www.uss.rnu.tn/"
       },
       {
          "university": "Université de Tunis",
          "website": "http://www.utunis.rnu.tn/"
       },
       {
          "university": "Université de Tunis El Manar",
          "website": "http://www.utm.rnu.tn/"
       },
       {
          "university": "Université du Centre - Sousse",
          "website": "http://www.uc.rnu.tn/"
       },
       {
          "university": "Université Ez-Zitouna",
          "website": "http://www.uz.rnu.tn/"
       },
       {
          "university": "Université Libre de Tunis",
          "website": "http://www.ult.ens.tn/"
       },
       {
          "university": "Université Virtuelle de Tunis",
          "website": "http://www.uvt.rnu.tn/"
       },
       {
          "university": "University of Aviation and Technology",
          "website": "http://www.unat.ens.tn/"
       }
    ],
    "TO": [
       {
          "university": "University of the South Pacific Centre",
          "website": "http://www.usp.ac.fj/"
       }
    ],
    "TR": [
       {
          "university": "Abant Izzet Baysal University",
          "website": "http://www.ibu.edu.tr/"
       },
       {
          "university": "Adnan Menderes University",
          "website": "http://www.adu.edu.tr/"
       },
       {
          "university": "Afyon Kocatepe University",
          "website": "http://www.aku.edu.tr/"
       },
       {
          "university": "Ahi Evran University",
          "website": "http://www.ahievran.edu.tr/"
       },
       {
          "university": "Akdeniz University",
          "website": "http://www.akdeniz.edu.tr/"
       },
       {
          "university": "Anadolu University",
          "website": "http://www.anadolu.edu.tr/"
       },
       {
          "university": "Ankara University",
          "website": "http://www.ankara.edu.tr/"
       },
       {
          "university": "Atatürk University",
          "website": "http://www.atauni.edu.tr/"
       },
       {
          "university": "Atilim University",
          "website": "http://www.atilim.edu.tr/"
       },
       {
          "university": "Bahcesehir University",
          "website": "http://www.bahcesehir.edu.tr/"
       },
       {
          "university": "Balikesir University",
          "website": "http://www.balikesir.edu.tr/"
       },
       {
          "university": "Baskent University",
          "website": "http://www.baskent.edu.tr/"
       },
       {
          "university": "Beykent University",
          "website": "http://www.beykent.edu.tr/"
       },
       {
          "university": "Bilecik University",
          "website": "http://www.bilecik.edu.tr/"
       },
       {
          "university": "Bilkent University",
          "website": "http://www.bilkent.edu.tr/"
       },
       {
          "university": "Bogazici University",
          "website": "http://www.boun.edu.tr/"
       },
       {
          "university": "Cag University",
          "website": "http://www.cag.edu.tr/"
       },
       {
          "university": "Canakkale (18th March) University",
          "website": "http://www.comu.edu.tr/"
       },
       {
          "university": "Cankaya University",
          "website": "http://www.cankaya.edu.tr/"
       },
       {
          "university": "Celal Bayar University",
          "website": "http://www.bayar.edu.tr/"
       },
       {
          "university": "Cukurova University",
          "website": "http://www.cu.edu.tr/"
       },
       {
          "university": "Cumhuriyet (Republik) University",
          "website": "http://www.cumhuriyet.edu.tr/"
       },
       {
          "university": "Dicle (Tirgris) University",
          "website": "http://www.dicle.edu.tr/"
       },
       {
          "university": "Dogus University",
          "website": "http://www.dogus.edu.tr/"
       },
       {
          "university": "Dokuz Eylül University",
          "website": "http://www.deu.edu.tr/"
       },
       {
          "university": "Dumlupinar University",
          "website": "http://www.dumlupinar.edu.tr/"
       },
       {
          "university": "Ege University",
          "website": "http://www.ege.edu.tr/"
       },
       {
          "university": "Erciyes University",
          "website": "http://www.erciyes.edu.tr/"
       },
       {
          "university": "Fatih University",
          "website": "http://www.fatih.edu.tr/"
       },
       {
          "university": "Firat (Euphrates) University",
          "website": "http://www.firat.edu.tr/"
       },
       {
          "university": "Galatasaray University",
          "website": "http://www.gsu.edu.tr/"
       },
       {
          "university": "Gaziantep University",
          "website": "http://www.gantep.edu.tr/"
       },
       {
          "university": "Gaziosmanpasa University",
          "website": "http://www.gop.edu.tr/"
       },
       {
          "university": "Gazi University Ankara",
          "website": "http://www.gazi.edu.tr/"
       },
       {
          "university": "Gebze Institute of Technology",
          "website": "http://www.gyte.edu.tr/"
       },
       {
          "university": "Gediz University",
          "website": "http://www.gediz.edu.tr/"
       },
       {
          "university": "Gulhane Military Medical Academy",
          "website": "http://www.gata.edu.tr/"
       },
       {
          "university": "Hacettepe University",
          "website": "http://www.hun.edu.tr/"
       },
       {
          "university": "Halic University",
          "website": "http://www.halic.edu.tr/"
       },
       {
          "university": "Harran University",
          "website": "http://www.harran.edu.tr/"
       },
       {
          "university": "Iasar University",
          "website": "http://www.yasar.edu.tr/"
       },
       {
          "university": "Inönü University",
          "website": "http://www.inonu.edu.tr/"
       },
       {
          "university": "Isik University",
          "website": "http://www.isikun.edu.tr/"
       },
       {
          "university": "Istanbul Arel University",
          "website": "http://www.arel.edu.tr/"
       },
       {
          "university": "Istanbul Bilgi University",
          "website": "http://www.ibun.edu.tr/"
       },
       {
          "university": "Istanbul Kultur University",
          "website": "http://www.iku.edu.tr/"
       },
       {
          "university": "İstanbul Şehir University",
          "website": "http://www.sehir.edu.tr/"
       },
       {
          "university": "Istanbul Technical University",
          "website": "http://www.itu.edu.tr/"
       },
       {
          "university": "Istanbul Ticaret University",
          "website": "http://www.iticu.edu.tr/"
       },
       {
          "university": "Istanbul University",
          "website": "http://www.istanbul.edu.tr/"
       },
       {
          "university": "Izmir Economic University",
          "website": "http://www.izmirekonomi.edu.tr/"
       },
       {
          "university": "Izmir Institute of Technology",
          "website": "http://www.iyte.edu.tr/"
       },
       {
          "university": "Kadir Has University",
          "website": "http://www.khas.edu.tr/"
       },
       {
          "university": "Kafkas University",
          "website": "http://www.kafkas.edu.tr/"
       },
       {
          "university": "Kahramanmaras Sütcü Imam University",
          "website": "http://www.ksu.edu.tr/"
       },
       {
          "university": "Karadeniz Technical University",
          "website": "http://www.ktu.edu.tr/"
       },
       {
          "university": "Kilis 7 Aralık University",
          "website": "http://www.kilis.edu.tr/"
       },
       {
          "university": "Kirikkale University",
          "website": "http://www.kku.edu.tr/"
       },
       {
          "university": "Kocaeli University",
          "website": "http://www.kou.edu.tr/"
       },
       {
          "university": "Koc University",
          "website": "http://www.ku.edu.tr/"
       },
       {
          "university": "Maltepe University",
          "website": "http://www.maltepe.edu.tr/"
       },
       {
          "university": "Marmara University",
          "website": "http://www.marmara.edu.tr/"
       },
       {
          "university": "Mersin University",
          "website": "http://www.mersin.edu.tr/"
       },
       {
          "university": "Middle East Technical University",
          "website": "http://www.odtu.edu.tr/"
       },
       {
          "university": "Mimar Sinan University",
          "website": "http://www.msu.edu.tr/"
       },
       {
          "university": "Mugla University",
          "website": "http://www.mu.edu.tr/"
       },
       {
          "university": "Mustafa Kemal University",
          "website": "http://www.mku.edu.tr/"
       },
       {
          "university": "Namik Kemal University",
          "website": "http://www.nku.edu.tr/"
       },
       {
          "university": "Necmettin Erbakan University",
          "website": "http://www.konya.edu.tr/"
       },
       {
          "university": "Nigde University",
          "website": "http://www.nigde.edu.tr/"
       },
       {
          "university": "Ondokuz Mayis University Samsun",
          "website": "http://www.omu.edu.tr/"
       },
       {
          "university": "Osmangazi University",
          "website": "http://www.ogu.edu.tr/"
       },
       {
          "university": "Ozyegin University",
          "website": "http://www.ozyegin.edu.tr/"
       },
       {
          "university": "Pamukkale University",
          "website": "http://www.pamukkale.edu.tr/"
       },
       {
          "university": "Sabanci University",
          "website": "http://www.sabanciuniv.edu.tr/"
       },
       {
          "university": "Sakarya University",
          "website": "http://www.sau.edu.tr/"
       },
       {
          "university": "Selcuk University",
          "website": "http://www.selcuk.edu.tr/"
       },
       {
          "university": "Suleyman Demirel University",
          "website": "http://www.sdu.edu.tr/"
       },
       {
          "university": "Tobb Economics and Technology University",
          "website": "http://www.etu.edu.tr/"
       },
       {
          "university": "Trakya University",
          "website": "http://www.trakya.edu.tr/"
       },
       {
          "university": "Türkisch-Deutsche Universität",
          "website": "http://www.tau.edu.tr/"
       },
       {
          "university": "Turkish Military Academy",
          "website": "http://www.kho.edu.tr/"
       },
       {
          "university": "Turkish Naval Academy",
          "website": "http://www.dho.edu.tr/"
       },
       {
          "university": "Ufuk University",
          "website": "http://www.ufuk.edu.tr/"
       },
       {
          "university": "Uludag University",
          "website": "http://www.uludag.edu.tr/"
       },
       {
          "university": "Yalova University",
          "website": "http://www.yalova.edu.tr/"
       },
       {
          "university": "Yasar University",
          "website": "http://www.yasar.edu.tr/"
       },
       {
          "university": "Yeditepe University",
          "website": "http://www.yeditepe.edu.tr/"
       },
       {
          "university": "Yildirim Beyazit University",
          "website": "http://www.ybu.edu.tr/"
       },
       {
          "university": "Yildiz Technical University",
          "website": "http://www.yildiz.edu.tr/"
       },
       {
          "university": "Yüzüncü Yil (Centennial) University",
          "website": "http://www.yyu.edu.tr/"
       },
       {
          "university": "Zonguldak Karaelmas University",
          "website": "http://www.karaelmas.edu.tr/"
       }
    ],
    "TT": [
       {
          "university": "University of the Southern Caribbean",
          "website": "http://www.usc.edu.tt/"
       },
       {
          "university": "University of the West Indies St. Augustine",
          "website": "http://www.uwi.tt/"
       },
       {
          "university": "University of Trinidad and Tobago",
          "website": "http://www.utt.edu.tt/"
       }
    ],
    "TW": [
       {
          "university": "Aletheia University",
          "website": "http://www.au.edu.tw/"
       },
       {
          "university": "Central Police University",
          "website": "http://www.cpu.edu.tw/"
       },
       {
          "university": "Chang Gung University",
          "website": "http://www.cgu.edu.tw/"
       },
       {
          "university": "Chang Jung University",
          "website": "http://www.cju.edu.tw/"
       },
       {
          "university": "Chao Yang University of Science and Technology",
          "website": "http://www.cyut.edu.tw/"
       },
       {
          "university": "Chia Nana College of Pharmacy and Science",
          "website": "http://www.chna.edu.tw/"
       },
       {
          "university": "China Medical College",
          "website": "http://www.cmc.edu.tw/"
       },
       {
          "university": "Chinese Culture University",
          "website": "http://www.pccu.edu.tw/"
       },
       {
          "university": "Ching Kuo Institue of Management & Health",
          "website": "http://www.cku.edu.tw/"
       },
       {
          "university": "Chung Hua University",
          "website": "http://www.chu.edu.tw/"
       },
       {
          "university": "Chung Shan Medical and Dental College",
          "website": "http://www.csmc.edu.tw/"
       },
       {
          "university": "Chung Yuan Christian University",
          "website": "http://www.cycu.edu.tw/"
       },
       {
          "university": "Da-Yeh University",
          "website": "http://www.dyu.edu.tw/"
       },
       {
          "university": "Feng Chia University",
          "website": "http://www.fcu.edu.tw/"
       },
       {
          "university": "Fu Jen Catholic University",
          "website": "http://www.fju.edu.tw/"
       },
       {
          "university": "Hsuan Chuang University",
          "website": "http://www.hcu.edu.tw/"
       },
       {
          "university": "Huafan University",
          "website": "http://www.hfu.edu.tw/"
       },
       {
          "university": "I-Shou University",
          "website": "http://www.isu.edu.tw/"
       },
       {
          "university": "Kaohsiung Medical College",
          "website": "http://www.kmc.edu.tw/"
       },
       {
          "university": "Kaohsuing Open University",
          "website": "http://www.ouk.edu.tw/"
       },
       {
          "university": "Kung Shan Institute of Technology",
          "website": "http://www.ksit.edu.tw/"
       },
       {
          "university": "Kun Shan University",
          "website": "http://www.ksu.edu.tw/"
       },
       {
          "university": "Ling Tung University",
          "website": "http://www.ltc.edu.tw/"
       },
       {
          "university": "LungHwa University of Science and Technology",
          "website": "http://www.lhu.edu.tw/"
       },
       {
          "university": "Ming Chuan University",
          "website": "http://www.mcu.edu.tw/"
       },
       {
          "university": "Nanhua University",
          "website": "http://www.nhu.edu.tw/"
       },
       {
          "university": "National Central University",
          "website": "http://www.ncu.edu.tw/"
       },
       {
          "university": "National Changhua University of Education",
          "website": "http://www.ncue.edu.tw/"
       },
       {
          "university": "National Chengchi University",
          "website": "http://www.nccu.edu.tw/"
       },
       {
          "university": "National Cheng Kung University",
          "website": "http://english.web.ncku.edu.tw/"
       },
       {
          "university": "National Chiao Tung University",
          "website": "http://www.nctu.edu.tw/"
       },
       {
          "university": "National Chiayi University",
          "website": "http://www.ncyu.edu.tw/"
       },
       {
          "university": "National Chi Nan University",
          "website": "http://www.ncnu.edu.tw/"
       },
       {
          "university": "National Chung Cheng University",
          "website": "http://www.ccu.edu.tw/"
       },
       {
          "university": "National Chung Hsing University - Taichung",
          "website": "http://www.nchu.edu.tw/"
       },
       {
          "university": "National Chung Hsing University - Taipei",
          "website": "http://www.nchulc.edu.tw/"
       },
       {
          "university": "National College of Physical Education and Sports",
          "website": "http://www.ncpes.edu.tw/"
       },
       {
          "university": "National Dong Hwa University",
          "website": "http://www.ndhu.edu.tw/"
       },
       {
          "university": "National Formosa University",
          "website": "http://www.nfu.edu.tw/"
       },
       {
          "university": "National Hsin-Chu Teachers College",
          "website": "http://www.nhctc.edu.tw/"
       },
       {
          "university": "National Hualien Teachers College",
          "website": "http://www.nhltc.edu.tw/"
       },
       {
          "university": "National Ilan University",
          "website": "http://www.niu.edu.tw/"
       },
       {
          "university": "National Institute of the Arts",
          "website": "http://www.nia.edu.tw/"
       },
       {
          "university": "National Kaohsiung First University of Science and Technology",
          "website": "http://www.nkfu.edu.tw/"
       },
       {
          "university": "National Kaohsiung Normal University",
          "website": "http://www.nknu.edu.tw/"
       },
       {
          "university": "National Kaohsiung University of Applied Sciences",
          "website": "http://www.kuas.edu.tw/"
       },
       {
          "university": "National Open University",
          "website": "http://www.nou.edu.tw/"
       },
       {
          "university": "National Pingtung Teachers College",
          "website": "http://www.npttc.edu.tw/"
       },
       {
          "university": "National Pingtung University of Science and Technology",
          "website": "http://www.npust.edu.tw/"
       },
       {
          "university": "National Sun Yat-Sen University",
          "website": "http://www.nsysu.edu.tw/"
       },
       {
          "university": "National Taichung Teachers College",
          "website": "http://www.ntctc.edu.tw/"
       },
       {
          "university": "National Tainan Teachers College",
          "website": "http://www.ntntc.edu.tw/"
       },
       {
          "university": "National Taipei College of Nursing",
          "website": "http://www.ntcn.edu.tw/"
       },
       {
          "university": "National Taipei Teachers College",
          "website": "http://www.ntptc.edu.tw/"
       },
       {
          "university": "National Taipei University",
          "website": "http://www.ntpu.edu.tw/"
       },
       {
          "university": "National Taipei University of Technology",
          "website": "http://www.ntut.edu.tw/"
       },
       {
          "university": "National Taitung Teachers College",
          "website": "http://www.ntttc.edu.tw/"
       },
       {
          "university": "National Taiwan College of Arts",
          "website": "http://www.ntca.edu.tw/"
       },
       {
          "university": "National Taiwan College of Physical Education and Sports",
          "website": "http://www.ntcpe.edu.tw/"
       },
       {
          "university": "National Taiwan College of the Arts",
          "website": "http://www.ntca.edu.tw/"
       },
       {
          "university": "National Taiwan Normal University",
          "website": "http://www.ntnu.edu.tw/"
       },
       {
          "university": "National Taiwan Ocean University",
          "website": "http://www.ntou.edu.tw/"
       },
       {
          "university": "National Taiwan University",
          "website": "http://www.ntu.edu.tw/"
       },
       {
          "university": "National Taiwan University of Science and Technology",
          "website": "http://www.ntust.edu.tw/"
       },
       {
          "university": "National Tsinghua University",
          "website": "http://www.nthu.edu.tw/"
       },
       {
          "university": "National United University",
          "website": "http://www.nuu.edu.tw/"
       },
       {
          "university": "National University of Kaohsiung",
          "website": "http://www.nuk.edu.tw/"
       },
       {
          "university": "National Yang Ming Medical College",
          "website": "http://www.ym.edu.tw/"
       },
       {
          "university": "National Yunlin University of Science and Technology",
          "website": "http://www.yuntech.edu.tw/"
       },
       {
          "university": "Providence University",
          "website": "http://www.pu.edu.tw/"
       },
       {
          "university": "Shih Chien University",
          "website": "http://www.scc.edu.tw/"
       },
       {
          "university": "Shih Hsin University",
          "website": "http://www.shu.edu.tw/"
       },
       {
          "university": "Soochow University",
          "website": "http://www.scu.edu.tw/"
       },
       {
          "university": "Southern Taiwan University of Technology",
          "website": "http://www.stut.edu.tw/"
       },
       {
          "university": "St. John's & St. Mary's Institute of Technology",
          "website": "http://www.sjsmit.edu.tw/"
       },
       {
          "university": "TaiChung Healthcare and Management University",
          "website": "http://www.thmu.edu.tw/"
       },
       {
          "university": "Tainan National College of the Arts",
          "website": "http://www.tnca.edu.tw/"
       },
       {
          "university": "Taipei Medical College",
          "website": "http://www.tmc.edu.tw/"
       },
       {
          "university": "Taipei Municipal Teachers College",
          "website": "http://www.tmtc.edu.tw/"
       },
       {
          "university": "Taipei Physical Education College",
          "website": "http://www.tpec.edu.tw/"
       },
       {
          "university": "Tamkang University",
          "website": "http://www.tku.edu.tw/"
       },
       {
          "university": "Tatung Institute of Technology",
          "website": "http://www.ttit.edu.tw/"
       },
       {
          "university": "TaTung University ",
          "website": "http://www.ttu.edu.tw/"
       },
       {
          "university": "Tunghai University",
          "website": "http://www.thu.edu.tw/"
       },
       {
          "university": "Tzu Chi College of Medicine and Humanities",
          "website": "http://www.tcu.edu.tw/"
       },
       {
          "university": "Yuan Ze University",
          "website": "http://www.yzu.edu.tw/"
       }
    ],
    "TZ": [
       {
          "university": "African Virtual University",
          "website": "http://www.avu.org/"
       },
       {
          "university": "Aga Khan University",
          "website": "http://www.aku.edu/"
       },
       {
          "university": "Dares Salaam Institute of Technology",
          "website": "http://www.dit.ac.tz/"
       },
       {
          "university": "Hubert Kairuki Memorial University",
          "website": "http://www.hkmu.ac.tz/"
       },
       {
          "university": "Institute of Accountancy Arusha",
          "website": "http://www.iaa.ac.tz/"
       },
       {
          "university": "Institute of Finance Management",
          "website": "http://www.ifm.ac.tz/"
       },
       {
          "university": "International Medical & Technological University",
          "website": "http://www.imtu.edu/index.htm"
       },
       {
          "university": "Kilimanjaro Christian Medical College",
          "website": "http://www.kcmc.ac.tz/"
       },
       {
          "university": "Moshi University College of Cooperative and Business Studies",
          "website": "http://www.muccobs.ac.tz/"
       },
       {
          "university": "Mount Meru University",
          "website": "http://www.mmu.ac.tz/"
       },
       {
          "university": "Muhimbili University of Health and Allied Sciences (MUHAS)",
          "website": "http://www.muchs.ac.tz/"
       },
       {
          "university": "Mwalimu Nyerere Memorial Academy",
          "website": "http://www.mnma.ac.tz/"
       },
       {
          "university": "Mzumbe University (Chuo Kikuu Mzumbe)",
          "website": "http://www.mzumbe.ac.tz/"
       },
       {
          "university": "Open University of Tanzania",
          "website": "http://www.out.ac.tz/"
       },
       {
          "university": "Sebatian Kolowa University College",
          "website": "http://www.sekuco.org/"
       },
       {
          "university": "Sokoine University of Agriculture",
          "website": "http://www.suanet.ac.tz/"
       },
       {
          "university": "St. Augustine University of Tanzania",
          "website": "http://www.saut.ac.tz/"
       },
       {
          "university": "St. Joseph Group of Institutes",
          "website": "http://www.stjosephtanzania.com/sjcet/index.html"
       },
       {
          "university": "Tumaini University - Iringa University College",
          "website": "http://www.tumaini.ac.tz/"
       },
       {
          "university": "University of Dar Es Salaam",
          "website": "http://www.udsm.ac.tz/"
       },
       {
          "university": "University of Dodoma",
          "website": "http://www.udom.ac.tz/"
       }
    ],
    "UA": [
       {
          "university": "Alfred Nobel University of Economics and Law ",
          "website": "http://www.duep.edu/"
       },
       {
          "university": "Bila Cerkva State Agrarian University",
          "website": "http://www.btsau.kiev.ua/"
       },
       {
          "university": "Chernivci National University",
          "website": "http://www.chnu.cv.ua/"
       },
       {
          "university": "Crimea State Medical University",
          "website": "http://www.crsmu.com/"
       },
       {
          "university": "Dneprodzerzhinsk State Technical University",
          "website": "http://www.dstu.dp.ua/"
       },
       {
          "university": "Dnepropetrovsk National University",
          "website": "http://www.dnu.dp.ua/"
       },
       {
          "university": "Dnepropetrovsk National University of Railway Transport",
          "website": "http://www.diit.edu.ua/"
       },
       {
          "university": "Donetsk National Technical University",
          "website": "http://www.donntu.edu.ua/"
       },
       {
          "university": "Donetsk National University",
          "website": "http://www.donnu.edu.ua/"
       },
       {
          "university": "Donetsk State Medical University",
          "website": "http://www.donmeduni.com/"
       },
       {
          "university": "East Ukrainian National University",
          "website": "http://www.snu.edu.ua/"
       },
       {
          "university": "Ivano-Frankivsk National University of Oli and Gas",
          "website": "http://www.ifdtung.if.ua/"
       },
       {
          "university": "Kharkiv National University",
          "website": "http://www.univer.kharkov.ua/"
       },
       {
          "university": "Kharkiv National University of Economics",
          "website": "http://www.hneu.edu.ua/"
       },
       {
          "university": "Kharkiv National University of Radio Electronics",
          "website": "http://www.kture.kharkov.ua/"
       },
       {
          "university": "Kharkiv State Medical University",
          "website": "http://www.ksmu.kharkov.ua/"
       },
       {
          "university": "Kharkiv State Technical University of Construction and Architecture",
          "website": "http://www.kstuca.kharkov.ua/"
       },
       {
          "university": "Kharkiv State Transport Technical University",
          "website": "http://www.khadi.kharkov.ua/"
       },
       {
          "university": "Kherson State University",
          "website": "http://www.university.kherson.ua/"
       },
       {
          "university": "Khmelnitsky National University",
          "website": "http://www.tup.km.ua/"
       },
       {
          "university": "Kiev National Economic University Named after Vadym Hetman",
          "website": "http://www.kneu.edu.ua/"
       },
       {
          "university": "Kiev National University of Construction and Architecture",
          "website": "http://www.knuba.edu.ua/"
       },
       {
          "university": "Kiev School Of Economics",
          "website": "http://www.kse.org.ua/"
       },
       {
          "university": "Kiev Slavonic University",
          "website": "http://www.ksu.edu.ua/"
       },
       {
          "university": "KROK Economics and Law University",
          "website": "http://www.krok.edu.ua/"
       },
       {
          "university": "Luhansk State Medical University",
          "website": "http://www.lsmu.com/"
       },
       {
          "university": "Luhansk Taras Shevchenko National Pedagogical University",
          "website": "http://www.lgpu.lg.ua/"
       },
       {
          "university": "Lviv Medical University",
          "website": "http://www.meduniv.lviv.ua/"
       },
       {
          "university": "Lviv National Medical Univeristy",
          "website": "http://www.meduniv.lviv.ua/"
       },
       {
          "university": "Lviv National University Ivan Franko",
          "website": "http://www.franko.lviv.ua/"
       },
       {
          "university": "Lviv Polytechnic National University",
          "website": "http://www.lp.edu.ua/"
       },
       {
          "university": "National Aerospace University Kharkov Aviation Institute",
          "website": "http://www.khai.edu/"
       },
       {
          "university": "National Agricultural University",
          "website": "http://www.nauu.kiev.ua/"
       },
       {
          "university": "National Aviation Univesity",
          "website": "http://www.nau.edu.ua/"
       },
       {
          "university": "National Medical University O.O. Bogomolets",
          "website": "http://www.nmu.edu.ua/"
       },
       {
          "university": "National Metallurgical Academy of Ukraine",
          "website": "http://www.nmetau.edu.ua/"
       },
       {
          "university": "National Mining University",
          "website": "http://www.nmu.org.ua/"
       },
       {
          "university": "National Pedagogical University M. Dragomanov ",
          "website": "http://www.npu.edu.ua/"
       },
       {
          "university": "National Pirogov Memorial Medical University",
          "website": "http://www.vnmu.vn.ua/"
       },
       {
          "university": "National Taras Shevchenko University of Kiev",
          "website": "http://www.univ.kiev.ua/"
       },
       {
          "university": "National Technical University (Kharkiv Polytechnical Institute)",
          "website": "http://www.kpi.kharkov.ua/"
       },
       {
          "university": "National Technical University (Kiev Politechnical Institute)",
          "website": "http://www.kpi.ua/"
       },
       {
          "university": "National Transport University",
          "website": "http://www.ntu.kar.net/"
       },
       {
          "university": "National University of Food Technologies",
          "website": "http://www.usuft.kiev.ua/"
       },
       {
          "university": "National University of Internal Affairs",
          "website": "http://www.univd.edu.ua/"
       },
       {
          "university": "National University of Kiev-Mohyla Academy",
          "website": "http://www.ukma.kiev.ua/"
       },
       {
          "university": "National University of Ostroh Academy",
          "website": "http://www.uosa.uar.net/"
       },
       {
          "university": "National University of Shipbuilding",
          "website": "http://www.usmtu.edu.ua/"
       },
       {
          "university": "Odessa National Academy of Food Technologies",
          "website": "http://www.osaft.odessa.ua/"
       },
       {
          "university": "Odessa National I.I. Mecnikov University",
          "website": "http://www.onu.edu.ua/"
       },
       {
          "university": "Odessa National Marine University",
          "website": "http://www.osmu.odessa.ua/"
       },
       {
          "university": "Odessa National Maritime Academy",
          "website": "http://www.ma.odessa.ua/"
       },
       {
          "university": "Odessa National Polytechnic University",
          "website": "http://www.opu.odessa.ua/"
       },
       {
          "university": "Odessa State Academy of Construction and Architecture",
          "website": "http://www.ogasa.odessa.ua/"
       },
       {
          "university": "Odessa State Medical University",
          "website": "http://www.arosmu.org/"
       },
       {
          "university": "Petro Mohyla Black Sea State University",
          "website": "http://www.kma.mk.ua/"
       },
       {
          "university": "Poltava National Technical University",
          "website": "http://www.pntu.edu.ua/"
       },
       {
          "university": "Poltava University of Consumer Cooperatives in Ukraine",
          "website": "http://www.pusku.edu.ua/"
       },
       {
          "university": "Precarpathian University",
          "website": "http://www.pu.if.ua/"
       },
       {
          "university": "Sevastopol National Technical University",
          "website": "http://sevntu.com.ua/"
       },
       {
          "university": "State Pedagogical University in Kryvyi Rih",
          "website": "http://www.kdpu.edu.ua/"
       },
       {
          "university": "Sumy National Agrarian University",
          "website": "http://www.sau.sumy.ua/"
       },
       {
          "university": "Sumy State University",
          "website": "http://www.sumdu.edu.ua/"
       },
       {
          "university": "Taurida National V.I.Vernadsky University",
          "website": "http://www.tnu.crimea.ua/"
       },
       {
          "university": "Technological University of Podillya",
          "website": "http://www.tup.km.ua/"
       },
       {
          "university": "Ternopil Academy of National Economy",
          "website": "http://www.tane.edu.ua/"
       },
       {
          "university": "Ternopil State Ivan Pul'uj Technical University",
          "website": "http://www.tu.edu.te.ua/"
       },
       {
          "university": "Ukrainian Academy of Banking",
          "website": "http://www.academy.sumy.ua/"
       },
       {
          "university": "Ukrainian Academy of Pharmacy",
          "website": "http://www.ukrfa.kharkov.ua/"
       },
       {
          "university": "Ukrainian Medical Stomatological Academy",
          "website": "http://www.uamsa.net/"
       },
       {
          "university": "Ukrainian National Forestry University",
          "website": "http://www.forest.lviv.ua/"
       },
       {
          "university": "Uzhgorod National University",
          "website": "http://www.univ.uzhgorod.ua/"
       },
       {
          "university": "Vinnica National Technical University",
          "website": "http://www.vstu.vinnica.ua/"
       },
       {
          "university": "Volyn National University Lesja Ukrainka",
          "website": "http://www.vdu.edu.ua/"
       },
       {
          "university": "Zaporizhzhe National Technical University",
          "website": "http://www.zntu.edu.ua/"
       },
       {
          "university": "Zaporizhzhe National University",
          "website": "http://www.znu.edu.ua/"
       }
    ],
    "UG": [
       {
          "university": "Aga Khan University",
          "website": "http://www.aku.edu/"
       },
       {
          "university": "Bugema University",
          "website": "http://www.bugemauniv.ac.ug/"
       },
       {
          "university": "Busitema University",
          "website": "http://www.busitema.ac.ug/"
       },
       {
          "university": "Busoga University",
          "website": "http://www.busogauniversity.ac.ug/"
       },
       {
          "university": "Central Buganda University",
          "website": "http://www.cbu2000.com/"
       },
       {
          "university": "Gulu University",
          "website": "http://www.gu.ac.ug/"
       },
       {
          "university": "Islamic University in Uganda",
          "website": "http://www.iuiu.ac.ug/"
       },
       {
          "university": "Kampala International University",
          "website": "http://www.kiu.ac.ug/"
       },
       {
          "university": "Kampala University",
          "website": "http://www.ku.ac.ug/"
       },
       {
          "university": "Kyambogo University",
          "website": "http://www.kyu.ac.ug/"
       },
       {
          "university": "Makerere University",
          "website": "http://www.mak.ac.ug/"
       },
       {
          "university": "Mbarara University of Science and Technology",
          "website": "http://www.must.ac.ug/"
       },
       {
          "university": "Mountains of the Moon University",
          "website": "http://www.mmu.ac.ug/"
       },
       {
          "university": "Mutesa 1 Royal University",
          "website": "http://www.mru.ac.ug/"
       },
       {
          "university": "Ndejje University",
          "website": "http://www.ndejjeuniversity.ac.ug/"
       },
       {
          "university": "Nkumba University",
          "website": "http://www.nkumbauniversity.ac.ug/"
       },
       {
          "university": "St. Augustine International University",
          "website": "http://www.saiu.ac.ug/"
       },
       {
          "university": "Uganda Christian University",
          "website": "http://www.ucu.ac.ug/"
       },
       {
          "university": "Uganda Martyr's University",
          "website": "http://www.umu.ac.ug/"
       }
    ],
    "US": [
       {
          "university": "Abilene Christian University",
          "website": "http://www.acu.edu/"
       },
       {
          "university": "Academy of Art College",
          "website": "http://www.academyart.edu/"
       },
       {
          "university": "Adams State College",
          "website": "http://www.adams.edu/"
       },
       {
          "university": "Adelphi University",
          "website": "http://www.adelphi.edu/"
       },
       {
          "university": "Adler School of Professional Psychology",
          "website": "http://www.adler.edu/"
       },
       {
          "university": "Adrian College",
          "website": "http://www.adrian.edu/"
       },
       {
          "university": "Agnes Scott College",
          "website": "http://www.scottlan.edu/"
       },
       {
          "university": "Air Force Institute of Technology",
          "website": "http://www.afit.af.mil/"
       },
       {
          "university": "Alabama Agricultural and Mechanical University",
          "website": "http://www.aamu.edu/"
       },
       {
          "university": "Alabama State University",
          "website": "http://www.alasu.edu/"
       },
       {
          "university": "Alaska Bible College",
          "website": "http://www.akbible.edu/"
       },
       {
          "university": "Alaska Pacific University",
          "website": "http://www.alaskapacific.edu/"
       },
       {
          "university": "Albany College of Pharmacy",
          "website": "http://www.acp.edu/"
       },
       {
          "university": "Albany Law School",
          "website": "http://www.albanylaw.edu/"
       },
       {
          "university": "Albany Medical Center",
          "website": "http://www.amc.edu/"
       },
       {
          "university": "Albany State University",
          "website": "http://www.asurams.edu/"
       },
       {
          "university": "Albertus Magnus College",
          "website": "http://www.albertus.edu/"
       },
       {
          "university": "Albion College",
          "website": "http://www.albion.edu/"
       },
       {
          "university": "Albright College",
          "website": "http://www.albright.edu/"
       },
       {
          "university": "Alcorn State University",
          "website": "http://www.alcorn.edu/"
       },
       {
          "university": "Alderson Broaddus College",
          "website": "http://www.ab.edu/"
       },
       {
          "university": "Alfred Adler Graduate School",
          "website": "http://www.alfredadler.edu/"
       },
       {
          "university": "Alfred University",
          "website": "http://www.alfred.edu/"
       },
       {
          "university": "Alice Lloyd College",
          "website": "http://www.alc.edu/"
       },
       {
          "university": "Allegheny College",
          "website": "http://www.alleg.edu/"
       },
       {
          "university": "Allen University",
          "website": "http://www.scicu.org/allen/"
       },
       {
          "university": "Alma College",
          "website": "http://www.alma.edu/"
       },
       {
          "university": "Alvernia College",
          "website": "http://www.alvernia.edu/"
       },
       {
          "university": "Alverno College",
          "website": "http://www.alverno.edu/"
       },
       {
          "university": "Ambassador University",
          "website": "http://www.ambassador.edu/"
       },
       {
          "university": "Amber University",
          "website": "http://www.amberu.edu/"
       },
       {
          "university": "American Academy of Nutrition",
          "website": "http://www.nutritioneducation.com/"
       },
       {
          "university": "American Business & Technology University",
          "website": "http://www.abtu.edu/"
       },
       {
          "university": "American Conservatory of Music",
          "website": "http://members.aol.com/amerconsmu/"
       },
       {
          "university": "American Conservatory Theater",
          "website": "http://www.act-sfbay.org/"
       },
       {
          "university": "American-European School of Management ",
          "website": "http://www.aesom.com/"
       },
       {
          "university": "American Film Institute Center for Advanced Film and Television Studies",
          "website": "http://www.afionline.org/cafts/cafts.home.html"
       },
       {
          "university": "American Indian College",
          "website": "http://www.aicag.edu/"
       },
       {
          "university": "American InterContinental University - Atlanta",
          "website": "http://www.aiuniv.edu/"
       },
       {
          "university": "American InterContinental University - Ft. Lauderdale",
          "website": "http://www.aiuniv.edu/"
       },
       {
          "university": "American InterContinental University - Georgia",
          "website": "http://www.aiuniv.edu/"
       },
       {
          "university": "American InterContinental University Online",
          "website": "http://www.aiu-online.com/"
       },
       {
          "university": "American International College",
          "website": "http://www.aic.edu/"
       },
       {
          "university": "American Jewish University",
          "website": "http://www.ajula.edu/"
       },
       {
          "university": "American Military University",
          "website": "http://www.amunet.edu/"
       },
       {
          "university": "American Public University",
          "website": "http://www.apus.edu/"
       },
       {
          "university": "American University",
          "website": "http://www.american.edu/"
       },
       {
          "university": "American World University",
          "website": "http://www.awu.edu/"
       },
       {
          "university": "Amherst College",
          "website": "http://www.amherst.edu/"
       },
       {
          "university": "Anderson College",
          "website": "http://www.anderson-college.edu/"
       },
       {
          "university": "Anderson University",
          "website": "http://www.anderson.edu/"
       },
       {
          "university": "Andon College - Modesto",
          "website": "http://www.andoncollege.com/"
       },
       {
          "university": "Andon College - Stockton",
          "website": "http://www.andoncollege.org/"
       },
       {
          "university": "Andrew Jackson University",
          "website": "http://www.aju.edu/"
       },
       {
          "university": "Andrews University",
          "website": "http://www.andrews.edu/"
       },
       {
          "university": "Angelo State University",
          "website": "http://www.angelo.edu/"
       },
       {
          "university": "Anna Maria College",
          "website": "http://www.anna-maria.edu/"
       },
       {
          "university": "Antioch New England Graduate School",
          "website": "http://www.antiochne.edu/"
       },
       {
          "university": "Antioch University",
          "website": "http://www.antioch.edu/"
       },
       {
          "university": "Antioch University Los Angeles",
          "website": "http://www.antiochla.edu/"
       },
       {
          "university": "Antioch University Santa Barbara",
          "website": "http://www.antiochsb.edu/"
       },
       {
          "university": "Antioch University Seattle",
          "website": "http://www.antiochsea.edu/"
       },
       {
          "university": "Appalachian Bible College",
          "website": "http://www.appbibco.edu/"
       },
       {
          "university": "Appalachian State University",
          "website": "http://www.appstate.edu/"
       },
       {
          "university": "Aquinas College",
          "website": "http://www.aquinas.edu/"
       },
       {
          "university": "Arcadia University",
          "website": "http://www.arcadia.edu/"
       },
       {
          "university": "Argosy University",
          "website": "http://www.argosy.edu/"
       },
       {
          "university": "Argosy University - Hawaii",
          "website": "http://www.argosyu.edu/"
       },
       {
          "university": "Arizona Christian University",
          "website": "http://www.arizonachristian.edu"
       },
       {
          "university": "Arizona State University",
          "website": "http://www.asu.edu/"
       },
       {
          "university": "Arizona State University - Downtown Phoenix Campus",
          "website": "https://campus.asu.edu/downtown"
       },
       {
          "university": "Arizona State University - Polytechnic Campus",
          "website": "https://campus.asu.edu/polytechnic"
       },
       {
          "university": "Arizona State University - Tempe Campus",
          "website": "https://campus.asu.edu/tempe"
       },
       {
          "university": "Arizona State University - West Campus",
          "website": "https://campus.asu.edu/west"
       },
       {
          "university": "Arkansas State University",
          "website": "http://www.astate.edu/"
       },
       {
          "university": "Arkansas State University - Beebe",
          "website": "http://www.asub.edu/"
       },
       {
          "university": "Arkansas State University - Mountain Home",
          "website": "http://www.asumh.edu/"
       },
       {
          "university": "Arkansas State University - Newport",
          "website": "http://www.asun.edu/"
       },
       {
          "university": "Arkansas Tech University",
          "website": "http://www.atu.edu/"
       },
       {
          "university": "Arlington Baptist College",
          "website": "http://www.abconline.edu/"
       },
       {
          "university": "Armstrong Atlantic State University",
          "website": "http://www.armstrong.edu/"
       },
       {
          "university": "Armstrong University",
          "website": "http://www.armstrong-u.edu/"
       },
       {
          "university": "Art Academy of Cincinnati",
          "website": "http://www.artacademy.edu/"
       },
       {
          "university": "Art Center College of Design",
          "website": "http://www.artcenter.edu/"
       },
       {
          "university": "Arthur D. Little Management Education Institute",
          "website": "http://www.adlsom.edu/"
       },
       {
          "university": "Art Institute of Charlotte",
          "website": "http://www.aich.artinstitutes.edu/"
       },
       {
          "university": "Art Institute of Southern California",
          "website": "http://www.aisc.edu/"
       },
       {
          "university": "Asbury College",
          "website": "http://www.asbury.edu/"
       },
       {
          "university": "Ashland University",
          "website": "http://www.ashland.edu/"
       },
       {
          "university": "Assumption College",
          "website": "http://www.assumption.edu/"
       },
       {
          "university": "Athenaeum of Ohio",
          "website": "http://www.mtsm.org/"
       },
       {
          "university": "Athens State College",
          "website": "http://www.athens.edu/"
       },
       {
          "university": "Atlanta Christian College",
          "website": "http://www.acc.edu/"
       },
       {
          "university": "Atlanta College of Art",
          "website": "http://www.aca.edu/"
       },
       {
          "university": "Atlantic International University",
          "website": "http://www.aiu.edu/"
       },
       {
          "university": "Atlantic Union College",
          "website": "http://www.atlanticuc.edu/"
       },
       {
          "university": "Atlantic University",
          "website": "http://www.atlanticuniv.edu/"
       },
       {
          "university": "Auburn University",
          "website": "http://www.auburn.edu/"
       },
       {
          "university": "Auburn University at Montgomery",
          "website": "http://www.aum.edu/"
       },
       {
          "university": "Audrey Cohen College",
          "website": "http://www.audrey-cohen.edu/"
       },
       {
          "university": "Augsburg College",
          "website": "http://www.augsburg.edu/"
       },
       {
          "university": "Augustana College",
          "website": "http://www.augustana.edu/"
       },
       {
          "university": "Augustana College",
          "website": "http://www.augie.edu/"
       },
       {
          "university": "Augusta State University",
          "website": "http://www.aug.edu/"
       },
       {
          "university": "Aurora University",
          "website": "http://www.aurora.edu/"
       },
       {
          "university": "Austin College",
          "website": "http://www.austinc.edu/"
       },
       {
          "university": "Austin Community College",
          "website": "http://www.austin.cc.tx.us/"
       },
       {
          "university": "Austin Peay State University",
          "website": "http://www.apsu.edu/"
       },
       {
          "university": "Ave Maria University",
          "website": "http://www.avemaria.edu/"
       },
       {
          "university": "Averett College",
          "website": "http://www.averett.edu/"
       },
       {
          "university": "Avila College",
          "website": "http://www.avila.edu/"
       },
       {
          "university": "Azusa Pacific University",
          "website": "http://www.apu.edu/"
       },
       {
          "university": "Babson College",
          "website": "http://www.babson.edu/"
       },
       {
          "university": "Baker College of Auburn Hills",
          "website": "http://www.baker.edu/visit/auburn.html"
       },
       {
          "university": "Baker College of Cadillac",
          "website": "http://www.baker.edu/visit/cadillac.html"
       },
       {
          "university": "Baker College of Flint",
          "website": "http://www.baker.edu/visit/flint.html"
       },
       {
          "university": "Baker College of Mount Clemens",
          "website": "http://www.baker.edu/visit/mtclemens.html"
       },
       {
          "university": "Baker College of Muskegon",
          "website": "http://www.baker.edu/visit/muskegon.html"
       },
       {
          "university": "Baker College of Owosso",
          "website": "http://www.baker.edu/visit/owosso.html"
       },
       {
          "university": "Baker College of Port Huron",
          "website": "http://www.baker.edu/visit/porthuron.html"
       },
       {
          "university": "Baker University",
          "website": "http://www.bakeru.edu/"
       },
       {
          "university": "Baldwin-Wallace College",
          "website": "http://www.baldwinw.edu/"
       },
       {
          "university": "Ball State University",
          "website": "http://www.bsu.edu/"
       },
       {
          "university": "Baltimore Hebrew University",
          "website": "http://www.bhu.edu/"
       },
       {
          "university": "Baltimore International College",
          "website": "http://www.bic.edu/"
       },
       {
          "university": "Bank Street College of Education",
          "website": "http://www.bnkst.edu/"
       },
       {
          "university": "Baptist Bible College of Missouri",
          "website": "http://www.bbcnet.edu/"
       },
       {
          "university": "Baptist Bible College of Pennsylvania",
          "website": "http://www.bbc.edu/"
       },
       {
          "university": "Barat College",
          "website": "http://www.barat.edu/"
       },
       {
          "university": "Barber-Scotia College",
          "website": "http://www.b-sc.edu/"
       },
       {
          "university": "Barclay College",
          "website": "http://www.barclaycollege.edu/"
       },
       {
          "university": "Bard College",
          "website": "http://www.bard.edu/"
       },
       {
          "university": "Bard Graduate Center for Studies in the Decorative Arts",
          "website": "http://www.bard.edu/graduate/BGC/intro.html"
       },
       {
          "university": "Barnard College - Columbia University",
          "website": "http://www.barnard.edu/"
       },
       {
          "university": "Barry University",
          "website": "http://www.barry.edu/"
       },
       {
          "university": "Bartlesville Wesleyan College",
          "website": "http://www.bwc.edu/"
       },
       {
          "university": "Barton College",
          "website": "http://www.barton.edu/"
       },
       {
          "university": "Bastyr University",
          "website": "http://www.bastyr.edu/"
       },
       {
          "university": "Bates College",
          "website": "http://www.bates.edu/"
       },
       {
          "university": "Bauder College",
          "website": "http://www.bauder.edu/"
       },
       {
          "university": "Baylor College of Dentistry",
          "website": "http://www.tambcd.edu/"
       },
       {
          "university": "Baylor College of Medicine",
          "website": "http://www.bcm.tmc.edu/"
       },
       {
          "university": "Baylor University",
          "website": "http://www.baylor.edu/"
       },
       {
          "university": "Belhaven College",
          "website": "http://www.belhaven.edu/"
       },
       {
          "university": "Bellarmine College",
          "website": "http://www.bellarmine.edu/"
       },
       {
          "university": "Bellevue University",
          "website": "http://www.bellevue.edu/"
       },
       {
          "university": "Bellin College of Nursing",
          "website": "http://www.bellin.org/bcn/"
       },
       {
          "university": "Belmont Abbey College",
          "website": "http://www.bac.edu/"
       },
       {
          "university": "Belmont University",
          "website": "http://www.belmont.edu/"
       },
       {
          "university": "Beloit College",
          "website": "http://www.beloit.edu/"
       },
       {
          "university": "Bemidji State Univeristy",
          "website": "http://www.bemidji.msus.edu/"
       },
       {
          "university": "Benedict College",
          "website": "http://www.benedict.edu/"
       },
       {
          "university": "Benedictine College",
          "website": "http://www.benedictine.edu/"
       },
       {
          "university": "Benedictine University",
          "website": "http://www.ben.edu/"
       },
       {
          "university": "Benedictine University - Springfield College in Illinois",
          "website": "http://www.sci.edu/"
       },
       {
          "university": "Bennett College",
          "website": "http://www.bennett.edu/"
       },
       {
          "university": "Bennington College",
          "website": "http://www.bennington.edu/"
       },
       {
          "university": "Bentley College",
          "website": "http://www.bentley.edu/"
       },
       {
          "university": "Berea College",
          "website": "http://www.berea.edu/"
       },
       {
          "university": "Berean University of the Assemblies of God",
          "website": "http://www.berean.edu/"
       },
       {
          "university": "Berklee College of Music",
          "website": "http://www.berklee.edu/"
       },
       {
          "university": "Berne University",
          "website": "http://www.berne.edu/"
       },
       {
          "university": "Berry College",
          "website": "http://www.berry.edu/"
       },
       {
          "university": "Bethany College California",
          "website": "http://www.bethany.edu/"
       },
       {
          "university": "Bethany College Kansas",
          "website": "http://www.bethanylb.edu/"
       },
       {
          "university": "Bethany College West Virginia",
          "website": "http://www.bethany.wvnet.edu/"
       },
       {
          "university": "Bethel College McKenzie",
          "website": "http://www.bethel-college.edu/"
       },
       {
          "university": "Bethel College Mishawaka",
          "website": "http://www.bethel-in.edu/"
       },
       {
          "university": "Bethel College Newton",
          "website": "http://www.bethelks.edu/"
       },
       {
          "university": "Beth-El College of Nursing and Health Sciences",
          "website": "http://www.uccs.edu/~bethel/"
       },
       {
          "university": "Bethel College St. Paul",
          "website": "http://www.bethel.edu/"
       },
       {
          "university": "Bethune-Cookman College",
          "website": "http://www.bethune.cookman.edu/"
       },
       {
          "university": "Biola University",
          "website": "http://www.biola.edu/"
       },
       {
          "university": "Birmingham-Southern College",
          "website": "http://www.bsc.edu/"
       },
       {
          "university": "Blackburn College",
          "website": "http://www.blackburn.edu/"
       },
       {
          "university": "Black Hawk College",
          "website": "http://www.bhc.edu/"
       },
       {
          "university": "Black Hills State University",
          "website": "http://www.bhsu.edu/"
       },
       {
          "university": "Blessing-Rieman College of Nursing",
          "website": "http://www.brcn.edu/"
       },
       {
          "university": "Bloomfield College",
          "website": "http://www.bloomfield.edu/"
       },
       {
          "university": "Bloomsburg University of Pennsylvania",
          "website": "http://www.bloomu.edu/"
       },
       {
          "university": "Bluefield College",
          "website": "http://www.bluefield.edu/"
       },
       {
          "university": "Bluefield State College",
          "website": "http://www.bluefield.wvnet.edu/"
       },
       {
          "university": "Blue Mountain College",
          "website": "http://www.bmc.edu/"
       },
       {
          "university": "Bluffton College",
          "website": "http://www.bluffton.edu/"
       },
       {
          "university": "Bob Jones University",
          "website": "http://www.bju.edu/"
       },
       {
          "university": "Boise Bible College",
          "website": "http://www.boisebible.edu/"
       },
       {
          "university": "Boise State University",
          "website": "http://www.boisestate.edu/"
       },
       {
          "university": "Boricua College",
          "website": "http://www.boricuacollege.edu/"
       },
       {
          "university": "Boston Architectural Center",
          "website": "http://www.the-bac.edu/"
       },
       {
          "university": "Boston College",
          "website": "http://www.bc.edu/"
       },
       {
          "university": "Boston University",
          "website": "http://www.bu.edu/"
       },
       {
          "university": "Bowdoin College",
          "website": "http://www.bowdoin.edu/"
       },
       {
          "university": "Bowie State University",
          "website": "http://www.bowiestate.edu/"
       },
       {
          "university": "Bowling Green State University",
          "website": "http://www.bgsu.edu/"
       },
       {
          "university": "Bowling Green State University - Firelands",
          "website": "http://www.firelands.bgsu.edu/"
       },
       {
          "university": "Bradley University",
          "website": "http://www.bradley.edu/"
       },
       {
          "university": "Brandeis University",
          "website": "http://www.brandeis.edu/"
       },
       {
          "university": "Brandman University",
          "website": "http://www.brandman.edu/"
       },
       {
          "university": "Brenau University",
          "website": "http://www.brenau.edu/"
       },
       {
          "university": "Brescia University",
          "website": "http://www.brescia.edu/"
       },
       {
          "university": "Brevard College",
          "website": "http://www.brevard.edu/"
       },
       {
          "university": "Brewton-Parker College",
          "website": "http://www.bpc.edu/"
       },
       {
          "university": "Breyer State University (Virtual University)",
          "website": "http://www.breyerstate.com/"
       },
       {
          "university": "Briar Cliff College",
          "website": "http://www.briar-cliff.edu/"
       },
       {
          "university": "Bridgewater College",
          "website": "http://www.bridgewater.edu/"
       },
       {
          "university": "Bridgewater State College",
          "website": "http://www.bridgew.edu/"
       },
       {
          "university": "Brigham Young University",
          "website": "http://www.byui.edu/"
       },
       {
          "university": "Brigham Young University",
          "website": "http://www.byu.edu/"
       },
       {
          "university": "Brigham Young University Hawaii",
          "website": "http://www.byuh.edu/"
       },
       {
          "university": "Brooklyn Law School",
          "website": "http://www.brooklaw.edu/"
       },
       {
          "university": "Brooks Institute of Photography",
          "website": "http://www.brooks.edu/"
       },
       {
          "university": "Brown University",
          "website": "http://www.brown.edu/"
       },
       {
          "university": "Brunswick Community College",
          "website": "http://www.brunswickcc.edu/"
       },
       {
          "university": "Bryan College",
          "website": "http://www.bryan.edu/"
       },
       {
          "university": "Bryant and Stratton College",
          "website": "http://www.bryantstratton.edu/"
       },
       {
          "university": "Bryant University",
          "website": "http://www.bryant.edu/"
       },
       {
          "university": "Bryn Athyn College of the New Church",
          "website": "http://www.newchurch.edu/college/"
       },
       {
          "university": "Bryn Mawr College",
          "website": "http://www.brynmawr.edu/"
       },
       {
          "university": "Bucknell University",
          "website": "http://www.bucknell.edu/"
       },
       {
          "university": "Buena Vista University",
          "website": "http://www.bvu.edu/"
       },
       {
          "university": "Burlington College",
          "website": "http://www.burlcol.edu/"
       },
       {
          "university": "Butler University",
          "website": "http://www.butler.edu/"
       },
       {
          "university": "Cabrini College",
          "website": "http://www.cabrini.edu/"
       },
       {
          "university": "Caldwell College",
          "website": "http://www.caldwell.edu/"
       },
       {
          "university": "California Baptist College",
          "website": "http://www.calbaptist.edu/"
       },
       {
          "university": "California Coast University",
          "website": "http://www.calcoast.edu/"
       },
       {
          "university": "California College for Health Sciences",
          "website": "http://www.cchs.edu/"
       },
       {
          "university": "California College of Arts and Crafts",
          "website": "http://www.ccac-art.edu/"
       },
       {
          "university": "California College of Podiatric Medicine",
          "website": "http://www.ccpm.edu/"
       },
       {
          "university": "California College San Diego",
          "website": "http://www.cc-sd.edu/"
       },
       {
          "university": "California Institute of Integral Studies",
          "website": "http://www.ciis.edu/"
       },
       {
          "university": "California Institute of Technology",
          "website": "http://www.caltech.edu/"
       },
       {
          "university": "California Institute of the Arts",
          "website": "http://www.calarts.edu/"
       },
       {
          "university": "California Lutheran University",
          "website": "http://www.callutheran.edu/"
       },
       {
          "university": "California Maritime Academy",
          "website": "http://www.csum.edu/"
       },
       {
          "university": "California Polytechnic State University - San Luis Obispo",
          "website": "http://www.calpoly.edu/"
       },
       {
          "university": "California School of Professional Psychology - Berkley/Alameda",
          "website": "http://www.cspp.edu/"
       },
       {
          "university": "California School of Professional Psychology - Fresno",
          "website": "http://www.cspp.edu/catalog/8.htm"
       },
       {
          "university": "California School of Professional Psychology - Los Angels",
          "website": "http://www.cspp.edu/catalog/9.htm"
       },
       {
          "university": "California School of Professional Psychology - San Diego",
          "website": "http://www.cspp.edu/catalog/10.htm"
       },
       {
          "university": "California State Polytechnic University - Pomona",
          "website": "http://www.csupomona.edu/"
       },
       {
          "university": "California State University - Bakersfield",
          "website": "http://www.csubak.edu/"
       },
       {
          "university": "California State University - Channel Islands",
          "website": "http://www.csuci.edu/"
       },
       {
          "university": "California State University - Chico",
          "website": "http://www.csuchico.edu/"
       },
       {
          "university": "California State University - Dominguez Hills",
          "website": "http://www.csudh.edu/"
       },
       {
          "university": "California State University - Fresno",
          "website": "http://www.csufresno.edu/"
       },
       {
          "university": "California State University - Fullerton",
          "website": "http://www.fullerton.edu/"
       },
       {
          "university": "California State University - Hayward",
          "website": "http://www.csuhayward.edu/"
       },
       {
          "university": "California State University - Long Beach",
          "website": "http://www.csulb.edu/"
       },
       {
          "university": "California State University - Los Angeles",
          "website": "http://www.calstatela.edu/"
       },
       {
          "university": "California State University - Monterey Bay",
          "website": "http://www.monterey.edu/"
       },
       {
          "university": "California State University - Northridge",
          "website": "http://www.csun.edu/"
       },
       {
          "university": "California State University - Sacramento",
          "website": "http://www.csus.edu/"
       },
       {
          "university": "California State University - San Bernadino",
          "website": "http://www.csusb.edu/"
       },
       {
          "university": "California State University - San Marcos",
          "website": "http://www.csusm.edu/"
       },
       {
          "university": "California State University - Stanislaus",
          "website": "http://www.csustan.edu/"
       },
       {
          "university": "California University of Management and Sciences",
          "website": "http://cauniversity.edu.cufce.org/"
       },
       {
          "university": "California University of Pennsylvania",
          "website": "http://www.cup.edu/"
       },
       {
          "university": "California Western School of Law",
          "website": "http://www.cwsl.edu/"
       },
       {
          "university": "Calumet College of St. Joseph",
          "website": "http://www.ccsj.edu/"
       },
       {
          "university": "Calvary Bible College",
          "website": "http://www.calvary.edu/"
       },
       {
          "university": "Calvin College",
          "website": "http://www.calvin.edu/"
       },
       {
          "university": "Cambridge College",
          "website": "http://www.cambridge.edu/"
       },
       {
          "university": "Cameron University",
          "website": "http://www.cameron.edu/"
       },
       {
          "university": "Campbellsville College",
          "website": "http://www.campbellsvil.edu/"
       },
       {
          "university": "Campbell University",
          "website": "http://www.campbell.edu/"
       },
       {
          "university": "Canisius College",
          "website": "http://www.canisius.edu/"
       },
       {
          "university": "Capella University",
          "website": "http://www.capella.edu/"
       },
       {
          "university": "Capital University",
          "website": "http://www.capital.edu/"
       },
       {
          "university": "Capital University Law School",
          "website": "http://www.law.capital.edu/"
       },
       {
          "university": "Capitol College",
          "website": "http://www.capitol-college.edu/"
       },
       {
          "university": "Cardinal Stritch University",
          "website": "http://www.stritch.edu/"
       },
       {
          "university": "Carleton College",
          "website": "http://www.carleton.edu/"
       },
       {
          "university": "Carlos Albizu University",
          "website": "http://www.albizu.edu/"
       },
       {
          "university": "Carlow College",
          "website": "http://www.carlow.edu/"
       },
       {
          "university": "Carnegie Mellon University",
          "website": "http://www.cmu.edu/"
       },
       {
          "university": "Carroll College Helena",
          "website": "http://www.carroll.edu/"
       },
       {
          "university": "Carroll College Waukesha",
          "website": "http://www.cc.edu/"
       },
       {
          "university": "Carson-Newman College",
          "website": "http://www.cn.edu/"
       },
       {
          "university": "Carthage College",
          "website": "http://www.carthage.edu/"
       },
       {
          "university": "Case Western Reserve University",
          "website": "http://www.cwru.edu/"
       },
       {
          "university": "Castleton State College",
          "website": "http://www.csc.vsc.edu/"
       },
       {
          "university": "Catawba College",
          "website": "http://www.catawba.edu/"
       },
       {
          "university": "Catholic Theological Union",
          "website": "http://www.ctu.edu/"
       },
       {
          "university": "Cedar Crest College",
          "website": "http://www.cedarcrest.edu/"
       },
       {
          "university": "Cedarville College",
          "website": "http://www.cedarville.edu/"
       },
       {
          "university": "Centenary College",
          "website": "http://www.centenarycollege.edu/"
       },
       {
          "university": "Centenary College of Louisiana",
          "website": "http://www.centenary.edu/"
       },
       {
          "university": "Center for Humanistic Studies",
          "website": "http://www.humanpsych.edu/"
       },
       {
          "university": "Central Baptist College",
          "website": "http://www.cbc.edu/"
       },
       {
          "university": "Central Bible College",
          "website": "http://www.cbcag.edu/"
       },
       {
          "university": "Central Christian College of the Bible",
          "website": "http://www.cccb.edu/"
       },
       {
          "university": "Central College",
          "website": "http://www.central.edu/"
       },
       {
          "university": "Central Connecticut State University",
          "website": "http://www.ccsu.edu/"
       },
       {
          "university": "Central Methodist College",
          "website": "http://www.cmc.edu/"
       },
       {
          "university": "Central Michigan University",
          "website": "http://www.cmich.edu/"
       },
       {
          "university": "Central State University",
          "website": "http://www.centralstate.edu/"
       },
       {
          "university": "Central Washington University",
          "website": "http://www.cwu.edu/"
       },
       {
          "university": "Centre College",
          "website": "http://www.centre.edu/"
       },
       {
          "university": "Chadron State College",
          "website": "http://www.csc.edu/"
       },
       {
          "university": "Chaminade University of Honolulu",
          "website": "http://www.chaminade.edu/"
       },
       {
          "university": "Champlain College",
          "website": "http://www.champlain.edu/"
       },
       {
          "university": "Chapman University",
          "website": "http://www.chapman.edu/"
       },
       {
          "university": "Charles R. Drew University of Medicine and Science",
          "website": "http://www.cdrewu.edu/"
       },
       {
          "university": "Charleston Southern University",
          "website": "http://www.csuniv.edu/"
       },
       {
          "university": "Charter Oak State College",
          "website": "http://www.cosc.edu/"
       },
       {
          "university": "Chatham College",
          "website": "http://www.chatham.edu/"
       },
       {
          "university": "Chestnut Hill College",
          "website": "http://www.chc.edu/"
       },
       {
          "university": "Cheyney University of Pennsylvania",
          "website": "http://www.cheyney.edu/"
       },
       {
          "university": "Chicago State University",
          "website": "http://www.csu.edu/"
       },
       {
          "university": "Chowan College",
          "website": "http://www.chowan.edu/"
       },
       {
          "university": "Christendom College",
          "website": "http://www.christendom.edu/"
       },
       {
          "university": "Christian Brothers University",
          "website": "http://www.cbu.edu/"
       },
       {
          "university": "Christian Heritage College",
          "website": "http://www.christianheritage.edu/"
       },
       {
          "university": "Christopher Newport University",
          "website": "http://www.cnu.edu/"
       },
       {
          "university": "Circleville Bible College",
          "website": "http://www.biblecollege.edu/"
       },
       {
          "university": "City University",
          "website": "http://www.cityu.edu/"
       },
       {
          "university": "City University of New York - Bernard M. Baruch College",
          "website": "http://www.baruch.cuny.edu/"
       },
       {
          "university": "City University of New York - Brooklyn College",
          "website": "http://www.brooklyn.cuny.edu/"
       },
       {
          "university": "City University of New York - City College",
          "website": "http://www.ccny.cuny.edu/"
       },
       {
          "university": "City University of New York - College of Staten Island",
          "website": "http://www.csi.cuny.edu/"
       },
       {
          "university": "City University of New York (CUNY) System",
          "website": "http://www.cuny.edu/"
       },
       {
          "university": "City University of New York - Graduate School and University Center",
          "website": "http://www.gc.cuny.edu/"
       },
       {
          "university": "City University of New York - Hunter College",
          "website": "http://www.hunter.cuny.edu/"
       },
       {
          "university": "City University of New York - John Jay College of Criminal Justice",
          "website": "http://www.jjay.cuny.edu/"
       },
       {
          "university": "City University of New York - Lehman College",
          "website": "http://www.lehman.cuny.edu/"
       },
       {
          "university": "City University of New York - Medgar Evers College",
          "website": "http://www.mec.cuny.edu/"
       },
       {
          "university": "City University of New York Medical School / Sophie Davis School of Biomedical Education",
          "website": "http://med.cuny.edu/"
       },
       {
          "university": "City University of New York - New York City Technical College",
          "website": "http://www.nyctc.cuny.edu/"
       },
       {
          "university": "City University of New York - Queens College",
          "website": "http://www.qc.edu/"
       },
       {
          "university": "City University of New York - School of Law at Queens College",
          "website": "http://www.law.cuny.edu/"
       },
       {
          "university": "City University of New York - York College",
          "website": "http://www.york.cuny.edu/"
       },
       {
          "university": "Claflin College",
          "website": "http://www.scicu.org/claflin/"
       },
       {
          "university": "Claremont Graduate University",
          "website": "http://www.cgu.edu/"
       },
       {
          "university": "Claremont Lincoln University",
          "website": "http://www.claremontlincoln.org/"
       },
       {
          "university": "Claremont McKenna College",
          "website": "http://www.mckenna.edu/"
       },
       {
          "university": "Clarion University",
          "website": "http://www.clarion.edu/"
       },
       {
          "university": "Clark Atlanta University",
          "website": "http://www.cau.edu/"
       },
       {
          "university": "Clarke College",
          "website": "http://www.clarke.edu/"
       },
       {
          "university": "Clarkson College",
          "website": "http://www.clarksoncollege.edu/"
       },
       {
          "university": "Clarkson University",
          "website": "http://www.clarkson.edu/"
       },
       {
          "university": "Clark University",
          "website": "http://www.clarku.edu/"
       },
       {
          "university": "Clayton College & State University",
          "website": "http://www.clayton.edu/"
       },
       {
          "university": "Clearwater Christian College",
          "website": "http://www.clearwater.edu/"
       },
       {
          "university": "Cleary College",
          "website": "http://www.cleary.edu/"
       },
       {
          "university": "Clemson University",
          "website": "http://www.clemson.edu/"
       },
       {
          "university": "Cleveland Chiropractic College - Kansas City",
          "website": "http://www.clevelandchiropractic.edu/"
       },
       {
          "university": "Cleveland Chiropractic College - Los Angeles",
          "website": "http://www.clevelandchiropractic.edu/"
       },
       {
          "university": "Cleveland Institute of Art",
          "website": "http://www.cia.edu/"
       },
       {
          "university": "Cleveland Institute of Music",
          "website": "http://www.cim.edu/"
       },
       {
          "university": "Cleveland State University",
          "website": "http://www.csuohio.edu/"
       },
       {
          "university": "Coastal Carolina University",
          "website": "http://www.coastal.edu/"
       },
       {
          "university": "Coe College",
          "website": "http://www.coe.edu/"
       },
       {
          "university": "Cogswell Polytechnical College",
          "website": "http://www.cogswell.edu/"
       },
       {
          "university": "Coker College",
          "website": "http://www.coker.edu/"
       },
       {
          "university": "Colby College",
          "website": "http://www.colby.edu/"
       },
       {
          "university": "Colby-Sawyer College",
          "website": "http://www.colby-sawyer.edu/"
       },
       {
          "university": "Coleman College",
          "website": "http://www.coleman.edu/"
       },
       {
          "university": "Colgate University",
          "website": "http://www.colgate.edu/"
       },
       {
          "university": "CollegeAmerica - Denver",
          "website": "http://www.collegeamerica.edu/"
       },
       {
          "university": "CollegeAmerica - Phoenix",
          "website": "http://www.collegeamerica.edu/"
       },
       {
          "university": "College for Creative Studies",
          "website": "http://www.ccscad.edu/"
       },
       {
          "university": "College for Financial Planning",
          "website": "http://www.fp.edu/"
       },
       {
          "university": "College for Lifelong Learning",
          "website": "http://www.cll.edu/"
       },
       {
          "university": "College Misericordia",
          "website": "http://www.miseri.edu/"
       },
       {
          "university": "College of Aeronautics",
          "website": "http://www.aero.edu/"
       },
       {
          "university": "College of Charleston",
          "website": "http://www.cofc.edu/"
       },
       {
          "university": "College of DuPage",
          "website": "http://www.cod.edu/"
       },
       {
          "university": "College of Idaho",
          "website": "http://www.collegeofidaho.edu/"
       },
       {
          "university": "College of Mount St. Joseph",
          "website": "http://www.msj.edu/"
       },
       {
          "university": "College of Mount St. Vincent",
          "website": "http://www.cmsv.edu/"
       },
       {
          "university": "College of New Rochelle",
          "website": "http://www.cnr.edu/"
       },
       {
          "university": "College of Notre Dame",
          "website": "http://www.cnd.edu/"
       },
       {
          "university": "College of Notre Dame of Maryland",
          "website": "http://www.ndm.edu/"
       },
       {
          "university": "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
          "website": "http://www.westernu.edu/comp.html"
       },
       {
          "university": "College of St. Benedict and St. John's University",
          "website": "http://www.csbsju.edu/"
       },
       {
          "university": "College of St. Catherine",
          "website": "http://www.stkate.edu/"
       },
       {
          "university": "College of St. Elizabeth",
          "website": "http://www.st-elizabeth.edu/"
       },
       {
          "university": "College of St. Joseph",
          "website": "http://www.csj.edu/"
       },
       {
          "university": "College of St. Mary",
          "website": "http://www.csm.edu/"
       },
       {
          "university": "College of St. Rose",
          "website": "http://www.strose.edu/"
       },
       {
          "university": "College of St. Scholastica",
          "website": "http://www.css.edu/"
       },
       {
          "university": "College of the Atlantic",
          "website": "http://www.coa.edu/"
       },
       {
          "university": "College of the Holy Cross",
          "website": "http://www.holycross.edu/"
       },
       {
          "university": "College of the Ozarks",
          "website": "http://www.cofo.edu/"
       },
       {
          "university": "College of Visual Arts",
          "website": "http://www.cva.edu/"
       },
       {
          "university": "College of William and Mary",
          "website": "http://www.wm.edu/"
       },
       {
          "university": "Colorado Christian University",
          "website": "http://www.ccu.edu/"
       },
       {
          "university": "Colorado College",
          "website": "http://www.cc.colorado.edu/"
       },
       {
          "university": "Colorado School of Mines",
          "website": "http://www.mines.edu/"
       },
       {
          "university": "Colorado State University",
          "website": "http://www.colostate.edu/"
       },
       {
          "university": "Colorado State University-Pueblo",
          "website": "http://www.colostate-pueblo.edu/"
       },
       {
          "university": "Colorado Technical University",
          "website": "http://www.colotechu.edu/"
       },
       {
          "university": "Columbia College Chicago",
          "website": "http://www.colum.edu/"
       },
       {
          "university": "Columbia College Hollywood",
          "website": "http://www.columbiacollege.edu/"
       },
       {
          "university": "Columbia College of Missouri",
          "website": "http://www.ccis.edu/"
       },
       {
          "university": "Columbia College South Carolina",
          "website": "http://www.colacoll.edu/"
       },
       {
          "university": "Columbia Commonwealth University",
          "website": "http://www.ccwu.edu/"
       },
       {
          "university": "Columbia International University",
          "website": "http://www.ciu.edu/"
       },
       {
          "university": "Columbia Southern University",
          "website": "http://www.columbiasouthern.edu/"
       },
       {
          "university": "Columbia Union College",
          "website": "http://www.cuc.edu/"
       },
       {
          "university": "Columbia University",
          "website": "http://www.columbia.edu/"
       },
       {
          "university": "Columbus College of Art and Design",
          "website": "http://www.ccad.edu/"
       },
       {
          "university": "Columbus State University",
          "website": "http://www.colstate.edu/"
       },
       {
          "university": "Columbus University",
          "website": "http://www.columbusu.com/"
       },
       {
          "university": "Community College of Denver",
          "website": "http://ccd.rightchoice.org/"
       },
       {
          "university": "Concord College",
          "website": "http://www.concord.edu/"
       },
       {
          "university": "Concordia College - Ann Arbor",
          "website": "http://www.ccaa.edu/"
       },
       {
          "university": "Concordia College - Bronxville",
          "website": "http://www.concordia-ny.edu/"
       },
       {
          "university": "Concordia College - Moorhead",
          "website": "http://www.cord.edu/"
       },
       {
          "university": "Concordia College - Selma",
          "website": "http://higher-ed.lcms.org/selma.htm"
       },
       {
          "university": "Concordia College - Seward",
          "website": "http://www.cune.edu/"
       },
       {
          "university": "Concordia College - St. Paul",
          "website": "http://www.csp.edu/"
       },
       {
          "university": "Concordia University - Austin",
          "website": "http://www.concordia.edu/"
       },
       {
          "university": "Concordia University - Irvine",
          "website": "http://www.cui.edu/"
       },
       {
          "university": "Concordia University - Mequon",
          "website": "http://www.cuw.edu/"
       },
       {
          "university": "Concordia University - Portland",
          "website": "http://www.cu-portland.edu/"
       },
       {
          "university": "Concordia University - River Forest",
          "website": "http://www.curf.edu/"
       },
       {
          "university": "Connecticut College",
          "website": "http://www.conncoll.edu/"
       },
       {
          "university": "Converse College",
          "website": "http://www.converse.edu/"
       },
       {
          "university": "Conway School of Landscape Design",
          "website": "http://www.csld.edu/"
       },
       {
          "university": "Coppin State College",
          "website": "http://www.coppin.edu/"
       },
       {
          "university": "Cornell College",
          "website": "http://www.cornell-iowa.edu/"
       },
       {
          "university": "Cornell University",
          "website": "http://www.cornell.edu/"
       },
       {
          "university": "Cornish College of the Arts",
          "website": "http://www.cornish.edu/"
       },
       {
          "university": "Cosmopolitan University",
          "website": "http://www.cosmoedu.net/"
       },
       {
          "university": "Covenant College",
          "website": "http://www.covenant.edu/"
       },
       {
          "university": "Cranbrook Academy of Art",
          "website": "http://www.cranbrookart.edu/"
       },
       {
          "university": "Creighton University",
          "website": "http://www.creighton.edu/"
       },
       {
          "university": "Crichton College",
          "website": "http://www.crichton.edu/"
       },
       {
          "university": "Crown College",
          "website": "http://www.crown.edu/"
       },
       {
          "university": "Culver-Stockton College",
          "website": "http://www.culver.edu/"
       },
       {
          "university": "Cumberland College",
          "website": "http://www.cumber.edu/"
       },
       {
          "university": "Cumberland University",
          "website": "http://www.cumberland.edu/"
       },
       {
          "university": "Curry College",
          "website": "http://www.curry.edu:8080/"
       },
       {
          "university": "Daemen College",
          "website": "http://www.daemen.edu/"
       },
       {
          "university": "Dakota State University",
          "website": "http://www.dsu.edu/"
       },
       {
          "university": "Dakota Wesleyan University",
          "website": "http://www.dwu.edu/"
       },
       {
          "university": "Dallas Baptist University",
          "website": "http://www.dbu.edu/"
       },
       {
          "university": "Dallas Christian College",
          "website": "http://www.dallas.edu/"
       },
       {
          "university": "Dana College",
          "website": "http://www.dana.edu/"
       },
       {
          "university": "Daniel Webster College",
          "website": "http://www.dwc.edu/"
       },
       {
          "university": "Danville Area Community College",
          "website": "http://www.dacc.cc.il.us/"
       },
       {
          "university": "Dartmouth College",
          "website": "http://www.dartmouth.edu/"
       },
       {
          "university": "Darton College",
          "website": "http://www.darton.edu/"
       },
       {
          "university": "Davenport College of Business - Grand Rapids",
          "website": "http://www.davenport.edu/grandrapids/"
       },
       {
          "university": "Davenport College of Business - Kalamazoo",
          "website": "http://www.davenport.edu/kalamazoo/"
       },
       {
          "university": "Davenport College of Business - Lansing",
          "website": "http://www.davenport.edu/lansing/"
       },
       {
          "university": "Davidson College",
          "website": "http://www.davidson.edu/"
       },
       {
          "university": "Davis and Elkins College",
          "website": "http://www.dne.edu/"
       },
       {
          "university": "Deaconess College of Nursing",
          "website": "http://www.deaconess.edu/"
       },
       {
          "university": "Delaware State University",
          "website": "http://www.dsc.edu/"
       },
       {
          "university": "Delaware Valley College",
          "website": "http://www.devalcol.edu/"
       },
       {
          "university": "Delta International University",
          "website": "http://www.delta-university.org/"
       },
       {
          "university": "Delta State University",
          "website": "http://www.deltast.edu/"
       },
       {
          "university": "Denison University",
          "website": "http://www.denison.edu/"
       },
       {
          "university": "Denver Paralegal Institute",
          "website": "http://www.paralegal-education.com/campuses/cosprings/"
       },
       {
          "university": "Denver Technical College",
          "website": "http://www.dtc.edu/"
       },
       {
          "university": "DePaul University",
          "website": "http://www.depaul.edu/"
       },
       {
          "university": "DePauw University",
          "website": "http://www.depauw.edu/"
       },
       {
          "university": "DeSales University",
          "website": "http://www.desales.edu/"
       },
       {
          "university": "Design Institute of San Diego",
          "website": "http://www.disd.edu/"
       },
       {
          "university": "Detroit College of Business",
          "website": "http://www.dcb.edu/"
       },
       {
          "university": "Detroit College of Business - Flint",
          "website": "http://www.davenport.edu/e3front.dll?durki=1283"
       },
       {
          "university": "Detroit College of Business - Warren",
          "website": "http://www.davenport.edu/e3front.dll?durki=108"
       },
       {
          "university": "Detroit College of Law",
          "website": "http://www.dcl.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Chicago",
          "website": "http://www.chi.devry.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Columbus",
          "website": "http://www.devrycols.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Decatur",
          "website": "http://www.atl.devry.edu/"
       },
       {
          "university": "DeVry Institute of Technology - DuPage",
          "website": "http://www.dpg.devry.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Irving",
          "website": "http://www.dal.devry.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Kansas City",
          "website": "http://www.kc.devry.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Phoenix",
          "website": "http://www.phx.devry.edu/"
       },
       {
          "university": "DeVry Institute of Technology - Pomona",
          "website": "http://www.pom.devry.edu/"
       },
       {
          "university": "Dickinson College",
          "website": "http://www.dickinson.edu/"
       },
       {
          "university": "Dickinson State University",
          "website": "http://www.dsu.nodak.edu/"
       },
       {
          "university": "Dillard University",
          "website": "http://www.dillard.edu/"
       },
       {
          "university": "Divine Word College",
          "website": "http://www.svd.org"
       },
       {
          "university": "Dixie College",
          "website": "http://www.dixie.edu/"
       },
       {
          "university": "Doane College",
          "website": "http://www.doane.edu/"
       },
       {
          "university": "Dominican College",
          "website": "http://www.dc.edu/"
       },
       {
          "university": "Dominican College of San Rafael",
          "website": "http://www.dominican.edu/"
       },
       {
          "university": "Dominican School of Philosophy and Theology",
          "website": "http://www.dspt.edu/"
       },
       {
          "university": "Dominican University",
          "website": "http://www.dom.edu/"
       },
       {
          "university": "Dominion College",
          "website": "http://www.dominion.edu/"
       },
       {
          "university": "Dordt College",
          "website": "http://www.dordt.edu/"
       },
       {
          "university": "Dowling College",
          "website": "http://www.dowling.edu/"
       },
       {
          "university": "Drake University",
          "website": "http://www.drake.edu/"
       },
       {
          "university": "Drew University",
          "website": "http://www.drew.edu/"
       },
       {
          "university": "Drexel University",
          "website": "http://www.drexel.edu/"
       },
       {
          "university": "Drury College",
          "website": "http://www.drury.edu/"
       },
       {
          "university": "Duke University",
          "website": "http://www.duke.edu/"
       },
       {
          "university": "Duluth Business University",
          "website": "http://www.dbumn.edu/"
       },
       {
          "university": "Duquesne University",
          "website": "http://www.duq.edu/"
       },
       {
          "university": "D'Youville College",
          "website": "http://www.dyc.edu/"
       },
       {
          "university": "Earlham College",
          "website": "http://www.earlham.edu/"
       },
       {
          "university": "EarthNet Institute",
          "website": "http://www.eni.edu/"
       },
       {
          "university": "East Carolina University",
          "website": "http://www.ecu.edu/"
       },
       {
          "university": "East Central University",
          "website": "http://www.ecok.edu/"
       },
       {
          "university": "Eastern College",
          "website": "http://www.eastern.edu/"
       },
       {
          "university": "Eastern Connecticut State University",
          "website": "http://www.ecsu.ctstateu.edu/"
       },
       {
          "university": "Eastern Conservatory of Music",
          "website": "http://easternconservatory-music.org/"
       },
       {
          "university": "Eastern Illinois University",
          "website": "http://www.eiu.edu/"
       },
       {
          "university": "Eastern Kentucky University",
          "website": "http://www.eku.edu/"
       },
       {
          "university": "Eastern Mennonite University",
          "website": "http://www.emu.edu/"
       },
       {
          "university": "Eastern Michigan University",
          "website": "http://www.emich.edu/"
       },
       {
          "university": "Eastern Nazarene College",
          "website": "http://www.enc.edu/"
       },
       {
          "university": "Eastern New Mexico University",
          "website": "http://www.enmu.edu/"
       },
       {
          "university": "Eastern Oregon University",
          "website": "http://www.eou.edu/"
       },
       {
          "university": "Eastern Virginia Medical School",
          "website": "http://www.evms.edu/"
       },
       {
          "university": "Eastern Washington University",
          "website": "http://www.ewu.edu/"
       },
       {
          "university": "East Stroudsburg State University",
          "website": "http://www.esu.edu/"
       },
       {
          "university": "East Tennessee State University",
          "website": "http://www.etsu.edu/"
       },
       {
          "university": "East Texas Baptist University",
          "website": "http://www.etbu.edu/"
       },
       {
          "university": "East-West University",
          "website": "http://www.eastwest.edu/"
       },
       {
          "university": "Eckerd College",
          "website": "http://www.eckerd.edu/"
       },
       {
          "university": "Edgewood College",
          "website": "http://www.edgewood.edu/"
       },
       {
          "university": "Edinboro University of Pennsylvania",
          "website": "http://www.edinboro.edu/"
       },
       {
          "university": "Edison Community College",
          "website": "http://www.edison.edu/"
       },
       {
          "university": "Edward Waters College",
          "website": "http://www.ewc.edu/"
       },
       {
          "university": "Elizabeth City State University",
          "website": "http://www.ecsu.edu/"
       },
       {
          "university": "Elizabethtown College",
          "website": "http://www.etown.edu/"
       },
       {
          "university": "Elmhurst College",
          "website": "http://www.elmhurst.edu/"
       },
       {
          "university": "Elmira College",
          "website": "http://www.elmira.edu/"
       },
       {
          "university": "Elms College",
          "website": "http://www.elms.edu/"
       },
       {
          "university": "Elon College",
          "website": "http://www.elon.edu/"
       },
       {
          "university": "Embry-Riddle Aeronautical University",
          "website": "http://www.embryriddle.edu/"
       },
       {
          "university": "Embry Riddle Aeronautical University - Prescott",
          "website": "http://www.prescott.erau.edu/"
       },
       {
          "university": "Emerson College",
          "website": "http://www.emerson.edu/"
       },
       {
          "university": "Emmanuel College",
          "website": "http://www.emmanuel.edu/"
       },
       {
          "university": "Emmanuel College Georgia",
          "website": "http://www.emmanuel-college.edu/"
       },
       {
          "university": "Emmaus Bible College",
          "website": "http://www.emmaus.edu/"
       },
       {
          "university": "Emory & Henry College",
          "website": "http://www.ehc.edu/"
       },
       {
          "university": "Emory University",
          "website": "http://www.emory.edu/"
       },
       {
          "university": "Emporia State University",
          "website": "http://www.emporia.edu/"
       },
       {
          "university": "Erskine College",
          "website": "http://www.erskine.edu/"
       },
       {
          "university": "Eugene Bible College",
          "website": "http://www.ebc.edu/"
       },
       {
          "university": "Eureka College",
          "website": "http://www.eureka.edu/"
       },
       {
          "university": "Evangel University",
          "website": "http://www.evangel.edu/"
       },
       {
          "university": "Evergreen State College",
          "website": "http://www.evergreen.edu/"
       },
       {
          "university": "Excel College",
          "website": "http://www.excelcollege.org/"
       },
       {
          "university": "Excelsior College",
          "website": "http://www.regents.edu/"
       },
       {
          "university": "Fairfield University",
          "website": "http://www.fairfield.edu/"
       },
       {
          "university": "Fairleigh Dickinson University - Florham-Madison Campus",
          "website": "http://www.fdu.edu/visitorcenter/fmwelcome.html"
       },
       {
          "university": "Fairleigh Dickinson University - Teaneck-Hackensack Campus",
          "website": "http://www.fdu.edu/visitorcenter/thwelcome.html"
       },
       {
          "university": "Fairmont State College",
          "website": "http://www.fscwv.edu/"
       },
       {
          "university": "Fashion Institute of New York",
          "website": "http://www.fitnyc.suny.edu/"
       },
       {
          "university": "Faulkner University",
          "website": "http://www.faulkner.edu/"
       },
       {
          "university": "Fayetteville State University",
          "website": "http://www.uncfsu.edu/"
       },
       {
          "university": "Felician College",
          "website": "http://www.felician.edu/"
       },
       {
          "university": "Ferris State University",
          "website": "http://www.ferris.edu/"
       },
       {
          "university": "Ferrum College",
          "website": "http://www.ferrum.edu/"
       },
       {
          "university": "Fielding Institute",
          "website": "http://www.fielding.edu/"
       },
       {
          "university": "Finch University of Health Sciences - The Chicago Medical School",
          "website": "http://www.finchcms.edu/"
       },
       {
          "university": "Finlandia University",
          "website": "http://www.finlandia.edu/"
       },
       {
          "university": "Fisher College",
          "website": "http://www.fisher.edu/"
       },
       {
          "university": "Fisk University",
          "website": "http://www.fisk.edu/"
       },
       {
          "university": "Fitchburg State College",
          "website": "http://www.fsc.edu/"
       },
       {
          "university": "Flagler College",
          "website": "http://www.flagler.edu/"
       },
       {
          "university": "Florida Agricultural and Mechanical University",
          "website": "http://www.famu.edu/"
       },
       {
          "university": "Florida Atlantic University",
          "website": "http://www.fau.edu/"
       },
       {
          "university": "Florida Christian College",
          "website": "http://www.fcc.edu/"
       },
       {
          "university": "Florida Community College at Jacksonville",
          "website": "http://www.fccj.org/"
       },
       {
          "university": "Florida Gulf Coast University",
          "website": "http://www.fgcu.edu/"
       },
       {
          "university": "Florida Institute of Technology",
          "website": "http://www.fit.edu/"
       },
       {
          "university": "Florida International University",
          "website": "http://www.fiu.edu/"
       },
       {
          "university": "Florida Memorial College",
          "website": "http://www.fmc.edu/"
       },
       {
          "university": "Florida Metropolitan University - Fort Lauderdale College",
          "website": "http://www.fmu.edu/784/f-784.htm"
       },
       {
          "university": "Florida Metropolitan University - Tampa College",
          "website": "http://www.fmu.edu/762/f-762.htm"
       },
       {
          "university": "Florida Metropolitan University - Tampa College Brandon",
          "website": "http://www.fmu.edu/764/f-764.htm"
       },
       {
          "university": "Florida Metropolitan University - Tampa College Lakeland",
          "website": "http://www.fmu.edu/765/f-765.htm"
       },
       {
          "university": "Florida Southern College",
          "website": "http://www.flsouthern.edu/"
       },
       {
          "university": "Florida State University",
          "website": "http://www.fsu.edu/"
       },
       {
          "university": "Florida University of Medicine",
          "website": "http://www.fuom.us/"
       },
       {
          "university": "Fontbonne College",
          "website": "http://www.fontbonne.edu/"
       },
       {
          "university": "Foothill-De Anza Community College District",
          "website": "http://www.fhda.edu/"
       },
       {
          "university": "Fordham University",
          "website": "http://www.fordham.edu/"
       },
       {
          "university": "Forest Institute of Professional Psychology",
          "website": "http://www.forestinstitute.org/"
       },
       {
          "university": "Fort Hays State University",
          "website": "http://www.fhsu.edu/"
       },
       {
          "university": "Fort Lewis College",
          "website": "http://www.fortlewis.edu/"
       },
       {
          "university": "Fort Valley State College",
          "website": "http://www.fvsc.peachnet.edu/"
       },
       {
          "university": "Framingham State College",
          "website": "http://www.framingham.edu/"
       },
       {
          "university": "Franciscan School of Theology",
          "website": "http://www.fst.edu/"
       },
       {
          "university": "Franciscan University of Steubenville",
          "website": "http://www.franuniv.edu/"
       },
       {
          "university": "Francis Marion University",
          "website": "http://www.fmarion.edu/"
       },
       {
          "university": "Franklin and Marshall College",
          "website": "http://www.fandm.edu/"
       },
       {
          "university": "Franklin College",
          "website": "http://www.franklincoll.edu/"
       },
       {
          "university": "Franklin Pierce College",
          "website": "http://www.fpc.edu/"
       },
       {
          "university": "Franklin Pierce Law Center",
          "website": "http://www.fplc.edu/"
       },
       {
          "university": "Franklin University",
          "website": "http://www.franklin.edu/"
       },
       {
          "university": "Franklin W. Olin College of Engineering",
          "website": "http://www.olin.edu/"
       },
       {
          "university": "Frank Lloyd Wright School of Architecture",
          "website": "http://www.taliesin.edu/"
       },
       {
          "university": "Fred Hutchinson Cancer Research Center",
          "website": "http://www.fhcrc.org/"
       },
       {
          "university": "Freed-Hardeman University",
          "website": "http://www.fhu.edu/"
       },
       {
          "university": "Free University (Virginia Theological University)",
          "website": "http://www.vtu.edu/"
       },
       {
          "university": "Free Will Baptist Bible College",
          "website": "http://www.fwbbc.edu/"
       },
       {
          "university": "Fresno City College",
          "website": "http://www.fresnocitycollege.edu/"
       },
       {
          "university": "Fresno Pacific University",
          "website": "http://www.fresno.edu/"
       },
       {
          "university": "Friends University",
          "website": "http://www.friends.edu/"
       },
       {
          "university": "Frostburg State University",
          "website": "http://www.fsu.umd.edu/"
       },
       {
          "university": "Full Sail University",
          "website": "http://www.fullsail.com/"
       },
       {
          "university": "Furman University",
          "website": "http://www.furman.edu/"
       },
       {
          "university": "Gallaudet University",
          "website": "http://www.gallaudet.edu/"
       },
       {
          "university": "Gannon University",
          "website": "http://www.gannon.edu/"
       },
       {
          "university": "Gardner Webb University",
          "website": "http://www.gardner-webb.edu/"
       },
       {
          "university": "Gemological Institute of America",
          "website": "http://www.gia.edu/"
       },
       {
          "university": "Geneva College",
          "website": "http://www.geneva.edu/"
       },
       {
          "university": "George Fox University",
          "website": "http://www.georgefox.edu/"
       },
       {
          "university": "George Mason University",
          "website": "http://www.gmu.edu/"
       },
       {
          "university": "Georgetown College",
          "website": "http://www.georgetowncollege.edu/"
       },
       {
          "university": "Georgetown University",
          "website": "http://www.georgetown.edu/"
       },
       {
          "university": "George Washington University",
          "website": "http://www.gwu.edu/"
       },
       {
          "university": "George Wythe College",
          "website": "http://www.gwc.edu/"
       },
       {
          "university": "Georgia Baptist College of Nursing",
          "website": "http://www.gbcn.edu/"
       },
       {
          "university": "Georgia College & State University",
          "website": "http://www.gcsu.edu/"
       },
       {
          "university": "Georgia Health Sciences University",
          "website": "http://www.georgiahealth.edu/"
       },
       {
          "university": "Georgia Institute of Technology",
          "website": "http://www.gatech.edu/"
       },
       {
          "university": "Georgian Court College",
          "website": "http://www.georgian.edu/"
       },
       {
          "university": "Georgia School of Professional Psychology",
          "website": "http://www.aspp.edu/ga.html"
       },
       {
          "university": "Georgia Southern University",
          "website": "http://www.georgiasouthern.edu/"
       },
       {
          "university": "Georgia Southwestern University",
          "website": "http://www.gsw.edu/"
       },
       {
          "university": "Georgia State University",
          "website": "http://www.gsu.edu/"
       },
       {
          "university": "Gettysburg College",
          "website": "http://www.gettysburg.edu/"
       },
       {
          "university": "Glendale University College of Law",
          "website": "http://www.glendalelaw.edu/"
       },
       {
          "university": "Glenville State College",
          "website": "http://www.glenville.wvnet.edu/"
       },
       {
          "university": "Goddard College",
          "website": "http://www.goddard.edu/"
       },
       {
          "university": "God's Bible School and College",
          "website": "http://www.gbs.edu/"
       },
       {
          "university": "Golden Gate University",
          "website": "http://www.ggu.edu/"
       },
       {
          "university": "Goldey-Beacom College",
          "website": "http://goldey.gbc.edu/"
       },
       {
          "university": "Gonzaga University",
          "website": "http://www.gonzaga.edu/"
       },
       {
          "university": "Gordon College",
          "website": "http://www.gordon.edu/"
       },
       {
          "university": "Gordon Conwell Theological Seminary",
          "website": "http://www.gcts.edu/"
       },
       {
          "university": "Goshen College",
          "website": "http://www.goshen.edu/"
       },
       {
          "university": "Goucher College",
          "website": "http://www.goucher.edu/"
       },
       {
          "university": "Governors State University",
          "website": "http://www.govst.edu/"
       },
       {
          "university": "Grace Bible College",
          "website": "http://www.gbcol.edu/"
       },
       {
          "university": "Grace College",
          "website": "http://www.grace.edu/"
       },
       {
          "university": "Graceland College",
          "website": "http://www.graceland.edu/"
       },
       {
          "university": "Grace University",
          "website": "http://www.graceu.edu/"
       },
       {
          "university": "Graduate Theological Union",
          "website": "http://www.gtu.edu/"
       },
       {
          "university": "Grambling State University",
          "website": "http://www.gram.edu/"
       },
       {
          "university": "Grand Canyon University",
          "website": "http://www.gcu.edu/"
       },
       {
          "university": "Grand Valley State University",
          "website": "http://www.gvsu.edu/"
       },
       {
          "university": "Grand View College",
          "website": "http://www.gvc.edu/"
       },
       {
          "university": "Grantham University",
          "website": "http://www.grantham.edu/"
       },
       {
          "university": "Gratz College",
          "website": "http://www.gratzcollege.edu/"
       },
       {
          "university": "Great Lakes Christian College",
          "website": "http://www.glcc.edu/"
       },
       {
          "university": "Green Mountain College",
          "website": "http://www.greenmtn.edu/"
       },
       {
          "university": "Greensboro College",
          "website": "http://www.gborocollege.edu/"
       },
       {
          "university": "Greenville College",
          "website": "http://www.greenville.edu/"
       },
       {
          "university": "Grinnell College",
          "website": "http://www.grinnell.edu/"
       },
       {
          "university": "Grove City College",
          "website": "http://www.gcc.edu/"
       },
       {
          "university": "Guilford College",
          "website": "http://www.guilford.edu/"
       },
       {
          "university": "Gustavus Adolphus College",
          "website": "http://www.gac.edu/"
       },
       {
          "university": "Gwynedd-Mercy College",
          "website": "http://www.gmc.edu/"
       },
       {
          "university": "Hagerstown Community College",
          "website": "http://www.hcc.cc.md.us/"
       },
       {
          "university": "Hamilton College",
          "website": "http://www.hamilton.edu/"
       },
       {
          "university": "Hamilton Technical College",
          "website": "http://www.vca1.com/hamiltontech/"
       },
       {
          "university": "Hamline University",
          "website": "http://www.hamline.edu/"
       },
       {
          "university": "Hampden-Sydney College",
          "website": "http://www.hsc.edu/"
       },
       {
          "university": "Hampshire College",
          "website": "http://www.hampshire.edu/"
       },
       {
          "university": "Hampton College",
          "website": "http://www.hamptoncollege.org/"
       },
       {
          "university": "Hampton University",
          "website": "http://www.hamptonu.edu/"
       },
       {
          "university": "Hannibal-Lagrange College",
          "website": "http://www.hlg.edu/"
       },
       {
          "university": "Hanover College",
          "website": "http://www.hanover.edu/"
       },
       {
          "university": "Harding University",
          "website": "http://www.harding.edu/"
       },
       {
          "university": "Harding University Graduate School of Religion",
          "website": "http://www.hugsr.edu/"
       },
       {
          "university": "Hardin-Simmons University",
          "website": "http://www.hsutx.edu/"
       },
       {
          "university": "Harrington Institute of Interior Design",
          "website": "http://www.interiordesign.edu/"
       },
       {
          "university": "Harris-Stowe State University",
          "website": "http://www.hssu.edu/"
       },
       {
          "university": "Hartford College for Women",
          "website": "http://www.hartford.edu/SchoolsColleges/HCW/HCW.html"
       },
       {
          "university": "Hartford Graduate Center (Rensselaer at Hartford)",
          "website": "http://www.hgc.edu/"
       },
       {
          "university": "Hartwick College",
          "website": "http://www.hartwick.edu/"
       },
       {
          "university": "Harvard University",
          "website": "http://www.harvard.edu/"
       },
       {
          "university": "Harvey Mudd College",
          "website": "http://www.hmc.edu/"
       },
       {
          "university": "Hastings College",
          "website": "http://www.hastings.edu/"
       },
       {
          "university": "Haverford College",
          "website": "http://www.haverford.edu/"
       },
       {
          "university": "Hawaii Pacific University",
          "website": "http://www.hpu.edu/"
       },
       {
          "university": "Heartland Baptist Bible College",
          "website": "http://www.hbbc.edu/"
       },
       {
          "university": "Hebrew College",
          "website": "http://www.hebrewcollege.edu/"
       },
       {
          "university": "Heidelberg College",
          "website": "http://www.heidelberg.edu/"
       },
       {
          "university": "Henderson State Univerisity",
          "website": "http://www.hsu.edu/"
       },
       {
          "university": "Hendrix College",
          "website": "http://www.hendrix.edu/"
       },
       {
          "university": "Heritage College",
          "website": "http://www.heritage.edu/"
       },
       {
          "university": "Hesser College",
          "website": "http://www.hesser.edu/"
       },
       {
          "university": "High Point University",
          "website": "http://www.highpoint.edu/"
       },
       {
          "university": "Hilbert College",
          "website": "http://www.hilbert.edu/"
       },
       {
          "university": "Hillsdale College",
          "website": "http://www.hillsdale.edu/"
       },
       {
          "university": "Hiram College",
          "website": "http://www.hiram.edu/"
       },
       {
          "university": "Hobart and William Smith Colleges",
          "website": "http://www.hws.edu/"
       },
       {
          "university": "Hobe Sound Bible College",
          "website": "http://www.hsbc.edu/"
       },
       {
          "university": "Hodges University",
          "website": "http://www.hodges.edu/"
       },
       {
          "university": "Hofstra University",
          "website": "http://www.hofstra.edu/"
       },
       {
          "university": "Hollins University",
          "website": "http://www.hollins.edu/"
       },
       {
          "university": "Holy Apostles College",
          "website": "http://www.holy-apostles.org/"
       },
       {
          "university": "Holy Cross College",
          "website": "http://www.hcc-nd.edu/"
       },
       {
          "university": "Holy Family College",
          "website": "http://www.hfc.edu/"
       },
       {
          "university": "Holy Names College",
          "website": "http://www.hnc.edu/"
       },
       {
          "university": "Hood College",
          "website": "http://www.hood.edu/"
       },
       {
          "university": "Hope College",
          "website": "http://www.hope.edu/"
       },
       {
          "university": "Hope International University",
          "website": "http://www.hiu.edu/"
       },
       {
          "university": "Houghton College",
          "website": "http://www.houghton.edu/"
       },
       {
          "university": "Houston Baptist University",
          "website": "http://www.hbu.edu/"
       },
       {
          "university": "Howard Payne University",
          "website": "http://www.hputx.edu/"
       },
       {
          "university": "Howard University",
          "website": "http://www.howard.edu/"
       },
       {
          "university": "Humboldt State University",
          "website": "http://www.humboldt.edu/"
       },
       {
          "university": "Humphreys College",
          "website": "http://www.humphreys.edu/"
       },
       {
          "university": "Huntington College",
          "website": "http://www.huntcol.edu/"
       },
       {
          "university": "Huron University",
          "website": "http://www.huron.edu/"
       },
       {
          "university": "Husson College",
          "website": "http://www.husson.edu/"
       },
       {
          "university": "Huston-Tillotson College",
          "website": "http://www.htc.edu/"
       },
       {
          "university": "ICI University",
          "website": "http://www.ici.edu/"
       },
       {
          "university": "ICT College",
          "website": "http://www.ictcollege.edu/"
       },
       {
          "university": "Idaho State University",
          "website": "http://www.isu.edu/"
       },
       {
          "university": "Iglobal University",
          "website": "http://www.iglobal.edu/"
       },
       {
          "university": "Illinois Benedictine University",
          "website": "http://www.ben.edu/"
       },
       {
          "university": "Illinois College",
          "website": "http://www.ic.edu/"
       },
       {
          "university": "Illinois College of Optometry",
          "website": "http://www.ico.edu/"
       },
       {
          "university": "Illinois Institute of Technology",
          "website": "http://www.iit.edu/"
       },
       {
          "university": "Illinois School of Professional Psychology - Chicago Campus",
          "website": "http://www.aspp.edu/ilch.html"
       },
       {
          "university": "Illinois School of Professional Psychology - Meadows Campus",
          "website": "http://www.aspp.edu/ilrm.html"
       },
       {
          "university": "Illinois State University",
          "website": "http://www.ilstu.edu/"
       },
       {
          "university": "Illinois Valley Community College",
          "website": "http://www.ivcc.edu/"
       },
       {
          "university": "Illinois Wesleyan University",
          "website": "http://www.iwu.edu/"
       },
       {
          "university": "Immaculata University",
          "website": "http://www.immaculata.edu/"
       },
       {
          "university": "IMPAC University",
          "website": "http://www.impacu.edu/"
       },
       {
          "university": "Indiana Institute of Technologyy",
          "website": "http://www.indtech.edu/"
       },
       {
          "university": "Indiana State University",
          "website": "http://www.indstate.edu/"
       },
       {
          "university": "Indiana University at Bloomington",
          "website": "http://www.iub.edu/"
       },
       {
          "university": "Indiana University at Kokomo",
          "website": "http://www.iuk.edu/"
       },
       {
          "university": "Indiana University at South Bend",
          "website": "http://www.iusb.edu/"
       },
       {
          "university": "Indiana University - East",
          "website": "http://www.iue.indiana.edu/"
       },
       {
          "university": "Indiana University - Northwest",
          "website": "http://www.iun.indiana.edu/"
       },
       {
          "university": "Indiana University of Pennsylvania",
          "website": "http://www.iup.edu/"
       },
       {
          "university": "Indiana University-Purdue University at Columbus",
          "website": "http://www.columbus.iupui.edu/"
       },
       {
          "university": "Indiana University-Purdue University at Fort Wayne",
          "website": "http://www.ipfw.edu/"
       },
       {
          "university": "Indiana University-Purdue University at Indianapolis",
          "website": "http://www.iupui.edu/"
       },
       {
          "university": "Indiana University - Southeast",
          "website": "http://www.ius.indiana.edu/"
       },
       {
          "university": "Indiana University (System)",
          "website": "http://www.indiana.edu/"
       },
       {
          "university": "Indiana Wesleyan University",
          "website": "http://www.indwes.edu/"
       },
       {
          "university": "Institute of Clinical Social Work",
          "website": "http://www.icsw.com/"
       },
       {
          "university": "Institute of Paper Science and Technology",
          "website": "http://www.ipst.edu/"
       },
       {
          "university": "Institute of Textile Technology",
          "website": "http://www.itt.edu/"
       },
       {
          "university": "Institute of Transpersonal Psychology",
          "website": "http://www.itp.edu/"
       },
       {
          "university": "IntelliTec College - Grand Junction",
          "website": "http://www.intelliteccollege.edu/"
       },
       {
          "university": "International Academy of  Merchandising and Design Chicago",
          "website": "http://www.iamd.edu/"
       },
       {
          "university": "International Academy of  Merchandising and Design Tampa",
          "website": "http://www.academy.edu/"
       },
       {
          "university": "International Bible College",
          "website": "http://www.i-b-c.edu/"
       },
       {
          "university": "International College",
          "website": "http://www.internationalcollege.edu/"
       },
       {
          "university": "Iona College",
          "website": "http://www.iona.edu/"
       },
       {
          "university": "Iowa State University of Science and Technology",
          "website": "http://www.iastate.edu/"
       },
       {
          "university": "Iowa Wesleyan College",
          "website": "http://www.iwc.edu/"
       },
       {
          "university": "Ithaca College",
          "website": "http://www.ithaca.edu/"
       },
       {
          "university": "ITT Technical Institute Fort Wayne",
          "website": "http://www.itttech.edu/campus/"
       },
       {
          "university": "ITT Technical Institute Indianapolis",
          "website": "http://www.itttech.edu/campus/"
       },
       {
          "university": "ITT Technical Institute Maitland",
          "website": "http://www.itttech.edu/campus/"
       },
       {
          "university": "ITT Technical Institute Portland",
          "website": "http://www.itttech.edu/campus/"
       },
       {
          "university": "ITT Technical Institute West Covina",
          "website": "http://www.itttech.edu/campus/"
       },
       {
          "university": "Jackson State University",
          "website": "http://www.jsums.edu/"
       },
       {
          "university": "Jacksonville State University",
          "website": "http://www.jsu.edu/"
       },
       {
          "university": "Jacksonville University",
          "website": "http://www.ju.edu/"
       },
       {
          "university": "James Madison University",
          "website": "http://www.jmu.edu/"
       },
       {
          "university": "Jamestown College",
          "website": "http://www.jc.edu/"
       },
       {
          "university": "Jarvis Christian College",
          "website": "http://www.jarvis.edu/"
       },
       {
          "university": "John Brown University",
          "website": "http://www.jbu.edu/"
       },
       {
          "university": "John Carroll University",
          "website": "http://www.jcu.edu/"
       },
       {
          "university": "John F. Kennedy University",
          "website": "http://www.jfku.edu/"
       },
       {
          "university": "John Marshall Law School",
          "website": "http://www.jmls.edu/"
       },
       {
          "university": "John Paul the Great Catholic University",
          "website": "http://www.jpcatholic.com/"
       },
       {
          "university": "Johns Hopkins University",
          "website": "http://www.jhu.edu/"
       },
       {
          "university": "Johnson Bible College",
          "website": "http://www.jbc.edu/"
       },
       {
          "university": "Johnson County Community College",
          "website": "http://www.jccc.edu/"
       },
       {
          "university": "Johnson C. Smith University",
          "website": "http://www.jcsu.edu/"
       },
       {
          "university": "Johnson State College",
          "website": "http://www.jsc.vsc.edu/"
       },
       {
          "university": "Johnson & Wales University",
          "website": "http://www.jwu.edu/"
       },
       {
          "university": "Johnson & Wales University - Charleston",
          "website": "http://www.jwu.edu/charles/camp_charles.htm"
       },
       {
          "university": "John Wesley College",
          "website": "http://www.johnwesley.edu/"
       },
       {
          "university": "Jones College",
          "website": "http://www.jones.edu/"
       },
       {
          "university": "Jones International University",
          "website": "http://www.jonesinternational.edu/"
       },
       {
          "university": "Judson College Elgin",
          "website": "http://www.judson-il.edu/"
       },
       {
          "university": "Judson College Marion",
          "website": "http://home.judson.edu/"
       },
       {
          "university": "Juniata College",
          "website": "http://www.juniata.edu/"
       },
       {
          "university": "Kalamazoo College",
          "website": "http://www.kzoo.edu/"
       },
       {
          "university": "Kankakee Community College",
          "website": "http://www.kankakeecc.org/"
       },
       {
          "university": "Kansas City Art Institute",
          "website": "http://www.kcai.edu/"
       },
       {
          "university": "Kansas State University",
          "website": "http://www.ksu.edu/"
       },
       {
          "university": "Kansas Wesleyan University",
          "website": "http://www.kwu.edu/"
       },
       {
          "university": "Kaplan University",
          "website": "http://www.kaplan.edu/"
       },
       {
          "university": "Katharine Gibbs School",
          "website": "http://www.kgibbs.com/"
       },
       {
          "university": "Kean University of New Jersey",
          "website": "http://www.kean.edu/"
       },
       {
          "university": "Keck Graduate Institute of Applied Life Sciences",
          "website": "http://www.kgi.edu/"
       },
       {
          "university": "Keene State College",
          "website": "http://www.keene.edu/"
       },
       {
          "university": "Keller Graduate School of Management",
          "website": "http://www.keller.edu/"
       },
       {
          "university": "Kendall College",
          "website": "http://www.kendall.edu/"
       },
       {
          "university": "Kendall College of Art and Design",
          "website": "http://www.kcad.edu/"
       },
       {
          "university": "Kennesaw State University",
          "website": "http://www.kennesaw.edu/"
       },
       {
          "university": "Kent State University",
          "website": "http://www.kent.edu/"
       },
       {
          "university": "Kent State University - Ashtabula",
          "website": "http://www.ashtabula.kent.edu/"
       },
       {
          "university": "Kent State University - East Liverpool",
          "website": "http://www.kenteliv.kent.edu/"
       },
       {
          "university": "Kent State University - Salem",
          "website": "http://www.salem.kent.edu/"
       },
       {
          "university": "Kent State University - Stark",
          "website": "http://www.stark.kent.edu/"
       },
       {
          "university": "Kent State University - Trumbull",
          "website": "http://www.trumbull.kent.edu/"
       },
       {
          "university": "Kent State University - Tuscarawas",
          "website": "http://www.tusc.kent.edu/"
       },
       {
          "university": "Kentucky Christian College",
          "website": "http://www.kcc.edu/"
       },
       {
          "university": "Kentucky State University",
          "website": "http://www.kysu.edu/"
       },
       {
          "university": "Kentucky Wesleyan College",
          "website": "http://www.kwc.edu/"
       },
       {
          "university": "Kenyon College",
          "website": "http://www.kenyon.edu/"
       },
       {
          "university": "Kettering University (GMI)",
          "website": "http://www.kettering.edu/"
       },
       {
          "university": "Keuka College",
          "website": "http://www.keuka.edu/"
       },
       {
          "university": "King College",
          "website": "http://www.king.edu/"
       },
       {
          "university": "King's College",
          "website": "http://www.kings.edu/"
       },
       {
          "university": "Kirksville College of Osteopathic Medicine",
          "website": "http://www.kcom.edu/"
       },
       {
          "university": "Kirkwood Community College",
          "website": "http://www.kirkwood.edu/"
       },
       {
          "university": "Knox College",
          "website": "http://www.knox.edu/"
       },
       {
          "university": "Knoxville College",
          "website": "http://www.knoxvillecollege.edu/"
       },
       {
          "university": "Kutztown University of Pennsylvania",
          "website": "http://www.kutztown.edu/"
       },
       {
          "university": "Laboratory Institute of Merchandising",
          "website": "http://www.limcollege.edu/"
       },
       {
          "university": "Lafayette College",
          "website": "http://www.lafayette.edu/"
       },
       {
          "university": "Lagrange College",
          "website": "http://www.lgc.edu/"
       },
       {
          "university": "Lake Erie College",
          "website": "http://www.lakeerie.edu/"
       },
       {
          "university": "Lake Forest College",
          "website": "http://www.lfc.edu/"
       },
       {
          "university": "Lake Forest Graduate School of Management",
          "website": "http://www.lfgsm.edu/"
       },
       {
          "university": "Lakeland College",
          "website": "http://www.lakeland.edu/"
       },
       {
          "university": "Lake Superior State University",
          "website": "http://www.lssu.edu/"
       },
       {
          "university": "Lakeview College of Nursing",
          "website": "http://www.lakeviewcol.edu/"
       },
       {
          "university": "Lamar University",
          "website": "http://www.lamar.edu/"
       },
       {
          "university": "Lamar University - Port Arthur",
          "website": "http://www.pa.lamar.edu/"
       },
       {
          "university": "Lambuth University",
          "website": "http://www.lambuth.edu/"
       },
       {
          "university": "Lancaster Bible College",
          "website": "http://www.lbc.edu/"
       },
       {
          "university": "Lander University",
          "website": "http://www.lander.edu/"
       },
       {
          "university": "Lane College",
          "website": "http://www.lanecollege.edu/"
       },
       {
          "university": "Langston University",
          "website": "http://www.lunet.edu/"
       },
       {
          "university": "La Roche College",
          "website": "http://www.laroche.edu/"
       },
       {
          "university": "La Salle University",
          "website": "http://www.lasalle.edu/"
       },
       {
          "university": "Lasell College",
          "website": "http://www.lasell.edu/"
       },
       {
          "university": "La Sierra University",
          "website": "http://www.lasierra.edu/"
       },
       {
          "university": "Laurus Technical Institute",
          "website": "http://www.laurus.edu/"
       },
       {
          "university": "Lawrence Technological University",
          "website": "http://www.ltu.edu/"
       },
       {
          "university": "Lawrence University",
          "website": "http://www.lawrence.edu/"
       },
       {
          "university": "Lebanon Valley College",
          "website": "http://www.lvc.edu/"
       },
       {
          "university": "Lees-McRae College",
          "website": "http://www.lmc.edu/"
       },
       {
          "university": "Lee University",
          "website": "http://www.leeuniversity.edu/"
       },
       {
          "university": "Lehigh Univervsity",
          "website": "http://www.lehigh.edu/"
       },
       {
          "university": "Le Moyne College",
          "website": "http://www.lemoyne.edu/"
       },
       {
          "university": "Le Moyne-Owen College",
          "website": "http://www.lemoyne-owen.edu/"
       },
       {
          "university": "Lenoir-Rhyne College",
          "website": "http://www.lrc.edu/"
       },
       {
          "university": "Lesley University",
          "website": "http://www.lesley.edu/"
       },
       {
          "university": "Letourneau University",
          "website": "http://www.letu.edu/"
       },
       {
          "university": "Lewis and Clark College",
          "website": "http://www.lclark.edu/"
       },
       {
          "university": "Lewis & Clark Community College",
          "website": "http://www.lc.cc.il.us/"
       },
       {
          "university": "Lewis-Clark State College",
          "website": "http://www.lcsc.edu/"
       },
       {
          "university": "Lewis University",
          "website": "http://www.lewisu.edu/"
       },
       {
          "university": "Liberty University",
          "website": "http://www.liberty.edu/"
       },
       {
          "university": "Life Chiropractic College West",
          "website": "http://www.lifewest.edu/"
       },
       {
          "university": "Life University",
          "website": "http://www.life.edu/"
       },
       {
          "university": "Limestone College",
          "website": "http://www.limestone.edu/"
       },
       {
          "university": "Lincoln Memorial University",
          "website": "http://www.lmunet.edu/"
       },
       {
          "university": "Lincoln University Missouri",
          "website": "http://www.lincolnu.edu/"
       },
       {
          "university": "Lincoln University Pennsylvania",
          "website": "http://www.lincoln.edu/"
       },
       {
          "university": "Lincoln University San Francisco",
          "website": "http://www.lincolnuca.edu/"
       },
       {
          "university": "Lindenwood University",
          "website": "http://www.lindenwood.edu/"
       },
       {
          "university": "Lindsey Wilson College",
          "website": "http://www.lindsey.edu/"
       },
       {
          "university": "Linfield College",
          "website": "http://www.linfield.edu/"
       },
       {
          "university": "Lipscomb University",
          "website": "http://www.lipscomb.edu/"
       },
       {
          "university": "Livingstone College",
          "website": "http://www.livingstone.edu/"
       },
       {
          "university": "Lock Haven University of Pennsylvania",
          "website": "http://www.lhup.edu/"
       },
       {
          "university": "Logan College of Chiropractic",
          "website": "http://www.logan.edu/"
       },
       {
          "university": "Loma Linda University",
          "website": "http://www.llu.edu/"
       },
       {
          "university": "Long Island University",
          "website": "http://www.liunet.edu/"
       },
       {
          "university": "Long Island University - C.W. Post Campus",
          "website": "http://www.cwpost.liunet.edu/cwis/cwp/post.html"
       },
       {
          "university": "Long Island University - Southampton College",
          "website": "http://www.southampton.liunet.edu/"
       },
       {
          "university": "Longwood College",
          "website": "http://www.lwc.edu/"
       },
       {
          "university": "Loras College",
          "website": "http://www.loras.edu/"
       },
       {
          "university": "Los Angeles College of Chiropractic",
          "website": "http://www.lacc.edu/"
       },
       {
          "university": "Louisiana Baptist University",
          "website": "http://www.lbu.edu/"
       },
       {
          "university": "Louisiana College",
          "website": "http://www.lacollege.edu/"
       },
       {
          "university": "Louisiana State University and Agricultural and Mechanical College",
          "website": "http://www.lsu.edu/"
       },
       {
          "university": "Louisiana State University at Alexandria",
          "website": "http://www.lsua.edu/"
       },
       {
          "university": "Louisiana State University at Eunice",
          "website": "http://www.lsue.edu/"
       },
       {
          "university": "Louisiana State University Health Sciences Center New Orleans",
          "website": "http://www.lsuhsc.edu/"
       },
       {
          "university": "Louisiana State University in Shreveport",
          "website": "http://www.lsus.edu/"
       },
       {
          "university": "Louisiana Tech University",
          "website": "http://www.latech.edu/"
       },
       {
          "university": "Lourdes College",
          "website": "http://www.lourdes.edu/"
       },
       {
          "university": "Loyola College in Maryland",
          "website": "http://www.loyola.edu/"
       },
       {
          "university": "Loyola Marymount University",
          "website": "http://www.lmu.edu/"
       },
       {
          "university": "Loyola University New Orleans",
          "website": "http://www.loyno.edu/"
       },
       {
          "university": "Loyola University of Chicago",
          "website": "http://www.luc.edu/"
       },
       {
          "university": "Lubbock Christian University",
          "website": "http://www.lcu.edu/"
       },
       {
          "university": "Lutheran Bible Institute of Seattle",
          "website": "http://www.lbi.edu/"
       },
       {
          "university": "Luther College",
          "website": "http://www.luther.edu/"
       },
       {
          "university": "Lycoming College",
          "website": "http://www.lycoming.edu/"
       },
       {
          "university": "Lynchburg College",
          "website": "http://www.lynchburg.edu/"
       },
       {
          "university": "Lyndon State College",
          "website": "http://www.lsc.vsc.edu/"
       },
       {
          "university": "Lynn University",
          "website": "http://www.lynn.edu/"
       },
       {
          "university": "Lyon College",
          "website": "http://www.lyon.edu/"
       },
       {
          "university": "Macalester College",
          "website": "http://www.macalstr.edu/"
       },
       {
          "university": "MacMurray College",
          "website": "http://www.mac.edu/"
       },
       {
          "university": "Macon State College",
          "website": "http://www.maconstate.edu/"
       },
       {
          "university": "Madison University (Distance Education)",
          "website": "http://www.madisonu.com/"
       },
       {
          "university": "Madonna University",
          "website": "http://madonna2.siteobjects.com/pages/index.cfm"
       },
       {
          "university": "Maharishi University of Management",
          "website": "http://www.mum.edu/"
       },
       {
          "university": "Maine College of Art",
          "website": "http://www.meca.edu/"
       },
       {
          "university": "Maine Maritime Academy",
          "website": "http://www.mainemaritime.edu/"
       },
       {
          "university": "Malone College",
          "website": "http://www.malone.edu/"
       },
       {
          "university": "Manchester College",
          "website": "http://www.manchester.edu/"
       },
       {
          "university": "Manhattan Christian College",
          "website": "http://www.mccks.edu/"
       },
       {
          "university": "Manhattan College",
          "website": "http://www.mancol.edu/"
       },
       {
          "university": "Manhattan School of Music",
          "website": "http://www.msmnyc.edu/"
       },
       {
          "university": "Manhattanville College",
          "website": "http://www.mville.edu/"
       },
       {
          "university": "Mankato State University",
          "website": "http://www.mankato.msus.edu/"
       },
       {
          "university": "Mansfield University of Pennsylvania",
          "website": "http://www.mnsfld.edu/"
       },
       {
          "university": "Maranatha Baptist Bible College",
          "website": "http://www.mbbc.edu/"
       },
       {
          "university": "Marian College",
          "website": "http://www.marian.edu/"
       },
       {
          "university": "Marian College of Fond Du Lac",
          "website": "http://www.mariancoll.edu/"
       },
       {
          "university": "Marietta College",
          "website": "http://www.marietta.edu/"
       },
       {
          "university": "Marist College",
          "website": "http://www.marist.edu/"
       },
       {
          "university": "Marlboro College",
          "website": "http://www.marlboro.edu/"
       },
       {
          "university": "Marquette University",
          "website": "http://www.mu.edu/"
       },
       {
          "university": "Marshall University",
          "website": "http://www.marshall.edu/"
       },
       {
          "university": "Mars Hill College",
          "website": "http://www.mhc.edu/"
       },
       {
          "university": "Martin Luther College",
          "website": "http://www.mlc-wels.edu/"
       },
       {
          "university": "Martin Methodist College",
          "website": "http://www.martinmethodist.edu/"
       },
       {
          "university": "Martin University",
          "website": "http://www.martin.edu/"
       },
       {
          "university": "Mary Baldwin College",
          "website": "http://www.mbc.edu/"
       },
       {
          "university": "Marycrest International University",
          "website": "http://www.mcrest.edu/"
       },
       {
          "university": "Marygrove College",
          "website": "http://www.marygrove.edu/"
       },
       {
          "university": "Marylhurst University",
          "website": "http://www.marylhurst.edu/"
       },
       {
          "university": "Marymount College New York",
          "website": "http://www.marymt.edu/"
       },
       {
          "university": "Marymount Manhattan College",
          "website": "http://marymount.mmm.edu/"
       },
       {
          "university": "Marymount University",
          "website": "http://www.marymount.edu/"
       },
       {
          "university": "Maryville College",
          "website": "http://www.maryvillecollege.edu/"
       },
       {
          "university": "Maryville University of St. Louis",
          "website": "http://www.maryvillestl.edu/"
       },
       {
          "university": "Mary Washington College",
          "website": "http://www.mwc.edu/"
       },
       {
          "university": "Marywood University",
          "website": "http://www.marywood.edu/"
       },
       {
          "university": "Massachusetts College of Art",
          "website": "http://www.massart.edu/"
       },
       {
          "university": "Massachusetts College of Liberal Arts",
          "website": "http://www.mcla.mass.edu/"
       },
       {
          "university": "Massachusetts College of Pharmacy and Allied Health Sciences",
          "website": "http://www.mcp.edu/"
       },
       {
          "university": "Massachusetts Institute of Technology",
          "website": "http://www.mit.edu/"
       },
       {
          "university": "Massachusetts Maritime Academy",
          "website": "http://www.mma.mass.edu/"
       },
       {
          "university": "Massachusetts School of Professional Psychology",
          "website": "http://www.mspp.edu/"
       },
       {
          "university": "Mayo Graduate School",
          "website": "http://www.mayo.edu/mgs/gs.html"
       },
       {
          "university": "Mayo Medical School",
          "website": "http://www.mayo.edu/education/mms/"
       },
       {
          "university": "Mayville State University",
          "website": "http://www.masu.nodak.edu/"
       },
       {
          "university": "McKendree College",
          "website": "http://www.mckendree.edu/"
       },
       {
          "university": "McMurry University",
          "website": "http://www.mcm.edu/"
       },
       {
          "university": "McNeese State University",
          "website": "http://www.mcneese.edu/"
       },
       {
          "university": "McPherson College",
          "website": "http://www.mcpherson.edu/"
       },
       {
          "university": "Medaille College",
          "website": "http://www.medaille.edu/"
       },
       {
          "university": "Medcenter One College of Nursing",
          "website": "http://www.medcenterone.com/nursing/nursing.htm"
       },
       {
          "university": "Medical College of Georgia",
          "website": "http://www.mcg.edu/"
       },
       {
          "university": "Medical College of Ohio",
          "website": "http://www.mco.edu/"
       },
       {
          "university": "Medical College of Pennsylvania and Hahnemann University",
          "website": "http://www.mcphu.edu/"
       },
       {
          "university": "Medical College of Wisconsin",
          "website": "http://www.mcw.edu/"
       },
       {
          "university": "Medical University of South Carolina",
          "website": "http://www.musc.edu/"
       },
       {
          "university": "Meharry Medical College",
          "website": "http://www.mmc.edu/"
       },
       {
          "university": "Memphis College of Art",
          "website": "http://www.mca.edu/"
       },
       {
          "university": "Menlo College",
          "website": "http://www.menlo.edu/"
       },
       {
          "university": "Mennonite College of Nursing",
          "website": "http://www.mcn.ilstu.edu/"
       },
       {
          "university": "Mercer University",
          "website": "http://www.mercer.edu/"
       },
       {
          "university": "Mercer University - Cecil B. Day Campus",
          "website": "http://www.mercer.edu/cbd/"
       },
       {
          "university": "Mercy College",
          "website": "http://www.mercynet.edu/"
       },
       {
          "university": "Mercyhurst College",
          "website": "http://www.mercyhurst.edu/"
       },
       {
          "university": "Meredith College",
          "website": "http://www.meredith.edu/"
       },
       {
          "university": "Merrimack College",
          "website": "http://www.merrimack.edu/"
       },
       {
          "university": "Mesa State College",
          "website": "http://www.mesastate.edu/"
       },
       {
          "university": "Messiah College",
          "website": "http://www.messiah.edu/"
       },
       {
          "university": "Methodist College",
          "website": "http://www.methodist.edu/"
       },
       {
          "university": "Metropolitan State College of Denver",
          "website": "http://www.mscd.edu/"
       },
       {
          "university": "Metropolitan State University",
          "website": "http://www.metro.msus.edu/"
       },
       {
          "university": "MGH Institute of Health Professions",
          "website": "http://www.mghihp.edu/"
       },
       {
          "university": "Miami Dade College",
          "website": "http://www.mdc.edu/"
       },
       {
          "university": "Miami University of Ohio",
          "website": "http://www.muohio.edu/"
       },
       {
          "university": "Miami University of Ohio - Hamilton",
          "website": "http://www.ham.muohio.edu/"
       },
       {
          "university": "Miami University of Ohio - Middletown",
          "website": "http://www.mid.muohio.edu/"
       },
       {
          "university": "Michigan School of Professional Psychology",
          "website": "http://www.mispp.edu/"
       },
       {
          "university": "Michigan State University",
          "website": "http://www.msu.edu/"
       },
       {
          "university": "Michigan Technological University",
          "website": "http://www.mtu.edu/"
       },
       {
          "university": "Mid-America Nazarene University",
          "website": "http://www.mnu.edu/"
       },
       {
          "university": "Mid-American Bible College",
          "website": "http://www.concentric.net/~rkriesel/MBC/MBC.shtml"
       },
       {
          "university": "Mid-Continent Baptist Bible College",
          "website": "http://www.mcbc.edu/"
       },
       {
          "university": "Middlebury College",
          "website": "http://www.middlebury.edu/"
       },
       {
          "university": "Middle Tennessee State University",
          "website": "http://www.mtsu.edu/"
       },
       {
          "university": "Midland Lutheran College",
          "website": "http://www.mlc.edu/"
       },
       {
          "university": "Midway College",
          "website": "http://www.midway.edu/"
       },
       {
          "university": "Midwestern State University",
          "website": "http://www.mwsu.edu/"
       },
       {
          "university": "Midwestern University",
          "website": "http://www.midwestern.edu/"
       },
       {
          "university": "Miles College",
          "website": "http://www.miles.edu/"
       },
       {
          "university": "Millennia Atlantic University",
          "website": "http://www.maufl.edu/"
       },
       {
          "university": "Millersville University of Pennsylvania",
          "website": "http://www.millersv.edu/"
       },
       {
          "university": "Milligan College",
          "website": "http://www.milligan.edu/"
       },
       {
          "university": "Millikin University",
          "website": "http://www.millikin.edu/"
       },
       {
          "university": "Millsaps College",
          "website": "http://www.millsaps.edu/"
       },
       {
          "university": "Mills College",
          "website": "http://www.mills.edu/"
       },
       {
          "university": "Mills Grae University",
          "website": "http://www.mgu.edu/"
       },
       {
          "university": "Milwaukee Institute of Art and Design",
          "website": "http://www.miad.edu/"
       },
       {
          "university": "Milwaukee School of Engineering",
          "website": "http://www.msoe.edu/"
       },
       {
          "university": "Minneapolis College of Art and Design",
          "website": "http://www.mcad.edu/"
       },
       {
          "university": "Minnesota Bible College",
          "website": "http://www.mnbc.edu/"
       },
       {
          "university": "Minnesota School of Professional Psychology",
          "website": "http://www.aspp.edu/mn.html"
       },
       {
          "university": "Minot State University",
          "website": "http://www.misu.nodak.edu/"
       },
       {
          "university": "Mississippi College",
          "website": "http://www.mc.edu/"
       },
       {
          "university": "Mississippi State University",
          "website": "http://www.msstate.edu/"
       },
       {
          "university": "Mississippi University for Women",
          "website": "http://www.muw.edu/"
       },
       {
          "university": "Mississippi Valley State University",
          "website": "http://www.mvsu.edu/"
       },
       {
          "university": "Missouri Baptist College",
          "website": "http://www.mobap.edu/"
       },
       {
          "university": "Missouri Southern State College",
          "website": "http://www.mssc.edu/"
       },
       {
          "university": "Missouri Tech",
          "website": "http://www.motech.edu/"
       },
       {
          "university": "Missouri University of Science and Technology",
          "website": "http://www.mst.edu/"
       },
       {
          "university": "Missouri Valley College",
          "website": "http://www.murlin.com/~webfx/mvc/"
       },
       {
          "university": "Missouri Western State College",
          "website": "http://www.mwsc.edu/"
       },
       {
          "university": "Molloy College",
          "website": "http://www.molloy.edu/"
       },
       {
          "university": "Monmouth University",
          "website": "http://www.monmouth.edu/"
       },
       {
          "university": "Montana State University",
          "website": "http://www.montana.edu/"
       },
       {
          "university": "Montana State University - Billings",
          "website": "http://www.msubillings.edu/"
       },
       {
          "university": "Montana State University - Northern",
          "website": "http://www.msun.edu/"
       },
       {
          "university": "Montana Tech",
          "website": "http://www.mtech.edu/"
       },
       {
          "university": "Montclair State University",
          "website": "http://www.montclair.edu/"
       },
       {
          "university": "Monterey Institute of International Studies",
          "website": "http://www.miis.edu/"
       },
       {
          "university": "Montreat College",
          "website": "http://www.montreat.edu/"
       },
       {
          "university": "Montserrat College of Art",
          "website": "http://www.montserrat.edu/"
       },
       {
          "university": "Moody Bible Institute",
          "website": "http://www.moody.edu/"
       },
       {
          "university": "Moore College of Art and Design",
          "website": "http://www.moore.edu/"
       },
       {
          "university": "Moorhead State University",
          "website": "http://www.moorhead.msus.edu/"
       },
       {
          "university": "Moraine Valley Community College",
          "website": "http://www.morainevalley.edu/"
       },
       {
          "university": "Moravian College",
          "website": "http://www.moravian.edu/"
       },
       {
          "university": "Morehead State University",
          "website": "http://www.morehead-st.edu/"
       },
       {
          "university": "Morehouse College",
          "website": "http://www.morehouse.edu/"
       },
       {
          "university": "Morehouse School of Medicine",
          "website": "http://www.msm.edu/"
       },
       {
          "university": "Morgan State University",
          "website": "http://www.morgan.edu/"
       },
       {
          "university": "Morningside College",
          "website": "http://www.morningside.edu/"
       },
       {
          "university": "Morris Brown College",
          "website": "http://www.morrisbrown.edu/"
       },
       {
          "university": "Morris College",
          "website": "http://www.scicu.org/morris/"
       },
       {
          "university": "Morrison College",
          "website": "http://www.morrison.neumont.edu/"
       },
       {
          "university": "Mountain State University",
          "website": "http://www.mountainstate.edu/"
       },
       {
          "university": "Mount Aloysius College",
          "website": "http://www.mtaloy.edu/"
       },
       {
          "university": "Mount Carmel College of Nursing",
          "website": "http://www.mccn.edu/"
       },
       {
          "university": "Mount Holyoke College",
          "website": "http://www.mtholyoke.edu/"
       },
       {
          "university": "Mount Ida College",
          "website": "http://www.mountida.edu/"
       },
       {
          "university": "Mount Marty College",
          "website": "http://www.mtmc.edu/"
       },
       {
          "university": "Mount Mary College",
          "website": "http://www.mtmary.edu/"
       },
       {
          "university": "Mount Mercy College",
          "website": "http://www.mtmercy.edu/"
       },
       {
          "university": "Mount Olive College",
          "website": "http://www.mountolivecollege.edu/"
       },
       {
          "university": "Mount Senario College",
          "website": "http://www.mscfs.edu/"
       },
       {
          "university": "Mount Sinai School of Medicine",
          "website": "http://www.mssm.edu/"
       },
       {
          "university": "Mount St. Clare College",
          "website": "http://www.clare.edu/"
       },
       {
          "university": "Mount St. Mary College",
          "website": "http://www.msmc.edu/"
       },
       {
          "university": "Mount St. Mary's College California",
          "website": "http://www.msmc.la.edu/"
       },
       {
          "university": "Mount St. Mary's College Maryland",
          "website": "http://www.msmary.edu/"
       },
       {
          "university": "Mount Union College",
          "website": "http://www.mountunion.edu/"
       },
       {
          "university": "Mount Vernon College",
          "website": "http://www.gwu.edu/~mvcgw/"
       },
       {
          "university": "Mount Vernon Nazarene College",
          "website": "http://www.mvnc.edu/"
       },
       {
          "university": "Muhlenberg College",
          "website": "http://www.muhlberg.edu/"
       },
       {
          "university": "Multnomah Bible College",
          "website": "http://www.multnomah.edu/"
       },
       {
          "university": "Murray State University",
          "website": "http://www.mursuky.edu/"
       },
       {
          "university": "Muskingum College",
          "website": "http://www.muskingum.edu/"
       },
       {
          "university": "NAES College",
          "website": "http://www.naes.indian.com/"
       },
       {
          "university": "National American University",
          "website": "http://www.national.edu/"
       },
       {
          "university": "National American University - Albuquerque",
          "website": "http://www.national.edu/albuquerque.html"
       },
       {
          "university": "National American University - Colorado Springs",
          "website": "http://www.national.edu/col_springs.html"
       },
       {
          "university": "National American University - Denver",
          "website": "http://www.national.edu/denver.html"
       },
       {
          "university": "National American University - Kansas City",
          "website": "http://www.national.edu/kansas_city.html"
       },
       {
          "university": "National American University - Roseville",
          "website": "http://www.national.edu/roseville.html"
       },
       {
          "university": "National American University - Sioux Falls",
          "website": "http://www.national.edu/sioux_falls.html"
       },
       {
          "university": "National College of Chiropractic",
          "website": "http://www.national.chiropractic.edu/"
       },
       {
          "university": "National College of Naturopathic Medicine",
          "website": "http://www.ncnm.edu/"
       },
       {
          "university": "National Defense University",
          "website": "http://www.ndu.edu/"
       },
       {
          "university": "National Hispanic University",
          "website": "http://www.nhu.edu/"
       },
       {
          "university": "National-Louis University",
          "website": "http://www.nl.edu/"
       },
       {
          "university": "National Technological University",
          "website": "http://www.ntu.edu/"
       },
       {
          "university": "National Theatre Conservatory",
          "website": "http://www.denvercenter.org/education/ed_ntc.htm"
       },
       {
          "university": "National University",
          "website": "http://www.nu.edu/"
       },
       {
          "university": "Naval Postgraduate School",
          "website": "http://www.nps.navy.mil/"
       },
       {
          "university": "Nazarene Bible College",
          "website": "http://www.nbc.edu/"
       },
       {
          "university": "Nazareth College",
          "website": "http://www.naz.edu/"
       },
       {
          "university": "Nebraska Christian College",
          "website": "http://www.nechristian.edu/"
       },
       {
          "university": "Nebraska Methodist College of Nursing and Allied Health",
          "website": "http://www.methodistcollege.edu/nurseover.html"
       },
       {
          "university": "Nebraska Wesleyan University",
          "website": "http://www.nebrwesleyan.edu/"
       },
       {
          "university": "Neumann College",
          "website": "http://www.neumann.edu/"
       },
       {
          "university": "Newberry College",
          "website": "http://www.newberry.edu/"
       },
       {
          "university": "New College of California",
          "website": "http://www.newcollege.edu/"
       },
       {
          "university": "New College of Florida",
          "website": "http://www.ncf.edu/"
       },
       {
          "university": "New England College",
          "website": "http://www.nec.edu/"
       },
       {
          "university": "New England College of Optometry",
          "website": "http://www.ne-optometry.edu/"
       },
       {
          "university": "New England Conservatory of Music",
          "website": "http://www.newenglandconservatory.edu/"
       },
       {
          "university": "New England Institute of Technology",
          "website": "http://www.neit.edu/"
       },
       {
          "university": "New England School of Art and Design",
          "website": "http://www.suffolk.edu/nesad/"
       },
       {
          "university": "New England School of Communications",
          "website": "http://www.nescom.edu/"
       },
       {
          "university": "New England School of Law",
          "website": "http://www.nesl.edu/"
       },
       {
          "university": "New Hampshire College",
          "website": "http://www.nhc.edu/"
       },
       {
          "university": "New Jersey City University",
          "website": "http://www.njcu.edu/"
       },
       {
          "university": "New Jersey Institute of Technology",
          "website": "http://www.njit.edu/"
       },
       {
          "university": "Newman University",
          "website": "http://www.ksnewman.edu/"
       },
       {
          "university": "New Mexico Highlands University",
          "website": "http://www.nmhu.edu/"
       },
       {
          "university": "New Mexico Institute of Mining and Technology",
          "website": "http://www.nmt.edu/"
       },
       {
          "university": "New Mexico State University",
          "website": "http://www.nmsu.edu/"
       },
       {
          "university": "Newschool of Architecture and Design",
          "website": "http://www.newschoolarch.edu/"
       },
       {
          "university": "New York Academy of Art - Graduate School of Figurative Art",
          "website": "http://www.nyaa.edu/"
       },
       {
          "university": "New York Chiropractic College",
          "website": "http://www.nycc.edu/"
       },
       {
          "university": "New York College of Podiatric Medicine",
          "website": "http://www.nycpm.edu/"
       },
       {
          "university": "New York Film Academy",
          "website": "http://www.nyfa.edu/"
       },
       {
          "university": "New York Institute of Technology",
          "website": "http://www.nyit.edu/"
       },
       {
          "university": "New York Law School",
          "website": "http://www.nyls.edu/"
       },
       {
          "university": "New York Medical College",
          "website": "http://www.nymc.edu/"
       },
       {
          "university": "New York School of Interior Design",
          "website": "http://www.nysid.edu/"
       },
       {
          "university": "New York University",
          "website": "http://www.nyu.edu/"
       },
       {
          "university": "Niagara University",
          "website": "http://www.niagara.edu/"
       },
       {
          "university": "Nicholls State University",
          "website": "http://www.nicholls.edu/"
       },
       {
          "university": "Nichols College",
          "website": "http://www.nichols.edu/"
       },
       {
          "university": "Norfolk State University",
          "website": "http://www.nsu.edu/"
       },
       {
          "university": "North Carolina Agricultural and Technical State University",
          "website": "http://www.ncat.edu/"
       },
       {
          "university": "North Carolina Central University",
          "website": "http://www.nccu.edu/"
       },
       {
          "university": "North Carolina School of the Arts",
          "website": "http://www.ncarts.edu/"
       },
       {
          "university": "North Carolina State University",
          "website": "http://www.ncsu.edu/"
       },
       {
          "university": "North Carolina Wesleyan College",
          "website": "http://www.ncwc.edu/"
       },
       {
          "university": "North Central Bible College",
          "website": "http://www.ncbc.edu/"
       },
       {
          "university": "North Central College",
          "website": "http://www.noctrl.edu/"
       },
       {
          "university": "Northcentral University",
          "website": "http://www.ncu.edu/"
       },
       {
          "university": "North Dakota State University",
          "website": "http://www.ndsu.nodak.edu/"
       },
       {
          "university": "Northeastern Illinois University",
          "website": "http://www.neiu.edu/"
       },
       {
          "university": "Northeastern Ohio University College of Medicine",
          "website": "http://www.neoucom.edu/"
       },
       {
          "university": "Northeastern State University",
          "website": "http://www.nsuok.edu/"
       },
       {
          "university": "Northeastern University",
          "website": "http://www.neu.edu/"
       },
       {
          "university": "Northern Arizona University",
          "website": "http://www.nau.edu/"
       },
       {
          "university": "Northern Illinois University",
          "website": "http://www.niu.edu/"
       },
       {
          "university": "Northern Kentucky University",
          "website": "http://www.nku.edu/"
       },
       {
          "university": "Northern Michigan University",
          "website": "http://www.nmu.edu/"
       },
       {
          "university": "Northern State University",
          "website": "http://www.northern.edu/"
       },
       {
          "university": "Northern Virginia Community College",
          "website": "http://www.nv.cc.va.us/"
       },
       {
          "university": "Northface University",
          "website": "http://www.northface.edu/"
       },
       {
          "university": "North Georgia College",
          "website": "http://www.ngc.peachnet.edu/"
       },
       {
          "university": "North Greenville College",
          "website": "http://www.ngc.edu/"
       },
       {
          "university": "Northland College",
          "website": "http://www.northland.edu/"
       },
       {
          "university": "North Park University",
          "website": "http://www.northpark.edu/"
       },
       {
          "university": "Northwest Christian College",
          "website": "http://www.nwcc.edu/"
       },
       {
          "university": "Northwest College of Art",
          "website": "http://www.nca.edu/"
       },
       {
          "university": "Northwestern College Iowa",
          "website": "http://www.nwciowa.edu/"
       },
       {
          "university": "Northwestern College of Chiropractic",
          "website": "http://www.nwchiro.edu/"
       },
       {
          "university": "Northwestern College St. Paul",
          "website": "http://www.nwc.edu/"
       },
       {
          "university": "Northwestern Oklahoma State University",
          "website": "http://www.nwalva.edu/"
       },
       {
          "university": "Northwestern State University of Louisiana",
          "website": "http://www.nsula.edu/"
       },
       {
          "university": "Northwestern University",
          "website": "http://www.nwu.edu/"
       },
       {
          "university": "Northwest Missouri State University",
          "website": "http://www.nwmissouri.edu/"
       },
       {
          "university": "Northwest Nazarene University",
          "website": "http://www.nnu.edu/"
       },
       {
          "university": "Northwest University",
          "website": "http://www.northwestu.edu/"
       },
       {
          "university": "Northwood University",
          "website": "http://www.northwood.edu/"
       },
       {
          "university": "Northwood University - Florida Campus",
          "website": "http://www.northwood.edu/campuses/florida/"
       },
       {
          "university": "Northwood University - Texas Campus",
          "website": "http://www.northwood.edu/campuses/texas/"
       },
       {
          "university": "Norwich University",
          "website": "http://www.norwich.edu/"
       },
       {
          "university": "Notre Dame College",
          "website": "http://www.notredame.edu/"
       },
       {
          "university": "Notre Dame College of Ohio",
          "website": "http://www.ndc.edu/"
       },
       {
          "university": "Notre Dame de Namur University",
          "website": "http://www.ndnu.edu/"
       },
       {
          "university": "Nova Southeastern University",
          "website": "http://www.nova.edu/"
       },
       {
          "university": "Nyack College",
          "website": "http://www.nyackcollege.edu/"
       },
       {
          "university": "Oakland City University",
          "website": "http://www.oak.edu/"
       },
       {
          "university": "Oakland University",
          "website": "http://www.oakland.edu/"
       },
       {
          "university": "Oakton Community College",
          "website": "http://www.oakton.edu/"
       },
       {
          "university": "Oakwood College",
          "website": "http://www.oakwood.edu/"
       },
       {
          "university": "Oberlin College",
          "website": "http://www.oberlin.edu/"
       },
       {
          "university": "Occidental College",
          "website": "http://www.oxy.edu/"
       },
       {
          "university": "Oglala Lakota College",
          "website": "http://www.olc.edu/"
       },
       {
          "university": "Oglethorpe University",
          "website": "http://www.oglethorpe.edu/"
       },
       {
          "university": "Ohio College of Podiatric Medicine",
          "website": "http://www.ocpm.edu/"
       },
       {
          "university": "Ohio Dominican College",
          "website": "http://www.odc.edu/"
       },
       {
          "university": "Ohio Northern University",
          "website": "http://www.onu.edu/"
       },
       {
          "university": "Ohio State University",
          "website": "http://www.ohio-state.edu/"
       },
       {
          "university": "Ohio State University - Lima",
          "website": "http://www.lima.ohio-state.edu/"
       },
       {
          "university": "Ohio State University - Mansfield",
          "website": "http://www.mansfield.ohio-state.edu/"
       },
       {
          "university": "Ohio State University - Marion",
          "website": "http://www.marion.ohio-state.edu/"
       },
       {
          "university": "Ohio State University - Newark",
          "website": "http://www.newark.ohio-state.edu/"
       },
       {
          "university": "Ohio University",
          "website": "http://www.ohiou.edu/"
       },
       {
          "university": "Ohio University - Chillicothe",
          "website": "http://www.ohiou.edu/chillicothe/"
       },
       {
          "university": "Ohio University - Eastern",
          "website": "http://www.eastern.ohiou.edu/"
       },
       {
          "university": "Ohio University - Lancaster",
          "website": "http://www.lancaster.ohiou.edu/"
       },
       {
          "university": "Ohio University - Southern",
          "website": "http://www.ohiou.edu/southern/"
       },
       {
          "university": "Ohio University - Zanesville",
          "website": "http://www.zanesville.ohiou.edu/"
       },
       {
          "university": "Ohio Valley College",
          "website": "http://www.ovc.edu/"
       },
       {
          "university": "Ohio Wesleyan University",
          "website": "http://www.owu.edu/"
       },
       {
          "university": "Oklahoma Baptist University",
          "website": "http://www.okbu.edu/"
       },
       {
          "university": "Oklahoma Christian University",
          "website": "http://www.oc.edu/"
       },
       {
          "university": "Oklahoma City University",
          "website": "http://www.okcu.edu/"
       },
       {
          "university": "Oklahoma Panhandle State University",
          "website": "http://www.opsu.edu/"
       },
       {
          "university": "Oklahoma State University",
          "website": "http://www.okstate.edu/"
       },
       {
          "university": "Oklahoma State University Center for Health Sciences",
          "website": "http://osu.com.okstate.edu/"
       },
       {
          "university": "Oklahoma State University - Institute of Technology",
          "website": "http://www.osuit.edu/"
       },
       {
          "university": "Oklahoma State University - Oklahoma City",
          "website": "http://www.osuokc.edu/"
       },
       {
          "university": "Oklahoma State University - Tulsa",
          "website": "http://www.osu-tulsa.okstate.edu/"
       },
       {
          "university": "Old Dominion University",
          "website": "http://www.odu.edu/"
       },
       {
          "university": "Olive-Harvey College",
          "website": "http://www.ccc.edu/oliveharvey/"
       },
       {
          "university": "Olivet College",
          "website": "http://www.olivetcollege.edu/"
       },
       {
          "university": "Olivet Nazarene University",
          "website": "http://www.olivet.edu/"
       },
       {
          "university": "O'More College of Design",
          "website": "http://www.omorecollege.edu/"
       },
       {
          "university": "Oral Roberts University",
          "website": "http://www.oru.edu/"
       },
       {
          "university": "Oregon College of Arts and Crafts",
          "website": "http://www.ocac.edu/"
       },
       {
          "university": "Oregon Graduate Institute of Science and Technology",
          "website": "http://www.ogi.edu/"
       },
       {
          "university": "Oregon Health Sciences University",
          "website": "http://www.ohsu.edu/"
       },
       {
          "university": "Oregon Institute of Technology",
          "website": "http://www.oit.edu/"
       },
       {
          "university": "Oregon State University",
          "website": "http://www.orst.edu/"
       },
       {
          "university": "Otis College of Art & Design",
          "website": "http://www.otisart.edu/"
       },
       {
          "university": "Ottawa University",
          "website": "http://www.ottawa.edu/"
       },
       {
          "university": "Otterbein College",
          "website": "http://www.otterbein.edu/"
       },
       {
          "university": "Ouachita Baptist University",
          "website": "http://www.obu.edu/"
       },
       {
          "university": "Our Lady of Holy Cross College",
          "website": "http://www.olhcc.edu/"
       },
       {
          "university": "Our Lady of the Lake University",
          "website": "http://www.ollusa.edu/"
       },
       {
          "university": "Ozark Christian College",
          "website": "http://www.occ.edu/"
       },
       {
          "university": "Pace University",
          "website": "http://www.pace.edu/"
       },
       {
          "university": "Pace University  Pleasantville/Briarcliff",
          "website": "http://www.pace.edu/campus/pville.html"
       },
       {
          "university": "Pacifica Graduate Institute",
          "website": "http://www.pacifica.edu/"
       },
       {
          "university": "Pacific College of Oriental Medicine",
          "website": "http://www.ormed.edu/"
       },
       {
          "university": "Pacific Graduate School of Psychology",
          "website": "http://www.pgsp.edu/"
       },
       {
          "university": "Pacific Lutheran University",
          "website": "http://www.plu.edu/"
       },
       {
          "university": "Pacific Northwest College of Art",
          "website": "http://www.pnca.edu/"
       },
       {
          "university": "Pacific Oaks College",
          "website": "http://www.pacificoaks.edu/"
       },
       {
          "university": "Pacific Union College",
          "website": "http://www.puc.edu/"
       },
       {
          "university": "Pacific University",
          "website": "http://www.pacificu.edu/"
       },
       {
          "university": "Paier College of Art",
          "website": "http://www.paierart.com/"
       },
       {
          "university": "Paine College",
          "website": "http://www.paine.edu/"
       },
       {
          "university": "Palm Beach Atlantic University",
          "website": "http://www.pba.edu/"
       },
       {
          "university": "Palm Beach State College",
          "website": "http://www.palmbeachstate.edu/"
       },
       {
          "university": "Palmer College of Chiropractic",
          "website": "http://www.palmer.edu/"
       },
       {
          "university": "Palmer College of Chiropractic West",
          "website": "http://www.palmer.edu/PCCW/pcwmain.htm"
       },
       {
          "university": "Park College",
          "website": "http://www.park.edu/"
       },
       {
          "university": "Parsons School of Design",
          "website": "http://www.parsons.edu/"
       },
       {
          "university": "Paul Quinn College",
          "website": "http://www.pqc.edu/"
       },
       {
          "university": "Peace College",
          "website": "http://www.peace.edu/"
       },
       {
          "university": "Pebble Hills University",
          "website": "http://www.pebblehills.edu/"
       },
       {
          "university": "Pennsylvania Academy of the Fine Arts",
          "website": "http://www.pafa.org/"
       },
       {
          "university": "Pennsylvania College of Optometry",
          "website": "http://www.pco.edu/"
       },
       {
          "university": "Pennsylvania Institute of Technology",
          "website": "http://www.pit.edu/"
       },
       {
          "university": "Pennsylvania State University",
          "website": "http://www.psu.edu/"
       },
       {
          "university": "Pennsylvania State University - Abington",
          "website": "http://www.abington.psu.edu/"
       },
       {
          "university": "Pennsylvania State University - Altoona",
          "website": "http://www.aa.psu.edu/"
       },
       {
          "university": "Pennsylvania State University at Erie - Behrend College",
          "website": "http://www.pserie.psu.edu/"
       },
       {
          "university": "Pennsylvania State University at Harrisburg - The Capital College",
          "website": "http://www.hbg.psu.edu/"
       },
       {
          "university": "Pennsylvania State University - Berks-Lehigh Valley College",
          "website": "http://www.bk.psu.edu/"
       },
       {
          "university": "Pennsylvania State University Delaware County",
          "website": "http://www.de.psu.edu/"
       },
       {
          "university": "Pennsylvania State University Great Valley",
          "website": "http://www.gv.psu.edu/"
       },
       {
          "university": "Pennsylvania State University - Lehigh Valley",
          "website": "http://www.an.psu.edu/"
       },
       {
          "university": "Pennsylvania State University - Milton S.Hershey Medical Center",
          "website": "http://www.collmed.psu.edu/"
       },
       {
          "university": "Pennsylvania State University - Schuylkill",
          "website": "http://www.sl.psu.edu/"
       },
       {
          "university": "Pepperdine University",
          "website": "http://www.pepperdine.edu/"
       },
       {
          "university": "Peru State College",
          "website": "http://www.peru.edu/"
       },
       {
          "university": "Pfeiffer University",
          "website": "http://www.pfeiffer.edu/"
       },
       {
          "university": "Philadelphia College of Bible",
          "website": "http://www.pcb.edu/"
       },
       {
          "university": "Philadelphia College of Osteopathic Medicine",
          "website": "http://www.pcom.edu/"
       },
       {
          "university": "Philadelphia University",
          "website": "http://www.philau.edu/"
       },
       {
          "university": "Philander Smith College",
          "website": "http://www.philander.edu/"
       },
       {
          "university": "Phillips Graduate Institute",
          "website": "http://www.pgi.edu/"
       },
       {
          "university": "Phillips University",
          "website": "http://www.phillips.edu/"
       },
       {
          "university": "Piedmont Baptist College",
          "website": "http://www.pbc.edu/"
       },
       {
          "university": "Piedmont College",
          "website": "http://www.piedmont.edu/"
       },
       {
          "university": "Pikeville College",
          "website": "http://www.pc.edu/"
       },
       {
          "university": "Pillsbury Baptist Bible College",
          "website": "http://www.pillsbury.edu/"
       },
       {
          "university": "Pittsburg State University",
          "website": "http://www.pittstate.edu/"
       },
       {
          "university": "Pitzer College",
          "website": "http://www.pitzer.edu/"
       },
       {
          "university": "Plymouth State College",
          "website": "http://www.plymouth.edu/"
       },
       {
          "university": "Point Loma Nazarene College",
          "website": "http://www.ptloma.edu/"
       },
       {
          "university": "Point Park College",
          "website": "http://www.ppc.edu/"
       },
       {
          "university": "Polytechnic University",
          "website": "http://www.poly.edu/"
       },
       {
          "university": "Polytechnic University - Long Island Campus",
          "website": "http://www.poly.edu/li/"
       },
       {
          "university": "Polytechnic University - Westchester Graduate Center",
          "website": "http://www.poly.edu/west/"
       },
       {
          "university": "Pomona College",
          "website": "http://www.pomona.edu/"
       },
       {
          "university": "Portland Community College",
          "website": "http://www.pcc.edu/"
       },
       {
          "university": "Portland State University",
          "website": "http://www.pdx.edu/"
       },
       {
          "university": "Post University of Waterbury",
          "website": "http://www.post.edu/"
       },
       {
          "university": "Prairie View Agricultural and Mechanical University",
          "website": "http://www.pvamu.edu/"
       },
       {
          "university": "Pratt Institute",
          "website": "http://www.pratt.edu/"
       },
       {
          "university": "Presbyterian College",
          "website": "http://www.presby.edu/"
       },
       {
          "university": "Prescott College",
          "website": "http://www.prescott.edu/"
       },
       {
          "university": "Preston University",
          "website": "http://www.preston.edu/"
       },
       {
          "university": "Princeton University",
          "website": "http://www.princeton.edu/"
       },
       {
          "university": "Principia College",
          "website": "http://www.prin.edu/"
       },
       {
          "university": "Providence College",
          "website": "http://www.providence.edu/"
       },
       {
          "university": "Puget Sound Christian College",
          "website": "http://members.aa.net/~bluvase/pscchome.html"
       },
       {
          "university": "Purdue University",
          "website": "http://www.purdue.edu/"
       },
       {
          "university": "Purdue University Calumet",
          "website": "http://www.calumet.purdue.edu/"
       },
       {
          "university": "Purdue University North Central",
          "website": "http://www.pnc.edu/"
       },
       {
          "university": "Quantum-Veritas International University",
          "website": "http://www.qvius.edu/"
       },
       {
          "university": "Queens College",
          "website": "http://www.queens.edu/"
       },
       {
          "university": "Quincy University",
          "website": "http://www.quincy.edu/"
       },
       {
          "university": "Quinnipiac College",
          "website": "http://www.quinnipiac.edu/"
       },
       {
          "university": "Radford University",
          "website": "http://www.runet.edu/"
       },
       {
          "university": "Ramapo College of New Jersey",
          "website": "http://www.ramapo.edu/"
       },
       {
          "university": "Rand Graduate School of Policy Studies",
          "website": "http://www.rgs.edu/"
       },
       {
          "university": "Randolph-Macon College",
          "website": "http://www.rmc.edu/"
       },
       {
          "university": "Randolph-Macon Woman's College",
          "website": "http://www.rmwc.edu/"
       },
       {
          "university": "Rasmussen College",
          "website": "http://www.rasmussen.edu/"
       },
       {
          "university": "Rasmussen College - Florida Campuses",
          "website": "http://www.rasmussen.edu/locations/florida/"
       },
       {
          "university": "Rasmussen College - Illinois Campuses",
          "website": "http://www.rasmussen.edu/locations/illinois/"
       },
       {
          "university": "Rasmussen College - Minnesota Campuses",
          "website": "http://www.rasmussen.edu/locations/minnesota/"
       },
       {
          "university": "Rasmussen College - North Dakota Campuses",
          "website": "http://www.rasmussen.edu/locations/north-dakota/"
       },
       {
          "university": "Rasmussen College - Wisconsin Campuses",
          "website": "http://www.rasmussen.edu/locations/wisconsin/"
       },
       {
          "university": "Reed College",
          "website": "http://www.reed.edu/"
       },
       {
          "university": "Reformed Bible College",
          "website": "http://www.reformed.edu/"
       },
       {
          "university": "Regent International University",
          "website": "http://www.regentinternational.net/"
       },
       {
          "university": "Regent University",
          "website": "http://www.regent.edu/"
       },
       {
          "university": "Regis College",
          "website": "http://www.regiscollege.edu/"
       },
       {
          "university": "Regis University",
          "website": "http://www.regis.edu/"
       },
       {
          "university": "Reinhardt College",
          "website": "http://www.reinhardt.edu/"
       },
       {
          "university": "Rensselaer Polytechnic Institute",
          "website": "http://www.rpi.edu/"
       },
       {
          "university": "Research College of Nursing - Rockhurst University",
          "website": "http://www.rockhurst.edu/3.0/academic_programs/nursing/admis3.html"
       },
       {
          "university": "Rhode Island College",
          "website": "http://www.ric.edu/"
       },
       {
          "university": "Rhode Island School of Design",
          "website": "http://www.risd.edu/"
       },
       {
          "university": "Rhodes College",
          "website": "http://www.rhodes.edu/"
       },
       {
          "university": "Rice University",
          "website": "http://www.rice.edu/"
       },
       {
          "university": "Richard Stockton College of New Jersey",
          "website": "http://www.stockton.edu/"
       },
       {
          "university": "Rider University",
          "website": "http://www.rider.edu/"
       },
       {
          "university": "Ringling College of Art and Design",
          "website": "http://www.ringling.edu/"
       },
       {
          "university": "Ripon College",
          "website": "http://www.ripon.edu/"
       },
       {
          "university": "Rivier College",
          "website": "http://www.rivier.edu/"
       },
       {
          "university": "Roanoke Bible College",
          "website": "http://www.roanokebible.edu/"
       },
       {
          "university": "Roanoke College",
          "website": "http://www.roanoke.edu/"
       },
       {
          "university": "Robert Morris College",
          "website": "http://www.robert-morris.edu/"
       },
       {
          "university": "Robert Morris College of Chicago",
          "website": "http://www.rmcil.edu/"
       },
       {
          "university": "Roberts Wesleyan College",
          "website": "http://www.rwc.edu/"
       },
       {
          "university": "Rochester College",
          "website": "http://www.rc.edu/"
       },
       {
          "university": "Rochester Institute of Technology",
          "website": "http://www.rit.edu/"
       },
       {
          "university": "Rockford College",
          "website": "http://www.rockford.edu/"
       },
       {
          "university": "Rockhurst College",
          "website": "http://www.rockhurst.edu/"
       },
       {
          "university": "Rock Valley College",
          "website": "http://www.rvc.cc.il.us/"
       },
       {
          "university": "Rocky Mountain College",
          "website": "http://www.rocky.edu/"
       },
       {
          "university": "Rocky Mountain College of Art and Design",
          "website": "http://www.rmcad.edu/"
       },
       {
          "university": "Rogers State University",
          "website": "http://www.rsu.edu/"
       },
       {
          "university": "Roger Williams University",
          "website": "http://www.rwu.edu/"
       },
       {
          "university": "Rollins College",
          "website": "http://www.rollins.edu/"
       },
       {
          "university": "Roosevelt University",
          "website": "http://www.roosevelt.edu/"
       },
       {
          "university": "Rose-Hulman Institute of Technology",
          "website": "http://www.rose-hulman.edu/"
       },
       {
          "university": "Rosemont College",
          "website": "http://www.rosemont.edu/"
       },
       {
          "university": "Ross University - School of Medicine",
          "website": "http://www.rossmed.edu/"
       },
       {
          "university": "Rowan University",
          "website": "http://www.rowan.edu/"
       },
       {
          "university": "Rush University",
          "website": "http://www.rushu.rush.edu/"
       },
       {
          "university": "Russel Sage College",
          "website": "http://www.sage.edu/RSC/"
       },
       {
          "university": "Rust College",
          "website": "http://www.rustcollege.edu/"
       },
       {
          "university": "Rutgers - The State University of New Jersey",
          "website": "http://www.rutgers.edu/"
       },
       {
          "university": "Rutgers - The State University of New Jersey - Camden",
          "website": "http://camden-www.rutgers.edu/"
       },
       {
          "university": "Rutgers - The State University of New Jersey - Newark",
          "website": "http://rutgers-newark.rutgers.edu/"
       },
       {
          "university": "Sacred Heart University",
          "website": "http://www.sacredheart.edu/"
       },
       {
          "university": "Sage Graduate School",
          "website": "http://www.sage.edu/SGS/"
       },
       {
          "university": "Saginaw Valley State University",
          "website": "http://www.svsu.edu/"
       },
       {
          "university": "Salem College",
          "website": "http://www.salem.edu/"
       },
       {
          "university": "Salem International University",
          "website": "http://www.salemiu.edu/"
       },
       {
          "university": "Salem State College",
          "website": "http://www.salem.mass.edu/"
       },
       {
          "university": "Salem Teikyo University",
          "website": "http://www.salem-teikyo.wvnet.edu/"
       },
       {
          "university": "Salisbury State University",
          "website": "http://www.ssu.edu/"
       },
       {
          "university": "Salve Regina University",
          "website": "http://www.salve.edu/"
       },
       {
          "university": "Samford University",
          "website": "http://www.samford.edu/"
       },
       {
          "university": "Sam Houston State University",
          "website": "http://www.shsu.edu/"
       },
       {
          "university": "Samuel Merritt College",
          "website": "http://www.samuelmerritt.edu/"
       },
       {
          "university": "San Diego State University",
          "website": "http://www.sdsu.edu/"
       },
       {
          "university": "San Diego University for Integrative Studies",
          "website": "http://www.sduis.edu/"
       },
       {
          "university": "Sanford-Brown Institute",
          "website": "http://www.sbjacksonville.com/"
       },
       {
          "university": "San Francisco Art Institute",
          "website": "http://www.sfai.edu/"
       },
       {
          "university": "San Francisco Conservatory of Music",
          "website": "http://www.sfcm.edu/"
       },
       {
          "university": "San Francisco State University",
          "website": "http://www.sfsu.edu/"
       },
       {
          "university": "San Joaquin College of Law",
          "website": "http://www.sjcl.org/"
       },
       {
          "university": "San Jose Christian College",
          "website": "http://www.sjchristiancol.edu/"
       },
       {
          "university": "San Jose State University",
          "website": "http://www.sjsu.edu/"
       },
       {
          "university": "Santa Clara University",
          "website": "http://www.scu.edu/"
       },
       {
          "university": "Sarah Lawrence College",
          "website": "http://www.slc.edu/"
       },
       {
          "university": "Savannah College of Art and Design",
          "website": "http://www.scad.edu/"
       },
       {
          "university": "Savannah State University",
          "website": "http://www.savstate.edu/"
       },
       {
          "university": "Saybrook Institute",
          "website": "http://www.saybrook.org/"
       },
       {
          "university": "Schiller International University",
          "website": "http://www.schiller.edu/"
       },
       {
          "university": "Scholl College of Podiatric Medicine",
          "website": "http://www.scholl.edu/"
       },
       {
          "university": "School for International Training",
          "website": "http://www.sit.edu/"
       },
       {
          "university": "School of the Museum of Fine Arts",
          "website": "http://www.smfa.edu/"
       },
       {
          "university": "School of the Visual Arts",
          "website": "http://www.schoolofvisualarts.edu/"
       },
       {
          "university": "Schreiner College",
          "website": "http://www.schreiner.edu/"
       },
       {
          "university": "Scripps College",
          "website": "http://www.scrippscol.edu/"
       },
       {
          "university": "Seattle Pacific University",
          "website": "http://www.spu.edu/"
       },
       {
          "university": "Seattle University",
          "website": "http://www.seattleu.edu/"
       },
       {
          "university": "Seton Hall University",
          "website": "http://www.shu.edu/"
       },
       {
          "university": "Seton Hill College",
          "website": "http://www.setonhill.edu/"
       },
       {
          "university": "Shawnee Community College",
          "website": "http://www.shawnee.cc.il.us/"
       },
       {
          "university": "Shawnee State University",
          "website": "http://www.shawnee.edu/"
       },
       {
          "university": "Shaw University",
          "website": "http://www.shawuniversity.edu/"
       },
       {
          "university": "Sheldon Jackson College",
          "website": "http://www.sheldonjackson.edu/"
       },
       {
          "university": "Shenandoah University",
          "website": "http://www.su.edu/"
       },
       {
          "university": "Shepherd College",
          "website": "http://www.shepherd.edu/"
       },
       {
          "university": "Sherman College of Straight Chiropractic",
          "website": "http://www.sherman.edu/"
       },
       {
          "university": "Shimer College",
          "website": "http://www.shimer.edu/"
       },
       {
          "university": "Shippensburg University of Pennsylvania",
          "website": "http://www.ship.edu/"
       },
       {
          "university": "Shoreline Community College",
          "website": "http://www.shoreline.edu/"
       },
       {
          "university": "Shorter College",
          "website": "http://www.shorter.edu/"
       },
       {
          "university": "Siena College",
          "website": "http://www.siena.edu/"
       },
       {
          "university": "Siena Heights University",
          "website": "http://www.sienahts.edu/"
       },
       {
          "university": "Sierra Nevada College",
          "website": "http://www.sierranevada.edu/"
       },
       {
          "university": "Silver Lake College",
          "website": "http://www.sl.edu/"
       },
       {
          "university": "Simmons College",
          "website": "http://www.simmons.edu/"
       },
       {
          "university": "Simon's Rock College",
          "website": "http://www.simons-rock.edu/"
       },
       {
          "university": "Simpson College",
          "website": "http://www.simpsonca.edu/"
       },
       {
          "university": "Simpson College Iowa",
          "website": "http://www.simpson.edu/"
       },
       {
          "university": "Sinte Gleska University",
          "website": "http://sinte.indian.com/"
       },
       {
          "university": "Skadron College",
          "website": "http://skadron.com/"
       },
       {
          "university": "Skidmore College",
          "website": "http://www.skidmore.edu/"
       },
       {
          "university": "Slippery Rock University",
          "website": "http://www.sru.edu/"
       },
       {
          "university": "Smith College",
          "website": "http://www.smith.edu/"
       },
       {
          "university": "Sojourner-Douglass College",
          "website": "http://www.sdc.edu/"
       },
       {
          "university": "Soka University of America",
          "website": "http://www.soka.edu"
       },
       {
          "university": "Sonoma State University",
          "website": "http://www.sonoma.edu/"
       },
       {
          "university": "South Carolina State University",
          "website": "http://www.scsu.edu/"
       },
       {
          "university": "South Dakota School of Mines and Technology",
          "website": "http://www.sdsmt.edu/"
       },
       {
          "university": "South Dakota State University",
          "website": "http://www.sdstate.edu/"
       },
       {
          "university": "Southeastern Baptist College",
          "website": "http://www.southeasternbaptist.edu/"
       },
       {
          "university": "Southeastern Bible College",
          "website": "http://www.sebc.edu/"
       },
       {
          "university": "Southeastern College of the Assemblies of God",
          "website": "http://www.secollege.edu/"
       },
       {
          "university": "Southeastern Louisiana University",
          "website": "http://www.southeastern.edu/"
       },
       {
          "university": "Southeastern Oklahoma State University",
          "website": "http://www.sosu.edu/"
       },
       {
          "university": "Southeastern University",
          "website": "http://www.seu.edu/"
       },
       {
          "university": "Southeast Missouri State University",
          "website": "http://www.semo.edu/"
       },
       {
          "university": "Southern Adventist University",
          "website": "http://www.southern.edu/"
       },
       {
          "university": "Southern Arkansas University",
          "website": "http://www.saumag.edu/"
       },
       {
          "university": "Southern California College",
          "website": "http://www.sccu.edu/"
       },
       {
          "university": "Southern California College of Optometry",
          "website": "http://www.scco.edu/"
       },
       {
          "university": "Southern California Institute of Architecture",
          "website": "http://www.sciarc.edu/"
       },
       {
          "university": "Southern College of Optometry",
          "website": "http://www.sco.edu/"
       },
       {
          "university": "Southern Connecticut State University",
          "website": "http://www.scsu.ctstateu.edu/"
       },
       {
          "university": "Southern Illinois University at Carbondale",
          "website": "http://www.siu.edu/"
       },
       {
          "university": "Southern Illinois University at Edwardsville",
          "website": "http://www.siue.edu/"
       },
       {
          "university": "Southern Methodist University",
          "website": "http://www.smu.edu/"
       },
       {
          "university": "Southern Nazarene University",
          "website": "http://www.snu.edu/"
       },
       {
          "university": "Southern New Hampshire University",
          "website": "http://www.snhu.edu/"
       },
       {
          "university": "Southern Oregon University",
          "website": "http://www.sou.edu/"
       },
       {
          "university": "Southern Polytechnic State Univerisity",
          "website": "http://www.spsu.edu/"
       },
       {
          "university": "Southern University - Baton Rouge",
          "website": "http://www.subr.edu/"
       },
       {
          "university": "Southern University - New Orleans",
          "website": "http://www.suno.edu/"
       },
       {
          "university": "Southern University - Shreveport",
          "website": "http://www.susla.edu/"
       },
       {
          "university": "Southern Utah University",
          "website": "http://www.suu.edu/"
       },
       {
          "university": "Southern Vermont College",
          "website": "http://www.svc.edu/"
       },
       {
          "university": "Southern Wesleyan University",
          "website": "http://www.swu.edu/"
       },
       {
          "university": "South Florida Bible College & Theological Seminary",
          "website": "http://www.sfbc.edu/"
       },
       {
          "university": "South Texas College of Law",
          "website": "http://www.stcl.edu/"
       },
       {
          "university": "Southwest Baptist University",
          "website": "http://www.sbuniv.edu/"
       },
       {
          "university": "Southwestern Adventist University",
          "website": "http://www.swau.edu/"
       },
       {
          "university": "Southwestern Assemblies of God University",
          "website": "http://www.sagu.edu/"
       },
       {
          "university": "Southwestern Baptist Theological Seminary",
          "website": "http://www.swbts.edu/"
       },
       {
          "university": "Southwestern Christian College",
          "website": "http://www.soulsociety.com/swcc.html"
       },
       {
          "university": "Southwestern Christian University",
          "website": "http://www.swcu.edu/"
       },
       {
          "university": "Southwestern College Kansas",
          "website": "http://www.sckans.edu/"
       },
       {
          "university": "Southwestern College Santa Fe",
          "website": "http://www.swc.edu/"
       },
       {
          "university": "Southwestern Oklahoma State University",
          "website": "http://www.swosu.edu/"
       },
       {
          "university": "Southwestern University",
          "website": "http://www.southwestern.edu/"
       },
       {
          "university": "Southwestern University School of Law",
          "website": "http://www.swlaw.edu/"
       },
       {
          "university": "Southwest Missouri State University",
          "website": "http://www.smsu.edu/"
       },
       {
          "university": "Southwest Missouri State University - West Plains",
          "website": "http://www.wp.smsu.edu/"
       },
       {
          "university": "Southwest State University",
          "website": "http://www.southwest.msus.edu/"
       },
       {
          "university": "Southwest Texas State University",
          "website": "http://www.swt.edu/"
       },
       {
          "university": "Southwest University",
          "website": "http://www.southwest.edu/"
       },
       {
          "university": "Spalding University",
          "website": "http://www.spalding.edu/"
       },
       {
          "university": "Spelman College",
          "website": "http://www.spelman.edu/"
       },
       {
          "university": "Spertus Institute of Jewish Studies",
          "website": "http://www.spertus.edu/"
       },
       {
          "university": "Spring Arbor College",
          "website": "http://www.arbor.edu/"
       },
       {
          "university": "Springfield College",
          "website": "http://www.spfldcol.edu/"
       },
       {
          "university": "Spring Hill College",
          "website": "http://www.shc.edu/"
       },
       {
          "university": "St. Ambrose University",
          "website": "http://www.sau.edu/"
       },
       {
          "university": "Standford Online University",
          "website": "http://www.standford-university.cjb.net/"
       },
       {
          "university": "Standford University",
          "website": "http://standford-university.edu.tf/"
       },
       {
          "university": "St. Andrews Presbyterian College",
          "website": "http://www.sapc.edu/"
       },
       {
          "university": "Stanford University",
          "website": "http://www.stanford.edu/"
       },
       {
          "university": "St. Anselm College",
          "website": "http://www.anselm.edu/"
       },
       {
          "university": "St. Anthony College of Nursing",
          "website": "http://www.sacn.edu/"
       },
       {
          "university": "State University of New York at Albany",
          "website": "http://www.albany.edu/"
       },
       {
          "university": "State University of New York at Binghamton",
          "website": "http://www.binghamton.edu/"
       },
       {
          "university": "State University of New York at Buffalo",
          "website": "http://www.buffalo.edu/"
       },
       {
          "university": "State University of New York at New Paltz",
          "website": "http://www.newpaltz.edu/"
       },
       {
          "university": "State University of New York at Oswego",
          "website": "http://www.oswego.edu/"
       },
       {
          "university": "State University of New York at Stony Brook",
          "website": "http://www.sunysb.edu/"
       },
       {
          "university": "State University of New York College at Brockport",
          "website": "http://www.brockport.edu/"
       },
       {
          "university": "State University of New York College at Cortland",
          "website": "http://www.cortland.edu/"
       },
       {
          "university": "State University of New York College at Fredonia",
          "website": "http://www.fredonia.edu/"
       },
       {
          "university": "State University of New York College at Geneseo",
          "website": "http://www.geneseo.edu/"
       },
       {
          "university": "State University of New York College at Old Westbury",
          "website": "http://www.oldwestbury.edu/"
       },
       {
          "university": "State University of New York College at Oneonta",
          "website": "http://www.oneonta.edu/"
       },
       {
          "university": "State University of New York College at Plattsburgh",
          "website": "http://www.plattsburgh.edu/"
       },
       {
          "university": "State University of New York College at Potsdam",
          "website": "http://www.potsdam.edu/"
       },
       {
          "university": "State University of New York College at Purchase",
          "website": "http://www.purchase.edu/"
       },
       {
          "university": "State University of New York College of Agriculture and Technology at Cobleskill",
          "website": "http://www.cobleskill.edu/"
       },
       {
          "university": "State University of New York College of Environmental Science and Forestry",
          "website": "http://www.esf.edu/"
       },
       {
          "university": "State University of New York College of Optometry",
          "website": "http://www.sunyopt.edu/"
       },
       {
          "university": "State University of New York College of Technology at Alfred",
          "website": "http://www.alfredtech.edu/"
       },
       {
          "university": "State University of New York College of Technology at Farmingdale",
          "website": "http://www.farmingdale.edu/"
       },
       {
          "university": "State University of New York Downstate Medical Center",
          "website": "http://www.hscbklyn.edu/"
       },
       {
          "university": "State University of New York Empire State College",
          "website": "http://www.esc.edu/"
       },
       {
          "university": "State University of New York Health Sience Centre Syracuse",
          "website": "http://www.hscsyr.edu/"
       },
       {
          "university": "State University of New York Institute of Technology at Utica/Rome",
          "website": "http://www.sunyit.edu/"
       },
       {
          "university": "State University of New York Maritime College",
          "website": "http://www.sunymaritime.edu/"
       },
       {
          "university": "State University of New York School of Engineering and Applied Sciences",
          "website": "http://www.eng.buffalo.edu/"
       },
       {
          "university": "State University of New York (SUNY)",
          "website": "http://www.sunycentral.edu/"
       },
       {
          "university": "State University of New York Upstate Medical University ",
          "website": "http://www.upstate.edu/"
       },
       {
          "university": "State University of West Georgia",
          "website": "http://www.westga.edu/"
       },
       {
          "university": "St. Augustine's College North Carolina",
          "website": "http://www.st-aug.edu/"
       },
       {
          "university": "St. Bernard's Institute",
          "website": "http://www.sbi.edu/"
       },
       {
          "university": "St. Bonaventure University",
          "website": "http://www.sbu.edu/"
       },
       {
          "university": "St. Cloud State University",
          "website": "http://www.stcloudstate.edu/"
       },
       {
          "university": "St. Edwards University",
          "website": "http://www.stedwards.edu/"
       },
       {
          "university": "Stefan University",
          "website": "http://www.stefan-university.edu/"
       },
       {
          "university": "Stephen F. Austin State University",
          "website": "http://www.sfasu.edu/"
       },
       {
          "university": "Stephens College",
          "website": "http://www.stephens.edu/"
       },
       {
          "university": "Sterling College",
          "website": "http://www.sterling.edu/"
       },
       {
          "university": "Stetson University",
          "website": "http://www.stetson.edu/"
       },
       {
          "university": "Stevens Institute of Technology",
          "website": "http://www.stevens-tech.edu/"
       },
       {
          "university": "St. Francis College - Brooklyn Heights",
          "website": "http://www.stfranciscollege.edu/"
       },
       {
          "university": "St. Francis College - Fort Wayne",
          "website": "http://www.sfc.edu/"
       },
       {
          "university": "St. Francis College - Loretto",
          "website": "http://www.sfcpa.edu/"
       },
       {
          "university": "St. Francis Medical Center College of Nursing",
          "website": "http://www.osfsaintfrancis.org/"
       },
       {
          "university": "St. George's University",
          "website": "http://www.sgu.edu/"
       },
       {
          "university": "Stillman College",
          "website": "http://www.stillman.edu/"
       },
       {
          "university": "St. John Fisher College",
          "website": "http://www.sjfc.edu/"
       },
       {
          "university": "St. John's College Maryland",
          "website": "http://www.sjca.edu/"
       },
       {
          "university": "St. John's College New Mexico",
          "website": "http://www.sjcsf.edu/"
       },
       {
          "university": "St. John's Seminary",
          "website": "http://www.stjohnsem.edu/"
       },
       {
          "university": "St. John's University",
          "website": "http://www.stjohns.edu/"
       },
       {
          "university": "St. Joseph College",
          "website": "http://www.sjc.edu/"
       },
       {
          "university": "St. Joseph College of Nursing",
          "website": "http://www.stfrancis.edu/sjcn/sjcnhome.htm"
       },
       {
          "university": "St. Joseph's College",
          "website": "http://www.saintjoe.edu/"
       },
       {
          "university": "St. Joseph's College New York",
          "website": "http://www.sjcny.edu/"
       },
       {
          "university": "St. Joseph's College New York - Suffolk Campus",
          "website": "http://www.sjcny.edu/patchogue/"
       },
       {
          "university": "St. Joseph's College of Maine",
          "website": "http://www.sjcme.edu/"
       },
       {
          "university": "St. Joseph's University",
          "website": "http://www.sju.edu/"
       },
       {
          "university": "St. Lawrence University",
          "website": "http://www.stlawu.edu/"
       },
       {
          "university": "St. Leo College",
          "website": "http://www.saintleo.edu/"
       },
       {
          "university": "St. Louis Christian College",
          "website": "http://www.slcc4ministry.edu/"
       },
       {
          "university": "St. Louis College of Pharmacy",
          "website": "http://www.stlcop.edu/"
       },
       {
          "university": "St. Louis University",
          "website": "http://www.slu.edu/"
       },
       {
          "university": "St. Luke's College",
          "website": "http://www.saint-lukes.org/about/slc/"
       },
       {
          "university": "St. Martin's College",
          "website": "http://www.stmartin.edu/"
       },
       {
          "university": "St. Mary College",
          "website": "http://www.smcks.edu/"
       },
       {
          "university": "St. Mary-of-the-Woods College",
          "website": "http://www.smwc.edu/"
       },
       {
          "university": "St. Mary's College Indiana",
          "website": "http://www.saintmarys.edu/"
       },
       {
          "university": "St. Mary's College of California",
          "website": "http://www.stmarys-ca.edu/"
       },
       {
          "university": "St. Mary's College of Maryland",
          "website": "http://www.smcm.edu/"
       },
       {
          "university": "St. Mary's University of Minnesota",
          "website": "http://www.smumn.edu/"
       },
       {
          "university": "St. Mary's University of San Antonio",
          "website": "http://www.stmarytx.edu/"
       },
       {
          "university": "St. Meinrad College",
          "website": "http://www.saintmeinrad.edu/"
       },
       {
          "university": "St. Michael's College",
          "website": "http://www.smcvt.edu/"
       },
       {
          "university": "St. Norbert College",
          "website": "http://www.snc.edu/"
       },
       {
          "university": "St. Olaf College",
          "website": "http://www.stolaf.edu/"
       },
       {
          "university": "Stonehill College",
          "website": "http://www.stonehill.edu/"
       },
       {
          "university": "St. Paul's College",
          "website": "http://www.saintpauls.edu/"
       },
       {
          "university": "St. Petersburg College",
          "website": "http://www.spcollege.edu/"
       },
       {
          "university": "St. Peter's College",
          "website": "http://www.spc.edu/"
       },
       {
          "university": "Strayer University",
          "website": "http://www.strayer.edu/"
       },
       {
          "university": "St. Thomas Aquinas College",
          "website": "http://www.stac.edu/"
       },
       {
          "university": "St. Thomas University",
          "website": "http://www.stu.edu/"
       },
       {
          "university": "St. Vincent College",
          "website": "http://www.stvincent.edu/"
       },
       {
          "university": "St. Xavier University",
          "website": "http://www.sxu.edu/"
       },
       {
          "university": "Suffolk University",
          "website": "http://www.suffolk.edu/"
       },
       {
          "university": "Sullivan College",
          "website": "http://www.sullivan.edu/"
       },
       {
          "university": "Sul Ross State University",
          "website": "http://www.sulross.edu/"
       },
       {
          "university": "Susquehanna University",
          "website": "http://www.susqu.edu/"
       },
       {
          "university": "Swarthmore College",
          "website": "http://www.swarthmore.edu/"
       },
       {
          "university": "Sweet Briar College",
          "website": "http://www.sbc.edu/"
       },
       {
          "university": "Syracuse University",
          "website": "http://www.syr.edu/"
       },
       {
          "university": "Tabor College",
          "website": "http://www.tabor.edu/"
       },
       {
          "university": "Talladega College",
          "website": "http://www.talladega.edu/"
       },
       {
          "university": "Tarleton State University",
          "website": "http://www.tarleton.edu/"
       },
       {
          "university": "Taylor University",
          "website": "http://www.tayloru.edu/"
       },
       {
          "university": "Taylor University - Fort Wayne Campus",
          "website": "http://www.tayloru.edu/fw/"
       },
       {
          "university": "Teachers College - Columbia University",
          "website": "http://www.tc.columbia.edu/"
       },
       {
          "university": "Temple University",
          "website": "http://www.temple.edu/"
       },
       {
          "university": "Temple University School of Podiatric Medicine",
          "website": "http://www.pcpm.edu/"
       },
       {
          "university": "Tennessee State University",
          "website": "http://www.tnstate.edu/"
       },
       {
          "university": "Tennessee Technological University",
          "website": "http://www.tntech.edu/"
       },
       {
          "university": "Tennessee Temple University",
          "website": "http://www.tntemple.edu/"
       },
       {
          "university": "Tennessee Wesleyan College",
          "website": "http://www.twcnet.edu/"
       },
       {
          "university": "Texas A&M International University",
          "website": "http://www.tamiu.edu/"
       },
       {
          "university": "Texas A&M University",
          "website": "http://www.tamu.edu/"
       },
       {
          "university": "Texas A&M University - Commerce",
          "website": "http://www.tamu-commerce.edu/"
       },
       {
          "university": "Texas A&M University - Corpus Christi",
          "website": "http://www.tamucc.edu/"
       },
       {
          "university": "Texas A&M University - Galveston",
          "website": "http://www.tamug.tamu.edu/"
       },
       {
          "university": "Texas A&M University - Kingsville",
          "website": "http://www.tamuk.edu/"
       },
       {
          "university": "Texas A&M University - Texarkana",
          "website": "http://www.tamut.edu/"
       },
       {
          "university": "Texas Chiropractic College",
          "website": "http://www.txchiro.edu/"
       },
       {
          "university": "Texas Christian University",
          "website": "http://www.tcu.edu/"
       },
       {
          "university": "Texas College",
          "website": "http://www.texascollege.edu/"
       },
       {
          "university": "Texas College of Osteopathic Medicine",
          "website": "http://www.hsc.unt.edu/education/tcom/"
       },
       {
          "university": "Texas Lutheran University",
          "website": "http://www.txlutheran.edu/"
       },
       {
          "university": "Texas Southern University",
          "website": "http://www.tsu.edu/"
       },
       {
          "university": "Texas Tech University",
          "website": "http://www.ttu.edu/"
       },
       {
          "university": "Texas Tech University Health Science Center",
          "website": "http://www.ttuhsc.edu/"
       },
       {
          "university": "Texas Wesleyan University",
          "website": "http://www.txwesleyan.edu/"
       },
       {
          "university": "Texas Woman's University",
          "website": "http://www.twu.edu/"
       },
       {
          "university": "The American College",
          "website": "http://www.amercoll.edu/"
       },
       {
          "university": "The Art Institute of Boston",
          "website": "http://www.aiboston.edu/"
       },
       {
          "university": "The Art Institutes International Portland",
          "website": "http://www.aipd.aii.edu/"
       },
       {
          "university": "The Art Institutes International San Francisco",
          "website": "http://www.aisf.aii.edu/"
       },
       {
          "university": "The Boston Conservatory",
          "website": "http://www.bostonconservatory.edu/"
       },
       {
          "university": "The Catholic University of America",
          "website": "http://www.cua.edu/"
       },
       {
          "university": "The Chicago School of Professional Psychology",
          "website": "http://www.thechicagoschool.edu/"
       },
       {
          "university": "The College of Insurance",
          "website": "http://www.tci.edu/"
       },
       {
          "university": "The College of New Jersey",
          "website": "http://www.tcnj.edu/"
       },
       {
          "university": "The College of Santa Fe",
          "website": "http://www.csf.edu/"
       },
       {
          "university": "The College of St. Scholastica",
          "website": "http://www.css.edu/"
       },
       {
          "university": "The College of Westchester",
          "website": "http://www.cw.edu/"
       },
       {
          "university": "The College of Wooster",
          "website": "http://www.wooster.edu/"
       },
       {
          "university": "The Cooper Union for the Advancement of Science and Art",
          "website": "http://www.cooper.edu/"
       },
       {
          "university": "The Corcoran College of Art",
          "website": "http://www.corcoran.edu/college/"
       },
       {
          "university": "The Curtis Institute of Music",
          "website": "http://www.curtis.edu/"
       },
       {
          "university": "The Defiance College",
          "website": "http://www.defiance.edu/"
       },
       {
          "university": "The Dickinson School of Law",
          "website": "http://www.dsl.edu/"
       },
       {
          "university": "The Illinois Institute of Art-Chicago",
          "website": "http://www.ilic.artinstitutes.edu/"
       },
       {
          "university": "The Johns Hopkins University",
          "website": "http://www.jhu.edu/"
       },
       {
          "university": "The Juilliard School",
          "website": "http://www.juilliard.edu/"
       },
       {
          "university": "The Leadership Institute of Seattle",
          "website": "http://www.lios.org/"
       },
       {
          "university": "The Maryland Institute - College of Art",
          "website": "http://www.mica.edu/"
       },
       {
          "university": "The Master's College",
          "website": "http://www.masters.edu/"
       },
       {
          "university": "The McGregor School of Antioch University",
          "website": "http://www.mcgregor.edu/"
       },
       {
          "university": "The Naropa Institute",
          "website": "http://www.naropa.edu/"
       },
       {
          "university": "The New School",
          "website": "http://www.newschool.edu/"
       },
       {
          "university": "The Rockefeller University",
          "website": "http://www.rockefeller.edu/"
       },
       {
          "university": "The School of the Art Institute of Chicago",
          "website": "http://www.artic.edu/"
       },
       {
          "university": "The Scripps Research Institute",
          "website": "http://www.scripps.edu/"
       },
       {
          "university": "The Southern Christian University",
          "website": "http://www.southernchristian.edu/"
       },
       {
          "university": "The Tulane University of New Orleans",
          "website": "http://www.tulane.edu/"
       },
       {
          "university": "The Union Institute",
          "website": "http://www.tui.edu/"
       },
       {
          "university": "Thiel College",
          "website": "http://www.thiel.edu/"
       },
       {
          "university": "Thomas A. Edison State College",
          "website": "http://www.tesc.edu/"
       },
       {
          "university": "Thomas Aquinas College",
          "website": "http://www.thomasaquinas.edu/"
       },
       {
          "university": "Thomas College Maine",
          "website": "http://www.thomas.edu/"
       },
       {
          "university": "Thomas Jefferson University",
          "website": "http://www.tju.edu/"
       },
       {
          "university": "Thomas More College",
          "website": "http://www.thomasmore.edu/"
       },
       {
          "university": "Thomas More College of Liberal Arts",
          "website": "http://www.thomasmorecollege.edu/"
       },
       {
          "university": "Thomas University",
          "website": "http://www.thomasu.edu/"
       },
       {
          "university": "Thunderbird School of Global Management",
          "website": "http://www.thunderbird.edu/"
       },
       {
          "university": "Tiffin University",
          "website": "http://www.tiffin.edu/"
       },
       {
          "university": "Toccoa Falls College",
          "website": "http://www.toccoafalls.edu/"
       },
       {
          "university": "Tomball College",
          "website": "http://wwwtc.nhmccd.edu/"
       },
       {
          "university": "Tougaloo College",
          "website": "http://www.tougaloo.edu/"
       },
       {
          "university": "Touro College",
          "website": "http://www.touro.edu/"
       },
       {
          "university": "Touro University",
          "website": "http://www.tu.edu/"
       },
       {
          "university": "Towson University",
          "website": "http://www.towson.edu/"
       },
       {
          "university": "Transylvania University",
          "website": "http://www.transy.edu/"
       },
       {
          "university": "Trevecca Nazarene University",
          "website": "http://www.trevecca.edu/"
       },
       {
          "university": "Tri-College University",
          "website": "http://www.ndsu.nodak.edu/tricollege/"
       },
       {
          "university": "Trident University",
          "website": "http://www.trident.edu/"
       },
       {
          "university": "Trinity Bible College",
          "website": "http://www.tbc2day.edu/"
       },
       {
          "university": "Trinity Christian College",
          "website": "http://www.trnty.edu/"
       },
       {
          "university": "Trinity College Connecticut",
          "website": "http://www.trincoll.edu/"
       },
       {
          "university": "Trinity College of Florida",
          "website": "http://www.trinitycollege.edu/"
       },
       {
          "university": "Trinity College of Vermont",
          "website": "http://www.trinityvt.edu/"
       },
       {
          "university": "Trinity International University",
          "website": "http://www.trin.edu/"
       },
       {
          "university": "Trinity International University (Excel) - Miami",
          "website": "http://www.tiu.edu/excel/index.html"
       },
       {
          "university": "Trinity University",
          "website": "http://www.trinitydc.edu/"
       },
       {
          "university": "Trinity University",
          "website": "http://www.trinity.edu/"
       },
       {
          "university": "Tri-State University",
          "website": "http://www.tristate.edu/"
       },
       {
          "university": "Triton College",
          "website": "http://www.triton.cc.il.us/"
       },
       {
          "university": "Troy University",
          "website": "http://www.troy.edu/"
       },
       {
          "university": "Troy University - Dothan",
          "website": "http://dothan.troy.edu/"
       },
       {
          "university": "Troy University - Montgomery",
          "website": "http://montgomery.troy.edu/"
       },
       {
          "university": "Troy University - Phenix City",
          "website": "http://phenix.troy.edu/"
       },
       {
          "university": "Troy University - Troy",
          "website": "http://troy.troy.edu/"
       },
       {
          "university": "Truman College",
          "website": "http://www.trumancollege.net/"
       },
       {
          "university": "Truman State University",
          "website": "http://www.truman.edu/"
       },
       {
          "university": "Tufts University",
          "website": "http://www.tufts.edu/"
       },
       {
          "university": "Tui Online University",
          "website": "http://www.tuiu.edu/"
       },
       {
          "university": "Tusculum College",
          "website": "http://www.tusculum.edu/"
       },
       {
          "university": "Tuskegee University",
          "website": "http://www.tusk.edu/"
       },
       {
          "university": "Uniformed Services Universty of the Health Sciences",
          "website": "http://www.usuhs.mil/"
       },
       {
          "university": "Union College",
          "website": "http://www.union.edu/"
       },
       {
          "university": "Union College Kentucky",
          "website": "http://www.unionky.edu/"
       },
       {
          "university": "Union College Nebraska",
          "website": "http://www.ucollege.edu/"
       },
       {
          "university": "Union Theological Seminary (UTS)",
          "website": "http://www.union-psce.edu/"
       },
       {
          "university": "Union University",
          "website": "http://www.uu.edu/"
       },
       {
          "university": "United States Air Force Academy",
          "website": "http://www.usafa.af.mil/"
       },
       {
          "university": "United States Coast Guard Academy",
          "website": "http://www.cga.edu/"
       },
       {
          "university": "United States International University",
          "website": "http://www.usiu.edu/"
       },
       {
          "university": "United States Merchant Marine Academy",
          "website": "http://www.usmma.edu/"
       },
       {
          "university": "United States Military Academy",
          "website": "http://www.usma.edu/"
       },
       {
          "university": "United States Naval Academy",
          "website": "http://www.usna.edu/"
       },
       {
          "university": "United States Sports Academy",
          "website": "http://www.sport.ussa.edu/"
       },
       {
          "university": "Unity College",
          "website": "http://www.unity.edu/"
       },
       {
          "university": "University of Advancing Technology (UAT)",
          "website": "http://www.uat.edu/"
       },
       {
          "university": "University of Akron",
          "website": "http://www.uakron.edu/"
       },
       {
          "university": "University of Alabama - Birmingham",
          "website": "http://www.uab.edu/"
       },
       {
          "university": "University of Alabama - Huntsville",
          "website": "http://www.uah.edu/"
       },
       {
          "university": "University of Alabama - Tuscaloosa",
          "website": "http://www.ua.edu/"
       },
       {
          "university": "University of Alanta",
          "website": "http://www.uofa.edu/"
       },
       {
          "university": "University of Alaska - Anchorage",
          "website": "http://www.uaa.alaska.edu/"
       },
       {
          "university": "University of Alaska - Fairbanks",
          "website": "http://www.uaf.edu/"
       },
       {
          "university": "University of Alaska - Southeast",
          "website": "http://www.uas.alaska.edu/"
       },
       {
          "university": "University of Alaska (System)",
          "website": "http://www.alaska.edu/"
       },
       {
          "university": "University of Arizona",
          "website": "http://www.arizona.edu/"
       },
       {
          "university": "University of Arkansas at Fayetteville",
          "website": "http://www.uark.edu/"
       },
       {
          "university": "University of Arkansas at Little Rock",
          "website": "http://www.ualr.edu/"
       },
       {
          "university": "University of Arkansas at Monticello",
          "website": "http://www.uamont.edu/"
       },
       {
          "university": "University of Arkansas at Pine Bluff",
          "website": "http://www.uapb.edu/"
       },
       {
          "university": "University of Arkansas for Medical Sciences",
          "website": "http://www.uams.edu/"
       },
       {
          "university": "University of Arkansas (System)",
          "website": "http://www.uasys.edu/"
       },
       {
          "university": "University of Baltimore",
          "website": "http://www.ubalt.edu/"
       },
       {
          "university": "University of Bridgeport",
          "website": "http://www.bridgeport.edu/"
       },
       {
          "university": "University of California - Berkeley",
          "website": "http://www.berkeley.edu/"
       },
       {
          "university": "University of California - Davis",
          "website": "http://www.ucdavis.edu/"
       },
       {
          "university": "University of California - Hastings College of Law",
          "website": "http://www.uchastings.edu/"
       },
       {
          "university": "University of California - Irvine",
          "website": "http://www.uci.edu/"
       },
       {
          "university": "University of California - Los Angeles",
          "website": "http://www.ucla.edu/"
       },
       {
          "university": "University of California - Merced",
          "website": "http://www.ucmerced.edu/"
       },
       {
          "university": "University of California - Oakland",
          "website": "http://www.ucop.edu/"
       },
       {
          "university": "University of California - Riverside",
          "website": "http://www.ucr.edu/"
       },
       {
          "university": "University of California - San Diego",
          "website": "http://www.ucsd.edu/"
       },
       {
          "university": "University of California - San Francisco",
          "website": "http://www.ucsf.edu/"
       },
       {
          "university": "University of California - Santa Barbara",
          "website": "http://www.ucsb.edu/"
       },
       {
          "university": "University of California - Santa Cruz",
          "website": "http://www.ucsc.edu/"
       },
       {
          "university": "University of California System",
          "website": "http://www.universityofcalifornia.edu/"
       },
       {
          "university": "University of Central Arkansas",
          "website": "http://www.uca.edu/"
       },
       {
          "university": "University of Central Florida",
          "website": "http://www.ucf.edu/"
       },
       {
          "university": "University of Central Missouri",
          "website": "http://www.ucmo.edu/"
       },
       {
          "university": "University of Central Oklahoma",
          "website": "http://www.ucok.edu/"
       },
       {
          "university": "University of Central Texas",
          "website": "http://www.vvm.com/uct/"
       },
       {
          "university": "University of Charleston",
          "website": "http://www.uchaswv.edu/"
       },
       {
          "university": "University of Charleston South Carolina",
          "website": "http://univchas.cofc.edu/"
       },
       {
          "university": "University of Chicago",
          "website": "http://www.uchicago.edu/"
       },
       {
          "university": "University of Cincinnati",
          "website": "http://www.uc.edu/"
       },
       {
          "university": "University of Colorado at Boulder",
          "website": "http://www.colorado.edu/"
       },
       {
          "university": "University of Colorado at Colorado Springs",
          "website": "http://www.uccs.edu/"
       },
       {
          "university": "University of Colorado at Denver",
          "website": "http://www.cudenver.edu/"
       },
       {
          "university": "University of Colorado Health Sciences Center",
          "website": "http://www.uchsc.edu/"
       },
       {
          "university": "University of Connecticut",
          "website": "http://www.uconn.edu/"
       },
       {
          "university": "University of Connecticut at Avery Point",
          "website": "http://www.averypoint.uconn.edu/"
       },
       {
          "university": "University of Connecticut at Hartford",
          "website": "http://vm.uconn.edu/~wwwhtfd/ugrad/"
       },
       {
          "university": "University of Connecticut at Stamford",
          "website": "http://www.stamford.uconn.edu/"
       },
       {
          "university": "University of Connecticut at Waterbury",
          "website": "http://www.waterbury.uconn.edu/"
       },
       {
          "university": "University of Connecticut Health Center",
          "website": "http://www.uchc.edu/"
       },
       {
          "university": "University of Dallas",
          "website": "http://www.udallas.edu/"
       },
       {
          "university": "University of Dayton",
          "website": "http://www.udayton.edu/"
       },
       {
          "university": "University of Delaware",
          "website": "http://www.udel.edu/"
       },
       {
          "university": "University of Denver",
          "website": "http://www.du.edu/"
       },
       {
          "university": "University of Detroit Mercy",
          "website": "http://www.udmercy.edu/"
       },
       {
          "university": "University of Dubuque",
          "website": "http://www.dbq.edu/"
       },
       {
          "university": "University of Evansville",
          "website": "http://www.evansville.edu/"
       },
       {
          "university": "University of Findlay",
          "website": "http://www.findlay.edu/"
       },
       {
          "university": "University of Florida",
          "website": "http://www.ufl.edu/"
       },
       {
          "university": "University of Georgia",
          "website": "http://www.uga.edu/"
       },
       {
          "university": "University of Great Falls",
          "website": "http://www.ugf.edu/"
       },
       {
          "university": "University of Hartford",
          "website": "http://www.hartford.edu/"
       },
       {
          "university": "University of Hawaii - Hilo",
          "website": "http://www.uhh.hawaii.edu/"
       },
       {
          "university": "University of Hawaii - Manoa",
          "website": "http://www.uhm.hawaii.edu/"
       },
       {
          "university": "University Of Hawaii - System",
          "website": "http://www.hawaii.edu/"
       },
       {
          "university": "University of Hawaii - West Oahu",
          "website": "http://www.uhwo.hawaii.edu/"
       },
       {
          "university": "University of Health Sciences",
          "website": "http://www.uhs.edu/"
       },
       {
          "university": "University of Houston",
          "website": "http://www.uh.edu/"
       },
       {
          "university": "University of Houston - Clear Lake",
          "website": "http://www.cl.uh.edu/"
       },
       {
          "university": "University of Houston - Downtown",
          "website": "http://www.dt.uh.edu/"
       },
       {
          "university": "University of Houston - Victoria",
          "website": "http://www.vic.uh.edu/"
       },
       {
          "university": "University of Idaho",
          "website": "http://www.uidaho.edu/"
       },
       {
          "university": "University of Illinois",
          "website": "http://www.uillinois.edu/"
       },
       {
          "university": "University of Illinois at Chicago",
          "website": "http://www.uic.edu/"
       },
       {
          "university": "University of Illinois at Springfield",
          "website": "http://www.uis.edu/"
       },
       {
          "university": "University of Illinois at Urbana-Champaign",
          "website": "http://www.uiuc.edu/"
       },
       {
          "university": "University of Indianapolis",
          "website": "http://www.uindy.edu/"
       },
       {
          "university": "University of Iowa",
          "website": "http://www.uiowa.edu/"
       },
       {
          "university": "University of Kansas",
          "website": "http://www.ku.edu/"
       },
       {
          "university": "University of Kentucky",
          "website": "http://www.uky.edu/"
       },
       {
          "university": "University of La Verne",
          "website": "http://www.ulaverne.edu/"
       },
       {
          "university": "University of Louisiana at Lafayette",
          "website": "http://www.louisiana.edu/"
       },
       {
          "university": "University of Louisiana at Monroe",
          "website": "http://www.ulm.edu/"
       },
       {
          "university": "University of Louisville",
          "website": "http://www.louisville.edu/"
       },
       {
          "university": "University of Maine - Augusta",
          "website": "http://www.uma.maine.edu/"
       },
       {
          "university": "University of Maine - Farmington",
          "website": "http://www.umf.maine.edu/"
       },
       {
          "university": "University of Maine - Fort Kent",
          "website": "http://www.umfk.maine.edu/"
       },
       {
          "university": "University of Maine - Machias",
          "website": "http://www.umm.maine.edu/"
       },
       {
          "university": "University of Maine - Orono",
          "website": "http://www.umaine.edu/"
       },
       {
          "university": "University of Maine - Presque Isle",
          "website": "http://www.umpi.maine.edu/"
       },
       {
          "university": "University of Maine (System)",
          "website": "http://www.maine.edu/"
       },
       {
          "university": "University of Management & Technology",
          "website": "http://www.umtweb.edu/"
       },
       {
          "university": "University of Mary",
          "website": "http://www.umary.edu/"
       },
       {
          "university": "University of Mary Hardin-Baylor",
          "website": "http://www.umhb.edu/"
       },
       {
          "university": "University of Maryland at Baltimore",
          "website": "http://www.umbc.edu/"
       },
       {
          "university": "University of Maryland at College Park",
          "website": "http://www.umd.edu/"
       },
       {
          "university": "University of Maryland Baltimore County",
          "website": "http://www.umbc.edu/"
       },
       {
          "university": "University of Maryland Eastern Shore",
          "website": "http://www.umes.edu/"
       },
       {
          "university": "University of Maryland Medicine",
          "website": "http://www.umm.edu/"
       },
       {
          "university": "University of Maryland (System)",
          "website": "http://www.ums.edu/"
       },
       {
          "university": "University of Maryland University College",
          "website": "http://www.umuc.edu/"
       },
       {
          "university": "University of Massachusetts at Amherst",
          "website": "http://www.umass.edu/"
       },
       {
          "university": "University of Massachusetts at Boston",
          "website": "http://www.umb.edu/"
       },
       {
          "university": "University of Massachusetts at Dartmouth",
          "website": "http://www.umassd.edu/"
       },
       {
          "university": "University of Massachusetts at Lowell",
          "website": "http://www.uml.edu/"
       },
       {
          "university": "University of Massachusetts Medical Center at Worcester",
          "website": "http://www.ummed.edu/"
       },
       {
          "university": "University of Massachusetts (System)",
          "website": "http://www.massachusetts.edu/"
       },
       {
          "university": "University of Medicine and Dentistry of New Jersey",
          "website": "http://www.umdnj.edu/"
       },
       {
          "university": "University of Memphis",
          "website": "http://www.memphis.edu/"
       },
       {
          "university": "University of Miami",
          "website": "http://www.miami.edu/"
       },
       {
          "university": "University of Michigan - Ann Arbor",
          "website": "http://www.umich.edu/"
       },
       {
          "university": "University of Michigan - Dearborn",
          "website": "http://www.umd.umich.edu/"
       },
       {
          "university": "University of Michigan - Flint",
          "website": "http://www.flint.umich.edu/"
       },
       {
          "university": "University of Minnesota - Crookston",
          "website": "http://www.crk.umn.edu/"
       },
       {
          "university": "University of Minnesota - Duluth",
          "website": "http://www.d.umn.edu/"
       },
       {
          "university": "University of Minnesota - Morris",
          "website": "http://www.mrs.umn.edu/"
       },
       {
          "university": "University of Minnesota - Twin Cities Campus",
          "website": "http://www1.umn.edu/twincities/"
       },
       {
          "university": "University of Mississippi",
          "website": "http://www.olemiss.edu/"
       },
       {
          "university": "University of Mississippi Medical Center",
          "website": "http://www.umc.edu/"
       },
       {
          "university": "University of Missouri - Columbia",
          "website": "http://www.missouri.edu/"
       },
       {
          "university": "University of Missouri - Kansas City",
          "website": "http://www.umkc.edu/"
       },
       {
          "university": "University of Missouri - Saint Louis",
          "website": "http://www.umsl.edu/"
       },
       {
          "university": "University of Mobile",
          "website": "http://www.umobile.edu/"
       },
       {
          "university": "University of Montana",
          "website": "http://www.umt.edu/"
       },
       {
          "university": "University of Montana Western",
          "website": "http://www.umwestern.edu/"
       },
       {
          "university": "University of Montevallo",
          "website": "http://www.montevallo.edu/"
       },
       {
          "university": "University of Nebraska - Kearney",
          "website": "http://www.unk.edu/"
       },
       {
          "university": "University of Nebraska - Lincoln",
          "website": "http://www.unl.edu/"
       },
       {
          "university": "University of Nebraska Medical Center",
          "website": "http://www.unmc.edu/"
       },
       {
          "university": "University of Nebraska - Omaha",
          "website": "http://www.unomaha.edu/"
       },
       {
          "university": "University of Nebraska (System)",
          "website": "http://www.nebraska.edu/"
       },
       {
          "university": "University of Nevada - Las Vegas",
          "website": "http://www.unlv.edu/"
       },
       {
          "university": "University of Nevada - Reno",
          "website": "http://www.unr.edu/"
       },
       {
          "university": "University of New England",
          "website": "http://www.une.edu/"
       },
       {
          "university": "University of New England - Westbrook College Campus",
          "website": "http://www.une.edu/wcdirctn.html"
       },
       {
          "university": "University of New Hampshire",
          "website": "http://www.unh.edu/"
       },
       {
          "university": "University of New Hampshire - Manchester",
          "website": "http://www.unh.edu/unhm/"
       },
       {
          "university": "University of New Haven",
          "website": "http://www.newhaven.edu/"
       },
       {
          "university": "University of New Mexico",
          "website": "http://www.unm.edu/"
       },
       {
          "university": "University of New Orleans",
          "website": "http://www.uno.edu/"
       },
       {
          "university": "University of North Alabama",
          "website": "http://www.una.edu/"
       },
       {
          "university": "University of North America",
          "website": "http://www.universityofnorthamerica.org/"
       },
       {
          "university": "University of North Carolina at Asheville",
          "website": "http://www.unca.edu/"
       },
       {
          "university": "University of North Carolina at Chapel Hill",
          "website": "http://www.unc.edu/"
       },
       {
          "university": "University of North Carolina at Charlotte",
          "website": "http://www.uncc.edu/"
       },
       {
          "university": "University of North Carolina at Greensboro",
          "website": "http://www.uncg.edu/"
       },
       {
          "university": "University of North Carolina at Pembroke",
          "website": "http://www.uncp.edu/"
       },
       {
          "university": "University of North Carolina at Wilmington",
          "website": "http://www.uncwil.edu/"
       },
       {
          "university": "University of North Dakota",
          "website": "http://www.und.nodak.edu/"
       },
       {
          "university": "University of Northern Colorado",
          "website": "http://www.univnorthco.edu/"
       },
       {
          "university": "University of Northern Iowa",
          "website": "http://www.uni.edu/"
       },
       {
          "university": "University of Northern Virginia",
          "website": "http://www.unva.edu/"
       },
       {
          "university": "University of Northern Washington",
          "website": "http://www.unw.edu/"
       },
       {
          "university": "University of North Florida",
          "website": "http://www.unf.edu/"
       },
       {
          "university": "University of North Texas",
          "website": "http://www.unt.edu/"
       },
       {
          "university": "University of North Texas Health Science Center at Fort Worth",
          "website": "http://www.hsc.unt.edu/"
       },
       {
          "university": "University of NorthWest",
          "website": "http://www.unw.ac/"
       },
       {
          "university": "University of Notre Dame",
          "website": "http://www.nd.edu/"
       },
       {
          "university": "University of Oklahoma",
          "website": "http://www.ou.edu/"
       },
       {
          "university": "University of Oklahoma Health Sciences Center",
          "website": "http://www.ouhsc.edu/"
       },
       {
          "university": "University of Oregon",
          "website": "http://www.uoregon.edu/"
       },
       {
          "university": "University of Osteopathic Medicine and Health Science",
          "website": "http://www.uomhs.edu/"
       },
       {
          "university": "University of Pennsylvania",
          "website": "http://www.upenn.edu/"
       },
       {
          "university": "University of Phoenix",
          "website": "http://www.phoenix.edu/"
       },
       {
          "university": "University of Pittsburgh",
          "website": "http://www.pitt.edu/"
       },
       {
          "university": "University of Pittsburgh at Bradford",
          "website": "http://www.upb.pitt.edu/"
       },
       {
          "university": "University of Pittsburgh at Greensburg",
          "website": "http://www.pitt.edu/~upg/"
       },
       {
          "university": "University of Pittsburgh at Johnstown",
          "website": "http://www.pitt.edu/~upjweb/"
       },
       {
          "university": "University of Portland",
          "website": "http://www.uofport.edu/"
       },
       {
          "university": "University of Puget Sound",
          "website": "http://www.ups.edu/"
       },
       {
          "university": "University of Redlands",
          "website": "http://www.redlands.edu/"
       },
       {
          "university": "University of Rhode Island",
          "website": "http://www.uri.edu/"
       },
       {
          "university": "University of Richmond",
          "website": "http://www.urich.edu/"
       },
       {
          "university": "University of Rio Grande",
          "website": "http://www.urgrgcc.edu/"
       },
       {
          "university": "University of Rochester",
          "website": "http://www.rochester.edu/"
       },
       {
          "university": "University of San Diego",
          "website": "http://www.sandiego.edu/"
       },
       {
          "university": "University of San Francisco",
          "website": "http://www.usfca.edu/"
       },
       {
          "university": "University of Science and Arts of Oklahoma",
          "website": "http://www.usao.edu/"
       },
       {
          "university": "University of Scranton",
          "website": "http://www.uofs.edu/"
       },
       {
          "university": "University of Sioux Falls",
          "website": "http://www.thecoo.edu/"
       },
       {
          "university": "University of South Alabama",
          "website": "http://www.usouthal.edu/"
       },
       {
          "university": "University of South Carolina",
          "website": "http://www.sc.edu/"
       },
       {
          "university": "University of South Carolina - Aiken",
          "website": "http://www.usca.sc.edu/"
       },
       {
          "university": "University of South Carolina - Beaufort",
          "website": "http://www.sc.edu/beaufort/"
       },
       {
          "university": "University of South Carolina - Lancaster",
          "website": "http://www.sc.edu/lancaster/"
       },
       {
          "university": "University of South Carolina - Salkehatchie",
          "website": "http://www.rcce.sc.edu/salkehatchie/"
       },
       {
          "university": "University of South Carolina - Spartanburg",
          "website": "http://www.uscs.edu/"
       },
       {
          "university": "University of South Carolina - Sumter",
          "website": "http://www.uscsumter.edu/"
       },
       {
          "university": "University of South Carolina - Union",
          "website": "http://www.sc.edu/union/"
       },
       {
          "university": "University of South Dakota",
          "website": "http://www.usd.edu/"
       },
       {
          "university": "University of Southern California",
          "website": "http://www.usc.edu/"
       },
       {
          "university": "University of Southern Indiana",
          "website": "http://www.usi.edu/"
       },
       {
          "university": "University of Southern Maine",
          "website": "http://www.usm.maine.edu/"
       },
       {
          "university": "University of Southern Mississippi",
          "website": "http://www.usm.edu/"
       },
       {
          "university": "University of South Florida",
          "website": "http://www.usf.edu/"
       },
       {
          "university": "University of St. Francis",
          "website": "http://www.stfrancis.edu/"
       },
       {
          "university": "University of St. Thomas - Houston",
          "website": "http://www.stthom.edu/"
       },
       {
          "university": "University of St. Thomas - St. Paul",
          "website": "http://www.stthomas.edu/"
       },
       {
          "university": "University of Tampa",
          "website": "http://www.utampa.edu/"
       },
       {
          "university": "University of Tennessee - Chattanooga",
          "website": "http://www.utc.edu/"
       },
       {
          "university": "University of Tennessee - Knoxville",
          "website": "http://www.utk.edu/"
       },
       {
          "university": "University of Tennessee - Martin",
          "website": "http://www.utm.edu/"
       },
       {
          "university": "University of Tennessee - Memphis",
          "website": "http://www.utmem.edu/"
       },
       {
          "university": "University of Tennessee Space Institute",
          "website": "http://www.utsi.edu/"
       },
       {
          "university": "University of Texas",
          "website": "http://www.utsystem.edu/"
       },
       {
          "university": "University of Texas at Arlington",
          "website": "http://www.uta.edu/"
       },
       {
          "university": "University of Texas at Austin",
          "website": "http://www.utexas.edu/"
       },
       {
          "university": "University of Texas at Brownsville",
          "website": "http://www.utb.edu/"
       },
       {
          "university": "University of Texas at Dallas",
          "website": "http://www.utdallas.edu/"
       },
       {
          "university": "University of Texas at El Paso",
          "website": "http://www.utep.edu/"
       },
       {
          "university": "University of Texas at San Antonio",
          "website": "http://www.utsa.edu/"
       },
       {
          "university": "University of Texas at Tyler",
          "website": "http://www.uttyl.edu/"
       },
       {
          "university": "University of Texas Health Center at Houston",
          "website": "http://www.uthouston.edu/"
       },
       {
          "university": "University of Texas Health Center at Tyler",
          "website": "http://www.uthct.edu/"
       },
       {
          "university": "University of Texas Health Science Center at San Antonio",
          "website": "http://www.uthscsa.edu/"
       },
       {
          "university": "University of Texas M.D. Anderson Cancer Center",
          "website": "http://www.mdanderson.org/"
       },
       {
          "university": "University of Texas Medical Branch Galveston",
          "website": "http://www.utmb.edu/"
       },
       {
          "university": "University of Texas of the Permian Basin",
          "website": "http://www.utpb.edu/"
       },
       {
          "university": "University of Texas Pan American",
          "website": "http://www.panam.edu/"
       },
       {
          "university": "University of Texas Southwestern Medical Center at Dallas",
          "website": "http://www.utsouthwestern.edu/"
       },
       {
          "university": "University of the Arts",
          "website": "http://www.uarts.edu/"
       },
       {
          "university": "University of the District of Columbia",
          "website": "http://www.udc.edu/"
       },
       {
          "university": "University of the Incarnate World",
          "website": "http://www.uiw.edu/"
       },
       {
          "university": "University of the Ozarks",
          "website": "http://www.ozarks.edu/"
       },
       {
          "university": "University of the Pacific",
          "website": "http://www.pacific.edu/"
       },
       {
          "university": "University of the Sciences in Philadelphia",
          "website": "http://www.usip.edu/"
       },
       {
          "university": "University of the South",
          "website": "http://www.sewanee.edu/"
       },
       {
          "university": "University of the Southwest",
          "website": "http://www.usw.edu/"
       },
       {
          "university": "University of Toledo",
          "website": "http://www.utoledo.edu/"
       },
       {
          "university": "University of Tulsa",
          "website": "http://www.utulsa.edu/"
       },
       {
          "university": "University of Utah",
          "website": "http://www.utah.edu/"
       },
       {
          "university": "University of Vermont",
          "website": "http://www.uvm.edu/"
       },
       {
          "university": "University of Virginia",
          "website": "http://www.virginia.edu/"
       },
       {
          "university": "University of Virginia - College at Wise",
          "website": "http://www.wise.virginia.edu/"
       },
       {
          "university": "University of Washington",
          "website": "http://www.washington.edu/"
       },
       {
          "university": "University of Washington - Tacoma",
          "website": "http://www.tacoma.washington.edu/"
       },
       {
          "university": "University of West Alabama",
          "website": "http://www.uwa.edu/"
       },
       {
          "university": "University of West Florida",
          "website": "http://www.uwf.edu/"
       },
       {
          "university": "University of West Los Angeles",
          "website": "http://www.uwla.edu/"
       },
       {
          "university": "University of Wisconsin - Eau Claire",
          "website": "http://www.uwec.edu/"
       },
       {
          "university": "University of Wisconsin - Green Bay",
          "website": "http://www.uwgb.edu/"
       },
       {
          "university": "University of Wisconsin - La Crosse",
          "website": "http://www.uwlax.edu/"
       },
       {
          "university": "University of Wisconsin - Madison",
          "website": "http://www.wisc.edu/"
       },
       {
          "university": "University of Wisconsin - Milwaukee",
          "website": "http://www.uwm.edu/"
       },
       {
          "university": "University of Wisconsin - Oshkosh",
          "website": "http://www.uwosh.edu/"
       },
       {
          "university": "University of Wisconsin - Parkside",
          "website": "http://www.uwp.edu/"
       },
       {
          "university": "University of Wisconsin - Platteville",
          "website": "http://www.uwplatt.edu/"
       },
       {
          "university": "University of Wisconsin - River Falls",
          "website": "http://www.uwrf.edu/"
       },
       {
          "university": "University of Wisconsin - Stevens Point",
          "website": "http://www.uwsp.edu/"
       },
       {
          "university": "University of Wisconsin - Stout",
          "website": "http://www.uwstout.edu/"
       },
       {
          "university": "University of Wisconsin - Superior",
          "website": "http://www.uwsuper.edu/"
       },
       {
          "university": "University of Wisconsin - Whitewater",
          "website": "http://www.uww.edu/"
       },
       {
          "university": "University of Wyoming",
          "website": "http://www.uwyo.edu/"
       },
       {
          "university": "Upper Iowa University",
          "website": "http://www.uiu.edu/"
       },
       {
          "university": "Urbana University",
          "website": "http://www.urbana.edu/"
       },
       {
          "university": "Ursinus College",
          "website": "http://www.ursinus.edu/"
       },
       {
          "university": "Ursuline College",
          "website": "http://www.ursuline.edu/"
       },
       {
          "university": "Utah State University",
          "website": "http://www.usu.edu/"
       },
       {
          "university": "Utah Valley State College",
          "website": "http://www.uvsc.edu/"
       },
       {
          "university": "Utica College",
          "website": "http://www.ucsu.edu/"
       },
       {
          "university": "Valdosta State University",
          "website": "http://www.valdosta.edu/"
       },
       {
          "university": "Valley City State University",
          "website": "http://www.vcsu.nodak.edu/"
       },
       {
          "university": "Valley Forge Christian College",
          "website": "http://www.vfcc.edu/"
       },
       {
          "university": "Valparaiso University",
          "website": "http://www.valpo.edu/"
       },
       {
          "university": "Vanderbilt University",
          "website": "http://www.vanderbilt.edu/"
       },
       {
          "university": "VanderCook College of Music",
          "website": "http://www.vandercook.edu/"
       },
       {
          "university": "Vanguard University of Southern California",
          "website": "http://www.vanguard.edu/"
       },
       {
          "university": "Vassar College",
          "website": "http://www.vassar.edu/"
       },
       {
          "university": "Vennard College",
          "website": "http://www.vennard.edu/"
       },
       {
          "university": "Vermont Law School",
          "website": "http://www.vermontlaw.edu/"
       },
       {
          "university": "Vermont Technical College",
          "website": "http://www.vtc.vsc.edu/"
       },
       {
          "university": "Villa Julie College",
          "website": "http://www.vjc.edu/"
       },
       {
          "university": "Villanova University",
          "website": "http://www.villanova.edu/"
       },
       {
          "university": "Virginia College",
          "website": "http://www.vc.edu/"
       },
       {
          "university": "Virginia Commonwealth University",
          "website": "http://www.vcu.edu/"
       },
       {
          "university": "Virginia Intermont College",
          "website": "http://www.vic.edu/"
       },
       {
          "university": "Virginia International University",
          "website": "http://www.viu.edu/"
       },
       {
          "university": "Virginia Military Institute",
          "website": "http://www.vmi.edu/"
       },
       {
          "university": "Virginia Polytechnic Institute and State University (Virginia Tech)",
          "website": "http://www.vt.edu/"
       },
       {
          "university": "Virginia State University",
          "website": "http://www.vsu.edu/"
       },
       {
          "university": "Virginia Union University",
          "website": "http://www.vuu.edu/"
       },
       {
          "university": "Virginia Wesleyan College",
          "website": "http://www.vwc.edu/"
       },
       {
          "university": "Viterbo College",
          "website": "http://www.viterbo.edu/"
       },
       {
          "university": "Voorhees College",
          "website": "http://www.voorhees.edu/"
       },
       {
          "university": "Wabash College",
          "website": "http://www.wabash.edu/"
       },
       {
          "university": "Wagner College",
          "website": "http://www.wagner.edu/"
       },
       {
          "university": "Wake Forest University",
          "website": "http://www.wfu.edu/"
       },
       {
          "university": "Walden University",
          "website": "http://www.waldenu.edu/"
       },
       {
          "university": "Walla Walla College",
          "website": "http://www.wwc.edu/"
       },
       {
          "university": "Walsh College of Accountancy and Business Administration",
          "website": "http://www.walshcol.edu/"
       },
       {
          "university": "Walsh University",
          "website": "http://www.walsh.edu/"
       },
       {
          "university": "Warner Pacific College",
          "website": "http://www.warnerpacific.edu/"
       },
       {
          "university": "Warner Southern College",
          "website": "http://www.warner.edu/"
       },
       {
          "university": "Warren Wilson College",
          "website": "http://www.warren-wilson.edu/"
       },
       {
          "university": "Wartburg College",
          "website": "http://www.wartburg.edu/"
       },
       {
          "university": "Washburn University",
          "website": "http://www.washburn.edu/"
       },
       {
          "university": "Washington and Lee University",
          "website": "http://www.wlu.edu/"
       },
       {
          "university": "Washington Bible College",
          "website": "http://www.bible.edu/"
       },
       {
          "university": "Washington College",
          "website": "http://www.washcoll.edu/"
       },
       {
          "university": "Washington State University",
          "website": "http://www.wsu.edu/"
       },
       {
          "university": "Washington State University - Spokane",
          "website": "http://www.spokane.wsu.edu/"
       },
       {
          "university": "Washington State University - Tri-Cities",
          "website": "http://www.tricity.wsu.edu/"
       },
       {
          "university": "Washington State University - Vancouver",
          "website": "http://www.vancouver.wsu.edu/"
       },
       {
          "university": "Washington University in St. Louis",
          "website": "http://www.wustl.edu/"
       },
       {
          "university": "Wayland Baptist University",
          "website": "http://www.wbu.edu/"
       },
       {
          "university": "Waynesburg College",
          "website": "http://www.waynesburg.edu/"
       },
       {
          "university": "Wayne State College",
          "website": "http://www.wsc.edu/"
       },
       {
          "university": "Wayne State University",
          "website": "http://www.wayne.edu/"
       },
       {
          "university": "Webber College",
          "website": "http://www.webber.edu/"
       },
       {
          "university": "Webb Institute",
          "website": "http://www.webb-institute.edu/"
       },
       {
          "university": "Weber State University",
          "website": "http://www.weber.edu/"
       },
       {
          "university": "Webster University",
          "website": "http://www.webster.edu/"
       },
       {
          "university": "Webster University North Florida",
          "website": "http://www.webster.edu/jack/"
       },
       {
          "university": "Weill Medical College of Cornell University",
          "website": "http://www.med.cornell.edu/"
       },
       {
          "university": "Wellesley College",
          "website": "http://www.wellesley.edu/"
       },
       {
          "university": "Wells College",
          "website": "http://www.wells.edu/"
       },
       {
          "university": "Wentworth Institute of Technology",
          "website": "http://www.wit.edu/"
       },
       {
          "university": "Wesleyan College",
          "website": "http://www.wesleyan-college.edu/"
       },
       {
          "university": "Wesleyan University",
          "website": "http://www.wesleyan.edu/"
       },
       {
          "university": "Wesley College",
          "website": "http://www.wesley.edu/"
       },
       {
          "university": "Wesley College Mississippi",
          "website": "http://www.wesleycollege.com/"
       },
       {
          "university": "Westbrook University ",
          "website": "http://www.westbrooku.edu/"
       },
       {
          "university": "West Chester University of Pennsylvania",
          "website": "http://www.wcupa.edu/"
       },
       {
          "university": "West Coast University",
          "website": "http://www.westcoastuniversity.com/"
       },
       {
          "university": "Western Baptist College",
          "website": "http://www.wbc.edu/"
       },
       {
          "university": "Western Bible College",
          "website": "http://www.westernbible.edu/"
       },
       {
          "university": "Western Carolina University",
          "website": "http://www.wcu.edu/"
       },
       {
          "university": "Western Connecticut State University",
          "website": "http://www.wcsu.ctstateu.edu/"
       },
       {
          "university": "Western Governors University",
          "website": "http://www.wgu.edu/"
       },
       {
          "university": "Western Illinois University",
          "website": "http://www.wiu.edu/"
       },
       {
          "university": "Western International University",
          "website": "http://www.west.edu/"
       },
       {
          "university": "Western Kentucky University",
          "website": "http://www.wku.edu/"
       },
       {
          "university": "Western Maryland College",
          "website": "http://www.wmdc.edu/"
       },
       {
          "university": "Western Michigan University",
          "website": "http://www.wmich.edu/"
       },
       {
          "university": "Western New England College",
          "website": "http://www.wnec.edu/"
       },
       {
          "university": "Western New Mexico University",
          "website": "http://www.wnmu.edu/"
       },
       {
          "university": "Western Oregon University",
          "website": "http://www.wou.edu/"
       },
       {
          "university": "Western State College",
          "website": "http://www.western.edu/"
       },
       {
          "university": "Western States Chiropractic College",
          "website": "http://www.wschiro.edu/"
       },
       {
          "university": "Western State University College of Law",
          "website": "http://www.wsulaw.edu/"
       },
       {
          "university": "Western State University College of Law - Orange County",
          "website": "http://www.wsulaw.edu/"
       },
       {
          "university": "Western Washington University",
          "website": "http://www.wwu.edu/"
       },
       {
          "university": "Westfield State College",
          "website": "http://www.wsc.mass.edu/"
       },
       {
          "university": "West Liberty State College",
          "website": "http://www.wlsc.wvnet.edu/"
       },
       {
          "university": "Westminster College Fulton",
          "website": "http://www.wcmo.edu/"
       },
       {
          "university": "Westminster College New Wilmington",
          "website": "http://www.westminster.edu/"
       },
       {
          "university": "Westminster College of Salt Lake City",
          "website": "http://www.wcslc.edu/"
       },
       {
          "university": "Westmont College",
          "website": "http://www.westmont.edu/"
       },
       {
          "university": "West Suburban College of Nursing",
          "website": "http://www.curf.edu/~wscasseyp/wscn.htm"
       },
       {
          "university": "West Texas A&M University",
          "website": "http://www.wtamu.edu/"
       },
       {
          "university": "West Virginia School of Osteopathic Medicine",
          "website": "http://www.wvsom.edu/"
       },
       {
          "university": "West Virginia State College",
          "website": "http://www.wvsc.edu/"
       },
       {
          "university": "West Virginia University",
          "website": "http://www.wvu.edu/"
       },
       {
          "university": "West Virginia University Institute of Technology",
          "website": "http://wvit.wvnet.edu/"
       },
       {
          "university": "West Virginia Wesleyan College",
          "website": "http://www.wvwc.edu/"
       },
       {
          "university": "Westwood College",
          "website": "http://www.westwood.edu/"
       },
       {
          "university": "Wheaton College Massachusetts",
          "website": "http://www.wheatonma.edu/"
       },
       {
          "university": "Wheeling Jesuit University",
          "website": "http://www.wju.edu/"
       },
       {
          "university": "Wheelock College",
          "website": "http://www.wheelock.edu/"
       },
       {
          "university": "Whitman College",
          "website": "http://www.whitman.edu/"
       },
       {
          "university": "Whittier College",
          "website": "http://www.whittier.edu/"
       },
       {
          "university": "Whitworth College",
          "website": "http://www.whitworth.edu/"
       },
       {
          "university": "Wichita State University",
          "website": "http://www.twsu.edu/"
       },
       {
          "university": "Widener University",
          "website": "http://www.widener.edu/"
       },
       {
          "university": "Wilberforce University",
          "website": "http://www.wilberforce.edu/"
       },
       {
          "university": "Wilbur Wright College",
          "website": "http://www.ccc.edu/wright/"
       },
       {
          "university": "Wiley College",
          "website": "http://www.wileyc.edu/"
       },
       {
          "university": "Wilkes University",
          "website": "http://www.wilkes.edu/"
       },
       {
          "university": "Willamette University",
          "website": "http://www.willamette.edu/"
       },
       {
          "university": "William Carey College",
          "website": "http://www.wmcarey.edu/"
       },
       {
          "university": "William Jewell College",
          "website": "http://www.jewell.edu/"
       },
       {
          "university": "William Mitchell College of Law",
          "website": "http://www.wmitchell.edu/"
       },
       {
          "university": "William Paterson University",
          "website": "http://www.wpunj.edu/"
       },
       {
          "university": "William Penn College",
          "website": "http://www.wmpenn.edu/"
       },
       {
          "university": "Williams Baptist College",
          "website": "http://www.wbcoll.edu/"
       },
       {
          "university": "Williams College",
          "website": "http://www.williams.edu/"
       },
       {
          "university": "William Tyndale College",
          "website": "http://www.williamtyndale.edu/"
       },
       {
          "university": "William Woods University",
          "website": "http://www.wmwoods.edu/"
       },
       {
          "university": "Wilmington College",
          "website": "http://www.wilmcoll.edu/"
       },
       {
          "university": "Wilmington College",
          "website": "http://www.wilmington.edu/"
       },
       {
          "university": "Wilson College",
          "website": "http://www.wilson.edu/"
       },
       {
          "university": "Wingate University",
          "website": "http://www.wingate.edu/"
       },
       {
          "university": "Winona State University",
          "website": "http://www.winona.msus.edu/"
       },
       {
          "university": "Winston-Salem State University",
          "website": "http://www.wssu.edu/"
       },
       {
          "university": "Winthrop University",
          "website": "http://www.winthrop.edu/"
       },
       {
          "university": "Wisconsin Lutheran College",
          "website": "http://www.wlc.edu/"
       },
       {
          "university": "Wisconsin School of Professional Psychology",
          "website": "http://www.execpc.com/~wspp/"
       },
       {
          "university": "Wittenberg University",
          "website": "http://www.wittenberg.edu/"
       },
       {
          "university": "Wofford College",
          "website": "http://www.wofford.edu/"
       },
       {
          "university": "Woodbury University",
          "website": "http://www.woodburyu.edu/"
       },
       {
          "university": "Worcester Polytechnic Institute",
          "website": "http://www.wpi.edu/"
       },
       {
          "university": "Worcester State College",
          "website": "http://www.worc.mass.edu/"
       },
       {
          "university": "Wright Institute",
          "website": "http://www.wrightinst.edu/"
       },
       {
          "university": "Wright State University",
          "website": "http://www.wright.edu/"
       },
       {
          "university": "Xavier University",
          "website": "http://www.xu.edu/"
       },
       {
          "university": "Xavier University of Louisiana",
          "website": "http://www.xula.edu/"
       },
       {
          "university": "Yale University",
          "website": "http://www.yale.edu/"
       },
       {
          "university": "Yeshiva University",
          "website": "http://www.yu.edu/"
       },
       {
          "university": "York College Nebraska",
          "website": "http://www.york.edu/"
       },
       {
          "university": "York College of Pennsylvania",
          "website": "http://www.yorkcol.edu/"
       },
       {
          "university": "Yorker International University",
          "website": "http://www.nyuniversity.net/"
       },
       {
          "university": "York University",
          "website": "http://www.yorkuniversity.us/"
       },
       {
          "university": "Youngstown State University",
          "website": "http://www.ysu.edu/"
       }
    ],
    "UY": [
       {
          "university": "Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh",
          "website": "http://www.claeh.edu.uy/"
       },
       {
          "university": "Universidad Católica del Uruguay",
          "website": "http://www.ucu.edu.uy/"
       },
       {
          "university": "Universidad de la Empresa (UDE)",
          "website": "http://www.ude.edu.uy/"
       },
       {
          "university": "Universidad de la República",
          "website": "http://www.universidad.edu.uy/"
       },
       {
          "university": "Universidad de Montevideo",
          "website": "http://www.um.edu.uy/"
       },
       {
          "university": "Universidad ORT Uruguay",
          "website": "http://www.ort.edu.uy/"
       }
    ],
    "UZ": [
       {
          "university": "Ferghana Politechnical Institute",
          "website": "http://ferpi.dem.ru/"
       },
       {
          "university": "International Business School Kelajak ILMI",
          "website": "http://www.ibs.uz/"
       },
       {
          "university": "Kokand State Pedagogical Institute",
          "website": "http://www.qdpi.uz/"
       },
       {
          "university": "Management Development Institute of Singapore - Tashkent",
          "website": "http://www.mdis.uz/"
       },
       {
          "university": "National University of Uzbekistan",
          "website": "http://www.nuu.uz/"
       },
       {
          "university": "Nukus State Teachers Training Institute",
          "website": "http://www.ndpi.uz/"
       },
       {
          "university": "Samarkand State University",
          "website": "http://www.samdu.uz/"
       },
       {
          "university": "Tashkent Automobile and Roads Institute",
          "website": "http://www.tayi.uz/"
       },
       {
          "university": "Tashkent Institute of Irrigation and Melioration",
          "website": "http://www.tiim.uz/"
       },
       {
          "university": "Tashkent Islam University",
          "website": "http://www.tiu.uz/"
       },
       {
          "university": "Tashkent Medical Academy",
          "website": "http://www.tma.uz/"
       },
       {
          "university": "Tashkent Pharmaceutical Institute",
          "website": "http://www.pharmi.uz/"
       },
       {
          "university": "Tashkent School of Finance",
          "website": "http://www.tfi.uz/"
       },
       {
          "university": "Tashkent State Agrarian University",
          "website": "http://www.agrar.uz/"
       },
       {
          "university": "Tashkent State Technical University",
          "website": "http://www.tdtu.uz/"
       },
       {
          "university": "Tashkent State University of Economics",
          "website": "http://www.tdiu.uz/"
       },
       {
          "university": "Tashkent State University of Oriental Studies ",
          "website": "http://www.tashgiv.uz/"
       },
       {
          "university": "Tashkent University of Information Technologies",
          "website": "http://www.tuit.uz/"
       },
       {
          "university": "Turin Polytechnic University In Tashkent",
          "website": "http://www.polito.uz/"
       },
       {
          "university": "University of World Economy and Diplomacy",
          "website": "http://www.uwed.uz/"
       },
       {
          "university": "Uzbek State World Languages University",
          "website": "http://www.uzswlu.uz/"
       },
       {
          "university": "Westminster International University in Tashkent",
          "website": "http://www.wiut.uz/"
       }
    ],
    "VA": [
       {
          "university": "Athenaeum Pontificium Regina Apostolorum",
          "website": "http://www.upra.org/"
       },
       {
          "university": "Pontifcia Università Gregoriana",
          "website": "http://www.unigre.urbe.it/"
       },
       {
          "university": "Pontifcia Universitas a S.Thomas Aquinate in Urbe",
          "website": "http://www.pust.urbe.it/"
       },
       {
          "university": "Pontifcia Universitas Lateranensis",
          "website": "http://www.pul.it/"
       },
       {
          "university": "Pontifcia Università Urbaniana",
          "website": "http://www.urbaniana.edu/"
       },
       {
          "university": "Università Pontifcia Salesiana",
          "website": "http://www.ups.urbe.it/"
       }
    ],
    "VC": [
       {
          "university": "Trinity University School of Medicine",
          "website": "http://www.tusom.org/"
       }
    ],
    "VE": [
       {
          "university": "Universidad Bicentenaria de Aragua",
          "website": "http://www.uba.edu.ve/"
       },
       {
          "university": "Universidad Bolivariana de Venezuela",
          "website": "http://www.ubv.edu.ve/"
       },
       {
          "university": "Universidad Católica Andres Bello",
          "website": "http://www.ucab.edu.ve/"
       },
       {
          "university": "Universidad Católica Cecilio Acosta",
          "website": "http://www.unica.edu.ve/"
       },
       {
          "university": "Universidad Católica del Táchira",
          "website": "http://www.ucat.edu.ve/"
       },
       {
          "university": "Universidad Central de Venezuela",
          "website": "http://www.ucv.ve/"
       },
       {
          "university": "Universidad Centro Occidental Lisandro Alvarado",
          "website": "http://www.ucla.edu.ve/"
       },
       {
          "university": "Universidad de Carabobo",
          "website": "http://www.uc.edu.ve/"
       },
       {
          "university": "Universidad de Los Andes",
          "website": "http://www.ula.ve/"
       },
       {
          "university": "Universidad del Zulia",
          "website": "http://www.luz.ve/index2.html"
       },
       {
          "university": "Universidad de Oriente",
          "website": "http://www.udo.edu.ve/"
       },
       {
          "university": "Universidad Dr. Rafael Belloso Chacín",
          "website": "http://www.urbe.edu/"
       },
       {
          "university": "Universidad Fermin Toro",
          "website": "http://www.uft.edu.ve/"
       },
       {
          "university": "Universidad Gran Mariscal de Ayacucho",
          "website": "http://www.ugma.edu.ve/"
       },
       {
          "university": "Universidad José Antonio Páez",
          "website": "http://www.ujap.edu.ve/"
       },
       {
          "university": "Universidad José Maria Vargas",
          "website": "http://www.ujmv.edu/"
       },
       {
          "university": "Universidad Metropolitana",
          "website": "http://www.unimet.edu.ve/"
       },
       {
          "university": "Universidad Monteávila",
          "website": "http://www.universidad-monteavila.edu.ve/"
       },
       {
          "university": "Universidad Nacional Abierta",
          "website": "http://www.una.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental de Guayana",
          "website": "http://www.uneg.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental de la Fuerza Armada",
          "website": "http://www.unefa.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental de los Llanos Occidentales Ezequiel Zamora",
          "website": "http://www.unellez.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental del Táchira",
          "website": "http://www.unet.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental Francisco de Miranda",
          "website": "http://www.unefm.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental Politécnica Antonio José de Sucre",
          "website": "http://www.unexpo.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental Rafael Maria Baralt",
          "website": "http://www.unermb.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental Romulo Gallegos",
          "website": "http://www.unerg.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental Simón Rodriguez",
          "website": "http://www.unesr.edu.ve/"
       },
       {
          "university": "Universidad Nacional Experimental Sur del Lago Jesús Maria Semprum",
          "website": "http://www.unesur.edu.ve/"
       },
       {
          "university": "Universidad Nueva Esparta",
          "website": "http://www.une.edu.ve/"
       },
       {
          "university": "Universidad Pedagógica Experimental Libertador",
          "website": "http://www.upel.edu.ve/"
       },
       {
          "university": "Universidad Rafael Urdaneta",
          "website": "http://www.uru.edu/"
       },
       {
          "university": "Universidad Santa Maria Caracas",
          "website": "http://usm.trompo.com/"
       },
       {
          "university": "Universidad Simón Bolivar",
          "website": "http://www.usb.ve/"
       },
       {
          "university": "Universidad Tecnológica del Centro",
          "website": "http://www.unitec.edu.ve/"
       },
       {
          "university": "Universidad Valle del Momboy",
          "website": "http://www.uvm.edu.ve/"
       },
       {
          "university": "Universidad Yacambu",
          "website": "http://www.yacambu.edu.ve/"
       }
    ],
    "VI": [
       {
          "university": "University of the Virgin Islands",
          "website": "http://www.uvi.edu/"
       }
    ],
    "VN": [
       {
          "university": "Banking University of Ho Chi Minh City",
          "website": "http://www.buh.edu.vn/"
       },
       {
          "university": "Binh Duong University",
          "website": "http://www.bdu.edu.vn/"
       },
       {
          "university": "Can-Tho University",
          "website": "http://www.ctu.edu.vn/"
       },
       {
          "university": "Danang College Of Technology",
          "website": "http://www.dct.udn.vn/"
       },
       {
          "university": "Foreign Trade University",
          "website": "http://www.ftu.edu.vn/"
       },
       {
          "university": "FPT University",
          "website": "http://www.fpt.edu.vn/"
       },
       {
          "university": "Hai Duong University",
          "website": "http://www.uhd.edu.vn/"
       },
       {
          "university": "Hanoi Medical University",
          "website": "http://www.hmu.edu.vn/"
       },
       {
          "university": "Hanoi National Economics University",
          "website": "http://www.neu.edu.vn/"
       },
       {
          "university": "Hanoi Open University",
          "website": "http://www.hou.edu.vn/"
       },
       {
          "university": "Hanoi University of Architecture",
          "website": "http://www.hau.edu.vn/"
       },
       {
          "university": "Hanoi University of Civil Engineering",
          "website": "http://www.dhxd.edu.vn/"
       },
       {
          "university": "Hanoi University of Mining and Geology",
          "website": "http://www.humg.edu.vn/"
       },
       {
          "university": "Hanoi University of Science",
          "website": "http://www.hus.edu.vn/"
       },
       {
          "university": "Hanoi University of Science & Technology",
          "website": "http://www.hust.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City Open University",
          "website": "http://www.ou.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Agriculture and Forestry",
          "website": "http://www.hcmuaf.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Architecture",
          "website": "http://www.uah.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Economics",
          "website": "http://www.ueh.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Foreign Languages and Information Technology",
          "website": "http://www.huflit.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Law",
          "website": "http://www.hcmulaw.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Medicine and Pharmacy",
          "website": "http://www.yds.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Natural Sciences",
          "website": "http://www.hcmuns.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Pedagogics",
          "website": "http://www.hcmup.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Social Sciences and Humanities",
          "website": "http://www.hcmussh.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Technology",
          "website": "http://www.hcmut.edu.vn/"
       },
       {
          "university": "Ho Chi Minh City University of Transport",
          "website": "http://www.hcmutrans.edu.vn/"
       },
       {
          "university": "Hong Bang University International",
          "website": "http://www.hbu.edu.vn/"
       },
       {
          "university": "Hue University",
          "website": "http://www.hueuni.edu.vn/"
       },
       {
          "university": "Hue University of Agriculture and Forestry ",
          "website": "http://www.huaf.edu.vn/"
       },
       {
          "university": "Institute of Finance",
          "website": "http://www.hvtc.edu.vn/"
       },
       {
          "university": "Posts & Telecommunications Institute of Technology",
          "website": "http://www.ptit.edu.vn/"
       },
       {
          "university": "RMIT International University Vietnam",
          "website": "http://www.rmit.edu.vn/"
       },
       {
          "university": "Saigon University",
          "website": "http://www.sgu.edu.vn/"
       },
       {
          "university": "Tay Nguyen University",
          "website": "http://www.taynguyenuni.edu.vn/"
       },
       {
          "university": "Thainguyen University of Agriculture and Forestry",
          "website": "http://www.tuaf.edu.vn/"
       },
       {
          "university": "University of Da Lat",
          "website": "http://www.dlu.edu.vn/"
       },
       {
          "university": "University of Da Nang",
          "website": "http://www.udn.vn/"
       },
       {
          "university": "University of Technical Education Ho Chi Minh City",
          "website": "http://www.hcmute.edu.vn/"
       },
       {
          "university": "University of Transport and Communications",
          "website": "http://www.utc.edu.vn/"
       },
       {
          "university": "Vietnam Maritime University",
          "website": "http://www.vimaru.edu.vn/"
       },
       {
          "university": "Vietnam National University Hanoi",
          "website": "http://www.vnu.edu.vn/"
       },
       {
          "university": "Vietnam National University Ho Chi Minh City",
          "website": "http://www.vnuhcm.edu.vn/"
       },
       {
          "university": "Vietnam National University of Agriculture",
          "website": "http://www.vnua.edu.vn/"
       },
       {
          "university": "Water Resources University",
          "website": "http://www.hwru.edu.vn/"
       }
    ],
    "WS": [
       {
          "university": "National University of Samoa",
          "website": "http://www.nus.edu.ws/"
       },
       {
          "university": "University of the South Pacific School of Agriculture",
          "website": "http://www.usp.ac.fj/wwwsoa/"
       }
    ],
    "YE": [
       {
          "university": "Alahgaff University",
          "website": "http://www.ahgaff.edu/"
       },
       {
          "university": "Al-Eman University",
          "website": "http://www.jameataleman.org/"
       },
       {
          "university": "Al-Nasser University",
          "website": "http://www.al-edu.com/"
       },
       {
          "university": "Hadhramout University of Science and Technology ",
          "website": "http://www.hust.edu.ye/"
       },
       {
          "university": "Hodeidah University",
          "website": "http://www.hoduniv.net.ye/"
       },
       {
          "university": "Queen Arwa University",
          "website": "http://www.arwauniversity.org/"
       },
       {
          "university": "Saba University",
          "website": "http://www.sabauni.net/"
       },
       {
          "university": "Sana'a University",
          "website": "http://www.su.edu.ye/"
       },
       {
          "university": "Taiz University",
          "website": "http://www.taiz.edu.ye/"
       },
       {
          "university": "Thamar University",
          "website": "http://www.thuniv.net/"
       },
       {
          "university": "University of Aden",
          "website": "http://www.aden-univ.net/"
       },
       {
          "university": "University of Modern Sciences",
          "website": "http://www.ums-edu.com/"
       },
       {
          "university": "University of Science and Technology Sana'a",
          "website": "http://www.ust.edu/"
       },
       {
          "university": "Yemenia University",
          "website": "http://www.yuniv.net/"
       }
    ],
    "ZA": [
       {
          "university": "Cape Peninsula University of Technology",
          "website": "http://www.cput.ac.za/"
       },
       {
          "university": "Central University of Technology - Free State",
          "website": "http://www.cut.ac.za/"
       },
       {
          "university": "Durban Institute of Technology",
          "website": "http://www.dit.ac.za/"
       },
       {
          "university": "Nelson Mandela Metropolitan University",
          "website": "http://www.nmmu.ac.za/"
       },
       {
          "university": "Rhodes University",
          "website": "http://www.ru.ac.za/"
       },
       {
          "university": "Tshwane University of Technology",
          "website": "http://www.tut.ac.za/"
       },
       {
          "university": "University of Cape Town",
          "website": "http://www.uct.ac.za/"
       },
       {
          "university": "University of Fort Hare",
          "website": "http://www.ufh.ac.za/"
       },
       {
          "university": "University of Johannesburg",
          "website": "http://www.uj.ac.za/"
       },
       {
          "university": "University of KwaZulu-Natal",
          "website": "http://www.ukzn.ac.za/"
       },
       {
          "university": "University of Limpopo",
          "website": "http://www.ul.ac.za/"
       },
       {
          "university": "University of North West",
          "website": "http://www.uniwest.ac.za/"
       },
       {
          "university": "University of Pretoria",
          "website": "http://www.up.ac.za/"
       },
       {
          "university": "University of South Africa",
          "website": "http://www.unisa.ac.za/"
       },
       {
          "university": "University of Stellenbosch",
          "website": "http://www.sun.ac.za/"
       },
       {
          "university": "University of the Free State",
          "website": "http://www.uovs.ac.za/"
       },
       {
          "university": "University of the Western Cape",
          "website": "http://www.uwc.ac.za/"
       },
       {
          "university": "University of Venda",
          "website": "http://www.univen.ac.za/"
       },
       {
          "university": "University of Witwatersrand",
          "website": "http://www.wits.ac.za/"
       },
       {
          "university": "University of Zululand",
          "website": "http://www.uzulu.ac.za/"
       },
       {
          "university": "Vaal University of Technology",
          "website": "http://www.vut.ac.za/"
       },
       {
          "university": "Walter Sisulu University for Technology and Science",
          "website": "http://www.wsu.ac.za/"
       }
    ],
    "ZM": [
       {
          "university": "Cavendish University",
          "website": "http://www.cavendishza.org/"
       },
       {
          "university": "Copperbelt University",
          "website": "http://www.cbu.edu.zm/"
       },
       {
          "university": "Mulungushi University",
          "website": "http://www.mu.ac.zm/"
       },
       {
          "university": "Northrise University",
          "website": "http://www.northrise.org/"
       },
       {
          "university": "Rusangu University",
          "website": "http://www.rusangu-university.edu.zm/"
       },
       {
          "university": "University of Lusaka",
          "website": "http://www.unilus.ac.zm/"
       },
       {
          "university": "University of Zambia",
          "website": "http://www.unza.zm/"
       },
       {
          "university": "Zambian Open University",
          "website": "http://www.zaou.ac.zm/"
       }
    ],
    "ZW": [
       {
          "university": "Africa University",
          "website": "http://www.africau.edu/"
       },
       {
          "university": "Bindura University of Science Education",
          "website": "http://www.buse.ac.zw/"
       },
       {
          "university": "Catholic University in Zimbabwe",
          "website": "http://www.cuz.ac.zw/"
       },
       {
          "university": "Chinhoyi University of Technology",
          "website": "http://www.cut.ac.zw/"
       },
       {
          "university": "Great Zimbabwe University",
          "website": "http://www.gzu.ac.zw/"
       },
       {
          "university": "Harare Institute of Technology",
          "website": "http://www.hit.ac.zw/"
       },
       {
          "university": "Lupane State University",
          "website": "http://www.lsu.ac.zw/"
       },
       {
          "university": "Midlands State University",
          "website": "http://www.msu.ac.zw/"
       },
       {
          "university": "National University of Science and Technology Bulawayo",
          "website": "http://www.nust.ac.zw/"
       },
       {
          "university": "Reformed Church University",
          "website": "http://www.rcu.ac.zw/"
       },
       {
          "university": "Solusi University",
          "website": "http://www.solusi.ac.zw/"
       },
       {
          "university": "University of Zimbabwe",
          "website": "http://www.uz.ac.zw/"
       },
       {
          "university": "Women's University in Africa",
          "website": "http://www.wua.ac.zw/"
       },
       {
          "university": "Zimbabwe Ezekiel Guti University",
          "website": "http://www.zegu.ac.zw/"
       },
       {
          "university": "Zimbabwe Open University",
          "website": "http://www.zou.ac.zw/"
       }
    ]
 }

const sitemap_universities = {
   "US": [
       
      {
         "university": "Adams State College",
         "website": "http://www.adams.edu/"
      },
      {
         "university": "Adelphi University",
         "website": "http://www.adelphi.edu/"
      },
      {
         "university": "Alabama Agricultural and Mechanical University",
         "website": "http://www.aamu.edu/"
      },
      {
         "university": "Alabama State University",
         "website": "http://www.alasu.edu/"
      },
      {
         "university": "Albany State University",
         "website": "http://www.asurams.edu/"
      },
      {
         "university": "American University",
         "website": "http://www.american.edu/"
      },
      {
         "university": "Amherst College",
         "website": "http://www.amherst.edu/"
      },
      {
         "university": "Appalachian State University",
         "website": "http://www.appstate.edu/"
      },
      {
         "university": "Arizona State University",
         "website": "http://www.asu.edu/"
      },
      {
         "university": "Arizona State University - Downtown Phoenix Campus",
         "website": "https://campus.asu.edu/downtown"
      },
      {
         "university": "Arizona State University - Polytechnic Campus",
         "website": "https://campus.asu.edu/polytechnic"
      },
      {
         "university": "Arizona State University - Tempe Campus",
         "website": "https://campus.asu.edu/tempe"
      },
      {
         "university": "Arizona State University - West Campus",
         "website": "https://campus.asu.edu/west"
      },
      {
         "university": "Arkansas State University",
         "website": "http://www.astate.edu/"
      },
      {
         "university": "Arkansas State University - Beebe",
         "website": "http://www.asub.edu/"
      },
      {
         "university": "Arkansas State University - Mountain Home",
         "website": "http://www.asumh.edu/"
      },
      {
         "university": "Arkansas State University - Newport",
         "website": "http://www.asun.edu/"
      },
      {
         "university": "Ashland University",
         "website": "http://www.ashland.edu/"
      },
      {
         "university": "Auburn University",
         "website": "http://www.auburn.edu/"
      },
      {
         "university": "Auburn University at Montgomery",
         "website": "http://www.aum.edu/"
      },
      {
         "university": "Austin Community College",
         "website": "http://www.austin.cc.tx.us/"
      },
      {
         "university": "Ball State University",
         "website": "http://www.bsu.edu/"
      },
      {
         "university": "Bard College",
         "website": "http://www.bard.edu/"
      },
      {
         "university": "Barnard College",
         "website": "http://www.barnard.edu/"
      },
      {
         "university": "Baylor University",
         "website": "http://www.baylor.edu/"
      },
      {
         "university": "Benedict College",
         "website": "http://www.benedict.edu/"
      },
      {
         "university": "Berklee College of Music",
         "website": "http://www.berklee.edu/"
      },
      {
         "university": "Boise State University",
         "website": "http://www.boisestate.edu/"
      },
      {
         "university": "Boston College",
         "website": "http://www.bc.edu/"
      },
      {
         "university": "Boston University",
         "website": "http://www.bu.edu/"
      },
      {
         "university": "Bowdoin College",
         "website": "http://www.bowdoin.edu/"
      },
      {
         "university": "Bowie State University",
         "website": "http://www.bowiestate.edu/"
      },
      {
         "university": "Bowling Green State University",
         "website": "http://www.bgsu.edu/"
      },
      {
         "university": "Brandeis University",
         "website": "http://www.brandeis.edu/"
      },
      {
         "university": "Brigham Young University",
         "website": "http://www.byu.edu/"
      },
      {
         "university": "Brigham Young University Hawaii",
         "website": "http://www.byuh.edu/"
      },
      {
         "university": "Brown University",
         "website": "http://www.brown.edu/"
      },
      {
         "university": "Brunswick Community College",
         "website": "http://www.brunswickcc.edu/"
      },
      {
         "university": "Bucknell University",
         "website": "http://www.bucknell.edu/"
      },
      {
         "university": "Butler University",
         "website": "http://www.butler.edu/"
      },
      {
         "university": "California Coast University",
         "website": "http://www.calcoast.edu/"
      },
      {
         "university": "California College San Diego",
         "website": "http://www.cc-sd.edu/"
      },
      {
         "university": "California Institute of Technology",
         "website": "http://www.caltech.edu/"
      },
      {
         "university": "California Polytechnic State University",
         "website": "http://www.calpoly.edu/"
      },
      {
         "university": "California State Polytechnic University - Pomona",
         "website": "http://www.csupomona.edu/"
      },
      {
         "university": "California State University - Bakersfield",
         "website": "http://www.csubak.edu/"
      },
      {
         "university": "California State University - Channel Islands",
         "website": "http://www.csuci.edu/"
      },
      {
         "university": "California State University - Chico",
         "website": "http://www.csuchico.edu/"
      },
      {
         "university": "California State University - Dominguez Hills",
         "website": "http://www.csudh.edu/"
      },
      {
         "university": "California State University - Fresno",
         "website": "http://www.csufresno.edu/"
      },
      {
         "university": "California State University - Fullerton",
         "website": "http://www.fullerton.edu/"
      },
      {
         "university": "California State University - Hayward",
         "website": "http://www.csuhayward.edu/"
      },
      {
         "university": "California State University - Long Beach",
         "website": "http://www.csulb.edu/"
      },
      {
         "university": "California State University - Los Angeles",
         "website": "http://www.calstatela.edu/"
      },
      {
         "university": "California State University - Monterey Bay",
         "website": "http://www.monterey.edu/"
      },
      {
         "university": "California State University - Northridge",
         "website": "http://www.csun.edu/"
      },
      {
         "university": "California State University - Sacramento",
         "website": "http://www.csus.edu/"
      },
      {
         "university": "California State University - San Bernadino",
         "website": "http://www.csusb.edu/"
      },
      {
         "university": "California State University - San Marcos",
         "website": "http://www.csusm.edu/"
      },
      {
         "university": "California State University - Stanislaus",
         "website": "http://www.csustan.edu/"
      },
      {
         "university": "Cambridge College",
         "website": "http://www.cambridge.edu/"
      },
      {
         "university": "Capella University",
         "website": "http://www.capella.edu/"
      },
      {
         "university": "Carleton College",
         "website": "http://www.carleton.edu/"
      },
      {
         "university": "Carnegie Mellon University",
         "website": "http://www.cmu.edu/"
      },
      {
         "university": "Case Western Reserve University",
         "website": "http://www.cwru.edu/"
      },
      {
         "university": "Central Michigan University",
         "website": "http://www.cmich.edu/"
      },
      {
         "university": "Central Washington University",
         "website": "http://www.cwu.edu/"
      },
      {
         "university": "Chicago State University",
         "website": "http://www.csu.edu/"
      },
      {
         "university": "City University of New York - Bernard M. Baruch College",
         "website": "http://www.baruch.cuny.edu/"
      },
      {
         "university": "City University of New York - Brooklyn College",
         "website": "http://www.brooklyn.cuny.edu/"
      },
      {
         "university": "City University of New York - City College",
         "website": "http://www.ccny.cuny.edu/"
      },
      {
         "university": "City University of New York - College of Staten Island",
         "website": "http://www.csi.cuny.edu/"
      },
      {
         "university": "City University of New York",
         "website": "http://www.cuny.edu/"
      },
      {
         "university": "City University of New York - Hunter College",
         "website": "http://www.hunter.cuny.edu/"
      },
      {
         "university": "City University of New York - Lehman College",
         "website": "http://www.lehman.cuny.edu/"
      },
      {
         "university": "City University of New York - Medgar Evers College",
         "website": "http://www.mec.cuny.edu/"
      },
      {
         "university": "City University of New York - Queens College",
         "website": "http://www.qc.edu/"
      },
      {
         "university": "City University of New York - York College",
         "website": "http://www.york.cuny.edu/"
      },
      {
         "university": "Claremont McKenna College",
         "website": "http://www.mckenna.edu/"
      },
      {
         "university": "Clemson University",
         "website": "http://www.clemson.edu/"
      },
      {
         "university": "Cleveland State University",
         "website": "http://www.csuohio.edu/"
      },
      {
         "university": "Coastal Carolina University",
         "website": "http://www.coastal.edu/"
      },
      {
         "university": "Colgate University",
         "website": "http://www.colgate.edu/"
      },
      {
         "university": "Colorado Christian University",
         "website": "http://www.ccu.edu/"
      },
      {
         "university": "Colorado College",
         "website": "http://www.cc.colorado.edu/"
      },
      {
         "university": "Colorado School of Mines",
         "website": "http://www.mines.edu/"
      },
      {
         "university": "Colorado State University",
         "website": "http://www.colostate.edu/"
      },
      {
         "university": "Colorado State University-Pueblo",
         "website": "http://www.colostate-pueblo.edu/"
      },
      {
         "university": "Colorado Technical University",
         "website": "http://www.colotechu.edu/"
      },
      {
         "university": "Columbia University",
         "website": "http://www.columbia.edu/"
      },
      {
         "university": "Columbus State University",
         "website": "http://www.colstate.edu/"
      },
      {
         "university": "Columbus University",
         "website": "http://www.columbusu.com/"
      },
      {
         "university": "Community College of Denver",
         "website": "http://ccd.rightchoice.org/"
      },
      {
         "university": "Concord College",
         "website": "http://www.concord.edu/"
      },
      {
         "university": "Concordia College - Ann Arbor",
         "website": "http://www.ccaa.edu/"
      },
      {
         "university": "Concordia College - Bronxville",
         "website": "http://www.concordia-ny.edu/"
      },
      {
         "university": "Concordia College - Moorhead",
         "website": "http://www.cord.edu/"
      },
      {
         "university": "Concordia College - Selma",
         "website": "http://higher-ed.lcms.org/selma.htm"
      },
      {
         "university": "Concordia College - Seward",
         "website": "http://www.cune.edu/"
      },
      {
         "university": "Concordia College - St. Paul",
         "website": "http://www.csp.edu/"
      },
      {
         "university": "Concordia University - Austin",
         "website": "http://www.concordia.edu/"
      },
      {
         "university": "Concordia University - Irvine",
         "website": "http://www.cui.edu/"
      },
      {
         "university": "Concordia University - Mequon",
         "website": "http://www.cuw.edu/"
      },
      {
         "university": "Concordia University - Portland",
         "website": "http://www.cu-portland.edu/"
      },
      {
         "university": "Concordia University - River Forest",
         "website": "http://www.curf.edu/"
      },
      {
         "university": "Connecticut College",
         "website": "http://www.conncoll.edu/"
      },
      {
         "university": "Cornell University",
         "website": "http://www.cornell.edu/"
      },
      {
         "university": "Creighton University",
         "website": "http://www.creighton.edu/"
      },
      {
         "university": "Dartmouth College",
         "website": "http://www.dartmouth.edu/"
      },
      {
         "university": "DePaul University",
         "website": "http://www.depaul.edu/"
      },
      {
         "university": "Drexel University",
         "website": "http://www.drexel.edu/"
      },
      {
         "university": "Duke University",
         "website": "http://www.duke.edu/"
      },
      {
         "university": "Duquesne University",
         "website": "http://www.duq.edu/"
      },
      {
         "university": "East Carolina University",
         "website": "http://www.ecu.edu/"
      },
      {
         "university": "Emory University",
         "website": "http://www.emory.edu/"
      },
      {
         "university": "Fayetteville State University",
         "website": "http://www.uncfsu.edu/"
      },
      {
         "university": "Florida Agricultural and Mechanical University",
         "website": "http://www.famu.edu/"
      },
      {
         "university": "Florida Atlantic University",
         "website": "http://www.fau.edu/"
      },
      {
         "university": "Florida International University",
         "website": "http://www.fiu.edu/"
      },
      {
         "university": "Florida State University",
         "website": "http://www.fsu.edu/"
      },
      {
         "university": "Fordham University",
         "website": "http://www.fordham.edu/"
      },
      {
         "university": "Franklin and Marshall College",
         "website": "http://www.fandm.edu/"
      },
      {
         "university": "George Mason University",
         "website": "http://www.gmu.edu/"
      },
      {
         "university": "Georgetown University",
         "website": "http://www.georgetown.edu/"
      },
      {
         "university": "George Washington University",
         "website": "http://www.gwu.edu/"
      },
      {
         "university": "Georgia Institute of Technology",
         "website": "http://www.gatech.edu/"
      },
      {
         "university": "Georgia State University",
         "website": "http://www.gsu.edu/"
      },
      {
         "university": "Gonzaga University",
         "website": "http://www.gonzaga.edu/"
      },
      {
         "university": "Grand Valley State University",
         "website": "http://www.gvsu.edu/"
      },
      {
         "university": "Harvard University",
         "website": "http://www.harvard.edu/"
      },
      {
         "university": "Harvey Mudd College",
         "website": "http://www.hmc.edu/"
      },
      {
         "university": "Hastings College",
         "website": "http://www.hastings.edu/"
      },
      {
         "university": "Haverford College",
         "website": "http://www.haverford.edu/"
      },
      {
         "university": "Hawaii Pacific University",
         "website": "http://www.hpu.edu/"
      },
      {
         "university": "Hofstra University",
         "website": "http://www.hofstra.edu/"
      },
      {
         "university": "Howard University",
         "website": "http://www.howard.edu/"
      },
      {
         "university": "Humboldt State University",
         "website": "http://www.humboldt.edu/"
      },
      {
         "university": "Idaho State University",
         "website": "http://www.isu.edu/"
      },
      {
         "university": "Illinois State University",
         "website": "http://www.ilstu.edu/"
      },
      {
         "university": "Indiana State University",
         "website": "http://www.indstate.edu/"
      },
      {
         "university": "Indiana University at Bloomington",
         "website": "http://www.iub.edu/"
      },
      {
         "university": "Indiana University at Kokomo",
         "website": "http://www.iuk.edu/"
      },
      {
         "university": "Indiana University at South Bend",
         "website": "http://www.iusb.edu/"
      },
      {
         "university": "Indiana University - East",
         "website": "http://www.iue.indiana.edu/"
      },
      {
         "university": "Indiana University - Northwest",
         "website": "http://www.iun.indiana.edu/"
      },
      {
         "university": "Indiana University of Pennsylvania",
         "website": "http://www.iup.edu/"
      },
      {
         "university": "Indiana University-Purdue University at Columbus",
         "website": "http://www.columbus.iupui.edu/"
      },
      {
         "university": "Indiana University-Purdue University at Fort Wayne",
         "website": "http://www.ipfw.edu/"
      },
      {
         "university": "Indiana University-Purdue University at Indianapolis",
         "website": "http://www.iupui.edu/"
      },
      {
         "university": "Indiana University - Southeast",
         "website": "http://www.ius.indiana.edu/"
      },
      {
         "university": "Indiana University",
         "website": "http://www.indiana.edu/"
      },
      {
         "university": "Ithaca College",
         "website": "http://www.ithaca.edu/"
      },
      {
         "university": "James Madison University",
         "website": "http://www.jmu.edu/"
      },
      {
         "university": "Johns Hopkins University",
         "website": "http://www.jhu.edu/"
      },
      {
         "university": "Johnson & Wales University",
         "website": "http://www.jwu.edu/"
      },
      {
         "university": "Kansas State University",
         "website": "http://www.ksu.edu/"
      },
      {
         "university": "Kent State University",
         "website": "http://www.kent.edu/"
      },
      {
         "university": "Kentucky State University",
         "website": "http://www.kysu.edu/"
      },
      {
         "university": "Lehigh Univervsity",
         "website": "http://www.lehigh.edu/"
      },
      {
         "university": "Louisiana Tech University",
         "website": "http://www.latech.edu/"
      },
      {
         "university": "Loyola College in Maryland",
         "website": "http://www.loyola.edu/"
      },
      {
         "university": "Loyola Marymount University",
         "website": "http://www.lmu.edu/"
      },
      {
         "university": "Loyola University New Orleans",
         "website": "http://www.loyno.edu/"
      },
      {
         "university": "Loyola University of Chicago",
         "website": "http://www.luc.edu/"
      },
      {
         "university": "Massachusetts Institute of Technology",
         "website": "http://www.mit.edu/"
      },
      {
         "university": "Metropolitan State University",
         "website": "http://www.metro.msus.edu/"
      },
      {
         "university": "Miami University of Ohio",
         "website": "http://www.muohio.edu/"
      },
      {
         "university": "Miami University of Ohio - Hamilton",
         "website": "http://www.ham.muohio.edu/"
      },
      {
         "university": "Miami University of Ohio - Middletown",
         "website": "http://www.mid.muohio.edu/"
      },
      {
         "university": "Michigan State University",
         "website": "http://www.msu.edu/"
      },
      {
         "university": "Middlebury College",
         "website": "http://www.middlebury.edu/"
      },
      {
         "university": "Mississippi State University",
         "website": "http://www.msstate.edu/"
      },
      {
         "university": "Monmouth University",
         "website": "http://www.monmouth.edu/"
      },
      {
         "university": "Montana State University",
         "website": "http://www.montana.edu/"
      },
      {
         "university": "Montana State University - Billings",
         "website": "http://www.msubillings.edu/"
      },
      {
         "university": "Montana State University - Northern",
         "website": "http://www.msun.edu/"
      },
      {
         "university": "Morehouse College",
         "website": "http://www.morehouse.edu/"
      },
      {
         "university": "New Jersey Institute of Technology",
         "website": "http://www.njit.edu/"
      },
      {
         "university": "New Mexico State University",
         "website": "http://www.nmsu.edu/"
      },
      {
         "university": "New York Institute of Technology",
         "website": "http://www.nyit.edu/"
      },
      {
         "university": "New York University",
         "website": "http://www.nyu.edu/"
      },
      {
         "university": "North Carolina Agricultural and Technical State University",
         "website": "http://www.ncat.edu/"
      },
      {
         "university": "North Carolina Central University",
         "website": "http://www.nccu.edu/"
      },
      {
         "university": "North Carolina State University",
         "website": "http://www.ncsu.edu/"
      },
      {
         "university": "Northcentral University",
         "website": "http://www.ncu.edu/"
      },
      {
         "university": "North Dakota State University",
         "website": "http://www.ndsu.nodak.edu/"
      },
      {
         "university": "Northeastern University",
         "website": "http://www.neu.edu/"
      },
      {
         "university": "Northern Arizona University",
         "website": "http://www.nau.edu/"
      },
      {
         "university": "Northern Virginia Community College",
         "website": "http://www.nv.cc.va.us/"
      },
      {
         "university": "Northwestern University",
         "website": "http://www.nwu.edu/"
      },
      {
         "university": "Northwest Missouri State University",
         "website": "http://www.nwmissouri.edu/"
      },
      {
         "university": "Northwest Nazarene University",
         "website": "http://www.nnu.edu/"
      },
      {
         "university": "Northwest University",
         "website": "http://www.northwestu.edu/"
      },
      {
         "university": "Oberlin College",
         "website": "http://www.oberlin.edu/"
      },
      {
         "university": "Occidental College",
         "website": "http://www.oxy.edu/"
      },
      {
         "university": "Ohio State University",
         "website": "http://www.ohio-state.edu/"
      },
      {
         "university": "Ohio State University - Lima",
         "website": "http://www.lima.ohio-state.edu/"
      },
      {
         "university": "Ohio State University - Mansfield",
         "website": "http://www.mansfield.ohio-state.edu/"
      },
      {
         "university": "Ohio State University - Marion",
         "website": "http://www.marion.ohio-state.edu/"
      },
      {
         "university": "Ohio State University - Newark",
         "website": "http://www.newark.ohio-state.edu/"
      },
      {
         "university": "Ohio University",
         "website": "http://www.ohiou.edu/"
      },
      {
         "university": "Ohio University - Chillicothe",
         "website": "http://www.ohiou.edu/chillicothe/"
      },
      {
         "university": "Ohio University - Eastern",
         "website": "http://www.eastern.ohiou.edu/"
      },
      {
         "university": "Ohio University - Lancaster",
         "website": "http://www.lancaster.ohiou.edu/"
      },
      {
         "university": "Ohio University - Southern",
         "website": "http://www.ohiou.edu/southern/"
      },
      {
         "university": "Ohio University - Zanesville",
         "website": "http://www.zanesville.ohiou.edu/"
      },
      {
         "university": "Oklahoma State University",
         "website": "http://www.okstate.edu/"
      },
      {
         "university": "Oregon Institute of Technology",
         "website": "http://www.oit.edu/"
      },
      {
         "university": "Oregon State University",
         "website": "http://www.orst.edu/"
      },
      {
         "university": "Pace University",
         "website": "http://www.pace.edu/"
      },
      {
         "university": "Pacific University",
         "website": "http://www.pacificu.edu/"
      },
      {
         "university": "Pennsylvania State University",
         "website": "http://www.psu.edu/"
      },
      {
         "university": "Pennsylvania State University - Abington",
         "website": "http://www.abington.psu.edu/"
      },
      {
         "university": "Pennsylvania State University - Altoona",
         "website": "http://www.aa.psu.edu/"
      },
      {
         "university": "Pennsylvania State University at Erie - Behrend College",
         "website": "http://www.pserie.psu.edu/"
      },
      {
         "university": "Pennsylvania State University at Harrisburg - The Capital College",
         "website": "http://www.hbg.psu.edu/"
      },
      {
         "university": "Pennsylvania State University - Berks-Lehigh Valley College",
         "website": "http://www.bk.psu.edu/"
      },
      {
         "university": "Pennsylvania State University Delaware County",
         "website": "http://www.de.psu.edu/"
      },
      {
         "university": "Pennsylvania State University Great Valley",
         "website": "http://www.gv.psu.edu/"
      },
      {
         "university": "Pennsylvania State University - Lehigh Valley",
         "website": "http://www.an.psu.edu/"
      },
      {
         "university": "Pennsylvania State University - Schuylkill",
         "website": "http://www.sl.psu.edu/"
      },
      {
         "university": "Pepperdine University",
         "website": "http://www.pepperdine.edu/"
      },
      {
         "university": "Pittsburg State University",
         "website": "http://www.pittstate.edu/"
      },
      {
         "university": "Portland Community College",
         "website": "http://www.pcc.edu/"
      },
      {
         "university": "Portland State University",
         "website": "http://www.pdx.edu/"
      },
      {
         "university": "Princeton University",
         "website": "http://www.princeton.edu/"
      },
      {
         "university": "Purdue University",
         "website": "http://www.purdue.edu/"
      },
      {
         "university": "Reed College",
         "website": "http://www.reed.edu/"
      },
      {
         "university": "Regent University",
         "website": "http://www.regent.edu/"
      },
      {
         "university": "Regis University",
         "website": "http://www.regis.edu/"
      },
      {
         "university": "Rensselaer Polytechnic Institute",
         "website": "http://www.rpi.edu/"
      },
      {
         "university": "Rice University",
         "website": "http://www.rice.edu/"
      },
      {
         "university": "Rochester Institute of Technology",
         "website": "http://www.rit.edu/"
      },
      {
         "university": "Rose-Hulman Institute of Technology",
         "website": "http://www.rose-hulman.edu/"
      },
      {
         "university": "Rutgers",
         "website": "http://www.rutgers.edu/"
      },
      {
         "university": "Rutgers - Camden",
         "website": "http://camden-www.rutgers.edu/"
      },
      {
         "university": "Rutgers - Newark",
         "website": "http://rutgers-newark.rutgers.edu/"
      },
      {
         "university": "San Diego State University",
         "website": "http://www.sdsu.edu/"
      },
      {
         "university": "San Francisco State University",
         "website": "http://www.sfsu.edu/"
      },
      {
         "university": "Santa Clara University",
         "website": "http://www.scu.edu/"
      },
      {
         "university": "Scripps College",
         "website": "http://www.scrippscol.edu/"
      },
      {
         "university": "Seattle Pacific University",
         "website": "http://www.spu.edu/"
      },
      {
         "university": "Seattle University",
         "website": "http://www.seattleu.edu/"
      },
      {
         "university": "Seton Hall University",
         "website": "http://www.shu.edu/"
      },
      {
         "university": "Sonoma State University",
         "website": "http://www.sonoma.edu/"
      },
      {
         "university": "South Carolina State University",
         "website": "http://www.scsu.edu/"
      },
      {
         "university": "South Dakota School of Mines and Technology",
         "website": "http://www.sdsmt.edu/"
      },
      {
         "university": "South Dakota State University",
         "website": "http://www.sdstate.edu/"
      },
      {
         "university": "Southern Methodist University",
         "website": "http://www.smu.edu/"
      },
      {
         "university": "Southern Oregon University",
         "website": "http://www.sou.edu/"
      },
      {
         "university": "Spelman College",
         "website": "http://www.spelman.edu/"
      },
      {
         "university": "Stanford University",
         "website": "http://www.stanford.edu/"
      },
      {
         "university": "State University of New York at Albany",
         "website": "http://www.albany.edu/"
      },
      {
         "university": "State University of New York at Binghamton",
         "website": "http://www.binghamton.edu/"
      },
      {
         "university": "State University of New York at Buffalo",
         "website": "http://www.buffalo.edu/"
      },
      {
         "university": "State University of New York at New Paltz",
         "website": "http://www.newpaltz.edu/"
      },
      {
         "university": "State University of New York at Oswego",
         "website": "http://www.oswego.edu/"
      },
      {
         "university": "State University of New York at Stony Brook",
         "website": "http://www.sunysb.edu/"
      },
      {
         "university": "State University of New York College at Brockport",
         "website": "http://www.brockport.edu/"
      },
      {
         "university": "State University of New York College at Cortland",
         "website": "http://www.cortland.edu/"
      },
      {
         "university": "State University of New York College at Fredonia",
         "website": "http://www.fredonia.edu/"
      },
      {
         "university": "State University of New York College at Geneseo",
         "website": "http://www.geneseo.edu/"
      },
      {
         "university": "State University of New York College at Old Westbury",
         "website": "http://www.oldwestbury.edu/"
      },
      {
         "university": "State University of New York College at Oneonta",
         "website": "http://www.oneonta.edu/"
      },
      {
         "university": "State University of New York College at Plattsburgh",
         "website": "http://www.plattsburgh.edu/"
      },
      {
         "university": "State University of New York College at Potsdam",
         "website": "http://www.potsdam.edu/"
      },
      {
         "university": "State University of New York College at Purchase",
         "website": "http://www.purchase.edu/"
      },
      {
         "university": "State University of New York College of Agriculture and Technology at Cobleskill",
         "website": "http://www.cobleskill.edu/"
      },
      {
         "university": "State University of New York College of Technology at Alfred",
         "website": "http://www.alfredtech.edu/"
      },
      {
         "university": "State University of New York College of Technology at Farmingdale",
         "website": "http://www.farmingdale.edu/"
      },
      {
         "university": "State University of New York Empire State College",
         "website": "http://www.esc.edu/"
      },
      {
         "university": "State University of New York (SUNY)",
         "website": "http://www.sunycentral.edu/"
      },
      {
         "university": "St. Edwards University",
         "website": "http://www.stedwards.edu/"
      },
      {
         "university": "Suffolk University",
         "website": "http://www.suffolk.edu/"
      },
      {
         "university": "Swarthmore College",
         "website": "http://www.swarthmore.edu/"
      },
      {
         "university": "Syracuse University",
         "website": "http://www.syr.edu/"
      },
      {
         "university": "Temple University",
         "website": "http://www.temple.edu/"
      },
      {
         "university": "Tennessee State University",
         "website": "http://www.tnstate.edu/"
      },
      {
         "university": "Texas A&M University",
         "website": "http://www.tamu.edu/"
      },
      {
         "university": "Texas A&M University - Commerce",
         "website": "http://www.tamu-commerce.edu/"
      },
      {
         "university": "Texas A&M University - Corpus Christi",
         "website": "http://www.tamucc.edu/"
      },
      {
         "university": "Texas A&M University - Galveston",
         "website": "http://www.tamug.tamu.edu/"
      },
      {
         "university": "Texas A&M University - Kingsville",
         "website": "http://www.tamuk.edu/"
      },
      {
         "university": "Texas A&M University - Texarkana",
         "website": "http://www.tamut.edu/"
      },
      {
         "university": "Texas Christian University",
         "website": "http://www.tcu.edu/"
      },
      {
         "university": "The Johns Hopkins University",
         "website": "http://www.jhu.edu/"
      },
      {
         "university": "The New School",
         "website": "http://www.newschool.edu/"
      },
      {
         "university": "The Tulane University of New Orleans",
         "website": "http://www.tulane.edu/"
      },
      {
         "university": "Tufts University",
         "website": "http://www.tufts.edu/"
      },
      {
         "university": "Tuskegee University",
         "website": "http://www.tusk.edu/"
      },
      {
         "university": "University of Akron",
         "website": "http://www.uakron.edu/"
      },
      {
         "university": "University of Alabama - Birmingham",
         "website": "http://www.uab.edu/"
      },
      {
         "university": "University of Alabama - Huntsville",
         "website": "http://www.uah.edu/"
      },
      {
         "university": "University of Alabama - Tuscaloosa",
         "website": "http://www.ua.edu/"
      },
      {
         "university": "University of Alanta",
         "website": "http://www.uofa.edu/"
      },
      {
         "university": "University of Alaska - Anchorage",
         "website": "http://www.uaa.alaska.edu/"
      },
      {
         "university": "University of Alaska - Fairbanks",
         "website": "http://www.uaf.edu/"
      },
      {
         "university": "University of Alaska - Southeast",
         "website": "http://www.uas.alaska.edu/"
      },
      {
         "university": "University of Alaska",
         "website": "http://www.alaska.edu/"
      },
      {
         "university": "University of Arizona",
         "website": "http://www.arizona.edu/"
      },
      {
         "university": "University of Arkansas at Fayetteville",
         "website": "http://www.uark.edu/"
      },
      {
         "university": "University of Arkansas at Little Rock",
         "website": "http://www.ualr.edu/"
      },
      {
         "university": "University of Arkansas at Monticello",
         "website": "http://www.uamont.edu/"
      },
      {
         "university": "University of Arkansas at Pine Bluff",
         "website": "http://www.uapb.edu/"
      },
      {
         "university": "University of Arkansas",
         "website": "http://www.uasys.edu/"
      },
      {
         "university": "University of Baltimore",
         "website": "http://www.ubalt.edu/"
      },
      {
         "university": "University of Bridgeport",
         "website": "http://www.bridgeport.edu/"
      },
      {
         "university": "University of California - Berkeley",
         "website": "http://www.berkeley.edu/"
      },
      {
         "university": "University of California - Davis",
         "website": "http://www.ucdavis.edu/"
      },
      {
         "university": "University of California - Irvine",
         "website": "http://www.uci.edu/"
      },
      {
         "university": "University of California - Los Angeles",
         "website": "http://www.ucla.edu/"
      },
      {
         "university": "University of California - Merced",
         "website": "http://www.ucmerced.edu/"
      },
      {
         "university": "University of California - Oakland",
         "website": "http://www.ucop.edu/"
      },
      {
         "university": "University of California - Riverside",
         "website": "http://www.ucr.edu/"
      },
      {
         "university": "University of California - San Diego",
         "website": "http://www.ucsd.edu/"
      },
      {
         "university": "University of California - San Francisco",
         "website": "http://www.ucsf.edu/"
      },
      {
         "university": "University of California - Santa Barbara",
         "website": "http://www.ucsb.edu/"
      },
      {
         "university": "University of California - Santa Cruz",
         "website": "http://www.ucsc.edu/"
      },
      {
         "university": "University of California",
         "website": "http://www.universityofcalifornia.edu/"
      },
      {
         "university": "University of Central Arkansas",
         "website": "http://www.uca.edu/"
      },
      {
         "university": "University of Central Florida",
         "website": "http://www.ucf.edu/"
      },
      {
         "university": "University of Central Missouri",
         "website": "http://www.ucmo.edu/"
      },
      {
         "university": "University of Central Oklahoma",
         "website": "http://www.ucok.edu/"
      },
      {
         "university": "University of Central Texas",
         "website": "http://www.vvm.com/uct/"
      },
      {
         "university": "University of Charleston",
         "website": "http://www.uchaswv.edu/"
      },
      {
         "university": "University of Charleston South Carolina",
         "website": "http://univchas.cofc.edu/"
      },
      {
         "university": "University of Chicago",
         "website": "http://www.uchicago.edu/"
      },
      {
         "university": "University of Cincinnati",
         "website": "http://www.uc.edu/"
      },
      {
         "university": "University of Colorado at Boulder",
         "website": "http://www.colorado.edu/"
      },
      {
         "university": "University of Colorado at Colorado Springs",
         "website": "http://www.uccs.edu/"
      },
      {
         "university": "University of Colorado at Denver",
         "website": "http://www.cudenver.edu/"
      },
      {
         "university": "University of Connecticut",
         "website": "http://www.uconn.edu/"
      },
      {
         "university": "University of Connecticut at Avery Point",
         "website": "http://www.averypoint.uconn.edu/"
      },
      {
         "university": "University of Connecticut at Hartford",
         "website": "http://vm.uconn.edu/~wwwhtfd/ugrad/"
      },
      {
         "university": "University of Connecticut at Stamford",
         "website": "http://www.stamford.uconn.edu/"
      },
      {
         "university": "University of Connecticut at Waterbury",
         "website": "http://www.waterbury.uconn.edu/"
      },
      {
         "university": "University of Dallas",
         "website": "http://www.udallas.edu/"
      },
      {
         "university": "University of Dayton",
         "website": "http://www.udayton.edu/"
      },
      {
         "university": "University of Delaware",
         "website": "http://www.udel.edu/"
      },
      {
         "university": "University of Denver",
         "website": "http://www.du.edu/"
      },
      {
         "university": "University of Detroit Mercy",
         "website": "http://www.udmercy.edu/"
      },
      {
         "university": "University of Dubuque",
         "website": "http://www.dbq.edu/"
      },
      {
         "university": "University of Evansville",
         "website": "http://www.evansville.edu/"
      },
      {
         "university": "University of Findlay",
         "website": "http://www.findlay.edu/"
      },
      {
         "university": "University of Florida",
         "website": "http://www.ufl.edu/"
      },
      {
         "university": "University of Georgia",
         "website": "http://www.uga.edu/"
      },
      {
         "university": "University of Great Falls",
         "website": "http://www.ugf.edu/"
      },
      {
         "university": "University of Hartford",
         "website": "http://www.hartford.edu/"
      },
      {
         "university": "University of Hawaii - Hilo",
         "website": "http://www.uhh.hawaii.edu/"
      },
      {
         "university": "University of Hawaii - Manoa",
         "website": "http://www.uhm.hawaii.edu/"
      },
      {
         "university": "University Of Hawaii",
         "website": "http://www.hawaii.edu/"
      },
      {
         "university": "University of Hawaii - West Oahu",
         "website": "http://www.uhwo.hawaii.edu/"
      },
      {
         "university": "University of Houston",
         "website": "http://www.uh.edu/"
      },
      {
         "university": "University of Houston - Clear Lake",
         "website": "http://www.cl.uh.edu/"
      },
      {
         "university": "University of Houston - Downtown",
         "website": "http://www.dt.uh.edu/"
      },
      {
         "university": "University of Houston - Victoria",
         "website": "http://www.vic.uh.edu/"
      },
      {
         "university": "University of Idaho",
         "website": "http://www.uidaho.edu/"
      },
      {
         "university": "University of Illinois",
         "website": "http://www.uillinois.edu/"
      },
      {
         "university": "University of Illinois at Chicago",
         "website": "http://www.uic.edu/"
      },
      {
         "university": "University of Illinois at Springfield",
         "website": "http://www.uis.edu/"
      },
      {
         "university": "University of Illinois at Urbana-Champaign",
         "website": "http://www.uiuc.edu/"
      },
      {
         "university": "University of Indianapolis",
         "website": "http://www.uindy.edu/"
      },
      {
         "university": "University of Iowa",
         "website": "http://www.uiowa.edu/"
      },
      {
         "university": "University of Kansas",
         "website": "http://www.ku.edu/"
      },
      {
         "university": "University of Kentucky",
         "website": "http://www.uky.edu/"
      },
      {
         "university": "University of La Verne",
         "website": "http://www.ulaverne.edu/"
      },
      {
         "university": "University of Louisiana at Lafayette",
         "website": "http://www.louisiana.edu/"
      },
      {
         "university": "University of Louisiana at Monroe",
         "website": "http://www.ulm.edu/"
      },
      {
         "university": "University of Louisville",
         "website": "http://www.louisville.edu/"
      },
      {
         "university": "University of Maine - Augusta",
         "website": "http://www.uma.maine.edu/"
      },
      {
         "university": "University of Maine - Farmington",
         "website": "http://www.umf.maine.edu/"
      },
      {
         "university": "University of Maine - Fort Kent",
         "website": "http://www.umfk.maine.edu/"
      },
      {
         "university": "University of Maine - Machias",
         "website": "http://www.umm.maine.edu/"
      },
      {
         "university": "University of Maine - Orono",
         "website": "http://www.umaine.edu/"
      },
      {
         "university": "University of Maine - Presque Isle",
         "website": "http://www.umpi.maine.edu/"
      },
      {
         "university": "University of Maine",
         "website": "http://www.maine.edu/"
      },
      {
         "university": "University of Management & Technology",
         "website": "http://www.umtweb.edu/"
      },
      {
         "university": "University of Mary",
         "website": "http://www.umary.edu/"
      },
      {
         "university": "University of Mary Hardin-Baylor",
         "website": "http://www.umhb.edu/"
      },
      {
         "university": "University of Maryland at Baltimore",
         "website": "http://www.umbc.edu/"
      },
      {
         "university": "University of Maryland at College Park",
         "website": "http://www.umd.edu/"
      },
      {
         "university": "University of Maryland Baltimore County",
         "website": "http://www.umbc.edu/"
      },
      {
         "university": "University of Maryland Eastern Shore",
         "website": "http://www.umes.edu/"
      },
      {
         "university": "University of Maryland",
         "website": "http://www.ums.edu/"
      },
      {
         "university": "University of Maryland University College",
         "website": "http://www.umuc.edu/"
      },
      {
         "university": "University of Massachusetts at Amherst",
         "website": "http://www.umass.edu/"
      },
      {
         "university": "University of Massachusetts at Boston",
         "website": "http://www.umb.edu/"
      },
      {
         "university": "University of Massachusetts at Dartmouth",
         "website": "http://www.umassd.edu/"
      },
      {
         "university": "University of Massachusetts at Lowell",
         "website": "http://www.uml.edu/"
      },
      {
         "university": "University of Massachusetts",
         "website": "http://www.massachusetts.edu/"
      },
      {
         "university": "University of Memphis",
         "website": "http://www.memphis.edu/"
      },
      {
         "university": "University of Miami",
         "website": "http://www.miami.edu/"
      },
      {
         "university": "University of Michigan - Ann Arbor",
         "website": "http://www.umich.edu/"
      },
      {
         "university": "University of Michigan - Dearborn",
         "website": "http://www.umd.umich.edu/"
      },
      {
         "university": "University of Michigan - Flint",
         "website": "http://www.flint.umich.edu/"
      },
      {
         "university": "University of Minnesota - Crookston",
         "website": "http://www.crk.umn.edu/"
      },
      {
         "university": "University of Minnesota - Duluth",
         "website": "http://www.d.umn.edu/"
      },
      {
         "university": "University of Minnesota - Morris",
         "website": "http://www.mrs.umn.edu/"
      },
      {
         "university": "University of Minnesota",
         "website": "http://www1.umn.edu/twincities/"
      },
      {
         "university": "University of Mississippi",
         "website": "http://www.olemiss.edu/"
      },
      {
         "university": "University of Missouri - Columbia",
         "website": "http://www.missouri.edu/"
      },
      {
         "university": "University of Missouri - Kansas City",
         "website": "http://www.umkc.edu/"
      },
      {
         "university": "University of Missouri - Saint Louis",
         "website": "http://www.umsl.edu/"
      },
      {
         "university": "University of Mobile",
         "website": "http://www.umobile.edu/"
      },
      {
         "university": "University of Montana",
         "website": "http://www.umt.edu/"
      },
      {
         "university": "University of Montana Western",
         "website": "http://www.umwestern.edu/"
      },
      {
         "university": "University of Montevallo",
         "website": "http://www.montevallo.edu/"
      },
      {
         "university": "University of Nebraska - Kearney",
         "website": "http://www.unk.edu/"
      },
      {
         "university": "University of Nebraska - Lincoln",
         "website": "http://www.unl.edu/"
      },
      {
         "university": "University of Nebraska - Omaha",
         "website": "http://www.unomaha.edu/"
      },
      {
         "university": "University of Nebraska",
         "website": "http://www.nebraska.edu/"
      },
      {
         "university": "University of Nevada - Las Vegas",
         "website": "http://www.unlv.edu/"
      },
      {
         "university": "University of Nevada - Reno",
         "website": "http://www.unr.edu/"
      },
      {
         "university": "University of New England",
         "website": "http://www.une.edu/"
      },
      {
         "university": "University of New England - Westbrook College Campus",
         "website": "http://www.une.edu/wcdirctn.html"
      },
      {
         "university": "University of New Hampshire",
         "website": "http://www.unh.edu/"
      },
      {
         "university": "University of New Hampshire - Manchester",
         "website": "http://www.unh.edu/unhm/"
      },
      {
         "university": "University of New Haven",
         "website": "http://www.newhaven.edu/"
      },
      {
         "university": "University of New Mexico",
         "website": "http://www.unm.edu/"
      },
      {
         "university": "University of New Orleans",
         "website": "http://www.uno.edu/"
      },
      {
         "university": "University of North Alabama",
         "website": "http://www.una.edu/"
      },
      {
         "university": "University of North America",
         "website": "http://www.universityofnorthamerica.org/"
      },
      {
         "university": "University of North Carolina at Asheville",
         "website": "http://www.unca.edu/"
      },
      {
         "university": "University of North Carolina at Chapel Hill",
         "website": "http://www.unc.edu/"
      },
      {
         "university": "University of North Carolina at Charlotte",
         "website": "http://www.uncc.edu/"
      },
      {
         "university": "University of North Carolina at Greensboro",
         "website": "http://www.uncg.edu/"
      },
      {
         "university": "University of North Carolina at Pembroke",
         "website": "http://www.uncp.edu/"
      },
      {
         "university": "University of North Carolina at Wilmington",
         "website": "http://www.uncwil.edu/"
      },
      {
         "university": "University of North Dakota",
         "website": "http://www.und.nodak.edu/"
      },
      {
         "university": "University of Northern Colorado",
         "website": "http://www.univnorthco.edu/"
      },
      {
         "university": "University of Northern Iowa",
         "website": "http://www.uni.edu/"
      },
      {
         "university": "University of Northern Virginia",
         "website": "http://www.unva.edu/"
      },
      {
         "university": "University of Northern Washington",
         "website": "http://www.unw.edu/"
      },
      {
         "university": "University of North Florida",
         "website": "http://www.unf.edu/"
      },
      {
         "university": "University of North Texas",
         "website": "http://www.unt.edu/"
      },
      {
         "university": "University of NorthWest",
         "website": "http://www.unw.ac/"
      },
      {
         "university": "University of Notre Dame",
         "website": "http://www.nd.edu/"
      },
      {
         "university": "University of Oklahoma",
         "website": "http://www.ou.edu/"
      },
      {
         "university": "University of Oregon",
         "website": "http://www.uoregon.edu/"
      },
      {
         "university": "University of Pennsylvania",
         "website": "http://www.upenn.edu/"
      },
      {
         "university": "University of Phoenix",
         "website": "http://www.phoenix.edu/"
      },
      {
         "university": "University of Pittsburgh",
         "website": "http://www.pitt.edu/"
      },
      {
         "university": "University of Pittsburgh at Bradford",
         "website": "http://www.upb.pitt.edu/"
      },
      {
         "university": "University of Pittsburgh at Greensburg",
         "website": "http://www.pitt.edu/~upg/"
      },
      {
         "university": "University of Pittsburgh at Johnstown",
         "website": "http://www.pitt.edu/~upjweb/"
      },
      {
         "university": "University of Portland",
         "website": "http://www.uofport.edu/"
      },
      {
         "university": "University of Puget Sound",
         "website": "http://www.ups.edu/"
      },
      {
         "university": "University of Redlands",
         "website": "http://www.redlands.edu/"
      },
      {
         "university": "University of Rhode Island",
         "website": "http://www.uri.edu/"
      },
      {
         "university": "University of Richmond",
         "website": "http://www.urich.edu/"
      },
      {
         "university": "University of Rio Grande",
         "website": "http://www.urgrgcc.edu/"
      },
      {
         "university": "University of Rochester",
         "website": "http://www.rochester.edu/"
      },
      {
         "university": "University of San Diego",
         "website": "http://www.sandiego.edu/"
      },
      {
         "university": "University of San Francisco",
         "website": "http://www.usfca.edu/"
      },
      {
         "university": "University of Science and Arts of Oklahoma",
         "website": "http://www.usao.edu/"
      },
      {
         "university": "University of Scranton",
         "website": "http://www.uofs.edu/"
      },
      {
         "university": "University of Sioux Falls",
         "website": "http://www.thecoo.edu/"
      },
      {
         "university": "University of South Alabama",
         "website": "http://www.usouthal.edu/"
      },
      {
         "university": "University of South Carolina",
         "website": "http://www.sc.edu/"
      },
      {
         "university": "University of South Carolina - Aiken",
         "website": "http://www.usca.sc.edu/"
      },
      {
         "university": "University of South Carolina - Beaufort",
         "website": "http://www.sc.edu/beaufort/"
      },
      {
         "university": "University of South Carolina - Lancaster",
         "website": "http://www.sc.edu/lancaster/"
      },
      {
         "university": "University of South Carolina - Salkehatchie",
         "website": "http://www.rcce.sc.edu/salkehatchie/"
      },
      {
         "university": "University of South Carolina - Spartanburg",
         "website": "http://www.uscs.edu/"
      },
      {
         "university": "University of South Carolina - Sumter",
         "website": "http://www.uscsumter.edu/"
      },
      {
         "university": "University of South Carolina - Union",
         "website": "http://www.sc.edu/union/"
      },
      {
         "university": "University of South Dakota",
         "website": "http://www.usd.edu/"
      },
      {
         "university": "University of Southern California",
         "website": "http://www.usc.edu/"
      },
      {
         "university": "University of Southern Indiana",
         "website": "http://www.usi.edu/"
      },
      {
         "university": "University of Southern Maine",
         "website": "http://www.usm.maine.edu/"
      },
      {
         "university": "University of Southern Mississippi",
         "website": "http://www.usm.edu/"
      },
      {
         "university": "University of South Florida",
         "website": "http://www.usf.edu/"
      },
      {
         "university": "University of St. Francis",
         "website": "http://www.stfrancis.edu/"
      },
      {
         "university": "University of St. Thomas - Houston",
         "website": "http://www.stthom.edu/"
      },
      {
         "university": "University of St. Thomas - St. Paul",
         "website": "http://www.stthomas.edu/"
      },
      {
         "university": "University of Tampa",
         "website": "http://www.utampa.edu/"
      },
      {
         "university": "University of Tennessee - Chattanooga",
         "website": "http://www.utc.edu/"
      },
      {
         "university": "University of Tennessee - Knoxville",
         "website": "http://www.utk.edu/"
      },
      {
         "university": "University of Tennessee - Martin",
         "website": "http://www.utm.edu/"
      },
      {
         "university": "University of Tennessee - Memphis",
         "website": "http://www.utmem.edu/"
      },
      {
         "university": "University of Tennessee Space Institute",
         "website": "http://www.utsi.edu/"
      },
      {
         "university": "University of Texas",
         "website": "http://www.utsystem.edu/"
      },
      {
         "university": "University of Texas at Arlington",
         "website": "http://www.uta.edu/"
      },
      {
         "university": "University of Texas at Austin",
         "website": "http://www.utexas.edu/"
      },
      {
         "university": "University of Texas at Brownsville",
         "website": "http://www.utb.edu/"
      },
      {
         "university": "University of Texas at Dallas",
         "website": "http://www.utdallas.edu/"
      },
      {
         "university": "University of Texas at El Paso",
         "website": "http://www.utep.edu/"
      },
      {
         "university": "University of Texas at San Antonio",
         "website": "http://www.utsa.edu/"
      },
      {
         "university": "University of Texas at Tyler",
         "website": "http://www.uttyl.edu/"
      },
      {
         "university": "University of Texas of the Permian Basin",
         "website": "http://www.utpb.edu/"
      },
      {
         "university": "University of Texas Pan American",
         "website": "http://www.panam.edu/"
      },
      {
         "university": "University of Toledo",
         "website": "http://www.utoledo.edu/"
      },
      {
         "university": "University of Tulsa",
         "website": "http://www.utulsa.edu/"
      },
      {
         "university": "University of Utah",
         "website": "http://www.utah.edu/"
      },
      {
         "university": "University of Vermont",
         "website": "http://www.uvm.edu/"
      },
      {
         "university": "University of Virginia",
         "website": "http://www.virginia.edu/"
      },
      {
         "university": "University of Virginia - College at Wise",
         "website": "http://www.wise.virginia.edu/"
      },
      {
         "university": "University of Washington",
         "website": "http://www.washington.edu/"
      },
      {
         "university": "University of Washington - Tacoma",
         "website": "http://www.tacoma.washington.edu/"
      },
      {
         "university": "University of West Alabama",
         "website": "http://www.uwa.edu/"
      },
      {
         "university": "University of West Florida",
         "website": "http://www.uwf.edu/"
      },
      {
         "university": "University of West Los Angeles",
         "website": "http://www.uwla.edu/"
      },
      {
         "university": "University of Wisconsin - Eau Claire",
         "website": "http://www.uwec.edu/"
      },
      {
         "university": "University of Wisconsin - Green Bay",
         "website": "http://www.uwgb.edu/"
      },
      {
         "university": "University of Wisconsin - La Crosse",
         "website": "http://www.uwlax.edu/"
      },
      {
         "university": "University of Wisconsin - Madison",
         "website": "http://www.wisc.edu/"
      },
      {
         "university": "University of Wisconsin - Milwaukee",
         "website": "http://www.uwm.edu/"
      },
      {
         "university": "University of Wisconsin - Oshkosh",
         "website": "http://www.uwosh.edu/"
      },
      {
         "university": "University of Wisconsin - Parkside",
         "website": "http://www.uwp.edu/"
      },
      {
         "university": "University of Wisconsin - Platteville",
         "website": "http://www.uwplatt.edu/"
      },
      {
         "university": "University of Wisconsin - River Falls",
         "website": "http://www.uwrf.edu/"
      },
      {
         "university": "University of Wisconsin - Stevens Point",
         "website": "http://www.uwsp.edu/"
      },
      {
         "university": "University of Wisconsin - Stout",
         "website": "http://www.uwstout.edu/"
      },
      {
         "university": "University of Wisconsin - Superior",
         "website": "http://www.uwsuper.edu/"
      },
      {
         "university": "University of Wisconsin - Whitewater",
         "website": "http://www.uww.edu/"
      },
      {
         "university": "University of Wyoming",
         "website": "http://www.uwyo.edu/"
      },
      {
         "university": "Utah State University",
         "website": "http://www.usu.edu/"
      },
      {
         "university": "Utah Valley State College",
         "website": "http://www.uvsc.edu/"
      },
      {
         "university": "Valparaiso University",
         "website": "http://www.valpo.edu/"
      },
      {
         "university": "Vanderbilt University",
         "website": "http://www.vanderbilt.edu/"
      },
      {
         "university": "Vassar College",
         "website": "http://www.vassar.edu/"
      },
      {
         "university": "Villanova University",
         "website": "http://www.villanova.edu/"
      },
      {
         "university": "Virginia Commonwealth University",
         "website": "http://www.vcu.edu/"
      },
      {
         "university": "Virginia Tech",
         "website": "http://www.vt.edu/"
      },
      {
         "university": "Virginia State University",
         "website": "http://www.vsu.edu/"
      },
      {
         "university": "Wake Forest University",
         "website": "http://www.wfu.edu/"
      },
      {
         "university": "Walden University",
         "website": "http://www.waldenu.edu/"
      },
      {
         "university": "Walla Walla College",
         "website": "http://www.wwc.edu/"
      },
      {
         "university": "Washington and Lee University",
         "website": "http://www.wlu.edu/"
      },
      {
         "university": "Washington State University",
         "website": "http://www.wsu.edu/"
      },
      {
         "university": "Washington State University - Spokane",
         "website": "http://www.spokane.wsu.edu/"
      },
      {
         "university": "Washington State University - Tri-Cities",
         "website": "http://www.tricity.wsu.edu/"
      },
      {
         "university": "Washington State University - Vancouver",
         "website": "http://www.vancouver.wsu.edu/"
      },
      {
         "university": "Washington University in St. Louis",
         "website": "http://www.wustl.edu/"
      },
      {
         "university": "Wayne State University",
         "website": "http://www.wayne.edu/"
      },
      {
         "university": "Wesleyan University",
         "website": "http://www.wesleyan.edu/"
      },
      {
         "university": "West Virginia University",
         "website": "http://www.wvu.edu/"
      },
      {
         "university": "Willamette University",
         "website": "http://www.willamette.edu/"
      },
      {
         "university": "Winston-Salem State University",
         "website": "http://www.wssu.edu/"
      },
      {
         "university": "Worcester Polytechnic Institute",
         "website": "http://www.wpi.edu/"
      },
      {
         "university": "Worcester State College",
         "website": "http://www.worc.mass.edu/"
      },
      {
         "university": "Xavier University",
         "website": "http://www.xu.edu/"
      },
      {
         "university": "Yale University",
         "website": "http://www.yale.edu/"
      },
      {
         "university": "Youngstown State University",
         "website": "http://www.ysu.edu/"
      }
   ],
   "CA": [],
   "UK": []
}
 export {universities, sitemap_universities};