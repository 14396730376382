import { SAVE_ALERT, CLEAR_ALERTS,
          SAVE_TOAST_ALERT, CLEAR_TOAST_ALERT } from "./actionTypes"

export const saveAlert = (alertType, message) => ({
  type: SAVE_ALERT,
  alertType: alertType,
  message: message
})

export const clearAlerts = () => ({
  type: CLEAR_ALERTS
})

//TOAST
export const saveToastAlert = (toastType, message, title = null) => ({
  type: SAVE_TOAST_ALERT,
  toastType: toastType,
  message: message,
  title: title
})

export const clearToastAlert = () => ({
  type: CLEAR_TOAST_ALERT
})
