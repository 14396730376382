import React, { Component } from "react"

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledAlert } from "reactstrap"

export default class BasicModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.toggleModal.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal !== this.props.isOpen) {
      this.setState({modal: this.props.isOpen});
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))

    if(this.props.toggleModal != undefined){
      this.props.toggleModal(this.state.modal);
    }
  }

  onClosed = () => {
    if(this.props.onClosed != undefined){
      this.props.onClosed();
    }
  }
  render() {
    let { header, error=null, showFooter=false } = this.props;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.toggleModal}
          onClosed={this.onClosed}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.toggleModal}>
              { header }
            </ModalHeader>
            <ModalBody>
              {
                error && <UncontrolledAlert color={"danger"}>{error}</UncontrolledAlert>
              }
             {
               this.props.children
             }
            </ModalBody>
            {
              showFooter &&
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={()=> {this.setState({modal: false})}}
                >
                  Close
                </Button>
              </ModalFooter>
            }
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
