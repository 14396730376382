import React, { Component } from "react"
import { MDBDataTable } from "mdbreact"
import PropTypes from 'prop-types';

import { camelCaseToWords } from "scripts/general";

export default class DataTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: this.props.columns || [],
      rows: this.props.rows,
      createColumns: this.props.createColumns == true ? true : false,
    }
    this.createDataTableColumnsFromRows.bind(this);
  }

  componentDidMount() {
    let { createColumns, rows } = this.state;
    
    if(createColumns){
      this.createDataTableColumnsFromRows(rows);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { createColumns } = this.state;
    let { rows } = this.props;
    
    if(prevState.rows != rows){
      if(createColumns){
        this.createDataTableColumnsFromRows(rows);
      }
      else{
        this.setState({rows: this.props.rows});
      }
    }
  }

  createDataTableColumnsFromRows(rowsArray){
    let columns = [];

    const row = rowsArray[0];
    
    for (const key in row) {
      if (Object.hasOwnProperty.call(row, key)) {
        let column = {};
        column["label"] = camelCaseToWords(key);
        column["field"] = key;
        column["sort"] = "asc";
        column["width"] = 150;

        columns.push(column);
      }
    }

    this.setState({columns});

  }

  render() {
    // data: {columns, rows}
    //columns: [{ label: "Name", field: "name", sort: "asc", width: 150},
    //          { label: "Position", field: "position", sort: "asc", width: 270 }]
    //rows: [{ name: "Tiger Nixon", position: "System Architect"},]

    let { columns, rows } = this.state;
    let data = {columns, rows}; //{columns: [], rows: []}
    
    return (
      <React.Fragment>
        <MDBDataTable responsive striped bordered small data={data} />
      </React.Fragment>
    )
  }
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  createColumns: PropTypes.bool
}