import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Card, CardBody, Media } from "reactstrap";

export default class MiniCard extends Component {
  render() {
    let { title, text, icon } = this.props;
    return (
      <React.Fragment>
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <p className="text-muted font-weight-medium">
                  {title}
                </p>
                <h4 className="mb-0">{text}</h4>
              </Media>

              <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                <span className="avatar-title">
                  <i
                    className={"bx " + icon + " font-size-24"}
                  />
                </span>
              </div>
            </Media>
            {
              this.props.children
            }
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

MiniCard.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}