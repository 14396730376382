import React, { Component } from "react"
import { Col, Row, CardTitle} from "reactstrap"

import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'

import "./kanban-style.scss" //For card width

class UncontrolledBoard extends Component {
  //REF: https://www.npmjs.com/package/@lourenci/react-kanban#oncardremove
  //Shape of board
  // {
  //   columns: [{
  //     id: ${unique-required-columnId},
  //     title: {$required-columnTitle**},
  //     cards: [{
  //       id: ${unique-required-cardId},
  //       title: ${required-cardTitle*}
  //       description: ${required-description*}
  //     }]
  //   }]
  // }

  render() {
    let { board, cardBox, onCardRemove, onCardDragEnd, renderCard } = this.props;

    return (
      <React.Fragment>
        <Row className="mb-4">
          <Col>
            <Board
              initialBoard={board}
              disableColumnDrag
              allowRemoveCard
              onCardRemove={onCardRemove}
              onCardDragEnd={onCardDragEnd}
              renderColumnHeader={({ title }) => (
                <CardTitle className="mb-4">{title}</CardTitle>
              )}
              renderCard={renderCard}
              onNewCardConfirm={draftCard => ({
                id: new Date().getTime(),
                ...draftCard,
              })}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default UncontrolledBoard
