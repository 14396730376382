import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { PROFICIENCY_EXAM_LIST } from 'routes/paths'

import ExamForm from 'components/forms/proficiency-exam/ExamForm'
import { loadAssessment, submitAssessment } from 'scripts/proficiencyExams'

import { ThemeSpinner } from 'theme/elements/spinner'

class Assessment extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            assessmentId: props.match.params.assessmentId || "",
            assessment: {
                questions: [],
                name: "",
                selectedAnswers: {},
                submitted: false,
                submittedAt: null,
                showSolutions: false},
            
            submitting: false,
            redirect: false
        }
        this.onSubmitExam.bind(this)
        this.onSaveExam.bind(this)
    }
    
    componentDidMount() {
        const { onSubmitApiCall } = this.props;

        var assessmentId = this.state.assessmentId;
        if(assessmentId != "") {
            onSubmitApiCall("loadAssessment", assessmentId);
        }        
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;

        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
            
            //Redirect
            if(apiResponse != {} && apiResponse.success && this.state.submitting){
                this.setState({redirect: true, submitting: false});
            }
        }
    }

    onSaveExam = formData => {
        const { onSubmitApiCall } = this.props;
        let { assessmentId } = this.state;

        let submitExam = false;
        let selectedAnswers = formData;

        formData = { selectedAnswers,
                    assessmentId,
                    submitExam };
        
        this.setState({submitting: true});
        onSubmitApiCall("submitAssessment", formData);
        
    }

    onSubmitExam = async (formData) => {
        const { onSubmitApiCall } = this.props;

        //Get User id & ADD TO FORM DATA
        let { assessmentId } = this.state;
        let submitExam = true;

        let selectedAnswers = formData;
        formData = { selectedAnswers,
                    assessmentId,
                    submitExam };        

        this.setState({submitting: true});
        onSubmitApiCall("submitAssessment", formData);
    }

    render() {
        const { apiResponse } = this.props;
        let { redirect, assessment: {questions, name, selectedAnswers, submitted, submittedAt} } = this.state;
        let loading = apiResponse.loading;
        
        return (
            <>
                {
                    redirect &&
                        <Redirect to={PROFICIENCY_EXAM_LIST} />
                }

                <BTNMetaTags title={name} />
                
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }
                {
                   questions.length > 0 &&
                        <ExamForm questions={ questions } 
                                title={ name }
                                selectedAnswers = { selectedAnswers }
                                submitted = { submitted }
                                submittedAt = { submittedAt }
                                onSubmitExam = { this.onSubmitExam }
                                onSaveExam = { this.onSaveExam }
                                //showSolutions = { showSolutions }
                                />
                }
            </>
        )
    }
}
const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Assessment)