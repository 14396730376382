import React, { Component } from "react"
import { Badge, UncontrolledTooltip} from "reactstrap"

import AssignmentModal from "components/modals/AssignmentModal"
import ChargeSessionModal from "components/modals/ChargeSessionModal"
import UpdateEndDateModal from "components/modals/UpdateEndDateModal"

import { getAssignmentStatus } from "scripts/general"

export default class AssignmentsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      isOpen:[],
      isChargeOpen: [],
      isDateOpen: [],
      assignments: this.props.assignments || [],
      badgeClass: {active: 'success',
                ending_soon: 'warning',
                ended: 'danger'},
    }
    this.openModal.bind(this);
    this.closeModal.bind(this)
    this.openChargeModal.bind(this);
    this.closeChargeModal.bind(this);
    this.openDateModal.bind(this)
    this.closeDateModal.bind(this)
  }

  openModal = (id) => {
    this.setState({
       isOpen: {
          [id]: true
       }
    });
  }
  
  closeModal = (id) => {
    this.setState({
       isOpen: {
          [id]: false
       }
    });
  }
  
  openChargeModal = (id) => {
    this.setState({
       isChargeOpen: {
          [id]: true
       }
    });
  }

  
  closeChargeModal = (id) => {
    this.setState({
       isChargeOpen: {
          [id]: false
       }
    });
  }

  openDateModal = (id) => {
    this.setState({
       isDateOpen: {
          [id]: true
       }
    });
  }

  
  closeDateModal = (id) => {
    this.setState({
       isDateOpen: {
          [id]: false
       }
    });
  }

  render() {
    let { assignments, badgeClass, isOpen, isChargeOpen, isDateOpen } = this.state

    return (
      <React.Fragment>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th>Student</th>
                </tr>
              </thead>
              <tbody>
                {assignments.map((assignment, key) => (
                  <tr key={"_tr_" + key}>
                    <td>
                      <a onClick={() => this.openModal(key)} className="text-primary">{assignment.name} </a>
                       - {assignment.course} {" "}
                      {
                        getAssignmentStatus(assignment.endDate) != "active" &&
                        <Badge
                          className={
                            "font-size-12 badge-soft-" + badgeClass[getAssignmentStatus(assignment.endDate)]
                          }
                          color={badgeClass[getAssignmentStatus(assignment.endDate)]}
                          pill
                        >
                          {getAssignmentStatus(assignment.endDate)}
                        </Badge>
                      }
                      <br /><br />
                      {
                        assignment.scholarship &&
                          <p>
                            <Badge
                              className="font-size-12 badge-info"
                              color="primary"
                            >
                              Scholarship
                            </Badge>
                            {" "}{" "}
                            <span className={`pl-2  ${assignment.prepaidHours <= 1 ? "text-danger" : "text-dark"}`}>
                              Hours Left : {assignment.prepaidHours.toFixed(2)} 
                            </span>
                            
                          </p>
                      }
                      <p>
                        <button id={"charges" + assignment.id} onClick={() => this.openChargeModal(key)} className="btn btn-sm btn-outline-success">
                            Charge Session
                        </button> 
                      </p>
                      { getAssignmentStatus(assignment.endDate) == "ending_soon" &&
                          <small><a id={"endDate" + assignment.id} onClick={() => this.openDateModal(key)} className="text-primary">
                            Extend End Date {" "}
                          </a></small>
                      }
                      <AssignmentModal
                        key={"assign-modal-"+ assignment.id}
                        assignment={assignment}
                        isOpen={isOpen[key]}
                        toggleModal={() => this.closeModal(key)}
                        />
                        
                      <ChargeSessionModal
                        key={"charge-"+key}
                        id={"charge-" + assignment.id}
                        assignment={assignment}
                        name={assignment.studentName + " / " + assignment.tutorName}
                        course={assignment.course}
                        rate={assignment.tutorCurrency + "" + assignment.tutorRate}
                        isOpen={isChargeOpen[key]}
                        toggleModal={() => this.closeChargeModal(key)}
                        />

                        <UpdateEndDateModal 
                          id={"endDate-" + assignment.id}
                          key={"endDate-"+key}
                          assignment={assignment}
                          isOpen={isDateOpen[key]}
                          toggleModal={() => this.closeDateModal(key)}
                        />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        
      </React.Fragment>
    )
  }
}