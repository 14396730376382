import {
          SAVE_TUTOR_CONTRACT, SAVE_MULTIPLE_TUTOR_CONTRACTS,
          LOAD_TUTOR_CONTRACT,
          TUTOR_CONTRACT_ERROR
        } from "./actionTypes";

const initialState = {
  tutorContracts: [],
  selectedTutorContract: {},
  error: "",
  loading: false
}

const tutorContractsReducer = (state=initialState, action) => {
	let tutorContracts = state.tutorContracts;

  switch(action.type){
    case LOAD_TUTOR_CONTRACT:
      return {
        ...state,
        error: "",
        loading: true
      }

    case TUTOR_CONTRACT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }

    case SAVE_MULTIPLE_TUTOR_CONTRACTS:
      let newTutorContracts = action.payload.hasOwnProperty("tutorContracts") ? action.payload.tutorContracts : action.payload;
      // //Remove existing Contracts
      // newTutorContracts.forEach(newTutorContract => {
      //               tutorContracts = tutorContracts.filter(tContract => {
      //                 return tContract.id != newTutorContract.id;
      //               })
      // });

      // tutorContracts = [...tutorContracts, ...newTutorContracts];
      
      tutorContracts = newTutorContracts;
      
      return {
        ...state,
        tutorContracts,
        error: "",
        loading: false
      }

    case SAVE_TUTOR_CONTRACT:
      let tutorContract = action.payload.hasOwnProperty("tutorContract") ? action.payload.tutorContract : action.payload;

      //remove existing contract
      tutorContracts.filter(tContract => {
            return tContract.id != tutorContract.id
      })

      tutorContracts.push(tutorContract);
      
      return {
        ...state,
        tutorContracts,
        error: "",
        loading: false
      }
  
    default:
      return state
  }
}

export default tutorContractsReducer;