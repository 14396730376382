import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import AnalyticsWeeklyChart from 'components/charts/AnalyticsWeeklyChart'
import AnalyticsMonthlyChart from 'components/charts/AnalyticsMonthlyChart'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

class Analytics extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            sessionCountChartData: [],
            assignmentCountChartData: [],
            tutorRequestCountChartData: [],
            appFeesChartData: [],
            tutorSessionChartData: []
        }
    }

    componentDidMount() {
        let { onSubmitApiCall } = this.props;

        onSubmitApiCall("getAnalyticsData");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        let { appFeesChartData, tutorSessionChartData, sessionCountChartData, assignmentCountChartData, tutorRequestCountChartData } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        return (
            <div>
                <BTNMetaTags title={`Analytics`} />

                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }
                <Card>
                    <CardBody>
                        <CardTitle>App Fees</CardTitle>
                        {
                            appFeesChartData.length  > 0 && 
                                <>
                                    <AnalyticsWeeklyChart chartData={appFeesChartData} title="Weekly Platform Net Earnings ($)" />
                                </>
                        }
                    </CardBody>
                </Card>
                
                <Card>
                    <CardBody>
                        <CardTitle>Assignments</CardTitle>
                        {
                            assignmentCountChartData.length > 0 &&
                                <AnalyticsWeeklyChart chartData={assignmentCountChartData} title="Weekly Assignments Created" type="count" />
                        }
                    </CardBody>
                </Card>
                
                <Card>
                    <CardBody>
                        <CardTitle>Tutor Requests</CardTitle>
                        {
                            tutorRequestCountChartData.length > 0 &&
                                <AnalyticsWeeklyChart chartData={tutorRequestCountChartData} title="Weekly Requests Received" type="count" />
                        }
                    </CardBody>
                </Card>
                
                <Card>
                    <CardBody>
                        <CardTitle>Tutor Sessions</CardTitle>
                        {
                            tutorSessionChartData.length > 0 &&
                                <>
                                    <AnalyticsWeeklyChart chartData={sessionCountChartData} title="Weekly Session Count" type="count" />
                                    <AnalyticsWeeklyChart chartData={sessionCountChartData} title="Weekly BTN Pay" type="btn_pay" />
                                    <br /><br />
                                    <AnalyticsMonthlyChart chartData={tutorSessionChartData} title="Monthly Tutor Session Charges" type="amount" />
                                    <br /><br />
                                    <AnalyticsMonthlyChart chartData={tutorSessionChartData} title="Monthly Tutor Session Hours" type="hours" />
                                    <br /><br />
                                    <AnalyticsMonthlyChart chartData={tutorSessionChartData} title="Monthly BTN Pay" type="btn_pay" />
                                </>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
