import React, { Component } from 'react'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { Input } from 'reactstrap';

export default class ScholarshipAssignmentHoursForm extends Component {
    onSubmit = values => {
        this.props.onSubmit(values);
    }

    render() {
        let { loading, scholarshipAssignmentId } = this.props;

        let initialValues = {hours: "", scholarshipAssignmentId }
        const ScholarSchema = Yup.object().shape({
                                    hours: Yup.number()
                                                .positive()
                                                .min(1)
                                                .required('Please enter approved hours (must be greater than 1 hour)'),                                    
          });

        return (
            <>
                <Formik
                    initialValues={{...initialValues}}
                    validationSchema={ScholarSchema}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            <div className="form-group">
                                <Input
                                    name="hours"
                                    label="Total Hours Approved"
                                    placeholder="Hours"
                                    min={0}
                                    className="form-control"
                                    type="number"
                                    required
                                    value={props.values.hours}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                <ErrorMessage name="hours" component="div" className="text-danger" />
                                
                            </div>

                            <Input
                                name="scholarshipAssignmentId"
                                value={props.values.scholarshipAssignmentId}
                                type="hidden"
                            />
                            
                            <button type="submit" 
                                className="btn btn-sm btn-primary float-right" 
                                disabled={loading}>
                                Submit Hours
                            </button>
                        </Form>
                    )}
                </Formik>            
            </>
        )
    }
}
