import { RESET_API_REDUCER, SUBMIT_API_CALL, 
        SAVE_API_RESPONSE, SAVE_API_ERROR } from "./actionTypes";

const initialState = {
    data: {},
    error: "",
    loading: false,
    success: false
}

const apiResponseReducer = (state=initialState, action) => {
	switch(action.type){
        case RESET_API_REDUCER:
            return initialState;

        case SUBMIT_API_CALL:
            return {
                ...state,
                error: "",
                loading: true,
                success: false
            }
        case SAVE_API_RESPONSE:
            return { data: {...action.payload},
                      error: "",
                      loading: false,
                      success: true
            }
        case SAVE_API_ERROR:
            return {
                data: {},
                error: action.error,
                loading: false,
                success: false 
            }
        default:
            return state;
    }
}

export default apiResponseReducer;
