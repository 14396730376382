import React, { Component } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { Button, Card, Col, Row, UncontrolledTooltip } from "reactstrap"

import ThemeAvatar from "theme/images/ThemeAvatar";

import { formatDateTime, getCurrencySymbol } from 'scripts/general'

import { TUTOR_PROFILE, STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE, STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION } from "routes/paths"
import { ThemeButtonLink } from "theme/elements/buttons";

class ContractCard extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          studentDecision: this.props.contract.studentDecision,
          rejectReason: ""
        }
        this.onSubmitStudentDecision.bind(this);
    }

    onSubmitStudentDecision = async(decision) => {
        let { contract } = this.props;
        let { rejectReason } = this.state;
    
        let formData = { decision, rejectReason, id: contract.id };
        
        if(typeof this.props.onSubmitDecision !== undefined){
          this.props.onSubmitDecision(formData);
        }
    }

    render() {
    let { contract, stage, scholarship = false } = this.props;
    let { studentDecision } = this.state;
    let currencySymbol = getCurrencySymbol(contract.currency || "usd");
    // let rate = contract.prepayRate > 0 ? contract.prepayRate : contract.rate;
    let rate = contract.rate;

    return (
        <React.Fragment>
            <Col xl="8" md="12">
                <Card>
                    <Row>
                        <Col md="3">
                            <div className="text-center p-4 border-end">
                                <div className="avatar-xl mx-auto mb-2 mt-1">
                                    <ThemeAvatar avatar={contract.tutorAvatar} name={contract.tutorName} size="xl" rounded="rounded" />
                                </div>
                                <br />
                                <center>
                                    <h4 className="text-dark mb-1">
                                        {contract.tutorName}
                                    </h4>
                                    <h5>
                                        {contract.city && <span className="text-muted">{contract.city}, {contract.state}</span>}
                                    </h5>
                                    <h5 className="text-danger mb-1 mb-lg-3">
                                        {currencySymbol}{rate}/hr
                                        { stage != "confirm" &&
                                            <>*</>
                                        }
                                    </h5>
                                    {
                                        contract.inPerson == true &&
                                        <span className="badge badge-primary" style={{fontSize: "14px", margin: "5px", padding: "6px"}}>In Person Tutoring</span>
                                    }
                                    {" "}
                                    {
                                        contract.online == true &&
                                        <span className="badge badge-success" style={{fontSize: "14px", margin: "5px", padding: "6px"}}>Online Tutoring</span>
                                    }
                                </center>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="p-4 text-xl-start">
                                <Row>
                                    <Col xs="12">
                                        <p className="font-size-15 text-muted" style={{fontWeight: "bold"}}>
                                            {contract.education}<br />
                                            {contract.major}
                                        </p>
                                        <p className="font-size-15">
                                            {contract.experience} {" "}
                                            <Link to={TUTOR_PROFILE + "/" + contract.tutorId} className="text-primary" target="_blank">
                                                View Profile
                                            </Link>
                                        </p>
                                    </Col>
                                </Row>
                                {
                                    contract.studentDeadline &&
                                        <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <h5 className="font-size-14" id="duedateTooltip">
                                            <i className="bx bx-calendar mr-1 text-primary" />
                                            Deadline To Respond:{" "}
                                            {
                                                moment().diff(contract.studentDeadline, 'minutes') > 0 
                                                ? <span className={"text-danger"}>{moment(contract.studentDeadline).fromNow()}</span>
                                                : <span className={"text-dark"}>{formatDateTime(contract.studentDeadline)}</span>

                                            }
                                             
                                            <UncontrolledTooltip
                                                placement="top"
                                                target="duedateTooltip"
                                            >
                                                Response Deadline
                                            </UncontrolledTooltip>
                                            </h5>
                                        </li>
                                        </ul>
                                }
                            
                                {
                                    this.props.stage == "confirm" ?
                                        <Row className="mt-4">
                                            <Col xs="6">
                                                {
                                                    moment().diff(contract.studentDeadline, 'minutes') > 0 
                                                    ?
                                                        <Button color="info"
                                                            className="btn-md w-md"
                                                            disabled={true}>
                                                            Accept Proposal
                                                            <br />
                                                            <small>Prepay 1st Hour</small>
                                                        </Button>
                                                    :
                                                    <ThemeButtonLink to={`${scholarship ? STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION : STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE}/${contract.id}`}
                                                        color="info"
                                                        className="btn-md w-md"
                                                    >
                                                        Accept Proposal
                                                        <br />
                                                        <small>Prepay 1st Hour</small>
                                                    </ThemeButtonLink>
                                                }
                                            </Col>
                                            <Col xs="6">
                                                {
                                                    moment().diff(contract.studentDeadline, 'minutes') > 0 //deadline passed
                                                    ?
                                                        <Button color="danger"
                                                            className="btn-md w-md"
                                                            disabled={true}>
                                                            {studentDecision !== false ? "Decline Proposal" : "Declined"}
                                                        </Button>
                                                    :
                                                        <Button color="danger"
                                                            className="btn-md w-md"
                                                            onClick={() => this.onSubmitStudentDecision(false)}
                                                            disabled={studentDecision !== false  ? false : true}>
                                                                {studentDecision !== false ? "Decline Proposal" : "Declined"}
                                                        </Button>
                                                }
                                            </Col>
                                            {
                                                moment().diff(contract.studentDeadline, 'minutes') > 0 &&
                                                    <p className="mt-3 text-secondary"><b>Want to work with {contract.tutorName}?</b> Please <a href="mailto:Contact@BuffTutor.com">email us</a> to extend the deadline to respond. </p>
                                            }
                                        </Row>
                                    :
                                        <Row>
                                            <Col xs="6">
                                                <h5 className="text-info" style={{fontSize: "4em"}} id={"accept-potential-" + contract.id}>
                                                    <center>
                                                        <a onClick={() => this.onSubmitStudentDecision(true)}><i className="bx bx-check-circle mr-1 text-info" /></a>
                                                    </center>
                                                </h5>
                                        </Col>
                                        <Col xs="6">
                                            {
                                                studentDecision !== false 
                                                ? 
                                                    <h5 className="text-warning" style={{fontSize: "4em"}} id={"decline-potential-" + contract.id}>
                                                    <center>
                                                        <a onClick={() => this.onSubmitStudentDecision(false)}><i className="bx bx-x-circle mr-1 text-warning"/></a>
                                                    </center>
                                                    </h5>
                                                : 
                                                <h5 className="text-danger">
                                                    <br />Declined
                                                </h5>
                                            }
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </React.Fragment>
    )
  }
}

export default ContractCard
