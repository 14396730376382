import { get, post, postFormData, patch } from "../helpers/api_helper"
import { POST_UPLOAD_TRANSCRIPTS, 
        GET_TUTOR_APPLICATION,
        SUBMIT_TUTOR_APPLICATION, 
        GET_TUTOR_CONTRACTOR_AGREEMENT, POST_SIGN_CONTRACTOR_AGREEMENT, 
        
        POST_CREATE_CHECKR_INVITATION_BASIC
        } from "../api/urls"

export const getTutorApplication = (userId) => {
        return get(GET_TUTOR_APPLICATION + "/" + userId);
}

export async function uploadTranscripts({tutorApplicationId, files}){
        var formData = new FormData();

        files.forEach(file => {
                formData.append("files", file);        
        });
        
        let headers = { headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } };

        return postFormData(POST_UPLOAD_TRANSCRIPTS + "/" + tutorApplicationId, formData, headers);
}

export const submitTutorApplication = async (tutorApplicationId) => {
        return await patch(SUBMIT_TUTOR_APPLICATION + "/" + tutorApplicationId);
}

export const getTutorContractorAgreementTemplate = () => {
        //TODO: add method to add/update address
        return get(GET_TUTOR_CONTRACTOR_AGREEMENT);
}

export const signTutorContractorAgreement = ({address, legalName}) => {
        return post(POST_SIGN_CONTRACTOR_AGREEMENT, { address, legalName });
}

export const createCheckrInvitation = formData => {
        // formData = {countryIso, userId}
        return post(POST_CREATE_CHECKR_INVITATION_BASIC, formData);
}
