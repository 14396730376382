import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import { CART_ORDER_CONFIRMATION } from 'routes/paths';
import { submitApiCallAction, addItemToCart } from "store/actions"
import { processApiResponse } from 'scripts/api'

import AddFundsToAdAccountForm from 'components/forms/formik/ads/AddFundsToAdAccountForm'
import AvatarUpload from "components/fileUpload/AvatarUpload";

import ThemeAvatar from 'theme/images/ThemeAvatar'
import { ThemeSpinner } from "theme/elements/spinner"
import { ThemeSoftBadge } from 'theme/elements/badges';
import CustomReactSwitch from 'components/switch/CustomReactSwitch'

import { getCurrencySymbol } from 'scripts/general'
import { capitalizeWords } from 'scripts/general'

import MiniCard from 'components/cards/MiniCard';
import UpdateSubjectsDualList from 'components/dualList/UpdateSubjectsDualList';

class AdAccountDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
          account: {}, 
          cartRedirect: false,
          showSubjects: false,
          showAddFunds: false,
          showUpload: false,
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall, user } = this.props;

        onSubmitApiCall("getAdAccountForOwner", user.id);
        // onSubmitApiCall("getAdAccountForOwner", "27832381"); //TEMP
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    onAddFunds = formData => {
        //Get Amount & Add To Cart
        let { account } = this.state;
        let { onAddItemToCart, cart } = this.props;
        let amount = formData.amount;
    
        let name = "Lead Generation Payment";
        let description = "Add funds to account balance";
        let type = "adAccount";

        let cartItems = cart.items;
        let accountPaymentPresent = false;

        cartItems.forEach(item => {
            if(item.id == account.id && item.type == "adAccount"){
                accountPaymentPresent = true;
            }
        });

        //create Cart Item
        if(!accountPaymentPresent){
            let cartItem = {
                id: account.id,
                type,
                object: "adAccount",
                objectId: account.id,
                name,
                description,
                price: amount,
                quantity: 1,
                currency: account.currency || "usd",
                metadata: { 
                            accountId: account.id,
                        }
            };
            onAddItemToCart(cartItem);
        }
        
        this.setState({cartRedirect: true});
    }

    updateNotifications = checked => {
        let { onSubmitApiCall } = this.props;
        let { account } = this.state;
        let formData = { accountId: account.id, notifications: checked };
        onSubmitApiCall("patchUpdateAdAccountLeadNotification", formData); 
    }

    uploadAvatar = avatarFile => {
        let { onSubmitApiCall } = this.props;
        let { account } = this.state;
        let formData = { accountId: account.id, avatarFile };
        onSubmitApiCall("postUpdateAdAccountAvatar", formData); 
    }

    refundAccountBalance = () => {
        alert("TODO");
    }

    render() {
        let { apiResponse, user } = this.props;
        let { account, cartRedirect, showSubjects, showAddFunds, showUpload } = this.state;

        let loading = apiResponse.loading;
        let selectedSubjects = [];
        if(account.subjects && account.subjects.length > 0){
            account.subjects.forEach(subject => {
                selectedSubjects.push(subject.id);
            });
        }

        if(cartRedirect){
            return  <Redirect to={CART_ORDER_CONFIRMATION} />
        }
        console.log("account", account)
        return (
            <>  
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }
                <Row>
                    <Col md={5}>
                        <Card>
                            <CardBody>
                                <CardTitle>My Details</CardTitle>
                                <Row>
                                    <Col sm="4">
                                        <ThemeAvatar avatar={account.avatar} name={account.name} size="lg" color="info" rounded="rounded" />
                                        <a className="text-primary" onClick={() => {this.setState({showUpload: !showUpload})}}>{showUpload ? "Hide Form" : "Upload Photo"}</a> {" "}
                                    </Col>
                                    <Col sm="8">
                                        {
                                            showUpload && <AvatarUpload onSubmit={this.uploadAvatar}/>
                                        }
                                    </Col>
                                </Row>
                                <br />
                                
                                <b>Name: </b>{account.name}<br />
                                <b>Location: </b>{account.location}<br />
                                <b>Major: </b> {account.major} {account.school && <>@ {account.school}</>}<br />
                                {
                                    account.education &&
                                    <>
                                        <b>Education:</b>  {account.education}<br />
                                    </>
                                }
                                {
                                    account.inPerson === true &&
                                        <ThemeSoftBadge color="info">In Person</ThemeSoftBadge>
                                }
                                {" "}
                                {
                                    account.online === true &&
                                        <ThemeSoftBadge color="success">Online</ThemeSoftBadge>
                                }
                                <br /><br />
                                <b>Email Notifications :</b> <CustomReactSwitch key={"switch-" + account.leadNotifications} height={10} checked={account.leadNotifications != null ? account.leadNotifications : false } onChange={this.updateNotifications} /> 
                                {" "}
                                <small className="pl-4">Click To Turn {account.leadNotifications ? "Off" : "On"} </small>

                                <br />
                                Receive an email alert when new requests come in for the subjects listed below.
                                <br /><br />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={7}>
                        <Row>
                            <Col md="8" sm="6">
                                <MiniCard icon={"bx-dollar"} title={"Lead Generation Account"} text={`Balance: ${getCurrencySymbol(account.currency || "usd")}${account.accountBalance || 0}`}> 
                                    <div className="mt-4 mt-sm-0">
                                        <br /><br />
                                        <div className="text-center">
                                            <a className="btn btn-outline-success btn-sm me-2 w-md" onClick={() => {this.setState({showAddFunds: !showAddFunds})}}>{showAddFunds ? "Hide Form" : "Add Funds"}</a> {" "}
                                            {
                                                account.accountBalance > 0 &&
                                                    <a className="btn btn-outline-secondary btn-sm me-2 w-md" onClick={this.refundAccountBalance}>Refund Balance</a>
                                            }
                                            
                                            
                                            {
                                                showAddFunds && 
                                                    <Row>
                                                        {/* <Col sm={12} md={{size: 10, offset: 1}}> */}
                                                        <Col lg={{size: 10, offset: 1}}>
                                                            <br /><br />
                                                            <AddFundsToAdAccountForm onSubmit={this.onAddFunds} />
                                                        </Col>
                                                    </Row>
                                            }
                                        </div>
                                    </div>
                                </MiniCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" lg="10">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Subjects Tutored</CardTitle>
                                        <a className={"text-primary"} onClick={() => {this.setState({showSubjects: !showSubjects})}}>{showSubjects ? "Hide " : "Edit "} Subjects</a><br />

                                        {
                                            account.subjects != null && 
                                                <>
                                                    
                                                    {account.subjects
                                                        .sort((a,b) => { return a.parentName > b.parentName})
                                                        .map((subject, i) => 
                                                            // <span key={"badge-" + i}><ThemeSoftBadge color="dark">{subject.parentName && subject.parentId != subject.id ? <>{capitalizeWords(subject.parentName.replace(/_/g, " "))}{" : "}</> : ""}{capitalizeWords(subject.name.replace(/_/g, " "))}</ThemeSoftBadge> {" "}</span>
                                                        <span key={"badge-" + i}><ThemeSoftBadge color="dark">{capitalizeWords(subject.name.replace(/_/g, " "))}</ThemeSoftBadge> {" "}</span>
                                                    )}
                                                    <br /><br /> 
                                                </>
                                        }
                                        {
                                            showSubjects &&
                                                <UpdateSubjectsDualList key={"selectedSubject-" + selectedSubjects.length} userRefId={user.id} userSubjects={account.subjects} showReload={false} onSubmit={() => {}} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ userReducer, apiResponseReducer, cartReducer }) => ({
    user: userReducer,
    apiResponse: apiResponseReducer,
    cart: cartReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onAddItemToCart: cartItem => dispatch(addItemToCart(cartItem)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(AdAccountDashboard)