import React, { Component } from 'react'

import { connect } from "react-redux"

import { pusher } from 'api/pusher';

import { triggerReducerLoadingAction, submitApiCallWithReducerAction, 
            saveToastAlert,saveChatThreads } from "store/actions"

            import BTNMetaTags from "components/metatags/BTNMetaTags"
import { CHAT_ERROR, LOAD_CHAT_THREADS, SAVE_CHAT_THREADS } from 'store/chat/actionTypes';
import Chats from 'theme-chat/components/Chats/Chats';

class ChatUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
          pusherLoaded: null
        }
        this.channel = null;
        this.channelName = null;
        this.pusher = pusher(this.props.user.id);
      }

    componentDidMount() {
        let { user, onReducerLoading, onSubmitApiCallWithReducer, onSaveToastAlert } = this.props;
        onReducerLoading(LOAD_CHAT_THREADS);
        onSubmitApiCallWithReducer("getUserChats", SAVE_CHAT_THREADS, user.id, CHAT_ERROR);

        this.pusher.signin();

        this.pusher.bind('pusher:signin_success', (data) => {
            this.setState({pusherLoaded: true});
            this.createUserChannel();
        });

        this.pusher.bind('pusher:error', (data) => {
            this.setState({pusherLoaded: false});
            onSaveToastAlert("danger", "Connection Error: Not Receiving Updates");
        });
    }

    componentWillUnmount(){
        if(this.channel != null){
            this.channel.unbind();
            this.pusher.unsubscribe(this.channelName);
        }
    }

    createUserChannel = () =>{
        let { user, onSaveToastAlert } = this.props;
        this.channelName = "private-chat_list-" + user.id;
        
        this.channel = this.pusher.subscribe(this.channelName);
        
        this.channel.bind("new-message", (data) => {
            let { chat } = data;
            this.addPusherThread(chat);
        });

        this.channel.bind("new-thread", (data) => {
            let { chat } = data;
            this.addPusherThread(chat);
        });

        this.channel.bind('pusher:subscription_succeeded', (data) => {
            
        });

        this.channel.bind('pusher:subscription_error', (data)  => {
            onSaveToastAlert("danger", "Network Error: " + data.error);
        });
    }

    addPusherThread = pusherThread => {
        const { chatsReducer, onSaveChatThreads } = this.props
        let chatThreads = chatsReducer.chatThreads;
 
        const index = chatThreads.findIndex(chatThread => chatThread.threadId === pusherThread.threadId);
        
        if (index > -1) { 
            chatThreads.splice(index, 1);     
        }

        pusherThread.isUnread = true;
        chatThreads = [pusherThread, ...chatThreads]; 
        onSaveChatThreads(chatThreads);
    }

    render() {
        let { chatsReducer, user } =  this.props;
        let { pusherLoaded } = this.state
        let chatThreads = chatsReducer.chatThreads;

        return (
            <>
                <BTNMetaTags title="Messages" />
                
                <Chats key={chatThreads.length} user={user} chats={chatThreads} pusher={this.pusher} pusherLoaded={pusherLoaded} />
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer, chatsReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer,
    chatsReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
    
    onSaveToastAlert: (alertType, message) => dispatch(saveToastAlert(alertType, message)),
    onSaveChatThreads: chatThreads => dispatch(saveChatThreads(chatThreads)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ChatUser)
  