import React from "react"
import moment from "moment-timezone"
import { Card, CardBody, Col, Row } from "reactstrap"

import { formatDateTime, getCurrencySymbol } from "scripts/general"

export const ContractData = (props) => {
    let contract = props.contract;

    return (
        <div className="ml-2">
            <h5>{contract.studentName} <br />
                
                {contract.course} 
                {
                    contract.school &&
                    <> @ {contract.school}</>   
                }
                </h5>
            
                <br /> 
                <b>Location: </b>{props.contract.studentLocation}
            <br />
            {
                props.contract.studentTimezone &&
                    <>
                        <b>Local Time:</b> {moment().tz(props.contract.studentTimezone).format("LT")} ({props.contract.studentTimezone})
                        <br />
                    </>
            }
            <br />
            <b>Dates: </b>{moment(props.contract.startDate).format("MMM Do")} to {moment(props.contract.endDate).format("MMM Do YYYY")}
            <br />
            <b>Preferred Time: </b>{props.contract.sessionTime}<br />
            <b>Frequency: </b>{props.contract.frequency}<br />
            {
                props.contract.requestConcerns &&
                    <>
                        <b>Concerns: </b>{props.contract.requestConcerns}
                        <br />
                        <br />
                    </>
                
            }
            <p className="mt-2">
            {
                props.contract.online == true &&
                    <span className="badge badge-soft-primary">Online</span>
            }
            {
                props.contract.inPerson == true &&
                    <span className="badge badge-soft-success ml-2">In-Person</span>
            }
            </p>
            <Row>
                <Col md={{size: 8}} lg={6}>
                    <Card className="pb-3 pt-2" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.25)"}}>
                        <CardBody className="text-center">
                            <h3>Net Earnings: <span className="text-info">{getCurrencySymbol(contract.tutorCurrency)}{contract.tutorRate}/hr</span></h3>
                            {props.children}
                            
                            {
                                contract.tutorDecision === true ?
                                        <>
                                            {contract.studentDeadline ? <b className="text-primary">{contract.studentName} must respond {moment(contract.studentDeadline).fromNow()}</b> : ""}
                                        </>
                                    :
                                        <>
                                            {contract.tutorDeadline ? <b>Respond By <br /> {formatDateTime(contract.tutorDeadline)}</b> : ""}
                                            <br />
                                        </>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </div>
    )
}