import React, { Component } from 'react'

export default class TutorReviewStep extends Component {

  render() {
    let tutorName = null;

    let { data=null } = this.props;

    if(data != null){
      let { firstName = null } = data;
      tutorName = firstName;
    }

    return (
      <>
        <h4><b>Write a public review</b> <br />(Recommended)</h4>
        <p style={{fontSize: "1.15rem"}}>
          Your honest feedback helps other students and parents when selecting a tutor to work with.
        </p>

        <div className="form-group pb40">
          <textarea
              id="publicRreview"
              name="publicReview"
              className="form-control "
              style={{height: "10rem"}}
              placeholder={`Provide a review for ${tutorName || "the tutor"}'s profile.`}
              onChange={this.props.onChange}
          />
        </div>

        <h4><b>Share a private note</b> <br />(Optional)</h4>
        <p style={{fontSize: "1.15rem"}}>
          Please provide constructive criticism, a few suggestions or heaps of praise!
        </p>
        <div className="form-group">
          <textarea
            id="privateReview"
            name="privateReview"
            className="form-control"
            style={{height: "10rem"}}
            placeholder={`Write feedback that only ${tutorName || "the tutor"} can read.`}
            onChange={this.props.onChange}
          />
        </div>
      </>
    )
  }
}
