import React, { Component } from "react"

import { Col, Container, Row } from "reactstrap"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import RequestTutorForm from "../../components/forms/request-tutor/RequestTutorForm"

class RequestTutor extends Component {
  render() {
    return (
      <React.Fragment>
        <BTNMetaTags title="Request A Tutor" />
        
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col md={{size: 10, offset: 1}}>
                <RequestTutorForm />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RequestTutor
