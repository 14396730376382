//Paths For Front End (React App)
export const HOME_LOGIN = "/login"
export const HOME_LOGOUT = "/logout"
// export const HOME_REGISTER = "/register"
export const HOME_FORGOT_PASSWORD = "/forgot-password"
export const HOME_DASHBOARD = "/dashboard"

//Search
export const SEARCH_TUTORS = "/search-tutors"

//ALL USERS
export const USER_ACCOUNT_EDIT = "/edit-account"
export const USER_TUTOR_SESSIONS = "/tutor-sessions"; //+userId
export const PAYMENT_SETTINGS = "/payment-settings"
export const CART_VIEW_DETAILS = "/cart";
export const CART_ORDER_CONFIRMATION = "/cart/confirmation";
export const CART_ORDER_SUCCESS = "/cart/success";

//Students

export const STUDENT_REQUEST_TUTOR = "/request-a-tutor"
export const STUDENT_TUTOR_REQUESTS = "/students/my-tutor-requests"; // + studentId
export const STUDENT_TUTOR_REQUEST_PROGRESS = "/students/tutor-request"; // + tutorRequestId
export const STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION= "/students/tutor-proposal/scholarship-confirmation"; // + tutorContractId
export const STUDENT_MARKETPLACE_HOW_IT_WORKS =  "/students/tutor-requests/how-it-works";
export const STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS =  "/students/tutor-request-progress";

export const STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE = "/students/tutor-proposal/select-package"; // + tutorContractId
export const STUDENT_EVALUATION_FORM = "/students/evaluation"; // + assignmentId

// Tutors
// export const BECOME_TUTOR = "/become-a-tutor"
const tutor_prefix = "/tutors";
export const TUTOR_PROFILE = tutor_prefix + "/tutor-profile" // +tutorId
export const TUTOR_LIST_TUTOR_CONTRACTS = tutor_prefix + "/my-tutor-requests"
export const TUTOR_ADD_RETURNING_STUDENT = tutor_prefix + "/add-returning-student"
export const TUTOR_EVALUATIONS = tutor_prefix + "/evaluations"
export const TUTOR_CONNECT_TO_STRIPE = tutor_prefix + "/connect-to-stripe"
export const TUTOR_LOG_INTO_STRIPE = tutor_prefix + "/log-into-stripe"
export const TUTOR_CHECKLIST = tutor_prefix + "/tutor-checklist"
export const TUTOR_TAX_INFO = tutor_prefix + "/taxes"

export const TUTOR_CONTRACTOR_AGREEMENT = tutor_prefix + "/sign-contract"

export const TUTOR_SWITCH_TO_STRIPE_EXPRESS = tutor_prefix + "/switch-account-to-stripe-express"
export const TUTOR_MARKETPLACE_HOW_IT_WORKS = tutor_prefix + "/tutor-requests/how-it-works";
export const TUTOR_MARKETPLACE = tutor_prefix + "/tutor-requests"
export const TUTOR_MARKETPLACE_CREATE_PROPOSAL = tutor_prefix + "/tutor-requests/create-proposal"
export const TUTOR_MARKETPLACE_PENDING_PROPOSALS = tutor_prefix + "/tutor-requests/pending-proposals"

//Proficiency Exams
export const PROFICIENCY_EXAM_TAKE_ASSESSMENT = "/assessments/take-assessment" // + assessmentId
export const PROFICIENCY_EXAM_LIST = "/proficiency-exams/exams-list"
export const PROFICIENCY_EXAM_LOAD_ASSESSMENT_SOLUTIONS = "/assessments/load-assessment-solutions" // + assessmentId
//Leads
const lead_prefix = "/leads"
export const LEADS_LIST_ACTIVE = lead_prefix + "/active-leads";

//ADMIN
export const ADMIN_USER_MANAGER = "/admin/user-manager"
export const ADMIN_USER_DETAILS = "/admin/user" //+ userId
export const ADMIN_TUTOR_REQUESTS = "/admin/tutor-requests"
export const ADMIN_TUTOR_APPLICATIONS = "/admin/tutor-applications"
export const ADMIN_TUTOR_APPLICATION_DETAILS = "/admin/tutor-applications/applicant"
export const ADMIN_ASSIGNMENTS = "/admin/assignments"
export const ADMIN_EVALUATIONS = "/admin/evaluations"
export const ADMIN_REFUNDS = "/admin/refunds"
export const ADMIN_REFUNDS_ISSUE_BALANCE_REFUND = "/admin/refunds/issue-balance-refund"
export const ADMIN_ANALYTICS = "/admin/analytics"
export const ADMIN_SCHOLARSHIPS = "/admin/scholarships"
export const ADMIN_GOOGLE_AUTH_CALLBACK = "/admin/google-auth-callback"
export const ADMIN_GOOGLE_SUBMIT_BATCH_URLS = "/admin/google/submit-batch-urls"
export const ADMIN_GOOGLE_MY_BUSINESS = "/admin/google/business"
export const ADMIN_GMAIL_INBOX = "/admin/gmail-inbox";

export const ADMIN_INBOX = "/admin/inbox";

export const ADMIN_MARKETPLACE = "/admin/marketplace";
export const ADMIN_MARKETPLACE_CONVERT_TUTOR_REQUEST = "/admin/marketplace/convert-tutor-request"; //+tutorReqID

//POLICIES
export const POLICIES_FAQS = "/policies/faqs"

export const CHAT_INBOX = "/inbox";

