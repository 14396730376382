import React, { Component } from 'react'
import { Card, CardBody, Input, Label} from 'reactstrap'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';

export default class AddFundsToAdAccountForm extends Component {
    onSubmit = (values) => {
        this.props.onSubmit(values);
    }

    render() {
        let initialValues = {amount: 5}
        const FundsSchema = Yup.object().shape({
                                    amount: Yup.number()
                                                .min(5)
                                                .required('Please enter an amount greater than 5'),
                                });
        return (
            <Card className="overflow-hidden" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.3)"}}>
                <CardBody className="pt-6">
                    <div className="p-2">
                        <b>Add Funds</b>
                        <br /><br />
                        <Formik
                            initialValues={{...initialValues}}
                            validationSchema={FundsSchema}
                            onSubmit={this.onSubmit}
                        >
                        { props  => (
                            <Form className="needs-validation">
                                <div className="form-group">
                                    <Label>Amount</Label>
                                    <Input
                                        name="amount"
                                        className="form-control"
                                        placeholder="Amount"
                                        type="number"
                                        min={5}
                                        value={props.values.amount}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                </div>                           
                                <div className="mt-3">  
                                    <button
                                        className={`btn btn-sm btn-primary btn-block waves-effect waves-light`}
                                        type="submit"
                                    >
                                        Add Funds
                                    </button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </CardBody>
            </Card>
        )
    }
}
