const sitemap_courses = 
[
    "algebra",
    "anatomy",
    "biology",
    "calculus",
    "cell_biology",
    "chemistry",
    "college_algebra",
    "college_biology",
    "college_chemistry",
    "college_math",
    "college_physics",
    "composition",
    "creative_writing",
    "differential_equations",
    "english_literature",
    "finite_math",
    "general_biology",
    "general_chemistry",
    "general_physics",
    "geometry",
    "human_anatomy",
    "human_physiology",
    "integral_calculus",
    "integral_geometry",
    "kinesiology",
    "linear_algebra",
    "math",
    "microbiology",
    "microeconomics",
    "molecular_biology",
    "multivariable_calculus",
    "organic_chemistry",
    "physics",
    "physiology",
    "pre_algebra",
    "pre_calculus",
    "probability",
    "quantitative_reasoning",
    "science",
    "sociology",
    "spanish",
    "statistics",
    "trigonometry",
    "weather_and_climate",
    "writing"
]

const sitemap_high_school_courses = [
    "ap_biology",
    "ap_calculus",
    "ap_chemistry",
    "ap_physics",
    "ap_spanish_language",
    "ap_spanish_literature_and_culture",

    "high_school_algebra",
    "high_school_biology",
    "high_school_chemistry",
    "high_school_english",
    "high_school_language_arts",
    "high_school_math",
    "high_school_physics",
    "high_school_science",
    "high_school_writing",

    "ib_biology",
    "ib_chemistry",
    "ib_english",
    "ib_french",
    "ib_mathematics",
    "ib_physics",
    "ib_spanish",
    "ib_writing",

    "integrated_math",
    "language_arts",
    "physical_science",
]

export { sitemap_courses, sitemap_high_school_courses }