import React, { Component } from 'react'

import "./css/covid.css"
import "./js/custom.js"

export default class AnfraVersion2 extends Component {
  //Step Class = covid-test-wrap test-step
  
  render() {
    let { leftTitle, leftSubtitle=null, leftImage=null, leftContent=null, leftList=[],
          progressBar=true, rightTitle, showStepNumber=false } = this.props;
    
    return (
      <div className="ugf-covid covid-bg">
        <div className="pt100">
          <div className="container">
            <div className="row">
              {/* Left Side */}
              <div className="col-xl-4 col-lg-5">
                <div className="covid-tips">
                  <h3>{leftTitle}
                    {
                      leftSubtitle != null &&
                        <>
                            <br /> 
                            <small>{leftSubtitle}</small>
                        </>
                    }
                  </h3>
                  
                  { leftContent  &&
                      <div className="tips">
                        <div className="content">
                        <p>{leftContent}</p>
                        </div>
                      </div>
                  }
                  
                  {
                    leftList.map((item, i) =>
                      <div className="tips" key={"div-"+i}>
                        {
                          item.image && 
                            <div className="icon">
                              <img src={item.image} className="img-fluid" alt="" />
                            </div>
                        }
                        <div className="content">
                          <h4>{item.title}</h4>
                          <p>{item.text}</p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>

              {/* Right Form */}
              <div className="col-xl-5 offset-xl-3 col-lg-6 offset-lg-1">
                <div className="covid-wrap">
                  <div className="covid-header">
                    
                    {
                      typeof rightTitle == "string" 
                        ? <h2>{rightTitle}</h2>
                        : <>{rightTitle}</>
                    }

                    {showStepNumber &&  <span className="step-count"><span className="current-step">1</span>/<span className="total-step">7</span></span>}
                  </div>
                  <form action="#" className="pb70" id="version2-form">
                    {
                      progressBar == true && 
                        <div className="progress" style={{marginBottom: "5em"}}>
                            <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    }
                    {this.props.children}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
