import PropTypes from 'prop-types';
import React, { Component } from "react";
import VerticalTimelineItem from "./VerticalTimelineItem";

export default class VerticalTimeline extends Component {
  render() {
    let { statuses } = this.props;
    
    return (
      <React.Fragment>
        <div className="">
          <ul className="verti-timeline list-unstyled">
            {statuses.map((status, key) => (
              <VerticalTimelineItem
                active={status.active}
                icon={status.icon} 
                date={status.date}
                title={status.title} 
                description={status.description}
                details={status.details}
                completed={status.completed}
                key={"_status_" + key}
              />
            ))}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

VerticalTimelineItem.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.object),
}
