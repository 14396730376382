import React, { Component } from 'react'

import { connect } from "react-redux"

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { ThemeSpinner } from 'theme/elements/spinner';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import BTNMetaTags from "components/metatags/BTNMetaTags"
import TutorRequestDetailsTableAdmin from 'components/tables/marketplace/TutorRequestDetailsTableAdmin';
import { FormLayout } from 'components/forms/marketplace/formLayout';
import SubjectsDualList from 'components/dualList/SubjectsDualList';
import MarketPlaceKanban from 'components/kanban/MarketPlaceKanban';
import { camelCaseToWords } from 'scripts/general';
import { Link } from 'react-router-dom';
import { ADMIN_MARKETPLACE, ADMIN_TUTOR_REQUESTS } from 'routes/paths';
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton';

import DuplicateTutorRequestForm from 'components/forms/formik/marketplace/DuplicateTutorRequestForm';
import EditTutorRequestForm from 'components/forms/formik/marketplace/EditTutorRequestForm';
import CreateMarketRequestForm from 'components/forms/formik/marketplace/CreateMarketRequestForm';
import BasicModal from 'components/modals/BasicModal';

class MarketConvertTutorRequest extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        tutorRequestId: props.match.params.tutorRequestId,
        tutorRequest: {},

        // exclusive: false,
        exclusiveTutorIds: [],
        exclusiveColumn: {
                              id: "exclusive",
                              title: "Exclusive",
                              cards: []
                          },
      
        bannedTutorIds: [],
        bannedTutorColumn: {
                              id: "banned",
                              title: "Rejected / Banned",
                              cards: []
                          },
        showSubjects: false,
        subjects: [],
        subjectIds: [],

        showDuplicateForm: false,
        showEditForm: false,

        googleModal: false,
        googleResults: [],

        showTutorSearch: false
      }
    }

    componentDidMount() { 
      const { onSubmitApiCall } = this.props;
      const { tutorRequestId } = this.state;
      onSubmitApiCall("getTutorRequest", tutorRequestId);
    }

    componentDidUpdate(prevProps, prevState) {
      let { apiResponse = {} } = this.props;
      
      if(prevProps != this.props){
          let newState = processApiResponse(apiResponse, this.state);

          if(apiResponse != {} && prevState != newState){
              this.setState(newState);
          }
      }
    }

    toggleDuplicateForm = () =>{
      let { showDuplicateForm } = this.state;
      showDuplicateForm = !showDuplicateForm;
      const showEditForm = false;
  
      this.setState({ showEditForm, showDuplicateForm });
    }
  
    toggleEditForm = () =>{
      let { showEditForm } = this.state;
      showEditForm = !showEditForm;
      const showDuplicateForm = false;
  
      this.setState({ showEditForm, showDuplicateForm });
    }

    updateNoTutorAvailable = tutorRequestId => {
        if(confirm("Are you sure there are NO TUTORS AVAILABLE?")){
          let { onSubmitApiCall } = this.props;
          onSubmitApiCall("patchNoTutorAvailable", tutorRequestId );
        }
    }

    deleteTutorRequest = tutorRequestRefId => {
      if(confirm("Are you sure you want to DELETE this tutor request?")){
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("deleteTutorRequest", tutorRequestRefId);
      }
    }

    duplicateTutorRequest = (values) => {
      let { onSubmitApiCall } = this.props;
      let { tutorRequestId } = this.state;
      onSubmitApiCall("postAdminDuplicateTutorRequest", {...values, tutorRequestId });
    }
  
    updateTutorRequest = (values) => {
      let { onSubmitApiCall } = this.props;
      let { tutorRequestId } = this.state;
      
      onSubmitApiCall("patchAdminUpdateTutorRequest", {...values, tutorRequestId });
    }

    searchCourseOnGoogle = tutorRequest => {
      let { onSubmitApiCall } = this.props;
      let query = tutorRequest.course + " " + tutorRequest.school;
      onSubmitApiCall("adminGoogleSearchCourse", {query});
    }

    addSubjectsToTutorRequest = subjectIds =>{
      const { onSubmitApiCall } = this.props;
      const { tutorRequestId } = this.state;

      let subjects = [];

      subjectIds.forEach(subjectId => {
          subjects.push(subjectId)
      })
      
      let formData = {subjects, tutorRequestId}
      
      onSubmitApiCall("updateTutorRequestSubjects", formData);
    }

    onBoardChange = ({columns}) =>{
      let exclusiveTutors = columns.find(col => col.id == "exclusive").cards;
      let bannedTutors = columns.find(col => col.id == "banned").cards;
      
      let exclusiveTutorIds = exclusiveTutors.map(exclusiveTutor => exclusiveTutor.id);
      let bannedTutorIds = bannedTutors.map(banned => banned.id); ;

      this.setState({exclusiveTutorIds, bannedTutorIds});
    }

    convertTutorRequest = formData =>{
      const { onSubmitApiCall } = this.props;
      const { tutorRequestId, exclusiveTutorIds, bannedTutorIds } = this.state;

      formData["tutorRequestId"] = tutorRequestId;
      formData["exclusiveTutorIds"] = exclusiveTutorIds;
      formData["bannedTutorIds"] = bannedTutorIds;

      onSubmitApiCall("createMarketRequestFromTutorRequest", formData);
    }

    render() {
      let { tutorRequest, subjectIds=[], showSubjects, exclusiveTutorIds, bannedTutorIds,
            showDuplicateForm, showEditForm,
          googleResults, googleModal, showTutorSearch } = this.state;
      let { apiResponse } = this.props;
      
      let loading = apiResponse.loading

      let subjectNames = [];

      if(tutorRequest.subjects && subjectIds.length == 0){
        subjectIds = tutorRequest.subjects.map(subject => subject.id);
        subjectNames = tutorRequest.subjects.map(subject => camelCaseToWords(subject.name));
      }
      
      return (
          <>
            <BTNMetaTags title="MarketPlace" />
            {loading && <center><ThemeSpinner /></center>}
            <Link to={ADMIN_TUTOR_REQUESTS}>Back To Tutor Requests</Link>
            <br />
            <Link to={ADMIN_MARKETPLACE}>Go To MarketPlace</Link>
            <br />
            <h3>Convert To Market Request</h3>
            {
              !tutorRequest.id ?
                  <Row>
                    <Col md="6">
                      <ThemeSkeleton />
                    </Col>
                  </Row>
                  
                :
                  <>
                    <BasicModal isOpen={googleModal}
                        onClosed={() => this.setState({googleModal: false})}
                        header={`Google Search: ${tutorRequest.course} @ ${tutorRequest.school}`}
                        toggleModal={modalState => this.setState({googleModal: modalState})}>
                      <Row>
                        <Col><button className="btn btn-md btn-outline-warning" onClick={() => this.searchCourseOnGoogle(tutorRequest)} disabled={loading}>Search</button></Col>
                        <Col><a href={"http://www.google.com/search?q=" + tutorRequest.course.replace(/ /g, "+") + "+" + tutorRequest.school.replace(/ /g, "+")} target="_blank" rel="noreferrer">{tutorRequest.course} @ {tutorRequest.school}</a> </Col>
                      </Row>
                      <br />
                      {loading && <center><ThemeSpinner /></center>}
                      {
                        googleResults.map((result, i) => 
                          <div key={"result-" + i}>
                            <b><a onClick={() => {navigator.clipboard.writeText(result.title)}}>{result.title}</a></b><br />
                            <a href={result.url} target="_blank" rel="noreferrer">{result.url}</a>
                            <p>{result.snippet}</p>
                          </div>
                        )
                      }
                    </BasicModal>
                    <Row>
                      <Col md="6">
                        <TutorRequestDetailsTableAdmin key={tutorRequest.id} tutorRequest={tutorRequest} onSearchGoogle={() => {this.setState({googleModal: true});if(googleResults == null || googleResults.length == 0){this.searchCourseOnGoogle(tutorRequest)}}}/>
                        {
                          tutorRequest.noTutorAvailable == true 
                            ? <span className="p-2"><button className="btn btn-sm btn-warning" disabled={true}>None Available</button></span>
                            :  <span className="p-2"><button className="btn btn-sm btn-outline-warning" onClick={() => this.updateNoTutorAvailable(tutorRequest.id)}>None Available</button></span>
                        }
                        
                        <span className="p-2"><button className="btn btn-sm btn-outline-danger" onClick={() => this.deleteTutorRequest(tutorRequest.refId)}><i className="bx bxs-trash" /></button></span>
                        <br /><br />
                        <Row>
                          <Col>
                          {subjectNames.map((name, i) => 
                              <span key={"span-" + i} className="badge badge-secondary" style={{fontSize: "0.75rem", margin: 3}}>{name}</span>
                          )}
                          </Col>
                        </Row>
                        <a className="text-primary" onClick={() => this.setState({showSubjects: !showSubjects})}>{showSubjects ? <>Hide Subjects</> : <>Show Subjects</>}</a>
                        <br />
                        <a className="text-primary" onClick={this.toggleDuplicateForm}>Duplicate Request</a>
                        <br />
                        <a className="text-primary" onClick={this.toggleEditForm}>Edit Request</a>
                        <br /><br />
                        <a className="text-primary" onClick={() => this.setState({showTutorSearch: !showTutorSearch})}>{showTutorSearch ? <>Hide Tutor Search</> : <>Show Tutor Search</>}</a>
                        <br /><br />
                        {
                          showSubjects &&
                            <SubjectsDualList onSubmit={this.addSubjectsToTutorRequest} selected={subjectIds}/>
                        }
                        
                        <br />
                        
                            {
                              showDuplicateForm && 
                              <Card>
                                <CardBody>
                                  <CardTitle>Duplicate Request</CardTitle>   
                                  <DuplicateTutorRequestForm onSubmit={this.duplicateTutorRequest} course={tutorRequest.course} loading={loading} />
                                </CardBody>
                              </Card>
                            }
                            {
                              showEditForm && 
                                <Card>
                                  <CardBody>
                                    <CardTitle>Edit Request</CardTitle>   
                                    <EditTutorRequestForm onSubmit={this.updateTutorRequest} {...tutorRequest} loading={loading} />
                                  </CardBody>
                                </Card>
                            }
                      </Col>
                      <Col md="6">
                        <FormLayout>
                          {
                            tutorRequest.subjects && tutorRequest.subjects.length == 0 
                              ?
                                <>Add Subjects to tutor request first.</>
                              :
                                <>
                                  <CreateMarketRequestForm onSubmit={this.convertTutorRequest} loading={loading} exclusiveCount={exclusiveTutorIds.length} bannedCount={bannedTutorIds.length} {...tutorRequest} />
                            </>    
                          }
                        </FormLayout>
                      </Col>
                    </Row>
                    {
                      showTutorSearch &&
                        <>
                          <hr />
                          <h3>Add Exclusive or Banned Tutors</h3>
                          <Card>
                            <CardBody>
                              <MarketPlaceKanban key={"kanban-"+tutorRequest.id} tutorRequest={tutorRequest} onBoardChange={this.onBoardChange}/>
                              {loading && <center><ThemeSpinner /></center>}
                            </CardBody>
                          </Card>
                        </>
                    }
                  </>
            }
          </>
      )
    }
}
const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
export default connect(mapStateToProps, mapDispatchToProps)(MarketConvertTutorRequest)
  