import { GET_MARKETPLACE, GET_MARKETPLACE_ADMIN,
          // GET_MARKET_REQUEST_PROGRESS,
          GET_MARKET_TUTOR_REQUEST_WITH_PROPOSALS,

          POST_CREATE_MARKET_REQUEST_FROM_TUTOR_REQUEST,  
          POST_CREATE_MARKET_PROPOSAL,
          PATCH_UPDATE_PROPOSAL_RATE,
          POST_STUDENT_ACCEPT_PROPOSAL,
          GET_MARKET_REQUEST,
          PATCH_REJECT_PROPOSAL,
          PATCH_EXTEND_PROPOSAL_DEADLINE,
          PATCH_TUTOR_REACTIVATE_PROPOSAL,
          POST_DELETE_MARKET_REQUEST,
          POST_DELETE_MARKET_PROPOSAL,
          PATCH_ENABLE_MARKET_PROPOSAL,
          PATCH_DISABLE_MARKET_PROPOSAL,
          GET_PENDING_PROPOSALS_FOR_TUTOR,
          POST_GOOGLE_SEARCH_COURSE,
        
        } from 'api/urls';
import { get, post, postFormData, patch, del } from "helpers/api_helper"

export const getMarketPlace = tutorId => {
  return get(GET_MARKETPLACE + "/" + tutorId);
}

export const getAdminMarketPlace = () => {
  return get(GET_MARKETPLACE_ADMIN);
}

export const createMarketRequestFromTutorRequest = formData => {
  return post(POST_CREATE_MARKET_REQUEST_FROM_TUTOR_REQUEST, formData);
}

export const getMarketRequest= marketRequestId => {
  return get(GET_MARKET_REQUEST + "/" + marketRequestId);
}

export const createMarketProposal = formData => {
  return post(POST_CREATE_MARKET_PROPOSAL, formData);
}

export const getPendingProposalsForTutor= tutorRefId => {
  return get(GET_PENDING_PROPOSALS_FOR_TUTOR + "/" + tutorRefId);
}

export const updateProposalRate = formData => {
  return patch(PATCH_UPDATE_PROPOSAL_RATE + "/" + formData.proposalId, formData);
}

export const rejectProposal = formData => {
  return patch(PATCH_REJECT_PROPOSAL + "/" + formData.proposalId, formData);
}

export const extendProposalDeadline = proposalId => {
  return patch(PATCH_EXTEND_PROPOSAL_DEADLINE + "/" + proposalId);
}

export const reactivateProposal = proposalId => {
  return patch(PATCH_TUTOR_REACTIVATE_PROPOSAL + "/" + proposalId);
}

export const acceptProposalCreateContract = proposalId => {
  return post(POST_STUDENT_ACCEPT_PROPOSAL + "/" + proposalId);
}

export const getTutorRequestWithProposals = tutorRequestId => {
  return get(GET_MARKET_TUTOR_REQUEST_WITH_PROPOSALS + "/" + tutorRequestId);
}


//ADMIN
export const adminDeleteMarketRequest = proposalId => {
  return post(POST_DELETE_MARKET_REQUEST + "/" + proposalId);
}

export const adminDisableProposal = proposalId => {
  return patch(PATCH_DISABLE_MARKET_PROPOSAL + "/" + proposalId);
}

export const adminEnableProposal = proposalId => {
  return patch(PATCH_ENABLE_MARKET_PROPOSAL + "/" + proposalId);
}

export const adminDeleteProposal = proposalId => {
  return post(POST_DELETE_MARKET_PROPOSAL + "/" + proposalId);
}

export const adminGoogleSearchCourse = formData => {
  return post(POST_GOOGLE_SEARCH_COURSE, formData);
}