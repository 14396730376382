
$(document).ready(function() {
	'use strict';

  var currentStep = $('.current-step').html();
  var totalStep = $('.total-step').html();

  //BTN ADDED
  var progress = document.querySelectorAll('.test-step');
  var progressUnit = 100 / progress.length;
  var progressWidth = 20;
  $('.progress-bar').width(progressWidth + '%');
  ////

  function checkStep() {

    if(currentStep == totalStep) {
      $('.step-count').addClass('final-step');

    } else if(currentStep > totalStep) {
      $('.covid-header').addClass('no-step');
      $('.step-count').removeClass('final-step');

    } else {
      $('.step-count').removeClass('final-step');
      $('.covid-header').removeClass('no-step');
    }

  }

  $('.test-step .button').on('click', function(e) {
  	e.preventDefault();

    currentStep++;
    $('.current-step').html(currentStep);
    checkStep();

    $(this).parents('.test-step').next().addClass('active');
    $(this).parents('.test-step').removeClass('active');

    //BTN ADDED
    $("html").scrollTop($("#version2-form").offset().top - 100);
    progressWidth += progressUnit;
    /////
    
    $('.progress-bar').width(progressWidth + '%');

  })

  $('.test-step .prev-btn').on('click', function(e) {

    e.preventDefault();

    currentStep--;
    $('.current-step').html(currentStep);
    checkStep();

    $(this).parents('.test-step').prev().addClass('active');
    $(this).parents('.test-step').removeClass('active');

    //BTN ADDED
    $("html").scrollTop($("#version2-form").offset().top - 100);
    progressWidth -= progressUnit;
    //////

    $('.progress-bar').width(progressWidth + '%');

  })

})
