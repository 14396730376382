import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { apiError, requestPasswordReset } from "store/actions"

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

import AuthHeader from "./AuthHeader"
import BTNMetaTags from "components/metatags/BTNMetaTags"
import ForgotPasswordForm from "components/forms/formik/authentication/ForgotPasswordForm"


class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }


  componentDidMount() {
    this.props.apiError("");
  }

  // handleValidSubmit {
  handleValidSubmit(values) {
    this.props.requestPasswordReset(values.email);
  }

  render() {
    let { loading, error } = this.props;

    return (
      <React.Fragment>
        <BTNMetaTags title="Forgot Password" />
        
        <div className="account-pages my-3 pt-sm-3">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <AuthHeader title="BuffTutor Login" subtitle="Forgot Password" />
                  <CardBody className="pt-6">

                    <div className="p-2">
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      
                      <p>
                          Enter your email address to reset password.
                      </p>
                      <ForgotPasswordForm onSubmit={this.handleValidSubmit}>
                        {
                            loading
                              ?
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                  disabled={true}
                                >
                                  Submitting ... {" "}
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                
                                </button>
                              :
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                >
                                  Send Email
                                </button>
                          }
                      </ForgotPasswordForm>
                      
                      <div className="mt-4 text-center">
                        <Link to="/login" className="text-muted">
                          <i className="mdi mdi-lock mr-1" /> Log Into Account
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} BuffTutor Network.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { error, loading } = state.Login
  return { error, loading }
}

export default connect(mapStateToProps, { apiError, requestPasswordReset })(ForgotPassword)