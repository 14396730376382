import React, { Component } from 'react'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import { ThemeButtonLink } from 'theme/elements/buttons'


export default class SuccessPage extends Component {
    
    render() {
        return (
            <>
                <BTNMetaTags title="Success" />
                
                <Container fluid={true}>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">
                                    <center>Payment Success!</center>
                                </h4>

                                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                    <h5 className="text-success" style={{fontSize: "5em"}}>
                                        <center><i className={"bx bx-check-circle text-success"} /></center>
                                    </h5>
                                    <center>
                                            <ThemeButtonLink to="/">
                                                Back To Dashboard
                                            </ThemeButtonLink>
                                    </center>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </Container>
            </>
        )
    }
}
