import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
//import Account from "./auth/register-THEME/reducer"
//import ForgetPassword from "./auth/forgetpwd-THEME/reducer"
//import Profile from "./auth/profile/reducer"

//BTN
import alertsReducer from "./alerts/reducer"
import apiResponseReducer from "./api/reducer"
import toastReducer from "./alerts/toastReducer"

import assignmentsReducer from "./assignments/reducer"
import cartReducer from "./cart/reducer"
import chatsReducer from "./chat/reducer"
import profileReducer from "./profile/reducer"
import tutorApplicationReducer from "./tutorApplication/reducer"
import tutorContractsReducer from "./tutorContracts/reducer"
import tutorRequestsReducer from "./tutorRequests/reducer"
import tutorSessionsReducer from "./tutorSessions/reducer"
import userReducer from "./user/reducer"
import subjectsReducer from "./subjects/reducer"

import inboxReducer from "./inbox/reducer"

import { logoutBTNUser } from "scripts/authentication"
import { CLONE_USER, LOGOUT_USER } from "./auth/login/actionTypes"

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  //Account,
  //ForgetPassword,
  //Profile,

  //BTN
  alertsReducer,
  apiResponseReducer,
  toastReducer,

  assignmentsReducer,
  cartReducer,
  chatsReducer,
  profileReducer,
  tutorApplicationReducer,
  tutorContractsReducer,
  tutorRequestsReducer,
  tutorSessionsReducer,
  userReducer,
  subjectsReducer,

  inboxReducer,
})

const rootReducer = (state, action) => {
  let history = null;

  switch (action.type) {
    case LOGOUT_USER:
      history  = action.payload.history;
    
      history.push("/login");
      logoutBTNUser();

      return appReducer(undefined, action);

    case CLONE_USER:
      const { user, token, tokenExpiration } = action.payload;
      history  = action.payload.history;

      logoutBTNUser();
      localStorage.setItem("btnToken", token); 
      localStorage.setItem("tokenExpiration", tokenExpiration);

      history.push("/");
      
      return appReducer({userReducer: user}, action);
        
    default:
      return appReducer(state, action);
  }

}

export default rootReducer
