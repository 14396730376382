import React from "react"
import { ReactComponent as CopySvg } from "theme-chat/assets/media/icons/copy.svg";
import { ReactComponent as ForwardSvg } from "theme-chat/assets/media/icons/forward.svg";
import { ReactComponent as DeleteSvg } from "theme-chat/assets/media/icons/delete.svg";
import { ReactComponent as FlagSvg } from "theme-chat/assets/media/heroicons/solid/flag.svg";
import { ReactComponent as UnflagSvg } from "theme-chat/assets/media/heroicons/outline/flag.svg";

const copyMessage = message => {
    document.body.click();
    if(!message.image && !message.attachment){
        navigator.clipboard.writeText(message.body);
    }
}

const flagMessage = message => {
    document.body.click();
    if(confirm("Please confirm that this message is problematic and needs to be reported.")){

    }
}

const unflagMessage= message => {
    document.body.click();
    if(confirm("Please confirm that this message can be unflagged.")){
        
    }
}

const disableMessage= message => {
    document.body.click();
    if(confirm("Please confirm that this message needs to be disabled.")){
        
    }
}

const enableMessage= message => {
    document.body.click();
    if(confirm("Please confirm that this message needs to be disabled.")){
        
    }
}

export const UserMessageDropdownOptions = [
    {id: 1, title: "Copy", icon: <CopySvg />,function: copyMessage},
    {id: 2, title: "Flag", icon: <FlagSvg />, function: flagMessage}
]

export const AdminMessageDropdownOptions = [
    {id: 1, title: "Copy", icon: <CopySvg />,function: copyMessage},
    {id: 2, title: "Flag", icon: <FlagSvg />, function: flagMessage},
    {id: 3, title: "Unflag", icon: <UnflagSvg />, function: unflagMessage},
    {id: 4, title: "Disable", icon: <DeleteSvg />, function: disableMessage},
    {id: 5, title: "Enable", icon: <ForwardSvg />, function: enableMessage},
];

