import React, { Component } from "react"
import { TabPane, Row, Col, FormGroup, Label, Input } from "reactstrap"

class TutorDetailsTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      education_levels: [
            {level: 'phd', description: "Doctorate (PhD)"},
            {level: 'md', description: "Doctor of Medicine (MD)"},
            {level: 'masters', description: "Master's Degree"},
            {level: 'bachelors', description: "Bachelor's Degree"},
            {level: 'grad_student', description: "Graduate Student"},
            {level: 'undergrad', description: "Undergraduate Student"},
            {level: 'associates', description: "Associate's Degree"}
      ]
    }
  }

  render() {
    return (
      <React.Fragment>
        <TabPane tabId={this.props.tabId}>
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="major">
                  Majors / Minors + Highest Education Level* 
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="major"
                  name="major"
                  placeholder="Your Majors & Minors"
                  onChange={this.props.onChange}
                  value={this.props.formData.major}
                />
                <br />
                <Input
                  type="select"
                  className="form-control"
                  id="education"
                  name="education"
                  onChange={this.props.onChange}
                  value={this.props.formData.education}
                >
                    <option>Select Education Level</option>
                    {this.state.education_levels.map((education_level, index) =>
                      <option
                        key={"education-"+index}
                        value={education_level.level}
                      >
                        {education_level.description}
                      </option>
                    )}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="languages">
                  Languages You Speak/Teach In*
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="languages"
                  name="languages"
                  placeholder="English, Spanish, etc"
                  onChange={this.props.onChange}
                  value={this.props.formData.languages}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
          <Col lg="6">
            <Label>Type Of Tutoring You Offer*</Label>
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="inPerson"
                  name="inPerson"
                  checked={this.props.formData.inPerson}
                  onChange={this.props.onChangeChecked}
                />
                <label
                  className="custom-control-label"
                  htmlFor="inPerson"
                >
                  In Person
                </label>
              </div>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="online"
                  name="online"
                  checked={this.props.formData.online}
                  onChange={this.props.onChangeChecked}
                />
                <label
                  className="custom-control-label"
                  htmlFor="online"
                >
                  Online Tutoring
                </label>
              </div>
            </FormGroup>
            
          </Col>
          </Row>
        </TabPane>
      </React.Fragment>
    )
  }
}

export default TutorDetailsTab
