import React from "react"
import { UncontrolledTooltip } from "reactstrap"

export const DeleteSessionAction = (props) => {
    //props: id, color, onClick
    return (
        <button id={"delete-session-" + props.id} className={`btn btn-sm btn-${props.color || "danger"}`} onClick={props.onClick}>
            <i className="bx bx-trash bx-xs" />
            <UncontrolledTooltip
                placement="top"
                target={"delete-session-" + props.id}
            >
            Delete Session #{props.id}
            </UncontrolledTooltip>
        </button>
    )
}

export const CancelSessionAction = (props) => {
    //props: id, color, onClick
    return (
        <button id={"cancel-session-" + props.id} className={`btn btn-sm btn-${props.color || "warning"}`} onClick={props.onClick}>
            <i className="bx bx-x-circle bx-xs" />
            <UncontrolledTooltip
                placement="top"
                target={"cancel-session-" + props.id}
            >
            Cancel Session #{props.id}
            </UncontrolledTooltip>
        </button>
    )
}

export const RefundSessionAction = (props) => {
    //props: id, color, onClick
    return (
        <button id={"refund-session-" + props.id} className={`btn btn-sm btn-${props.color || "info"}`} onClick={props.onClick}>
            <i className="bx bx-undo bx-xs" />
            <UncontrolledTooltip
                placement="top"
                target={"refund-session-" + props.id}
            >
            Refund Session #{props.id}
            </UncontrolledTooltip>
        </button>
    )
}