import { get, post, put, patch, del } from "../helpers/api_helper"
import { GET_LOAD_TUTOR_PROFICIENCY_EXAMS, POST_CREATE_ASSESSMENT, 
         GET_USER_ASSESSMENTS, GET_USER_ASSESSMENT_GRADES,
         GET_LOAD_ASSESSMENT, PATCH_SUBMIT_ASSESSMENT, GET_LOAD_ASSESSMENT_SOLUTIONS } from "../api/urls"

export const loadTutorProficiencyExams = () => {
   return get(GET_LOAD_TUTOR_PROFICIENCY_EXAMS); 
}

export const getUserAssessments = userId => {
   return get(GET_USER_ASSESSMENTS + "/" + userId);
}

export const getUserAssessmentGrades = userId => {
   return get(GET_USER_ASSESSMENT_GRADES + "/" + userId);
}

export const createAssessments = (profIdArray) => {
   return post(POST_CREATE_ASSESSMENT, profIdArray);
}

export const loadAssessment = (assessmentId) => {
   return get(GET_LOAD_ASSESSMENT + "/" + assessmentId);
}

export const submitAssessment = (formData) => {
   return patch(PATCH_SUBMIT_ASSESSMENT, formData);
}


//ADMIN
export const loadAssessmentSolutions = (assessmentRefId) => {
   return get(GET_LOAD_ASSESSMENT_SOLUTIONS + "/" + assessmentRefId);
}