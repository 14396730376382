import React, { Component } from 'react'

import { Form, Formik } from 'formik';
import { Col, Row, Card, CardBody, Label, CardTitle, Input, FormGroup } from 'reactstrap'
import { getCurrencySymbol } from "scripts/general";

const FormLayout = props => {
    return (
        <Row>
            {/* <Col md={12} lg={10} xl={8}> */}
            <Col md={12}>
                <Card className="overflow-hidden" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.3)"}}>
                    <CardBody className="pt-6">
                        <div className="p-2">
                            {props.children}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
} 

export default class MarketCreateProposalForm extends Component {

    render() {
        let { marketRequest } = this.props;
        let initialValues = { 
                                studentRate: "",
                                message: "",
                                tutoringType: [],
                            }

        let typeDisabled = marketRequest.distance == null || marketRequest.distance > 50 ? true : false;
        
        let onlineProps = {};
        if(typeDisabled){
            initialValues.tutoringType = ["online"];
            onlineProps = { disabled: true, checked: true }
        }

        return (
            <FormLayout>
                <CardTitle>Submit Proposal</CardTitle>
                <h5>{marketRequest.studentName} - {" "}{marketRequest.course} {" "}</h5>
                
                <div className="p-2">
                    <Formik
                        initialValues={{...initialValues}}
                        // validationSchema={ContactInfoSchema}
                        onSubmit={this.props.onSubmit}
                    >
                    { props  => (
                        <Form className="needs-validation">
                            <div className="form-group">
                                <Label>
                                    {`Student Rate (${getCurrencySymbol(marketRequest.currency || "usd")} / hr)`}
                                    <br />
                                    <small>You will earn this minus our service fees.</small>
                                </Label>
                                <Input
                                    name="studentRate"
                                    className="form-control col-md-6"
                                    placeholder="Hourly Rate"
                                    type="number"
                                    min={15}
                                    max={200}
                                    step={1}
                                    required
                                    value={props.values.studentRate}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                            </div>
                            <br />
                            <div className="form-group">
                                <Label>
                                    Message To Student
                                    <br />
                                    <small style={{fontWeight: "bold", color: "darkred"}}> Do NOT include your full name, email or phone number or external links.</small>
                                </Label>
                                <Input
                                    name="message"
                                    className="form-control"
                                    placeholder="Introductory message to student."
                                    type="textarea"
                                    rows={5}
                                    required
                                    value={props.values.message}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                            </div>
                            <FormGroup check inline>
                                <Input 
                                    type="checkbox" 
                                    id="online" 
                                    name="tutoringType" 
                                    value="online"
                                    {...onlineProps} 
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />

                                <Label check for="online">
                                    Online Tutoring
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Input 
                                    type="checkbox" 
                                    id="inPerson" 
                                    name="tutoringType" 
                                    value="inPerson"  
                                    disabled={typeDisabled} 
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                <Label check for="inPerson">
                                    In-Person Tutoring 
                                    {
                                        !typeDisabled && 
                                        <>
                                            <br />
                                            <small>{marketRequest.studentLocation}
                                                {
                                                    marketRequest.distance != null &&
                                                        <> is {marketRequest.distance.toFixed(0)} miles away</>
                                                }
                                            </small>
                                        </>
                                    }
                                </Label>
                            </FormGroup>
                            {
                                !typeDisabled && 
                                <>
                                    <br /><br />
                                    <p>
                                        If you select the In-Person Tutoring option, you <b>must</b> include a note in your initial message about where the in-person sessions will be occur. 
                                    </p>
                                </>
                            }
                            <div className="mt-3">  
                                {
                                    marketRequest.proposalSubmitted ?
                                        <button
                                            className={`btn btn-sm btn-primary btn-block waves-effect waves-light`}
                                            disabled={true}
                                        >
                                            Proposal Already Submitted
                                        </button>
                                    :
                                        <button
                                            className={`btn btn-sm btn-primary btn-block waves-effect waves-light`}
                                            type="submit"
                                        >
                                            Submit Proposal
                                        </button>
                                }
                            </div>
                        </Form>
                    )}
                    </Formik>
                </div>
            </FormLayout>
        )
  }
}
