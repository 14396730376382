import React, { Component } from 'react'
import { Col, Input, Label, Row } from 'reactstrap';

import { getCurrencySymbol } from 'scripts/general';

export default class TutorView extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            studentRate: 0,
        }
    }

    componentDidMount() { 
        const { details } = this.props;
        let studentRate = Number((details.studentRate || 0));
        this.setState({ studentRate });
    }

    updateStudentRate = () =>{
        //TODO: validation of rate, >0, >15
        if(confirm("Please confirm this rate change.  A notification will be sent to the student.")){
            const { details, onUpdateRate } = this.props;
            let { studentRate = 0 } = this.state;
            
            let proposalId = details.proposalId;
            let studentCurrency = details.studentCurrency;
            studentRate = Number(studentRate).toFixed(2);
            studentRate = Number(studentRate);

            onUpdateRate({ studentRate, proposalId, studentCurrency });
        }
    }

    render() {
        const { details } = this.props;
        let { studentRate } = this.state;

        studentRate = Number(studentRate);
        let btnFeePercent = details.btnFeePercent;
        let btnRate = studentRate * btnFeePercent;
        let tutorEarnings = studentRate - btnRate;

        return (
            <>
                <h4>How Much You Will Earn</h4>
                <br />
                <table className="table table-sm">
                    <tbody>
                        <tr>
                            <td>Student Rate</td>
                            <td>${studentRate.toFixed(2)}/hr</td>
                        </tr>
                        <tr>
                            <td>BuffTutor Fee ({btnFeePercent * 100}%)</td>
                            <td>-${btnRate.toFixed(2)}/hr</td>
                        </tr>
                        <tr style={{fontWeight: "bold"}}>
                            <td>You Earn</td>
                            <td>${tutorEarnings.toFixed(2)}/hr</td>
                        </tr>
                    </tbody>
                </table>
                <br />

                We offer various discounts (typically 5-10%) to students throughout the semester to help keep the cost of tutoring affordable. <b>Discounts created by BuffTutor will NOT affect your earnings.</b>
                <br /><br />

                {
                    !details.matchCreated &&
                        <>
                            <hr className="pt-2 pb-2" />
                            <Row>
                                <Col>
                                    <h5>Need To Change The Rate?</h5>
                                    <p>
                                        Discuss this with the student first and then submit this form <b>BEFORE</b> the student creates a match.  
                                        You will not be able to change the rate after a match is created.
                                    </p>
                                    <Label>Edit Student Rate</Label>{" "}
                                    <small>View your new earnings in the table above.</small>
                                    <Input
                                        type="number"
                                        className="form-control col-md-4 col-xs-6 col-sm-4"
                                        placeholder="Student Rate"
                                        onChange={event => {this.setState({studentRate: event.target.value})}}
                                        value={studentRate > 0 ? studentRate : ""}
                                        min={15}
                                        max={200}
                                    />
                                    
                                    <br />
                                    <button className="btn btn-sm btn-primary" onClick={this.updateStudentRate}>Change Rate To {getCurrencySymbol(details.studentCurrency)}{studentRate.toFixed(2)}/hr </button>
                                </Col>
                            </Row>
                        </>
                }
            </>
        )
    }
}
