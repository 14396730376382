import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardTitle, CardBody } from 'reactstrap'

import { ThemeSpinner } from 'theme/elements/spinner'
import EvaluationsTable from 'components/tables/EvaluationsTable'

class Evaluations extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             evaluations: []
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall, user } = this.props;
        onSubmitApiCall("getTutorEvaluations", user.id);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }


    render() {
        let { evaluations } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="Evaluations" />
                
                <Card>
                    
                    <CardBody>
                        <CardTitle>Evaluations</CardTitle>
                        <p>There are 2 types of evaluations</p>
                        <ul>
                            <li><b>First Session Evaluation:</b> Submitted after the first session</li>
                            <li><b>End Of Semester Evaluation:</b> Becomes available 7 days before the assignment End Date.  Eligible assignments have at least 3 tutor sessions. Includes a profile Reference section.</li>
                        </ul>
                        <center>
                            <p><b>Encourage students to submit a review of you.</b>  <br /> 
                                Students simply need to click the <i className="bx bx-star text-warning" /> next to your name on their Dashboard.</p>
                        </center>
                        {
                            loading &&
                                <center><ThemeSpinner /></center>
                        }
                        <EvaluationsTable key={"eval-" + evaluations.length }  evaluations={evaluations} />
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Evaluations)