import React, { Component } from "react"
import { connect } from "react-redux"

import { ErrorMessage, Form, Formik } from "formik"
import * as Yup from "yup";

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Card, CardBody, Col, Container, FormGroup, Input, Row } from "reactstrap"
import { ThemeSpinner } from "theme/elements/spinner"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import UsersListTable from "components/tables/users/UsersListTable"

class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      deletedUsers: []
    }
    this.getRecentUsers.bind(this)
    this.searchUsers.bind(this)
    this.getDeletedUsers.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;
    
    if(prevProps != this.props){
        let newState = processApiResponse(apiResponse, this.state);

        if(apiResponse != {} && prevState != newState){
            this.setState(newState);
        }
    }
  }

  getRecentUsers = () => {
    let { onSubmitApiCall } = this.props;
    onSubmitApiCall("getAdminRecentUsers");
  }

  searchUsers = ({name}) => {
    let { onSubmitApiCall } = this.props;
    onSubmitApiCall("postAdminSearchUsers", { name });
  }

  //NOT USED
  getDeletedUsers = () => {
    let { onSubmitApiCall } = this.props;
    onSubmitApiCall("getAdminDeletedUsers");
  }

  render() {
    const { apiResponse } = this.props;
    const { users } = this.state
    const SearchSchema = Yup.object().shape({
                          name: Yup.string()
                                  .required('Enter name or email'),
                         });

    const loading = apiResponse.loading;

    return (
      <React.Fragment>
        <BTNMetaTags title="User Manager" />
        
        <Container fluid>
          <Row>
            <Col md={{size: 6}}>
              <a className="text-primary" onClick={this.getRecentUsers}>Load Recent Users</a>
              <br /><br />
              <Formik
                initialValues={{ name: '' }}
                validationSchema={SearchSchema}
                onSubmit={this.searchUsers}
              >
              { props  => (
                <Form className="needs-validation">
                  <Row>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                        <Input
                          name="name"
                          label="Name"
                          value={props.values.name}
                          type="text"
                          
                          placeholder="Name or email"
                          className="form-control"
                          
                          autoFocus={true}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <button
                          className="btn btn-primary btn-block waves-effect waves-light float-right"
                          type="submit"
                      >
                          <i className="bx bx-search-alt" />
                      </button>
                    </Col>
                  </Row>
                 </Form>
              )}
              </Formik> 
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {
                    loading &&
                      <center><ThemeSpinner /></center>
                  }
                  <UsersListTable users={users} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
  apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)