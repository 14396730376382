import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
} from "reactstrap"
import { USER_ACCOUNT_EDIT } from "routes/paths"

import ThemeAvatar from "theme/images/ThemeAvatar"

class UserCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  //Setting Menu
  toggleMenu() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  render() {
    let { user } = this.props;
    let name = user.firstName;
    let avatar = user.avatar;
    let city = user.city;
    let state = user.state;

    let userId = (typeof user.refId == undefined || user.refId == null) ? user.id : user.refId;

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div>
              <div className="mb-4 mr-3">
                {
                  avatar 
                    ? <ThemeAvatar className={"img-thumbnail avatar-md rounded-circle"} avatar={avatar} />
                    : <i className="mdi mdi-account-circle text-primary h1"></i>
                }
              </div>
              
              <div>
                <h5>{name}</h5>
                {
                  city &&
                    <p className="text-muted mb-0"><i className="bx bx-map-pin text-info" />{" "}{city}, {state}</p>
                }
                
                <br />
                <Link to={USER_ACCOUNT_EDIT + "/" + userId} className="btn btn-primary btn-sm me-2 w-md">Edit Info {" "} <i className="mdi mdi-arrow-right ms-1"></i></Link>
              </div>
              <br />
              {
                this.props.children
              }
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default UserCard