import React, { Component } from 'react'
import { connect } from 'react-redux'

import { submitApiCallAction } from "store/actions"

import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import NavTabs from 'components/navTabs/NavTabs';
import TutorSessionsTable from 'components/tables/tutor-sessions/TutorSessionsTable';
import AssignmentsAdminTable from 'components/tables/assignments/AssignmentsAdminTable';
import PaymentTransfersTable from 'components/tables/payments/PaymentTransfersTable';
import EvaluationsTable from 'components/tables/evaluations/EvaluationsTable';
import RefundsTable from 'components/tables/refunds/RefundsTable';

import MiniCard from 'components/cards/MiniCard';

import { getCurrencySymbol, camelCaseToWords} from 'scripts/general';
import SubjectsDualList from 'components/dualList/SubjectsDualList';
import TutorCalendar from 'components/calendar/TutorCalendar';

class TutorDetails extends Component {
    sortResults = () => {
        //Sort Id Desc
        return function (a, b) {
            return a.id < b.id;
        }
    }

    updateSubjects = subjectIds => {
        let { user={} } = this.props;
        let subjects = [];

        subjectIds.forEach(subjectId => {
            subjects.push({id: subjectId})
        })

        let formData = {subjects, tutorId: user.refId};
        
        let { onSubmitApiCall } = this.props;         
        onSubmitApiCall("updateProfileSubjects", formData);
    }

    // /**
    //  * Calendar Methods / API Calls
    //  */
    createTutorCalendar = () => {
        let { user={}, onSubmitApiCall } = this.props;
        onSubmitApiCall("createTutorCalendar", user.refId);
    }

    render() {
        let { user={}, tutor={}, profile={}, apiResponse } = this.props;
        let assignments = tutor.assignments.sort(this.sortResults()) ||[];
        let tutorSessions = tutor.tutorSessions.sort(this.sortResults()) || [];
        let evaluations = tutor.evaluations.sort(this.sortResults()) || [];
        let transfers = tutor.transfers.sort(this.sortResults()) || [];
        let refunds = tutor.refunds.sort(this.sortResults()) || [];
        
        let subjectIds = [];
        let subjectNames = [];

        if(user.subjects){
            subjectIds = user.subjects.map(subject => subject.id);
            subjectNames = user.subjects.map(subject => camelCaseToWords(subject.name));
        }
        
        let courses = <>
                        
                        <Row>
                            <Col sm="8">
                                <Row>
                                    {subjectNames.map((name, i) => 
                                        <Col xs="3" key={"col-" + i}><li>{name}</li></Col>
                                    )}
                                </Row>
                                <SubjectsDualList onSubmit={this.updateSubjects} selected={subjectIds}/>
                            </Col>
                            <Col sm="4">
                                <p style={{ whiteSpace: "pre-wrap", overflowX: "auto"}}>{profile.courses}</p>
                            </Col>
                        </Row>
                    </>

        let experience = <><h3>Experience</h3><p style={{ whiteSpace: "pre-wrap"}}>{profile.experience}</p></>
        
        let profileTabs = [{id: 1, title: "Courses", content: courses},
                            {id: 2, title: "Experience", content: experience}]
        let profileContent = <><h3>{profile.major} - {profile.education}</h3><NavTabs tabs={profileTabs} /></>
        
        let calendarContent = <>
                                {
                                    profile.calendarId == null || profile.calendarId == ""
                                        ?   <Button onClick={this.createTutorCalendar} disabled={apiResponse.loading}>Create Calendar</Button>
                                        :   <TutorCalendar googleCalendarId={profile.calendarId} 
                                                            timezone={profile.timezone} 
                                                            assignments={[...assignments]}
                                                            user={user}
                                                            admin={true}
                                                            owner={false}
                                                            />
                                }
                            </>
        
        let tabs = [{title: 'Assignments', content: <AssignmentsAdminTable assignments={assignments} />},
                    {title: 'Tutor Sessions', content: <TutorSessionsTable tutorSessions={tutorSessions} role={"admin"} />},
                    {title: 'Transfers / Payments', content: <PaymentTransfersTable admin={true} transfers={transfers} /> },
                    {title: 'Refunds', content: <RefundsTable role="admin" refunds={refunds} />},
                    {title: 'Evaluations', content: <EvaluationsTable admin={true} evaluations={evaluations} />},
                    {title: 'Profile', content: profileContent},
                    ];
        
        let i = 1;
        tabs.forEach(tab => {
            tab.id = i;
            i++;    
        })
        
        let currencySymbol = "$";
        if(transfers.length > 0){
            currencySymbol = getCurrencySymbol(transfers[0].currency);
        }

        return (
            <>
                <Row>
                    <Col md="4">
                        <MiniCard title={"Assignments"} text={tutor.assignmentsCount.toString()} icon={"bx-user"} />
                    </Col>
                    <Col md="4">
                        <MiniCard title={"Sessions"} text={tutor.sessionsCount.toString()} icon={"bx-chalkboard"} />
                    </Col>
                    <Col md="4">
                        <MiniCard title={"Earnings"} text={typeof tutor.earnings == "number" ? currencySymbol + tutor.earnings.toFixed(2) : "$0"} icon={"bx-dollar"} />
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <NavTabs tabs={tabs} />
                    </CardBody>
                </Card>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                { calendarContent }
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>
                
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(TutorDetails)