import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';

import { Alert, Col, Row } from 'reactstrap';
import { submitApiCallAction } from "store/actions"

import { ThemeButton } from 'theme/elements/buttons';
import { ThemeDate } from "theme/elements/styledInputs"

class UpdateEndDateForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
             submitted: false,
        }
        this.onChangeValue.bind(this)
        this.onSubmit.bind(this)
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { apiResponse } = this.props;

        if(apiResponse.success && this.state.submitted){
            if(typeof this.props.onSubmit !== undefined){
                this.props.onSubmit();
            }
        }
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              ...this.state,
              [name]: value
        });
    }

    onSubmit = event => {
        event.preventDefault();

        let { endDate } = this.state;
        let { onSubmitApiCall, assignmentId } = this.props;

        onSubmitApiCall("updateEndDate", { endDate, assignmentId });
        this.setState({submitted: true});
    }

    render() {
        let { endDate } = this.state;
        let { apiResponse } = this.props;

        return (
            <>
                {
                    apiResponse.error && 
                        <Alert color="danger">{apiResponse.error}</Alert>
                }
                <Row>
                    <Col md={{size: 8, offset:2}}>
                        <ThemeDate id={"endDate"} 
                            name={"endDate"}
                            value={endDate}
                            min={endDate}
                            max={moment(endDate).add(4, "months").format("YYYY-MM-DD")}
                            onChange={this.onChangeValue} />
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 4, offset:8}}>
                        <div className="mt-3">
                            {
                                apiResponse.loading ?
                                    <ThemeButton disabled={true} active={false} icon={"bx bx-loader bx-spin"}> Updating </ThemeButton>
                                :
                                    <ThemeButton onClick={this.onSubmit}> Update </ThemeButton>
                            }
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(UpdateEndDateForm)