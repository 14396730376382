import React, { Component } from 'react'
import { connect } from "react-redux"

import { CardTitle, Col, Row } from "reactstrap"

import { ThemeSoftBadge } from 'theme/elements/badges'
import { ThemeSpinner } from 'theme/elements/spinner'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import PaymentForm from 'components/stripe/PaymentForm'

class EditPaymentSettings extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             cards: [],
             banks: [],
        }
        this.onSubmitCard.bind(this)
    }
    
    componentDidMount() {
        let { onSubmitApiCall, user } = this.props;
        onSubmitApiCall("getUserPaymentMethods", user.id);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    makeCardDefault = cardId => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("makeCardDefaultPaymentMethod", cardId);
    }

    onSubmitCard = ({ error }) => {
        if(error == null || error == ""){
            window.location.reload();
        }
    }

    render() {
        let { cards, banks } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;
        let paymentMethodCount = cards.length + banks.length;

        return (
            <>
                {
                    loading &&
                        <Row><center><ThemeSpinner /></center></Row>
                }
                <Row>
                    {
                        paymentMethodCount > 0 &&
                            <Col md="6">
                                <CardTitle className="mb-3">Saved Payment Methods</CardTitle>
                                {
                                    cards.map((card, i) => 
                                        <p key={i}>{card.brand} <small>xxxx</small>{card.lastFour} {" "}
                                            {
                                                card.defaultSource &&
                                                <ThemeSoftBadge color="success">
                                                    Default
                                                </ThemeSoftBadge>
                                            }
                                            <br />
                                            <small className="text-secondary">
                                                Expires {card.expMonth}/{card.expYear} {" "}
                                                {
                                                    !card.defaultSource && 
                                                        <a href="#" onClick={() => this.makeCardDefault(card.id)}>Make Default</a>    
                                                }
                                            </small>    
                                            
                                        </p>
                                    )
                                }
                                
                                {
                                    banks.map((bank, i) => 
                                        <li key={i}>{bank.bankName} - Account ending in {bank.lastFour}{" "}
                                            {
                                                bank.defaultSource &&
                                                <ThemeSoftBadge color="success">
                                                    Default
                                                </ThemeSoftBadge>
                                            }
                                        </li>
                                    )
                                }
                            </Col>
                    }

                    <Col md="6">
                        <CardTitle>Add A Payment Method</CardTitle>
                        <PaymentForm 
                            saveCard={true}
                            chargeCard={false}
                            onSubmit={this.onSubmitCard} />   
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentSettings)