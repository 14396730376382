import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
} from "reactstrap"

import { removeItemFromCart, updateItemInCart } from 'store/actions'

import { formatCurrencyAmount } from 'scripts/general';

class ShoppingCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
        total: 0,
    }
  }

  removeCartItem = itemId => {
    let { onRemoveItemFromCart } = this.props;
    onRemoveItemFromCart(itemId);
  }

  countUP = itemId => {
    let {cart: {items}, onUpdateItemInCart} = this.props;

    let item = {};
    let found = false;
    
    items.forEach(cartItem =>{
        if(cartItem.id == itemId){
            item = cartItem;
            found = true;
        }
    })

    if(found){
        item["quantity"] += 1;
        onUpdateItemInCart(item);
    }
  }

  countDown = itemId => {
    let {cart: {items}, onUpdateItemInCart} = this.props;

    let item = {};
    let found = false;
    
    items.forEach(cartItem =>{
        if(cartItem.id == itemId){
            item = cartItem;
            found = true;
        }
    });

    if(found && item.quantity > 1){
        item["quantity"] -= 1;
        onUpdateItemInCart(item);
    }
  }

  render() {
    const { cart: {items, currency="usd" }, editable=true } = this.props;
    
    return (
      <React.Fragment>
          <Container fluid>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table align-middle mb-0 table-nowrap">
                    <thead className="thead-light">
                        <tr>
                            <th></th>
                            <th>Description</th>
                            {/* <th>Unit Price</th> */}
                            <th colSpan={editable ? 2 : 1}>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => 
                      
                        <tr key={i}>
                            <td>
                                {
                                    item.image &&
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        title={item.image}
                                        className="avatar-sm" />
                                }
                            </td>
                            <td>
                                <h5 className="font-size-14 text-truncate">
                                    { item.link
                                        ?
                                            <Link
                                                to={item.link}
                                                className="text-dark"
                                            >
                                                {item.name}
                                            </Link>
                                        :
                                            <span className="text-dark">{item.name}</span>
                                    }
                                </h5>
                                <p className="mb-0">
                                    <small>{item.description}</small>
                                </p>
                                {/*
                                    editable &&
                                    <>
                                        <br />
                                        <div style={{ width: "120px" }}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => { this.countDown(item.id) }}
                                                    >
                                                        -
                                                    </Button>
                                                    </InputGroupAddon>
                                                    <Input
                                                      type="text"
                                                      id={item.id}
                                                      value={item.quantity}
                                                      name={item.id}
                                                      readOnly
                                                    />
                                                    <InputGroupAddon addonType="prepend">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => { this.countUP(item.id) }}
                                                    >
                                                        +
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </>
                                */}
                            </td>
                            {/* <td>{formatCurrencyAmount(item.price, currency)}</td> */}
                            <td>{formatCurrencyAmount(item.subtotal, currency)}</td>
                            {
                                editable && 
                                    <td>
                                        <Link
                                        to="#"
                                        onClick={() =>
                                            this.removeCartItem(item.id)
                                        }
                                        className="action-icon text-danger"
                                        >
                                        {" "}
                                        <i className="mdi mdi-trash-can font-size-18" />
                                        </Link>
                                    </td>
                            }
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          
          </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cartReducer}) => ({
    cart: cartReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onRemoveItemFromCart: itemId => dispatch(removeItemFromCart(itemId)),
    onUpdateItemInCart: itemId => dispatch(updateItemInCart(itemId)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)