//REF: https://gist.github.com/ahmu83/38865147cf3727d221941a2ef8c22a77
const cities = {
    "New York": [
      "New York",
      "Buffalo",
      "Rochester",
      "Yonkers",
      "Syracuse",
      "Albany",
      "New Rochelle",
      "Mount Vernon",
      "Schenectady",
      "Utica",
      "White Plains",
      "Hempstead",
      "Troy",
      "Niagara Falls",
      "Binghamton",
      "Freeport",
      "Valley Stream"
    ],
    "California": [
      "Los Angeles",
      "San Diego",
      "San Jose",
      "San Francisco",
      "Fresno",
      "Sacramento",
      "Long Beach",
      "Oakland",
      "Bakersfield",
      "Anaheim",
      "Santa Ana",
      "Riverside",
      "Stockton",
      "Chula Vista",
      "Irvine",
      "Fremont",
      "San Bernardino",
      "Modesto",
      "Fontana",
      "Oxnard",
      "Moreno Valley",
      "Huntington Beach",
      "Glendale",
      "Santa Clarita",
      "Garden Grove",
      "Oceanside",
      "Rancho Cucamonga",
      "Santa Rosa",
      "Ontario",
      "Lancaster",
      "Elk Grove",
      "Corona",
      "Palmdale",
      "Salinas",
      "Pomona",
      "Hayward",
      "Escondido",
      "Torrance",
      "Sunnyvale",
      "Orange",
      "Fullerton",
      "Pasadena",
      "Thousand Oaks",
      "Visalia",
      "Simi Valley",
      "Concord",
      "Roseville",
      "Victorville",
      "Santa Clara",
      "Vallejo",
      "Berkeley",
      "El Monte",
      "Downey",
      "Costa Mesa",
      "Inglewood",
      "Carlsbad",
      "San Buenaventura",
      "Ventura",
      "Fairfield",
      "West Covina",
      "Murrieta",
      "Richmond",
      "Norwalk",
      "Antioch",
      "Temecula",
      "Burbank",
      "Daly City",
      "Rialto",
      "Santa Maria",
      "El Cajon",
      "San Mateo",
      "Clovis",
      "Compton",
      "Jurupa Valley",
      "Vista",
      "South Gate",
      "Mission Viejo",
      "Vacaville",
      "Carson",
      "Hesperia",
      "Santa Monica",
      "Westminster",
      "Redding",
      "Santa Barbara",
      "Chico",
      "Newport Beach",
      "San Leandro",
      "San Marcos",
      "Whittier",
      "Hawthorne",
      "Citrus Heights",
      "Tracy",
      "Alhambra",
      "Livermore",
      "Buena Park",
      "Menifee",
      "Hemet",
      "Lakewood",
      "Merced",
      "Chino",
      "Indio",
      "Redwood City",
      "Lake Forest",
      "Napa",
      "Tustin",
      "Bellflower",
      "Mountain View",
      "Chino Hills",
      "Baldwin Park",
      "Alameda",
      "Upland",
      "San Ramon",
      "Folsom",
      "Pleasanton",
      "Union City",
      "Perris",
      "Manteca",
      "Lynwood",
      "Apple Valley",
      "Redlands",
      "Turlock",
      "Milpitas",
      "Redondo Beach",
      "Rancho Cordova",
      "Yorba Linda",
      "Palo Alto",
      "Davis",
      "Camarillo",
      "Walnut Creek",
      "Pittsburg",
      "South San Francisco",
      "Yuba City",
      "San Clemente",
      "Laguna Niguel",
      "Pico Rivera",
      "Montebello",
      "Lodi",
      "Madera",
      "Santa Cruz",
      "La Habra",
      "Encinitas",
      "Monterey Park",
      "Tulare",
      "Cupertino",
      "Gardena",
      "National City",
      "Rocklin",
      "Petaluma",
      "Huntington Park",
      "San Rafael",
      "La Mesa",
      "Arcadia",
      "Fountain Valley",
      "Diamond Bar",
      "Woodland",
      "Santee",
      "Lake Elsinore",
      "Porterville",
      "Paramount",
      "Eastvale",
      "Rosemead",
      "Hanford",
      "Highland",
      "Brentwood",
      "Novato",
      "Colton",
      "Cathedral City",
      "Delano",
      "Yucaipa",
      "Watsonville",
      "Placentia",
      "Glendora",
      "Gilroy",
      "Palm Desert",
      "Cerritos",
      "West Sacramento",
      "Aliso Viejo",
      "Poway",
      "La Mirada",
      "Rancho Santa Margarita",
      "Cypress",
      "Dublin",
      "Covina",
      "Azusa",
      "Palm Springs",
      "San Luis Obispo",
      "Ceres",
      "San Jacinto",
      "Lincoln",
      "Newark",
      "Lompoc",
      "El Centro",
      "Danville",
      "Bell Gardens",
      "Coachella",
      "Rancho Palos Verdes",
      "San Bruno",
      "Rohnert Park",
      "Brea",
      "La Puente",
      "Campbell",
      "San Gabriel",
      "Beaumont",
      "Morgan Hill",
      "Culver City",
      "Calexico",
      "Stanton",
      "La Quinta",
      "Pacifica",
      "Montclair",
      "Oakley",
      "Monrovia",
      "Los Banos",
      "Martinez"
    ],
    "Illinois": [
      "Chicago",
      "Aurora",
      "Rockford",
      "Joliet",
      "Naperville",
      "Springfield",
      "Peoria",
      "Elgin",
      "Waukegan",
      "Cicero",
      "Champaign",
      "Bloomington",
      "Arlington Heights",
      "Evanston",
      "Decatur",
      "Schaumburg",
      "Bolingbrook",
      "Palatine",
      "Skokie",
      "Des Plaines",
      "Orland Park",
      "Tinley Park",
      "Oak Lawn",
      "Berwyn",
      "Mount Prospect",
      "Normal",
      "Wheaton",
      "Hoffman Estates",
      "Oak Park",
      "Downers Grove",
      "Elmhurst",
      "Glenview",
      "DeKalb",
      "Lombard",
      "Belleville",
      "Moline",
      "Buffalo Grove",
      "Bartlett",
      "Urbana",
      "Quincy",
      "Crystal Lake",
      "Plainfield",
      "Streamwood",
      "Carol Stream",
      "Romeoville",
      "Rock Island",
      "Hanover Park",
      "Carpentersville",
      "Wheeling",
      "Park Ridge",
      "Addison",
      "Calumet City"
    ],
    "Texas": [
      "Houston",
      "San Antonio",
      "Dallas",
      "Austin",
      "Fort Worth",
      "El Paso",
      "Arlington",
      "Corpus Christi",
      "Plano",
      "Laredo",
      "Lubbock",
      "Garland",
      "Irving",
      "Amarillo",
      "Grand Prairie",
      "Brownsville",
      "Pasadena",
      "McKinney",
      "Mesquite",
      "McAllen",
      "Killeen",
      "Frisco",
      "Waco",
      "Carrollton",
      "Denton",
      "Midland",
      "Abilene",
      "Beaumont",
      "Round Rock",
      "Odessa",
      "Wichita Falls",
      "Richardson",
      "Lewisville",
      "Tyler",
      "College Station",
      "Pearland",
      "San Angelo",
      "Allen",
      "League City",
      "Sugar Land",
      "Longview",
      "Edinburg",
      "Mission",
      "Bryan",
      "Baytown",
      "Pharr",
      "Temple",
      "Missouri City",
      "Flower Mound",
      "Harlingen",
      "North Richland Hills",
      "Victoria",
      "Conroe",
      "New Braunfels",
      "Mansfield",
      "Cedar Park",
      "Rowlett",
      "Port Arthur",
      "Euless",
      "Georgetown",
      "Pflugerville",
      "DeSoto",
      "San Marcos",
      "Grapevine",
      "Bedford",
      "Galveston",
      "Cedar Hill",
      "Texas City",
      "Wylie",
      "Haltom City",
      "Keller",
      "Coppell",
      "Rockwall",
      "Huntsville",
      "Duncanville",
      "Sherman",
      "The Colony",
      "Burleson",
      "Hurst",
      "Lancaster",
      "Texarkana",
      "Friendswood",
      "Weslaco"
    ],
    "Pennsylvania": [
      "Philadelphia",
      "Pittsburgh",
      "Allentown",
      "Erie",
      "Reading",
      "Scranton",
      "Bethlehem",
      "Lancaster",
      "Harrisburg",
      "Altoona",
      "York",
      "State College",
      "Wilkes Barre"
    ],
    "Arizona": [
      "Phoenix",
      "Tucson",
      "Mesa",
      "Chandler",
      "Glendale",
      "Scottsdale",
      "Gilbert",
      "Tempe",
      "Peoria",
      "Surprise",
      "Yuma",
      "Avondale",
      "Goodyear",
      "Flagstaff",
      "Buckeye",
      "Lake Havasu City",
      "Casa Grande",
      "Sierra Vista",
      "Maricopa",
      "Oro Valley",
      "Prescott",
      "Bullhead City",
      "Prescott Valley",
      "Marana",
      "Apache Junction"
    ],
    "Florida": [
      "Jacksonville",
      "Miami",
      "Tampa",
      "Orlando",
      "St. Petersburg",
      "Hialeah",
      "Tallahassee",
      "Fort Lauderdale",
      "Port St. Lucie",
      "Cape Coral",
      "Pembroke Pines",
      "Hollywood",
      "Miramar",
      "Gainesville",
      "Coral Springs",
      "Miami Gardens",
      "Clearwater",
      "Palm Bay",
      "Pompano Beach",
      "West Palm Beach",
      "Lakeland",
      "Davie",
      "Miami Beach",
      "Sunrise",
      "Plantation",
      "Boca Raton",
      "Deltona",
      "Largo",
      "Deerfield Beach",
      "Palm Coast",
      "Melbourne",
      "Boynton Beach",
      "Lauderhill",
      "Weston",
      "Fort Myers",
      "Kissimmee",
      "Homestead",
      "Tamarac",
      "Delray Beach",
      "Daytona Beach",
      "North Miami",
      "Wellington",
      "North Port",
      "Jupiter",
      "Ocala",
      "Port Orange",
      "Margate",
      "Coconut Creek",
      "Sanford",
      "Sarasota",
      "Pensacola",
      "Bradenton",
      "Palm Beach Gardens",
      "Pinellas Park",
      "Coral Gables",
      "Doral",
      "Bonita Springs",
      "Apopka",
      "Titusville",
      "North Miami Beach",
      "Oakland Park",
      "Fort Pierce",
      "North Lauderdale",
      "Cutler Bay",
      "Altamonte Springs",
      "St. Cloud",
      "Greenacres",
      "Ormond Beach",
      "Ocoee",
      "Hallandale Beach",
      "Winter Garden",
      "Aventura"
    ],
    "Indiana": [
      "Indianapolis",
      "Fort Wayne",
      "Evansville",
      "South Bend",
      "Carmel",
      "Bloomington",
      "Fishers",
      "Hammond",
      "Gary",
      "Muncie",
      "Lafayette",
      "Terre Haute",
      "Kokomo",
      "Anderson",
      "Noblesville",
      "Greenwood",
      "Elkhart",
      "Mishawaka",
      "Lawrence",
      "Jeffersonville",
      "Columbus",
      "Portage"
    ],
    "Ohio": [
      "Columbus",
      "Cleveland",
      "Cincinnati",
      "Toledo",
      "Akron",
      "Dayton",
      "Parma",
      "Canton",
      "Youngstown",
      "Lorain",
      "Hamilton",
      "Springfield",
      "Kettering",
      "Elyria",
      "Lakewood",
      "Cuyahoga Falls",
      "Middletown",
      "Euclid",
      "Newark",
      "Mansfield",
      "Mentor",
      "Beavercreek",
      "Cleveland Heights",
      "Strongsville",
      "Dublin",
      "Fairfield",
      "Findlay",
      "Warren",
      "Lancaster",
      "Lima",
      "Huber Heights",
      "Westerville",
      "Marion",
      "Grove City"
    ],
    "North Carolina": [
      "Charlotte",
      "Raleigh",
      "Greensboro",
      "Durham",
      "Winston Salem",
      "Fayetteville",
      "Cary",
      "Wilmington",
      "High Point",
      "Greenville",
      "Asheville",
      "Concord",
      "Gastonia",
      "Jacksonville",
      "Chapel Hill",
      "Rocky Mount",
      "Burlington",
      "Wilson",
      "Huntersville",
      "Kannapolis",
      "Apex",
      "Hickory",
      "Goldsboro"
    ],
    "Michigan": [
      "Detroit",
      "Grand Rapids",
      "Warren",
      "Sterling Heights",
      "Ann Arbor",
      "Lansing",
      "Flint",
      "Dearborn",
      "Livonia",
      "Westland",
      "Troy",
      "Farmington Hills",
      "Kalamazoo",
      "Wyoming",
      "Southfield",
      "Rochester Hills",
      "Taylor",
      "Pontiac",
      "St. Clair Shores",
      "Royal Oak",
      "Novi",
      "Dearborn Heights",
      "Battle Creek",
      "Saginaw",
      "Kentwood",
      "East Lansing",
      "Roseville",
      "Portage",
      "Midland",
      "Lincoln Park",
      "Muskegon"
    ],
    "Tennessee": [
      "Memphis",
      "Nashville",
      "Davidson",
      "Knoxville",
      "Chattanooga",
      "Clarksville",
      "Murfreesboro",
      "Jackson",
      "Franklin",
      "Johnson City",
      "Bartlett",
      "Hendersonville",
      "Kingsport",
      "Collierville",
      "Cleveland",
      "Smyrna",
      "Germantown",
      "Brentwood"
    ],
    "Massachusetts": [
      "Boston",
      "Worcester",
      "Springfield",
      "Lowell",
      "Cambridge",
      "New Bedford",
      "Brockton",
      "Quincy",
      "Lynn",
      "Fall River",
      "Newton",
      "Lawrence",
      "Somerville",
      "Waltham",
      "Haverhill",
      "Malden",
      "Medford",
      "Taunton",
      "Chicopee",
      "Weymouth Town",
      "Revere",
      "Peabody",
      "Methuen",
      "Barnstable Town",
      "Pittsfield",
      "Attleboro",
      "Everett",
      "Salem",
      "Westfield",
      "Leominster",
      "Fitchburg",
      "Beverly",
      "Holyoke",
      "Marlborough",
      "Woburn",
      "Chelsea"
    ],
    "Washington": [
      "Seattle",
      "Spokane",
      "Tacoma",
      "Vancouver",
      "Bellevue",
      "Kent",
      "Everett",
      "Renton",
      "Yakima",
      "Federal Way",
      "Spokane Valley",
      "Bellingham",
      "Kennewick",
      "Auburn",
      "Pasco",
      "Marysville",
      "Lakewood",
      "Redmond",
      "Shoreline",
      "Richland",
      "Kirkland",
      "Burien",
      "Sammamish",
      "Olympia",
      "Lacey",
      "Edmonds",
      "Bremerton",
      "Puyallup"
    ],
    "Colorado": [
      "Denver",
      "Colorado Springs",
      "Aurora",
      "Fort Collins",
      "Lakewood",
      "Thornton",
      "Arvada",
      "Westminster",
      "Pueblo",
      "Centennial",
      "Boulder",
      "Greeley",
      "Longmont",
      "Loveland",
      "Grand Junction",
      "Broomfield",
      "Castle Rock",
      "Commerce City",
      "Parker",
      "Littleton",
      "Northglenn"
    ],
    "District of Columbia": [
      "Washington"
    ],
    "District Of Columbia": [
      "Washington"
    ],
    "Maryland": [
      "Baltimore",
      "Frederick",
      "Rockville",
      "Gaithersburg",
      "Bowie",
      "Hagerstown",
      "Annapolis"
    ],
    "Kentucky": [
      "Louisville",
      "Lexington",
      "Fayette",
      "Bowling Green",
      "Owensboro",
      "Covington"
    ],
    "Oregon": [
      "Portland",
      "Eugene",
      "Salem",
      "Gresham",
      "Hillsboro",
      "Beaverton",
      "Bend",
      "Medford",
      "Springfield",
      "Corvallis",
      "Albany",
      "Tigard",
      "Lake Oswego",
      "Keizer"
    ],
    "Oklahoma": [
      "Oklahoma City",
      "Tulsa",
      "Norman",
      "Broken Arrow",
      "Lawton",
      "Edmond",
      "Moore",
      "Midwest City",
      "Enid",
      "Stillwater",
      "Muskogee"
    ],
    "Wisconsin": [
      "Milwaukee",
      "Madison",
      "Green Bay",
      "Kenosha",
      "Racine",
      "Appleton",
      "Waukesha",
      "Eau Claire",
      "Oshkosh",
      "Janesville",
      "West Allis",
      "La Crosse",
      "Sheboygan",
      "Wauwatosa",
      "Fond du Lac",
      "New Berlin",
      "Wausau",
      "Brookfield",
      "Greenfield",
      "Beloit"
    ],
    "Nevada": [
      "Las Vegas",
      "Henderson",
      "Reno",
      "North Las Vegas",
      "Sparks",
      "Carson City"
    ],
    "New Mexico": [
      "Albuquerque",
      "Las Cruces",
      "Rio Rancho",
      "Santa Fe",
      "Roswell",
      "Farmington",
      "Clovis"
    ],
    "Missouri": [
      "Kansas City",
      "St. Louis",
      "Springfield",
      "Independence",
      "Columbia",
      "Lee's Summit",
      "O'Fallon",
      "St. Joseph",
      "St. Charles",
      "St. Peters",
      "Blue Springs",
      "Florissant",
      "Joplin",
      "Chesterfield",
      "Jefferson City",
      "Cape Girardeau"
    ],
    "Virginia": [
      "Virginia Beach",
      "Norfolk",
      "Chesapeake",
      "Richmond",
      "Newport News",
      "Alexandria",
      "Hampton",
      "Roanoke",
      "Portsmouth",
      "Suffolk",
      "Lynchburg",
      "Harrisonburg",
      "Leesburg",
      "Charlottesville",
      "Danville",
      "Blacksburg",
      "Manassas"
    ],
    "Georgia": [
      "Atlanta",
      "Columbus",
      "Savannah",
      "Athens",
      "Sandy Springs",
      "Roswell",
      "Macon",
      "Johns Creek",
      "Albany",
      "Warner Robins",
      "Alpharetta",
      "Marietta",
      "Valdosta",
      "Smyrna",
      "Dunwoody"
    ],
    "Nebraska": [
      "Omaha",
      "Lincoln",
      "Bellevue",
      "Grand Island"
    ],
    "Minnesota": [
      "Minneapolis",
      "St. Paul",
      "Rochester",
      "Duluth",
      "Bloomington",
      "Brooklyn Park",
      "Plymouth",
      "St. Cloud",
      "Eagan",
      "Woodbury",
      "Maple Grove",
      "Eden Prairie",
      "Coon Rapids",
      "Burnsville",
      "Blaine",
      "Lakeville",
      "Minnetonka",
      "Apple Valley",
      "Edina",
      "St. Louis Park",
      "Mankato",
      "Maplewood",
      "Moorhead",
      "Shakopee"
    ],
    "Kansas": [
      "Wichita",
      "Overland Park",
      "Kansas City",
      "Olathe",
      "Topeka",
      "Lawrence",
      "Shawnee",
      "Manhattan",
      "Lenexa",
      "Salina",
      "Hutchinson"
    ],
    "Louisiana": [
      "New Orleans",
      "Baton Rouge",
      "Shreveport",
      "Lafayette",
      "Lake Charles",
      "Kenner",
      "Bossier City",
      "Monroe",
      "Alexandria"
    ],
    "Hawaii": [
      "Honolulu"
    ],
    "Alaska": [
      "Anchorage"
    ],
    "New Jersey": [
      "Newark",
      "Jersey City",
      "Paterson",
      "Elizabeth",
      "Clifton",
      "Trenton",
      "Camden",
      "Passaic",
      "Union City",
      "Bayonne",
      "East Orange",
      "Vineland",
      "New Brunswick",
      "Hoboken",
      "Perth Amboy",
      "West New York",
      "Plainfield",
      "Hackensack",
      "Sayreville",
      "Kearny",
      "Linden",
      "Atlantic City"
    ],
    "Idaho": [
      "Boise City",
      "Nampa",
      "Meridian",
      "Idaho Falls",
      "Pocatello",
      "Caldwell",
      "Coeur d'Alene",
      "Twin Falls"
    ],
    "Alabama": [
      "Birmingham",
      "Montgomery",
      "Mobile",
      "Huntsville",
      "Tuscaloosa",
      "Hoover",
      "Dothan",
      "Auburn",
      "Decatur",
      "Madison",
      "Florence",
      "Gadsden"
    ],
    "Iowa": [
      "Des Moines",
      "Cedar Rapids",
      "Davenport",
      "Sioux City",
      "Iowa City",
      "Waterloo",
      "Council Bluffs",
      "Ames",
      "West Des Moines",
      "Dubuque",
      "Ankeny",
      "Urbandale",
      "Cedar Falls"
    ],
    "Arkansas": [
      "Little Rock",
      "Fort Smith",
      "Fayetteville",
      "Springdale",
      "Jonesboro",
      "North Little Rock",
      "Conway",
      "Rogers",
      "Pine Bluff",
      "Bentonville"
    ],
    "Utah": [
      "Salt Lake City",
      "West Valley City",
      "Provo",
      "West Jordan",
      "Orem",
      "Sandy",
      "Ogden",
      "St. George",
      "Layton",
      "Taylorsville",
      "South Jordan",
      "Lehi",
      "Logan",
      "Murray",
      "Draper",
      "Bountiful",
      "Riverton",
      "Roy"
    ],
    "Rhode Island": [
      "Providence",
      "Warwick",
      "Cranston",
      "Pawtucket",
      "East Providence",
      "Woonsocket"
    ],
    "Mississippi": [
      "Jackson",
      "Gulfport",
      "Southaven",
      "Hattiesburg",
      "Biloxi",
      "Meridian"
    ],
    "South Dakota": [
      "Sioux Falls",
      "Rapid City"
    ],
    "Connecticut": [
      "Bridgeport",
      "New Haven",
      "Stamford",
      "Hartford",
      "Waterbury",
      "Norwalk",
      "Danbury",
      "New Britain",
      "Meriden",
      "Bristol",
      "West Haven",
      "Milford",
      "Middletown",
      "Norwich",
      "Shelton"
    ],
    "South Carolina": [
      "Columbia",
      "Charleston",
      "North Charleston",
      "Mount Pleasant",
      "Rock Hill",
      "Greenville",
      "Summerville",
      "Sumter",
      "Goose Creek",
      "Hilton Head Island",
      "Florence",
      "Spartanburg"
    ],
    "New Hampshire": [
      "Manchester",
      "Nashua",
      "Concord"
    ],
    "North Dakota": [
      "Fargo",
      "Bismarck",
      "Grand Forks",
      "Minot"
    ],
    "Montana": [
      "Billings",
      "Missoula",
      "Great Falls",
      "Bozeman"
    ],
    "Delaware": [
      "Wilmington",
      "Dover"
    ],
    "Maine": [
      "Portland"
    ],
    "Wyoming": [
      "Cheyenne",
      "Casper"
    ],
    "West Virginia": [
      "Charleston",
      "Huntington"
    ],
    "Vermont": [
      "Burlington"
    ],
}

//Ref: https://gist.github.com/Miserlou/c5cd8364bf9b2420bb29
//Ref (With Zip Codes): https://raw.githubusercontent.com/millbj92/US-Zip-Codes-JSON/master/USCities.json
//Conversion: https://www.convertcsv.com/csv-to-json.htm
const cities_with_geo = {
   "Alabama": [
      {
         "city": "Auburn",
         "latitude": 32.6098566,
         "longitude": -85.4807825,
         "postalCode": 36830
      },
      {
         "city": "Birmingham",
         "latitude": 33.5206608,
         "longitude": -86.80249,
         "postalCode": 35201
      },
      {
         "city": "Decatur",
         "latitude": 34.6059253,
         "longitude": -86.9833417,
         "postalCode": 35601
      },
      {
         "city": "Dothan",
         "latitude": 31.2232313,
         "longitude": -85.3904888,
         "postalCode": 36301
      },
      {
         "city": "Florence",
         "latitude": 34.79981,
         "longitude": -87.677251,
         "postalCode": 35630
      },
      {
         "city": "Hoover",
         "latitude": 33.4053867,
         "longitude": -86.8113781,
         "postalCode": ""
      },
      {
         "city": "Huntsville",
         "latitude": 34.7303688,
         "longitude": -86.5861037,
         "postalCode": 35801
      },
      {
         "city": "Madison",
         "latitude": 34.6992579,
         "longitude": -86.7483318,
         "postalCode": 35756
      },
      {
         "city": "Mobile",
         "latitude": 30.6953657,
         "longitude": -88.0398912,
         "postalCode": 36601
      },
      {
         "city": "Montgomery",
         "latitude": 32.3668052,
         "longitude": -86.2999689,
         "postalCode": 36101
      },
      {
         "city": "Phenix City",
         "latitude": 32.4709761,
         "longitude": -85.0007653,
         "postalCode": 36867
      },
      {
         "city": "Tuscaloosa",
         "latitude": 33.2098407,
         "longitude": -87.5691735,
         "postalCode": 35401
      }
   ],
   "Alaska": [
      {
         "city": "Anchorage",
         "latitude": 61.2180556,
         "longitude": -149.9002778,
         "postalCode": 99501
      }
   ],
   "Arizona": [
      {
         "city": "Apache Junction",
         "latitude": 33.4150485,
         "longitude": -111.5495777,
         "postalCode": 85217
      },
      {
         "city": "Avondale",
         "latitude": 33.4355977,
         "longitude": -112.3496021,
         "postalCode": 85323
      },
      {
         "city": "Buckeye",
         "latitude": 33.3703197,
         "longitude": -112.5837766,
         "postalCode": 85326
      },
      {
         "city": "Bullhead City",
         "latitude": 35.1359386,
         "longitude": -114.5285981,
         "postalCode": 86429
      },
      {
         "city": "Casa Grande",
         "latitude": 32.8795022,
         "longitude": -111.7573521,
         "postalCode": 85222
      },
      {
         "city": "Chandler",
         "latitude": 33.3061605,
         "longitude": -111.8412502,
         "postalCode": 85224
      },
      {
         "city": "Flagstaff",
         "latitude": 35.1982836,
         "longitude": -111.651302,
         "postalCode": 86001
      },
      {
         "city": "Gilbert",
         "latitude": 33.3528264,
         "longitude": -111.789027,
         "postalCode": 85233
      },
      {
         "city": "Glendale",
         "latitude": 33.5386523,
         "longitude": -112.1859866,
         "postalCode": 85301
      },
      {
         "city": "Goodyear",
         "latitude": 33.4353394,
         "longitude": -112.3576567,
         "postalCode": 85338
      },
      {
         "city": "Lake Havasu City",
         "latitude": 34.483901,
         "longitude": -114.3224548,
         "postalCode": 86403
      },
      {
         "city": "Marana",
         "latitude": 32.436381,
         "longitude": -111.2224422,
         "postalCode": 85653
      },
      {
         "city": "Maricopa",
         "latitude": 33.0581063,
         "longitude": -112.0476423,
         "postalCode": 85239
      },
      {
         "city": "Mesa",
         "latitude": 33.4151843,
         "longitude": -111.8314724,
         "postalCode": 85201
      },
      {
         "city": "Oro Valley",
         "latitude": 32.3909071,
         "longitude": -110.966488,
         "postalCode": ""
      },
      {
         "city": "Peoria",
         "latitude": 33.5805955,
         "longitude": -112.2373779,
         "postalCode": 85345
      },
      {
         "city": "Phoenix",
         "latitude": 33.4483771,
         "longitude": -112.0740373,
         "postalCode": 85001
      },
      {
         "city": "Prescott",
         "latitude": 34.5400242,
         "longitude": -112.4685025,
         "postalCode": 86301
      },
      {
         "city": "Prescott Valley",
         "latitude": 34.6100243,
         "longitude": -112.315721,
         "postalCode": 86312
      },
      {
         "city": "Scottsdale",
         "latitude": 33.4941704,
         "longitude": -111.9260519,
         "postalCode": 85250
      },
      {
         "city": "Sierra Vista",
         "latitude": 31.5455001,
         "longitude": -110.2772856,
         "postalCode": 85635
      },
      {
         "city": "Surprise",
         "latitude": 33.6292337,
         "longitude": -112.3679279,
         "postalCode": 85374
      },
      {
         "city": "Tempe",
         "latitude": 33.4255104,
         "longitude": -111.9400054,
         "postalCode": 85280
      },
      {
         "city": "Tucson",
         "latitude": 32.2217429,
         "longitude": -110.926479,
         "postalCode": 85701
      },
      {
         "city": "Yuma",
         "latitude": 32.6926512,
         "longitude": -114.6276916,
         "postalCode": 85364
      }
   ],
   "Arkansas": [
      {
         "city": "Bentonville",
         "latitude": 36.3728538,
         "longitude": -94.2088172,
         "postalCode": 72712
      },
      {
         "city": "Conway",
         "latitude": 35.0886963,
         "longitude": -92.4421011,
         "postalCode": 72032
      },
      {
         "city": "Fayetteville",
         "latitude": 36.0625795,
         "longitude": -94.1574263,
         "postalCode": 72701
      },
      {
         "city": "Fort Smith",
         "latitude": 35.3859242,
         "longitude": -94.3985475,
         "postalCode": 72901
      },
      {
         "city": "Jonesboro",
         "latitude": 35.8422967,
         "longitude": -90.704279,
         "postalCode": 72401
      },
      {
         "city": "Little Rock",
         "latitude": 34.7464809,
         "longitude": -92.2895948,
         "postalCode": 72201
      },
      {
         "city": "North Little Rock",
         "latitude": 34.769536,
         "longitude": -92.2670941,
         "postalCode": 72114
      },
      {
         "city": "Pine Bluff",
         "latitude": 34.2284312,
         "longitude": -92.0031955,
         "postalCode": 71601
      },
      {
         "city": "Rogers",
         "latitude": 36.3320196,
         "longitude": -94.1185366,
         "postalCode": 72756
      },
      {
         "city": "Springdale",
         "latitude": 36.1867442,
         "longitude": -94.1288141,
         "postalCode": 72762
      }
   ],
   "California": [
      {
         "city": "Alameda",
         "latitude": 37.7652065,
         "longitude": -122.2416355,
         "postalCode": 94501
      },
      {
         "city": "Alhambra",
         "latitude": 34.095287,
         "longitude": -118.1270146,
         "postalCode": 91801
      },
      {
         "city": "Aliso Viejo",
         "latitude": 33.5676842,
         "longitude": -117.7256083,
         "postalCode": 92656
      },
      {
         "city": "Anaheim",
         "latitude": 33.8352932,
         "longitude": -117.9145036,
         "postalCode": 92801
      },
      {
         "city": "Antioch",
         "latitude": 38.0049214,
         "longitude": -121.805789,
         "postalCode": 94509
      },
      {
         "city": "Apple Valley",
         "latitude": 34.5008311,
         "longitude": -117.1858759,
         "postalCode": 92307
      },
      {
         "city": "Arcadia",
         "latitude": 34.1397292,
         "longitude": -118.0353449,
         "postalCode": 91006
      },
      {
         "city": "Azusa",
         "latitude": 34.1336186,
         "longitude": -117.9075627,
         "postalCode": 91702
      },
      {
         "city": "Bakersfield",
         "latitude": 35.3732921,
         "longitude": -119.0187125,
         "postalCode": 93301
      },
      {
         "city": "Baldwin Park",
         "latitude": 34.0852868,
         "longitude": -117.9608978,
         "postalCode": 91706
      },
      {
         "city": "Beaumont",
         "latitude": 33.9294606,
         "longitude": -116.977248,
         "postalCode": 92223
      },
      {
         "city": "Bell Gardens",
         "latitude": 33.9652918,
         "longitude": -118.1514588,
         "postalCode": 90202
      },
      {
         "city": "Bellflower",
         "latitude": 33.8816818,
         "longitude": -118.1170117,
         "postalCode": 90706
      },
      {
         "city": "Berkeley",
         "latitude": 37.8715926,
         "longitude": -122.272747,
         "postalCode": 94701
      },
      {
         "city": "Brea",
         "latitude": 33.9166805,
         "longitude": -117.9000604,
         "postalCode": 92821
      },
      {
         "city": "Brentwood",
         "latitude": 37.931868,
         "longitude": -121.6957863,
         "postalCode": 94513
      },
      {
         "city": "Buena Park",
         "latitude": 33.8675143,
         "longitude": -117.9981181,
         "postalCode": 90620
      },
      {
         "city": "Burbank",
         "latitude": 34.1808392,
         "longitude": -118.3089661,
         "postalCode": 91501
      },
      {
         "city": "Calexico",
         "latitude": 32.6789476,
         "longitude": -115.4988834,
         "postalCode": 92231
      },
      {
         "city": "Camarillo",
         "latitude": 34.2163937,
         "longitude": -119.0376023,
         "postalCode": 93010
      },
      {
         "city": "Campbell",
         "latitude": 37.2871651,
         "longitude": -121.9499568,
         "postalCode": 95008
      },
      {
         "city": "Carlsbad",
         "latitude": 33.1580933,
         "longitude": -117.3505939,
         "postalCode": 92008
      },
      {
         "city": "Carson",
         "latitude": 33.8316745,
         "longitude": -118.281693,
         "postalCode": 90745
      },
      {
         "city": "Cathedral City",
         "latitude": 33.7805388,
         "longitude": -116.4668036,
         "postalCode": 92234
      },
      {
         "city": "Ceres",
         "latitude": 37.5949316,
         "longitude": -120.9577098,
         "postalCode": 95307
      },
      {
         "city": "Cerritos",
         "latitude": 33.8583483,
         "longitude": -118.0647871,
         "postalCode": 90703
      },
      {
         "city": "Chico",
         "latitude": 39.7284944,
         "longitude": -121.8374777,
         "postalCode": 95926
      },
      {
         "city": "Chino",
         "latitude": 34.0122346,
         "longitude": -117.688944,
         "postalCode": 91708
      },
      {
         "city": "Chino Hills",
         "latitude": 33.9898188,
         "longitude": -117.7325848,
         "postalCode": 91709
      },
      {
         "city": "Chula Vista",
         "latitude": 32.6400541,
         "longitude": -117.0841955,
         "postalCode": 91909
      },
      {
         "city": "Citrus Heights",
         "latitude": 38.7071247,
         "longitude": -121.2810611,
         "postalCode": 95610
      },
      {
         "city": "Clovis",
         "latitude": 36.8252277,
         "longitude": -119.7029194,
         "postalCode": 93611
      },
      {
         "city": "Coachella",
         "latitude": 33.6803003,
         "longitude": -116.173894,
         "postalCode": 92236
      },
      {
         "city": "Colton",
         "latitude": 34.0739016,
         "longitude": -117.3136547,
         "postalCode": 92324
      },
      {
         "city": "Compton",
         "latitude": 33.8958492,
         "longitude": -118.2200712,
         "postalCode": 90220
      },
      {
         "city": "Concord",
         "latitude": 37.9779776,
         "longitude": -122.0310733,
         "postalCode": 94518
      },
      {
         "city": "Corona",
         "latitude": 33.8752935,
         "longitude": -117.5664384,
         "postalCode": 92877
      },
      {
         "city": "Costa Mesa",
         "latitude": 33.6411316,
         "longitude": -117.9186689,
         "postalCode": 92626
      },
      {
         "city": "Covina",
         "latitude": 34.0900091,
         "longitude": -117.8903397,
         "postalCode": 91722
      },
      {
         "city": "Culver City",
         "latitude": 34.0211224,
         "longitude": -118.3964665,
         "postalCode": 90230
      },
      {
         "city": "Cupertino",
         "latitude": 37.3229978,
         "longitude": -122.0321823,
         "postalCode": 95014
      },
      {
         "city": "Cypress",
         "latitude": 33.8169599,
         "longitude": -118.0372852,
         "postalCode": 90630
      },
      {
         "city": "Daly City",
         "latitude": 37.6879241,
         "longitude": -122.4702079,
         "postalCode": 94013
      },
      {
         "city": "Danville",
         "latitude": 37.8215929,
         "longitude": -121.9999606,
         "postalCode": 94506
      },
      {
         "city": "Davis",
         "latitude": 38.5449065,
         "longitude": -121.7405167,
         "postalCode": 95616
      },
      {
         "city": "Delano",
         "latitude": 35.7688425,
         "longitude": -119.2470536,
         "postalCode": 93215
      },
      {
         "city": "Diamond Bar",
         "latitude": 34.0286226,
         "longitude": -117.8103367,
         "postalCode": 91765
      },
      {
         "city": "Downey",
         "latitude": 33.9401088,
         "longitude": -118.1331593,
         "postalCode": 90239
      },
      {
         "city": "Dublin",
         "latitude": 37.7021521,
         "longitude": -121.9357918,
         "postalCode": 94568
      },
      {
         "city": "Eastvale",
         "latitude": 33.952463,
         "longitude": -117.5848025,
         "postalCode": ""
      },
      {
         "city": "El Cajon",
         "latitude": 32.7947731,
         "longitude": -116.9625269,
         "postalCode": 92019
      },
      {
         "city": "El Centro",
         "latitude": 32.792,
         "longitude": -115.5630514,
         "postalCode": 92243
      },
      {
         "city": "El Monte",
         "latitude": 34.0686206,
         "longitude": -118.0275667,
         "postalCode": 91731
      },
      {
         "city": "Elk Grove",
         "latitude": 38.4087993,
         "longitude": -121.3716178,
         "postalCode": 95624
      },
      {
         "city": "Encinitas",
         "latitude": 33.0369867,
         "longitude": -117.2919818,
         "postalCode": 92023
      },
      {
         "city": "Escondido",
         "latitude": 33.1192068,
         "longitude": -117.086421,
         "postalCode": 92025
      },
      {
         "city": "Fairfield",
         "latitude": 38.2493581,
         "longitude": -122.0399663,
         "postalCode": 94533
      },
      {
         "city": "Folsom",
         "latitude": 38.6779591,
         "longitude": -121.1760583,
         "postalCode": 95630
      },
      {
         "city": "Fontana",
         "latitude": 34.0922335,
         "longitude": -117.435048,
         "postalCode": 92334
      },
      {
         "city": "Fountain Valley",
         "latitude": 33.7091847,
         "longitude": -117.9536697,
         "postalCode": 92708
      },
      {
         "city": "Fremont",
         "latitude": 37.5482697,
         "longitude": -121.9885719,
         "postalCode": 94536
      },
      {
         "city": "Fresno",
         "latitude": 36.7468422,
         "longitude": -119.7725868,
         "postalCode": 93650
      },
      {
         "city": "Fullerton",
         "latitude": 33.8703596,
         "longitude": -117.9242966,
         "postalCode": 92831
      },
      {
         "city": "Garden Grove",
         "latitude": 33.7739053,
         "longitude": -117.9414477,
         "postalCode": 92840
      },
      {
         "city": "Gardena",
         "latitude": 33.8883487,
         "longitude": -118.3089624,
         "postalCode": 90247
      },
      {
         "city": "Gilroy",
         "latitude": 37.0057816,
         "longitude": -121.5682751,
         "postalCode": 95020
      },
      {
         "city": "Glendale",
         "latitude": 34.1425078,
         "longitude": -118.255075,
         "postalCode": 91201
      },
      {
         "city": "Glendora",
         "latitude": 34.1361187,
         "longitude": -117.865339,
         "postalCode": 91740
      },
      {
         "city": "Hanford",
         "latitude": 36.3274502,
         "longitude": -119.6456844,
         "postalCode": 93230
      },
      {
         "city": "Hawthorne",
         "latitude": 33.9164032,
         "longitude": -118.3525748,
         "postalCode": 90250
      },
      {
         "city": "Hayward",
         "latitude": 37.6688205,
         "longitude": -122.0807964,
         "postalCode": 94540
      },
      {
         "city": "Hemet",
         "latitude": 33.7475203,
         "longitude": -116.9719684,
         "postalCode": 92543
      },
      {
         "city": "Hesperia",
         "latitude": 34.4263886,
         "longitude": -117.3008784,
         "postalCode": 92340
      },
      {
         "city": "Highland",
         "latitude": 34.1283442,
         "longitude": -117.2086513,
         "postalCode": 92346
      },
      {
         "city": "Huntington Beach",
         "latitude": 33.660297,
         "longitude": -117.9992265,
         "postalCode": 92605
      },
      {
         "city": "Huntington Park",
         "latitude": 33.9816812,
         "longitude": -118.2250725,
         "postalCode": 90255
      },
      {
         "city": "Indio",
         "latitude": 33.7205771,
         "longitude": -116.2155619,
         "postalCode": 92201
      },
      {
         "city": "Inglewood",
         "latitude": 33.9616801,
         "longitude": -118.3531311,
         "postalCode": 90301
      },
      {
         "city": "Irvine",
         "latitude": 33.6839473,
         "longitude": -117.7946942,
         "postalCode": 92602
      },
      {
         "city": "Jurupa Valley",
         "latitude": 33.9971974,
         "longitude": -117.4854802,
         "postalCode": ""
      },
      {
         "city": "La Habra",
         "latitude": 33.9319578,
         "longitude": -117.9461734,
         "postalCode": 90631
      },
      {
         "city": "La Mesa",
         "latitude": 32.7678287,
         "longitude": -117.0230839,
         "postalCode": 91941
      },
      {
         "city": "La Mirada",
         "latitude": 33.9172357,
         "longitude": -118.0120086,
         "postalCode": 90637
      },
      {
         "city": "La Puente",
         "latitude": 34.0200114,
         "longitude": -117.9495083,
         "postalCode": 91744
      },
      {
         "city": "La Quinta",
         "latitude": 33.6633573,
         "longitude": -116.3100095,
         "postalCode": 92253
      },
      {
         "city": "Laguna Niguel",
         "latitude": 33.5225261,
         "longitude": -117.7075526,
         "postalCode": 92607
      },
      {
         "city": "Lake Elsinore",
         "latitude": 33.6680772,
         "longitude": -117.3272615,
         "postalCode": 92530
      },
      {
         "city": "Lake Forest",
         "latitude": 33.6469661,
         "longitude": -117.689218,
         "postalCode": 92630
      },
      {
         "city": "Lakewood",
         "latitude": 33.8536269,
         "longitude": -118.1339563,
         "postalCode": 90711
      },
      {
         "city": "Lancaster",
         "latitude": 34.6867846,
         "longitude": -118.1541632,
         "postalCode": 93534
      },
      {
         "city": "Lincoln",
         "latitude": 38.891565,
         "longitude": -121.2930079,
         "postalCode": 95648
      },
      {
         "city": "Livermore",
         "latitude": 37.6818745,
         "longitude": -121.7680088,
         "postalCode": 94550
      },
      {
         "city": "Lodi",
         "latitude": 38.1341477,
         "longitude": -121.2722194,
         "postalCode": 95240
      },
      {
         "city": "Lompoc",
         "latitude": 34.6391501,
         "longitude": -120.4579409,
         "postalCode": 93436
      },
      {
         "city": "Long Beach",
         "latitude": 33.7700504,
         "longitude": -118.1937395,
         "postalCode": 90801
      },
      {
         "city": "Los Angeles",
         "latitude": 34.0522342,
         "longitude": -118.2436849,
         "postalCode": 90001
      },
      {
         "city": "Lynwood",
         "latitude": 33.930293,
         "longitude": -118.2114603,
         "postalCode": 90262
      },
      {
         "city": "Madera",
         "latitude": 36.9613356,
         "longitude": -120.0607176,
         "postalCode": 93637
      },
      {
         "city": "Manteca",
         "latitude": 37.7974273,
         "longitude": -121.2160526,
         "postalCode": 95336
      },
      {
         "city": "Martinez",
         "latitude": 38.0193657,
         "longitude": -122.1341321,
         "postalCode": 94553
      },
      {
         "city": "Menifee",
         "latitude": 33.6971468,
         "longitude": -117.185294,
         "postalCode": 92584
      },
      {
         "city": "Merced",
         "latitude": 37.3021632,
         "longitude": -120.4829677,
         "postalCode": 95340
      },
      {
         "city": "Milpitas",
         "latitude": 37.4323341,
         "longitude": -121.8995741,
         "postalCode": 95035
      },
      {
         "city": "Mission Viejo",
         "latitude": 33.6000232,
         "longitude": -117.6719953,
         "postalCode": 92690
      },
      {
         "city": "Modesto",
         "latitude": 37.6390972,
         "longitude": -120.9968782,
         "postalCode": 95350
      },
      {
         "city": "Monrovia",
         "latitude": 34.1442616,
         "longitude": -118.0019482,
         "postalCode": 91016
      },
      {
         "city": "Montclair",
         "latitude": 34.0775104,
         "longitude": -117.6897776,
         "postalCode": 91763
      },
      {
         "city": "Montebello",
         "latitude": 34.0165053,
         "longitude": -118.1137535,
         "postalCode": 90640
      },
      {
         "city": "Monterey Park",
         "latitude": 34.0625106,
         "longitude": -118.1228476,
         "postalCode": 91754
      },
      {
         "city": "Moreno Valley",
         "latitude": 33.9424658,
         "longitude": -117.2296717,
         "postalCode": 92551
      },
      {
         "city": "Morgan Hill",
         "latitude": 37.1305012,
         "longitude": -121.6543901,
         "postalCode": 95037
      },
      {
         "city": "Mountain View",
         "latitude": 37.3860517,
         "longitude": -122.0838511,
         "postalCode": 94035
      },
      {
         "city": "Murrieta",
         "latitude": 33.5539143,
         "longitude": -117.2139232,
         "postalCode": 92562
      },
      {
         "city": "Napa",
         "latitude": 38.2975381,
         "longitude": -122.286865,
         "postalCode": 94558
      },
      {
         "city": "National City",
         "latitude": 32.6781085,
         "longitude": -117.0991967,
         "postalCode": 91950
      },
      {
         "city": "Newark",
         "latitude": 37.5296593,
         "longitude": -122.0402399,
         "postalCode": 94560
      },
      {
         "city": "Newport Beach",
         "latitude": 33.6189101,
         "longitude": -117.9289469,
         "postalCode": 92658
      },
      {
         "city": "Norwalk",
         "latitude": 33.9022367,
         "longitude": -118.081733,
         "postalCode": 90650
      },
      {
         "city": "Novato",
         "latitude": 38.1074198,
         "longitude": -122.5697032,
         "postalCode": 94945
      },
      {
         "city": "Oakland",
         "latitude": 37.8043637,
         "longitude": -122.2711137,
         "postalCode": 94601
      },
      {
         "city": "Oakley",
         "latitude": 37.9974219,
         "longitude": -121.7124536,
         "postalCode": 94561
      },
      {
         "city": "Oceanside",
         "latitude": 33.1958696,
         "longitude": -117.3794834,
         "postalCode": 92049
      },
      {
         "city": "Ontario",
         "latitude": 34.0633443,
         "longitude": -117.6508876,
         "postalCode": 91758
      },
      {
         "city": "Orange",
         "latitude": 33.7877944,
         "longitude": -117.8531119,
         "postalCode": 92856
      },
      {
         "city": "Oxnard",
         "latitude": 34.1975048,
         "longitude": -119.1770516,
         "postalCode": 93030
      },
      {
         "city": "Pacifica",
         "latitude": 37.6138253,
         "longitude": -122.4869194,
         "postalCode": 94044
      },
      {
         "city": "Palm Desert",
         "latitude": 33.7222445,
         "longitude": -116.3744556,
         "postalCode": 92211
      },
      {
         "city": "Palm Springs",
         "latitude": 33.8302961,
         "longitude": -116.5452921,
         "postalCode": 92262
      },
      {
         "city": "Palmdale",
         "latitude": 34.5794343,
         "longitude": -118.1164613,
         "postalCode": 93550
      },
      {
         "city": "Palo Alto",
         "latitude": 37.4418834,
         "longitude": -122.1430195,
         "postalCode": 94301
      },
      {
         "city": "Paramount",
         "latitude": 33.8894598,
         "longitude": -118.1597911,
         "postalCode": 90723
      },
      {
         "city": "Pasadena",
         "latitude": 34.1477849,
         "longitude": -118.1445155,
         "postalCode": 91050
      },
      {
         "city": "Perris",
         "latitude": 33.7825194,
         "longitude": -117.2286478,
         "postalCode": 92570
      },
      {
         "city": "Petaluma",
         "latitude": 38.232417,
         "longitude": -122.6366524,
         "postalCode": 94952
      },
      {
         "city": "Pico Rivera",
         "latitude": 33.9830688,
         "longitude": -118.096735,
         "postalCode": 90660
      },
      {
         "city": "Pittsburg",
         "latitude": 38.0279762,
         "longitude": -121.8846806,
         "postalCode": 94565
      },
      {
         "city": "Placentia",
         "latitude": 33.8722371,
         "longitude": -117.8703363,
         "postalCode": 92870
      },
      {
         "city": "Pleasanton",
         "latitude": 37.6624312,
         "longitude": -121.8746789,
         "postalCode": 94566
      },
      {
         "city": "Pomona",
         "latitude": 34.055103,
         "longitude": -117.7499909,
         "postalCode": 91766
      },
      {
         "city": "Porterville",
         "latitude": 36.06523,
         "longitude": -119.0167679,
         "postalCode": 93257
      },
      {
         "city": "Poway",
         "latitude": 32.9628232,
         "longitude": -117.0358646,
         "postalCode": 92064
      },
      {
         "city": "Rancho Cordova",
         "latitude": 38.5890723,
         "longitude": -121.302728,
         "postalCode": 95670
      },
      {
         "city": "Rancho Cucamonga",
         "latitude": 34.1063989,
         "longitude": -117.5931084,
         "postalCode": 91701
      },
      {
         "city": "Rancho Palos Verdes",
         "latitude": 33.7444613,
         "longitude": -118.3870173,
         "postalCode": 90275
      },
      {
         "city": "Rancho Santa Margarita",
         "latitude": 33.640855,
         "longitude": -117.603104,
         "postalCode": 92688
      },
      {
         "city": "Redding",
         "latitude": 40.5865396,
         "longitude": -122.3916754,
         "postalCode": 96001
      },
      {
         "city": "Redlands",
         "latitude": 34.0555693,
         "longitude": -117.1825381,
         "postalCode": 92373
      },
      {
         "city": "Redondo Beach",
         "latitude": 33.8491816,
         "longitude": -118.3884078,
         "postalCode": 90277
      },
      {
         "city": "Redwood City",
         "latitude": 37.4852152,
         "longitude": -122.2363548,
         "postalCode": 94059
      },
      {
         "city": "Rialto",
         "latitude": 34.1064001,
         "longitude": -117.3703235,
         "postalCode": 92376
      },
      {
         "city": "Richmond",
         "latitude": 37.9357576,
         "longitude": -122.3477486,
         "postalCode": 94801
      },
      {
         "city": "Riverside",
         "latitude": 33.9533487,
         "longitude": -117.3961564,
         "postalCode": 92501
      },
      {
         "city": "Rocklin",
         "latitude": 38.7907339,
         "longitude": -121.2357828,
         "postalCode": 95677
      },
      {
         "city": "Rohnert Park",
         "latitude": 38.3396367,
         "longitude": -122.7010984,
         "postalCode": 94927
      },
      {
         "city": "Rosemead",
         "latitude": 34.0805651,
         "longitude": -118.072846,
         "postalCode": 91770
      },
      {
         "city": "Roseville",
         "latitude": 38.7521235,
         "longitude": -121.2880059,
         "postalCode": 95661
      },
      {
         "city": "Sacramento",
         "latitude": 38.5815719,
         "longitude": -121.4943996,
         "postalCode": 94203
      },
      {
         "city": "Salinas",
         "latitude": 36.6777372,
         "longitude": -121.6555013,
         "postalCode": 93901
      },
      {
         "city": "San Bernardino",
         "latitude": 34.1083449,
         "longitude": -117.2897652,
         "postalCode": 92401
      },
      {
         "city": "San Bruno",
         "latitude": 37.6304904,
         "longitude": -122.4110835,
         "postalCode": 94066
      },
      {
         "city": "San Buenaventura (Ventura)",
         "latitude": 34.274646,
         "longitude": -119.2290316,
         "postalCode": ""
      },
      {
         "city": "San Clemente",
         "latitude": 33.4269728,
         "longitude": -117.6119925,
         "postalCode": 92672
      },
      {
         "city": "San Diego",
         "latitude": 32.715738,
         "longitude": -117.1610838,
         "postalCode": 92101
      },
      {
         "city": "San Francisco",
         "latitude": 37.7749295,
         "longitude": -122.4194155,
         "postalCode": 94101
      },
      {
         "city": "San Gabriel",
         "latitude": 34.0961111,
         "longitude": -118.1058333,
         "postalCode": 91775
      },
      {
         "city": "San Jacinto",
         "latitude": 33.7839084,
         "longitude": -116.958635,
         "postalCode": 92581
      },
      {
         "city": "San Jose",
         "latitude": 37.3382082,
         "longitude": -121.8863286,
         "postalCode": 95101
      },
      {
         "city": "San Leandro",
         "latitude": 37.7249296,
         "longitude": -122.1560768,
         "postalCode": 94577
      },
      {
         "city": "San Luis Obispo",
         "latitude": 35.2827524,
         "longitude": -120.6596156,
         "postalCode": 93401
      },
      {
         "city": "San Marcos",
         "latitude": 33.1433723,
         "longitude": -117.1661449,
         "postalCode": 92069
      },
      {
         "city": "San Mateo",
         "latitude": 37.5629917,
         "longitude": -122.3255254,
         "postalCode": 94401
      },
      {
         "city": "San Rafael",
         "latitude": 37.9735346,
         "longitude": -122.5310874,
         "postalCode": 94901
      },
      {
         "city": "San Ramon",
         "latitude": 37.7799273,
         "longitude": -121.9780153,
         "postalCode": 94583
      },
      {
         "city": "Santa Ana",
         "latitude": 33.7455731,
         "longitude": -117.8678338,
         "postalCode": 92701
      },
      {
         "city": "Santa Barbara",
         "latitude": 34.4208305,
         "longitude": -119.6981901,
         "postalCode": 93101
      },
      {
         "city": "Santa Clara",
         "latitude": 37.3541079,
         "longitude": -121.9552356,
         "postalCode": 95050
      },
      {
         "city": "Santa Clarita",
         "latitude": 34.3916641,
         "longitude": -118.542586,
         "postalCode": 91350
      },
      {
         "city": "Santa Cruz",
         "latitude": 36.9741171,
         "longitude": -122.0307963,
         "postalCode": 95060
      },
      {
         "city": "Santa Maria",
         "latitude": 34.9530337,
         "longitude": -120.4357191,
         "postalCode": 93454
      },
      {
         "city": "Santa Monica",
         "latitude": 34.0194543,
         "longitude": -118.4911912,
         "postalCode": 90401
      },
      {
         "city": "Santa Rosa",
         "latitude": 38.440429,
         "longitude": -122.7140548,
         "postalCode": 95401
      },
      {
         "city": "Santee",
         "latitude": 32.8383828,
         "longitude": -116.9739167,
         "postalCode": 92071
      },
      {
         "city": "Simi Valley",
         "latitude": 34.2694474,
         "longitude": -118.781482,
         "postalCode": 93062
      },
      {
         "city": "South Gate",
         "latitude": 33.954737,
         "longitude": -118.2120161,
         "postalCode": 90280
      },
      {
         "city": "South San Francisco",
         "latitude": 37.654656,
         "longitude": -122.4077498,
         "postalCode": 94080
      },
      {
         "city": "Stanton",
         "latitude": 33.8025155,
         "longitude": -117.9931165,
         "postalCode": 90680
      },
      {
         "city": "Stockton",
         "latitude": 37.9577016,
         "longitude": -121.2907796,
         "postalCode": 95201
      },
      {
         "city": "Sunnyvale",
         "latitude": 37.36883,
         "longitude": -122.0363496,
         "postalCode": 94085
      },
      {
         "city": "Temecula",
         "latitude": 33.4936391,
         "longitude": -117.1483648,
         "postalCode": 92589
      },
      {
         "city": "Thousand Oaks",
         "latitude": 34.1705609,
         "longitude": -118.8375937,
         "postalCode": 91358
      },
      {
         "city": "Torrance",
         "latitude": 33.8358492,
         "longitude": -118.3406288,
         "postalCode": 90501
      },
      {
         "city": "Tracy",
         "latitude": 37.7396513,
         "longitude": -121.4252227,
         "postalCode": 95304
      },
      {
         "city": "Tulare",
         "latitude": 36.2077288,
         "longitude": -119.3473379,
         "postalCode": 93274
      },
      {
         "city": "Turlock",
         "latitude": 37.4946568,
         "longitude": -120.8465941,
         "postalCode": 95380
      },
      {
         "city": "Tustin",
         "latitude": 33.7458511,
         "longitude": -117.826166,
         "postalCode": 92780
      },
      {
         "city": "Union City",
         "latitude": 37.5933918,
         "longitude": -122.0438298,
         "postalCode": 94587
      },
      {
         "city": "Upland",
         "latitude": 34.09751,
         "longitude": -117.6483876,
         "postalCode": 91784
      },
      {
         "city": "Vacaville",
         "latitude": 38.3565773,
         "longitude": -121.9877444,
         "postalCode": 95687
      },
      {
         "city": "Vallejo",
         "latitude": 38.1040864,
         "longitude": -122.2566367,
         "postalCode": 94589
      },
      {
         "city": "Victorville",
         "latitude": 34.5362184,
         "longitude": -117.2927641,
         "postalCode": 92392
      },
      {
         "city": "Visalia",
         "latitude": 36.3302284,
         "longitude": -119.2920585,
         "postalCode": 93277
      },
      {
         "city": "Vista",
         "latitude": 33.2000368,
         "longitude": -117.2425355,
         "postalCode": 92083
      },
      {
         "city": "Walnut Creek",
         "latitude": 37.9100783,
         "longitude": -122.0651819,
         "postalCode": 94595
      },
      {
         "city": "Watsonville",
         "latitude": 36.910231,
         "longitude": -121.7568946,
         "postalCode": 95076
      },
      {
         "city": "West Covina",
         "latitude": 34.0686208,
         "longitude": -117.9389526,
         "postalCode": 91790
      },
      {
         "city": "West Sacramento",
         "latitude": 38.5804609,
         "longitude": -121.530234,
         "postalCode": 95605
      },
      {
         "city": "Westminster",
         "latitude": 33.7513419,
         "longitude": -117.9939921,
         "postalCode": 92683
      },
      {
         "city": "Whittier",
         "latitude": 33.9791793,
         "longitude": -118.032844,
         "postalCode": 90601
      },
      {
         "city": "Woodland",
         "latitude": 38.6785157,
         "longitude": -121.7732971,
         "postalCode": 95695
      },
      {
         "city": "Yorba Linda",
         "latitude": 33.8886259,
         "longitude": -117.8131125,
         "postalCode": 92885
      },
      {
         "city": "Yuba City",
         "latitude": 39.1404477,
         "longitude": -121.6169108,
         "postalCode": 95991
      },
      {
         "city": "Yucaipa",
         "latitude": 34.033625,
         "longitude": -117.0430865,
         "postalCode": 92399
      }
   ],
   "Colorado": [
      {
         "city": "Arvada",
         "latitude": 39.8027644,
         "longitude": -105.0874842,
         "postalCode": 80001
      },
      {
         "city": "Aurora",
         "latitude": 39.7294319,
         "longitude": -104.8319195,
         "postalCode": 80010
      },
      {
         "city": "Boulder",
         "latitude": 40.0149856,
         "longitude": -105.2705456,
         "postalCode": 80301
      },
      {
         "city": "Broomfield",
         "latitude": 39.9205411,
         "longitude": -105.0866504,
         "postalCode": 80020
      },
      {
         "city": "Castle Rock",
         "latitude": 39.3722121,
         "longitude": -104.8560902,
         "postalCode": 80104
      },
      {
         "city": "Centennial",
         "latitude": 39.5807452,
         "longitude": -104.8771726,
         "postalCode": ""
      },
      {
         "city": "Colorado Springs",
         "latitude": 38.8338816,
         "longitude": -104.8213634,
         "postalCode": 80901
      },
      {
         "city": "Commerce City",
         "latitude": 39.8083196,
         "longitude": -104.9338675,
         "postalCode": 80022
      },
      {
         "city": "Denver",
         "latitude": 39.7392358,
         "longitude": -104.990251,
         "postalCode": 80201
      },
      {
         "city": "Fort Collins",
         "latitude": 40.5852602,
         "longitude": -105.084423,
         "postalCode": 80521
      },
      {
         "city": "Grand Junction",
         "latitude": 39.0638705,
         "longitude": -108.5506486,
         "postalCode": 81501
      },
      {
         "city": "Greeley",
         "latitude": 40.4233142,
         "longitude": -104.7091322,
         "postalCode": 80631
      },
      {
         "city": "Lakewood",
         "latitude": 39.7047095,
         "longitude": -105.0813734,
         "postalCode": ""
      },
      {
         "city": "Littleton",
         "latitude": 39.613321,
         "longitude": -105.0166498,
         "postalCode": 80120
      },
      {
         "city": "Longmont",
         "latitude": 40.1672068,
         "longitude": -105.1019275,
         "postalCode": 80501
      },
      {
         "city": "Loveland",
         "latitude": 40.3977612,
         "longitude": -105.0749801,
         "postalCode": 80537
      },
      {
         "city": "Northglenn",
         "latitude": 39.8961821,
         "longitude": -104.9811468,
         "postalCode": ""
      },
      {
         "city": "Parker",
         "latitude": 39.5186002,
         "longitude": -104.7613633,
         "postalCode": 80134
      },
      {
         "city": "Pueblo",
         "latitude": 38.2544472,
         "longitude": -104.6091409,
         "postalCode": 81001
      },
      {
         "city": "Thornton",
         "latitude": 39.8680412,
         "longitude": -104.9719243,
         "postalCode": ""
      },
      {
         "city": "Westminster",
         "latitude": 39.8366528,
         "longitude": -105.0372046,
         "postalCode": 80030
      }
   ],
   "Connecticut": [
      {
         "city": "Bridgeport",
         "latitude": 41.1865478,
         "longitude": -73.1951767,
         "postalCode": 6601
      },
      {
         "city": "Bristol",
         "latitude": 41.6717648,
         "longitude": -72.9492703,
         "postalCode": 6010
      },
      {
         "city": "Danbury",
         "latitude": 41.394817,
         "longitude": -73.4540111,
         "postalCode": 6810
      },
      {
         "city": "Hartford",
         "latitude": 41.7637111,
         "longitude": -72.6850932,
         "postalCode": 6101
      },
      {
         "city": "Meriden",
         "latitude": 41.5381535,
         "longitude": -72.8070435,
         "postalCode": 6450
      },
      {
         "city": "Middletown",
         "latitude": 41.5623209,
         "longitude": -72.6506488,
         "postalCode": 6457
      },
      {
         "city": "Milford",
         "latitude": 41.2306979,
         "longitude": -73.064036,
         "postalCode": 6460
      },
      {
         "city": "New Britain",
         "latitude": 41.6612104,
         "longitude": -72.7795419,
         "postalCode": 6050
      },
      {
         "city": "New Haven",
         "latitude": 41.308274,
         "longitude": -72.9278835,
         "postalCode": 6501
      },
      {
         "city": "Norwalk",
         "latitude": 41.117744,
         "longitude": -73.4081575,
         "postalCode": 6850
      },
      {
         "city": "Norwich",
         "latitude": 41.5242649,
         "longitude": -72.0759105,
         "postalCode": 6360
      },
      {
         "city": "Shelton",
         "latitude": 41.3164856,
         "longitude": -73.0931641,
         "postalCode": 6484
      },
      {
         "city": "Stamford",
         "latitude": 41.0534302,
         "longitude": -73.5387341,
         "postalCode": 6901
      },
      {
         "city": "Waterbury",
         "latitude": 41.5581525,
         "longitude": -73.0514965,
         "postalCode": 6701
      },
      {
         "city": "West Haven",
         "latitude": 41.2705484,
         "longitude": -72.9469711,
         "postalCode": 6516
      }
   ],
   "Delaware": [
      {
         "city": "Dover",
         "latitude": 39.158168,
         "longitude": -75.5243682,
         "postalCode": 19901
      },
      {
         "city": "Wilmington",
         "latitude": 39.7390721,
         "longitude": -75.5397878,
         "postalCode": 19801
      }
   ],
   "District of Columbia": [
      {
         "city": "Washington",
         "latitude": 38.9071923,
         "longitude": -77.0368707,
         "postalCode": ""
      }
   ],
   "Florida": [
      {
         "city": "Altamonte Springs",
         "latitude": 28.6611089,
         "longitude": -81.3656242,
         "postalCode": 32701
      },
      {
         "city": "Apopka",
         "latitude": 28.6934076,
         "longitude": -81.5322149,
         "postalCode": 32703
      },
      {
         "city": "Aventura",
         "latitude": 25.9564812,
         "longitude": -80.1392121,
         "postalCode": ""
      },
      {
         "city": "Boca Raton",
         "latitude": 26.3683064,
         "longitude": -80.1289321,
         "postalCode": 33427
      },
      {
         "city": "Bonita Springs",
         "latitude": 26.339806,
         "longitude": -81.7786972,
         "postalCode": 34133
      },
      {
         "city": "Boynton Beach",
         "latitude": 26.5317866,
         "longitude": -80.0905465,
         "postalCode": 33424
      },
      {
         "city": "Bradenton",
         "latitude": 27.4989278,
         "longitude": -82.5748194,
         "postalCode": 34201
      },
      {
         "city": "Cape Coral",
         "latitude": 26.5628537,
         "longitude": -81.9495331,
         "postalCode": 33904
      },
      {
         "city": "Clearwater",
         "latitude": 27.9658533,
         "longitude": -82.8001026,
         "postalCode": 33755
      },
      {
         "city": "Coconut Creek",
         "latitude": 26.2517482,
         "longitude": -80.1789351,
         "postalCode": ""
      },
      {
         "city": "Coral Gables",
         "latitude": 25.72149,
         "longitude": -80.2683838,
         "postalCode": 33124
      },
      {
         "city": "Coral Springs",
         "latitude": 26.271192,
         "longitude": -80.2706044,
         "postalCode": ""
      },
      {
         "city": "Cutler Bay",
         "latitude": 25.5808323,
         "longitude": -80.3468593,
         "postalCode": ""
      },
      {
         "city": "Davie",
         "latitude": 26.0764783,
         "longitude": -80.2521157,
         "postalCode": ""
      },
      {
         "city": "Daytona Beach",
         "latitude": 29.2108147,
         "longitude": -81.0228331,
         "postalCode": 32114
      },
      {
         "city": "Deerfield Beach",
         "latitude": 26.3184123,
         "longitude": -80.0997657,
         "postalCode": 33441
      },
      {
         "city": "Delray Beach",
         "latitude": 26.4614625,
         "longitude": -80.0728201,
         "postalCode": 33444
      },
      {
         "city": "Deltona",
         "latitude": 28.9005446,
         "longitude": -81.2636738,
         "postalCode": 32725
      },
      {
         "city": "Doral",
         "latitude": 25.8195424,
         "longitude": -80.3553302,
         "postalCode": ""
      },
      {
         "city": "Fort Lauderdale",
         "latitude": 26.1224386,
         "longitude": -80.1373174,
         "postalCode": 33301
      },
      {
         "city": "Fort Myers",
         "latitude": 26.640628,
         "longitude": -81.8723084,
         "postalCode": 33901
      },
      {
         "city": "Fort Pierce",
         "latitude": 27.4467056,
         "longitude": -80.3256056,
         "postalCode": 34945
      },
      {
         "city": "Gainesville",
         "latitude": 29.6516344,
         "longitude": -82.3248262,
         "postalCode": 32601
      },
      {
         "city": "Greenacres",
         "latitude": 26.6276276,
         "longitude": -80.1353896,
         "postalCode": ""
      },
      {
         "city": "Hallandale Beach",
         "latitude": 25.9812024,
         "longitude": -80.148379,
         "postalCode": ""
      },
      {
         "city": "Hialeah",
         "latitude": 25.8575963,
         "longitude": -80.2781057,
         "postalCode": 33002
      },
      {
         "city": "Hollywood",
         "latitude": 26.0112014,
         "longitude": -80.1494901,
         "postalCode": 33019
      },
      {
         "city": "Homestead",
         "latitude": 25.4687224,
         "longitude": -80.4775569,
         "postalCode": 33030
      },
      {
         "city": "Jacksonville",
         "latitude": 30.3321838,
         "longitude": -81.655651,
         "postalCode": 32099
      },
      {
         "city": "Jupiter",
         "latitude": 26.9342246,
         "longitude": -80.0942087,
         "postalCode": 33458
      },
      {
         "city": "Kissimmee",
         "latitude": 28.2919557,
         "longitude": -81.407571,
         "postalCode": 34741
      },
      {
         "city": "Lakeland",
         "latitude": 28.0394654,
         "longitude": -81.9498042,
         "postalCode": 33801
      },
      {
         "city": "Largo",
         "latitude": 27.9094665,
         "longitude": -82.7873244,
         "postalCode": 33770
      },
      {
         "city": "Lauderhill",
         "latitude": 26.1403635,
         "longitude": -80.2133808,
         "postalCode": ""
      },
      {
         "city": "Margate",
         "latitude": 26.2445263,
         "longitude": -80.206436,
         "postalCode": ""
      },
      {
         "city": "Melbourne",
         "latitude": 28.0836269,
         "longitude": -80.6081089,
         "postalCode": 32901
      },
      {
         "city": "Miami",
         "latitude": 25.7616798,
         "longitude": -80.1917902,
         "postalCode": 33101
      },
      {
         "city": "Miami Beach",
         "latitude": 25.790654,
         "longitude": -80.1300455,
         "postalCode": 33109
      },
      {
         "city": "Miami Gardens",
         "latitude": 25.9420377,
         "longitude": -80.2456045,
         "postalCode": ""
      },
      {
         "city": "Miramar",
         "latitude": 25.9860762,
         "longitude": -80.3035602,
         "postalCode": ""
      },
      {
         "city": "North Lauderdale",
         "latitude": 26.217305,
         "longitude": -80.2258811,
         "postalCode": ""
      },
      {
         "city": "North Miami",
         "latitude": 25.8900949,
         "longitude": -80.1867138,
         "postalCode": ""
      },
      {
         "city": "North Miami Beach",
         "latitude": 25.9331488,
         "longitude": -80.1625463,
         "postalCode": 33160
      },
      {
         "city": "North Port",
         "latitude": 27.044224,
         "longitude": -82.2359254,
         "postalCode": 34286
      },
      {
         "city": "Oakland Park",
         "latitude": 26.1723065,
         "longitude": -80.1319893,
         "postalCode": ""
      },
      {
         "city": "Ocala",
         "latitude": 29.1871986,
         "longitude": -82.1400923,
         "postalCode": 34470
      },
      {
         "city": "Ocoee",
         "latitude": 28.5691677,
         "longitude": -81.5439619,
         "postalCode": 34761
      },
      {
         "city": "Orlando",
         "latitude": 28.5383355,
         "longitude": -81.3792365,
         "postalCode": 32801
      },
      {
         "city": "Ormond Beach",
         "latitude": 29.2858129,
         "longitude": -81.0558894,
         "postalCode": 32173
      },
      {
         "city": "Palm Bay",
         "latitude": 28.0344621,
         "longitude": -80.5886646,
         "postalCode": 32905
      },
      {
         "city": "Palm Beach Gardens",
         "latitude": 26.8233946,
         "longitude": -80.1386547,
         "postalCode": ""
      },
      {
         "city": "Palm Coast",
         "latitude": 29.5844524,
         "longitude": -81.2078699,
         "postalCode": 32135
      },
      {
         "city": "Panama City",
         "latitude": 30.1588129,
         "longitude": -85.6602058,
         "postalCode": 32401
      },
      {
         "city": "Pembroke Pines",
         "latitude": 26.007765,
         "longitude": -80.2962555,
         "postalCode": 33082
      },
      {
         "city": "Pensacola",
         "latitude": 30.421309,
         "longitude": -87.2169149,
         "postalCode": 32501
      },
      {
         "city": "Pinellas Park",
         "latitude": 27.8428025,
         "longitude": -82.6995443,
         "postalCode": 33780
      },
      {
         "city": "Plantation",
         "latitude": 26.1275862,
         "longitude": -80.2331036,
         "postalCode": ""
      },
      {
         "city": "Pompano Beach",
         "latitude": 26.2378597,
         "longitude": -80.1247667,
         "postalCode": 33060
      },
      {
         "city": "Port Orange",
         "latitude": 29.1383165,
         "longitude": -80.9956105,
         "postalCode": 32129
      },
      {
         "city": "Port St. Lucie",
         "latitude": 27.2730492,
         "longitude": -80.3582261,
         "postalCode": ""
      },
      {
         "city": "Sanford",
         "latitude": 28.8028612,
         "longitude": -81.269453,
         "postalCode": 32771
      },
      {
         "city": "Sarasota",
         "latitude": 27.3364347,
         "longitude": -82.5306527,
         "postalCode": 34230
      },
      {
         "city": "St. Cloud",
         "latitude": 28.2489016,
         "longitude": -81.2811801,
         "postalCode": ""
      },
      {
         "city": "St. Petersburg",
         "latitude": 27.773056,
         "longitude": -82.64,
         "postalCode": 33701
      },
      {
         "city": "Sunrise",
         "latitude": 26.1669711,
         "longitude": -80.256595,
         "postalCode": ""
      },
      {
         "city": "Tallahassee",
         "latitude": 30.4382559,
         "longitude": -84.2807329,
         "postalCode": 32301
      },
      {
         "city": "Tamarac",
         "latitude": 26.2128609,
         "longitude": -80.2497707,
         "postalCode": ""
      },
      {
         "city": "Tampa",
         "latitude": 27.950575,
         "longitude": -82.4571776,
         "postalCode": 33601
      },
      {
         "city": "Titusville",
         "latitude": 28.6122187,
         "longitude": -80.8075537,
         "postalCode": 32780
      },
      {
         "city": "Wellington",
         "latitude": 26.6617635,
         "longitude": -80.2683571,
         "postalCode": ""
      },
      {
         "city": "West Palm Beach",
         "latitude": 26.7153424,
         "longitude": -80.0533746,
         "postalCode": 33401
      },
      {
         "city": "Weston",
         "latitude": 26.1003654,
         "longitude": -80.3997748,
         "postalCode": ""
      },
      {
         "city": "Winter Garden",
         "latitude": 28.5652787,
         "longitude": -81.5861847,
         "postalCode": 34777
      }
   ],
   "Georgia": [
      {
         "city": "Albany",
         "latitude": 31.5785074,
         "longitude": -84.155741,
         "postalCode": 31701
      },
      {
         "city": "Alpharetta",
         "latitude": 34.0753762,
         "longitude": -84.2940899,
         "postalCode": 30004
      },
      {
         "city": "Athens-Clarke County",
         "latitude": 33.9519347,
         "longitude": -83.357567,
         "postalCode": ""
      },
      {
         "city": "Atlanta",
         "latitude": 33.7489954,
         "longitude": -84.3879824,
         "postalCode": 30301
      },
      {
         "city": "Augusta-Richmond County",
         "latitude": 33.4734978,
         "longitude": -82.0105148,
         "postalCode": ""
      },
      {
         "city": "Brookhaven",
         "latitude": 33.8651033,
         "longitude": -84.3365917,
         "postalCode": ""
      },
      {
         "city": "Columbus",
         "latitude": 32.4609764,
         "longitude": -84.9877094,
         "postalCode": 31901
      },
      {
         "city": "Dunwoody",
         "latitude": 33.9462125,
         "longitude": -84.3346473,
         "postalCode": ""
      },
      {
         "city": "Johns Creek",
         "latitude": 34.0289259,
         "longitude": -84.198579,
         "postalCode": ""
      },
      {
         "city": "Macon",
         "latitude": 32.8406946,
         "longitude": -83.6324022,
         "postalCode": 31201
      },
      {
         "city": "Marietta",
         "latitude": 33.952602,
         "longitude": -84.5499327,
         "postalCode": 30006
      },
      {
         "city": "Peachtree Corners",
         "latitude": 33.9698929,
         "longitude": -84.2214551,
         "postalCode": ""
      },
      {
         "city": "Roswell",
         "latitude": 34.0232431,
         "longitude": -84.3615555,
         "postalCode": 30075
      },
      {
         "city": "Sandy Springs",
         "latitude": 33.9304352,
         "longitude": -84.3733147,
         "postalCode": ""
      },
      {
         "city": "Savannah",
         "latitude": 32.0835407,
         "longitude": -81.0998342,
         "postalCode": 31401
      },
      {
         "city": "Smyrna",
         "latitude": 33.8839926,
         "longitude": -84.5143761,
         "postalCode": 30080
      },
      {
         "city": "Valdosta",
         "latitude": 30.8327022,
         "longitude": -83.2784851,
         "postalCode": 31601
      },
      {
         "city": "Warner Robins",
         "latitude": 32.6130007,
         "longitude": -83.624201,
         "postalCode": 31088
      }
   ],
   "Hawaii": [
      {
         "city": "Honolulu",
         "latitude": 21.3069444,
         "longitude": -157.8583333,
         "postalCode": 96801
      }
   ],
   "Idaho": [
      {
         "city": "Boise City",
         "latitude": 43.6187102,
         "longitude": -116.2146068,
         "postalCode": ""
      },
      {
         "city": "Caldwell",
         "latitude": 43.6629384,
         "longitude": -116.6873596,
         "postalCode": 83605
      },
      {
         "city": "Coeur d'Alene",
         "latitude": 47.6776832,
         "longitude": -116.7804664,
         "postalCode": ""
      },
      {
         "city": "Idaho Falls",
         "latitude": 43.4916514,
         "longitude": -112.0339645,
         "postalCode": 83401
      },
      {
         "city": "Meridian",
         "latitude": 43.6121087,
         "longitude": -116.3915131,
         "postalCode": 83642
      },
      {
         "city": "Nampa",
         "latitude": 43.5407172,
         "longitude": -116.5634624,
         "postalCode": 83651
      },
      {
         "city": "Pocatello",
         "latitude": 42.8713032,
         "longitude": -112.4455344,
         "postalCode": 83201
      },
      {
         "city": "Twin Falls",
         "latitude": 42.5629668,
         "longitude": -114.4608711,
         "postalCode": 83301
      }
   ],
   "Illinois": [
      {
         "city": "Addison",
         "latitude": 41.931696,
         "longitude": -87.9889556,
         "postalCode": 60101
      },
      {
         "city": "Arlington Heights",
         "latitude": 42.0883603,
         "longitude": -87.9806265,
         "postalCode": 60004
      },
      {
         "city": "Aurora",
         "latitude": 41.7605849,
         "longitude": -88.3200715,
         "postalCode": 60504
      },
      {
         "city": "Bartlett",
         "latitude": 41.9950276,
         "longitude": -88.1856301,
         "postalCode": 60103
      },
      {
         "city": "Belleville",
         "latitude": 38.5200504,
         "longitude": -89.9839935,
         "postalCode": 62220
      },
      {
         "city": "Berwyn",
         "latitude": 41.8505874,
         "longitude": -87.7936685,
         "postalCode": 60402
      },
      {
         "city": "Bloomington",
         "latitude": 40.4842027,
         "longitude": -88.9936873,
         "postalCode": 61701
      },
      {
         "city": "Bolingbrook",
         "latitude": 41.6986416,
         "longitude": -88.0683955,
         "postalCode": 60440
      },
      {
         "city": "Buffalo Grove",
         "latitude": 42.1662831,
         "longitude": -87.9631308,
         "postalCode": 60089
      },
      {
         "city": "Calumet City",
         "latitude": 41.6155909,
         "longitude": -87.5294871,
         "postalCode": 60409
      },
      {
         "city": "Carol Stream",
         "latitude": 41.9125286,
         "longitude": -88.1347927,
         "postalCode": 60116
      },
      {
         "city": "Carpentersville",
         "latitude": 42.1211364,
         "longitude": -88.2578582,
         "postalCode": 60110
      },
      {
         "city": "Champaign",
         "latitude": 40.1164204,
         "longitude": -88.2433829,
         "postalCode": 61820
      },
      {
         "city": "Chicago",
         "latitude": 41.8781136,
         "longitude": -87.6297982,
         "postalCode": 60601
      },
      {
         "city": "Cicero",
         "latitude": 41.8455877,
         "longitude": -87.7539448,
         "postalCode": 60804
      },
      {
         "city": "Crystal Lake",
         "latitude": 42.2411344,
         "longitude": -88.3161965,
         "postalCode": 60012
      },
      {
         "city": "DeKalb",
         "latitude": 41.9294736,
         "longitude": -88.7503647,
         "postalCode": 60115
      },
      {
         "city": "Decatur",
         "latitude": 39.8403147,
         "longitude": -88.9548001,
         "postalCode": 62521
      },
      {
         "city": "Des Plaines",
         "latitude": 42.0333623,
         "longitude": -87.8833991,
         "postalCode": 60016
      },
      {
         "city": "Downers Grove",
         "latitude": 41.8089191,
         "longitude": -88.0111746,
         "postalCode": 60515
      },
      {
         "city": "Elgin",
         "latitude": 42.0354084,
         "longitude": -88.2825668,
         "postalCode": 60120
      },
      {
         "city": "Elmhurst",
         "latitude": 41.8994744,
         "longitude": -87.9403418,
         "postalCode": 60126
      },
      {
         "city": "Evanston",
         "latitude": 42.0450722,
         "longitude": -87.6876969,
         "postalCode": 60201
      },
      {
         "city": "Glenview",
         "latitude": 42.0697509,
         "longitude": -87.7878408,
         "postalCode": 60025
      },
      {
         "city": "Hanover Park",
         "latitude": 41.9994722,
         "longitude": -88.1450735,
         "postalCode": 60133
      },
      {
         "city": "Hoffman Estates",
         "latitude": 42.0629915,
         "longitude": -88.1227199,
         "postalCode": 60179
      },
      {
         "city": "Joliet",
         "latitude": 41.525031,
         "longitude": -88.0817251,
         "postalCode": 60431
      },
      {
         "city": "Lombard",
         "latitude": 41.8800296,
         "longitude": -88.0078435,
         "postalCode": 60148
      },
      {
         "city": "Moline",
         "latitude": 41.5067003,
         "longitude": -90.5151342,
         "postalCode": 61265
      },
      {
         "city": "Mount Prospect",
         "latitude": 42.0664167,
         "longitude": -87.9372908,
         "postalCode": 60056
      },
      {
         "city": "Naperville",
         "latitude": 41.7508391,
         "longitude": -88.1535352,
         "postalCode": 60540
      },
      {
         "city": "Normal",
         "latitude": 40.5142026,
         "longitude": -88.9906312,
         "postalCode": 61761
      },
      {
         "city": "Oak Lawn",
         "latitude": 41.719978,
         "longitude": -87.7479528,
         "postalCode": 60453
      },
      {
         "city": "Oak Park",
         "latitude": 41.8850317,
         "longitude": -87.7845025,
         "postalCode": 60301
      },
      {
         "city": "Orland Park",
         "latitude": 41.6303103,
         "longitude": -87.8539425,
         "postalCode": 60462
      },
      {
         "city": "Palatine",
         "latitude": 42.1103041,
         "longitude": -88.03424,
         "postalCode": 60038
      },
      {
         "city": "Park Ridge",
         "latitude": 42.0111412,
         "longitude": -87.8406192,
         "postalCode": 60068
      },
      {
         "city": "Peoria",
         "latitude": 40.6936488,
         "longitude": -89.5889864,
         "postalCode": 61601
      },
      {
         "city": "Plainfield",
         "latitude": 41.632223,
         "longitude": -88.2120315,
         "postalCode": 60544
      },
      {
         "city": "Quincy",
         "latitude": 39.9356016,
         "longitude": -91.4098726,
         "postalCode": 62301
      },
      {
         "city": "Rock Island",
         "latitude": 41.5094771,
         "longitude": -90.5787476,
         "postalCode": 61201
      },
      {
         "city": "Rockford",
         "latitude": 42.2711311,
         "longitude": -89.0939952,
         "postalCode": 61101
      },
      {
         "city": "Romeoville",
         "latitude": 41.6475306,
         "longitude": -88.0895061,
         "postalCode": 60446
      },
      {
         "city": "Schaumburg",
         "latitude": 42.0333607,
         "longitude": -88.0834059,
         "postalCode": 60159
      },
      {
         "city": "Skokie",
         "latitude": 42.0324025,
         "longitude": -87.7416246,
         "postalCode": 60076
      },
      {
         "city": "Springfield",
         "latitude": 39.7817213,
         "longitude": -89.6501481,
         "postalCode": 62701
      },
      {
         "city": "Streamwood",
         "latitude": 42.0255827,
         "longitude": -88.1784085,
         "postalCode": 60107
      },
      {
         "city": "Tinley Park",
         "latitude": 41.5731442,
         "longitude": -87.7932939,
         "postalCode": 60477
      },
      {
         "city": "Urbana",
         "latitude": 40.1105875,
         "longitude": -88.2072697,
         "postalCode": 61801
      },
      {
         "city": "Waukegan",
         "latitude": 42.3636331,
         "longitude": -87.8447938,
         "postalCode": 60079
      },
      {
         "city": "Wheaton",
         "latitude": 41.8661403,
         "longitude": -88.1070127,
         "postalCode": 60187
      },
      {
         "city": "Wheeling",
         "latitude": 42.1391927,
         "longitude": -87.9289591,
         "postalCode": 60090
      }
   ],
   "Indiana": [
      {
         "city": "Anderson",
         "latitude": 40.1053196,
         "longitude": -85.6802541,
         "postalCode": 46011
      },
      {
         "city": "Bloomington",
         "latitude": 39.165325,
         "longitude": -86.5263857,
         "postalCode": 47401
      },
      {
         "city": "Carmel",
         "latitude": 39.978371,
         "longitude": -86.1180435,
         "postalCode": 46032
      },
      {
         "city": "Columbus",
         "latitude": 39.2014404,
         "longitude": -85.9213796,
         "postalCode": 47201
      },
      {
         "city": "Elkhart",
         "latitude": 41.6819935,
         "longitude": -85.9766671,
         "postalCode": 46514
      },
      {
         "city": "Evansville",
         "latitude": 37.9715592,
         "longitude": -87.5710898,
         "postalCode": 47701
      },
      {
         "city": "Fishers",
         "latitude": 39.9567548,
         "longitude": -86.01335,
         "postalCode": 46038
      },
      {
         "city": "Fort Wayne",
         "latitude": 41.079273,
         "longitude": -85.1393513,
         "postalCode": 46801
      },
      {
         "city": "Gary",
         "latitude": 41.5933696,
         "longitude": -87.3464271,
         "postalCode": 46401
      },
      {
         "city": "Greenwood",
         "latitude": 39.6136578,
         "longitude": -86.1066526,
         "postalCode": 46142
      },
      {
         "city": "Hammond",
         "latitude": 41.5833688,
         "longitude": -87.5000412,
         "postalCode": 46320
      },
      {
         "city": "Indianapolis",
         "latitude": 39.768403,
         "longitude": -86.158068,
         "postalCode": 46201
      },
      {
         "city": "Jeffersonville",
         "latitude": 38.2775702,
         "longitude": -85.7371847,
         "postalCode": 47130
      },
      {
         "city": "Kokomo",
         "latitude": 40.486427,
         "longitude": -86.1336033,
         "postalCode": 46901
      },
      {
         "city": "Lafayette",
         "latitude": 40.4167022,
         "longitude": -86.8752869,
         "postalCode": 47901
      },
      {
         "city": "Lawrence",
         "latitude": 39.8386516,
         "longitude": -86.0252612,
         "postalCode": ""
      },
      {
         "city": "Mishawaka",
         "latitude": 41.6619927,
         "longitude": -86.1586156,
         "postalCode": 46544
      },
      {
         "city": "Muncie",
         "latitude": 40.1933767,
         "longitude": -85.3863599,
         "postalCode": 47302
      },
      {
         "city": "Noblesville",
         "latitude": 40.0455917,
         "longitude": -86.0085955,
         "postalCode": 46060
      },
      {
         "city": "South Bend",
         "latitude": 41.6763545,
         "longitude": -86.2519898,
         "postalCode": 46601
      },
      {
         "city": "Terre Haute",
         "latitude": 39.4667034,
         "longitude": -87.4139092,
         "postalCode": 47801
      }
   ],
   "Iowa": [
      {
         "city": "Ames",
         "latitude": 42.034722,
         "longitude": -93.62,
         "postalCode": 50010
      },
      {
         "city": "Ankeny",
         "latitude": 41.7317884,
         "longitude": -93.6001278,
         "postalCode": 50015
      },
      {
         "city": "Cedar Falls",
         "latitude": 42.5348993,
         "longitude": -92.4453161,
         "postalCode": 50613
      },
      {
         "city": "Cedar Rapids",
         "latitude": 41.9778795,
         "longitude": -91.6656232,
         "postalCode": 52401
      },
      {
         "city": "Council Bluffs",
         "latitude": 41.2619444,
         "longitude": -95.8608333,
         "postalCode": 51501
      },
      {
         "city": "Davenport",
         "latitude": 41.5236437,
         "longitude": -90.5776367,
         "postalCode": 52801
      },
      {
         "city": "Des Moines",
         "latitude": 41.6005448,
         "longitude": -93.6091064,
         "postalCode": 50301
      },
      {
         "city": "Dubuque",
         "latitude": 42.5005583,
         "longitude": -90.6645718,
         "postalCode": 52001
      },
      {
         "city": "Iowa City",
         "latitude": 41.6611277,
         "longitude": -91.5301683,
         "postalCode": 52240
      },
      {
         "city": "Sioux City",
         "latitude": 42.4999942,
         "longitude": -96.4003069,
         "postalCode": 51101
      },
      {
         "city": "Urbandale",
         "latitude": 41.6266555,
         "longitude": -93.7121656,
         "postalCode": 50322
      },
      {
         "city": "Waterloo",
         "latitude": 42.492786,
         "longitude": -92.3425775,
         "postalCode": 50701
      },
      {
         "city": "West Des Moines",
         "latitude": 41.5772115,
         "longitude": -93.711332,
         "postalCode": 50265
      }
   ],
   "Kansas": [
      {
         "city": "Hutchinson",
         "latitude": 38.0608445,
         "longitude": -97.9297743,
         "postalCode": 67501
      },
      {
         "city": "Kansas City",
         "latitude": 39.114053,
         "longitude": -94.6274636,
         "postalCode": 66101
      },
      {
         "city": "Lawrence",
         "latitude": 38.9716689,
         "longitude": -95.2352501,
         "postalCode": 66044
      },
      {
         "city": "Lenexa",
         "latitude": 38.9536174,
         "longitude": -94.7335709,
         "postalCode": ""
      },
      {
         "city": "Manhattan",
         "latitude": 39.1836082,
         "longitude": -96.5716694,
         "postalCode": 66502
      },
      {
         "city": "Olathe",
         "latitude": 38.8813958,
         "longitude": -94.8191285,
         "postalCode": 66051
      },
      {
         "city": "Overland Park",
         "latitude": 38.9822282,
         "longitude": -94.6707917,
         "postalCode": ""
      },
      {
         "city": "Salina",
         "latitude": 38.8402805,
         "longitude": -97.6114237,
         "postalCode": 67401
      },
      {
         "city": "Shawnee",
         "latitude": 39.0228485,
         "longitude": -94.7151865,
         "postalCode": ""
      },
      {
         "city": "Topeka",
         "latitude": 39.0558235,
         "longitude": -95.6890185,
         "postalCode": 66601
      },
      {
         "city": "Wichita",
         "latitude": 37.688889,
         "longitude": -97.336111,
         "postalCode": 67201
      }
   ],
   "Kentucky": [
      {
         "city": "Bowling Green",
         "latitude": 36.9685219,
         "longitude": -86.4808043,
         "postalCode": 42101
      },
      {
         "city": "Covington",
         "latitude": 39.0836712,
         "longitude": -84.5085536,
         "postalCode": 41011
      },
      {
         "city": "Lexington-Fayette",
         "latitude": 38.0405837,
         "longitude": -84.5037164,
         "postalCode": ""
      },
      {
         "city": "Louisville/Jefferson County",
         "latitude": 38.2526647,
         "longitude": -85.7584557,
         "postalCode": ""
      },
      {
         "city": "Owensboro",
         "latitude": 37.7719074,
         "longitude": -87.1111676,
         "postalCode": 42301
      }
   ],
   "Louisiana": [
      {
         "city": "Alexandria",
         "latitude": 31.3112936,
         "longitude": -92.4451371,
         "postalCode": 71301
      },
      {
         "city": "Baton Rouge",
         "latitude": 30.4582829,
         "longitude": -91.1403196,
         "postalCode": 70801
      },
      {
         "city": "Bossier City",
         "latitude": 32.5159852,
         "longitude": -93.7321228,
         "postalCode": 71111
      },
      {
         "city": "Kenner",
         "latitude": 29.9940924,
         "longitude": -90.2417434,
         "postalCode": 70062
      },
      {
         "city": "Lafayette",
         "latitude": 30.2240897,
         "longitude": -92.0198427,
         "postalCode": 70501
      },
      {
         "city": "Lake Charles",
         "latitude": 30.2265949,
         "longitude": -93.2173758,
         "postalCode": 70601
      },
      {
         "city": "Monroe",
         "latitude": 32.5093109,
         "longitude": -92.1193012,
         "postalCode": 71201
      },
      {
         "city": "New Orleans",
         "latitude": 29.9510658,
         "longitude": -90.0715323,
         "postalCode": 70112
      },
      {
         "city": "Shreveport",
         "latitude": 32.5251516,
         "longitude": -93.7501789,
         "postalCode": 71101
      }
   ],
   "Maine": [
      {
         "city": "Portland",
         "latitude": 43.661471,
         "longitude": -70.2553259,
         "postalCode": 4101
      }
   ],
   "Maryland": [
      {
         "city": "Annapolis",
         "latitude": 38.9784453,
         "longitude": -76.4921829,
         "postalCode": 21401
      },
      {
         "city": "Baltimore",
         "latitude": 39.2903848,
         "longitude": -76.6121893,
         "postalCode": 21201
      },
      {
         "city": "Bowie",
         "latitude": 39.0067768,
         "longitude": -76.7791365,
         "postalCode": 20715
      },
      {
         "city": "Frederick",
         "latitude": 39.4142688,
         "longitude": -77.4105409,
         "postalCode": 21701
      },
      {
         "city": "Gaithersburg",
         "latitude": 39.1434406,
         "longitude": -77.2013705,
         "postalCode": 20877
      },
      {
         "city": "Hagerstown",
         "latitude": 39.6417629,
         "longitude": -77.7199932,
         "postalCode": 21740
      },
      {
         "city": "Rockville",
         "latitude": 39.0839973,
         "longitude": -77.1527578,
         "postalCode": 20847
      }
   ],
   "Massachusetts": [
      {
         "city": "Attleboro",
         "latitude": 41.9445441,
         "longitude": -71.2856082,
         "postalCode": 2703
      },
      {
         "city": "Barnstable Town",
         "latitude": 41.7003208,
         "longitude": -70.3002024,
         "postalCode": ""
      },
      {
         "city": "Beverly",
         "latitude": 42.5584283,
         "longitude": -70.880049,
         "postalCode": 1915
      },
      {
         "city": "Boston",
         "latitude": 42.3600825,
         "longitude": -71.0588801,
         "postalCode": 2101
      },
      {
         "city": "Brockton",
         "latitude": 42.0834335,
         "longitude": -71.0183787,
         "postalCode": 2301
      },
      {
         "city": "Cambridge",
         "latitude": 42.3736158,
         "longitude": -71.1097335,
         "postalCode": 2138
      },
      {
         "city": "Chelsea",
         "latitude": 42.3917638,
         "longitude": -71.0328284,
         "postalCode": 2150
      },
      {
         "city": "Chicopee",
         "latitude": 42.1487043,
         "longitude": -72.6078672,
         "postalCode": 1013
      },
      {
         "city": "Everett",
         "latitude": 42.40843,
         "longitude": -71.0536625,
         "postalCode": 2149
      },
      {
         "city": "Fall River",
         "latitude": 41.7014912,
         "longitude": -71.1550451,
         "postalCode": 2720
      },
      {
         "city": "Fitchburg",
         "latitude": 42.5834228,
         "longitude": -71.8022955,
         "postalCode": 1420
      },
      {
         "city": "Haverhill",
         "latitude": 42.7762015,
         "longitude": -71.0772796,
         "postalCode": 1830
      },
      {
         "city": "Holyoke",
         "latitude": 42.2042586,
         "longitude": -72.6162009,
         "postalCode": 1040
      },
      {
         "city": "Lawrence",
         "latitude": 42.7070354,
         "longitude": -71.1631137,
         "postalCode": 1840
      },
      {
         "city": "Leominster",
         "latitude": 42.5250906,
         "longitude": -71.759794,
         "postalCode": 1453
      },
      {
         "city": "Lowell",
         "latitude": 42.6334247,
         "longitude": -71.3161718,
         "postalCode": 1850
      },
      {
         "city": "Lynn",
         "latitude": 42.466763,
         "longitude": -70.9494938,
         "postalCode": 1901
      },
      {
         "city": "Malden",
         "latitude": 42.4250964,
         "longitude": -71.066163,
         "postalCode": 2148
      },
      {
         "city": "Marlborough",
         "latitude": 42.3459271,
         "longitude": -71.5522874,
         "postalCode": 1752
      },
      {
         "city": "Medford",
         "latitude": 42.4184296,
         "longitude": -71.1061639,
         "postalCode": 2153
      },
      {
         "city": "Methuen",
         "latitude": 42.7262016,
         "longitude": -71.1908924,
         "postalCode": 1844
      },
      {
         "city": "New Bedford",
         "latitude": 41.6362152,
         "longitude": -70.934205,
         "postalCode": 2740
      },
      {
         "city": "Newton",
         "latitude": 42.3370413,
         "longitude": -71.2092214,
         "postalCode": 2458
      },
      {
         "city": "Peabody",
         "latitude": 42.5278731,
         "longitude": -70.9286609,
         "postalCode": 1960
      },
      {
         "city": "Pittsfield",
         "latitude": 42.4500845,
         "longitude": -73.2453824,
         "postalCode": 1201
      },
      {
         "city": "Quincy",
         "latitude": 42.2528772,
         "longitude": -71.0022705,
         "postalCode": 2169
      },
      {
         "city": "Revere",
         "latitude": 42.4084302,
         "longitude": -71.0119948,
         "postalCode": 2151
      },
      {
         "city": "Salem",
         "latitude": 42.51954,
         "longitude": -70.8967155,
         "postalCode": 1970
      },
      {
         "city": "Somerville",
         "latitude": 42.3875968,
         "longitude": -71.0994968,
         "postalCode": 2143
      },
      {
         "city": "Springfield",
         "latitude": 42.1014831,
         "longitude": -72.589811,
         "postalCode": 1101
      },
      {
         "city": "Taunton",
         "latitude": 41.900101,
         "longitude": -71.0897674,
         "postalCode": 2780
      },
      {
         "city": "Waltham",
         "latitude": 42.3764852,
         "longitude": -71.2356113,
         "postalCode": 2451
      },
      {
         "city": "Westfield",
         "latitude": 42.1250929,
         "longitude": -72.749538,
         "postalCode": 1085
      },
      {
         "city": "Weymouth Town",
         "latitude": 42.2180724,
         "longitude": -70.9410356,
         "postalCode": ""
      },
      {
         "city": "Woburn",
         "latitude": 42.4792618,
         "longitude": -71.1522765,
         "postalCode": 1801
      },
      {
         "city": "Worcester",
         "latitude": 42.2625932,
         "longitude": -71.8022934,
         "postalCode": 1601
      }
   ],
   "Michigan": [
      {
         "city": "Ann Arbor",
         "latitude": 42.2808256,
         "longitude": -83.7430378,
         "postalCode": 48103
      },
      {
         "city": "Battle Creek",
         "latitude": 42.3211522,
         "longitude": -85.1797142,
         "postalCode": 49014
      },
      {
         "city": "Dearborn",
         "latitude": 42.3222599,
         "longitude": -83.1763145,
         "postalCode": 48120
      },
      {
         "city": "Dearborn Heights",
         "latitude": 42.3369816,
         "longitude": -83.2732627,
         "postalCode": 48125
      },
      {
         "city": "Detroit",
         "latitude": 42.331427,
         "longitude": -83.0457538,
         "postalCode": 48201
      },
      {
         "city": "East Lansing",
         "latitude": 42.7369792,
         "longitude": -84.4838654,
         "postalCode": 48823
      },
      {
         "city": "Farmington Hills",
         "latitude": 42.4989936,
         "longitude": -83.3677168,
         "postalCode": ""
      },
      {
         "city": "Flint",
         "latitude": 43.0125274,
         "longitude": -83.6874562,
         "postalCode": 48501
      },
      {
         "city": "Grand Rapids",
         "latitude": 42.9633599,
         "longitude": -85.6680863,
         "postalCode": 49501
      },
      {
         "city": "Kalamazoo",
         "latitude": 42.2917069,
         "longitude": -85.5872286,
         "postalCode": 49001
      },
      {
         "city": "Kentwood",
         "latitude": 42.8694731,
         "longitude": -85.6447492,
         "postalCode": ""
      },
      {
         "city": "Lansing",
         "latitude": 42.732535,
         "longitude": -84.5555347,
         "postalCode": 48901
      },
      {
         "city": "Lincoln Park",
         "latitude": 42.2505943,
         "longitude": -83.1785361,
         "postalCode": 48146
      },
      {
         "city": "Livonia",
         "latitude": 42.36837,
         "longitude": -83.3527097,
         "postalCode": 48150
      },
      {
         "city": "Midland",
         "latitude": 43.6155825,
         "longitude": -84.2472116,
         "postalCode": 48640
      },
      {
         "city": "Muskegon",
         "latitude": 43.2341813,
         "longitude": -86.2483921,
         "postalCode": 49440
      },
      {
         "city": "Novi",
         "latitude": 42.48059,
         "longitude": -83.4754913,
         "postalCode": 48374
      },
      {
         "city": "Pontiac",
         "latitude": 42.6389216,
         "longitude": -83.2910468,
         "postalCode": 48340
      },
      {
         "city": "Portage",
         "latitude": 42.2011538,
         "longitude": -85.5800022,
         "postalCode": 49002
      },
      {
         "city": "Rochester Hills",
         "latitude": 42.6583661,
         "longitude": -83.1499322,
         "postalCode": ""
      },
      {
         "city": "Roseville",
         "latitude": 42.4972583,
         "longitude": -82.9371409,
         "postalCode": 48066
      },
      {
         "city": "Royal Oak",
         "latitude": 42.4894801,
         "longitude": -83.1446485,
         "postalCode": 48067
      },
      {
         "city": "Saginaw",
         "latitude": 43.4194699,
         "longitude": -83.9508068,
         "postalCode": 48601
      },
      {
         "city": "Southfield",
         "latitude": 42.4733688,
         "longitude": -83.2218731,
         "postalCode": 48034
      },
      {
         "city": "St. Clair Shores",
         "latitude": 42.4974085,
         "longitude": -82.8963604,
         "postalCode": ""
      },
      {
         "city": "Sterling Heights",
         "latitude": 42.5803122,
         "longitude": -83.0302033,
         "postalCode": 48310
      },
      {
         "city": "Taylor",
         "latitude": 42.240872,
         "longitude": -83.2696509,
         "postalCode": 48180
      },
      {
         "city": "Troy",
         "latitude": 42.6064095,
         "longitude": -83.1497751,
         "postalCode": 48007
      },
      {
         "city": "Warren",
         "latitude": 42.5144566,
         "longitude": -83.0146526,
         "postalCode": 48088
      },
      {
         "city": "Westland",
         "latitude": 42.324204,
         "longitude": -83.400211,
         "postalCode": 48185
      },
      {
         "city": "Wyoming",
         "latitude": 42.9133602,
         "longitude": -85.7053085,
         "postalCode": ""
      }
   ],
   "Minnesota": [
      {
         "city": "Apple Valley",
         "latitude": 44.7319094,
         "longitude": -93.21772,
         "postalCode": ""
      },
      {
         "city": "Blaine",
         "latitude": 45.1607987,
         "longitude": -93.2349489,
         "postalCode": ""
      },
      {
         "city": "Bloomington",
         "latitude": 44.840798,
         "longitude": -93.2982799,
         "postalCode": ""
      },
      {
         "city": "Brooklyn Park",
         "latitude": 45.0941315,
         "longitude": -93.3563405,
         "postalCode": ""
      },
      {
         "city": "Burnsville",
         "latitude": 44.7677424,
         "longitude": -93.2777226,
         "postalCode": 55306
      },
      {
         "city": "Coon Rapids",
         "latitude": 45.1732394,
         "longitude": -93.3030063,
         "postalCode": ""
      },
      {
         "city": "Duluth",
         "latitude": 46.7866719,
         "longitude": -92.1004852,
         "postalCode": 55801
      },
      {
         "city": "Eagan",
         "latitude": 44.8041322,
         "longitude": -93.1668858,
         "postalCode": ""
      },
      {
         "city": "Eden Prairie",
         "latitude": 44.8546856,
         "longitude": -93.470786,
         "postalCode": 55344
      },
      {
         "city": "Edina",
         "latitude": 44.8896866,
         "longitude": -93.3499489,
         "postalCode": ""
      },
      {
         "city": "Lakeville",
         "latitude": 44.6496868,
         "longitude": -93.24272,
         "postalCode": 55044
      },
      {
         "city": "Mankato",
         "latitude": 44.1635775,
         "longitude": -93.9993996,
         "postalCode": 56001
      },
      {
         "city": "Maple Grove",
         "latitude": 45.0724642,
         "longitude": -93.4557877,
         "postalCode": ""
      },
      {
         "city": "Maplewood",
         "latitude": 44.9530215,
         "longitude": -92.9952153,
         "postalCode": ""
      },
      {
         "city": "Minneapolis",
         "latitude": 44.977753,
         "longitude": -93.2650108,
         "postalCode": 55401
      },
      {
         "city": "Minnetonka",
         "latitude": 44.9211836,
         "longitude": -93.4687489,
         "postalCode": 55345
      },
      {
         "city": "Moorhead",
         "latitude": 46.8737648,
         "longitude": -96.7678039,
         "postalCode": 56560
      },
      {
         "city": "Plymouth",
         "latitude": 45.0105194,
         "longitude": -93.4555093,
         "postalCode": ""
      },
      {
         "city": "Rochester",
         "latitude": 44.0121221,
         "longitude": -92.4801989,
         "postalCode": 55901
      },
      {
         "city": "Shakopee",
         "latitude": 44.7973962,
         "longitude": -93.5272861,
         "postalCode": 55379
      },
      {
         "city": "St. Cloud",
         "latitude": 45.5579451,
         "longitude": -94.1632404,
         "postalCode": ""
      },
      {
         "city": "St. Louis Park",
         "latitude": 44.9597376,
         "longitude": -93.3702186,
         "postalCode": ""
      },
      {
         "city": "St. Paul",
         "latitude": 44.9537029,
         "longitude": -93.0899578,
         "postalCode": ""
      },
      {
         "city": "Woodbury",
         "latitude": 44.9238552,
         "longitude": -92.9593797,
         "postalCode": ""
      }
   ],
   "Mississippi": [
      {
         "city": "Biloxi",
         "latitude": 30.3960318,
         "longitude": -88.8853078,
         "postalCode": 39530
      },
      {
         "city": "Gulfport",
         "latitude": 30.3674198,
         "longitude": -89.0928155,
         "postalCode": 39501
      },
      {
         "city": "Hattiesburg",
         "latitude": 31.3271189,
         "longitude": -89.2903392,
         "postalCode": 39401
      },
      {
         "city": "Jackson",
         "latitude": 32.2987573,
         "longitude": -90.1848103,
         "postalCode": 39201
      },
      {
         "city": "Meridian",
         "latitude": 32.3643098,
         "longitude": -88.703656,
         "postalCode": 39301
      },
      {
         "city": "Southaven",
         "latitude": 34.9889818,
         "longitude": -90.0125913,
         "postalCode": 38671
      }
   ],
   "Missouri": [
      {
         "city": "Blue Springs",
         "latitude": 39.0169509,
         "longitude": -94.2816148,
         "postalCode": 64013
      },
      {
         "city": "Cape Girardeau",
         "latitude": 37.3058839,
         "longitude": -89.5181476,
         "postalCode": 63701
      },
      {
         "city": "Chesterfield",
         "latitude": 38.6631083,
         "longitude": -90.5770675,
         "postalCode": 63005
      },
      {
         "city": "Columbia",
         "latitude": 38.9517053,
         "longitude": -92.3340724,
         "postalCode": 65201
      },
      {
         "city": "Florissant",
         "latitude": 38.789217,
         "longitude": -90.322614,
         "postalCode": 63031
      },
      {
         "city": "Independence",
         "latitude": 39.0911161,
         "longitude": -94.4155068,
         "postalCode": 64050
      },
      {
         "city": "Jefferson City",
         "latitude": 38.5767017,
         "longitude": -92.1735164,
         "postalCode": 65101
      },
      {
         "city": "Joplin",
         "latitude": 37.0842271,
         "longitude": -94.513281,
         "postalCode": 64801
      },
      {
         "city": "Kansas City",
         "latitude": 39.0997265,
         "longitude": -94.5785667,
         "postalCode": 64101
      },
      {
         "city": "Lee's Summit",
         "latitude": 38.9108408,
         "longitude": -94.3821724,
         "postalCode": ""
      },
      {
         "city": "O'Fallon",
         "latitude": 38.8106075,
         "longitude": -90.6998477,
         "postalCode": ""
      },
      {
         "city": "Springfield",
         "latitude": 37.2089572,
         "longitude": -93.2922989,
         "postalCode": 65801
      },
      {
         "city": "St. Charles",
         "latitude": 38.7881062,
         "longitude": -90.4974359,
         "postalCode": ""
      },
      {
         "city": "St. Joseph",
         "latitude": 39.7674578,
         "longitude": -94.846681,
         "postalCode": ""
      },
      {
         "city": "St. Louis",
         "latitude": 38.6270025,
         "longitude": -90.1994042,
         "postalCode": ""
      },
      {
         "city": "St. Peters",
         "latitude": 38.7874699,
         "longitude": -90.6298922,
         "postalCode": ""
      }
   ],
   "Montana": [
      {
         "city": "Billings",
         "latitude": 45.7832856,
         "longitude": -108.5006904,
         "postalCode": 59101
      },
      {
         "city": "Bozeman",
         "latitude": 45.6769979,
         "longitude": -111.0429339,
         "postalCode": 59715
      },
      {
         "city": "Great Falls",
         "latitude": 47.4941836,
         "longitude": -111.2833449,
         "postalCode": 59401
      },
      {
         "city": "Missoula",
         "latitude": 46.8787176,
         "longitude": -113.996586,
         "postalCode": 59801
      }
   ],
   "Nebraska": [
      {
         "city": "Bellevue",
         "latitude": 41.1543623,
         "longitude": -95.9145568,
         "postalCode": 68005
      },
      {
         "city": "Grand Island",
         "latitude": 40.9263957,
         "longitude": -98.3420118,
         "postalCode": 68801
      },
      {
         "city": "Lincoln",
         "latitude": 40.8257625,
         "longitude": -96.6851982,
         "postalCode": 68501
      },
      {
         "city": "Omaha",
         "latitude": 41.2523634,
         "longitude": -95.9979883,
         "postalCode": 68101
      }
   ],
   "Nevada": [
      {
         "city": "Carson City",
         "latitude": 39.1637984,
         "longitude": -119.7674034,
         "postalCode": 89701
      },
      {
         "city": "Henderson",
         "latitude": 36.0395247,
         "longitude": -114.9817213,
         "postalCode": 89009
      },
      {
         "city": "Las Vegas",
         "latitude": 36.1699412,
         "longitude": -115.1398296,
         "postalCode": 89101
      },
      {
         "city": "North Las Vegas",
         "latitude": 36.1988592,
         "longitude": -115.1175013,
         "postalCode": 89030
      },
      {
         "city": "Reno",
         "latitude": 39.5296329,
         "longitude": -119.8138027,
         "postalCode": 89501
      },
      {
         "city": "Sparks",
         "latitude": 39.5349112,
         "longitude": -119.7526886,
         "postalCode": 89431
      }
   ],
   "New Hampshire": [
      {
         "city": "Concord",
         "latitude": 43.2081366,
         "longitude": -71.5375718,
         "postalCode": 3301
      },
      {
         "city": "Manchester",
         "latitude": 42.9956397,
         "longitude": -71.4547891,
         "postalCode": 3101
      },
      {
         "city": "Nashua",
         "latitude": 42.7653662,
         "longitude": -71.467566,
         "postalCode": 3060
      }
   ],
   "New Jersey": [
      {
         "city": "Atlantic City",
         "latitude": 39.3642834,
         "longitude": -74.4229266,
         "postalCode": 8401
      },
      {
         "city": "Bayonne",
         "latitude": 40.6687141,
         "longitude": -74.1143091,
         "postalCode": 7002
      },
      {
         "city": "Camden",
         "latitude": 39.9259463,
         "longitude": -75.1196199,
         "postalCode": 8101
      },
      {
         "city": "Clifton",
         "latitude": 40.8584328,
         "longitude": -74.1637553,
         "postalCode": 7011
      },
      {
         "city": "East Orange",
         "latitude": 40.767323,
         "longitude": -74.2048677,
         "postalCode": 7017
      },
      {
         "city": "Elizabeth",
         "latitude": 40.6639916,
         "longitude": -74.2107006,
         "postalCode": 7201
      },
      {
         "city": "Hackensack",
         "latitude": 40.8859325,
         "longitude": -74.0434736,
         "postalCode": 7601
      },
      {
         "city": "Hoboken",
         "latitude": 40.7439905,
         "longitude": -74.0323626,
         "postalCode": 7030
      },
      {
         "city": "Jersey City",
         "latitude": 40.7281575,
         "longitude": -74.0776417,
         "postalCode": 7097
      },
      {
         "city": "Kearny",
         "latitude": 40.7684342,
         "longitude": -74.1454214,
         "postalCode": 7032
      },
      {
         "city": "Linden",
         "latitude": 40.6220478,
         "longitude": -74.2445902,
         "postalCode": 7036
      },
      {
         "city": "New Brunswick",
         "latitude": 40.4862157,
         "longitude": -74.4518188,
         "postalCode": 8901
      },
      {
         "city": "Newark",
         "latitude": 40.735657,
         "longitude": -74.1723667,
         "postalCode": 7101
      },
      {
         "city": "Passaic",
         "latitude": 40.8567662,
         "longitude": -74.1284764,
         "postalCode": 7055
      },
      {
         "city": "Paterson",
         "latitude": 40.9167654,
         "longitude": -74.171811,
         "postalCode": 7501
      },
      {
         "city": "Perth Amboy",
         "latitude": 40.5067723,
         "longitude": -74.2654234,
         "postalCode": 8861
      },
      {
         "city": "Plainfield",
         "latitude": 40.6337136,
         "longitude": -74.4073736,
         "postalCode": 7060
      },
      {
         "city": "Sayreville",
         "latitude": 40.4594021,
         "longitude": -74.360846,
         "postalCode": 8871
      },
      {
         "city": "Trenton",
         "latitude": 40.2170534,
         "longitude": -74.7429384,
         "postalCode": 8601
      },
      {
         "city": "Union City",
         "latitude": 40.6975898,
         "longitude": -74.2631635,
         "postalCode": 7087
      },
      {
         "city": "Vineland",
         "latitude": 39.4863773,
         "longitude": -75.0259637,
         "postalCode": 8360
      },
      {
         "city": "West New York",
         "latitude": 40.7878788,
         "longitude": -74.0143064,
         "postalCode": 7093
      }
   ],
   "New Mexico": [
      {
         "city": "Albuquerque",
         "latitude": 35.0853336,
         "longitude": -106.6055534,
         "postalCode": 87101
      },
      {
         "city": "Clovis",
         "latitude": 34.4047987,
         "longitude": -103.2052272,
         "postalCode": 88101
      },
      {
         "city": "Farmington",
         "latitude": 36.7280583,
         "longitude": -108.2186856,
         "postalCode": 87401
      },
      {
         "city": "Las Cruces",
         "latitude": 32.3199396,
         "longitude": -106.7636538,
         "postalCode": 88001
      },
      {
         "city": "Rio Rancho",
         "latitude": 35.2327544,
         "longitude": -106.6630437,
         "postalCode": 87124
      },
      {
         "city": "Roswell",
         "latitude": 33.3942655,
         "longitude": -104.5230242,
         "postalCode": 88201
      },
      {
         "city": "Santa Fe",
         "latitude": 35.6869752,
         "longitude": -105.937799,
         "postalCode": 87500
      }
   ],
   "New York": [
      {
         "city": "Albany",
         "latitude": 42.6525793,
         "longitude": -73.7562317,
         "postalCode": 12201
      },
      {
         "city": "Binghamton",
         "latitude": 42.0986867,
         "longitude": -75.9179738,
         "postalCode": 13901
      },
      {
         "city": "Buffalo",
         "latitude": 42.8864468,
         "longitude": -78.8783689,
         "postalCode": 14201
      },
      {
         "city": "Freeport",
         "latitude": 40.6576022,
         "longitude": -73.5831835,
         "postalCode": 11520
      },
      {
         "city": "Hempstead",
         "latitude": 40.7062128,
         "longitude": -73.6187397,
         "postalCode": 11549
      },
      {
         "city": "Mount Vernon",
         "latitude": 40.9125992,
         "longitude": -73.8370786,
         "postalCode": 10550
      },
      {
         "city": "New Rochelle",
         "latitude": 40.9114882,
         "longitude": -73.7823549,
         "postalCode": 10801
      },
      {
         "city": "New York",
         "latitude": 40.7127837,
         "longitude": -74.0059413,
         "postalCode": 10001
      },
      {
         "city": "Niagara Falls",
         "latitude": 43.0962143,
         "longitude": -79.0377388,
         "postalCode": 14301
      },
      {
         "city": "Rochester",
         "latitude": 43.16103,
         "longitude": -77.6109219,
         "postalCode": 14601
      },
      {
         "city": "Schenectady",
         "latitude": 42.8142432,
         "longitude": -73.9395687,
         "postalCode": 12301
      },
      {
         "city": "Syracuse",
         "latitude": 43.0481221,
         "longitude": -76.1474244,
         "postalCode": 13201
      },
      {
         "city": "Troy",
         "latitude": 42.7284117,
         "longitude": -73.6917851,
         "postalCode": 12179
      },
      {
         "city": "Utica",
         "latitude": 43.100903,
         "longitude": -75.232664,
         "postalCode": 13501
      },
      {
         "city": "Valley Stream",
         "latitude": 40.6642699,
         "longitude": -73.7084645,
         "postalCode": 11580
      },
      {
         "city": "White Plains",
         "latitude": 41.0339862,
         "longitude": -73.7629097,
         "postalCode": 10601
      },
      {
         "city": "Yonkers",
         "latitude": 40.9312099,
         "longitude": -73.8987469,
         "postalCode": 10701
      }
   ],
   "North Carolina": [
      {
         "city": "Apex",
         "latitude": 35.732652,
         "longitude": -78.8502856,
         "postalCode": 27502
      },
      {
         "city": "Asheville",
         "latitude": 35.5950581,
         "longitude": -82.5514869,
         "postalCode": 28801
      },
      {
         "city": "Burlington",
         "latitude": 36.0956918,
         "longitude": -79.4377991,
         "postalCode": 27215
      },
      {
         "city": "Cary",
         "latitude": 35.79154,
         "longitude": -78.7811169,
         "postalCode": 27511
      },
      {
         "city": "Chapel Hill",
         "latitude": 35.9131996,
         "longitude": -79.0558445,
         "postalCode": 27514
      },
      {
         "city": "Charlotte",
         "latitude": 35.2270869,
         "longitude": -80.8431267,
         "postalCode": 28201
      },
      {
         "city": "Concord",
         "latitude": 35.4087517,
         "longitude": -80.579511,
         "postalCode": 28025
      },
      {
         "city": "Durham",
         "latitude": 35.9940329,
         "longitude": -78.898619,
         "postalCode": 27701
      },
      {
         "city": "Fayetteville",
         "latitude": 35.0526641,
         "longitude": -78.8783585,
         "postalCode": 28301
      },
      {
         "city": "Gastonia",
         "latitude": 35.262082,
         "longitude": -81.1873005,
         "postalCode": 28052
      },
      {
         "city": "Greensboro",
         "latitude": 36.0726354,
         "longitude": -79.7919754,
         "postalCode": 27401
      },
      {
         "city": "Greenville",
         "latitude": 35.612661,
         "longitude": -77.3663538,
         "postalCode": 27833
      },
      {
         "city": "Hickory",
         "latitude": 35.7344538,
         "longitude": -81.3444573,
         "postalCode": 28601
      },
      {
         "city": "High Point",
         "latitude": 35.9556923,
         "longitude": -80.0053176,
         "postalCode": 27260
      },
      {
         "city": "Huntersville",
         "latitude": 35.410694,
         "longitude": -80.8428504,
         "postalCode": 28070
      },
      {
         "city": "Jacksonville",
         "latitude": 34.7540524,
         "longitude": -77.4302414,
         "postalCode": 28540
      },
      {
         "city": "Kannapolis",
         "latitude": 35.4873613,
         "longitude": -80.6217341,
         "postalCode": 28081
      },
      {
         "city": "Raleigh",
         "latitude": 35.7795897,
         "longitude": -78.6381787,
         "postalCode": 27601
      },
      {
         "city": "Rocky Mount",
         "latitude": 35.9382103,
         "longitude": -77.7905339,
         "postalCode": 27801
      },
      {
         "city": "Wilmington",
         "latitude": 34.2257255,
         "longitude": -77.9447102,
         "postalCode": 28401
      },
      {
         "city": "Wilson",
         "latitude": 35.7212689,
         "longitude": -77.9155395,
         "postalCode": 27893
      },
      {
         "city": "Winston-Salem",
         "latitude": 36.0998596,
         "longitude": -80.244216,
         "postalCode": ""
      }
   ],
   "North Dakota": [
      {
         "city": "Bismarck",
         "latitude": 46.8083268,
         "longitude": -100.7837392,
         "postalCode": 58501
      },
      {
         "city": "Fargo",
         "latitude": 46.8771863,
         "longitude": -96.7898034,
         "postalCode": 58102
      },
      {
         "city": "Grand Forks",
         "latitude": 47.9252568,
         "longitude": -97.0328547,
         "postalCode": 58201
      },
      {
         "city": "Minot",
         "latitude": 48.2329668,
         "longitude": -101.2922906,
         "postalCode": 58701
      }
   ],
   "Ohio": [
      {
         "city": "Akron",
         "latitude": 41.0814447,
         "longitude": -81.5190053,
         "postalCode": 44301
      },
      {
         "city": "Beavercreek",
         "latitude": 39.7092262,
         "longitude": -84.0632685,
         "postalCode": ""
      },
      {
         "city": "Canton",
         "latitude": 40.7989473,
         "longitude": -81.378447,
         "postalCode": 44701
      },
      {
         "city": "Cincinnati",
         "latitude": 39.1031182,
         "longitude": -84.5120196,
         "postalCode": 45201
      },
      {
         "city": "Cleveland",
         "latitude": 41.49932,
         "longitude": -81.6943605,
         "postalCode": 44101
      },
      {
         "city": "Cleveland Heights",
         "latitude": 41.5200518,
         "longitude": -81.556235,
         "postalCode": ""
      },
      {
         "city": "Columbus",
         "latitude": 39.9611755,
         "longitude": -82.9987942,
         "postalCode": 43085
      },
      {
         "city": "Cuyahoga Falls",
         "latitude": 41.1339449,
         "longitude": -81.4845585,
         "postalCode": 44221
      },
      {
         "city": "Dayton",
         "latitude": 39.7589478,
         "longitude": -84.1916069,
         "postalCode": 45401
      },
      {
         "city": "Dublin",
         "latitude": 40.0992294,
         "longitude": -83.1140771,
         "postalCode": 43016
      },
      {
         "city": "Elyria",
         "latitude": 41.3683798,
         "longitude": -82.1076486,
         "postalCode": 44035
      },
      {
         "city": "Euclid",
         "latitude": 41.5931049,
         "longitude": -81.5267873,
         "postalCode": 44117
      },
      {
         "city": "Fairfield",
         "latitude": 39.3454673,
         "longitude": -84.5603187,
         "postalCode": 45014
      },
      {
         "city": "Findlay",
         "latitude": 41.04422,
         "longitude": -83.6499321,
         "postalCode": 45839
      },
      {
         "city": "Grove City",
         "latitude": 39.8814519,
         "longitude": -83.0929644,
         "postalCode": 43123
      },
      {
         "city": "Hamilton",
         "latitude": 39.3995008,
         "longitude": -84.5613355,
         "postalCode": 45011
      },
      {
         "city": "Huber Heights",
         "latitude": 39.843947,
         "longitude": -84.1246608,
         "postalCode": ""
      },
      {
         "city": "Kettering",
         "latitude": 39.6895036,
         "longitude": -84.1688274,
         "postalCode": ""
      },
      {
         "city": "Lakewood",
         "latitude": 41.4819932,
         "longitude": -81.7981908,
         "postalCode": 44107
      },
      {
         "city": "Lancaster",
         "latitude": 39.7136754,
         "longitude": -82.5993294,
         "postalCode": 43130
      },
      {
         "city": "Lima",
         "latitude": 40.742551,
         "longitude": -84.1052256,
         "postalCode": 45801
      },
      {
         "city": "Lorain",
         "latitude": 41.452819,
         "longitude": -82.1823746,
         "postalCode": 44052
      },
      {
         "city": "Mansfield",
         "latitude": 40.75839,
         "longitude": -82.5154471,
         "postalCode": 44901
      },
      {
         "city": "Mentor",
         "latitude": 41.6661573,
         "longitude": -81.339552,
         "postalCode": 44060
      },
      {
         "city": "Middletown",
         "latitude": 39.5150576,
         "longitude": -84.3982763,
         "postalCode": 45042
      },
      {
         "city": "Newark",
         "latitude": 40.0581205,
         "longitude": -82.4012642,
         "postalCode": 43055
      },
      {
         "city": "Parma",
         "latitude": 41.4047742,
         "longitude": -81.7229086,
         "postalCode": ""
      },
      {
         "city": "Springfield",
         "latitude": 39.9242266,
         "longitude": -83.8088171,
         "postalCode": 45501
      },
      {
         "city": "Strongsville",
         "latitude": 41.3144966,
         "longitude": -81.83569,
         "postalCode": 44136
      },
      {
         "city": "Toledo",
         "latitude": 41.6639383,
         "longitude": -83.555212,
         "postalCode": 43601
      },
      {
         "city": "Warren",
         "latitude": 41.2375569,
         "longitude": -80.8184166,
         "postalCode": 44481
      },
      {
         "city": "Westerville",
         "latitude": 40.1261743,
         "longitude": -82.9290696,
         "postalCode": 43081
      },
      {
         "city": "Youngstown",
         "latitude": 41.0997803,
         "longitude": -80.6495194,
         "postalCode": 44501
      }
   ],
   "Oklahoma": [
      {
         "city": "Broken Arrow",
         "latitude": 36.060949,
         "longitude": -95.7974526,
         "postalCode": 74011
      },
      {
         "city": "Edmond",
         "latitude": 35.6528323,
         "longitude": -97.4780954,
         "postalCode": 73003
      },
      {
         "city": "Enid",
         "latitude": 36.3955891,
         "longitude": -97.8783911,
         "postalCode": 73701
      },
      {
         "city": "Lawton",
         "latitude": 34.6035669,
         "longitude": -98.3959291,
         "postalCode": 73501
      },
      {
         "city": "Midwest City",
         "latitude": 35.4495065,
         "longitude": -97.3967019,
         "postalCode": ""
      },
      {
         "city": "Moore",
         "latitude": 35.3395079,
         "longitude": -97.4867028,
         "postalCode": ""
      },
      {
         "city": "Muskogee",
         "latitude": 35.7478769,
         "longitude": -95.3696909,
         "postalCode": 74401
      },
      {
         "city": "Norman",
         "latitude": 35.2225668,
         "longitude": -97.4394777,
         "postalCode": 73019
      },
      {
         "city": "Oklahoma City",
         "latitude": 35.4675602,
         "longitude": -97.5164276,
         "postalCode": 73101
      },
      {
         "city": "Stillwater",
         "latitude": 36.1156071,
         "longitude": -97.0583681,
         "postalCode": 74074
      },
      {
         "city": "Tulsa",
         "latitude": 36.1539816,
         "longitude": -95.992775,
         "postalCode": 74101
      }
   ],
   "Oregon": [
      {
         "city": "Albany",
         "latitude": 44.6365107,
         "longitude": -123.1059282,
         "postalCode": 97321
      },
      {
         "city": "Beaverton",
         "latitude": 45.487062,
         "longitude": -122.8037102,
         "postalCode": 97005
      },
      {
         "city": "Bend",
         "latitude": 44.0581728,
         "longitude": -121.3153096,
         "postalCode": 97701
      },
      {
         "city": "Corvallis",
         "latitude": 44.5645659,
         "longitude": -123.2620435,
         "postalCode": 97330
      },
      {
         "city": "Eugene",
         "latitude": 44.0520691,
         "longitude": -123.0867536,
         "postalCode": 97401
      },
      {
         "city": "Gresham",
         "latitude": 45.5001357,
         "longitude": -122.4302013,
         "postalCode": 97030
      },
      {
         "city": "Hillsboro",
         "latitude": 45.5228939,
         "longitude": -122.989827,
         "postalCode": 97123
      },
      {
         "city": "Keizer",
         "latitude": 44.9901194,
         "longitude": -123.0262077,
         "postalCode": 97307
      },
      {
         "city": "Lake Oswego",
         "latitude": 45.4206749,
         "longitude": -122.6706498,
         "postalCode": 97034
      },
      {
         "city": "Medford",
         "latitude": 42.3265152,
         "longitude": -122.8755949,
         "postalCode": 97501
      },
      {
         "city": "Portland",
         "latitude": 45.5230622,
         "longitude": -122.6764816,
         "postalCode": 97201
      },
      {
         "city": "Salem",
         "latitude": 44.9428975,
         "longitude": -123.0350963,
         "postalCode": 97301
      },
      {
         "city": "Springfield",
         "latitude": 44.0462362,
         "longitude": -123.0220289,
         "postalCode": 97477
      },
      {
         "city": "Tigard",
         "latitude": 45.4312294,
         "longitude": -122.7714861,
         "postalCode": ""
      }
   ],
   "Pennsylvania": [
      {
         "city": "Allentown",
         "latitude": 40.6084305,
         "longitude": -75.4901833,
         "postalCode": 18101
      },
      {
         "city": "Altoona",
         "latitude": 40.5186809,
         "longitude": -78.3947359,
         "postalCode": 16601
      },
      {
         "city": "Bethlehem",
         "latitude": 40.6259316,
         "longitude": -75.3704579,
         "postalCode": 18015
      },
      {
         "city": "Erie",
         "latitude": 42.1292241,
         "longitude": -80.085059,
         "postalCode": 16501
      },
      {
         "city": "Harrisburg",
         "latitude": 40.2731911,
         "longitude": -76.8867008,
         "postalCode": 17101
      },
      {
         "city": "Lancaster",
         "latitude": 40.0378755,
         "longitude": -76.3055144,
         "postalCode": 17601
      },
      {
         "city": "Philadelphia",
         "latitude": 39.9525839,
         "longitude": -75.1652215,
         "postalCode": 19019
      },
      {
         "city": "Pittsburgh",
         "latitude": 40.4406248,
         "longitude": -79.9958864,
         "postalCode": 15201
      },
      {
         "city": "Reading",
         "latitude": 40.3356483,
         "longitude": -75.9268747,
         "postalCode": 19601
      },
      {
         "city": "Scranton",
         "latitude": 41.408969,
         "longitude": -75.6624122,
         "postalCode": 18501
      },
      {
         "city": "State College",
         "latitude": 40.7933949,
         "longitude": -77.8600012,
         "postalCode": 16801
      },
      {
         "city": "Wilkes-Barre",
         "latitude": 41.2459149,
         "longitude": -75.8813075,
         "postalCode": ""
      },
      {
         "city": "York",
         "latitude": 39.9625984,
         "longitude": -76.727745,
         "postalCode": 17401
      }
   ],
   "Rhode Island": [
      {
         "city": "Cranston",
         "latitude": 41.7798226,
         "longitude": -71.4372796,
         "postalCode": 2910
      },
      {
         "city": "East Providence",
         "latitude": 41.8137116,
         "longitude": -71.3700545,
         "postalCode": 2914
      },
      {
         "city": "Pawtucket",
         "latitude": 41.878711,
         "longitude": -71.3825558,
         "postalCode": 2860
      },
      {
         "city": "Providence",
         "latitude": 41.8239891,
         "longitude": -71.4128343,
         "postalCode": 2901
      },
      {
         "city": "Warwick",
         "latitude": 41.7001009,
         "longitude": -71.4161671,
         "postalCode": 2886
      },
      {
         "city": "Woonsocket",
         "latitude": 42.0028761,
         "longitude": -71.5147839,
         "postalCode": 2895
      }
   ],
   "South Carolina": [
      {
         "city": "Charleston",
         "latitude": 32.7764749,
         "longitude": -79.9310512,
         "postalCode": 29401
      },
      {
         "city": "Columbia",
         "latitude": 34.0007104,
         "longitude": -81.0348144,
         "postalCode": 29201
      },
      {
         "city": "Florence",
         "latitude": 34.1954331,
         "longitude": -79.7625625,
         "postalCode": 29501
      },
      {
         "city": "Goose Creek",
         "latitude": 32.9810059,
         "longitude": -80.0325867,
         "postalCode": 29445
      },
      {
         "city": "Greenville",
         "latitude": 34.8526176,
         "longitude": -82.3940104,
         "postalCode": 29601
      },
      {
         "city": "Hilton Head Island",
         "latitude": 32.216316,
         "longitude": -80.752608,
         "postalCode": 29925
      },
      {
         "city": "Mount Pleasant",
         "latitude": 32.8323225,
         "longitude": -79.8284258,
         "postalCode": 29464
      },
      {
         "city": "North Charleston",
         "latitude": 32.8546197,
         "longitude": -79.9748103,
         "postalCode": 29405
      },
      {
         "city": "Rock Hill",
         "latitude": 34.9248667,
         "longitude": -81.0250784,
         "postalCode": 29730
      },
      {
         "city": "Spartanburg",
         "latitude": 34.9495672,
         "longitude": -81.9320482,
         "postalCode": 29301
      },
      {
         "city": "Summerville",
         "latitude": 33.0185039,
         "longitude": -80.1756481,
         "postalCode": 29483
      },
      {
         "city": "Sumter",
         "latitude": 33.9204354,
         "longitude": -80.3414693,
         "postalCode": 29150
      }
   ],
   "South Dakota": [
      {
         "city": "Rapid City",
         "latitude": 44.0805434,
         "longitude": -103.2310149,
         "postalCode": 57701
      },
      {
         "city": "Sioux Falls",
         "latitude": 43.5445959,
         "longitude": -96.7311034,
         "postalCode": 57101
      }
   ],
   "Tennessee": [
      {
         "city": "Bartlett",
         "latitude": 35.2045328,
         "longitude": -89.8739753,
         "postalCode": ""
      },
      {
         "city": "Brentwood",
         "latitude": 36.0331164,
         "longitude": -86.7827772,
         "postalCode": 37024
      },
      {
         "city": "Chattanooga",
         "latitude": 35.0456297,
         "longitude": -85.3096801,
         "postalCode": 37401
      },
      {
         "city": "Clarksville",
         "latitude": 36.5297706,
         "longitude": -87.3594528,
         "postalCode": 37040
      },
      {
         "city": "Cleveland",
         "latitude": 35.1595182,
         "longitude": -84.8766115,
         "postalCode": 37311
      },
      {
         "city": "Collierville",
         "latitude": 35.042036,
         "longitude": -89.6645266,
         "postalCode": 38017
      },
      {
         "city": "Franklin",
         "latitude": 35.9250637,
         "longitude": -86.8688899,
         "postalCode": 37064
      },
      {
         "city": "Germantown",
         "latitude": 35.0867577,
         "longitude": -89.8100858,
         "postalCode": 38138
      },
      {
         "city": "Hendersonville",
         "latitude": 36.3047735,
         "longitude": -86.6199957,
         "postalCode": 37075
      },
      {
         "city": "Jackson",
         "latitude": 35.6145169,
         "longitude": -88.8139469,
         "postalCode": 38301
      },
      {
         "city": "Johnson City",
         "latitude": 36.3134397,
         "longitude": -82.3534727,
         "postalCode": 37601
      },
      {
         "city": "Kingsport",
         "latitude": 36.548434,
         "longitude": -82.5618186,
         "postalCode": 37660
      },
      {
         "city": "Knoxville",
         "latitude": 35.9606384,
         "longitude": -83.9207392,
         "postalCode": 37901
      },
      {
         "city": "Memphis",
         "latitude": 35.1495343,
         "longitude": -90.0489801,
         "postalCode": 37501
      },
      {
         "city": "Murfreesboro",
         "latitude": 35.8456213,
         "longitude": -86.39027,
         "postalCode": 37127
      },
      {
         "city": "Nashville-Davidson",
         "latitude": 36.1626638,
         "longitude": -86.7816016,
         "postalCode": ""
      },
      {
         "city": "Smyrna",
         "latitude": 35.9828412,
         "longitude": -86.5186045,
         "postalCode": 37167
      }
   ],
   "Texas": [
      {
         "city": "Abilene",
         "latitude": 32.4487364,
         "longitude": -99.7331439,
         "postalCode": 79601
      },
      {
         "city": "Allen",
         "latitude": 33.1031744,
         "longitude": -96.6705503,
         "postalCode": 75002
      },
      {
         "city": "Amarillo",
         "latitude": 35.2219971,
         "longitude": -101.8312969,
         "postalCode": 79101
      },
      {
         "city": "Arlington",
         "latitude": 32.735687,
         "longitude": -97.1080656,
         "postalCode": 76001
      },
      {
         "city": "Austin",
         "latitude": 30.267153,
         "longitude": -97.7430608,
         "postalCode": 73301
      },
      {
         "city": "Baytown",
         "latitude": 29.7355047,
         "longitude": -94.9774274,
         "postalCode": 77520
      },
      {
         "city": "Beaumont",
         "latitude": 30.080174,
         "longitude": -94.1265562,
         "postalCode": 77701
      },
      {
         "city": "Bedford",
         "latitude": 32.844017,
         "longitude": -97.1430671,
         "postalCode": 76021
      },
      {
         "city": "Brownsville",
         "latitude": 25.9017472,
         "longitude": -97.4974838,
         "postalCode": 78520
      },
      {
         "city": "Bryan",
         "latitude": 30.6743643,
         "longitude": -96.3699632,
         "postalCode": 77801
      },
      {
         "city": "Burleson",
         "latitude": 32.5420821,
         "longitude": -97.3208492,
         "postalCode": 76028
      },
      {
         "city": "Carrollton",
         "latitude": 32.9756415,
         "longitude": -96.8899636,
         "postalCode": 75006
      },
      {
         "city": "Cedar Hill",
         "latitude": 32.5884689,
         "longitude": -96.9561152,
         "postalCode": 75104
      },
      {
         "city": "Cedar Park",
         "latitude": 30.505198,
         "longitude": -97.8202888,
         "postalCode": 78613
      },
      {
         "city": "College Station",
         "latitude": 30.627977,
         "longitude": -96.3344068,
         "postalCode": 77840
      },
      {
         "city": "Conroe",
         "latitude": 30.3118769,
         "longitude": -95.4560512,
         "postalCode": 77301
      },
      {
         "city": "Coppell",
         "latitude": 32.9545687,
         "longitude": -97.0150078,
         "postalCode": 75019
      },
      {
         "city": "Corpus Christi",
         "latitude": 27.8005828,
         "longitude": -97.396381,
         "postalCode": 78401
      },
      {
         "city": "Dallas",
         "latitude": 32.7766642,
         "longitude": -96.7969879,
         "postalCode": 75201
      },
      {
         "city": "DeSoto",
         "latitude": 32.5896998,
         "longitude": -96.8570738,
         "postalCode": 75115
      },
      {
         "city": "Denton",
         "latitude": 33.2148412,
         "longitude": -97.1330683,
         "postalCode": 76201
      },
      {
         "city": "Duncanville",
         "latitude": 32.6518004,
         "longitude": -96.9083366,
         "postalCode": 75116
      },
      {
         "city": "Edinburg",
         "latitude": 26.3017374,
         "longitude": -98.1633432,
         "postalCode": 78539
      },
      {
         "city": "El Paso",
         "latitude": 31.7775757,
         "longitude": -106.4424559,
         "postalCode": 79901
      },
      {
         "city": "Euless",
         "latitude": 32.8370727,
         "longitude": -97.0819541,
         "postalCode": 76039
      },
      {
         "city": "Flower Mound",
         "latitude": 33.0145673,
         "longitude": -97.0969552,
         "postalCode": 75022
      },
      {
         "city": "Fort Worth",
         "latitude": 32.7554883,
         "longitude": -97.3307658,
         "postalCode": 76101
      },
      {
         "city": "Friendswood",
         "latitude": 29.5293998,
         "longitude": -95.2010447,
         "postalCode": 77546
      },
      {
         "city": "Frisco",
         "latitude": 33.1506744,
         "longitude": -96.8236116,
         "postalCode": 75034
      },
      {
         "city": "Galveston",
         "latitude": 29.3013479,
         "longitude": -94.7976958,
         "postalCode": 77550
      },
      {
         "city": "Garland",
         "latitude": 32.912624,
         "longitude": -96.6388833,
         "postalCode": 75040
      },
      {
         "city": "Georgetown",
         "latitude": 30.6332618,
         "longitude": -97.6779842,
         "postalCode": 78626
      },
      {
         "city": "Grand Prairie",
         "latitude": 32.7459645,
         "longitude": -96.9977846,
         "postalCode": 75050
      },
      {
         "city": "Grapevine",
         "latitude": 32.9342919,
         "longitude": -97.0780654,
         "postalCode": 76051
      },
      {
         "city": "Haltom City",
         "latitude": 32.7995738,
         "longitude": -97.2691817,
         "postalCode": 76117
      },
      {
         "city": "Harlingen",
         "latitude": 26.1906306,
         "longitude": -97.6961026,
         "postalCode": 78550
      },
      {
         "city": "Houston",
         "latitude": 29.7604267,
         "longitude": -95.3698028,
         "postalCode": 77001
      },
      {
         "city": "Huntsville",
         "latitude": 30.7235263,
         "longitude": -95.5507771,
         "postalCode": 77320
      },
      {
         "city": "Hurst",
         "latitude": 32.8234621,
         "longitude": -97.1705678,
         "postalCode": 76053
      },
      {
         "city": "Irving",
         "latitude": 32.8140177,
         "longitude": -96.9488945,
         "postalCode": 75014
      },
      {
         "city": "Keller",
         "latitude": 32.9341893,
         "longitude": -97.229298,
         "postalCode": 76244
      },
      {
         "city": "Killeen",
         "latitude": 31.1171194,
         "longitude": -97.7277959,
         "postalCode": 76540
      },
      {
         "city": "Lancaster",
         "latitude": 32.5920798,
         "longitude": -96.7561082,
         "postalCode": 75134
      },
      {
         "city": "Laredo",
         "latitude": 27.5305671,
         "longitude": -99.4803241,
         "postalCode": 78040
      },
      {
         "city": "League City",
         "latitude": 29.5074538,
         "longitude": -95.0949303,
         "postalCode": 77573
      },
      {
         "city": "Lewisville",
         "latitude": 33.046233,
         "longitude": -96.994174,
         "postalCode": 75029
      },
      {
         "city": "Longview",
         "latitude": 32.5007037,
         "longitude": -94.7404891,
         "postalCode": 75601
      },
      {
         "city": "Lubbock",
         "latitude": 33.5778631,
         "longitude": -101.8551665,
         "postalCode": 79401
      },
      {
         "city": "Mansfield",
         "latitude": 32.5631924,
         "longitude": -97.1416768,
         "postalCode": 76063
      },
      {
         "city": "McAllen",
         "latitude": 26.2034071,
         "longitude": -98.2300124,
         "postalCode": 78501
      },
      {
         "city": "McKinney",
         "latitude": 33.1972465,
         "longitude": -96.6397822,
         "postalCode": ""
      },
      {
         "city": "Mesquite",
         "latitude": 32.7667955,
         "longitude": -96.5991593,
         "postalCode": 75149
      },
      {
         "city": "Midland",
         "latitude": 31.9973456,
         "longitude": -102.0779146,
         "postalCode": 79701
      },
      {
         "city": "Mission",
         "latitude": 26.2159066,
         "longitude": -98.3252932,
         "postalCode": 78572
      },
      {
         "city": "Missouri City",
         "latitude": 29.6185669,
         "longitude": -95.5377215,
         "postalCode": 77459
      },
      {
         "city": "New Braunfels",
         "latitude": 29.7030024,
         "longitude": -98.1244531,
         "postalCode": 78130
      },
      {
         "city": "North Richland Hills",
         "latitude": 32.8342952,
         "longitude": -97.2289029,
         "postalCode": 76180
      },
      {
         "city": "Odessa",
         "latitude": 31.8456816,
         "longitude": -102.3676431,
         "postalCode": 79760
      },
      {
         "city": "Pasadena",
         "latitude": 29.6910625,
         "longitude": -95.2091006,
         "postalCode": 77501
      },
      {
         "city": "Pearland",
         "latitude": 29.5635666,
         "longitude": -95.2860474,
         "postalCode": 77581
      },
      {
         "city": "Pflugerville",
         "latitude": 30.4393696,
         "longitude": -97.6200043,
         "postalCode": 78660
      },
      {
         "city": "Pharr",
         "latitude": 26.1947962,
         "longitude": -98.1836216,
         "postalCode": 78577
      },
      {
         "city": "Plano",
         "latitude": 33.0198431,
         "longitude": -96.6988856,
         "postalCode": 75023
      },
      {
         "city": "Port Arthur",
         "latitude": 29.8849504,
         "longitude": -93.939947,
         "postalCode": 77640
      },
      {
         "city": "Richardson",
         "latitude": 32.9483335,
         "longitude": -96.7298519,
         "postalCode": 75080
      },
      {
         "city": "Rockwall",
         "latitude": 32.9312336,
         "longitude": -96.4597089,
         "postalCode": 75032
      },
      {
         "city": "Round Rock",
         "latitude": 30.5082551,
         "longitude": -97.678896,
         "postalCode": 78664
      },
      {
         "city": "Rowlett",
         "latitude": 32.9029017,
         "longitude": -96.56388,
         "postalCode": 75030
      },
      {
         "city": "San Angelo",
         "latitude": 31.4637723,
         "longitude": -100.4370375,
         "postalCode": 76901
      },
      {
         "city": "San Antonio",
         "latitude": 29.4241219,
         "longitude": -98.4936282,
         "postalCode": 78201
      },
      {
         "city": "San Marcos",
         "latitude": 29.8832749,
         "longitude": -97.9413941,
         "postalCode": 78666
      },
      {
         "city": "Sherman",
         "latitude": 33.6356618,
         "longitude": -96.6088805,
         "postalCode": 75090
      },
      {
         "city": "Sugar Land",
         "latitude": 29.6196787,
         "longitude": -95.6349463,
         "postalCode": 77478
      },
      {
         "city": "Temple",
         "latitude": 31.0982344,
         "longitude": -97.342782,
         "postalCode": 76501
      },
      {
         "city": "Texarkana",
         "latitude": 33.425125,
         "longitude": -94.0476882,
         "postalCode": 75501
      },
      {
         "city": "Texas City",
         "latitude": 29.383845,
         "longitude": -94.9027002,
         "postalCode": 77590
      },
      {
         "city": "The Colony",
         "latitude": 33.0806083,
         "longitude": -96.8928309,
         "postalCode": 75056
      },
      {
         "city": "Tyler",
         "latitude": 32.3512601,
         "longitude": -95.3010624,
         "postalCode": 75701
      },
      {
         "city": "Victoria",
         "latitude": 28.8052674,
         "longitude": -97.0035982,
         "postalCode": 77901
      },
      {
         "city": "Waco",
         "latitude": 31.549333,
         "longitude": -97.1466695,
         "postalCode": 76701
      },
      {
         "city": "Weslaco",
         "latitude": 26.1595194,
         "longitude": -97.9908366,
         "postalCode": 78596
      },
      {
         "city": "Wichita Falls",
         "latitude": 33.9137085,
         "longitude": -98.4933873,
         "postalCode": 76301
      },
      {
         "city": "Wylie",
         "latitude": 33.0151201,
         "longitude": -96.5388789,
         "postalCode": 75098
      }
   ],
   "Utah": [
      {
         "city": "Bountiful",
         "latitude": 40.8893895,
         "longitude": -111.880771,
         "postalCode": 84010
      },
      {
         "city": "Draper",
         "latitude": 40.5246711,
         "longitude": -111.8638226,
         "postalCode": 84020
      },
      {
         "city": "Layton",
         "latitude": 41.0602216,
         "longitude": -111.9710529,
         "postalCode": 84040
      },
      {
         "city": "Lehi",
         "latitude": 40.3916172,
         "longitude": -111.8507662,
         "postalCode": 84043
      },
      {
         "city": "Logan",
         "latitude": 41.7369803,
         "longitude": -111.8338359,
         "postalCode": 84321
      },
      {
         "city": "Murray",
         "latitude": 40.6668916,
         "longitude": -111.8879909,
         "postalCode": ""
      },
      {
         "city": "Ogden",
         "latitude": 41.223,
         "longitude": -111.9738304,
         "postalCode": 84201
      },
      {
         "city": "Orem",
         "latitude": 40.2968979,
         "longitude": -111.6946475,
         "postalCode": 84057
      },
      {
         "city": "Provo",
         "latitude": 40.2338438,
         "longitude": -111.6585337,
         "postalCode": 84601
      },
      {
         "city": "Riverton",
         "latitude": 40.521893,
         "longitude": -111.9391023,
         "postalCode": 84065
      },
      {
         "city": "Roy",
         "latitude": 41.1616108,
         "longitude": -112.0263313,
         "postalCode": 84067
      },
      {
         "city": "Salt Lake City",
         "latitude": 40.7607793,
         "longitude": -111.8910474,
         "postalCode": 84101
      },
      {
         "city": "Sandy",
         "latitude": 40.5649781,
         "longitude": -111.8389726,
         "postalCode": 84070
      },
      {
         "city": "South Jordan",
         "latitude": 40.5621704,
         "longitude": -111.929658,
         "postalCode": 84095
      },
      {
         "city": "Spanish Fork",
         "latitude": 40.114955,
         "longitude": -111.654923,
         "postalCode": 84660
      },
      {
         "city": "St. George",
         "latitude": 37.0965278,
         "longitude": -113.5684164,
         "postalCode": ""
      },
      {
         "city": "Taylorsville",
         "latitude": 40.6677248,
         "longitude": -111.9388258,
         "postalCode": ""
      },
      {
         "city": "West Jordan",
         "latitude": 40.6096698,
         "longitude": -111.9391031,
         "postalCode": 84084
      },
      {
         "city": "West Valley City",
         "latitude": 40.6916132,
         "longitude": -112.0010501,
         "postalCode": ""
      }
   ],
   "Vermont": [
      {
         "city": "Burlington",
         "latitude": 44.4758825,
         "longitude": -73.212072,
         "postalCode": 5401
      }
   ],
   "Virginia": [
      {
         "city": "Alexandria",
         "latitude": 38.8048355,
         "longitude": -77.0469214,
         "postalCode": 22301
      },
      {
         "city": "Blacksburg",
         "latitude": 37.2295733,
         "longitude": -80.4139393,
         "postalCode": 24060
      },
      {
         "city": "Charlottesville",
         "latitude": 38.0293059,
         "longitude": -78.4766781,
         "postalCode": 22901
      },
      {
         "city": "Chesapeake",
         "latitude": 36.7682088,
         "longitude": -76.2874927,
         "postalCode": 23320
      },
      {
         "city": "Danville",
         "latitude": 36.5859718,
         "longitude": -79.3950228,
         "postalCode": 24540
      },
      {
         "city": "Hampton",
         "latitude": 37.0298687,
         "longitude": -76.3452218,
         "postalCode": 23630
      },
      {
         "city": "Harrisonburg",
         "latitude": 38.4495688,
         "longitude": -78.8689155,
         "postalCode": 22801
      },
      {
         "city": "Leesburg",
         "latitude": 39.1156615,
         "longitude": -77.5636015,
         "postalCode": 20175
      },
      {
         "city": "Lynchburg",
         "latitude": 37.4137536,
         "longitude": -79.1422464,
         "postalCode": 24501
      },
      {
         "city": "Manassas",
         "latitude": 38.7509488,
         "longitude": -77.4752667,
         "postalCode": 20108
      },
      {
         "city": "Newport News",
         "latitude": 37.0870821,
         "longitude": -76.4730122,
         "postalCode": 23601
      },
      {
         "city": "Norfolk",
         "latitude": 36.8507689,
         "longitude": -76.2858726,
         "postalCode": 23501
      },
      {
         "city": "Portsmouth",
         "latitude": 36.8354258,
         "longitude": -76.2982742,
         "postalCode": 23701
      },
      {
         "city": "Richmond",
         "latitude": 37.5407246,
         "longitude": -77.4360481,
         "postalCode": 23218
      },
      {
         "city": "Roanoke",
         "latitude": 37.2709704,
         "longitude": -79.9414266,
         "postalCode": 24001
      },
      {
         "city": "Suffolk",
         "latitude": 36.7282054,
         "longitude": -76.5835621,
         "postalCode": 23432
      },
      {
         "city": "Virginia Beach",
         "latitude": 36.8529263,
         "longitude": -75.977985,
         "postalCode": 23450
      }
   ],
   "Washington": [
      {
         "city": "Auburn",
         "latitude": 47.3073228,
         "longitude": -122.2284532,
         "postalCode": 98001
      },
      {
         "city": "Bellevue",
         "latitude": 47.610377,
         "longitude": -122.2006786,
         "postalCode": 98004
      },
      {
         "city": "Bellingham",
         "latitude": 48.74908,
         "longitude": -122.4781473,
         "postalCode": 98225
      },
      {
         "city": "Bremerton",
         "latitude": 47.5673202,
         "longitude": -122.6329356,
         "postalCode": 98310
      },
      {
         "city": "Burien",
         "latitude": 47.4703767,
         "longitude": -122.3467918,
         "postalCode": ""
      },
      {
         "city": "Edmonds",
         "latitude": 47.8106521,
         "longitude": -122.3773552,
         "postalCode": 98020
      },
      {
         "city": "Everett",
         "latitude": 47.9789848,
         "longitude": -122.2020794,
         "postalCode": 98201
      },
      {
         "city": "Federal Way",
         "latitude": 47.3223221,
         "longitude": -122.3126222,
         "postalCode": 98003
      },
      {
         "city": "Kennewick",
         "latitude": 46.2112458,
         "longitude": -119.1372338,
         "postalCode": 99336
      },
      {
         "city": "Kent",
         "latitude": 47.3809335,
         "longitude": -122.2348431,
         "postalCode": 98031
      },
      {
         "city": "Kirkland",
         "latitude": 47.6814875,
         "longitude": -122.2087353,
         "postalCode": 98033
      },
      {
         "city": "Lacey",
         "latitude": 47.0342629,
         "longitude": -122.8231915,
         "postalCode": 98503
      },
      {
         "city": "Lakewood",
         "latitude": 47.1717649,
         "longitude": -122.518458,
         "postalCode": 98439
      },
      {
         "city": "Marysville",
         "latitude": 48.0517637,
         "longitude": -122.1770818,
         "postalCode": 98270
      },
      {
         "city": "Olympia",
         "latitude": 47.0378741,
         "longitude": -122.9006951,
         "postalCode": 98501
      },
      {
         "city": "Pasco",
         "latitude": 46.2395793,
         "longitude": -119.1005657,
         "postalCode": 99301
      },
      {
         "city": "Puyallup",
         "latitude": 47.1853785,
         "longitude": -122.2928974,
         "postalCode": 98371
      },
      {
         "city": "Redmond",
         "latitude": 47.6739881,
         "longitude": -122.121512,
         "postalCode": 98052
      },
      {
         "city": "Renton",
         "latitude": 47.4828776,
         "longitude": -122.2170661,
         "postalCode": 98055
      },
      {
         "city": "Richland",
         "latitude": 46.2856907,
         "longitude": -119.2844621,
         "postalCode": 99352
      },
      {
         "city": "Sammamish",
         "latitude": 47.6162683,
         "longitude": -122.0355736,
         "postalCode": 98074
      },
      {
         "city": "Seattle",
         "latitude": 47.6062095,
         "longitude": -122.3320708,
         "postalCode": 98101
      },
      {
         "city": "Shoreline",
         "latitude": 47.7556531,
         "longitude": -122.3415178,
         "postalCode": ""
      },
      {
         "city": "Spokane",
         "latitude": 47.6587802,
         "longitude": -117.4260466,
         "postalCode": 99201
      },
      {
         "city": "Spokane Valley",
         "latitude": 47.6732281,
         "longitude": -117.2393748,
         "postalCode": ""
      },
      {
         "city": "Tacoma",
         "latitude": 47.2528768,
         "longitude": -122.4442906,
         "postalCode": 98401
      },
      {
         "city": "Vancouver",
         "latitude": 45.6387281,
         "longitude": -122.6614861,
         "postalCode": 98660
      },
      {
         "city": "Yakima",
         "latitude": 46.6020711,
         "longitude": -120.5058987,
         "postalCode": 98901
      }
   ],
   "West Virginia": [
      {
         "city": "Charleston",
         "latitude": 38.3498195,
         "longitude": -81.6326234,
         "postalCode": 25301
      },
      {
         "city": "Huntington",
         "latitude": 38.4192496,
         "longitude": -82.445154,
         "postalCode": 25701
      }
   ],
   "Wisconsin": [
      {
         "city": "Appleton",
         "latitude": 44.2619309,
         "longitude": -88.4153847,
         "postalCode": 54911
      },
      {
         "city": "Beloit",
         "latitude": 42.5083482,
         "longitude": -89.0317765,
         "postalCode": 53511
      },
      {
         "city": "Brookfield",
         "latitude": 43.0605671,
         "longitude": -88.1064787,
         "postalCode": 53005
      },
      {
         "city": "Eau Claire",
         "latitude": 44.811349,
         "longitude": -91.4984941,
         "postalCode": 54701
      },
      {
         "city": "Fond du Lac",
         "latitude": 43.7730448,
         "longitude": -88.4470508,
         "postalCode": 54935
      },
      {
         "city": "Green Bay",
         "latitude": 44.519159,
         "longitude": -88.019826,
         "postalCode": 54301
      },
      {
         "city": "Greenfield",
         "latitude": 42.9614039,
         "longitude": -88.0125865,
         "postalCode": ""
      },
      {
         "city": "Janesville",
         "latitude": 42.6827885,
         "longitude": -89.0187222,
         "postalCode": 53545
      },
      {
         "city": "Kenosha",
         "latitude": 42.5847425,
         "longitude": -87.8211854,
         "postalCode": 53140
      },
      {
         "city": "La Crosse",
         "latitude": 43.8013556,
         "longitude": -91.2395807,
         "postalCode": 54601
      },
      {
         "city": "Madison",
         "latitude": 43.0730517,
         "longitude": -89.4012302,
         "postalCode": 53701
      },
      {
         "city": "Milwaukee",
         "latitude": 43.0389025,
         "longitude": -87.9064736,
         "postalCode": 53201
      },
      {
         "city": "New Berlin",
         "latitude": 42.9764027,
         "longitude": -88.1084224,
         "postalCode": 53146
      },
      {
         "city": "Oshkosh",
         "latitude": 44.0247062,
         "longitude": -88.5426136,
         "postalCode": 54901
      },
      {
         "city": "Racine",
         "latitude": 42.7261309,
         "longitude": -87.7828523,
         "postalCode": 53401
      },
      {
         "city": "Sheboygan",
         "latitude": 43.7508284,
         "longitude": -87.71453,
         "postalCode": 53081
      },
      {
         "city": "Waukesha",
         "latitude": 43.0116784,
         "longitude": -88.2314813,
         "postalCode": 53186
      },
      {
         "city": "Wausau",
         "latitude": 44.9591352,
         "longitude": -89.6301221,
         "postalCode": 54401
      },
      {
         "city": "Wauwatosa",
         "latitude": 43.0494572,
         "longitude": -88.0075875,
         "postalCode": ""
      },
      {
         "city": "West Allis",
         "latitude": 43.0166806,
         "longitude": -88.0070315,
         "postalCode": ""
      }
   ],
   "Wyoming": [
      {
         "city": "Casper",
         "latitude": 42.866632,
         "longitude": -106.313081,
         "postalCode": 82601
      },
      {
         "city": "Cheyenne",
         "latitude": 41.1399814,
         "longitude": -104.8202462,
         "postalCode": 82001
      }
   ]
}


const ca_cities = {
  "Alberta": [
    "Airdrie",
    "Grande Prairie",
    "Red Deer",
    "Beaumont",
    "Hanna",
    "St. Albert",
    "Bonnyville",
    "Hinton",
    "Spruce Grove",
    "Brazeau",
    "Irricana",
    "Strathcona County",
    "Breton",
    "Lacombe",
    "Strathmore",
    "Calgary",
    "Leduc",
    "Sylvan Lake",
    "Camrose",
    "Lethbridge",
    "Swan Hills",
    "Canmore",
    "McLennan",
    "Taber",
    "Didzbury",
    "Medicine Hat",
    "Turner Valley",
    "Drayton Valley",
    "Olds",
    "Vermillion",
    "Edmonton",
    "Onoway",
    "Wood Buffalo",
    "Ft. Saskatchewan",
    "Provost"
  ],
  "British Columbia": [
    "Burnaby",
    "Lumby",
    "City of Port Moody",
    "Cache Creek",
    "Maple Ridge",
    "Prince George",
    "Castlegar",
    "Merritt",
    "Prince Rupert",
    "Chemainus",
    "Mission",
    "Richmond",
    "Chilliwack",
    "Nanaimo",
    "Saanich",
    "Clearwater",
    "Nelson",
    "Sooke",
    "Colwood",
    "New Westminster",
    "Sparwood",
    "Coquitlam",
    "North Cowichan",
    "Surrey",
    "Cranbrook",
    "North Vancouver",
    "Terrace",
    "Dawson Creek",
    "North Vancouver",
    "Tumbler",
    "Delta",
    "Osoyoos",
    "Vancouver",
    "Fernie",
    "Parksville",
    "Vancouver",
    "Invermere",
    "Peace River",
    "Vernon",
    "Kamloops",
    "Penticton",
    "Victoria",
    "Kaslo",
    "Port Alberni",
    "Whistler",
    "Langley",
    "Port Hardy"
  ],
  "Manitoba": [
    "Birtle",
    "Flin Flon",
    "Swan River",
    "Brandon",
    "Snow Lake",
    "The Pas",
    "Cranberry Portage",
    "Steinbach",
    "Thompson",
    "Dauphin",
    "Stonewall",
    "Winnipeg"
  ],
  "New Brunswick": [
    "Cap-Pele",
    "Miramichi",
    "Saint John",
    "Fredericton",
    "Moncton",
    "Saint Stephen",
    "Grand Bay-Westfield",
    "Oromocto",
    "Shippagan",
    "Grand Falls",
    "Port Elgin",
    "Sussex",
    "Memramcook",
    "Sackville",
    "Tracadie-Sheila"
  ],
  "Newfoundland And Labrador": [
    "Argentia",
    "Corner Brook",
    "Paradise",
    "Bishop's Falls",
    "Labrador City",
    "Portaux Basques",
    "Botwood",
    "Mount Pearl",
    "St. John's",
    "Brigus"
  ],
  "Northwest Territories": [
    "Town of Hay River",
    "Town of Inuvik",
    "Yellowknife"
  ],
  "Nova Scotia": [
    "Amherst",
    "Hants County",
    "Pictou",
    "Annapolis",
    "Inverness County",
    "Pictou County",
    "Argyle",
    "Kentville",
    "Queens",
    "Baddeck",
    "County of Kings",
    "Richmond",
    "Bridgewater",
    "Lunenburg",
    "Shelburne",
    "Cape Breton",
    "Lunenburg County",
    "Stellarton",
    "Chester",
    "Mahone Bay",
    "Truro",
    "Cumberland County",
    "New Glasgow",
    "Windsor",
    "East Hants",
    "New Minas",
    "Yarmouth",
    "Halifax",
    "Parrsboro"
  ],
  "Nunavut": [],
  "Ontario": [
    "Ajax",
    "Halton",
    "Peterborough",
    "Atikokan",
    "Halton Hills",
    "Pickering",
    "Barrie",
    "Hamilton",
    "Port Bruce",
    "Belleville",
    "Hamilton-Wentworth",
    "Port Burwell",
    "Blandford-Blenheim",
    "Hearst",
    "Port Colborne",
    "Blind River",
    "Huntsville",
    "Port Hope",
    "Brampton",
    "Ingersoll",
    "Prince Edward",
    "Brant",
    "James",
    "Quinte West",
    "Brantford",
    "Kanata",
    "Renfrew",
    "Brock",
    "Kincardine",
    "Richmond Hill",
    "Brockville",
    "King",
    "Sarnia",
    "Burlington",
    "Kingston",
    "Sault Ste. Marie",
    "Caledon",
    "Kirkland Lake",
    "Scarborough",
    "Cambridge",
    "Kitchener",
    "Scugog",
    "Chatham-Kent",
    "Larder Lake",
    "Souix Lookout CoC Sioux Lookout",
    "Chesterville",
    "Leamington",
    "Smiths Falls",
    "Clarington",
    "Lennox-Addington",
    "South-West Oxford",
    "Cobourg",
    "Lincoln",
    "St. Catharines",
    "Cochrane",
    "Lindsay",
    "St. Thomas",
    "Collingwood",
    "London",
    "Stoney Creek",
    "Cornwall",
    "Loyalist Township",
    "Stratford",
    "Cumberland",
    "Markham",
    "Sudbury",
    "Deep River",
    "Metro Toronto",
    "Temagami",
    "Dundas",
    "Merrickville",
    "Thorold",
    "Durham",
    "Milton",
    "Thunder Bay",
    "Dymond",
    "Nepean",
    "Tillsonburg",
    "Ear Falls",
    "Newmarket",
    "Timmins",
    "East Gwillimbury",
    "Niagara",
    "Toronto",
    "East Zorra-Tavistock",
    "Niagara Falls",
    "Uxbridge",
    "Elgin",
    "Niagara-on-the-Lake",
    "Vaughan",
    "Elliot Lake",
    "North Bay",
    "Wainfleet",
    "Flamborough",
    "North Dorchester",
    "Wasaga Beach",
    "Fort Erie",
    "North Dumfries",
    "Waterloo",
    "Fort Frances",
    "North York",
    "Waterloo",
    "Gananoque",
    "Norwich",
    "Welland",
    "Georgina",
    "Oakville",
    "Wellesley",
    "Glanbrook",
    "Orangeville",
    "West Carleton",
    "Gloucester",
    "Orillia",
    "West Lincoln",
    "Goulbourn",
    "Osgoode",
    "Whitby",
    "Gravenhurst",
    "Oshawa",
    "Wilmot",
    "Grimsby",
    "Ottawa",
    "Windsor",
    "Guelph",
    "Ottawa-Carleton",
    "Woolwich",
    "Haldimand-Norfork",
    "Owen Sound",
    "York"
  ],
  "Prince Edward Island": [
    "Alberton",
    "Montague",
    "Stratford",
    "Charlottetown",
    "Souris",
    "Summerside",
    "Cornwall"
  ],
  "Quebec": [
    "Alma",
    "Fleurimont",
    "Longueuil",
    "Amos",
    "Gaspe",
    "Marieville",
    "Anjou",
    "Gatineau",
    "Mount Royal",
    "Aylmer",
    "Hull",
    "Montreal",
    "Beauport",
    "Joliette",
    "Montreal Region",
    "Bromptonville",
    "Jonquiere",
    "Montreal-Est",
    "Brosssard",
    "Lachine",
    "Quebec",
    "Chateauguay",
    "Lasalle",
    "Saint-Leonard",
    "Chicoutimi",
    "Laurentides",
    "Sherbrooke",
    "Coaticook",
    "LaSalle",
    "Sorel",
    "Coaticook",
    "Laval",
    "Thetford Mines",
    "Dorval",
    "Lennoxville",
    "Victoriaville",
    "Drummondville",
    "Levis"
  ],
  "Saskatchewan": [
    "Avonlea",
    "Melfort",
    "Swift Current",
    "Colonsay",
    "Nipawin",
    "Tisdale",
    "Craik",
    "Prince Albert",
    "Unity",
    "Creighton",
    "Regina",
    "Weyburn",
    "Eastend",
    "Saskatoon",
    "Wynyard",
    "Esterhazy",
    "Shell Lake",
    "Yorkton",
    "Gravelbourg"
  ],
  "Yukon Territory": [
    "Carcross",
  "Whitehorse"
  ]
}

//Cities Arrays without states
const uk_cities_array = ["Aberdeenshire", "Angus", "Argyll", "Avon", "Ayrshire", "Banffshire", "Bedfordshire", "Berkshire", "Berwickshire", "Buckinghamshire", "Bute", "Caithness", "Cambridgeshire", "Cheshire", "Clackmannanshire", "Cleveland", "Clwyd", "Cornwall", "Cumbria", "Derbyshire", "Devon", "Dorset", "Dumfriesshire", "Dunbartonshire", "Durham", "Dyfed", "East Lothian", "East Sussex", "Essex", "Fife", "Gloucestershire", "Gwent", "Gwynedd", "Hampshire", "Herefordshire", "Hertfordshire", "Inverness-shire", "Isle of Wight", "Kent", "Kincardineshire", "Kinross-shire", "Kirkcudbrightshire", "Lanarkshire", "Lancashire", "Leicestershire", "Lincolnshire", "London", "Merseyside", "Mid Glamorgan", "Middlesex", "Midlothian", "Moray", "Nairnshire", "Norfolk", "North Humberside", "North Yorkshire", "Northamptonshire", "Northumberland", "Nottinghamshire", "Orkney", "Oxfordshire", "Peeblesshire", "Perthshire", "Powys", "Renfrewshire", "Ross-shire", "Roxburghshire", "Rutland", "Selkirkshire", "Shetland", "Shropshire", "Somerset", "South Glamorgan", "South Humberside", "South Yorkshire", "Staffordshire", "Stirlingshire", "Suffolk", "Surrey", "Sutherland", "Tyne And Wear", "Warwickshire", "West Glamorgan", "West Lothian", "West Midlands", "West Sussex", "West Yorkshire", "Wigtownshire", "Wiltshire", "Worcestershire"];

//Ireland
//Europe
//Australia & New Zealand
//Asia
//Middle East
//Africa
//South America

export { cities, cities_with_geo, ca_cities,  uk_cities_array };