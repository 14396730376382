import { del, get, post, put, patch } from "../helpers/api_helper"
import { POST_BTN_LOGIN, POST_BTN_PASSWORD_UPDATE, POST_BTN_PASSWORD_EMAIL_RESET
			 } from '../api/urls';

export function validateName(name){
	//TODO: check for dangerous characters
	return name.length >= 2 ? true : false;
}
export function validateEmail(email){
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function validatePassword(password){
	//must be at least 6 for dev, 8 for live
	//check characters
	//TODO: check password for dangerous characters
	return password.length >= 6 ? true : false;
}

export async function loginBTNUser(loginResponse){
  	let email = loginResponse.email;
  	let password = loginResponse.password;

	return await post(POST_BTN_LOGIN, { email, password });
}

export async function logoutBTNUser(){
	localStorage.clear();
	//TODO: Clear/Delete reducers
}

export async function updateBTNPassword({ userId, password }){
	let url = POST_BTN_PASSWORD_UPDATE +"/"+ userId;
	return patch(url, { userId, password });
}

export async function sendPasswordResetEmail(email){
	let url = POST_BTN_PASSWORD_EMAIL_RESET;
	return post(url, { email });
}

