import React, { Component } from 'react'
import moment from 'moment';
import { connect } from "react-redux"
import { Link } from 'react-router-dom';

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { Col, Row, Card, CardBody, Table } from 'reactstrap';
import { Dropdown, DropdownButton } from "react-bootstrap";

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { getCurrencySymbol } from 'scripts/general';
import { requestFilterOptionsAdmin } from './scripts';

import { ThemeSpinner } from 'theme/elements/spinner';
import MarketRequestDetailsTable from 'components/tables/marketplace/MarketRequestDetailsTable';
import MarketProposalsTable from 'components/tables/marketplace/MarketProposalsTable';

import { ADMIN_MARKETPLACE_CONVERT_TUTOR_REQUEST, ADMIN_USER_DETAILS, STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS } from 'routes/paths';


class MarketPlaceAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
          marketRequestsAdmin: [],
          
          filteredRequests: [],
          selectedFilterOption: 1,
          filterOptions: requestFilterOptionsAdmin,

          showDetails: false,
          showTutorRequestId: null,

          showProposals: false,
          showProposalsId: null
        }
      }

    componentDidMount() {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("getAdminMarketPlace");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    getDetailsClass = request => {
        let deadlineDays = moment().diff(request.proposalsDeadline, 'days'); //today - deadline
        let deadlineHours = moment().diff(request.proposalsDeadline, 'hours'); 
        let deadlinePast = deadlineDays > 0 ? true : false;
        let deadlineClass = "text-dark";
        
        if(deadlinePast){
            deadlineClass = "text-danger";
        }
        else if(Math.abs(deadlineHours) < 3){
            deadlineClass = "text-warning"
        }

        return deadlineClass;
    }

    showDetails = requestId => {
        this.setState({showDetails: true, 
                        showTutorRequestId: requestId});
    }

    hideDetails = requestId => {
        this.setState({showDetails: false, 
                        showTutorRequestId: requestId});
    }

    showProposals = requestId => {
        this.setState({showProposals: true, 
                    showProposalsId: requestId});
    }

    hideProposals = requestId => {
        this.setState({showProposals: false, 
                    showProposalsId: requestId});
    }

    filterRequests = eventKey => {
        let { filterOptions, marketRequestsAdmin } = this.state;

        let marketRequestsList = [...marketRequestsAdmin];
        let selectedFilterOption = filterOptions.find(option => option.id == eventKey)

        marketRequestsList = marketRequestsList.filter(request => selectedFilterOption.filter(request));

        this.setState({
            selectedFilterOption: selectedFilterOption.id,
            filteredRequests: marketRequestsList,
        });
    }

    updateNoTutorAvailable = tutorRequestId => {
        if(confirm("Are you sure there are NO TUTORS AVAILABLE?")){
          let { onSubmitApiCall } = this.props;
          onSubmitApiCall("patchNoTutorAvailable", tutorRequestId );
        }
    }

    deleteMarketRequest = marketRequestId => {
        if(confirm("Are you sure you want to DELETE this MARKET request?")){
          let { onSubmitApiCall } = this.props;
          onSubmitApiCall("adminDeleteMarketRequest", marketRequestId);
        }
    }

    extendProposalDeadline = proposalId => {
        if(confirm("This action will add 2 days to the student's deadline to respond.  Please confirm.")){
            const { onSubmitApiCall } = this.props;
            onSubmitApiCall("extendProposalDeadline", proposalId);
        }
    }

    deleteProposal = proposalId => {
        if(confirm("Are you sure you want to DELETE this proposal?")){
            let { onSubmitApiCall } = this.props;
            this.setState({ processing: true });
            onSubmitApiCall("adminDeleteProposal", proposalId);
        }
    }

    sendEmailReminder = tutorRequestId => {
        alert("TODO");
    }

    sendSMSReminder = tutorRequestId => {
        alert("TODO");
    }

    render() {
        const { apiResponse } = this.props
        let { marketRequestsAdmin, filteredRequests, 
            selectedFilterOption, filterOptions,
            showDetails, showTutorRequestId,
            showProposals, showProposalsId  } = this.state
        
        const loading = apiResponse.loading;
        let selectedTitle = filterOptions.find(opt => opt.id == selectedFilterOption).title;

        if(selectedFilterOption == 1 && filteredRequests.length == 0){
            filteredRequests = marketRequestsAdmin;
        }
        
        filteredRequests.sort((a,b) => {
            return b.id > a.id;
        });
        
        return (
            <>
                <BTNMetaTags title="MarketPlace Admin" />
                
                {
                    loading && <ThemeSpinner />
                }
                <Row>
                    <Col md={{size: 10, offset: 1}}>
                        <Card>
                            <CardBody>
                                {filteredRequests.length} / {marketRequestsAdmin.length} Market Requests
                                <Row>
                                    <Col sm="4" xs="12">
                                        <DropdownButton
                                            className="border-0 f-16 p-0 min-w-150 cut-texts"
                                            variant="outline-default"
                                            onSelect={this.filterRequests}
                                            title={"Filter: " + selectedTitle}
                                            >
                                            {
                                                filterOptions.map((option, i) =>
                                                    <Dropdown.Item
                                                        key={"dropdown-"+i}
                                                        eventKey={option.id}
                                                        active={selectedFilterOption === option.id}
                                                    >
                                                        <span>{option.title}</span>
                                                    </Dropdown.Item>
                                                )
                                            }
                                        </DropdownButton>
                                    </Col>
                                </Row>
                                <Table>
                                    <tbody>
                                        {filteredRequests.map((request, i) =>
                                            <tr key={"row-"+i}>
                                                <td>{request.id}</td>
                                                <td>
                                                    <Row>
                                                        <Col sm="4">
                                                            <h5>
                                                                <Link to={ADMIN_USER_DETAILS + "/" + request.studentRefId} className="text-info" target="_blank">
                                                                    {request.studentName}
                                                                </Link><br />
                                                                {request.course} {" "}
                                                                {
                                                                    request.school &&
                                                                    <> @ {request.school}</>   
                                                                }
                                                                <br />
                                                                <b>{getCurrencySymbol(request.currency)}{request.minRate} - {request.maxRate}/hr</b>
                                                                <br />
                                                                <small>{request.studentLocation}</small><br />
                                                            </h5>
                                                            {
                                                                request.online &&
                                                                    <span className="badge badge-soft-info">Online</span> 
                                                            }
                                                            {" "}
                                                            {
                                                                request.inPerson &&
                                                                    <span className="badge badge-soft-success">In-Person</span>
                                                            }
                                                            <br /><br />
                                                            {
                                                                moment(request.proposalsDeadline).isAfter(moment()) || (moment(request.proposalsDeadline).isBefore(moment()) && request.proposals.length == 0) &&
                                                                    <b className={this.getDetailsClass(request)}>
                                                                        Proposals Deadline: {moment(request.proposalsDeadline).format("lll")}  ({moment(request.proposalsDeadline).fromNow()})
                                                                        <br /><br />
                                                                    </b>
                                                            }
                                                            
                                                            <button className="btn btn-sm btn-outline-secondary" onClick={showDetails && showTutorRequestId == request.id ? () => this.hideDetails(request.id) : () => this.showDetails(request.id) }>{showDetails && showTutorRequestId == request.id  ? "Hide Details" : "Show Details"}</button>
                                                            <br /><br />
                                                            {
                                                                showDetails && showTutorRequestId == request.id &&
                                                                    <MarketRequestDetailsTable key={request.tutorRequestId} marketRequest={request} isAdmin={true} />
                                                            }
                                                            
                                                            {
                                                                request.proposals.length > 0 &&
                                                                <>
                                                                    {
                                                                        request.smsAlert == true && 
                                                                            <span className=" float-right p-2"><button className="btn btn-sm btn-info" onClick={() => this.sendSMSReminder(request.tutorRequestId)}><i className="bx bx-phone" /></button></span>
                                                                    }
                                                                    <span className="float-right p-2"><button className="btn btn-sm btn-success" onClick={() => this.sendEmailReminder(request.tutorRequestId)}><i className="bx bx-envelope" /></button></span>
                                                                    <br /><br />
                                                                </>
                                                            }
                                                            
                                                        </Col>
                                                        <Col sm="8">
                                                            <Link to={STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS + "/" + request.tutorRequestRefId}
                                                                    className="btn btn-sm btn-outline-primary"
                                                                    target="_blank">
                                                                View Progress
                                                            </Link>
                                                            <span className=" float-right p-2"><button className="btn btn-sm btn-warning" onClick={() => this.updateNoTutorAvailable(request.tutorRequestId)}>None Available</button></span>
                                                            <span className="float-right p-2"><button className="btn btn-sm btn-danger" onClick={() => this.deleteMarketRequest(request.refId)}><i className="bx bx-trash" /></button></span>
                                                            <br /> <br />

                                                            {
                                                                request.proposalsLeft &&
                                                                    <>
                                                                        <em className={request.proposalsLeft  <= 3 ? "text-danger" : "text-dark"}><b>{request.proposalsLeft} Proposals Left</b></em>
                                                                        <br /><br />
                                                                    </>
                                                            }
                                                            
                                                            {
                                                                request.proposals && request.proposals.length > 0 
                                                                ? 
                                                                    <>
                                                                        <br />
                                                                        <b>{request.proposals.length} Proposals</b><br/>
                                                                        <button className="btn btn-sm btn-outline-secondary" onClick={showProposals && showProposalsId == request.id ? () => this.hideProposals(request.id) : () => this.showProposals(request.id) }>{showDetails && showTutorRequestId == request.id  ? "Hide Proposals" : "Show Proposals"}</button>
                                                                        <br /><br />
                                                                        {showProposals && showProposalsId == request.id &&
                                                                            <MarketProposalsTable proposals={request.proposals} onDelete={proposalId => this.deleteProposal(proposalId)} onExtendProposalDeadline={this.extendProposalDeadline}/>
                                                                        }
                                                                    </>
                                                                : <center><em>No Proposals</em></center>
                                                            }
                                                            <br /><br />
                                                            <Link to={ADMIN_MARKETPLACE_CONVERT_TUTOR_REQUEST + "/" + request.tutorRequestId} target="_blank">Create New Market Request</Link>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceAdmin)
  