import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import TutorContractsTable from 'components/tables/tutor-contracts/TutorContractsTable'
import NavTabs from 'components/navTabs/NavTabs'


class MyTutorContracts extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             tutorId: props.match.params.tutorId,
             tutorContracts: [],
             
             contractsProcessed: false,
             tabs: [],

             showHowItWorks: false,
        }
    }
    
    componentDidMount() {
        let { tutorId } = this.state;
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorContractsForTutor", tutorId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(!newState.contractsProcessed && newState.tutorContracts.length > 0){
                    this.processTutorContracts(newState.tutorContracts);
                  }
            }
        }
    }

    processTutorContracts = tutorContracts => {
        let tabs = [];
        let unaddressedContracts = [];
        let acceptedContracts = [];
        let declinedContracts = [];
        
        tutorContracts.forEach(contract =>{
            if(contract.tutorDecision === true){
                acceptedContracts.push(contract);
            }
            else if(contract.tutorDecision === false){
                declinedContracts.push(contract);
            }
            else{
                unaddressedContracts.push(contract);
            }
        });

        tabs = [{id: 1, title: "Pending Requests (" + unaddressedContracts.length + ")", content: <TutorContractsTable key={"pending-" + unaddressedContracts.length} tutorContracts={unaddressedContracts} /> }];

        if(acceptedContracts.length > 0){
            tabs.push({id: 2, title: "Accepted Requests (" + acceptedContracts.length + ")", content: <TutorContractsTable key={"accepted-" + acceptedContracts.length} tutorContracts={acceptedContracts} /> });
        }

        if(declinedContracts.length > 0){
            tabs.push({id: 3, title: "Declined Requests (" + declinedContracts.length + ")", content: <TutorContractsTable key={"declined-" + declinedContracts.length} tutorContracts={declinedContracts} /> });
        }

        this.setState({tabs});

    }
    render() {
        let { tutorContracts, tabs, showHowItWorks } = this.state;
        let { apiResponse } = this.props;
        let loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="My Tutor Requests" />
                
                <Row>
                    <Col md={12} lg={{size: 8, offset: 1}}>
                        <Card>
                            <CardBody>
                                <CardTitle>My Tutor Requests ({tutorContracts.length})</CardTitle>

                                <a className="text-primary" onClick={() => this.setState({showHowItWorks : !showHowItWorks})}>{showHowItWorks ? "Hide" : "Show" } How It Works:</a><br /><br />
                                {showHowItWorks && 
                                    <Row>
                                        <Col md={{size: 10}}>
                                            <Card className="pb-3" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.25)"}}>
                                                <CardBody>
                                                    <CardTitle>How It Works</CardTitle>
                                                    <h4><b>1) Review & Respond</b></h4>
                                                    <ul>
                                                        <li>Carefuly review the requests below and either accept or decline each request.</li>
                                                        <li>Accept or decline each request by the listed deadline (typically within 1 day)</li>
                                                        <li>If you accept, the student will have 3 days to respond.</li>
                                                        <li>If the student selects you as his/her tutor, you will receive an e-mail alert.</li>
                                                    </ul>

                                                    <h4><b>2) Wait For Student Confirmation</b></h4>
                                                    <p>Accepting a request <b>does not create a match</b>, it simply sends your profile to the student to review. The student will have to respond by the listed deadline (typically 3 days).</p>

                                                    <h4><b>3) Get To Work!</b></h4>
                                                    <p>If the student selects you as his/her tutor, you will receive an e-mail alert. The student will be added to your list of students on your Dashboard where you will be able to access the student's contact information.</p>
                                                    <p>Reach out to the student immediately, introduce yourself and discuss the session details (e.g. when, where, how often).</p>
                                                    <p className="text-primary">Use our online tools to charge each session <b>AFTER</b> it occurs.  </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                
                                
                                <h5>Before Accepting, PLEASE NOTE:</h5>
                                <li>The listed rate CANNOT be changed after you accept the request. </li>
                                <li>If circumstances change that warrant a rate change, e.g. student wants in-person instead of online sessions, contact us immediately and we'll discuss this with the student.  <b>You are not permitted to renegotiate rates directly with students.</b></li>
                                <li>You MUST be available to assist the student during the specified dates.</li>
                                <li>Pay attention to any time zone difference and make sure you can meet during the student's specified time.</li>
                                <li><b>ALL SESSIONS</b> with the student must be charged using our online tools within <b>3 days</b> of the session. </li>
                                <li><span className="text-danger" style={{fontWeight: "bold"}}>Direct payment from student of any kind (e.g. cash, check, Venmo, etc) is a violation of your contract with us.</span></li>
                                
                                <li>Email us any questions or concerns you may have for the student.</li>
                                <br />
                                {
                                    loading &&
                                        <center><ThemeSpinner /></center>
                                }
                                
                                <Row>
                                    <Col md={{size: 12}} lg={{size: 10, offset: 1}}>
                                        <NavTabs key={"tabs-" + tabs.length} tabs={tabs} />
                                    </Col>
                                </Row>
                                
                                {/* <TutorContractsTable key={"contracts-" + tutorContracts.length} tutorContracts={tutorContracts} />  */}
                                * Net Earnings = Your take home earnings after all service & payment processing fees have been deducted.
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(MyTutorContracts)