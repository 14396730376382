import React, { useEffect } from "react"

function BTNMetaTags(props) {
  // usePageMeta(props.title, props.description);
  let title = props.title;
  let description = props.description;

  const defaultTitle = "BuffTutor Dashboard";
  const defaultDesc = "BuffTutor Dashboard";

  useEffect(() => {
      document.title = title ? title + " | BuffTutor" : defaultTitle;
      document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
  }, [defaultTitle, title, defaultDesc, description]);
  
  return(
      <></>
  )
}

export default BTNMetaTags;