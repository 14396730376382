import React, { Component } from 'react'
import { connect } from "react-redux"

import SubjectsDualList from './SubjectsDualList';

import { submitApiCallAction } from "store/actions"
// import { camelCaseToWords } from 'scripts/general';

class UpdateSubjectsDualList extends Component {
    updateSubjects = subjectIds => {
        let { userRefId } = this.props;
        let subjects = [];

        subjectIds.forEach(subjectId => {
            subjects.push({id: subjectId})
        })

        let formData = {subjects, tutorId: userRefId};
        
        let { onSubmitApiCall } = this.props;         
        
        onSubmitApiCall("updateProfileSubjects", formData);
        
        if(this.props.onSubmit){
            this.props.onSubmit();
        }
    }

    render() {
        let { userSubjects=[], showReload=true } = this.props;
        let subjectIds = [];
        // let subjectNames = [];

        subjectIds = userSubjects.map(subject => subject.id);
        // subjectNames = userSubjects.map(subject => camelCaseToWords(subject.name));
        
        return (
            <SubjectsDualList onSubmit={this.updateSubjects} selected={subjectIds} showReload={showReload}/>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSubjectsDualList)
