import {
          LOAD_TUTOR_SESSIONS,
          SAVE_RECENT_TUTOR_SESSIONS,
          TUTOR_SESSION_ERROR
        } from "./actionTypes";

const initialState = {
  recentSessions: [],
  tutorSessions: [],
  error: "",
  loading: false
}

const tutorSessionsReducer = (state=initialState, action) => {
	switch(action.type){
    case LOAD_TUTOR_SESSIONS:
      return {
        ...state,
        error: "",
        loading: true
      }
    case SAVE_RECENT_TUTOR_SESSIONS:
      return {
        ...state,
        recentSessions: action.payload.recentSessions,
        error: "",
        loading: false
      }
    case TUTOR_SESSION_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    default:
      return state
  }
}

export default tutorSessionsReducer;