import { GET_USER_CHATS, GET_CHAT_MESSAGES, POST_SUBMIT_CHAT_MESSAGE, POST_UPLOAD_CHAT_FILE, POST_UPLOAD_CHAT_IMAGE,
        GET_ADMIN_RECENT_CHATS, 
        GET_ADMIN_FLAGGED_CHATS,
        GET_ADMIN_USER_FLAGGED_CHATS,
        POST_LOAD_CHAT_DETAILS,
        PATCH_BLOCK_CHAT_PARTICIPANT,
        PATCH_UNBLOCK_CHAT_PARTICIPANT
        } from '../api/urls';
import { get, patch, post, postFormData } from "helpers/api_helper";

//Hidden Emails: t e s t @ m a i l . c o m, test <<at>> mail [dot] com, etc
//Ref: https://regexr.com/2tj0n
const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
const obfuscatedEmailPattern = /.*(at|@).*(((dot|\.|punto)[^a-z]*(c[^a-z]*o[^a-z]*m|n[^a-z]*e[^a-z]*t|e[^a-z]*d[^a-z]*u|o[^a-z]*r[^a-z]*g|[a-z][^a-z]*[a-z][^a-z]*(dot|\.|punto)[^a-z]*[a-z][^a-z]*[a-z]))($|[^a-z]))/g;
// const obfuscatedEmailPattern = /([-0-9a-zA-Z.+_]+\s*(?:@|\(at\)|\|at\||\(@\))\s*+[-0-9a-zA-Z.+_]+\s*(?:\.|\(Dot\)|\(dot\))+\s*(?:de|com|net|org))/g;
const phonePattern = /(\+?(\s*|-|\.)?\d{1,2}(\s*|\s*-\s*|\.)?)?(\(?\d{3}\)?|\d{3})(\s*|\s*-\s*|\.)?(\d{3}(\s*|\s*-\s*|\.)?\d{4})/g;
const urlPattern = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g;

// get chats
export const getUserChats = userId => {
    return get(GET_USER_CHATS + "/" + userId);
}

// get messages
export const getChatMessages = threadId => {
    return get(`${GET_CHAT_MESSAGES}/${threadId}`)
}

export const getAdminRecentChats = () => {
    return get(GET_ADMIN_RECENT_CHATS);
}

export const getAdminFlaggedChats = () => {
    return get(GET_ADMIN_FLAGGED_CHATS);
}

export const getAdminUserFlaggedChats = userId => {
    return get(GET_ADMIN_USER_FLAGGED_CHATS + "/" + userId);
}

// post messages
export const submitChatMessage = formData => {
    return post(POST_SUBMIT_CHAT_MESSAGE + "/" + formData.threadId, formData);
}

export const uploadChatFile = ({ threadId, chatFile }) => {
    var formData = new FormData();
    formData.append("file", chatFile); 
    let headers = { headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } };
  
    return postFormData(POST_UPLOAD_CHAT_FILE + "/" + threadId, formData, headers);
}

export const uploadChatImage = ({ threadId, chatImage }) => {
    var formData = new FormData();
    formData.append("file", chatImage); 
    let headers = { headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } };
  
    return postFormData(POST_UPLOAD_CHAT_IMAGE + "/" + threadId, formData, headers);
}

export const getChatDetails = formData => {
    return post(POST_LOAD_CHAT_DETAILS + "/" + formData.threadId, formData);
}

export const blockChatParticipant = ({threadId, participantId}) =>{
    return patch(PATCH_BLOCK_CHAT_PARTICIPANT + "/" + threadId + "/" + participantId);
}

export const unblockChatParticipant = ({threadId, participantId}) =>{
    return patch(PATCH_UNBLOCK_CHAT_PARTICIPANT + "/" + threadId + "/" + participantId);
}

//Check If Message Contains Email
export const checkIfEmailInString = (text) => { 
    return emailPattern.test(text);
}

export const checkIfObfuscatedEmailInString = (text) => { 
    return obfuscatedEmailPattern.test(text);
}

//Check For Phone
export const checkIfPhoneInString = (text) => { 
    return phonePattern.test(text);
}

//Check For URLs
export const checkIfURLInString = (text) => { 
    return urlPattern.test(text);
}

export const checkAllLinkTypes = (text) => {
    return checkIfEmailInString(text) || checkIfObfuscatedEmailInString(text) || checkIfPhoneInString(text) || checkIfURLInString(text);
}

//Replace Email
export const replaceEmailInString = (text) => { 
    text = text.replace(emailPattern , "xxxxx");
    // text = text.replace(obfuscatedEmailPattern, "******@****.***");
    return text;
}

//Replace Phone
export const replacePhoneInString = (text) => { 
    return text.replace(phonePattern, "#####");
}

//Replace URLs
export const replaceIfURLInString = (text) => { 
    return text.replace(urlPattern, "*****");
}

export const replaceAllLinks = text =>{
    text = replaceEmailInString(text);
    text = replacePhoneInString(text);
    text = replaceIfURLInString(text);
    return text;
}
