import * as paths from "./paths";

const allRoutes = [{ title: "Dashboard", path: "/dashboard", userIdRequired: false, icon: "bx bx-home-circle" },];

const studentRoutes = [{ title: "Inbox", path: paths.CHAT_INBOX, userIdRequired: false, icon: "bx bxs-chat" },     
                        { title: "Request A Tutor", path: paths.STUDENT_REQUEST_TUTOR, userIdRequired: false, icon: "bx bx-desktop" },]

const tutorRoutes = [{ title: "Inbox", path: paths.CHAT_INBOX, userIdRequired: false, icon: "bx bxs-chat" },     
                        { title: "Proficiency Exams", path: paths.PROFICIENCY_EXAM_LIST, userIdRequired: false, icon: "bx bx-edit" },
                        { title: "Tutor Checklist", path: paths.TUTOR_CHECKLIST, userIdRequired: false, icon: "bx bx-task" },
                        { title: "Add Returning Student", path: paths.TUTOR_ADD_RETURNING_STUDENT, userIdRequired: false, icon: "bx bx-group" },
                        { title: "Taxes", path: paths.TUTOR_TAX_INFO, userIdRequired: false, icon: "bx bx-dollar" },
                        // { title: "Switch To Stripe Express", path: paths.TUTOR_SWITCH_TO_STRIPE_EXPRESS, userIdRequired: false, icon: "bx bxl-stripe" },
                    ]

const tutorApplicantRoutes = [{ title: "Proficiency Exams", path: paths.PROFICIENCY_EXAM_LIST, userIdRequired: false, icon: "bx bx-edit" },]

const leadRoutes = [ {title: "Active Leads", path: paths.LEADS_LIST_ACTIVE, userIdRequired: false, icon: "bx bx-list-ol"}];

const adminRoutes = [{ title: "User Manager", path: paths.ADMIN_USER_MANAGER, userIdRequired: false, icon: "bx bx-user-circle" },
                        { title: "Tutor Requests", path: paths.ADMIN_TUTOR_REQUESTS, userIdRequired: false, icon: "bx bx-list-ol" },   
                        { title: "Search Tutors", path: paths.SEARCH_TUTORS, userIdRequired: false, icon: "bx bx-search-alt" },     
                        { title: "Applications", path: paths.ADMIN_TUTOR_APPLICATIONS, userIdRequired: false, icon: "bx bx-group" },     
                        { title: "Assignments", path: paths.ADMIN_ASSIGNMENTS, userIdRequired: false, icon: "bx bxs-graduation" },     
                        { title: "Scholarships", path: paths.ADMIN_SCHOLARSHIPS, userIdRequired: false, icon: "bx bxs-graduation" },
                        { title: "Analytics", path: paths.ADMIN_ANALYTICS, userIdRequired: false, icon: "bx bx-chart" },
                        { title: "Google Indexing", path: paths.ADMIN_GOOGLE_SUBMIT_BATCH_URLS, userIdRequired: false, icon: "bx bx-redo" },     
                        { title: "Google My Business", path: paths.ADMIN_GOOGLE_MY_BUSINESS, userIdRequired: false, icon: "bx bxl-google" },     
                        
                        { title: "Chat (Admin)", path: paths.ADMIN_INBOX, userIdRequired: false, icon: "bx bx-chat" },     
                        { title: "MarketPlace (Admin)", path: paths.ADMIN_MARKETPLACE, userIdRequired: false, icon: "bx bxs-store" },     
                        { title: "Inbox", path: paths.ADMIN_GMAIL_INBOX, userIdRequired: false, icon: "bx bx-envelope" },     

                        { title: "Evaluations", path: paths.ADMIN_EVALUATIONS, userIdRequired: false, icon: "bx bx-star" },     
                        { title: "Refunds", path: paths.ADMIN_REFUNDS, userIdRequired: false, icon: "bx bx-undo" },     
                        

                    ]

const staffRoutes = []

const policiesRoutes = [{ title: "FAQs (TODO)", path: paths.POLICIES_FAQS, userIdRequired: false, icon: "bx bx-help-circle" },]

const sidebarMenu = [{ title: "Menu", items: allRoutes},
                        { title: "Admin", items: adminRoutes, role: "admin"},
                        { title: "Students", items: studentRoutes, role: "student"},
                        { title: "Tutors", items: tutorRoutes, role: "tutor"},
                        { title: "Tutor Applicants", items: tutorApplicantRoutes, role: "tutor-applicant"},
                        { title: "Lead Generation", items: leadRoutes, role: "leads"},
                        { title: "Staff", items: staffRoutes, role: "staff"},
                        // { title: "Policies", items: policiesRoutes}
                    ];
export default sidebarMenu