import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment';

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { LeadDetails, BidFormLayout } from './actions';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { getCurrencySymbol } from 'scripts/general';
import { Col, Row } from 'reactstrap';

import CreateBidForm from 'components/forms/formik/leads/CreateBidForm';
import AddToLeadListForm from 'components/forms/formik/leads/AddToLeadListForm';

class TutorRequestLeadsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            // leadsColumns: ["course", "bid"],
            leadsColumns: ["course"],
            processing: false
        }
    }
    
    componentDidMount() {
        let { leads=[]} = this.props;
        let { leadsColumns } = this.state;
        
        let columns = createSpecifiedColumns(leadsColumns);
        this.setState({columns});

        this.createRows(leads);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    window.location.reload();
                    // this.setState({processing: false});
                }
            }
        }
    }

    createRows = leads => {
        let rows = leads;

        rows.sort((a,b) =>{
            return moment(b.createdAt) > moment(a.createdAt); //SORT DESC createdAt
        });

        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row.course = this.createLeadData(row)
            // row.bid = row.minBid > 0 ? <CreateBidForm minBid={row.minBid} onSubmit={values => this.submitBid({...values, leadId: row.id})} /> : <><p>Lead Cost: {getCurrencySymbol(row.currency)}{row.price}</p><AddToLeadListForm price={row.price} onSubmit={() => {}}/></>;
        });
        
        return rows;
    }

    createLeadData = row => {
        return <>
                <Row>
                    <Col sm="6">
                        <h5>{row.studentName} <br />
                
                        {row.course} 
                        {
                            row.school &&
                            <> @ {row.school}</>   
                        }
                        </h5>
                        <b>Student's Budget: {getCurrencySymbol(row.currency)}{row.minRate} - {row.maxRate}/hr</b>
                        <br /><br />
                        <LeadDetails lead={row} />
                        <br /><br />
                    </Col>
                    <Col sm="6">
                        <BidFormLayout>
                            { (row.bid != null ? true : false) &&
                                <i style={{fontSize: "3em"}} className="pl-3 bx bx-check-circle text-success" />
                            }
                            <div className="p-2">
                                {
                                    row.minBid > 0 
                                        ? 
                                            <CreateBidForm 
                                                currency={row.leadCurrency} 
                                                minBid={row.minBid} 
                                                maxBid={row.bid ? row.bid.maxBid : null} 
                                                message={row.bid != null ? row.bid.attachment : ""} 
                                                submitted={row.bid != null ? true : false} 
                                                onSubmit={ values => this.submitBid({...values, leadId: row.id}) }  
                                                onDelete={() => this.deleteBid({leadId: row.id, bidId: (row.bid != null ? row.bid.id : null)})} /> 
                                        : 
                                            <AddToLeadListForm 
                                                currency={row.leadCurrency} 
                                                price={row.price} 
                                                maxBid={row.bid ? row.bid.maxBid : null} 
                                                message={row.bid != null ? row.bid.attachment : null} 
                                                submitted={row.bid != null ? true : false} 
                                                onSubmit={values => this.submitBid({...values, leadId: row.id})} 
                                                onDelete={() => this.deleteBid({leadId: row.id, bidId: (row.bid != null ? row.bid.id : null)})} />
                                }
                            </div>
                        </BidFormLayout>
                    </Col>
                </Row>
            </>
    }

    submitBid = formValues => {
        if(confirm("Please confirm this action.")){
            let { onSubmitApiCall } = this.props;
            this.setState({ processing: true });
            onSubmitApiCall("postCreateLeadBid", formValues);
            this.removeLeadFromRows(formValues.leadId);
        } 
    }

    deleteBid = ({leadId, bidId}) => {
        if(confirm("Are you sure you want to delete?")){
            let { onSubmitApiCall } = this.props;
            this.setState({ processing: true });
            onSubmitApiCall("patchDeleteLeadBid", bidId);
            this.removeLeadFromRows(leadId);
        }
    }

    removeLeadFromRows = leadId => {
        let { rows } = this.state;
        
        for (let i = 0; i < rows.length; i++) {
            const lead = rows[i];

            if(lead.id == leadId){
                rows.splice(i, 1);
            } 
        }

        this.setState({ rows, processing: false });
    }

    render() {
        let {rows, columns, processing } = this.state;

        let tableProps = {...this.props, rows, columns, createColumns: false};

        return (
            <>
                {
                    columns.length > 0 && !processing &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(TutorRequestLeadsTable)
