import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

import { formatDate, getCurrencySymbol } from 'scripts/general'
import { ThemeSoftBadge } from 'theme/elements/badges'
import ScholarshipAssignmentHoursForm from 'components/forms/formik/scholarships/ScholarshipAssignmentHoursForm'
import MarkChargesAsPaidForm from 'components/forms/formik/scholarships/MarkChargesAsPaidForm'

export class ScholarshipAssignments extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            activeScholarshipAssignments: [],
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getActiveScholarshipAssignments");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    setScholarshipAssignmentHours = (values) => {
        if(confirm("Confirm Setting TOTAL hours.")){
            const { onSubmitApiCall } = this.props;
            onSubmitApiCall("patchSetScholarshipAssignmentHours", values);
        }
    }

    updateChargesAsPaid = (values) => {
        const { onSubmitApiCall } = this.props;
        
        if(values.chargeIds && values.chargeIds.length > 0){
            onSubmitApiCall("patchMarkChargesAsPaid", values);
        }
    }

    render() {
        const { apiResponse } = this.props;
        let { activeScholarshipAssignments }  = this.state;
        let loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title= "Scholarship Assignments" />

                <Card>
                    <CardBody>
                        <CardTitle>
                            Active Scholarship Assignments
                        </CardTitle>
                        {
                            loading &&
                            <center><ThemeSpinner /></center>
                        }
                        <Row>
                            <Col xs="12">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Details</th>
                                            <th colSpan="2">Sessions</th>
                                            <th>Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activeScholarshipAssignments.map((scholarAssign, i) => 
                                            <tr key={i}>
                                                <td>
                                                    <b>{scholarAssign.studentName} && {scholarAssign.tutorName} <br />
                                                        {scholarAssign.course}</b> 
                                                        {" "} {!scholarAssign.active && <ThemeSoftBadge color="danger">NOT ACTIVE</ThemeSoftBadge>}
                                                    <br />
                                                    <b>Program: </b>{scholarAssign.program}<br />
                                                    <b>Rate:</b> {getCurrencySymbol(scholarAssign.studentCurrency)}{scholarAssign.studentRate} / {getCurrencySymbol(scholarAssign.tutorCurrency)}{scholarAssign.tutorRate}<br />
                                                    <b>Hours: </b>{scholarAssign.hours}<br />
                                                    <b>Balance: </b>{getCurrencySymbol(scholarAssign.studentCurrency)}{scholarAssign.balance} / {scholarAssign.balance / scholarAssign.studentRate} hrs<br />
                                                    <b>Dates: </b>{formatDate(scholarAssign.startDate)} - {formatDate(scholarAssign.endDate)}<br />
                                                    ID: {scholarAssign.id} {" "} Assign ID: {scholarAssign.assignmentId}<br />
                                                </td>
                                                <td colSpan="2">
                                                    <MarkChargesAsPaidForm onSubmit={this.updateChargesAsPaid} loading={loading} scholarshipAssignment={scholarAssign} />
                                                </td>
                                                <td>
                                                    <div className="col-md-4 col-md-offset-6">
                                                        <ScholarshipAssignmentHoursForm onSubmit={this.setScholarshipAssignmentHours} loading={loading} scholarshipAssignmentId={scholarAssign.id} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipAssignments)

