import {
          LOAD_CHAT_THREADS, LOAD_CHAT_MESSAGES,
          SAVE_CHAT_THREADS, SAVE_CHAT_MESSAGES,
          CHAT_ERROR, RESET_CHATS_REDUCER, RESET_CHAT_MESSAGES
        } from "./actionTypes";

const initialState = {
  chatThreads: [],
  messages: [], //for one (active) chat thread
  error: "",
  loadingThreads: false,
  loadingMessages: false,
}

const chatsReducer = (state=initialState, action) => {
	switch(action.type){
    case LOAD_CHAT_THREADS:
      return {
        ...state,
        error: "",
        loadingThreads: true,
        loadingMessages: false,
      }
    case LOAD_CHAT_MESSAGES:
      return {
        ...state,
        error: "",
        loadingThreads: false,
        loadingMessages: true,
      }
  
    case SAVE_CHAT_THREADS:
      let chatThreads = action.payload.hasOwnProperty("chatThreads") ? action.payload.chatThreads : action.payload;
      return {
        ...state,
        chatThreads,
        error: "",
        loadingThreads: false,
        loadingMessages: false,
      }

    case SAVE_CHAT_MESSAGES:
      let messages = action.payload.hasOwnProperty("messages") ? action.payload.messages : action.payload;
      return {
        ...state,
        messages,
        error: "",
        loadingThreads: false,
        loadingMessages: false,
      }

    case CHAT_ERROR:
      return {
        ...state,
        error: action.error,
        loadingThreads: false,
        loadingMessages: false,
      }
      
    case RESET_CHAT_MESSAGES:
        return {
          ...state,
          messages: [],
          // error: "",
          // loadingThreads: false,
          // loadingMessages: false,
        }

    case RESET_CHATS_REDUCER:
      return initialState;

    default:
      return state
  }
}

export default chatsReducer;