import {
  ASSIGNMENT_ERROR,
          LOAD_ASSIGNMENTS,
          SAVE_ASSIGNMENTS,
          SAVE_PREVIOUS_ASSIGNMENTS
        } from "./actionTypes";

const initialState = {
  assignments: [],
  previousAssignments: [],
  error: "",
  loading: false
}

const assignmentsReducer = (state=initialState, action) => {
	switch(action.type){
    case LOAD_ASSIGNMENTS:
      return {
        ...state,
        error: "",
        loading: true
      }

    case ASSIGNMENT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }
      
    case SAVE_ASSIGNMENTS:
      return {
        ...state,
        assignments: action.payload.assignments,
        error: "",
        loading: false
      }

      case SAVE_PREVIOUS_ASSIGNMENTS:
        return {
          ...state,
          previousAssignments: action.payload.previousAssignments,
          error: "",
          loading: false
        }
    default:
      return state
  }
}

export default assignmentsReducer;