import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {} from "../../store/actions"

//Simple bar
import SimpleBar from "simplebar-react"

//i18n
// import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100" style={{ overflowY:"scroll" }}>
            {this.props.type !== "condensed" ? (
              // <SimpleBar style={{ maxHeight: "100%" }}>
              <SimpleBar>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Sidebar.propTypes = {
  type: PropTypes.string
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(Sidebar))

// export default connect(
//   mapStateToProps,
//   {}
// )(withRouter(withTranslation()(Sidebar)))
