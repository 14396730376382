
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Row, Col, Input } from "reactstrap"

import { TUTOR_PROFILE, ADMIN_USER_DETAILS } from "routes/paths";
import BasicModal from "components/modals/BasicModal";

class CardTutorBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rates: this.props.rates || [],
      studentRate: "",
      studentPrepayRate: "",
      tutorId: "",
      tutorRate: "",
      tutorCurrency: "",
      btnRate: 0,
      btnPercent: 0,
      btnPrepayRate: 0,
      btnPrepayPercent: 0,
      concerns: "",

      isOpen: [],
    }

    this.calculateBtnPercent.bind(this);
    this.onChangeValue.bind(this);
    this.openModal.bind(this);
    this.closeModal.bind(this);
  }

  componentDidMount() {
      //When page loaded or BOX MOVED
      let rate = {};
      let { rates } = this.state;
      let tutor = this.props.data;

      this.setState({tutorId: tutor.id,
        tutorCurrency: tutor.currency,
        concerns: tutor.concerns}); //tutor request concerns

      rate = rates.filter(rt => { return rt.tutorId == tutor.id });
      if (rate.length > 0) {
        rate = rate[0];

        this.setState({studentRate: rate.studentRate,
                        studentPrepayRate: rate.studentPrepayRate,
                        tutorRate: rate.tutorRate,
                        concerns: rate.concerns});
        this.calculateBtnPercent();
      }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.dragging 
        && (prevState.studentRate != this.state.studentRate 
            || prevState.studentPrepayRate != this.state.studentPrepayRate  
            || prevState.tutorRate != this.state.tutorRate
              || prevState.concerns != this.state.concerns

              )) {
        if(this.state.studentRate != NaN && this.state.studentRate > 0
          && this.state.studentPrepayRate != NaN && this.state.studentPrepayRate > 0
          && this.state.tutorRate != NaN && this.state.tutorRate > 0
          ){
          this.calculateBtnPercent();
          this.props.onChangeRate(this.state.tutorId, this.state.studentPrepayRate, this.state.studentRate, this.state.tutorRate, this.state.tutorCurrency, this.state.concerns);
        }       
    }
  }

  calculateBtnPercent = () => {
    let { studentPrepayRate, studentRate, tutorRate } = this.state;
    let btnRate = studentRate - tutorRate;
    let btnPercent = parseInt((btnRate / studentRate) * 100);
    let btnPrepayRate = studentPrepayRate - tutorRate;
    let btnPrepayPercent = parseInt((btnPrepayRate / studentPrepayRate) * 100);

    this.setState({ btnRate, btnPercent, btnPrepayRate, btnPrepayPercent });
  }

  onChangeValue = (event) => {
    const { name, value } = event.target;
    
    this.setState({
          ...this.state,
          [name]: value
    });
  }

  openModal = (id) => {
    this.setState({
       isOpen: {
          [id]: true
       }
    });
  }
  
  closeModal = (id) => {
    this.setState({
       isOpen: {
          [id]: false
       }
    });
  }

  render() {
    let { showRates=true, showConcerns=true } = this.props;
    let tutor = this.props.data;

    let { studentPrepayRate, studentRate, tutorRate, btnRate, btnPercent, btnPrepayRate, btnPrepayPercent, concerns, isOpen } = this.state;
    let minPercent = 20;
    let maxPercent = 50;
    let passingGrade = 80;
    
    return (
      <React.Fragment>
        <Card className="task-box" style={{backgroundColor: tutor.status ? "" : "#f7f7f7"}}>
          <CardBody className="borad-width">
            <span onClick={this.props.removeCard} className="float-right ml-2" style={{cursor: "pointer"}}>×</span>
              {
                !tutor.status &&
                  <div className="float-right ml-2">
                    <span className="badge badge-pill font-size-11 badge-soft-danger">
                      Unavailable
                    </span>
                  </div>
              }
              <div className="float-right ml-2">
              {
                tutor.online &&
                  (
                    <span className="badge badge-pill font-size-11 badge-soft-info">
                      Online
                    </span>
                  )
              }
              {
                tutor.inPerson && 
                (
                  <span className="badge badge-pill font-size-11 badge-soft-warning">
                    In-Person
                  </span>
                )
              }
              </div>

            <div>
              <h5 className="font-size-15">
                <Link to={ADMIN_USER_DETAILS + "/" + tutor.refId} className="text-primary" target="_blank">
                  {tutor.title}
                </Link><br />
                {tutor.city}, {tutor.state} - {tutor.distance} miles away <br />
                {tutor.currencySymbol}{tutor.minRate} - {tutor.maxRate}/hr <br />
                {
                  tutor.rateDetails != null && tutor.rateDetails != "" &&
                    <>
                      <small><a onClick={() => this.openModal(tutor.id)} className="text-primary">Rate Details</a></small>

                      <BasicModal 
                          header={tutor.title + ": Rate Details"}
                          isOpen={isOpen[tutor.id]}
                          toggleModal={() => this.closeModal(tutor.id)}
                      >
                        <span style={{whiteSpace: "pre-line"}}>{tutor.rateDetails}</span>
                      </BasicModal>
                    </>
                }

              </h5>
              <p className="text-muted mb-4">
                <b>{tutor.description}</b><br />
                {tutor.experience.length > 100 ? `${tutor.experience.substring(0,100)}...` : tutor.experience}
                <br />
                  {
                    tutor.profExamResults.length > 0 &&
                    tutor.profExamResults.map((result, i) =>
                      <span key={i}>
                        <span style={{color: parseInt(result.grade) >= passingGrade ? "blue" : "red"}}>
                          {result.exam} - {result.grade}
                        </span>
                        <br />
                      </span>)
                  }
              </p>
            </div>

            <div className="team float-left">
              {
                <Link
                  to={TUTOR_PROFILE + "/" + tutor.refId}
                  target="_blank"
                  className="team-member d-inline-block"
                >
                  {tutor.avatar ? (
                    <img
                      src={tutor.avatar}
                      className="rounded-circle avatar-sm m-1"
                      alt=""
                    />
                  ) : (
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                        {tutor.name.toUpperCase().charAt(0)}
                      </span>
                    </div>
                  )}
                </Link>
              }
            </div>

            <div className="text-right">
            {
                tutor.assignments != null && 
                  <span className="badge font-size-11 badge-soft-success">
                    Students: {tutor.assignments}
                  </span>
              }
              {
                tutor.hours != null && 
                  <span className="badge font-size-11 badge-soft-dark">
                    Hours: {tutor.hours}
                  </span>
              }
              <br />
              {
                tutor.contracts != null &&
                  <span className="badge font-size-11 badge-soft-info">
                    Contracts: {tutor.contracts}
                  </span>
              }
              {
                tutor.missedContracts != null &&
                  <span className="badge font-size-11 badge-soft-warning">
                    Missed: {tutor.missedContracts}
                  </span>
              }
              
              {
                tutor.declinedContracts != null &&
                  <span className="badge font-size-11 badge-soft-danger">
                    Declined: {tutor.declinedContracts}
                  </span>
              }
              
              { showRates &&
                <Row>
                  
                  <Col sm={6}>
                    <Input type="number" name="studentRate" id="studentRate" value={studentRate} placeholder="Student Rate" onChange={this.onChangeValue} />
                  </Col>
                  <Col sm={6}>
                    <Input type="number" name="studentPrepayRate" id="studentPrepayRate" value={studentPrepayRate} placeholder="Student Prepay Rate" onChange={this.onChangeValue} />
                  </Col>
                  <br />
                  <Col sm={6}>
                    <Input type="number" name="tutorRate" id="tutorRate" value={tutorRate} placeholder="Tutor Rate" onChange={this.onChangeValue} />
                  </Col>
                  <Col sm={6}>
                    { studentRate < studentPrepayRate &&
                      <small className={"text-danger"}>Prepay > PayGo </small>
                    }
                    { studentRate > 0 &&
                      <small className={btnPercent <= minPercent || btnPercent > maxPercent ? 'error' : ''} >Rate ${btnRate}, Fee: {btnPercent}%</small>
                    }
                    <br />
                    { studentPrepayRate > 0 &&
                      <small className={btnPrepayPercent <= minPercent || btnPrepayPercent > maxPercent ? 'error' : ''} >PREPAY Rate ${btnPrepayRate}, Fee: {btnPrepayPercent}%</small>
                    }
                  </Col>
                </Row>
              }
              
              <br />

              {
                showConcerns && 
                  <Row>
                    <Col sm={12}>
                      <Input type="textarea" name="concerns" id="concerns" value={concerns} placeholder="Concerns" onChange={this.onChangeValue} />
                    </Col>
                  </Row>
              }
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default CardTutorBox
