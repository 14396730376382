import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Link , withRouter } from "react-router-dom"

import AuthHeader from "./AuthHeader"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

import { apiError, loginUser } from "store/actions"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import LoginForm from "components/forms/formik/authentication/LoginForm";

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(values) {
    this.props.loginUser(values, this.props.history)
  }

  componentDidMount() {
    this.props.apiError("");
  }

  render() {
    let { loading, error } = this.props;
    
    return (
      <React.Fragment>
        <BTNMetaTags title="Account Login" />
        
        <div className="account-pages my-3 pt-sm-3">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <AuthHeader title="BuffTutor Login" subtitle="Sign into your account" />
                  <CardBody className="pt-6">
                    <div className="p-2">
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <LoginForm onSubmit={this.handleValidSubmit}>
                        <div className="mt-3">
                          {
                            loading
                              ?
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                  disabled={true}
                                >
                                  Logging In ... {" "}
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                
                                </button>
                              :
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </button>
                          }
                        </div>
                      </LoginForm>
                      
                      <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1" /> Forgot your
                            password?
                          </Link>
                        </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} BuffTutor Network.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}

const mapStateToProps = state => {
  const { error, loading } = state.Login
  return { error, loading }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)
