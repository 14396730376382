import { LOADING_USER, SAVE_USER, UPDATE_USER, 
          UPDATE_SELECTED_ROLE,
        REMOVE_USER, 
        USER_ERROR} from "./actionTypes";

const initialState = {
  // id: null, //btn ref_id
  // firstName: null,
  // lastName: null,
  // email: null,
  // phone: null,
  // token: null, //jwt for spring app login
  // token_expiration: null,
  // roles: [], //[roles]
  // selectedRole: '', 
  error: "",
  loading: false
}

const userReducer = (state=initialState, action) => {
	let user = {};

  switch(action.type){
    case LOADING_USER:
      return {
        ...state,
        error: "",
        loading: true
      }
    
    case SAVE_USER:
      user = action.payload.hasOwnProperty("user") ? action.payload.user : action.payload;
      
      return {
        ...user,
        error: "",
        loading: false
      }
    
    case UPDATE_USER:
      user = action.payload.hasOwnProperty("user") ? action.payload.user : action.payload;
      
      return {
        ...state,
        ...user,
        error: "",
        loading: false
      }

    case UPDATE_SELECTED_ROLE:{
      return {
        ...state,
        selectedRole: action.payload,
        error: "",
        loading: false
      }
		}

    case USER_ERROR:{
      return {
        ...state,
        error: action.error,
        loading: false
      }
		}
    
    case REMOVE_USER:
      return initialState;
      
    default:
      return state;
  }
}

export default userReducer;
