import { SAVE_USER, UPDATE_USER, 
          UPDATE_SELECTED_ROLE, REMOVE_USER,
        USER_ERROR } from "./actionTypes";

export const saveUser = user => {
    return {
      type: SAVE_USER,
      payload: user,
    }
}

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: user
  }
}

export const updateUserSelectedRole =  role => {
  return {
    type: UPDATE_SELECTED_ROLE,
    payload: role
  }
}

export const removeUser = () => {
  return {
    type: REMOVE_USER
  }
}

export const userError = error => {
  return {
    type: USER_ERROR,
    error
  }
}