import { get, post, put, del } from "../helpers/api_helper"
import { GET_LIST_FAILED_JOBS, 
        POST_RESTART_CHARGE_SESSION_JOB,
        POST_RESTART_ALL_CHARGE_SESSION_JOBS,
        POST_RESTART_REQUEST_TUTOR_JOB,
        POST_RESTART_ALL_REQUEST_TUTOR_JOBS } from "../api/urls"

//Spring Batch
export const listFailedJobs = () => {
    return get(GET_LIST_FAILED_JOBS);
}

export const restartChargeSessionBatchJob = jobExecutionId => {
   return post(POST_RESTART_CHARGE_SESSION_JOB + "/" + jobExecutionId);
}

export const restartAllChargedSessionBatchJobs = () => {
    return post(POST_RESTART_ALL_CHARGE_SESSION_JOBS);
 }

 export const restartRequestTutorBatchJob = jobExecutionId => {
    return post(POST_RESTART_REQUEST_TUTOR_JOB + "/" + jobExecutionId);
 }
 
 export const restartAllRequestTutorBatchJobs = () => {
     return post(POST_RESTART_ALL_REQUEST_TUTOR_JOBS);
  }
//Spring Quartz