import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from "react-router-dom";

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

import { submitApiCallAction } from "store/actions"

import FindPreviousStudentForm from 'components/search/FindPreviousStudentForm'
import { ThemeInput, ThemeRadio } from 'theme/elements/styledInputs'

import { getCurrencySymbol } from 'scripts/general'
import { ThemeButton } from 'theme/elements/buttons'
import DateRangeUI from 'components/datePicker/DateRangeUI'
import { formatDateWithMonth } from 'scripts/general'

class AddReturningStudents extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             assignments: [],
             assignmentId: null,
             course: "",
             startDate: "",
             endDate: "",
             error: "",

             redirect: false,
        }
        this.onChangeValue.bind(this)
        this.onChangeDate.bind(this)
        this.onSubmit.bind(this)
    }
    
    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              [name]: value
        });
    }

    onChangeDate = (startDate, endDate) => {
        this.setState({startDate, endDate});
    }

    onSubmit = async () =>{
        let { assignmentId, course, startDate, endDate } = this.state;
        if(assignmentId != null && assignmentId != ""
            && course != null && course != "" && course.length > 2
            && startDate != null && startDate != ""
            && endDate != null && endDate != ""){
            
            let { onSubmitApiCall} = this.props;
            await onSubmitApiCall("addReturningStudent", { assignmentId, course, startDate, endDate })
            this.setState({redirect: true});
        }       
        else{
            this.setState({error: "All Fields Are Required"});
        } 
    }

    render() {
        let { assignments, course, startDate, endDate, error, redirect } = this.state;
        let { apiResponse = {}} = this.props;
        let loading = apiResponse.loading;

        return (
            <Container fluid>
                <BTNMetaTags title="Add Returning Students" />
                
                {
                    redirect && !loading  &&
                        <Redirect to="/" />
                }
                <Row>
                    <Col lg={{offset: 4, size: 4}}>
                        <Card>
                            <CardBody>
                                <CardTitle>Use This Form To Search And Add Returning Students</CardTitle>
                                    <FindPreviousStudentForm onSubmit={foundAssignments => this.setState({assignments: foundAssignments})}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                {
                    assignments.length > 0 &&
                        <>
                            <Row>
                                <Col lg={{offset: 3, size: 6}}>
                                    <Card>
                                        <CardBody> 
                                            <Row>
                                                <Col lg={{size: 8, offset: 2}}>
                                                    <CardTitle>1: Select Previous Assignment</CardTitle>
                                                    <span className="text-danger">If you wish to change the rate, please contact us BEFORE adding your student.</span>
                                                    <br />
                                                    <br />
                                                    {
                                                        assignments.map((assignment, i) =>
                                                            <ThemeRadio 
                                                                key={i}
                                                                id={"assign-"+ i}
                                                                name={"assignmentId"}
                                                                value={assignment.id}
                                                                onChange={this.onChangeValue}
                                                                label={`${assignment.name} - ${assignment.course} @ ${getCurrencySymbol(assignment.currency)}${assignment.rate}/hr`}
                                                            />
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{size: 8, offset: 2}}>
                                                    <CardTitle>2: Enter New Course</CardTitle>
                                                    <ThemeInput 
                                                        id="course"
                                                        name="course"
                                                        value={course}
                                                        placeholder="New Course Name"
                                                        onChange={this.onChangeValue}
                                                    />
                                                    <br />
                                                    <br />  
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{size: 8, offset: 2}} style={{overflowX: "scroll"}}>
                                                    <CardTitle>3: Select Start & End Date 
                                                    <br /> <small>An estimate is fine.</small></CardTitle>
                                                    <br />
                                                    <DateRangeUI onChange={this.onChangeDate}/>

                                                    {
                                                        startDate != "" && 
                                                            <span className="text-info">Dates: {formatDateWithMonth(startDate)} to {formatDateWithMonth(endDate)}</span>
                                                    }
                                                    <br />
                                                    {
                                                        error && <span className="text-danger">{error} <br/></span>
                                                    }
                                                    
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{size: 6, offset: 3}}>
                                                    {
                                                        loading ? 
                                                            <ThemeButton onClick={this.onSubmit} icon={"bx bx-plus"} disabled={true}>Adding...</ThemeButton>
                                                            :
                                                            <ThemeButton onClick={this.onSubmit} icon={"bx bx-plus"}>Add Student</ThemeButton>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                }
            </Container>
        
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(AddReturningStudents)