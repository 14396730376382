import React, { Component } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"

import {
  Button, Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"

import InboxEditor from "./InboxEditor"
import InboxEditorModal from "./InboxEditorModal"

class InboxSidebar extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      modal: false,
      activeTab: "1",
    }
    this.toggleTab = this.toggleTab.bind(this)
    // this.toggleModal.bind(this)
  }

  toggleTab(tab) {

    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  // toggleModal = () => {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal,
  //   }))
  // }

  render() {
    return (
      <>
        <Card className="email-leftbar">
          <Button
            type="button"
            color="danger"
            onClick={this.toggleModal}
            block
          >
            Compose
          </Button>
          <div className="mail-list mt-4">
            <Nav tabs className="nav-tabs-custom" vertical role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggleTab("1")
                  }}
                >
                  <i className="mdi mdi-email-outline me-2"></i> Inbox{" "}
                  <span className="ml-1 float-end">(18)</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggleTab("2")
                  }}
                >
                  <i className="mdi mdi-star-outline me-2"></i>Starred
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggleTab("3")
                  }}
                >
                  <i className="mdi mdi-diamond-stone me-2"></i>Important
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggleTab("4")
                  }}
                >
                  <i className="mdi mdi-file-outline me-2"></i>Draft
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggleTab("5")
                  }}
                >
                  <i className="mdi mdi-email-check-outline me-2"></i>Sent Mail
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "6",
                  })}
                  onClick={() => {
                    this.toggleTab("6")
                  }}
                >
                  <i className="mdi mdi-trash-can-outline me-2"></i>Trash
                </NavLink>
              </NavItem>

            </Nav>

          </div>

          <h6 className="mt-4">Labels</h6>

          <div className="mail-list mt-1">
            <Link to="#">
              <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>
              Theme Support
            </Link>
            <Link to="#">
              <span className="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>
              Freelance
            </Link>
            <Link to="#">
              <span className="mdi mdi-arrow-right-drop-circle text-primary float-end"></span>
              Social
            </Link>
            <Link to="#">
              <span className="mdi mdi-arrow-right-drop-circle text-danger float-end"></span>
              Friends
            </Link>
            <Link to="#">
              <span className="mdi mdi-arrow-right-drop-circle text-success float-end"></span>
              Family
            </Link>
          </div>
        </Card>
        <InboxEditorModal 
            isOpen= {this.state.modal}
            toggleModal={modalState => this.setState({modal: modalState})} 
            reply={false} 
            onSubmit={() => {alert("TODO")}}/>
      </>

    )
  }
}

export default InboxSidebar