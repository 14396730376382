import React, { Component } from "react"
import { connect } from "react-redux"
import { UncontrolledAlert } from "reactstrap"

class BTNAlert extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.alertType &&
          <UncontrolledAlert color={this.props.alertType}>{this.props.message}</UncontrolledAlert> }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ alertsReducer }) => ({
  alertType: alertsReducer.alertType,
  message: alertsReducer.message,
})
export default connect(mapStateToProps)(BTNAlert)
