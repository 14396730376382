/*
 * Add Api Response Data To Component State
*/

export function processApiResponse (apiResponse, state) {
    if(typeof apiResponse == "object" 
        && typeof apiResponse.data == "object"
        && Object.keys(apiResponse.data).length !== 0){

        let apiData = apiResponse.data;
        let newData = {};

        //Check if state contains loaded data
        for (const key in apiData) {
            if(state[key] == undefined || state[key] != apiData[key]){
                newData[key] = apiData[key];
            }
        }
        
        if(Object.keys(newData).length !== 0){
            state = {...state, ...newData};
        }
    }          

    return state;
}