import React, { Component } from "react"
import BTNMetaTags from "components/metatags/BTNMetaTags"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Alert,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import { getTutorProfile } from "../../scripts/profile";

import MiniCard from "../../components/cards/MiniCard"
import NavTabs from "../../components/navTabs/NavTabs"

import TutorReviews from "./profile-details/TutorReviews"
import { ThemeButtonLink } from "theme/elements/buttons";

import { getCurrencySymbol } from "scripts/general";
import MapWithMarkers from "components/maps/MapWithMarkers";
import ThemeSkeleton from "components/skeleton/ThemeSkeleton";
import moment from "moment";
import momentTz from "moment-timezone"
import TutorStarRating from "components/ratings/TutorStarRating";

class TutorProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: "",
      user: {},
      tutorId: props.match.params.tutorId,
      miniCards: [],
    }
  }

  async componentDidMount() {
    let { tutorId } = this.state;
    this.setState({loading: true});

    let response = await getTutorProfile(tutorId);
    
    if(response.hasOwnProperty("error")){
      this.setState({ error: response.error, loading: false });
    }
    else{
      let user = response;
      let miniCards = user.studentsCount >= 3 && user.evaluationsCount >= 3 ? [
                          {
                            title: "Students Assisted",
                            iconClass: "bx-group",
                            text: user.studentsCount.toString(),
                          },
                          { title: "Hours Tutored", iconClass: "bx-hourglass", text: user.hoursCount.toString() },
                          { title: "Reviews", iconClass: "bx-star", text: user.evaluationsCount.toString() },
                        ] 
                      : [];
      this.setState({user, miniCards, loading: false});
    }
  }

  render() {
    const { user, miniCards, loading, error }= this.state;
    let hasGeolocations = user.hasOwnProperty('geolocations') && user.geolocations.length ? true : false;

    const tutorTabs = [{id: 1, title: "Experience", content: <p style={{ whiteSpace: "pre-wrap"}}>{user.userName ? user.experience : <ThemeSkeleton count={5} />}</p>},
                        {id: 2, title: "Courses", content: <p style={{ whiteSpace: "pre-wrap"}}>{user.userName ? user.courses : <ThemeSkeleton count={5} />}</p>},
                      ];

    if (user.hasOwnProperty('references') && user.references.length > 0) {
      tutorTabs.push({id: 3, title: "References - " + user.references.length, content: <TutorReviews reviews={user.references}/>});
    }
    
    let title = user.userName ? `${user.userName}'s Profile` : "Tutor Profile" 

    return (
      <React.Fragment>
        <BTNMetaTags title={title} />
        
        <Container fluid>
          { 
            error 
              ?
                <>
                    <Card>
                      <CardBody>
                        <Alert color="danger"><center>{error}</center></Alert>
                        <center><ThemeButtonLink to="/dashboard">Back To Dashboard</ThemeButtonLink></center>
                      </CardBody>
                    </Card>
                </>
              :
                <Row>
                  <Col xl={{size: 3, offset: 1}} lg={{size: "5"}} md={{size:"5"}} sm="12">
                    <Card className="overflow-hidden">
                      <div className="bg-soft-primary">
                        <Row>
                          <Col xs="12">
                            <div className="text-primary p-3">
                              <h3 className="text-dark float-right">{user.userName} </h3>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0 pr-1 pl-1">
                        <Row>
                          {
                            loading ?
                              <>
                                <Col xs="3" className="mb-4">
                                  <ThemeSkeleton circle={true} width={"15vh"} height="15vh" />
                                </Col>
                                <Col xs={{size: 7, offset: 2}}>                                 
                                  <br /><br />
                                  <ThemeSkeleton count={2} />
                                </Col>
                              </>
                              :
                              <>
                                {
                                  user.avatar &&
                                    <Col xs="3">
                                        <div className="avatar-xl profile-user-wid mb-4">
                                          <img
                                            src={user.avatar}
                                            alt=""
                                            className="img-thumbnail rounded-circle"
                                          />
                                        </div>
                                      </Col>
                                }
                                
                                <Col xs={{size: 7, offset: 2}}>                                 
                                  <br />
                                  <h5 className="font-size-16">
                                    {user.major}<br />
                                    <small className="text-secondary">{user.education}</small>
                                  </h5>
                                  {user.acceptingStudents!= null && user.acceptingStudents != true &&
                                    <>
                                      <span className="badge badge-soft-danger font-size-14">Unavailable</span><br /><br />
                                    </>
                                  }
                                  {user.rating > 0 && <TutorStarRating rating={user.rating} reviewsCount={user.reviewsCount} />}
                                </Col>
                              </>
                          }
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              {
                                loading ?
                                  <>
                                    <tr>
                                      <th scope="row"><i className="bx bx-map-pin font-size-24" /> :</th>
                                      <td><ThemeSkeleton count={1} /></td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><i className="bx bx-time font-size-24" /> :</th>
                                      <td><ThemeSkeleton count={1} /></td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><i className="bx bx-chat font-size-24" /> :</th>
                                      <td><ThemeSkeleton count={1} /></td>
                                    </tr>
                                  </>
                                  :
                                  <>
                                    {user.minRate &&
                                      <>
                                        <tr>
                                          <th scope="row"><i className="bx bx-dollar font-size-24" /> :</th>
                                          <td>{getCurrencySymbol(user.currency)}{user.minRate} - {user.maxRate} / hr</td>
                                        </tr>
                                        <tr>
                                          <th scope="row"><i className="bx bx-dollar-circle font-size-24" /> :</th>
                                          <td>{user.rateDetails}</td>
                                        </tr>
                                      </>
                                    }
                                    {
                                      user.city &&
                                        <tr>
                                          <th scope="row"><i className="bx bx-map-pin font-size-24" /> :</th>
                                            <td>{user.city}, {user.state}
                                            </td>
                                        </tr>
                                    }
                                    {
                                      user.timezone &&
                                        <tr>
                                          <th scope="row"><i className="bx bx-time font-size-24" /> :</th>
                                          <td>Local Time {momentTz().tz(user.timezone).format("LT")}</td>
                                        </tr>
                                    }
                                    {
                                      user.languages &&
                                        <tr>
                                          <th scope="row"><i className="bx bx-chat font-size-24" /> :</th>
                                          <td>Teaches in {user.languages}</td>
                                        </tr>
                                    }
                                    <tr>
                                      <th scope="row"><i className="bx bx-check-circle font-size-24" /> :</th>
                                      <td><b>{user.backgroundCheck ? <span className="text-success">Background Check - {moment(user.backgroundCheck).format("MMM Y") }</span> : <span className="text-danger">Background Check Not Performed</span>}</b></td>
                                    </tr>
                                  </>
                              }
                              
                            </tbody>
                          </Table>
                          <center>
                            {
                              user.online &&
                                  <span className="badge badge font-size-11 badge-info">
                                    Online Tutoring
                                  </span>
                            }
                            {" "}
                            {
                              user.inPerson && 
                                <span className="badge badge font-size-11 badge-success">
                                  In-Person Tutoring
                                </span>
                            }
                          </center>
                        </div>
                      </CardBody>
                    </Card>
                    
                    { (hasGeolocations == true || (user.sessionLocations != null && user.sessionLocations != "") )&&
                      <Card>
                        <CardBody>
                          <CardTitle>Tutor Session Locations</CardTitle>
                          <CardSubtitle className="mb-3">{user.sessionLocations}</CardSubtitle>
                          { hasGeolocations == true && <MapWithMarkers geolocations={user.geolocations} height={"30vh"} /> }
                        </CardBody>
                      </Card>
                    }
                  </Col>

                  <Col xl={{size: 7}} lg="7" md="7">
                    <Row>
                      {
                        miniCards.map((card, key) => (
                            <Col key={"col-" + key} md={{size: 4, offset: 0}} xs={{size: 10, offset: 1}}>
                              <MiniCard
                                title={card.title}
                                text={card.text}
                                icon={card.iconClass}
                                key={"_card_" + key}
                              />
                            </Col>
                        ))
                      }
                    </Row>
                    <Card>
                      <CardBody>
                        <NavTabs key={"tabs-" + user.id} tabs={tutorTabs} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
          }
        </Container>
      </React.Fragment>
    )
  }
}

export default TutorProfile
