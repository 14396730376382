import React, { Component } from 'react'

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { Input } from 'reactstrap';

export default class SignContractForm extends Component {
    onSubmit = values => {
        this.props.onSubmit(values);
    }

    render() {
        let { loading } = this.props;

        let initialValues = {name: "", address: "" }
        const ContractSchema = Yup.object().shape({
                                    name: Yup.string()
                                                .required('Please enter your full legal name'),
                                    address: Yup.string()
                                                .required('Please enter your full address'),
          });

        return (
            <>
                <Formik
                    initialValues={{...initialValues}}
                    validationSchema={ContractSchema}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            <div className="form-group">
                                <Input
                                    name="name"
                                    label="Legal Name"
                                    className="form-control"
                                    placeholder="Your Full Legal Name"
                                    type="text"
                                    // required
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                <ErrorMessage name="name" component="div" className="text-danger" />
                            </div>
                            <div className="form-group">
                                <Input
                                    name="address"
                                    label="Address"
                                    className="form-control"
                                    placeholder="Your Address"
                                    type="textarea"
                                    // required
                                    value={props.values.address}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                <ErrorMessage name="address" component="div" className="text-danger" />
                            </div>
                            <div className="mt-3">
                                <button
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Sign Contract
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>            
            </>
        )
    }
}
