import React, { Component } from 'react'
import moment from "moment"

import { replaceAllLinks } from "scripts/chat";
import { groupSubjectsByParent } from './scripts';
import { Link } from 'react-router-dom';
import { ADMIN_USER_DETAILS } from 'routes/paths';
import { getCurrencySymbol } from 'scripts/general';
export default class TutorRequestDetailsTableAdmin extends Component {
    searchCourseOnGoogle = () =>{
        let { tutorRequest, onSearchGoogle } = this.props;
        onSearchGoogle(tutorRequest);
    }

    render() {
        let { tutorRequest } = this.props
        let subjectParents = [];

        if(tutorRequest.subjects && tutorRequest.subjects.length > 0){
            subjectParents = groupSubjectsByParent(tutorRequest.subjects);
        }

        return (
            <div>
                <table className="table table-striped table-sm table-responsive">
                    <tbody>
                        
                        <tr>
                            <td><b>ID: </b></td>
                            <td>{tutorRequest.id}{tutorRequest.refId && <> / {tutorRequest.refId }</>}</td>
                        </tr>
                        <tr>
                            <td><b>Student: </b></td>
                            <td>
                            <Link to={ADMIN_USER_DETAILS + "/" + tutorRequest.studentRefId} target="_blank" rel="noreferrer">{tutorRequest.studentName}</Link><br />
                                <a href={`https://www.google.com/maps/place/${tutorRequest.city},+${tutorRequest.state}`} target="_blank" rel="noreferrer">{tutorRequest.city}, {tutorRequest.state}</a><br />
                                {
                                    tutorRequest.distance != null &&
                                        <><br /><small>({tutorRequest.distance.toFixed(0)} miles away)</small></>
                                }
                                { tutorRequest.studentTimezone && <><br />{tutorRequest.studentTimezone}</> }
                            </td>
                        </tr>
                        <tr>
                            <td><b>Marketing:</b></td> 
                            <td>{tutorRequest.marketing}</td> 
                        </tr>
                        <tr>
                            <td><b>Courses:</b></td> 
                            <td>
                                <a href={"http://www.google.com/search?q=" + tutorRequest.course.replace(/ /g, "+") + "+" + tutorRequest.school.replace(/ /g, "+")} target="_blank" rel="noreferrer">{tutorRequest.course} @ {tutorRequest.school}</a> {" "}
                                <br />
                                <button className="btn btn-sm btn-outline-info" onClick={this.searchCourseOnGoogle}>Google Search</button>
                                <br />
                                <br />
                                {
                                    subjectParents.map((parent, i) =>
                                        <span key={"parent-"+i}>
                                            <b>{parent.name}</b>: {parent.subjects.map(subject => subject.name).join(", ")}<br />
                                        </span>
                                    )
                                }
                            </td>
                        </tr>
                        <tr>
                            <td><b>Rate: </b></td>
                            <td>{getCurrencySymbol(tutorRequest.currency)}{tutorRequest.minRate} - {tutorRequest.maxRate} / hr</td>
                        </tr>
                        
                        
                        <tr>
                            <td><b>Dates: </b></td>
                            <td>{moment(tutorRequest.startDate).format("MMM Do")} to {moment(tutorRequest.endDate).format("MMM Do YYYY")} *</td>
                        </tr>
                        <tr>
                            <td><b>Preferred Time: </b></td>
                            <td>{tutorRequest.sessionTime && replaceAllLinks(tutorRequest.sessionTime)}</td>
                        </tr>
                        <tr>
                            <td><b>Frequency: </b></td>
                            <td>{tutorRequest.frequency &&replaceAllLinks(tutorRequest.frequency)}</td>
                        </tr>
                        {
                            tutorRequest.concerns &&
                                <tr className="table-warning">
                                    <td colSpan="2">
                                        <b>Concerns: </b><br />
                                        {replaceAllLinks(tutorRequest.concerns)}
                                    </td>
                                </tr>
                            
                        }
                        <tr>
                            <td><b>Type: </b></td>
                            <td>{
                                    tutorRequest.online &&
                                    <span className="badge badge-success" style={{fontSize: "0.75rem"}}>Online</span>
                                }
                                {" "}
                                {
                                    tutorRequest.inPerson &&
                                    <span className="badge badge-info">In-Person</span>
                                }
                                {" "}
                                {
                                    tutorRequest.scholarship &&
                                    <span className="badge badge-warning">Scholarship</span>
                                }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
