import React, { Component } from 'react'
import { connect } from "react-redux"
import PropTypes from 'prop-types'
import moment from 'moment'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { ReverseTransferAction } from './actions'

import { formatCurrencyAmount, formatDateTime } from 'scripts/general'

class PaymentTransfersTable extends Component {
    //Payment Transfers To Tutors
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            
            tableColumns: ["#", "id", "amount", /*"status",*/ "description", "completedAt", "failed", "totalReversed", "createdAt" ],
            processing: false
        }
        
        this.createColumns.bind(this)
        this.createRows.bind(this)

        this.addActionField.bind(this)
        this.formatFields.bind(this)
        this.reverseTransfer.bind(this)
    }
    
    componentDidMount() {
        let { transfers=[], admin=false } = this.props;
        this.createColumns(admin);
        this.createRows(transfers, admin);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { transfers=[], admin=false } = nextProps;
    //     this.createRows(transfers, admin);
    // }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createColumns = (admin) => {
        let { tableColumns } = this.state;

        if(admin){
            tableColumns.push("transferId")
            tableColumns.push("refId");
            tableColumns.push("action");
        }

        let columns = createSpecifiedColumns(tableColumns);

        this.setState({columns});
    }

    createRows = (transfers, admin) => {
        let rows = transfers;
        rows = this.formatFields(rows);
        rows = this.addActionField(rows, admin);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        let count = 1
        rows.forEach(row => {
            let currency = row.currency || "usd";

            row["#"] = count;
            
            if(typeof row.createdAt !== undefined){
                row.createdAt = moment(row.createdAt).format('MMM D YYYY');
            }

            if(typeof row.completedAt !== undefined && row.completedAt != null){
                row.completedAt = formatDateTime(row.completedAt);
            }

            if(typeof row.amount !== undefined && typeof row.amount == "number"){
                row.amount = formatCurrencyAmount(row.amount, currency);
            }
            if(typeof row.totalReversed !== undefined && typeof row.totalReversed == "number"){
                row.totalReversed = row.totalReversed > 0 ? formatCurrencyAmount(row.totalReversed , currency) : null;
            }

            count++;
        });
        
        return rows;
    }

    addActionField = (rows, admin) => {
        if(admin){
            rows.forEach(row => {
                row["action"] = <ReverseTransferAction id={row.id} onClick={() => this.reverseTransfer("reverse", row.id)} />        
            });
        }

       return rows;
    }

    reverseTransfer = (action, transferId) =>{
        if(confirm(`Are you sure you want to reverse this transfer WITHOUT deleting a charge?`)){
            alert("Needs Work");
            // let { rows } = this.state;
            // let { onSubmitApiCall } = this.props;

            // this.setState({processing: true});
        }

    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        return (
            <>
                <p>{rows.length} Payments</p>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTransfersTable)


PaymentTransfersTable.protoType = {
    admin: PropTypes.bool,
    transfers: PropTypes.arrayOf(PropTypes.object)
}