import React, { Component } from 'react'
import TutorInfoWindow from './TutorInfoWindow';

export default class Marker extends Component {
  render() {
    let { show, tutor={}, onClick, text } = this.props

    const markerStyle = {
      position: "relative ",
      top: "50%",
      left: "50%",
      width: "5vh",
      height: "5vh",
      textAlign: 'center',
      paddingTop: "0.5vh",
      fontSize: "3vh",
      fontWeight: 'bold',
      backgroundColor: "#fff",
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      borderRadius: "20%",
      userSelect: "none",
      transform: "translate(-50%, -50%)",
      // cursor: () => (onClick ? 'pointer' : 'default'),
      cursor: "pointer",
      hover: {
          zIndex: 1,
      }
    };

    return (
      <>
        <div style={markerStyle} className="text-dark">
          {text}  
        </div>
        {show && <TutorInfoWindow tutor={tutor} onCloseWindow={() => this.props.onCloseWindow()}/>}
      </>
    );
  }
}