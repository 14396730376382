import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment';
import { Link } from 'react-router-dom';

import { ADMIN_TUTOR_APPLICATION_DETAILS } from 'routes/paths';

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { DeclineApplicant, DeleteApplicant } from './actions';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { formatDate, capitalizeWords } from 'scripts/general';

class TutorApplicationsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            tableColumns: ["id", "applicant", "subjects", "education", "dates", "createdAt", "action"],

            processing: false
        }
    }
    
    componentDidMount() {
        let { applications=[]} = this.props;
        let { tableColumns } = this.state;
        
        let columns = createSpecifiedColumns(tableColumns);
        this.setState({columns});

        this.createRows(applications);
    }

    // componentWillReceiveProps(nextProps) {
    //     let { applications=[] } = nextProps;
    //     this.createRows(applications);
    // }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createRows = applications => {
        let rows = applications;

        rows.sort((a,b) =>{
            return moment(b.createdAt) > moment(a.createdAt); //SORT DESC createdAt
        });

        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row.applicant = this.createUserData(row);
            row.subjects = this.createSubjectsData(row);
            row.education = this.createEducationData(row);

            row.dates = formatDate(row.startDate) + " - " + formatDate(row.endDate);
            row.createdAt = formatDate(row.createdAt);
            row["action"] = <>
                                <DeleteApplicant id={row.id} onClick={() => this.triggerAction("delete", row.id)} />
                                <DeclineApplicant id={row.id} onClick={() => this.triggerAction("decline", row.id)} />
                            </>
        });
        
        return rows;
    }

    createUserData = row => {
        let className = "text-danger";

        switch (row.status) {
            case "Hired":
                className = "text-secondary";
                break;
            case "Approved":
                className = "text-warning";
                break;
            case "Submitted":
                className = "text-success";
                break;
            default:
                break;
        }

        return <>
                    <Link to={ADMIN_TUTOR_APPLICATION_DETAILS + "/" + row.id}>{row.name}</Link><br />
                    <small>{row.city}, {row.state}</small><br />
                    { row.status !== null &&
                        <span className={className}>{row.status}</span>
                    }
                </>
    }

    createSubjectsData = row => {
        return <>
                {
                    row.subjects && row.subjects.map((subject) => {return capitalizeWords(subject.name.replace(/_/g, " "))}).join(", ")
                }
        </>
    }

    createEducationData = row => {
        return <>
                {row.major}{" "}
                {
                    row.school &&
                        <>
                            @ {row.school}
                        </>
                }
                <br />
                <small>
                    {
                        row.assessmentResults && row.assessmentResults.map((result, i) =>
                            <span key={i}>
                                {result.name} - {result.grade}%<br />
                            </span>
                        )
                    }
                </small>

        </>
    }

    triggerAction = (action, applicationId) =>{
        if(confirm("Are you sure you want to "+ action +" this application?")){
            let { onSubmitApiCall } = this.props;       

            switch (action) {
                case "delete":
                    onSubmitApiCall("deleteTutorApplication", applicationId);
                    break;
                case "decline":
                    let formData = {tutorApplicantId: applicationId, offered: false};
                    onSubmitApiCall("tutorApplicationOfferPosition", formData);
                    break;
                default:
                    break;
            }
            window.location.reload();
        }
    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        
        return (
            <>
                <p>{rows.length} Applications</p>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />

                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationsTable)
