import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

import LineChart from './chartjs/LineChart'

export default class AnalyticsWeeklyChart extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             datasets: [],
             cumulativeDatasets: [],
             chartData: this.props.chartData || "",
             type: this.props.type || "amount", //e.g. amount, hours, count
             chartTitle: this.props.title || "",
             labels: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53],
        }
    }
    
    componentDidMount() {
        this.processWeeklyChartData();
    }
    
    processWeeklyChartData = () => {
        let { chartData, labels, type } = this.state;
        let datasets = [];
        let cumulativeDatasets = [];
        let years = [];

        let colors = ['rgba(153, 102, 255)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)', 'rgba(255, 99, 132)', 'rgba(255, 159, 64)',
                        //Repeat Colors
                    'rgba(153, 102, 255)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)', 'rgba(255, 99, 132)', 'rgba(255, 159, 64)'];
        
        let backgroundColors = ['rgba(153, 102, 255, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)',
                                    //Repeat Colors
                                'rgba(153, 102, 255, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)'];

        chartData.forEach(dataObject => {
            if(years.indexOf(dataObject.year) == -1){
                years.push(dataObject.year);
            }
        });

        let colorIndex = 0;
        years.forEach(year => {
            let weekData =[];
            let weekCumulativeData =[];

            labels.forEach(week =>{
                weekData[week] = 0;
                weekCumulativeData[week] = 0;
                const matchedData = chartData.filter(data => {
                                            return data.year == year && data.week == week;
                                    })[0];
                if(matchedData && matchedData.hasOwnProperty(type)){
                    weekData[week] = matchedData[type];
                }
                
                weekCumulativeData[week] = week == 0 ? weekData[week] : weekCumulativeData[week -1] + weekData[week];
            })

            const dataset = {
                    label: year,
                    data: weekData,
                    fill: true,
                    lineTension: 0.2,
                    backgroundColor: backgroundColors[colorIndex],
                    borderColor: colors[colorIndex],
                }
            
            const cumulativeDataset = {
                label: year,
                data: weekCumulativeData,
                fill: true,
                lineTension: 0.2,
                backgroundColor: backgroundColors[colorIndex],
                borderColor: colors[colorIndex],
            }
        
            datasets.push(dataset);
            cumulativeDatasets.push(cumulativeDataset);
            colorIndex++;
        });
        
        this.setState({datasets, cumulativeDatasets});
    }

    render() {
        const { datasets, cumulativeDatasets, labels, chartTitle } = this.state
        return (
            <div>
                {
                    datasets.length > 0 &&
                        <Row>
                            <Col sm="6">
                                <LineChart chartTitle={chartTitle} labels={labels} datasets={datasets} />
                            </Col>
                            <Col sm="6">
                                <LineChart chartTitle={chartTitle + " - Cumulative"} labels={labels} datasets={cumulativeDatasets} />
                            </Col>
                        </Row>
                }
            </div>
        )
    }
}


