import React, { Component } from "react"
import moment from "moment"

import BasicModal from "./BasicModal"

import UpdateEndDateForm from "components/forms/assignments/UpdateEndDateForm"

class UpdateEndDateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.toggleModal.bind(this)
    this.onSubmitDate.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal !== this.props.isOpen) {
      this.setState({modal: this.props.isOpen});
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));

    if(this.props.toggleModal != undefined){
      this.props.toggleModal();
    }
  }

  onSubmitDate = () =>{
    if(typeof this.props.toggleModal !== undefined){
      this.props.toggleModal(); //Close Modal
    }
    this.setState({modal: false});
  }

  //Extra <br /> To Create a large Modal to prevent clicking outside boundary when selecting a date
  render() {
    let { assignment } = this.props;

    return (
        <BasicModal isOpen={this.state.modal}
            header={`Update End Date`}
            toggleModal={this.toggleModal}>
          <p>{assignment.name} in {assignment.course}</p>
          <p>Current End Date: {moment(assignment.endDate).format("MMM Do YYYY")}</p>
          <UpdateEndDateForm assignmentId={assignment.id} 
                              endDate={assignment.endDate} 
                              onSubmit={this.onSubmitDate} />
        </BasicModal>
    )
  }
}

export default UpdateEndDateModal;
