import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, UPDATE_PASSWORD, REQUEST_PASSWORD_RESET, RESET_PASSWORD } from "./actionTypes"

//Redux Actions
import { clearAlerts, clearToastAlert, saveUser } from "store/actions"
import { saveAlert, saveToastAlert } from "store/actions";
import { loginSuccess, apiError, requestPasswordResetSuccess, resetPasswordSuccess } from "./actions"

//API Calls
import { loginBTNUser, updateBTNPassword, sendPasswordResetEmail } from "scripts/authentication"
import { processApiError } from "store/api/scripts"

function* loginUser({ payload: { user, history } }) {
  try {

    const response = yield call(loginBTNUser, {
      email: user.email,
      password: user.password,
    });

    const apiUser = response.user;
    yield put(saveUser(apiUser)); //update reducer/store
    localStorage.setItem("btnToken", response.token); //update local storage to "login" the user
    localStorage.setItem("tokenExpiration", response.tokenExpiration);
    
    yield put(loginSuccess());
    history.push("/dashboard");

  } catch (error) {
    let errorMessage = processApiError(error);
    yield put(apiError(errorMessage));
  }
}

function* updatePassword({ payload: { userId, password } }) {
  yield put(clearToastAlert());

  try {

    const response = yield call(updateBTNPassword, { userId, password });
    localStorage.setItem("btnToken", response.token); 
    localStorage.setItem("tokenExpiration", response.tokenExpiration);

    yield put(saveToastAlert("success", response.message));

  } catch (error) {
    let errorMessage = processApiError(error);
    yield put(apiError(errorMessage));
  }
}

function* resetPasswordSaga({ payload: { userId, password, history} }) {
  try {
    yield call(updateBTNPassword, { userId, password });
    yield put(resetPasswordSuccess());
    localStorage.removeItem("tempToken");  
    history.push("/login");

  } catch (error) {
    let errorMessage = processApiError(error);
    yield put(apiError(errorMessage));
  }
}


function* sendPasswordResetEmailSaga({ payload: { email } }) {
  yield put(clearToastAlert());

  try {

    const response = yield call(sendPasswordResetEmail, email);
  
    yield put(saveToastAlert("success", response.message));
    yield put(requestPasswordResetSuccess());
  
  } catch (error) {
    let errorMessage = processApiError(error);
    yield put(apiError(errorMessage));
  }
}

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(UPDATE_PASSWORD, updatePassword)
  
  yield takeLatest(REQUEST_PASSWORD_RESET, sendPasswordResetEmailSaga)
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga)
  
}

export default authSaga
