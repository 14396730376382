import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment'
import { triggerReducerLoadingAction, submitApiCallWithReducerAction,
        resetApiReducerAction, submitApiCallAction } from "store/actions"

import { Alert, CardTitle, Col, Form, FormGroup, Row } from 'reactstrap'
import { ThemeButton } from 'theme/elements/buttons'
import { ThemeDate, ThemeSelect } from 'theme/elements/styledInputs'

import { formatCurrencyAmount } from 'scripts/general'
import { LOAD_TUTOR_SESSIONS, SAVE_RECENT_TUTOR_SESSIONS, TUTOR_SESSION_ERROR } from 'store/tutorSessions/actionTypes'

class ChargeTutorSessionForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             duration: 60,
             durationOptions: [
                {duration: 30, label: "30 min"},
                {duration: 45, label: "45 min"},
                {duration: 60, label: "1 hour"},
                {duration: 75, label: "1 hour & 15 min"},
                {duration: 90, label: "1 hour & 30 min"},
                {duration: 105, label: "1 hour & 45 min"},
                {duration: 120, label: "2 hours"},
                {duration: 135, label: "2 hours & 15 min"},
                {duration: 150, label: "2 hours & 30 min"},
                {duration: 165, label: "2 hours & 45 min"},
                {duration: 180, label: "3 hours"}						
             ],
             outcomeOptions: [
                {outcome: 'Student & Tutor Met As Scheduled', free: 0},
                {outcome: 'Student Cancelled Without 24 Hours Notice', free: 0},    
             ],
             outcome: 'Student & Tutor Met As Scheduled',
             sessionDate: moment().format("YYYY-MM-DD"),
             submitting: false,
             error: "",
        }

        this.calculateTutorEarnings.bind(this);
        this.onChangeValue.bind(this);
        this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { onResetApi } = this.props;
        onResetApi();
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { apiResponse } = this.props;
        let { onReducerLoading, onSubmitApiCallWithReducer, user } = this.props;

        if(apiResponse.success){
            if(typeof this.props.onSubmit !== undefined){
                onReducerLoading(LOAD_TUTOR_SESSIONS);
                onSubmitApiCallWithReducer("getRecentTutorSessionsForTutor", SAVE_RECENT_TUTOR_SESSIONS, user.id, TUTOR_SESSION_ERROR);
                this.props.onSubmit();
            }
        }
    }

    calculateTutorEarnings = () => {
        let { duration, outcomeOptions, outcome } = this.state;
        let { assignment } = this.props;
        
        let tutorPay = 0;
        let selectedOutcome = {};
        
        outcomeOptions.forEach(option =>{
            if(option.outcome == outcome){
                selectedOutcome = option;
            }
        });

        if(!selectedOutcome.free){
            let rate = assignment.rate || 0;
            tutorPay = rate * (duration / 60);
        }

        return tutorPay;
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;

        this.setState({
              ...this.state,
              [name]: value
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        const confirm = window.confirm("Please confirm this charge.");

        if(confirm){
            let { duration, outcome, sessionDate } = this.state;
            let { assignment, onSubmitApiCall } = this.props;
            
            duration = parseInt(duration);
            sessionDate = moment(sessionDate).format();

            let formData = {duration, outcome, sessionDate,
                            assignmentId: assignment.id};
            
            onSubmitApiCall("chargeTutorSession", formData);
        }
    }


    render() {
        let { duration, durationOptions, outcomeOptions, outcome, sessionDate } = this.state;
        let { assignment, apiResponse } = this.props;

        let tutorPay = formatCurrencyAmount(this.calculateTutorEarnings(), assignment.currency);

        return (
            <>
                <Form>
                    {
                        apiResponse.error && 
                            <Alert color="danger">{apiResponse.error}</Alert>
                    }
                    {
                        apiResponse.success && 
                            <Alert color="success">Charge Submitted</Alert>
                    }
                    <Row>
                        <Col lg={12}>
                            <CardTitle className="text-primary">Your Earnings: {tutorPay}</CardTitle>
                            <FormGroup>
                                <ThemeSelect name="duration" id="duration" 
                                            label="Duration Of Tutor Session" 
                                            value={duration}
                                            onChange={this.onChangeValue}>
                                    {
                                        durationOptions.map((option, key) =>
                                            <option key={key} value={option.duration}>{option.label}</option>    
                                        )
                                    }
                                    
                                </ThemeSelect>
                            </FormGroup>
                        </Col>
                        <Col lg={12}>
                            <FormGroup>
                                <ThemeSelect name="outcome" id="outcome" 
                                            label="Outcome Of Tutor Session" 
                                            value={outcome}
                                            onChange={this.onChangeValue}>
                                    {
                                        outcomeOptions.map((option, j) =>
                                            <option key={j} value={option.outcome}>{option.outcome}</option>    
                                        )
                                    }
                                </ThemeSelect>
                            </FormGroup>
                        </Col>
                        <Col lg={12}>
                            <ThemeDate id="sessionDate" name="sessionDate" 
                                        label="Tutor Session Date"
                                        onChange={this.onChangeValue} 
                                        value={sessionDate}
                                        min={moment().add(-7, "days").format("YYYY-MM-DD")}
                                        max={moment().format("YYYY-MM-DD")} />
                        </Col>

                    </Row>
                    
                    <Row>
                        <Col>
                            <div className="mt-3">
                                {
                                    apiResponse.loading ?
                                        <ThemeButton disabled={true} active={false} icon={"bx bx-loader bx-spin"}> Submitting </ThemeButton>
                                    :
                                        <ThemeButton onClick={this.onSubmit}> Submit </ThemeButton>
                                }
                            </div>
                       </Col>
                    </Row>
                </Form>  
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onResetApi: () => dispatch(resetApiReducerAction()),
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChargeTutorSessionForm)