import { LOAD_CART, 
          ADD_ITEM_TO_CART, UPDATE_ITEM_IN_CART,
          REMOVE_ITEM_FROM_CART, 
          SAVE_CART_FROM_SERVER,
          DELETE_CART,
          CART_ERROR
        } from "./actionTypes";

const calculateItemSubtotal = item =>{
  item["subtotal"] = item.price * item.quantity;
  return item;
}

const initialState = {
  items: [
    // { id (objectid), object, type (charge type, e.g. prepayment, backgroundcheck),
    //   name, description, 
    //   price, quantity, 
    //   subtotal, fee, discount, total, (calculated on server)
    //   image, link, metadata: {hours, action (e.g. createMatch)} }
  ],
  subtotal: 0,
  discount: 0,
  fee: 0,
  total: 0,
  currency: "usd",
  error: "",
  loading: false
}

const cartReducer = (state=initialState, action) => {
  let items = state.items;

  switch(action.type){
    case LOAD_CART:
      return {
        ...state,
        error: "",
        loading: true
      }

    case CART_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }

    case ADD_ITEM_TO_CART:
      let item = action.item;
     
      //Remove same item from cart
      items = items.filter(cartItem => {
                return cartItem.id != item.id;
              })
      item = calculateItemSubtotal(item);

      items.push(item);

      return {
        ...state,
        items,
        subtotal: 0,
        discount: 0,
        fee: 0,
        total: 0,
        currency: item.currency || "usd",
        error: "",
        loading: false
      }

    case UPDATE_ITEM_IN_CART:
      let newItem = action.item;
     
      items.forEach(item => {
        if(item.id == newItem.id){
          //item = newItem;
          item = calculateItemSubtotal(newItem);
        }
      })

      return {
        ...state,
        items,
        subtotal: 0,
        discount: 0,
        fee: 0,
        total: 0,
        currency: newItem.currency || "usd",
        error: "",
        loading: false
      }

    case REMOVE_ITEM_FROM_CART:
      let itemId = action.itemId;
      
      items = items.filter(item => {
        return item.id != itemId;
      })

      return {
        ...state,
        items,
        subtotal: 0,
        discount: 0,
        fee: 0,
        total: 0,
        error: "",
        loading: false
      }
    
    case SAVE_CART_FROM_SERVER:
      return{
        ...action.payload.cart,
        error: "",
        loading: false,
      }

    case DELETE_CART:
      return  initialState;
      
    default:
      return state
  }
}

export default cartReducer;