import React, { Component } from "react"
import PropTypes from 'prop-types'
import momentTz from "moment-timezone"

import { Button, Col, Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap"
import { getCurrencySymbol, formatDateWithMonth } from "scripts/general"

class AssignmentModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.toggleModal.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal !== this.props.isOpen) {
      this.setState({modal: this.props.isOpen});
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))

    if(this.props.toggleModal != undefined){
      this.props.toggleModal(this.state.modal);
    }
  }

  render() {
    let { assignment } = this.props;
    let currency = getCurrencySymbol(assignment.currency) || "$";

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.toggleModal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.toggleModal}>
              {assignment.name} {" "}
              {
                assignment.inPerson == true &&
                <span className="badge badge-soft-success">In Person Tutoring</span>
              }
              {" "}
              {
                assignment.online == true &&
                <span className="badge badge-soft-primary">Online Tutoring</span>
              }
              {assignment.city && 
                <small><br />{assignment.city}, {assignment.state} {assignment.timezone && <span className="text-secondary"><br />Local Time {momentTz().tz(assignment.timezone).format("LT")}</span>}</small>}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={{size: 10, offset: 1}}>
                  <p className="mb-4">
                    Email:{" "}
                    <a href={"mailto:" + assignment.email}><span className="text-primary">{assignment.email}</span></a>
                  </p>
                  {
                    assignment.phone && 
                      <p className="mb-4">
                        Phone:{" "}
                        <a href={"sms:" + assignment.phone}><span className="text-primary">{assignment.phone}</span></a>
                      </p>
                  }
                
                  <p className="mb-4">
                    Course:{" "}
                    <span className="text-secondary">
                      {assignment.course}
                      {
                        assignment.school && 
                          <>{" "}@ {assignment.school}</>
                      }
                    </span>
                  </p>
                  <p className="mb-4">
                    {assignment.prepayRate > 0
                        ?
                          <>
                            Prepay Rate: <span className="text-secondary">{currency}{assignment.prepayRate ? assignment.prepayRate.toFixed(2) : ""}/hr</span><br/>
                            Pay-As-You-Go Rate: <span className="text-secondary">{currency}{assignment.rate ? assignment.rate.toFixed(2) : ""}/hr plus processing fees</span>
                          </>
                        :
                          <>
                            Rate:{" "}
                            <span className="text-secondary">{currency}{assignment.rate}/hr</span>
                          </>
                    }
                  </p>
                  <p className="mb-4">
                    Dates:{" "}
                    <span className="text-secondary">{formatDateWithMonth(assignment.startDate)} to {formatDateWithMonth(assignment.endDate)}</span>
                  </p>
                  <p className="mb-2">
                    REF ID: <span className="text-secondary">{assignment.id}</span>
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={()=> {this.setState({modal: false})}}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AssignmentModal;

AssignmentModal.protoType = {
  assignment: PropTypes.object.isRequired,
}