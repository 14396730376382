import React, { Component } from 'react'

import {  Form, Formik } from 'formik';
import { FormGroup, Input, Label } from 'reactstrap';

import { formatDate, getCurrencySymbol } from 'scripts/general'

export default class MarkChargesAsPaidForm extends Component {
    onSubmit = values => {
        if(values.chargeIds != null && values.chargeIds.length == 0){
            alert("Please select charges to update");
        }
        else{
            this.props.onSubmit(values);
        }
    }

    render() {
        let { loading, scholarshipAssignment } = this.props;

        let initialValues = { chargeIds: [] }

        return (
            <>
                <Formik
                    initialValues={{...initialValues}}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            {scholarshipAssignment.scholarshipCharges.map((charge, j) =>
                                <span key={j}>
                                    {
                                        charge.paymentDate == null &&
                                            <FormGroup check inline>
                                                <Input 
                                                    type="checkbox" 
                                                    id={"check-"+j }
                                                    name="chargeIds" 
                                                    value={charge.id}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                                <Label check for={"check-"+j }>
                                                    {`${formatDate(charge.sessionDate)}  -  ${getCurrencySymbol(scholarshipAssignment.studentCurrency)}${charge.total}`}
                                                </Label>
                                            </FormGroup>
                                    }
                                </span>
                            )}
                            <button type="submit" 
                                className="btn btn-sm btn-info" 
                                disabled={loading}>
                                    Mark As Paid
                            </button>
                        </Form>
                    )}
                </Formik>            
            </>
        )
    }
}
