import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap'

import { formatCurrencyAmount } from 'scripts/general'
import { CART_ORDER_CONFIRMATION } from 'routes/paths'

class OrderSummary extends Component {
    render() {
        let { cart: {items, discount, fee, subtotal, total, currency }, confirm=false } = this.props;
        
        //Temp Calculation When Server Data isn't loaded
        if(!confirm && (typeof subtotal == undefined || subtotal == 0)){
            items.forEach(item => {
                subtotal += item.subtotal;
            });
        }

        return (
            <Card>
                <CardBody>
                <CardTitle className="mb-3 h4">Summary</CardTitle>
                <div className="table-responsive">
                    <Table className="table mb-0" style={{tableLayout: "fixed", width: "100%"}}>
                        <tbody>
                            <tr>
                                <td>Sub Total :</td>
                                <td>{formatCurrencyAmount(subtotal, currency) }</td>
                            </tr>
                            {
                                confirm &&
                                    <>
                                        {
                                            discount > 0 &&
                                            <tr>
                                                <td>Discount :</td>
                                                <td>- {formatCurrencyAmount(discount, currency)}</td>
                                            </tr>
                                        }
                                        {
                                            fee > 0 &&
                                            <tr>
                                                <td>Processing Fee :</td>
                                                <td>+ {formatCurrencyAmount(fee, currency)}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th>Total :</th>
                                            <td>{formatCurrencyAmount(total)}</td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </Table>
                    {
                        !confirm && items.length > 0 &&
                        <Row className="mt-4">
                            <Col sm="6">
                                <div className="text-sm-end mt-2 mt-sm-0">
                                    <Link
                                        to={CART_ORDER_CONFIRMATION}
                                        className="btn btn-success btn-sm"
                                    >
                                        <i className="mdi mdi-cart-arrow-right me-1" />{" "}
                                        Checkout{" "}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    }
                </div>
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = ({ cartReducer}) => ({
    cart: cartReducer
  })
  

export default connect(mapStateToProps, null)(OrderSummary)