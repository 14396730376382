import React, { Component } from 'react'
import { connect } from "react-redux"
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Alert, Col, Row } from 'reactstrap'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { cleanUpString } from "scripts/general"

import SearchTutorsAdminForm from 'components/search/SearchTutorsAdminForm'
import BTNKanban from './BTNKanban'
import CardTutorBox from './CardTutorBox'
import SortHeader from './SortHeader'
import { formatCardData } from './scripts'

class SelectTutorsKanban extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         tutorRequest: this.props.tutorRequest || {},
         tutors: [],
         tutorRatesArray: [], //array of student/tutor rates + additional details (e.g concerns)
         boardId: "",
         columns: [{
                id: 1,
                title: "Potential Tutors",
                cards: []
            },
            {
                id: "online",
                title: "Online",
                cards: []
            },
            {
                id: "inPerson",
                title: "In-Person",
                cards: []
            }],
        
        sortOptions: [//{id: 1, key: "btnRank", title: "Recommended"},
                    {id: 2, key: "distanceFromUser", title: "Distance"},
                    {id: 3, key: "timeDifference", title: "Time Difference"},
                    {id: 4, key: "hoursCount", title: "Hours"},              
                    {id: 5, key: "studentsCount", title: "Students"},              
                  ],
        
        //Filter (TODO) & Sort
        filterInPerson: false,
        filterOnline: true,
        filterStatus: true,

        contractError: "",
        processing: false

      }
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
            
            if(apiResponse.success && this.state.processing){
              this.setState({processing: false})
              window.location.reload();
            }
  
            if(apiResponse != {} && prevState != newState){
              this.setState(newState);
              this.formatBoardData(newState.tutors);  
            }
        }
    }

    onTutorSearch = async (e, formData) => {
        e.preventDefault();
        let { onSubmitApiCall } = this.props;
  
        //Clean Up String Fields Before Submit
        for (var key in formData) {
          if (typeof formData[key] == "string") {
            formData[key] = cleanUpString(formData[key]);
          }
        }
  
        onSubmitApiCall("submitTutorSearchAdmin", formData);
    }

    createCardData = tutor => {
        const {tutorRequest: {concerns}} = this.state;
        let cardData = formatCardData(tutor);
        cardData["concerns"] = concerns;

        return cardData;
    }

    formatBoardData = (tutors) => {
        let { columns } = this.state;
        let potentialColumnId = 1;
        let potentialColumn = columns.find(col => {return col.id === potentialColumnId});

        // let potentialCards = potentialColumn.cards;
        let potentialCards = [];

        for (var tutor of tutors) {
            let found = false;
            columns.map(column => {
                column.cards.map(card => {
                    if(card.id == tutor.id && column.id != potentialColumnId){
                        found = true;
                    }
                });
            });
            
            if(!found){
                potentialCards.push(this.createCardData(tutor));
            }
        }

        potentialColumn.cards = potentialCards;

        this.setState({columns, boardId: moment().valueOf()});
    }

    onChangeMatchRates = (tutorId, studentPrepayRate, studentRate, tutorRate, tutorCurrency, concerns = null) => {
        let { tutorRatesArray } = this.state;
        let tutorRates = tutorRatesArray.filter(tutorRate => { return tutorRate.tutorId !== tutorId });
        let newTutorRate = {tutorId, 
                            tutorCurrency, 
                            studentPrepayRate: parseInt(studentPrepayRate),
                            studentRate: parseInt(studentRate), 
                            tutorRate: parseInt(tutorRate),
                            concerns};
    
        tutorRates.push(newTutorRate);
        this.setState({tutorRatesArray: tutorRates});
    }

    onCardRemove = ({board, column, card}) => {
        //TODO: move to end of Potential column
        let columns = board.columns;
        this.setState({columns});
    }

    onCardDragEnd = ({board, card, source, destination}) => {
        let columns = board.columns;
        this.setState({columns});
    }

    submitSelectedTutors = type => {
        let { onSubmitApiCall } = this.props;
        let { columns, tutorRatesArray } = this.state;

        let onlineTutors = columns.find(col => {return col.id === "online"}).cards;
        let inPersonTutors = columns.find(col => {return col.id === "inPerson"}).cards;

        let contractError = "";
    
        if (inPersonTutors.length > 0 || onlineTutors.length > 0) {
          let { tutorRequest } = this.props;
    
          if (tutorRequest.id == null || tutorRequest.id == 0 || tutorRequest.id == "") {
            this.setState({contractError: "Tutor Request Not Selected (no Tutor Request ID found)"});
          }
          else{
            //Format Tutors For POST
            let selectedTutors = [];
    
            for (var inTutor of inPersonTutors) {
              let rates = {};
    
              let rateExists = tutorRatesArray.filter(tRate => tRate.tutorId == inTutor.id);
              if (rateExists.length > 0) {
                rates = rateExists[0];
    
                if (rates.studentRate && rates.tutorRate) {
                  let tutor = {//id: inTutor.id,
                                tutorId: inTutor.id,
                                studentCurrency: tutorRequest.currency,
                                studentRate: rates.studentRate,
                                studentPrepayRate: rates.studentPrepayRate || null,
                                tutorRate: rates.tutorRate,
                                tutorCurrency: rates.tutorCurrency,
                                //studentDeadline: inTutor.studentDeadline,
                                online: false,
                                inPerson: true,
                                concerns: rates.concerns};
    
                  selectedTutors.push(tutor);
                }
                else{
                  error += "Missing Rate: " + inTutor.name + ". ";
                }
              }
              else{
                contractError += "Missing Rate: " + inTutor.name + ". ";
              }
            }
    
            for (var onTutor of onlineTutors) {
              let rates = {};
    
              let rateExists = tutorRatesArray.filter(tRate => tRate.tutorId == onTutor.id);
              if (rateExists.length > 0) {
                rates = rateExists[0];
                if (rates.studentRate && rates.tutorRate) {
                  let tutor = {//id: onTutor.id,
                                tutorId: onTutor.id,
                                studentCurrency: tutorRequest.currency,
                                studentRate: rates.studentRate,
                                studentPrepayRate: rates.studentPrepayRate || null,
                                tutorRate: rates.tutorRate,
                                tutorCurrency: rates.tutorCurrency,
                                //studentDeadline: onTutor.studentDeadline,
                                online: true,
                                inPerson: false,
                                concerns: rates.concerns};
    
                  selectedTutors.push(tutor);
                }
                else{
                  contractError += "Missing Rate: " + onTutor.name + ". ";
                }
              }
              else{
                contractError += "Missing Rate: " + onTutor.name + ". ";
              }
            }
    
            if(contractError == ""){
              if (selectedTutors.length) {
                let potentialTutorsData = {tutorRequestId: tutorRequest.id,
                                            potentialTutors: selectedTutors};
                if(confirm("Confirm Type: " + type)){
                    this.setState({processing: true}, () => {
                        switch (type) {
                            case "contract":
                                onSubmitApiCall("createTutorContracts", potentialTutorsData);    
                                break;
                            case "potential":
                                onSubmitApiCall("createPotentialTutors", potentialTutorsData);    
                            default:
                                break;
                        }
                    });
                }
              }
            }
            else{
              this.setState({contractError});
            }
          }
        }
    }

    render() {
        let { tutorRequest } = this.props;
        let { boardId, columns, tutorRatesArray } = this.state;
        let { tutors, sortOptions } = this.state;

        let disableCreateButton = true;

        columns.map(col => {
            if(col.id != 1 && col.cards.length > 0){
                disableCreateButton = false;
                return;
            }
        });

        let card = !isEmpty(tutors) ? <CardTutorBox rates={tutorRatesArray} onChangeRate={this.onChangeMatchRates} /> : <></>

        return (
            <>
                <div id="search">
                    <SearchTutorsAdminForm
                        onSubmit={this.onTutorSearch}
                        course={tutorRequest.course || ""}
                        zipcode={tutorRequest.city ? tutorRequest.city + ", " + tutorRequest.state : ""}
                        // minRate={tutorRequest.minRate}
                        minRate={10}
                        maxRate={tutorRequest.maxRate > 20 ? tutorRequest.maxRate - 10 : 20}
                        subjects={tutorRequest.subjects || []}
                        />
                </div>
                
                <br />

                {this.state.contractError && <Alert color="danger">{this.state.contractError}</Alert> }

                <Row>
                  <Col sm={{offset: 5, size: 3}} xs={{offset: 3, size: 4}}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.submitSelectedTutors("contract")}
                      disabled={disableCreateButton}
                      >
                      Create Contracts for #{tutorRequest.id}
                    </button>
                  </Col>
                  <Col sm={{offset: 1, size: 3}} xs={{offset: 1, size: 4}}>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => this.submitSelectedTutors("potential")}
                      disabled={disableCreateButton}
                      >
                      Create Potential Tutors #{tutorRequest.id}
                    </button>
                  </Col>
                </Row>

                {!isEmpty(tutors)
                    ? 
                        <React.Fragment>
                            <Row>
                                <h3>{tutors.length} Tutors</h3>
                                {" "}
                                <SortHeader data={tutors} onChangeOption={this.formatBoardData} onChangeOrder={this.formatBoardData} sortOptions={sortOptions} defaultSortBy={2} />
                            </Row>
                            
                            <BTNKanban key={"kanban-" + boardId} columns={columns} card={card} onCardRemove={this.onCardRemove} onCardDragEnd={this.onCardDragEnd} />

                        </React.Fragment>
                    :
                        <Row>
                            <h3>No Tutors Found</h3>
                        </Row>
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectTutorsKanban)
  