import React, { Component } from 'react'
import BTNStarRatings from './BTNStarRatings';

export default class TutorStarRating extends Component {
    render() {
        const { rating=0, reviewsCount=0 } = this.props;

        return (
            <>
                <BTNStarRatings active={false} rating={rating} showFractions={true} /> &nbsp;&nbsp;&nbsp; 
                <br /> 
                <small className="text-secondary">{rating > 0 && <><b>{rating.toFixed(1)}</b> &bull; </>}
                {" "}{reviewsCount} {reviewsCount == 1 ? "Review" : "Reviews"} </small>
            </>
        )
    }
}
