import { ADD_ITEM_TO_CART, UPDATE_ITEM_IN_CART, 
          REMOVE_ITEM_FROM_CART, SAVE_CART_FROM_SERVER,
          DELETE_CART
           } from "./actionTypes"

export const addItemToCart = item => ({
  type: ADD_ITEM_TO_CART,
  item
})

export const updateItemInCart = item => ({
  type: UPDATE_ITEM_IN_CART,
  item
})

export const removeItemFromCart = itemId => ({
  type: REMOVE_ITEM_FROM_CART,
  itemId
})

export const deleteCart = () => ({
  type: DELETE_CART
})
