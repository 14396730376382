import React, { Component } from 'react'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import createProposal from "assets/images/how-it-works/tutors/01-Tutor-Create_Proposal.png";
import chatImage from "assets/images/how-it-works/tutors/02-RequestDetails-TutorView-with_chat.png";
import studentInfo from "assets/images/how-it-works/tutors/03-Tutor-Student_Contact_Info.png";
import chargeSession from "assets/images/how-it-works/tutors/04-Tutor-Charge_Session.png";

import BTNMetaTags from "components/metatags/BTNMetaTags"
import MarketHowItWorks from './MarketHowItWorks';

export default class MarketHowItWorksTutor extends Component {
    render() {
        let steps = [{id: 1, title: "Create A Proposal", image: createProposal, desc: "Review tutor details and submit a proposal, if you can help out. A match has NOT been created yet!  The student will receive proposals from multiple tutors to review. Your earnings will be the student rate that you input minus our processing fee. Use the included calculator to determine your net earnings."},
                    {id: 2, title: "Chat With Student", image: chatImage, desc: "Our Chat application allows you to further discuss the student's academic needs and address any concerns either party may have. Click on the 'Request Details' button for a summary of the tutor request and your submitted proposal.  Click on the user avatar for more information about the student."},
                    {id: 3, title: "Student Creates A Match", image: studentInfo, desc: "If the student decides to work with you, he/she will create a match. You will receive an email alerting you to the newly created match. The student will then show up on your Dashboard and you will be able to view the student's contact information (simply click on the student's name). Reach out to the student to schedule the first session and get to work!"},
                    {id: 4, title: "Charge Session", image: chargeSession, desc: "After each session, use our online tools to charge the session.  Simply click the Charge Student button under the student's name on your Dashboard. Your earnings will then be transferred to your Stripe account (or your bank account via Stripe). "},

                    ]
        return (
            <>
                <BTNMetaTags title="How It Works" />
                
                <Row>
                    <Col md={{size: 6, offset: 3}}>
                        <Card>
                            <CardBody>
                                <CardTitle>How To Match With Students</CardTitle>
                                <p>The steps below detail the process of getting new students to work with.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <MarketHowItWorks steps={steps} />
            </>
        )
    }
}
