import React, { Component } from "react"
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap"
import { Link } from "react-router-dom"

import RateSlider from "../slider/RateSlider"
import SubjectsSelect from "components/select/SubjectsSelect"

class SearchTutorsAdminForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        // course: this.props.course || "",
        course: "",
        zipcode: this.props.zipcode || "",
        subjectIds: this.props.subjectIds,
        excludedSubjectIds: this.props.excludedSubjectIds,
        online: true,
        inPerson: true,
        status: true,
        minRate: this.props.minRate || 30,
        maxRate: this.props.maxRate || 60
      }
    }
    this.onChangeCheck.bind(this)
    this.onChangeValue.bind(this)
  }

  componentDidMount() { 
    let { subjects=[] } = this.props;
    let subjectIds = [];

    if(subjects.length > 0){
      subjects.forEach(subject => {
        subjectIds.push(subject.id);
      });

      this.setState({
          formData: {
            ...this.state.formData,
            subjectIds
          }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.course != this.props.course
          || prevProps.zipcode != this.props.zipcode
          ) {
      this.setState(prevState => ({
          formData: {
            ...this.state.formData,
            course: this.props.course,
            zipcode: this.props.zipcode
          }
      }));
    }
  }

  onChangeCheck= (event) => {
    let { name } = event.target;
    this.setState(prevState => ({
        formData: {
          ...this.state.formData,
          [name]: !this.state.formData[name]
        }
    }));
    this.props.onChangeCheck({name, value: !this.state.formData[name]});
  }

  onChangeValue = (event) => {
    const { name, value } = event.target;
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
    });
  }

  onChangeRate = (render, handle, sliderValue) => {
    this.setState({
        formData: {
          ...this.state.formData,
          minRate: sliderValue[0],
          maxRate: sliderValue[1],
        }
    });
  }

  onChangeSubjects = subjectIds => {
    this.setState({
        formData: {
          ...this.state.formData,
          subjectIds
        }
    });
  }

  onChangeExcludedSubjects = excludedSubjectIds => {
    this.setState({
        formData: {
          ...this.state.formData,
          excludedSubjectIds
        }
    });
  }

  render() {
    let { subjects=[] } = this.props;

    return (
      <React.Fragment>
        <Form>
          <Row>
            <Col md={4} sm={6}>
              <FormGroup>
              <Label>Course</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="course"
                  name="course"
                  placeholder="Course"
                  onChange={this.onChangeValue}
                  value={this.state.formData.course}
                  autoFocus={true}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  name="zipcode"
                  placeholder="Zipcode or City, State"
                  onChange={this.onChangeValue}
                  value={this.state.formData.zipcode}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <Label>Subjects</Label>
              <SubjectsSelect selected={subjects} onChange={this.onChangeSubjects} />
            </Col>
            <Col md={4} sm={6}>
              <Label>Excluded Subjects</Label>
              <SubjectsSelect onChange={this.onChangeExcludedSubjects} />
            </Col>
          </Row>
          <Row>
            <Col sm="3" xs="6">
              <FormGroup>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="online"
                    name="online"
                    checked={this.state.formData.online}
                    onChange={this.onChangeCheck}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="online"
                  >
                    Online Tutors
                  </label>
                </div>
              </FormGroup>
            </Col>
            <Col sm="3" xs="6">
              <FormGroup>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="inPerson"
                    name="inPerson"
                    checked={this.state.formData.inPerson}
                    onChange={this.onChangeCheck}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="inPerson"
                  >
                    In Person Tutors
                  </label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="status"
                    name="status"
                    checked={this.state.formData.status}
                    onChange={this.onChangeCheck}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="status"
                  >
                    Accepting Students
                  </label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <br /><br />
          <Row>
            <Col lg={6} sm={6}>
              <Row>
                <Col xs={2}>
                  <Label>Rate</Label>
                </Col>
                <Col xs={10}>
                  <RateSlider onChange={this.onChangeRate} minRate={this.state.formData.minRate} maxRate={this.state.formData.maxRate} />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="6">
              <button
                type="button"
                className="btn btn-success"
                onClick={event => this.props.onSubmit(event, this.state.formData)}
              >
                Search
              </button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

export default SearchTutorsAdminForm
