import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Formik } from 'formik';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import {sitemap_courses, sitemap_high_school_courses} from "constants/subjects"

import { capitalizeWords } from 'scripts/general'

class GoogleCreateServicesForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
           subjects: "",
           services: "",
           suffix: "Tutoring",
        }

        this.generateDefaultServices.bind(this);
        this.createServices.bind(this);
        this.onChangeValue.bind(this);
        this.onSubmit.bind(this);
    }

    componentDidMount() { 
        this.generateDefaultServices();
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              ...this.state,
              [name]: value
        });
    }

    generateDefaultServices = () =>{
        let subjectsArray = [...sitemap_courses, ...sitemap_high_school_courses];
        let subjects = "";

        subjectsArray.forEach(subject => {
            subject = capitalizeWords(subject.replace(/_/g, " "));
            subjects += subject + "\n"
        });

        subjects.trim();

        this.setState({subjects}, () => this.createServices());
    }

    createServices = () => {
        let { subjects, suffix } = this.state;
        let services = "";

        let subjectsArray = subjects.trim().split("\n");
        
        subjectsArray.forEach(subject => {
            services += subject.trim() + " " + suffix + "\n"
        });

        services.trim();
        this.setState({ services });
    }


    onSubmit = async (values) => {
        let { services } = this.state;
        let { locations } = this.props;
        
        services = services.trim();
        let locationNames = locations.map((selectedEntry) => { return selectedEntry.name;})
                                    .join(",");
        values = {...values, services, locationNames};

        this.props.onSubmit(values);
    }

    render() {
        let { apiResponse, locations = [] } = this.props;
        const { subjects, services } = this.state;

        let loading = apiResponse.loading;
        let error = apiResponse.error;

        const defaultValues = {
                                    category: "categories/gcid:tutoring_service",
                                    subjects
                                };

        return (
            <>  
                <Row>
                    <Col sm="6">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <Formik
                            initialValues={defaultValues}
                            onSubmit={this.onSubmit}
                        >
                            { props  => (
                                <Form className="needs-validation">
                                    <Col sm="12">
                                        <label>GMB Service</label>
                                        <FormGroup check>
                                            <Input type="radio" id="service" name="category" value="categories/gcid:tutoring_service" required onClick={() => {this.setState({suffix: "Tutoring"}, () => this.createServices())}} onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="service" check>Tutoring Service</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input type="radio" id="private" name="category" value="categories/gcid:private_tutor" onClick={() => {this.setState({suffix: "Tutors"}, () => this.createServices())}} onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                            <Label for="private" check>Private Tutor</Label>
                                        </FormGroup>
                                    </Col>
                                    <br />
                                    <Row>
                                        <Col sm="6">
                                            <Label>Subjects</Label>
                                            <Input type="textarea" id="subjects" rows={5} name="subjects" value={subjects} placeholder="List Subjects" onChange={event => {this.onChangeValue(event); this.createServices()}} />
                                            <br />
                                            <Label>Services</Label>
                                            <Input type="textarea" id="services" rows={5} name="services" value={services} placeholder="List Services" onChange={ event => {this.onChangeValue(event); }} required />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={6}>
                                            <div className="mt-3">
                                                {
                                                loading
                                                    ?
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                        disabled={true}
                                                    >
                                                        Adding Services ... {" "}
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    
                                                    </button>
                                                    :
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Submit Services
                                                    </button>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                    <Col sm="6">
                        <p><b>Add Services To { locations != null && locations.length } Locations</b></p>
                        {locations.map((selectedEntry, i) => <li key={"li-"+ i}>{selectedEntry.title}</li>)}
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

// const mapDispatchToProps = dispatch => ({
//     onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
// })
  
export default connect(mapStateToProps, null)(GoogleCreateServicesForm)