import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { TUTOR_PROFILE, ADMIN_TUTOR_APPLICATION_DETAILS } from 'routes/paths'

import { cloneUser, saveToastAlert, submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import DefaultDetails from 'components/userDetails/DefaultDetails'
import StudentDetails from 'components/userDetails/StudentDetails'
import TutorDetails from 'components/userDetails/TutorDetails'
import TutorApplicantDetails from 'components/userDetails/TutorApplicantDetails'
import AdAccountDetails from 'components/userDetails/AdAccountDetails'
import UserCard from 'components/cards/user-card/UserCard'

import { ThemeButton, ThemeButtonGroup, ThemeButtonLink } from 'theme/elements/buttons'

import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import { ThemeSoftBadge } from 'theme/elements/badges'

import { formatDateWithMonth, formatOldProficiencyExamName } from 'scripts/general'
import { ThemeSpinner } from 'theme/elements/spinner'

import { postCloneUser } from 'scripts/admin'
import { processApiError } from 'store/api/scripts'
import { ThemeInput } from 'theme/elements/styledInputs'
import CloneUser from 'pages/Authentication/CloneUser'
import { Link } from 'react-router-dom'
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton'

class UserDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            userId: props.match.params.userId || "",
            user: {},
            tutor: {},
            tutorProfile: {},
            student: {},
            tutorApplications: [],
            adAccount: {},
            role: "",
            roleButtons: [],

            newRole: "",
            cloneUser: null
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        let { userId } = this.state;

        onSubmitApiCall("getAdminUserDetails", userId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                let role = newState.user.selectedRole;
                newState.role = role;
                this.setState(newState);
            }
        }
    }

    createRoleButtons = roles => {
        let roleButtons = [];
        if(roles.length > 1){
          if(roles.includes("student")){
            roleButtons.push({color: "info", value: "student", text: "Student"})
          }
    
          if(roles.includes("tutor")){
            roleButtons.push({color: "secondary", value: "tutor", text: "Tutor"})
          }
          
          if(roles.includes("leads")){
            roleButtons.push({color: "success", value: "leads", text: "Lead Generation"})
          }

          if(roles.includes("tutor-applicant")){
            roleButtons.push({color: "warning", value: "tutor-applicant", text: "Tutor Applicant"})
          }

          if(roles.includes("staff")){
    
          }
    
        //   this.setState({ roleButtons });
        }
        return roleButtons;
    }

    switchRole = (event) => {
        const { value } = event.target;
        this.setState({role: value});
    }

    addRole = () => {
        const { userId, newRole } = this.state;
        if(confirm("Confirm Role ADDITION: " + newRole)){
            const { onSubmitApiCall } = this.props;
            const role = newRole.trim();

            let formData =  { role, userId };
            onSubmitApiCall("patchAddRole", formData);
        }
    }

    removeRole = role => {
        if(confirm("Confirm Role REMOVAL: " + role)){
            const { onSubmitApiCall } = this.props;
            const { userId } = this.state;
            let formData =  {role, userId };
            onSubmitApiCall("patchRemoveRole", formData);
        }
    }

    banUser = userId => {
        const { onSubmitApiCall } = this.props;
        let formData =  {role: "banned", userId };
        onSubmitApiCall("patchAddRole", formData);
    }

    loginAsUser = async () => {
        if(confirm("Are you sure you want to switch users?")){
            const { onSaveToastAlert } = this.props;

            try {
                const { userId } = this.state;
                let response = await postCloneUser(userId);
                const cloneUser = <CloneUser user={response.user} token={response.token} tokenExpiration={response.tokenExpiration}/>
                this.setState({cloneUser});

            } catch (error) {
                let errorMessage = processApiError(error);
                onSaveToastAlert("danger", errorMessage);
            }
        }
    }

    convertProfileToAdAccount = userRefId => {
        if(confirm("Are you sure you want to ADD AdAccount to this profile?")){
            let { onSubmitApiCall } = this.props;         
            onSubmitApiCall("convertProfileToAdAccount", userRefId);
        }
    }

    render() {
        const { user, student, tutor, tutorProfile, tutorApplications, adAccount, role, cloneUser, newRole } = this.state;
        const { apiResponse } = this.props;
        const loading = apiResponse.loading;

        let details = <DefaultDetails />;
        let roles = user.roles || [];
        let avatar = tutor.avatar || null;
        user.avatar = avatar;
        let title = user.firstName ?  user.firstName + " " + user.lastName +"'s" : "User";

        let roleButtons = [];
        if(roles.length > 1){
            roleButtons = this.createRoleButtons(roles);
        }

        switch(role) {
            case "student":
                details = <StudentDetails student={student} />;
                break;

            case "leads":
                details = <AdAccountDetails adAccount={adAccount} tutorApplications={tutorApplications} />;
                break;
            case "tutor-applicant":
            case "tutorApplicant":
                details = <TutorApplicantDetails tutorApplications={tutorApplications} />
                break;
            
            case "tutor":
                details = <TutorDetails tutor={tutor} user={user} profile={tutorProfile} />
                break;

            case "admin":
            case "staff":
            default:
                break;
        }

        return (
            <>
                {cloneUser}
                <BTNMetaTags title={`${title} Details`} />
                
                <Container fluid>
                    {loading &&
                        <center><ThemeSpinner /></center>
                    }
                            
                    {
                        roleButtons.length > 1 &&
                        <>  
                            <p>{user.firstName} account has multiple roles. </p>
                                <ThemeButtonGroup onClick={(this.switchRole)} buttons={roleButtons}/>
                            <br />
                        </>
                    }
                    
                    <br />
                    <Row>
                        <Col md="3">
                            {!user.id 
                                ?
                                    <Card>
                                        <CardBody>
                                            <ThemeSkeleton circle={true} />
                                            <ThemeSkeleton />
                                        </CardBody>
                                    </Card>  
                                :
                                    <UserCard user={user}>
                                        {roles.map((role, key) => (
                                            <span key={key}>
                                                <ThemeSoftBadge
                                                    color={(() =>{
                                                        switch (role) {
                                                            case "student":
                                                                return "primary";
                                                        
                                                            case "tutor":
                                                                return "success";
                                                        
                                                            case "tutor-applicant":
                                                            case "tutorApplicant":
                                                                return "warning";
                                                        
                                                            case "banned":
                                                                return "danger";
                                                            
                                                            case "leads":
                                                                return "dark"
                                                        
                                                            case "admin":
                                                            case "staff":
                                                                return "info";
                                                            
                                                            default:
                                                                return "secondary";
                                                        }})() }>
                                                            {role}{" "}<a onClick={() => this.removeRole(role)}><i className="bx bx-x-circle p-1  font-size-8" /></a>
                                                </ThemeSoftBadge> {" "} 
                                            </span>
                                        ))}
                                        <br />
                                        <Row>
                                            <Col xs="8">
                                                <ThemeInput id="newRole" name="newRole" placeholder="Add Role" onChange={event => this.setState({newRole: event.target.value})}/>     
                                            </Col>
                                            <Col xs="4" className="pt-2">
                                                <br/>
                                                <button className="btn btn-sm btn-success" onClick={this.addRole} disabled={newRole == null || newRole == ""} >+</button>       
                                            </Col>
                                        </Row>
                                        <br /><br />
                                        <a onClick={() => {navigator.clipboard.writeText(user.email)}} className="text-muted mb-0"><i className="bx bx-envelope text-info" />{" "}{user.email}</a><br />

                                        <a href={`sms:${user.phone}`}className="text-muted mb-0"><i className="bx bx-phone text-info" />{" "}{user.phone}</a>
                                        <br /><br />
                                        {
                                            roles.includes("tutor") &&
                                                <>
                                                    <ThemeButtonLink 
                                                        to={TUTOR_PROFILE + "/" + user.refId}
                                                        color="info"
                                                        target="_blank">
                                                            View Profile
                                                    </ThemeButtonLink>
                                                    <br />
                                                    {
                                                        tutor.assessmentResults && tutor.assessmentResults.map((result, i)=>
                                                            <span key={"results-"+i}>
                                                                {
                                                                    result.grade != null && 
                                                                        <span className={result.grade >= 80 ? "text-primary" : (result.grade > 70 ? "text-warning" : "text-danger")}>
                                                                            {result.proficiencyExamName != null && result.proficiencyExamName != "" 
                                                                                ?
                                                                                    result.proficiencyExamName
                                                                                :
                                                                                    formatOldProficiencyExamName(result.name)
                                                                            }
                                                                            {" "} - {result.grade}%
                                                                            <br />
                                                                        </span> 
                                                                }
                                                            </span>   
                                                        )
                                                    }
                                                    <br />
                                                </>
                                        }
                                        
                                        <p>
                                            ID: {user.id} / {user.refId}<br />
                                            Marketing: {user.marketing} <br />
                                            Member Since: {formatDateWithMonth(user.createdAt)} <br />
                                            {
                                                tutor.hired &&
                                                    <>    
                                                        Hired At: {tutor.hiredAt} <br />
                                                    </>
                                            }
                                            {
                                                tutor.backgroundCheckDate &&
                                                <>Background Passed: {formatDateWithMonth(tutor.backgroundCheck)}<br /></>
                                            }
                                            {/* <br /><br />
                                            TODO: Pending Background Check: <button className="btn btn-sm btn-success">Clear User</button> */}
                                            <br />
                                            {
                                                tutorApplications.map((application, i) =>
                                                    <li key={"li-"+i}>
                                                        <Link className="pl-2" to={ADMIN_TUTOR_APPLICATION_DETAILS + "/" + application.id}
                                                                target="_blank">
                                                            View Application
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        </p>
                                        <hr />
                                        <button className="btn btn-sm btn-secondary" onClick={this.loginAsUser}>Login As {user.firstName}</button>  
                                        <br /><br />
                                        {
                                            roles.includes("tutor") && !roles.includes("leads") &&
                                                <ThemeButton className="btn btn-sm" color="warning" onClick={() => this.convertProfileToAdAccount(user.refId)}>Create Leads Account</ThemeButton>
                                        }
                                    </UserCard>
                            }
                            
                        </Col>
                        <Col md="9">
                            <Row>
                                <Col>
                                    {!user.id 
                                        ?
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>User Details</CardTitle>
                                                    <ThemeSkeleton />
                                                </CardBody>
                                            </Card>  
                                        :
                                            <>{ details }</>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    // onCloneUser: ({history, user, token})=> dispatch(cloneUser({history, user, token})),
    cloneUser,
    onSaveToastAlert: (toastType, message) => dispatch(saveToastAlert(toastType, message))
})


export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
