import React, { Component } from 'react'
import moment from 'moment';

import ReactDataTable from './dataTableComponents/ReactDataTable';
import { createSpecifiedColumns } from './dataTableComponents/scripts'

import BTNStarRatings from 'components/ratings/BTNStarRatings'

export default class EvaluationsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            evalColumns: ["#", "studentName", "course", "type", "strength", "weakness", "reference", "rating", "createdAt"]
        }
    }
    
    componentDidMount() {
        let { evaluations=[]} = this.props;
        let { evalColumns } = this.state;
        
        let columns = createSpecifiedColumns(evalColumns);
        this.setState({columns});

        this.createRows(evaluations);
    }

    createRows = (evaluations) => {
        let rows = evaluations;
        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        let count = 1
        rows.forEach(row => {
            row["#"] = count;

            if(typeof row.createdAt !== undefined){
                row.createdAt = moment(row.createdAt).format('MMM D YYYY');
            }

            row.rating = <BTNStarRatings rating={row.rating} active={false} />    
            count++;
        });
        
        return rows;
    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};

        return (
            <>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate || true} />
                }
            </>
        )
    }
}
