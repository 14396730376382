import React, { Component } from "react"
import { connect } from "react-redux"

import Select from "react-select"

import { submitApiCallAction, triggerReducerLoadingAction, submitApiCallWithReducerAction } from "store/actions"

import { camelCaseToWords } from "scripts/general";
import { LOAD_SUBJECTS, SAVE_SUBJECTS, SUBJECTS_ERROR } from "store/subjects/actionTypes"

class SubjectsSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || [],
        }
    }

    componentDidMount() {
        let { subjectsReducer } = this.props;
        
        if(subjectsReducer.subjects.length == 0){
            this.loadSubjects();
        }
    }

    loadSubjects = () =>{
        let { onReducerLoading, onSubmitApiCallWithReducer } = this.props;
        onReducerLoading(LOAD_SUBJECTS);
        onSubmitApiCallWithReducer("getSubjects", SAVE_SUBJECTS, null, SUBJECTS_ERROR);
    }

    onChange = selectSubjects => {
        //returns array of ids
        let selected = []
        
        if(selectSubjects != null){
            selectSubjects.forEach(rsEntry => {
                selected.push(rsEntry.value);
            })
        }
        
        this.setState({selected});
        if(this.props.onChange != undefined){
            this.props.onChange(selected);
        }
    }

    getSubjectGroups = subjects => {
        let groups = subjects.map(subject => {
                                    if(subject.parentId == null || subject.id == subject.parentId){
                                        return camelCaseToWords(subject.name);
                                    }
                                })
                            .filter((subject => {
                                return subject != undefined;
                            }));
        
        groups = groups.sort((a,b)=> {return a > b}); //ASC
        return groups;
    }

    groupSelectOptions = subjects => {
        let groups = this.getSubjectGroups(subjects); 

        let selectData = [];

        groups.forEach(group => {
            let groupSubjects = subjects.filter(subject => {
                                    if(subject.parentName == null){
                                        return camelCaseToWords(subject.name) == group;
                                    }
                                    return camelCaseToWords(subject.parentName) == group;
                                }).sort((a,b) => {
                                    return a.name > b.name;
                                });
            let options = [];

            groupSubjects.map(subject => {
                options.push({ label: camelCaseToWords(subject.name), value: subject.id });
            })

            let selectGroup = {
                label: group,
                options
              };
            
              selectData.push(selectGroup);
        });

        return selectData;
    }

    formatSelectedSubjects = selectedSubjects => {
        let selectData = [];
        selectedSubjects.forEach(subject =>{
            selectData.push({ label: camelCaseToWords(subject.name), value: subject.id });
        })
        return selectData;
    }

    render() {
        const { subjectsReducer, placeholder = "Type In Course...", selected=[]} = this.props;
        let subjects = subjectsReducer.subjects;
        const subjectsSelectArray = subjects.length  > 0 ? this.groupSelectOptions(subjects) : [];
        const selectedSubjectsArray = selected.length > 0 ? this.formatSelectedSubjects(selected) : [];

        return (
            <>
                {
                    subjectsSelectArray.length > 0 &&
                        <Select options={subjectsSelectArray}
                            isMulti={true}  
                            hideSelectedOptions={true}
                            defaultValue={selectedSubjectsArray}
                            placeholder={placeholder}
                            onChange={this.onChange} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, subjectsReducer }) => ({
    apiResponse: apiResponseReducer,
    subjectsReducer
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(SubjectsSelect)