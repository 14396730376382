import React, { Component } from "react"

import Answer from "./Answer"

class Question extends Component {

  render() {
    let q = this.props.question || {};
    let answers = q.answers || [];
    var question = q.question;
    //question = question.replace(/\\/g, "\\\\");
    //question = question.replace(/\)/g, "\\)");

    return (
      <React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: question }}></div>
        {
          q.image &&
          <img src={q.image} />
        }
        <br />
        <br />
        {
          answers.map((answer, k) =>
            <div key={k} className={`custom-control custom-radio ${answer.isCorrectAnswer ? "custom-radio-success" : "custom-radio-primary" } mb-3`} >
              <input
                type="radio"
                id={answer.id}
                name={q.id}
                value={answer.id}
                className="custom-control-input"
                defaultChecked={q.selectedAnswerId == answer.id}
                onChange={ this.props.onChange }
              />
              <label
                className="custom-control-label"
                htmlFor={answer.id}
              >
                <Answer id={answer.id} 
                        answer={answer.value} 
                        image={answer.image} 
                        isCorrectAnswer={answer.isCorrectAnswer ? true: false} 
                />
              </label>
            </div>
          )
        }
        {/* <small><a>Report Problem</a></small> */}
      </React.Fragment>
    )
  }
}

export default Question
