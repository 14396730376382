import React, { Component } from "react"
import { connect } from "react-redux"

import { withRouter } from "react-router-dom"

import { cloneUser } from "store/auth/login/actions"

class CloneUser extends Component {
  /** 
   * Redirect To Dashboard
   */
  componentDidMount = () => {
    // emit the event
    this.props.cloneUser({history: this.props.history,
                          user: this.props.user,
                          token: this.props.token,
                          tokenExpiration: this.props.tokenExpiration});
  }

  render() {
    return <></>
  }
}

export default withRouter(connect(null, { cloneUser })(CloneUser))
