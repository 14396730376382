import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { formatDate } from 'scripts/general';
import { ADMIN_TUTOR_APPLICATION_DETAILS } from 'routes/paths'

export default class TutorApplicantDetails extends Component {
    render() {
        let { tutorApplications=[] } = this.props;

        return (
            <div>
                <p>Applicant Details</p>
                {
                    tutorApplications.map((application, i) =>
                        <>
                            <li>ID: {application.id}, Submitted: {formatDate(application.createdAt)} 
                                <Link className="pl-2" to={ADMIN_TUTOR_APPLICATION_DETAILS + "/" + application.id}
                                        target="_blank">
                                    View Application
                                </Link>
                            </li>
                        </>
                    )
                }
                
            </div>
        )
    }
}
