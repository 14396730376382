import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { PROFICIENCY_EXAM_TAKE_ASSESSMENT } from 'routes/paths';

import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { ThemeCheckBox } from 'theme/elements/styledInputs';
import { ThemeSpinner } from 'theme/elements/spinner';
import { formatOldProficiencyExamName } from 'scripts/general';

class ExamList extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            examList: [],
            selectedExams: [],
            userAssessments: [],
            selectedAssessment: "",
            results: [],

            createExam: false
        }

        this.onSelectExam.bind(this)
        this.onCreateAssessments.bind(this)
    }
    
    componentDidMount() {
        const { onSubmitApiCall, user } = this.props;
        onSubmitApiCall("loadTutorProficiencyExams");
        onSubmitApiCall("getUserAssessments", user.id);
        onSubmitApiCall("getUserAssessmentGrades", user.id);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;

        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(apiResponse.success && this.state.createExam){
                    window.location.reload();
                }
            }
        }
    }

    onSelectExam = (event) => {
        const {name, value} = event.target;
        let selectedExams = this.state.selectedExams;

        if (event.target.checked){
            if(!selectedExams.includes(value)){
                selectedExams.push(value);
            }
        }
        else{
            var index = selectedExams.indexOf(value);
            if (index >= 0) {
                selectedExams.splice( index, 1 );
            }
        }

        this.setState({ selectedExams });
    }
    
    onCreateAssessments = async (event) => {
        event.preventDefault();
        const { onSubmitApiCall } = this.props;

        this.setState({createExam: true});
        var { selectedExams } = this.state;
        onSubmitApiCall("createAssessments", selectedExams);
    }
    
    render() {
        var { examList,  userAssessments, results, createExam } = this.state;
        const { apiResponse } = this.props;
        const loading = apiResponse.loading;

        return (
            <>
                <BTNMetaTags title="Proficiency Exams" />
                
                <Row>
                    <Col md={{size: 8, offset: 2}}>
                        <Card>
                            <CardBody>
                                <CardTitle>Proficiency Exams Instructions</CardTitle>
                                <p>This is a proficiency test so please DO NOT GUESS.</p> 
                                <p><b>For tutor applicants</b>, you may submit each exam <b>ONCE</b>, all other attempts will be disregarded.</p>

                                <p>You may use other resources, e.g. textbooks, during the exam. You may use the internet to quickly look up equations or definitions, but do not search the entire question for an answer.</p>

                                <p>For each problem, recognize the underlying concepts covered in the question, consider what students will likely struggle with and what you would do to assist students grasp the concepts or tricks of the problem.</p>
                                
                                <p className="text-primary" style={{fontWeight: "bold"}}>Click Save & Exit to save your progress.  You will have 5 days to access & complete the exam.</p>
                                
                                <p><b>For Math Exams</b>: <br />
                                    Do NOT use a calculator, unless absolutely necessary. <br />
                                    Math equations and formulas may take a few moments to load. </p>

                                <p><b className="text-danger">Please Report Errors!</b> If there is an error in a question statement, equations or grading, please notify us via e-mail. Include the question number and a brief description of the issue.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={{size: 8, offset: 2}}> 
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <CardTitle>Proficiency Exam List</CardTitle>
                                        <p>Select Proficiency Exam(s) You Wish To Take: </p>
                                        {
                                            loading &&
                                                <center><ThemeSpinner /></center>
                                        }
                                        {
                                            examList.map((exam, i) => 
                                            <ThemeCheckBox 
                                                    key={i}
                                                    color="primary"
                                                    id={exam.id}
                                                    name="selectedExams[]"
                                                    value={exam.id}
                                                    checked={this.state.selectedExams.includes(exam.id)}
                                                    onChange={(this.onSelectExam)}
                                                    label={ exam.name }
                                                />
                                            )
                                        }
                                        <br />
                                
                                        {
                                            createExam && loading ?
                                                    <Button disabled={true}>Creating...</Button>
                                                :
                                                    <Button onClick={this.onCreateAssessments}>Create Exams</Button>
                                        }
                                    </Col>
                                    {
                                        results.length > 0 &&
                                        <Col md="6">
                                            <CardTitle>Exam Results</CardTitle>
                                            <p>To Tutor A Course, At Least An <span className="text-success"><b>80%</b></span> Is Required</p>
                                            {
                                                results.map((result, j) =>
                                                    <li key={j}>{result.proficiencyExamName != null && result.proficiencyExamName != "" ? result.proficiencyExamName : formatOldProficiencyExamName(result.name)}
                                                     {" "} - {result.grade}%</li>
                                                )
                                            }
                                        </Col>
                                    }
                                </Row>

                                <br />
                                <hr />
                                
                                {
                                    userAssessments.length > 0 &&
                                        userAssessments.map((assess, j) =>
                                            
                                            <div key={j}>
                                                {assess.name} {" "}
                                                    <Link to={PROFICIENCY_EXAM_TAKE_ASSESSMENT + "/" + assess.id} target="_blank">
                                                        Take Assessment
                                                    </Link>
                                            </div>
                                        )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}


const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ExamList)