import { post } from "../helpers/api_helper"
import { POST_RETRIEVE_TUTOR_CONVERSION_RATES, 
    POST_RETRIEVE_THREAD_CONVERSION_RATES, 
    POST_RETRIEVE_BOUNCE_RATES,
    POST_RETRIEVE_TUTOR_ASSIGNMENTS_METRICS,
    POST_RETRIEVE_STUDENT_RETURN_RATES,
    POST_RETRIEVE_RECENT_TUTOR_ACTIVITIES} from "../api/urls"


export const retrieveConversionRatesAllTutors = (formData = {}) => {
    return post(POST_RETRIEVE_TUTOR_CONVERSION_RATES, formData);
}

export const retrieveConversionRatesByTutorId = (formData = {}) => {
    return post(POST_RETRIEVE_TUTOR_CONVERSION_RATES + "/" + formData.tutorId, formData);
}

export const retrieveThreadConversionRatesAllTutors = (formData = {}) => {
    return post(POST_RETRIEVE_THREAD_CONVERSION_RATES, formData);
}

export const retrieveThreadConversionRatesByTutorId = (formData = {}) => {
    return post(POST_RETRIEVE_THREAD_CONVERSION_RATES + "/" + formData.tutorId, formData);
}

export const retrieveBounceRatesAllTutors = (formData = {}) => {
    return post(POST_RETRIEVE_BOUNCE_RATES, formData);
}

export const retrieveBounceRatesByTutorId = (formData = {}) => {
        return post(POST_RETRIEVE_BOUNCE_RATES + "/" + formData.tutorId, formData);
}

export const retrieveAssignmentsMetricsAllTutors = (formData = {}) => {
    return post(POST_RETRIEVE_TUTOR_ASSIGNMENTS_METRICS, formData);
}

export const retrieveAssignmentsMetricsByTutorId = (formData = {}) => {
        return post(POST_RETRIEVE_TUTOR_ASSIGNMENTS_METRICS + "/" + formData.tutorId, formData);
}

export const retrieveReturnRatesAllTutors = () => {
    return post(POST_RETRIEVE_STUDENT_RETURN_RATES);
}

export const retrieveReturnRatesByTutorId = (formData = {}) => {
        return post(POST_RETRIEVE_STUDENT_RETURN_RATES + "/" + formData.tutorId, formData);
}

export const retrieveRecentTutorActivities = (formData = {}) => {
    return post(POST_RETRIEVE_RECENT_TUTOR_ACTIVITIES, formData);
}

export const retrieveRecentTutorActivitiesByTutorId = (formData = {}) => {
    return post(POST_RETRIEVE_RECENT_TUTOR_ACTIVITIES + "/" + formData.tutorId, formData);
}