import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { CART_ORDER_SUCCESS, CART_VIEW_DETAILS } from 'routes/paths'

import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

import ShoppingCart from 'components/cart/ShoppingCart'
import OrderSummary from 'components/cart/OrderSummary'
import PaymentOptions from 'components/cart/PaymentOptions'

import { ThemeSpinner } from 'theme/elements/spinner'

import { deleteCart } from 'store/actions'
import { triggerReducerLoadingAction, submitApiCallAction, submitApiCallWithReducerAction } from 'store/actions'
import { ThemeButtonLink } from 'theme/elements/buttons'
import { CART_ERROR, LOAD_CART, SAVE_CART_FROM_SERVER } from 'store/cart/actionTypes'

class OrderConfirmation extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             success: false,
             redirectUrl: CART_ORDER_SUCCESS
        }
        this.onPaymentSuccess.bind(this);
    }
    
    componentDidMount() {
        const { onReducerLoading, onSubmitApiCallWithReducer, cart } = this.props;
        onReducerLoading(LOAD_CART);
        onSubmitApiCallWithReducer("calculateCartTotal", SAVE_CART_FROM_SERVER, cart, CART_ERROR);
    }

    onPaymentSuccess = ({error, results}) => {
        let { onDeleteCart, onSubmitApiCall, cart:{items} } = this.props;

        if(results != null && results != ""){
            if(Array.isArray(items)){
                items.forEach(item => {
                    let metadata = item.metadata;
    
                    if(metadata != null && typeof metadata == "object" && metadata.hasOwnProperty("method")){
                        let method = metadata.method;
                        let data = metadata.data;
    
                        onSubmitApiCall(method, data);
                    }
                });
            }
            //TODO: ADD GOOGLE ANALYTICS payment event
            //Ref: https://developers.google.com/analytics/devguides/collection/ga4/set-up-ecommerce
            
            onDeleteCart();
            this.setState({ success: true });
        }
        else{
            //console.log("error", error);
        }
    }

    render() {
        let { cart:{ loading }, user } = this.props;
        let { success, redirectUrl } = this.state;

        if(success){
            return  <Redirect to={redirectUrl} />
        }
            
        return (
            <>
                <BTNMetaTags title="Order Confirmation" />
                
                <Container fluid={true}>
                    {
                        loading 
                            ?
                                <Card>
                                    <CardBody>
                                        <CardTitle>Shopping Cart</CardTitle>
                                        <center><ThemeSpinner /></center>
                                    </CardBody>
                                </Card>
                                
                            :
                                
                                <>
                                    <Row>
                                        <Col>
                                            <ShoppingCart editable={false} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4" sm="6">
                                            <OrderSummary confirm={true} />
                                            <ThemeButtonLink to={CART_VIEW_DETAILS}>Edit Cart</ThemeButtonLink>
                                        </Col>
                                        <Col lg="8" sm="12" >
                                            <PaymentOptions userId={user.id} onSubmit={this.onPaymentSuccess}/>
                                        </Col>
                                    </Row>
                                </>
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ cartReducer, userReducer}) => ({
    cart: cartReducer,
    user: userReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
    onDeleteCart: () => dispatch(deleteCart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation)