export const POST_TUTOR_REQUEST = "POST_TUTOR_REQUEST"
export const POST_TUTOR_REQUEST_SUCCESS = "POST_TUTOR_REQUEST_SUCCESS"
export const POST_TUTOR_REQUEST_FAIL = "POST_TUTOR_REQUEST_FAIL"
export const POST_TUTOR_REQUEST_VALIDATION_FAIL = "POST_TUTOR_REQUEST_VALIDATION_FAIL"

//General API
export const LOAD_TUTOR_REQUESTS = "LOAD_TUTOR_REQUESTS"
export const SAVE_TUTOR_REQUESTS_SUMMARY = "SAVE_TUTOR_REQUESTS_SUMMARY"
export const SAVE_TUTOR_REQUESTS = "SAVE_TUTOR_REQUESTS"
export const SAVE_TUTOR_REQUEST = "SAVE_TUTOR_REQUEST"

//Admin
export const GET_TUTOR_REQUESTS_UNADDRESSED = "GET_TUTOR_REQUESTS_UNADDRESSED"
export const GET_TUTOR_REQUESTS_UNADDRESSED_FAIL = "GET_TUTOR_REQUESTS_UNADDRESSED_FAIL"
export const SAVE_TUTOR_REQUESTS_UNADDRESSED = "SAVE_TUTOR_REQUESTS_UNADDRESSED"

export const POST_CREATE_TUTOR_CONTRACTS = "POST_CREATE_TUTOR_CONTRACTS"

export const TUTOR_REQUEST_ERROR = "TUTOR_REQUEST_ERROR"