import { all, fork } from "redux-saga/effects"

//public
//import AccountSaga from "./auth/register-THEME/saga"
import AuthSaga from "./auth/login/saga"
//import ForgetSaga from "./auth/forgetpwd-THEME/saga"
//import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

//BTN
import apiSaga from "./api/saga"
// import userSaga from "./user/saga"
import alertsSaga from "./alerts/saga"
import tutorRequestSaga from "./tutorRequests/saga"

export default function* rootSaga() {
  yield all([
    //public
    //fork(AccountSaga),
    fork(AuthSaga),
    //fork(ForgetSaga),
    //fork(ProfileSaga),
    fork(LayoutSaga),

    //BTN
    fork(apiSaga),
    // fork(userSaga),
    fork(alertsSaga),
    fork(tutorRequestSaga),
  ])
}
