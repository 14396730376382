import React, { Component } from 'react'
import { CustomInput, FormGroup } from 'reactstrap';

const themeColors = [{"color": "primary", code: "#556ee6"},
                        {"color": "secondary", code: "#74788d"},
                        {"color": "dark", code: "#343a40"},
                        {"color": "success", code: "#34c38f"},
                        {"color": "info", code: "#50a5f1"},
                        {"color": "warning", code: "#f1b44c"},
                        {"color": "danger", code: "#f46a6a"},];

export default class CustomReactSwitch extends Component {
    constructor(props) {
        super(props)
    
        this.state = { 
            checked: false 
        };
        this.handleChange.bind(this);
        this.translateColors.bind(this);
    }
    
    componentDidMount() {
        let { checked=false, onColor=null, offColor=null  } = this.props;
        onColor = this.translateColors(onColor);
        offColor = this.translateColors(offColor);

        this.setState({ checked, onColor, offColor });
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevState.checked != this.props.checked){
            this.setState({checked: this.props.checked});
        }
    }
    

    handleChange = checked => {
        if(this.props.onChange != undefined){
            this.props.onChange(checked);
        }

        this.setState({ checked });
    }

    translateColors = (colorName) =>{
        if(colorName == null){
            return null;
        }

        colorName = colorName.toString().toLowerCase();
        themeColors.forEach(themeColor => {
            if(themeColor.color == colorName){
                colorName = themeColor.code;
            }
        });
       
        return colorName;
    }
  
    render() {
        let {checked, onColor, offColor } = this.state;
        let {id="switch", required=false, labelAfterSwitch=false, disabled=false, 
                yesLabel=null, noLabel=null} = this.props;
        
        let switchProps = {id, disabled, required};

        let label = yesLabel ? (checked ? <span style={{color: onColor}}>{yesLabel}</span> : <span style={{color: offColor}}>{noLabel}</span>) : null;
        
        return (
            <label>
                {
                    !labelAfterSwitch &&
                        <>
                            {label} {" "}
                        </>
                }

                <FormGroup switch>
                    <CustomInput
                        type="switch"
                        role="switch"
                        checked={checked}
                        onChange={() => this.handleChange(!checked)}
                        {...switchProps}
                    />
                </FormGroup>
                
                {
                    labelAfterSwitch &&
                        label
                }
            </label>
        )
    }
}


