import React, { Component } from 'react'

import "../index.js"

import Step from './Step.js';

export default class MultiStepForm extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      formData: this.props.formData || {},
    }
  }

  onChangeValue = (event) => {
    const { name, value } = event.target;
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
    });
  }

  onChangeChecked = (event) => {
    const { name, checked } = event.target;
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: checked
        }
    });
  }

  onChangeDateRange = (startDate, endDate) => {
    this.setState({
        formData: {
          ...this.state.formData,
          startDate,
          endDate
        }
    });
  }

  onChangeRateRange = (render, handle, sliderValue) => {
    this.setState({
        formData: {
          ...this.state.formData,
          minRate: sliderValue[0],
          maxRate: sliderValue[1],
        }
    });
  }

  onChangeSlider = (name, value) => {
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
    });
  }

  onChangeStarRating = (name, rating) => {
    this.setState({
        formData: {
          ...this.state.formData,
          [name]: rating
        }
    });
  }

  onSubmit = event => {
    event.preventDefault();
    let { formData } = this.state;

    this.props.onSubmit(formData);
  }
  
  render() {
    let { steps=[], data } = this.props;

    return (
      <>
          {steps.map((step, i) =>
            <Step key={"step-" + i} 
                step={step} 
                first={i == 0 } 
                last={i == (steps.length - 1)}
                data={data}
                // formData={this.state.formData}
                onSubmit={this.onSubmit}
                onChange={this.onChangeValue}
                onChangeChecked={this.onChangeChecked}
                onChangeDateRange={this.onChangeDateRange}
                onChangeRateRange={this.onChangeRateRange}
                onChangeSlider={this.onChangeSlider}
                onChangeStarRating={this.onChangeStarRating}
                // validationErrors={validationErrors}
                // invalidFields={invalidFields}
            />
          )} 
      </>
    )
  }
}

