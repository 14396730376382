import { takeEvery, put, call } from "redux-saga/effects"

import { POST_TUTOR_REQUEST } from "./actionTypes";
import {saveAlert, clearAlerts,
          saveToastAlert, clearToastAlert } from "../actions";

import { saveTutorRequestSuccess, saveTutorRequestFail,
          tutorRequestValidationFail,
          } from "./actions";

//API CALLS
import { submitTutorRequest, loadTutorRequestsForStudent } from "../../scripts/tutorRequests"

function* saveTutorRequestSaga({ tutorRequest }){
  //yield put(clearToastAlert());
  try {
    const response = yield call(submitTutorRequest, tutorRequest);
    yield put(saveTutorRequestSuccess(response))
  } catch (error) {
    if (error.response) {
        if(error.response.status == 401){
            //Unauthorized
            yield put(saveTutorRequestFail("Unauthorized. You do not have permission to perform this operation."));
        }
        if(error.response.status == 400 && error.response.data){
            //Bad Request : Failed Validation
            var errors = error.response.data.errors;
            var errorMesssage = "The following fields have errors: ";
            let invalidFields = [];
            let validationErrors = [];

            for (var i = 0; i < errors.length; i++) {
              invalidFields.push(errors[i].field);
              validationErrors.push({
                  field: errors[i].field,
                  rejectedValue: errors[i].rejectedValue,
                  message: errors[i].defaultMessage
              })
            }

            errorMesssage += invalidFields.join(", ");
            yield put(tutorRequestValidationFail(errorMesssage, validationErrors, invalidFields));
        }
    }
    else{
        yield put(saveTutorRequestFail(error.message))
    }
  }
}

function* tutorRequestSaga() {
  yield takeEvery(POST_TUTOR_REQUEST, saveTutorRequestSaga)
}

export default tutorRequestSaga
