import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Formik } from 'formik';
import { Alert, Col, Input, Row } from 'reactstrap';

class GoogleSaveLocationDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
        }
        this.onSubmit.bind(this)
    }

    onSubmit = async (values) => {
        let { locations } = this.props;
        let { locationNames } = values;

        let formattedLocations = [];

        locationNames.forEach(locationName =>{
            let location = locations.filter(loc => { return loc.name == locationName; })[0];
            if(location.name == locationName){
                let { name, city, state, accountId, accountName, postalCode } = location;
                formattedLocations.push({ name, city, state, accountId, accountName, postalCode });
            }
        })
        this.props.onSubmit({locations: formattedLocations});
    }

    render() {
        let { apiResponse, locations = [] } = this.props;
        
        let loading = apiResponse.loading;
        let error = apiResponse.error;

        let locationNames = [];
        locations.forEach(location =>{
            locationNames.push(location.name);
        })

        const defaultValues = { locationNames };
        
        return (
            <>
                { error && <Alert color="danger">{error}</Alert> }
                <Formik
                    initialValues={defaultValues}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            {
                                locations.length > 0 &&
                                    <Input type="select" 
                                        name="locationNames" 
                                        label="Locations" 
                                        multiple 
                                        required
                                        value={props.values.locationNames}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    >
                                        {
                                            locations.map((location, index) =>
                                                <option key={index} value={location.name}>{location.title}</option>
                                            )
                                        }
                                    </Input>
                            }
                            <Row>
                                <Col sm={6}>
                                    <div className="mt-3">
                                        {
                                        loading
                                            ?
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={true}
                                            >
                                                Saving Locations ... {" "}
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            
                                            </button>
                                            :
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                            >
                                                Save Locations
                                            </button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

// const mapDispatchToProps = dispatch => ({
//     onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
// })
  
export default connect(mapStateToProps, null)(GoogleSaveLocationDetails)