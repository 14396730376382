import React, { Component } from 'react'
import { connect } from 'react-redux'

import { submitApiCallAction } from "store/actions"

class GoogleSubmitSitemapsForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {}
        this.onSubmit.bind(this)
    }

    onSubmit = () => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("submitSitemapsToGoogle");
    }

    render() {
        let { apiResponse } = this.props;

        let loading = apiResponse.loading;

        return (
            <>
                {
                    loading
                        ?
                        <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            disabled={true}
                        >
                            Submitting ... {" "}
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                        
                        </button>
                        :
                        <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            onClick={this.onSubmit}
                        >
                            Submit Sitemaps
                        </button>
                }         
            </>
        )
    }
}


const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSubmitSitemapsForm);
