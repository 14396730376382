import { formatOldProficiencyExamName, getCurrencySymbol } from "scripts/general";

export const sortResults = (key, sortOrder) => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {

        return 0;
      }

      const valueA = (typeof a[key] === 'string')
        ? a[key].toLowerCase() : a[key];
      const valueB = (typeof b[key] === 'string')
        ? b[key].toLowerCase() : b[key];

      let comparison = 0;
      if (valueA < valueB) {
        comparison = 1;
      } else if (valueA > valueB) {
        comparison = -1;
      }

      return (
        (sortOrder !== 'desc') ? (comparison * -1) : comparison
      );
    };
}

const formatProficiencyExams = (proficiencyExamResults = []) =>{
  let resultsArray = [];

  if (proficiencyExamResults.length) {
      for (var result of proficiencyExamResults) {
          if(result.grade != null){
              let exam = result.proficiencyExamName != null && result.proficiencyExamName != "" 
                                                          ?
                                                              result.proficiencyExamName
                                                          :
                                                              formatOldProficiencyExamName(result.name);
                                                              
              resultsArray.push({exam, grade: result.grade + "%"});
          }
      }
  }

  return resultsArray;
}

export const formatCardData = tutor => {
  return {
    id: tutor.id,
    title: tutor.userName,
    description: tutor.major + " - " + tutor.education,
    avatar: tutor.avatar,
    name: tutor.userName,
    refId: tutor.refId,
    experience: tutor.experience || "",
    profExamResults: formatProficiencyExams(tutor.assessmentResults || []),
    city: tutor.city,
    state: tutor. state,
    minRate: tutor.minRate,
    maxRate: tutor.maxRate,
    rateDetails: tutor.rateDetails,
    currency: tutor.currency,
    currencySymbol: getCurrencySymbol(tutor.currency),
    status: tutor.acceptingStudents,
    online: tutor.online,
    inPerson: tutor.inPerson,
    distance: tutor.distanceFromUser,
    searchRank: tutor.searchRank,
    //
    studentRate: 0,
    tutorRate: 0,
    
    //Performance
    hours: tutor.hoursCount ? (tutor.hoursCount == tutor.activeHoursCount ? tutor.hoursCount : tutor.hoursCount + " / " + tutor.activeHoursCount) : null,
    assignments: tutor.studentsCount ? (tutor.studentsCount == tutor.activeStudentsCount ? tutor.studentsCount : tutor.studentsCount + " / " + tutor.activeStudentsCount): null,
    contracts: tutor.tutorContractsCount ? (tutor.tutorContractsCount == tutor.activeTutorContractsCount  ? tutor.tutorContractsCount  : tutor.tutorContractsCount + " / " + tutor.activeTutorContractsCount) : null,
    declinedContracts: tutor.declinedContractsCount ? (tutor.declinedContractsCount == tutor.activeDeclinedContractsCount ? tutor.declinedContractsCount : tutor.declinedContractsCount + "/" + tutor.activeDeclinedContractsCount) : null,
    missedContracts: tutor.missedContractsCount ? (tutor.missedContractsCount == tutor.activeMissedContractsCount ? tutor.missedContractsCount : tutor.missedContractsCount + "/" + tutor.activeMissedContractsCount) : null,
    
    // concerns: concerns
  }
}