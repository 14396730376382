import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Formik } from 'formik';
import { Alert, Col, Input, Row } from 'reactstrap';


class GoogleCreateServiceAreaForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
           
        }

        this.onChangeValue.bind(this);
        this.onSubmit.bind(this);
    }


    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              ...this.state,
              [name]: value
        });
    }


    onSubmit = async (values) => {
        let { location } = this.props;
        
        let geolocations = []
        let geolocationArray = values.geolocations.trim().split("\n");
        
        geolocationArray.forEach(geoloc => {
            geoloc = geoloc.trim()
            if(!geoloc == ""){
                geolocations.push(geoloc);
            }
        });

        values.geolocations = geolocations;
        values = {...values, locationName: location.name};
        this.props.onSubmit(values);
    }

    render() {
        let { apiResponse, location = {} } = this.props;

        let loading = apiResponse.loading;
        let error = apiResponse.error;

        let defaultValues = {}
        if(location.city){
            let geolocations = location.city + ", " + location.state
            defaultValues = { geolocations };
        }

        return (
            <>  
                <p>Add Service Areas To {location.title} in {location.city}, {location.state}</p>
                
                {error ? <Alert color="danger">{error}</Alert> : null}

                <Formik
                    initialValues={defaultValues}
                    onSubmit={this.onSubmit}
                >
                    { props  => (
                        <Form className="needs-validation">
                            <Row>
                                <Col sm="8">
                                    <div className="form-group">
                                        <Input
                                            type="textarea"
                                            name="geolocations"
                                            label="Service Areas (city, state)"
                                            value={props.values.geolocations}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="mt-3">
                                        {
                                        loading
                                            ?
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={true}
                                            >
                                                Adding Service Areas ... {" "}
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            
                                            </button>
                                            :
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                            >
                                                Submit Service Areas
                                            </button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

// const mapDispatchToProps = dispatch => ({
//     onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
// })
  
export default connect(mapStateToProps, null)(GoogleCreateServiceAreaForm)