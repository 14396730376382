import React, { Component } from "react";
import { connect } from "react-redux"
import { size } from "lodash"

import "../Chats.css";

import { CHAT_ERROR, LOAD_CHAT_MESSAGES, SAVE_CHAT_MESSAGES } from "store/chat/actionTypes";

import { submitApiCallAction, saveToastAlert,
          triggerReducerLoadingAction, submitApiCallWithReducerAction,
          resetChatMessages, saveChatMessages } from "store/actions"

import { ThemeSpinner } from "theme/elements/spinner";
import { Alert } from "reactstrap";

import ChatHeader from "./layout/ChatHeader";
import ChatMessage from "./layout/ChatMessage";
import ChatFooter from "./layout/ChatFooter";
import ChatSidebar from "./sidebar/ChatSidebar";
import { checkAllLinkTypes } from "scripts/chat";
// import ChatSearch from "./layout/ChatSearch-UNUSED";

// User  chat detail component - chat with users
class ChatConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsType: "user", // user vs tutorRequest
      selectedChatParticipant: this.props.chat ? this.props.chat.participants[0] : {},
      showSidebar: false,
      chatSearchBox: false,
      showUserInformation: true,
      showLastMedia: true,
      showDocuments: true,
      emojiWidth: window.innerWidth / 16 + "rem",
      
      onlineMembers: [],

    }

    this.messagesEndRef = React.createRef();
    this.channel = null;
    this.pusher = this.props.pusher || null;

    window.onresize = () => {
      let width = window.innerWidth / 16;
      if (width >= 50) width = 50;
      this.setState({ emojiWidth: width + "rem" });
    };
  }

  componentDidMount() { 
    let { threadId, chat=null } = this.props
    let { onResetChatMessages } = this.props;

    onResetChatMessages();
    this.loadThreadMessages(threadId);

    if(this.pusher != null && chat != null && threadId != null){
      this.createPusherThreadChannel();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props.chatsReducer;
    const { prevMessages } = prevProps.chatsReducer;

    if (size(messages) !== size(prevMessages)) {
      this.scrollToBottom()
    }
  }

  componentWillUnmount(){
    if(this.channel != null){
      let { chat } = this.props;
        this.channel.unbind();
        this.pusher.unsubscribe(chat.channelName);
    }
  }

  isChatDisabled = () =>{
    let { isAdmin=false, chat, flagged=false, disabled=false } = this.props;
    let { participants } = chat;

    let hasBlockedParticipant = false;
    participants.forEach(participant => {
      if(participant.blocked == true){
        hasBlockedParticipant = true;
      }
    });

    // return chat.disabled || chat.flagged || isAdmin || flagged || disabled;
    return chat.disabled || disabled || hasBlockedParticipant || isAdmin;
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }

  loadThreadMessages = threadId => {
    let { onReducerLoading, onSubmitApiCallWithReducer, onResetChatMessages } = this.props;
    
    onResetChatMessages();
    onReducerLoading(LOAD_CHAT_MESSAGES);
    onSubmitApiCallWithReducer("getChatMessages", SAVE_CHAT_MESSAGES, threadId, CHAT_ERROR);
  }

  /*PUSHER*/
  createPusherThreadChannel = () =>{
    let { chat, onSaveToastAlert } = this.props;

    const channelName = chat.channelName;
    
    if(channelName != null && channelName.trim() != ""){
      this.channel = this.pusher.subscribe(channelName);
      
      if(this.channel != null && this.channel.subscribed == true){
        this.setState({channelSubscribed: true});
        this.getMembersData(this.channel.members.members);
      }

      this.channel.bind('pusher:subscription_succeeded', data => {
          let members = data.members;
          this.getMembersData(members);
          this.setState({channelSubscribed: true});
      });

      this.channel.bind('pusher:subscription_error', data => {
          this.setState({channelSubscribed: false});  
          onSaveToastAlert("danger", "Network Error: " + data.error);
      });

      this.channel.bind("pusher:member_added", (member) => {
        // id = string, info = Object { userName, }
        // add_member(member.id, member.info);
        var { onlineMembers }= this.state;
        onlineMembers.push(member.id);
        this.setState({ onlineMembers });
      });

      this.channel.bind("pusher:member_removed", (member) => {
        var { onlineMembers }= this.state;
        const index = onlineMembers.indexOf(member.id);
        
        if (index > -1) { 
          onlineMembers.splice(index, 1); 
        }
        
        this.setState({ onlineMembers });
      });

      this.channel.bind('new-message', (data) => {
        let { message  }  = data;
        this.addPusherMessage(message);
      });
    }
  }

  getMembersData = membersObject =>{
    //members: count (Number), each (function), get(userId) (function), me (Object)
    //member: id (String), info (Object)
    let memberIds = Object.keys(membersObject);
    
    if(memberIds.length > 0){
      this.setState({onlineMembers: memberIds});
    }
  }
  
  addPusherMessage = pusherMessage => {
    const { chatsReducer, onSaveChatMessages } = this.props
    let messages = chatsReducer.messages;
    messages = [...messages, pusherMessage]; 
    onSaveChatMessages(messages);
  }

  /*TOGGLES*/
  openSidebar = (detailsType="user") => {
    this.setState({ showSidebar: true, detailsType });
    document.body.click();
  };

  closeSidebar = () => {
    this.setState({ showSidebar: false });
    document.body.click();
  };

  toggleChatSearchBox = () => {
    this.setState({ chatSearchBox: !this.state.chatSearchBox });
    document.body.click();
  };

  /*Sidebar*/
  changeSelectedParticipant = participantId =>{
    const { chat={} } = this.props
    let participants = chat.participants;

    let selectedChatParticipant = participants.find(participant =>{
      return participant.id == participantId;
    });

    this.setState({selectedChatParticipant, showSidebar: true, detailsType: "user"});
  }

  /* Server API */
  addMessage = messageObject => {
    const { user, chat={}, chatsReducer, onSubmitApiCall, onSaveChatMessages } = this.props
    let { onlineMembers } = this.state;

    let messages = chatsReducer.messages;
    let showLinks = chat.showLinks;

    const socketId = this.pusher != null ? this.pusher.connection.socket_id : null;
    messageObject.socketId = socketId;
    messageObject.subscribedMembers = onlineMembers;

    let chatFile = messageObject.chatFile;
    let chatImage = messageObject.chatImage;

    delete messageObject.chatFile; 
    delete messageObject.chatImage;

    if(messageObject.body != null && messageObject.body.trim() != ""){
      messages = [...messages, messageObject]; 
      if(showLinks != true && checkAllLinkTypes(messageObject.body.trim())){
        messageObject.flagged = true;
      }
      onSubmitApiCall("submitChatMessage", messageObject);
    }

    if(chatFile != null && chatFile != ""){
      onSubmitApiCall("uploadChatFile", { chatFile, threadId: messageObject.threadId });

      const fileMessage = {
        id: Math.floor(Math.random() * 100),
        threadId: messageObject.threadId,
        userId: user.id,
        body: "...(sending file)...",
        // body: chatFile.name,
        // attachment: chatImage.name,
        createdAt: new Date(),
      }
      messages = [...messages, fileMessage]; 
    }

    if(chatImage != null && chatImage != ""){
      onSubmitApiCall("uploadChatImage", { chatImage, threadId: messageObject.threadId });

      const chatMessage = {
        id: Math.floor(Math.random() * 100),
        threadId: messageObject.threadId,
        userId: user.id,
        body: "...(sending image)...",
        // body: chatImage.name,
        // attachment: chatImage.name,
        image: true,
        createdAt: new Date(),
      }
      messages = [...messages, chatMessage]; 
    }

    onSaveChatMessages(messages);
  }

  blockParticipant = participantId => {
    const { onSubmitApiCall, threadId } = this.props
    onSubmitApiCall("blockChatParticipant", {participantId:participantId, threadId: threadId});
  }

  unblockParticipant = participantId => {
    const { onSubmitApiCall, threadId } = this.props
    onSubmitApiCall("unblockChatParticipant", {participantId:participantId, threadId: threadId});
  }

  render() {
    const { user, chat={}, threadId, chatsReducer, isAdmin } = this.props
    let { showSidebar, detailsType, onlineMembers, channelSubscribed, selectedChatParticipant } = this.state;

    let showLinks = chat.showLinks;
    const pusherErrorMsg = <><b>Connection Error: </b> Not Receiving Updates</>

    let messages = chatsReducer.messages;
    let loadingMessages = chatsReducer.loadingMessages;

    let participants = chat.participants;
    
    if(!isAdmin){
      participants = participants.filter(participant => {return participant.userId != user.id});
    }

    let disableChat = this.isChatDisabled();

    return (
      <main className={"main " + (this.props.showMain === true ? "main-visible" : "")}>
        <div className="chats">
          <div className="chat-body">
            <ChatHeader participants={participants} 
                onlineMembers={onlineMembers}
                backToListClicked={this.props.backToListClicked}
                showDetails={this.openSidebar}
                toggleChatSearchBox={this.toggleChatSearchBox}
                blockParticipant={this.blockParticipant}
                unblockParticipant={this.unblockParticipant}
                onChangeSelectedParticipant={this.changeSelectedParticipant}
                />
            
            {/* SEARCH */}
            {/* <div
              className={
                "border-bottom px-3 collapse " +
                (this.state.chatSearchBox ? "show" : "")
              }
            >
              <ChatSearch />
            </div> */}

            <div className="chat-content p-2">
              <div className="container">
                {channelSubscribed == false && <Alert color="danger"><center>{pusherErrorMsg}</center></Alert>}
                {loadingMessages && <center><ThemeSpinner /></center>}
                {/* <div className="message-divider pb-2" data-label="Yesterday"></div> */}
                {
                  messages.map((message, i) =>
                    <span key={"message-"+ i} ref={ messages.length - 1 == i ? this.messagesEndRef : null}>
                      <ChatMessage message={message} self={message.userId == user.id} showLinks={showLinks} isAdmin={isAdmin} />
                    </span>
                  )
                }
              </div>
              <div className="chat-finished" id="chat-finished"></div>  
            </div>

            { showLinks != true && 
                <button className="btn btn-sm btn-outline-secondary " onClick={() => this.openSidebar("tutorRequest")}>Click To Accept / Decline Proposal</button>
            }
            
            <ChatFooter threadId={threadId} user={user} disableChat={disableChat} showLinks={showLinks} onSendMessage={this.addMessage} />

          </div>
            
          {/* CHAT DETAILS */}
          {
            showSidebar && 
            <div
              className={
                "chat-info " +
                (showSidebar ? "chat-info-visible" : "")
              }
            >
              <ChatSidebar 
                key={chat.threadId}
                chat={chat} 
                messages={messages} 
                pusher={this.pusher}
                detailsType={detailsType}
                isAdmin={isAdmin}
                participant={selectedChatParticipant}
                closeSidebar={this.closeSidebar}  />

            </div>
          }

        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ apiResponseReducer, userReducer, chatsReducer }) => ({
  apiResponse: apiResponseReducer,
  user: userReducer,
  chatsReducer
})

const mapDispatchToProps = dispatch => ({
  onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
  onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
  
  onSaveToastAlert: (alertType, message) => dispatch(saveToastAlert(alertType, message)),
  onResetChatMessages: () => dispatch(resetChatMessages()),
  onSaveChatMessages: messages => dispatch(saveChatMessages(messages)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversation)