import React from "react"
import moment from "moment"

import { Col, Row, Card, CardBody } from 'reactstrap'

export const LeadDetails = (props) => {
    return (
        <>
            <b>ID: </b>{props.lead.id}<br />
            <b>Location: </b>{props.lead.studentLocation} {" "}
            {
                props.lead.distance != null &&
                    <>({props.lead.distance.toFixed(0)} miles away)</>
            }
            <br />
            {
                props.lead.studentTimezone &&
                    <>
                        <b>Timezone:</b> {props.lead.studentTimezone}
                        <br />
                    </>
            }
            <b>Dates: </b>{moment(props.lead.startDate).format("MMM Do")} to {moment(props.lead.endDate).format("MMM Do YYYY")}
            <br />
            <b>Preferred Time: </b>{props.lead.sessionTime}<br />
            <b>Frequency: </b>{props.lead.frequency}<br />
            {
                props.lead.concerns &&
                    <>
                        <b>Concerns: </b>{props.lead.concerns}
                        <br />
                        <br />
                    </>
                
            }
            <b style={{color: "darkred"}}>Bid Deadline: {moment(props.lead.responseDeadline).fromNow()} | {moment(props.lead.responseDeadline).format("llll")} </b>
            <br />
            <br />
            {
                props.lead.online &&
                    <span className="badge badge-soft-info">Online Tutoring</span> 
            }
            {" "}
            {
                props.lead.inPerson &&
                    <span className="badge badge-soft-success">In-Person Tutoring</span>
            }

        </>
    )
}

export const BidFormLayout = props => {
    return (
        <Row>
            {/* <Col md={12} lg={10} xl={8}> */}
            <Col md={12}>
                <Card className="overflow-hidden" style={{boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.3)"}}>
                    <CardBody className="pt-6">
                        <div className="p-2">
                            {props.children}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )

} 