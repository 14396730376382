import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  // LOGOUT_USER_SUCCESS,
  LOGIN_API_ERROR,

  UPDATE_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  CLONE_USER,
} from "./actionTypes"

//BTN ADDED
export const updatePassword = ({ userId, password }) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { userId, password },
  }
}

export const resetPassword = ({userId, password, history}) => {
  return {
    type: RESET_PASSWORD,
    payload: { userId, password, history },
  }
}

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS
  }
}

export const requestPasswordReset = email => {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: { email },
  }
}

export const requestPasswordResetSuccess = () => {
  return {
    type: REQUEST_PASSWORD_RESET_SUCCESS
  }
}

export const apiError = error => {
  return {
    type: LOGIN_API_ERROR,
    payload: error,
  }
}

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS
  }
}

// export const logoutUser = history => {
//   return {
//     type: LOGOUT_USER,
//     payload: { history },
//   }
// }

// export const logoutUserSuccess = () => {
//   return {
//     type: LOGOUT_USER_SUCCESS,
//     payload: {},
//   }
// }

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const cloneUser = ({history, user, token, tokenExpiration}) => {
  return {
    type: CLONE_USER,
    payload: { history, user, token, tokenExpiration },
  }
}