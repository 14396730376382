import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Formik } from 'formik';
import { Alert, Col, Input, Row } from 'reactstrap';

class GoogleSubmitVerificationCodeForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          
        }
        this.onSubmit.bind(this);
    }

    onSubmit = async (values) => {
        let { locationName } = this.props;
        
        values = {...values, locationName};
        
        this.props.onSubmit(values);
    }

    render() {
        let { apiResponse } = this.props;

        let loading = apiResponse.loading;
        let error = apiResponse.error;

        return (
            <>  
                {error ? <Alert color="danger">{error}</Alert> : null}

                <Formik
                    onSubmit={this.onSubmit}
                    initialValues={{pin: ""}}
                >
                    { props  => (
                        <Form className="needs-validation">
                            <Row>
                                <Col xs={6}>
                                    <Input
                                        name="pin"
                                        className="form-control"
                                        placeholder="Enter Verification Code"
                                        type="text"
                                        required
                                        value={props.values.pin}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                </Col>
                                <Col xs={4}>
                                    {
                                        loading
                                            ?
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                                disabled={true}
                                            >
                                                Submitting Pin ... {" "}
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            
                                            </button>
                                            :
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                            >
                                                Submit Pin
                                            </button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

// const mapDispatchToProps = dispatch => ({
//     onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
// })
  
export default connect(mapStateToProps, null)(GoogleSubmitVerificationCodeForm)