import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Card, CardHeader, CardBody, Collapse } from 'reactstrap'
import { ThemeRadio } from 'theme/elements/styledInputs'

export default class ThemeAccordion extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             items: this.props.items || [],
        }
        this.openItem.bind(this)
        this.onChangeRadio.bind(this)
    }
    
    openItem = itemId => {
        let {items} = this.state;
        items.forEach(item => {
            item["isOpen"] = item.id == itemId ? true : false;
        })

        this.setState({items});
    }

    onChangeRadio = () => {

    }

    render() {
        let { items = [] } = this.state; //item: {id, title, body, isOpen}
        let { showRadio = false, name="accordion" } = this.props;

        return (
            <Card style={{ border: "1px solid lightgray"}}>
                <CardBody>
                    {
                        items.map((item, i) => 
                            <Card key={`accordion-${i}`} className="mb-1">
                                <CardHeader className="p-3" id={item.id}>
                                    <h6 className="m-0 font-14">
                                        <span
                                            onClick={() => this.openItem(item.id)}
                                            style={{ cursor: "pointer" }}
                                            className="text-dark"
                                        >
                                            {
                                                showRadio 
                                                    ?
                                                        <ThemeRadio id={item.id} name={name + "-radio"} label={item.title} 
                                                                    checked={item.isOpen} 
                                                                    onChange={this.onChangeRadio} />
                                                    :
                                                        <>{item.title}</>
                                            }
                                        </span>
                                        
                                    </h6>
                                </CardHeader>
            
                                <Collapse isOpen={item.isOpen}>
                                    <Card>
                                        <CardBody>
                                            { typeof item.body == "string"
                                                ?
                                                    <div className="text-muted">
                                                        {item.body}
                                                    </div>
                                                :
                                                    item.body
                                            }
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </Card>
                        )
                    }
                </CardBody>
            </Card>
        )
    }
}

ThemeAccordion.protoType = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]).isRequired,
        title: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,

    })),
}