import { get, postFormData, patch } from "helpers/api_helper"
import { GET_TUTOR_PROFILE, PATCH_UPDATE_PROFILE_STATUS,
        GET_TUTOR_PROFILE_DETAILS, PATCH_TUTOR_PROFILE_DETAILS, POST_UPDATE_AVATAR,
        PATCH_UPDATE_PROFILE_SUBJECTS,
        PATCH_UPDATE_PROFILE_SESSION_LOCATIONS,
        PATCH_UPDATE_PROFILE_GEOLOCATIONS } from 'api/urls';

import { processApiError } from "store/api/scripts";

export async function getTutorProfile(tutorId){
  let url = GET_TUTOR_PROFILE +"/"+ tutorId;
  
  try {
    return await get(url);

  } catch (error) {
    let errorMessage = processApiError(error);
    return {error: errorMessage};
  }
}

export const updateProfileStatus = formData => {
  return patch(PATCH_UPDATE_PROFILE_STATUS + "/" + formData.userId, formData);
}

export const getProfileDetails = tutorId => {
  return get(GET_TUTOR_PROFILE_DETAILS + "/" + tutorId);
}

export const updateProfileDetails = formData => {
  return patch(PATCH_TUTOR_PROFILE_DETAILS + "/" + formData.tutorId, formData);
}

export const updateProfileAvatar = ({ tutorId, avatarFile }) => {

  var formData = new FormData();
  formData.append("avatar", avatarFile); 
  let headers = { headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } };

  return postFormData(POST_UPDATE_AVATAR + "/" + tutorId, formData, headers);
}

export const updateProfileSubjects = formData => {
  const tutorId = formData.tutorId;
  delete formData.tutorId;
  return patch(PATCH_UPDATE_PROFILE_SUBJECTS + "/" + tutorId, formData);
}

export const updateSessionLocations = formData => {
  return patch(PATCH_UPDATE_PROFILE_SESSION_LOCATIONS + "/" + formData.tutorId, formData);
}

export const updateProfileGeolocations = formData => {
  const tutorId = formData.tutorId;
  delete formData.tutorId;
  return patch(PATCH_UPDATE_PROFILE_GEOLOCATIONS + "/" + tutorId, formData);
}