import { get, post, put, del } from "../helpers/api_helper"
import { POST_CREATE_STRIPE_SETUP_INTENT,
          // POST_CREATE_STRIPE_PAYMENT_INTENT,
          POST_CREATE_STRIPE_PAYMENT_INTENT_ON_CART,

          GET_STRIPE_CONNECT_ACCOUNT, 
          POST_CREATE_STRIPE_CONNECT_ACCOUNT,
          POST_STRIPE_CREATE_ONBOARDING_LINK, POST_STRIPE_CREATE_LOGIN_LINK,

          POST_DELETE_STRIPE_STANDARD_ACCOUNT
        } from "../api/urls"

import { processApiError } from "store/api/scripts";

export async function createSetupIntent(metadata){
  try {
    let clientSecret = await post(POST_CREATE_STRIPE_SETUP_INTENT, metadata);
    return {error: "", clientSecret};

  } catch (error) {
    let errorMessage = processApiError(error);
    return {error: errorMessage, clientSecret: ""};
  }
}

export async function createPaymentIntent(metadata){
  let clientSecret = "";
  let error = "";

  try {      
      if(typeof metadata == "object" && metadata.hasOwnProperty("cart")){
        clientSecret = await post(POST_CREATE_STRIPE_PAYMENT_INTENT_ON_CART, metadata);
      }
      // else{
      //   clientSecret = await post(POST_CREATE_STRIPE_PAYMENT_INTENT, metadata);
      // }

    } catch (apiError) {
      error = processApiError(apiError);
    }
    
  return {error, clientSecret};
}

export const getAccountId = () => {
  return get(GET_STRIPE_CONNECT_ACCOUNT);
}

export const createConnectAccount = formData => {
  return post(POST_CREATE_STRIPE_CONNECT_ACCOUNT, formData);
}

export const createOnboardingLink = accountId => {
  return post(POST_STRIPE_CREATE_ONBOARDING_LINK, {accountId});
}

export const createLoginLink = accountId => {
  return post(POST_STRIPE_CREATE_LOGIN_LINK, {accountId});
}

//Switch to Stripe Express
export const deleteStripeStandardAccount = () => {
    return post(POST_DELETE_STRIPE_STANDARD_ACCOUNT);
}