import { post } from "../helpers/api_helper"
import { POST_SEARCH_TUTORS, POST_SEARCH_TUTORS_ADMIN, POST_SEARCH_FIND_PREVIOUS_STUDENT } from "../api/urls"


export const findPreviousStudent = formData => {
  return post(POST_SEARCH_FIND_PREVIOUS_STUDENT, formData);
}

export async function submitTutorSearch(formData){
  //NOT USED...only allowing admin searches
  results = await post(POST_SEARCH_TUTORS, formData);
}

export async function submitTutorSearchAdmin(formData){
  return await post(POST_SEARCH_TUTORS_ADMIN, formData);
}


