import React, { Component } from "react"
import { Link } from "react-router-dom"

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
} from "reactstrap"

import AssignmentModal from "../modals/AssignmentModal"
import EvaluationFormModal from "components/modals/EvaluationFormModal"

import { ThemeButton, ThemeButtonLink } from "theme/elements/buttons"

import { TUTOR_PROFILE, USER_TUTOR_SESSIONS, STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE, STUDENT_EVALUATION_FORM} from "routes/paths"

import { getCurrencySymbol, getAssignmentStatus } from "scripts/general"

export default class TutorAssignmentCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen:[],
      isOpenEval: [],
      badgeClass: {active: 'success',
                    ending_soon: 'warning',
                    ended: 'danger'},
    }
    this.openModal.bind(this);
    this.openModalEval.bind(this);
  }

  openModal = (id) => {
    this.setState({
       isOpen: {
          [id]: true
       }
    });
  }

  closeModal = (id) => {
    this.setState({
       isOpen: {
          [id]: false
       }
    });
  }

  openModalEval = (id)=> {
    this.setState({
      isOpenEval: {
         [id]: true
      }
   });
  }

  closeModalEval = (id) => {
    this.setState({
      isOpenEval: {
          [id]: false
       }
    });
  }

  requestBalanceRefund = tutorContractId => {
    if(confirm("Are you sure you want to refund this balance?")){
      if(this.props.onRequestBalanceRefund != undefined){
        this.props.onRequestBalanceRefund(tutorContractId);
      }
    }
  }

  render() {
    let { assignment, user } = this.props;
    let { badgeClass, isOpen, isOpenEval } = this.state;

    let status = getAssignmentStatus(assignment.endDate);
    let currency = getCurrencySymbol(assignment.currency) || "$";
    let userId = user.id;
    let prepaidHours = assignment.prepaidHours || 0;
    let balance = assignment.balance || 0;

    return (
      <React.Fragment>
        <Card className="text-center">
          <CardBody>
            {!assignment.avatar ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                  }
                >
                  {assignment.name.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-xl"
                  src={assignment.avatar}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15">
              {
                status == "ended" ? 
                  <span>{assignment.name}</span>
                  :
                  <a onClick={() => this.openModal(assignment.id)} className="text-primary">
                    {assignment.name}
                  </a>
              }
              
            </h5>
            <p className="text-muted">{assignment.course}</p>
            {
             assignment.scholarship 
                ?
                  <p>
                    <Badge
                      className="font-size-12 badge-info"
                      color="primary"
                    >
                      Scholarship
                    </Badge>
                    <br />
                    <br />
                    <b className={`${prepaidHours <= 1 ? "text-danger" : "text-dark"}`}>Hours Left : {prepaidHours.toFixed(2)}</b>
                  </p>
                :
                  <p><b>Balance: <span className={`${balance <= 0 ? "text-danger" : "text-dark"}`}>{currency}{" "}{balance || 0}</span></b>
                      
                    {status != "ended" && 
                      <>
                        <br />
                        <br />
                        <ThemeButtonLink to={STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE + "/" + assignment.tutorContractId} color="info" className={"btn btn-sm"}>Add Funds</ThemeButtonLink>
                      </>
                    }
                    <br />
                    {balance > 0 && <Button color="secondary" outline={true} className={"btn-sm"} onClick={() => this.requestBalanceRefund(assignment.tutorContractId)}>Refund Balance</Button>}
                  </p>
            }
            
            <div>
              { status != "active" &&
                <Badge
                  className={
                    "font-size-12 badge-soft-" + badgeClass[status]
                  }
                  color={badgeClass[status]}
                  pill
                >
                  {status}
                </Badge>
              }
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              {
                status != "ended" && assignment.email &&
                  <div className="flex-fill">
                    <a href={"mailto:" + assignment.email} id={"email" + assignment.id} className="text-primary">
                      <i className="bx bx-envelope" />
                      <UncontrolledTooltip
                        placement="top"
                        target={"email" + assignment.id}
                      >
                        Send Email
                      </UncontrolledTooltip>
                    </a>
                  </div>
              }
              
              {
                assignment.phone &&
                <div className="flex-fill">
                  <a href={"sms:" + assignment.phone} id={"phone" + assignment.id} className="text-info">
                    <i className="bx bx-phone" />
                    <UncontrolledTooltip
                      placement="top"
                      target={"phone" + assignment.id}
                    >
                      Send Text
                    </UncontrolledTooltip>
                  </a>
                </div>
              }

              <div className="flex-fill">
                <Link to={TUTOR_PROFILE + "/" + assignment.tutorId} 
                      id={"profile" + assignment.id}
                      target="_blank">
                  <i className="bx bx-user-circle" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"profile" + assignment.id}
                  >
                    Profile
                  </UncontrolledTooltip>
                </Link>
              </div>
              <div className="flex-fill">
                <a href={USER_TUTOR_SESSIONS + "/" + userId} id={"charges" + assignment.id} className="text-success">
                  <i className="bx bx-dollar" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"charges" + assignment.id}
                  >
                    Charges & Refunds
                  </UncontrolledTooltip>
                </a>
              </div>
              <div className="flex-fill">
                <Link to={STUDENT_EVALUATION_FORM + "/" + assignment.id} 
                      id={"eval" + assignment.id}
                      className="text-warning"
                      target="_blank">
                {/* <a id={"eval" + assignment.id} className="text-warning" onClick={() => this.openModalEval(assignment.id)}> */}
                  <i className="bx bx-star" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"eval" + assignment.id}
                  >
                    Evaluations
                  </UncontrolledTooltip>
                {/* </a> */}
                </Link>
              </div>

            </div>
          </CardFooter>
        </Card>

        <AssignmentModal
          key={"assign-modal-"+ assignment.id}
          assignment={assignment}
          isOpen={isOpen[assignment.id]}
          toggleModal={() => this.closeModal(assignment.id)}
          />
          
        <EvaluationFormModal 
          assignment={assignment}
          type={assignment.evaluationType}
          isOpen={isOpenEval[assignment.id]}
          toggleModal={() => this.closeModalEval(assignment.id)} />
      </React.Fragment>
    )
  }
}

