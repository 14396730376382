import React, { Component } from "react"
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";

export default class MapWithInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      places: this.props.geolocations || [] //id, lat, lng, tutor
    }
  }

  onChildClickCallback = (key) => {
    let { places } = this.state;

    places.forEach(place => {
      place.show = place.id == key ? place.show = !place.show : false
    });

    this.setState({ places });
  };

  onChildMouseEnter = (key, childProps) => {
    // const markerId = childProps.marker.get('id');
    // const index = this.props.markers.findIndex(m => m.get('id') === markerId);
    // if (this.props.onMarkerHover) {
    //   this.props.onMarkerHover(index);
    // }
  }

  onChildMouseLeave = (/* key, childProps */) => {
    // if (this.props.onMarkerHover) {
    //   this.props.onMarkerHover(-1);
    // }
  }

  render() {
    let { centerLat=null, centerLng=null, zoom=11, height="80vh", width="100%"} = this.props;
    let { places } = this.state
    centerLat = centerLat || places[0].lat;
    centerLng = centerLng || places[0].lng;

    const defaultProps = {
      center: { lat: centerLat, lng: centerLng},
      zoom
    };

    return ( 
        <div style={{ height, width }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            onChildClick={this.onChildClickCallback}
            onChildMouseEnter={this._onChildMouseEnter}
            onChildMouseLeave={this._onChildMouseLeave}
            // onBoundsChange={this._onBoundsChange}
            // margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
            // hoverDistance={K_HOVER_DISTANCE}
            // distanceToMouse={this._distanceToMouse}
          >
            {
              places.map((place, i) =>
                <Marker
                  key={place.id}
                  text={i + 1}
                  lat={place.lat}
                  lng={place.lng}
                  show={place.show || false}
                  tutor={place.tutor || {}}
                  onCloseWindow={() => this.onChildClickCallback(place.id)}
                />
              )
            }
          </GoogleMapReact>
        </div>
    )
  }
}