import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Media, Row } from "reactstrap";
import InboxEditorModal from './InboxEditorModal';

export default class ThreadMessages extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         modal: false,
         reply: true, 
         maggie: false
      }
    }

    getThreadBody = thread =>{
        let messages = thread.messages;
        return messages[messages.length - 1].body;
    }

    fixAndSendEmailWithError = () => {
        let { thread } = this.props;

        var errorRow = document.getElementById("errorRow");

        if(errorRow == null){
            alert("Error row not found");
            return;
        }
        var errorDiv = document.getElementById("errorDiv");
        
        if(errorDiv == null){
            alert("Error div not found");
            return;
        }

        var errorMessage = errorDiv.innerHTML;
        
        var errorArray = errorMessage.split(" ");
        let toEmailAddress = errorArray[errorArray.length - 1];
        errorRow.remove();

        let subject = thread.subject.replace("EMAIL NOT SENT: ", "");
        let body = document.getElementById("emailBody").innerHTML;
        let mimeType = "html";
        
        this.sendEmail({toEmailAddress, subject, body, mimeType});
    }

    sendEmail = values => {
        this.props.onSendEmail(values);

        if(this.state.modal == true){
            this.setState({ modal: false });
        }
    }

    render() {
        let { thread, loading } = this.props;
        let { modal } = this.state;
        let messages = thread.messages;
        
        return (
            <>
                <InboxEditorModal 
                    key={thread.id}
                    isOpen= {modal}
                    toggleModal={() => this.setState({modal: false})} 
                    thread={thread}
                    {...this.state}
                    onSubmit={this.sendEmail} />
                <Card>
                    <CardBody>
                        <h4 className="mt-0 font-size-16">
                            {thread.subject}
                        </h4>
                        {
                            messages.map((message, key) =>
                                <div key={key}>
                                    <Media className="mb-4">
                                        {/* <img
                                            className="d-flex me-3 rounded-circle avatar-sm"
                                            src={avatar2}
                                            alt="skote"
                                        /> */}
                                        <Media body>
                                            <h5 className="font-size-14 mt-1">
                                                <b>{message.from}</b>
                                            </h5>
                                            <small className="text-muted">
                                                to: {message.to}
                                            </small>

                                        </Media>
                                    </Media>
                                    {
                                        thread.subject.includes("EMAIL NOT SENT: ") &&
                                            <Row>
                                                <Col>
                                                    <Button
                                                        to="#"
                                                        className="btn btn-success mt-4 ml-4"
                                                        disabled={loading}
                                                        onClick={() => this.setState(() => this.fixAndSendEmailWithError())}
                                                    >
                                                        Fix & Send Email
                                                    </Button>
                                                </Col>
                                            
                                            </Row>
                                    }
                                    <p id="emailBody" dangerouslySetInnerHTML={{ __html: message.body }}></p>
                                </div>
                            )
                        }

                        {
                            !thread.subject.includes("EMAIL NOT SENT: ") &&
                                    <>
                                        <hr />
                                        <Button
                                            to="#"
                                            className="btn btn-info mt-4"
                                            disabled={loading}
                                            onClick={() => this.setState({modal: true, reply: true, maggie: true })}
                                        >
                                            Maggie Response
                                        </Button>
                                        <Link
                                            to="#"
                                            className="btn btn-secondary mt-4 ml-4 float-right"
                                            disabled={loading}
                                            onClick={() => this.setState({modal: true, reply: false, maggie: false})}
                                        >
                                            <i className="mdi mdi-forward"></i> Forward
                                        </Link>
                                        <Button
                                            to="#"
                                            className="btn btn-secondary mt-4 ml-4 float-right"
                                            disabled={loading}
                                            onClick={() => this.setState({modal: true, reply: true, maggie: false})}
                                        >
                                            <i className="mdi mdi-reply"></i> Reply
                                        </Button>
                                        
                                    </>
                        }
                    </CardBody>
                </Card>
            </>
        )
    }
}

