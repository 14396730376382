import React, { Component } from "react"

import { loadMathJax } from "../../../scripts/mathjax"

import FormWizard from "../FormWizard"
import Question from "./Question"

class ExamForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //activeTab: 1,
      validationError: "",
      showConfirmation: true,
      showSubmitButton: true,
      tabs: [],
      formFields: {},
      questions: this.props.questions || []
    }

    this.createTabs.bind(this)
    this.resetForm.bind(this)
    this.onSave.bind(this)
    this.onSubmit.bind(this)
  }

  componentDidMount() {
    this.createTabs();
    loadMathJax();
  }

  createTabs = () => {
      var tabs = [];
      var questions = this.state.questions;

      var formFields = {};

      questions.map((q, i) => {
        var tab = { id: q.questionNumber, component: Question, props: { question: q } };
        tabs.push(tab);

        formFields[q.id] = "";
      })

      this.setState({ tabs, formFields });
  }

  resetForm = () => {
    var { formFields } = this.state;
    for (var qID in formFields) {
      if (formFields.hasOwnProperty(qID)) {
        formFields[qid] = "";
      }
    }

    this.setState({ formFields,
                    //activeTab: 1,
                    validationError: "",
                    showConfirmation: true,
                    showSubmitButton: true});
  }

  onSave = (formData) => {
    let {submitted} = this.props;
    if(submitted == true){
      alert("Exam has already been submitted.");
    }
    else{
      this.setState({validationError: "",
                        showSubmitButton: false});
        
      if(typeof this.props.onSaveExam != undefined){
        this.props.onSaveExam(formData);
      }
    }
  }

  onSubmit = (e, formData) => {
    let {submitted} = this.props;
    if(submitted == true){
      alert("Exam has already been submitted.");
    }
    else{
      e.preventDefault();
      this.setState({validationError: "",
                      showSubmitButton: false});

      if(typeof this.props.onSubmitExam != undefined){
        this.props.onSubmitExam(formData);
      }
    }
  }

  render() {
    let { tabs, formFields, showSubmitButton } = this.state;
    let examTitle = this.props.title || "Proficiency Exam";
    let { submitted, submittedAt} = this.props;
    
    return (
      <React.Fragment>
          {
            tabs.length > 0 &&
            <>
              <p><b>Save Progress: </b> Click Save & Exit.</p>
              <p><b>Submit Exam:</b> Navigate to the last question and click Submit</p>
              <p>Zoom In/Out On Math: Right click on the formula --> Math Settings --> Scale All Math</p>
              {
                submitted == true &&
                  <center><b className="text-danger">Exam Has Already Been Submitted</b><br /><br /></center>
              }
              <FormWizard formTitle={examTitle}
                        tabs={tabs}
                        formFields={formFields}
                        onSubmit={this.onSubmit}
                        onSave={this.onSave}
                        loading={this.props.loading}
                        // showSubmitButton={submitted == true ? false : showSubmitButton}
                        showSubmitButton={showSubmitButton}
                        validationErrors={this.props.validationErrors}
                        saveButtonText="Save & Exit"
                        />
            </>
          }
      </React.Fragment>
    )
  }
}

export default ExamForm
