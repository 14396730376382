import React, { Component } from 'react'
import { connect } from "react-redux"
import PropTypes from 'prop-types'
import moment from 'moment'

import { submitApiCallAction, triggerReducerLoadingAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts'

import { CancelSessionAction, DeleteSessionAction, RefundSessionAction } from './actions'

import { formatCurrencyAmount } from 'scripts/general'


class TutorSessionsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows: [],
            columns: [],
            studentColumns: ["#", "sessionDate", "course", "duration", "outcome", /*"subtotal", "discount", */ "total", "tutorName", "createdAt", "id", "refund"],
            studentSummaryColumns: ["#", "sessionDate", "tutorName", "course", "duration", "total",  "refund" ],
            
            tutorColumns: ["#", "delete", "sessionDate", "studentName","course", "duration", "outcome","earnings", "createdAt","id"],
            tutorSummaryColumns: ["delete", "sessionDate", "studentName", "course", "duration", "earnings"],
            
            adminColumns: ["id", "action","sessionDate", "studentName", "tutorName", "course", "duration", "outcome", "subtotal", "discount", "fee", "total", "tutorPay", "createdAt", "refId"],
            processing: false
        }
        
        this.createColumns.bind(this)
        this.createRows.bind(this)

        this.addActionField.bind(this)
        this.formatFields.bind(this)
        this.triggerAction.bind(this)
    }
    
    componentDidMount() {
        let { tutorSessions=[], role, type="summary" } = this.props;
        this.createColumns(type, role);
        this.createRows(tutorSessions, type, role);
    }
    
    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);

                if(this.state.processing && apiResponse.success){
                    this.setState({processing: false});
                    window.location.reload();
                }
            }
        }
    }

    createColumns = (type, role) => {
        let { studentColumns, studentSummaryColumns, tutorColumns, tutorSummaryColumns, adminColumns } = this.state;
        let specifiedFields = [];

        switch (role) {
            case "student":
                specifiedFields = type == "summary" ? studentSummaryColumns : studentColumns;
                break;
            case "tutor":
                specifiedFields = type == "summary" ? tutorSummaryColumns : tutorColumns;
                break;
            case "admin":
                specifiedFields = adminColumns;
                break;
            default:
                break;
        }

        let columns = createSpecifiedColumns(specifiedFields);

        this.setState({columns});
    }

    createRows = (tutorSessions, type, role) => {
        let rows = tutorSessions;
        rows = this.formatFields(rows);
        rows = this.addActionField(rows, role);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        let count = 1
        rows.forEach(row => {
            row["#"] = count;
            if(typeof row.sessionDate !== undefined){
                row.sessionDate = moment(row.sessionDate).format('MMM D YYYY');
            }
            if(typeof row.createdAt !== undefined){
                row.createdAt = moment(row.createdAt).format('MMM D YYYY');
            }

            //Duration
            if(typeof row.duration !== undefined && typeof row.duration == "number"){
                let unit = row.duration == 1 ? "hour" : "hours";
                row.duration += " " + unit; 
            }
            //Currency
            if(typeof row.currency !== undefined){
                if(typeof row.subtotal !== undefined && typeof row.subtotal == "number"){
                    row.subtotal = formatCurrencyAmount(row.subtotal, row.currency);
                }
                if(typeof row.discount !== undefined && typeof row.discount == "number"){
                    
                    row.discount = row.discount > 0 ? "-" + formatCurrencyAmount(row.discount, row.currency) : null;
                }
                if(typeof row.fee !== undefined && typeof row.fee == "number"){
                    row.fee = formatCurrencyAmount(row.fee, row.currency);
                }
                if(typeof row.total !== undefined && typeof row.total == "number"){
                    row.total = formatCurrencyAmount(row.total, row.currency);
                }
                if(typeof row.earnings !== undefined){ 
                    if(typeof row.earnings == "number"){
                        row.earnings = formatCurrencyAmount(row.earnings, row.currency);
                    }
                    // else if(row.earnings == null && row.cancelable){
                    //     row.earnings = <em><b>{row.earnings} Pending</b></em>
                    // }
                }

                //Admin
                if(typeof row.tutorPay !== undefined){
                    if(typeof row.tutorPay == "number"){
                        row.tutorPay = formatCurrencyAmount(row.tutorPay, row.tutorCurrency);
                    }
                    // else if(row.tutorPay == null && row.cancelable){
                    //     row.tutorPay = <em><b>Pending</b></em>
                    // }
                }
            }
            count++;
        });
        
        return rows;
    }

    addActionField = (rows, role) => {
        switch (role) {
            case "student":
                //Request Refund
                rows.forEach(row => {
                    if(row.refundable){
                        row["refund"] = <RefundSessionAction id={row.id} onClick={() => this.triggerAction("refund", row.id)} />
                    }
                });
                break;

            case "tutor":
                //Delete Charge
                rows.forEach(row => {
                    if(row.earnings == null && row.cancelable){
                        row["delete"] = <CancelSessionAction id={row.id} onClick={() => this.triggerAction("cancel", row.id)} />
                    }
                    else if(row.deletable){
                        row["delete"] = <DeleteSessionAction id={row.id} onClick={() => this.triggerAction("delete", row.id)} />
                    }
                });
                break;

            case "admin":
                //Delete Charge
                rows.forEach(row => {
                    if(row.tutorPay == null && row.cancelable){
                        row["action"] = <CancelSessionAction id={row.refId} onClick={() => this.triggerAction("cancel", row.refId)}/>
                    }
                    else {
                        row["action"] = <DeleteSessionAction id={row.refId} onClick={() => this.triggerAction("delete", row.refId)} />
                    }
                });
                break;
        
            default:
                break;
        }

       return rows;
    }

    triggerAction = (action, tutorSessionId) =>{
        if(confirm(`Are you sure you want to ${action} this tutor session?`)){
            let { rows } = this.state;
            let { onSubmitApiCall } = this.props;

            switch (action) {
                case "cancel":
                    onSubmitApiCall("cancelTutorSessionScheduledCharge", tutorSessionId);
                    rows = rows.filter(row => row.id != tutorSessionId);
                    this.setState(rows);
                    break;

                case "delete":
                    onSubmitApiCall("deleteTutorSession", { tutorSessionId });
                    rows = rows.filter(row => row.id != tutorSessionId);
                    this.setState(rows);
                    break;

                case "refund":
                    onSubmitApiCall("requestDeleteTutorSession", { tutorSessionId });
                    break;
                default:
                    break;
            }
            this.setState({processing: true});
        }

    }

    render() {
        let {rows, columns} = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false};
        return (
            <>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} pagination={this.props.paginate == false ? false : true} />

                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(TutorSessionsTable)


TutorSessionsTable.protoType = {
    type: PropTypes.oneOf(["summary", "detailed"]).isRequired,
    role: PropTypes.oneOf(["student", "tutor"]).isRequired,
    tutorSessions: PropTypes.arrayOf(PropTypes.object)
}