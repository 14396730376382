import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  // LOGOUT_USER,
  // LOGOUT_USER_SUCCESS,
  LOGIN_API_ERROR,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    // case LOGOUT_USER:
    //   state = { ...state }
    //   break
    // case LOGOUT_USER_SUCCESS:
    //   state = { ...state }
    //   break
    case LOGIN_API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case REQUEST_PASSWORD_RESET:
        state = {
          ...state,
          error: "",
          loading: true,
        }
        break
        
    case REQUEST_PASSWORD_RESET_SUCCESS:
          state = {
            ...state,
            loading: false,
          }
          break

    case RESET_PASSWORD:
            state = {
              ...state,
              error: "",
              loading: true,
            }
            break
            
    case RESET_PASSWORD_SUCCESS:
              state = {
                ...state,
                loading: false,
              }
              break

    default:
      state = { ...state }
      break
  }
  return state
}

export default login
