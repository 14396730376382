import React from "react"
import { UncontrolledTooltip } from "reactstrap"


export const DeclineApplicant = (props) => {
    //props: id, color, onClick
    return (
        <a style={{fontSize: "2em"}} id={"decline-" + props.id} className={`float-right pr-3 text-${props.color || "warning"}`} onClick={props.onClick}>
            <i className="bx bx-x" />
            <UncontrolledTooltip
                placement="top"
                target={"decline-" + props.id}
            >
            Decline Applicant #{props.id}
            </UncontrolledTooltip>
        </a>
    )
}

export const DeleteApplicant = (props) => {
    //props: id, color, onClick
    return (
        <a style={{fontSize: "2em"}} id={"delete-" + props.id} className={`float-right pr-3 text-${props.color || "danger"}`} onClick={props.onClick}>
            <i className="bx bx-trash" />
            <UncontrolledTooltip
                placement="top"
                target={"delete-" + props.id}
            >
            Delete Applicant #{props.id}
            </UncontrolledTooltip>
        </a>
    )
}