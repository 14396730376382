import axios from "axios"

const token = localStorage.getItem("btnToken");

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

axiosApi.defaults.headers.common["Authorization"] = token;

export async function get(url, config = {}) {
  if (axiosApi.defaults.headers.common["Authorization"] == null && localStorage.getItem("btnToken")) {
    axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("btnToken");
  }
  
  return await axiosApi.get(url, { ...config })
                      .then(response => response.data);
}

export async function post(url, data, config = {}) {
  if (axiosApi.defaults.headers.common["Authorization"] == null && localStorage.getItem("btnToken")) {
    axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("btnToken");
  }
  
  return await axiosApi.post(url, { ...data }, { ...config })
                      .then(response => response.data)
}

export async function postFormData(url, formData, config = {}) {
  if (axiosApi.defaults.headers.common["Authorization"] == null && localStorage.getItem("btnToken")) {
    axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("btnToken");
  }
  
  return await axiosApi.post(url, formData, { ...config })
                      .then(response => response.data)
}

export async function put(url, data, config = {}) {
  if (axiosApi.defaults.headers.common["Authorization"] == null && localStorage.getItem("btnToken")) {
    axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("btnToken");
  }

  return await axiosApi.put(url, { ...data }, { ...config })
                      .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  if (axiosApi.defaults.headers.common["Authorization"] == null && localStorage.getItem("btnToken")) {
    axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("btnToken");
  }

  return await axiosApi.patch(url, { ...data }, { ...config })
                      .then(response => response.data)
}

export async function del(url, config = {}) {
  if (axiosApi.defaults.headers.common["Authorization"] == null && localStorage.getItem("btnToken")) {
    axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("btnToken");
  }

  return await axiosApi.delete(url, { ...config })
                      .then(response => response.data)
}
