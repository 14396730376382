import React, { Component } from "react"
import { Card, Col, Row } from "reactstrap"

import { getCurrencySymbol } from 'scripts/general'
import { deadlineHasPassed } from "./proposalFilterOptions";

import ThemeAvatar from "theme/images/ThemeAvatar";
import TutorStarRating from "components/ratings/TutorStarRating";

export default class MarketProposalSummaryCard extends Component {
    showDetails = () => {
        let { proposal, onShowDetails } = this.props;
        onShowDetails(proposal.id)
    }

    render() {
        let { proposal } = this.props;
        let currencySymbol = getCurrencySymbol(proposal.currency || "usd");
        let rate = proposal.rate;

        let expired = deadlineHasPassed(proposal);
        
        return (
            <Card style={expired == true || proposal.studentDecision == false ? {backgroundColor: "#f0f0f0", border: "1px lightgray solid"} : {backgroundColor: "white", border: "1px lightgray solid"}}>
                <Row>
                    <Col>
                        <a onClick={this.showDetails}>
                            <div className="text-center p-4 border-end">
                                <div className="avatar-xl mx-auto mb-2 mt-1">
                                    <ThemeAvatar avatar={proposal.tutorAvatar} name={proposal.tutorName} size="xl" rounded="rounded" />
                                </div>
                                <br />
                                <center>
                                    <h4 className="text-dark mb-1">
                                        {proposal.tutorName}
                                    </h4>
                                    <p className="font-size-15 text-muted text-truncate" style={{fontWeight: "bold"}}>
                                                {proposal.education}<br />
                                                {proposal.major}
                                            </p>
                                    <h5>
                                        {proposal.city && <span className="text-muted">{proposal.city}, {proposal.state}</span>}
                                    </h5>
                                    
                                    {proposal.tutorRating > 0 && <TutorStarRating rating={proposal.tutorRating} reviewsCount={proposal.reviewsCount} />}
                                    
                                    <br /><br />
                                    <h5 className="text-danger mb-1 mb-lg-3">
                                        {currencySymbol}{rate}/hr
                                    </h5>
                                    {
                                        proposal.studentDecision != false && expired && <b>Deadline Has Passed</b>
                                    }
                                    {
                                        proposal.studentDecision == false && <b>Declined</b>
                                    }
                                </center>
                            </div>
                        </a>
                    </Col>
                </Row>
            </Card>
        )
    }
}
