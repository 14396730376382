import { del, get, post, put } from "../helpers/api_helper"
import { POST_TUTOR_EVALUATION, GET_TUTOR_EVALUATIONS,
        GET_ASSIGNMENT_TO_EVALUATE, POST_SUBMIT_TUTOR_EVALUATION_DETAILED } from '../api/urls';

export const postSubmitTutorEvaluation = formData => {
    return post(POST_TUTOR_EVALUATION, formData);
}

export const getTutorEvaluations = tutorId => {
    return get(GET_TUTOR_EVALUATIONS + "/" +tutorId);
}

//Detailed Evaluations
export const getAssignmentToEvaluate = assignmentId => {
    return get(GET_ASSIGNMENT_TO_EVALUATE + "/" + assignmentId);
}

export const submitDetailedEvaluation = formData => {
    return post(POST_SUBMIT_TUTOR_EVALUATION_DETAILED, formData);
}