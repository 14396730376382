import { POST_TUTOR_REQUEST,
        POST_TUTOR_REQUEST_SUCCESS,
        POST_TUTOR_REQUEST_FAIL,
        POST_TUTOR_REQUEST_VALIDATION_FAIL,

        //NEW API PROCEDURE
        LOAD_TUTOR_REQUESTS,
        SAVE_TUTOR_REQUESTS_SUMMARY,
        SAVE_TUTOR_REQUESTS,
        SAVE_TUTOR_REQUEST,
        TUTOR_REQUEST_ERROR
        } from "./actionTypes";

const initialState = {
  tutorRequestsSummary: [],
  tutorRequests: [],
  error: "",
  loading: false,
  submitted: false
}

const tutorRequestsReducer = (state=initialState, action) => {
  let tutorRequests = state.tutorRequests;

  switch(action.type){
    case LOAD_TUTOR_REQUESTS:
      return {
        ...state,
        error: "",
        loading: true
      }

    case SAVE_TUTOR_REQUESTS_SUMMARY:
      return {
        ...state,
        tutorRequestsSummary: action.payload.tutorRequestsSummary,
        error: "",
        loading: false,
        submitted: false,
      }
    
    case SAVE_TUTOR_REQUESTS:
      return {
        ...state,
        tutorRequests: action.payload.tutorRequests,
        error: "",
        loading: false,
        submitted: false,
      }
    case SAVE_TUTOR_REQUEST:
      let tutorRequest = action.payload.tutorRequest;
      let requestPresent = false;
      
      //Update Existing Request
      if(tutorRequests.length > 0){
        tutorRequests.forEach(tRequest => {
          if(tRequest.id == tutorRequest.id){
            tRequest = tutorRequest;
            requestPresent = true;
          }
        });
      }

      //OR Add New Request
      if(!requestPresent){
        tutorRequests.push(tutorRequest);
      }

      return {
        ...state,
        tutorRequests,
        error: "",
        loading: false,
        submitted: false,
      }




     /////////////////////////////////////////////////////////////////////////////////////////////////// 
    case POST_TUTOR_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
        submitted: false,
      }
    case POST_TUTOR_REQUEST_SUCCESS:

      // return {
      //   ...state,
      //   tutorRequests: [...state.tutorRequests, action.payload],
      //   error: "",
      //   loading: false,
      //   submitted: true,
      // }    

      if (Array.isArray(action.payload) ) {
        tutorRequests = action.payload;
      }
      else{
        tutorRequests = [...state.tutorRequests, action.payload];
      }

      state = {
        ...state,
        tutorRequests,
        error: "",
        loading: false,
        submitted: true,
      }
      break;
    case POST_TUTOR_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        submitted: false,
      }
    case POST_TUTOR_REQUEST_VALIDATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        submitted: false,
      }

    case TUTOR_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        submitted: false,
      }
    default:
      return state
  }
  return state;
}

export default tutorRequestsReducer;
