import React, { Component } from 'react'
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as VerticalOptionDots } from "theme-chat/assets/media/icons/verticaloptiondots.svg";
import { ReactComponent as InfoSvg } from "theme-chat/assets/media/icons/infosvg.svg";
import { ReactComponent as BlockSvg } from "theme-chat/assets/media/icons/block.svg";
// import { ReactComponent as MuteNotificationsSvg } from "theme-chat/assets/media/icons/mutenotifications.svg";
// import { ReactComponent as ArchiveSvg } from "theme-chat/assets/media/icons/archive.svg";
// import { ReactComponent as DeleteSvg } from "theme-chat/assets/media/icons/delete.svg";


export default class ChatHeaderDropDown extends Component {
    render() {
        let { participants, showDetails, blockParticipant, unblockParticipant} = this.props;
        
        return (
            <Dropdown>
                <Dropdown.Toggle
                    className="text-muted hw-20 mt-2"
                    as={VerticalOptionDots}
                ></Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                    
                    <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                        onClick={() => showDetails()}
                    >
                        <InfoSvg className="hw-20 mr-2" />
                        <span>Request Details</span>
                    </Link>
                    {
                        participants.map((participant, i) =>
                        <>
                            {
                                participant.blocked == true
                                    ?
                                        <Link
                                            key={"block-" + i}
                                            to="#"
                                            className="dropdown-item align-items-center d-flex"
                                            onClick={() => unblockParticipant({participantId: participant.id, userName: participant.userName})}
                                        >
                                            <BlockSvg className="hw-20 mr-2" />
                                            <span>Unblock {participant.userName}</span>
                                        </Link>
                                    :
                                        <Link
                                            key={"block-" + i}
                                            to="#"
                                            className="dropdown-item align-items-center d-flex"
                                            onClick={() => blockParticipant({participantId: participant.id, userName: participant.userName})}
                                        >
                                            <BlockSvg className="hw-20 mr-2" />
                                            <span>Block {participant.userName}</span>
                                        </Link>
                            }
                        </>
                        
                        )
                    }

                    {/* <Link
                        className="dropdown-item align-items-center d-flex"
                        onClick={this.toggleChatSearchBox}
                        to="#"
                        >
                        <SearchSvg className="hw-20 mr-2" />
                        <span>Search</span>
                        </Link>  
                        <Link
                            className="dropdown-item align-items-center d-flex"
                            to="#"
                        >
                        <MuteNotificationsSvg className="hw-20 mr-2" />
                            <span>Mute</span>
                        </Link>
                        <Link
                            className="dropdown-item align-items-center d-flex"
                            to="#"
                        >
                            <ArchiveSvg className="hw-20 mr-2" />
                            <span>Archive</span>
                        </Link> 
                        <Link
                            className="dropdown-item align-items-center d-flex"
                            to="#"
                        >
                            <DeleteSvg className="hw-20 mr-2" />
                            <span>Delete</span>
                        </Link> 
                    */}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}
