import React, { Component } from 'react';
import { Button, Col, Input } from "reactstrap";

import { countryCallingCodes } from 'constants/country_calling_codes';

export class ThemeInput extends Component {
    render() {
        return (
            <>
                <label
                htmlFor={this.props.id || "text"}
                className="col-form-label"
                >
                   { this.props.label }
                </label>
                
                <input
                    className="form-control"
                    type="text"
                    id={this.props.id || "text"}
                    name={this.props.name || "text"}
                    value={this.props.value}
                    placeholder={this.props.placeholder || null}
                    onChange={this.props.onChange}
                    required={this.props.required || false}
                    autoFocus={this.props.autoFocus || false}
                />
            </>
        )
    }
}

export class ThemeCheckBox extends Component {
    render() {
        var color = this.props.color || "primary";
        return (
            <div className={`custom-control custom-checkbox  custom-checkbox-${color}`}>
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={this.props.id}
                    name={this.props.name}
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                />
                <label className="custom-control-label" htmlFor={this.props.id} >
                    { this.props.label }
                </label>
            </div>
        )
    }
}

export class ThemeRadio extends Component {
    render() {
        var color = this.props.color || "primary";
        return (
            <div className={`custom-control custom-radio ${color} mb-3`} >
                <input
                    type="radio"
                    className="custom-control-input"
                    id={this.props.id}
                    name={this.props.name}
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    defaultChecked={this.props.defaultChecked}
                />
                <label className="custom-control-label" htmlFor={this.props.id}>
                    { this.props.label }
                </label>
            </div>
        )
    }
}

export const ThemeSelect = (props) => {
    return (
        <>
            {
                props.label && 
                <label className="col-form-label" htmlFor={props.id || "select"}>
                    { props.label }
                </label>
            }
            <select className="custom-select" 
                    id={props.id || "select"} 
                    name={props.name || "select"} 
                    value={props.value} 
                    onChange={props.onChange}>
                {props.children}
            </select>
        </>
    )
}

export class ThemeDate extends Component {
    render() {
        return (
            <>
                <label
                    htmlFor={this.props.id || "date"}
                    className="col-form-label"
                >
                    {this.props.label}
                </label>
                <input
                    className="form-control"
                    type="date"
                    id={this.props.id || "date"}
                    name={this.props.name || "date"}
                    value={this.props.value || new Date()}
                    min={this.props.min || null}
                    max={this.props.max || null}
                    onChange={this.props.onChange}
                />
            </>
        )
    }
}



export const ThemePhoneInput = (props) =>{
    return (
       <>
       <Col sm={4}>
            <ThemeSelect id={props.dialCodeId || "dialCode"} name={props.dialCodeName || "dialCode"} value={props.dialCodeValue} onChange={props.onChangeDialCode}>
                {
                    countryCallingCodes.map((country, index) => 
                        <option key={index} value={country.dialCode}>
                        {country.dialCode} {country.name} 
                        </option>
                    )
                }
            </ThemeSelect>
        </Col>
        <Col sm={8}>
            <Input
                type="text"
                className="form-control"
                id={props.phoneId || "phone"}
                name={props.phoneName || "phone"}
                placeholder="Phone"
                onChange={props.onChangePhone}
                value={props.phoneValue}
            />
        </Col>
        </>
    )
}


export class ThemeFileUpload extends Component {
    //Single File Upload
    render() {
        const { hideButton=false } = this.props;

        return (
            <div className="input-group">
                <Input type="file" 
                        className="form-control" 
                        id={this.props.id || "file"} 
                        name={this.props.name || "file"} 
                        accept={this.props.accept}
                        onChange={this.props.onChange}
                        aria-describedby={this.props.btnId || "fileBtn"}
                        aria-label="Upload" />

                {
                    !hideButton &&
                        <Button color={this.props.color || "primary" }
                            type="button" 
                            id={this.props.btnId || "fileBtn"}
                            onClick={this.props.onClick}
                            >Upload</Button>
                }
            </div>
        )
    }
}