import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";

export default class VerticalTimelineItem extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       showDetails: false,
       showMessage: "More", //More vs Hide
    }
    this.toggleDetails.bind(this);
  }
  
  toggleDetails = () =>{
    let { showDetails } = this.state;
    let showMessage = "More"
    showDetails = !showDetails;

    if(showDetails){
      showMessage = "Hide";
    }

    this.setState({ showDetails, showMessage});
  }

  render() {
    let { active=false, icon=null, date = null, title, description, details = null, completed=false } = this.props;
    let { showDetails, showMessage } = this.state;

    return (
      <React.Fragment>
        <li className={`event-list ${active ? "active" : ""}`} style={{paddingBottom: "20px"}}>
          <div className="event-timeline-dot">
            <i
              className={
                active
                  ? "bx bxs-right-arrow-circle bx-fade-right"
                  : "bx bx-right-arrow-circle"
              }
            />
          </div>
          <div className="media">
            <div className="me-3">
              {
                date &&
                <h5 className="font-size-14">
                    { date }{" "}
                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"/>
                </h5>
              }
              {
                icon ?
                    <i className={ "bx bx-" + icon + " h2 text-primary" } />
                  :
                  <>
                    {
                      completed &&
                      <i className={ "bx bx-check h2 text-success" } />
                    }
                  </>
              }
            </div>
            <div className="media-body">
              <div>
                <h5>{title}</h5>
                {description}{" "}
                {
                  details && 
                  <Link to={"#"} onClick={this.toggleDetails}>{showMessage}</Link>  
                }
              
                {
                  showDetails &&
                  <p className="text-muted">
                    {details}
                  </p>
                }
              </div>
            </div>
          </div>
        </li>
      </React.Fragment>
    )
  }
}

VerticalTimelineItem.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.string,
  completed: PropTypes.bool
}

