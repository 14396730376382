import { SAVE_ALERT, CLEAR_ALERTS } from "./actionTypes";

const initialState = {
  alertType: "", //info, warning, danger, success, primary, dark
  message: ""
}

const alertsReducer = (state=initialState, action) => {
	switch(action.type){
    case SAVE_ALERT:
      return {
        alertType: action.alertType,
        message: action.message
      }
    case CLEAR_ALERTS:
      return {
        alertType: "",
        message: ""
      }
    default:
      return state
  }
}

export default alertsReducer;
