import React from "react"
import { UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

import { ADMIN_USER_DETAILS, TUTOR_PROFILE } from "routes/paths"

import { ThemeSoftBadge } from "theme/elements/badges"
import { formatDateWithMonth } from "scripts/general"

const userListColumns = () => [
  {
    name  : "#",
    sortable: false,
    selector: user => (
      <>
        {!user.avatar ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.firstName.charAt(0)}
            </span>
          </div>
        ) : (
          <div>
            <img
              className="rounded-circle avatar-xs"
              src={user.avatar}
              alt=""
            />
          </div>
        )}
      </>
    ),
  },
  {
    name: "Name",
    sortable: true,
    sortFunction: (rowA, rowB) => {return rowA.firstName > rowB.firstName},
    selector: user => (
      <div className="p-3">
        <h5 className="font-size-14 mb-1">
          <Link to={ADMIN_USER_DETAILS + "/" + user.id} className="text-dark">
            {user.firstName} {user.lastName}
          </Link>
        </h5>
        {
          user.city && 
            <p className="text-muted mb-0">{user.city}, {user.state}</p>
        }
        <br />
        <p className="text-muted mb-0">
          <small>
            <b>Created:</b> {formatDateWithMonth(user.createdAt)}<br />
            <b>Last Login:</b> {formatDateWithMonth(user.lastLoginDate)}
          </small>
        </p>
      </div>
    ),
  },
  {
    name: "Email",
    sortable: true,
    sortFunction: (rowA, rowB) => {return rowA.email > rowB.email},
    selector: user => (
      <>
        <h5 className="font-size-14 mb-1">
          <a onClick={() => {navigator.clipboard.writeText(user.email)}}  className="text-secondary">
            {user.email}
          </a>
        </h5>
      </>
    ),
  },
  {
    name: "Roles",
    sortable: false,
    selector: user => (
      <>
        {user.roles.map((role, key) => (
            <span key={key}>
              <ThemeSoftBadge color={(() =>{
                  switch (role) {
                    case "student":
                      return "primary";

                    case "tutor":
                        return "success";

                    case "tutor-applicant":
                    case "tutorApplicant":
                      return "warning";
                      
                    case "banned":
                      return "danger";
                      
                    case "admin":
                    case "staff":
                      return "info";
                      
                    default:
                      return "secondary";
                      
                  }})() }>{role}</ThemeSoftBadge> {" "} 
            </span>
        ))}
      </>
    ),
  },
  {
    name: "Action",
    sortable: false,
    selector: user => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <a href={"sms:"+user.phone} id={"message" + user.id}>
            <i className="bx bx-phone" />
            <UncontrolledTooltip placement="top" target={"message" + user.id}>
              Send Text
            </UncontrolledTooltip>
          </a>
        </li>
        
        {
          user.roles.includes("tutor") &&
          <li className="list-inline-item px-2">
            <Link to={TUTOR_PROFILE + "/" + user.id} id={"profile" + user.id}>
              <i className="bx bx-user-circle" />
              <UncontrolledTooltip placement="top" target={"profile" + user.id}>
                Profile
              </UncontrolledTooltip>
            </Link>
          </li>
        }
      </ul>
    ),
  },
]

export default userListColumns
