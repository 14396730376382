import React, { Component } from 'react'
import BTNMetaTags from "components/metatags/BTNMetaTags"

import ShoppingCart from 'components/cart/ShoppingCart'
import OrderSummary from 'components/cart/OrderSummary'
import { Col, Container, Row } from 'reactstrap'

export default class ViewCart extends Component {
    render() {
        return (
            <>
                <BTNMetaTags title="Cart" />
                
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <ShoppingCart editable={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{size: 6, offset: 6}}>
                            <OrderSummary confirm={false} />
                        </Col>
                    </Row> 
                </Container>
            </>
        )
    }
}
