import { createStore, applyMiddleware, compose } from "redux"

import createSagaMiddleware from "redux-saga"

import rootReducer from "./reducers"
import rootSaga from "./sagas"

//ADDED FOR PERSISTING REDUCER STORAGE
//REF: https://www.npmjs.com/package/redux-persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// )
//sagaMiddleware.run(rootSaga)

//export default store

/**
 * ADDED FOR PERSISTING REDUCER STORAGE 
 */
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

let persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }



