import { del, get, patch, post, put } from "../helpers/api_helper"
import { GET_ASSIGNMENTS_FOR_STUDENT, GET_PREVIOUS_ASSIGNMENTS_FOR_STUDENT,
            GET_ASSIGNMENTS_FOR_TUTOR, POST_ADD_RETURNING_STUDENT,
            PATCH_UPDATE_END_DATE } from '../api/urls';


//Load Assignments
export const getAssignmentsForStudent = studentId => {
    return get(GET_ASSIGNMENTS_FOR_STUDENT + "/" + studentId);
}

export const getPreviousAssignmentsForStudent = studentId => {
    return get(GET_PREVIOUS_ASSIGNMENTS_FOR_STUDENT + "/" + studentId);
}

export const getAssignmentsForTutor = tutorId => {
    return get(GET_ASSIGNMENTS_FOR_TUTOR + "/" + tutorId);
}

export const addReturningStudent = formData => {
    return post(POST_ADD_RETURNING_STUDENT, formData);
}

export const updateEndDate = formData => {
    return patch(PATCH_UPDATE_END_DATE + "/" + formData.assignmentId, formData)
}


