import { GET_ADMIN_RECENT_USERS, GET_ADMIN_DELETED_USERS, 
        POST_LOAD_ACTIVE_TUTORS,
        POST_ADMIN_SEARCH_USERS,
        GET_ADMIN_USER_DETAILS,
        GET_ADMIN_ACTIVE_ASSIGNMENTS, PATCH_ADMIN_DELETE_ASSIGNMENT,
        GET_ADMIN_PENDING_APPLICATIONS, GET_ADMIN_TUTOR_APPLICATION,
        POST_ADMIN_CREATE_FREE_BACKGROUND_CHECK_FOR_APPLICANT,
        POST_CREATE_BACKGROUND_CHECK, PATCH_UPDATE_BACKGROUND_CHECK,
        PATCH_ADMIN_DELETE_TUTOR_APPLICATION, POST_ADMIN_TUTOR_APPLICATION_EMAIL_REMINDER,
        PATCH_ADMIN_TUTOR_APPLICATION_OFFER_DECISION, PATCH_ADMIN_TUTOR_APPLICATION_HIRE_APPLICANT,
    
        // GET_ADMIN_UNADDRESSED_TUTOR_REQUESTS,
        GET_ADMIN_SEMESTER_TUTOR_REQUESTS_WITHOUT_ASSIGNMENTS,
        PATCH_ADMIN_UPDATE_TUTOR_REQUEST,
        PATCH_UPDATE_TUTOR_REQUEST_SUBJECTS,
        PATCH_ADMIN_NO_TUTOR_AVAILABLE,
        POST_ADMIN_DUPLICATE_TUTOR_REQUEST,
        POST_ADMIN_TUTOR_REQUEST_SEND_SMS_REMINDER,
        POST_ADMIN_TUTOR_REQUEST_SEND_EMAIL_REMINDER,

        POST_CREATE_POTENTIAL_TUTORS,
        PATCH_SELECTED_POTENTIAL_TUTORS_DECLINED,
        POST_CREATE_TUTOR_CONTRACTS,

        POST_ADMIN_ISSUE_PAYMENT_REFUND,
        POST_ADMIN_CREATE_BALANCE_REFUND,
        POST_ADMIN_ISSUE_BALANCE_REFUND,

        GET_ADMIN_ANALYTICS_DATA,
        POST_ADMIN_CLONE_USER,
        PATCH_ADMIN_USER_ADD_ROLE,
        PATCH_ADMIN_USER_REMOVE_ROLE,

        GET_ADMIN_ACTIVE_SCHOLARSHIP_ASSIGNMENTS,
        PATCH_ADMIN_SET_SCHOLARSHIP_ASSIGNMENT_HOURS,
        PATCH_ADMIN_ACTIVE_SCHOLARSHIP_CHARGES_MARK_AS_PAID,

    } from 'api/urls';
import { get, post, patch } from "helpers/api_helper";


export const getAdminRecentUsers = () => {
    return get(GET_ADMIN_RECENT_USERS);
}

export const getAdminDeletedUsers = () => {
    return get(GET_ADMIN_DELETED_USERS);
}

export const postAdminLoadActiveTutors = (formData = {}) => {
    return post(POST_LOAD_ACTIVE_TUTORS, formData);
}

export const postAdminLoadActiveStudents = () => {
    // return post();
}


export const postAdminSearchUsers = ({name}) => {
    return post(POST_ADMIN_SEARCH_USERS, { name });
}

export const getAdminUserDetails = userId => {
    return get(GET_ADMIN_USER_DETAILS + "/" + userId);
}

export const patchAddRole = formData => {
    return patch(PATCH_ADMIN_USER_ADD_ROLE + "/" + formData.userId, formData);
}

export const patchRemoveRole = formData => {
    return patch(PATCH_ADMIN_USER_REMOVE_ROLE + "/" + formData.userId, formData);
}

export const postCloneUser = userId => {
    return post(POST_ADMIN_CLONE_USER + "/" + userId);
}

//Assignments
export const getActiveAssignments = () => {
    return get(GET_ADMIN_ACTIVE_ASSIGNMENTS);
}

export const deleteAssignment = assignmentId  => {
    return patch(PATCH_ADMIN_DELETE_ASSIGNMENT + "/" + assignmentId);
}

//Tutor Applications
export const getPendingTutorApplications = () => {
    return get(GET_ADMIN_PENDING_APPLICATIONS);
}

export const getAdminTutorApplication = applicationId => {
    return get(GET_ADMIN_TUTOR_APPLICATION + "/" + applicationId);
}

export const createFreeBackgroundCheckForApplicant = applicationId => {
    return post(POST_ADMIN_CREATE_FREE_BACKGROUND_CHECK_FOR_APPLICANT + "/" + applicationId);
}

export const deleteTutorApplication = applicationId => {
    return patch(PATCH_ADMIN_DELETE_TUTOR_APPLICATION + "/" + applicationId);
}

export async function sendTutorApplicantEmailReminder(formData) {
    return await post(POST_ADMIN_TUTOR_APPLICATION_EMAIL_REMINDER + "/" + formData.tutorApplicantId, formData);
}

export async function tutorApplicationOfferPosition(formData) {
    return await patch(PATCH_ADMIN_TUTOR_APPLICATION_OFFER_DECISION + "/" + formData.tutorApplicantId, formData);
}

export async function adminClearBackgroundWithoutPayment(userId) {
    //formData options: paid: true/false, status: string, result: string, cleared: true/false
    const backgroundCheckId = await post(POST_CREATE_BACKGROUND_CHECK + "/" +userId);
    const formData = {backgroundCheckId, cleared: true, status: "clear", result: "clear"};
    return await adminUpdateBackgroundCheck(formData);
}

export async function adminUpdateBackgroundCheck(formData) {
    //formData options: paid: true/false, status: string, result: string, cleared: true/false
    return await patch(PATCH_UPDATE_BACKGROUND_CHECK  + "/" + formData.backgroundCheckId, formData);
}


export async function hireTutorApplicant(formData) {
    return await patch( PATCH_ADMIN_TUTOR_APPLICATION_HIRE_APPLICANT  + "/" + formData.tutorApplicantId, formData);
}

//Tutor Requests
// export async function getUnaddressedRequests() {
//     return await get(GET_ADMIN_UNADDRESSED_TUTOR_REQUESTS);
// }

export async function getRequestsWithoutAssignments() {
    return await get(GET_ADMIN_SEMESTER_TUTOR_REQUESTS_WITHOUT_ASSIGNMENTS);
}

export async function patchAdminUpdateTutorRequest(formData) {
    return await patch(PATCH_ADMIN_UPDATE_TUTOR_REQUEST + "/" + formData.tutorRequestId, formData);
}

export const updateTutorRequestSubjects = formData => {
    const tutorRequestId = formData.tutorRequestId;
    return patch(PATCH_UPDATE_TUTOR_REQUEST_SUBJECTS + "/" + tutorRequestId, formData);
}

export async function patchNoTutorAvailable(tutorRequestId) {
    return await patch(PATCH_ADMIN_NO_TUTOR_AVAILABLE + "/" + tutorRequestId);
}

export async function postAdminDuplicateTutorRequest(formData) {
    return await post(POST_ADMIN_DUPLICATE_TUTOR_REQUEST + "/" + formData.tutorRequestId, formData);
}

export async function postAdminTutorRequestSendSMSReminder(formData) {
    return await post(POST_ADMIN_TUTOR_REQUEST_SEND_SMS_REMINDER + "/" + formData.tutorRequestId, formData);
}

export async function postAdminTutorRequestSendEmailReminder(formData) {
    return await post(POST_ADMIN_TUTOR_REQUEST_SEND_EMAIL_REMINDER + "/" + formData.tutorRequestId, formData);
}


//Potential Tutors
export async function createPotentialTutors(potentialTutorsData){
    return await post(POST_CREATE_POTENTIAL_TUTORS, potentialTutorsData);
}

export async function updateSelectedPotentialTutorsDeclined(tutorRequestId){
    return await patch(PATCH_SELECTED_POTENTIAL_TUTORS_DECLINED +"/" + tutorRequestId);
}

//Tutor Contracts
export async function createTutorContracts(potentialTutorsData){
    return await post(POST_CREATE_TUTOR_CONTRACTS, potentialTutorsData);
}

//Refunds
export async function issuePaymentRefund(paymentId){
    return await post(POST_ADMIN_ISSUE_PAYMENT_REFUND + "/" + paymentId);
}

export async function createBalanceRefund(assignmentId){
    return await post(POST_ADMIN_CREATE_BALANCE_REFUND + "/" + assignmentId);
}

export async function issueBalanceRefund(refundId){
    return await post(POST_ADMIN_ISSUE_BALANCE_REFUND + "/" + refundId);
}

//Analytics
export async function getAnalyticsData(){
    return await get(GET_ADMIN_ANALYTICS_DATA,);
}

//Scholarships
export async function getActiveScholarshipAssignments(){
    return await get(GET_ADMIN_ACTIVE_SCHOLARSHIP_ASSIGNMENTS);
}

export async function patchSetScholarshipAssignmentHours(formData){
    return await patch(PATCH_ADMIN_SET_SCHOLARSHIP_ASSIGNMENT_HOURS + "/" + formData.scholarshipAssignmentId, formData);
}

export async function patchMarkChargesAsPaid(formData){
    return await patch(PATCH_ADMIN_ACTIVE_SCHOLARSHIP_CHARGES_MARK_AS_PAID, formData);
}