import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from "react-select"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Button, Col, Row } from 'reactstrap'

import { timezones } from 'constants/timezones';

import CalendarEventModal from './CalendarEventModal'
import TimeGridCalendar from './TimeGridCalendar'

class TutorCalendar extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        modal: false,
        isEdit: false,
        event: {},
        selectedStartDate: null,
        selectedEndDate: null,

        timezone: this.props.timezone || "America/Chicago",
        showTzSelect: false,
        assignments: []
      }
      
      this.toggle = this.toggle.bind(this)
    }

    componentDidMount() { 
        //load active assignments
        let { onSubmitApiCall, user={} } = this.props; 

        if(user != null && user.id != undefined){
            onSubmitApiCall("getAssignmentsForTutor", user.refId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal,
        }))
    }

    onDateSelected = ({selectedStartDate, selectedEndDate}) => {
        this.setState({selectedStartDate, selectedEndDate});
        this.toggle();
    }

    onEventClicked = event => {
        this.setState({
            event,
            isEdit: true,
        })

        this.toggle();
    }

    onEventDropped = ({title, selectedStartDate, element}) =>{
        console.log("drop", {title, selectedStartDate, element});
    }

    onCreateNewEvent = formType => {
        this.setState({ formType, isEdit: false, event: {}, modal: true, selectedStartDate: null });
    }

    createTutorAvailabilityEvent = formData => {
        let { user, onSubmitApiCall } = this.props;
        formData.userId = user.refId;
        onSubmitApiCall("createTutorAvailableEvent", formData);
    }

    createTutorSessionEvent = formData => {
        let { user, onSubmitApiCall } = this.props;
        // formData.calendarId = profile.calendarId;
        formData.creatorId = user.refId;
        formData.userId = user.refId;
        
        onSubmitApiCall("createTutorSessionEvent", formData);
    }

    updateEvent = formData => {
        let { user, onSubmitApiCall } = this.props;

        let method = "";
        let { type } = formData;
        
        switch (type) {
            case "session":
                method = "updateTutorSessionEvent";
                break;
            case "availability":
                method = "updateTutorAvailability";
                break;
            default:
                break;
        }

        formData.userId = user.refId;
        delete formData.type;

        onSubmitApiCall(method, formData);
    }

    deleteEvent = formData => {
        let { user, onSubmitApiCall } = this.props;

        let method = "";
        let { type } = formData;

        switch (type) {
            case "session":
                method = "";
                break;
            case "availability":
            default: //temp
                method = "deleteTutorAvailability";
                break;
        }

        formData.userId = user.refId;
        
        onSubmitApiCall(method, formData);
        this.toggle;
    }

    removeEvent = eventId => {
        // let event = this.calendarComponentRef.current?.getApi().getEventById(eventId);
        // event.remove();
    }

    /**
     * Handling submit event on event form
     */
    onEventSubmitted =  values => {
        // const { onCreateTutorSession, onCreateTutorAvailability, onUpdateEvent } = this.props;
       
        const { isEdit, formType } = this.state

        if (isEdit) {
            this.updateEvent(values);

        } else {
            switch (formType) {
                case "session":
                    this.createTutorSessionEvent(values);
                    break;
                case "availability":
                    this.createTutorAvailabilityEvent(values);
                    break;
                default:
                    break;
            }
        }

        this.setState({ selectedStartDate: null })
        this.toggle()
    }

    render() {
        let { googleCalendarId, admin=false, owner=false, apiResponse: { loading } } = this.props;
        let { assignments=[], showTzSelect, timezone, event, modal, isEdit, formType,
                selectedStartDate, selectedEndDate } = this.state;

        let tzName = timezones.filter(tz => {return tz.utc.includes(timezone)})[0].value;
        let tzArray = [];
        timezones.forEach(tz => tzArray.push({value: tz.utc[0], label: tz.value}))
      
        return (
            <>
                <h4>Events Are In {tzName}</h4>
                <a className='text-primary' onClick={() => this.setState({showTzSelect: !showTzSelect})}>{showTzSelect ? "Hide Time Zone Select" : "Change Time Zone"}</a>
                <br />
                {
                    showTzSelect &&
                        <Row>
                            <Col xs="6" md="5" lg="4">
                                <Select options={tzArray}
                                    placeholder="Select Time Zone"
                                    onChange={selectedTz => {this.setState({timezone: selectedTz.value})}} 
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </Col>
                        </Row>
                }

                { (admin == true || owner == true) && 
                    <Row className='mt-3 mb-3'>
                        <Col xs={4} sm={3}>
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => this.onCreateNewEvent("session")}
                            >
                                <i className="mdi mdi-calendar-outline me-1" />
                                {" "}Create New Session
                            </Button>
                        </Col>
                        <Col xs={4} sm={3}>
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-secondary"
                                onClick={() => this.onCreateNewEvent("availability")}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                {" "}Add Availability
                            </Button>
                        </Col>
                    </Row>
                }
                
                <TimeGridCalendar 
                    googleCalendarId={googleCalendarId}
                    onDateSelected={this.onDateSelected}
                    onEventClicked={this.onEventClicked}
                    onEventDropped={this.onEventDropped}
                    timezone={timezone}
                    // maxSelectedHours

                    admin={admin}
                    owner={owner}
                />

                {
                    modal &&
                        <CalendarEventModal 
                            key={Math.floor(Math.random() * 10000)}
                            isOpen={modal}
                            event={event} 
                            calendarId={googleCalendarId}
                            timezone={timezone}
                            timezoneName={tzName}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            isEdit={isEdit}
                            formType={formType}
                            loading={loading}
                            assignments={assignments}
                            onSaveEvent={this.onEventSubmitted}
                            onDeleteEvent={this.deleteEvent}
                            onClose={() => this.setState({modal: false})}
                        />
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(TutorCalendar)