import React, { Component } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"

//i18n
// import { withTranslation } from "react-i18next"

import ThemeAvatar from "theme/images/ThemeAvatar"

import { USER_ACCOUNT_EDIT } from "routes/paths"
import { topBarLinks } from "routes/navigation"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "",
      avatar: null,
      roles: [],
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  render() {
    let { menu } = this.state;
    let { user: { id, firstName, avatar, roles} } = this.props
    
    let topLinks = topBarLinks.filter(link => { 
          return link.role == "all" || roles.includes(link.role) 
        });
    
    return (
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            {
              avatar 
                ? <ThemeAvatar className={"rounded-circle header-profile-user"} avatar={avatar} name={firstName}/>
                : 
                  <ThemeAvatar color="secondary" size="xs" name={firstName}/>
            }
            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {firstName}
            </span>
            {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/> */}
            <i className="mdi mdi-chevron-down d-xl-inline-block"/>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href={USER_ACCOUNT_EDIT +"/" + id}>
              <i className="bx bx-wrench font-size-17 align-middle mr-1"/>
              Edit Account
            </DropdownItem>
            {
              topLinks.map((topLink, i) =>
              <DropdownItem key={i} tag="a" href={topLink.path}>
                <i className={`${topLink.icon} font-size-16 align-middle mr-1`}/>
                {topLink.title}
              </DropdownItem>
              )
            }
           
            <div className="dropdown-divider"/>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"/>
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any
}

const mapStateToProps = ({userReducer}) => ({
  user: userReducer
})

export default withRouter(
  connect(mapStateToProps, null) (ProfileMenu)
)

//export default withRouter(ProfileMenu)
// export default withRouter(withTranslation()(ProfileMenu))