import { GET_USER_CONTACT_INFO, PATCH_USER_CONTACT_INFO, PATCH_USER_POSTAL_CODE,
          GET_USER_PAYMENT_METHODS, PATCH_MAKE_CARD_DEFAULT_PAYMENT_METHOD,
          GET_ACCOUNT_USER_DETAILS,
          GET_ACCOUNT_STUDENT_REQUESTS_SUMMARY,

          GET_PAYMENTS_FOR_STUDENT, GET_TRANSFERS_FOR_TUTOR
        } from 'api/urls';
import { get, patch } from "helpers/api_helper";

/**
 * Dashboard Calls
 */
export const getAccountUserDetails = userId =>{
  return get(GET_ACCOUNT_USER_DETAILS + "/" + userId);
}

export const getAccountStudentRequestsSummary = userId => {
  return get(GET_ACCOUNT_STUDENT_REQUESTS_SUMMARY + "/" + userId);
}

/**
 * Edit Account Calls
 */
export const getContactInfo = userId => {
  let url = GET_USER_CONTACT_INFO +"/"+ userId;
  return get(url, {});
}

export const updateContactInfo = async (formData) => {
  let url = PATCH_USER_CONTACT_INFO +"/"+ formData.userId;
  return await patch(url, formData);
}

export const updatePostalCode = formData => {
  let url = PATCH_USER_POSTAL_CODE +"/"+ formData.userId;
  return patch(url, formData);
}

/**
 * Payment Settings
 */

export const getUserPaymentMethods = userId => {
  return get(GET_USER_PAYMENT_METHODS +"/"+ userId);  
}

export const makeCardDefaultPaymentMethod = cardId => {
  return patch(PATCH_MAKE_CARD_DEFAULT_PAYMENT_METHOD +"/"+ cardId);  
}

/**
 * Payments
 */

export const getStudentPayments = userId => {
  return get(GET_PAYMENTS_FOR_STUDENT +"/"+ userId);  
}

export const getTutorTransfers = userId => {
  return get(GET_TRANSFERS_FOR_TUTOR +"/"+ userId);  
}
