import React, { Component } from 'react'
import ReactJoyride, { ACTIONS, STATUS } from 'react-joyride'
import { Button } from 'reactstrap';

export default class BasicTour extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         run: this.props.run || false
      }
    }

    handleClickStart = event => {
        event.preventDefault();
        
        this.setState({ run: true });
      };
    
    handleJoyrideCallback = (data) => {
        const { status, action } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        ACTIONS.CLOSE
    
        if (finishedStatuses.includes(status) || action == ACTIONS.CLOSE) {
          this.setState({ run: false });
        }
    };

    render() {
        let { steps, continuous = true, showProgress = true, showSkipButton = true, 
                styleOptions = {},
                buttonText="Take A Tour", 
                showButton=true } = this.props
        let { run } = this.state;

        let tourProps = {steps, continuous, showProgress, showSkipButton}

        return (
            <>
                {showButton && <Button color='primary' outline onClick={this.handleClickStart}>{buttonText}</Button>}

                <ReactJoyride 
                    {...tourProps}
                    run = {run}
                    callback={this.handleJoyrideCallback}
                    continuous
                    scrollToFirstStep
                    styles={{
                        options: {
                            ...styleOptions,
                            // modal arrow and background color
                            arrowColor: "#eee",
                            backgroundColor: "#eee",
                            // page overlay color
                            // overlayColor: "rgba(79, 26, 0, 0.4)",
                            //button color
                            primaryColor: "mediumaquamarine",
                            //text color
                            textColor: "#333",

                            //width of modal
                            // width: 500,
                            //zindex of modal
                            zIndex: 1000
                        }
                    }}
                    locale= {{ last: <strong aria-label="last">Close</strong> }}
                />
            </>
        )
    }
}
