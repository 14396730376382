import React, { Component } from "react";

import ChatParticipantDetails from "./components/ChatParticipantDetails";
import ChatMedia from "./components/ChatMedia";
import ChatDocuments from "./components/ChatDocuments";

// User  chat detail component 
class UserChatDetails extends Component {
  //documents = {fileName, url, fileSize: "79.2 KB"}
  render() {
    let { participant, images=[], documents=[], isAdmin=false } = this.props;

    return (
        <>
          <div className="hide-scrollbar flex-fill">
            <ChatParticipantDetails participant={participant} isAdmin={isAdmin}/>

            {
              images.length > 0 &&
                <ChatMedia images={images} />
            }
            
            {
              documents.length > 0 &&
                <ChatDocuments documents={documents} />  
            }
          </div>
        </>
    );
  }
}
export default UserChatDetails;
