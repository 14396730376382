import React, { Component } from "react"

import UncontrolledBoard from "components/kanban/UncontrolledBoard"

export default class BTNKanban extends Component {
  constructor(props) {
    super(props)
    this.state = {
      board: {columns: this.props.columns || []},
    }

    this.onCardRemove.bind(this);
    this.onCardDragEnd.bind(this);
  }

  onCardRemove = (board, column, card) => {
    this.props.onCardRemove({board, column, card});
  };

  onCardDragEnd = (board, card, source, destination) => {
    //Position = array Index
    //source: {fromPosition, fromColumnId}
    //destination: {toPosition, toColumnId}
    this.props.onCardDragEnd({board, card, source, destination});
  };

  render() {
    let { key, board } = this.state;
    let { card } = this.props;

    return (
      <UncontrolledBoard key={key} board={board}
          renderCard={(cardData, { removeCard, dragging }) => (
                        React.cloneElement(card, {data: cardData, removeCard, dragging})
                      )}
          onCardDragEnd={this.onCardDragEnd}
          onCardRemove={this.onCardRemove}/>
    )
  }
}
