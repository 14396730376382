import React, { Component } from 'react'

import "../index.js"

// import "../assets/css/main.css"
import "../layouts/job1/css/job-1.css"
import '../layouts/job1/js/custom.js';

import greenCheck from "../assets/images/big-green-check.png"

export default class AnfraSuccessPage extends Component {
  render() {
    const { image } = this.props;
    return (
        <div className="ugf-bg">
            <div className="final-content" style={{padding: "0px"}}>
                <div className="icon">
                    <img src={image || greenCheck} alt="" style={{maxHeight: "15rem"}} />
                </div>
                {this.props.children}
            </div>
        </div>
    )
  }
}

