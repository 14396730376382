//NOT USED
import { POST_TUTOR_REQUEST,
          POST_TUTOR_REQUEST_SUCCESS,
          POST_TUTOR_REQUEST_FAIL,
          POST_TUTOR_REQUEST_VALIDATION_FAIL,
        } from "./actionTypes";



//Action to trigger saga execution
export const saveTutorRequest = tutorRequest => {
    return {
      type: POST_TUTOR_REQUEST,
      tutorRequest,
    }
}

export const saveTutorRequestSuccess = tutorRequest => {
    return {
      type: POST_TUTOR_REQUEST_SUCCESS,
      payload: tutorRequest,
    }
}

export const saveTutorRequestFail = errorMesssage => {
    return {
      type: POST_TUTOR_REQUEST_FAIL,
      payload: errorMesssage,
    }
}

export const tutorRequestValidationFail = (error, validationErrors, invalidFields) => {
    return {
      type: POST_TUTOR_REQUEST_VALIDATION_FAIL,
      error,
      validationErrors,
      invalidFields
    }
}
