//CDN Libraries: <p>\( f'(x)=\left(x+1\right)\left(x-1\right)^2\left(x-2\right).\;f \) has</p>
export function loadMathJax(output = "chtml"){
  const script = document.createElement("script");

  //TEX2CTHML vs Tex2SVG
  script.src = output == "chtml"
                ? "https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.1.4/es5/tex-chtml.min.js"
                : "https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.1.4/es5/tex-svg.min.js";

  script.async = true;

  document.body.appendChild(script);
}
