import React, { Component } from 'react'
import moment from "moment"
import { connect } from "react-redux"
import { Link, Redirect } from 'react-router-dom'

import { submitApiCallAction  } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { replaceAllLinks } from 'scripts/chat';

import { Col, Row } from 'reactstrap';
// import { getCurrencySymbol } from 'scripts/general';

import ProposalTutorView from './components/ProposalTutorView'
import ProposalStudentView from './components/ProposalStudentView'
import TutorView from './components/TutorView'
import StudentView from './components/StudentView'
import { ThemeSoftBadge } from 'theme/elements/badges'
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton'
import { STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION, STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE } from 'routes/paths'

class ChatRequestDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            details: {},
            showRateDetails: true,
            showRequestDetails: true,

            contractId: null,
            scholarship: false,

            redirect: false
        }
    } 

    componentDidMount() { 
        this.loadDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
    
            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    toggleShowRequestDetails = () => {
        this.setState({ showRequestDetails: !this.state.showRequestDetails });
    };

    toggleShowRateDetails = () => {
        this.setState({ showRateDetails: !this.state.showRateDetails });
    };

    loadDetails = () => {
        const { chat, onSubmitApiCall } = this.props
        // let { loadedDetails, details } = this.state;
        
        let type = null;
        let objectId = null;
        let threadId = chat.threadId;

        if(chat.proposalId != null){
            type = "proposal";
            objectId = chat.proposalId;
        }
        else if(chat.contractId != null){
            type="contract";
            objectId = chat.contractId;
        }
        else{
            alert("Unable to load details");
            return;
        }

        // //Search if details already loaded
        // let alreadyLoaded = false;
        // loadedDetails.some(loadedDetail =>{
        //     if(loadedDetail.threadId == details.threadId){
        //         alreadyLoaded = true;
        //         return true;
        //     }
        // });

        // if(!alreadyLoaded){
        //     onSubmitApiCall("getChatDetails", { type, objectId, threadId });
        // }
        onSubmitApiCall("getChatDetails", { type, objectId, threadId });
    }

    // updateRate = ({ proposalId, studentRate, studentCurrency }) => {
    updateRate = ({ proposalId, studentRate }) => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("updateProposalRate", {studentRate, proposalId});
        // const { user, onSubmitApiCall, pusher } = this.props;
        // let { currentThreadId } = this.state;

        // const socketId = pusher != null ? pusher.connection.socket_id : null;
        // // const messageObject = {
        // //     id: Math.floor(Math.random() * 100),
        // //     threadId: currentThreadId,
        // //     userId: user.id,
        // //     // userId: process.env.REACT_APP_ADMIN_REF_ID,
        // //     body: "SYSTEM MESSAGE: Rate Changed To " + getCurrencySymbol(studentCurrency) + studentRate.toFixed(2) + "/hr",
        // //     createdAt: new Date(),
        // //     socketId
        // // }
        
        // // onSubmitApiCall("submitChatMessage", messageObject);
    }

    acceptProposal = ({ proposalId }) => {
        const { onSubmitApiCall } = this.props
        onSubmitApiCall("acceptProposalCreateContract", proposalId);
        this.setState({redirect: true});
    }

    rejectProposal = ({ proposalId, rejectReason }) => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("rejectProposal", {proposalId, rejectReason});
    }
    
    extendProposalDeadline = ({ proposalId })  => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("extendProposalDeadline", proposalId);
    }

    reactivateProposal = ({ proposalId })  => {
        const { onSubmitApiCall } = this.props;
        onSubmitApiCall("reactivateProposal", proposalId);
    }

    render() {
        let { details, showRateDetails, showRequestDetails,
                contractId, redirect } = this.state;
        let { apiResponse } = this.props;

        let loading = apiResponse.loading;

        if(redirect && (details.contractId != null || contractId != null)){
            let redirectURL = details.scholarship ? STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION : STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE;
            redirectURL += "/" + (details.contractId || contractId);
            return  <Redirect to={redirectURL} />
        } 
        
        return (
            <>
            {
                details.tutorRequestId 
                    ?
                        <>
                            <div className="chat-info-group">
                                <Link
                                    className="chat-info-group-header"
                                    data-toggle="collapse"
                                    to="#"
                                    onClick={this.toggleShowRequestDetails}
                                >
                                    <h6 className="mb-0">Tutor Request Details</h6>
                                </Link>

                                <div
                                    className={
                                        "chat-info-group-body collapse " +
                                        (showRequestDetails ? "show" : "")
                                    }
                                    id="request-details"
                                >
                                    <div className="chat-info-group-content list-item-has-padding">
                                        <ul className="list-group list-group-flush ">
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Course</p>
                                                <p className="mb-0">
                                                    {details.studentEmail ? replaceAllLinks(details.course): details.course}
                                                    {
                                                        details.school != null && details.school.trim() != "" &&
                                                        <>@ {details.school}</>
                                                    }
                                                </p>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Dates</p>
                                                <p className="mb-0">
                                                    {details.startDate && <>{moment(details.startDate).format("MMM D")}</>} to {details.endDate && <>{moment(details.endDate).format("MMM D YYYY")}</>}
                                                    <br />
                                                    <small>* Listed start & end dates are estimates.</small>
                                                </p>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Sesson Time:</p>
                                                <p className="mb-0">{details.studentEmail ? replaceAllLinks(details.sessionTime): details.sessionTime}</p>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Frequency: </p>
                                                <p className="mb-0">{details.studentEmail ? replaceAllLinks(details.frequency) : details.frequency}</p>
                                            </li>
                                            {
                                                details.requestConcerns && 
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Concerns: </p>
                                                    <p className="mb-0">{details.studentEmail ? replaceAllLinks(details.requestConcerns) : details.requestConcerns}</p>
                                                </li>
                                            }
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Type: </p>
                                                <p className="mb-0"> {details.inPerson == true && <ThemeSoftBadge >In-Person Tutoring</ThemeSoftBadge>} {details.online == true && <ThemeSoftBadge color="success">In-Person Tutoring</ThemeSoftBadge>}</p>
                                            </li>
                                            {
                                                details.scholarship == true && 
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Scholarship: </p>
                                                    <p className="mb-0">This student's scholarship program will pay for sessions. Please contact us for more details.</p>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center p-3">
                                {
                                    (details.userRole == "tutor" || details.userRole == "admin" )&&
                                        <ProposalTutorView details={details} loading={loading} onRejectProposal={this.rejectProposal} onReactivateProposal={this.reactivateProposal} onExtendProposalDeadline={this.extendProposalDeadline} />
                                }
                                {
                                    details.userRole == "student" &&
                                        <ProposalStudentView details={details} loading={loading} onAcceptProposal={this.acceptProposal} onRejectProposal={this.rejectProposal} />
                                }
                            </div>

                            <div className="chat-info-group">
                                <Link
                                    className="chat-info-group-header"
                                    data-toggle="collapse"
                                    to="#"
                                    onClick={this.toggleShowRateDetails}
                                >
                                    <h6 className="mb-0">Rate Details</h6>
                                </Link>

                                <div
                                    className={
                                        "chat-info-group-body collapse " +
                                        (showRateDetails ? "show" : "")
                                    }
                                    id="request-details"
                                >
                                    <div className="chat-info-group-content list-item-has-padding">
                                        <Row>
                                            <Col className="pl-4">
                                                {
                                                    details.userRole == "tutor" &&
                                                        <TutorView key={details.threadId} details={details} onUpdateRate={this.updateRate}/>
                                                }
                                                {
                                                    details.userRole == "student" &&
                                                        <StudentView key={details.threadId} details={details} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    :
                        <ThemeSkeleton />
            }
            </>
        )
    } 
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChatRequestDetails)
  