import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import TutorApplicationsTable from 'components/tables/tutor-applications/TutorApplicationsTable'
import { Card, CardBody } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'

class TutorApplicationsList extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             applications: []
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getPendingTutorApplications");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    render() {
        const { applications } = this.state;
        const { apiResponse } = this.props;
        let loading = apiResponse.loading
        return (
            <>
                <BTNMetaTags title="Tutor Applications" />
                
                <Card>
                    <CardBody>
                        {
                            loading &&
                                <center><ThemeSpinner /></center>
                        }
                        <TutorApplicationsTable key={"applications-" + applications.length} applications={applications}/>
                    </CardBody>    
                </Card> 
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationsList)
