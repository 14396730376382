import React, { Component } from 'react'
import { connect } from 'react-redux'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { countryListAllIsoData } from 'constants/country_ISO_codes'

import { ThemeSpinner } from 'theme/elements/spinner'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ThemeSelect } from 'theme/elements/styledInputs'
import { ThemeButton } from 'theme/elements/buttons'

class CreateStripeAccount extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             accountId: "",
             onboardingLink: "",
             userCountry: "US"
        }
    }
    
    //1) Check If Account ID Exists
    //2) Create Account
    //3) Redirect To Stripe Onboarding Flow
    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getAccountId");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                if(newState.onboardingLink != null && newState.onboardingLink != ""){
                    window.location.href = newState.onboardingLink;
                }
                else if(prevState.accountId != newState.accountId 
                    && newState.accountId != null && newState.accountId != ""){
                       this.getOnboardingLink(newState.accountId); 
                }
                
                this.setState(newState);
            }
        }
    }

    createAccount = () =>{
        let { onSubmitApiCall } = this.props;
        let { userCountry } = this.state;
        onSubmitApiCall("createConnectAccount", {country: userCountry});
    }

    getOnboardingLink = accountId => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("createOnboardingLink", accountId);
    }

    render() {
        const { apiResponse } = this.props;
        let { accountId, userCountry } = this.state;
        const loading = apiResponse.loading;
        userCountry = userCountry || "US";

        return (
            <>
                <BTNMetaTags title="Connect To Stripe" />

                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                {
                                    accountId != null && accountId != ""
                                        ?
                                            <center><CardTitle>Redirecting To Stripe...</CardTitle></center>
                                        :
                                            <center><CardTitle>Connect To Stripe</CardTitle></center>
                                }
                                {
                                    loading && 
                                        <center><ThemeSpinner /></center>
                                }
                                {
                                    apiResponse.success && (accountId == null || accountId == "") && 
                                        <Row>
                                            <Col sm={4}>
                                                <ThemeSelect id="country" name="country" label="You (Or Your Business) Location" value={userCountry.toUpperCase()} onChange={event => this.setState({userCountry: event.target.value})}>
                                                    {
                                                        countryListAllIsoData.map((country, index) => 
                                                            <option key={index} value={country.code}>
                                                                {country.name} 
                                                            </option>
                                                        )
                                                    }
                                                </ThemeSelect>
                                                <br /><br />
                                                <ThemeButton onClick={this.createAccount}>Submit</ThemeButton>
                                            </Col>
                                        </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
})
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateStripeAccount)
