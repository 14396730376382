import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { ReactComponent as PhotoSvg } from "theme-chat/assets/media/icons/photo.svg";
import { ReactComponent as DocsSvg } from "theme-chat/assets/media/icons/docs.svg";
import { replaceAllLinks } from "scripts/chat";
import { getAvatarClass } from "../scripts";
import { proposalSortOptions } from "pages/marketplace/tutor-request-progress/proposalFilterOptions";

const ChatListItem = (props) => {
  let { chat, isAdmin, user } = props;

  let participants = chat.participants || [];
  let participantNames = participants.map(participant => {return participant.userName != null ? participant.userName : "[deleted]"}).join(', ');

  let hasBlockedParticipant = false;
  participants.forEach(participant => {
    participant.statusClass = getAvatarClass(participant, isAdmin);
    if(participant.blocked == true){
      hasBlockedParticipant = true;
    }
  });

  if(!isAdmin){
    participants = participants.filter(participant => {return participant.userId != user.id});
  }

  return (
    <li
      className={
        "contacts-item friends " +
        (chat.active
          ? "active "
          : " ") +
        (chat.isUnread ? "unread" : "")
      }
    >
      <Link
        className="contacts-link"
        to="#"
        onClick={props.handleChatClick}
      >
        {participants.map((participant, i) =>
          participant.avatar ?
            <div key={"link-"+i} className={"avatar " + participant.statusClass}>
              <img src={participant.avatar} alt=""></img>
            </div>
          :
            <>
              {
                participant.userName &&
                  <div key={"link-" + i} className={"avatar bg-info text-light " + participant.statusClass}>
                    <span style={{fontSize: "1.5em"}}>{participant.userName.substring(0,1)}</span>
                  </div>
              }
            </>
        )}
        
        <div className="contacts-content">
          <div className="contacts-info">
            <h6 className="chat-name text-truncate">{participantNames}</h6>
            <div className="chat-time">
              {
                moment().diff(chat.lastMessageDate, 'days') < 7 ?
                  <>
                      {
                          moment().diff(chat.lastMessageDate, 'days') < 3 ?
                              moment(chat.lastMessageDate).fromNow()
                              :
                              moment(chat.lastMessageDate).format("ddd")
                      }
                  </>    
                  
                  :
                  <>
                  {
                      moment().diff(chat.lastMessageDate, 'days') > 365 ?
                          moment(chat.lastMessageDate).format("l")
                          :
                          moment(chat.lastMessageDate).format("MMM Do")
                  }
                  </>
                      
              }
            </div>
          </div>
          <div className="contacts-texts">
            {chat.lastMessage == "[Image]" ? <PhotoSvg /> : null}
            {chat.lastMessage == "[File]" ? <DocsSvg /> : null}

            <p className="text-truncate">{chat.showLinks == true ? chat.lastMessage : replaceAllLinks(chat.lastMessage)}</p>
            {/* {props.endIcon ? (
              <div className="d-inline-flex align-items-center ml-1">
                {props.endIcon}
              </div>
            ) : null} */}
            { chat.unreadCount > 1 && (
                <div className="badge badge-rounded badge-primary ml-1">
                  {chat.unreadCount}
                </div>
            )}
            { isAdmin && chat.flagged == true  && (
                <div className="badge badge-rounded badge-warning ml-1">
                  Flagged
                </div>
            )}
            { isAdmin && chat.disabled == true && (
                <div className="badge badge-rounded badge-danger ml-1">
                  Disabled
                </div>
            )}
            { hasBlockedParticipant && (
                <div className="badge badge-rounded badge-dark ml-1">
                  Blocked
                </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};
export default ChatListItem;
