import React, { Component } from 'react'
import { connect } from 'react-redux'

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import TutorRequestLeadsTable from 'components/tables/leads/TutorRequestLeadsTable'
import { ThemeSpinner } from 'theme/elements/spinner'
import { Col, Row, Container, Card, CardBody } from 'reactstrap'

class ActiveLeads extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             leads: [],
        }
    }

    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getLeadsForTutors");
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }   

    render() {
        let { leads } = this.state;
        let { apiResponse } = this.props;

        let loading = apiResponse.loading;

        return (
            <>  
                <h3>Leads</h3>
                    <ol>
                        <li>Review request details and select the ones you can provide assistance for.</li>
                        <li>Place a max bid for each tutor request and add an introductory message.</li>
                        <li>At the specified deadline, all tutors will be compiled and made available to the student. Students will receive a notification</li>
                        <li>Student will have access to each tutor's contact information and tutor details listed above.</li>
                        <li>BuffTutor makes no guaranteed as to whether students will contact you (so be sure to write a good introductory message).</li>
                        <li>Students are not obligated to contact you or work with you.</li>
                        <li>Students obtained through this method will be your clients and there will be no further connection with BuffTutor.</li>
                    </ol>    
                <p>{leads.length} Leads</p>
                <div className="account-pages my-3 pt-sm-3">
                    <Container>
                        {/* <Row className="justify-content-center"> */}
                        <Row>
                            <Col md={12} lg={10} xl={8}>
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-6">
                                        <div className="p-2">
                                            {
                                                loading && 
                                                    <center><ThemeSpinner /></center>
                                            }
                                            <TutorRequestLeadsTable key={"leads-"+leads.length} role={"tutor"} leads={leads} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}


const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ActiveLeads)