import React, { Component } from 'react'
import { connect } from "react-redux"

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from 'formik';
import { Alert, Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap"
import { ThemeInput } from 'theme/elements/styledInputs'

import { validateEmail, validateName, validatePassword } from 'scripts/authentication'
import { processApiResponse } from 'scripts/api'

import { triggerReducerLoadingAction, submitApiCallAction, submitApiCallWithReducerAction } from "store/actions"
import { ThemeButton } from 'theme/elements/buttons'
import { LOADING_USER, SAVE_USER, USER_ERROR } from 'store/user/actionTypes'

import { countryCallingCodes } from 'constants/country_calling_codes';
import EditContactInfoForm from '../formik/edit-account/EditContactInfoForm';

class EditUserInfoForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            userId: this.props.userId,
            // user: {},

             firstName: this.props.user.firstName || "",
             lastName: this.props.user.lastName || "",
             email: this.props.user.email || "",
             dialCode: this.props.user.dialCode || "",
             phone: this.props.user.phone || "",

             postalCode: this.props.user.postalCode || "",
             timezone: this.props.user.timezone || "",
             city: this.props.user.city || "",
             state: this.props.user.state || "",

             password: "",
             password_confirmation: "",

             token: "",
             error: ""
        }
        this.onSubmitContactInfo.bind(this)
        this.onSubmitPassword.bind(this)
        this.onSubmitPostalCode.bind(this)
    }
    
    componentDidMount() {
        const { onSubmitApiCall } = this.props;
        const { userId } = this.state
        onSubmitApiCall("getContactInfo", userId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { user, apiResponse = {} } = this.props;
        let { userId } = this.state;
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                newState.dialCode = newState.dialCode == null || newState.dialCode == "" ? "+1" : newState.dialCode;
                

                //Update Token if user (not admin)
                if(user.id == userId){
                    if((newState.token != null && newState.token != "")
                        || (prevProps.user.token != this.props.user.token
                                    && this.props.user.token != null && this.props.user.token != "" 
                                    && localStorage.getItem("btnToken") != this.props.user.token)){
                        
                        let token = newState.token ? newState.token : this.props.user.token;
                        let tokenExpiration = newState.tokenExpiration ? newState.tokenExpiration : this.props.user.tokenExpiration;
                        localStorage.setItem("btnToken", token);
                        localStorage.setItem("tokenExpiration", tokenExpiration);
                    }
                }

                this.setState(newState);
            }
        }
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              ...this.state,
              [name]: value
        });
    }

    onSubmitContactInfo = async (values) =>{
        let { firstName, lastName, email } = values;
        let { userId } = this.state;
        const { user, onReducerLoading, onSubmitApiCallWithReducer, onSubmitApiCall } = this.props;
        
        let valid = true;

        if(!validateEmail(email)){
            valid = false;
            this.setState({error: "Invalid Email"});
        }

        if(!validateName(firstName)){
            valid = false;
            this.setState({error: "Invalid First Name"});
        }

        if(!validateName(lastName)){
            valid = false;
            this.setState({error: "Invalid Last Name"});
        }

        if(valid){
            if(user.id != userId){
                await onSubmitApiCall("updateContactInfo", { ...values, userId });
            }
            else{
                onReducerLoading(LOADING_USER);
                await onSubmitApiCallWithReducer("updateContactInfo", SAVE_USER, { ...values, userId }, USER_ERROR);
            }
        }
    }

    onSubmitPostalCode = async (event) => {
        event.preventDefault();
        let { postalCode, userId } = this.state;
        
        const { user, onReducerLoading, onSubmitApiCallWithReducer, onSubmitApiCall } = this.props;

        if(user.id != userId){
            await onSubmitApiCall("updatePostalCode", { userId, postalCode });
        }
        else{
            onReducerLoading(LOADING_USER);
            await onSubmitApiCallWithReducer("updatePostalCode", SAVE_USER, { userId, postalCode }, USER_ERROR);
        }
        
        this.setState({postalCode: ""});
    }

    onSubmitPassword = event => {
        event.preventDefault();
        let { userId, password, password_confirmation } = this.state;
        let { onSubmitApiCall } = this.props;
        
        if(validatePassword(password) && password == password_confirmation ){
            onSubmitApiCall("updateBTNPassword", { userId, password });
        }
        else{
            this.setState({error: "Invalid Password"});
        }
        
        this.setState({password: "", password_confirmation: ""});
    }

    render() {
        let { user, apiResponse } = this.props;
        let { firstName, lastName, email, dialCode, phone,
            password, password_confirmation, postalCode, error } = this.state;
        postalCode = postalCode != null ? postalCode : "";

        let loading = user.loading || apiResponse.loading;

        return (
            <>
                {
                    loading &&
                        <center><Spinner color="info" /></center>
                }
                <Card>
                    <CardBody>
                        <CardTitle>Contact Information</CardTitle>
                        <Row>
                            <Col>
                                { error && <Alert color="danger">{error}</Alert> }
                                <EditContactInfoForm initialValues={{firstName, lastName, email, dialCode, phone}} loading={loading} onSubmit={this.onSubmitContactInfo} />

                                {/* <Formik
                                    initialValues={{firstName, lastName, email, dialCode, phone}}
                                    validationSchema={ContactInfoSchema}
                                    onSubmit={this.onSubmitContactInfo}
                                >
                                    { props  => (
                                        <Form className="needs-validation">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for="firstName">First Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            className="form-control"
                                                            value={props.values.firstName}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                        />
                                                        <ErrorMessage name="firstName" component="div" className="text-danger" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for="lastName">Last Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            className="form-control"
                                                            value={props.values.lastName}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                        />
                                                        <ErrorMessage name="lastName" component="div" className="text-danger" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup className="mb-3">
                                                        <Label for="email">Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            label="Email"
                                                            placeholder="Email Address"
                                                            className="form-control"
                                                            value={props.values.email}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                        />
                                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label for="phone">
                                                            Phone <span className="flag-icon flag-icon-gr"></span>
                                                        </Label>
                                                        
                                                        <Row>
                                                            <Col sm={4}>
                                                                <Input 
                                                                    type="select" 
                                                                    name="dialCode" 
                                                                    className="custom-select" 
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur}
                                                                    value={props.values.dialCode}
                                                                    required>
                                                                    {
                                                                        countryCallingCodes.map((country, index) => 
                                                                            <option key={index} value={country.dialCode}>
                                                                            {country.dialCode} {country.name} 
                                                                            </option>
                                                                        )
                                                                    }
                                                                </Input>
                                                            </Col>
                                                            <Col sm={8}>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        name="phone"
                                                                        placeholder="Phone"
                                                                        className="form-control"
                                                                        value={props.values.phone}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                    />
                                                                    <ErrorMessage name="phone" component="div" className="text-danger" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button type="submit" color="primary" disabled={loading}>Update Contact Info</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik> */}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Location</CardTitle>
                        <span>Your location help us connect you with others in your location or timezone.</span><br />
                        {
                            this.state.city &&
                                <small className="text-info">Your Location: {this.state.city}, {this.state.state}</small>
                        }
                        {" "}
                        {
                            this.state.timezone &&
                                <small className="text-secondary">({this.state.timezone})</small>
                        }
                        <Row>
                            <Col md={4}>
                                <ThemeInput id="postalCode"
                                            name="postalCode"
                                            value={postalCode}
                                            placeholder={"ZipCode OR City, State"}
                                            onChange={this.onChangeValue}
                                />
                            </Col>
                            <Col md={8}>
                                <br />
                                {/*
                                    user.loading &&
                                        <center><Spinner color="warning" /></center>
                                */}
                                <ThemeButton color="info" onClick={this.onSubmitPostalCode} disabled={loading || postalCode == null || postalCode.trim() == ""}>Update Location</ThemeButton>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Password</CardTitle>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                <Input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    onChange={this.onChangeValue}
                                    value={this.state.password}
                                />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        placeholder="Re-enter Password"
                                        onChange={this.onChangeValue}
                                        value={this.state.password_confirmation}
                                    />
                                    {   
                                        password_confirmation.length > 2 && password != password_confirmation && 
                                            <span className="text-danger">Must be same as password</span> 
                                    }
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <br />
                                <ThemeButton color="secondary" onClick={this.onSubmitPassword} disabled={loading}>Update Password</ThemeButton>
                                { 
                                    error &&
                                        <p className="text-danger"><b>{error}</b></p>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    user: userReducer
})

const mapDispatchToProps = dispatch => ({
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfoForm)