import { call, takeEvery, put } from "redux-saga/effects"

import { SAVE_ALERT, CLEAR_ALERTS,
          SAVE_TOAST_ALERT, CLEAR_TOAST_ALERT } from "./actionTypes"

import { saveAlert, clearAlerts,
          saveToastAlert, clearToastAlert } from "./actions"

//API CALLS
// import { getUnaddressedRequests } from "../../scripts/tutorRequests"

function* saveAlertSaga(alertType, message){
  //NO CODE NEEDED
}

function* clearAlertsSaga(){
  //NO CODE NEEDED
}

function* saveToastAlertSaga(alertType, message){
  //NO CODE NEEDED
}

function* clearToastAlertsSaga(){
  //NO CODE NEEDED
}

function* alertsSaga() {
  yield takeEvery(SAVE_ALERT, saveAlertSaga)
  yield takeEvery(CLEAR_ALERTS, clearAlertsSaga)
  yield takeEvery(SAVE_TOAST_ALERT, saveToastAlertSaga)
  yield takeEvery(CLEAR_TOAST_ALERT, clearToastAlertsSaga)
}

export default alertsSaga
