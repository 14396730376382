import React, { Component } from 'react'
import { connect } from "react-redux"

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';

import { STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS, STUDENT_TUTOR_REQUEST_PROGRESS } from 'routes/paths';
import { triggerReducerLoadingAction, submitApiCallWithReducerAction } from "store/actions"

import { ThemeSpinner } from 'theme/elements/spinner';
import { ThemeSoftBadge } from 'theme/elements/badges';
import { LOAD_TUTOR_REQUESTS, SAVE_TUTOR_REQUESTS, TUTOR_REQUEST_ERROR } from 'store/tutorRequests/actionTypes';

class MyTutorRequests extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            studentId: props.match.params.studentId,
        }
    }
    
    componentDidMount() {
        let { studentId } = this.state;
        let { onReducerLoading, onSubmitApiCallWithReducer } = this.props;
        
        onReducerLoading(LOAD_TUTOR_REQUESTS);
        onSubmitApiCallWithReducer("getTutorRequestsForStudent", SAVE_TUTOR_REQUESTS, studentId, TUTOR_REQUEST_ERROR);
    }

    render() {
        let { tutorRequestsReducer: {tutorRequests, loading } } = this.props

        return (
            <>
                <BTNMetaTags title="My Tutor Requests" />
                
                <Container fluid>
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 h4">My Tutor Requests</CardTitle>
                                    {
                                        loading 
                                            ?
                                                <center><ThemeSpinner /></center>
                                            :
                                                <>
                                                    {
                                                        Array.isArray(tutorRequests) && tutorRequests.length > 0
                                                            ?
                                                                tutorRequests.map((tutorRequest, key) =>
                                                                <div key={key}>
                                                                    <li>
                                                                    {/* <Link to={STUDENT_TUTOR_REQUEST_PROGRESS + "/" + tutorRequest.id}> */}
                                                                        <Link to={(tutorRequest.hasMarketRequest ? STUDENT_MARKETPLACE_TUTOR_REQUEST_PROGRESS : STUDENT_TUTOR_REQUEST_PROGRESS) + "/" + tutorRequest.id}>
                                                                            {tutorRequest.course}
                                                                        </Link>
                                                                        {
                                                                            tutorRequest.potentialTutorsCount > 0 &&
                                                                            <small>{" - "}{tutorRequest.potentialTutorsCount} Tutor(s)</small>
                                                                        }
                                                                        {
                                                                            tutorRequest.tutorContractsCount > 0 &&
                                                                            <small>{" - "}{tutorRequest.tutorContractsCount} Proposal(s)</small>
                                                                        }
                                                                        {
                                                                            tutorRequest.marketProposalsCount > 0 &&
                                                                            <small>{" - "}{tutorRequest.marketProposalsCount} Proposal(s)</small>
                                                                        }
                                                                        {
                                                                            tutorRequest.noTutorAvailable &&
                                                                                <>{" - "}<ThemeSoftBadge color="danger">No Tutor Available</ThemeSoftBadge></>
                                                                        }
                                                                    </li>
                                                                </div>
                                                                )
                                                            
                                                            :
                                                                <center><p>No Recent Tutor Requests</p></center>
                                                    }
                                                </>
                                    }  
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({ tutorRequestsReducer }) => ({
  tutorRequestsReducer: tutorRequestsReducer,
})

const mapDispatchToProps = dispatch => ({
  onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
  onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyTutorRequests)