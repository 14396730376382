import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import {
  Button, Card, CardBody, Col, Container, Input, Label, Row, TabContent, TabPane,
} from "reactstrap"

import { map } from "lodash"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

//Import Email Topbar
import InboxToolbar from "components/inbox/InboxToolbar"

import BTNMetaTags from "components/metatags/BTNMetaTags"
import InboxSidebar from "components/inbox/InboxSidebar"
import { ThemeSpinner } from "theme/elements/spinner"
import moment from "moment"
import { formatTime } from "scripts/general"
import ThreadMessages from "components/inbox/ThreadMessages"
import ThemeSkeleton from "components/skeleton/ThemeSkeleton"

class Inbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      resultArray: [],

      threads: [],
      nextPageToken: null,
      resultSize: null,

      thread: null,
      showThreadMessages: false,
      showToolbar: false
    }
  }

  componentDidMount() {
    const { onSubmitApiCall } = this.props
    onSubmitApiCall("getGmailInbox");
  }

  componentDidUpdate(prevProps, prevState) {
    let { apiResponse = {} } = this.props;
    
    if(prevProps != this.props){
        let newState = processApiResponse(apiResponse, this.state);

        if(apiResponse != {} && prevState != newState){
            this.setState(newState);
        }
    }
  }
  
  getThreadMesages = threadId => {
    const { onSubmitApiCall } = this.props
    this.setState({showThreadMessages: true});
    onSubmitApiCall("getThreadMessages", threadId);
  }

  sendGmailEmail = values => {
    if(values.body == null || values.body.trim() == ""){
        alert("Message Required");
        return;
    }

    const { onSubmitApiCall } = this.props
    
    onSubmitApiCall("postSendGmailEmail", values);
  }

  hasLabel = (thread, label) => {
    let hasLabel = false;
    
    thread.messages.forEach(message => {
        if (message.labelIds.includes(label.toUpperCase()))
            hasLabel = true;
    })
    
    return hasLabel;
  }

  processToFrom = toFrom =>{
    if(toFrom.toLowerCase().includes("@bufftutor.com")){
        return "me"
    }
    else{
        return toFrom.split("<")[0].trim();
    }
  }
  
  processDate = headerDate => {
    if(moment(headerDate).isSame(moment(), 'day')){
        return formatTime(headerDate);
    }
    return moment(headerDate).format("MMM d");
  }

  render() {
    let { apiResponse } = this.props;
    let loading = apiResponse.loading;

    const { threads, resultSize, nextPageToken, thread, showThreadMessages, showToolbar } = this.state;

    return (
        <React.Fragment>
            <BTNMetaTags title="Messages" />
            <Container fluid>
                <Row>
                    <Col xs="12">
                        {/* Render Email SideBar */}
                        <InboxSidebar />

                        <div className="email-rightbar mb-3">
                            <Card>
                                <CardBody>
                                    { loading && threads.length == 0 && 
                                        <Row>
                                            <Col xs={{size: 10, offset: 1}}>
                                                <ThemeSkeleton count={10} lineHeight={"1rem"} />
                                            </Col>
                                        </Row>
                                    }
                                    { loading && <center><ThemeSpinner /></center> }
                                    {
                                        showThreadMessages == false ?
                                            <>  
                                                {/* Render Email Top Tool Bar */}
                                                { showToolbar && <InboxToolbar /> }

                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">
                                                        <ul className="message-list">
                                                            {map(threads, (thread, key) => (
                                                                <li key={key} className={this.hasLabel(thread, "unread") ? "unread" : ""}>
                                                                    <div className="col-mail col-mail-1">
                                                                        <div className="checkbox-wrapper-mail">
                                                                            <Input type="checkbox" id={thread.id} />
                                                                            <Label htmlFor={thread.id} className="toggle" />
                                                                        </div>
                                                                        <Link to="#" className="title" onClick={() => this.getThreadMesages(thread.id)}>
                                                                            {this.processToFrom(thread.from)}
                                                                        </Link>
                                                                        <span className="star-toggle far fa-star" />
                                                                    </div>
                                                                    <div className="col-mail col-mail-2">
                                                                        <div>
                                                                            {thread.subject}
                                                                        </div>
                                                                        <div className="date">{this.processDate(thread.date)}</div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </TabPane>
                                                </TabContent>
                                                {
                                                    threads.length > 0 &&
                                                        <Row>
                                                            <Col xs="7">Showing 1 - {threads.length} of {resultSize}</Col>
                                                            <Col xs="5">
                                                                <div className="btn-group float-end">
                                                                    <Button
                                                                    type="button"
                                                                    color="success"
                                                                    size="sm"
                                                                    >
                                                                    <i className="fa fa-chevron-left" />
                                                                    </Button>
                                                                    <Button
                                                                    type="button"
                                                                    color="success"
                                                                    size="sm"
                                                                    >
                                                                    <i className="fa fa-chevron-right" />
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                }
                                            </>
                                        :
                                            <>
                                                <Row>
                                                    <Col sm={{col: 10, offset: 1}}>
                                                        
                                                        <Button color="secondary" onClick={() => {this.setState({showThreadMessages: false, thread: null})}}>Back</Button>
                                                        {
                                                            thread != null && 
                                                                <ThreadMessages key={thread.id} thread={thread} loading={loading} onSendEmail={this.sendGmailEmail} />
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </CardBody>
                            </Card> 
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
  }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Inbox))