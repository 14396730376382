import React, { Component } from "react"
import { connect } from "react-redux"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import sidebarMenu from 'routes/sidebar'

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add("mm-active")
          }
        }
      }
      return false
    }
    return false
  }

  render() {
    const { user } = this.props;
    const roles = user.roles || [];

    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
              sidebarMenu.map((sidebarItem , i) => 
                  <div key={i}>
                    {
                      (!sidebarItem.hasOwnProperty("role") || (roles.includes("admin") || roles.includes(sidebarItem.role))) 
                        && sidebarItem.items.length > 0 &&
                          <>
                            <li className="menu-title">{sidebarItem.title}</li>
                            {
                              sidebarItem.items.map((item, j)=>
                                <li key={j}>
                                  <Link to={item.path} className=" waves-effect">
                                    <i className={item.icon} />
                                    <span>{item.title}</span>
                                  </Link>
                                </li>
                              )
                            }
                          </>
                    }
                  </div>
              )
            }
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

//export default withRouter(SidebarContent)
// export default withRouter(withTranslation()(SidebarContent))

const mapStateToProps = ({ userReducer }) => ({
  user: userReducer,
})

export default connect(mapStateToProps)(withRouter(SidebarContent))
