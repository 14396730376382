import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ReactDataTable from '../dataTableComponents/ReactDataTable'
import { createSpecifiedColumns } from '../dataTableComponents/scripts';
import { ADMIN_USER_DETAILS } from 'routes/paths';

export default class ReturnRatesTable extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         rows: [],
         columns: [],
         tableColumns: [ "tutor", "student_count", "return_rate" ],
      }
    }

    componentDidMount() { 
        let { returnRates=[] } = this.props;
        this.createColumns();
        this.createRows(returnRates);
    }

    createColumns = () => {
        let { tableColumns } = this.state;

        let columns = createSpecifiedColumns(tableColumns);
        this.setState({columns});
    }

    createRows = (evaluations, admin) => {
        let rows = evaluations;
        rows = this.formatFields(rows);
        
        this.setState({rows});
    }

    formatFields = (rows) => {
        rows.forEach(row => {
            row["tutor"] = <Link to={ADMIN_USER_DETAILS + "/" + row["tutor_ref_id"]} target="_blank">{row["tutor_name"]}</Link>
            row["student_count"] = <>{row["returned_students"]} / {row["total_students"]}</>
            row["return_rate"] = <>{row["return_rate"].toFixed(2) * 100} %</>
        });
        
        return rows;
    }

    render() {
        let {rows, columns } = this.state;
        let tableProps = {...this.props, rows, columns, createColumns: false,
                            fixedHeader: true, fixedHeaderScrollHeight: "200px"};

        return (
            <>
                {
                    columns.length > 0 &&
                        <ReactDataTable {...tableProps} />
                }
            </>
        )
    }
}
