import React from "react"
import { TUTOR_CONNECT_TO_STRIPE, TUTOR_MARKETPLACE_HOW_IT_WORKS } from "routes/paths"

import { Link } from "react-router-dom"

export const tutorChecklistItems = [
            {
                id: 1,
                navTitle: "Account Checklist",
                navIcon: "bx bx-check",
                listIcon: "bx bx-check-circle text-success",
                items: [
                    // {title: "Background Check", body: <>Please submit and pass a background check.  <span className="text-danger">NEEDS LINK!</span>  Annual checks may be required (typically for tutors working with students under 18 years of age).</>},
                    {title: "Stripe Payments", body: <>We use Stripe to process tutor session payments and pay tutors.  <Link to={TUTOR_CONNECT_TO_STRIPE}>Click here </Link> to connect your account to our Stripe platform. <b>Please provide your SSN/TIN number and address when prompted</b>, this will help Stripe generate your 1099 next year. <br /><br />
                                                        <b>Stripe Payment Schedule:</b><br />
                                                        <ul>
                                                            {/* <li className="pb-1">For tutors who set up their Stripe accounts after 09/01/2021, payments will be bundled and transferred to your bank account every Tuesday. For accounts created before 09/01/2021, no changes have been made to the payout schedule.</li> */}
                                                            <li className="pb-1">Payments will be bundled and transferred to your bank account every Tuesday. </li>
                                                            <li className="pb-1">Stripe may impose a waiting period of a few days before making funds available to be transferred to your account.</li>
                                                            <li>Click the View Payments button on your <Link to={"/"}>Dashboard</Link> to view past and pending payments.</li>
                                                        </ul>
                                                        </>},
                    {title: "Tutor Profile", body: <>Complete your profile by adding relevant information, e.g. your academic background, teaching experience, etc.  We share your profile with potential students to review. Click the "Edit Info" button on your <Link to={"/"}>Dashboard</Link></>},
                    {title: "Get To Work", body:<><b>Please review <Link to={TUTOR_MARKETPLACE_HOW_IT_WORKS} className="text-primary">How It Works</Link> </b><br/>
                                                    You will receive an email alert when we receive a tutor request that you would be a good fit for. Carefully review the details of the request and either accept or decline the request. <span className="text-danger"><b>You will have 24 hours to respond to each request</b></span>, students will then have 3 days to accept the match.  <br /><br />
                                                    Pending requests are listed under the <b>My Tutor Requests</b> section on your <Link to={"/"}>Dashboard</Link><br /><br />
                                                    Refer to the "New Assignment Checklist" Tab for steps to take once you have been matched with a student.
                                                    </>},
                    {title: "COMING SOON: Annual Background Checks", body: <>A requirement of our partnership with certain school districts is annual background checks of our tutors. We will email further instructions on how to resubmit a background check in the future.</>},
                    // {title: "Explore Account", body: <>Review the New Assignment Checklist (this page) to view videos on how to charge students.  <br />
                    //                                     Review our cancellation/refund policies.  <Link to={POLICIES_FAQS}>Policies & FAQs</Link></>},
                ]
            },
            {
                id: 2,
                navTitle: "New Assignment Checklist",
                navIcon: "bx bx-user",
                listIcon: "bx bx-user-circle text-info",
                items: [
                    {title: "Contact Student", body: <>Do not make the student wait, contact your new student immediately!  Simply click on the Student Name to view his/her contact information.  Your first email/text/call should be PROFESSIONAL.  Introduce yourself, ask students to elaborate on what they are struggling with and let them know your availability. Details of the session (e.g. when & where to meet, how often) is up to you and the student. </>},
                    {title: "Charge Session", body: <>After each session, use our online tools to charge the session. Simply click the Charge Session button and submit the form.  Do not wait, do it within 3 days of the session.  </>},
                    {title: "Evaluations", body: <>After the first session, ask your student to evaluate your performance.  On the student Dashboard, there is a Star icon under your contact information that leads to the evaluation form.</>},
                    {title: "Missed Sessions", body: <>If a student is late or does not show up, tutors must wait at least 15 minutes before considering the session "missed". Students must provide 24 hours notice to cancel or reschedule a confirmed tutor session.  If they do not, you may charge the missed session (up to the duration of the scheduled session).  <br /><br />
                                                        For Example: If a student cancels a 2 hour session without 24 hour notice, you can charge up to 2 hours.  You can also charge less, for example the 15 minutes you waited for the student. <br /><br />
                                                        <b>Charging a missed session is NOT mandatory</b>, we'll leave this up to your discretion.</>},
                ]
            },

]
