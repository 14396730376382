import React, { Component } from 'react'
import { connect } from "react-redux"
import { Buffer } from "buffer";
import Select from "react-select"
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { cities_with_geo } from 'constants/cities'

import GoogleSubmitSitemapsForm from 'components/forms/google-search-api/GoogleSubmitSitemapsForm'
import GoogleApiLoginForm from 'components/forms/google-business-api/GoogleApiLoginForm'
import GoogleIndexUrls from 'components/forms/google-indexing-api/GoogleIndexUrls'

import ReturnRatesTable from 'components/tables/metrics/ReturnRatesTable';
import AssignmentsMetricsTable from 'components/tables/metrics/AssignmentsMetricsTable';
import RecentTutorActivitiesTable from 'components/tables/metrics/RecentTutorActivitiesTable';
import BatchJobsTable from 'components/tables/scheduled-jobs/batch/BatchJobsTable';


class AdminDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: "",
            locationsArray: [],
            selectedLocations: [],
            adtest: true,
            fetchURL: "",

            returnRates: null,
            assignmentsMetrics: null,
            recentActivities: null,

            failedJobExecutions: []
        }
    }

    componentDidMount() { 
        this.createLocationArray();
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);
    
            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
      }

    createLocationArray = () => {
        
        var locationsArray = [];

        for (const state in cities_with_geo) {
            if (Object.hasOwnProperty.call(cities_with_geo, state)) {
                const citiesArray = cities_with_geo[state];

                citiesArray.forEach(detailsObject => {
                    let city = detailsObject.city;
                    let lat = detailsObject.latitude;
                    let lng = detailsObject.longitude;
                    
                    let value = lat + "," + lng;
                    let label = city + ", " + state;

                    locationsArray.push({value, label});
                })

            }
        }
        
        this.setState({locationsArray});
    }   

    createButtons = () => {
        let { query, selectedLocations } = this.state;
        document.getElementById("nav-btns").innerHTML = "";

        if(selectedLocations != null && selectedLocations.length > 0){
            

            selectedLocations.forEach(loc => {
                let city = loc.label.split(",")[0];
                let lat = loc.value.split(",")[0];
                let lng = loc.value.split(",")[1];

                let searchURL = this.createGoogleSearchURL(lat, lng);
                let mapURL = searchURL + "&tbm=lcl";
                let jobURL = searchURL + "&ibp=htl;jobs";

                var searchButton = document.createElement("a");
                var mapButton = document.createElement("a");
                var jobButton = document.createElement("a");
    
                searchButton.setAttribute("href", searchURL);
                searchButton.setAttribute("class", "btn btn-sm btn-primary");
                searchButton.setAttribute("style", "margin:1em");
                searchButton.setAttribute("target", "_blank");
                searchButton.textContent = "Search: " + query + " in " + city;

                mapButton.setAttribute("href", mapURL);
                mapButton.setAttribute("class", "btn btn-sm btn-success");
                mapButton.setAttribute("style", "margin:1em");
                mapButton.setAttribute("target", "_blank");
                mapButton.textContent = "Map: " + query + " in " + city;

                jobButton.setAttribute("href", jobURL);
                jobButton.setAttribute("class", "btn btn-sm btn-secondary");
                jobButton.setAttribute("style", "margin:1em");
                jobButton.setAttribute("target", "_blank");
                jobButton.textContent = "Job: " + query + " in " + city;
    
                document.getElementById("nav-btns").appendChild(searchButton);
                document.getElementById("nav-btns").appendChild(mapButton);
                document.getElementById("nav-btns").appendChild(jobButton);
                document.getElementById("nav-btns").innerHTML += "<br />";
            })
            
            this.setState({selectedLocations});
        }  
    }

    createBase64UULE = (lat, lng) => {
        let today = new Date()
        let timestamp = today * 1000;
        
        let latE7 = Math.round(lat * 1e7);
        let lngE7 = Math.round(lng * 1e7);

        let location = "role:1\nproducer:12\nprovenance:6\ntimestamp:" + timestamp + "\nlatlng{\nlatitude_e7:" + latE7 + "\nlongitude_e7:" + lngE7 + "\n}\nradius:93000";
        const uule = Buffer.from(location).toString('base64'); 
        
        return uule;
    }

    createGoogleSearchURL = (lat, lng) =>{
        let { query, adtest } = this.state;
        const uule = this.createBase64UULE(lat, lng);
        query = query.replace(/ /g, "+");
        let searchURL = "https://www.google.com/search?q=" + query
                            + "&gl=US&pws=0"
                            + "&uule=a+" +uule;
        
        this.setState({fetchURL: searchURL});

        if(adtest){
            searchURL += "&adtest=on"
        }

        return searchURL;
    }

    fetchResults = url => {
        let proxyurl = "https://cors-anywhere.herokuapp.com/"
        // let proxyurl = ""

        fetch(proxyurl + url, {
            method: "GET",
            headers: {"User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Safari/605.1.15"},
            // mode: 'no-cors'
        })
        // .then(response => response.json())
        .then(response => {
            console.log(response);
        })
        .catch(error =>{
            console.log(error)
        });
    }

    loadReturnRates = () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("retrieveReturnRatesAllTutors");
    }

    loadAssignmentsMetrics = () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("retrieveAssignmentsMetricsAllTutors");
    }

    loadRecentActivities = () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("retrieveRecentTutorActivities");
    }

    loadFailedJobs = () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("listFailedJobs");
    }

    restartAllChargedSessionBatchJos = () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("restartAllChargedSessionBatchJobs");
    }

    restartAllRequestTutorBatchJos = () => {
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("restartAllRequestTutorBatchJobs");
    }

    render() {
        let { apiResponse } = this.props;
        let { query, locationsArray, fetchURL, 
            returnRates, assignmentsMetrics, recentActivities,
            failedJobExecutions } = this.state;
        let loading = apiResponse.loading;

            console.log(failedJobExecutions);
        return (
            <>
                Admin Dashboard
                <br />
                <br />
                
                <Row style={{paddingBottom: "10px"}}>  
                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <h4>Search Local Results</h4>
                                        <Input type="text" value={query} placeholder="Search Query" onChange={event => {this.setState({query: event.target.value}, () => this.createButtons())}} autoFocus={true} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <Select options={locationsArray}
                                                isMulti={true}  
                                                placeholder="Select Cities"
                                                onChange={selectedLocations => {this.setState({selectedLocations}, () => this.createButtons())}} />
                                    </Col>
                                    <Col sm="6">
                                        <button className="btn btn-sm btn-info" onClick={() => this.fetchResults(fetchURL)}>Fetch JSON</button>
                                        <div id="nav-btns"></div>
                                    </Col>
                                </Row> 
                            </CardBody>
                        </Card>
                    </Col>
                        
                    
                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <h4>Submit All Sitemaps</h4>
                                <a href={process.env.REACT_APP_FRONT_URL + "/admin/create-sitemaps"} target="_blank" rel="noreferrer">Create Sitemaps</a>
                                <br /><br />
                                
                                <Row>
                                    <Col sm="4">
                                        <GoogleSubmitSitemapsForm />
                                    </Col>
                                    <Col sm={{size: 4, offset: 4}}>
                                        <GoogleApiLoginForm />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm="6">
                                        <p>Click To Initiate Workers</p>
                                        <GoogleIndexUrls />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                       
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Failed Batch Jobs</CardTitle>
                                <br />
                                <Button color="primary" onClick={() => this.loadFailedJobs()} disabled={loading}>Load Failed Jobs</Button> {" "} {" "} {" "}
                                {
                                    failedJobExecutions.length > 0 &&
                                        <>
                                        <Button color="warning" onClick={() => this.restartAllChargedSessionBatchJos()} disabled={loading}>Restart All Charge Session Jobs</Button>
                                        {" "} {" "} {" "}
                                        <Button color="secondary" onClick={() => this.restartAllRequestTutorBatchJos()} disabled={loading}>Restart All Request Tutor Jobs</Button>
                                        <br /><br />
                                        <BatchJobsTable key={"batch-" + failedJobExecutions.length} jobExecutions={failedJobExecutions} />
                                        </>
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <CardTitle>Tutor Metrics</CardTitle>
                                <br />
                                <Button color="primary" onClick={() => this.loadReturnRates()}>Return Rates</Button>
                                <Button color="success" className="ml-2" onClick={() => this.loadAssignmentsMetrics()}>Assignment Metrics</Button>
                                <Button color="secondary" className="ml-2" onClick={() => this.loadRecentActivities()}>Recent Activity</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    returnRates != null &&
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Return Rates</CardTitle>
                                        {
                                            returnRates.length > 0 &&
                                                <ReturnRatesTable returnRates={returnRates} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                }
                {
                    assignmentsMetrics != null &&
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Assignments Metrics</CardTitle>
                                        {
                                            assignmentsMetrics.length > 0 &&
                                                <AssignmentsMetricsTable assignmentsMetrics={assignmentsMetrics} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                }
                {
                    recentActivities != null && 
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Recent Activity</CardTitle>
                                        {
                                            recentActivities.length > 0 &&
                                                <RecentTutorActivitiesTable recentActivities={recentActivities} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                }
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)