import React, { Component } from 'react'

export default class StudentView extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            studentRate: 0,
        }
    }

    componentDidMount() { 
        const { details } = this.props;
        let studentRate = Number((details.studentRate || 0));
        this.setState({ studentRate });
    }

    render() {
        const { details } = this.props;
        let { studentRate } = this.state;

        studentRate = Number(studentRate);
        let processingFeeRate = details.processingFeePercent;
        let prepayDiscountRate = details.prepayDiscountPercent;
        let discount = studentRate * prepayDiscountRate;
        let processingFee = studentRate * processingFeeRate;

        return (
            <>
                <h4>How Much You Pay</h4>
                <p>Enjoy a lower rate with the Prepaid plan or the flexibility of the Pay As You Go plan.</p>
                <br />
                <h5>Pay As You Go</h5>
                <p>Pay for sessions one at a time after each session occurs.</p>
                <table className="table table-sm">
                    <tbody>
                        <tr>
                            <td>Hourly Rate</td>
                            <td>${studentRate.toFixed(2)}/hr</td>
                        </tr>
                        <tr>
                            <td>Processing Fee ({processingFeeRate * 100}%)</td>
                            <td>+${processingFee.toFixed(2)}/hr</td>
                        </tr>
                        <tr style={{fontWeight: "bold"}}>
                            <td>You Pay</td>
                            <td>${(studentRate + processingFee).toFixed(2)}/hr</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5>Prepay And Save</h5>
                <p>Enjoy a discount and no card processing fees when you prepay for sessions.</p>
                <table className="table table-sm">
                    <tbody>
                        <tr>
                            <td>Hourly Rate</td>
                            <td>${studentRate.toFixed(2)}/hr</td>
                        </tr>
                        <tr>
                            <td>Prepay Discount ({prepayDiscountRate * 100}%)</td>
                            <td>-${discount.toFixed(2)}/hr</td>
                        </tr>
                        <tr>
                            <td>Processing Fee</td>
                            <td>Free!</td>
                        </tr>
                        <tr style={{fontWeight: "bold"}}>
                            <td>You Pay</td>
                            <td>${(studentRate - discount).toFixed(2)}/hr</td>
                        </tr>
                    </tbody>
                </table>
                <p>That's a {(processingFeeRate + prepayDiscountRate) * 100}% savings compared to the Pay As You Go option.</p>
                <p>
                    {/* <span className="text-primary" style={{fontWeight: "bold"}}>That's a {((processingFee + discount)/studentRate * 100).toFixed(0) }% discount overall! </span>
                    <br /> */}
                    <small>* Unused balances are refundable, minus a 15% admin fee. </small>
                </p>
            </>
        )
    }
}
