import React, { Component } from 'react'
import moment from 'moment';
import { replaceAllLinks } from 'scripts/chat';

import { ReactComponent as ChatDocFileSvg } from "theme-chat/assets/media/icons/chatdocmessage.svg";
import { ReactComponent as CopySvg } from "theme-chat/assets/media/icons/copy.svg";
import BTNMessageDropdown from '../Dropdowns/BTNMessageDropdown';

export default class ChatMessage extends Component {
    cleanUpMessage = message => {
        let { showLinks } = this.props;
  
        if(!showLinks){
            message = replaceAllLinks(message);
        }
  
        return message;
    }

    render() {
        let { message, self=false, isAdmin=false } = this.props;

        return (
            <div className={self ? "message self" : "message"}>
                <div className="message-wrapper">
                    <div className="message-content">
                        <span style={{whiteSpace: "pre-line"}}>
                        {
                            message.attachment != null && message.attach != "" ?
                                <>
                                    
                                    {
                                        message.image == true 
                                            ? 
                                            <a href={message.attachment} target="_blank" rel="noreferrer">
                                                <img src={message.attachment} alt={message.body} style={{maxWidth: "150px"}} />
                                            </a>
                                            : 
                                            <div className="document">
                                                <div className="btn btn-info btn-icon rounded-circle text-light mr-2">
                                                    <ChatDocFileSvg />
                                                </div>
                                                <div className="document-body">
                                                    <h6>
                                                        <a href={message.attachment} target="_blank" rel="noreferrer" className="text-info">
                                                            {message.body}
                                                        </a>
                                                    </h6>
                                                    {/* <ul className="list-inline small mb-0">
                                                        <li className="list-inline-item">
                                                        <span className="text-muted">79.2 KB</span>
                                                        </li>
                                                        <li className="list-inline-item">
                                                        <span className="text-muted text-uppercase">
                                                            xlxs
                                                        </span>
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                            
                                    }
                                </>
                            :
                                <>
                                    {this.cleanUpMessage(message.body)}
                                </>
                        }
                        {
                            isAdmin && 
                                <>
                                    <br />
                                    { message.flagged == true  && (
                                        <div className="badge badge-rounded badge-warning ml-1">
                                            Flagged
                                        </div>
                                    )}
                                    { message.disabled == true && (
                                        <div className="badge badge-rounded badge-danger ml-1">
                                            Disabled
                                        </div>
                                    )}
                                </>
                        }
                        
                        </span>
                    </div>
                </div>
                <div className="message-options">
                    {!self && 
                        <div className="avatar avatar-sm">
                            {message.avatar 
                                ? <img alt="" src={message.avatar}></img>
                                : <span className="bg-secondary text-light" style={{fontSize: "1.25rem"}}>{message.userName.substring(0,1)}</span>
                            }
                        </div>
                    }
                    <span className="message-date">
                        {moment().diff(message.createdAt, 'days') < 1 
                        ?  moment(message.createdAt).fromNow()
                        : moment(message.createdAt).format("L LT")}
                    </span>

                    <BTNMessageDropdown isAdmin={isAdmin} message={message} />
                </div>
            </div>
        )
    }
}

