import React, { Component } from 'react'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import reviewProposals from "assets/images/how-it-works/students/01-Student-Review_Proposals.png";
import chatImage from "assets/images/how-it-works/students/02-Chat_With_RequestDetails.png";
import tutorInfo from "assets/images/how-it-works/students/03-Student-Tutor_Contact_Info.png";

import BTNMetaTags from "components/metatags/BTNMetaTags"
import MarketHowItWorks from './MarketHowItWorks';


export default class MarketHowItWorksStudent extends Component {
    render() {
        let steps = [{id: 1, title: "Review Proposals", image: reviewProposals, desc: "After submitting your tutor request, available tutors will submit proposals for you to review over the next 24-48 hours (most students hear from tutors within a few hours). Click on each tutor for more details."},
                {id: 2, title: "Chat With Tutors", image: chatImage, desc: "Our Chat application allows you to further discuss the your academic needs and address any concerns you may have. Click on the 'Request Details' button to create a match (or decline it). Click on the tutor's avatar for more information about the tutor and a link his/her profile."},
                {id: 3, title: "Create A Match", image: tutorInfo, desc: "Click the Create Match button in the original proposal (or Chat) to match with your selected tutor. You will be asked to prepay for the first hour (all future sessions will be charged after they occur). The tutor will then show up on your Dashboard and you will be able to view the tutor's contact information (simply click on the tutor's name). Reach out to the tutor to schedule the first session and get to work!"},
                ]
        return (
            <>
                <BTNMetaTags title="How It Works" />
                
                <Row>
                    <Col md={{size: 6, offset: 3}}>
                        <Card>
                            <CardBody>
                                <CardTitle>How To Match With Tutors</CardTitle>
                                <p>The steps below detail the process of selecting tutors to work with.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <MarketHowItWorks steps={steps} />
            </>
        )
    }
}
