import React, { Component } from "react"

class Answer extends Component {

  render() {
    let answer = this.props.answer || null;
    let image = this.props.image || null;
    let isCorrectAnswer = this.props.isCorrectAnswer || false;

    return (
      <React.Fragment>
        {
          answer &&
          <>
            <span className={isCorrectAnswer ? "text-success" : ""} dangerouslySetInnerHTML={{ __html: answer }}></span>
          </>
        }
        {
          image &&
          <img src={image} />
        }
      </React.Fragment>
    )
  }
}

export default Answer
