import { takeEvery, put, call } from "redux-saga/effects"

import { SUBMIT_API_CALL, SUBMIT_API_CALL_WITH_REDUCER_ACTION } from "./actionTypes";

import { saveAlert, clearAlerts, saveToastAlert, clearToastAlert } from "../actions";
import { saveApiResponseAction, saveApiErrorAction, 
        saveApiReducerAction, saveApiReducerErrorAction } from "./actions";

//ALL API CALL SCRIPTS
import * as scripts from "scripts/all-scripts"

import { processApiError } from "./scripts";


function* submitApiCallSaga({method, data, alertType}){
  yield put(clearToastAlert());
  yield put(clearAlerts());
  
  try {
    const response = yield call(scripts[method], data);
    let message = "";
    if(typeof response == "string"){
        message = response;
    }

    if(typeof response == "object"){
        if(response["alertType"] != undefined){
            alertType = response["alertType"];
        }
        if(response["message"] != undefined){
            message = response["message"];
        }
        
        yield put(saveApiResponseAction(response));
    }
    else{
      yield put(saveApiResponseAction({}));
    }

    if(message != null && message != ""){
      yield put(saveToastAlert(alertType, message));
    }

  } catch (error) {
    let errorMessage = processApiError(error);
    
    if(errorMessage != "Request aborted"){
      // yield put(saveAlert("danger", errorMessage + " " + method));
      // yield put(saveToastAlert("danger", errorMessage + " " + method));
      // yield put(saveAlert("danger", errorMessage));
      yield put(saveToastAlert("danger", errorMessage));
    // yield put(saveApiErrorAction(errorMessage)); //add error to reducer
    }
  }
}

//Run API Call & save data to reducer
function* submitApiCallWithReducerSaga({ method, actionType, data, alertType, errorAction }){
  yield put(clearToastAlert());
  yield put(clearAlerts());

  try {
    const response = yield call(scripts[method], data);
    let message = "";
    
    if(typeof response == "string"){
        message = response;
    }

    if(typeof response == "object"){
        if(response["alertType"] != undefined){
            alertType = response["alertType"];
        }
        if(response["message"] != undefined){
            message = response["message"];
        }
        
        yield put(saveApiReducerAction(response, actionType))
    }
    else {
      yield put(saveApiResponseAction({}));
    }

    if(message != null && message != ""){
      yield put(saveToastAlert(alertType, message));
    }
    
    
  } catch (error) {
    let errorMessage = processApiError(error);
    
    if(errorMessage != "Request aborted"){
      // yield put(saveAlert("danger", errorMessage + " " + method));
      // yield put(saveToastAlert("danger", errorMessage + " " + method));
      // yield put(saveAlert("danger", errorMessage));
      yield put(saveToastAlert("danger", errorMessage));
      
      if(errorAction != null){
        yield put(saveApiReducerErrorAction(errorAction, errorMessage));
      }
    }
  }
}

function* apiSaga() {
  yield takeEvery(SUBMIT_API_CALL, submitApiCallSaga);
  yield takeEvery(SUBMIT_API_CALL_WITH_REDUCER_ACTION, submitApiCallWithReducerSaga);
}

export default apiSaga
